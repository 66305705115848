import { CloseOutlined } from '@ant-design/icons';
import { UploadProps } from 'antd';
import { UploadPatientListResult } from '../../../../uc-api-sdk';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PatientListUploadButtonComponent } from '../../../../uiComponent/PatientListUploadButtonComponent/PatientListUploadButtonComponent';
import { UploadComponent } from '../../../../uiComponent/UploadComponent/UploadComponent';
import { BatchConsentCsvRowCountContainer } from '../../container/BatchConsentCsvRowCountContainer/BatchConsentCsvRowCountContainer';
import './PatientListUploadComponent.scss';

export interface PatientListUploadComponentProps {
  action: UploadProps['action'];
  onRemove?: UploadProps['onRemove'];
  onFileStatusChange: (file: UploadPatientListResult | undefined) => void;
}

export const PatientListUploadComponent = ({
  action,
  onRemove,
  onFileStatusChange,
}: PatientListUploadComponentProps) => (
  <div className="PatientListUploadComponent">
    <div>Patient list should include:</div>
    <ul className="fs14">
      <li>
        <strong>ID * - Required</strong>
      </li>
      <li>
        Optional: First Name, Last Name, DOB, Age, MRN, Primary Insurance,
        Secondary Insurance, Insurance Eligibility, Current Program, Enrolled,
        ICD code.
      </li>
    </ul>
    <UploadComponent
      action={action}
      accept=".csv"
      showUploadList={{
        removeIcon: <CloseOutlined />,
      }}
      maxCount={1}
      onRemove={onRemove}
    >
      <PatientListUploadButtonComponent />
    </UploadComponent>
    <FormItem dependencies={['ptListFile']}>
      {({ getFieldValue }) => {
        const f = getFieldValue('ptListFile');
        return (
          <BatchConsentCsvRowCountContainer
            file={f}
            onFileStatusChange={onFileStatusChange}
          />
        );
      }}
    </FormItem>
  </div>
);
