import { useTableChange } from '../../../../hooks/useTableChange/useTableChange';
import {
  BatchConsentResult, SearchRequest, Sort$Direction, useBatchSendConsentBatchSendConsentResultList
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { BatchSentConsentHistoryTableComponent } from '../../component/BatchSentConsentHistoryTableComponent/BatchSentConsentHistoryTableComponent';

export const BatchSentConsentHistoryTableContainer = () => {
  const tableChange = useTableChange<BatchConsentResult, BatchConsentResult>({
    name: 'BatchSentConsentHistoryTable',
    sort: [
      { direction: Sort$Direction.DESC, property: 'sentAt' },
    ],
    filterProcessor: (filters) => {
      const newFilters: SearchRequest<BatchConsentResult>['filter'] = {};
      if (filters.medicalOrg) {
        newFilters.medicalOrgIdsIn = {
          in: filters.medicalOrg[0] as unknown as string[]
        };
      }
      return newFilters;
    },
  });

  const records = useBatchSendConsentBatchSendConsentResultList({
    options: {
      sendOnMount: true
    },
    params: {
      searchRequest: {
        filter: {
          ...tableChange.processedFilter,
        },
        pageInfo: tableChange.pageInfo,
      }
    }
  });

  return (
    <FetchComponent
      info={records}
    >
      {
        (data, info) => (
          <BatchSentConsentHistoryTableComponent
            dataSource={data?.rawValue?.data?.content || []}
            isLoading={info.isLoading}
            onChange={tableChange.handleTableChange}
            pagination={info.pagination}
            filters={tableChange.filter}
          />
        )
      }
    </FetchComponent>
  );
};
