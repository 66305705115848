import { Image, Typography } from 'antd';
import BgSeverityTooltip from '../../../../assets/icons/bg-severity-tooltip.png';
import BgReadingTooltip from '../../../../assets/icons/bg-reading-tooltip.png';

import './BGTooltipComponent.scss';

const { Text } = Typography;

export const BGTooltipComponent = () => (
  <div className="p12 BGTooltipComponent">
    <div className="mb12">
      <Text className="b7 fs14">
        How to Read BG Log Book
      </Text>
      <Text className="BGTooltipComponent__normal">
        Normal Patients (Non-diabetic and Pre-diabetic)
      </Text>
      <Text className="BGTooltipComponent__dm2">
        DM Patients
      </Text>
    </div>
    <Image src={BgReadingTooltip} preview={false} width={230} height={220} />
    <Image src={BgSeverityTooltip} preview={false} width={900} height={227} className="pl24" />
  </div>
);
