import { usePostWithFormData } from '../../../hooks/ajaxRequest';
import StrictFormData from '../../../hooks/ajaxRequest/formData/strictFormData';
import { RESTfulResponse } from '../../../types/restful';
import { UploadPatientListResult } from '../../../uc-api-sdk';

export const useBatchSendConsentUploadPatientList = () => {
  const {
    mutateAsync: uploadPatientCsv,
  } = usePostWithFormData<
    StrictFormData<{ ptListFile: Blob }>,
    RESTfulResponse<UploadPatientListResult>
  >(
    () => '/batch-send-consent/upload-patient-list',
    {
      logError: true,
      throwError: true,
    },
  )();
  return { uploadPatientCsv };
};
