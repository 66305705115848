import { LanguageType } from '../../types/languages';

type LanguagesMapType = {
  [key in LanguageType]: {
    name: string,
    abbr: string,
    value: string,
  }
}

/* Abbreviation defined using referrence from https://lingohub.com/academy/best-practices/iso-639-2-list */
const LANGUAGES_MAP: LanguagesMapType = {
  [LanguageType.English]: {
    name: 'English',
    abbr: 'ENG',
    value: 'EL',
  },
  [LanguageType.Cantonese]: {
    name: 'Cantonese',
    abbr: 'CAN',
    value: 'CT',
  },
  [LanguageType.Mandarin]: {
    name: 'Mandarin',
    abbr: 'MAN',
    value: 'MN',
  },
  [LanguageType.Spanish]: {
    name: 'Spanish',
    abbr: 'SPA',
    value: 'SP',
  },
  [LanguageType.Vietnamese]: {
    name: 'Vietnamese',
    abbr: 'VIE',
    value: 'VN',
  },
  [LanguageType.Tagalog]: {
    name: 'Tagalog',
    abbr: 'TGL',
    value: 'TA',
  },
  [LanguageType.Japanese]: {
    name: 'Japanese',
    abbr: 'JPN',
    value: 'JP',
  },
  [LanguageType.Korean]: {
    name: 'Korean',
    abbr: 'KOR',
    value: 'KR',
  },
  // this is local language, unofficial language code is zh-yue
  [LanguageType.Taishanese]: {
    name: 'Taishanese',
    abbr: 'YUZ',
    value: 'YUZ', // non-standard, not ISO 639-2 code
  },
  [LanguageType.Amharic]: {
    name: 'Amharic',
    abbr: 'AMH',
    value: 'AH',
  },
  [LanguageType.Akan]: {
    name: 'Akan',
    abbr: 'AKA',
    value: 'AK',
  },
  [LanguageType.Albanian]: {
    name: 'Albanian',
    abbr: 'ALB',
    value: 'AL',
  },
  [LanguageType.Armenian]: {
    name: 'Armenian',
    abbr: 'ARM',
    value: 'AM',
  },
  [LanguageType.Annamese]: {
    name: 'Annamese',
    abbr: 'AN',
    value: 'AN',
  },
  [LanguageType.Arabic]: {
    name: 'Arabic',
    abbr: 'ARA',
    value: 'AR',
  },
  [LanguageType.Assamese]: {
    name: 'Assamese',
    abbr: 'ASM',
    value: 'AS',
  },
  [LanguageType.Azerbaijani]: {
    name: 'Azerbaijani',
    abbr: 'AZE',
    value: 'AZ',
  },
  [LanguageType.Batak]: {
    name: 'Batak',
    abbr: 'BTK',
    value: 'BA',
  },
  [LanguageType.Bulgarian]: {
    name: 'Bulgarian',
    abbr: 'BUL',
    value: 'BB',
  },
  [LanguageType.Bangla]: {
    name: 'Bangla',
    abbr: 'BD',
    value: 'BD',
  },
  [LanguageType.Bugis]: {
    name: 'Bugis',
    abbr: 'BG',
    value: 'BG',
  },
  [LanguageType.Bengali]: {
    name: 'Bengali',
    abbr: 'BEN',
    value: 'BI',
  },
  [LanguageType.Banjarese]: {
    name: 'Banjarese',
    abbr: 'BJ',
    value: 'BJ',
  },
  [LanguageType.Bauan]: {
    name: 'Bauan',
    abbr: 'BN',
    value: 'BN',
  },
  [LanguageType.Bhutanese]: {
    name: 'Bhutanese',
    abbr: 'BT',
    value: 'BT',
  },
  [LanguageType.Burmese]: {
    name: 'Burmese',
    abbr: 'BUR',
    value: 'BU',
  },
  [LanguageType.Boyanese]: {
    name: 'Boyanese',
    abbr: 'BY',
    value: 'BY',
  },
  [LanguageType.Croatian]: {
    name: 'Croatian',
    abbr: 'SCR',
    value: 'CA',
  },
  [LanguageType.Chekiang]: {
    name: 'Chekiang',
    abbr: 'CK',
    value: 'CK',
  },
  [LanguageType.Chaldean]: {
    name: 'Chaldean',
    abbr: 'CL',
    value: 'CL',
  },
  [LanguageType.Cambodian]: {
    name: 'Cambodian',
    abbr: 'CM',
    value: 'CM',
  },
  [LanguageType.Creole]: {
    name: 'Creole',
    abbr: 'CR',
    value: 'CR',
  },
  [LanguageType.Chawan]: {
    name: 'Chawan',
    abbr: 'CW',
    value: 'CW',
  },
  [LanguageType.Czech]: {
    name: 'Czech',
    abbr: 'CZE',
    value: 'CZ',
  },
  [LanguageType.Danish]: {
    name: 'Danish',
    abbr: 'DAN',
    value: 'DA',
  },
  [LanguageType.Dusun]: {
    name: 'Dusun',
    abbr: 'DS',
    value: 'DS',
  },
  [LanguageType.Dutch]: {
    name: 'Dutch',
    abbr: 'DUT',
    value: 'DU',
  },
  [LanguageType.Dayak]: {
    name: 'Dayak',
    abbr: 'DAY',
    value: 'DY',
  },
  [LanguageType.Farsi]: {
    name: 'Farsi',
    abbr: 'FA',
    value: 'FA',
  },
  [LanguageType.Finnish]: {
    name: 'Finnish',
    abbr: 'FIN',
    value: 'FI',
  },
  [LanguageType.Flemish]: {
    name: 'Flemish',
    abbr: 'FL',
    value: 'FL',
  },
  [LanguageType.Foochow]: {
    name: 'Foochow',
    abbr: 'FO',
    value: 'FO',
  },
  [LanguageType.French]: {
    name: 'French',
    abbr: 'FRE',
    value: 'FR',
  },
  [LanguageType.Gujarati]: {
    name: 'Gujarati',
    abbr: 'GUJ',
    value: 'GE',
  },
  [LanguageType.Ghanaian]: {
    name: 'Ghanaian',
    abbr: 'GH',
    value: 'GH',
  },
  [LanguageType.German]: {
    name: 'German',
    abbr: 'GER',
    value: 'GM',
  },
  [LanguageType.Goanese]: {
    name: 'Goanese',
    abbr: 'GO',
    value: 'GO',
  },
  [LanguageType.Greek]: {
    name: 'Greek',
    abbr: 'GR',
    value: 'GR',
  },
  [LanguageType.Gurkhali]: {
    name: 'Gurkhali',
    abbr: 'GU',
    value: 'GU',
  },
  [LanguageType.Hunan]: {
    name: 'Hunan',
    abbr: 'HA',
    value: 'HA',
  },
  [LanguageType.Hebrew]: {
    name: 'Hebrew',
    abbr: 'HEB',
    value: 'HB',
  },
  [LanguageType.Hakka]: {
    name: 'Hakka',
    abbr: 'HC',
    value: 'HC',
  },
  [LanguageType.Hindi]: {
    name: 'Hindi',
    abbr: 'HIN',
    value: 'HD',
  },
  [LanguageType.Hubei]: {
    name: 'Hubei',
    abbr: 'HE',
    value: 'HE',
  },
  [LanguageType.Henghua]: {
    name: 'Henghua',
    abbr: 'HH',
    value: 'HH',
  },
  [LanguageType.Hockchia]: {
    name: 'Hockchia',
    abbr: 'HI',
    value: 'HI',
  },
  [LanguageType.Hokkien]: {
    name: 'Hokkien',
    abbr: 'HK',
    value: 'HK',
  },
  [LanguageType.Hainanese]: {
    name: 'Hainanese',
    abbr: 'HN',
    value: 'HN',
  },
  [LanguageType.Hindustani]: {
    name: 'Hindustani',
    abbr: 'HT',
    value: 'HT',
  },
  [LanguageType.Hungarian]: {
    name: 'Hungarian',
    abbr: 'HUN',
    value: 'HU',
  },
  [LanguageType.Iban]: {
    name: 'Iban',
    abbr: 'IBA',
    value: 'IB',
  },
  [LanguageType.Icelandic]: {
    name: 'Icelandic',
    abbr: 'ICE',
    value: 'IC',
  },
  [LanguageType.Indonesian]: {
    name: 'Indonesian',
    abbr: 'IND',
    value: 'ID',
  },
  [LanguageType.Ilongo]: {
    name: 'Ilongo',
    abbr: 'IG',
    value: 'IG',
  },
  [LanguageType.Ilocano]: {
    name: 'Ilocano',
    abbr: 'IH',
    value: 'IH',
  },
  [LanguageType.Irish]: {
    name: 'Irish',
    abbr: 'GLE',
    value: 'IR',
  },
  [LanguageType.Italian]: {
    name: 'Italian',
    abbr: 'ITA',
    value: 'IT',
  },
  [LanguageType.Javanese]: {
    name: 'Javanese',
    abbr: 'JAV',
    value: 'JA',
  },
  [LanguageType.Jaffnese]: {
    name: 'Jaffnese',
    abbr: 'JF',
    value: 'JF',
  },
  [LanguageType.Kannada]: {
    name: 'Kannada',
    abbr: 'KAN',
    value: 'KA',
  },
  [LanguageType.Khasi]: {
    name: 'Khasi',
    abbr: 'KHA',
    value: 'KB',
  },
  [LanguageType.Kikuyu]: {
    name: 'Kikuyu',
    abbr: 'KE',
    value: 'KE',
  },
  [LanguageType.Khmer]: {
    name: 'Khmer',
    abbr: 'KHM',
    value: 'KH',
  },
  [LanguageType.Kiangsi]: {
    name: 'Kiangsi',
    abbr: 'KI',
    value: 'KI',
  },
  [LanguageType.Khek]: {
    name: 'Khek',
    abbr: 'KK',
    value: 'KK',
  },
  [LanguageType.Kelabit]: {
    name: 'Kelabit',
    abbr: 'KL',
    value: 'KL',
  },
  [LanguageType.Kashmiri]: {
    name: 'Kashmiri',
    abbr: 'KAS',
    value: 'KM',
  },
  [LanguageType.Karen]: {
    name: 'Karen',
    abbr: 'KAR',
    value: 'KN',
  },
  [LanguageType.Konkani]: {
    name: 'Konkani',
    abbr: 'KOK',
    value: 'KO',
  },
  [LanguageType.Kwongsai]: {
    name: 'Kwongsai',
    abbr: 'KS',
    value: 'KS',
  },
  [LanguageType.Kayan]: {
    name: 'Kayan',
    abbr: 'KY',
    value: 'KY',
  },
  [LanguageType.Kadazan]: {
    name: 'Kadazan',
    abbr: 'KZ',
    value: 'KZ',
  },
  [LanguageType.Lao]: {
    name: 'Lao',
    abbr: 'LAO',
    value: 'LA',
  },
  [LanguageType.Lithuanian]: {
    name: 'Lithuanian',
    abbr: 'LIT',
    value: 'LH',
  },
  [LanguageType.Luichew]: {
    name: 'Luichew',
    abbr: 'LU',
    value: 'LU',
  },
  [LanguageType.Maltese]: {
    name: 'Maltese',
    abbr: 'MLT',
    value: 'MA',
  },
  [LanguageType.Malabari]: {
    name: 'Malabari',
    abbr: 'MB',
    value: 'MB',
  },
  [LanguageType.Myanmar]: {
    name: 'Myanmar',
    abbr: 'MC',
    value: 'MC',
  },
  [LanguageType.Mandingo]: {
    name: 'Mandingo',
    abbr: 'MAN',
    value: 'MD',
  },
  [LanguageType.Minangkabau]: {
    name: 'Minangkabau',
    abbr: 'MIN',
    value: 'ME',
  },
  [LanguageType.Montenegrin]: {
    name: 'Montenegrin',
    abbr: 'MF',
    value: 'MF',
  },
  [LanguageType.Malagasy]: {
    name: 'Malagasy',
    abbr: 'MLG',
    value: 'MG',
  },
  [LanguageType.Manchu]: {
    name: 'Manchu',
    abbr: 'MNC',
    value: 'MH',
  },
  [LanguageType.Mongolian]: {
    name: 'Mongolian',
    abbr: 'MON',
    value: 'MI',
  },
  [LanguageType.Maldivian]: {
    name: 'Maldivian',
    abbr: 'ML',
    value: 'ML',
  },
  [LanguageType.Malayalam]: {
    name: 'Malayalam',
    abbr: 'MAL',
    value: 'MM',
  },
  [LanguageType.Maori]: {
    name: 'Maori',
    abbr: 'MAO',
    value: 'MO',
  },
  [LanguageType.Marathi]: {
    name: 'Marathi',
    abbr: 'MAR',
    value: 'MR',
  },
  [LanguageType.Multani]: {
    name: 'Multani',
    abbr: 'MT',
    value: 'MT',
  },
  [LanguageType.Malay]: {
    name: 'Malay',
    abbr: 'MAY',
    value: 'MY',
  },
  [LanguageType.Melanau]: {
    name: 'Melanau',
    abbr: 'MZ',
    value: 'MZ',
  },
  [LanguageType.Nepali]: {
    name: 'Nepali',
    abbr: 'NEP',
    value: 'NE',
  },
  [LanguageType.Norwegian]: {
    name: 'Norwegian',
    abbr: 'NOR',
    value: 'NO',
  },
  [LanguageType.Nepalese]: {
    name: 'Nepalese',
    abbr: 'NP',
    value: 'NP',
  },
  [LanguageType.Newari]: {
    name: 'Newari',
    abbr: 'NEW',
    value: 'NW',
  },
  [LanguageType.Oriya]: {
    name: 'Oriya',
    abbr: 'ORI',
    value: 'OR',
  },
  [LanguageType.Persian]: {
    name: 'Persian',
    abbr: 'PER',
    value: 'PE',
  },
  [LanguageType.Filipino]: {
    name: 'Filipino',
    abbr: 'PH',
    value: 'PH',
  },
  [LanguageType.Pekinese]: {
    name: 'Pekinese',
    abbr: 'PI',
    value: 'PI',
  },
  [LanguageType.Punjabi]: {
    name: 'Punjabi',
    abbr: 'PJ',
    value: 'PJ',
  },
  [LanguageType.Polish]: {
    name: 'Polish',
    abbr: 'POL',
    value: 'PL',
  },
  [LanguageType.Pathan]: {
    name: 'Pathan',
    abbr: 'PN',
    value: 'PN',
  },
  [LanguageType.Portuguese]: {
    name: 'Portuguese',
    abbr: 'POR',
    value: 'PO',
  },
  [LanguageType.Pampangan]: {
    name: 'Pampangan',
    abbr: 'PP',
    value: 'PP',
  },
  [LanguageType.Pushtu]: {
    name: 'Pushtu',
    abbr: 'PU',
    value: 'PU',
  },
  [LanguageType.Rakhine]: {
    name: 'Rakhine',
    abbr: 'RK',
    value: 'RK',
  },
  [LanguageType.Romanian]: {
    name: 'Romanian',
    abbr: 'RUM',
    value: 'RO',
  },
  [LanguageType.Russian]: {
    name: 'Russian',
    abbr: 'RUS',
    value: 'RU',
  },
  [LanguageType.Shan]: {
    name: 'Shan',
    abbr: 'SHN',
    value: 'SA',
  },
  [LanguageType.Scottish]: {
    name: 'Scottish',
    abbr: 'SB',
    value: 'SB',
  },
  [LanguageType.Szechuan]: {
    name: 'Szechuan',
    abbr: 'SC',
    value: 'SC',
  },
  [LanguageType.Sindhi]: {
    name: 'Sindhi',
    abbr: 'SND',
    value: 'SD',
  },
  [LanguageType.Serbian]: {
    name: 'Serbian',
    abbr: 'SCC',
    value: 'SE',
  },
  [LanguageType.SwissGerman]: {
    name: 'Swiss German',
    abbr: 'SG',
    value: 'SG',
  },
  [LanguageType.Shanghainese]: {
    name: 'Shanghainese',
    abbr: 'SH',
    value: 'SH',
  },
  [LanguageType.Sinhalese]: {
    name: 'Sinhalese',
    abbr: 'SIN',
    value: 'SI',
  },
  [LanguageType.Slovak]: {
    name: 'Slovak',
    abbr: 'SLO',
    value: 'SL',
  },
  [LanguageType.Shansi]: {
    name: 'Shansi',
    abbr: 'SN',
    value: 'SN',
  },
  [LanguageType.Sundanese]: {
    name: 'Sundanese',
    abbr: 'SUN',
    value: 'SS',
  },
  [LanguageType.Shantung]: {
    name: 'Shantung',
    abbr: 'ST',
    value: 'ST',
  },
  [LanguageType.Slavic]: {
    name: 'Slavic',
    abbr: 'SV',
    value: 'SV',
  },
  [LanguageType.Swedish]: {
    name: 'Swedish',
    abbr: 'SWE',
    value: 'SW',
  },
  [LanguageType.Teochew]: {
    name: 'Teochew',
    abbr: 'TC',
    value: 'TC',
  },
  [LanguageType.Telugu]: {
    name: 'Telugu',
    abbr: 'TEL',
    value: 'TE',
  },
  [LanguageType.Thai]: {
    name: 'Thai',
    abbr: 'THA',
    value: 'TH',
  },
  [LanguageType.Tibetan]: {
    name: 'Tibetan',
    abbr: 'TIB',
    value: 'TI',
  },
  [LanguageType.Tamil]: {
    name: 'Tamil',
    abbr: 'TAM',
    value: 'TM',
  },
  [LanguageType.Tongan]: {
    name: 'Tongan',
    abbr: 'TO',
    value: 'TO',
  },
  [LanguageType.Turkish]: {
    name: 'Turkish',
    abbr: 'TUR',
    value: 'TU',
  },
  [LanguageType.Taiwanese]: {
    name: 'Taiwanese',
    abbr: 'TW',
    value: 'TW',
  },
  [LanguageType.Unknown]: {
    name: 'Unknown',
    abbr: 'UN',
    value: 'UN',
  },
  [LanguageType.Urdu]: {
    name: 'Urdu',
    abbr: 'URD',
    value: 'UR',
  },
  [LanguageType.Visayan]: {
    name: 'Visayan',
    abbr: 'VS',
    value: 'VS',
  },
  [LanguageType.Wenchow]: {
    name: 'Wenchow',
    abbr: 'WC',
    value: 'WC',
  },
  [LanguageType.Welsh]: {
    name: 'Welsh',
    abbr: 'WEL',
    value: 'WE',
  },
  [LanguageType.Others]: {
    name: 'Others',
    abbr: 'Others',
    value: 'XX',
  },
  [LanguageType.Yiddish]: {
    name: 'Yiddish',
    abbr: 'YID',
    value: 'YI',
  },
  [LanguageType.Hmong]: {
    name: 'Hmong',
    abbr: 'HM',
    value: 'HM',
  },
};

export default LANGUAGES_MAP;
