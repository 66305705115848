import { ClinicalOrgData } from '../../../types/organizations/clinicalOrgs';
import { ConsentForm, EmployeeStatus } from '../../../uc-api-sdk';
import {
  CLINICAL_ORG_STATUS_TEXT,
  ENTITY_CHILD, ENTITY_TEXT
} from '../constants';
import { ClinicalOrgStatusEnum, EntityType } from '../types';
import { MEMBERS_STATUS_TEXT } from '../users/constant';

const MISSING_TEXT = 'MISSING TEXT!';

export const getEntityTextBasedFromType = (type?: EntityType) => (
  type ? (ENTITY_TEXT[type] || MISSING_TEXT) : undefined
);

export const getChildEntityType = (type?: EntityType) => (
  type ? ENTITY_CHILD[type] : undefined
);

export const getChildEntityTextBasedFromType = (type?: EntityType) => {
  const childType = getChildEntityType(type);
  return childType ? (ENTITY_TEXT[childType] || MISSING_TEXT) : undefined;
};

export const getClinicalOrgStatusText = (status?: ClinicalOrgStatusEnum) => (
  status ? (CLINICAL_ORG_STATUS_TEXT[status] || MISSING_TEXT) : undefined
);

export const getMembersStatusText = (status?: EmployeeStatus) => (
  status ? (MEMBERS_STATUS_TEXT[status] || MISSING_TEXT) : undefined
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const searchTreeWithId: any = (element: any, matchingId: string) => {
  if (element.id === matchingId) {
    return element;
  } if (element.children != null) {
    let i;
    let result = null;
    // eslint-disable-next-line no-plusplus
    for (i = 0; result == null && i < element.children.length; i++) {
      result = searchTreeWithId(element.children[i], matchingId);
    }
    return result;
  }
  return null;
};

export const parseClinicName = (clinic: ClinicalOrgData) => {
  const {
    nickName, businessName, city, state, sequenceNum, parentName,
  } = clinic;
  let clinicName = '';
  if (sequenceNum) clinicName = `${sequenceNum}-`;
  if (parentName) {
    clinicName = `${clinicName}${parentName} / `;
  }
  clinicName = `${clinicName}${nickName || businessName}`;
  if (city || state) clinicName = `${clinicName} (${[city, state].join('/')})`;
  return clinicName;
};

export const getConsentFormName = (form: ConsentForm) => {
  const s = form.name;
  return form.languages ? `${s} (${form.languages.sort().join(',')})` : s;
};
