import { Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { DeviceTypeEnumComponent } from '../../../../enumComponent/DeviceTypeEnumComponent/DeviceTypeEnumComponent';
import {
  CgmDeviceStatusEnum,
  DeviceModelEnum,
  DeviceTypeEnum,
  PatientCgmDevice,
  PatientCgmDeviceDto,
  VitalEnumType
} from '../../../../uc-api-sdk';
import { DeviceLabelComponent } from '../../../device/component/DeviceLabelComponent/DeviceLabelComponent';
import { DeviceImageComponent } from '../../../device/DeviceImageComponent';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { CGMDeviceDrawerComponent } from '../CGMDeviceDrawerComponent/CGMDeviceDrawerComponent';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { CGMLinkingStatus } from '../CGMLinkingStatusComponent/CGMLinkingStatusComponent';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { CGMDeviceDaysLeftComponent } from '../CGMDeviceDaysLeftComponent/CGMDeviceDaysLeftComponent';

export interface CGMDeviceItemComponentProps {
  patientId: string;
  device?: PatientCgmDevice | null;
  linkingStatus?: PatientCgmDeviceDto;
}

export const CGMDeviceItemComponent = ({
  patientId,
  device,
  linkingStatus,
}: CGMDeviceItemComponentProps) => {
  const {
    isOpen: isOpenDetails,
    open: openDetails,
    close: closeDetails,
  } = useOpen();

  const {
    status,
    deviceStatus,
    lastCgmGeneratedAt: lastSyncedAt,
  } = linkingStatus || {};
  const {
    deviceStartTime,
    deviceEndTime,
  } = deviceStatus || {};
  const isLinked = status === CgmDeviceStatusEnum.LINK_SUCCESS;

  const renderStatus = () => {
    if (!isLinked) {
      return (<CGMLinkingStatus.IncompleteLinking />);
    }
    if (isEmpty(deviceStatus)) {
      return (<CGMLinkingStatus.NoDeviceStatus />);
    }
    if (isLinked) {
      const isToday = lastSyncedAt && (
        TimezoneService.calcDateTimeDayjs(lastSyncedAt).isSame(dayjs(), 'day')
      );
      return (
        <div>
          Last Synced:
          {' '}
          {
            isToday
              ? (
                <div className="text-green">
                  Today
                </div>
              )
              : (
                <DisplayDateComponent
                  value={lastSyncedAt}
                  format="MMDDYY"
                />
              )
          }
        </div>
      );
    }
    return (<CGMLinkingStatus.IncompleteLinking />);
  };

  return (
    <>
      <Row>
        <Col span={5}>
          <TextComponent>
            <DeviceTypeEnumComponent
              value={VitalEnumType.CGM as unknown as DeviceTypeEnum}
            />
          </TextComponent>
          <div className="text-gray-scale-2 flex">
            <LinkButton
              useBlueColor
              onClick={openDetails}
            >
              See Details
            </LinkButton>
          </div>
        </Col>
        <Col span={9} className="overflow-h">
          <Row className="flex jc-sb">
            <div className="flex gap5 ai-c">
              <div className="device-item">
                <DeviceImageComponent deviceModel={DeviceModelEnum.CGM} width={82} />
              </div>
              <div className="flex fd-c">
                <TextComponent bold>
                  <DeviceLabelComponent
                    device={{
                      deviceModel: DeviceModelEnum.CGM,
                      brand: device?.brand,
                      model: device?.model,
                    }}
                    showImage={false}
                  />
                </TextComponent>
                {renderStatus()}
              </div>
            </div>
          </Row>
        </Col>
        {
          deviceStatus
          && (
            <Col className="flex ai-c ml40">
              <div className="flex">
                <div>
                  Start Date:
                  {' '}
                  {
                    deviceStartTime
                      ? (
                        <DisplayDateComponent
                          value={linkingStatus?.deviceStatus?.deviceStartTime}
                          format="MMDDYY"
                        />
                      ) : <EmptyComponent />
                  }
                  {' '}
                  <CGMDeviceDaysLeftComponent
                    startDate={deviceStartTime}
                    endDate={deviceEndTime}
                  />
                </div>
              </div>
            </Col>
          )
        }
      </Row>
      <CGMDeviceDrawerComponent
        patientId={patientId}
        open={isOpenDetails}
        onClose={closeDetails}
      />
    </>
  );
};
