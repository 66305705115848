import { compact } from 'lodash';
import { ReactNode } from 'react';
import { PatientConsent, usePatientGetConsentFormHistory } from '../../../../uc-api-sdk';

export interface ConsentFormFetchContainerChildrenProps {
  consentForms?: PatientConsent[];
  isLoading: boolean;
}

export interface ConsentFormFetchContainerProps {
  patientId: string;
  children: (props: ConsentFormFetchContainerChildrenProps) => ReactNode;
}

export const ConsentFormFetchContainer = ({
  patientId,
  children,
}: ConsentFormFetchContainerProps) => {
  const consentInfo = usePatientGetConsentFormHistory({
    options: { sendOnMount: true },
    params: { memberId: patientId },
  });

  const isLoading = (consentInfo.data?.data === undefined || consentInfo.isLoading);

  return (
    <>
      {
        children({
          isLoading,
          consentForms: compact(consentInfo.data?.data),
        })
      }
    </>
  );
};
