// @ts-nocheck
/* eslint-disable */
/* eslint-disable lines-between-class-members */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/export */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-tabs */
/* eslint-disable no-shadow */
import axios from 'axios';
import { useMemo } from 'react';
import { isArray, map } from 'lodash';
import dayjs from 'dayjs';
import {
  UseRequestFactory,
  useRequestFactory,
  MakeRequestHookConfig,
  AjaxOptions,
  GetOptions,
  BaseController,
  MethodTypeEnum,
  BaseGetParams,
  BaseDeleteParams,
  BaseInsertParams,
  BaseSearchParams,
  BaseUpdateParams,
} from './staticFiles/useReqHook';

export type Nullable<T> = T | null;

export interface Headers { [key: string]: string; }
interface QueryParam { name: string, value: string }

export interface RequestOption<T, M = undefined> {
  baseUrl?: string;
  isMock?: boolean;
  params: T;
  headers?: Headers;
  mocker?: (mocker: M) => M;
}
const getQueryStrings = (queryParams: QueryParam[]) => {
  let str = '';
  for (let i = 0; i < queryParams.length; i += 1) {
    if (isArray(queryParams[i].value)) {
      for (let j = 0; j < queryParams[i].value.length; j += 1) {
        if (i === 0 && j === 0) {
          str += '?';
        } else {
          str += '&';
        }
        str += `${queryParams[i].name}=${queryParams[i].value[j]}`;
      }
    } else {
      if (i === 0) {
        str += '?';
      } else {
        str += '&';
      }
      str += `${queryParams[i].name}=${queryParams[i].value}`;
    }
  }
  return str;
};
export interface RangeFilter<T> {
	gt?: Nullable<T>;
	lt?: Nullable<T>;
	gte?: Nullable<T>;
	lte?: Nullable<T>;
}
export interface DateRangeFilter extends RangeFilter<string> {
}
export interface InFilter<T> {
	in?: Nullable<T[]>;
	nin?: Nullable<T[]>;
}
export interface Pair<S, T> {
	first?: Nullable<S>;
	second?: Nullable<T>;
}
export interface OrFilter {
	pairs?: Nullable<Pair<string, unknown>[]>;
	keyValues?: Nullable<{ [key: string]: unknown }>;
	key?: Nullable<string>;
	values?: Nullable<unknown[]>;
	keys?: Nullable<string[]>;
	value?: Nullable<unknown>;
}
export interface UnsetUpdate {
	fields?: Nullable<string[]>;
}
export interface BaseModel {
	id?: Nullable<string>;
	createdAt?: Nullable<string>;
	updatedAt?: Nullable<string>;
	createdAtRange?: Nullable<DateRangeFilter>;
	updatedAtRange?: Nullable<DateRangeFilter>;
	idIn?: Nullable<InFilter<string>>;
	$or?: Nullable<OrFilter>;
	$unset?: Nullable<UnsetUpdate>;
	$set?: Nullable<{ [key: string]: unknown }>;
}
export interface BillableHealthCondition extends BaseModel {
	description?: Nullable<string>;
	code?: Nullable<string>;
}
export enum ResultSourceEnum {
	VISIT = 'VISIT',
	PROFILE = 'PROFILE',
	MEASUREMENT = 'MEASUREMENT',
	MANUALLY_INPUT = 'MANUALLY_INPUT',
	TRANSCRIBING = 'TRANSCRIBING',
	EHR = 'EHR',
	BASELINE_TRANSCRIBING = 'BASELINE_TRANSCRIBING',
	MIXED = 'MIXED',
}
export interface Weight {
	value?: Nullable<number>;
	unit?: Nullable<string>;
	bmi?: Nullable<number>;
	source?: Nullable<ResultSourceEnum>;
	updatedAt?: Nullable<string>;
}
export interface Height {
	value?: Nullable<number>;
	unit?: Nullable<string>;
}
export enum ProgramCategoryEnum {
	CCM = 'CCM',
	RPM = 'RPM',
	VALUE_BASED = 'VALUE_BASED',
	APCM = 'APCM',
	MNT = 'MNT',
	MEMBERSHIP = 'MEMBERSHIP',
	value = 'value',
}
export interface ProgramCategories {
	name?: Nullable<ProgramCategoryEnum>;
	enrolled?: Nullable<boolean>;
}
export interface MedicationManagement {
	reconcilationCompliance?: Nullable<string>;
	otherInfo?: Nullable<string>;
	extraNote?: Nullable<string>;
}
export interface LastMeasuredByType {
	BG?: Nullable<string>;
	BP?: Nullable<string>;
	HS?: Nullable<string>;
	TM?: Nullable<string>;
}
export interface Profile {
	gender?: Nullable<string>;
	insuranceProvider?: Nullable<string>;
	insuranceProvider2?: Nullable<string>;
	firstName?: Nullable<string>;
	lastName?: Nullable<string>;
	language?: Nullable<string>;
	languages?: Nullable<string[]>;
	memberId?: Nullable<string>;
	billableHealthConditions?: Nullable<BillableHealthCondition[]>;
	appLanguage?: Nullable<string>;
	birthday?: Nullable<string>;
	doctorId?: Nullable<string>;
	doctorName?: Nullable<string>;
	handouts?: Nullable<string[]>;
	languageIn?: Nullable<InFilter<string>>;
	weight?: Nullable<Weight>;
	height?: Nullable<Height>;
	npi?: Nullable<string>;
	programCategories?: Nullable<ProgramCategories[]>;
	medicationManagement?: Nullable<MedicationManagement>;
	healthConditions?: Nullable<string[]>;
	lastMeasuredByType?: Nullable<LastMeasuredByType>;
	lastMeasuredAt?: Nullable<string>;
}
export interface Role {
	organizationId?: Nullable<string>;
	roleId?: Nullable<string>;
	active?: Nullable<boolean>;
	startFrom?: Nullable<string>;
	organizationIdIn?: Nullable<InFilter<string>>;
}
export interface Email {
	address?: Nullable<string>;
	verified?: Nullable<boolean>;
}
export interface Auth {
	suspended?: Nullable<boolean>;
	username?: Nullable<string>;
}
export interface Identification {
	type?: Nullable<string>;
	value?: Nullable<string>;
	MRN?: Nullable<boolean>;
}
export interface ElemMatchFilter<T> {
	match?: Nullable<T[]>;
	notMatch?: Nullable<T[]>;
}
export enum PhoneTypeEnum {
	HOME = 'HOME',
	MOBILE = 'MOBILE',
	WORK = 'WORK',
}
export interface Phone {
	countryCode?: Nullable<string>;
	areaCode?: Nullable<string>;
	number?: Nullable<string>;
	verified?: Nullable<boolean>;
	isPrimary?: Nullable<boolean>;
	isPrivate?: Nullable<boolean>;
	type?: Nullable<PhoneTypeEnum>;
	canUseForLogin?: Nullable<boolean>;
	isSmartPhone?: Nullable<boolean>;
}
export interface Account extends BaseModel {
	profile?: Nullable<Profile>;
	role?: Nullable<Role[]>;
	email?: Nullable<Email[]>;
	majorRole?: Nullable<string>;
	auth?: Nullable<Auth>;
	monthlyMeasurementDays?: Nullable<number>;
	timezone?: Nullable<string>;
	profileLanguageIn?: Nullable<InFilter<string>>;
	profileAppLanguageIn?: Nullable<InFilter<string>>;
	profileDoctorIdIn?: Nullable<InFilter<string>>;
	profileBirthdayIn?: Nullable<InFilter<string>>;
	organizationIdIn?: Nullable<InFilter<string>>;
	identification?: Nullable<Identification[]>;
	identificationMatch?: Nullable<ElemMatchFilter<Identification>>;
	phone?: Nullable<Phone[]>;
	phoneMatch?: Nullable<ElemMatchFilter<Phone>>;
	majorRoleIn?: Nullable<InFilter<string>>;
}
export interface PatientAssignedHistory {
	assignedToRD?: Nullable<string>;
	start?: Nullable<string>;
	end?: Nullable<string>;
}
export interface ImageSchema {
	fileKey?: Nullable<string>;
	link?: Nullable<string>;
	thumbnailLink?: Nullable<string>;
	url?: Nullable<string>;
}
export enum RoleTypeEnum {
	SUPER_ADMIN = 'SUPER_ADMIN',
	ADMIN = 'ADMIN',
	MANAGER = 'MANAGER',
	PROVIDER = 'PROVIDER',
	MA = 'MA',
	CLINICAL_GROUP_BILLER = 'CLINICAL_GROUP_BILLER',
	CLINICAL_MANAGER = 'CLINICAL_MANAGER',
	BILLER = 'BILLER',
	RD = 'RD',
	HC = 'HC',
	CA = 'CA',
	CALL_CENTER_REPRESENTATIVE = 'CALL_CENTER_REPRESENTATIVE',
	NP = 'NP',
	QUALITY_TEAM_MEMBER = 'QUALITY_TEAM_MEMBER',
	ACCOUNTING_AUDIT = 'ACCOUNTING_AUDIT',
	MEMBER = 'MEMBER',
	order = 'order',
	label = 'label',
}
export interface LimitedUser {
	id?: Nullable<string>;
	firstName?: Nullable<string>;
	lastName?: Nullable<string>;
	title?: Nullable<string>;
	email?: Nullable<string>;
	avatar?: Nullable<ImageSchema>;
	roles?: Nullable<RoleTypeEnum[]>;
	clinicIds?: Nullable<string[]>;
}
export interface PatientAssignees extends BaseModel {
	__v?: Nullable<number>;
	assignedToRD?: Nullable<string>;
	assignedToCA?: Nullable<string>;
	assignedToRDNotNecessary?: Nullable<boolean>;
	assignedToCANotNecessary?: Nullable<boolean>;
	tag?: Nullable<string>;
	reassignedFromRD?: Nullable<string>;
	reassignedFromCA?: Nullable<string>;
	doctorId?: Nullable<string>;
	medicalOrgId?: Nullable<string>;
	assignedHistory?: Nullable<PatientAssignedHistory[]>;
	createdBy?: Nullable<string>;
	updatedBy?: Nullable<string>;
	assignedToRDIn?: Nullable<InFilter<string>>;
	assignedToCAIn?: Nullable<InFilter<string>>;
	reassignedFromRDIn?: Nullable<InFilter<string>>;
	reassignedFromCAIn?: Nullable<InFilter<string>>;
	assignedVersionIn?: Nullable<InFilter<string>>;
	tagIn?: Nullable<InFilter<string>>;
	doctorIdIn?: Nullable<InFilter<string>>;
	meidcalOrgIdIn?: Nullable<InFilter<string>>;
	assignedToRDUser?: Nullable<LimitedUser>;
	assignedToCAUser?: Nullable<LimitedUser>;
}
export interface APIResponse<T> {
	code?: Nullable<number>;
	data?: Nullable<T>;
	msg?: Nullable<string>;
}
export class RangeFilterClass<T> {
  protected readonly _value: RangeFilter<any>;
	protected readonly gtConstructorFunc?: (value: any) => T;
	protected readonly ltConstructorFunc?: (value: any) => T;
	protected readonly gteConstructorFunc?: (value: any) => T;
	protected readonly lteConstructorFunc?: (value: any) => T;

	constructor(value: RangeFilter<any>, gtConstructorFunc?: (value: any) => T, ltConstructorFunc?: (value: any) => T, gteConstructorFunc?: (value: any) => T, lteConstructorFunc?: (value: any) => T) {
		this._value = value;
		this.gtConstructorFunc = gtConstructorFunc;
		this.ltConstructorFunc = ltConstructorFunc;
		this.gteConstructorFunc = gteConstructorFunc;
		this.lteConstructorFunc = lteConstructorFunc;
	}

  get rawValue() {
    return this._value;
  }

  get gt(): T {
    return this.gtConstructorFunc ? (this._value.gt && this.gtConstructorFunc(this._value.gt)) : this._value.gt;
  }

  get lt(): T {
    return this.ltConstructorFunc ? (this._value.lt && this.ltConstructorFunc(this._value.lt)) : this._value.lt;
  }

  get gte(): T {
    return this.gteConstructorFunc ? (this._value.gte && this.gteConstructorFunc(this._value.gte)) : this._value.gte;
  }

  get lte(): T {
    return this.lteConstructorFunc ? (this._value.lte && this.lteConstructorFunc(this._value.lte)) : this._value.lte;
  }
}
export class DateRangeFilterClass extends RangeFilterClass {
  protected readonly _value: DateRangeFilter;

	constructor(value: DateRangeFilter) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }
}
export class InFilterClass<T> {
  protected readonly _value: InFilter<any>;
	protected readonly inConstructorFunc?: (value: any) => T;
	protected readonly ninConstructorFunc?: (value: any) => T;

	constructor(value: InFilter<any>, inConstructorFunc?: (value: any) => T, ninConstructorFunc?: (value: any) => T) {
		this._value = value;
		this.inConstructorFunc = inConstructorFunc;
		this.ninConstructorFunc = ninConstructorFunc;
	}

  get rawValue() {
    return this._value;
  }

  get in(): T {
    return this.inConstructorFunc ? (this._value.in && this.inConstructorFunc(this._value.in)) : this._value.in;
  }

  get inWithDefault() {
    return this._value.in || [];
  }

  get nin(): T {
    return this.ninConstructorFunc ? (this._value.nin && this.ninConstructorFunc(this._value.nin)) : this._value.nin;
  }

  get ninWithDefault() {
    return this._value.nin || [];
  }
}
export class PairClass<S, T> {
  protected readonly _value: Pair<any, any>;
	protected readonly firstConstructorFunc?: (value: any) => S;
	protected readonly secondConstructorFunc?: (value: any) => T;

	constructor(value: Pair<any, any>, firstConstructorFunc?: (value: any) => S, secondConstructorFunc?: (value: any) => T) {
		this._value = value;
		this.firstConstructorFunc = firstConstructorFunc;
		this.secondConstructorFunc = secondConstructorFunc;
	}

  get rawValue() {
    return this._value;
  }

  get first(): S {
    return this.firstConstructorFunc ? (this._value.first && this.firstConstructorFunc(this._value.first)) : this._value.first;
  }

  get second(): T {
    return this.secondConstructorFunc ? (this._value.second && this.secondConstructorFunc(this._value.second)) : this._value.second;
  }
}
export class OrFilterClass {
  protected readonly _value: OrFilter;

	constructor(value: OrFilter) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get pairs() {
    return this._value.pairs !== undefined && this._value.pairs !== null ? map(this._value.pairs, (v) => new PairClass<string, unknown>(v)) : this._value.pairs;
  }

  get pairsWithDefault() {
    return this._value.pairs || [];
  }

  get keyValues() {
    return this._value.keyValues;
  }

  get keyValuesWithDefault() {
    return this._value.keyValues || {};
  }

  get key() {
    return this._value.key;
  }

  get keyWithDefault() {
    return this._value.key || '';
  }

  get values() {
    return this._value.values;
  }

  get valuesWithDefault() {
    return this._value.values || [];
  }

  get keys() {
    return this._value.keys;
  }

  get keysWithDefault() {
    return this._value.keys || [];
  }

  get value() {
    return this._value.value;
  }
}
export class UnsetUpdateClass {
  protected readonly _value: UnsetUpdate;

	constructor(value: UnsetUpdate) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get fields() {
    return this._value.fields;
  }

  get fieldsWithDefault() {
    return this._value.fields || [];
  }
}
export class BaseModelClass {
  protected readonly _value: BaseModel;

	constructor(value: BaseModel) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get createdAt() {
    return (this._value.createdAt !== undefined && this._value.createdAt !== null) ? dayjs(this._value.createdAt) : this._value.createdAt;
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }

  get createdAtRange() {
    return this._value.createdAtRange !== undefined && this._value.createdAtRange !== null ? new DateRangeFilterClass(this._value.createdAtRange) : this._value.createdAtRange;
  }

  get updatedAtRange() {
    return this._value.updatedAtRange !== undefined && this._value.updatedAtRange !== null ? new DateRangeFilterClass(this._value.updatedAtRange) : this._value.updatedAtRange;
  }

  get idIn() {
    return this._value.idIn !== undefined && this._value.idIn !== null ? new InFilterClass(this._value.idIn) : this._value.idIn;
  }

  get $or() {
    return this._value.$or !== undefined && this._value.$or !== null ? new OrFilterClass(this._value.$or) : this._value.$or;
  }

  get $unset() {
    return this._value.$unset !== undefined && this._value.$unset !== null ? new UnsetUpdateClass(this._value.$unset) : this._value.$unset;
  }

  get $set() {
    return this._value.$set;
  }

  get $setWithDefault() {
    return this._value.$set || {};
  }
}
export class PatientAssignedHistoryClass {
  protected readonly _value: PatientAssignedHistory;

	constructor(value: PatientAssignedHistory) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get assignedToRD() {
    return this._value.assignedToRD;
  }

  get assignedToRDWithDefault() {
    return this._value.assignedToRD || '';
  }

  get start() {
    return (this._value.start !== undefined && this._value.start !== null) ? dayjs(this._value.start) : this._value.start;
  }

  get end() {
    return (this._value.end !== undefined && this._value.end !== null) ? dayjs(this._value.end) : this._value.end;
  }
}
export class ImageSchemaClass {
  protected readonly _value: ImageSchema;

	constructor(value: ImageSchema) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get fileKey() {
    return this._value.fileKey;
  }

  get fileKeyWithDefault() {
    return this._value.fileKey || '';
  }

  get link() {
    return this._value.link;
  }

  get linkWithDefault() {
    return this._value.link || '';
  }

  get thumbnailLink() {
    return this._value.thumbnailLink;
  }

  get thumbnailLinkWithDefault() {
    return this._value.thumbnailLink || '';
  }

  get url() {
    return this._value.url;
  }

  get urlWithDefault() {
    return this._value.url || '';
  }
}
export class LimitedUserClass {
  protected readonly _value: LimitedUser;

	constructor(value: LimitedUser) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get firstName() {
    return this._value.firstName;
  }

  get firstNameWithDefault() {
    return this._value.firstName || '';
  }

  get lastName() {
    return this._value.lastName;
  }

  get lastNameWithDefault() {
    return this._value.lastName || '';
  }

  get title() {
    return this._value.title;
  }

  get titleWithDefault() {
    return this._value.title || '';
  }

  get email() {
    return this._value.email;
  }

  get emailWithDefault() {
    return this._value.email || '';
  }

  get avatar() {
    return this._value.avatar !== undefined && this._value.avatar !== null ? new ImageSchemaClass(this._value.avatar) : this._value.avatar;
  }

  get roles() {
    return this._value.roles;
  }

  get rolesWithDefault() {
    return this._value.roles || [];
  }

  get clinicIds() {
    return this._value.clinicIds;
  }

  get clinicIdsWithDefault() {
    return this._value.clinicIds || [];
  }
}
export class PatientAssigneesClass extends BaseModelClass {
  protected readonly _value: PatientAssignees;

	constructor(value: PatientAssignees) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get __v() {
    return this._value.__v;
  }

  get __vWithDefault() {
    return this._value.__v || 0;
  }

  get assignedToRD() {
    return this._value.assignedToRD;
  }

  get assignedToCA() {
    return this._value.assignedToCA;
  }

  get assignedToRDNotNecessary() {
    return this._value.assignedToRDNotNecessary;
  }

  get assignedToRDNotNecessaryWithDefault() {
    return this._value.assignedToRDNotNecessary || false;
  }

  get assignedToCANotNecessary() {
    return this._value.assignedToCANotNecessary;
  }

  get assignedToCANotNecessaryWithDefault() {
    return this._value.assignedToCANotNecessary || false;
  }

  get tag() {
    return this._value.tag;
  }

  get tagWithDefault() {
    return this._value.tag || '';
  }

  get reassignedFromRD() {
    return this._value.reassignedFromRD;
  }

  get reassignedFromCA() {
    return this._value.reassignedFromCA;
  }

  get doctorId() {
    return this._value.doctorId;
  }

  get medicalOrgId() {
    return this._value.medicalOrgId;
  }

  get assignedHistory() {
    return this._value.assignedHistory !== undefined && this._value.assignedHistory !== null ? map(this._value.assignedHistory, (v) => new PatientAssignedHistoryClass(v)) : this._value.assignedHistory;
  }

  get assignedHistoryWithDefault() {
    return this._value.assignedHistory || [];
  }

  get createdBy() {
    return this._value.createdBy;
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get assignedToRDIn() {
    return this._value.assignedToRDIn !== undefined && this._value.assignedToRDIn !== null ? new InFilterClass(this._value.assignedToRDIn) : this._value.assignedToRDIn;
  }

  get assignedToCAIn() {
    return this._value.assignedToCAIn !== undefined && this._value.assignedToCAIn !== null ? new InFilterClass(this._value.assignedToCAIn) : this._value.assignedToCAIn;
  }

  get reassignedFromRDIn() {
    return this._value.reassignedFromRDIn !== undefined && this._value.reassignedFromRDIn !== null ? new InFilterClass(this._value.reassignedFromRDIn) : this._value.reassignedFromRDIn;
  }

  get reassignedFromCAIn() {
    return this._value.reassignedFromCAIn !== undefined && this._value.reassignedFromCAIn !== null ? new InFilterClass(this._value.reassignedFromCAIn) : this._value.reassignedFromCAIn;
  }

  get assignedVersionIn() {
    return this._value.assignedVersionIn !== undefined && this._value.assignedVersionIn !== null ? new InFilterClass(this._value.assignedVersionIn) : this._value.assignedVersionIn;
  }

  get tagIn() {
    return this._value.tagIn !== undefined && this._value.tagIn !== null ? new InFilterClass(this._value.tagIn) : this._value.tagIn;
  }

  get doctorIdIn() {
    return this._value.doctorIdIn !== undefined && this._value.doctorIdIn !== null ? new InFilterClass(this._value.doctorIdIn) : this._value.doctorIdIn;
  }

  get meidcalOrgIdIn() {
    return this._value.meidcalOrgIdIn !== undefined && this._value.meidcalOrgIdIn !== null ? new InFilterClass(this._value.meidcalOrgIdIn) : this._value.meidcalOrgIdIn;
  }

  get assignedToRDUser() {
    return this._value.assignedToRDUser !== undefined && this._value.assignedToRDUser !== null ? new LimitedUserClass(this._value.assignedToRDUser) : this._value.assignedToRDUser;
  }

  get assignedToCAUser() {
    return this._value.assignedToCAUser !== undefined && this._value.assignedToCAUser !== null ? new LimitedUserClass(this._value.assignedToCAUser) : this._value.assignedToCAUser;
  }
}
export class APIResponseClass<T> {
  protected readonly _value: APIResponse<any>;
	protected readonly dataConstructorFunc?: (value: any) => T;

	constructor(value: APIResponse<any>, dataConstructorFunc?: (value: any) => T) {
		this._value = value;
		this.dataConstructorFunc = dataConstructorFunc;
	}

  get rawValue() {
    return this._value;
  }

  get code() {
    return this._value.code;
  }

  get codeWithDefault() {
    return this._value.code || 0;
  }

  get data(): T {
    return this.dataConstructorFunc ? (this._value.data && this.dataConstructorFunc(this._value.data)) : this._value.data;
  }

  get msg() {
    return this._value.msg;
  }

  get msgWithDefault() {
    return this._value.msg || '';
  }
}
export interface MiniProvider {
	name?: Nullable<string>;
	id?: Nullable<string>;
}
export interface MiniOrganization {
	id?: Nullable<string>;
	name?: Nullable<string>;
	businessName?: Nullable<string>;
	orgNumber?: Nullable<string>;
}
export enum PatientComplexityEnum {
	COMPLEX = 'COMPLEX',
	NON_COMPLEX = 'NON_COMPLEX',
	NULL_CODES = 'NULL_CODES',
}
export interface ReassignOption {
	assignedToRD?: Nullable<MiniProvider[]>;
	assignedToCA?: Nullable<MiniProvider[]>;
	reassignedFromRD?: Nullable<MiniProvider[]>;
	reassignedFromCA?: Nullable<MiniProvider[]>;
	doctor?: Nullable<MiniProvider[]>;
	organization?: Nullable<MiniOrganization[]>;
	language?: Nullable<string[]>;
	appLanguage?: Nullable<string[]>;
	complexity?: Nullable<PatientComplexityEnum[]>;
	tag?: Nullable<string[]>;
}
export class MiniProviderClass {
  protected readonly _value: MiniProvider;

	constructor(value: MiniProvider) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get id() {
    return this._value.id;
  }
}
export class MiniOrganizationClass {
  protected readonly _value: MiniOrganization;

	constructor(value: MiniOrganization) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get businessName() {
    return this._value.businessName;
  }

  get businessNameWithDefault() {
    return this._value.businessName || '';
  }

  get orgNumber() {
    return this._value.orgNumber;
  }

  get orgNumberWithDefault() {
    return this._value.orgNumber || '';
  }
}
export class ReassignOptionClass {
  protected readonly _value: ReassignOption;

	constructor(value: ReassignOption) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get assignedToRD() {
    return this._value.assignedToRD !== undefined && this._value.assignedToRD !== null ? map(this._value.assignedToRD, (v) => new MiniProviderClass(v)) : this._value.assignedToRD;
  }

  get assignedToRDWithDefault() {
    return this._value.assignedToRD || [];
  }

  get assignedToCA() {
    return this._value.assignedToCA !== undefined && this._value.assignedToCA !== null ? map(this._value.assignedToCA, (v) => new MiniProviderClass(v)) : this._value.assignedToCA;
  }

  get assignedToCAWithDefault() {
    return this._value.assignedToCA || [];
  }

  get reassignedFromRD() {
    return this._value.reassignedFromRD !== undefined && this._value.reassignedFromRD !== null ? map(this._value.reassignedFromRD, (v) => new MiniProviderClass(v)) : this._value.reassignedFromRD;
  }

  get reassignedFromRDWithDefault() {
    return this._value.reassignedFromRD || [];
  }

  get reassignedFromCA() {
    return this._value.reassignedFromCA !== undefined && this._value.reassignedFromCA !== null ? map(this._value.reassignedFromCA, (v) => new MiniProviderClass(v)) : this._value.reassignedFromCA;
  }

  get reassignedFromCAWithDefault() {
    return this._value.reassignedFromCA || [];
  }

  get doctor() {
    return this._value.doctor !== undefined && this._value.doctor !== null ? map(this._value.doctor, (v) => new MiniProviderClass(v)) : this._value.doctor;
  }

  get doctorWithDefault() {
    return this._value.doctor || [];
  }

  get organization() {
    return this._value.organization !== undefined && this._value.organization !== null ? map(this._value.organization, (v) => new MiniOrganizationClass(v)) : this._value.organization;
  }

  get organizationWithDefault() {
    return this._value.organization || [];
  }

  get language() {
    return this._value.language;
  }

  get languageWithDefault() {
    return this._value.language || [];
  }

  get appLanguage() {
    return this._value.appLanguage;
  }

  get appLanguageWithDefault() {
    return this._value.appLanguage || [];
  }

  get complexity() {
    return this._value.complexity;
  }

  get complexityWithDefault() {
    return this._value.complexity || [];
  }

  get tag() {
    return this._value.tag;
  }

  get tagWithDefault() {
    return this._value.tag || [];
  }
}
export interface PatientListResult {
	id?: Nullable<string>;
	profile?: Nullable<Profile>;
	doctor?: Nullable<MiniProvider>;
	organization?: Nullable<MiniOrganization>;
	assignedToCA?: Nullable<MiniProvider>;
	assignedToRD?: Nullable<MiniProvider>;
	reassignedFromCA?: Nullable<MiniProvider>;
	reassignedFromRD?: Nullable<MiniProvider>;
	tag?: Nullable<string>;
	updatedAt?: Nullable<string>;
	complexity?: Nullable<PatientComplexityEnum>;
}
export class BillableHealthConditionClass extends BaseModelClass {
  protected readonly _value: BillableHealthCondition;

	constructor(value: BillableHealthCondition) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get code() {
    return this._value.code;
  }

  get codeWithDefault() {
    return this._value.code || '';
  }
}
export class WeightClass {
  protected readonly _value: Weight;

	constructor(value: Weight) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || 0.0;
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }

  get bmi() {
    return this._value.bmi;
  }

  get bmiWithDefault() {
    return this._value.bmi || 0.0;
  }

  get source() {
    return this._value.source;
  }

  sourceIsIn(values: ResultSourceEnum[] = []) {
    return this._value.source && values.includes(this._value.source);
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }
}
export class HeightClass {
  protected readonly _value: Height;

	constructor(value: Height) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || 0.0;
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }
}
export class ProgramCategoriesClass {
  protected readonly _value: ProgramCategories;

	constructor(value: ProgramCategories) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  nameIsIn(values: ProgramCategoryEnum[] = []) {
    return this._value.name && values.includes(this._value.name);
  }

  get enrolled() {
    return this._value.enrolled;
  }

  get enrolledWithDefault() {
    return this._value.enrolled || false;
  }
}
export class MedicationManagementClass {
  protected readonly _value: MedicationManagement;

	constructor(value: MedicationManagement) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get reconcilationCompliance() {
    return this._value.reconcilationCompliance;
  }

  get reconcilationComplianceWithDefault() {
    return this._value.reconcilationCompliance || '';
  }

  get otherInfo() {
    return this._value.otherInfo;
  }

  get otherInfoWithDefault() {
    return this._value.otherInfo || '';
  }

  get extraNote() {
    return this._value.extraNote;
  }

  get extraNoteWithDefault() {
    return this._value.extraNote || '';
  }
}
export class LastMeasuredByTypeClass {
  protected readonly _value: LastMeasuredByType;

	constructor(value: LastMeasuredByType) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get BG() {
    return (this._value.BG !== undefined && this._value.BG !== null) ? dayjs(this._value.BG) : this._value.BG;
  }

  get BP() {
    return (this._value.BP !== undefined && this._value.BP !== null) ? dayjs(this._value.BP) : this._value.BP;
  }

  get HS() {
    return (this._value.HS !== undefined && this._value.HS !== null) ? dayjs(this._value.HS) : this._value.HS;
  }

  get TM() {
    return (this._value.TM !== undefined && this._value.TM !== null) ? dayjs(this._value.TM) : this._value.TM;
  }
}
export class ProfileClass {
  protected readonly _value: Profile;

	constructor(value: Profile) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get gender() {
    return this._value.gender;
  }

  get genderWithDefault() {
    return this._value.gender || '';
  }

  get insuranceProvider() {
    return this._value.insuranceProvider;
  }

  get insuranceProviderWithDefault() {
    return this._value.insuranceProvider || '';
  }

  get insuranceProvider2() {
    return this._value.insuranceProvider2;
  }

  get insuranceProvider2WithDefault() {
    return this._value.insuranceProvider2 || '';
  }

  get firstName() {
    return this._value.firstName;
  }

  get firstNameWithDefault() {
    return this._value.firstName || '';
  }

  get lastName() {
    return this._value.lastName;
  }

  get lastNameWithDefault() {
    return this._value.lastName || '';
  }

  get language() {
    return this._value.language;
  }

  get languageWithDefault() {
    return this._value.language || '';
  }

  get languages() {
    return this._value.languages;
  }

  get languagesWithDefault() {
    return this._value.languages || [];
  }

  get memberId() {
    return this._value.memberId;
  }

  get memberIdWithDefault() {
    return this._value.memberId || '';
  }

  get billableHealthConditions() {
    return this._value.billableHealthConditions !== undefined && this._value.billableHealthConditions !== null ? map(this._value.billableHealthConditions, (v) => new BillableHealthConditionClass(v)) : this._value.billableHealthConditions;
  }

  get billableHealthConditionsWithDefault() {
    return this._value.billableHealthConditions || [];
  }

  get appLanguage() {
    return this._value.appLanguage;
  }

  get appLanguageWithDefault() {
    return this._value.appLanguage || '';
  }

  get birthday() {
    return this._value.birthday;
  }

  get birthdayWithDefault() {
    return this._value.birthday || '';
  }

  get doctorId() {
    return this._value.doctorId;
  }

  get doctorName() {
    return this._value.doctorName;
  }

  get doctorNameWithDefault() {
    return this._value.doctorName || '';
  }

  get handouts() {
    return this._value.handouts;
  }

  get handoutsWithDefault() {
    return this._value.handouts || [];
  }

  get languageIn() {
    return this._value.languageIn !== undefined && this._value.languageIn !== null ? new InFilterClass(this._value.languageIn) : this._value.languageIn;
  }

  get weight() {
    return this._value.weight !== undefined && this._value.weight !== null ? new WeightClass(this._value.weight) : this._value.weight;
  }

  get height() {
    return this._value.height !== undefined && this._value.height !== null ? new HeightClass(this._value.height) : this._value.height;
  }

  get npi() {
    return this._value.npi;
  }

  get npiWithDefault() {
    return this._value.npi || '';
  }

  get programCategories() {
    return this._value.programCategories !== undefined && this._value.programCategories !== null ? map(this._value.programCategories, (v) => new ProgramCategoriesClass(v)) : this._value.programCategories;
  }

  get programCategoriesWithDefault() {
    return this._value.programCategories || [];
  }

  get medicationManagement() {
    return this._value.medicationManagement !== undefined && this._value.medicationManagement !== null ? new MedicationManagementClass(this._value.medicationManagement) : this._value.medicationManagement;
  }

  get healthConditions() {
    return this._value.healthConditions;
  }

  get healthConditionsWithDefault() {
    return this._value.healthConditions || [];
  }

  get lastMeasuredByType() {
    return this._value.lastMeasuredByType !== undefined && this._value.lastMeasuredByType !== null ? new LastMeasuredByTypeClass(this._value.lastMeasuredByType) : this._value.lastMeasuredByType;
  }

  get lastMeasuredAt() {
    return (this._value.lastMeasuredAt !== undefined && this._value.lastMeasuredAt !== null) ? dayjs(this._value.lastMeasuredAt) : this._value.lastMeasuredAt;
  }
}
export class PatientListResultClass {
  protected readonly _value: PatientListResult;

	constructor(value: PatientListResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get profile() {
    return this._value.profile !== undefined && this._value.profile !== null ? new ProfileClass(this._value.profile) : this._value.profile;
  }

  get doctor() {
    return this._value.doctor !== undefined && this._value.doctor !== null ? new MiniProviderClass(this._value.doctor) : this._value.doctor;
  }

  get organization() {
    return this._value.organization !== undefined && this._value.organization !== null ? new MiniOrganizationClass(this._value.organization) : this._value.organization;
  }

  get assignedToCA() {
    return this._value.assignedToCA !== undefined && this._value.assignedToCA !== null ? new MiniProviderClass(this._value.assignedToCA) : this._value.assignedToCA;
  }

  get assignedToRD() {
    return this._value.assignedToRD !== undefined && this._value.assignedToRD !== null ? new MiniProviderClass(this._value.assignedToRD) : this._value.assignedToRD;
  }

  get reassignedFromCA() {
    return this._value.reassignedFromCA !== undefined && this._value.reassignedFromCA !== null ? new MiniProviderClass(this._value.reassignedFromCA) : this._value.reassignedFromCA;
  }

  get reassignedFromRD() {
    return this._value.reassignedFromRD !== undefined && this._value.reassignedFromRD !== null ? new MiniProviderClass(this._value.reassignedFromRD) : this._value.reassignedFromRD;
  }

  get tag() {
    return this._value.tag;
  }

  get tagWithDefault() {
    return this._value.tag || '';
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }

  get complexity() {
    return this._value.complexity;
  }

  complexityIsIn(values: PatientComplexityEnum[] = []) {
    return this._value.complexity && values.includes(this._value.complexity);
  }
}
export interface PatientAssigneesRequest {
	patientIds?: Nullable<string[]>;
	patientAssignees?: Nullable<PatientAssignees>;
	tag?: Nullable<string>;
	removedList?: Nullable<RoleTypeEnum[]>;
}
export class RoleClass {
  protected readonly _value: Role;

	constructor(value: Role) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get roleId() {
    return this._value.roleId;
  }

  get active() {
    return this._value.active;
  }

  get activeWithDefault() {
    return this._value.active || false;
  }

  get startFrom() {
    return (this._value.startFrom !== undefined && this._value.startFrom !== null) ? dayjs(this._value.startFrom) : this._value.startFrom;
  }

  get organizationIdIn() {
    return this._value.organizationIdIn !== undefined && this._value.organizationIdIn !== null ? new InFilterClass(this._value.organizationIdIn) : this._value.organizationIdIn;
  }
}
export class EmailClass {
  protected readonly _value: Email;

	constructor(value: Email) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get address() {
    return this._value.address;
  }

  get addressWithDefault() {
    return this._value.address || '';
  }

  get verified() {
    return this._value.verified;
  }

  get verifiedWithDefault() {
    return this._value.verified || false;
  }
}
export class AuthClass {
  protected readonly _value: Auth;

	constructor(value: Auth) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get suspended() {
    return this._value.suspended;
  }

  get suspendedWithDefault() {
    return this._value.suspended || false;
  }

  get username() {
    return this._value.username;
  }

  get usernameWithDefault() {
    return this._value.username || '';
  }
}
export class IdentificationClass {
  protected readonly _value: Identification;

	constructor(value: Identification) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get type() {
    return this._value.type;
  }

  get typeWithDefault() {
    return this._value.type || '';
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || '';
  }

  get MRN() {
    return this._value.MRN;
  }

  get MRNWithDefault() {
    return this._value.MRN || false;
  }
}
export class ElemMatchFilterClass<T> {
  protected readonly _value: ElemMatchFilter<any>;
	protected readonly matchConstructorFunc?: (value: any) => T;
	protected readonly notMatchConstructorFunc?: (value: any) => T;

	constructor(value: ElemMatchFilter<any>, matchConstructorFunc?: (value: any) => T, notMatchConstructorFunc?: (value: any) => T) {
		this._value = value;
		this.matchConstructorFunc = matchConstructorFunc;
		this.notMatchConstructorFunc = notMatchConstructorFunc;
	}

  get rawValue() {
    return this._value;
  }

  get match(): T {
    return this.matchConstructorFunc ? (this._value.match && this.matchConstructorFunc(this._value.match)) : this._value.match;
  }

  get matchWithDefault() {
    return this._value.match || [];
  }

  get notMatch(): T {
    return this.notMatchConstructorFunc ? (this._value.notMatch && this.notMatchConstructorFunc(this._value.notMatch)) : this._value.notMatch;
  }

  get notMatchWithDefault() {
    return this._value.notMatch || [];
  }
}
export class PhoneClass {
  protected readonly _value: Phone;

	constructor(value: Phone) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get countryCode() {
    return this._value.countryCode;
  }

  get countryCodeWithDefault() {
    return this._value.countryCode || '';
  }

  get areaCode() {
    return this._value.areaCode;
  }

  get areaCodeWithDefault() {
    return this._value.areaCode || '';
  }

  get number() {
    return this._value.number;
  }

  get numberWithDefault() {
    return this._value.number || '';
  }

  get verified() {
    return this._value.verified;
  }

  get verifiedWithDefault() {
    return this._value.verified || false;
  }

  get isPrimary() {
    return this._value.isPrimary;
  }

  get isPrimaryWithDefault() {
    return this._value.isPrimary || false;
  }

  get isPrivate() {
    return this._value.isPrivate;
  }

  get isPrivateWithDefault() {
    return this._value.isPrivate || false;
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: PhoneTypeEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get canUseForLogin() {
    return this._value.canUseForLogin;
  }

  get canUseForLoginWithDefault() {
    return this._value.canUseForLogin || false;
  }

  get isSmartPhone() {
    return this._value.isSmartPhone;
  }

  get isSmartPhoneWithDefault() {
    return this._value.isSmartPhone || false;
  }
}
export class AccountClass extends BaseModelClass {
  protected readonly _value: Account;

	constructor(value: Account) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get profile() {
    return this._value.profile !== undefined && this._value.profile !== null ? new ProfileClass(this._value.profile) : this._value.profile;
  }

  get role() {
    return this._value.role !== undefined && this._value.role !== null ? map(this._value.role, (v) => new RoleClass(v)) : this._value.role;
  }

  get roleWithDefault() {
    return this._value.role || [];
  }

  get email() {
    return this._value.email !== undefined && this._value.email !== null ? map(this._value.email, (v) => new EmailClass(v)) : this._value.email;
  }

  get emailWithDefault() {
    return this._value.email || [];
  }

  get majorRole() {
    return this._value.majorRole;
  }

  get majorRoleWithDefault() {
    return this._value.majorRole || '';
  }

  get auth() {
    return this._value.auth !== undefined && this._value.auth !== null ? new AuthClass(this._value.auth) : this._value.auth;
  }

  get monthlyMeasurementDays() {
    return this._value.monthlyMeasurementDays;
  }

  get monthlyMeasurementDaysWithDefault() {
    return this._value.monthlyMeasurementDays || 0;
  }

  get timezone() {
    return this._value.timezone;
  }

  get timezoneWithDefault() {
    return this._value.timezone || '';
  }

  get profileLanguageIn() {
    return this._value.profileLanguageIn !== undefined && this._value.profileLanguageIn !== null ? new InFilterClass(this._value.profileLanguageIn) : this._value.profileLanguageIn;
  }

  get profileAppLanguageIn() {
    return this._value.profileAppLanguageIn !== undefined && this._value.profileAppLanguageIn !== null ? new InFilterClass(this._value.profileAppLanguageIn) : this._value.profileAppLanguageIn;
  }

  get profileDoctorIdIn() {
    return this._value.profileDoctorIdIn !== undefined && this._value.profileDoctorIdIn !== null ? new InFilterClass(this._value.profileDoctorIdIn) : this._value.profileDoctorIdIn;
  }

  get profileBirthdayIn() {
    return this._value.profileBirthdayIn !== undefined && this._value.profileBirthdayIn !== null ? new InFilterClass(this._value.profileBirthdayIn) : this._value.profileBirthdayIn;
  }

  get organizationIdIn() {
    return this._value.organizationIdIn !== undefined && this._value.organizationIdIn !== null ? new InFilterClass(this._value.organizationIdIn) : this._value.organizationIdIn;
  }

  get identification() {
    return this._value.identification !== undefined && this._value.identification !== null ? map(this._value.identification, (v) => new IdentificationClass(v)) : this._value.identification;
  }

  get identificationWithDefault() {
    return this._value.identification || [];
  }

  get identificationMatch() {
    return this._value.identificationMatch !== undefined && this._value.identificationMatch !== null ? new ElemMatchFilterClass(this._value.identificationMatch) : this._value.identificationMatch;
  }

  get phone() {
    return this._value.phone !== undefined && this._value.phone !== null ? map(this._value.phone, (v) => new PhoneClass(v)) : this._value.phone;
  }

  get phoneWithDefault() {
    return this._value.phone || [];
  }

  get phoneMatch() {
    return this._value.phoneMatch !== undefined && this._value.phoneMatch !== null ? new ElemMatchFilterClass(this._value.phoneMatch) : this._value.phoneMatch;
  }

  get majorRoleIn() {
    return this._value.majorRoleIn !== undefined && this._value.majorRoleIn !== null ? new InFilterClass(this._value.majorRoleIn) : this._value.majorRoleIn;
  }
}
export interface AccountGetPatientAssigneesParams {
	id: string;
}

export interface AccountListFiltersParams {
}

export interface AccountListPatientsParams {
	filter: { [key: string]: string[] };
}

export interface AccountUpdatePatientAssigneesParams {
	patientAssigneesRequest: PatientAssigneesRequest;
}

export interface AccountUpsertPatientAssigneesParams {
	patientAssigneesRequest: PatientAssigneesRequest;
}

export class AccountService extends BaseController<Account, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/account';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getPatientAssignees({ baseUrl, params, headers }: RequestOption<AccountGetPatientAssigneesParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PatientAssignees>>(`${baseUrl || this.baseURL}${this.basePath}/get/patient/${params.id}/assignees${getQueryStrings(queryList)}`, { headers });
	}

	public listFilters({ baseUrl, params, headers }: RequestOption<AccountListFiltersParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<ReassignOption>>(`${baseUrl || this.baseURL}${this.basePath}/list/patients/filters${getQueryStrings(queryList)}`, { headers });
	}

	public listPatients({ baseUrl, params, headers }: RequestOption<AccountListPatientsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PatientListResult[]>>(`${baseUrl || this.baseURL}${this.basePath}/list/patients${getQueryStrings(queryList)}`, params.filter, { headers });
	}

	public updatePatientAssignees({ baseUrl, params, headers }: RequestOption<AccountUpdatePatientAssigneesParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/update/patient/assignees${getQueryStrings(queryList)}`, params.patientAssigneesRequest, { headers });
	}

	public upsertPatientAssignees({ baseUrl, params, headers }: RequestOption<AccountUpsertPatientAssigneesParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/upsert/patient/assignees${getQueryStrings(queryList)}`, params.patientAssigneesRequest, { headers });
	}
}

export const accountService = new AccountService();

export const createAccountGetPatientAssigneesObject = (value: APIResponse<PatientAssignees>) => new APIResponseClass<PatientAssigneesClass>(value, (value: PatientAssignees) => new PatientAssigneesClass(value));

export const useAccountGetPatientAssignees = <RCN = undefined>(configs: MakeRequestHookConfig<AccountGetPatientAssigneesParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AccountGetPatientAssigneesParams,
    APIResponse<PatientAssignees>,
    typeof accountService.getPatientAssignees,
    GetOptions,
    APIResponseClass<PatientAssigneesClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof accountService.getPatientAssignees>) => (
      accountService.getPatientAssignees(...args)
    ),
    ResponseClassCreator: createAccountGetPatientAssigneesObject,
    typeName: 'useAccountGetPatientAssignees',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAccountGetPatientAssignees.mock = () => new Error('Mock has not been implemented!');
useAccountGetPatientAssignees.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAccountGetPatientAssignees.createObject = createAccountGetPatientAssigneesObject;

export const createAccountListFiltersObject = (value: APIResponse<ReassignOption>) => new APIResponseClass<ReassignOptionClass>(value, (value: ReassignOption) => new ReassignOptionClass(value));

export const useAccountListFilters = <RCN = undefined>(configs: MakeRequestHookConfig<AccountListFiltersParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AccountListFiltersParams,
    APIResponse<ReassignOption>,
    typeof accountService.listFilters,
    GetOptions,
    APIResponseClass<ReassignOptionClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof accountService.listFilters>) => (
      accountService.listFilters(...args)
    ),
    ResponseClassCreator: createAccountListFiltersObject,
    typeName: 'useAccountListFilters',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAccountListFilters.mock = () => new Error('Mock has not been implemented!');
useAccountListFilters.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAccountListFilters.createObject = createAccountListFiltersObject;

export const createAccountListPatientsObject = (value: APIResponse<PatientListResult[]>) => new APIResponseClass<PatientListResultClass[]>(value, (v) => map(v, (value: PatientListResult) => new PatientListResultClass(value)));

export const useAccountListPatients = <RCN = undefined>(configs: MakeRequestHookConfig<AccountListPatientsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AccountListPatientsParams,
    APIResponse<PatientListResult[]>,
    typeof accountService.listPatients,
    AjaxOptions,
    APIResponseClass<PatientListResultClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof accountService.listPatients>) => (
      accountService.listPatients(...args)
    ),
    ResponseClassCreator: createAccountListPatientsObject,
    typeName: 'useAccountListPatients',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAccountListPatients.mock = () => new Error('Mock has not been implemented!');
useAccountListPatients.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAccountListPatients.createObject = createAccountListPatientsObject;

export const createAccountUpdatePatientAssigneesObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useAccountUpdatePatientAssignees = <RCN = undefined>(configs: MakeRequestHookConfig<AccountUpdatePatientAssigneesParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AccountUpdatePatientAssigneesParams,
    APIResponse<string>,
    typeof accountService.updatePatientAssignees,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof accountService.updatePatientAssignees>) => (
      accountService.updatePatientAssignees(...args)
    ),
    ResponseClassCreator: createAccountUpdatePatientAssigneesObject,
    typeName: 'useAccountUpdatePatientAssignees',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAccountUpdatePatientAssignees.mock = () => new Error('Mock has not been implemented!');
useAccountUpdatePatientAssignees.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAccountUpdatePatientAssignees.createObject = createAccountUpdatePatientAssigneesObject;

export const createAccountUpsertPatientAssigneesObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useAccountUpsertPatientAssignees = <RCN = undefined>(configs: MakeRequestHookConfig<AccountUpsertPatientAssigneesParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AccountUpsertPatientAssigneesParams,
    APIResponse<string>,
    typeof accountService.upsertPatientAssignees,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof accountService.upsertPatientAssignees>) => (
      accountService.upsertPatientAssignees(...args)
    ),
    ResponseClassCreator: createAccountUpsertPatientAssigneesObject,
    typeName: 'useAccountUpsertPatientAssignees',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAccountUpsertPatientAssignees.mock = () => new Error('Mock has not been implemented!');
useAccountUpsertPatientAssignees.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAccountUpsertPatientAssignees.createObject = createAccountUpsertPatientAssigneesObject;


export const createAccountGetObject = (value: APIResponse<Account>) => new APIResponseClass<AccountClass>(value, (value: Account) => new AccountClass(value));
export const useAccountGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<Account>,
    // @ts-ignore
    typeof accountService.get,
    GetOptions,
    APIResponseClass<AccountClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof accountService.get>) => (
      accountService.get(...args)
    ),
    ResponseClassCreator: createAccountGetObject,
    typeName: 'useAccountGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAccountGet.mock = () => new Error('Mock has not been implemented!');
useAccountGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAccountGet.createObject = createAccountGetObject;

export const createAccountUpdateObject = (value: APIResponse<Account>) => new APIResponseClass<AccountClass>(value, (value: Account) => new AccountClass(value));
export const useAccountUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, Account>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, Account>,
    APIResponse<Account>,
    // @ts-ignore
    typeof accountService.update,
    AjaxOptions,
    APIResponseClass<AccountClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof accountService.update>) => (
      accountService.update(...args)
    ),
    ResponseClassCreator: createAccountUpdateObject,
    typeName: 'useAccountUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAccountUpdate.mock = () => new Error('Mock has not been implemented!');
useAccountUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAccountUpdate.createObject = createAccountUpdateObject;

export const createAccountInsertObject = (value: APIResponse<Account>) => new APIResponseClass<AccountClass>(value, (value: Account) => new AccountClass(value));
export const useAccountInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<Account>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<Account>,
    APIResponse<Account>,
    // @ts-ignore
    typeof accountService.insert,
    AjaxOptions,
    APIResponseClass<AccountClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof accountService.insert>) => (
      accountService.insert(...args)
    ),
    ResponseClassCreator: createAccountInsertObject,
    typeName: 'useAccountInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAccountInsert.mock = () => new Error('Mock has not been implemented!');
useAccountInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAccountInsert.createObject = createAccountInsertObject;

export const createAccountDeleteObject = (value: APIResponse<Account>) => new APIResponseClass<AccountClass>(value, (value: Account) => new AccountClass(value));
export const useAccountDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<Account>,
    // @ts-ignore
    typeof accountService.delete,
    AjaxOptions,
    APIResponseClass<AccountClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof accountService.delete>) => (
      accountService.delete(...args)
    ),
    ResponseClassCreator: createAccountDeleteObject,
    typeName: 'useAccountDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAccountDelete.mock = () => new Error('Mock has not been implemented!');
useAccountDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAccountDelete.createObject = createAccountDeleteObject;

export const createAccountSearchObject = (value: APIResponse<PageResponse<Account>>) => new APIResponseClass<PageResponseClass<AccountClass>>(value, (value: PageResponse<Account>) => new PageResponseClass<AccountClass>(value, (value: Account) => new AccountClass(value)));
export const useAccountSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<Account>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<Account>,
    APIResponse<PageResponse<Account>>,
    // @ts-ignore
    typeof accountService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<AccountClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof accountService.search>) => (
      accountService.search(...args)
    ),
    ResponseClassCreator: createAccountSearchObject,
    typeName: 'useAccountSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAccountSearch.mock = () => new Error('Mock has not been implemented!');
useAccountSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAccountSearch.createObject = createAccountSearchObject;

export enum ProgramStatusEnum {
	DRAFT = 'DRAFT',
	ACTIVE = 'ACTIVE',
}
export enum HealthConditionEnum {
	DIABETES = 'DIABETES',
	HEART_FAILURE = 'HEART_FAILURE',
	AMI = 'AMI',
	COPD = 'COPD',
	HYPERTENSION = 'HYPERTENSION',
	DEFAULT = 'DEFAULT',
	STAGE_1_HYPERTENSION = 'STAGE_1_HYPERTENSION',
	STAGE_2_HYPERTENSION_CONTROLLED = 'STAGE_2_HYPERTENSION_CONTROLLED',
	STAGE_2_HYPERTENSION_UNCONTROLLED = 'STAGE_2_HYPERTENSION_UNCONTROLLED',
	TYPE_1_DIABETES = 'TYPE_1_DIABETES',
	TYPE_2_DIABETES = 'TYPE_2_DIABETES',
	GESTATIONAL_DIABETES = 'GESTATIONAL_DIABETES',
}
export enum MeasurementResultTypeEnum {
	BG = 'BG',
	BP = 'BP',
	EXERCISE = 'EXERCISE',
	HS = 'HS',
	PO = 'PO',
	TM = 'TM',
	HR = 'HR',
	CGM = 'CGM',
	ASSESSMENT = 'ASSESSMENT',
	SURVEY = 'SURVEY',
}
export enum TaskRepeatEnum {
	SCHEDULED = 'SCHEDULED',
	DAILY = 'DAILY',
	WEEKLY = 'WEEKLY',
	MONTHLY = 'MONTHLY',
	WEEKLYMEAL = 'WEEKLYMEAL',
}
export enum TaskScheduleBeforeAfterEnum {
	ANY_TIME = 'ANY_TIME',
	BEFORE_MEAL = 'BEFORE_MEAL',
	AFTER_MEAL = 'AFTER_MEAL',
}
export enum BgMealTypeEnum {
	BREAKFAST = 'BREAKFAST',
	LUNCH = 'LUNCH',
	DINNER = 'DINNER',
	SNACK = 'SNACK',
	BEDTIME = 'BEDTIME',
	OVERNIGHT = 'OVERNIGHT',
	OTHER = 'OTHER',
}
export interface TaskSchedule {
	frequency?: Nullable<string>;
	target?: Nullable<number>;
	start?: Nullable<string>;
	end?: Nullable<string>;
	beforeMeal?: Nullable<TaskScheduleBeforeAfterEnum>;
	mealType?: Nullable<BgMealTypeEnum>;
}
export enum MeasureTypeEnum {
	SYSTOLIC = 'SYSTOLIC',
	DIASTOLIC = 'DIASTOLIC',
	HEART_RATE = 'HEART_RATE',
	ECG_HEART_RATE = 'ECG_HEART_RATE',
	BEFORE_MEAL = 'BEFORE_MEAL',
	AFTER_MEAL = 'AFTER_MEAL',
	WEIGHT = 'WEIGHT',
	WEIGHT_CHANGE = 'WEIGHT_CHANGE',
	WEIGHT_CHANGE_BASELINE = 'WEIGHT_CHANGE_BASELINE',
	BLOOD_OXYGEN = 'BLOOD_OXYGEN',
	BODY_TEMPERATURE = 'BODY_TEMPERATURE',
	EXERCISE = 'EXERCISE',
}
export interface ExerciseGoal {
	target?: Nullable<number>;
	unit?: Nullable<string>;
	timeUnit?: Nullable<string>;
}
export interface AlertThreshold {
	measure?: Nullable<MeasureTypeEnum>;
	unit?: Nullable<string>;
	baseline?: Nullable<number>;
	baselineUpdatedAt?: Nullable<string>;
	range?: Nullable<number[]>;
	exerciseGoal?: Nullable<ExerciseGoal>;
}
export enum AlertFormulaEnum {
	SYSTOLIC = 'SYSTOLIC',
	DIASTOLIC = 'DIASTOLIC',
	SYSTOLIC_OR_DIASTOLIC = 'SYSTOLIC_OR_DIASTOLIC',
	HEART_RATE = 'HEART_RATE',
	BEFORE_MEAL = 'BEFORE_MEAL',
	AFTER_MEAL = 'AFTER_MEAL',
	BEFORE_OR_AFTER_MEAL = 'BEFORE_OR_AFTER_MEAL',
	WEIGHT = 'WEIGHT',
	WEIGHT_CHANGE = 'WEIGHT_CHANGE',
	WEIGHT_CHANGE_BASELINE = 'WEIGHT_CHANGE_BASELINE',
	BLOOD_OXYGEN = 'BLOOD_OXYGEN',
	PERFUSION_INDEX = 'PERFUSION_INDEX',
	BODY_TEMPERATURE = 'BODY_TEMPERATURE',
	EXERCISE = 'EXERCISE',
}
export enum NotificationEnum {
	SMS = 'SMS',
	EMAIL = 'EMAIL',
	PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
}
export enum AlertTriggerEnum {
	CRITICAL_LOW = 'CRITICAL_LOW',
	LOW = 'LOW',
	NORMAL = 'NORMAL',
	HIGH = 'HIGH',
	CRITICAL_HIGH = 'CRITICAL_HIGH',
}
export interface NotificationSetting {
	formula?: Nullable<AlertFormulaEnum>;
	type?: Nullable<NotificationEnum[]>;
	trigger?: Nullable<AlertTriggerEnum[]>;
	chance?: Nullable<number>;
}
export enum AssessmentQuestionCategoryEnum {
	OPEN_ENDED = 'OPEN_ENDED',
	CLOSED_ENDED = 'CLOSED_ENDED',
}
export enum AssessmentQuestionTypeEnum {
	TRUE_OR_FALSE = 'TRUE_OR_FALSE',
	SINGLE_SELECT = 'SINGLE_SELECT',
	MULTI_SELECT = 'MULTI_SELECT',
	SCALED = 'SCALED',
	INPUT_LONG = 'INPUT_LONG',
	INPUT_SHORT = 'INPUT_SHORT',
	INPUT_NUMBER = 'INPUT_NUMBER',
	INPUT_DATE = 'INPUT_DATE',
}
export interface AssessmentOption {
	label?: Nullable<string>;
	meaning?: Nullable<string>;
}
export interface AssessmentAnswer {
	content?: Nullable<string>;
	type?: Nullable<string>;
	other?: Nullable<string>;
}
export interface AssessmentQuestion {
	category?: Nullable<AssessmentQuestionCategoryEnum>;
	type?: Nullable<AssessmentQuestionTypeEnum>;
	text?: Nullable<string>;
	options?: Nullable<AssessmentOption[]>;
	answer?: Nullable<AssessmentAnswer>;
}
export interface TaskAssessment {
	name?: Nullable<string>;
	description?: Nullable<string>;
	version?: Nullable<number>;
	questions?: Nullable<AssessmentQuestion[]>;
}
export interface DailyMealSchedule {
	beforeBreakfast?: Nullable<boolean>;
	afterBreakfast?: Nullable<boolean>;
	beforeLunch?: Nullable<boolean>;
	afterLunch?: Nullable<boolean>;
	beforeDinner?: Nullable<boolean>;
	afterDinner?: Nullable<boolean>;
	bedtime?: Nullable<boolean>;
	overnight?: Nullable<boolean>;
}
export interface WeeklyMealSchedule {
	_id?: Nullable<string>;
	mon?: Nullable<DailyMealSchedule>;
	tues?: Nullable<DailyMealSchedule>;
	wed?: Nullable<DailyMealSchedule>;
	thurs?: Nullable<DailyMealSchedule>;
	fri?: Nullable<DailyMealSchedule>;
	sat?: Nullable<DailyMealSchedule>;
	sun?: Nullable<DailyMealSchedule>;
}
export interface TemplateDetails {
	name?: Nullable<string>;
	description?: Nullable<string>;
	schedule?: Nullable<WeeklyMealSchedule>;
}
export interface ProgramTask {
	type?: Nullable<MeasurementResultTypeEnum>;
	description?: Nullable<string>;
	activated?: Nullable<boolean>;
	repeat?: Nullable<TaskRepeatEnum>;
	schedule?: Nullable<TaskSchedule[]>;
	threshold?: Nullable<AlertThreshold[]>;
	notification?: Nullable<NotificationSetting[]>;
	assessment?: Nullable<TaskAssessment>;
	scheduleType?: Nullable<string>;
	templateDetails?: Nullable<TemplateDetails>;
}
export enum DeviceTypeEnum {
	TABLET = 'TABLET',
	HR = 'HR',
	BG = 'BG',
	BP = 'BP',
	HS = 'HS',
	PO = 'PO',
	TM = 'TM',
	EXERCISE = 'EXERCISE',
	CGM = 'CGM',
}
export enum DeviceModelEnum {
	UNKNOWN = 'UNKNOWN',
	IPAD_MINI = 'IPAD_MINI',
	BP3L = 'BP3L',
	BP5 = 'BP5',
	BP7250 = 'BP7250',
	BPM1 = 'BPM1',
	BPM1_HOTSPOT = 'BPM1_HOTSPOT',
	LOAN_DEVICE = 'LOAN_DEVICE',
	BG5 = 'BG5',
	BG1 = 'BG1',
	BG1S = 'BG1S',
	BG5S = 'BG5S',
	BG5A = 'BG5A',
	PO3 = 'PO3',
	HS2 = 'HS2',
	HS4S = 'HS4S',
	HS6 = 'HS6',
	HS2S = 'HS2S',
	TM3 = 'TM3',
	TS28B = 'TS28B',
	PY_802_LTE = 'PY_802_LTE',
	BIOLAND = 'BIOLAND',
	EXERCISE = 'EXERCISE',
	KN_550BT = 'KN_550BT',
	BPM5 = 'BPM5',
	BP3 = 'BP3',
	CGM = 'CGM',
}
export interface ProgramDevice {
	type?: Nullable<DeviceTypeEnum>;
	model?: Nullable<DeviceModelEnum>;
}
export enum CalendarLengthEnum {
	MINUTE = 'MINUTE',
	HOUR = 'HOUR',
	DAY = 'DAY',
	WEEK = 'WEEK',
	MONTH = 'MONTH',
}
export interface Duration {
	length?: Nullable<number>;
	calendar?: Nullable<CalendarLengthEnum>;
}
export interface AdminProgram extends BaseModel {
	organizationId?: Nullable<string>;
	name?: Nullable<string>;
	description?: Nullable<string>;
	isDefault?: Nullable<boolean>;
	status?: Nullable<ProgramStatusEnum>;
	healthCondition?: Nullable<HealthConditionEnum>;
	tasks?: Nullable<ProgramTask[]>;
	devices?: Nullable<ProgramDevice[]>;
	duration?: Nullable<Duration>;
}
export class TaskScheduleClass {
  protected readonly _value: TaskSchedule;

	constructor(value: TaskSchedule) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get frequency() {
    return this._value.frequency;
  }

  get frequencyWithDefault() {
    return this._value.frequency || '';
  }

  get target() {
    return this._value.target;
  }

  get targetWithDefault() {
    return this._value.target || 0;
  }

  get start() {
    return (this._value.start !== undefined && this._value.start !== null) ? dayjs(this._value.start) : this._value.start;
  }

  get end() {
    return (this._value.end !== undefined && this._value.end !== null) ? dayjs(this._value.end) : this._value.end;
  }

  get beforeMeal() {
    return this._value.beforeMeal;
  }

  beforeMealIsIn(values: TaskScheduleBeforeAfterEnum[] = []) {
    return this._value.beforeMeal && values.includes(this._value.beforeMeal);
  }

  get mealType() {
    return this._value.mealType;
  }

  mealTypeIsIn(values: BgMealTypeEnum[] = []) {
    return this._value.mealType && values.includes(this._value.mealType);
  }
}
export class ExerciseGoalClass {
  protected readonly _value: ExerciseGoal;

	constructor(value: ExerciseGoal) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get target() {
    return this._value.target;
  }

  get targetWithDefault() {
    return this._value.target || 0;
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }

  get timeUnit() {
    return this._value.timeUnit;
  }

  get timeUnitWithDefault() {
    return this._value.timeUnit || '';
  }
}
export class AlertThresholdClass {
  protected readonly _value: AlertThreshold;

	constructor(value: AlertThreshold) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get measure() {
    return this._value.measure;
  }

  measureIsIn(values: MeasureTypeEnum[] = []) {
    return this._value.measure && values.includes(this._value.measure);
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }

  get baseline() {
    return this._value.baseline;
  }

  get baselineWithDefault() {
    return this._value.baseline || 0.0;
  }

  get baselineUpdatedAt() {
    return (this._value.baselineUpdatedAt !== undefined && this._value.baselineUpdatedAt !== null) ? dayjs(this._value.baselineUpdatedAt) : this._value.baselineUpdatedAt;
  }

  get range() {
    return this._value.range;
  }

  get rangeWithDefault() {
    return this._value.range || [];
  }

  get exerciseGoal() {
    return this._value.exerciseGoal !== undefined && this._value.exerciseGoal !== null ? new ExerciseGoalClass(this._value.exerciseGoal) : this._value.exerciseGoal;
  }
}
export class NotificationSettingClass {
  protected readonly _value: NotificationSetting;

	constructor(value: NotificationSetting) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get formula() {
    return this._value.formula;
  }

  formulaIsIn(values: AlertFormulaEnum[] = []) {
    return this._value.formula && values.includes(this._value.formula);
  }

  get type() {
    return this._value.type;
  }

  get typeWithDefault() {
    return this._value.type || [];
  }

  get trigger() {
    return this._value.trigger;
  }

  get triggerWithDefault() {
    return this._value.trigger || [];
  }

  get chance() {
    return this._value.chance;
  }

  get chanceWithDefault() {
    return this._value.chance || 0;
  }
}
export class AssessmentOptionClass {
  protected readonly _value: AssessmentOption;

	constructor(value: AssessmentOption) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get label() {
    return this._value.label;
  }

  get labelWithDefault() {
    return this._value.label || '';
  }

  get meaning() {
    return this._value.meaning;
  }

  get meaningWithDefault() {
    return this._value.meaning || '';
  }
}
export class AssessmentAnswerClass {
  protected readonly _value: AssessmentAnswer;

	constructor(value: AssessmentAnswer) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get content() {
    return this._value.content;
  }

  get contentWithDefault() {
    return this._value.content || '';
  }

  get type() {
    return this._value.type;
  }

  get typeWithDefault() {
    return this._value.type || '';
  }

  get other() {
    return this._value.other;
  }

  get otherWithDefault() {
    return this._value.other || '';
  }
}
export class AssessmentQuestionClass {
  protected readonly _value: AssessmentQuestion;

	constructor(value: AssessmentQuestion) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get category() {
    return this._value.category;
  }

  categoryIsIn(values: AssessmentQuestionCategoryEnum[] = []) {
    return this._value.category && values.includes(this._value.category);
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: AssessmentQuestionTypeEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get text() {
    return this._value.text;
  }

  get textWithDefault() {
    return this._value.text || '';
  }

  get options() {
    return this._value.options !== undefined && this._value.options !== null ? map(this._value.options, (v) => new AssessmentOptionClass(v)) : this._value.options;
  }

  get optionsWithDefault() {
    return this._value.options || [];
  }

  get answer() {
    return this._value.answer !== undefined && this._value.answer !== null ? new AssessmentAnswerClass(this._value.answer) : this._value.answer;
  }
}
export class TaskAssessmentClass {
  protected readonly _value: TaskAssessment;

	constructor(value: TaskAssessment) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get version() {
    return this._value.version;
  }

  get versionWithDefault() {
    return this._value.version || 0;
  }

  get questions() {
    return this._value.questions !== undefined && this._value.questions !== null ? map(this._value.questions, (v) => new AssessmentQuestionClass(v)) : this._value.questions;
  }

  get questionsWithDefault() {
    return this._value.questions || [];
  }
}
export class DailyMealScheduleClass {
  protected readonly _value: DailyMealSchedule;

	constructor(value: DailyMealSchedule) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get beforeBreakfast() {
    return this._value.beforeBreakfast;
  }

  get beforeBreakfastWithDefault() {
    return this._value.beforeBreakfast || false;
  }

  get afterBreakfast() {
    return this._value.afterBreakfast;
  }

  get afterBreakfastWithDefault() {
    return this._value.afterBreakfast || false;
  }

  get beforeLunch() {
    return this._value.beforeLunch;
  }

  get beforeLunchWithDefault() {
    return this._value.beforeLunch || false;
  }

  get afterLunch() {
    return this._value.afterLunch;
  }

  get afterLunchWithDefault() {
    return this._value.afterLunch || false;
  }

  get beforeDinner() {
    return this._value.beforeDinner;
  }

  get beforeDinnerWithDefault() {
    return this._value.beforeDinner || false;
  }

  get afterDinner() {
    return this._value.afterDinner;
  }

  get afterDinnerWithDefault() {
    return this._value.afterDinner || false;
  }

  get bedtime() {
    return this._value.bedtime;
  }

  get bedtimeWithDefault() {
    return this._value.bedtime || false;
  }

  get overnight() {
    return this._value.overnight;
  }

  get overnightWithDefault() {
    return this._value.overnight || false;
  }
}
export class WeeklyMealScheduleClass {
  protected readonly _value: WeeklyMealSchedule;

	constructor(value: WeeklyMealSchedule) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get _id() {
    return this._value._id;
  }

  get mon() {
    return this._value.mon !== undefined && this._value.mon !== null ? new DailyMealScheduleClass(this._value.mon) : this._value.mon;
  }

  get tues() {
    return this._value.tues !== undefined && this._value.tues !== null ? new DailyMealScheduleClass(this._value.tues) : this._value.tues;
  }

  get wed() {
    return this._value.wed !== undefined && this._value.wed !== null ? new DailyMealScheduleClass(this._value.wed) : this._value.wed;
  }

  get thurs() {
    return this._value.thurs !== undefined && this._value.thurs !== null ? new DailyMealScheduleClass(this._value.thurs) : this._value.thurs;
  }

  get fri() {
    return this._value.fri !== undefined && this._value.fri !== null ? new DailyMealScheduleClass(this._value.fri) : this._value.fri;
  }

  get sat() {
    return this._value.sat !== undefined && this._value.sat !== null ? new DailyMealScheduleClass(this._value.sat) : this._value.sat;
  }

  get sun() {
    return this._value.sun !== undefined && this._value.sun !== null ? new DailyMealScheduleClass(this._value.sun) : this._value.sun;
  }
}
export class TemplateDetailsClass {
  protected readonly _value: TemplateDetails;

	constructor(value: TemplateDetails) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get schedule() {
    return this._value.schedule !== undefined && this._value.schedule !== null ? new WeeklyMealScheduleClass(this._value.schedule) : this._value.schedule;
  }
}
export class ProgramTaskClass {
  protected readonly _value: ProgramTask;

	constructor(value: ProgramTask) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: MeasurementResultTypeEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get activated() {
    return this._value.activated;
  }

  get activatedWithDefault() {
    return this._value.activated || false;
  }

  get repeat() {
    return this._value.repeat;
  }

  repeatIsIn(values: TaskRepeatEnum[] = []) {
    return this._value.repeat && values.includes(this._value.repeat);
  }

  get schedule() {
    return this._value.schedule !== undefined && this._value.schedule !== null ? map(this._value.schedule, (v) => new TaskScheduleClass(v)) : this._value.schedule;
  }

  get scheduleWithDefault() {
    return this._value.schedule || [];
  }

  get threshold() {
    return this._value.threshold !== undefined && this._value.threshold !== null ? map(this._value.threshold, (v) => new AlertThresholdClass(v)) : this._value.threshold;
  }

  get thresholdWithDefault() {
    return this._value.threshold || [];
  }

  get notification() {
    return this._value.notification !== undefined && this._value.notification !== null ? map(this._value.notification, (v) => new NotificationSettingClass(v)) : this._value.notification;
  }

  get notificationWithDefault() {
    return this._value.notification || [];
  }

  get assessment() {
    return this._value.assessment !== undefined && this._value.assessment !== null ? new TaskAssessmentClass(this._value.assessment) : this._value.assessment;
  }

  get scheduleType() {
    return this._value.scheduleType;
  }

  get scheduleTypeWithDefault() {
    return this._value.scheduleType || '';
  }

  get templateDetails() {
    return this._value.templateDetails !== undefined && this._value.templateDetails !== null ? new TemplateDetailsClass(this._value.templateDetails) : this._value.templateDetails;
  }
}
export class ProgramDeviceClass {
  protected readonly _value: ProgramDevice;

	constructor(value: ProgramDevice) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: DeviceTypeEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get model() {
    return this._value.model;
  }

  modelIsIn(values: DeviceModelEnum[] = []) {
    return this._value.model && values.includes(this._value.model);
  }
}
export class DurationClass {
  protected readonly _value: Duration;

	constructor(value: Duration) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get length() {
    return this._value.length;
  }

  get lengthWithDefault() {
    return this._value.length || 0;
  }

  get calendar() {
    return this._value.calendar;
  }

  calendarIsIn(values: CalendarLengthEnum[] = []) {
    return this._value.calendar && values.includes(this._value.calendar);
  }
}
export class AdminProgramClass extends BaseModelClass {
  protected readonly _value: AdminProgram;

	constructor(value: AdminProgram) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get isDefault() {
    return this._value.isDefault;
  }

  get isDefaultWithDefault() {
    return this._value.isDefault || false;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: ProgramStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get healthCondition() {
    return this._value.healthCondition;
  }

  healthConditionIsIn(values: HealthConditionEnum[] = []) {
    return this._value.healthCondition && values.includes(this._value.healthCondition);
  }

  get tasks() {
    return this._value.tasks !== undefined && this._value.tasks !== null ? map(this._value.tasks, (v) => new ProgramTaskClass(v)) : this._value.tasks;
  }

  get tasksWithDefault() {
    return this._value.tasks || [];
  }

  get devices() {
    return this._value.devices !== undefined && this._value.devices !== null ? map(this._value.devices, (v) => new ProgramDeviceClass(v)) : this._value.devices;
  }

  get devicesWithDefault() {
    return this._value.devices || [];
  }

  get duration() {
    return this._value.duration !== undefined && this._value.duration !== null ? new DurationClass(this._value.duration) : this._value.duration;
  }
}
export class AdminProgramService extends BaseController<AdminProgram, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/admin-programs';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const adminProgramService = new AdminProgramService();


export const createAdminProgramGetObject = (value: APIResponse<AdminProgram>) => new APIResponseClass<AdminProgramClass>(value, (value: AdminProgram) => new AdminProgramClass(value));
export const useAdminProgramGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<AdminProgram>,
    // @ts-ignore
    typeof adminProgramService.get,
    GetOptions,
    APIResponseClass<AdminProgramClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof adminProgramService.get>) => (
      adminProgramService.get(...args)
    ),
    ResponseClassCreator: createAdminProgramGetObject,
    typeName: 'useAdminProgramGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAdminProgramGet.mock = () => new Error('Mock has not been implemented!');
useAdminProgramGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAdminProgramGet.createObject = createAdminProgramGetObject;

export const createAdminProgramUpdateObject = (value: APIResponse<AdminProgram>) => new APIResponseClass<AdminProgramClass>(value, (value: AdminProgram) => new AdminProgramClass(value));
export const useAdminProgramUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, AdminProgram>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, AdminProgram>,
    APIResponse<AdminProgram>,
    // @ts-ignore
    typeof adminProgramService.update,
    AjaxOptions,
    APIResponseClass<AdminProgramClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof adminProgramService.update>) => (
      adminProgramService.update(...args)
    ),
    ResponseClassCreator: createAdminProgramUpdateObject,
    typeName: 'useAdminProgramUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAdminProgramUpdate.mock = () => new Error('Mock has not been implemented!');
useAdminProgramUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAdminProgramUpdate.createObject = createAdminProgramUpdateObject;

export const createAdminProgramInsertObject = (value: APIResponse<AdminProgram>) => new APIResponseClass<AdminProgramClass>(value, (value: AdminProgram) => new AdminProgramClass(value));
export const useAdminProgramInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<AdminProgram>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<AdminProgram>,
    APIResponse<AdminProgram>,
    // @ts-ignore
    typeof adminProgramService.insert,
    AjaxOptions,
    APIResponseClass<AdminProgramClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof adminProgramService.insert>) => (
      adminProgramService.insert(...args)
    ),
    ResponseClassCreator: createAdminProgramInsertObject,
    typeName: 'useAdminProgramInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAdminProgramInsert.mock = () => new Error('Mock has not been implemented!');
useAdminProgramInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAdminProgramInsert.createObject = createAdminProgramInsertObject;

export const createAdminProgramDeleteObject = (value: APIResponse<AdminProgram>) => new APIResponseClass<AdminProgramClass>(value, (value: AdminProgram) => new AdminProgramClass(value));
export const useAdminProgramDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<AdminProgram>,
    // @ts-ignore
    typeof adminProgramService.delete,
    AjaxOptions,
    APIResponseClass<AdminProgramClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof adminProgramService.delete>) => (
      adminProgramService.delete(...args)
    ),
    ResponseClassCreator: createAdminProgramDeleteObject,
    typeName: 'useAdminProgramDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAdminProgramDelete.mock = () => new Error('Mock has not been implemented!');
useAdminProgramDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAdminProgramDelete.createObject = createAdminProgramDeleteObject;

export const createAdminProgramSearchObject = (value: APIResponse<PageResponse<AdminProgram>>) => new APIResponseClass<PageResponseClass<AdminProgramClass>>(value, (value: PageResponse<AdminProgram>) => new PageResponseClass<AdminProgramClass>(value, (value: AdminProgram) => new AdminProgramClass(value)));
export const useAdminProgramSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<AdminProgram>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<AdminProgram>,
    APIResponse<PageResponse<AdminProgram>>,
    // @ts-ignore
    typeof adminProgramService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<AdminProgramClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof adminProgramService.search>) => (
      adminProgramService.search(...args)
    ),
    ResponseClassCreator: createAdminProgramSearchObject,
    typeName: 'useAdminProgramSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAdminProgramSearch.mock = () => new Error('Mock has not been implemented!');
useAdminProgramSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAdminProgramSearch.createObject = createAdminProgramSearchObject;

export enum AnnouncementStatusEnum {
	UNPUBLISHED = 'UNPUBLISHED',
	PUBLISHED = 'PUBLISHED',
	DISABLED = 'DISABLED',
	EXPIRED = 'EXPIRED',
}
export enum PublishTypeEnum {
	NOW = 'NOW',
	SCHEDULED = 'SCHEDULED',
}
export enum AnnouncementTypeEnum {
	RELEASE = 'RELEASE',
	INCIDENT = 'INCIDENT',
	HOTFIX = 'HOTFIX',
	ANNOUNCEMENT = 'ANNOUNCEMENT',
	OTHER = 'OTHER',
}
export enum TargetTypeEnum {
	ALL = 'ALL',
	SPECIFIC_USERS = 'SPECIFIC_USERS',
	SPECIFIC_CLINICS = 'SPECIFIC_CLINICS',
	SPECIFIC_ROLES = 'SPECIFIC_ROLES',
}
export interface AnnouncementTarget {
	targetType?: Nullable<TargetTypeEnum>;
	targetIds?: Nullable<string[]>;
}
export interface Announcement extends BaseModel {
	title?: Nullable<string>;
	content?: Nullable<string>;
	summary?: Nullable<string>;
	status?: Nullable<AnnouncementStatusEnum>;
	publishType?: Nullable<PublishTypeEnum>;
	scheduledTime?: Nullable<string>;
	announcementType?: Nullable<AnnouncementTypeEnum>;
	targets?: Nullable<AnnouncementTarget[]>;
	createdBy?: Nullable<string>;
	publishedBy?: Nullable<string>;
	publishedAt?: Nullable<string>;
	announcementTypeIn?: Nullable<InFilter<string>>;
	statusIn?: Nullable<InFilter<string>>;
}
export class AnnouncementTargetClass {
  protected readonly _value: AnnouncementTarget;

	constructor(value: AnnouncementTarget) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get targetType() {
    return this._value.targetType;
  }

  targetTypeIsIn(values: TargetTypeEnum[] = []) {
    return this._value.targetType && values.includes(this._value.targetType);
  }

  get targetIds() {
    return this._value.targetIds;
  }

  get targetIdsWithDefault() {
    return this._value.targetIds || [];
  }
}
export class AnnouncementClass extends BaseModelClass {
  protected readonly _value: Announcement;

	constructor(value: Announcement) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get title() {
    return this._value.title;
  }

  get titleWithDefault() {
    return this._value.title || '';
  }

  get content() {
    return this._value.content;
  }

  get contentWithDefault() {
    return this._value.content || '';
  }

  get summary() {
    return this._value.summary;
  }

  get summaryWithDefault() {
    return this._value.summary || '';
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: AnnouncementStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get publishType() {
    return this._value.publishType;
  }

  publishTypeIsIn(values: PublishTypeEnum[] = []) {
    return this._value.publishType && values.includes(this._value.publishType);
  }

  get scheduledTime() {
    return (this._value.scheduledTime !== undefined && this._value.scheduledTime !== null) ? dayjs(this._value.scheduledTime) : this._value.scheduledTime;
  }

  get announcementType() {
    return this._value.announcementType;
  }

  announcementTypeIsIn(values: AnnouncementTypeEnum[] = []) {
    return this._value.announcementType && values.includes(this._value.announcementType);
  }

  get targets() {
    return this._value.targets !== undefined && this._value.targets !== null ? map(this._value.targets, (v) => new AnnouncementTargetClass(v)) : this._value.targets;
  }

  get targetsWithDefault() {
    return this._value.targets || [];
  }

  get createdBy() {
    return this._value.createdBy;
  }

  get publishedBy() {
    return this._value.publishedBy;
  }

  get publishedAt() {
    return (this._value.publishedAt !== undefined && this._value.publishedAt !== null) ? dayjs(this._value.publishedAt) : this._value.publishedAt;
  }

  get announcementTypeIn() {
    return this._value.announcementTypeIn !== undefined && this._value.announcementTypeIn !== null ? new InFilterClass(this._value.announcementTypeIn) : this._value.announcementTypeIn;
  }

  get statusIn() {
    return this._value.statusIn !== undefined && this._value.statusIn !== null ? new InFilterClass(this._value.statusIn) : this._value.statusIn;
  }
}
export enum Sort$Direction {
	ASC = 'ASC',
	DESC = 'DESC',
}
export interface SortInfo {
	direction?: Nullable<Sort$Direction>;
	property?: Nullable<string>;
}
export interface PageInfo {
	page?: Nullable<number>;
	size?: Nullable<number>;
	sort?: Nullable<SortInfo[]>;
	pagination?: Nullable<boolean>;
	countOnly?: Nullable<boolean>;
	dataOnly?: Nullable<boolean>;
}
export interface SearchRequest<T> {
	filter?: Nullable<T>;
	pageInfo?: Nullable<PageInfo>;
}
export interface PageResponse<T> {
	totalPage?: Nullable<number>;
	totalSize?: Nullable<number>;
	page?: Nullable<number>;
	size?: Nullable<number>;
	content?: Nullable<T[]>;
}
export class PageResponseClass<T> {
  protected readonly _value: PageResponse<any>;
	protected readonly contentConstructorFunc?: (value: any) => T;

	constructor(value: PageResponse<any>, contentConstructorFunc?: (value: any) => T) {
		this._value = value;
		this.contentConstructorFunc = contentConstructorFunc;
	}

  get rawValue() {
    return this._value;
  }

  get totalPage() {
    return this._value.totalPage;
  }

  get totalPageWithDefault() {
    return this._value.totalPage || 0;
  }

  get totalSize() {
    return this._value.totalSize;
  }

  get totalSizeWithDefault() {
    return this._value.totalSize || 0;
  }

  get page() {
    return this._value.page;
  }

  get pageWithDefault() {
    return this._value.page || 0;
  }

  get size() {
    return this._value.size;
  }

  get sizeWithDefault() {
    return this._value.size || 0;
  }

  get content(): T {
    return this.contentConstructorFunc ? (this._value.content && this.contentConstructorFunc(this._value.content)) : this._value.content;
  }

  get contentWithDefault() {
    return this._value.content || [];
  }
}
export enum AnnouncementUserStatusEnum {
	UNREAD = 'UNREAD',
	READ = 'READ',
	DISMISSED = 'DISMISSED',
	PINNED = 'PINNED',
	EXPIRED = 'EXPIRED',
}
export interface AnnouncementUserStatus extends BaseModel {
	announcementId?: Nullable<string>;
	userId?: Nullable<string>;
	userStatus?: Nullable<AnnouncementUserStatusEnum>;
}
export class AnnouncementUserStatusClass extends BaseModelClass {
  protected readonly _value: AnnouncementUserStatus;

	constructor(value: AnnouncementUserStatus) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get announcementId() {
    return this._value.announcementId;
  }

  get userId() {
    return this._value.userId;
  }

  get userStatus() {
    return this._value.userStatus;
  }

  userStatusIsIn(values: AnnouncementUserStatusEnum[] = []) {
    return this._value.userStatus && values.includes(this._value.userStatus);
  }
}
export interface AnnouncementUpdateParams {
	id: string;
	request: Announcement;
}

export interface AnnouncementInsertParams {
	request: Announcement;
}

export interface AnnouncementDeleteParams {
	id: string;
}

export interface AnnouncementListAnnouncementParams {
	searchRequest: SearchRequest<Announcement>;
}

export interface AnnouncementGetMyAnnouncementsParams {
	searchRequest: SearchRequest<Announcement>;
}

export interface AnnouncementReadAnnouncementParams {
	id: string;
}

export interface AnnouncementPublishAnnouncementParams {
	id: string;
}

export class AnnouncementService extends BaseController<Announcement, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/announcements';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public update({ baseUrl, params, headers }: RequestOption<AnnouncementUpdateParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<Announcement>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public insert({ baseUrl, params, headers }: RequestOption<AnnouncementInsertParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Announcement>>(`${baseUrl || this.baseURL}${this.basePath}${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public delete({ baseUrl, params, headers }: RequestOption<AnnouncementDeleteParams>) {
		const queryList: QueryParam[] = [];
		return axios.delete<APIResponse<Announcement>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}${getQueryStrings(queryList)}`, { headers });
	}

	public listAnnouncement({ baseUrl, params, headers }: RequestOption<AnnouncementListAnnouncementParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<Announcement>>>(`${baseUrl || this.baseURL}${this.basePath}/list${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getMyAnnouncements({ baseUrl, params, headers }: RequestOption<AnnouncementGetMyAnnouncementsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<Announcement>>>(`${baseUrl || this.baseURL}${this.basePath}/my-feed${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public readAnnouncement({ baseUrl, params, headers }: RequestOption<AnnouncementReadAnnouncementParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<AnnouncementUserStatus>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/read${getQueryStrings(queryList)}`, {}, { headers });
	}

	public publishAnnouncement({ baseUrl, params, headers }: RequestOption<AnnouncementPublishAnnouncementParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Announcement>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/publish${getQueryStrings(queryList)}`, {}, { headers });
	}
}

export const announcementService = new AnnouncementService();

export const createAnnouncementUpdateObject = (value: APIResponse<Announcement>) => new APIResponseClass<AnnouncementClass>(value, (value: Announcement) => new AnnouncementClass(value));

export const useAnnouncementUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<AnnouncementUpdateParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AnnouncementUpdateParams,
    APIResponse<Announcement>,
    typeof announcementService.update,
    AjaxOptions,
    APIResponseClass<AnnouncementClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof announcementService.update>) => (
      announcementService.update(...args)
    ),
    ResponseClassCreator: createAnnouncementUpdateObject,
    typeName: 'useAnnouncementUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAnnouncementUpdate.mock = () => new Error('Mock has not been implemented!');
useAnnouncementUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAnnouncementUpdate.createObject = createAnnouncementUpdateObject;

export const createAnnouncementInsertObject = (value: APIResponse<Announcement>) => new APIResponseClass<AnnouncementClass>(value, (value: Announcement) => new AnnouncementClass(value));

export const useAnnouncementInsert = <RCN = undefined>(configs: MakeRequestHookConfig<AnnouncementInsertParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AnnouncementInsertParams,
    APIResponse<Announcement>,
    typeof announcementService.insert,
    AjaxOptions,
    APIResponseClass<AnnouncementClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof announcementService.insert>) => (
      announcementService.insert(...args)
    ),
    ResponseClassCreator: createAnnouncementInsertObject,
    typeName: 'useAnnouncementInsert',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAnnouncementInsert.mock = () => new Error('Mock has not been implemented!');
useAnnouncementInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAnnouncementInsert.createObject = createAnnouncementInsertObject;

export const createAnnouncementDeleteObject = (value: APIResponse<Announcement>) => new APIResponseClass<AnnouncementClass>(value, (value: Announcement) => new AnnouncementClass(value));

export const useAnnouncementDelete = <RCN = undefined>(configs: MakeRequestHookConfig<AnnouncementDeleteParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AnnouncementDeleteParams,
    APIResponse<Announcement>,
    typeof announcementService.delete,
    AjaxOptions,
    APIResponseClass<AnnouncementClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof announcementService.delete>) => (
      announcementService.delete(...args)
    ),
    ResponseClassCreator: createAnnouncementDeleteObject,
    typeName: 'useAnnouncementDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAnnouncementDelete.mock = () => new Error('Mock has not been implemented!');
useAnnouncementDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAnnouncementDelete.createObject = createAnnouncementDeleteObject;

export const createAnnouncementListAnnouncementObject = (value: APIResponse<PageResponse<Announcement>>) => new APIResponseClass<PageResponseClass<AnnouncementClass>>(value, (value: PageResponse<Announcement>) => new PageResponseClass<AnnouncementClass>(value, (value: Announcement) => new AnnouncementClass(value)));

export const useAnnouncementListAnnouncement = <RCN = undefined>(configs: MakeRequestHookConfig<AnnouncementListAnnouncementParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AnnouncementListAnnouncementParams,
    APIResponse<PageResponse<Announcement>>,
    typeof announcementService.listAnnouncement,
    AjaxOptions,
    APIResponseClass<PageResponseClass<AnnouncementClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof announcementService.listAnnouncement>) => (
      announcementService.listAnnouncement(...args)
    ),
    ResponseClassCreator: createAnnouncementListAnnouncementObject,
    typeName: 'useAnnouncementListAnnouncement',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAnnouncementListAnnouncement.mock = () => new Error('Mock has not been implemented!');
useAnnouncementListAnnouncement.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAnnouncementListAnnouncement.createObject = createAnnouncementListAnnouncementObject;

export const createAnnouncementGetMyAnnouncementsObject = (value: APIResponse<PageResponse<Announcement>>) => new APIResponseClass<PageResponseClass<AnnouncementClass>>(value, (value: PageResponse<Announcement>) => new PageResponseClass<AnnouncementClass>(value, (value: Announcement) => new AnnouncementClass(value)));

export const useAnnouncementGetMyAnnouncements = <RCN = undefined>(configs: MakeRequestHookConfig<AnnouncementGetMyAnnouncementsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AnnouncementGetMyAnnouncementsParams,
    APIResponse<PageResponse<Announcement>>,
    typeof announcementService.getMyAnnouncements,
    AjaxOptions,
    APIResponseClass<PageResponseClass<AnnouncementClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof announcementService.getMyAnnouncements>) => (
      announcementService.getMyAnnouncements(...args)
    ),
    ResponseClassCreator: createAnnouncementGetMyAnnouncementsObject,
    typeName: 'useAnnouncementGetMyAnnouncements',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAnnouncementGetMyAnnouncements.mock = () => new Error('Mock has not been implemented!');
useAnnouncementGetMyAnnouncements.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAnnouncementGetMyAnnouncements.createObject = createAnnouncementGetMyAnnouncementsObject;

export const createAnnouncementReadAnnouncementObject = (value: APIResponse<AnnouncementUserStatus>) => new APIResponseClass<AnnouncementUserStatusClass>(value, (value: AnnouncementUserStatus) => new AnnouncementUserStatusClass(value));

export const useAnnouncementReadAnnouncement = <RCN = undefined>(configs: MakeRequestHookConfig<AnnouncementReadAnnouncementParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AnnouncementReadAnnouncementParams,
    APIResponse<AnnouncementUserStatus>,
    typeof announcementService.readAnnouncement,
    AjaxOptions,
    APIResponseClass<AnnouncementUserStatusClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof announcementService.readAnnouncement>) => (
      announcementService.readAnnouncement(...args)
    ),
    ResponseClassCreator: createAnnouncementReadAnnouncementObject,
    typeName: 'useAnnouncementReadAnnouncement',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAnnouncementReadAnnouncement.mock = () => new Error('Mock has not been implemented!');
useAnnouncementReadAnnouncement.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAnnouncementReadAnnouncement.createObject = createAnnouncementReadAnnouncementObject;

export const createAnnouncementPublishAnnouncementObject = (value: APIResponse<Announcement>) => new APIResponseClass<AnnouncementClass>(value, (value: Announcement) => new AnnouncementClass(value));

export const useAnnouncementPublishAnnouncement = <RCN = undefined>(configs: MakeRequestHookConfig<AnnouncementPublishAnnouncementParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AnnouncementPublishAnnouncementParams,
    APIResponse<Announcement>,
    typeof announcementService.publishAnnouncement,
    AjaxOptions,
    APIResponseClass<AnnouncementClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof announcementService.publishAnnouncement>) => (
      announcementService.publishAnnouncement(...args)
    ),
    ResponseClassCreator: createAnnouncementPublishAnnouncementObject,
    typeName: 'useAnnouncementPublishAnnouncement',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAnnouncementPublishAnnouncement.mock = () => new Error('Mock has not been implemented!');
useAnnouncementPublishAnnouncement.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAnnouncementPublishAnnouncement.createObject = createAnnouncementPublishAnnouncementObject;


export const createAnnouncementGetObject = (value: APIResponse<Announcement>) => new APIResponseClass<AnnouncementClass>(value, (value: Announcement) => new AnnouncementClass(value));
export const useAnnouncementGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<Announcement>,
    // @ts-ignore
    typeof announcementService.get,
    GetOptions,
    APIResponseClass<AnnouncementClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof announcementService.get>) => (
      announcementService.get(...args)
    ),
    ResponseClassCreator: createAnnouncementGetObject,
    typeName: 'useAnnouncementGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAnnouncementGet.mock = () => new Error('Mock has not been implemented!');
useAnnouncementGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAnnouncementGet.createObject = createAnnouncementGetObject;

export const createAnnouncementSearchObject = (value: APIResponse<PageResponse<Announcement>>) => new APIResponseClass<PageResponseClass<AnnouncementClass>>(value, (value: PageResponse<Announcement>) => new PageResponseClass<AnnouncementClass>(value, (value: Announcement) => new AnnouncementClass(value)));
export const useAnnouncementSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<Announcement>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<Announcement>,
    APIResponse<PageResponse<Announcement>>,
    // @ts-ignore
    typeof announcementService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<AnnouncementClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof announcementService.search>) => (
      announcementService.search(...args)
    ),
    ResponseClassCreator: createAnnouncementSearchObject,
    typeName: 'useAnnouncementSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAnnouncementSearch.mock = () => new Error('Mock has not been implemented!');
useAnnouncementSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAnnouncementSearch.createObject = createAnnouncementSearchObject;

export interface AppBanner extends BaseModel {
	patientId?: Nullable<string>;
	saved?: Nullable<boolean>;
	name?: Nullable<string>;
	slug?: Nullable<string>;
	imageUrl?: Nullable<string>;
	rsvpLink?: Nullable<string>;
	external?: Nullable<boolean>;
	displayOrder?: Nullable<number>;
	publishDate?: Nullable<string>;
	imageUrlSpanish?: Nullable<string>;
	imageUrlVietnamese?: Nullable<string>;
	imageUrlChineseSimplified?: Nullable<string>;
	imageUrlChineseTraditional?: Nullable<string>;
	rsvpLinkSpanish?: Nullable<string>;
	rsvpLinkVietnamese?: Nullable<string>;
	rsvpLinkChineseSimplified?: Nullable<string>;
	rsvpLinkChineseTraditional?: Nullable<string>;
}
export class AppBannerClass extends BaseModelClass {
  protected readonly _value: AppBanner;

	constructor(value: AppBanner) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientId() {
    return this._value.patientId;
  }

  get patientIdWithDefault() {
    return this._value.patientId || '';
  }

  get saved() {
    return this._value.saved;
  }

  get savedWithDefault() {
    return this._value.saved || false;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get slug() {
    return this._value.slug;
  }

  get slugWithDefault() {
    return this._value.slug || '';
  }

  get imageUrl() {
    return this._value.imageUrl;
  }

  get imageUrlWithDefault() {
    return this._value.imageUrl || '';
  }

  get rsvpLink() {
    return this._value.rsvpLink;
  }

  get rsvpLinkWithDefault() {
    return this._value.rsvpLink || '';
  }

  get external() {
    return this._value.external;
  }

  get externalWithDefault() {
    return this._value.external || false;
  }

  get displayOrder() {
    return this._value.displayOrder;
  }

  get displayOrderWithDefault() {
    return this._value.displayOrder || 0;
  }

  get publishDate() {
    return (this._value.publishDate !== undefined && this._value.publishDate !== null) ? dayjs(this._value.publishDate) : this._value.publishDate;
  }

  get imageUrlSpanish() {
    return this._value.imageUrlSpanish;
  }

  get imageUrlSpanishWithDefault() {
    return this._value.imageUrlSpanish || '';
  }

  get imageUrlVietnamese() {
    return this._value.imageUrlVietnamese;
  }

  get imageUrlVietnameseWithDefault() {
    return this._value.imageUrlVietnamese || '';
  }

  get imageUrlChineseSimplified() {
    return this._value.imageUrlChineseSimplified;
  }

  get imageUrlChineseSimplifiedWithDefault() {
    return this._value.imageUrlChineseSimplified || '';
  }

  get imageUrlChineseTraditional() {
    return this._value.imageUrlChineseTraditional;
  }

  get imageUrlChineseTraditionalWithDefault() {
    return this._value.imageUrlChineseTraditional || '';
  }

  get rsvpLinkSpanish() {
    return this._value.rsvpLinkSpanish;
  }

  get rsvpLinkSpanishWithDefault() {
    return this._value.rsvpLinkSpanish || '';
  }

  get rsvpLinkVietnamese() {
    return this._value.rsvpLinkVietnamese;
  }

  get rsvpLinkVietnameseWithDefault() {
    return this._value.rsvpLinkVietnamese || '';
  }

  get rsvpLinkChineseSimplified() {
    return this._value.rsvpLinkChineseSimplified;
  }

  get rsvpLinkChineseSimplifiedWithDefault() {
    return this._value.rsvpLinkChineseSimplified || '';
  }

  get rsvpLinkChineseTraditional() {
    return this._value.rsvpLinkChineseTraditional;
  }

  get rsvpLinkChineseTraditionalWithDefault() {
    return this._value.rsvpLinkChineseTraditional || '';
  }
}
export class AppBannerService extends BaseController<AppBanner, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/app-banner';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const appBannerService = new AppBannerService();


export const createAppBannerGetObject = (value: APIResponse<AppBanner>) => new APIResponseClass<AppBannerClass>(value, (value: AppBanner) => new AppBannerClass(value));
export const useAppBannerGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<AppBanner>,
    // @ts-ignore
    typeof appBannerService.get,
    GetOptions,
    APIResponseClass<AppBannerClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof appBannerService.get>) => (
      appBannerService.get(...args)
    ),
    ResponseClassCreator: createAppBannerGetObject,
    typeName: 'useAppBannerGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAppBannerGet.mock = () => new Error('Mock has not been implemented!');
useAppBannerGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAppBannerGet.createObject = createAppBannerGetObject;

export const createAppBannerUpdateObject = (value: APIResponse<AppBanner>) => new APIResponseClass<AppBannerClass>(value, (value: AppBanner) => new AppBannerClass(value));
export const useAppBannerUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, AppBanner>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, AppBanner>,
    APIResponse<AppBanner>,
    // @ts-ignore
    typeof appBannerService.update,
    AjaxOptions,
    APIResponseClass<AppBannerClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof appBannerService.update>) => (
      appBannerService.update(...args)
    ),
    ResponseClassCreator: createAppBannerUpdateObject,
    typeName: 'useAppBannerUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAppBannerUpdate.mock = () => new Error('Mock has not been implemented!');
useAppBannerUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAppBannerUpdate.createObject = createAppBannerUpdateObject;

export const createAppBannerInsertObject = (value: APIResponse<AppBanner>) => new APIResponseClass<AppBannerClass>(value, (value: AppBanner) => new AppBannerClass(value));
export const useAppBannerInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<AppBanner>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<AppBanner>,
    APIResponse<AppBanner>,
    // @ts-ignore
    typeof appBannerService.insert,
    AjaxOptions,
    APIResponseClass<AppBannerClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof appBannerService.insert>) => (
      appBannerService.insert(...args)
    ),
    ResponseClassCreator: createAppBannerInsertObject,
    typeName: 'useAppBannerInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAppBannerInsert.mock = () => new Error('Mock has not been implemented!');
useAppBannerInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAppBannerInsert.createObject = createAppBannerInsertObject;

export const createAppBannerDeleteObject = (value: APIResponse<AppBanner>) => new APIResponseClass<AppBannerClass>(value, (value: AppBanner) => new AppBannerClass(value));
export const useAppBannerDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<AppBanner>,
    // @ts-ignore
    typeof appBannerService.delete,
    AjaxOptions,
    APIResponseClass<AppBannerClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof appBannerService.delete>) => (
      appBannerService.delete(...args)
    ),
    ResponseClassCreator: createAppBannerDeleteObject,
    typeName: 'useAppBannerDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAppBannerDelete.mock = () => new Error('Mock has not been implemented!');
useAppBannerDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAppBannerDelete.createObject = createAppBannerDeleteObject;

export const createAppBannerSearchObject = (value: APIResponse<PageResponse<AppBanner>>) => new APIResponseClass<PageResponseClass<AppBannerClass>>(value, (value: PageResponse<AppBanner>) => new PageResponseClass<AppBannerClass>(value, (value: AppBanner) => new AppBannerClass(value)));
export const useAppBannerSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<AppBanner>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<AppBanner>,
    APIResponse<PageResponse<AppBanner>>,
    // @ts-ignore
    typeof appBannerService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<AppBannerClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof appBannerService.search>) => (
      appBannerService.search(...args)
    ),
    ResponseClassCreator: createAppBannerSearchObject,
    typeName: 'useAppBannerSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAppBannerSearch.mock = () => new Error('Mock has not been implemented!');
useAppBannerSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAppBannerSearch.createObject = createAppBannerSearchObject;

export enum PatientClinicStatus {
	NORMAL = 'NORMAL',
	CLINIC_TERMINATED_INIT = 'CLINIC_TERMINATED_INIT',
	CLINIC_TERMINATED_AGREEMENT_SIGNED = 'CLINIC_TERMINATED_AGREEMENT_SIGNED',
}
export enum CgmTokenStatusEnum {
	VALID = 'VALID',
	REVOKED = 'REVOKED',
	INVALID_REQUEST = 'INVALID_REQUEST',
	INVALID_CLIENT = 'INVALID_CLIENT',
	INVALID_GRANT = 'INVALID_GRANT',
	UNAUTHORIZED_CLIENT = 'UNAUTHORIZED_CLIENT',
	UNSUPPORTED_GRANT_TYPE = 'UNSUPPORTED_GRANT_TYPE',
	label = 'label',
}
export interface DexcomToken {
	accessToken?: Nullable<string>;
	expiresIn?: Nullable<number>;
	tokenType?: Nullable<string>;
	refreshToken?: Nullable<string>;
	code?: Nullable<string>;
	tokenStatus?: Nullable<CgmTokenStatusEnum>;
}
export interface RewardPointsSettings {
	dailyRewardTimes?: Nullable<number>;
	pointsPerMeasure?: Nullable<number>;
}
export enum EventReminderEnum {
	NO_REMINDER = 'NO_REMINDER',
	BEFORE_EVENT_1_MINUTES = 'BEFORE_EVENT_1_MINUTES',
	BEFORE_EVENT_5_MINUTES = 'BEFORE_EVENT_5_MINUTES',
	BEFORE_EVENT_10_MINUTES = 'BEFORE_EVENT_10_MINUTES',
	BEFORE_EVENT_15_MINUTES = 'BEFORE_EVENT_15_MINUTES',
	BEFORE_EVENT_30_MINUTES = 'BEFORE_EVENT_30_MINUTES',
}
export enum ArticlePushFrequencyEnum {
	RECOMMENDED = 'RECOMMENDED',
	WEEKLY = 'WEEKLY',
	MONTHLY = 'MONTHLY',
	NEVER = 'NEVER',
}
export interface AppFunctions extends BaseModel {
	customized?: Nullable<boolean>;
	measurementReminder?: Nullable<boolean>;
	vitalHistory?: Nullable<boolean>;
	chat?: Nullable<boolean>;
	visit?: Nullable<boolean>;
	visitHistory?: Nullable<boolean>;
	vitalTasks?: Nullable<boolean>;
	healthRecords?: Nullable<boolean>;
	healthGoals?: Nullable<boolean>;
	medication?: Nullable<boolean>;
	exercise?: Nullable<boolean>;
	foodLog?: Nullable<boolean>;
	education?: Nullable<boolean>;
	skipRewardPopup?: Nullable<boolean>;
	patientClinicStatus?: Nullable<PatientClinicStatus>;
	dexcomToken?: Nullable<DexcomToken>;
	rewardPointsSettings?: Nullable<RewardPointsSettings>;
	visitReminder?: Nullable<EventReminderEnum>;
	otherEventReminder?: Nullable<EventReminderEnum>;
	calendarEventReminder?: Nullable<EventReminderEnum>;
	articlePushFrequency?: Nullable<ArticlePushFrequencyEnum>;
	medicationReminder?: Nullable<boolean>;
	createdBy?: Nullable<string>;
	updatedBy?: Nullable<string>;
	updatedByUser?: Nullable<LimitedUser>;
}
export class DexcomTokenClass {
  protected readonly _value: DexcomToken;

	constructor(value: DexcomToken) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get accessToken() {
    return this._value.accessToken;
  }

  get accessTokenWithDefault() {
    return this._value.accessToken || '';
  }

  get expiresIn() {
    return this._value.expiresIn;
  }

  get expiresInWithDefault() {
    return this._value.expiresIn || 0;
  }

  get tokenType() {
    return this._value.tokenType;
  }

  get tokenTypeWithDefault() {
    return this._value.tokenType || '';
  }

  get refreshToken() {
    return this._value.refreshToken;
  }

  get refreshTokenWithDefault() {
    return this._value.refreshToken || '';
  }

  get code() {
    return this._value.code;
  }

  get codeWithDefault() {
    return this._value.code || '';
  }

  get tokenStatus() {
    return this._value.tokenStatus;
  }

  tokenStatusIsIn(values: CgmTokenStatusEnum[] = []) {
    return this._value.tokenStatus && values.includes(this._value.tokenStatus);
  }
}
export class RewardPointsSettingsClass {
  protected readonly _value: RewardPointsSettings;

	constructor(value: RewardPointsSettings) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get dailyRewardTimes() {
    return this._value.dailyRewardTimes;
  }

  get dailyRewardTimesWithDefault() {
    return this._value.dailyRewardTimes || 0;
  }

  get pointsPerMeasure() {
    return this._value.pointsPerMeasure;
  }

  get pointsPerMeasureWithDefault() {
    return this._value.pointsPerMeasure || 0;
  }
}
export class AppFunctionsClass extends BaseModelClass {
  protected readonly _value: AppFunctions;

	constructor(value: AppFunctions) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get customized() {
    return this._value.customized;
  }

  get customizedWithDefault() {
    return this._value.customized || false;
  }

  get measurementReminder() {
    return this._value.measurementReminder;
  }

  get measurementReminderWithDefault() {
    return this._value.measurementReminder || false;
  }

  get vitalHistory() {
    return this._value.vitalHistory;
  }

  get vitalHistoryWithDefault() {
    return this._value.vitalHistory || false;
  }

  get chat() {
    return this._value.chat;
  }

  get chatWithDefault() {
    return this._value.chat || false;
  }

  get visit() {
    return this._value.visit;
  }

  get visitWithDefault() {
    return this._value.visit || false;
  }

  get visitHistory() {
    return this._value.visitHistory;
  }

  get visitHistoryWithDefault() {
    return this._value.visitHistory || false;
  }

  get vitalTasks() {
    return this._value.vitalTasks;
  }

  get vitalTasksWithDefault() {
    return this._value.vitalTasks || false;
  }

  get healthRecords() {
    return this._value.healthRecords;
  }

  get healthRecordsWithDefault() {
    return this._value.healthRecords || false;
  }

  get healthGoals() {
    return this._value.healthGoals;
  }

  get healthGoalsWithDefault() {
    return this._value.healthGoals || false;
  }

  get medication() {
    return this._value.medication;
  }

  get medicationWithDefault() {
    return this._value.medication || false;
  }

  get exercise() {
    return this._value.exercise;
  }

  get exerciseWithDefault() {
    return this._value.exercise || false;
  }

  get foodLog() {
    return this._value.foodLog;
  }

  get foodLogWithDefault() {
    return this._value.foodLog || false;
  }

  get education() {
    return this._value.education;
  }

  get educationWithDefault() {
    return this._value.education || false;
  }

  get skipRewardPopup() {
    return this._value.skipRewardPopup;
  }

  get skipRewardPopupWithDefault() {
    return this._value.skipRewardPopup || false;
  }

  get patientClinicStatus() {
    return this._value.patientClinicStatus;
  }

  patientClinicStatusIsIn(values: PatientClinicStatus[] = []) {
    return this._value.patientClinicStatus && values.includes(this._value.patientClinicStatus);
  }

  get dexcomToken() {
    return this._value.dexcomToken !== undefined && this._value.dexcomToken !== null ? new DexcomTokenClass(this._value.dexcomToken) : this._value.dexcomToken;
  }

  get rewardPointsSettings() {
    return this._value.rewardPointsSettings !== undefined && this._value.rewardPointsSettings !== null ? new RewardPointsSettingsClass(this._value.rewardPointsSettings) : this._value.rewardPointsSettings;
  }

  get visitReminder() {
    return this._value.visitReminder;
  }

  visitReminderIsIn(values: EventReminderEnum[] = []) {
    return this._value.visitReminder && values.includes(this._value.visitReminder);
  }

  get otherEventReminder() {
    return this._value.otherEventReminder;
  }

  otherEventReminderIsIn(values: EventReminderEnum[] = []) {
    return this._value.otherEventReminder && values.includes(this._value.otherEventReminder);
  }

  get calendarEventReminder() {
    return this._value.calendarEventReminder;
  }

  calendarEventReminderIsIn(values: EventReminderEnum[] = []) {
    return this._value.calendarEventReminder && values.includes(this._value.calendarEventReminder);
  }

  get articlePushFrequency() {
    return this._value.articlePushFrequency;
  }

  articlePushFrequencyIsIn(values: ArticlePushFrequencyEnum[] = []) {
    return this._value.articlePushFrequency && values.includes(this._value.articlePushFrequency);
  }

  get medicationReminder() {
    return this._value.medicationReminder;
  }

  get medicationReminderWithDefault() {
    return this._value.medicationReminder || false;
  }

  get createdBy() {
    return this._value.createdBy;
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get updatedByUser() {
    return this._value.updatedByUser !== undefined && this._value.updatedByUser !== null ? new LimitedUserClass(this._value.updatedByUser) : this._value.updatedByUser;
  }
}
export class AppFunctionsService extends BaseController<AppFunctions, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/app-functions';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const appFunctionsService = new AppFunctionsService();


export const createAppFunctionsGetObject = (value: APIResponse<AppFunctions>) => new APIResponseClass<AppFunctionsClass>(value, (value: AppFunctions) => new AppFunctionsClass(value));
export const useAppFunctionsGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<AppFunctions>,
    // @ts-ignore
    typeof appFunctionsService.get,
    GetOptions,
    APIResponseClass<AppFunctionsClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof appFunctionsService.get>) => (
      appFunctionsService.get(...args)
    ),
    ResponseClassCreator: createAppFunctionsGetObject,
    typeName: 'useAppFunctionsGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAppFunctionsGet.mock = () => new Error('Mock has not been implemented!');
useAppFunctionsGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAppFunctionsGet.createObject = createAppFunctionsGetObject;

export const createAppFunctionsUpdateObject = (value: APIResponse<AppFunctions>) => new APIResponseClass<AppFunctionsClass>(value, (value: AppFunctions) => new AppFunctionsClass(value));
export const useAppFunctionsUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, AppFunctions>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, AppFunctions>,
    APIResponse<AppFunctions>,
    // @ts-ignore
    typeof appFunctionsService.update,
    AjaxOptions,
    APIResponseClass<AppFunctionsClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof appFunctionsService.update>) => (
      appFunctionsService.update(...args)
    ),
    ResponseClassCreator: createAppFunctionsUpdateObject,
    typeName: 'useAppFunctionsUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAppFunctionsUpdate.mock = () => new Error('Mock has not been implemented!');
useAppFunctionsUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAppFunctionsUpdate.createObject = createAppFunctionsUpdateObject;

export const createAppFunctionsInsertObject = (value: APIResponse<AppFunctions>) => new APIResponseClass<AppFunctionsClass>(value, (value: AppFunctions) => new AppFunctionsClass(value));
export const useAppFunctionsInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<AppFunctions>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<AppFunctions>,
    APIResponse<AppFunctions>,
    // @ts-ignore
    typeof appFunctionsService.insert,
    AjaxOptions,
    APIResponseClass<AppFunctionsClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof appFunctionsService.insert>) => (
      appFunctionsService.insert(...args)
    ),
    ResponseClassCreator: createAppFunctionsInsertObject,
    typeName: 'useAppFunctionsInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAppFunctionsInsert.mock = () => new Error('Mock has not been implemented!');
useAppFunctionsInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAppFunctionsInsert.createObject = createAppFunctionsInsertObject;

export const createAppFunctionsDeleteObject = (value: APIResponse<AppFunctions>) => new APIResponseClass<AppFunctionsClass>(value, (value: AppFunctions) => new AppFunctionsClass(value));
export const useAppFunctionsDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<AppFunctions>,
    // @ts-ignore
    typeof appFunctionsService.delete,
    AjaxOptions,
    APIResponseClass<AppFunctionsClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof appFunctionsService.delete>) => (
      appFunctionsService.delete(...args)
    ),
    ResponseClassCreator: createAppFunctionsDeleteObject,
    typeName: 'useAppFunctionsDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAppFunctionsDelete.mock = () => new Error('Mock has not been implemented!');
useAppFunctionsDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAppFunctionsDelete.createObject = createAppFunctionsDeleteObject;

export const createAppFunctionsSearchObject = (value: APIResponse<PageResponse<AppFunctions>>) => new APIResponseClass<PageResponseClass<AppFunctionsClass>>(value, (value: PageResponse<AppFunctions>) => new PageResponseClass<AppFunctionsClass>(value, (value: AppFunctions) => new AppFunctionsClass(value)));
export const useAppFunctionsSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<AppFunctions>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<AppFunctions>,
    APIResponse<PageResponse<AppFunctions>>,
    // @ts-ignore
    typeof appFunctionsService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<AppFunctionsClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof appFunctionsService.search>) => (
      appFunctionsService.search(...args)
    ),
    ResponseClassCreator: createAppFunctionsSearchObject,
    typeName: 'useAppFunctionsSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAppFunctionsSearch.mock = () => new Error('Mock has not been implemented!');
useAppFunctionsSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAppFunctionsSearch.createObject = createAppFunctionsSearchObject;

export interface VersionDetail {
	version?: Nullable<string>;
	updateTime?: Nullable<string>;
}
export interface ContentDetail {
	language?: Nullable<string>;
	title?: Nullable<string>;
	description?: Nullable<string>;
}
export interface AppVersionConfig extends BaseModel {
	name?: Nullable<string>;
	versionDetails?: Nullable<VersionDetail[]>;
	medOrgIds?: Nullable<string[]>;
	activateDate?: Nullable<string>;
	contents?: Nullable<ContentDetail[]>;
	note?: Nullable<string>;
	medOrgIdIn?: Nullable<InFilter<string>>;
	miniOrganizations?: Nullable<MiniOrganization[]>;
}
export interface AppVersionRequest {
	memberId?: Nullable<string>;
	version?: Nullable<string>;
}
export interface AppVersionResponse {
	title?: Nullable<string>;
	description?: Nullable<string>;
	forceUpdate?: Nullable<boolean>;
	hasOptionalUpdate?: Nullable<boolean>;
	hasNewerVersion?: Nullable<boolean>;
	latestVersion?: Nullable<string>;
}
export class AppVersionResponseClass {
  protected readonly _value: AppVersionResponse;

	constructor(value: AppVersionResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get title() {
    return this._value.title;
  }

  get titleWithDefault() {
    return this._value.title || '';
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get forceUpdate() {
    return this._value.forceUpdate;
  }

  get forceUpdateWithDefault() {
    return this._value.forceUpdate || false;
  }

  get hasOptionalUpdate() {
    return this._value.hasOptionalUpdate;
  }

  get hasOptionalUpdateWithDefault() {
    return this._value.hasOptionalUpdate || false;
  }

  get hasNewerVersion() {
    return this._value.hasNewerVersion;
  }

  get hasNewerVersionWithDefault() {
    return this._value.hasNewerVersion || false;
  }

  get latestVersion() {
    return this._value.latestVersion;
  }

  get latestVersionWithDefault() {
    return this._value.latestVersion || '';
  }
}
export class VersionDetailClass {
  protected readonly _value: VersionDetail;

	constructor(value: VersionDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get version() {
    return this._value.version;
  }

  get versionWithDefault() {
    return this._value.version || '';
  }

  get updateTime() {
    return (this._value.updateTime !== undefined && this._value.updateTime !== null) ? dayjs(this._value.updateTime) : this._value.updateTime;
  }
}
export class ContentDetailClass {
  protected readonly _value: ContentDetail;

	constructor(value: ContentDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get language() {
    return this._value.language;
  }

  get languageWithDefault() {
    return this._value.language || '';
  }

  get title() {
    return this._value.title;
  }

  get titleWithDefault() {
    return this._value.title || '';
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }
}
export class AppVersionConfigClass extends BaseModelClass {
  protected readonly _value: AppVersionConfig;

	constructor(value: AppVersionConfig) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get versionDetails() {
    return this._value.versionDetails !== undefined && this._value.versionDetails !== null ? map(this._value.versionDetails, (v) => new VersionDetailClass(v)) : this._value.versionDetails;
  }

  get versionDetailsWithDefault() {
    return this._value.versionDetails || [];
  }

  get medOrgIds() {
    return this._value.medOrgIds;
  }

  get medOrgIdsWithDefault() {
    return this._value.medOrgIds || [];
  }

  get activateDate() {
    return (this._value.activateDate !== undefined && this._value.activateDate !== null) ? dayjs(this._value.activateDate) : this._value.activateDate;
  }

  get contents() {
    return this._value.contents !== undefined && this._value.contents !== null ? map(this._value.contents, (v) => new ContentDetailClass(v)) : this._value.contents;
  }

  get contentsWithDefault() {
    return this._value.contents || [];
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get medOrgIdIn() {
    return this._value.medOrgIdIn !== undefined && this._value.medOrgIdIn !== null ? new InFilterClass(this._value.medOrgIdIn) : this._value.medOrgIdIn;
  }

  get miniOrganizations() {
    return this._value.miniOrganizations !== undefined && this._value.miniOrganizations !== null ? map(this._value.miniOrganizations, (v) => new MiniOrganizationClass(v)) : this._value.miniOrganizations;
  }

  get miniOrganizationsWithDefault() {
    return this._value.miniOrganizations || [];
  }
}
export interface AppVersionConfigGetStatusParams {
	request: AppVersionRequest;
}

export class AppVersionConfigService extends BaseController<AppVersionConfig, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/app-version-config';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getStatus({ baseUrl, params, headers }: RequestOption<AppVersionConfigGetStatusParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<AppVersionResponse>>(`${baseUrl || this.baseURL}${this.basePath}/status${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const appVersionConfigService = new AppVersionConfigService();

export const createAppVersionConfigGetStatusObject = (value: APIResponse<AppVersionResponse>) => new APIResponseClass<AppVersionResponseClass>(value, (value: AppVersionResponse) => new AppVersionResponseClass(value));

export const useAppVersionConfigGetStatus = <RCN = undefined>(configs: MakeRequestHookConfig<AppVersionConfigGetStatusParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AppVersionConfigGetStatusParams,
    APIResponse<AppVersionResponse>,
    typeof appVersionConfigService.getStatus,
    AjaxOptions,
    APIResponseClass<AppVersionResponseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof appVersionConfigService.getStatus>) => (
      appVersionConfigService.getStatus(...args)
    ),
    ResponseClassCreator: createAppVersionConfigGetStatusObject,
    typeName: 'useAppVersionConfigGetStatus',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAppVersionConfigGetStatus.mock = () => new Error('Mock has not been implemented!');
useAppVersionConfigGetStatus.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAppVersionConfigGetStatus.createObject = createAppVersionConfigGetStatusObject;


export const createAppVersionConfigGetObject = (value: APIResponse<AppVersionConfig>) => new APIResponseClass<AppVersionConfigClass>(value, (value: AppVersionConfig) => new AppVersionConfigClass(value));
export const useAppVersionConfigGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<AppVersionConfig>,
    // @ts-ignore
    typeof appVersionConfigService.get,
    GetOptions,
    APIResponseClass<AppVersionConfigClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof appVersionConfigService.get>) => (
      appVersionConfigService.get(...args)
    ),
    ResponseClassCreator: createAppVersionConfigGetObject,
    typeName: 'useAppVersionConfigGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAppVersionConfigGet.mock = () => new Error('Mock has not been implemented!');
useAppVersionConfigGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAppVersionConfigGet.createObject = createAppVersionConfigGetObject;

export const createAppVersionConfigUpdateObject = (value: APIResponse<AppVersionConfig>) => new APIResponseClass<AppVersionConfigClass>(value, (value: AppVersionConfig) => new AppVersionConfigClass(value));
export const useAppVersionConfigUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, AppVersionConfig>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, AppVersionConfig>,
    APIResponse<AppVersionConfig>,
    // @ts-ignore
    typeof appVersionConfigService.update,
    AjaxOptions,
    APIResponseClass<AppVersionConfigClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof appVersionConfigService.update>) => (
      appVersionConfigService.update(...args)
    ),
    ResponseClassCreator: createAppVersionConfigUpdateObject,
    typeName: 'useAppVersionConfigUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAppVersionConfigUpdate.mock = () => new Error('Mock has not been implemented!');
useAppVersionConfigUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAppVersionConfigUpdate.createObject = createAppVersionConfigUpdateObject;

export const createAppVersionConfigInsertObject = (value: APIResponse<AppVersionConfig>) => new APIResponseClass<AppVersionConfigClass>(value, (value: AppVersionConfig) => new AppVersionConfigClass(value));
export const useAppVersionConfigInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<AppVersionConfig>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<AppVersionConfig>,
    APIResponse<AppVersionConfig>,
    // @ts-ignore
    typeof appVersionConfigService.insert,
    AjaxOptions,
    APIResponseClass<AppVersionConfigClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof appVersionConfigService.insert>) => (
      appVersionConfigService.insert(...args)
    ),
    ResponseClassCreator: createAppVersionConfigInsertObject,
    typeName: 'useAppVersionConfigInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAppVersionConfigInsert.mock = () => new Error('Mock has not been implemented!');
useAppVersionConfigInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAppVersionConfigInsert.createObject = createAppVersionConfigInsertObject;

export const createAppVersionConfigDeleteObject = (value: APIResponse<AppVersionConfig>) => new APIResponseClass<AppVersionConfigClass>(value, (value: AppVersionConfig) => new AppVersionConfigClass(value));
export const useAppVersionConfigDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<AppVersionConfig>,
    // @ts-ignore
    typeof appVersionConfigService.delete,
    AjaxOptions,
    APIResponseClass<AppVersionConfigClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof appVersionConfigService.delete>) => (
      appVersionConfigService.delete(...args)
    ),
    ResponseClassCreator: createAppVersionConfigDeleteObject,
    typeName: 'useAppVersionConfigDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAppVersionConfigDelete.mock = () => new Error('Mock has not been implemented!');
useAppVersionConfigDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAppVersionConfigDelete.createObject = createAppVersionConfigDeleteObject;

export const createAppVersionConfigSearchObject = (value: APIResponse<PageResponse<AppVersionConfig>>) => new APIResponseClass<PageResponseClass<AppVersionConfigClass>>(value, (value: PageResponse<AppVersionConfig>) => new PageResponseClass<AppVersionConfigClass>(value, (value: AppVersionConfig) => new AppVersionConfigClass(value)));
export const useAppVersionConfigSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<AppVersionConfig>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<AppVersionConfig>,
    APIResponse<PageResponse<AppVersionConfig>>,
    // @ts-ignore
    typeof appVersionConfigService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<AppVersionConfigClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof appVersionConfigService.search>) => (
      appVersionConfigService.search(...args)
    ),
    ResponseClassCreator: createAppVersionConfigSearchObject,
    typeName: 'useAppVersionConfigSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAppVersionConfigSearch.mock = () => new Error('Mock has not been implemented!');
useAppVersionConfigSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAppVersionConfigSearch.createObject = createAppVersionConfigSearchObject;

export interface ArrayContainsFilter {
	contains?: Nullable<string[]>;
}
export interface Article extends BaseModel {
	patientId?: Nullable<string>;
	name?: Nullable<string>;
	slug?: Nullable<string>;
	url?: Nullable<string>;
	thumbnailImageUrl?: Nullable<string>;
	notificationImageUrl?: Nullable<string>;
	categories?: Nullable<string[]>;
	coreConcept?: Nullable<string>;
	publishDate?: Nullable<string>;
	createDate?: Nullable<string>;
	minuteRead?: Nullable<number>;
	summary?: Nullable<string>;
	language?: Nullable<string>;
	viewCount?: Nullable<number>;
	hasVideo?: Nullable<boolean>;
	isNew?: Nullable<boolean>;
	saved?: Nullable<boolean>;
	internalArticleSlugs?: Nullable<string[]>;
	recommendedReads?: Nullable<string[]>;
	articleType?: Nullable<string>;
	category?: Nullable<ArrayContainsFilter>;
	publishDateRange?: Nullable<DateRangeFilter>;
	nameRegex?: Nullable<string>;
	summaryRegex?: Nullable<string>;
	languageIn?: Nullable<InFilter<string>>;
}
export interface ArticleSave extends BaseModel {
	memberId?: Nullable<string>;
	articleId?: Nullable<string>;
	url?: Nullable<string>;
	articles?: Nullable<Article[]>;
}
export interface ExistsFilter {
	exists?: Nullable<boolean>;
}
export interface ArticleCategory extends BaseModel {
	name?: Nullable<string>;
	slug?: Nullable<string>;
	showOnMobileExploreMore?: Nullable<boolean>;
	subCategoriesExists?: Nullable<ExistsFilter>;
	icd?: Nullable<string>;
	subCategories?: Nullable<string[]>;
	publishDate?: Nullable<string>;
	displayOnApp?: Nullable<boolean>;
	displayOrder?: Nullable<number>;
	nameSpanish?: Nullable<string>;
	nameVietnamese?: Nullable<string>;
	nameChineseSimplified?: Nullable<string>;
	nameChineseTraditional?: Nullable<string>;
}
export class ExistsFilterClass {
  protected readonly _value: ExistsFilter;

	constructor(value: ExistsFilter) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get exists() {
    return this._value.exists;
  }

  get existsWithDefault() {
    return this._value.exists || false;
  }
}
export class ArticleCategoryClass extends BaseModelClass {
  protected readonly _value: ArticleCategory;

	constructor(value: ArticleCategory) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get slug() {
    return this._value.slug;
  }

  get slugWithDefault() {
    return this._value.slug || '';
  }

  get showOnMobileExploreMore() {
    return this._value.showOnMobileExploreMore;
  }

  get showOnMobileExploreMoreWithDefault() {
    return this._value.showOnMobileExploreMore || false;
  }

  get subCategoriesExists() {
    return this._value.subCategoriesExists !== undefined && this._value.subCategoriesExists !== null ? new ExistsFilterClass(this._value.subCategoriesExists) : this._value.subCategoriesExists;
  }

  get icd() {
    return this._value.icd;
  }

  get icdWithDefault() {
    return this._value.icd || '';
  }

  get subCategories() {
    return this._value.subCategories;
  }

  get subCategoriesWithDefault() {
    return this._value.subCategories || [];
  }

  get publishDate() {
    return (this._value.publishDate !== undefined && this._value.publishDate !== null) ? dayjs(this._value.publishDate) : this._value.publishDate;
  }

  get displayOnApp() {
    return this._value.displayOnApp;
  }

  get displayOnAppWithDefault() {
    return this._value.displayOnApp || false;
  }

  get displayOrder() {
    return this._value.displayOrder;
  }

  get displayOrderWithDefault() {
    return this._value.displayOrder || 0;
  }

  get nameSpanish() {
    return this._value.nameSpanish;
  }

  get nameSpanishWithDefault() {
    return this._value.nameSpanish || '';
  }

  get nameVietnamese() {
    return this._value.nameVietnamese;
  }

  get nameVietnameseWithDefault() {
    return this._value.nameVietnamese || '';
  }

  get nameChineseSimplified() {
    return this._value.nameChineseSimplified;
  }

  get nameChineseSimplifiedWithDefault() {
    return this._value.nameChineseSimplified || '';
  }

  get nameChineseTraditional() {
    return this._value.nameChineseTraditional;
  }

  get nameChineseTraditionalWithDefault() {
    return this._value.nameChineseTraditional || '';
  }
}
export class ArrayContainsFilterClass {
  protected readonly _value: ArrayContainsFilter;

	constructor(value: ArrayContainsFilter) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get contains() {
    return this._value.contains;
  }

  get containsWithDefault() {
    return this._value.contains || [];
  }
}
export class ArticleClass extends BaseModelClass {
  protected readonly _value: Article;

	constructor(value: Article) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientId() {
    return this._value.patientId;
  }

  get patientIdWithDefault() {
    return this._value.patientId || '';
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get slug() {
    return this._value.slug;
  }

  get slugWithDefault() {
    return this._value.slug || '';
  }

  get url() {
    return this._value.url;
  }

  get urlWithDefault() {
    return this._value.url || '';
  }

  get thumbnailImageUrl() {
    return this._value.thumbnailImageUrl;
  }

  get thumbnailImageUrlWithDefault() {
    return this._value.thumbnailImageUrl || '';
  }

  get notificationImageUrl() {
    return this._value.notificationImageUrl;
  }

  get notificationImageUrlWithDefault() {
    return this._value.notificationImageUrl || '';
  }

  get categories() {
    return this._value.categories;
  }

  get categoriesWithDefault() {
    return this._value.categories || [];
  }

  get coreConcept() {
    return this._value.coreConcept;
  }

  get coreConceptWithDefault() {
    return this._value.coreConcept || '';
  }

  get publishDate() {
    return (this._value.publishDate !== undefined && this._value.publishDate !== null) ? dayjs(this._value.publishDate) : this._value.publishDate;
  }

  get createDate() {
    return (this._value.createDate !== undefined && this._value.createDate !== null) ? dayjs(this._value.createDate) : this._value.createDate;
  }

  get minuteRead() {
    return this._value.minuteRead;
  }

  get minuteReadWithDefault() {
    return this._value.minuteRead || 0;
  }

  get summary() {
    return this._value.summary;
  }

  get summaryWithDefault() {
    return this._value.summary || '';
  }

  get language() {
    return this._value.language;
  }

  get languageWithDefault() {
    return this._value.language || '';
  }

  get viewCount() {
    return this._value.viewCount;
  }

  get viewCountWithDefault() {
    return this._value.viewCount || 0;
  }

  get hasVideo() {
    return this._value.hasVideo;
  }

  get hasVideoWithDefault() {
    return this._value.hasVideo || false;
  }

  get isNew() {
    return this._value.isNew;
  }

  get isNewWithDefault() {
    return this._value.isNew || false;
  }

  get saved() {
    return this._value.saved;
  }

  get savedWithDefault() {
    return this._value.saved || false;
  }

  get internalArticleSlugs() {
    return this._value.internalArticleSlugs;
  }

  get internalArticleSlugsWithDefault() {
    return this._value.internalArticleSlugs || [];
  }

  get recommendedReads() {
    return this._value.recommendedReads;
  }

  get recommendedReadsWithDefault() {
    return this._value.recommendedReads || [];
  }

  get articleType() {
    return this._value.articleType;
  }

  get articleTypeWithDefault() {
    return this._value.articleType || '';
  }

  get category() {
    return this._value.category !== undefined && this._value.category !== null ? new ArrayContainsFilterClass(this._value.category) : this._value.category;
  }

  get publishDateRange() {
    return this._value.publishDateRange !== undefined && this._value.publishDateRange !== null ? new DateRangeFilterClass(this._value.publishDateRange) : this._value.publishDateRange;
  }

  get nameRegex() {
    return this._value.nameRegex;
  }

  get nameRegexWithDefault() {
    return this._value.nameRegex || '';
  }

  get summaryRegex() {
    return this._value.summaryRegex;
  }

  get summaryRegexWithDefault() {
    return this._value.summaryRegex || '';
  }

  get languageIn() {
    return this._value.languageIn !== undefined && this._value.languageIn !== null ? new InFilterClass(this._value.languageIn) : this._value.languageIn;
  }
}
export interface ListArticleSavesRequest {
	memberId?: Nullable<string>;
	pageInfo?: Nullable<PageInfo>;
}
export class ArticleSaveClass extends BaseModelClass {
  protected readonly _value: ArticleSave;

	constructor(value: ArticleSave) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get articleId() {
    return this._value.articleId;
  }

  get url() {
    return this._value.url;
  }

  get urlWithDefault() {
    return this._value.url || '';
  }

  get articles() {
    return this._value.articles !== undefined && this._value.articles !== null ? map(this._value.articles, (v) => new ArticleClass(v)) : this._value.articles;
  }

  get articlesWithDefault() {
    return this._value.articles || [];
  }
}
export interface ArticleSaveParams {
	articleSave: ArticleSave;
}

export interface ArticleGetCategoriesParams {
}

export interface ArticleGetArticleCategoriesByPatientIdParams {
	searchRequest: SearchRequest<Article>;
}

export interface ArticleGetPatientIcdParams {
	searchRequest: SearchRequest<Article>;
}

export interface ArticleGetMostPopularArticlesParams {
	searchRequest: SearchRequest<Article>;
}

export interface ArticleGetRecommendedReadsParams {
	articleIdOrSlug: string;
}

export interface ArticleUnSaveParams {
	articleSave: ArticleSave;
}

export interface ArticleGetSavedArticlesByMemberIdParams {
	request: ListArticleSavesRequest;
}

export interface ArticleReadArticleParams {
	id: string;
	memberId: string;
}

export interface ArticleGetAppCategoriesParams {
}

export class ArticleService extends BaseController<Article, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/articles';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public save({ baseUrl, params, headers }: RequestOption<ArticleSaveParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/save${getQueryStrings(queryList)}`, params.articleSave, { headers });
	}

	public getCategories({ baseUrl, params, headers }: RequestOption<ArticleGetCategoriesParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<ArticleCategory[]>>(`${baseUrl || this.baseURL}${this.basePath}/categories${getQueryStrings(queryList)}`, {}, { headers });
	}

	public getArticleCategoriesByPatientId({ baseUrl, params, headers }: RequestOption<ArticleGetArticleCategoriesByPatientIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string[]>>(`${baseUrl || this.baseURL}${this.basePath}/patient-categories${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getPatientIcd({ baseUrl, params, headers }: RequestOption<ArticleGetPatientIcdParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string[]>>(`${baseUrl || this.baseURL}${this.basePath}/icd${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getMostPopularArticles({ baseUrl, params, headers }: RequestOption<ArticleGetMostPopularArticlesParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Article[]>>(`${baseUrl || this.baseURL}${this.basePath}/most-popular${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getRecommendedReads({ baseUrl, params, headers }: RequestOption<ArticleGetRecommendedReadsParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<Article[]>>(`${baseUrl || this.baseURL}${this.basePath}/recommended-reads/${params.articleIdOrSlug}${getQueryStrings(queryList)}`, { headers });
	}

	public unSave({ baseUrl, params, headers }: RequestOption<ArticleUnSaveParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/unsave${getQueryStrings(queryList)}`, params.articleSave, { headers });
	}

	public getSavedArticlesByMemberId({ baseUrl, params, headers }: RequestOption<ArticleGetSavedArticlesByMemberIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<ArticleSave>>>(`${baseUrl || this.baseURL}${this.basePath}/saved${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public readArticle({ baseUrl, params, headers }: RequestOption<ArticleReadArticleParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}${params.id}/read-article${getQueryStrings(queryList)}`, params.memberId, { headers });
	}

	public getAppCategories({ baseUrl, params, headers }: RequestOption<ArticleGetAppCategoriesParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<ArticleCategory[]>>(`${baseUrl || this.baseURL}${this.basePath}/app-categories${getQueryStrings(queryList)}`, {}, { headers });
	}
}

export const articleService = new ArticleService();

export const createArticleSaveObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useArticleSave = <RCN = undefined>(configs: MakeRequestHookConfig<ArticleSaveParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ArticleSaveParams,
    APIResponse<string>,
    typeof articleService.save,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof articleService.save>) => (
      articleService.save(...args)
    ),
    ResponseClassCreator: createArticleSaveObject,
    typeName: 'useArticleSave',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useArticleSave.mock = () => new Error('Mock has not been implemented!');
useArticleSave.getLastMockedValue = () => new Error('Mock has not been implemented!');
useArticleSave.createObject = createArticleSaveObject;

export const createArticleGetCategoriesObject = (value: APIResponse<ArticleCategory[]>) => new APIResponseClass<ArticleCategoryClass[]>(value, (v) => map(v, (value: ArticleCategory) => new ArticleCategoryClass(value)));

export const useArticleGetCategories = <RCN = undefined>(configs: MakeRequestHookConfig<ArticleGetCategoriesParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ArticleGetCategoriesParams,
    APIResponse<ArticleCategory[]>,
    typeof articleService.getCategories,
    AjaxOptions,
    APIResponseClass<ArticleCategoryClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof articleService.getCategories>) => (
      articleService.getCategories(...args)
    ),
    ResponseClassCreator: createArticleGetCategoriesObject,
    typeName: 'useArticleGetCategories',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useArticleGetCategories.mock = () => new Error('Mock has not been implemented!');
useArticleGetCategories.getLastMockedValue = () => new Error('Mock has not been implemented!');
useArticleGetCategories.createObject = createArticleGetCategoriesObject;

export const createArticleGetArticleCategoriesByPatientIdObject = (value: APIResponse<string[]>) => new APIResponseClass<string[]>(value, (v) => (v));

export const useArticleGetArticleCategoriesByPatientId = <RCN = undefined>(configs: MakeRequestHookConfig<ArticleGetArticleCategoriesByPatientIdParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ArticleGetArticleCategoriesByPatientIdParams,
    APIResponse<string[]>,
    typeof articleService.getArticleCategoriesByPatientId,
    AjaxOptions,
    APIResponseClass<string[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof articleService.getArticleCategoriesByPatientId>) => (
      articleService.getArticleCategoriesByPatientId(...args)
    ),
    ResponseClassCreator: createArticleGetArticleCategoriesByPatientIdObject,
    typeName: 'useArticleGetArticleCategoriesByPatientId',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useArticleGetArticleCategoriesByPatientId.mock = () => new Error('Mock has not been implemented!');
useArticleGetArticleCategoriesByPatientId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useArticleGetArticleCategoriesByPatientId.createObject = createArticleGetArticleCategoriesByPatientIdObject;

export const createArticleGetPatientIcdObject = (value: APIResponse<string[]>) => new APIResponseClass<string[]>(value, (v) => (v));

export const useArticleGetPatientIcd = <RCN = undefined>(configs: MakeRequestHookConfig<ArticleGetPatientIcdParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ArticleGetPatientIcdParams,
    APIResponse<string[]>,
    typeof articleService.getPatientIcd,
    AjaxOptions,
    APIResponseClass<string[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof articleService.getPatientIcd>) => (
      articleService.getPatientIcd(...args)
    ),
    ResponseClassCreator: createArticleGetPatientIcdObject,
    typeName: 'useArticleGetPatientIcd',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useArticleGetPatientIcd.mock = () => new Error('Mock has not been implemented!');
useArticleGetPatientIcd.getLastMockedValue = () => new Error('Mock has not been implemented!');
useArticleGetPatientIcd.createObject = createArticleGetPatientIcdObject;

export const createArticleGetMostPopularArticlesObject = (value: APIResponse<Article[]>) => new APIResponseClass<ArticleClass[]>(value, (v) => map(v, (value: Article) => new ArticleClass(value)));

export const useArticleGetMostPopularArticles = <RCN = undefined>(configs: MakeRequestHookConfig<ArticleGetMostPopularArticlesParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ArticleGetMostPopularArticlesParams,
    APIResponse<Article[]>,
    typeof articleService.getMostPopularArticles,
    AjaxOptions,
    APIResponseClass<ArticleClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof articleService.getMostPopularArticles>) => (
      articleService.getMostPopularArticles(...args)
    ),
    ResponseClassCreator: createArticleGetMostPopularArticlesObject,
    typeName: 'useArticleGetMostPopularArticles',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useArticleGetMostPopularArticles.mock = () => new Error('Mock has not been implemented!');
useArticleGetMostPopularArticles.getLastMockedValue = () => new Error('Mock has not been implemented!');
useArticleGetMostPopularArticles.createObject = createArticleGetMostPopularArticlesObject;

export const createArticleGetRecommendedReadsObject = (value: APIResponse<Article[]>) => new APIResponseClass<ArticleClass[]>(value, (v) => map(v, (value: Article) => new ArticleClass(value)));

export const useArticleGetRecommendedReads = <RCN = undefined>(configs: MakeRequestHookConfig<ArticleGetRecommendedReadsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ArticleGetRecommendedReadsParams,
    APIResponse<Article[]>,
    typeof articleService.getRecommendedReads,
    GetOptions,
    APIResponseClass<ArticleClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof articleService.getRecommendedReads>) => (
      articleService.getRecommendedReads(...args)
    ),
    ResponseClassCreator: createArticleGetRecommendedReadsObject,
    typeName: 'useArticleGetRecommendedReads',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useArticleGetRecommendedReads.mock = () => new Error('Mock has not been implemented!');
useArticleGetRecommendedReads.getLastMockedValue = () => new Error('Mock has not been implemented!');
useArticleGetRecommendedReads.createObject = createArticleGetRecommendedReadsObject;

export const createArticleUnSaveObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useArticleUnSave = <RCN = undefined>(configs: MakeRequestHookConfig<ArticleUnSaveParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ArticleUnSaveParams,
    APIResponse<string>,
    typeof articleService.unSave,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof articleService.unSave>) => (
      articleService.unSave(...args)
    ),
    ResponseClassCreator: createArticleUnSaveObject,
    typeName: 'useArticleUnSave',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useArticleUnSave.mock = () => new Error('Mock has not been implemented!');
useArticleUnSave.getLastMockedValue = () => new Error('Mock has not been implemented!');
useArticleUnSave.createObject = createArticleUnSaveObject;

export const createArticleGetSavedArticlesByMemberIdObject = (value: APIResponse<PageResponse<ArticleSave>>) => new APIResponseClass<PageResponseClass<ArticleSaveClass>>(value, (value: PageResponse<ArticleSave>) => new PageResponseClass<ArticleSaveClass>(value, (value: ArticleSave) => new ArticleSaveClass(value)));

export const useArticleGetSavedArticlesByMemberId = <RCN = undefined>(configs: MakeRequestHookConfig<ArticleGetSavedArticlesByMemberIdParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ArticleGetSavedArticlesByMemberIdParams,
    APIResponse<PageResponse<ArticleSave>>,
    typeof articleService.getSavedArticlesByMemberId,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ArticleSaveClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof articleService.getSavedArticlesByMemberId>) => (
      articleService.getSavedArticlesByMemberId(...args)
    ),
    ResponseClassCreator: createArticleGetSavedArticlesByMemberIdObject,
    typeName: 'useArticleGetSavedArticlesByMemberId',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useArticleGetSavedArticlesByMemberId.mock = () => new Error('Mock has not been implemented!');
useArticleGetSavedArticlesByMemberId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useArticleGetSavedArticlesByMemberId.createObject = createArticleGetSavedArticlesByMemberIdObject;

export const createArticleReadArticleObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useArticleReadArticle = <RCN = undefined>(configs: MakeRequestHookConfig<ArticleReadArticleParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ArticleReadArticleParams,
    APIResponse<string>,
    typeof articleService.readArticle,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof articleService.readArticle>) => (
      articleService.readArticle(...args)
    ),
    ResponseClassCreator: createArticleReadArticleObject,
    typeName: 'useArticleReadArticle',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useArticleReadArticle.mock = () => new Error('Mock has not been implemented!');
useArticleReadArticle.getLastMockedValue = () => new Error('Mock has not been implemented!');
useArticleReadArticle.createObject = createArticleReadArticleObject;

export const createArticleGetAppCategoriesObject = (value: APIResponse<ArticleCategory[]>) => new APIResponseClass<ArticleCategoryClass[]>(value, (v) => map(v, (value: ArticleCategory) => new ArticleCategoryClass(value)));

export const useArticleGetAppCategories = <RCN = undefined>(configs: MakeRequestHookConfig<ArticleGetAppCategoriesParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ArticleGetAppCategoriesParams,
    APIResponse<ArticleCategory[]>,
    typeof articleService.getAppCategories,
    AjaxOptions,
    APIResponseClass<ArticleCategoryClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof articleService.getAppCategories>) => (
      articleService.getAppCategories(...args)
    ),
    ResponseClassCreator: createArticleGetAppCategoriesObject,
    typeName: 'useArticleGetAppCategories',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useArticleGetAppCategories.mock = () => new Error('Mock has not been implemented!');
useArticleGetAppCategories.getLastMockedValue = () => new Error('Mock has not been implemented!');
useArticleGetAppCategories.createObject = createArticleGetAppCategoriesObject;


export const createArticleGetObject = (value: APIResponse<Article>) => new APIResponseClass<ArticleClass>(value, (value: Article) => new ArticleClass(value));
export const useArticleGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<Article>,
    // @ts-ignore
    typeof articleService.get,
    GetOptions,
    APIResponseClass<ArticleClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof articleService.get>) => (
      articleService.get(...args)
    ),
    ResponseClassCreator: createArticleGetObject,
    typeName: 'useArticleGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useArticleGet.mock = () => new Error('Mock has not been implemented!');
useArticleGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useArticleGet.createObject = createArticleGetObject;

export const createArticleUpdateObject = (value: APIResponse<Article>) => new APIResponseClass<ArticleClass>(value, (value: Article) => new ArticleClass(value));
export const useArticleUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, Article>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, Article>,
    APIResponse<Article>,
    // @ts-ignore
    typeof articleService.update,
    AjaxOptions,
    APIResponseClass<ArticleClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof articleService.update>) => (
      articleService.update(...args)
    ),
    ResponseClassCreator: createArticleUpdateObject,
    typeName: 'useArticleUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useArticleUpdate.mock = () => new Error('Mock has not been implemented!');
useArticleUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useArticleUpdate.createObject = createArticleUpdateObject;

export const createArticleInsertObject = (value: APIResponse<Article>) => new APIResponseClass<ArticleClass>(value, (value: Article) => new ArticleClass(value));
export const useArticleInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<Article>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<Article>,
    APIResponse<Article>,
    // @ts-ignore
    typeof articleService.insert,
    AjaxOptions,
    APIResponseClass<ArticleClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof articleService.insert>) => (
      articleService.insert(...args)
    ),
    ResponseClassCreator: createArticleInsertObject,
    typeName: 'useArticleInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useArticleInsert.mock = () => new Error('Mock has not been implemented!');
useArticleInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useArticleInsert.createObject = createArticleInsertObject;

export const createArticleDeleteObject = (value: APIResponse<Article>) => new APIResponseClass<ArticleClass>(value, (value: Article) => new ArticleClass(value));
export const useArticleDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<Article>,
    // @ts-ignore
    typeof articleService.delete,
    AjaxOptions,
    APIResponseClass<ArticleClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof articleService.delete>) => (
      articleService.delete(...args)
    ),
    ResponseClassCreator: createArticleDeleteObject,
    typeName: 'useArticleDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useArticleDelete.mock = () => new Error('Mock has not been implemented!');
useArticleDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useArticleDelete.createObject = createArticleDeleteObject;

export const createArticleSearchObject = (value: APIResponse<PageResponse<Article>>) => new APIResponseClass<PageResponseClass<ArticleClass>>(value, (value: PageResponse<Article>) => new PageResponseClass<ArticleClass>(value, (value: Article) => new ArticleClass(value)));
export const useArticleSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<Article>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<Article>,
    APIResponse<PageResponse<Article>>,
    // @ts-ignore
    typeof articleService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ArticleClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof articleService.search>) => (
      articleService.search(...args)
    ),
    ResponseClassCreator: createArticleSearchObject,
    typeName: 'useArticleSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useArticleSearch.mock = () => new Error('Mock has not been implemented!');
useArticleSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useArticleSearch.createObject = createArticleSearchObject;

export interface AuditRecordSessionInfoInput {
	phoneName?: Nullable<string>;
	phoneModel?: Nullable<string>;
	phoneOSVersion?: Nullable<string>;
	phoneId?: Nullable<string>;
	appVersion?: Nullable<string>;
	appScheme?: Nullable<string>;
	serverUrl?: Nullable<string>;
}
export interface AuditTrail extends BaseModel {
	sessionInfo?: Nullable<AuditRecordSessionInfoInput>;
	userName?: Nullable<string>;
	userId?: Nullable<string>;
	success?: Nullable<boolean>;
	action?: Nullable<string>;
	group?: Nullable<string>;
	note?: Nullable<string>;
	sessionId?: Nullable<string>;
	networkType?: Nullable<string>;
	networkSpeed?: Nullable<string>;
}
export class AuditRecordSessionInfoInputClass {
  protected readonly _value: AuditRecordSessionInfoInput;

	constructor(value: AuditRecordSessionInfoInput) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get phoneName() {
    return this._value.phoneName;
  }

  get phoneNameWithDefault() {
    return this._value.phoneName || '';
  }

  get phoneModel() {
    return this._value.phoneModel;
  }

  get phoneModelWithDefault() {
    return this._value.phoneModel || '';
  }

  get phoneOSVersion() {
    return this._value.phoneOSVersion;
  }

  get phoneOSVersionWithDefault() {
    return this._value.phoneOSVersion || '';
  }

  get phoneId() {
    return this._value.phoneId;
  }

  get phoneIdWithDefault() {
    return this._value.phoneId || '';
  }

  get appVersion() {
    return this._value.appVersion;
  }

  get appVersionWithDefault() {
    return this._value.appVersion || '';
  }

  get appScheme() {
    return this._value.appScheme;
  }

  get appSchemeWithDefault() {
    return this._value.appScheme || '';
  }

  get serverUrl() {
    return this._value.serverUrl;
  }

  get serverUrlWithDefault() {
    return this._value.serverUrl || '';
  }
}
export class AuditTrailClass extends BaseModelClass {
  protected readonly _value: AuditTrail;

	constructor(value: AuditTrail) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get sessionInfo() {
    return this._value.sessionInfo !== undefined && this._value.sessionInfo !== null ? new AuditRecordSessionInfoInputClass(this._value.sessionInfo) : this._value.sessionInfo;
  }

  get userName() {
    return this._value.userName;
  }

  get userNameWithDefault() {
    return this._value.userName || '';
  }

  get userId() {
    return this._value.userId;
  }

  get success() {
    return this._value.success;
  }

  get successWithDefault() {
    return this._value.success || false;
  }

  get action() {
    return this._value.action;
  }

  get actionWithDefault() {
    return this._value.action || '';
  }

  get group() {
    return this._value.group;
  }

  get groupWithDefault() {
    return this._value.group || '';
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get sessionId() {
    return this._value.sessionId;
  }

  get sessionIdWithDefault() {
    return this._value.sessionId || '';
  }

  get networkType() {
    return this._value.networkType;
  }

  get networkTypeWithDefault() {
    return this._value.networkType || '';
  }

  get networkSpeed() {
    return this._value.networkSpeed;
  }

  get networkSpeedWithDefault() {
    return this._value.networkSpeed || '';
  }
}
export class AuditTrailService extends BaseController<AuditTrail, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/audit-trail';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const auditTrailService = new AuditTrailService();


export const createAuditTrailGetObject = (value: APIResponse<AuditTrail>) => new APIResponseClass<AuditTrailClass>(value, (value: AuditTrail) => new AuditTrailClass(value));
export const useAuditTrailGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<AuditTrail>,
    // @ts-ignore
    typeof auditTrailService.get,
    GetOptions,
    APIResponseClass<AuditTrailClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof auditTrailService.get>) => (
      auditTrailService.get(...args)
    ),
    ResponseClassCreator: createAuditTrailGetObject,
    typeName: 'useAuditTrailGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAuditTrailGet.mock = () => new Error('Mock has not been implemented!');
useAuditTrailGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAuditTrailGet.createObject = createAuditTrailGetObject;

export const createAuditTrailUpdateObject = (value: APIResponse<AuditTrail>) => new APIResponseClass<AuditTrailClass>(value, (value: AuditTrail) => new AuditTrailClass(value));
export const useAuditTrailUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, AuditTrail>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, AuditTrail>,
    APIResponse<AuditTrail>,
    // @ts-ignore
    typeof auditTrailService.update,
    AjaxOptions,
    APIResponseClass<AuditTrailClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof auditTrailService.update>) => (
      auditTrailService.update(...args)
    ),
    ResponseClassCreator: createAuditTrailUpdateObject,
    typeName: 'useAuditTrailUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAuditTrailUpdate.mock = () => new Error('Mock has not been implemented!');
useAuditTrailUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAuditTrailUpdate.createObject = createAuditTrailUpdateObject;

export const createAuditTrailInsertObject = (value: APIResponse<AuditTrail>) => new APIResponseClass<AuditTrailClass>(value, (value: AuditTrail) => new AuditTrailClass(value));
export const useAuditTrailInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<AuditTrail>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<AuditTrail>,
    APIResponse<AuditTrail>,
    // @ts-ignore
    typeof auditTrailService.insert,
    AjaxOptions,
    APIResponseClass<AuditTrailClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof auditTrailService.insert>) => (
      auditTrailService.insert(...args)
    ),
    ResponseClassCreator: createAuditTrailInsertObject,
    typeName: 'useAuditTrailInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAuditTrailInsert.mock = () => new Error('Mock has not been implemented!');
useAuditTrailInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAuditTrailInsert.createObject = createAuditTrailInsertObject;

export const createAuditTrailDeleteObject = (value: APIResponse<AuditTrail>) => new APIResponseClass<AuditTrailClass>(value, (value: AuditTrail) => new AuditTrailClass(value));
export const useAuditTrailDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<AuditTrail>,
    // @ts-ignore
    typeof auditTrailService.delete,
    AjaxOptions,
    APIResponseClass<AuditTrailClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof auditTrailService.delete>) => (
      auditTrailService.delete(...args)
    ),
    ResponseClassCreator: createAuditTrailDeleteObject,
    typeName: 'useAuditTrailDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAuditTrailDelete.mock = () => new Error('Mock has not been implemented!');
useAuditTrailDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAuditTrailDelete.createObject = createAuditTrailDeleteObject;

export const createAuditTrailSearchObject = (value: APIResponse<PageResponse<AuditTrail>>) => new APIResponseClass<PageResponseClass<AuditTrailClass>>(value, (value: PageResponse<AuditTrail>) => new PageResponseClass<AuditTrailClass>(value, (value: AuditTrail) => new AuditTrailClass(value)));
export const useAuditTrailSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<AuditTrail>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<AuditTrail>,
    APIResponse<PageResponse<AuditTrail>>,
    // @ts-ignore
    typeof auditTrailService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<AuditTrailClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof auditTrailService.search>) => (
      auditTrailService.search(...args)
    ),
    ResponseClassCreator: createAuditTrailSearchObject,
    typeName: 'useAuditTrailSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useAuditTrailSearch.mock = () => new Error('Mock has not been implemented!');
useAuditTrailSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAuditTrailSearch.createObject = createAuditTrailSearchObject;

export enum AuthUserTypeEnum {
	EMPLOYEE = 'EMPLOYEE',
	PATIENT = 'PATIENT',
}
export interface AuthPhone {
	countryCode?: Nullable<string>;
	number?: Nullable<string>;
}
export interface LoginRequest {
	authUserType?: Nullable<AuthUserTypeEnum>;
	usernameOrEmail?: Nullable<string>;
	password?: Nullable<string>;
	googleAuthToken?: Nullable<string>;
	phone?: Nullable<AuthPhone>;
	otpCode?: Nullable<string>;
	appVersion?: Nullable<string>;
	phoneOsVersion?: Nullable<string>;
}
export interface RoleAssignment extends BaseModel {
	memberId: string;
	organizationId: string;
	roleType: RoleTypeEnum;
	active?: Nullable<boolean>;
	organizationIdIn?: Nullable<InFilter<string>>;
	roleIn?: Nullable<InFilter<RoleTypeEnum>>;
	memberIdIn?: Nullable<InFilter<string>>;
	miniOrganization?: Nullable<MiniOrganization>;
}
export interface ChatInfo {
	authKey?: Nullable<string>;
	publishKey?: Nullable<string>;
	subscribeKey?: Nullable<string>;
}
export interface LoginResponse<T> {
	sessionToken?: Nullable<string>;
	gqlToken?: Nullable<string>;
	sessionId?: Nullable<string>;
	authenticated?: Nullable<boolean>;
	userInfo?: Nullable<T>;
	roles?: Nullable<RoleAssignment[]>;
	chatInfo?: Nullable<ChatInfo>;
}
export class RoleAssignmentClass extends BaseModelClass {
  protected readonly _value: RoleAssignment;

	constructor(value: RoleAssignment) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get roleType() {
    return this._value.roleType;
  }

  roleTypeIsIn(values: RoleTypeEnum[] = []) {
    return this._value.roleType && values.includes(this._value.roleType);
  }

  get active() {
    return this._value.active;
  }

  get activeWithDefault() {
    return this._value.active || false;
  }

  get organizationIdIn() {
    return this._value.organizationIdIn !== undefined && this._value.organizationIdIn !== null ? new InFilterClass(this._value.organizationIdIn) : this._value.organizationIdIn;
  }

  get roleIn() {
    return this._value.roleIn !== undefined && this._value.roleIn !== null ? new InFilterClass(this._value.roleIn) : this._value.roleIn;
  }

  get memberIdIn() {
    return this._value.memberIdIn !== undefined && this._value.memberIdIn !== null ? new InFilterClass(this._value.memberIdIn) : this._value.memberIdIn;
  }

  get miniOrganization() {
    return this._value.miniOrganization !== undefined && this._value.miniOrganization !== null ? new MiniOrganizationClass(this._value.miniOrganization) : this._value.miniOrganization;
  }
}
export class ChatInfoClass {
  protected readonly _value: ChatInfo;

	constructor(value: ChatInfo) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get authKey() {
    return this._value.authKey;
  }

  get authKeyWithDefault() {
    return this._value.authKey || '';
  }

  get publishKey() {
    return this._value.publishKey;
  }

  get publishKeyWithDefault() {
    return this._value.publishKey || '';
  }

  get subscribeKey() {
    return this._value.subscribeKey;
  }

  get subscribeKeyWithDefault() {
    return this._value.subscribeKey || '';
  }
}
export class LoginResponseClass<T> {
  protected readonly _value: LoginResponse<any>;
	protected readonly userInfoConstructorFunc?: (value: any) => T;

	constructor(value: LoginResponse<any>, userInfoConstructorFunc?: (value: any) => T) {
		this._value = value;
		this.userInfoConstructorFunc = userInfoConstructorFunc;
	}

  get rawValue() {
    return this._value;
  }

  get sessionToken() {
    return this._value.sessionToken;
  }

  get sessionTokenWithDefault() {
    return this._value.sessionToken || '';
  }

  get gqlToken() {
    return this._value.gqlToken;
  }

  get gqlTokenWithDefault() {
    return this._value.gqlToken || '';
  }

  get sessionId() {
    return this._value.sessionId;
  }

  get sessionIdWithDefault() {
    return this._value.sessionId || '';
  }

  get authenticated() {
    return this._value.authenticated;
  }

  get authenticatedWithDefault() {
    return this._value.authenticated || false;
  }

  get userInfo(): T {
    return this.userInfoConstructorFunc ? (this._value.userInfo && this.userInfoConstructorFunc(this._value.userInfo)) : this._value.userInfo;
  }

  get roles() {
    return this._value.roles !== undefined && this._value.roles !== null ? map(this._value.roles, (v) => new RoleAssignmentClass(v)) : this._value.roles;
  }

  get rolesWithDefault() {
    return this._value.roles || [];
  }

  get chatInfo() {
    return this._value.chatInfo !== undefined && this._value.chatInfo !== null ? new ChatInfoClass(this._value.chatInfo) : this._value.chatInfo;
  }
}
export interface ResetPasswordRequest {
	username?: Nullable<string>;
	password?: Nullable<string>;
}
export interface RecoverCodeRequest {
	username?: Nullable<string>;
	authPhone?: Nullable<AuthPhone>;
	code?: Nullable<string>;
}
export interface AuthLoginParams {
	loginRequest: LoginRequest;
}

export interface AuthRecoverPasswordParams {
	request: ResetPasswordRequest;
}

export interface AuthLogoutParams {
}

export interface AuthRefreshTokenParams {
}

export interface AuthSendOtpCodeParams {
	phone: AuthPhone;
}

export interface AuthSendRecoverCodeParams {
	request: RecoverCodeRequest;
}

export interface AuthGetLoginResponseParams {
}

export interface AuthVerifyRecoverCodeParams {
	request: RecoverCodeRequest;
}

export class AuthService {
  protected baseURL = '';

  protected basePath = '/v1/uc/auth';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public login({ baseUrl, params, headers }: RequestOption<AuthLoginParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<LoginResponse>>(`${baseUrl || this.baseURL}${this.basePath}/login${getQueryStrings(queryList)}`, params.loginRequest, { headers });
	}

	public recoverPassword({ baseUrl, params, headers }: RequestOption<AuthRecoverPasswordParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/recover-password${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public logout({ baseUrl, params, headers }: RequestOption<AuthLogoutParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/logout${getQueryStrings(queryList)}`, { headers });
	}

	public refreshToken({ baseUrl, params, headers }: RequestOption<AuthRefreshTokenParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<LoginResponse>>(`${baseUrl || this.baseURL}${this.basePath}/refresh-token${getQueryStrings(queryList)}`, { headers });
	}

	public sendOtpCode({ baseUrl, params, headers }: RequestOption<AuthSendOtpCodeParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/send-otp${getQueryStrings(queryList)}`, params.phone, { headers });
	}

	public sendRecoverCode({ baseUrl, params, headers }: RequestOption<AuthSendRecoverCodeParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/send-recover-code${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getLoginResponse({ baseUrl, params, headers }: RequestOption<AuthGetLoginResponseParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<LoginResponse>>(`${baseUrl || this.baseURL}${this.basePath}/me${getQueryStrings(queryList)}`, { headers });
	}

	public verifyRecoverCode({ baseUrl, params, headers }: RequestOption<AuthVerifyRecoverCodeParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<LoginResponse>>(`${baseUrl || this.baseURL}${this.basePath}/verify-recover-code${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const authService = new AuthService();

export const createAuthLoginObject = (value: APIResponse<LoginResponse>) => new APIResponseClass<LoginResponseClass>(value, (value: LoginResponse) => new LoginResponseClass(value));

export const useAuthLogin = <RCN = undefined>(configs: MakeRequestHookConfig<AuthLoginParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AuthLoginParams,
    APIResponse<LoginResponse>,
    typeof authService.login,
    AjaxOptions,
    APIResponseClass<LoginResponseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof authService.login>) => (
      authService.login(...args)
    ),
    ResponseClassCreator: createAuthLoginObject,
    typeName: 'useAuthLogin',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAuthLogin.mock = () => new Error('Mock has not been implemented!');
useAuthLogin.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAuthLogin.createObject = createAuthLoginObject;

export const createAuthRecoverPasswordObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useAuthRecoverPassword = <RCN = undefined>(configs: MakeRequestHookConfig<AuthRecoverPasswordParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AuthRecoverPasswordParams,
    APIResponse<string>,
    typeof authService.recoverPassword,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof authService.recoverPassword>) => (
      authService.recoverPassword(...args)
    ),
    ResponseClassCreator: createAuthRecoverPasswordObject,
    typeName: 'useAuthRecoverPassword',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAuthRecoverPassword.mock = () => new Error('Mock has not been implemented!');
useAuthRecoverPassword.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAuthRecoverPassword.createObject = createAuthRecoverPasswordObject;

export const createAuthLogoutObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useAuthLogout = <RCN = undefined>(configs: MakeRequestHookConfig<AuthLogoutParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AuthLogoutParams,
    APIResponse<string>,
    typeof authService.logout,
    GetOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof authService.logout>) => (
      authService.logout(...args)
    ),
    ResponseClassCreator: createAuthLogoutObject,
    typeName: 'useAuthLogout',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAuthLogout.mock = () => new Error('Mock has not been implemented!');
useAuthLogout.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAuthLogout.createObject = createAuthLogoutObject;

export const createAuthRefreshTokenObject = (value: APIResponse<LoginResponse>) => new APIResponseClass<LoginResponseClass>(value, (value: LoginResponse) => new LoginResponseClass(value));

export const useAuthRefreshToken = <RCN = undefined>(configs: MakeRequestHookConfig<AuthRefreshTokenParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AuthRefreshTokenParams,
    APIResponse<LoginResponse>,
    typeof authService.refreshToken,
    GetOptions,
    APIResponseClass<LoginResponseClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof authService.refreshToken>) => (
      authService.refreshToken(...args)
    ),
    ResponseClassCreator: createAuthRefreshTokenObject,
    typeName: 'useAuthRefreshToken',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAuthRefreshToken.mock = () => new Error('Mock has not been implemented!');
useAuthRefreshToken.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAuthRefreshToken.createObject = createAuthRefreshTokenObject;

export const createAuthSendOtpCodeObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useAuthSendOtpCode = <RCN = undefined>(configs: MakeRequestHookConfig<AuthSendOtpCodeParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AuthSendOtpCodeParams,
    APIResponse<string>,
    typeof authService.sendOtpCode,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof authService.sendOtpCode>) => (
      authService.sendOtpCode(...args)
    ),
    ResponseClassCreator: createAuthSendOtpCodeObject,
    typeName: 'useAuthSendOtpCode',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAuthSendOtpCode.mock = () => new Error('Mock has not been implemented!');
useAuthSendOtpCode.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAuthSendOtpCode.createObject = createAuthSendOtpCodeObject;

export const createAuthSendRecoverCodeObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useAuthSendRecoverCode = <RCN = undefined>(configs: MakeRequestHookConfig<AuthSendRecoverCodeParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AuthSendRecoverCodeParams,
    APIResponse<string>,
    typeof authService.sendRecoverCode,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof authService.sendRecoverCode>) => (
      authService.sendRecoverCode(...args)
    ),
    ResponseClassCreator: createAuthSendRecoverCodeObject,
    typeName: 'useAuthSendRecoverCode',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAuthSendRecoverCode.mock = () => new Error('Mock has not been implemented!');
useAuthSendRecoverCode.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAuthSendRecoverCode.createObject = createAuthSendRecoverCodeObject;

export const createAuthGetLoginResponseObject = (value: APIResponse<LoginResponse>) => new APIResponseClass<LoginResponseClass>(value, (value: LoginResponse) => new LoginResponseClass(value));

export const useAuthGetLoginResponse = <RCN = undefined>(configs: MakeRequestHookConfig<AuthGetLoginResponseParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AuthGetLoginResponseParams,
    APIResponse<LoginResponse>,
    typeof authService.getLoginResponse,
    GetOptions,
    APIResponseClass<LoginResponseClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof authService.getLoginResponse>) => (
      authService.getLoginResponse(...args)
    ),
    ResponseClassCreator: createAuthGetLoginResponseObject,
    typeName: 'useAuthGetLoginResponse',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAuthGetLoginResponse.mock = () => new Error('Mock has not been implemented!');
useAuthGetLoginResponse.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAuthGetLoginResponse.createObject = createAuthGetLoginResponseObject;

export const createAuthVerifyRecoverCodeObject = (value: APIResponse<LoginResponse>) => new APIResponseClass<LoginResponseClass>(value, (value: LoginResponse) => new LoginResponseClass(value));

export const useAuthVerifyRecoverCode = <RCN = undefined>(configs: MakeRequestHookConfig<AuthVerifyRecoverCodeParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    AuthVerifyRecoverCodeParams,
    APIResponse<LoginResponse>,
    typeof authService.verifyRecoverCode,
    AjaxOptions,
    APIResponseClass<LoginResponseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof authService.verifyRecoverCode>) => (
      authService.verifyRecoverCode(...args)
    ),
    ResponseClassCreator: createAuthVerifyRecoverCodeObject,
    typeName: 'useAuthVerifyRecoverCode',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useAuthVerifyRecoverCode.mock = () => new Error('Mock has not been implemented!');
useAuthVerifyRecoverCode.getLastMockedValue = () => new Error('Mock has not been implemented!');
useAuthVerifyRecoverCode.createObject = createAuthVerifyRecoverCodeObject;
export class EmptyBaseService {
  protected baseURL = '';

  protected basePath = '';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }
}

export const emptyBaseService = new EmptyBaseService();
export interface FileDownloadUrlOutput {
	fileKey?: Nullable<string>;
	url?: Nullable<string>;
	fileName?: Nullable<string>;
}
export enum MedicalOrganizationStatus {
	ACTIVE = 'ACTIVE',
	TERMINATED = 'TERMINATED',
	TERMINATION_INITIATED = 'TERMINATION_INITIATED',
	DELETED = 'DELETED',
}
export enum ClinicStatus {
	PENDING = 'PENDING',
	ACTIVE = 'ACTIVE',
	HIATUS = 'HIATUS',
	SERVICE_ENDED = 'SERVICE_ENDED',
	TERMINATED = 'TERMINATED',
	TERMINATION_INITIATED = 'TERMINATION_INITIATED',
	DELETED = 'DELETED',
	priority = 'priority',
}
export interface LimitedClinic {
	id?: Nullable<string>;
	businessName?: Nullable<string>;
	alias?: Nullable<string>;
	orgNumber?: Nullable<string>;
	address?: Nullable<string>;
	address2?: Nullable<string>;
	state?: Nullable<string>;
	city?: Nullable<string>;
	timezone?: Nullable<string>;
	medicalOrgStatus?: Nullable<MedicalOrganizationStatus>;
	enableShareAccess?: Nullable<boolean>;
	isMyClinics?: Nullable<boolean>;
	sequenceNumber?: Nullable<string>;
	nickName?: Nullable<string>;
	clinicStatus?: Nullable<ClinicStatus>;
}
export interface BatchConsentResult extends BaseModel {
	ptDownloadFileOutput?: Nullable<FileDownloadUrlOutput>;
	medicalOrgId?: Nullable<string>;
	patientIdList?: Nullable<string[]>;
	sentAt?: Nullable<string>;
	medicalOrg?: Nullable<LimitedClinic>;
	medicalOrgIdsIn?: Nullable<InFilter<string>>;
}
export interface BatchSendConsentRequest {
	ptDownloadFileOutput?: Nullable<FileDownloadUrlOutput>;
	medicalOrgId?: Nullable<string>;
	patientIdList?: Nullable<string[]>;
}
export interface MultipartFile {
}
export interface UploadPatientListResult {
	fileDownloadUrlOutput?: Nullable<FileDownloadUrlOutput>;
	patientIdList?: Nullable<string[]>;
}
export class FileDownloadUrlOutputClass {
  protected readonly _value: FileDownloadUrlOutput;

	constructor(value: FileDownloadUrlOutput) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get fileKey() {
    return this._value.fileKey;
  }

  get fileKeyWithDefault() {
    return this._value.fileKey || '';
  }

  get url() {
    return this._value.url;
  }

  get urlWithDefault() {
    return this._value.url || '';
  }

  get fileName() {
    return this._value.fileName;
  }

  get fileNameWithDefault() {
    return this._value.fileName || '';
  }
}
export class UploadPatientListResultClass {
  protected readonly _value: UploadPatientListResult;

	constructor(value: UploadPatientListResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get fileDownloadUrlOutput() {
    return this._value.fileDownloadUrlOutput !== undefined && this._value.fileDownloadUrlOutput !== null ? new FileDownloadUrlOutputClass(this._value.fileDownloadUrlOutput) : this._value.fileDownloadUrlOutput;
  }

  get patientIdList() {
    return this._value.patientIdList;
  }

  get patientIdListWithDefault() {
    return this._value.patientIdList || [];
  }
}
export class LimitedClinicClass {
  protected readonly _value: LimitedClinic;

	constructor(value: LimitedClinic) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get businessName() {
    return this._value.businessName;
  }

  get businessNameWithDefault() {
    return this._value.businessName || '';
  }

  get alias() {
    return this._value.alias;
  }

  get aliasWithDefault() {
    return this._value.alias || '';
  }

  get orgNumber() {
    return this._value.orgNumber;
  }

  get orgNumberWithDefault() {
    return this._value.orgNumber || '';
  }

  get address() {
    return this._value.address;
  }

  get addressWithDefault() {
    return this._value.address || '';
  }

  get address2() {
    return this._value.address2;
  }

  get address2WithDefault() {
    return this._value.address2 || '';
  }

  get state() {
    return this._value.state;
  }

  get stateWithDefault() {
    return this._value.state || '';
  }

  get city() {
    return this._value.city;
  }

  get cityWithDefault() {
    return this._value.city || '';
  }

  get timezone() {
    return this._value.timezone;
  }

  get timezoneWithDefault() {
    return this._value.timezone || '';
  }

  get medicalOrgStatus() {
    return this._value.medicalOrgStatus;
  }

  medicalOrgStatusIsIn(values: MedicalOrganizationStatus[] = []) {
    return this._value.medicalOrgStatus && values.includes(this._value.medicalOrgStatus);
  }

  get enableShareAccess() {
    return this._value.enableShareAccess;
  }

  get enableShareAccessWithDefault() {
    return this._value.enableShareAccess || false;
  }

  get isMyClinics() {
    return this._value.isMyClinics;
  }

  get isMyClinicsWithDefault() {
    return this._value.isMyClinics || false;
  }

  get sequenceNumber() {
    return this._value.sequenceNumber;
  }

  get sequenceNumberWithDefault() {
    return this._value.sequenceNumber || '';
  }

  get nickName() {
    return this._value.nickName;
  }

  get nickNameWithDefault() {
    return this._value.nickName || '';
  }

  get clinicStatus() {
    return this._value.clinicStatus;
  }

  clinicStatusIsIn(values: ClinicStatus[] = []) {
    return this._value.clinicStatus && values.includes(this._value.clinicStatus);
  }
}
export class BatchConsentResultClass extends BaseModelClass {
  protected readonly _value: BatchConsentResult;

	constructor(value: BatchConsentResult) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get ptDownloadFileOutput() {
    return this._value.ptDownloadFileOutput !== undefined && this._value.ptDownloadFileOutput !== null ? new FileDownloadUrlOutputClass(this._value.ptDownloadFileOutput) : this._value.ptDownloadFileOutput;
  }

  get medicalOrgId() {
    return this._value.medicalOrgId;
  }

  get patientIdList() {
    return this._value.patientIdList;
  }

  get patientIdListWithDefault() {
    return this._value.patientIdList || [];
  }

  get sentAt() {
    return (this._value.sentAt !== undefined && this._value.sentAt !== null) ? dayjs(this._value.sentAt) : this._value.sentAt;
  }

  get medicalOrg() {
    return this._value.medicalOrg !== undefined && this._value.medicalOrg !== null ? new LimitedClinicClass(this._value.medicalOrg) : this._value.medicalOrg;
  }

  get medicalOrgIdsIn() {
    return this._value.medicalOrgIdsIn !== undefined && this._value.medicalOrgIdsIn !== null ? new InFilterClass(this._value.medicalOrgIdsIn) : this._value.medicalOrgIdsIn;
  }
}
export interface BatchSendConsentSendConsentFormPNParams {
	request: BatchSendConsentRequest;
}

export interface BatchSendConsentUploadPatientListParams {
	ptListFile: MultipartFile;
}

export interface BatchSendConsentBatchSendConsentResultListParams {
	searchRequest: SearchRequest<BatchConsentResult>;
}

export interface BatchSendConsentDownloadSignStatusListParams {
	fileInfo: FileDownloadUrlOutput;
}

export class BatchSendConsentService extends BaseController<BatchConsentResult, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/batch-send-consent';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public sendConsentFormPN({ baseUrl, params, headers }: RequestOption<BatchSendConsentSendConsentFormPNParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/send-consent-form-pn${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public uploadPatientList({ baseUrl, params, headers }: RequestOption<BatchSendConsentUploadPatientListParams>) {
		const queryList: QueryParam[] = [];
		if (params.ptListFile !== undefined && params.ptListFile !== null) {
			queryList.push({ name: 'ptListFile', value: params.ptListFile.toString() });
		}
		return axios.post<APIResponse<UploadPatientListResult>>(`${baseUrl || this.baseURL}${this.basePath}/upload-patient-list${getQueryStrings(queryList)}`, {}, { headers });
	}

	public batchSendConsentResultList({ baseUrl, params, headers }: RequestOption<BatchSendConsentBatchSendConsentResultListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<BatchConsentResult>>>(`${baseUrl || this.baseURL}${this.basePath}/list${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public downloadSignStatusList({ baseUrl, params, headers }: RequestOption<BatchSendConsentDownloadSignStatusListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/download-sign-status-list${getQueryStrings(queryList)}`, params.fileInfo, { headers });
	}
}

export const batchSendConsentService = new BatchSendConsentService();

export const createBatchSendConsentSendConsentFormPNObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useBatchSendConsentSendConsentFormPN = <RCN = undefined>(configs: MakeRequestHookConfig<BatchSendConsentSendConsentFormPNParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BatchSendConsentSendConsentFormPNParams,
    APIResponse<string>,
    typeof batchSendConsentService.sendConsentFormPN,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof batchSendConsentService.sendConsentFormPN>) => (
      batchSendConsentService.sendConsentFormPN(...args)
    ),
    ResponseClassCreator: createBatchSendConsentSendConsentFormPNObject,
    typeName: 'useBatchSendConsentSendConsentFormPN',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBatchSendConsentSendConsentFormPN.mock = () => new Error('Mock has not been implemented!');
useBatchSendConsentSendConsentFormPN.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBatchSendConsentSendConsentFormPN.createObject = createBatchSendConsentSendConsentFormPNObject;

export const createBatchSendConsentUploadPatientListObject = (value: APIResponse<UploadPatientListResult>) => new APIResponseClass<UploadPatientListResultClass>(value, (value: UploadPatientListResult) => new UploadPatientListResultClass(value));

export const useBatchSendConsentUploadPatientList = <RCN = undefined>(configs: MakeRequestHookConfig<BatchSendConsentUploadPatientListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BatchSendConsentUploadPatientListParams,
    APIResponse<UploadPatientListResult>,
    typeof batchSendConsentService.uploadPatientList,
    AjaxOptions,
    APIResponseClass<UploadPatientListResultClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof batchSendConsentService.uploadPatientList>) => (
      batchSendConsentService.uploadPatientList(...args)
    ),
    ResponseClassCreator: createBatchSendConsentUploadPatientListObject,
    typeName: 'useBatchSendConsentUploadPatientList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBatchSendConsentUploadPatientList.mock = () => new Error('Mock has not been implemented!');
useBatchSendConsentUploadPatientList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBatchSendConsentUploadPatientList.createObject = createBatchSendConsentUploadPatientListObject;

export const createBatchSendConsentBatchSendConsentResultListObject = (value: APIResponse<PageResponse<BatchConsentResult>>) => new APIResponseClass<PageResponseClass<BatchConsentResultClass>>(value, (value: PageResponse<BatchConsentResult>) => new PageResponseClass<BatchConsentResultClass>(value, (value: BatchConsentResult) => new BatchConsentResultClass(value)));

export const useBatchSendConsentBatchSendConsentResultList = <RCN = undefined>(configs: MakeRequestHookConfig<BatchSendConsentBatchSendConsentResultListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BatchSendConsentBatchSendConsentResultListParams,
    APIResponse<PageResponse<BatchConsentResult>>,
    typeof batchSendConsentService.batchSendConsentResultList,
    AjaxOptions,
    APIResponseClass<PageResponseClass<BatchConsentResultClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof batchSendConsentService.batchSendConsentResultList>) => (
      batchSendConsentService.batchSendConsentResultList(...args)
    ),
    ResponseClassCreator: createBatchSendConsentBatchSendConsentResultListObject,
    typeName: 'useBatchSendConsentBatchSendConsentResultList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBatchSendConsentBatchSendConsentResultList.mock = () => new Error('Mock has not been implemented!');
useBatchSendConsentBatchSendConsentResultList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBatchSendConsentBatchSendConsentResultList.createObject = createBatchSendConsentBatchSendConsentResultListObject;

export const createBatchSendConsentDownloadSignStatusListObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useBatchSendConsentDownloadSignStatusList = <RCN = undefined>(configs: MakeRequestHookConfig<BatchSendConsentDownloadSignStatusListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BatchSendConsentDownloadSignStatusListParams,
    APIResponse<string>,
    typeof batchSendConsentService.downloadSignStatusList,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof batchSendConsentService.downloadSignStatusList>) => (
      batchSendConsentService.downloadSignStatusList(...args)
    ),
    ResponseClassCreator: createBatchSendConsentDownloadSignStatusListObject,
    typeName: 'useBatchSendConsentDownloadSignStatusList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBatchSendConsentDownloadSignStatusList.mock = () => new Error('Mock has not been implemented!');
useBatchSendConsentDownloadSignStatusList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBatchSendConsentDownloadSignStatusList.createObject = createBatchSendConsentDownloadSignStatusListObject;


export const createBatchSendConsentGetObject = (value: APIResponse<BatchConsentResult>) => new APIResponseClass<BatchConsentResultClass>(value, (value: BatchConsentResult) => new BatchConsentResultClass(value));
export const useBatchSendConsentGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<BatchConsentResult>,
    // @ts-ignore
    typeof batchSendConsentService.get,
    GetOptions,
    APIResponseClass<BatchConsentResultClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof batchSendConsentService.get>) => (
      batchSendConsentService.get(...args)
    ),
    ResponseClassCreator: createBatchSendConsentGetObject,
    typeName: 'useBatchSendConsentGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBatchSendConsentGet.mock = () => new Error('Mock has not been implemented!');
useBatchSendConsentGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBatchSendConsentGet.createObject = createBatchSendConsentGetObject;

export const createBatchSendConsentUpdateObject = (value: APIResponse<BatchConsentResult>) => new APIResponseClass<BatchConsentResultClass>(value, (value: BatchConsentResult) => new BatchConsentResultClass(value));
export const useBatchSendConsentUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, BatchConsentResult>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, BatchConsentResult>,
    APIResponse<BatchConsentResult>,
    // @ts-ignore
    typeof batchSendConsentService.update,
    AjaxOptions,
    APIResponseClass<BatchConsentResultClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof batchSendConsentService.update>) => (
      batchSendConsentService.update(...args)
    ),
    ResponseClassCreator: createBatchSendConsentUpdateObject,
    typeName: 'useBatchSendConsentUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBatchSendConsentUpdate.mock = () => new Error('Mock has not been implemented!');
useBatchSendConsentUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBatchSendConsentUpdate.createObject = createBatchSendConsentUpdateObject;

export const createBatchSendConsentInsertObject = (value: APIResponse<BatchConsentResult>) => new APIResponseClass<BatchConsentResultClass>(value, (value: BatchConsentResult) => new BatchConsentResultClass(value));
export const useBatchSendConsentInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<BatchConsentResult>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<BatchConsentResult>,
    APIResponse<BatchConsentResult>,
    // @ts-ignore
    typeof batchSendConsentService.insert,
    AjaxOptions,
    APIResponseClass<BatchConsentResultClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof batchSendConsentService.insert>) => (
      batchSendConsentService.insert(...args)
    ),
    ResponseClassCreator: createBatchSendConsentInsertObject,
    typeName: 'useBatchSendConsentInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBatchSendConsentInsert.mock = () => new Error('Mock has not been implemented!');
useBatchSendConsentInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBatchSendConsentInsert.createObject = createBatchSendConsentInsertObject;

export const createBatchSendConsentDeleteObject = (value: APIResponse<BatchConsentResult>) => new APIResponseClass<BatchConsentResultClass>(value, (value: BatchConsentResult) => new BatchConsentResultClass(value));
export const useBatchSendConsentDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<BatchConsentResult>,
    // @ts-ignore
    typeof batchSendConsentService.delete,
    AjaxOptions,
    APIResponseClass<BatchConsentResultClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof batchSendConsentService.delete>) => (
      batchSendConsentService.delete(...args)
    ),
    ResponseClassCreator: createBatchSendConsentDeleteObject,
    typeName: 'useBatchSendConsentDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBatchSendConsentDelete.mock = () => new Error('Mock has not been implemented!');
useBatchSendConsentDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBatchSendConsentDelete.createObject = createBatchSendConsentDeleteObject;

export const createBatchSendConsentSearchObject = (value: APIResponse<PageResponse<BatchConsentResult>>) => new APIResponseClass<PageResponseClass<BatchConsentResultClass>>(value, (value: PageResponse<BatchConsentResult>) => new PageResponseClass<BatchConsentResultClass>(value, (value: BatchConsentResult) => new BatchConsentResultClass(value)));
export const useBatchSendConsentSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<BatchConsentResult>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<BatchConsentResult>,
    APIResponse<PageResponse<BatchConsentResult>>,
    // @ts-ignore
    typeof batchSendConsentService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<BatchConsentResultClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof batchSendConsentService.search>) => (
      batchSendConsentService.search(...args)
    ),
    ResponseClassCreator: createBatchSendConsentSearchObject,
    typeName: 'useBatchSendConsentSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBatchSendConsentSearch.mock = () => new Error('Mock has not been implemented!');
useBatchSendConsentSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBatchSendConsentSearch.createObject = createBatchSendConsentSearchObject;

export interface BillableMonthlySummary extends BaseModel {
	memberId?: Nullable<string>;
	clinicId?: Nullable<string>;
	monthOfYear?: Nullable<number>;
	totalTime?: Nullable<number>;
	timeEligible?: Nullable<boolean>;
	measurementDays?: Nullable<number>;
	measurementEligible?: Nullable<boolean>;
	memberIdIn?: Nullable<InFilter<string>>;
	monthOfYearRange?: Nullable<RangeFilter<number>>;
}
export class BillableMonthlySummaryClass extends BaseModelClass {
  protected readonly _value: BillableMonthlySummary;

	constructor(value: BillableMonthlySummary) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get clinicId() {
    return this._value.clinicId;
  }

  get monthOfYear() {
    return this._value.monthOfYear;
  }

  get monthOfYearWithDefault() {
    return this._value.monthOfYear || 0;
  }

  get totalTime() {
    return this._value.totalTime;
  }

  get totalTimeWithDefault() {
    return this._value.totalTime || 0;
  }

  get timeEligible() {
    return this._value.timeEligible;
  }

  get timeEligibleWithDefault() {
    return this._value.timeEligible || false;
  }

  get measurementDays() {
    return this._value.measurementDays;
  }

  get measurementDaysWithDefault() {
    return this._value.measurementDays || 0;
  }

  get measurementEligible() {
    return this._value.measurementEligible;
  }

  get measurementEligibleWithDefault() {
    return this._value.measurementEligible || false;
  }

  get memberIdIn() {
    return this._value.memberIdIn !== undefined && this._value.memberIdIn !== null ? new InFilterClass(this._value.memberIdIn) : this._value.memberIdIn;
  }

  get monthOfYearRange() {
    return this._value.monthOfYearRange !== undefined && this._value.monthOfYearRange !== null ? new RangeFilterClass(this._value.monthOfYearRange) : this._value.monthOfYearRange;
  }
}
export interface EventItem {
	eventId?: Nullable<string>;
	intervention?: Nullable<string>;
}
export interface BillableEvent {
	providerId?: Nullable<string>;
	accumulatedTime?: Nullable<number>;
	startTime?: Nullable<string>;
	endTime?: Nullable<string>;
	interventions?: Nullable<EventItem[]>;
}
export interface BillableMonthlyTime extends BaseModel {
	memberId?: Nullable<string>;
	organizationId?: Nullable<string>;
	monthOfYear?: Nullable<number>;
	totalTime?: Nullable<number>;
	billableTime?: Nullable<number>;
	billableTimeTarget?: Nullable<number>;
	billableTimeQualified?: Nullable<boolean>;
	measurementDays?: Nullable<number>;
	measurementDaysTarget?: Nullable<number>;
	measurementDaysQualified?: Nullable<boolean>;
	events?: Nullable<BillableEvent[]>;
	memberIdIn?: Nullable<InFilter<string>>;
	monthOfYearRange?: Nullable<RangeFilter<number>>;
}
export interface CurrentMonthlyTime {
	memberId?: Nullable<string>;
	confirmedBillableMinutes?: Nullable<number>;
	processingBillableMinutes?: Nullable<number>;
	billableMinutesTarget?: Nullable<number>;
	timeEligible?: Nullable<boolean>;
	measurementDays?: Nullable<number>;
	measurementDaysTarget?: Nullable<number>;
	measurementEligible?: Nullable<boolean>;
}
export class CurrentMonthlyTimeClass {
  protected readonly _value: CurrentMonthlyTime;

	constructor(value: CurrentMonthlyTime) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get confirmedBillableMinutes() {
    return this._value.confirmedBillableMinutes;
  }

  get confirmedBillableMinutesWithDefault() {
    return this._value.confirmedBillableMinutes || 0;
  }

  get processingBillableMinutes() {
    return this._value.processingBillableMinutes;
  }

  get processingBillableMinutesWithDefault() {
    return this._value.processingBillableMinutes || 0;
  }

  get billableMinutesTarget() {
    return this._value.billableMinutesTarget;
  }

  get billableMinutesTargetWithDefault() {
    return this._value.billableMinutesTarget || 0;
  }

  get timeEligible() {
    return this._value.timeEligible;
  }

  get timeEligibleWithDefault() {
    return this._value.timeEligible || false;
  }

  get measurementDays() {
    return this._value.measurementDays;
  }

  get measurementDaysWithDefault() {
    return this._value.measurementDays || 0;
  }

  get measurementDaysTarget() {
    return this._value.measurementDaysTarget;
  }

  get measurementDaysTargetWithDefault() {
    return this._value.measurementDaysTarget || 0;
  }

  get measurementEligible() {
    return this._value.measurementEligible;
  }

  get measurementEligibleWithDefault() {
    return this._value.measurementEligible || false;
  }
}
export interface BillableMonthlySummarySearchBillableHistoryParams {
	filter: SearchRequest<BillableMonthlySummary>;
}

export interface BillableMonthlySummaryQueryCurrentMonthParams {
	param: BillableMonthlyTime;
}

export class BillableMonthlySummaryService extends BaseController<BillableMonthlySummary, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/billable-monthly-time';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public searchBillableHistory({ baseUrl, params, headers }: RequestOption<BillableMonthlySummarySearchBillableHistoryParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<BillableMonthlySummary>>>(`${baseUrl || this.baseURL}${this.basePath}/search-history${getQueryStrings(queryList)}`, params.filter, { headers });
	}

	public queryCurrentMonth({ baseUrl, params, headers }: RequestOption<BillableMonthlySummaryQueryCurrentMonthParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<CurrentMonthlyTime>>(`${baseUrl || this.baseURL}${this.basePath}/current${getQueryStrings(queryList)}`, params.param, { headers });
	}
}

export const billableMonthlySummaryService = new BillableMonthlySummaryService();

export const createBillableMonthlySummarySearchBillableHistoryObject = (value: APIResponse<PageResponse<BillableMonthlySummary>>) => new APIResponseClass<PageResponseClass<BillableMonthlySummaryClass>>(value, (value: PageResponse<BillableMonthlySummary>) => new PageResponseClass<BillableMonthlySummaryClass>(value, (value: BillableMonthlySummary) => new BillableMonthlySummaryClass(value)));

export const useBillableMonthlySummarySearchBillableHistory = <RCN = undefined>(configs: MakeRequestHookConfig<BillableMonthlySummarySearchBillableHistoryParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillableMonthlySummarySearchBillableHistoryParams,
    APIResponse<PageResponse<BillableMonthlySummary>>,
    typeof billableMonthlySummaryService.searchBillableHistory,
    AjaxOptions,
    APIResponseClass<PageResponseClass<BillableMonthlySummaryClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableMonthlySummaryService.searchBillableHistory>) => (
      billableMonthlySummaryService.searchBillableHistory(...args)
    ),
    ResponseClassCreator: createBillableMonthlySummarySearchBillableHistoryObject,
    typeName: 'useBillableMonthlySummarySearchBillableHistory',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillableMonthlySummarySearchBillableHistory.mock = () => new Error('Mock has not been implemented!');
useBillableMonthlySummarySearchBillableHistory.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableMonthlySummarySearchBillableHistory.createObject = createBillableMonthlySummarySearchBillableHistoryObject;

export const createBillableMonthlySummaryQueryCurrentMonthObject = (value: APIResponse<CurrentMonthlyTime>) => new APIResponseClass<CurrentMonthlyTimeClass>(value, (value: CurrentMonthlyTime) => new CurrentMonthlyTimeClass(value));

export const useBillableMonthlySummaryQueryCurrentMonth = <RCN = undefined>(configs: MakeRequestHookConfig<BillableMonthlySummaryQueryCurrentMonthParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillableMonthlySummaryQueryCurrentMonthParams,
    APIResponse<CurrentMonthlyTime>,
    typeof billableMonthlySummaryService.queryCurrentMonth,
    AjaxOptions,
    APIResponseClass<CurrentMonthlyTimeClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableMonthlySummaryService.queryCurrentMonth>) => (
      billableMonthlySummaryService.queryCurrentMonth(...args)
    ),
    ResponseClassCreator: createBillableMonthlySummaryQueryCurrentMonthObject,
    typeName: 'useBillableMonthlySummaryQueryCurrentMonth',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillableMonthlySummaryQueryCurrentMonth.mock = () => new Error('Mock has not been implemented!');
useBillableMonthlySummaryQueryCurrentMonth.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableMonthlySummaryQueryCurrentMonth.createObject = createBillableMonthlySummaryQueryCurrentMonthObject;


export const createBillableMonthlySummaryGetObject = (value: APIResponse<BillableMonthlySummary>) => new APIResponseClass<BillableMonthlySummaryClass>(value, (value: BillableMonthlySummary) => new BillableMonthlySummaryClass(value));
export const useBillableMonthlySummaryGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<BillableMonthlySummary>,
    // @ts-ignore
    typeof billableMonthlySummaryService.get,
    GetOptions,
    APIResponseClass<BillableMonthlySummaryClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof billableMonthlySummaryService.get>) => (
      billableMonthlySummaryService.get(...args)
    ),
    ResponseClassCreator: createBillableMonthlySummaryGetObject,
    typeName: 'useBillableMonthlySummaryGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBillableMonthlySummaryGet.mock = () => new Error('Mock has not been implemented!');
useBillableMonthlySummaryGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableMonthlySummaryGet.createObject = createBillableMonthlySummaryGetObject;

export const createBillableMonthlySummaryUpdateObject = (value: APIResponse<BillableMonthlySummary>) => new APIResponseClass<BillableMonthlySummaryClass>(value, (value: BillableMonthlySummary) => new BillableMonthlySummaryClass(value));
export const useBillableMonthlySummaryUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, BillableMonthlySummary>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, BillableMonthlySummary>,
    APIResponse<BillableMonthlySummary>,
    // @ts-ignore
    typeof billableMonthlySummaryService.update,
    AjaxOptions,
    APIResponseClass<BillableMonthlySummaryClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableMonthlySummaryService.update>) => (
      billableMonthlySummaryService.update(...args)
    ),
    ResponseClassCreator: createBillableMonthlySummaryUpdateObject,
    typeName: 'useBillableMonthlySummaryUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBillableMonthlySummaryUpdate.mock = () => new Error('Mock has not been implemented!');
useBillableMonthlySummaryUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableMonthlySummaryUpdate.createObject = createBillableMonthlySummaryUpdateObject;

export const createBillableMonthlySummaryInsertObject = (value: APIResponse<BillableMonthlySummary>) => new APIResponseClass<BillableMonthlySummaryClass>(value, (value: BillableMonthlySummary) => new BillableMonthlySummaryClass(value));
export const useBillableMonthlySummaryInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<BillableMonthlySummary>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<BillableMonthlySummary>,
    APIResponse<BillableMonthlySummary>,
    // @ts-ignore
    typeof billableMonthlySummaryService.insert,
    AjaxOptions,
    APIResponseClass<BillableMonthlySummaryClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableMonthlySummaryService.insert>) => (
      billableMonthlySummaryService.insert(...args)
    ),
    ResponseClassCreator: createBillableMonthlySummaryInsertObject,
    typeName: 'useBillableMonthlySummaryInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBillableMonthlySummaryInsert.mock = () => new Error('Mock has not been implemented!');
useBillableMonthlySummaryInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableMonthlySummaryInsert.createObject = createBillableMonthlySummaryInsertObject;

export const createBillableMonthlySummaryDeleteObject = (value: APIResponse<BillableMonthlySummary>) => new APIResponseClass<BillableMonthlySummaryClass>(value, (value: BillableMonthlySummary) => new BillableMonthlySummaryClass(value));
export const useBillableMonthlySummaryDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<BillableMonthlySummary>,
    // @ts-ignore
    typeof billableMonthlySummaryService.delete,
    AjaxOptions,
    APIResponseClass<BillableMonthlySummaryClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableMonthlySummaryService.delete>) => (
      billableMonthlySummaryService.delete(...args)
    ),
    ResponseClassCreator: createBillableMonthlySummaryDeleteObject,
    typeName: 'useBillableMonthlySummaryDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBillableMonthlySummaryDelete.mock = () => new Error('Mock has not been implemented!');
useBillableMonthlySummaryDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableMonthlySummaryDelete.createObject = createBillableMonthlySummaryDeleteObject;

export const createBillableMonthlySummarySearchObject = (value: APIResponse<PageResponse<BillableMonthlySummary>>) => new APIResponseClass<PageResponseClass<BillableMonthlySummaryClass>>(value, (value: PageResponse<BillableMonthlySummary>) => new PageResponseClass<BillableMonthlySummaryClass>(value, (value: BillableMonthlySummary) => new BillableMonthlySummaryClass(value)));
export const useBillableMonthlySummarySearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<BillableMonthlySummary>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<BillableMonthlySummary>,
    APIResponse<PageResponse<BillableMonthlySummary>>,
    // @ts-ignore
    typeof billableMonthlySummaryService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<BillableMonthlySummaryClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableMonthlySummaryService.search>) => (
      billableMonthlySummaryService.search(...args)
    ),
    ResponseClassCreator: createBillableMonthlySummarySearchObject,
    typeName: 'useBillableMonthlySummarySearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBillableMonthlySummarySearch.mock = () => new Error('Mock has not been implemented!');
useBillableMonthlySummarySearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableMonthlySummarySearch.createObject = createBillableMonthlySummarySearchObject;

export enum BillableEventStatusEnum {
	PROCESSING = 'PROCESSING',
	REVIEW_REQUIRED = 'REVIEW_REQUIRED',
	CONFIRMED = 'CONFIRMED',
	MERGED_DROP = 'MERGED_DROP',
	SYSTEM_DROP = 'SYSTEM_DROP',
	REVIEW_EXPIRED = 'REVIEW_EXPIRED',
}
export enum TrackingEventRoleEnumType {
	RD_HC = 'RD_HC',
	CA = 'CA',
	MD = 'MD',
	MA = 'MA',
	UNKNOWN = 'UNKNOWN',
}
export enum BillableSegmentSourceEnum {
	MANUAL = 'MANUAL',
	TRACKING = 'TRACKING',
	MERGED = 'MERGED',
}
export enum GenderEnum {
	F = 'F',
	M = 'M',
}
export interface LimitedPatient {
	id?: Nullable<string>;
	firstName?: Nullable<string>;
	lastName?: Nullable<string>;
	gender?: Nullable<GenderEnum>;
	birthday?: Nullable<string>;
	timezone?: Nullable<string>;
	clinicId?: Nullable<string>;
}
export interface BillableTimeSegment extends BaseModel {
	memberId?: Nullable<string>;
	viewerId?: Nullable<string>;
	clinicId?: Nullable<string>;
	sessionId?: Nullable<string>;
	uuid?: Nullable<string>;
	totalTime?: Nullable<number>;
	billableTime?: Nullable<number>;
	status?: Nullable<BillableEventStatusEnum>;
	roleType?: Nullable<TrackingEventRoleEnumType>;
	startTime?: Nullable<number>;
	endTime?: Nullable<number>;
	activity?: Nullable<string[]>;
	intervention?: Nullable<string[]>;
	component?: Nullable<string[]>;
	programs?: Nullable<string>;
	monthOfYear?: Nullable<number>;
	source?: Nullable<BillableSegmentSourceEnum>;
	provider?: Nullable<LimitedUser>;
	patient?: Nullable<LimitedPatient>;
	monthOfYearRange?: Nullable<RangeFilter<number>>;
	startTimeRange?: Nullable<RangeFilter<number>>;
	statusIn?: Nullable<InFilter<BillableEventStatusEnum>>;
	providerIdIn?: Nullable<InFilter<string>>;
	memberIdIn?: Nullable<InFilter<string>>;
}
export class LimitedPatientClass {
  protected readonly _value: LimitedPatient;

	constructor(value: LimitedPatient) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get firstName() {
    return this._value.firstName;
  }

  get firstNameWithDefault() {
    return this._value.firstName || '';
  }

  get lastName() {
    return this._value.lastName;
  }

  get lastNameWithDefault() {
    return this._value.lastName || '';
  }

  get gender() {
    return this._value.gender;
  }

  genderIsIn(values: GenderEnum[] = []) {
    return this._value.gender && values.includes(this._value.gender);
  }

  get birthday() {
    return this._value.birthday;
  }

  get birthdayWithDefault() {
    return this._value.birthday || '';
  }

  get timezone() {
    return this._value.timezone;
  }

  get timezoneWithDefault() {
    return this._value.timezone || '';
  }

  get clinicId() {
    return this._value.clinicId;
  }
}
export class BillableTimeSegmentClass extends BaseModelClass {
  protected readonly _value: BillableTimeSegment;

	constructor(value: BillableTimeSegment) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get viewerId() {
    return this._value.viewerId;
  }

  get clinicId() {
    return this._value.clinicId;
  }

  get sessionId() {
    return this._value.sessionId;
  }

  get sessionIdWithDefault() {
    return this._value.sessionId || '';
  }

  get uuid() {
    return this._value.uuid;
  }

  get uuidWithDefault() {
    return this._value.uuid || '';
  }

  get totalTime() {
    return this._value.totalTime;
  }

  get totalTimeWithDefault() {
    return this._value.totalTime || 0;
  }

  get billableTime() {
    return this._value.billableTime;
  }

  get billableTimeWithDefault() {
    return this._value.billableTime || 0;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: BillableEventStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get roleType() {
    return this._value.roleType;
  }

  roleTypeIsIn(values: TrackingEventRoleEnumType[] = []) {
    return this._value.roleType && values.includes(this._value.roleType);
  }

  get startTime() {
    return this._value.startTime;
  }

  get startTimeWithDefault() {
    return this._value.startTime || 0;
  }

  get endTime() {
    return this._value.endTime;
  }

  get endTimeWithDefault() {
    return this._value.endTime || 0;
  }

  get activity() {
    return this._value.activity;
  }

  get activityWithDefault() {
    return this._value.activity || [];
  }

  get intervention() {
    return this._value.intervention;
  }

  get interventionWithDefault() {
    return this._value.intervention || [];
  }

  get component() {
    return this._value.component;
  }

  get componentWithDefault() {
    return this._value.component || [];
  }

  get programs() {
    return this._value.programs;
  }

  get programsWithDefault() {
    return this._value.programs || '';
  }

  get monthOfYear() {
    return this._value.monthOfYear;
  }

  get monthOfYearWithDefault() {
    return this._value.monthOfYear || 0;
  }

  get source() {
    return this._value.source;
  }

  sourceIsIn(values: BillableSegmentSourceEnum[] = []) {
    return this._value.source && values.includes(this._value.source);
  }

  get provider() {
    return this._value.provider !== undefined && this._value.provider !== null ? new LimitedUserClass(this._value.provider) : this._value.provider;
  }

  get patient() {
    return this._value.patient !== undefined && this._value.patient !== null ? new LimitedPatientClass(this._value.patient) : this._value.patient;
  }

  get monthOfYearRange() {
    return this._value.monthOfYearRange !== undefined && this._value.monthOfYearRange !== null ? new RangeFilterClass(this._value.monthOfYearRange) : this._value.monthOfYearRange;
  }

  get startTimeRange() {
    return this._value.startTimeRange !== undefined && this._value.startTimeRange !== null ? new RangeFilterClass(this._value.startTimeRange) : this._value.startTimeRange;
  }

  get statusIn() {
    return this._value.statusIn !== undefined && this._value.statusIn !== null ? new InFilterClass(this._value.statusIn) : this._value.statusIn;
  }

  get providerIdIn() {
    return this._value.providerIdIn !== undefined && this._value.providerIdIn !== null ? new InFilterClass(this._value.providerIdIn) : this._value.providerIdIn;
  }

  get memberIdIn() {
    return this._value.memberIdIn !== undefined && this._value.memberIdIn !== null ? new InFilterClass(this._value.memberIdIn) : this._value.memberIdIn;
  }
}
export interface TimeSpentRequest {
	viewerId?: Nullable<string>;
	startTime?: Nullable<number>;
	endTime?: Nullable<number>;
}
export interface DailyTimeSpent {
	billableMinutes?: Nullable<number>;
	totalSpentMinutes?: Nullable<number>;
}
export class DailyTimeSpentClass {
  protected readonly _value: DailyTimeSpent;

	constructor(value: DailyTimeSpent) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get billableMinutes() {
    return this._value.billableMinutes;
  }

  get billableMinutesWithDefault() {
    return this._value.billableMinutes || 0;
  }

  get totalSpentMinutes() {
    return this._value.totalSpentMinutes;
  }

  get totalSpentMinutesWithDefault() {
    return this._value.totalSpentMinutes || 0;
  }
}
export interface BillableTimeSegmentInsertParams {
	param: BillableTimeSegment;
}

export interface BillableTimeSegmentSearchParams {
	searchRequest: SearchRequest<BillableTimeSegment>;
}

export interface BillableTimeSegmentQueryDailyTimeSpentParams {
	request: TimeSpentRequest;
}

export interface BillableTimeSegmentSearchViewerParams {
	searchRequest: SearchRequest<BillableTimeSegment>;
}

export class BillableTimeSegmentService extends BaseController<BillableTimeSegment, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/billable-time';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public insert({ baseUrl, params, headers }: RequestOption<BillableTimeSegmentInsertParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<BillableTimeSegment>>(`${baseUrl || this.baseURL}${this.basePath}${getQueryStrings(queryList)}`, params.param, { headers });
	}

	public search({ baseUrl, params, headers }: RequestOption<BillableTimeSegmentSearchParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<BillableTimeSegment>>>(`${baseUrl || this.baseURL}${this.basePath}/search${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public queryDailyTimeSpent({ baseUrl, params, headers }: RequestOption<BillableTimeSegmentQueryDailyTimeSpentParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<DailyTimeSpent>>(`${baseUrl || this.baseURL}${this.basePath}/daily${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public searchViewer({ baseUrl, params, headers }: RequestOption<BillableTimeSegmentSearchViewerParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<BillableTimeSegment>>>(`${baseUrl || this.baseURL}${this.basePath}/viewer${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}
}

export const billableTimeSegmentService = new BillableTimeSegmentService();

export const createBillableTimeSegmentInsertObject = (value: APIResponse<BillableTimeSegment>) => new APIResponseClass<BillableTimeSegmentClass>(value, (value: BillableTimeSegment) => new BillableTimeSegmentClass(value));

export const useBillableTimeSegmentInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BillableTimeSegmentInsertParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillableTimeSegmentInsertParams,
    APIResponse<BillableTimeSegment>,
    typeof billableTimeSegmentService.insert,
    AjaxOptions,
    APIResponseClass<BillableTimeSegmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableTimeSegmentService.insert>) => (
      billableTimeSegmentService.insert(...args)
    ),
    ResponseClassCreator: createBillableTimeSegmentInsertObject,
    typeName: 'useBillableTimeSegmentInsert',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillableTimeSegmentInsert.mock = () => new Error('Mock has not been implemented!');
useBillableTimeSegmentInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableTimeSegmentInsert.createObject = createBillableTimeSegmentInsertObject;

export const createBillableTimeSegmentSearchObject = (value: APIResponse<PageResponse<BillableTimeSegment>>) => new APIResponseClass<PageResponseClass<BillableTimeSegmentClass>>(value, (value: PageResponse<BillableTimeSegment>) => new PageResponseClass<BillableTimeSegmentClass>(value, (value: BillableTimeSegment) => new BillableTimeSegmentClass(value)));

export const useBillableTimeSegmentSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BillableTimeSegmentSearchParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillableTimeSegmentSearchParams,
    APIResponse<PageResponse<BillableTimeSegment>>,
    typeof billableTimeSegmentService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<BillableTimeSegmentClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableTimeSegmentService.search>) => (
      billableTimeSegmentService.search(...args)
    ),
    ResponseClassCreator: createBillableTimeSegmentSearchObject,
    typeName: 'useBillableTimeSegmentSearch',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillableTimeSegmentSearch.mock = () => new Error('Mock has not been implemented!');
useBillableTimeSegmentSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableTimeSegmentSearch.createObject = createBillableTimeSegmentSearchObject;

export const createBillableTimeSegmentQueryDailyTimeSpentObject = (value: APIResponse<DailyTimeSpent>) => new APIResponseClass<DailyTimeSpentClass>(value, (value: DailyTimeSpent) => new DailyTimeSpentClass(value));

export const useBillableTimeSegmentQueryDailyTimeSpent = <RCN = undefined>(configs: MakeRequestHookConfig<BillableTimeSegmentQueryDailyTimeSpentParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillableTimeSegmentQueryDailyTimeSpentParams,
    APIResponse<DailyTimeSpent>,
    typeof billableTimeSegmentService.queryDailyTimeSpent,
    AjaxOptions,
    APIResponseClass<DailyTimeSpentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableTimeSegmentService.queryDailyTimeSpent>) => (
      billableTimeSegmentService.queryDailyTimeSpent(...args)
    ),
    ResponseClassCreator: createBillableTimeSegmentQueryDailyTimeSpentObject,
    typeName: 'useBillableTimeSegmentQueryDailyTimeSpent',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillableTimeSegmentQueryDailyTimeSpent.mock = () => new Error('Mock has not been implemented!');
useBillableTimeSegmentQueryDailyTimeSpent.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableTimeSegmentQueryDailyTimeSpent.createObject = createBillableTimeSegmentQueryDailyTimeSpentObject;

export const createBillableTimeSegmentSearchViewerObject = (value: APIResponse<PageResponse<BillableTimeSegment>>) => new APIResponseClass<PageResponseClass<BillableTimeSegmentClass>>(value, (value: PageResponse<BillableTimeSegment>) => new PageResponseClass<BillableTimeSegmentClass>(value, (value: BillableTimeSegment) => new BillableTimeSegmentClass(value)));

export const useBillableTimeSegmentSearchViewer = <RCN = undefined>(configs: MakeRequestHookConfig<BillableTimeSegmentSearchViewerParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillableTimeSegmentSearchViewerParams,
    APIResponse<PageResponse<BillableTimeSegment>>,
    typeof billableTimeSegmentService.searchViewer,
    AjaxOptions,
    APIResponseClass<PageResponseClass<BillableTimeSegmentClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableTimeSegmentService.searchViewer>) => (
      billableTimeSegmentService.searchViewer(...args)
    ),
    ResponseClassCreator: createBillableTimeSegmentSearchViewerObject,
    typeName: 'useBillableTimeSegmentSearchViewer',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillableTimeSegmentSearchViewer.mock = () => new Error('Mock has not been implemented!');
useBillableTimeSegmentSearchViewer.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableTimeSegmentSearchViewer.createObject = createBillableTimeSegmentSearchViewerObject;


export const createBillableTimeSegmentGetObject = (value: APIResponse<BillableTimeSegment>) => new APIResponseClass<BillableTimeSegmentClass>(value, (value: BillableTimeSegment) => new BillableTimeSegmentClass(value));
export const useBillableTimeSegmentGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<BillableTimeSegment>,
    // @ts-ignore
    typeof billableTimeSegmentService.get,
    GetOptions,
    APIResponseClass<BillableTimeSegmentClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof billableTimeSegmentService.get>) => (
      billableTimeSegmentService.get(...args)
    ),
    ResponseClassCreator: createBillableTimeSegmentGetObject,
    typeName: 'useBillableTimeSegmentGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBillableTimeSegmentGet.mock = () => new Error('Mock has not been implemented!');
useBillableTimeSegmentGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableTimeSegmentGet.createObject = createBillableTimeSegmentGetObject;

export const createBillableTimeSegmentUpdateObject = (value: APIResponse<BillableTimeSegment>) => new APIResponseClass<BillableTimeSegmentClass>(value, (value: BillableTimeSegment) => new BillableTimeSegmentClass(value));
export const useBillableTimeSegmentUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, BillableTimeSegment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, BillableTimeSegment>,
    APIResponse<BillableTimeSegment>,
    // @ts-ignore
    typeof billableTimeSegmentService.update,
    AjaxOptions,
    APIResponseClass<BillableTimeSegmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableTimeSegmentService.update>) => (
      billableTimeSegmentService.update(...args)
    ),
    ResponseClassCreator: createBillableTimeSegmentUpdateObject,
    typeName: 'useBillableTimeSegmentUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBillableTimeSegmentUpdate.mock = () => new Error('Mock has not been implemented!');
useBillableTimeSegmentUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableTimeSegmentUpdate.createObject = createBillableTimeSegmentUpdateObject;

export const createBillableTimeSegmentDeleteObject = (value: APIResponse<BillableTimeSegment>) => new APIResponseClass<BillableTimeSegmentClass>(value, (value: BillableTimeSegment) => new BillableTimeSegmentClass(value));
export const useBillableTimeSegmentDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<BillableTimeSegment>,
    // @ts-ignore
    typeof billableTimeSegmentService.delete,
    AjaxOptions,
    APIResponseClass<BillableTimeSegmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableTimeSegmentService.delete>) => (
      billableTimeSegmentService.delete(...args)
    ),
    ResponseClassCreator: createBillableTimeSegmentDeleteObject,
    typeName: 'useBillableTimeSegmentDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBillableTimeSegmentDelete.mock = () => new Error('Mock has not been implemented!');
useBillableTimeSegmentDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableTimeSegmentDelete.createObject = createBillableTimeSegmentDeleteObject;

export enum BillableProcessStatusEnum {
	PROCESSING = 'PROCESSING',
	REVIEW_REQUIRED = 'REVIEW_REQUIRED',
	CONFIRMED = 'CONFIRMED',
	REVIEW_EXPIRED = 'REVIEW_EXPIRED',
}
export interface BillableReviewSegment extends BaseModel {
	segmentId?: Nullable<string>;
	viewerId?: Nullable<string>;
	startTime?: Nullable<number>;
	status?: Nullable<BillableProcessStatusEnum>;
	billableTime?: Nullable<number>;
	correctedBillableTime?: Nullable<number>;
	comment?: Nullable<string>;
}
export interface BillableTimeReviewRequest {
	startTime: number;
	endTime: number;
	clinicIds?: Nullable<string[]>;
	statuses?: Nullable<BillableProcessStatusEnum[]>;
}
export interface BillableTimeReviewRecord {
	id?: Nullable<string>;
	memberId?: Nullable<string>;
	memberName?: Nullable<string>;
	medicalOrganization?: Nullable<string>;
	medicalOrgNumber?: Nullable<string>;
	startTime?: Nullable<number>;
	activity?: Nullable<string[]>;
	originBillableTime?: Nullable<number>;
	correctedBillableTime?: Nullable<number>;
	totalTime?: Nullable<number>;
	comment?: Nullable<string>;
	reviewerName?: Nullable<string>;
	status?: Nullable<BillableProcessStatusEnum>;
}
export class BillableTimeReviewRecordClass {
  protected readonly _value: BillableTimeReviewRecord;

	constructor(value: BillableTimeReviewRecord) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get memberId() {
    return this._value.memberId;
  }

  get memberIdWithDefault() {
    return this._value.memberId || '';
  }

  get memberName() {
    return this._value.memberName;
  }

  get memberNameWithDefault() {
    return this._value.memberName || '';
  }

  get medicalOrganization() {
    return this._value.medicalOrganization;
  }

  get medicalOrganizationWithDefault() {
    return this._value.medicalOrganization || '';
  }

  get medicalOrgNumber() {
    return this._value.medicalOrgNumber;
  }

  get medicalOrgNumberWithDefault() {
    return this._value.medicalOrgNumber || '';
  }

  get startTime() {
    return this._value.startTime;
  }

  get startTimeWithDefault() {
    return this._value.startTime || 0;
  }

  get activity() {
    return this._value.activity;
  }

  get activityWithDefault() {
    return this._value.activity || [];
  }

  get originBillableTime() {
    return this._value.originBillableTime;
  }

  get originBillableTimeWithDefault() {
    return this._value.originBillableTime || 0;
  }

  get correctedBillableTime() {
    return this._value.correctedBillableTime;
  }

  get correctedBillableTimeWithDefault() {
    return this._value.correctedBillableTime || 0;
  }

  get totalTime() {
    return this._value.totalTime;
  }

  get totalTimeWithDefault() {
    return this._value.totalTime || 0;
  }

  get comment() {
    return this._value.comment;
  }

  get commentWithDefault() {
    return this._value.comment || '';
  }

  get reviewerName() {
    return this._value.reviewerName;
  }

  get reviewerNameWithDefault() {
    return this._value.reviewerName || '';
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: BillableProcessStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }
}
export enum BillableReviewActionType {
	APPROVE = 'APPROVE',
	CORRECT_AND_APPROVE = 'CORRECT_AND_APPROVE',
}
export interface BillableTimeActionRequest {
	id?: Nullable<string>;
	action?: Nullable<BillableReviewActionType>;
	correctedBillableTime?: Nullable<number>;
	comment?: Nullable<string>;
}
export interface BillableTimesRequiredReviewCount {
	count?: Nullable<number>;
	dueDateString?: Nullable<string>;
	expirationDays?: Nullable<number>;
}
export class BillableTimesRequiredReviewCountClass {
  protected readonly _value: BillableTimesRequiredReviewCount;

	constructor(value: BillableTimesRequiredReviewCount) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get count() {
    return this._value.count;
  }

  get countWithDefault() {
    return this._value.count || 0;
  }

  get dueDateString() {
    return this._value.dueDateString;
  }

  get dueDateStringWithDefault() {
    return this._value.dueDateString || '';
  }

  get expirationDays() {
    return this._value.expirationDays;
  }

  get expirationDaysWithDefault() {
    return this._value.expirationDays || 0;
  }
}
export interface CountResponse {
	count?: Nullable<number>;
}
export class CountResponseClass {
  protected readonly _value: CountResponse;

	constructor(value: CountResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get count() {
    return this._value.count;
  }

  get countWithDefault() {
    return this._value.count || 0;
  }
}
export class BillableReviewSegmentClass extends BaseModelClass {
  protected readonly _value: BillableReviewSegment;

	constructor(value: BillableReviewSegment) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get segmentId() {
    return this._value.segmentId;
  }

  get viewerId() {
    return this._value.viewerId;
  }

  get startTime() {
    return this._value.startTime;
  }

  get startTimeWithDefault() {
    return this._value.startTime || 0;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: BillableProcessStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get billableTime() {
    return this._value.billableTime;
  }

  get billableTimeWithDefault() {
    return this._value.billableTime || 0;
  }

  get correctedBillableTime() {
    return this._value.correctedBillableTime;
  }

  get correctedBillableTimeWithDefault() {
    return this._value.correctedBillableTime || 0;
  }

  get comment() {
    return this._value.comment;
  }

  get commentWithDefault() {
    return this._value.comment || '';
  }
}
export interface BillableTimesReviewListParams {
	request: SearchRequest<BillableTimeReviewRequest>;
}

export interface BillableTimesReviewHandleParams {
	request: BillableTimeActionRequest;
}

export interface BillableTimesReviewQueryRequiredReviewCountsParams {
}

export interface BillableTimesReviewQueryRequiredReviewCountsWithRangeParams {
	startTime: number;
	endTime: number;
}

export class BillableTimesReviewService extends BaseController<BillableReviewSegment, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/billable-time-review';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public list({ baseUrl, params, headers }: RequestOption<BillableTimesReviewListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<BillableTimeReviewRecord>>>(`${baseUrl || this.baseURL}${this.basePath}/list${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public handle({ baseUrl, params, headers }: RequestOption<BillableTimesReviewHandleParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/handle${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public queryRequiredReviewCounts({ baseUrl, params, headers }: RequestOption<BillableTimesReviewQueryRequiredReviewCountsParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<BillableTimesRequiredReviewCount>>(`${baseUrl || this.baseURL}${this.basePath}/todo/count${getQueryStrings(queryList)}`, { headers });
	}

	public queryRequiredReviewCountsWithRange({ baseUrl, params, headers }: RequestOption<BillableTimesReviewQueryRequiredReviewCountsWithRangeParams>) {
		const queryList: QueryParam[] = [];
		if (params.startTime !== undefined && params.startTime !== null) {
			queryList.push({ name: 'startTime', value: params.startTime.toString() });
		}
		if (params.endTime !== undefined && params.endTime !== null) {
			queryList.push({ name: 'endTime', value: params.endTime.toString() });
		}
		return axios.get<APIResponse<CountResponse>>(`${baseUrl || this.baseURL}${this.basePath}/review-required/count${getQueryStrings(queryList)}`, { headers });
	}
}

export const billableTimesReviewService = new BillableTimesReviewService();

export const createBillableTimesReviewListObject = (value: APIResponse<PageResponse<BillableTimeReviewRecord>>) => new APIResponseClass<PageResponseClass<BillableTimeReviewRecordClass>>(value, (value: PageResponse<BillableTimeReviewRecord>) => new PageResponseClass<BillableTimeReviewRecordClass>(value, (value: BillableTimeReviewRecord) => new BillableTimeReviewRecordClass(value)));

export const useBillableTimesReviewList = <RCN = undefined>(configs: MakeRequestHookConfig<BillableTimesReviewListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillableTimesReviewListParams,
    APIResponse<PageResponse<BillableTimeReviewRecord>>,
    typeof billableTimesReviewService.list,
    AjaxOptions,
    APIResponseClass<PageResponseClass<BillableTimeReviewRecordClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableTimesReviewService.list>) => (
      billableTimesReviewService.list(...args)
    ),
    ResponseClassCreator: createBillableTimesReviewListObject,
    typeName: 'useBillableTimesReviewList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillableTimesReviewList.mock = () => new Error('Mock has not been implemented!');
useBillableTimesReviewList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableTimesReviewList.createObject = createBillableTimesReviewListObject;

export const createBillableTimesReviewHandleObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useBillableTimesReviewHandle = <RCN = undefined>(configs: MakeRequestHookConfig<BillableTimesReviewHandleParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillableTimesReviewHandleParams,
    APIResponse<string>,
    typeof billableTimesReviewService.handle,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableTimesReviewService.handle>) => (
      billableTimesReviewService.handle(...args)
    ),
    ResponseClassCreator: createBillableTimesReviewHandleObject,
    typeName: 'useBillableTimesReviewHandle',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillableTimesReviewHandle.mock = () => new Error('Mock has not been implemented!');
useBillableTimesReviewHandle.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableTimesReviewHandle.createObject = createBillableTimesReviewHandleObject;

export const createBillableTimesReviewQueryRequiredReviewCountsObject = (value: APIResponse<BillableTimesRequiredReviewCount>) => new APIResponseClass<BillableTimesRequiredReviewCountClass>(value, (value: BillableTimesRequiredReviewCount) => new BillableTimesRequiredReviewCountClass(value));

export const useBillableTimesReviewQueryRequiredReviewCounts = <RCN = undefined>(configs: MakeRequestHookConfig<BillableTimesReviewQueryRequiredReviewCountsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillableTimesReviewQueryRequiredReviewCountsParams,
    APIResponse<BillableTimesRequiredReviewCount>,
    typeof billableTimesReviewService.queryRequiredReviewCounts,
    GetOptions,
    APIResponseClass<BillableTimesRequiredReviewCountClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof billableTimesReviewService.queryRequiredReviewCounts>) => (
      billableTimesReviewService.queryRequiredReviewCounts(...args)
    ),
    ResponseClassCreator: createBillableTimesReviewQueryRequiredReviewCountsObject,
    typeName: 'useBillableTimesReviewQueryRequiredReviewCounts',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillableTimesReviewQueryRequiredReviewCounts.mock = () => new Error('Mock has not been implemented!');
useBillableTimesReviewQueryRequiredReviewCounts.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableTimesReviewQueryRequiredReviewCounts.createObject = createBillableTimesReviewQueryRequiredReviewCountsObject;

export const createBillableTimesReviewQueryRequiredReviewCountsWithRangeObject = (value: APIResponse<CountResponse>) => new APIResponseClass<CountResponseClass>(value, (value: CountResponse) => new CountResponseClass(value));

export const useBillableTimesReviewQueryRequiredReviewCountsWithRange = <RCN = undefined>(configs: MakeRequestHookConfig<BillableTimesReviewQueryRequiredReviewCountsWithRangeParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillableTimesReviewQueryRequiredReviewCountsWithRangeParams,
    APIResponse<CountResponse>,
    typeof billableTimesReviewService.queryRequiredReviewCountsWithRange,
    GetOptions,
    APIResponseClass<CountResponseClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof billableTimesReviewService.queryRequiredReviewCountsWithRange>) => (
      billableTimesReviewService.queryRequiredReviewCountsWithRange(...args)
    ),
    ResponseClassCreator: createBillableTimesReviewQueryRequiredReviewCountsWithRangeObject,
    typeName: 'useBillableTimesReviewQueryRequiredReviewCountsWithRange',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillableTimesReviewQueryRequiredReviewCountsWithRange.mock = () => new Error('Mock has not been implemented!');
useBillableTimesReviewQueryRequiredReviewCountsWithRange.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableTimesReviewQueryRequiredReviewCountsWithRange.createObject = createBillableTimesReviewQueryRequiredReviewCountsWithRangeObject;


export const createBillableTimesReviewGetObject = (value: APIResponse<BillableReviewSegment>) => new APIResponseClass<BillableReviewSegmentClass>(value, (value: BillableReviewSegment) => new BillableReviewSegmentClass(value));
export const useBillableTimesReviewGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<BillableReviewSegment>,
    // @ts-ignore
    typeof billableTimesReviewService.get,
    GetOptions,
    APIResponseClass<BillableReviewSegmentClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof billableTimesReviewService.get>) => (
      billableTimesReviewService.get(...args)
    ),
    ResponseClassCreator: createBillableTimesReviewGetObject,
    typeName: 'useBillableTimesReviewGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBillableTimesReviewGet.mock = () => new Error('Mock has not been implemented!');
useBillableTimesReviewGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableTimesReviewGet.createObject = createBillableTimesReviewGetObject;

export const createBillableTimesReviewUpdateObject = (value: APIResponse<BillableReviewSegment>) => new APIResponseClass<BillableReviewSegmentClass>(value, (value: BillableReviewSegment) => new BillableReviewSegmentClass(value));
export const useBillableTimesReviewUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, BillableReviewSegment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, BillableReviewSegment>,
    APIResponse<BillableReviewSegment>,
    // @ts-ignore
    typeof billableTimesReviewService.update,
    AjaxOptions,
    APIResponseClass<BillableReviewSegmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableTimesReviewService.update>) => (
      billableTimesReviewService.update(...args)
    ),
    ResponseClassCreator: createBillableTimesReviewUpdateObject,
    typeName: 'useBillableTimesReviewUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBillableTimesReviewUpdate.mock = () => new Error('Mock has not been implemented!');
useBillableTimesReviewUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableTimesReviewUpdate.createObject = createBillableTimesReviewUpdateObject;

export const createBillableTimesReviewInsertObject = (value: APIResponse<BillableReviewSegment>) => new APIResponseClass<BillableReviewSegmentClass>(value, (value: BillableReviewSegment) => new BillableReviewSegmentClass(value));
export const useBillableTimesReviewInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<BillableReviewSegment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<BillableReviewSegment>,
    APIResponse<BillableReviewSegment>,
    // @ts-ignore
    typeof billableTimesReviewService.insert,
    AjaxOptions,
    APIResponseClass<BillableReviewSegmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableTimesReviewService.insert>) => (
      billableTimesReviewService.insert(...args)
    ),
    ResponseClassCreator: createBillableTimesReviewInsertObject,
    typeName: 'useBillableTimesReviewInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBillableTimesReviewInsert.mock = () => new Error('Mock has not been implemented!');
useBillableTimesReviewInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableTimesReviewInsert.createObject = createBillableTimesReviewInsertObject;

export const createBillableTimesReviewDeleteObject = (value: APIResponse<BillableReviewSegment>) => new APIResponseClass<BillableReviewSegmentClass>(value, (value: BillableReviewSegment) => new BillableReviewSegmentClass(value));
export const useBillableTimesReviewDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<BillableReviewSegment>,
    // @ts-ignore
    typeof billableTimesReviewService.delete,
    AjaxOptions,
    APIResponseClass<BillableReviewSegmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableTimesReviewService.delete>) => (
      billableTimesReviewService.delete(...args)
    ),
    ResponseClassCreator: createBillableTimesReviewDeleteObject,
    typeName: 'useBillableTimesReviewDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBillableTimesReviewDelete.mock = () => new Error('Mock has not been implemented!');
useBillableTimesReviewDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableTimesReviewDelete.createObject = createBillableTimesReviewDeleteObject;

export const createBillableTimesReviewSearchObject = (value: APIResponse<PageResponse<BillableReviewSegment>>) => new APIResponseClass<PageResponseClass<BillableReviewSegmentClass>>(value, (value: PageResponse<BillableReviewSegment>) => new PageResponseClass<BillableReviewSegmentClass>(value, (value: BillableReviewSegment) => new BillableReviewSegmentClass(value)));
export const useBillableTimesReviewSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<BillableReviewSegment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<BillableReviewSegment>,
    APIResponse<PageResponse<BillableReviewSegment>>,
    // @ts-ignore
    typeof billableTimesReviewService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<BillableReviewSegmentClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billableTimesReviewService.search>) => (
      billableTimesReviewService.search(...args)
    ),
    ResponseClassCreator: createBillableTimesReviewSearchObject,
    typeName: 'useBillableTimesReviewSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useBillableTimesReviewSearch.mock = () => new Error('Mock has not been implemented!');
useBillableTimesReviewSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillableTimesReviewSearch.createObject = createBillableTimesReviewSearchObject;

export enum ReportTypeEnum {
	CLAIM = 'CLAIM',
	NON_BILLABLE = 'NON_BILLABLE',
	FQHC_CLAIM = 'FQHC_CLAIM',
	INVOICE_SUMMARY = 'INVOICE_SUMMARY',
	INVOICE_ITEMIZED = 'INVOICE_ITEMIZED',
	DUMP_REPORT = 'DUMP_REPORT',
	MISSING_INFORMATION = 'MISSING_INFORMATION',
	INDIVIDUAL_SUMMARY = 'INDIVIDUAL_SUMMARY',
	ADDITIONAL_CLAIM = 'ADDITIONAL_CLAIM',
}
export interface FileInfo {
	fileName?: Nullable<string>;
	bucket?: Nullable<string>;
	fileKey?: Nullable<string>;
}
export enum ReportGenerateStatusEnum {
	PENDING = 'PENDING',
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED',
}
export interface BillingReportVersionHistory {
	id?: Nullable<string>;
	version?: Nullable<string>;
	monthOfYear?: Nullable<number>;
	type?: Nullable<ReportTypeEnum>;
	fileInfo?: Nullable<FileInfo>;
	status?: Nullable<ReportGenerateStatusEnum>;
	createdAt?: Nullable<string>;
}
export class FileInfoClass {
  protected readonly _value: FileInfo;

	constructor(value: FileInfo) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get fileName() {
    return this._value.fileName;
  }

  get fileNameWithDefault() {
    return this._value.fileName || '';
  }

  get bucket() {
    return this._value.bucket;
  }

  get bucketWithDefault() {
    return this._value.bucket || '';
  }

  get fileKey() {
    return this._value.fileKey;
  }

  get fileKeyWithDefault() {
    return this._value.fileKey || '';
  }
}
export class BillingReportVersionHistoryClass {
  protected readonly _value: BillingReportVersionHistory;

	constructor(value: BillingReportVersionHistory) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get version() {
    return this._value.version;
  }

  get versionWithDefault() {
    return this._value.version || '';
  }

  get monthOfYear() {
    return this._value.monthOfYear;
  }

  get monthOfYearWithDefault() {
    return this._value.monthOfYear || 0;
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: ReportTypeEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get fileInfo() {
    return this._value.fileInfo !== undefined && this._value.fileInfo !== null ? new FileInfoClass(this._value.fileInfo) : this._value.fileInfo;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: ReportGenerateStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get createdAt() {
    return (this._value.createdAt !== undefined && this._value.createdAt !== null) ? dayjs(this._value.createdAt) : this._value.createdAt;
  }
}
export interface FileSearchRequest {
	parent?: Nullable<string>;
	pageInfo?: Nullable<PageInfo>;
}
export enum RowType {
	FOLDER = 'FOLDER',
	FILE = 'FILE',
}
export interface RowEntity {
	rowType?: Nullable<RowType>;
	parent?: Nullable<string>;
	path?: Nullable<string>;
	name?: Nullable<string>;
	fileKey?: Nullable<string>;
	bucketName?: Nullable<string>;
	timestamp?: Nullable<number>;
}
export class RowEntityClass {
  protected readonly _value: RowEntity;

	constructor(value: RowEntity) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get rowType() {
    return this._value.rowType;
  }

  rowTypeIsIn(values: RowType[] = []) {
    return this._value.rowType && values.includes(this._value.rowType);
  }

  get parent() {
    return this._value.parent;
  }

  get parentWithDefault() {
    return this._value.parent || '';
  }

  get path() {
    return this._value.path;
  }

  get pathWithDefault() {
    return this._value.path || '';
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get fileKey() {
    return this._value.fileKey;
  }

  get fileKeyWithDefault() {
    return this._value.fileKey || '';
  }

  get bucketName() {
    return this._value.bucketName;
  }

  get bucketNameWithDefault() {
    return this._value.bucketName || '';
  }

  get timestamp() {
    return this._value.timestamp;
  }

  get timestampWithDefault() {
    return this._value.timestamp || 0;
  }
}
export interface ReportVersionRequest {
	reportType?: Nullable<ReportTypeEnum>;
	pageInfo?: Nullable<PageInfo>;
}
export enum ReportApproveStatusEnum {
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	SKIPPED = 'SKIPPED',
}
export interface BillingReportManagementRequest {
	monthOfYear?: Nullable<number>;
	type?: Nullable<ReportTypeEnum>;
	status?: Nullable<ReportApproveStatusEnum[]>;
	pageIndex?: Nullable<number>;
	pageSize?: Nullable<number>;
	sortField?: Nullable<string>;
	sortOrder?: Nullable<string>;
}
export interface BillingReportManagementEntity {
	id?: Nullable<string>;
	medicalOrgId?: Nullable<string>;
	medicalOrgName?: Nullable<string>;
	orgNumber?: Nullable<number>;
	status?: Nullable<ReportApproveStatusEnum>;
	monthOfYear?: Nullable<number>;
	reportType?: Nullable<ReportTypeEnum>;
	approvedVersion?: Nullable<string>;
	approvedDate?: Nullable<string>;
}
export class BillingReportManagementEntityClass {
  protected readonly _value: BillingReportManagementEntity;

	constructor(value: BillingReportManagementEntity) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get medicalOrgId() {
    return this._value.medicalOrgId;
  }

  get medicalOrgName() {
    return this._value.medicalOrgName;
  }

  get medicalOrgNameWithDefault() {
    return this._value.medicalOrgName || '';
  }

  get orgNumber() {
    return this._value.orgNumber;
  }

  get orgNumberWithDefault() {
    return this._value.orgNumber || 0;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: ReportApproveStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get monthOfYear() {
    return this._value.monthOfYear;
  }

  get monthOfYearWithDefault() {
    return this._value.monthOfYear || 0;
  }

  get reportType() {
    return this._value.reportType;
  }

  reportTypeIsIn(values: ReportTypeEnum[] = []) {
    return this._value.reportType && values.includes(this._value.reportType);
  }

  get approvedVersion() {
    return this._value.approvedVersion;
  }

  get approvedVersionWithDefault() {
    return this._value.approvedVersion || '';
  }

  get approvedDate() {
    return (this._value.approvedDate !== undefined && this._value.approvedDate !== null) ? dayjs(this._value.approvedDate) : this._value.approvedDate;
  }
}
export interface DumpReportVersionRequest {
	monthOfYear?: Nullable<number>;
	fileInfo?: Nullable<FileInfo>;
	orgId?: Nullable<string>;
}
export interface ApprovedRequest {
	approvedVersion?: Nullable<string>;
	monthOfYear?: Nullable<number>;
	billingManagementEntityIds?: Nullable<string[]>;
}
export interface BillingServiceGetDumpReportVersionListParams {
	monthOfYear: number;
}

export interface BillingServiceShowDirectoryParams {
	request: FileSearchRequest;
}

export interface BillingServiceGetMissingInformationReportVersionListParams {
	reportRequest: ReportVersionRequest;
}

export interface BillingServiceListBillingManagementTableParams {
	request: BillingReportManagementRequest;
}

export interface BillingServiceGenerateDumpReportParams {
	request: DumpReportVersionRequest;
}

export interface BillingServiceGetDownloadUrlParams {
	fileInfo: FileInfo;
}

export interface BillingServiceGenerateApprovedReportParams {
	request: ApprovedRequest;
}

export interface BillingServiceGenerateMissingInformationParams {
	request: DumpReportVersionRequest;
}

export class BillingServiceService {
  protected baseURL = '';

  protected basePath = '/v1/uc/billing';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public getDumpReportVersionList({ baseUrl, params, headers }: RequestOption<BillingServiceGetDumpReportVersionListParams>) {
		const queryList: QueryParam[] = [];
		if (params.monthOfYear !== undefined && params.monthOfYear !== null) {
			queryList.push({ name: 'monthOfYear', value: params.monthOfYear.toString() });
		}
		return axios.get<APIResponse<BillingReportVersionHistory[]>>(`${baseUrl || this.baseURL}${this.basePath}/list-dump-report-version${getQueryStrings(queryList)}`, { headers });
	}

	public showDirectory({ baseUrl, params, headers }: RequestOption<BillingServiceShowDirectoryParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<RowEntity>>>(`${baseUrl || this.baseURL}${this.basePath}/showDirectory${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getMissingInformationReportVersionList({ baseUrl, params, headers }: RequestOption<BillingServiceGetMissingInformationReportVersionListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<BillingReportVersionHistory>>>(`${baseUrl || this.baseURL}${this.basePath}/list-missing-information-report-version${getQueryStrings(queryList)}`, params.reportRequest, { headers });
	}

	public listBillingManagementTable({ baseUrl, params, headers }: RequestOption<BillingServiceListBillingManagementTableParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<BillingReportManagementEntity>>>(`${baseUrl || this.baseURL}${this.basePath}/list${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public generateDumpReport({ baseUrl, params, headers }: RequestOption<BillingServiceGenerateDumpReportParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<BillingReportVersionHistory>>(`${baseUrl || this.baseURL}${this.basePath}/dump-report${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getDownloadUrl({ baseUrl, params, headers }: RequestOption<BillingServiceGetDownloadUrlParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/generate-download-link${getQueryStrings(queryList)}`, params.fileInfo, { headers });
	}

	public generateApprovedReport({ baseUrl, params, headers }: RequestOption<BillingServiceGenerateApprovedReportParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<BillingReportVersionHistory>>(`${baseUrl || this.baseURL}${this.basePath}/generate-approved-report${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public generateMissingInformation({ baseUrl, params, headers }: RequestOption<BillingServiceGenerateMissingInformationParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<BillingReportVersionHistory>>(`${baseUrl || this.baseURL}${this.basePath}/missing-information-report${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const billingServiceService = new BillingServiceService();

export const createBillingServiceGetDumpReportVersionListObject = (value: APIResponse<BillingReportVersionHistory[]>) => new APIResponseClass<BillingReportVersionHistoryClass[]>(value, (v) => map(v, (value: BillingReportVersionHistory) => new BillingReportVersionHistoryClass(value)));

export const useBillingServiceGetDumpReportVersionList = <RCN = undefined>(configs: MakeRequestHookConfig<BillingServiceGetDumpReportVersionListParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillingServiceGetDumpReportVersionListParams,
    APIResponse<BillingReportVersionHistory[]>,
    typeof billingServiceService.getDumpReportVersionList,
    GetOptions,
    APIResponseClass<BillingReportVersionHistoryClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof billingServiceService.getDumpReportVersionList>) => (
      billingServiceService.getDumpReportVersionList(...args)
    ),
    ResponseClassCreator: createBillingServiceGetDumpReportVersionListObject,
    typeName: 'useBillingServiceGetDumpReportVersionList',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillingServiceGetDumpReportVersionList.mock = () => new Error('Mock has not been implemented!');
useBillingServiceGetDumpReportVersionList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillingServiceGetDumpReportVersionList.createObject = createBillingServiceGetDumpReportVersionListObject;

export const createBillingServiceShowDirectoryObject = (value: APIResponse<PageResponse<RowEntity>>) => new APIResponseClass<PageResponseClass<RowEntityClass>>(value, (value: PageResponse<RowEntity>) => new PageResponseClass<RowEntityClass>(value, (value: RowEntity) => new RowEntityClass(value)));

export const useBillingServiceShowDirectory = <RCN = undefined>(configs: MakeRequestHookConfig<BillingServiceShowDirectoryParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillingServiceShowDirectoryParams,
    APIResponse<PageResponse<RowEntity>>,
    typeof billingServiceService.showDirectory,
    AjaxOptions,
    APIResponseClass<PageResponseClass<RowEntityClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billingServiceService.showDirectory>) => (
      billingServiceService.showDirectory(...args)
    ),
    ResponseClassCreator: createBillingServiceShowDirectoryObject,
    typeName: 'useBillingServiceShowDirectory',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillingServiceShowDirectory.mock = () => new Error('Mock has not been implemented!');
useBillingServiceShowDirectory.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillingServiceShowDirectory.createObject = createBillingServiceShowDirectoryObject;

export const createBillingServiceGetMissingInformationReportVersionListObject = (value: APIResponse<PageResponse<BillingReportVersionHistory>>) => new APIResponseClass<PageResponseClass<BillingReportVersionHistoryClass>>(value, (value: PageResponse<BillingReportVersionHistory>) => new PageResponseClass<BillingReportVersionHistoryClass>(value, (value: BillingReportVersionHistory) => new BillingReportVersionHistoryClass(value)));

export const useBillingServiceGetMissingInformationReportVersionList = <RCN = undefined>(configs: MakeRequestHookConfig<BillingServiceGetMissingInformationReportVersionListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillingServiceGetMissingInformationReportVersionListParams,
    APIResponse<PageResponse<BillingReportVersionHistory>>,
    typeof billingServiceService.getMissingInformationReportVersionList,
    AjaxOptions,
    APIResponseClass<PageResponseClass<BillingReportVersionHistoryClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billingServiceService.getMissingInformationReportVersionList>) => (
      billingServiceService.getMissingInformationReportVersionList(...args)
    ),
    ResponseClassCreator: createBillingServiceGetMissingInformationReportVersionListObject,
    typeName: 'useBillingServiceGetMissingInformationReportVersionList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillingServiceGetMissingInformationReportVersionList.mock = () => new Error('Mock has not been implemented!');
useBillingServiceGetMissingInformationReportVersionList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillingServiceGetMissingInformationReportVersionList.createObject = createBillingServiceGetMissingInformationReportVersionListObject;

export const createBillingServiceListBillingManagementTableObject = (value: APIResponse<PageResponse<BillingReportManagementEntity>>) => new APIResponseClass<PageResponseClass<BillingReportManagementEntityClass>>(value, (value: PageResponse<BillingReportManagementEntity>) => new PageResponseClass<BillingReportManagementEntityClass>(value, (value: BillingReportManagementEntity) => new BillingReportManagementEntityClass(value)));

export const useBillingServiceListBillingManagementTable = <RCN = undefined>(configs: MakeRequestHookConfig<BillingServiceListBillingManagementTableParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillingServiceListBillingManagementTableParams,
    APIResponse<PageResponse<BillingReportManagementEntity>>,
    typeof billingServiceService.listBillingManagementTable,
    AjaxOptions,
    APIResponseClass<PageResponseClass<BillingReportManagementEntityClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billingServiceService.listBillingManagementTable>) => (
      billingServiceService.listBillingManagementTable(...args)
    ),
    ResponseClassCreator: createBillingServiceListBillingManagementTableObject,
    typeName: 'useBillingServiceListBillingManagementTable',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillingServiceListBillingManagementTable.mock = () => new Error('Mock has not been implemented!');
useBillingServiceListBillingManagementTable.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillingServiceListBillingManagementTable.createObject = createBillingServiceListBillingManagementTableObject;

export const createBillingServiceGenerateDumpReportObject = (value: APIResponse<BillingReportVersionHistory>) => new APIResponseClass<BillingReportVersionHistoryClass>(value, (value: BillingReportVersionHistory) => new BillingReportVersionHistoryClass(value));

export const useBillingServiceGenerateDumpReport = <RCN = undefined>(configs: MakeRequestHookConfig<BillingServiceGenerateDumpReportParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillingServiceGenerateDumpReportParams,
    APIResponse<BillingReportVersionHistory>,
    typeof billingServiceService.generateDumpReport,
    AjaxOptions,
    APIResponseClass<BillingReportVersionHistoryClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billingServiceService.generateDumpReport>) => (
      billingServiceService.generateDumpReport(...args)
    ),
    ResponseClassCreator: createBillingServiceGenerateDumpReportObject,
    typeName: 'useBillingServiceGenerateDumpReport',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillingServiceGenerateDumpReport.mock = () => new Error('Mock has not been implemented!');
useBillingServiceGenerateDumpReport.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillingServiceGenerateDumpReport.createObject = createBillingServiceGenerateDumpReportObject;

export const createBillingServiceGetDownloadUrlObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useBillingServiceGetDownloadUrl = <RCN = undefined>(configs: MakeRequestHookConfig<BillingServiceGetDownloadUrlParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillingServiceGetDownloadUrlParams,
    APIResponse<string>,
    typeof billingServiceService.getDownloadUrl,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billingServiceService.getDownloadUrl>) => (
      billingServiceService.getDownloadUrl(...args)
    ),
    ResponseClassCreator: createBillingServiceGetDownloadUrlObject,
    typeName: 'useBillingServiceGetDownloadUrl',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillingServiceGetDownloadUrl.mock = () => new Error('Mock has not been implemented!');
useBillingServiceGetDownloadUrl.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillingServiceGetDownloadUrl.createObject = createBillingServiceGetDownloadUrlObject;

export const createBillingServiceGenerateApprovedReportObject = (value: APIResponse<BillingReportVersionHistory>) => new APIResponseClass<BillingReportVersionHistoryClass>(value, (value: BillingReportVersionHistory) => new BillingReportVersionHistoryClass(value));

export const useBillingServiceGenerateApprovedReport = <RCN = undefined>(configs: MakeRequestHookConfig<BillingServiceGenerateApprovedReportParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillingServiceGenerateApprovedReportParams,
    APIResponse<BillingReportVersionHistory>,
    typeof billingServiceService.generateApprovedReport,
    AjaxOptions,
    APIResponseClass<BillingReportVersionHistoryClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billingServiceService.generateApprovedReport>) => (
      billingServiceService.generateApprovedReport(...args)
    ),
    ResponseClassCreator: createBillingServiceGenerateApprovedReportObject,
    typeName: 'useBillingServiceGenerateApprovedReport',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillingServiceGenerateApprovedReport.mock = () => new Error('Mock has not been implemented!');
useBillingServiceGenerateApprovedReport.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillingServiceGenerateApprovedReport.createObject = createBillingServiceGenerateApprovedReportObject;

export const createBillingServiceGenerateMissingInformationObject = (value: APIResponse<BillingReportVersionHistory>) => new APIResponseClass<BillingReportVersionHistoryClass>(value, (value: BillingReportVersionHistory) => new BillingReportVersionHistoryClass(value));

export const useBillingServiceGenerateMissingInformation = <RCN = undefined>(configs: MakeRequestHookConfig<BillingServiceGenerateMissingInformationParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BillingServiceGenerateMissingInformationParams,
    APIResponse<BillingReportVersionHistory>,
    typeof billingServiceService.generateMissingInformation,
    AjaxOptions,
    APIResponseClass<BillingReportVersionHistoryClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof billingServiceService.generateMissingInformation>) => (
      billingServiceService.generateMissingInformation(...args)
    ),
    ResponseClassCreator: createBillingServiceGenerateMissingInformationObject,
    typeName: 'useBillingServiceGenerateMissingInformation',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useBillingServiceGenerateMissingInformation.mock = () => new Error('Mock has not been implemented!');
useBillingServiceGenerateMissingInformation.getLastMockedValue = () => new Error('Mock has not been implemented!');
useBillingServiceGenerateMissingInformation.createObject = createBillingServiceGenerateMissingInformationObject;
export enum CalendarEvent$CalendarSource {
	UC_VISIT = 'UC_VISIT',
	CALENDAR_MEETING = 'CALENDAR_MEETING',
}
export enum CalendarEvent$VisitEnum {
	INIT = 'INIT',
	FOLLOW_UP = 'FOLLOW_UP',
	ADDITIONAL = 'ADDITIONAL',
	CLINIC_FOLLOW_UP = 'CLINIC_FOLLOW_UP',
	MNT = 'MNT',
	TECH = 'TECH',
	TECH_ONBOARDING = 'TECH_ONBOARDING',
}
export interface CalendarEmployee {
	employeeId?: Nullable<string>;
	email?: Nullable<string>;
	employeeInfo?: Nullable<LimitedUser>;
}
export interface CalendarEvent extends BaseModel {
	calendarEventId?: Nullable<string>;
	patientId?: Nullable<string>;
	summary?: Nullable<string>;
	eventSource?: Nullable<CalendarEvent$CalendarSource>;
	referenceId?: Nullable<string>;
	visitType?: Nullable<CalendarEvent$VisitEnum>;
	responseStatus?: Nullable<string>;
	teamMembers?: Nullable<string[]>;
	eventOwner?: Nullable<Profile>;
	eventType?: Nullable<string>;
	clinicId?: Nullable<string>;
	description?: Nullable<string>;
	clinic?: Nullable<LimitedClinic>;
	patient?: Nullable<LimitedPatient>;
	employees?: Nullable<CalendarEmployee[]>;
	startTime?: Nullable<string>;
	endTime?: Nullable<string>;
	startTimeRange?: Nullable<DateRangeFilter>;
	teamMembersIn?: Nullable<InFilter<string>>;
	visitTypeIn?: Nullable<InFilter<CalendarEvent$VisitEnum>>;
	patientIdIn?: Nullable<InFilter<string>>;
}
export class CalendarEmployeeClass {
  protected readonly _value: CalendarEmployee;

	constructor(value: CalendarEmployee) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get employeeId() {
    return this._value.employeeId;
  }

  get email() {
    return this._value.email;
  }

  get emailWithDefault() {
    return this._value.email || '';
  }

  get employeeInfo() {
    return this._value.employeeInfo !== undefined && this._value.employeeInfo !== null ? new LimitedUserClass(this._value.employeeInfo) : this._value.employeeInfo;
  }
}
export class CalendarEventClass extends BaseModelClass {
  protected readonly _value: CalendarEvent;

	constructor(value: CalendarEvent) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get calendarEventId() {
    return this._value.calendarEventId;
  }

  get calendarEventIdWithDefault() {
    return this._value.calendarEventId || '';
  }

  get patientId() {
    return this._value.patientId;
  }

  get patientIdWithDefault() {
    return this._value.patientId || '';
  }

  get summary() {
    return this._value.summary;
  }

  get summaryWithDefault() {
    return this._value.summary || '';
  }

  get eventSource() {
    return this._value.eventSource;
  }

  eventSourceIsIn(values: CalendarEvent$CalendarSource[] = []) {
    return this._value.eventSource && values.includes(this._value.eventSource);
  }

  get referenceId() {
    return this._value.referenceId;
  }

  get referenceIdWithDefault() {
    return this._value.referenceId || '';
  }

  get visitType() {
    return this._value.visitType;
  }

  visitTypeIsIn(values: CalendarEvent$VisitEnum[] = []) {
    return this._value.visitType && values.includes(this._value.visitType);
  }

  get responseStatus() {
    return this._value.responseStatus;
  }

  get responseStatusWithDefault() {
    return this._value.responseStatus || '';
  }

  get teamMembers() {
    return this._value.teamMembers;
  }

  get teamMembersWithDefault() {
    return this._value.teamMembers || [];
  }

  get eventOwner() {
    return this._value.eventOwner !== undefined && this._value.eventOwner !== null ? new ProfileClass(this._value.eventOwner) : this._value.eventOwner;
  }

  get eventType() {
    return this._value.eventType;
  }

  get eventTypeWithDefault() {
    return this._value.eventType || '';
  }

  get clinicId() {
    return this._value.clinicId;
  }

  get clinicIdWithDefault() {
    return this._value.clinicId || '';
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get clinic() {
    return this._value.clinic !== undefined && this._value.clinic !== null ? new LimitedClinicClass(this._value.clinic) : this._value.clinic;
  }

  get patient() {
    return this._value.patient !== undefined && this._value.patient !== null ? new LimitedPatientClass(this._value.patient) : this._value.patient;
  }

  get employees() {
    return this._value.employees !== undefined && this._value.employees !== null ? map(this._value.employees, (v) => new CalendarEmployeeClass(v)) : this._value.employees;
  }

  get employeesWithDefault() {
    return this._value.employees || [];
  }

  get startTime() {
    return (this._value.startTime !== undefined && this._value.startTime !== null) ? dayjs(this._value.startTime) : this._value.startTime;
  }

  get endTime() {
    return (this._value.endTime !== undefined && this._value.endTime !== null) ? dayjs(this._value.endTime) : this._value.endTime;
  }

  get startTimeRange() {
    return this._value.startTimeRange !== undefined && this._value.startTimeRange !== null ? new DateRangeFilterClass(this._value.startTimeRange) : this._value.startTimeRange;
  }

  get teamMembersIn() {
    return this._value.teamMembersIn !== undefined && this._value.teamMembersIn !== null ? new InFilterClass(this._value.teamMembersIn) : this._value.teamMembersIn;
  }

  get visitTypeIn() {
    return this._value.visitTypeIn !== undefined && this._value.visitTypeIn !== null ? new InFilterClass(this._value.visitTypeIn) : this._value.visitTypeIn;
  }

  get patientIdIn() {
    return this._value.patientIdIn !== undefined && this._value.patientIdIn !== null ? new InFilterClass(this._value.patientIdIn) : this._value.patientIdIn;
  }
}
export interface CalendarEventInsertParams {
	event: CalendarEvent;
}

export interface CalendarEventSearchParams {
	filter: SearchRequest<CalendarEvent>;
}

export interface CalendarEventUpdateUcAndCalendarVisitParams {
	referenceId: string;
	request: CalendarEvent;
}

export interface CalendarEventDeleteUcAndCalendarVisitParams {
	referenceId: string;
}

export class CalendarEventService extends BaseController<CalendarEvent, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/calendar-event';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public insert({ baseUrl, params, headers }: RequestOption<CalendarEventInsertParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<CalendarEvent>>(`${baseUrl || this.baseURL}${this.basePath}${getQueryStrings(queryList)}`, params.event, { headers });
	}

	public search({ baseUrl, params, headers }: RequestOption<CalendarEventSearchParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<CalendarEvent>>>(`${baseUrl || this.baseURL}${this.basePath}/search${getQueryStrings(queryList)}`, params.filter, { headers });
	}

	public updateUcAndCalendarVisit({ baseUrl, params, headers }: RequestOption<CalendarEventUpdateUcAndCalendarVisitParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<CalendarEvent>>(`${baseUrl || this.baseURL}${this.basePath}/referenceId/${params.referenceId}${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public deleteUcAndCalendarVisit({ baseUrl, params, headers }: RequestOption<CalendarEventDeleteUcAndCalendarVisitParams>) {
		const queryList: QueryParam[] = [];
		return axios.delete<APIResponse<CalendarEvent>>(`${baseUrl || this.baseURL}${this.basePath}/referenceId/${params.referenceId}${getQueryStrings(queryList)}`, { headers });
	}
}

export const calendarEventService = new CalendarEventService();

export const createCalendarEventInsertObject = (value: APIResponse<CalendarEvent>) => new APIResponseClass<CalendarEventClass>(value, (value: CalendarEvent) => new CalendarEventClass(value));

export const useCalendarEventInsert = <RCN = undefined>(configs: MakeRequestHookConfig<CalendarEventInsertParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    CalendarEventInsertParams,
    APIResponse<CalendarEvent>,
    typeof calendarEventService.insert,
    AjaxOptions,
    APIResponseClass<CalendarEventClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof calendarEventService.insert>) => (
      calendarEventService.insert(...args)
    ),
    ResponseClassCreator: createCalendarEventInsertObject,
    typeName: 'useCalendarEventInsert',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useCalendarEventInsert.mock = () => new Error('Mock has not been implemented!');
useCalendarEventInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCalendarEventInsert.createObject = createCalendarEventInsertObject;

export const createCalendarEventSearchObject = (value: APIResponse<PageResponse<CalendarEvent>>) => new APIResponseClass<PageResponseClass<CalendarEventClass>>(value, (value: PageResponse<CalendarEvent>) => new PageResponseClass<CalendarEventClass>(value, (value: CalendarEvent) => new CalendarEventClass(value)));

export const useCalendarEventSearch = <RCN = undefined>(configs: MakeRequestHookConfig<CalendarEventSearchParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    CalendarEventSearchParams,
    APIResponse<PageResponse<CalendarEvent>>,
    typeof calendarEventService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<CalendarEventClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof calendarEventService.search>) => (
      calendarEventService.search(...args)
    ),
    ResponseClassCreator: createCalendarEventSearchObject,
    typeName: 'useCalendarEventSearch',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useCalendarEventSearch.mock = () => new Error('Mock has not been implemented!');
useCalendarEventSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCalendarEventSearch.createObject = createCalendarEventSearchObject;

export const createCalendarEventUpdateUcAndCalendarVisitObject = (value: APIResponse<CalendarEvent>) => new APIResponseClass<CalendarEventClass>(value, (value: CalendarEvent) => new CalendarEventClass(value));

export const useCalendarEventUpdateUcAndCalendarVisit = <RCN = undefined>(configs: MakeRequestHookConfig<CalendarEventUpdateUcAndCalendarVisitParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    CalendarEventUpdateUcAndCalendarVisitParams,
    APIResponse<CalendarEvent>,
    typeof calendarEventService.updateUcAndCalendarVisit,
    AjaxOptions,
    APIResponseClass<CalendarEventClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof calendarEventService.updateUcAndCalendarVisit>) => (
      calendarEventService.updateUcAndCalendarVisit(...args)
    ),
    ResponseClassCreator: createCalendarEventUpdateUcAndCalendarVisitObject,
    typeName: 'useCalendarEventUpdateUcAndCalendarVisit',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useCalendarEventUpdateUcAndCalendarVisit.mock = () => new Error('Mock has not been implemented!');
useCalendarEventUpdateUcAndCalendarVisit.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCalendarEventUpdateUcAndCalendarVisit.createObject = createCalendarEventUpdateUcAndCalendarVisitObject;

export const createCalendarEventDeleteUcAndCalendarVisitObject = (value: APIResponse<CalendarEvent>) => new APIResponseClass<CalendarEventClass>(value, (value: CalendarEvent) => new CalendarEventClass(value));

export const useCalendarEventDeleteUcAndCalendarVisit = <RCN = undefined>(configs: MakeRequestHookConfig<CalendarEventDeleteUcAndCalendarVisitParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    CalendarEventDeleteUcAndCalendarVisitParams,
    APIResponse<CalendarEvent>,
    typeof calendarEventService.deleteUcAndCalendarVisit,
    AjaxOptions,
    APIResponseClass<CalendarEventClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof calendarEventService.deleteUcAndCalendarVisit>) => (
      calendarEventService.deleteUcAndCalendarVisit(...args)
    ),
    ResponseClassCreator: createCalendarEventDeleteUcAndCalendarVisitObject,
    typeName: 'useCalendarEventDeleteUcAndCalendarVisit',
    type: MethodTypeEnum.DELETE,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useCalendarEventDeleteUcAndCalendarVisit.mock = () => new Error('Mock has not been implemented!');
useCalendarEventDeleteUcAndCalendarVisit.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCalendarEventDeleteUcAndCalendarVisit.createObject = createCalendarEventDeleteUcAndCalendarVisitObject;


export const createCalendarEventGetObject = (value: APIResponse<CalendarEvent>) => new APIResponseClass<CalendarEventClass>(value, (value: CalendarEvent) => new CalendarEventClass(value));
export const useCalendarEventGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<CalendarEvent>,
    // @ts-ignore
    typeof calendarEventService.get,
    GetOptions,
    APIResponseClass<CalendarEventClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof calendarEventService.get>) => (
      calendarEventService.get(...args)
    ),
    ResponseClassCreator: createCalendarEventGetObject,
    typeName: 'useCalendarEventGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useCalendarEventGet.mock = () => new Error('Mock has not been implemented!');
useCalendarEventGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCalendarEventGet.createObject = createCalendarEventGetObject;

export const createCalendarEventUpdateObject = (value: APIResponse<CalendarEvent>) => new APIResponseClass<CalendarEventClass>(value, (value: CalendarEvent) => new CalendarEventClass(value));
export const useCalendarEventUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, CalendarEvent>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, CalendarEvent>,
    APIResponse<CalendarEvent>,
    // @ts-ignore
    typeof calendarEventService.update,
    AjaxOptions,
    APIResponseClass<CalendarEventClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof calendarEventService.update>) => (
      calendarEventService.update(...args)
    ),
    ResponseClassCreator: createCalendarEventUpdateObject,
    typeName: 'useCalendarEventUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useCalendarEventUpdate.mock = () => new Error('Mock has not been implemented!');
useCalendarEventUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCalendarEventUpdate.createObject = createCalendarEventUpdateObject;

export const createCalendarEventDeleteObject = (value: APIResponse<CalendarEvent>) => new APIResponseClass<CalendarEventClass>(value, (value: CalendarEvent) => new CalendarEventClass(value));
export const useCalendarEventDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<CalendarEvent>,
    // @ts-ignore
    typeof calendarEventService.delete,
    AjaxOptions,
    APIResponseClass<CalendarEventClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof calendarEventService.delete>) => (
      calendarEventService.delete(...args)
    ),
    ResponseClassCreator: createCalendarEventDeleteObject,
    typeName: 'useCalendarEventDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useCalendarEventDelete.mock = () => new Error('Mock has not been implemented!');
useCalendarEventDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCalendarEventDelete.createObject = createCalendarEventDeleteObject;

export enum CareNoteTagEnum {
	COMPLIANCE_ALERT = 'COMPLIANCE_ALERT',
	MEDICAL_ALERT = 'MEDICAL_ALERT',
	VISIT = 'VISIT',
	TASK_NOTES = 'TASK_NOTES',
	CALL_LOG = 'CALL_LOG',
	PAUSED_SERVICE = 'PAUSED_SERVICE',
	MONTHLY_REVIEW = 'MONTHLY_REVIEW',
	LIFE_EVENTS = 'LIFE_EVENTS',
	MEDICATION = 'MEDICATION',
	CLINIC_PROVIDER = 'CLINIC_PROVIDER',
	CARE_PLAN_REVIEW = 'CARE_PLAN_REVIEW',
	OFFLINE_TASK = 'OFFLINE_TASK',
	DISCHARGE_NOTE = 'DISCHARGE_NOTE',
	UNABLE_TO_ENGAGE = 'UNABLE_TO_ENGAGE',
	GOAL_UPDATE = 'GOAL_UPDATE',
	OTHERS = 'OTHERS',
	MONTHLY_REVIEW_CA = 'MONTHLY_REVIEW_CA',
	MONTHLY_REVIEW_RD_HC = 'MONTHLY_REVIEW_RD_HC',
	CLINICAL_ALERT = 'CLINICAL_ALERT',
	FOLLOW_UP_COUNSELING = 'FOLLOW_UP_COUNSELING',
	ENGAGEMENT = 'ENGAGEMENT',
	IMPORTANT = 'IMPORTANT',
}
export interface CareNoteHistory {
	content?: Nullable<string>;
	date?: Nullable<string>;
}
export interface CareNote extends BaseModel {
	memberId?: Nullable<string>;
	createdBy?: Nullable<string>;
	updatedBy?: Nullable<string>;
	tags?: Nullable<CareNoteTagEnum[]>;
	content?: Nullable<string>;
	histories?: Nullable<CareNoteHistory[]>;
	startTime?: Nullable<string>;
	endTime?: Nullable<string>;
	nonBillable?: Nullable<boolean>;
	isOfflineCall?: Nullable<boolean>;
	createdByUser?: Nullable<LimitedUser>;
	tagsIn?: Nullable<InFilter<CareNoteTagEnum>>;
}
export class CareNoteHistoryClass {
  protected readonly _value: CareNoteHistory;

	constructor(value: CareNoteHistory) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get content() {
    return this._value.content;
  }

  get contentWithDefault() {
    return this._value.content || '';
  }

  get date() {
    return (this._value.date !== undefined && this._value.date !== null) ? dayjs(this._value.date) : this._value.date;
  }
}
export class CareNoteClass extends BaseModelClass {
  protected readonly _value: CareNote;

	constructor(value: CareNote) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get createdBy() {
    return this._value.createdBy;
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get tags() {
    return this._value.tags;
  }

  get tagsWithDefault() {
    return this._value.tags || [];
  }

  get content() {
    return this._value.content;
  }

  get contentWithDefault() {
    return this._value.content || '';
  }

  get histories() {
    return this._value.histories !== undefined && this._value.histories !== null ? map(this._value.histories, (v) => new CareNoteHistoryClass(v)) : this._value.histories;
  }

  get historiesWithDefault() {
    return this._value.histories || [];
  }

  get startTime() {
    return (this._value.startTime !== undefined && this._value.startTime !== null) ? dayjs(this._value.startTime) : this._value.startTime;
  }

  get endTime() {
    return (this._value.endTime !== undefined && this._value.endTime !== null) ? dayjs(this._value.endTime) : this._value.endTime;
  }

  get nonBillable() {
    return this._value.nonBillable;
  }

  get nonBillableWithDefault() {
    return this._value.nonBillable || false;
  }

  get isOfflineCall() {
    return this._value.isOfflineCall;
  }

  get isOfflineCallWithDefault() {
    return this._value.isOfflineCall || false;
  }

  get createdByUser() {
    return this._value.createdByUser !== undefined && this._value.createdByUser !== null ? new LimitedUserClass(this._value.createdByUser) : this._value.createdByUser;
  }

  get tagsIn() {
    return this._value.tagsIn !== undefined && this._value.tagsIn !== null ? new InFilterClass(this._value.tagsIn) : this._value.tagsIn;
  }
}
export class CareNoteService extends BaseController<CareNote, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/care-note';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const careNoteService = new CareNoteService();


export const createCareNoteGetObject = (value: APIResponse<CareNote>) => new APIResponseClass<CareNoteClass>(value, (value: CareNote) => new CareNoteClass(value));
export const useCareNoteGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<CareNote>,
    // @ts-ignore
    typeof careNoteService.get,
    GetOptions,
    APIResponseClass<CareNoteClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof careNoteService.get>) => (
      careNoteService.get(...args)
    ),
    ResponseClassCreator: createCareNoteGetObject,
    typeName: 'useCareNoteGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useCareNoteGet.mock = () => new Error('Mock has not been implemented!');
useCareNoteGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCareNoteGet.createObject = createCareNoteGetObject;

export const createCareNoteUpdateObject = (value: APIResponse<CareNote>) => new APIResponseClass<CareNoteClass>(value, (value: CareNote) => new CareNoteClass(value));
export const useCareNoteUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, CareNote>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, CareNote>,
    APIResponse<CareNote>,
    // @ts-ignore
    typeof careNoteService.update,
    AjaxOptions,
    APIResponseClass<CareNoteClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof careNoteService.update>) => (
      careNoteService.update(...args)
    ),
    ResponseClassCreator: createCareNoteUpdateObject,
    typeName: 'useCareNoteUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useCareNoteUpdate.mock = () => new Error('Mock has not been implemented!');
useCareNoteUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCareNoteUpdate.createObject = createCareNoteUpdateObject;

export const createCareNoteInsertObject = (value: APIResponse<CareNote>) => new APIResponseClass<CareNoteClass>(value, (value: CareNote) => new CareNoteClass(value));
export const useCareNoteInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<CareNote>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<CareNote>,
    APIResponse<CareNote>,
    // @ts-ignore
    typeof careNoteService.insert,
    AjaxOptions,
    APIResponseClass<CareNoteClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof careNoteService.insert>) => (
      careNoteService.insert(...args)
    ),
    ResponseClassCreator: createCareNoteInsertObject,
    typeName: 'useCareNoteInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useCareNoteInsert.mock = () => new Error('Mock has not been implemented!');
useCareNoteInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCareNoteInsert.createObject = createCareNoteInsertObject;

export const createCareNoteDeleteObject = (value: APIResponse<CareNote>) => new APIResponseClass<CareNoteClass>(value, (value: CareNote) => new CareNoteClass(value));
export const useCareNoteDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<CareNote>,
    // @ts-ignore
    typeof careNoteService.delete,
    AjaxOptions,
    APIResponseClass<CareNoteClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof careNoteService.delete>) => (
      careNoteService.delete(...args)
    ),
    ResponseClassCreator: createCareNoteDeleteObject,
    typeName: 'useCareNoteDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useCareNoteDelete.mock = () => new Error('Mock has not been implemented!');
useCareNoteDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCareNoteDelete.createObject = createCareNoteDeleteObject;

export const createCareNoteSearchObject = (value: APIResponse<PageResponse<CareNote>>) => new APIResponseClass<PageResponseClass<CareNoteClass>>(value, (value: PageResponse<CareNote>) => new PageResponseClass<CareNoteClass>(value, (value: CareNote) => new CareNoteClass(value)));
export const useCareNoteSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<CareNote>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<CareNote>,
    APIResponse<PageResponse<CareNote>>,
    // @ts-ignore
    typeof careNoteService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<CareNoteClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof careNoteService.search>) => (
      careNoteService.search(...args)
    ),
    ResponseClassCreator: createCareNoteSearchObject,
    typeName: 'useCareNoteSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useCareNoteSearch.mock = () => new Error('Mock has not been implemented!');
useCareNoteSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCareNoteSearch.createObject = createCareNoteSearchObject;

export enum CareTeamNotificationType {
	PATIENT_ENTER_MEETING = 'PATIENT_ENTER_MEETING',
	VISIT_REMINDER = 'VISIT_REMINDER',
}
export interface CareTeamNotificationRequest {
	type?: Nullable<CareTeamNotificationType>;
	clinicEventId?: Nullable<string>;
}
export interface CareTeamNotificationSendParams {
	request: CareTeamNotificationRequest;
}

export class CareTeamNotificationService {
  protected baseURL = '';

  protected basePath = '/v1/uc/care-team-notification';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public send({ baseUrl, params, headers }: RequestOption<CareTeamNotificationSendParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/send${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const careTeamNotificationService = new CareTeamNotificationService();

export const createCareTeamNotificationSendObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useCareTeamNotificationSend = <RCN = undefined>(configs: MakeRequestHookConfig<CareTeamNotificationSendParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    CareTeamNotificationSendParams,
    APIResponse<string>,
    typeof careTeamNotificationService.send,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof careTeamNotificationService.send>) => (
      careTeamNotificationService.send(...args)
    ),
    ResponseClassCreator: createCareTeamNotificationSendObject,
    typeName: 'useCareTeamNotificationSend',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useCareTeamNotificationSend.mock = () => new Error('Mock has not been implemented!');
useCareTeamNotificationSend.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCareTeamNotificationSend.createObject = createCareTeamNotificationSendObject;
export interface CgmAuthUnlinkRequest {
	externalUserId?: Nullable<string>;
}
export interface Class {
}
export interface Void {
	TYPE?: Nullable<Class<Void>>;
}
export class ClassClass {
  protected readonly _value: Class;

	constructor(value: Class) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }
}
export class VoidClass {
  protected readonly _value: Void;

	constructor(value: Void) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get TYPE() {
    return this._value.TYPE !== undefined && this._value.TYPE !== null ? new ClassClass(this._value.TYPE) : this._value.TYPE;
  }
}
export enum CgmBrandEnum {
	DEXCOM = 'DEXCOM',
	FREESTYLELIBRE = 'FREESTYLELIBRE',
	displayName = 'displayName',
}
export interface CgmDeviceModelDetail {
	id?: Nullable<number>;
	brand?: Nullable<string>;
	brandLogoUrl?: Nullable<string>;
	model?: Nullable<string>;
	createdAt?: Nullable<string>;
	updatedAt?: Nullable<string>;
}
export interface CgmSupportedDevice {
	brand?: Nullable<CgmBrandEnum>;
	brandLogoUrl?: Nullable<string>;
	devices?: Nullable<CgmDeviceModelDetail[]>;
}
export class CgmDeviceModelDetailClass {
  protected readonly _value: CgmDeviceModelDetail;

	constructor(value: CgmDeviceModelDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || 0;
  }

  get brand() {
    return this._value.brand;
  }

  get brandWithDefault() {
    return this._value.brand || '';
  }

  get brandLogoUrl() {
    return this._value.brandLogoUrl;
  }

  get brandLogoUrlWithDefault() {
    return this._value.brandLogoUrl || '';
  }

  get model() {
    return this._value.model;
  }

  get modelWithDefault() {
    return this._value.model || '';
  }

  get createdAt() {
    return (this._value.createdAt !== undefined && this._value.createdAt !== null) ? dayjs(this._value.createdAt) : this._value.createdAt;
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }
}
export class CgmSupportedDeviceClass {
  protected readonly _value: CgmSupportedDevice;

	constructor(value: CgmSupportedDevice) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get brand() {
    return this._value.brand;
  }

  brandIsIn(values: CgmBrandEnum[] = []) {
    return this._value.brand && values.includes(this._value.brand);
  }

  get brandLogoUrl() {
    return this._value.brandLogoUrl;
  }

  get brandLogoUrlWithDefault() {
    return this._value.brandLogoUrl || '';
  }

  get devices() {
    return this._value.devices !== undefined && this._value.devices !== null ? map(this._value.devices, (v) => new CgmDeviceModelDetailClass(v)) : this._value.devices;
  }

  get devicesWithDefault() {
    return this._value.devices || [];
  }
}
export enum CgmModelEnum {
	LIBRE2 = 'LIBRE2',
	LIBRE3 = 'LIBRE3',
	LIBRE_PRO = 'LIBRE_PRO',
	G6 = 'G6',
	G7 = 'G7',
	STELO = 'STELO',
	displayName = 'displayName',
}
export interface CgmAuthRequest {
	ucPatientId?: Nullable<string>;
	brands?: Nullable<CgmBrandEnum[]>;
	model?: Nullable<CgmModelEnum>;
	authSuccessRedirectUrl?: Nullable<string>;
	authFailureRedirectUrl?: Nullable<string>;
	sendMessage?: Nullable<boolean>;
}
export interface CgmAuthResponse {
	expires_in?: Nullable<number>;
	session_id?: Nullable<string>;
	status?: Nullable<string>;
	url?: Nullable<string>;
}
export class CgmAuthResponseClass {
  protected readonly _value: CgmAuthResponse;

	constructor(value: CgmAuthResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get expires_in() {
    return this._value.expires_in;
  }

  get expires_inWithDefault() {
    return this._value.expires_in || 0;
  }

  get session_id() {
    return this._value.session_id;
  }

  get session_idWithDefault() {
    return this._value.session_id || '';
  }

  get status() {
    return this._value.status;
  }

  get statusWithDefault() {
    return this._value.status || '';
  }

  get url() {
    return this._value.url;
  }

  get urlWithDefault() {
    return this._value.url || '';
  }
}
export interface Instant {
	EPOCH?: Nullable<Instant>;
	MIN_SECOND?: Nullable<number>;
	MAX_SECOND?: Nullable<number>;
	MIN?: Nullable<Instant>;
	MAX?: Nullable<Instant>;
	serialVersionUID?: Nullable<number>;
	seconds?: Nullable<number>;
	nanos?: Nullable<number>;
}
export interface CgmDataPoint {
	glucoseLevelFlag?: Nullable<number>;
	trendArrow?: Nullable<number>;
	bloodGlucoseMgPerDL?: Nullable<number>;
	collectedAt?: Nullable<Instant>;
}
export enum PatientCategoryEnum {
	DM2 = 'DM2',
	HTN = 'HTN',
	HLD = 'HLD',
	PRE_DM = 'PRE_DM',
	CKD = 'CKD',
	CHF = 'CHF',
	COPD = 'COPD',
	RDD = 'RDD',
	HYPOTENSION = 'HYPOTENSION',
	OBESITY = 'OBESITY',
	OTHERS = 'OTHERS',
	DM_OTHERS = 'DM_OTHERS',
	GDM = 'GDM',
	HYPERLIPIDEMIA = 'HYPERLIPIDEMIA',
	OTHER = 'OTHER',
	DM = 'DM',
}
export enum CgmDeviceStatusEnum {
	LINK_SENT = 'LINK_SENT',
	LINK_SUCCESS = 'LINK_SUCCESS',
	LINK_FAILED = 'LINK_FAILED',
	LINK_EXPIRED = 'LINK_EXPIRED',
	LINK_DEAUTHED = 'LINK_DEAUTHED',
}
export interface CgmDeviceResponse {
	id?: Nullable<number>;
	ucPatientId?: Nullable<string>;
	deviceId?: Nullable<string>;
	deviceStartTime?: Nullable<string>;
	deviceEndTime?: Nullable<string>;
	brand?: Nullable<string>;
	model?: Nullable<string>;
	createdAt?: Nullable<string>;
	updatedAt?: Nullable<string>;
}
export interface PatientCgmDeviceResponse {
	id?: Nullable<number>;
	ucPatientId?: Nullable<string>;
	externalUserId?: Nullable<string>;
	status?: Nullable<CgmDeviceStatusEnum>;
	brand?: Nullable<CgmBrandEnum>;
	model?: Nullable<CgmModelEnum>;
	linkRequestedAt?: Nullable<string>;
	linkedExpiredAt?: Nullable<string>;
	accountLinkedAt?: Nullable<string>;
	accountDeauthorized?: Nullable<boolean>;
	accountDeauthorizedAt?: Nullable<string>;
	lastCgmGeneratedAt?: Nullable<string>;
	deviceStatuses?: Nullable<CgmDeviceResponse[]>;
	createdAt?: Nullable<string>;
	updatedAt?: Nullable<string>;
}
export interface CgmMetricContext {
	patientId?: Nullable<string>;
	icdCodes?: Nullable<string[]>;
	patientCategory?: Nullable<PatientCategoryEnum>;
	patientCgmDeviceResponse?: Nullable<PatientCgmDeviceResponse>;
	dataInterval?: Nullable<number>;
	startTime?: Nullable<string>;
	endTime?: Nullable<string>;
}
export enum CgmMetricEnum {
	TOTAL_TIME = 'TOTAL_TIME',
	TIR = 'TIR',
	TAR_LEVEL_1 = 'TAR_LEVEL_1',
	TAR_LEVEL_2 = 'TAR_LEVEL_2',
	TBR_LEVEL_1 = 'TBR_LEVEL_1',
	TBR_LEVEL_2 = 'TBR_LEVEL_2',
	CGM_WEAR_DAYS = 'CGM_WEAR_DAYS',
	CGM_LEFT_DAYS = 'CGM_LEFT_DAYS',
	CGM_WEAR_MINUTES = 'CGM_WEAR_MINUTES',
	CGM_ACTIVE_TIME = 'CGM_ACTIVE_TIME',
	CGM_ACTIVE_PERCENTAGE = 'CGM_ACTIVE_PERCENTAGE',
	AVERAGE_GLUCOSE = 'AVERAGE_GLUCOSE',
	GMI = 'GMI',
	SD = 'SD',
	GV = 'GV',
	PERCENTILE = 'PERCENTILE',
	displayName = 'displayName',
}
export enum CgmGoalType {
	GT = 'GT',
	GTE = 'GTE',
	LT = 'LT',
	LTE = 'LTE',
}
export interface CgmThresholdRange {
	lowerBound?: Nullable<number>;
	upperBound?: Nullable<number>;
	goalValue?: Nullable<number>;
	goalUnit?: Nullable<string>;
	goalType?: Nullable<CgmGoalType>;
}
export interface CgmMetricResult {
	metricName?: Nullable<CgmMetricEnum>;
	values?: Nullable<{ [key: string]: number }>;
	threshold?: Nullable<CgmThresholdRange>;
}
export interface CgmReadingDto {
	rawData?: Nullable<CgmDataPoint[]>;
	metricContext?: Nullable<CgmMetricContext>;
	metrics?: Nullable<CgmMetricResult[]>;
}
export class InstantClass {
  protected readonly _value: Instant;

	constructor(value: Instant) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get EPOCH() {
    return this._value.EPOCH !== undefined && this._value.EPOCH !== null ? new InstantClass(this._value.EPOCH) : this._value.EPOCH;
  }

  get MIN_SECOND() {
    return this._value.MIN_SECOND;
  }

  get MIN_SECONDWithDefault() {
    return this._value.MIN_SECOND || 0;
  }

  get MAX_SECOND() {
    return this._value.MAX_SECOND;
  }

  get MAX_SECONDWithDefault() {
    return this._value.MAX_SECOND || 0;
  }

  get MIN() {
    return this._value.MIN !== undefined && this._value.MIN !== null ? new InstantClass(this._value.MIN) : this._value.MIN;
  }

  get MAX() {
    return this._value.MAX !== undefined && this._value.MAX !== null ? new InstantClass(this._value.MAX) : this._value.MAX;
  }

  get serialVersionUID() {
    return this._value.serialVersionUID;
  }

  get serialVersionUIDWithDefault() {
    return this._value.serialVersionUID || 0;
  }

  get seconds() {
    return this._value.seconds;
  }

  get secondsWithDefault() {
    return this._value.seconds || 0;
  }

  get nanos() {
    return this._value.nanos;
  }

  get nanosWithDefault() {
    return this._value.nanos || 0;
  }
}
export class CgmDataPointClass {
  protected readonly _value: CgmDataPoint;

	constructor(value: CgmDataPoint) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get glucoseLevelFlag() {
    return this._value.glucoseLevelFlag;
  }

  get glucoseLevelFlagWithDefault() {
    return this._value.glucoseLevelFlag || 0;
  }

  get trendArrow() {
    return this._value.trendArrow;
  }

  get trendArrowWithDefault() {
    return this._value.trendArrow || 0;
  }

  get bloodGlucoseMgPerDL() {
    return this._value.bloodGlucoseMgPerDL;
  }

  get bloodGlucoseMgPerDLWithDefault() {
    return this._value.bloodGlucoseMgPerDL || 0.0;
  }

  get collectedAt() {
    return this._value.collectedAt !== undefined && this._value.collectedAt !== null ? new InstantClass(this._value.collectedAt) : this._value.collectedAt;
  }
}
export class CgmDeviceResponseClass {
  protected readonly _value: CgmDeviceResponse;

	constructor(value: CgmDeviceResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || 0;
  }

  get ucPatientId() {
    return this._value.ucPatientId;
  }

  get ucPatientIdWithDefault() {
    return this._value.ucPatientId || '';
  }

  get deviceId() {
    return this._value.deviceId;
  }

  get deviceIdWithDefault() {
    return this._value.deviceId || '';
  }

  get deviceStartTime() {
    return this._value.deviceStartTime;
  }

  get deviceStartTimeWithDefault() {
    return this._value.deviceStartTime || '';
  }

  get deviceEndTime() {
    return this._value.deviceEndTime;
  }

  get deviceEndTimeWithDefault() {
    return this._value.deviceEndTime || '';
  }

  get brand() {
    return this._value.brand;
  }

  get brandWithDefault() {
    return this._value.brand || '';
  }

  get model() {
    return this._value.model;
  }

  get modelWithDefault() {
    return this._value.model || '';
  }

  get createdAt() {
    return this._value.createdAt;
  }

  get createdAtWithDefault() {
    return this._value.createdAt || '';
  }

  get updatedAt() {
    return this._value.updatedAt;
  }

  get updatedAtWithDefault() {
    return this._value.updatedAt || '';
  }
}
export class PatientCgmDeviceResponseClass {
  protected readonly _value: PatientCgmDeviceResponse;

	constructor(value: PatientCgmDeviceResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || 0;
  }

  get ucPatientId() {
    return this._value.ucPatientId;
  }

  get ucPatientIdWithDefault() {
    return this._value.ucPatientId || '';
  }

  get externalUserId() {
    return this._value.externalUserId;
  }

  get externalUserIdWithDefault() {
    return this._value.externalUserId || '';
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: CgmDeviceStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get brand() {
    return this._value.brand;
  }

  brandIsIn(values: CgmBrandEnum[] = []) {
    return this._value.brand && values.includes(this._value.brand);
  }

  get model() {
    return this._value.model;
  }

  modelIsIn(values: CgmModelEnum[] = []) {
    return this._value.model && values.includes(this._value.model);
  }

  get linkRequestedAt() {
    return (this._value.linkRequestedAt !== undefined && this._value.linkRequestedAt !== null) ? dayjs(this._value.linkRequestedAt) : this._value.linkRequestedAt;
  }

  get linkedExpiredAt() {
    return (this._value.linkedExpiredAt !== undefined && this._value.linkedExpiredAt !== null) ? dayjs(this._value.linkedExpiredAt) : this._value.linkedExpiredAt;
  }

  get accountLinkedAt() {
    return (this._value.accountLinkedAt !== undefined && this._value.accountLinkedAt !== null) ? dayjs(this._value.accountLinkedAt) : this._value.accountLinkedAt;
  }

  get accountDeauthorized() {
    return this._value.accountDeauthorized;
  }

  get accountDeauthorizedWithDefault() {
    return this._value.accountDeauthorized || false;
  }

  get accountDeauthorizedAt() {
    return (this._value.accountDeauthorizedAt !== undefined && this._value.accountDeauthorizedAt !== null) ? dayjs(this._value.accountDeauthorizedAt) : this._value.accountDeauthorizedAt;
  }

  get lastCgmGeneratedAt() {
    return (this._value.lastCgmGeneratedAt !== undefined && this._value.lastCgmGeneratedAt !== null) ? dayjs(this._value.lastCgmGeneratedAt) : this._value.lastCgmGeneratedAt;
  }

  get deviceStatuses() {
    return this._value.deviceStatuses !== undefined && this._value.deviceStatuses !== null ? map(this._value.deviceStatuses, (v) => new CgmDeviceResponseClass(v)) : this._value.deviceStatuses;
  }

  get deviceStatusesWithDefault() {
    return this._value.deviceStatuses || [];
  }

  get createdAt() {
    return (this._value.createdAt !== undefined && this._value.createdAt !== null) ? dayjs(this._value.createdAt) : this._value.createdAt;
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }
}
export class CgmMetricContextClass {
  protected readonly _value: CgmMetricContext;

	constructor(value: CgmMetricContext) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientId() {
    return this._value.patientId;
  }

  get icdCodes() {
    return this._value.icdCodes;
  }

  get icdCodesWithDefault() {
    return this._value.icdCodes || [];
  }

  get patientCategory() {
    return this._value.patientCategory;
  }

  patientCategoryIsIn(values: PatientCategoryEnum[] = []) {
    return this._value.patientCategory && values.includes(this._value.patientCategory);
  }

  get patientCgmDeviceResponse() {
    return this._value.patientCgmDeviceResponse !== undefined && this._value.patientCgmDeviceResponse !== null ? new PatientCgmDeviceResponseClass(this._value.patientCgmDeviceResponse) : this._value.patientCgmDeviceResponse;
  }

  get dataInterval() {
    return this._value.dataInterval;
  }

  get dataIntervalWithDefault() {
    return this._value.dataInterval || 0;
  }

  get startTime() {
    return (this._value.startTime !== undefined && this._value.startTime !== null) ? dayjs(this._value.startTime) : this._value.startTime;
  }

  get endTime() {
    return (this._value.endTime !== undefined && this._value.endTime !== null) ? dayjs(this._value.endTime) : this._value.endTime;
  }
}
export class CgmThresholdRangeClass {
  protected readonly _value: CgmThresholdRange;

	constructor(value: CgmThresholdRange) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get lowerBound() {
    return this._value.lowerBound;
  }

  get lowerBoundWithDefault() {
    return this._value.lowerBound || 0.0;
  }

  get upperBound() {
    return this._value.upperBound;
  }

  get upperBoundWithDefault() {
    return this._value.upperBound || 0.0;
  }

  get goalValue() {
    return this._value.goalValue;
  }

  get goalValueWithDefault() {
    return this._value.goalValue || 0.0;
  }

  get goalUnit() {
    return this._value.goalUnit;
  }

  get goalUnitWithDefault() {
    return this._value.goalUnit || '';
  }

  get goalType() {
    return this._value.goalType;
  }

  goalTypeIsIn(values: CgmGoalType[] = []) {
    return this._value.goalType && values.includes(this._value.goalType);
  }
}
export class CgmMetricResultClass {
  protected readonly _value: CgmMetricResult;

	constructor(value: CgmMetricResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get metricName() {
    return this._value.metricName;
  }

  metricNameIsIn(values: CgmMetricEnum[] = []) {
    return this._value.metricName && values.includes(this._value.metricName);
  }

  get values() {
    return this._value.values;
  }

  get valuesWithDefault() {
    return this._value.values || {};
  }

  get threshold() {
    return this._value.threshold !== undefined && this._value.threshold !== null ? new CgmThresholdRangeClass(this._value.threshold) : this._value.threshold;
  }
}
export class CgmReadingDtoClass {
  protected readonly _value: CgmReadingDto;

	constructor(value: CgmReadingDto) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get rawData() {
    return this._value.rawData !== undefined && this._value.rawData !== null ? map(this._value.rawData, (v) => new CgmDataPointClass(v)) : this._value.rawData;
  }

  get rawDataWithDefault() {
    return this._value.rawData || [];
  }

  get metricContext() {
    return this._value.metricContext !== undefined && this._value.metricContext !== null ? new CgmMetricContextClass(this._value.metricContext) : this._value.metricContext;
  }

  get metrics() {
    return this._value.metrics !== undefined && this._value.metrics !== null ? map(this._value.metrics, (v) => new CgmMetricResultClass(v)) : this._value.metrics;
  }

  get metricsWithDefault() {
    return this._value.metrics || [];
  }
}
export interface CgmDeviceListRequest {
	statusIn?: Nullable<CgmDeviceStatusEnum[]>;
	brandIn?: Nullable<CgmBrandEnum[]>;
}
export interface PatientCgmDeviceDto {
	id?: Nullable<number>;
	ucPatientId?: Nullable<string>;
	externalUserId?: Nullable<string>;
	status?: Nullable<CgmDeviceStatusEnum>;
	brand?: Nullable<CgmBrandEnum>;
	model?: Nullable<CgmModelEnum>;
	linkRequestedAt?: Nullable<string>;
	linkedExpiredAt?: Nullable<string>;
	accountLinkedAt?: Nullable<string>;
	accountDeauthorized?: Nullable<boolean>;
	accountDeauthorizedAt?: Nullable<string>;
	lastCgmGeneratedAt?: Nullable<string>;
	deviceStatus?: Nullable<CgmDeviceResponse>;
	createdAt?: Nullable<string>;
	updatedAt?: Nullable<string>;
}
export class PatientCgmDeviceDtoClass {
  protected readonly _value: PatientCgmDeviceDto;

	constructor(value: PatientCgmDeviceDto) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || 0;
  }

  get ucPatientId() {
    return this._value.ucPatientId;
  }

  get ucPatientIdWithDefault() {
    return this._value.ucPatientId || '';
  }

  get externalUserId() {
    return this._value.externalUserId;
  }

  get externalUserIdWithDefault() {
    return this._value.externalUserId || '';
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: CgmDeviceStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get brand() {
    return this._value.brand;
  }

  brandIsIn(values: CgmBrandEnum[] = []) {
    return this._value.brand && values.includes(this._value.brand);
  }

  get model() {
    return this._value.model;
  }

  modelIsIn(values: CgmModelEnum[] = []) {
    return this._value.model && values.includes(this._value.model);
  }

  get linkRequestedAt() {
    return (this._value.linkRequestedAt !== undefined && this._value.linkRequestedAt !== null) ? dayjs(this._value.linkRequestedAt) : this._value.linkRequestedAt;
  }

  get linkedExpiredAt() {
    return (this._value.linkedExpiredAt !== undefined && this._value.linkedExpiredAt !== null) ? dayjs(this._value.linkedExpiredAt) : this._value.linkedExpiredAt;
  }

  get accountLinkedAt() {
    return (this._value.accountLinkedAt !== undefined && this._value.accountLinkedAt !== null) ? dayjs(this._value.accountLinkedAt) : this._value.accountLinkedAt;
  }

  get accountDeauthorized() {
    return this._value.accountDeauthorized;
  }

  get accountDeauthorizedWithDefault() {
    return this._value.accountDeauthorized || false;
  }

  get accountDeauthorizedAt() {
    return (this._value.accountDeauthorizedAt !== undefined && this._value.accountDeauthorizedAt !== null) ? dayjs(this._value.accountDeauthorizedAt) : this._value.accountDeauthorizedAt;
  }

  get lastCgmGeneratedAt() {
    return (this._value.lastCgmGeneratedAt !== undefined && this._value.lastCgmGeneratedAt !== null) ? dayjs(this._value.lastCgmGeneratedAt) : this._value.lastCgmGeneratedAt;
  }

  get deviceStatus() {
    return this._value.deviceStatus !== undefined && this._value.deviceStatus !== null ? new CgmDeviceResponseClass(this._value.deviceStatus) : this._value.deviceStatus;
  }

  get createdAt() {
    return (this._value.createdAt !== undefined && this._value.createdAt !== null) ? dayjs(this._value.createdAt) : this._value.createdAt;
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }
}
export interface CgmUnlinkParams {
	request: CgmAuthUnlinkRequest;
}

export interface CgmGetCgmSupportedDevicesParams {
}

export interface CgmSendAuthMessageParams {
	request: CgmAuthRequest;
}

export interface CgmFetchLinkSessionParams {
	request: CgmAuthRequest;
}

export interface CgmGetPatientCgmReadingParams {
	patientId: string;
	startDate?: string;
	endDate?: string;
}

export interface CgmGetPatientCgmDeviceParams {
	patientId: string;
	request: CgmDeviceListRequest;
}

export class CgmService {
  protected baseURL = '';

  protected basePath = '/v1/uc/cgm';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public unlink({ baseUrl, params, headers }: RequestOption<CgmUnlinkParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Void>>(`${baseUrl || this.baseURL}${this.basePath}/auth/unlink${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getCgmSupportedDevices({ baseUrl, params, headers }: RequestOption<CgmGetCgmSupportedDevicesParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<CgmSupportedDevice[]>>(`${baseUrl || this.baseURL}${this.basePath}/supported-devices${getQueryStrings(queryList)}`, { headers });
	}

	public sendAuthMessage({ baseUrl, params, headers }: RequestOption<CgmSendAuthMessageParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Void>>(`${baseUrl || this.baseURL}${this.basePath}/auth/send-link-message${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public fetchLinkSession({ baseUrl, params, headers }: RequestOption<CgmFetchLinkSessionParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<CgmAuthResponse>>(`${baseUrl || this.baseURL}${this.basePath}/auth/link-session${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getPatientCgmReading({ baseUrl, params, headers }: RequestOption<CgmGetPatientCgmReadingParams>) {
		const queryList: QueryParam[] = [];
		if (params.startDate !== undefined && params.startDate !== null) {
			queryList.push({ name: 'startDate', value: params.startDate.toString() });
		}
		if (params.endDate !== undefined && params.endDate !== null) {
			queryList.push({ name: 'endDate', value: params.endDate.toString() });
		}
		return axios.get<APIResponse<CgmReadingDto>>(`${baseUrl || this.baseURL}${this.basePath}/patients/${params.patientId}/reading${getQueryStrings(queryList)}`, { headers });
	}

	public getPatientCgmDevice({ baseUrl, params, headers }: RequestOption<CgmGetPatientCgmDeviceParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PatientCgmDeviceDto[]>>(`${baseUrl || this.baseURL}${this.basePath}/patients/${params.patientId}/devices/list${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const cgmService = new CgmService();

export const createCgmUnlinkObject = (value: APIResponse<Void>) => new APIResponseClass<VoidClass>(value, (value: Void) => new VoidClass(value));

export const useCgmUnlink = <RCN = undefined>(configs: MakeRequestHookConfig<CgmUnlinkParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    CgmUnlinkParams,
    APIResponse<Void>,
    typeof cgmService.unlink,
    AjaxOptions,
    APIResponseClass<VoidClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof cgmService.unlink>) => (
      cgmService.unlink(...args)
    ),
    ResponseClassCreator: createCgmUnlinkObject,
    typeName: 'useCgmUnlink',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useCgmUnlink.mock = () => new Error('Mock has not been implemented!');
useCgmUnlink.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCgmUnlink.createObject = createCgmUnlinkObject;

export const createCgmGetCgmSupportedDevicesObject = (value: APIResponse<CgmSupportedDevice[]>) => new APIResponseClass<CgmSupportedDeviceClass[]>(value, (v) => map(v, (value: CgmSupportedDevice) => new CgmSupportedDeviceClass(value)));

export const useCgmGetCgmSupportedDevices = <RCN = undefined>(configs: MakeRequestHookConfig<CgmGetCgmSupportedDevicesParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    CgmGetCgmSupportedDevicesParams,
    APIResponse<CgmSupportedDevice[]>,
    typeof cgmService.getCgmSupportedDevices,
    GetOptions,
    APIResponseClass<CgmSupportedDeviceClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof cgmService.getCgmSupportedDevices>) => (
      cgmService.getCgmSupportedDevices(...args)
    ),
    ResponseClassCreator: createCgmGetCgmSupportedDevicesObject,
    typeName: 'useCgmGetCgmSupportedDevices',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useCgmGetCgmSupportedDevices.mock = () => new Error('Mock has not been implemented!');
useCgmGetCgmSupportedDevices.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCgmGetCgmSupportedDevices.createObject = createCgmGetCgmSupportedDevicesObject;

export const createCgmSendAuthMessageObject = (value: APIResponse<Void>) => new APIResponseClass<VoidClass>(value, (value: Void) => new VoidClass(value));

export const useCgmSendAuthMessage = <RCN = undefined>(configs: MakeRequestHookConfig<CgmSendAuthMessageParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    CgmSendAuthMessageParams,
    APIResponse<Void>,
    typeof cgmService.sendAuthMessage,
    AjaxOptions,
    APIResponseClass<VoidClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof cgmService.sendAuthMessage>) => (
      cgmService.sendAuthMessage(...args)
    ),
    ResponseClassCreator: createCgmSendAuthMessageObject,
    typeName: 'useCgmSendAuthMessage',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useCgmSendAuthMessage.mock = () => new Error('Mock has not been implemented!');
useCgmSendAuthMessage.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCgmSendAuthMessage.createObject = createCgmSendAuthMessageObject;

export const createCgmFetchLinkSessionObject = (value: APIResponse<CgmAuthResponse>) => new APIResponseClass<CgmAuthResponseClass>(value, (value: CgmAuthResponse) => new CgmAuthResponseClass(value));

export const useCgmFetchLinkSession = <RCN = undefined>(configs: MakeRequestHookConfig<CgmFetchLinkSessionParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    CgmFetchLinkSessionParams,
    APIResponse<CgmAuthResponse>,
    typeof cgmService.fetchLinkSession,
    AjaxOptions,
    APIResponseClass<CgmAuthResponseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof cgmService.fetchLinkSession>) => (
      cgmService.fetchLinkSession(...args)
    ),
    ResponseClassCreator: createCgmFetchLinkSessionObject,
    typeName: 'useCgmFetchLinkSession',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useCgmFetchLinkSession.mock = () => new Error('Mock has not been implemented!');
useCgmFetchLinkSession.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCgmFetchLinkSession.createObject = createCgmFetchLinkSessionObject;

export const createCgmGetPatientCgmReadingObject = (value: APIResponse<CgmReadingDto>) => new APIResponseClass<CgmReadingDtoClass>(value, (value: CgmReadingDto) => new CgmReadingDtoClass(value));

export const useCgmGetPatientCgmReading = <RCN = undefined>(configs: MakeRequestHookConfig<CgmGetPatientCgmReadingParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    CgmGetPatientCgmReadingParams,
    APIResponse<CgmReadingDto>,
    typeof cgmService.getPatientCgmReading,
    GetOptions,
    APIResponseClass<CgmReadingDtoClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof cgmService.getPatientCgmReading>) => (
      cgmService.getPatientCgmReading(...args)
    ),
    ResponseClassCreator: createCgmGetPatientCgmReadingObject,
    typeName: 'useCgmGetPatientCgmReading',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useCgmGetPatientCgmReading.mock = () => new Error('Mock has not been implemented!');
useCgmGetPatientCgmReading.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCgmGetPatientCgmReading.createObject = createCgmGetPatientCgmReadingObject;

export const createCgmGetPatientCgmDeviceObject = (value: APIResponse<PatientCgmDeviceDto[]>) => new APIResponseClass<PatientCgmDeviceDtoClass[]>(value, (v) => map(v, (value: PatientCgmDeviceDto) => new PatientCgmDeviceDtoClass(value)));

export const useCgmGetPatientCgmDevice = <RCN = undefined>(configs: MakeRequestHookConfig<CgmGetPatientCgmDeviceParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    CgmGetPatientCgmDeviceParams,
    APIResponse<PatientCgmDeviceDto[]>,
    typeof cgmService.getPatientCgmDevice,
    AjaxOptions,
    APIResponseClass<PatientCgmDeviceDtoClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof cgmService.getPatientCgmDevice>) => (
      cgmService.getPatientCgmDevice(...args)
    ),
    ResponseClassCreator: createCgmGetPatientCgmDeviceObject,
    typeName: 'useCgmGetPatientCgmDevice',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useCgmGetPatientCgmDevice.mock = () => new Error('Mock has not been implemented!');
useCgmGetPatientCgmDevice.getLastMockedValue = () => new Error('Mock has not been implemented!');
useCgmGetPatientCgmDevice.createObject = createCgmGetPatientCgmDeviceObject;
export enum ChallengeRewardCategoryEnum {
	POINTS = 'POINTS',
	REWARD_REDEEM = 'REWARD_REDEEM',
}
export interface ChallengeRewardDetails extends BaseModel {
	memberId?: Nullable<string>;
	category?: Nullable<ChallengeRewardCategoryEnum>;
	type?: Nullable<string>;
	rewardDate?: Nullable<string>;
	measureId?: Nullable<string>;
	referenceId?: Nullable<string>;
	rewardRedeemId?: Nullable<string>;
	date?: Nullable<string>;
	points?: Nullable<number>;
	timezone?: Nullable<string>;
	categoryIn?: Nullable<InFilter<ChallengeRewardCategoryEnum>>;
	dateRange?: Nullable<DateRangeFilter>;
}
export interface ChallengeRewardStatistics {
	monthOfYear?: Nullable<string>;
	totalPoints?: Nullable<number>;
	milestones?: Nullable<ChallengeRewardDetails[]>;
}
export class ChallengeRewardDetailsClass extends BaseModelClass {
  protected readonly _value: ChallengeRewardDetails;

	constructor(value: ChallengeRewardDetails) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get category() {
    return this._value.category;
  }

  categoryIsIn(values: ChallengeRewardCategoryEnum[] = []) {
    return this._value.category && values.includes(this._value.category);
  }

  get type() {
    return this._value.type;
  }

  get typeWithDefault() {
    return this._value.type || '';
  }

  get rewardDate() {
    return this._value.rewardDate;
  }

  get rewardDateWithDefault() {
    return this._value.rewardDate || '';
  }

  get measureId() {
    return this._value.measureId;
  }

  get referenceId() {
    return this._value.referenceId;
  }

  get rewardRedeemId() {
    return this._value.rewardRedeemId;
  }

  get date() {
    return (this._value.date !== undefined && this._value.date !== null) ? dayjs(this._value.date) : this._value.date;
  }

  get points() {
    return this._value.points;
  }

  get pointsWithDefault() {
    return this._value.points || 0;
  }

  get timezone() {
    return this._value.timezone;
  }

  get timezoneWithDefault() {
    return this._value.timezone || '';
  }

  get categoryIn() {
    return this._value.categoryIn !== undefined && this._value.categoryIn !== null ? new InFilterClass(this._value.categoryIn) : this._value.categoryIn;
  }

  get dateRange() {
    return this._value.dateRange !== undefined && this._value.dateRange !== null ? new DateRangeFilterClass(this._value.dateRange) : this._value.dateRange;
  }
}
export class ChallengeRewardStatisticsClass {
  protected readonly _value: ChallengeRewardStatistics;

	constructor(value: ChallengeRewardStatistics) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get monthOfYear() {
    return this._value.monthOfYear;
  }

  get monthOfYearWithDefault() {
    return this._value.monthOfYear || '';
  }

  get totalPoints() {
    return this._value.totalPoints;
  }

  get totalPointsWithDefault() {
    return this._value.totalPoints || 0;
  }

  get milestones() {
    return this._value.milestones !== undefined && this._value.milestones !== null ? map(this._value.milestones, (v) => new ChallengeRewardDetailsClass(v)) : this._value.milestones;
  }

  get milestonesWithDefault() {
    return this._value.milestones || [];
  }
}
export interface ChallengeRewardDetailsGetRecommendedReadsParams {
	memberId: string;
	monthOfYear: string;
}

export interface ChallengeRewardDetailsFindByMemberIdParams {
	memberId: string;
}

export class ChallengeRewardDetailsService extends BaseController<ChallengeRewardDetails, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/challenge-reward-details';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getRecommendedReads({ baseUrl, params, headers }: RequestOption<ChallengeRewardDetailsGetRecommendedReadsParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<ChallengeRewardStatistics>>(`${baseUrl || this.baseURL}${this.basePath}/statistics/${params.memberId}/${params.monthOfYear}${getQueryStrings(queryList)}`, { headers });
	}

	public findByMemberId({ baseUrl, params, headers }: RequestOption<ChallengeRewardDetailsFindByMemberIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<ChallengeRewardDetails[]>>(`${baseUrl || this.baseURL}${this.basePath}/list/${params.memberId}${getQueryStrings(queryList)}`, { headers });
	}
}

export const challengeRewardDetailsService = new ChallengeRewardDetailsService();

export const createChallengeRewardDetailsGetRecommendedReadsObject = (value: APIResponse<ChallengeRewardStatistics>) => new APIResponseClass<ChallengeRewardStatisticsClass>(value, (value: ChallengeRewardStatistics) => new ChallengeRewardStatisticsClass(value));

export const useChallengeRewardDetailsGetRecommendedReads = <RCN = undefined>(configs: MakeRequestHookConfig<ChallengeRewardDetailsGetRecommendedReadsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ChallengeRewardDetailsGetRecommendedReadsParams,
    APIResponse<ChallengeRewardStatistics>,
    typeof challengeRewardDetailsService.getRecommendedReads,
    GetOptions,
    APIResponseClass<ChallengeRewardStatisticsClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof challengeRewardDetailsService.getRecommendedReads>) => (
      challengeRewardDetailsService.getRecommendedReads(...args)
    ),
    ResponseClassCreator: createChallengeRewardDetailsGetRecommendedReadsObject,
    typeName: 'useChallengeRewardDetailsGetRecommendedReads',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useChallengeRewardDetailsGetRecommendedReads.mock = () => new Error('Mock has not been implemented!');
useChallengeRewardDetailsGetRecommendedReads.getLastMockedValue = () => new Error('Mock has not been implemented!');
useChallengeRewardDetailsGetRecommendedReads.createObject = createChallengeRewardDetailsGetRecommendedReadsObject;

export const createChallengeRewardDetailsFindByMemberIdObject = (value: APIResponse<ChallengeRewardDetails[]>) => new APIResponseClass<ChallengeRewardDetailsClass[]>(value, (v) => map(v, (value: ChallengeRewardDetails) => new ChallengeRewardDetailsClass(value)));

export const useChallengeRewardDetailsFindByMemberId = <RCN = undefined>(configs: MakeRequestHookConfig<ChallengeRewardDetailsFindByMemberIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ChallengeRewardDetailsFindByMemberIdParams,
    APIResponse<ChallengeRewardDetails[]>,
    typeof challengeRewardDetailsService.findByMemberId,
    GetOptions,
    APIResponseClass<ChallengeRewardDetailsClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof challengeRewardDetailsService.findByMemberId>) => (
      challengeRewardDetailsService.findByMemberId(...args)
    ),
    ResponseClassCreator: createChallengeRewardDetailsFindByMemberIdObject,
    typeName: 'useChallengeRewardDetailsFindByMemberId',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useChallengeRewardDetailsFindByMemberId.mock = () => new Error('Mock has not been implemented!');
useChallengeRewardDetailsFindByMemberId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useChallengeRewardDetailsFindByMemberId.createObject = createChallengeRewardDetailsFindByMemberIdObject;


export const createChallengeRewardDetailsGetObject = (value: APIResponse<ChallengeRewardDetails>) => new APIResponseClass<ChallengeRewardDetailsClass>(value, (value: ChallengeRewardDetails) => new ChallengeRewardDetailsClass(value));
export const useChallengeRewardDetailsGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<ChallengeRewardDetails>,
    // @ts-ignore
    typeof challengeRewardDetailsService.get,
    GetOptions,
    APIResponseClass<ChallengeRewardDetailsClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof challengeRewardDetailsService.get>) => (
      challengeRewardDetailsService.get(...args)
    ),
    ResponseClassCreator: createChallengeRewardDetailsGetObject,
    typeName: 'useChallengeRewardDetailsGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useChallengeRewardDetailsGet.mock = () => new Error('Mock has not been implemented!');
useChallengeRewardDetailsGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useChallengeRewardDetailsGet.createObject = createChallengeRewardDetailsGetObject;

export const createChallengeRewardDetailsUpdateObject = (value: APIResponse<ChallengeRewardDetails>) => new APIResponseClass<ChallengeRewardDetailsClass>(value, (value: ChallengeRewardDetails) => new ChallengeRewardDetailsClass(value));
export const useChallengeRewardDetailsUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, ChallengeRewardDetails>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, ChallengeRewardDetails>,
    APIResponse<ChallengeRewardDetails>,
    // @ts-ignore
    typeof challengeRewardDetailsService.update,
    AjaxOptions,
    APIResponseClass<ChallengeRewardDetailsClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof challengeRewardDetailsService.update>) => (
      challengeRewardDetailsService.update(...args)
    ),
    ResponseClassCreator: createChallengeRewardDetailsUpdateObject,
    typeName: 'useChallengeRewardDetailsUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useChallengeRewardDetailsUpdate.mock = () => new Error('Mock has not been implemented!');
useChallengeRewardDetailsUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useChallengeRewardDetailsUpdate.createObject = createChallengeRewardDetailsUpdateObject;

export const createChallengeRewardDetailsInsertObject = (value: APIResponse<ChallengeRewardDetails>) => new APIResponseClass<ChallengeRewardDetailsClass>(value, (value: ChallengeRewardDetails) => new ChallengeRewardDetailsClass(value));
export const useChallengeRewardDetailsInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<ChallengeRewardDetails>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<ChallengeRewardDetails>,
    APIResponse<ChallengeRewardDetails>,
    // @ts-ignore
    typeof challengeRewardDetailsService.insert,
    AjaxOptions,
    APIResponseClass<ChallengeRewardDetailsClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof challengeRewardDetailsService.insert>) => (
      challengeRewardDetailsService.insert(...args)
    ),
    ResponseClassCreator: createChallengeRewardDetailsInsertObject,
    typeName: 'useChallengeRewardDetailsInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useChallengeRewardDetailsInsert.mock = () => new Error('Mock has not been implemented!');
useChallengeRewardDetailsInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useChallengeRewardDetailsInsert.createObject = createChallengeRewardDetailsInsertObject;

export const createChallengeRewardDetailsDeleteObject = (value: APIResponse<ChallengeRewardDetails>) => new APIResponseClass<ChallengeRewardDetailsClass>(value, (value: ChallengeRewardDetails) => new ChallengeRewardDetailsClass(value));
export const useChallengeRewardDetailsDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<ChallengeRewardDetails>,
    // @ts-ignore
    typeof challengeRewardDetailsService.delete,
    AjaxOptions,
    APIResponseClass<ChallengeRewardDetailsClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof challengeRewardDetailsService.delete>) => (
      challengeRewardDetailsService.delete(...args)
    ),
    ResponseClassCreator: createChallengeRewardDetailsDeleteObject,
    typeName: 'useChallengeRewardDetailsDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useChallengeRewardDetailsDelete.mock = () => new Error('Mock has not been implemented!');
useChallengeRewardDetailsDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useChallengeRewardDetailsDelete.createObject = createChallengeRewardDetailsDeleteObject;

export const createChallengeRewardDetailsSearchObject = (value: APIResponse<PageResponse<ChallengeRewardDetails>>) => new APIResponseClass<PageResponseClass<ChallengeRewardDetailsClass>>(value, (value: PageResponse<ChallengeRewardDetails>) => new PageResponseClass<ChallengeRewardDetailsClass>(value, (value: ChallengeRewardDetails) => new ChallengeRewardDetailsClass(value)));
export const useChallengeRewardDetailsSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<ChallengeRewardDetails>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<ChallengeRewardDetails>,
    APIResponse<PageResponse<ChallengeRewardDetails>>,
    // @ts-ignore
    typeof challengeRewardDetailsService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ChallengeRewardDetailsClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof challengeRewardDetailsService.search>) => (
      challengeRewardDetailsService.search(...args)
    ),
    ResponseClassCreator: createChallengeRewardDetailsSearchObject,
    typeName: 'useChallengeRewardDetailsSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useChallengeRewardDetailsSearch.mock = () => new Error('Mock has not been implemented!');
useChallengeRewardDetailsSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useChallengeRewardDetailsSearch.createObject = createChallengeRewardDetailsSearchObject;

export interface NotificationRequestBody {
	message?: Nullable<string>;
	messageType?: Nullable<string>;
	imageUrl?: Nullable<string>;
	fileKey?: Nullable<string>;
	displayName?: Nullable<string>;
	userRole?: Nullable<string>;
	title?: Nullable<string>;
	senderId?: Nullable<string>;
	userIds?: Nullable<string[]>;
	eventId?: Nullable<string>;
	uniqueIdentifier?: Nullable<string>;
}
export interface ChsSendBatchMessageParams {
	notificationRequestBody: NotificationRequestBody;
}

export class ChsService {
  protected baseURL = '';

  protected basePath = '/v1/uc/chs';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public sendBatchMessage({ baseUrl, params, headers }: RequestOption<ChsSendBatchMessageParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/batch-message${getQueryStrings(queryList)}`, params.notificationRequestBody, { headers });
	}
}

export const chsService = new ChsService();

export const createChsSendBatchMessageObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useChsSendBatchMessage = <RCN = undefined>(configs: MakeRequestHookConfig<ChsSendBatchMessageParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ChsSendBatchMessageParams,
    APIResponse<string>,
    typeof chsService.sendBatchMessage,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof chsService.sendBatchMessage>) => (
      chsService.sendBatchMessage(...args)
    ),
    ResponseClassCreator: createChsSendBatchMessageObject,
    typeName: 'useChsSendBatchMessage',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useChsSendBatchMessage.mock = () => new Error('Mock has not been implemented!');
useChsSendBatchMessage.getLastMockedValue = () => new Error('Mock has not been implemented!');
useChsSendBatchMessage.createObject = createChsSendBatchMessageObject;
export interface MonthlyEnrollGoalItem {
	date?: Nullable<string>;
	goal?: Nullable<number>;
}
export interface DailyPatientNum {
	date?: Nullable<string>;
	patientNum?: Nullable<number>;
}
export interface ClinicAnalytics extends BaseModel {
	clinicId?: Nullable<string>;
	enrolledPatientNum?: Nullable<number>;
	potentialPatientNum?: Nullable<number>;
	enrolledPctTotal?: Nullable<number>;
	newPatientNumThisMonth?: Nullable<number>;
	monthlyEnrollGoal?: Nullable<number>;
	enrolledPctThisMonth?: Nullable<number>;
	monthlyEnrollGoalList?: Nullable<MonthlyEnrollGoalItem[]>;
	newPatientNumLastMonth?: Nullable<number>;
	newPatientNum?: Nullable<number>;
	dailyPatientNums?: Nullable<DailyPatientNum[]>;
}
export interface ClinicAnalyticsGetRequest {
	clinicId: string;
	startDate: string;
	endDate: string;
}
export class MonthlyEnrollGoalItemClass {
  protected readonly _value: MonthlyEnrollGoalItem;

	constructor(value: MonthlyEnrollGoalItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get date() {
    return (this._value.date !== undefined && this._value.date !== null) ? dayjs(this._value.date) : this._value.date;
  }

  get goal() {
    return this._value.goal;
  }

  get goalWithDefault() {
    return this._value.goal || 0;
  }
}
export class DailyPatientNumClass {
  protected readonly _value: DailyPatientNum;

	constructor(value: DailyPatientNum) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get date() {
    return (this._value.date !== undefined && this._value.date !== null) ? dayjs(this._value.date) : this._value.date;
  }

  get patientNum() {
    return this._value.patientNum;
  }

  get patientNumWithDefault() {
    return this._value.patientNum || 0;
  }
}
export class ClinicAnalyticsClass extends BaseModelClass {
  protected readonly _value: ClinicAnalytics;

	constructor(value: ClinicAnalytics) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get clinicId() {
    return this._value.clinicId;
  }

  get enrolledPatientNum() {
    return this._value.enrolledPatientNum;
  }

  get enrolledPatientNumWithDefault() {
    return this._value.enrolledPatientNum || 0;
  }

  get potentialPatientNum() {
    return this._value.potentialPatientNum;
  }

  get potentialPatientNumWithDefault() {
    return this._value.potentialPatientNum || 0;
  }

  get enrolledPctTotal() {
    return this._value.enrolledPctTotal;
  }

  get enrolledPctTotalWithDefault() {
    return this._value.enrolledPctTotal || 0.0;
  }

  get newPatientNumThisMonth() {
    return this._value.newPatientNumThisMonth;
  }

  get newPatientNumThisMonthWithDefault() {
    return this._value.newPatientNumThisMonth || 0;
  }

  get monthlyEnrollGoal() {
    return this._value.monthlyEnrollGoal;
  }

  get monthlyEnrollGoalWithDefault() {
    return this._value.monthlyEnrollGoal || 0;
  }

  get enrolledPctThisMonth() {
    return this._value.enrolledPctThisMonth;
  }

  get enrolledPctThisMonthWithDefault() {
    return this._value.enrolledPctThisMonth || 0.0;
  }

  get monthlyEnrollGoalList() {
    return this._value.monthlyEnrollGoalList !== undefined && this._value.monthlyEnrollGoalList !== null ? map(this._value.monthlyEnrollGoalList, (v) => new MonthlyEnrollGoalItemClass(v)) : this._value.monthlyEnrollGoalList;
  }

  get monthlyEnrollGoalListWithDefault() {
    return this._value.monthlyEnrollGoalList || [];
  }

  get newPatientNumLastMonth() {
    return this._value.newPatientNumLastMonth;
  }

  get newPatientNumLastMonthWithDefault() {
    return this._value.newPatientNumLastMonth || 0;
  }

  get newPatientNum() {
    return this._value.newPatientNum;
  }

  get newPatientNumWithDefault() {
    return this._value.newPatientNum || 0;
  }

  get dailyPatientNums() {
    return this._value.dailyPatientNums !== undefined && this._value.dailyPatientNums !== null ? map(this._value.dailyPatientNums, (v) => new DailyPatientNumClass(v)) : this._value.dailyPatientNums;
  }

  get dailyPatientNumsWithDefault() {
    return this._value.dailyPatientNums || [];
  }
}
export interface ClinicAnalyticsGetClinicAnalyticsParams {
	request: ClinicAnalyticsGetRequest;
}

export interface ClinicAnalyticsUpdatePotentialPatientNumParams {
	clinicId: string;
	number: number;
}

export interface ClinicAnalyticsUpdateMonthlyEnrollGoalParams {
	clinicId: string;
	number: number;
}

export class ClinicAnalyticsService extends BaseController<ClinicAnalytics, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/clinic-analytics';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getClinicAnalytics({ baseUrl, params, headers }: RequestOption<ClinicAnalyticsGetClinicAnalyticsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<ClinicAnalytics>>(`${baseUrl || this.baseURL}${this.basePath}/get-one${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public updatePotentialPatientNum({ baseUrl, params, headers }: RequestOption<ClinicAnalyticsUpdatePotentialPatientNumParams>) {
		const queryList: QueryParam[] = [];
		if (params.clinicId !== undefined && params.clinicId !== null) {
			queryList.push({ name: 'clinicId', value: params.clinicId.toString() });
		}
		if (params.number !== undefined && params.number !== null) {
			queryList.push({ name: 'number', value: params.number.toString() });
		}
		return axios.put<APIResponse<ClinicAnalytics>>(`${baseUrl || this.baseURL}${this.basePath}/potential-patient-num${getQueryStrings(queryList)}`, {}, { headers });
	}

	public updateMonthlyEnrollGoal({ baseUrl, params, headers }: RequestOption<ClinicAnalyticsUpdateMonthlyEnrollGoalParams>) {
		const queryList: QueryParam[] = [];
		if (params.clinicId !== undefined && params.clinicId !== null) {
			queryList.push({ name: 'clinicId', value: params.clinicId.toString() });
		}
		if (params.number !== undefined && params.number !== null) {
			queryList.push({ name: 'number', value: params.number.toString() });
		}
		return axios.put<APIResponse<ClinicAnalytics>>(`${baseUrl || this.baseURL}${this.basePath}/monthly-enroll-goal${getQueryStrings(queryList)}`, {}, { headers });
	}
}

export const clinicAnalyticsService = new ClinicAnalyticsService();

export const createClinicAnalyticsGetClinicAnalyticsObject = (value: APIResponse<ClinicAnalytics>) => new APIResponseClass<ClinicAnalyticsClass>(value, (value: ClinicAnalytics) => new ClinicAnalyticsClass(value));

export const useClinicAnalyticsGetClinicAnalytics = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicAnalyticsGetClinicAnalyticsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicAnalyticsGetClinicAnalyticsParams,
    APIResponse<ClinicAnalytics>,
    typeof clinicAnalyticsService.getClinicAnalytics,
    AjaxOptions,
    APIResponseClass<ClinicAnalyticsClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicAnalyticsService.getClinicAnalytics>) => (
      clinicAnalyticsService.getClinicAnalytics(...args)
    ),
    ResponseClassCreator: createClinicAnalyticsGetClinicAnalyticsObject,
    typeName: 'useClinicAnalyticsGetClinicAnalytics',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicAnalyticsGetClinicAnalytics.mock = () => new Error('Mock has not been implemented!');
useClinicAnalyticsGetClinicAnalytics.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicAnalyticsGetClinicAnalytics.createObject = createClinicAnalyticsGetClinicAnalyticsObject;

export const createClinicAnalyticsUpdatePotentialPatientNumObject = (value: APIResponse<ClinicAnalytics>) => new APIResponseClass<ClinicAnalyticsClass>(value, (value: ClinicAnalytics) => new ClinicAnalyticsClass(value));

export const useClinicAnalyticsUpdatePotentialPatientNum = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicAnalyticsUpdatePotentialPatientNumParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicAnalyticsUpdatePotentialPatientNumParams,
    APIResponse<ClinicAnalytics>,
    typeof clinicAnalyticsService.updatePotentialPatientNum,
    AjaxOptions,
    APIResponseClass<ClinicAnalyticsClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicAnalyticsService.updatePotentialPatientNum>) => (
      clinicAnalyticsService.updatePotentialPatientNum(...args)
    ),
    ResponseClassCreator: createClinicAnalyticsUpdatePotentialPatientNumObject,
    typeName: 'useClinicAnalyticsUpdatePotentialPatientNum',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicAnalyticsUpdatePotentialPatientNum.mock = () => new Error('Mock has not been implemented!');
useClinicAnalyticsUpdatePotentialPatientNum.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicAnalyticsUpdatePotentialPatientNum.createObject = createClinicAnalyticsUpdatePotentialPatientNumObject;

export const createClinicAnalyticsUpdateMonthlyEnrollGoalObject = (value: APIResponse<ClinicAnalytics>) => new APIResponseClass<ClinicAnalyticsClass>(value, (value: ClinicAnalytics) => new ClinicAnalyticsClass(value));

export const useClinicAnalyticsUpdateMonthlyEnrollGoal = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicAnalyticsUpdateMonthlyEnrollGoalParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicAnalyticsUpdateMonthlyEnrollGoalParams,
    APIResponse<ClinicAnalytics>,
    typeof clinicAnalyticsService.updateMonthlyEnrollGoal,
    AjaxOptions,
    APIResponseClass<ClinicAnalyticsClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicAnalyticsService.updateMonthlyEnrollGoal>) => (
      clinicAnalyticsService.updateMonthlyEnrollGoal(...args)
    ),
    ResponseClassCreator: createClinicAnalyticsUpdateMonthlyEnrollGoalObject,
    typeName: 'useClinicAnalyticsUpdateMonthlyEnrollGoal',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicAnalyticsUpdateMonthlyEnrollGoal.mock = () => new Error('Mock has not been implemented!');
useClinicAnalyticsUpdateMonthlyEnrollGoal.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicAnalyticsUpdateMonthlyEnrollGoal.createObject = createClinicAnalyticsUpdateMonthlyEnrollGoalObject;


export const createClinicAnalyticsGetObject = (value: APIResponse<ClinicAnalytics>) => new APIResponseClass<ClinicAnalyticsClass>(value, (value: ClinicAnalytics) => new ClinicAnalyticsClass(value));
export const useClinicAnalyticsGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<ClinicAnalytics>,
    // @ts-ignore
    typeof clinicAnalyticsService.get,
    GetOptions,
    APIResponseClass<ClinicAnalyticsClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof clinicAnalyticsService.get>) => (
      clinicAnalyticsService.get(...args)
    ),
    ResponseClassCreator: createClinicAnalyticsGetObject,
    typeName: 'useClinicAnalyticsGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicAnalyticsGet.mock = () => new Error('Mock has not been implemented!');
useClinicAnalyticsGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicAnalyticsGet.createObject = createClinicAnalyticsGetObject;

export const createClinicAnalyticsUpdateObject = (value: APIResponse<ClinicAnalytics>) => new APIResponseClass<ClinicAnalyticsClass>(value, (value: ClinicAnalytics) => new ClinicAnalyticsClass(value));
export const useClinicAnalyticsUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, ClinicAnalytics>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, ClinicAnalytics>,
    APIResponse<ClinicAnalytics>,
    // @ts-ignore
    typeof clinicAnalyticsService.update,
    AjaxOptions,
    APIResponseClass<ClinicAnalyticsClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicAnalyticsService.update>) => (
      clinicAnalyticsService.update(...args)
    ),
    ResponseClassCreator: createClinicAnalyticsUpdateObject,
    typeName: 'useClinicAnalyticsUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicAnalyticsUpdate.mock = () => new Error('Mock has not been implemented!');
useClinicAnalyticsUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicAnalyticsUpdate.createObject = createClinicAnalyticsUpdateObject;

export const createClinicAnalyticsInsertObject = (value: APIResponse<ClinicAnalytics>) => new APIResponseClass<ClinicAnalyticsClass>(value, (value: ClinicAnalytics) => new ClinicAnalyticsClass(value));
export const useClinicAnalyticsInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<ClinicAnalytics>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<ClinicAnalytics>,
    APIResponse<ClinicAnalytics>,
    // @ts-ignore
    typeof clinicAnalyticsService.insert,
    AjaxOptions,
    APIResponseClass<ClinicAnalyticsClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicAnalyticsService.insert>) => (
      clinicAnalyticsService.insert(...args)
    ),
    ResponseClassCreator: createClinicAnalyticsInsertObject,
    typeName: 'useClinicAnalyticsInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicAnalyticsInsert.mock = () => new Error('Mock has not been implemented!');
useClinicAnalyticsInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicAnalyticsInsert.createObject = createClinicAnalyticsInsertObject;

export const createClinicAnalyticsDeleteObject = (value: APIResponse<ClinicAnalytics>) => new APIResponseClass<ClinicAnalyticsClass>(value, (value: ClinicAnalytics) => new ClinicAnalyticsClass(value));
export const useClinicAnalyticsDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<ClinicAnalytics>,
    // @ts-ignore
    typeof clinicAnalyticsService.delete,
    AjaxOptions,
    APIResponseClass<ClinicAnalyticsClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicAnalyticsService.delete>) => (
      clinicAnalyticsService.delete(...args)
    ),
    ResponseClassCreator: createClinicAnalyticsDeleteObject,
    typeName: 'useClinicAnalyticsDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicAnalyticsDelete.mock = () => new Error('Mock has not been implemented!');
useClinicAnalyticsDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicAnalyticsDelete.createObject = createClinicAnalyticsDeleteObject;

export const createClinicAnalyticsSearchObject = (value: APIResponse<PageResponse<ClinicAnalytics>>) => new APIResponseClass<PageResponseClass<ClinicAnalyticsClass>>(value, (value: PageResponse<ClinicAnalytics>) => new PageResponseClass<ClinicAnalyticsClass>(value, (value: ClinicAnalytics) => new ClinicAnalyticsClass(value)));
export const useClinicAnalyticsSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<ClinicAnalytics>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<ClinicAnalytics>,
    APIResponse<PageResponse<ClinicAnalytics>>,
    // @ts-ignore
    typeof clinicAnalyticsService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ClinicAnalyticsClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicAnalyticsService.search>) => (
      clinicAnalyticsService.search(...args)
    ),
    ResponseClassCreator: createClinicAnalyticsSearchObject,
    typeName: 'useClinicAnalyticsSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicAnalyticsSearch.mock = () => new Error('Mock has not been implemented!');
useClinicAnalyticsSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicAnalyticsSearch.createObject = createClinicAnalyticsSearchObject;

export interface NeFilter<T> {
	value?: Nullable<T>;
}
export interface MedicalOrganizationAssignment extends BaseModel {
	medicalOrgId?: Nullable<string>;
	careGroupId?: Nullable<string>;
	careCenterId?: Nullable<string>;
	careDivisionId?: Nullable<string>;
	rootId?: Nullable<string>;
	deleted?: Nullable<boolean>;
	medicalOrgIdIn?: Nullable<InFilter<string>>;
	careGroupIdIn?: Nullable<InFilter<string>>;
	careCenterIdIn?: Nullable<InFilter<string>>;
	careDivisionIdIn?: Nullable<InFilter<string>>;
	rootIdIn?: Nullable<InFilter<string>>;
	medicalOrganization?: Nullable<LimitedClinic>;
	careGroup?: Nullable<MiniOrganization>;
	deletedNe?: Nullable<NeFilter<string>>;
}
export interface ClinicAssignment extends MedicalOrganizationAssignment {
}
export enum MedicalOrganizationType {
	CLINIC = 'CLINIC',
	MEDICAL_GROUP = 'MEDICAL_GROUP',
	CUSTOM = 'CUSTOM',
}
export interface Address {
	type?: Nullable<string>;
	streetName?: Nullable<string>;
	streetNumber?: Nullable<string>;
	floor?: Nullable<string>;
	unit?: Nullable<string>;
	city?: Nullable<string>;
	state?: Nullable<string>;
	country?: Nullable<string>;
	postCode?: Nullable<string>;
}
export interface MedicalOrganizationProfile {
	businessName?: Nullable<string>;
	alias: string;
	description?: Nullable<string>;
	phone?: Nullable<string>;
	address?: Nullable<string>;
	address2?: Nullable<string>;
	addresses?: Nullable<Address[]>;
	state?: Nullable<string>;
	city?: Nullable<string>;
	zip?: Nullable<string>;
	timezone?: Nullable<string>;
	orgNumber?: Nullable<string>;
}
export interface BillingEntity {
	billingEntityName?: Nullable<string>;
	netSuiteCustomerId?: Nullable<string>;
	businessAddress?: Nullable<Address>;
	addressName?: Nullable<string>;
	officeNpi?: Nullable<string>;
	taxId?: Nullable<string>;
	medicalOrgRecipientsEmails?: Nullable<string[]>;
	ihealthInternalRecipientsEmails?: Nullable<string[]>;
	ihealthInternalCCRecipientsEmails?: Nullable<string[]>;
	otherNetSuiteCustomerId?: Nullable<string>;
}
export enum BillingFeeModelEnum {
	PER_CODE = 'PER_CODE',
	PEPM = 'PEPM',
	PBPM = 'PBPM',
	PMAPM = 'PMAPM',
	NA = 'NA',
}
export interface PerCodeFeeModel {
	dataServiceFee?: Nullable<string>;
	first20MinutesFee?: Nullable<string>;
	moreThan40MinutesFee?: Nullable<string>;
	cgmServiceFee?: Nullable<string>;
}
export interface UnitFeeModel {
	unitFee?: Nullable<string>;
}
export interface CodeCap {
	code?: Nullable<string>;
	capUnits?: Nullable<number>;
}
export enum InvoiceCycleEnum {
	MONTHLY = 'MONTHLY',
	QUARTERLY = 'QUARTERLY',
}
export enum BillingGenerationEnum {
	SINGLE = 'SINGLE',
	SPLIT = 'SPLIT',
}
export interface BillingFeeModel {
	type?: Nullable<BillingFeeModelEnum>;
	perCode?: Nullable<PerCodeFeeModel>;
	pmapm?: Nullable<UnitFeeModel>;
	pbpm?: Nullable<UnitFeeModel>;
	pepm?: Nullable<UnitFeeModel>;
	codes?: Nullable<CodeCap[]>;
	invoiceCycle?: Nullable<InvoiceCycleEnum>;
	isReportRequiredForInsurance?: Nullable<boolean>;
	billingGeneration?: Nullable<BillingGenerationEnum>;
}
export interface ProgramParticipation {
	name?: Nullable<ProgramCategoryEnum>;
	consentFormId?: Nullable<string>;
	templateGroupName?: Nullable<string>;
	consentFormLanguages?: Nullable<string[]>;
}
export enum AddOnServiceEnum {
	CGM = 'CGM',
}
export enum AlternativeDeviveType {
	LOANER_PHONE = 'LOANER_PHONE',
	WIFI_ROUTER = 'WIFI_ROUTER',
	HOTSPOT = 'HOTSPOT',
	BP_CLEAR = 'BP_CLEAR',
	BP_CELLULAR_PYLO = 'BP_CELLULAR_PYLO',
	value = 'value',
}
export interface CoBrandConfig {
	coBrandName?: Nullable<string>;
	logo?: Nullable<ImageSchema>;
}
export interface ServiceConfig {
	inClinicInventory?: Nullable<boolean>;
	callCenterLocalNumber?: Nullable<string>;
	nonSmartPhoneAlternatives?: Nullable<AlternativeDeviveType[]>;
	coBrandConfig?: Nullable<CoBrandConfig>;
}
export interface CareServiceConfig {
	medicalAlertTrigger?: Nullable<boolean>;
	complianceAlertTrigger?: Nullable<boolean>;
	onboardingTodoTrigger?: Nullable<boolean>;
	mtprTrigger?: Nullable<boolean>;
	sendAutoMessageTrigger?: Nullable<boolean>;
	foodLogTrigger?: Nullable<boolean>;
	messageTrigger?: Nullable<boolean>;
}
export interface CareQualityStatus {
	agreedToJoin?: Nullable<boolean>;
	fileInfo?: Nullable<FileInfo>;
	signedDate?: Nullable<string>;
}
export enum EhrServiceType {
	AMAZING_CHART = 'AMAZING_CHART',
	ATHENA_HEALTH = 'ATHENA_HEALTH',
	CITRIX_WORKSPACE = 'CITRIX_WORKSPACE',
	E_CLINICAL_WORKS = 'E_CLINICAL_WORKS',
	EHI = 'EHI',
	E_MDS = 'E_MDS',
	E_MED_REC = 'E_MED_REC',
	EPIC_SYSTEMS = 'EPIC_SYSTEMS',
	HEALTH_FUSION = 'HEALTH_FUSION',
	IMS = 'IMS',
	LIQUID = 'LIQUID',
	OFFICE_ALLY = 'OFFICE_ALLY',
	PRACTICE_FUSION = 'PRACTICE_FUSION',
	PRACTICE_PARTNER = 'PRACTICE_PARTNER',
	OTHERS = 'OTHERS',
	NOT_USING_ANY_EHR = 'NOT_USING_ANY_EHR',
	value = 'value',
}
export enum EhrServiceName {
	E_CLINICAL_WORKS = 'E_CLINICAL_WORKS',
	UNIFIED_CARE = 'UNIFIED_CARE',
	PRACTICE_FUSION = 'PRACTICE_FUSION',
	LCMC = 'LCMC',
	ATHENA = 'ATHENA',
	CAREQUALITY = 'CAREQUALITY',
	EPIC_SYSTEMS = 'EPIC_SYSTEMS',
	OFFICE_ALLY = 'OFFICE_ALLY',
	LIQUID = 'LIQUID',
	E_MDS = 'E_MDS',
	IMS = 'IMS',
	CITRIX_WORKSPACE = 'CITRIX_WORKSPACE',
	AMAZING_CHART = 'AMAZING_CHART',
	E_MED_REC = 'E_MED_REC',
	EHI = 'EHI',
	ALL_SCRIPTS = 'ALL_SCRIPTS',
	CERNER = 'CERNER',
	CHART_LOGIC = 'CHART_LOGIC',
	EBIO = 'EBIO',
	ELATION = 'ELATION',
	HEALTH_FUSION = 'HEALTH_FUSION',
	PRACTICE_SUITE = 'PRACTICE_SUITE',
	OTHERS = 'OTHERS',
	NOT_USING_ANY_EHR = 'NOT_USING_ANY_EHR',
	value = 'value',
}
export interface EhrClinic extends MedicalOrganizationProfile {
	id?: Nullable<string>;
	ehr?: Nullable<EhrServiceName>;
	departments?: Nullable<EhrClinic[]>;
}
export interface IntegrationFeature {
	ehrServiceProvider?: Nullable<EhrServiceName>;
	isReferralEnabled?: Nullable<boolean>;
	isAutoBillingEnabled?: Nullable<boolean>;
	athenaPracticeId?: Nullable<string>;
	athenaDepartments?: Nullable<EhrClinic[]>;
}
export interface MedicalOrganization extends BaseModel {
	type?: Nullable<MedicalOrganizationType>;
	profile?: Nullable<MedicalOrganizationProfile>;
	billingEntity?: Nullable<BillingEntity>;
	feeModel?: Nullable<BillingFeeModel>;
	status?: Nullable<MedicalOrganizationStatus>;
	programParticipation?: Nullable<ProgramParticipation[]>;
	addOnServices?: Nullable<AddOnServiceEnum[]>;
	serviceConfig?: Nullable<ServiceConfig>;
	careServiceConfig?: Nullable<CareServiceConfig>;
	enableSharePatientAccess?: Nullable<boolean>;
	staffList?: Nullable<string[]>;
	careQualityStatus?: Nullable<CareQualityStatus>;
	careQualityHistory?: Nullable<CareQualityStatus[]>;
	ehr?: Nullable<EhrServiceType>;
	integrationFeature?: Nullable<IntegrationFeature>;
	createdBy?: Nullable<string>;
	updatedBy?: Nullable<string>;
	migratedAt?: Nullable<string>;
	isSaasModel?: Nullable<boolean>;
	terminationInitiatedDate?: Nullable<string>;
	terminationDate?: Nullable<string>;
	source?: Nullable<string>;
	staffIn?: Nullable<InFilter<string>>;
	typeIn?: Nullable<InFilter<MedicalOrganizationType>>;
	statusIn?: Nullable<InFilter<MedicalOrganizationStatus>>;
	businessNameNear?: Nullable<string>;
	aliasNear?: Nullable<string>;
	orgNumberNear?: Nullable<string>;
	orgNumberIn?: Nullable<InFilter<string>>;
	careUnit?: Nullable<MiniOrganization>;
	stateNear?: Nullable<string>;
	cityNear?: Nullable<string>;
	name?: Nullable<string>;
	nameNear?: Nullable<string>;
	fullNameNear?: Nullable<string>;
	complianceAlertTriggerNe?: Nullable<NeFilter<boolean>>;
	mtprTriggerNe?: Nullable<NeFilter<boolean>>;
}
export interface Clinic extends MedicalOrganization {
	nickNameNear?: Nullable<string>;
	stateNear?: Nullable<string>;
	cityNear?: Nullable<string>;
	sequenceNumberNear?: Nullable<string>;
	timezoneIn?: Nullable<InFilter<string>>;
	patientLanguagesIn?: Nullable<InFilter<string>>;
}
export enum OrganizationTypeEnum {
	ROOT = 'ROOT',
	CARE_CENTER = 'CARE_CENTER',
	CARE_DIVISION = 'CARE_DIVISION',
	CARE_GROUP = 'CARE_GROUP',
	CARE_DEPARTMENT = 'CARE_DEPARTMENT',
	priority = 'priority',
}
export interface UcOrganization extends BaseModel {
	name?: Nullable<string>;
	organizationType: OrganizationTypeEnum;
	parentId?: Nullable<string>;
	careDivisionId?: Nullable<string>;
	careCenterId?: Nullable<string>;
	rootId?: Nullable<string>;
	deleted?: Nullable<boolean>;
	parentIdIn?: Nullable<InFilter<string>>;
	careDivisionIdIn?: Nullable<InFilter<string>>;
	careCenterIdIn?: Nullable<InFilter<string>>;
	languageIn?: Nullable<InFilter<string>>;
	organizationTypeIn?: Nullable<InFilter<string>>;
	nameIn?: Nullable<InFilter<string>>;
	nameNear?: Nullable<string>;
	deletedNe?: Nullable<NeFilter<boolean>>;
}
export interface ClinicAssignmentAggregationResult extends ClinicAssignment {
	clinic?: Nullable<Clinic>;
	organization?: Nullable<UcOrganization>;
}
export class NeFilterClass<T> {
  protected readonly _value: NeFilter<any>;
	protected readonly valueConstructorFunc?: (value: any) => T;

	constructor(value: NeFilter<any>, valueConstructorFunc?: (value: any) => T) {
		this._value = value;
		this.valueConstructorFunc = valueConstructorFunc;
	}

  get rawValue() {
    return this._value;
  }

  get value(): T {
    return this.valueConstructorFunc ? (this._value.value && this.valueConstructorFunc(this._value.value)) : this._value.value;
  }
}
export class MedicalOrganizationAssignmentClass extends BaseModelClass {
  protected readonly _value: MedicalOrganizationAssignment;

	constructor(value: MedicalOrganizationAssignment) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get medicalOrgId() {
    return this._value.medicalOrgId;
  }

  get careGroupId() {
    return this._value.careGroupId;
  }

  get careCenterId() {
    return this._value.careCenterId;
  }

  get careDivisionId() {
    return this._value.careDivisionId;
  }

  get rootId() {
    return this._value.rootId;
  }

  get deleted() {
    return this._value.deleted;
  }

  get deletedWithDefault() {
    return this._value.deleted || false;
  }

  get medicalOrgIdIn() {
    return this._value.medicalOrgIdIn !== undefined && this._value.medicalOrgIdIn !== null ? new InFilterClass(this._value.medicalOrgIdIn) : this._value.medicalOrgIdIn;
  }

  get careGroupIdIn() {
    return this._value.careGroupIdIn !== undefined && this._value.careGroupIdIn !== null ? new InFilterClass(this._value.careGroupIdIn) : this._value.careGroupIdIn;
  }

  get careCenterIdIn() {
    return this._value.careCenterIdIn !== undefined && this._value.careCenterIdIn !== null ? new InFilterClass(this._value.careCenterIdIn) : this._value.careCenterIdIn;
  }

  get careDivisionIdIn() {
    return this._value.careDivisionIdIn !== undefined && this._value.careDivisionIdIn !== null ? new InFilterClass(this._value.careDivisionIdIn) : this._value.careDivisionIdIn;
  }

  get rootIdIn() {
    return this._value.rootIdIn !== undefined && this._value.rootIdIn !== null ? new InFilterClass(this._value.rootIdIn) : this._value.rootIdIn;
  }

  get medicalOrganization() {
    return this._value.medicalOrganization !== undefined && this._value.medicalOrganization !== null ? new LimitedClinicClass(this._value.medicalOrganization) : this._value.medicalOrganization;
  }

  get careGroup() {
    return this._value.careGroup !== undefined && this._value.careGroup !== null ? new MiniOrganizationClass(this._value.careGroup) : this._value.careGroup;
  }

  get deletedNe() {
    return this._value.deletedNe !== undefined && this._value.deletedNe !== null ? new NeFilterClass(this._value.deletedNe) : this._value.deletedNe;
  }
}
export class ClinicAssignmentClass extends MedicalOrganizationAssignmentClass {
  protected readonly _value: ClinicAssignment;

	constructor(value: ClinicAssignment) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }
}
export class AddressClass {
  protected readonly _value: Address;

	constructor(value: Address) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get type() {
    return this._value.type;
  }

  get typeWithDefault() {
    return this._value.type || '';
  }

  get streetName() {
    return this._value.streetName;
  }

  get streetNameWithDefault() {
    return this._value.streetName || '';
  }

  get streetNumber() {
    return this._value.streetNumber;
  }

  get streetNumberWithDefault() {
    return this._value.streetNumber || '';
  }

  get floor() {
    return this._value.floor;
  }

  get floorWithDefault() {
    return this._value.floor || '';
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }

  get city() {
    return this._value.city;
  }

  get cityWithDefault() {
    return this._value.city || '';
  }

  get state() {
    return this._value.state;
  }

  get stateWithDefault() {
    return this._value.state || '';
  }

  get country() {
    return this._value.country;
  }

  get countryWithDefault() {
    return this._value.country || '';
  }

  get postCode() {
    return this._value.postCode;
  }

  get postCodeWithDefault() {
    return this._value.postCode || '';
  }
}
export class MedicalOrganizationProfileClass {
  protected readonly _value: MedicalOrganizationProfile;

	constructor(value: MedicalOrganizationProfile) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get businessName() {
    return this._value.businessName;
  }

  get businessNameWithDefault() {
    return this._value.businessName || '';
  }

  get alias() {
    return this._value.alias;
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get phone() {
    return this._value.phone;
  }

  get phoneWithDefault() {
    return this._value.phone || '';
  }

  get address() {
    return this._value.address;
  }

  get addressWithDefault() {
    return this._value.address || '';
  }

  get address2() {
    return this._value.address2;
  }

  get address2WithDefault() {
    return this._value.address2 || '';
  }

  get addresses() {
    return this._value.addresses !== undefined && this._value.addresses !== null ? map(this._value.addresses, (v) => new AddressClass(v)) : this._value.addresses;
  }

  get addressesWithDefault() {
    return this._value.addresses || [];
  }

  get state() {
    return this._value.state;
  }

  get stateWithDefault() {
    return this._value.state || '';
  }

  get city() {
    return this._value.city;
  }

  get cityWithDefault() {
    return this._value.city || '';
  }

  get zip() {
    return this._value.zip;
  }

  get zipWithDefault() {
    return this._value.zip || '';
  }

  get timezone() {
    return this._value.timezone;
  }

  get timezoneWithDefault() {
    return this._value.timezone || '';
  }

  get orgNumber() {
    return this._value.orgNumber;
  }

  get orgNumberWithDefault() {
    return this._value.orgNumber || '';
  }
}
export class BillingEntityClass {
  protected readonly _value: BillingEntity;

	constructor(value: BillingEntity) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get billingEntityName() {
    return this._value.billingEntityName;
  }

  get billingEntityNameWithDefault() {
    return this._value.billingEntityName || '';
  }

  get netSuiteCustomerId() {
    return this._value.netSuiteCustomerId;
  }

  get netSuiteCustomerIdWithDefault() {
    return this._value.netSuiteCustomerId || '';
  }

  get businessAddress() {
    return this._value.businessAddress !== undefined && this._value.businessAddress !== null ? new AddressClass(this._value.businessAddress) : this._value.businessAddress;
  }

  get addressName() {
    return this._value.addressName;
  }

  get addressNameWithDefault() {
    return this._value.addressName || '';
  }

  get officeNpi() {
    return this._value.officeNpi;
  }

  get officeNpiWithDefault() {
    return this._value.officeNpi || '';
  }

  get taxId() {
    return this._value.taxId;
  }

  get taxIdWithDefault() {
    return this._value.taxId || '';
  }

  get medicalOrgRecipientsEmails() {
    return this._value.medicalOrgRecipientsEmails;
  }

  get medicalOrgRecipientsEmailsWithDefault() {
    return this._value.medicalOrgRecipientsEmails || [];
  }

  get ihealthInternalRecipientsEmails() {
    return this._value.ihealthInternalRecipientsEmails;
  }

  get ihealthInternalRecipientsEmailsWithDefault() {
    return this._value.ihealthInternalRecipientsEmails || [];
  }

  get ihealthInternalCCRecipientsEmails() {
    return this._value.ihealthInternalCCRecipientsEmails;
  }

  get ihealthInternalCCRecipientsEmailsWithDefault() {
    return this._value.ihealthInternalCCRecipientsEmails || [];
  }

  get otherNetSuiteCustomerId() {
    return this._value.otherNetSuiteCustomerId;
  }

  get otherNetSuiteCustomerIdWithDefault() {
    return this._value.otherNetSuiteCustomerId || '';
  }
}
export class PerCodeFeeModelClass {
  protected readonly _value: PerCodeFeeModel;

	constructor(value: PerCodeFeeModel) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get dataServiceFee() {
    return this._value.dataServiceFee;
  }

  get dataServiceFeeWithDefault() {
    return this._value.dataServiceFee || '';
  }

  get first20MinutesFee() {
    return this._value.first20MinutesFee;
  }

  get first20MinutesFeeWithDefault() {
    return this._value.first20MinutesFee || '';
  }

  get moreThan40MinutesFee() {
    return this._value.moreThan40MinutesFee;
  }

  get moreThan40MinutesFeeWithDefault() {
    return this._value.moreThan40MinutesFee || '';
  }

  get cgmServiceFee() {
    return this._value.cgmServiceFee;
  }

  get cgmServiceFeeWithDefault() {
    return this._value.cgmServiceFee || '';
  }
}
export class UnitFeeModelClass {
  protected readonly _value: UnitFeeModel;

	constructor(value: UnitFeeModel) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get unitFee() {
    return this._value.unitFee;
  }

  get unitFeeWithDefault() {
    return this._value.unitFee || '';
  }
}
export class CodeCapClass {
  protected readonly _value: CodeCap;

	constructor(value: CodeCap) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get code() {
    return this._value.code;
  }

  get codeWithDefault() {
    return this._value.code || '';
  }

  get capUnits() {
    return this._value.capUnits;
  }

  get capUnitsWithDefault() {
    return this._value.capUnits || 0;
  }
}
export class BillingFeeModelClass {
  protected readonly _value: BillingFeeModel;

	constructor(value: BillingFeeModel) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: BillingFeeModelEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get perCode() {
    return this._value.perCode !== undefined && this._value.perCode !== null ? new PerCodeFeeModelClass(this._value.perCode) : this._value.perCode;
  }

  get pmapm() {
    return this._value.pmapm !== undefined && this._value.pmapm !== null ? new UnitFeeModelClass(this._value.pmapm) : this._value.pmapm;
  }

  get pbpm() {
    return this._value.pbpm !== undefined && this._value.pbpm !== null ? new UnitFeeModelClass(this._value.pbpm) : this._value.pbpm;
  }

  get pepm() {
    return this._value.pepm !== undefined && this._value.pepm !== null ? new UnitFeeModelClass(this._value.pepm) : this._value.pepm;
  }

  get codes() {
    return this._value.codes !== undefined && this._value.codes !== null ? map(this._value.codes, (v) => new CodeCapClass(v)) : this._value.codes;
  }

  get codesWithDefault() {
    return this._value.codes || [];
  }

  get invoiceCycle() {
    return this._value.invoiceCycle;
  }

  invoiceCycleIsIn(values: InvoiceCycleEnum[] = []) {
    return this._value.invoiceCycle && values.includes(this._value.invoiceCycle);
  }

  get isReportRequiredForInsurance() {
    return this._value.isReportRequiredForInsurance;
  }

  get isReportRequiredForInsuranceWithDefault() {
    return this._value.isReportRequiredForInsurance || false;
  }

  get billingGeneration() {
    return this._value.billingGeneration;
  }

  billingGenerationIsIn(values: BillingGenerationEnum[] = []) {
    return this._value.billingGeneration && values.includes(this._value.billingGeneration);
  }
}
export class ProgramParticipationClass {
  protected readonly _value: ProgramParticipation;

	constructor(value: ProgramParticipation) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  nameIsIn(values: ProgramCategoryEnum[] = []) {
    return this._value.name && values.includes(this._value.name);
  }

  get consentFormId() {
    return this._value.consentFormId;
  }

  get templateGroupName() {
    return this._value.templateGroupName;
  }

  get templateGroupNameWithDefault() {
    return this._value.templateGroupName || '';
  }

  get consentFormLanguages() {
    return this._value.consentFormLanguages;
  }

  get consentFormLanguagesWithDefault() {
    return this._value.consentFormLanguages || [];
  }
}
export class CoBrandConfigClass {
  protected readonly _value: CoBrandConfig;

	constructor(value: CoBrandConfig) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get coBrandName() {
    return this._value.coBrandName;
  }

  get coBrandNameWithDefault() {
    return this._value.coBrandName || '';
  }

  get logo() {
    return this._value.logo !== undefined && this._value.logo !== null ? new ImageSchemaClass(this._value.logo) : this._value.logo;
  }
}
export class ServiceConfigClass {
  protected readonly _value: ServiceConfig;

	constructor(value: ServiceConfig) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get inClinicInventory() {
    return this._value.inClinicInventory;
  }

  get inClinicInventoryWithDefault() {
    return this._value.inClinicInventory || false;
  }

  get callCenterLocalNumber() {
    return this._value.callCenterLocalNumber;
  }

  get callCenterLocalNumberWithDefault() {
    return this._value.callCenterLocalNumber || '';
  }

  get nonSmartPhoneAlternatives() {
    return this._value.nonSmartPhoneAlternatives;
  }

  get nonSmartPhoneAlternativesWithDefault() {
    return this._value.nonSmartPhoneAlternatives || [];
  }

  get coBrandConfig() {
    return this._value.coBrandConfig !== undefined && this._value.coBrandConfig !== null ? new CoBrandConfigClass(this._value.coBrandConfig) : this._value.coBrandConfig;
  }
}
export class CareServiceConfigClass {
  protected readonly _value: CareServiceConfig;

	constructor(value: CareServiceConfig) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get medicalAlertTrigger() {
    return this._value.medicalAlertTrigger;
  }

  get medicalAlertTriggerWithDefault() {
    return this._value.medicalAlertTrigger || false;
  }

  get complianceAlertTrigger() {
    return this._value.complianceAlertTrigger;
  }

  get complianceAlertTriggerWithDefault() {
    return this._value.complianceAlertTrigger || false;
  }

  get onboardingTodoTrigger() {
    return this._value.onboardingTodoTrigger;
  }

  get onboardingTodoTriggerWithDefault() {
    return this._value.onboardingTodoTrigger || false;
  }

  get mtprTrigger() {
    return this._value.mtprTrigger;
  }

  get mtprTriggerWithDefault() {
    return this._value.mtprTrigger || false;
  }

  get sendAutoMessageTrigger() {
    return this._value.sendAutoMessageTrigger;
  }

  get sendAutoMessageTriggerWithDefault() {
    return this._value.sendAutoMessageTrigger || false;
  }

  get foodLogTrigger() {
    return this._value.foodLogTrigger;
  }

  get foodLogTriggerWithDefault() {
    return this._value.foodLogTrigger || false;
  }

  get messageTrigger() {
    return this._value.messageTrigger;
  }

  get messageTriggerWithDefault() {
    return this._value.messageTrigger || false;
  }
}
export class CareQualityStatusClass {
  protected readonly _value: CareQualityStatus;

	constructor(value: CareQualityStatus) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get agreedToJoin() {
    return this._value.agreedToJoin;
  }

  get agreedToJoinWithDefault() {
    return this._value.agreedToJoin || false;
  }

  get fileInfo() {
    return this._value.fileInfo !== undefined && this._value.fileInfo !== null ? new FileInfoClass(this._value.fileInfo) : this._value.fileInfo;
  }

  get signedDate() {
    return (this._value.signedDate !== undefined && this._value.signedDate !== null) ? dayjs(this._value.signedDate) : this._value.signedDate;
  }
}
export class EhrClinicClass extends MedicalOrganizationProfileClass {
  protected readonly _value: EhrClinic;

	constructor(value: EhrClinic) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get ehr() {
    return this._value.ehr;
  }

  ehrIsIn(values: EhrServiceName[] = []) {
    return this._value.ehr && values.includes(this._value.ehr);
  }

  get departments() {
    return this._value.departments !== undefined && this._value.departments !== null ? map(this._value.departments, (v) => new EhrClinicClass(v)) : this._value.departments;
  }

  get departmentsWithDefault() {
    return this._value.departments || [];
  }
}
export class IntegrationFeatureClass {
  protected readonly _value: IntegrationFeature;

	constructor(value: IntegrationFeature) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get ehrServiceProvider() {
    return this._value.ehrServiceProvider;
  }

  ehrServiceProviderIsIn(values: EhrServiceName[] = []) {
    return this._value.ehrServiceProvider && values.includes(this._value.ehrServiceProvider);
  }

  get isReferralEnabled() {
    return this._value.isReferralEnabled;
  }

  get isReferralEnabledWithDefault() {
    return this._value.isReferralEnabled || false;
  }

  get isAutoBillingEnabled() {
    return this._value.isAutoBillingEnabled;
  }

  get isAutoBillingEnabledWithDefault() {
    return this._value.isAutoBillingEnabled || false;
  }

  get athenaPracticeId() {
    return this._value.athenaPracticeId;
  }

  get athenaPracticeIdWithDefault() {
    return this._value.athenaPracticeId || '';
  }

  get athenaDepartments() {
    return this._value.athenaDepartments !== undefined && this._value.athenaDepartments !== null ? map(this._value.athenaDepartments, (v) => new EhrClinicClass(v)) : this._value.athenaDepartments;
  }

  get athenaDepartmentsWithDefault() {
    return this._value.athenaDepartments || [];
  }
}
export class MedicalOrganizationClass extends BaseModelClass {
  protected readonly _value: MedicalOrganization;

	constructor(value: MedicalOrganization) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: MedicalOrganizationType[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get profile() {
    return this._value.profile !== undefined && this._value.profile !== null ? new MedicalOrganizationProfileClass(this._value.profile) : this._value.profile;
  }

  get billingEntity() {
    return this._value.billingEntity !== undefined && this._value.billingEntity !== null ? new BillingEntityClass(this._value.billingEntity) : this._value.billingEntity;
  }

  get feeModel() {
    return this._value.feeModel !== undefined && this._value.feeModel !== null ? new BillingFeeModelClass(this._value.feeModel) : this._value.feeModel;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: MedicalOrganizationStatus[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get programParticipation() {
    return this._value.programParticipation !== undefined && this._value.programParticipation !== null ? map(this._value.programParticipation, (v) => new ProgramParticipationClass(v)) : this._value.programParticipation;
  }

  get programParticipationWithDefault() {
    return this._value.programParticipation || [];
  }

  get addOnServices() {
    return this._value.addOnServices;
  }

  get addOnServicesWithDefault() {
    return this._value.addOnServices || [];
  }

  get serviceConfig() {
    return this._value.serviceConfig !== undefined && this._value.serviceConfig !== null ? new ServiceConfigClass(this._value.serviceConfig) : this._value.serviceConfig;
  }

  get careServiceConfig() {
    return this._value.careServiceConfig !== undefined && this._value.careServiceConfig !== null ? new CareServiceConfigClass(this._value.careServiceConfig) : this._value.careServiceConfig;
  }

  get enableSharePatientAccess() {
    return this._value.enableSharePatientAccess;
  }

  get enableSharePatientAccessWithDefault() {
    return this._value.enableSharePatientAccess || false;
  }

  get staffList() {
    return this._value.staffList;
  }

  get staffListWithDefault() {
    return this._value.staffList || [];
  }

  get careQualityStatus() {
    return this._value.careQualityStatus !== undefined && this._value.careQualityStatus !== null ? new CareQualityStatusClass(this._value.careQualityStatus) : this._value.careQualityStatus;
  }

  get careQualityHistory() {
    return this._value.careQualityHistory !== undefined && this._value.careQualityHistory !== null ? map(this._value.careQualityHistory, (v) => new CareQualityStatusClass(v)) : this._value.careQualityHistory;
  }

  get careQualityHistoryWithDefault() {
    return this._value.careQualityHistory || [];
  }

  get ehr() {
    return this._value.ehr;
  }

  ehrIsIn(values: EhrServiceType[] = []) {
    return this._value.ehr && values.includes(this._value.ehr);
  }

  get integrationFeature() {
    return this._value.integrationFeature !== undefined && this._value.integrationFeature !== null ? new IntegrationFeatureClass(this._value.integrationFeature) : this._value.integrationFeature;
  }

  get createdBy() {
    return this._value.createdBy;
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get migratedAt() {
    return (this._value.migratedAt !== undefined && this._value.migratedAt !== null) ? dayjs(this._value.migratedAt) : this._value.migratedAt;
  }

  get isSaasModel() {
    return this._value.isSaasModel;
  }

  get isSaasModelWithDefault() {
    return this._value.isSaasModel || false;
  }

  get terminationInitiatedDate() {
    return (this._value.terminationInitiatedDate !== undefined && this._value.terminationInitiatedDate !== null) ? dayjs(this._value.terminationInitiatedDate) : this._value.terminationInitiatedDate;
  }

  get terminationDate() {
    return (this._value.terminationDate !== undefined && this._value.terminationDate !== null) ? dayjs(this._value.terminationDate) : this._value.terminationDate;
  }

  get source() {
    return this._value.source;
  }

  get sourceWithDefault() {
    return this._value.source || '';
  }

  get staffIn() {
    return this._value.staffIn !== undefined && this._value.staffIn !== null ? new InFilterClass(this._value.staffIn) : this._value.staffIn;
  }

  get typeIn() {
    return this._value.typeIn !== undefined && this._value.typeIn !== null ? new InFilterClass(this._value.typeIn) : this._value.typeIn;
  }

  get statusIn() {
    return this._value.statusIn !== undefined && this._value.statusIn !== null ? new InFilterClass(this._value.statusIn) : this._value.statusIn;
  }

  get businessNameNear() {
    return this._value.businessNameNear;
  }

  get businessNameNearWithDefault() {
    return this._value.businessNameNear || '';
  }

  get aliasNear() {
    return this._value.aliasNear;
  }

  get aliasNearWithDefault() {
    return this._value.aliasNear || '';
  }

  get orgNumberNear() {
    return this._value.orgNumberNear;
  }

  get orgNumberNearWithDefault() {
    return this._value.orgNumberNear || '';
  }

  get orgNumberIn() {
    return this._value.orgNumberIn !== undefined && this._value.orgNumberIn !== null ? new InFilterClass(this._value.orgNumberIn) : this._value.orgNumberIn;
  }

  get careUnit() {
    return this._value.careUnit !== undefined && this._value.careUnit !== null ? new MiniOrganizationClass(this._value.careUnit) : this._value.careUnit;
  }

  get stateNear() {
    return this._value.stateNear;
  }

  get stateNearWithDefault() {
    return this._value.stateNear || '';
  }

  get cityNear() {
    return this._value.cityNear;
  }

  get cityNearWithDefault() {
    return this._value.cityNear || '';
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get nameNear() {
    return this._value.nameNear;
  }

  get nameNearWithDefault() {
    return this._value.nameNear || '';
  }

  get fullNameNear() {
    return this._value.fullNameNear;
  }

  get fullNameNearWithDefault() {
    return this._value.fullNameNear || '';
  }

  get complianceAlertTriggerNe() {
    return this._value.complianceAlertTriggerNe !== undefined && this._value.complianceAlertTriggerNe !== null ? new NeFilterClass(this._value.complianceAlertTriggerNe) : this._value.complianceAlertTriggerNe;
  }

  get mtprTriggerNe() {
    return this._value.mtprTriggerNe !== undefined && this._value.mtprTriggerNe !== null ? new NeFilterClass(this._value.mtprTriggerNe) : this._value.mtprTriggerNe;
  }
}
export class ClinicClass extends MedicalOrganizationClass {
  protected readonly _value: Clinic;

	constructor(value: Clinic) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get nickNameNear() {
    return this._value.nickNameNear;
  }

  get nickNameNearWithDefault() {
    return this._value.nickNameNear || '';
  }

  get stateNear() {
    return this._value.stateNear;
  }

  get stateNearWithDefault() {
    return this._value.stateNear || '';
  }

  get cityNear() {
    return this._value.cityNear;
  }

  get cityNearWithDefault() {
    return this._value.cityNear || '';
  }

  get sequenceNumberNear() {
    return this._value.sequenceNumberNear;
  }

  get sequenceNumberNearWithDefault() {
    return this._value.sequenceNumberNear || '';
  }

  get timezoneIn() {
    return this._value.timezoneIn !== undefined && this._value.timezoneIn !== null ? new InFilterClass(this._value.timezoneIn) : this._value.timezoneIn;
  }

  get patientLanguagesIn() {
    return this._value.patientLanguagesIn !== undefined && this._value.patientLanguagesIn !== null ? new InFilterClass(this._value.patientLanguagesIn) : this._value.patientLanguagesIn;
  }
}
export class UcOrganizationClass extends BaseModelClass {
  protected readonly _value: UcOrganization;

	constructor(value: UcOrganization) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get organizationType() {
    return this._value.organizationType;
  }

  organizationTypeIsIn(values: OrganizationTypeEnum[] = []) {
    return this._value.organizationType && values.includes(this._value.organizationType);
  }

  get parentId() {
    return this._value.parentId;
  }

  get careDivisionId() {
    return this._value.careDivisionId;
  }

  get careCenterId() {
    return this._value.careCenterId;
  }

  get rootId() {
    return this._value.rootId;
  }

  get deleted() {
    return this._value.deleted;
  }

  get deletedWithDefault() {
    return this._value.deleted || false;
  }

  get parentIdIn() {
    return this._value.parentIdIn !== undefined && this._value.parentIdIn !== null ? new InFilterClass(this._value.parentIdIn) : this._value.parentIdIn;
  }

  get careDivisionIdIn() {
    return this._value.careDivisionIdIn !== undefined && this._value.careDivisionIdIn !== null ? new InFilterClass(this._value.careDivisionIdIn) : this._value.careDivisionIdIn;
  }

  get careCenterIdIn() {
    return this._value.careCenterIdIn !== undefined && this._value.careCenterIdIn !== null ? new InFilterClass(this._value.careCenterIdIn) : this._value.careCenterIdIn;
  }

  get languageIn() {
    return this._value.languageIn !== undefined && this._value.languageIn !== null ? new InFilterClass(this._value.languageIn) : this._value.languageIn;
  }

  get organizationTypeIn() {
    return this._value.organizationTypeIn !== undefined && this._value.organizationTypeIn !== null ? new InFilterClass(this._value.organizationTypeIn) : this._value.organizationTypeIn;
  }

  get nameIn() {
    return this._value.nameIn !== undefined && this._value.nameIn !== null ? new InFilterClass(this._value.nameIn) : this._value.nameIn;
  }

  get nameNear() {
    return this._value.nameNear;
  }

  get nameNearWithDefault() {
    return this._value.nameNear || '';
  }

  get deletedNe() {
    return this._value.deletedNe !== undefined && this._value.deletedNe !== null ? new NeFilterClass(this._value.deletedNe) : this._value.deletedNe;
  }
}
export class ClinicAssignmentAggregationResultClass extends ClinicAssignmentClass {
  protected readonly _value: ClinicAssignmentAggregationResult;

	constructor(value: ClinicAssignmentAggregationResult) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get clinic() {
    return this._value.clinic !== undefined && this._value.clinic !== null ? new ClinicClass(this._value.clinic) : this._value.clinic;
  }

  get organization() {
    return this._value.organization !== undefined && this._value.organization !== null ? new UcOrganizationClass(this._value.organization) : this._value.organization;
  }
}
export interface ClinicListFilter {
	clinicAssignment?: Nullable<ClinicAssignment>;
	clinic?: Nullable<Clinic>;
	patientLanguages?: Nullable<string[]>;
	careGroups?: Nullable<string[]>;
	clinicStatus?: Nullable<ClinicStatus[]>;
}
export interface ClinicListAggregationResult {
	id?: Nullable<string>;
	clinic?: Nullable<Clinic>;
	careGroups?: Nullable<UcOrganization[]>;
	clinicAnalytics?: Nullable<ClinicAnalytics>;
}
export class ClinicListAggregationResultClass {
  protected readonly _value: ClinicListAggregationResult;

	constructor(value: ClinicListAggregationResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get clinic() {
    return this._value.clinic !== undefined && this._value.clinic !== null ? new ClinicClass(this._value.clinic) : this._value.clinic;
  }

  get careGroups() {
    return this._value.careGroups !== undefined && this._value.careGroups !== null ? map(this._value.careGroups, (v) => new UcOrganizationClass(v)) : this._value.careGroups;
  }

  get careGroupsWithDefault() {
    return this._value.careGroups || [];
  }

  get clinicAnalytics() {
    return this._value.clinicAnalytics !== undefined && this._value.clinicAnalytics !== null ? new ClinicAnalyticsClass(this._value.clinicAnalytics) : this._value.clinicAnalytics;
  }
}
export interface ClinicAssignmentMultiUpsertRequest {
	clinicId: string;
	careGroupIds: string[];
}
export interface ClinicAssignmentListParams {
	searchRequest: SearchRequest<ClinicAssignment>;
}

export interface ClinicAssignmentListClinicsParams {
	searchRequest: SearchRequest<ClinicListFilter>;
}

export interface ClinicAssignmentMultiUpsertParams {
	request: ClinicAssignmentMultiUpsertRequest;
}

export class ClinicAssignmentService extends BaseController<ClinicAssignment, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/clinic-assignments';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public list({ baseUrl, params, headers }: RequestOption<ClinicAssignmentListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<ClinicAssignmentAggregationResult>>>(`${baseUrl || this.baseURL}${this.basePath}/list${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public listClinics({ baseUrl, params, headers }: RequestOption<ClinicAssignmentListClinicsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<ClinicListAggregationResult>>>(`${baseUrl || this.baseURL}${this.basePath}/list/clinics${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public multiUpsert({ baseUrl, params, headers }: RequestOption<ClinicAssignmentMultiUpsertParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<ClinicAssignment[]>>(`${baseUrl || this.baseURL}${this.basePath}/multi-upsert${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const clinicAssignmentService = new ClinicAssignmentService();

export const createClinicAssignmentListObject = (value: APIResponse<PageResponse<ClinicAssignmentAggregationResult>>) => new APIResponseClass<PageResponseClass<ClinicAssignmentAggregationResultClass>>(value, (value: PageResponse<ClinicAssignmentAggregationResult>) => new PageResponseClass<ClinicAssignmentAggregationResultClass>(value, (value: ClinicAssignmentAggregationResult) => new ClinicAssignmentAggregationResultClass(value)));

export const useClinicAssignmentList = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicAssignmentListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicAssignmentListParams,
    APIResponse<PageResponse<ClinicAssignmentAggregationResult>>,
    typeof clinicAssignmentService.list,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ClinicAssignmentAggregationResultClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicAssignmentService.list>) => (
      clinicAssignmentService.list(...args)
    ),
    ResponseClassCreator: createClinicAssignmentListObject,
    typeName: 'useClinicAssignmentList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicAssignmentList.mock = () => new Error('Mock has not been implemented!');
useClinicAssignmentList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicAssignmentList.createObject = createClinicAssignmentListObject;

export const createClinicAssignmentListClinicsObject = (value: APIResponse<PageResponse<ClinicListAggregationResult>>) => new APIResponseClass<PageResponseClass<ClinicListAggregationResultClass>>(value, (value: PageResponse<ClinicListAggregationResult>) => new PageResponseClass<ClinicListAggregationResultClass>(value, (value: ClinicListAggregationResult) => new ClinicListAggregationResultClass(value)));

export const useClinicAssignmentListClinics = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicAssignmentListClinicsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicAssignmentListClinicsParams,
    APIResponse<PageResponse<ClinicListAggregationResult>>,
    typeof clinicAssignmentService.listClinics,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ClinicListAggregationResultClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicAssignmentService.listClinics>) => (
      clinicAssignmentService.listClinics(...args)
    ),
    ResponseClassCreator: createClinicAssignmentListClinicsObject,
    typeName: 'useClinicAssignmentListClinics',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicAssignmentListClinics.mock = () => new Error('Mock has not been implemented!');
useClinicAssignmentListClinics.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicAssignmentListClinics.createObject = createClinicAssignmentListClinicsObject;

export const createClinicAssignmentMultiUpsertObject = (value: APIResponse<ClinicAssignment[]>) => new APIResponseClass<ClinicAssignmentClass[]>(value, (v) => map(v, (value: ClinicAssignment) => new ClinicAssignmentClass(value)));

export const useClinicAssignmentMultiUpsert = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicAssignmentMultiUpsertParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicAssignmentMultiUpsertParams,
    APIResponse<ClinicAssignment[]>,
    typeof clinicAssignmentService.multiUpsert,
    AjaxOptions,
    APIResponseClass<ClinicAssignmentClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicAssignmentService.multiUpsert>) => (
      clinicAssignmentService.multiUpsert(...args)
    ),
    ResponseClassCreator: createClinicAssignmentMultiUpsertObject,
    typeName: 'useClinicAssignmentMultiUpsert',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicAssignmentMultiUpsert.mock = () => new Error('Mock has not been implemented!');
useClinicAssignmentMultiUpsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicAssignmentMultiUpsert.createObject = createClinicAssignmentMultiUpsertObject;


export const createClinicAssignmentGetObject = (value: APIResponse<ClinicAssignment>) => new APIResponseClass<ClinicAssignmentClass>(value, (value: ClinicAssignment) => new ClinicAssignmentClass(value));
export const useClinicAssignmentGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<ClinicAssignment>,
    // @ts-ignore
    typeof clinicAssignmentService.get,
    GetOptions,
    APIResponseClass<ClinicAssignmentClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof clinicAssignmentService.get>) => (
      clinicAssignmentService.get(...args)
    ),
    ResponseClassCreator: createClinicAssignmentGetObject,
    typeName: 'useClinicAssignmentGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicAssignmentGet.mock = () => new Error('Mock has not been implemented!');
useClinicAssignmentGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicAssignmentGet.createObject = createClinicAssignmentGetObject;

export const createClinicAssignmentUpdateObject = (value: APIResponse<ClinicAssignment>) => new APIResponseClass<ClinicAssignmentClass>(value, (value: ClinicAssignment) => new ClinicAssignmentClass(value));
export const useClinicAssignmentUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, ClinicAssignment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, ClinicAssignment>,
    APIResponse<ClinicAssignment>,
    // @ts-ignore
    typeof clinicAssignmentService.update,
    AjaxOptions,
    APIResponseClass<ClinicAssignmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicAssignmentService.update>) => (
      clinicAssignmentService.update(...args)
    ),
    ResponseClassCreator: createClinicAssignmentUpdateObject,
    typeName: 'useClinicAssignmentUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicAssignmentUpdate.mock = () => new Error('Mock has not been implemented!');
useClinicAssignmentUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicAssignmentUpdate.createObject = createClinicAssignmentUpdateObject;

export const createClinicAssignmentInsertObject = (value: APIResponse<ClinicAssignment>) => new APIResponseClass<ClinicAssignmentClass>(value, (value: ClinicAssignment) => new ClinicAssignmentClass(value));
export const useClinicAssignmentInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<ClinicAssignment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<ClinicAssignment>,
    APIResponse<ClinicAssignment>,
    // @ts-ignore
    typeof clinicAssignmentService.insert,
    AjaxOptions,
    APIResponseClass<ClinicAssignmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicAssignmentService.insert>) => (
      clinicAssignmentService.insert(...args)
    ),
    ResponseClassCreator: createClinicAssignmentInsertObject,
    typeName: 'useClinicAssignmentInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicAssignmentInsert.mock = () => new Error('Mock has not been implemented!');
useClinicAssignmentInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicAssignmentInsert.createObject = createClinicAssignmentInsertObject;

export const createClinicAssignmentDeleteObject = (value: APIResponse<ClinicAssignment>) => new APIResponseClass<ClinicAssignmentClass>(value, (value: ClinicAssignment) => new ClinicAssignmentClass(value));
export const useClinicAssignmentDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<ClinicAssignment>,
    // @ts-ignore
    typeof clinicAssignmentService.delete,
    AjaxOptions,
    APIResponseClass<ClinicAssignmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicAssignmentService.delete>) => (
      clinicAssignmentService.delete(...args)
    ),
    ResponseClassCreator: createClinicAssignmentDeleteObject,
    typeName: 'useClinicAssignmentDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicAssignmentDelete.mock = () => new Error('Mock has not been implemented!');
useClinicAssignmentDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicAssignmentDelete.createObject = createClinicAssignmentDeleteObject;

export const createClinicAssignmentSearchObject = (value: APIResponse<PageResponse<ClinicAssignment>>) => new APIResponseClass<PageResponseClass<ClinicAssignmentClass>>(value, (value: PageResponse<ClinicAssignment>) => new PageResponseClass<ClinicAssignmentClass>(value, (value: ClinicAssignment) => new ClinicAssignmentClass(value)));
export const useClinicAssignmentSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<ClinicAssignment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<ClinicAssignment>,
    APIResponse<PageResponse<ClinicAssignment>>,
    // @ts-ignore
    typeof clinicAssignmentService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ClinicAssignmentClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicAssignmentService.search>) => (
      clinicAssignmentService.search(...args)
    ),
    ResponseClassCreator: createClinicAssignmentSearchObject,
    typeName: 'useClinicAssignmentSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicAssignmentSearch.mock = () => new Error('Mock has not been implemented!');
useClinicAssignmentSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicAssignmentSearch.createObject = createClinicAssignmentSearchObject;

export interface UpdateRequest<T> {
	filter?: Nullable<T>;
	update?: Nullable<T>;
}
export interface ClientsOption {
	careGroups?: Nullable<string[]>;
	patientLanguages?: Nullable<string[]>;
	clinicStatus?: Nullable<ClinicStatus[]>;
}
export class ClientsOptionClass {
  protected readonly _value: ClientsOption;

	constructor(value: ClientsOption) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get careGroups() {
    return this._value.careGroups;
  }

  get careGroupsWithDefault() {
    return this._value.careGroups || [];
  }

  get patientLanguages() {
    return this._value.patientLanguages;
  }

  get patientLanguagesWithDefault() {
    return this._value.patientLanguages || [];
  }

  get clinicStatus() {
    return this._value.clinicStatus;
  }

  get clinicStatusWithDefault() {
    return this._value.clinicStatus || [];
  }
}
export interface GroupAnalyticsRequest {
	clinic?: Nullable<Clinic>;
	startDate: string;
	endDate: string;
}
export interface ClinicWithParentSeq extends Clinic {
	parentSequenceNumber?: Nullable<string>;
}
export class ClinicWithParentSeqClass extends ClinicClass {
  protected readonly _value: ClinicWithParentSeq;

	constructor(value: ClinicWithParentSeq) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get parentSequenceNumber() {
    return this._value.parentSequenceNumber;
  }

  get parentSequenceNumberWithDefault() {
    return this._value.parentSequenceNumber || '';
  }
}
export enum ImageTypeEnum {
	ORIGINAL = 'ORIGINAL',
	THUMBNAIL = 'THUMBNAIL',
	REGULAR = 'REGULAR',
}
export interface AvatarDownloadUrlInput {
	fileKey?: Nullable<string>;
	type?: Nullable<ImageTypeEnum>;
}
export interface AvatarDownloadUrlOutput {
	fileKey?: Nullable<string>;
	url?: Nullable<string>;
}
export class AvatarDownloadUrlOutputClass {
  protected readonly _value: AvatarDownloadUrlOutput;

	constructor(value: AvatarDownloadUrlOutput) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get fileKey() {
    return this._value.fileKey;
  }

  get fileKeyWithDefault() {
    return this._value.fileKey || '';
  }

  get url() {
    return this._value.url;
  }

  get urlWithDefault() {
    return this._value.url || '';
  }
}
export interface ClinicSearchListRequest extends Clinic {
}
export interface CoBrandingLogoModification {
	id?: Nullable<string>;
	logoBefore?: Nullable<ImageSchema>;
	logoAfter?: Nullable<ImageSchema>;
}
export class CoBrandingLogoModificationClass {
  protected readonly _value: CoBrandingLogoModification;

	constructor(value: CoBrandingLogoModification) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get logoBefore() {
    return this._value.logoBefore !== undefined && this._value.logoBefore !== null ? new ImageSchemaClass(this._value.logoBefore) : this._value.logoBefore;
  }

  get logoAfter() {
    return this._value.logoAfter !== undefined && this._value.logoAfter !== null ? new ImageSchemaClass(this._value.logoAfter) : this._value.logoAfter;
  }
}
export interface ClinicBulkUpdateParams {
	clinicUpdateRequest: UpdateRequest<Clinic>;
}

export interface ClinicListClientsParams {
	searchRequest: SearchRequest<ClinicListFilter>;
}

export interface ClinicListClientsFilterParams {
}

export interface ClinicGroupAnalyticsParams {
	request: GroupAnalyticsRequest;
}

export interface ClinicGetProgramServiceParams {
	id: string;
}

export interface ClinicClinicWithParentSeqParams {
	id: string;
}

export interface ClinicLogoDownloadUrlParams {
	avatarDownloadUrlInput: AvatarDownloadUrlInput;
}

export interface ClinicSearchClinicListParams {
	searchRequest: SearchRequest<ClinicSearchListRequest>;
}

export interface ClinicLogoUploadParams {
	id: string;
	photo: MultipartFile;
}

export interface ClinicLogoDeleteParams {
	id: string;
}

export interface ClinicUpdateCoBrandNameParams {
	id: string;
	coBrandConfig: CoBrandConfig;
}

export class ClinicService extends BaseController<Clinic, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/clinics';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public bulkUpdate({ baseUrl, params, headers }: RequestOption<ClinicBulkUpdateParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Clinic>>(`${baseUrl || this.baseURL}${this.basePath}/bulk${getQueryStrings(queryList)}`, params.clinicUpdateRequest, { headers });
	}

	public listClients({ baseUrl, params, headers }: RequestOption<ClinicListClientsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<ClinicListAggregationResult>>>(`${baseUrl || this.baseURL}${this.basePath}/list/clients${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public listClientsFilter({ baseUrl, params, headers }: RequestOption<ClinicListClientsFilterParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<ClientsOption>>(`${baseUrl || this.baseURL}${this.basePath}/list/clients-filter${getQueryStrings(queryList)}`, { headers });
	}

	public groupAnalytics({ baseUrl, params, headers }: RequestOption<ClinicGroupAnalyticsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<ClinicAnalytics>>(`${baseUrl || this.baseURL}${this.basePath}/group-analytics${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getProgramService({ baseUrl, params, headers }: RequestOption<ClinicGetProgramServiceParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<Clinic>>(`${baseUrl || this.baseURL}${this.basePath}program-service/${params.id}${getQueryStrings(queryList)}`, { headers });
	}

	public clinicWithParentSeq({ baseUrl, params, headers }: RequestOption<ClinicClinicWithParentSeqParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<ClinicWithParentSeq>>(`${baseUrl || this.baseURL}${this.basePath}/with-parent-seq/${params.id}${getQueryStrings(queryList)}`, { headers });
	}

	public logoDownloadUrl({ baseUrl, params, headers }: RequestOption<ClinicLogoDownloadUrlParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<AvatarDownloadUrlOutput>>(`${baseUrl || this.baseURL}${this.basePath}/logo/download-url${getQueryStrings(queryList)}`, params.avatarDownloadUrlInput, { headers });
	}

	public searchClinicList({ baseUrl, params, headers }: RequestOption<ClinicSearchClinicListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<LimitedClinic>>>(`${baseUrl || this.baseURL}${this.basePath}/search-list${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public logoUpload({ baseUrl, params, headers }: RequestOption<ClinicLogoUploadParams>) {
		const queryList: QueryParam[] = [];
		if (params.photo !== undefined && params.photo !== null) {
			queryList.push({ name: 'photo', value: params.photo.toString() });
		}
		return axios.put<APIResponse<CoBrandingLogoModification>>(`${baseUrl || this.baseURL}${this.basePath}program-service/${params.id}/co-brand-logo${getQueryStrings(queryList)}`, {}, { headers });
	}

	public logoDelete({ baseUrl, params, headers }: RequestOption<ClinicLogoDeleteParams>) {
		const queryList: QueryParam[] = [];
		return axios.delete<APIResponse<CoBrandingLogoModification>>(`${baseUrl || this.baseURL}${this.basePath}program-service/${params.id}/co-brand-logo${getQueryStrings(queryList)}`, { headers });
	}

	public updateCoBrandName({ baseUrl, params, headers }: RequestOption<ClinicUpdateCoBrandNameParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<Clinic>>(`${baseUrl || this.baseURL}${this.basePath}program-service/${params.id}/co-brand-name${getQueryStrings(queryList)}`, params.coBrandConfig, { headers });
	}
}

export const clinicService = new ClinicService();

export const createClinicBulkUpdateObject = (value: APIResponse<Clinic>) => new APIResponseClass<ClinicClass>(value, (value: Clinic) => new ClinicClass(value));

export const useClinicBulkUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicBulkUpdateParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicBulkUpdateParams,
    APIResponse<Clinic>,
    typeof clinicService.bulkUpdate,
    AjaxOptions,
    APIResponseClass<ClinicClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicService.bulkUpdate>) => (
      clinicService.bulkUpdate(...args)
    ),
    ResponseClassCreator: createClinicBulkUpdateObject,
    typeName: 'useClinicBulkUpdate',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicBulkUpdate.mock = () => new Error('Mock has not been implemented!');
useClinicBulkUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicBulkUpdate.createObject = createClinicBulkUpdateObject;

export const createClinicListClientsObject = (value: APIResponse<PageResponse<ClinicListAggregationResult>>) => new APIResponseClass<PageResponseClass<ClinicListAggregationResultClass>>(value, (value: PageResponse<ClinicListAggregationResult>) => new PageResponseClass<ClinicListAggregationResultClass>(value, (value: ClinicListAggregationResult) => new ClinicListAggregationResultClass(value)));

export const useClinicListClients = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicListClientsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicListClientsParams,
    APIResponse<PageResponse<ClinicListAggregationResult>>,
    typeof clinicService.listClients,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ClinicListAggregationResultClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicService.listClients>) => (
      clinicService.listClients(...args)
    ),
    ResponseClassCreator: createClinicListClientsObject,
    typeName: 'useClinicListClients',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicListClients.mock = () => new Error('Mock has not been implemented!');
useClinicListClients.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicListClients.createObject = createClinicListClientsObject;

export const createClinicListClientsFilterObject = (value: APIResponse<ClientsOption>) => new APIResponseClass<ClientsOptionClass>(value, (value: ClientsOption) => new ClientsOptionClass(value));

export const useClinicListClientsFilter = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicListClientsFilterParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicListClientsFilterParams,
    APIResponse<ClientsOption>,
    typeof clinicService.listClientsFilter,
    GetOptions,
    APIResponseClass<ClientsOptionClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof clinicService.listClientsFilter>) => (
      clinicService.listClientsFilter(...args)
    ),
    ResponseClassCreator: createClinicListClientsFilterObject,
    typeName: 'useClinicListClientsFilter',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicListClientsFilter.mock = () => new Error('Mock has not been implemented!');
useClinicListClientsFilter.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicListClientsFilter.createObject = createClinicListClientsFilterObject;

export const createClinicGroupAnalyticsObject = (value: APIResponse<ClinicAnalytics>) => new APIResponseClass<ClinicAnalyticsClass>(value, (value: ClinicAnalytics) => new ClinicAnalyticsClass(value));

export const useClinicGroupAnalytics = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicGroupAnalyticsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicGroupAnalyticsParams,
    APIResponse<ClinicAnalytics>,
    typeof clinicService.groupAnalytics,
    AjaxOptions,
    APIResponseClass<ClinicAnalyticsClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicService.groupAnalytics>) => (
      clinicService.groupAnalytics(...args)
    ),
    ResponseClassCreator: createClinicGroupAnalyticsObject,
    typeName: 'useClinicGroupAnalytics',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicGroupAnalytics.mock = () => new Error('Mock has not been implemented!');
useClinicGroupAnalytics.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicGroupAnalytics.createObject = createClinicGroupAnalyticsObject;

export const createClinicGetProgramServiceObject = (value: APIResponse<Clinic>) => new APIResponseClass<ClinicClass>(value, (value: Clinic) => new ClinicClass(value));

export const useClinicGetProgramService = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicGetProgramServiceParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicGetProgramServiceParams,
    APIResponse<Clinic>,
    typeof clinicService.getProgramService,
    GetOptions,
    APIResponseClass<ClinicClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof clinicService.getProgramService>) => (
      clinicService.getProgramService(...args)
    ),
    ResponseClassCreator: createClinicGetProgramServiceObject,
    typeName: 'useClinicGetProgramService',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicGetProgramService.mock = () => new Error('Mock has not been implemented!');
useClinicGetProgramService.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicGetProgramService.createObject = createClinicGetProgramServiceObject;

export const createClinicClinicWithParentSeqObject = (value: APIResponse<ClinicWithParentSeq>) => new APIResponseClass<ClinicWithParentSeqClass>(value, (value: ClinicWithParentSeq) => new ClinicWithParentSeqClass(value));

export const useClinicClinicWithParentSeq = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicClinicWithParentSeqParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicClinicWithParentSeqParams,
    APIResponse<ClinicWithParentSeq>,
    typeof clinicService.clinicWithParentSeq,
    GetOptions,
    APIResponseClass<ClinicWithParentSeqClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof clinicService.clinicWithParentSeq>) => (
      clinicService.clinicWithParentSeq(...args)
    ),
    ResponseClassCreator: createClinicClinicWithParentSeqObject,
    typeName: 'useClinicClinicWithParentSeq',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicClinicWithParentSeq.mock = () => new Error('Mock has not been implemented!');
useClinicClinicWithParentSeq.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicClinicWithParentSeq.createObject = createClinicClinicWithParentSeqObject;

export const createClinicLogoDownloadUrlObject = (value: APIResponse<AvatarDownloadUrlOutput>) => new APIResponseClass<AvatarDownloadUrlOutputClass>(value, (value: AvatarDownloadUrlOutput) => new AvatarDownloadUrlOutputClass(value));

export const useClinicLogoDownloadUrl = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicLogoDownloadUrlParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicLogoDownloadUrlParams,
    APIResponse<AvatarDownloadUrlOutput>,
    typeof clinicService.logoDownloadUrl,
    AjaxOptions,
    APIResponseClass<AvatarDownloadUrlOutputClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicService.logoDownloadUrl>) => (
      clinicService.logoDownloadUrl(...args)
    ),
    ResponseClassCreator: createClinicLogoDownloadUrlObject,
    typeName: 'useClinicLogoDownloadUrl',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicLogoDownloadUrl.mock = () => new Error('Mock has not been implemented!');
useClinicLogoDownloadUrl.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicLogoDownloadUrl.createObject = createClinicLogoDownloadUrlObject;

export const createClinicSearchClinicListObject = (value: APIResponse<PageResponse<LimitedClinic>>) => new APIResponseClass<PageResponseClass<LimitedClinicClass>>(value, (value: PageResponse<LimitedClinic>) => new PageResponseClass<LimitedClinicClass>(value, (value: LimitedClinic) => new LimitedClinicClass(value)));

export const useClinicSearchClinicList = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicSearchClinicListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicSearchClinicListParams,
    APIResponse<PageResponse<LimitedClinic>>,
    typeof clinicService.searchClinicList,
    AjaxOptions,
    APIResponseClass<PageResponseClass<LimitedClinicClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicService.searchClinicList>) => (
      clinicService.searchClinicList(...args)
    ),
    ResponseClassCreator: createClinicSearchClinicListObject,
    typeName: 'useClinicSearchClinicList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicSearchClinicList.mock = () => new Error('Mock has not been implemented!');
useClinicSearchClinicList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicSearchClinicList.createObject = createClinicSearchClinicListObject;

export const createClinicLogoUploadObject = (value: APIResponse<CoBrandingLogoModification>) => new APIResponseClass<CoBrandingLogoModificationClass>(value, (value: CoBrandingLogoModification) => new CoBrandingLogoModificationClass(value));

export const useClinicLogoUpload = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicLogoUploadParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicLogoUploadParams,
    APIResponse<CoBrandingLogoModification>,
    typeof clinicService.logoUpload,
    AjaxOptions,
    APIResponseClass<CoBrandingLogoModificationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicService.logoUpload>) => (
      clinicService.logoUpload(...args)
    ),
    ResponseClassCreator: createClinicLogoUploadObject,
    typeName: 'useClinicLogoUpload',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicLogoUpload.mock = () => new Error('Mock has not been implemented!');
useClinicLogoUpload.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicLogoUpload.createObject = createClinicLogoUploadObject;

export const createClinicLogoDeleteObject = (value: APIResponse<CoBrandingLogoModification>) => new APIResponseClass<CoBrandingLogoModificationClass>(value, (value: CoBrandingLogoModification) => new CoBrandingLogoModificationClass(value));

export const useClinicLogoDelete = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicLogoDeleteParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicLogoDeleteParams,
    APIResponse<CoBrandingLogoModification>,
    typeof clinicService.logoDelete,
    AjaxOptions,
    APIResponseClass<CoBrandingLogoModificationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicService.logoDelete>) => (
      clinicService.logoDelete(...args)
    ),
    ResponseClassCreator: createClinicLogoDeleteObject,
    typeName: 'useClinicLogoDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicLogoDelete.mock = () => new Error('Mock has not been implemented!');
useClinicLogoDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicLogoDelete.createObject = createClinicLogoDeleteObject;

export const createClinicUpdateCoBrandNameObject = (value: APIResponse<Clinic>) => new APIResponseClass<ClinicClass>(value, (value: Clinic) => new ClinicClass(value));

export const useClinicUpdateCoBrandName = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicUpdateCoBrandNameParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicUpdateCoBrandNameParams,
    APIResponse<Clinic>,
    typeof clinicService.updateCoBrandName,
    AjaxOptions,
    APIResponseClass<ClinicClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicService.updateCoBrandName>) => (
      clinicService.updateCoBrandName(...args)
    ),
    ResponseClassCreator: createClinicUpdateCoBrandNameObject,
    typeName: 'useClinicUpdateCoBrandName',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicUpdateCoBrandName.mock = () => new Error('Mock has not been implemented!');
useClinicUpdateCoBrandName.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicUpdateCoBrandName.createObject = createClinicUpdateCoBrandNameObject;


export const createClinicGetObject = (value: APIResponse<Clinic>) => new APIResponseClass<ClinicClass>(value, (value: Clinic) => new ClinicClass(value));
export const useClinicGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<Clinic>,
    // @ts-ignore
    typeof clinicService.get,
    GetOptions,
    APIResponseClass<ClinicClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof clinicService.get>) => (
      clinicService.get(...args)
    ),
    ResponseClassCreator: createClinicGetObject,
    typeName: 'useClinicGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicGet.mock = () => new Error('Mock has not been implemented!');
useClinicGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicGet.createObject = createClinicGetObject;

export const createClinicUpdateObject = (value: APIResponse<Clinic>) => new APIResponseClass<ClinicClass>(value, (value: Clinic) => new ClinicClass(value));
export const useClinicUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, Clinic>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, Clinic>,
    APIResponse<Clinic>,
    // @ts-ignore
    typeof clinicService.update,
    AjaxOptions,
    APIResponseClass<ClinicClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicService.update>) => (
      clinicService.update(...args)
    ),
    ResponseClassCreator: createClinicUpdateObject,
    typeName: 'useClinicUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicUpdate.mock = () => new Error('Mock has not been implemented!');
useClinicUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicUpdate.createObject = createClinicUpdateObject;

export const createClinicInsertObject = (value: APIResponse<Clinic>) => new APIResponseClass<ClinicClass>(value, (value: Clinic) => new ClinicClass(value));
export const useClinicInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<Clinic>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<Clinic>,
    APIResponse<Clinic>,
    // @ts-ignore
    typeof clinicService.insert,
    AjaxOptions,
    APIResponseClass<ClinicClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicService.insert>) => (
      clinicService.insert(...args)
    ),
    ResponseClassCreator: createClinicInsertObject,
    typeName: 'useClinicInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicInsert.mock = () => new Error('Mock has not been implemented!');
useClinicInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicInsert.createObject = createClinicInsertObject;

export const createClinicDeleteObject = (value: APIResponse<Clinic>) => new APIResponseClass<ClinicClass>(value, (value: Clinic) => new ClinicClass(value));
export const useClinicDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<Clinic>,
    // @ts-ignore
    typeof clinicService.delete,
    AjaxOptions,
    APIResponseClass<ClinicClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicService.delete>) => (
      clinicService.delete(...args)
    ),
    ResponseClassCreator: createClinicDeleteObject,
    typeName: 'useClinicDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicDelete.mock = () => new Error('Mock has not been implemented!');
useClinicDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicDelete.createObject = createClinicDeleteObject;

export const createClinicSearchObject = (value: APIResponse<PageResponse<Clinic>>) => new APIResponseClass<PageResponseClass<ClinicClass>>(value, (value: PageResponse<Clinic>) => new PageResponseClass<ClinicClass>(value, (value: Clinic) => new ClinicClass(value)));
export const useClinicSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<Clinic>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<Clinic>,
    APIResponse<PageResponse<Clinic>>,
    // @ts-ignore
    typeof clinicService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ClinicClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicService.search>) => (
      clinicService.search(...args)
    ),
    ResponseClassCreator: createClinicSearchObject,
    typeName: 'useClinicSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicSearch.mock = () => new Error('Mock has not been implemented!');
useClinicSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicSearch.createObject = createClinicSearchObject;

export enum CalendarSourceEnum {
	UC_VISIT = 'UC_VISIT',
	GOOGLE_CALENDAR = 'GOOGLE_CALENDAR',
	OTHER = 'OTHER',
}
export enum VisitTypeEnum {
	INIT = 'INIT',
	TECH_ONBOARDING = 'TECH_ONBOARDING',
	TECH = 'TECH',
	FOLLOW_UP = 'FOLLOW_UP',
	ADDITIONAL = 'ADDITIONAL',
	INITIAL = 'INITIAL',
	POST_MD_FOLLOW_UP = 'POST_MD_FOLLOW_UP',
	MNT = 'MNT',
	FOLLOW_UP1 = 'FOLLOW_UP1',
	FOLLOW_UP2 = 'FOLLOW_UP2',
	FOLLOW_UP3 = 'FOLLOW_UP3',
	CLINIC_FOLLOW_UP = 'CLINIC_FOLLOW_UP',
	ANNUAL = 'ANNUAL',
	shortName = 'shortName',
}
export enum VisitMethodEnum {
	IN_CLINIC = 'IN_CLINIC',
	PHONE = 'PHONE',
	VIRTUAL = 'VIRTUAL',
	INCLINIC = 'INCLINIC',
}
export enum VisitParticipantRoleEnum {
	RD_HC = 'RD_HC',
	CA = 'CA',
	MD = 'MD',
	MA = 'MA',
}
export interface VisitParticipant {
	role?: Nullable<VisitParticipantRoleEnum>;
	participantId?: Nullable<string>;
	hasSentReminder?: Nullable<boolean>;
	roleInVisit?: Nullable<RoleTypeEnum>;
	participantInfo?: Nullable<LimitedUser>;
}
export interface CommonHistoryItem {
	text?: Nullable<string>;
	updatedAt?: Nullable<string>;
	updatedBy?: Nullable<string>;
	updatedByUser?: Nullable<LimitedUser>;
}
export enum VisitChartingStatusEnum {
	TODO = 'TODO',
	IN_PROGRESS = 'IN_PROGRESS',
	DONE = 'DONE',
}
export enum VisitStatusEnum {
	UPCOMING = 'UPCOMING',
	START_SOON = 'START_SOON',
	WAITING = 'WAITING',
	ONGOING = 'ONGOING',
	NO_SHOW = 'NO_SHOW',
	CHECKED_OUT = 'CHECKED_OUT',
}
export enum OnboardingChecklistItemEnum {
	AUTO_UPDATE_ENABLED = 'AUTO_UPDATE_ENABLED',
	CT_NUMBER_ADDED = 'CT_NUMBER_ADDED',
	PATIENT_GUIDED = 'PATIENT_GUIDED',
	KEYFUNCTIONS_INTRODUCED = 'KEYFUNCTIONS_INTRODUCED',
}
export enum ZoomStatusEnum {
	IN_PROGRESS = 'IN_PROGRESS',
	ENDED = 'ENDED',
}
export enum PatientSourceEnum {
	BATCH_CREATED = 'BATCH_CREATED',
	MANUALLY_CREATED = 'MANUALLY_CREATED',
	MD_MA_REFERRED = 'MD_MA_REFERRED',
	SCREENED_FROM_EHR_VISITS = 'SCREENED_FROM_EHR_VISITS',
	EHR_INTEGRATION_CREATED = 'EHR_INTEGRATION_CREATED',
	MIGRATION = 'MIGRATION',
}
export interface LastMeasurementResponse {
	type?: Nullable<MeasurementResultTypeEnum>;
	date?: Nullable<string>;
}
export enum VitalEnumType {
	BP = 'BP',
	BG = 'BG',
	HS = 'HS',
	PO = 'PO',
	TM = 'TM',
	EXERCISE = 'EXERCISE',
	CGM = 'CGM',
	value = 'value',
}
export interface MeasurementEntity {
	lastMeasurementResponses?: Nullable<LastMeasurementResponse[]>;
	vitals?: Nullable<VitalEnumType[]>;
}
export interface ZoomCredential {
	meetingNumber?: Nullable<string>;
	password?: Nullable<string>;
	signature?: Nullable<string>;
	clientId?: Nullable<string>;
	createTime?: Nullable<string>;
}
export interface EventOwner {
	employeeId?: Nullable<string>;
	name?: Nullable<string>;
	email?: Nullable<string>;
}
export enum TranscribeStatusEnum {
	TODO = 'TODO',
	INCOMPLETE = 'INCOMPLETE',
	NO_RECORD = 'NO_RECORD',
	COMPLETE = 'COMPLETE',
}
export enum EnrolledProgramStatusEnum {
	UNENROLLED = 'UNENROLLED',
	ENROLLED = 'ENROLLED',
	DISCHARGED = 'DISCHARGED',
	REJECTED = 'REJECTED',
}
export interface LimitedEnrolledProgram {
	status?: Nullable<EnrolledProgramStatusEnum>;
	programs?: Nullable<ProgramCategoryEnum[]>;
}
export interface ClinicEvent extends BaseModel {
	patientId?: Nullable<string>;
	clinicId?: Nullable<string>;
	providerId?: Nullable<string>;
	calendarEventId?: Nullable<string>;
	summary?: Nullable<string>;
	calendarSource?: Nullable<CalendarSourceEnum>;
	visitType?: Nullable<VisitTypeEnum>;
	visitMethod?: Nullable<VisitMethodEnum>;
	responseStatus?: Nullable<string>;
	visitParticipants?: Nullable<VisitParticipant[]>;
	description?: Nullable<string>;
	declineToCheckVital?: Nullable<boolean>;
	transcribeId?: Nullable<string>;
	eventTitle?: Nullable<string>;
	visitSummary?: Nullable<string>;
	visitSummaryHistory?: Nullable<CommonHistoryItem[]>;
	visitChartingStatus?: Nullable<VisitChartingStatusEnum>;
	followupVisitSkipped?: Nullable<boolean>;
	visitStatus?: Nullable<VisitStatusEnum>;
	onboardingChecklist?: Nullable<OnboardingChecklistItemEnum[]>;
	zoomStatus?: Nullable<ZoomStatusEnum>;
	accessToView?: Nullable<boolean>;
	source?: Nullable<PatientSourceEnum>;
	startTime?: Nullable<string>;
	endTime?: Nullable<string>;
	checkInTime?: Nullable<string>;
	checkOutTime?: Nullable<string>;
	hasSentReminder?: Nullable<boolean>;
	checkOwnPatients?: Nullable<boolean>;
	measurementResponse?: Nullable<MeasurementEntity>;
	createdBy?: Nullable<string>;
	updatedBy?: Nullable<string>;
	zoomCredential?: Nullable<ZoomCredential>;
	owner?: Nullable<EventOwner>;
	hasCheckedOut?: Nullable<boolean>;
	transcribeStatus?: Nullable<TranscribeStatusEnum>;
	patientInfo?: Nullable<LimitedPatient>;
	doctorInfo?: Nullable<LimitedUser>;
	enrolledProgramInfo?: Nullable<LimitedEnrolledProgram>;
	clinicInfo?: Nullable<LimitedClinic>;
	patientIdIn?: Nullable<InFilter<string>>;
	startTimeRange?: Nullable<DateRangeFilter>;
	endTimeRange?: Nullable<DateRangeFilter>;
	participantsIn?: Nullable<InFilter<string>>;
	visitTypeIn?: Nullable<InFilter<VisitTypeEnum>>;
	myVisitTypeIn?: Nullable<InFilter<VisitTypeEnum>>;
	visitMethodIn?: Nullable<InFilter<VisitMethodEnum>>;
	clinicIdIn?: Nullable<InFilter<string>>;
	calendarSourceIn?: Nullable<InFilter<CalendarSourceEnum>>;
	myCalendarSourceIn?: Nullable<InFilter<CalendarSourceEnum>>;
}
export class VisitParticipantClass {
  protected readonly _value: VisitParticipant;

	constructor(value: VisitParticipant) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get role() {
    return this._value.role;
  }

  roleIsIn(values: VisitParticipantRoleEnum[] = []) {
    return this._value.role && values.includes(this._value.role);
  }

  get participantId() {
    return this._value.participantId;
  }

  get hasSentReminder() {
    return this._value.hasSentReminder;
  }

  get hasSentReminderWithDefault() {
    return this._value.hasSentReminder || false;
  }

  get roleInVisit() {
    return this._value.roleInVisit;
  }

  roleInVisitIsIn(values: RoleTypeEnum[] = []) {
    return this._value.roleInVisit && values.includes(this._value.roleInVisit);
  }

  get participantInfo() {
    return this._value.participantInfo !== undefined && this._value.participantInfo !== null ? new LimitedUserClass(this._value.participantInfo) : this._value.participantInfo;
  }
}
export class CommonHistoryItemClass {
  protected readonly _value: CommonHistoryItem;

	constructor(value: CommonHistoryItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get text() {
    return this._value.text;
  }

  get textWithDefault() {
    return this._value.text || '';
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get updatedByUser() {
    return this._value.updatedByUser !== undefined && this._value.updatedByUser !== null ? new LimitedUserClass(this._value.updatedByUser) : this._value.updatedByUser;
  }
}
export class LastMeasurementResponseClass {
  protected readonly _value: LastMeasurementResponse;

	constructor(value: LastMeasurementResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: MeasurementResultTypeEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get date() {
    return (this._value.date !== undefined && this._value.date !== null) ? dayjs(this._value.date) : this._value.date;
  }
}
export class MeasurementEntityClass {
  protected readonly _value: MeasurementEntity;

	constructor(value: MeasurementEntity) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get lastMeasurementResponses() {
    return this._value.lastMeasurementResponses !== undefined && this._value.lastMeasurementResponses !== null ? map(this._value.lastMeasurementResponses, (v) => new LastMeasurementResponseClass(v)) : this._value.lastMeasurementResponses;
  }

  get lastMeasurementResponsesWithDefault() {
    return this._value.lastMeasurementResponses || [];
  }

  get vitals() {
    return this._value.vitals;
  }

  get vitalsWithDefault() {
    return this._value.vitals || [];
  }
}
export class ZoomCredentialClass {
  protected readonly _value: ZoomCredential;

	constructor(value: ZoomCredential) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get meetingNumber() {
    return this._value.meetingNumber;
  }

  get meetingNumberWithDefault() {
    return this._value.meetingNumber || '';
  }

  get password() {
    return this._value.password;
  }

  get passwordWithDefault() {
    return this._value.password || '';
  }

  get signature() {
    return this._value.signature;
  }

  get signatureWithDefault() {
    return this._value.signature || '';
  }

  get clientId() {
    return this._value.clientId;
  }

  get clientIdWithDefault() {
    return this._value.clientId || '';
  }

  get createTime() {
    return (this._value.createTime !== undefined && this._value.createTime !== null) ? dayjs(this._value.createTime) : this._value.createTime;
  }
}
export class EventOwnerClass {
  protected readonly _value: EventOwner;

	constructor(value: EventOwner) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get employeeId() {
    return this._value.employeeId;
  }

  get employeeIdWithDefault() {
    return this._value.employeeId || '';
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get email() {
    return this._value.email;
  }

  get emailWithDefault() {
    return this._value.email || '';
  }
}
export class LimitedEnrolledProgramClass {
  protected readonly _value: LimitedEnrolledProgram;

	constructor(value: LimitedEnrolledProgram) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: EnrolledProgramStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get programs() {
    return this._value.programs;
  }

  get programsWithDefault() {
    return this._value.programs || [];
  }
}
export class ClinicEventClass extends BaseModelClass {
  protected readonly _value: ClinicEvent;

	constructor(value: ClinicEvent) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientId() {
    return this._value.patientId;
  }

  get clinicId() {
    return this._value.clinicId;
  }

  get providerId() {
    return this._value.providerId;
  }

  get calendarEventId() {
    return this._value.calendarEventId;
  }

  get calendarEventIdWithDefault() {
    return this._value.calendarEventId || '';
  }

  get summary() {
    return this._value.summary;
  }

  get summaryWithDefault() {
    return this._value.summary || '';
  }

  get calendarSource() {
    return this._value.calendarSource;
  }

  calendarSourceIsIn(values: CalendarSourceEnum[] = []) {
    return this._value.calendarSource && values.includes(this._value.calendarSource);
  }

  get visitType() {
    return this._value.visitType;
  }

  visitTypeIsIn(values: VisitTypeEnum[] = []) {
    return this._value.visitType && values.includes(this._value.visitType);
  }

  get visitMethod() {
    return this._value.visitMethod;
  }

  visitMethodIsIn(values: VisitMethodEnum[] = []) {
    return this._value.visitMethod && values.includes(this._value.visitMethod);
  }

  get responseStatus() {
    return this._value.responseStatus;
  }

  get responseStatusWithDefault() {
    return this._value.responseStatus || '';
  }

  get visitParticipants() {
    return this._value.visitParticipants !== undefined && this._value.visitParticipants !== null ? map(this._value.visitParticipants, (v) => new VisitParticipantClass(v)) : this._value.visitParticipants;
  }

  get visitParticipantsWithDefault() {
    return this._value.visitParticipants || [];
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get declineToCheckVital() {
    return this._value.declineToCheckVital;
  }

  get declineToCheckVitalWithDefault() {
    return this._value.declineToCheckVital || false;
  }

  get transcribeId() {
    return this._value.transcribeId;
  }

  get eventTitle() {
    return this._value.eventTitle;
  }

  get eventTitleWithDefault() {
    return this._value.eventTitle || '';
  }

  get visitSummary() {
    return this._value.visitSummary;
  }

  get visitSummaryWithDefault() {
    return this._value.visitSummary || '';
  }

  get visitSummaryHistory() {
    return this._value.visitSummaryHistory !== undefined && this._value.visitSummaryHistory !== null ? map(this._value.visitSummaryHistory, (v) => new CommonHistoryItemClass(v)) : this._value.visitSummaryHistory;
  }

  get visitSummaryHistoryWithDefault() {
    return this._value.visitSummaryHistory || [];
  }

  get visitChartingStatus() {
    return this._value.visitChartingStatus;
  }

  visitChartingStatusIsIn(values: VisitChartingStatusEnum[] = []) {
    return this._value.visitChartingStatus && values.includes(this._value.visitChartingStatus);
  }

  get followupVisitSkipped() {
    return this._value.followupVisitSkipped;
  }

  get followupVisitSkippedWithDefault() {
    return this._value.followupVisitSkipped || false;
  }

  get visitStatus() {
    return this._value.visitStatus;
  }

  visitStatusIsIn(values: VisitStatusEnum[] = []) {
    return this._value.visitStatus && values.includes(this._value.visitStatus);
  }

  get onboardingChecklist() {
    return this._value.onboardingChecklist;
  }

  get onboardingChecklistWithDefault() {
    return this._value.onboardingChecklist || [];
  }

  get zoomStatus() {
    return this._value.zoomStatus;
  }

  zoomStatusIsIn(values: ZoomStatusEnum[] = []) {
    return this._value.zoomStatus && values.includes(this._value.zoomStatus);
  }

  get accessToView() {
    return this._value.accessToView;
  }

  get accessToViewWithDefault() {
    return this._value.accessToView || false;
  }

  get source() {
    return this._value.source;
  }

  sourceIsIn(values: PatientSourceEnum[] = []) {
    return this._value.source && values.includes(this._value.source);
  }

  get startTime() {
    return (this._value.startTime !== undefined && this._value.startTime !== null) ? dayjs(this._value.startTime) : this._value.startTime;
  }

  get endTime() {
    return (this._value.endTime !== undefined && this._value.endTime !== null) ? dayjs(this._value.endTime) : this._value.endTime;
  }

  get checkInTime() {
    return (this._value.checkInTime !== undefined && this._value.checkInTime !== null) ? dayjs(this._value.checkInTime) : this._value.checkInTime;
  }

  get checkOutTime() {
    return (this._value.checkOutTime !== undefined && this._value.checkOutTime !== null) ? dayjs(this._value.checkOutTime) : this._value.checkOutTime;
  }

  get hasSentReminder() {
    return this._value.hasSentReminder;
  }

  get hasSentReminderWithDefault() {
    return this._value.hasSentReminder || false;
  }

  get checkOwnPatients() {
    return this._value.checkOwnPatients;
  }

  get checkOwnPatientsWithDefault() {
    return this._value.checkOwnPatients || false;
  }

  get measurementResponse() {
    return this._value.measurementResponse !== undefined && this._value.measurementResponse !== null ? new MeasurementEntityClass(this._value.measurementResponse) : this._value.measurementResponse;
  }

  get createdBy() {
    return this._value.createdBy;
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get zoomCredential() {
    return this._value.zoomCredential !== undefined && this._value.zoomCredential !== null ? new ZoomCredentialClass(this._value.zoomCredential) : this._value.zoomCredential;
  }

  get owner() {
    return this._value.owner !== undefined && this._value.owner !== null ? new EventOwnerClass(this._value.owner) : this._value.owner;
  }

  get hasCheckedOut() {
    return this._value.hasCheckedOut;
  }

  get hasCheckedOutWithDefault() {
    return this._value.hasCheckedOut || false;
  }

  get transcribeStatus() {
    return this._value.transcribeStatus;
  }

  transcribeStatusIsIn(values: TranscribeStatusEnum[] = []) {
    return this._value.transcribeStatus && values.includes(this._value.transcribeStatus);
  }

  get patientInfo() {
    return this._value.patientInfo !== undefined && this._value.patientInfo !== null ? new LimitedPatientClass(this._value.patientInfo) : this._value.patientInfo;
  }

  get doctorInfo() {
    return this._value.doctorInfo !== undefined && this._value.doctorInfo !== null ? new LimitedUserClass(this._value.doctorInfo) : this._value.doctorInfo;
  }

  get enrolledProgramInfo() {
    return this._value.enrolledProgramInfo !== undefined && this._value.enrolledProgramInfo !== null ? new LimitedEnrolledProgramClass(this._value.enrolledProgramInfo) : this._value.enrolledProgramInfo;
  }

  get clinicInfo() {
    return this._value.clinicInfo !== undefined && this._value.clinicInfo !== null ? new LimitedClinicClass(this._value.clinicInfo) : this._value.clinicInfo;
  }

  get patientIdIn() {
    return this._value.patientIdIn !== undefined && this._value.patientIdIn !== null ? new InFilterClass(this._value.patientIdIn) : this._value.patientIdIn;
  }

  get startTimeRange() {
    return this._value.startTimeRange !== undefined && this._value.startTimeRange !== null ? new DateRangeFilterClass(this._value.startTimeRange) : this._value.startTimeRange;
  }

  get endTimeRange() {
    return this._value.endTimeRange !== undefined && this._value.endTimeRange !== null ? new DateRangeFilterClass(this._value.endTimeRange) : this._value.endTimeRange;
  }

  get participantsIn() {
    return this._value.participantsIn !== undefined && this._value.participantsIn !== null ? new InFilterClass(this._value.participantsIn) : this._value.participantsIn;
  }

  get visitTypeIn() {
    return this._value.visitTypeIn !== undefined && this._value.visitTypeIn !== null ? new InFilterClass(this._value.visitTypeIn) : this._value.visitTypeIn;
  }

  get myVisitTypeIn() {
    return this._value.myVisitTypeIn !== undefined && this._value.myVisitTypeIn !== null ? new InFilterClass(this._value.myVisitTypeIn) : this._value.myVisitTypeIn;
  }

  get visitMethodIn() {
    return this._value.visitMethodIn !== undefined && this._value.visitMethodIn !== null ? new InFilterClass(this._value.visitMethodIn) : this._value.visitMethodIn;
  }

  get clinicIdIn() {
    return this._value.clinicIdIn !== undefined && this._value.clinicIdIn !== null ? new InFilterClass(this._value.clinicIdIn) : this._value.clinicIdIn;
  }

  get calendarSourceIn() {
    return this._value.calendarSourceIn !== undefined && this._value.calendarSourceIn !== null ? new InFilterClass(this._value.calendarSourceIn) : this._value.calendarSourceIn;
  }

  get myCalendarSourceIn() {
    return this._value.myCalendarSourceIn !== undefined && this._value.myCalendarSourceIn !== null ? new InFilterClass(this._value.myCalendarSourceIn) : this._value.myCalendarSourceIn;
  }
}
export interface SimpleDateRange {
	startDate?: Nullable<string>;
	endDate?: Nullable<string>;
}
export class SimpleDateRangeClass {
  protected readonly _value: SimpleDateRange;

	constructor(value: SimpleDateRange) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get startDate() {
    return (this._value.startDate !== undefined && this._value.startDate !== null) ? dayjs(this._value.startDate) : this._value.startDate;
  }

  get endDate() {
    return (this._value.endDate !== undefined && this._value.endDate !== null) ? dayjs(this._value.endDate) : this._value.endDate;
  }
}
export interface OverlapEventRequest {
	startTime?: Nullable<string>;
	endTime?: Nullable<string>;
	patientId?: Nullable<string>;
	memberIds?: Nullable<string[]>;
	excludedVisitIds?: Nullable<string[]>;
}
export interface OverlapEvent {
	startTime?: Nullable<string>;
	endTime?: Nullable<string>;
}
export interface OverlapEventResponse {
	overlapEvents?: Nullable<OverlapEvent[]>;
	employeeId?: Nullable<string>;
	employeeUser?: Nullable<LimitedUser>;
}
export class OverlapEventClass {
  protected readonly _value: OverlapEvent;

	constructor(value: OverlapEvent) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get startTime() {
    return (this._value.startTime !== undefined && this._value.startTime !== null) ? dayjs(this._value.startTime) : this._value.startTime;
  }

  get endTime() {
    return (this._value.endTime !== undefined && this._value.endTime !== null) ? dayjs(this._value.endTime) : this._value.endTime;
  }
}
export class OverlapEventResponseClass {
  protected readonly _value: OverlapEventResponse;

	constructor(value: OverlapEventResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get overlapEvents() {
    return this._value.overlapEvents !== undefined && this._value.overlapEvents !== null ? map(this._value.overlapEvents, (v) => new OverlapEventClass(v)) : this._value.overlapEvents;
  }

  get overlapEventsWithDefault() {
    return this._value.overlapEvents || [];
  }

  get employeeId() {
    return this._value.employeeId;
  }

  get employeeUser() {
    return this._value.employeeUser !== undefined && this._value.employeeUser !== null ? new LimitedUserClass(this._value.employeeUser) : this._value.employeeUser;
  }
}
export interface ClinicEventCount {
	visitCount?: Nullable<number>;
	otherCount?: Nullable<number>;
}
export class ClinicEventCountClass {
  protected readonly _value: ClinicEventCount;

	constructor(value: ClinicEventCount) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get visitCount() {
    return this._value.visitCount;
  }

  get visitCountWithDefault() {
    return this._value.visitCount || 0;
  }

  get otherCount() {
    return this._value.otherCount;
  }

  get otherCountWithDefault() {
    return this._value.otherCount || 0;
  }
}
export interface ClinicEventDeleteParams {
	id: string;
}

export interface ClinicEventGetFollowupDateRangeParams {
	id: string;
}

export interface ClinicEventCheckInVisitParams {
	id: string;
}

export interface ClinicEventCheckOutVisitParams {
	id: string;
	request: ClinicEvent;
}

export interface ClinicEventInternalSearchParams {
	request: SearchRequest<ClinicEvent>;
}

export interface ClinicEventGetClinicEventListParams {
	request: SearchRequest<ClinicEvent>;
}

export interface ClinicEventGetPtFutureVisitsParams {
	id: string;
}

export interface ClinicEventGetVisitWindowSuggestionParams {
	memberId: string;
	request: ClinicEvent;
}

export interface ClinicEventCheckOverlapCalendarEventParams {
	overlapEventRequest: OverlapEventRequest;
}

export interface ClinicEventGetClinicEventCountParams {
	request: SearchRequest<ClinicEvent>;
}

export class ClinicEventService extends BaseController<ClinicEvent, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/clinic-event';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public delete({ baseUrl, params, headers }: RequestOption<ClinicEventDeleteParams>) {
		const queryList: QueryParam[] = [];
		return axios.delete<APIResponse<ClinicEvent>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}${getQueryStrings(queryList)}`, { headers });
	}

	public getFollowupDateRange({ baseUrl, params, headers }: RequestOption<ClinicEventGetFollowupDateRangeParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<SimpleDateRange>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/followup-date-range${getQueryStrings(queryList)}`, { headers });
	}

	public checkInVisit({ baseUrl, params, headers }: RequestOption<ClinicEventCheckInVisitParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<ClinicEvent>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/check-in${getQueryStrings(queryList)}`, {}, { headers });
	}

	public checkOutVisit({ baseUrl, params, headers }: RequestOption<ClinicEventCheckOutVisitParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<ClinicEvent>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/check-out${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public internalSearch({ baseUrl, params, headers }: RequestOption<ClinicEventInternalSearchParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<ClinicEvent>>>(`${baseUrl || this.baseURL}${this.basePath}/internal-search${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getClinicEventList({ baseUrl, params, headers }: RequestOption<ClinicEventGetClinicEventListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<ClinicEvent>>>(`${baseUrl || this.baseURL}${this.basePath}/list${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getPtFutureVisits({ baseUrl, params, headers }: RequestOption<ClinicEventGetPtFutureVisitsParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<ClinicEvent[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/future-visits${getQueryStrings(queryList)}`, { headers });
	}

	public getVisitWindowSuggestion({ baseUrl, params, headers }: RequestOption<ClinicEventGetVisitWindowSuggestionParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<ClinicEvent[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/visit-window-suggestion${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public checkOverlapCalendarEvent({ baseUrl, params, headers }: RequestOption<ClinicEventCheckOverlapCalendarEventParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<OverlapEventResponse[]>>(`${baseUrl || this.baseURL}${this.basePath}/overlap${getQueryStrings(queryList)}`, params.overlapEventRequest, { headers });
	}

	public getClinicEventCount({ baseUrl, params, headers }: RequestOption<ClinicEventGetClinicEventCountParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<ClinicEventCount>>(`${baseUrl || this.baseURL}${this.basePath}/count${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const clinicEventService = new ClinicEventService();

export const createClinicEventDeleteObject = (value: APIResponse<ClinicEvent>) => new APIResponseClass<ClinicEventClass>(value, (value: ClinicEvent) => new ClinicEventClass(value));

export const useClinicEventDelete = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicEventDeleteParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicEventDeleteParams,
    APIResponse<ClinicEvent>,
    typeof clinicEventService.delete,
    AjaxOptions,
    APIResponseClass<ClinicEventClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicEventService.delete>) => (
      clinicEventService.delete(...args)
    ),
    ResponseClassCreator: createClinicEventDeleteObject,
    typeName: 'useClinicEventDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicEventDelete.mock = () => new Error('Mock has not been implemented!');
useClinicEventDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicEventDelete.createObject = createClinicEventDeleteObject;

export const createClinicEventGetFollowupDateRangeObject = (value: APIResponse<SimpleDateRange>) => new APIResponseClass<SimpleDateRangeClass>(value, (value: SimpleDateRange) => new SimpleDateRangeClass(value));

export const useClinicEventGetFollowupDateRange = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicEventGetFollowupDateRangeParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicEventGetFollowupDateRangeParams,
    APIResponse<SimpleDateRange>,
    typeof clinicEventService.getFollowupDateRange,
    GetOptions,
    APIResponseClass<SimpleDateRangeClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof clinicEventService.getFollowupDateRange>) => (
      clinicEventService.getFollowupDateRange(...args)
    ),
    ResponseClassCreator: createClinicEventGetFollowupDateRangeObject,
    typeName: 'useClinicEventGetFollowupDateRange',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicEventGetFollowupDateRange.mock = () => new Error('Mock has not been implemented!');
useClinicEventGetFollowupDateRange.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicEventGetFollowupDateRange.createObject = createClinicEventGetFollowupDateRangeObject;

export const createClinicEventCheckInVisitObject = (value: APIResponse<ClinicEvent>) => new APIResponseClass<ClinicEventClass>(value, (value: ClinicEvent) => new ClinicEventClass(value));

export const useClinicEventCheckInVisit = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicEventCheckInVisitParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicEventCheckInVisitParams,
    APIResponse<ClinicEvent>,
    typeof clinicEventService.checkInVisit,
    AjaxOptions,
    APIResponseClass<ClinicEventClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicEventService.checkInVisit>) => (
      clinicEventService.checkInVisit(...args)
    ),
    ResponseClassCreator: createClinicEventCheckInVisitObject,
    typeName: 'useClinicEventCheckInVisit',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicEventCheckInVisit.mock = () => new Error('Mock has not been implemented!');
useClinicEventCheckInVisit.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicEventCheckInVisit.createObject = createClinicEventCheckInVisitObject;

export const createClinicEventCheckOutVisitObject = (value: APIResponse<ClinicEvent>) => new APIResponseClass<ClinicEventClass>(value, (value: ClinicEvent) => new ClinicEventClass(value));

export const useClinicEventCheckOutVisit = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicEventCheckOutVisitParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicEventCheckOutVisitParams,
    APIResponse<ClinicEvent>,
    typeof clinicEventService.checkOutVisit,
    AjaxOptions,
    APIResponseClass<ClinicEventClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicEventService.checkOutVisit>) => (
      clinicEventService.checkOutVisit(...args)
    ),
    ResponseClassCreator: createClinicEventCheckOutVisitObject,
    typeName: 'useClinicEventCheckOutVisit',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicEventCheckOutVisit.mock = () => new Error('Mock has not been implemented!');
useClinicEventCheckOutVisit.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicEventCheckOutVisit.createObject = createClinicEventCheckOutVisitObject;

export const createClinicEventInternalSearchObject = (value: APIResponse<PageResponse<ClinicEvent>>) => new APIResponseClass<PageResponseClass<ClinicEventClass>>(value, (value: PageResponse<ClinicEvent>) => new PageResponseClass<ClinicEventClass>(value, (value: ClinicEvent) => new ClinicEventClass(value)));

export const useClinicEventInternalSearch = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicEventInternalSearchParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicEventInternalSearchParams,
    APIResponse<PageResponse<ClinicEvent>>,
    typeof clinicEventService.internalSearch,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ClinicEventClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicEventService.internalSearch>) => (
      clinicEventService.internalSearch(...args)
    ),
    ResponseClassCreator: createClinicEventInternalSearchObject,
    typeName: 'useClinicEventInternalSearch',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicEventInternalSearch.mock = () => new Error('Mock has not been implemented!');
useClinicEventInternalSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicEventInternalSearch.createObject = createClinicEventInternalSearchObject;

export const createClinicEventGetClinicEventListObject = (value: APIResponse<PageResponse<ClinicEvent>>) => new APIResponseClass<PageResponseClass<ClinicEventClass>>(value, (value: PageResponse<ClinicEvent>) => new PageResponseClass<ClinicEventClass>(value, (value: ClinicEvent) => new ClinicEventClass(value)));

export const useClinicEventGetClinicEventList = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicEventGetClinicEventListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicEventGetClinicEventListParams,
    APIResponse<PageResponse<ClinicEvent>>,
    typeof clinicEventService.getClinicEventList,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ClinicEventClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicEventService.getClinicEventList>) => (
      clinicEventService.getClinicEventList(...args)
    ),
    ResponseClassCreator: createClinicEventGetClinicEventListObject,
    typeName: 'useClinicEventGetClinicEventList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicEventGetClinicEventList.mock = () => new Error('Mock has not been implemented!');
useClinicEventGetClinicEventList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicEventGetClinicEventList.createObject = createClinicEventGetClinicEventListObject;

export const createClinicEventGetPtFutureVisitsObject = (value: APIResponse<ClinicEvent[]>) => new APIResponseClass<ClinicEventClass[]>(value, (v) => map(v, (value: ClinicEvent) => new ClinicEventClass(value)));

export const useClinicEventGetPtFutureVisits = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicEventGetPtFutureVisitsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicEventGetPtFutureVisitsParams,
    APIResponse<ClinicEvent[]>,
    typeof clinicEventService.getPtFutureVisits,
    GetOptions,
    APIResponseClass<ClinicEventClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof clinicEventService.getPtFutureVisits>) => (
      clinicEventService.getPtFutureVisits(...args)
    ),
    ResponseClassCreator: createClinicEventGetPtFutureVisitsObject,
    typeName: 'useClinicEventGetPtFutureVisits',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicEventGetPtFutureVisits.mock = () => new Error('Mock has not been implemented!');
useClinicEventGetPtFutureVisits.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicEventGetPtFutureVisits.createObject = createClinicEventGetPtFutureVisitsObject;

export const createClinicEventGetVisitWindowSuggestionObject = (value: APIResponse<ClinicEvent[]>) => new APIResponseClass<ClinicEventClass[]>(value, (v) => map(v, (value: ClinicEvent) => new ClinicEventClass(value)));

export const useClinicEventGetVisitWindowSuggestion = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicEventGetVisitWindowSuggestionParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicEventGetVisitWindowSuggestionParams,
    APIResponse<ClinicEvent[]>,
    typeof clinicEventService.getVisitWindowSuggestion,
    AjaxOptions,
    APIResponseClass<ClinicEventClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicEventService.getVisitWindowSuggestion>) => (
      clinicEventService.getVisitWindowSuggestion(...args)
    ),
    ResponseClassCreator: createClinicEventGetVisitWindowSuggestionObject,
    typeName: 'useClinicEventGetVisitWindowSuggestion',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicEventGetVisitWindowSuggestion.mock = () => new Error('Mock has not been implemented!');
useClinicEventGetVisitWindowSuggestion.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicEventGetVisitWindowSuggestion.createObject = createClinicEventGetVisitWindowSuggestionObject;

export const createClinicEventCheckOverlapCalendarEventObject = (value: APIResponse<OverlapEventResponse[]>) => new APIResponseClass<OverlapEventResponseClass[]>(value, (v) => map(v, (value: OverlapEventResponse) => new OverlapEventResponseClass(value)));

export const useClinicEventCheckOverlapCalendarEvent = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicEventCheckOverlapCalendarEventParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicEventCheckOverlapCalendarEventParams,
    APIResponse<OverlapEventResponse[]>,
    typeof clinicEventService.checkOverlapCalendarEvent,
    AjaxOptions,
    APIResponseClass<OverlapEventResponseClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicEventService.checkOverlapCalendarEvent>) => (
      clinicEventService.checkOverlapCalendarEvent(...args)
    ),
    ResponseClassCreator: createClinicEventCheckOverlapCalendarEventObject,
    typeName: 'useClinicEventCheckOverlapCalendarEvent',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicEventCheckOverlapCalendarEvent.mock = () => new Error('Mock has not been implemented!');
useClinicEventCheckOverlapCalendarEvent.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicEventCheckOverlapCalendarEvent.createObject = createClinicEventCheckOverlapCalendarEventObject;

export const createClinicEventGetClinicEventCountObject = (value: APIResponse<ClinicEventCount>) => new APIResponseClass<ClinicEventCountClass>(value, (value: ClinicEventCount) => new ClinicEventCountClass(value));

export const useClinicEventGetClinicEventCount = <RCN = undefined>(configs: MakeRequestHookConfig<ClinicEventGetClinicEventCountParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ClinicEventGetClinicEventCountParams,
    APIResponse<ClinicEventCount>,
    typeof clinicEventService.getClinicEventCount,
    AjaxOptions,
    APIResponseClass<ClinicEventCountClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicEventService.getClinicEventCount>) => (
      clinicEventService.getClinicEventCount(...args)
    ),
    ResponseClassCreator: createClinicEventGetClinicEventCountObject,
    typeName: 'useClinicEventGetClinicEventCount',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useClinicEventGetClinicEventCount.mock = () => new Error('Mock has not been implemented!');
useClinicEventGetClinicEventCount.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicEventGetClinicEventCount.createObject = createClinicEventGetClinicEventCountObject;


export const createClinicEventGetObject = (value: APIResponse<ClinicEvent>) => new APIResponseClass<ClinicEventClass>(value, (value: ClinicEvent) => new ClinicEventClass(value));
export const useClinicEventGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<ClinicEvent>,
    // @ts-ignore
    typeof clinicEventService.get,
    GetOptions,
    APIResponseClass<ClinicEventClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof clinicEventService.get>) => (
      clinicEventService.get(...args)
    ),
    ResponseClassCreator: createClinicEventGetObject,
    typeName: 'useClinicEventGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicEventGet.mock = () => new Error('Mock has not been implemented!');
useClinicEventGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicEventGet.createObject = createClinicEventGetObject;

export const createClinicEventUpdateObject = (value: APIResponse<ClinicEvent>) => new APIResponseClass<ClinicEventClass>(value, (value: ClinicEvent) => new ClinicEventClass(value));
export const useClinicEventUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, ClinicEvent>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, ClinicEvent>,
    APIResponse<ClinicEvent>,
    // @ts-ignore
    typeof clinicEventService.update,
    AjaxOptions,
    APIResponseClass<ClinicEventClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicEventService.update>) => (
      clinicEventService.update(...args)
    ),
    ResponseClassCreator: createClinicEventUpdateObject,
    typeName: 'useClinicEventUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicEventUpdate.mock = () => new Error('Mock has not been implemented!');
useClinicEventUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicEventUpdate.createObject = createClinicEventUpdateObject;

export const createClinicEventInsertObject = (value: APIResponse<ClinicEvent>) => new APIResponseClass<ClinicEventClass>(value, (value: ClinicEvent) => new ClinicEventClass(value));
export const useClinicEventInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<ClinicEvent>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<ClinicEvent>,
    APIResponse<ClinicEvent>,
    // @ts-ignore
    typeof clinicEventService.insert,
    AjaxOptions,
    APIResponseClass<ClinicEventClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicEventService.insert>) => (
      clinicEventService.insert(...args)
    ),
    ResponseClassCreator: createClinicEventInsertObject,
    typeName: 'useClinicEventInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicEventInsert.mock = () => new Error('Mock has not been implemented!');
useClinicEventInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicEventInsert.createObject = createClinicEventInsertObject;

export const createClinicEventSearchObject = (value: APIResponse<PageResponse<ClinicEvent>>) => new APIResponseClass<PageResponseClass<ClinicEventClass>>(value, (value: PageResponse<ClinicEvent>) => new PageResponseClass<ClinicEventClass>(value, (value: ClinicEvent) => new ClinicEventClass(value)));
export const useClinicEventSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<ClinicEvent>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<ClinicEvent>,
    APIResponse<PageResponse<ClinicEvent>>,
    // @ts-ignore
    typeof clinicEventService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ClinicEventClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof clinicEventService.search>) => (
      clinicEventService.search(...args)
    ),
    ResponseClassCreator: createClinicEventSearchObject,
    typeName: 'useClinicEventSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useClinicEventSearch.mock = () => new Error('Mock has not been implemented!');
useClinicEventSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useClinicEventSearch.createObject = createClinicEventSearchObject;

export interface Note {
	note?: Nullable<string>;
	providerId?: Nullable<string>;
	createdAt?: Nullable<string>;
}
export interface PatientBase extends BaseModel {
	updatedBy?: Nullable<string>;
	notes?: Nullable<Note[]>;
	hasNoteHistory?: Nullable<boolean>;
	historyEnabled?: Nullable<boolean>;
	updatedByUser?: Nullable<LimitedUser>;
	source?: Nullable<string>;
}
export enum ComplianceAlertTypeEnum {
	NEW_PATIENT_ENGAGEMENT_14_DAY = 'NEW_PATIENT_ENGAGEMENT_14_DAY',
	NEW_PATIENT_ENGAGEMENT_1_DAY = 'NEW_PATIENT_ENGAGEMENT_1_DAY',
	A1C_DUE = 'A1C_DUE',
	INACTIVE_7_DAY = 'INACTIVE_7_DAY',
	VISIT_DUE_POST_MD = 'VISIT_DUE_POST_MD',
	VISIT_DUE_TECH_ONBOARDING = 'VISIT_DUE_TECH_ONBOARDING',
	VISIT_DUE_FOLLOW_UP = 'VISIT_DUE_FOLLOW_UP',
	VISIT_DUE_INIT = 'VISIT_DUE_INIT',
	INACTIVE_14_DAY = 'INACTIVE_14_DAY',
	VISIT_OVERDUE = 'VISIT_OVERDUE',
	INACTIVE_2_WEEK_LOW_RISK = 'INACTIVE_2_WEEK_LOW_RISK',
	LEVEL_FIVE_HUB_USER_MISSING_CALL_LOG = 'LEVEL_FIVE_HUB_USER_MISSING_CALL_LOG',
	LEVEL_FIVE_NO_PROVIDERNOTE_IN_SIX_WEEKS = 'LEVEL_FIVE_NO_PROVIDERNOTE_IN_SIX_WEEKS',
	LEVEL_FOUR_A1C_OVERDUE = 'LEVEL_FOUR_A1C_OVERDUE',
	LEVEL_FOUR_NO_PROVIDERNOTE_IN_FOUR_WEEKS = 'LEVEL_FOUR_NO_PROVIDERNOTE_IN_FOUR_WEEKS',
	LEVEL_THREE_20_DAYS_LOW_ACTIVITY = 'LEVEL_THREE_20_DAYS_LOW_ACTIVITY',
	LEVEL_THREE_8_DAYS_LOW_ACTIVITY = 'LEVEL_THREE_8_DAYS_LOW_ACTIVITY',
	LEVEL_THREE_BIRTHDAY = 'LEVEL_THREE_BIRTHDAY',
	LEVEL_THREE_PATIENT_APP_ENGAGEMENT = 'LEVEL_THREE_PATIENT_APP_ENGAGEMENT',
	LEVEL_THREE_PATIENT_ENGAGEMENT = 'LEVEL_THREE_PATIENT_ENGAGEMENT',
	LEVEL_THREE_PATIENT_NO_APP_ENGAGEMENT = 'LEVEL_THREE_PATIENT_NO_APP_ENGAGEMENT',
	name = 'name',
}
export enum PriorityLevelEnum {
	LOW = 'LOW',
	MEDIUM = 'MEDIUM',
	HIGH = 'HIGH',
	level = 'level',
}
export enum SmartAlertTaskStatus {
	TODO = 'TODO',
	DONE = 'DONE',
	MUTE = 'MUTE',
	SILENCED = 'SILENCED',
}
export interface AlertReasonDetail {
	patientEnrolledDate?: Nullable<string>;
	lastMeasurementDate?: Nullable<string>;
	isAppUser?: Nullable<boolean>;
}
export enum SnoozeIntervalEnum {
	ONE_DAY = 'ONE_DAY',
	THREE_DAYS = 'THREE_DAYS',
	ONE_WEEK = 'ONE_WEEK',
	TWO_WEEKS = 'TWO_WEEKS',
	ONE_MONTH = 'ONE_MONTH',
	THREE_MONTHS = 'THREE_MONTHS',
	day = 'day',
	name = 'name',
}
export interface TimeWindow {
	startTime?: Nullable<string>;
	endTime?: Nullable<string>;
}
export interface ComplianceAlert extends PatientBase {
	memberId?: Nullable<string>;
	alertType?: Nullable<ComplianceAlertTypeEnum>;
	priority?: Nullable<PriorityLevelEnum>;
	dueDate?: Nullable<string>;
	status?: Nullable<SmartAlertTaskStatus>;
	reason?: Nullable<string>;
	reasonDetail?: Nullable<AlertReasonDetail>;
	snoozeInterval?: Nullable<SnoozeIntervalEnum>;
	snoozeReason?: Nullable<string>;
	closeAt?: Nullable<string>;
	snoozedDate?: Nullable<string>;
	a1cTestWindow?: Nullable<TimeWindow>;
	closedBy?: Nullable<string>;
	closedByUser?: Nullable<LimitedUser>;
	priorityIn?: Nullable<InFilter<PriorityLevelEnum>>;
	alertTypeIn?: Nullable<InFilter<ComplianceAlertTypeEnum>>;
	statusIn?: Nullable<InFilter<SmartAlertTaskStatus>>;
	memberIdIn?: Nullable<InFilter<string>>;
	closeAtRange?: Nullable<DateRangeFilter>;
}
export interface AlertConfig extends BaseModel {
	patientId?: Nullable<string>;
	alertType?: Nullable<ComplianceAlertTypeEnum>;
	startDate?: Nullable<string>;
	endDate?: Nullable<string>;
}
export class AlertConfigClass extends BaseModelClass {
  protected readonly _value: AlertConfig;

	constructor(value: AlertConfig) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientId() {
    return this._value.patientId;
  }

  get alertType() {
    return this._value.alertType;
  }

  alertTypeIsIn(values: ComplianceAlertTypeEnum[] = []) {
    return this._value.alertType && values.includes(this._value.alertType);
  }

  get startDate() {
    return (this._value.startDate !== undefined && this._value.startDate !== null) ? dayjs(this._value.startDate) : this._value.startDate;
  }

  get endDate() {
    return (this._value.endDate !== undefined && this._value.endDate !== null) ? dayjs(this._value.endDate) : this._value.endDate;
  }
}
export interface UpdateSnoozeRequest {
	alerts?: Nullable<ComplianceAlert[]>;
	isUnsnooze?: Nullable<boolean>;
	alertType?: Nullable<ComplianceAlertTypeEnum>;
	interval?: Nullable<SnoozeIntervalEnum>;
	snoozeReason?: Nullable<string>;
	snoozedDate?: Nullable<string>;
}
export class NoteClass {
  protected readonly _value: Note;

	constructor(value: Note) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get providerId() {
    return this._value.providerId;
  }

  get createdAt() {
    return (this._value.createdAt !== undefined && this._value.createdAt !== null) ? dayjs(this._value.createdAt) : this._value.createdAt;
  }
}
export class PatientBaseClass extends BaseModelClass {
  protected readonly _value: PatientBase;

	constructor(value: PatientBase) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get notes() {
    return this._value.notes !== undefined && this._value.notes !== null ? map(this._value.notes, (v) => new NoteClass(v)) : this._value.notes;
  }

  get notesWithDefault() {
    return this._value.notes || [];
  }

  get hasNoteHistory() {
    return this._value.hasNoteHistory;
  }

  get hasNoteHistoryWithDefault() {
    return this._value.hasNoteHistory || false;
  }

  get historyEnabled() {
    return this._value.historyEnabled;
  }

  get historyEnabledWithDefault() {
    return this._value.historyEnabled || false;
  }

  get updatedByUser() {
    return this._value.updatedByUser !== undefined && this._value.updatedByUser !== null ? new LimitedUserClass(this._value.updatedByUser) : this._value.updatedByUser;
  }

  get source() {
    return this._value.source;
  }

  get sourceWithDefault() {
    return this._value.source || '';
  }
}
export class AlertReasonDetailClass {
  protected readonly _value: AlertReasonDetail;

	constructor(value: AlertReasonDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientEnrolledDate() {
    return (this._value.patientEnrolledDate !== undefined && this._value.patientEnrolledDate !== null) ? dayjs(this._value.patientEnrolledDate) : this._value.patientEnrolledDate;
  }

  get lastMeasurementDate() {
    return (this._value.lastMeasurementDate !== undefined && this._value.lastMeasurementDate !== null) ? dayjs(this._value.lastMeasurementDate) : this._value.lastMeasurementDate;
  }

  get isAppUser() {
    return this._value.isAppUser;
  }

  get isAppUserWithDefault() {
    return this._value.isAppUser || false;
  }
}
export class TimeWindowClass {
  protected readonly _value: TimeWindow;

	constructor(value: TimeWindow) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get startTime() {
    return (this._value.startTime !== undefined && this._value.startTime !== null) ? dayjs(this._value.startTime) : this._value.startTime;
  }

  get endTime() {
    return (this._value.endTime !== undefined && this._value.endTime !== null) ? dayjs(this._value.endTime) : this._value.endTime;
  }
}
export class ComplianceAlertClass extends PatientBaseClass {
  protected readonly _value: ComplianceAlert;

	constructor(value: ComplianceAlert) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get alertType() {
    return this._value.alertType;
  }

  alertTypeIsIn(values: ComplianceAlertTypeEnum[] = []) {
    return this._value.alertType && values.includes(this._value.alertType);
  }

  get priority() {
    return this._value.priority;
  }

  priorityIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.priority && values.includes(this._value.priority);
  }

  get dueDate() {
    return (this._value.dueDate !== undefined && this._value.dueDate !== null) ? dayjs(this._value.dueDate) : this._value.dueDate;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: SmartAlertTaskStatus[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get reason() {
    return this._value.reason;
  }

  get reasonWithDefault() {
    return this._value.reason || '';
  }

  get reasonDetail() {
    return this._value.reasonDetail !== undefined && this._value.reasonDetail !== null ? new AlertReasonDetailClass(this._value.reasonDetail) : this._value.reasonDetail;
  }

  get snoozeInterval() {
    return this._value.snoozeInterval;
  }

  snoozeIntervalIsIn(values: SnoozeIntervalEnum[] = []) {
    return this._value.snoozeInterval && values.includes(this._value.snoozeInterval);
  }

  get snoozeReason() {
    return this._value.snoozeReason;
  }

  get snoozeReasonWithDefault() {
    return this._value.snoozeReason || '';
  }

  get closeAt() {
    return (this._value.closeAt !== undefined && this._value.closeAt !== null) ? dayjs(this._value.closeAt) : this._value.closeAt;
  }

  get snoozedDate() {
    return (this._value.snoozedDate !== undefined && this._value.snoozedDate !== null) ? dayjs(this._value.snoozedDate) : this._value.snoozedDate;
  }

  get a1cTestWindow() {
    return this._value.a1cTestWindow !== undefined && this._value.a1cTestWindow !== null ? new TimeWindowClass(this._value.a1cTestWindow) : this._value.a1cTestWindow;
  }

  get closedBy() {
    return this._value.closedBy;
  }

  get closedByUser() {
    return this._value.closedByUser !== undefined && this._value.closedByUser !== null ? new LimitedUserClass(this._value.closedByUser) : this._value.closedByUser;
  }

  get priorityIn() {
    return this._value.priorityIn !== undefined && this._value.priorityIn !== null ? new InFilterClass(this._value.priorityIn) : this._value.priorityIn;
  }

  get alertTypeIn() {
    return this._value.alertTypeIn !== undefined && this._value.alertTypeIn !== null ? new InFilterClass(this._value.alertTypeIn) : this._value.alertTypeIn;
  }

  get statusIn() {
    return this._value.statusIn !== undefined && this._value.statusIn !== null ? new InFilterClass(this._value.statusIn) : this._value.statusIn;
  }

  get memberIdIn() {
    return this._value.memberIdIn !== undefined && this._value.memberIdIn !== null ? new InFilterClass(this._value.memberIdIn) : this._value.memberIdIn;
  }

  get closeAtRange() {
    return this._value.closeAtRange !== undefined && this._value.closeAtRange !== null ? new DateRangeFilterClass(this._value.closeAtRange) : this._value.closeAtRange;
  }
}
export interface ResolveAlertRequest {
	note?: Nullable<string>;
	alertIds?: Nullable<string[]>;
	noteOnly?: Nullable<boolean>;
}
export interface ComplianceAlertGetSnoozedAlertListParams {
	memberId: string;
}

export interface ComplianceAlertUpdateSnoozeConfigParams {
	memberId: string;
	request: UpdateSnoozeRequest;
}

export interface ComplianceAlertGetComplianceAlertByMemberIdParams {
	memberId: string;
}

export interface ComplianceAlertCloseSmartAlertByIdParams {
	resolveAlertRequest: ResolveAlertRequest;
}

export class ComplianceAlertService extends BaseController<ComplianceAlert, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/compliance-alert';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getSnoozedAlertList({ baseUrl, params, headers }: RequestOption<ComplianceAlertGetSnoozedAlertListParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<AlertConfig[]>>(`${baseUrl || this.baseURL}${this.basePath}/snooze-list/${params.memberId}${getQueryStrings(queryList)}`, { headers });
	}

	public updateSnoozeConfig({ baseUrl, params, headers }: RequestOption<ComplianceAlertUpdateSnoozeConfigParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/snooze/${params.memberId}${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getComplianceAlertByMemberId({ baseUrl, params, headers }: RequestOption<ComplianceAlertGetComplianceAlertByMemberIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<ComplianceAlert[]>>(`${baseUrl || this.baseURL}${this.basePath}/patient-list/${params.memberId}${getQueryStrings(queryList)}`, { headers });
	}

	public closeSmartAlertById({ baseUrl, params, headers }: RequestOption<ComplianceAlertCloseSmartAlertByIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<string[]>>(`${baseUrl || this.baseURL}${this.basePath}/close${getQueryStrings(queryList)}`, params.resolveAlertRequest, { headers });
	}
}

export const complianceAlertService = new ComplianceAlertService();

export const createComplianceAlertGetSnoozedAlertListObject = (value: APIResponse<AlertConfig[]>) => new APIResponseClass<AlertConfigClass[]>(value, (v) => map(v, (value: AlertConfig) => new AlertConfigClass(value)));

export const useComplianceAlertGetSnoozedAlertList = <RCN = undefined>(configs: MakeRequestHookConfig<ComplianceAlertGetSnoozedAlertListParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ComplianceAlertGetSnoozedAlertListParams,
    APIResponse<AlertConfig[]>,
    typeof complianceAlertService.getSnoozedAlertList,
    GetOptions,
    APIResponseClass<AlertConfigClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof complianceAlertService.getSnoozedAlertList>) => (
      complianceAlertService.getSnoozedAlertList(...args)
    ),
    ResponseClassCreator: createComplianceAlertGetSnoozedAlertListObject,
    typeName: 'useComplianceAlertGetSnoozedAlertList',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useComplianceAlertGetSnoozedAlertList.mock = () => new Error('Mock has not been implemented!');
useComplianceAlertGetSnoozedAlertList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useComplianceAlertGetSnoozedAlertList.createObject = createComplianceAlertGetSnoozedAlertListObject;

export const createComplianceAlertUpdateSnoozeConfigObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useComplianceAlertUpdateSnoozeConfig = <RCN = undefined>(configs: MakeRequestHookConfig<ComplianceAlertUpdateSnoozeConfigParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ComplianceAlertUpdateSnoozeConfigParams,
    APIResponse<string>,
    typeof complianceAlertService.updateSnoozeConfig,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof complianceAlertService.updateSnoozeConfig>) => (
      complianceAlertService.updateSnoozeConfig(...args)
    ),
    ResponseClassCreator: createComplianceAlertUpdateSnoozeConfigObject,
    typeName: 'useComplianceAlertUpdateSnoozeConfig',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useComplianceAlertUpdateSnoozeConfig.mock = () => new Error('Mock has not been implemented!');
useComplianceAlertUpdateSnoozeConfig.getLastMockedValue = () => new Error('Mock has not been implemented!');
useComplianceAlertUpdateSnoozeConfig.createObject = createComplianceAlertUpdateSnoozeConfigObject;

export const createComplianceAlertGetComplianceAlertByMemberIdObject = (value: APIResponse<ComplianceAlert[]>) => new APIResponseClass<ComplianceAlertClass[]>(value, (v) => map(v, (value: ComplianceAlert) => new ComplianceAlertClass(value)));

export const useComplianceAlertGetComplianceAlertByMemberId = <RCN = undefined>(configs: MakeRequestHookConfig<ComplianceAlertGetComplianceAlertByMemberIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ComplianceAlertGetComplianceAlertByMemberIdParams,
    APIResponse<ComplianceAlert[]>,
    typeof complianceAlertService.getComplianceAlertByMemberId,
    GetOptions,
    APIResponseClass<ComplianceAlertClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof complianceAlertService.getComplianceAlertByMemberId>) => (
      complianceAlertService.getComplianceAlertByMemberId(...args)
    ),
    ResponseClassCreator: createComplianceAlertGetComplianceAlertByMemberIdObject,
    typeName: 'useComplianceAlertGetComplianceAlertByMemberId',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useComplianceAlertGetComplianceAlertByMemberId.mock = () => new Error('Mock has not been implemented!');
useComplianceAlertGetComplianceAlertByMemberId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useComplianceAlertGetComplianceAlertByMemberId.createObject = createComplianceAlertGetComplianceAlertByMemberIdObject;

export const createComplianceAlertCloseSmartAlertByIdObject = (value: APIResponse<string[]>) => new APIResponseClass<string[]>(value, (v) => (v));

export const useComplianceAlertCloseSmartAlertById = <RCN = undefined>(configs: MakeRequestHookConfig<ComplianceAlertCloseSmartAlertByIdParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ComplianceAlertCloseSmartAlertByIdParams,
    APIResponse<string[]>,
    typeof complianceAlertService.closeSmartAlertById,
    AjaxOptions,
    APIResponseClass<string[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof complianceAlertService.closeSmartAlertById>) => (
      complianceAlertService.closeSmartAlertById(...args)
    ),
    ResponseClassCreator: createComplianceAlertCloseSmartAlertByIdObject,
    typeName: 'useComplianceAlertCloseSmartAlertById',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useComplianceAlertCloseSmartAlertById.mock = () => new Error('Mock has not been implemented!');
useComplianceAlertCloseSmartAlertById.getLastMockedValue = () => new Error('Mock has not been implemented!');
useComplianceAlertCloseSmartAlertById.createObject = createComplianceAlertCloseSmartAlertByIdObject;


export const createComplianceAlertGetObject = (value: APIResponse<ComplianceAlert>) => new APIResponseClass<ComplianceAlertClass>(value, (value: ComplianceAlert) => new ComplianceAlertClass(value));
export const useComplianceAlertGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<ComplianceAlert>,
    // @ts-ignore
    typeof complianceAlertService.get,
    GetOptions,
    APIResponseClass<ComplianceAlertClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof complianceAlertService.get>) => (
      complianceAlertService.get(...args)
    ),
    ResponseClassCreator: createComplianceAlertGetObject,
    typeName: 'useComplianceAlertGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useComplianceAlertGet.mock = () => new Error('Mock has not been implemented!');
useComplianceAlertGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useComplianceAlertGet.createObject = createComplianceAlertGetObject;

export const createComplianceAlertUpdateObject = (value: APIResponse<ComplianceAlert>) => new APIResponseClass<ComplianceAlertClass>(value, (value: ComplianceAlert) => new ComplianceAlertClass(value));
export const useComplianceAlertUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, ComplianceAlert>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, ComplianceAlert>,
    APIResponse<ComplianceAlert>,
    // @ts-ignore
    typeof complianceAlertService.update,
    AjaxOptions,
    APIResponseClass<ComplianceAlertClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof complianceAlertService.update>) => (
      complianceAlertService.update(...args)
    ),
    ResponseClassCreator: createComplianceAlertUpdateObject,
    typeName: 'useComplianceAlertUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useComplianceAlertUpdate.mock = () => new Error('Mock has not been implemented!');
useComplianceAlertUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useComplianceAlertUpdate.createObject = createComplianceAlertUpdateObject;

export const createComplianceAlertInsertObject = (value: APIResponse<ComplianceAlert>) => new APIResponseClass<ComplianceAlertClass>(value, (value: ComplianceAlert) => new ComplianceAlertClass(value));
export const useComplianceAlertInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<ComplianceAlert>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<ComplianceAlert>,
    APIResponse<ComplianceAlert>,
    // @ts-ignore
    typeof complianceAlertService.insert,
    AjaxOptions,
    APIResponseClass<ComplianceAlertClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof complianceAlertService.insert>) => (
      complianceAlertService.insert(...args)
    ),
    ResponseClassCreator: createComplianceAlertInsertObject,
    typeName: 'useComplianceAlertInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useComplianceAlertInsert.mock = () => new Error('Mock has not been implemented!');
useComplianceAlertInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useComplianceAlertInsert.createObject = createComplianceAlertInsertObject;

export const createComplianceAlertDeleteObject = (value: APIResponse<ComplianceAlert>) => new APIResponseClass<ComplianceAlertClass>(value, (value: ComplianceAlert) => new ComplianceAlertClass(value));
export const useComplianceAlertDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<ComplianceAlert>,
    // @ts-ignore
    typeof complianceAlertService.delete,
    AjaxOptions,
    APIResponseClass<ComplianceAlertClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof complianceAlertService.delete>) => (
      complianceAlertService.delete(...args)
    ),
    ResponseClassCreator: createComplianceAlertDeleteObject,
    typeName: 'useComplianceAlertDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useComplianceAlertDelete.mock = () => new Error('Mock has not been implemented!');
useComplianceAlertDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useComplianceAlertDelete.createObject = createComplianceAlertDeleteObject;

export const createComplianceAlertSearchObject = (value: APIResponse<PageResponse<ComplianceAlert>>) => new APIResponseClass<PageResponseClass<ComplianceAlertClass>>(value, (value: PageResponse<ComplianceAlert>) => new PageResponseClass<ComplianceAlertClass>(value, (value: ComplianceAlert) => new ComplianceAlertClass(value)));
export const useComplianceAlertSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<ComplianceAlert>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<ComplianceAlert>,
    APIResponse<PageResponse<ComplianceAlert>>,
    // @ts-ignore
    typeof complianceAlertService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ComplianceAlertClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof complianceAlertService.search>) => (
      complianceAlertService.search(...args)
    ),
    ResponseClassCreator: createComplianceAlertSearchObject,
    typeName: 'useComplianceAlertSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useComplianceAlertSearch.mock = () => new Error('Mock has not been implemented!');
useComplianceAlertSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useComplianceAlertSearch.createObject = createComplianceAlertSearchObject;

export interface ConsentTemplate extends BaseModel {
	title?: Nullable<string>;
	language?: Nullable<string>;
	groupId?: Nullable<string>;
	content?: Nullable<string>;
	template?: Nullable<string>;
	appTemplate?: Nullable<string>;
	templateWithTerm?: Nullable<string>;
	version?: Nullable<string>;
	note?: Nullable<string>;
	effectiveDate?: Nullable<string>;
}
export class ConsentTemplateClass extends BaseModelClass {
  protected readonly _value: ConsentTemplate;

	constructor(value: ConsentTemplate) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get title() {
    return this._value.title;
  }

  get titleWithDefault() {
    return this._value.title || '';
  }

  get language() {
    return this._value.language;
  }

  get languageWithDefault() {
    return this._value.language || '';
  }

  get groupId() {
    return this._value.groupId;
  }

  get content() {
    return this._value.content;
  }

  get contentWithDefault() {
    return this._value.content || '';
  }

  get template() {
    return this._value.template;
  }

  get templateWithDefault() {
    return this._value.template || '';
  }

  get appTemplate() {
    return this._value.appTemplate;
  }

  get appTemplateWithDefault() {
    return this._value.appTemplate || '';
  }

  get templateWithTerm() {
    return this._value.templateWithTerm;
  }

  get templateWithTermWithDefault() {
    return this._value.templateWithTerm || '';
  }

  get version() {
    return this._value.version;
  }

  get versionWithDefault() {
    return this._value.version || '';
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get effectiveDate() {
    return (this._value.effectiveDate !== undefined && this._value.effectiveDate !== null) ? dayjs(this._value.effectiveDate) : this._value.effectiveDate;
  }
}
export class ConsentTemplateService extends BaseController<ConsentTemplate, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/consent_template';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const consentTemplateService = new ConsentTemplateService();


export const createConsentTemplateGetObject = (value: APIResponse<ConsentTemplate>) => new APIResponseClass<ConsentTemplateClass>(value, (value: ConsentTemplate) => new ConsentTemplateClass(value));
export const useConsentTemplateGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<ConsentTemplate>,
    // @ts-ignore
    typeof consentTemplateService.get,
    GetOptions,
    APIResponseClass<ConsentTemplateClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof consentTemplateService.get>) => (
      consentTemplateService.get(...args)
    ),
    ResponseClassCreator: createConsentTemplateGetObject,
    typeName: 'useConsentTemplateGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useConsentTemplateGet.mock = () => new Error('Mock has not been implemented!');
useConsentTemplateGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useConsentTemplateGet.createObject = createConsentTemplateGetObject;

export const createConsentTemplateUpdateObject = (value: APIResponse<ConsentTemplate>) => new APIResponseClass<ConsentTemplateClass>(value, (value: ConsentTemplate) => new ConsentTemplateClass(value));
export const useConsentTemplateUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, ConsentTemplate>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, ConsentTemplate>,
    APIResponse<ConsentTemplate>,
    // @ts-ignore
    typeof consentTemplateService.update,
    AjaxOptions,
    APIResponseClass<ConsentTemplateClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof consentTemplateService.update>) => (
      consentTemplateService.update(...args)
    ),
    ResponseClassCreator: createConsentTemplateUpdateObject,
    typeName: 'useConsentTemplateUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useConsentTemplateUpdate.mock = () => new Error('Mock has not been implemented!');
useConsentTemplateUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useConsentTemplateUpdate.createObject = createConsentTemplateUpdateObject;

export const createConsentTemplateInsertObject = (value: APIResponse<ConsentTemplate>) => new APIResponseClass<ConsentTemplateClass>(value, (value: ConsentTemplate) => new ConsentTemplateClass(value));
export const useConsentTemplateInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<ConsentTemplate>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<ConsentTemplate>,
    APIResponse<ConsentTemplate>,
    // @ts-ignore
    typeof consentTemplateService.insert,
    AjaxOptions,
    APIResponseClass<ConsentTemplateClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof consentTemplateService.insert>) => (
      consentTemplateService.insert(...args)
    ),
    ResponseClassCreator: createConsentTemplateInsertObject,
    typeName: 'useConsentTemplateInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useConsentTemplateInsert.mock = () => new Error('Mock has not been implemented!');
useConsentTemplateInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useConsentTemplateInsert.createObject = createConsentTemplateInsertObject;

export const createConsentTemplateDeleteObject = (value: APIResponse<ConsentTemplate>) => new APIResponseClass<ConsentTemplateClass>(value, (value: ConsentTemplate) => new ConsentTemplateClass(value));
export const useConsentTemplateDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<ConsentTemplate>,
    // @ts-ignore
    typeof consentTemplateService.delete,
    AjaxOptions,
    APIResponseClass<ConsentTemplateClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof consentTemplateService.delete>) => (
      consentTemplateService.delete(...args)
    ),
    ResponseClassCreator: createConsentTemplateDeleteObject,
    typeName: 'useConsentTemplateDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useConsentTemplateDelete.mock = () => new Error('Mock has not been implemented!');
useConsentTemplateDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useConsentTemplateDelete.createObject = createConsentTemplateDeleteObject;

export const createConsentTemplateSearchObject = (value: APIResponse<PageResponse<ConsentTemplate>>) => new APIResponseClass<PageResponseClass<ConsentTemplateClass>>(value, (value: PageResponse<ConsentTemplate>) => new PageResponseClass<ConsentTemplateClass>(value, (value: ConsentTemplate) => new ConsentTemplateClass(value)));
export const useConsentTemplateSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<ConsentTemplate>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<ConsentTemplate>,
    APIResponse<PageResponse<ConsentTemplate>>,
    // @ts-ignore
    typeof consentTemplateService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ConsentTemplateClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof consentTemplateService.search>) => (
      consentTemplateService.search(...args)
    ),
    ResponseClassCreator: createConsentTemplateSearchObject,
    typeName: 'useConsentTemplateSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useConsentTemplateSearch.mock = () => new Error('Mock has not been implemented!');
useConsentTemplateSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useConsentTemplateSearch.createObject = createConsentTemplateSearchObject;

export enum EhrJobTypeEnum {
	CREATE = 'CREATE',
	UPDATE = 'UPDATE',
}
export enum JobStatusEnum {
	RECEIVED = 'RECEIVED',
	PROCESSING = 'PROCESSING',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
	NOT_APPLICABLE = 'NOT_APPLICABLE',
}
export interface EhrJobRecord extends BaseModel {
	jobId?: Nullable<string>;
	index?: Nullable<number>;
	jobType?: Nullable<EhrJobTypeEnum>;
	ucJobStatus?: Nullable<JobStatusEnum>;
	sharecareJobStatus?: Nullable<JobStatusEnum>;
	ucError?: Nullable<string>;
	sharecareError?: Nullable<string>;
	memberId?: Nullable<string>;
	ehr?: Nullable<string>;
	ehrOrgId?: Nullable<string>;
	ehrDepartmentId?: Nullable<string>;
	ehrUserId?: Nullable<string>;
}
export class EhrJobRecordClass extends BaseModelClass {
  protected readonly _value: EhrJobRecord;

	constructor(value: EhrJobRecord) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get jobId() {
    return this._value.jobId;
  }

  get jobIdWithDefault() {
    return this._value.jobId || '';
  }

  get index() {
    return this._value.index;
  }

  get indexWithDefault() {
    return this._value.index || 0;
  }

  get jobType() {
    return this._value.jobType;
  }

  jobTypeIsIn(values: EhrJobTypeEnum[] = []) {
    return this._value.jobType && values.includes(this._value.jobType);
  }

  get ucJobStatus() {
    return this._value.ucJobStatus;
  }

  ucJobStatusIsIn(values: JobStatusEnum[] = []) {
    return this._value.ucJobStatus && values.includes(this._value.ucJobStatus);
  }

  get sharecareJobStatus() {
    return this._value.sharecareJobStatus;
  }

  sharecareJobStatusIsIn(values: JobStatusEnum[] = []) {
    return this._value.sharecareJobStatus && values.includes(this._value.sharecareJobStatus);
  }

  get ucError() {
    return this._value.ucError;
  }

  get ucErrorWithDefault() {
    return this._value.ucError || '';
  }

  get sharecareError() {
    return this._value.sharecareError;
  }

  get sharecareErrorWithDefault() {
    return this._value.sharecareError || '';
  }

  get memberId() {
    return this._value.memberId;
  }

  get memberIdWithDefault() {
    return this._value.memberId || '';
  }

  get ehr() {
    return this._value.ehr;
  }

  get ehrWithDefault() {
    return this._value.ehr || '';
  }

  get ehrOrgId() {
    return this._value.ehrOrgId;
  }

  get ehrOrgIdWithDefault() {
    return this._value.ehrOrgId || '';
  }

  get ehrDepartmentId() {
    return this._value.ehrDepartmentId;
  }

  get ehrDepartmentIdWithDefault() {
    return this._value.ehrDepartmentId || '';
  }

  get ehrUserId() {
    return this._value.ehrUserId;
  }

  get ehrUserIdWithDefault() {
    return this._value.ehrUserId || '';
  }
}
export interface EhrUserInfo {
	ehr?: Nullable<string>;
	ehrUserId?: Nullable<string>;
	ehrUserSecondaryId?: Nullable<string>;
	ehrDepartmentId?: Nullable<string>;
	ehrOrgId?: Nullable<string>;
	ucId?: Nullable<string>;
	ucRoleId?: Nullable<string>;
	ucOrgId?: Nullable<string>;
}
export interface EhrJobGetMessageParams {
	jobId: string;
	index: string;
}

export interface EhrJobGetMessagesParams {
	jobId: string;
}

export interface EhrJobGetMessagesByEhrInfoParams {
	info: EhrUserInfo;
}

export class EhrJobService {
  protected baseURL = '';

  protected basePath = '/ehr/job';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public getMessage({ baseUrl, params, headers }: RequestOption<EhrJobGetMessageParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<EhrJobRecord>>(`${baseUrl || this.baseURL}${this.basePath}/${params.jobId}/${params.index}${getQueryStrings(queryList)}`, { headers });
	}

	public getMessages({ baseUrl, params, headers }: RequestOption<EhrJobGetMessagesParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<EhrJobRecord[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.jobId}${getQueryStrings(queryList)}`, { headers });
	}

	public getMessagesByEhrInfo({ baseUrl, params, headers }: RequestOption<EhrJobGetMessagesByEhrInfoParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<EhrJobRecord[]>>(`${baseUrl || this.baseURL}${this.basePath}/search${getQueryStrings(queryList)}`, params.info, { headers });
	}
}

export const ehrJobService = new EhrJobService();

export const createEhrJobGetMessageObject = (value: APIResponse<EhrJobRecord>) => new APIResponseClass<EhrJobRecordClass>(value, (value: EhrJobRecord) => new EhrJobRecordClass(value));

export const useEhrJobGetMessage = <RCN = undefined>(configs: MakeRequestHookConfig<EhrJobGetMessageParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EhrJobGetMessageParams,
    APIResponse<EhrJobRecord>,
    typeof ehrJobService.getMessage,
    GetOptions,
    APIResponseClass<EhrJobRecordClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ehrJobService.getMessage>) => (
      ehrJobService.getMessage(...args)
    ),
    ResponseClassCreator: createEhrJobGetMessageObject,
    typeName: 'useEhrJobGetMessage',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEhrJobGetMessage.mock = () => new Error('Mock has not been implemented!');
useEhrJobGetMessage.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEhrJobGetMessage.createObject = createEhrJobGetMessageObject;

export const createEhrJobGetMessagesObject = (value: APIResponse<EhrJobRecord[]>) => new APIResponseClass<EhrJobRecordClass[]>(value, (v) => map(v, (value: EhrJobRecord) => new EhrJobRecordClass(value)));

export const useEhrJobGetMessages = <RCN = undefined>(configs: MakeRequestHookConfig<EhrJobGetMessagesParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EhrJobGetMessagesParams,
    APIResponse<EhrJobRecord[]>,
    typeof ehrJobService.getMessages,
    GetOptions,
    APIResponseClass<EhrJobRecordClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ehrJobService.getMessages>) => (
      ehrJobService.getMessages(...args)
    ),
    ResponseClassCreator: createEhrJobGetMessagesObject,
    typeName: 'useEhrJobGetMessages',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEhrJobGetMessages.mock = () => new Error('Mock has not been implemented!');
useEhrJobGetMessages.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEhrJobGetMessages.createObject = createEhrJobGetMessagesObject;

export const createEhrJobGetMessagesByEhrInfoObject = (value: APIResponse<EhrJobRecord[]>) => new APIResponseClass<EhrJobRecordClass[]>(value, (v) => map(v, (value: EhrJobRecord) => new EhrJobRecordClass(value)));

export const useEhrJobGetMessagesByEhrInfo = <RCN = undefined>(configs: MakeRequestHookConfig<EhrJobGetMessagesByEhrInfoParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EhrJobGetMessagesByEhrInfoParams,
    APIResponse<EhrJobRecord[]>,
    typeof ehrJobService.getMessagesByEhrInfo,
    AjaxOptions,
    APIResponseClass<EhrJobRecordClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ehrJobService.getMessagesByEhrInfo>) => (
      ehrJobService.getMessagesByEhrInfo(...args)
    ),
    ResponseClassCreator: createEhrJobGetMessagesByEhrInfoObject,
    typeName: 'useEhrJobGetMessagesByEhrInfo',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEhrJobGetMessagesByEhrInfo.mock = () => new Error('Mock has not been implemented!');
useEhrJobGetMessagesByEhrInfo.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEhrJobGetMessagesByEhrInfo.createObject = createEhrJobGetMessagesByEhrInfoObject;
export interface EhrOrganizationSearchOrganizationsFromEhrParams {
	filter: SearchRequest<EhrClinic>;
}

export interface EhrOrganizationSearchDepartmentsParams {
	ehr: string;
	ehrOrganizationId: string;
}

export class EhrOrganizationService {
  protected baseURL = '';

  protected basePath = '/v1/uc/ehr/organizations';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public searchOrganizationsFromEhr({ baseUrl, params, headers }: RequestOption<EhrOrganizationSearchOrganizationsFromEhrParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<EhrClinic>>>(`${baseUrl || this.baseURL}${this.basePath}/search${getQueryStrings(queryList)}`, params.filter, { headers });
	}

	public searchDepartments({ baseUrl, params, headers }: RequestOption<EhrOrganizationSearchDepartmentsParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<EhrClinic>>(`${baseUrl || this.baseURL}${this.basePath}/${params.ehr}/${params.ehrOrganizationId}/departments${getQueryStrings(queryList)}`, { headers });
	}
}

export const ehrOrganizationService = new EhrOrganizationService();

export const createEhrOrganizationSearchOrganizationsFromEhrObject = (value: APIResponse<PageResponse<EhrClinic>>) => new APIResponseClass<PageResponseClass<EhrClinicClass>>(value, (value: PageResponse<EhrClinic>) => new PageResponseClass<EhrClinicClass>(value, (value: EhrClinic) => new EhrClinicClass(value)));

export const useEhrOrganizationSearchOrganizationsFromEhr = <RCN = undefined>(configs: MakeRequestHookConfig<EhrOrganizationSearchOrganizationsFromEhrParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EhrOrganizationSearchOrganizationsFromEhrParams,
    APIResponse<PageResponse<EhrClinic>>,
    typeof ehrOrganizationService.searchOrganizationsFromEhr,
    AjaxOptions,
    APIResponseClass<PageResponseClass<EhrClinicClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ehrOrganizationService.searchOrganizationsFromEhr>) => (
      ehrOrganizationService.searchOrganizationsFromEhr(...args)
    ),
    ResponseClassCreator: createEhrOrganizationSearchOrganizationsFromEhrObject,
    typeName: 'useEhrOrganizationSearchOrganizationsFromEhr',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEhrOrganizationSearchOrganizationsFromEhr.mock = () => new Error('Mock has not been implemented!');
useEhrOrganizationSearchOrganizationsFromEhr.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEhrOrganizationSearchOrganizationsFromEhr.createObject = createEhrOrganizationSearchOrganizationsFromEhrObject;

export const createEhrOrganizationSearchDepartmentsObject = (value: APIResponse<EhrClinic>) => new APIResponseClass<EhrClinicClass>(value, (value: EhrClinic) => new EhrClinicClass(value));

export const useEhrOrganizationSearchDepartments = <RCN = undefined>(configs: MakeRequestHookConfig<EhrOrganizationSearchDepartmentsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EhrOrganizationSearchDepartmentsParams,
    APIResponse<EhrClinic>,
    typeof ehrOrganizationService.searchDepartments,
    GetOptions,
    APIResponseClass<EhrClinicClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ehrOrganizationService.searchDepartments>) => (
      ehrOrganizationService.searchDepartments(...args)
    ),
    ResponseClassCreator: createEhrOrganizationSearchDepartmentsObject,
    typeName: 'useEhrOrganizationSearchDepartments',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEhrOrganizationSearchDepartments.mock = () => new Error('Mock has not been implemented!');
useEhrOrganizationSearchDepartments.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEhrOrganizationSearchDepartments.createObject = createEhrOrganizationSearchDepartmentsObject;
export interface EhrOrgMappingRequest {
	ehr?: Nullable<string>;
	ehrOrgId?: Nullable<string>;
	ehrDepartmentIds?: Nullable<string[]>;
}
export interface EhrOrgDetail {
	id?: Nullable<number>;
	ehr?: Nullable<string>;
	ehrOrgId?: Nullable<string>;
	name?: Nullable<string>;
	address?: Nullable<string>;
	city?: Nullable<string>;
	state?: Nullable<string>;
	zipcode?: Nullable<string>;
	ehrParentOrgId?: Nullable<string>;
}
export interface EhrOrgMappingWithDetail {
	id?: Nullable<number>;
	ehr?: Nullable<string>;
	ehrOrgId?: Nullable<string>;
	ehrOrgInformation?: Nullable<EhrOrgDetail>;
	ehrDepartmentId?: Nullable<string>;
	ehrDepartmentInformation?: Nullable<EhrOrgDetail>;
	ucOrgId?: Nullable<string>;
	isDefault?: Nullable<boolean>;
}
export class EhrOrgDetailClass {
  protected readonly _value: EhrOrgDetail;

	constructor(value: EhrOrgDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || 0;
  }

  get ehr() {
    return this._value.ehr;
  }

  get ehrWithDefault() {
    return this._value.ehr || '';
  }

  get ehrOrgId() {
    return this._value.ehrOrgId;
  }

  get ehrOrgIdWithDefault() {
    return this._value.ehrOrgId || '';
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get address() {
    return this._value.address;
  }

  get addressWithDefault() {
    return this._value.address || '';
  }

  get city() {
    return this._value.city;
  }

  get cityWithDefault() {
    return this._value.city || '';
  }

  get state() {
    return this._value.state;
  }

  get stateWithDefault() {
    return this._value.state || '';
  }

  get zipcode() {
    return this._value.zipcode;
  }

  get zipcodeWithDefault() {
    return this._value.zipcode || '';
  }

  get ehrParentOrgId() {
    return this._value.ehrParentOrgId;
  }

  get ehrParentOrgIdWithDefault() {
    return this._value.ehrParentOrgId || '';
  }
}
export class EhrOrgMappingWithDetailClass {
  protected readonly _value: EhrOrgMappingWithDetail;

	constructor(value: EhrOrgMappingWithDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || 0;
  }

  get ehr() {
    return this._value.ehr;
  }

  get ehrWithDefault() {
    return this._value.ehr || '';
  }

  get ehrOrgId() {
    return this._value.ehrOrgId;
  }

  get ehrOrgIdWithDefault() {
    return this._value.ehrOrgId || '';
  }

  get ehrOrgInformation() {
    return this._value.ehrOrgInformation !== undefined && this._value.ehrOrgInformation !== null ? new EhrOrgDetailClass(this._value.ehrOrgInformation) : this._value.ehrOrgInformation;
  }

  get ehrDepartmentId() {
    return this._value.ehrDepartmentId;
  }

  get ehrDepartmentIdWithDefault() {
    return this._value.ehrDepartmentId || '';
  }

  get ehrDepartmentInformation() {
    return this._value.ehrDepartmentInformation !== undefined && this._value.ehrDepartmentInformation !== null ? new EhrOrgDetailClass(this._value.ehrDepartmentInformation) : this._value.ehrDepartmentInformation;
  }

  get ucOrgId() {
    return this._value.ucOrgId;
  }

  get ucOrgIdWithDefault() {
    return this._value.ucOrgId || '';
  }

  get isDefault() {
    return this._value.isDefault;
  }

  get isDefaultWithDefault() {
    return this._value.isDefault || false;
  }
}
export interface EhrOrganizationMappingUpdateParams {
	ucClinicId: string;
	request: EhrOrgMappingRequest[];
}

export interface EhrOrganizationMappingInsertParams {
	record: EhrOrgMappingWithDetail;
}

export interface EhrOrganizationMappingSearchParams {
	filter: EhrOrgMappingWithDetail;
}

export interface EhrOrganizationMappingInsertDetailParams {
	detail: EhrOrgDetail;
}

export class EhrOrganizationMappingService {
  protected baseURL = '';

  protected basePath = '/v1/uc/organization-mapping';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public update({ baseUrl, params, headers }: RequestOption<EhrOrganizationMappingUpdateParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<EhrOrgMappingWithDetail[]>>(`${baseUrl || this.baseURL}${this.basePath}/update/${params.ucClinicId}${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public insert({ baseUrl, params, headers }: RequestOption<EhrOrganizationMappingInsertParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}${getQueryStrings(queryList)}`, params.record, { headers });
	}

	public search({ baseUrl, params, headers }: RequestOption<EhrOrganizationMappingSearchParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<EhrOrgMappingWithDetail[]>>(`${baseUrl || this.baseURL}${this.basePath}/search${getQueryStrings(queryList)}`, params.filter, { headers });
	}

	public insertDetail({ baseUrl, params, headers }: RequestOption<EhrOrganizationMappingInsertDetailParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<EhrOrgDetail>>(`${baseUrl || this.baseURL}${this.basePath}/detail${getQueryStrings(queryList)}`, params.detail, { headers });
	}
}

export const ehrOrganizationMappingService = new EhrOrganizationMappingService();

export const createEhrOrganizationMappingUpdateObject = (value: APIResponse<EhrOrgMappingWithDetail[]>) => new APIResponseClass<EhrOrgMappingWithDetailClass[]>(value, (v) => map(v, (value: EhrOrgMappingWithDetail) => new EhrOrgMappingWithDetailClass(value)));

export const useEhrOrganizationMappingUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<EhrOrganizationMappingUpdateParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EhrOrganizationMappingUpdateParams,
    APIResponse<EhrOrgMappingWithDetail[]>,
    typeof ehrOrganizationMappingService.update,
    AjaxOptions,
    APIResponseClass<EhrOrgMappingWithDetailClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ehrOrganizationMappingService.update>) => (
      ehrOrganizationMappingService.update(...args)
    ),
    ResponseClassCreator: createEhrOrganizationMappingUpdateObject,
    typeName: 'useEhrOrganizationMappingUpdate',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEhrOrganizationMappingUpdate.mock = () => new Error('Mock has not been implemented!');
useEhrOrganizationMappingUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEhrOrganizationMappingUpdate.createObject = createEhrOrganizationMappingUpdateObject;

export const createEhrOrganizationMappingInsertObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useEhrOrganizationMappingInsert = <RCN = undefined>(configs: MakeRequestHookConfig<EhrOrganizationMappingInsertParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EhrOrganizationMappingInsertParams,
    APIResponse<string>,
    typeof ehrOrganizationMappingService.insert,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ehrOrganizationMappingService.insert>) => (
      ehrOrganizationMappingService.insert(...args)
    ),
    ResponseClassCreator: createEhrOrganizationMappingInsertObject,
    typeName: 'useEhrOrganizationMappingInsert',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEhrOrganizationMappingInsert.mock = () => new Error('Mock has not been implemented!');
useEhrOrganizationMappingInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEhrOrganizationMappingInsert.createObject = createEhrOrganizationMappingInsertObject;

export const createEhrOrganizationMappingSearchObject = (value: APIResponse<EhrOrgMappingWithDetail[]>) => new APIResponseClass<EhrOrgMappingWithDetailClass[]>(value, (v) => map(v, (value: EhrOrgMappingWithDetail) => new EhrOrgMappingWithDetailClass(value)));

export const useEhrOrganizationMappingSearch = <RCN = undefined>(configs: MakeRequestHookConfig<EhrOrganizationMappingSearchParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EhrOrganizationMappingSearchParams,
    APIResponse<EhrOrgMappingWithDetail[]>,
    typeof ehrOrganizationMappingService.search,
    AjaxOptions,
    APIResponseClass<EhrOrgMappingWithDetailClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ehrOrganizationMappingService.search>) => (
      ehrOrganizationMappingService.search(...args)
    ),
    ResponseClassCreator: createEhrOrganizationMappingSearchObject,
    typeName: 'useEhrOrganizationMappingSearch',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEhrOrganizationMappingSearch.mock = () => new Error('Mock has not been implemented!');
useEhrOrganizationMappingSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEhrOrganizationMappingSearch.createObject = createEhrOrganizationMappingSearchObject;

export const createEhrOrganizationMappingInsertDetailObject = (value: APIResponse<EhrOrgDetail>) => new APIResponseClass<EhrOrgDetailClass>(value, (value: EhrOrgDetail) => new EhrOrgDetailClass(value));

export const useEhrOrganizationMappingInsertDetail = <RCN = undefined>(configs: MakeRequestHookConfig<EhrOrganizationMappingInsertDetailParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EhrOrganizationMappingInsertDetailParams,
    APIResponse<EhrOrgDetail>,
    typeof ehrOrganizationMappingService.insertDetail,
    AjaxOptions,
    APIResponseClass<EhrOrgDetailClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ehrOrganizationMappingService.insertDetail>) => (
      ehrOrganizationMappingService.insertDetail(...args)
    ),
    ResponseClassCreator: createEhrOrganizationMappingInsertDetailObject,
    typeName: 'useEhrOrganizationMappingInsertDetail',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEhrOrganizationMappingInsertDetail.mock = () => new Error('Mock has not been implemented!');
useEhrOrganizationMappingInsertDetail.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEhrOrganizationMappingInsertDetail.createObject = createEhrOrganizationMappingInsertDetailObject;
export interface PatientRequest {
	ehrOrgId: string;
	ehrDepartmentId?: Nullable<string>;
	ehrPatientId: string;
	ehrPatientSecondaryId?: Nullable<string>;
	ehrProviderId: string;
	ehrProviderSecondaryId?: Nullable<string>;
}
export interface User extends BaseModel {
	auth?: Nullable<Auth>;
	hasPassword?: Nullable<boolean>;
	hash?: Nullable<string>;
	salt?: Nullable<string>;
	isDefaultPassword?: Nullable<boolean>;
	isTestUser?: Nullable<boolean>;
	deleted?: Nullable<boolean>;
	isNew?: Nullable<boolean>;
}
export enum GenderIdentityEnum {
	MAN = 'MAN',
	WOMAN = 'WOMAN',
	NON_BINARY = 'NON_BINARY',
	SELF_IDENTIFY = 'SELF_IDENTIFY',
	PREFER_NOT_TO_SAY = 'PREFER_NOT_TO_SAY',
}
export enum RaceEnum {
	AIAN = 'AIAN',
	ASIAN = 'ASIAN',
	BAA = 'BAA',
	HISPANIC = 'HISPANIC',
	MULTIRACIAL = 'MULTIRACIAL',
	NHOPI = 'NHOPI',
	NOT_TO_SAY = 'NOT_TO_SAY',
	OTHER = 'OTHER',
	WHITE = 'WHITE',
}
export enum BehaviorChangeEnum {
	NONE = 'NONE',
	PRECONTEMPLATION = 'PRECONTEMPLATION',
	CONTEMPLATION = 'CONTEMPLATION',
	PREPARATION = 'PREPARATION',
	ACTION = 'ACTION',
	MAINTENANCE = 'MAINTENANCE',
}
export interface BehaviorChange {
	behaviorChangeLevel?: Nullable<BehaviorChangeEnum>;
	updatedBy?: Nullable<string>;
	updatedAt?: Nullable<string>;
	isEdit?: Nullable<boolean>;
	updatedByUser?: Nullable<LimitedUser>;
}
export enum PatientTechLevelEnum {
	LOW = 'LOW',
	MEDIUM = 'MEDIUM',
	HIGH = 'HIGH',
}
export interface PatientProfile {
	insuranceProvider?: Nullable<string>;
	insuranceProvider2?: Nullable<string>;
	firstName?: Nullable<string>;
	lastName?: Nullable<string>;
	nickName?: Nullable<string>;
	languages?: Nullable<string[]>;
	memberId?: Nullable<string>;
	billableHealthConditions?: Nullable<BillableHealthCondition[]>;
	appLanguage?: Nullable<string>;
	birthday?: Nullable<string>;
	gender?: Nullable<GenderEnum>;
	genderIdentity?: Nullable<GenderIdentityEnum>;
	race?: Nullable<RaceEnum>;
	avatar?: Nullable<ImageSchema>;
	timezone?: Nullable<string>;
	weight?: Nullable<Weight>;
	height?: Nullable<Height>;
	testPatient?: Nullable<boolean>;
	communitySupport?: Nullable<string>;
	contactInfo?: Nullable<string>;
	otherProviders?: Nullable<string>;
	behaviorChangeInfo?: Nullable<BehaviorChange>;
	behaviorChange?: Nullable<BehaviorChangeEnum>;
	patientTechLevel?: Nullable<PatientTechLevelEnum>;
	doctorId?: Nullable<string>;
	doctorUser?: Nullable<LimitedUser>;
	$unset?: Nullable<UnsetUpdate>;
}
export interface MonthlyBillableStatistic {
	totalTime?: Nullable<number>;
	measurementDays?: Nullable<number>;
	callEligible?: Nullable<boolean>;
	eligible?: Nullable<boolean>;
	monthOfYear?: Nullable<number>;
	updatedAt?: Nullable<string>;
	totalTimeRange?: Nullable<RangeFilter<number>>;
	measurementDaysRange?: Nullable<RangeFilter<number>>;
}
export enum ScreenStatusEnum {
	UNSCREENED = 'UNSCREENED',
	ELIGIBLE = 'ELIGIBLE',
	INELIGIBLE = 'INELIGIBLE',
}
export enum RiskLevelEnum {
	LOW_RISK = 'LOW_RISK',
	HIGH_RISK = 'HIGH_RISK',
	MEDIUM_RISK = 'MEDIUM_RISK',
}
export interface MonitoringVital extends BaseModel {
	type?: Nullable<VitalEnumType>;
	repeat?: Nullable<TaskRepeatEnum>;
	templateDetails?: Nullable<TemplateDetails>;
	target?: Nullable<number>;
	activated?: Nullable<boolean>;
}
export interface Program extends BaseModel {
	category?: Nullable<ProgramCategoryEnum>;
}
export interface AdditionalService {
	service?: Nullable<AddOnServiceEnum>;
	startDate?: Nullable<string>;
}
export interface LimitedPatientListInfo {
	enrolledStatus?: Nullable<EnrolledProgramStatusEnum>;
	vitals?: Nullable<MonitoringVital[]>;
	programs?: Nullable<Program[]>;
	additionalServices?: Nullable<AdditionalService[]>;
	enrollmentDate?: Nullable<string>;
	dischargedAt?: Nullable<string>;
	$or?: Nullable<OrFilter>;
}
export interface AssociatedInfo {
	clinicId?: Nullable<string>;
	uploadedTime?: Nullable<string>;
	uploadedFrom?: Nullable<string>;
	doctorId?: Nullable<string>;
	clinicInfo?: Nullable<LimitedClinic>;
	doctorInfo?: Nullable<LimitedUser>;
}
export interface PatientDeviceInfo {
	recentUsedDeviceMacId?: Nullable<string>;
	recentUsedTime?: Nullable<string>;
	pairedTime?: Nullable<string>;
}
export interface PatientCgmDevice {
	brand?: Nullable<CgmBrandEnum>;
	model?: Nullable<CgmModelEnum>;
}
export enum PatientDiagnosesEnum {
	HTN = 'HTN',
	HLD = 'HLD',
	DM2 = 'DM2',
	DM_OTHERS = 'DM_OTHERS',
	PRE_DM = 'PRE_DM',
	HYPOTENSION = 'HYPOTENSION',
	COPD = 'COPD',
	OBESITY = 'OBESITY',
	CKD = 'CKD',
	CHF = 'CHF',
	RDD = 'RDD',
	OTHERS = 'OTHERS',
	DM = 'DM',
	CKF = 'CKF',
	ESRD_DIALYSIS = 'ESRD_DIALYSIS',
}
export interface HealthConditionItem {
	code?: Nullable<string>;
	diagnosis?: Nullable<PatientDiagnosesEnum>;
	condition?: Nullable<string>;
	isBillable?: Nullable<boolean>;
	duplicate?: Nullable<boolean>;
	conflict?: Nullable<boolean>;
}
export enum PatientProtocolEnum {
	HTN = 'HTN',
	DM2 = 'DM2',
	OTHER = 'OTHER',
	DM = 'DM',
	PRE_DM = 'PRE_DM',
}
export enum ControlLevelEnum {
	CONTROLLED = 'CONTROLLED',
	UNCONTROLLED = 'UNCONTROLLED',
	UNCATEGORIZED = 'UNCATEGORIZED',
}
export enum IncompleteStatusEnum {
	MISS_INSURANCE_ELIGIBILITY = 'MISS_INSURANCE_ELIGIBILITY',
	INACTIVE_INSURANCE_ELIGIBILITY = 'INACTIVE_INSURANCE_ELIGIBILITY',
	MISS_INSURANCE_NAME = 'MISS_INSURANCE_NAME',
	MISS_ICD_CODE = 'MISS_ICD_CODE',
	MISMATCH_ICD_CODE_AND_DIAGNOSIS = 'MISMATCH_ICD_CODE_AND_DIAGNOSIS',
	MISMATCH_ICD_CODE_AND_ENROLLED_PROGRAM = 'MISMATCH_ICD_CODE_AND_ENROLLED_PROGRAM',
	MISS_ENROLLED_PROGRAM = 'MISS_ENROLLED_PROGRAM',
}
export interface MedicalOrgPatientAccess {
	medicalOrgId?: Nullable<string>;
	doctorIds?: Nullable<string[]>;
}
export enum ProgramCategorySearchFilterEnum {
	RPM_ONLY = 'RPM_ONLY',
	CCM_ONLY = 'CCM_ONLY',
	RPM_CCM = 'RPM_CCM',
	VBC = 'VBC',
	MNT = 'MNT',
}
export interface WatchInfo {
	isWatched?: Nullable<boolean>;
	watchNote?: Nullable<string>;
	addedTime?: Nullable<string>;
}
export interface PinInfo {
	pinnedDate?: Nullable<string>;
}
export interface Patient extends User {
	profile?: Nullable<PatientProfile>;
	email?: Nullable<string>;
	majorRole?: Nullable<RoleTypeEnum>;
	loginId?: Nullable<string>;
	phone?: Nullable<Phone[]>;
	medicalRecordId?: Nullable<string>;
	source?: Nullable<string>;
	address?: Nullable<Address[]>;
	monthlyMeasurementDays?: Nullable<number>;
	visitId?: Nullable<string>;
	editWeight?: Nullable<boolean>;
	editHeight?: Nullable<boolean>;
	weightFixTag?: Nullable<string>;
	monthlyBillableStatistic?: Nullable<MonthlyBillableStatistic>;
	updatedBy?: Nullable<string>;
	createdBy?: Nullable<string>;
	patientSource?: Nullable<PatientSourceEnum>;
	screenStatus?: Nullable<ScreenStatusEnum>;
	clinicId?: Nullable<string>;
	riskLevel?: Nullable<RiskLevelEnum>;
	patientListInfo?: Nullable<LimitedPatientListInfo>;
	assignedToRD?: Nullable<string>;
	assignedToCA?: Nullable<string>;
	tag?: Nullable<string>;
	complexity?: Nullable<PatientComplexityEnum>;
	associatedList?: Nullable<AssociatedInfo[]>;
	bpDeviceInfo?: Nullable<PatientDeviceInfo>;
	bgDeviceInfo?: Nullable<PatientDeviceInfo>;
	patientCgmDevice?: Nullable<PatientCgmDevice>;
	deleteReason?: Nullable<string>;
	inquiryIdList?: Nullable<string[]>;
	loginFailCount?: Nullable<number>;
	diagnoses?: Nullable<PatientDiagnosesEnum[]>;
	icdCodes?: Nullable<string[]>;
	healthConditions?: Nullable<HealthConditionItem[]>;
	protocol?: Nullable<PatientProtocolEnum>;
	patientCategories?: Nullable<PatientCategoryEnum[]>;
	controlLevel?: Nullable<ControlLevelEnum>;
	incompleteStatusList?: Nullable<IncompleteStatusEnum[]>;
	monthlyMeasurementDaysRange?: Nullable<RangeFilter<number>>;
	phoneNumberNear?: Nullable<string>;
	firstNameNear?: Nullable<string>;
	lastNameNear?: Nullable<string>;
	identificationNear?: Nullable<string>;
	birthdayNear?: Nullable<string>;
	medicalRecordIdNear?: Nullable<string>;
	filterWatchedPatient?: Nullable<boolean>;
	filterPinnedPatient?: Nullable<boolean>;
	patientAccessFilter?: Nullable<MedicalOrgPatientAccess[]>;
	incompleteStatusInFilter?: Nullable<InFilter<IncompleteStatusEnum>>;
	nameNear?: Nullable<string>;
	phoneMatch?: Nullable<ElemMatchFilter<Phone>>;
	phoneNumberIn?: Nullable<InFilter<string>>;
	deletedNe?: Nullable<NeFilter<string>>;
	assignedToRDIn?: Nullable<InFilter<string>>;
	assignedToCAIn?: Nullable<InFilter<string>>;
	programCategoryFilter?: Nullable<ProgramCategorySearchFilterEnum>;
	programIn?: Nullable<InFilter<ProgramCategoryEnum>>;
	vitalIn?: Nullable<InFilter<VitalEnumType>>;
	enrolledStatusIn?: Nullable<InFilter<EnrolledProgramStatusEnum>>;
	riskLevelIn?: Nullable<InFilter<RiskLevelEnum>>;
	profileLanguageIn?: Nullable<InFilter<string>>;
	profileAppLanguageIn?: Nullable<InFilter<string>>;
	profileDoctorIdIn?: Nullable<InFilter<string>>;
	patientSourceIn?: Nullable<InFilter<PatientSourceEnum>>;
	screenStatusIn?: Nullable<InFilter<ScreenStatusEnum>>;
	genderIn?: Nullable<InFilter<GenderEnum>>;
	complexityIn?: Nullable<InFilter<PatientComplexityEnum>>;
	tagIn?: Nullable<InFilter<string>>;
	clinicIdsIn?: Nullable<InFilter<string>>;
	updatedByUser?: Nullable<LimitedUser>;
	watchInfo?: Nullable<WatchInfo>;
	pinInfo?: Nullable<PinInfo>;
	clinic?: Nullable<LimitedClinic>;
	medicalOrganizationAssignment?: Nullable<MedicalOrganizationAssignment>;
	assignedToRDUser?: Nullable<LimitedUser>;
	assignedToCAUser?: Nullable<LimitedUser>;
	controlLevelIn?: Nullable<InFilter<ControlLevelEnum>>;
}
export class UserClass extends BaseModelClass {
  protected readonly _value: User;

	constructor(value: User) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get auth() {
    return this._value.auth !== undefined && this._value.auth !== null ? new AuthClass(this._value.auth) : this._value.auth;
  }

  get hasPassword() {
    return this._value.hasPassword;
  }

  get hasPasswordWithDefault() {
    return this._value.hasPassword || false;
  }

  get hash() {
    return this._value.hash;
  }

  get hashWithDefault() {
    return this._value.hash || '';
  }

  get salt() {
    return this._value.salt;
  }

  get saltWithDefault() {
    return this._value.salt || '';
  }

  get isDefaultPassword() {
    return this._value.isDefaultPassword;
  }

  get isDefaultPasswordWithDefault() {
    return this._value.isDefaultPassword || false;
  }

  get isTestUser() {
    return this._value.isTestUser;
  }

  get isTestUserWithDefault() {
    return this._value.isTestUser || false;
  }

  get deleted() {
    return this._value.deleted;
  }

  get deletedWithDefault() {
    return this._value.deleted || false;
  }

  get isNew() {
    return this._value.isNew;
  }

  get isNewWithDefault() {
    return this._value.isNew || false;
  }
}
export class BehaviorChangeClass {
  protected readonly _value: BehaviorChange;

	constructor(value: BehaviorChange) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get behaviorChangeLevel() {
    return this._value.behaviorChangeLevel;
  }

  behaviorChangeLevelIsIn(values: BehaviorChangeEnum[] = []) {
    return this._value.behaviorChangeLevel && values.includes(this._value.behaviorChangeLevel);
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }

  get isEdit() {
    return this._value.isEdit;
  }

  get isEditWithDefault() {
    return this._value.isEdit || false;
  }

  get updatedByUser() {
    return this._value.updatedByUser !== undefined && this._value.updatedByUser !== null ? new LimitedUserClass(this._value.updatedByUser) : this._value.updatedByUser;
  }
}
export class PatientProfileClass {
  protected readonly _value: PatientProfile;

	constructor(value: PatientProfile) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get insuranceProvider() {
    return this._value.insuranceProvider;
  }

  get insuranceProviderWithDefault() {
    return this._value.insuranceProvider || '';
  }

  get insuranceProvider2() {
    return this._value.insuranceProvider2;
  }

  get insuranceProvider2WithDefault() {
    return this._value.insuranceProvider2 || '';
  }

  get firstName() {
    return this._value.firstName;
  }

  get firstNameWithDefault() {
    return this._value.firstName || '';
  }

  get lastName() {
    return this._value.lastName;
  }

  get lastNameWithDefault() {
    return this._value.lastName || '';
  }

  get nickName() {
    return this._value.nickName;
  }

  get nickNameWithDefault() {
    return this._value.nickName || '';
  }

  get languages() {
    return this._value.languages;
  }

  get languagesWithDefault() {
    return this._value.languages || [];
  }

  get memberId() {
    return this._value.memberId;
  }

  get billableHealthConditions() {
    return this._value.billableHealthConditions !== undefined && this._value.billableHealthConditions !== null ? map(this._value.billableHealthConditions, (v) => new BillableHealthConditionClass(v)) : this._value.billableHealthConditions;
  }

  get billableHealthConditionsWithDefault() {
    return this._value.billableHealthConditions || [];
  }

  get appLanguage() {
    return this._value.appLanguage;
  }

  get appLanguageWithDefault() {
    return this._value.appLanguage || '';
  }

  get birthday() {
    return this._value.birthday;
  }

  get birthdayWithDefault() {
    return this._value.birthday || '';
  }

  get gender() {
    return this._value.gender;
  }

  genderIsIn(values: GenderEnum[] = []) {
    return this._value.gender && values.includes(this._value.gender);
  }

  get genderIdentity() {
    return this._value.genderIdentity;
  }

  genderIdentityIsIn(values: GenderIdentityEnum[] = []) {
    return this._value.genderIdentity && values.includes(this._value.genderIdentity);
  }

  get race() {
    return this._value.race;
  }

  raceIsIn(values: RaceEnum[] = []) {
    return this._value.race && values.includes(this._value.race);
  }

  get avatar() {
    return this._value.avatar !== undefined && this._value.avatar !== null ? new ImageSchemaClass(this._value.avatar) : this._value.avatar;
  }

  get timezone() {
    return this._value.timezone;
  }

  get timezoneWithDefault() {
    return this._value.timezone || '';
  }

  get weight() {
    return this._value.weight !== undefined && this._value.weight !== null ? new WeightClass(this._value.weight) : this._value.weight;
  }

  get height() {
    return this._value.height !== undefined && this._value.height !== null ? new HeightClass(this._value.height) : this._value.height;
  }

  get testPatient() {
    return this._value.testPatient;
  }

  get testPatientWithDefault() {
    return this._value.testPatient || false;
  }

  get communitySupport() {
    return this._value.communitySupport;
  }

  get communitySupportWithDefault() {
    return this._value.communitySupport || '';
  }

  get contactInfo() {
    return this._value.contactInfo;
  }

  get contactInfoWithDefault() {
    return this._value.contactInfo || '';
  }

  get otherProviders() {
    return this._value.otherProviders;
  }

  get otherProvidersWithDefault() {
    return this._value.otherProviders || '';
  }

  get behaviorChangeInfo() {
    return this._value.behaviorChangeInfo !== undefined && this._value.behaviorChangeInfo !== null ? new BehaviorChangeClass(this._value.behaviorChangeInfo) : this._value.behaviorChangeInfo;
  }

  get behaviorChange() {
    return this._value.behaviorChange;
  }

  behaviorChangeIsIn(values: BehaviorChangeEnum[] = []) {
    return this._value.behaviorChange && values.includes(this._value.behaviorChange);
  }

  get patientTechLevel() {
    return this._value.patientTechLevel;
  }

  patientTechLevelIsIn(values: PatientTechLevelEnum[] = []) {
    return this._value.patientTechLevel && values.includes(this._value.patientTechLevel);
  }

  get doctorId() {
    return this._value.doctorId;
  }

  get doctorUser() {
    return this._value.doctorUser !== undefined && this._value.doctorUser !== null ? new LimitedUserClass(this._value.doctorUser) : this._value.doctorUser;
  }

  get $unset() {
    return this._value.$unset !== undefined && this._value.$unset !== null ? new UnsetUpdateClass(this._value.$unset) : this._value.$unset;
  }
}
export class MonthlyBillableStatisticClass {
  protected readonly _value: MonthlyBillableStatistic;

	constructor(value: MonthlyBillableStatistic) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get totalTime() {
    return this._value.totalTime;
  }

  get totalTimeWithDefault() {
    return this._value.totalTime || 0;
  }

  get measurementDays() {
    return this._value.measurementDays;
  }

  get measurementDaysWithDefault() {
    return this._value.measurementDays || 0;
  }

  get callEligible() {
    return this._value.callEligible;
  }

  get callEligibleWithDefault() {
    return this._value.callEligible || false;
  }

  get eligible() {
    return this._value.eligible;
  }

  get eligibleWithDefault() {
    return this._value.eligible || false;
  }

  get monthOfYear() {
    return this._value.monthOfYear;
  }

  get monthOfYearWithDefault() {
    return this._value.monthOfYear || 0;
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }

  get totalTimeRange() {
    return this._value.totalTimeRange !== undefined && this._value.totalTimeRange !== null ? new RangeFilterClass(this._value.totalTimeRange) : this._value.totalTimeRange;
  }

  get measurementDaysRange() {
    return this._value.measurementDaysRange !== undefined && this._value.measurementDaysRange !== null ? new RangeFilterClass(this._value.measurementDaysRange) : this._value.measurementDaysRange;
  }
}
export class MonitoringVitalClass extends BaseModelClass {
  protected readonly _value: MonitoringVital;

	constructor(value: MonitoringVital) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: VitalEnumType[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get repeat() {
    return this._value.repeat;
  }

  repeatIsIn(values: TaskRepeatEnum[] = []) {
    return this._value.repeat && values.includes(this._value.repeat);
  }

  get templateDetails() {
    return this._value.templateDetails !== undefined && this._value.templateDetails !== null ? new TemplateDetailsClass(this._value.templateDetails) : this._value.templateDetails;
  }

  get target() {
    return this._value.target;
  }

  get targetWithDefault() {
    return this._value.target || 0;
  }

  get activated() {
    return this._value.activated;
  }

  get activatedWithDefault() {
    return this._value.activated || false;
  }
}
export class ProgramClass extends BaseModelClass {
  protected readonly _value: Program;

	constructor(value: Program) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get category() {
    return this._value.category;
  }

  categoryIsIn(values: ProgramCategoryEnum[] = []) {
    return this._value.category && values.includes(this._value.category);
  }
}
export class AdditionalServiceClass {
  protected readonly _value: AdditionalService;

	constructor(value: AdditionalService) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get service() {
    return this._value.service;
  }

  serviceIsIn(values: AddOnServiceEnum[] = []) {
    return this._value.service && values.includes(this._value.service);
  }

  get startDate() {
    return (this._value.startDate !== undefined && this._value.startDate !== null) ? dayjs(this._value.startDate) : this._value.startDate;
  }
}
export class LimitedPatientListInfoClass {
  protected readonly _value: LimitedPatientListInfo;

	constructor(value: LimitedPatientListInfo) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get enrolledStatus() {
    return this._value.enrolledStatus;
  }

  enrolledStatusIsIn(values: EnrolledProgramStatusEnum[] = []) {
    return this._value.enrolledStatus && values.includes(this._value.enrolledStatus);
  }

  get vitals() {
    return this._value.vitals !== undefined && this._value.vitals !== null ? map(this._value.vitals, (v) => new MonitoringVitalClass(v)) : this._value.vitals;
  }

  get vitalsWithDefault() {
    return this._value.vitals || [];
  }

  get programs() {
    return this._value.programs !== undefined && this._value.programs !== null ? map(this._value.programs, (v) => new ProgramClass(v)) : this._value.programs;
  }

  get programsWithDefault() {
    return this._value.programs || [];
  }

  get additionalServices() {
    return this._value.additionalServices !== undefined && this._value.additionalServices !== null ? map(this._value.additionalServices, (v) => new AdditionalServiceClass(v)) : this._value.additionalServices;
  }

  get additionalServicesWithDefault() {
    return this._value.additionalServices || [];
  }

  get enrollmentDate() {
    return (this._value.enrollmentDate !== undefined && this._value.enrollmentDate !== null) ? dayjs(this._value.enrollmentDate) : this._value.enrollmentDate;
  }

  get dischargedAt() {
    return (this._value.dischargedAt !== undefined && this._value.dischargedAt !== null) ? dayjs(this._value.dischargedAt) : this._value.dischargedAt;
  }

  get $or() {
    return this._value.$or !== undefined && this._value.$or !== null ? new OrFilterClass(this._value.$or) : this._value.$or;
  }
}
export class AssociatedInfoClass {
  protected readonly _value: AssociatedInfo;

	constructor(value: AssociatedInfo) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get clinicId() {
    return this._value.clinicId;
  }

  get uploadedTime() {
    return (this._value.uploadedTime !== undefined && this._value.uploadedTime !== null) ? dayjs(this._value.uploadedTime) : this._value.uploadedTime;
  }

  get uploadedFrom() {
    return this._value.uploadedFrom;
  }

  get uploadedFromWithDefault() {
    return this._value.uploadedFrom || '';
  }

  get doctorId() {
    return this._value.doctorId;
  }

  get clinicInfo() {
    return this._value.clinicInfo !== undefined && this._value.clinicInfo !== null ? new LimitedClinicClass(this._value.clinicInfo) : this._value.clinicInfo;
  }

  get doctorInfo() {
    return this._value.doctorInfo !== undefined && this._value.doctorInfo !== null ? new LimitedUserClass(this._value.doctorInfo) : this._value.doctorInfo;
  }
}
export class PatientDeviceInfoClass {
  protected readonly _value: PatientDeviceInfo;

	constructor(value: PatientDeviceInfo) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get recentUsedDeviceMacId() {
    return this._value.recentUsedDeviceMacId;
  }

  get recentUsedDeviceMacIdWithDefault() {
    return this._value.recentUsedDeviceMacId || '';
  }

  get recentUsedTime() {
    return (this._value.recentUsedTime !== undefined && this._value.recentUsedTime !== null) ? dayjs(this._value.recentUsedTime) : this._value.recentUsedTime;
  }

  get pairedTime() {
    return (this._value.pairedTime !== undefined && this._value.pairedTime !== null) ? dayjs(this._value.pairedTime) : this._value.pairedTime;
  }
}
export class PatientCgmDeviceClass {
  protected readonly _value: PatientCgmDevice;

	constructor(value: PatientCgmDevice) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get brand() {
    return this._value.brand;
  }

  brandIsIn(values: CgmBrandEnum[] = []) {
    return this._value.brand && values.includes(this._value.brand);
  }

  get model() {
    return this._value.model;
  }

  modelIsIn(values: CgmModelEnum[] = []) {
    return this._value.model && values.includes(this._value.model);
  }
}
export class HealthConditionItemClass {
  protected readonly _value: HealthConditionItem;

	constructor(value: HealthConditionItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get code() {
    return this._value.code;
  }

  get codeWithDefault() {
    return this._value.code || '';
  }

  get diagnosis() {
    return this._value.diagnosis;
  }

  diagnosisIsIn(values: PatientDiagnosesEnum[] = []) {
    return this._value.diagnosis && values.includes(this._value.diagnosis);
  }

  get condition() {
    return this._value.condition;
  }

  get conditionWithDefault() {
    return this._value.condition || '';
  }

  get isBillable() {
    return this._value.isBillable;
  }

  get isBillableWithDefault() {
    return this._value.isBillable || false;
  }

  get duplicate() {
    return this._value.duplicate;
  }

  get duplicateWithDefault() {
    return this._value.duplicate || false;
  }

  get conflict() {
    return this._value.conflict;
  }

  get conflictWithDefault() {
    return this._value.conflict || false;
  }
}
export class MedicalOrgPatientAccessClass {
  protected readonly _value: MedicalOrgPatientAccess;

	constructor(value: MedicalOrgPatientAccess) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get medicalOrgId() {
    return this._value.medicalOrgId;
  }

  get doctorIds() {
    return this._value.doctorIds;
  }

  get doctorIdsWithDefault() {
    return this._value.doctorIds || [];
  }
}
export class WatchInfoClass {
  protected readonly _value: WatchInfo;

	constructor(value: WatchInfo) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get isWatched() {
    return this._value.isWatched;
  }

  get isWatchedWithDefault() {
    return this._value.isWatched || false;
  }

  get watchNote() {
    return this._value.watchNote;
  }

  get watchNoteWithDefault() {
    return this._value.watchNote || '';
  }

  get addedTime() {
    return (this._value.addedTime !== undefined && this._value.addedTime !== null) ? dayjs(this._value.addedTime) : this._value.addedTime;
  }
}
export class PinInfoClass {
  protected readonly _value: PinInfo;

	constructor(value: PinInfo) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get pinnedDate() {
    return (this._value.pinnedDate !== undefined && this._value.pinnedDate !== null) ? dayjs(this._value.pinnedDate) : this._value.pinnedDate;
  }
}
export class PatientClass extends UserClass {
  protected readonly _value: Patient;

	constructor(value: Patient) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get profile() {
    return this._value.profile !== undefined && this._value.profile !== null ? new PatientProfileClass(this._value.profile) : this._value.profile;
  }

  get email() {
    return this._value.email;
  }

  get emailWithDefault() {
    return this._value.email || '';
  }

  get majorRole() {
    return this._value.majorRole;
  }

  majorRoleIsIn(values: RoleTypeEnum[] = []) {
    return this._value.majorRole && values.includes(this._value.majorRole);
  }

  get loginId() {
    return this._value.loginId;
  }

  get loginIdWithDefault() {
    return this._value.loginId || '';
  }

  get phone() {
    return this._value.phone !== undefined && this._value.phone !== null ? map(this._value.phone, (v) => new PhoneClass(v)) : this._value.phone;
  }

  get phoneWithDefault() {
    return this._value.phone || [];
  }

  get medicalRecordId() {
    return this._value.medicalRecordId;
  }

  get medicalRecordIdWithDefault() {
    return this._value.medicalRecordId || '';
  }

  get source() {
    return this._value.source;
  }

  get sourceWithDefault() {
    return this._value.source || '';
  }

  get address() {
    return this._value.address !== undefined && this._value.address !== null ? map(this._value.address, (v) => new AddressClass(v)) : this._value.address;
  }

  get addressWithDefault() {
    return this._value.address || [];
  }

  get monthlyMeasurementDays() {
    return this._value.monthlyMeasurementDays;
  }

  get monthlyMeasurementDaysWithDefault() {
    return this._value.monthlyMeasurementDays || 0;
  }

  get visitId() {
    return this._value.visitId;
  }

  get editWeight() {
    return this._value.editWeight;
  }

  get editWeightWithDefault() {
    return this._value.editWeight || false;
  }

  get editHeight() {
    return this._value.editHeight;
  }

  get editHeightWithDefault() {
    return this._value.editHeight || false;
  }

  get weightFixTag() {
    return this._value.weightFixTag;
  }

  get weightFixTagWithDefault() {
    return this._value.weightFixTag || '';
  }

  get monthlyBillableStatistic() {
    return this._value.monthlyBillableStatistic !== undefined && this._value.monthlyBillableStatistic !== null ? new MonthlyBillableStatisticClass(this._value.monthlyBillableStatistic) : this._value.monthlyBillableStatistic;
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get createdBy() {
    return this._value.createdBy;
  }

  get patientSource() {
    return this._value.patientSource;
  }

  patientSourceIsIn(values: PatientSourceEnum[] = []) {
    return this._value.patientSource && values.includes(this._value.patientSource);
  }

  get screenStatus() {
    return this._value.screenStatus;
  }

  screenStatusIsIn(values: ScreenStatusEnum[] = []) {
    return this._value.screenStatus && values.includes(this._value.screenStatus);
  }

  get clinicId() {
    return this._value.clinicId;
  }

  get riskLevel() {
    return this._value.riskLevel;
  }

  riskLevelIsIn(values: RiskLevelEnum[] = []) {
    return this._value.riskLevel && values.includes(this._value.riskLevel);
  }

  get patientListInfo() {
    return this._value.patientListInfo !== undefined && this._value.patientListInfo !== null ? new LimitedPatientListInfoClass(this._value.patientListInfo) : this._value.patientListInfo;
  }

  get assignedToRD() {
    return this._value.assignedToRD;
  }

  get assignedToCA() {
    return this._value.assignedToCA;
  }

  get tag() {
    return this._value.tag;
  }

  get tagWithDefault() {
    return this._value.tag || '';
  }

  get complexity() {
    return this._value.complexity;
  }

  complexityIsIn(values: PatientComplexityEnum[] = []) {
    return this._value.complexity && values.includes(this._value.complexity);
  }

  get associatedList() {
    return this._value.associatedList !== undefined && this._value.associatedList !== null ? map(this._value.associatedList, (v) => new AssociatedInfoClass(v)) : this._value.associatedList;
  }

  get associatedListWithDefault() {
    return this._value.associatedList || [];
  }

  get bpDeviceInfo() {
    return this._value.bpDeviceInfo !== undefined && this._value.bpDeviceInfo !== null ? new PatientDeviceInfoClass(this._value.bpDeviceInfo) : this._value.bpDeviceInfo;
  }

  get bgDeviceInfo() {
    return this._value.bgDeviceInfo !== undefined && this._value.bgDeviceInfo !== null ? new PatientDeviceInfoClass(this._value.bgDeviceInfo) : this._value.bgDeviceInfo;
  }

  get patientCgmDevice() {
    return this._value.patientCgmDevice !== undefined && this._value.patientCgmDevice !== null ? new PatientCgmDeviceClass(this._value.patientCgmDevice) : this._value.patientCgmDevice;
  }

  get deleteReason() {
    return this._value.deleteReason;
  }

  get deleteReasonWithDefault() {
    return this._value.deleteReason || '';
  }

  get inquiryIdList() {
    return this._value.inquiryIdList;
  }

  get inquiryIdListWithDefault() {
    return this._value.inquiryIdList || [];
  }

  get loginFailCount() {
    return this._value.loginFailCount;
  }

  get loginFailCountWithDefault() {
    return this._value.loginFailCount || 0;
  }

  get diagnoses() {
    return this._value.diagnoses;
  }

  get diagnosesWithDefault() {
    return this._value.diagnoses || [];
  }

  get icdCodes() {
    return this._value.icdCodes;
  }

  get icdCodesWithDefault() {
    return this._value.icdCodes || [];
  }

  get healthConditions() {
    return this._value.healthConditions !== undefined && this._value.healthConditions !== null ? map(this._value.healthConditions, (v) => new HealthConditionItemClass(v)) : this._value.healthConditions;
  }

  get healthConditionsWithDefault() {
    return this._value.healthConditions || [];
  }

  get protocol() {
    return this._value.protocol;
  }

  protocolIsIn(values: PatientProtocolEnum[] = []) {
    return this._value.protocol && values.includes(this._value.protocol);
  }

  get patientCategories() {
    return this._value.patientCategories;
  }

  get patientCategoriesWithDefault() {
    return this._value.patientCategories || [];
  }

  get controlLevel() {
    return this._value.controlLevel;
  }

  controlLevelIsIn(values: ControlLevelEnum[] = []) {
    return this._value.controlLevel && values.includes(this._value.controlLevel);
  }

  get incompleteStatusList() {
    return this._value.incompleteStatusList;
  }

  get incompleteStatusListWithDefault() {
    return this._value.incompleteStatusList || [];
  }

  get monthlyMeasurementDaysRange() {
    return this._value.monthlyMeasurementDaysRange !== undefined && this._value.monthlyMeasurementDaysRange !== null ? new RangeFilterClass(this._value.monthlyMeasurementDaysRange) : this._value.monthlyMeasurementDaysRange;
  }

  get phoneNumberNear() {
    return this._value.phoneNumberNear;
  }

  get phoneNumberNearWithDefault() {
    return this._value.phoneNumberNear || '';
  }

  get firstNameNear() {
    return this._value.firstNameNear;
  }

  get firstNameNearWithDefault() {
    return this._value.firstNameNear || '';
  }

  get lastNameNear() {
    return this._value.lastNameNear;
  }

  get lastNameNearWithDefault() {
    return this._value.lastNameNear || '';
  }

  get identificationNear() {
    return this._value.identificationNear;
  }

  get identificationNearWithDefault() {
    return this._value.identificationNear || '';
  }

  get birthdayNear() {
    return this._value.birthdayNear;
  }

  get birthdayNearWithDefault() {
    return this._value.birthdayNear || '';
  }

  get medicalRecordIdNear() {
    return this._value.medicalRecordIdNear;
  }

  get medicalRecordIdNearWithDefault() {
    return this._value.medicalRecordIdNear || '';
  }

  get filterWatchedPatient() {
    return this._value.filterWatchedPatient;
  }

  get filterWatchedPatientWithDefault() {
    return this._value.filterWatchedPatient || false;
  }

  get filterPinnedPatient() {
    return this._value.filterPinnedPatient;
  }

  get filterPinnedPatientWithDefault() {
    return this._value.filterPinnedPatient || false;
  }

  get patientAccessFilter() {
    return this._value.patientAccessFilter !== undefined && this._value.patientAccessFilter !== null ? map(this._value.patientAccessFilter, (v) => new MedicalOrgPatientAccessClass(v)) : this._value.patientAccessFilter;
  }

  get patientAccessFilterWithDefault() {
    return this._value.patientAccessFilter || [];
  }

  get incompleteStatusInFilter() {
    return this._value.incompleteStatusInFilter !== undefined && this._value.incompleteStatusInFilter !== null ? new InFilterClass(this._value.incompleteStatusInFilter) : this._value.incompleteStatusInFilter;
  }

  get nameNear() {
    return this._value.nameNear;
  }

  get nameNearWithDefault() {
    return this._value.nameNear || '';
  }

  get phoneMatch() {
    return this._value.phoneMatch !== undefined && this._value.phoneMatch !== null ? new ElemMatchFilterClass(this._value.phoneMatch) : this._value.phoneMatch;
  }

  get phoneNumberIn() {
    return this._value.phoneNumberIn !== undefined && this._value.phoneNumberIn !== null ? new InFilterClass(this._value.phoneNumberIn) : this._value.phoneNumberIn;
  }

  get deletedNe() {
    return this._value.deletedNe !== undefined && this._value.deletedNe !== null ? new NeFilterClass(this._value.deletedNe) : this._value.deletedNe;
  }

  get assignedToRDIn() {
    return this._value.assignedToRDIn !== undefined && this._value.assignedToRDIn !== null ? new InFilterClass(this._value.assignedToRDIn) : this._value.assignedToRDIn;
  }

  get assignedToCAIn() {
    return this._value.assignedToCAIn !== undefined && this._value.assignedToCAIn !== null ? new InFilterClass(this._value.assignedToCAIn) : this._value.assignedToCAIn;
  }

  get programCategoryFilter() {
    return this._value.programCategoryFilter;
  }

  programCategoryFilterIsIn(values: ProgramCategorySearchFilterEnum[] = []) {
    return this._value.programCategoryFilter && values.includes(this._value.programCategoryFilter);
  }

  get programIn() {
    return this._value.programIn !== undefined && this._value.programIn !== null ? new InFilterClass(this._value.programIn) : this._value.programIn;
  }

  get vitalIn() {
    return this._value.vitalIn !== undefined && this._value.vitalIn !== null ? new InFilterClass(this._value.vitalIn) : this._value.vitalIn;
  }

  get enrolledStatusIn() {
    return this._value.enrolledStatusIn !== undefined && this._value.enrolledStatusIn !== null ? new InFilterClass(this._value.enrolledStatusIn) : this._value.enrolledStatusIn;
  }

  get riskLevelIn() {
    return this._value.riskLevelIn !== undefined && this._value.riskLevelIn !== null ? new InFilterClass(this._value.riskLevelIn) : this._value.riskLevelIn;
  }

  get profileLanguageIn() {
    return this._value.profileLanguageIn !== undefined && this._value.profileLanguageIn !== null ? new InFilterClass(this._value.profileLanguageIn) : this._value.profileLanguageIn;
  }

  get profileAppLanguageIn() {
    return this._value.profileAppLanguageIn !== undefined && this._value.profileAppLanguageIn !== null ? new InFilterClass(this._value.profileAppLanguageIn) : this._value.profileAppLanguageIn;
  }

  get profileDoctorIdIn() {
    return this._value.profileDoctorIdIn !== undefined && this._value.profileDoctorIdIn !== null ? new InFilterClass(this._value.profileDoctorIdIn) : this._value.profileDoctorIdIn;
  }

  get patientSourceIn() {
    return this._value.patientSourceIn !== undefined && this._value.patientSourceIn !== null ? new InFilterClass(this._value.patientSourceIn) : this._value.patientSourceIn;
  }

  get screenStatusIn() {
    return this._value.screenStatusIn !== undefined && this._value.screenStatusIn !== null ? new InFilterClass(this._value.screenStatusIn) : this._value.screenStatusIn;
  }

  get genderIn() {
    return this._value.genderIn !== undefined && this._value.genderIn !== null ? new InFilterClass(this._value.genderIn) : this._value.genderIn;
  }

  get complexityIn() {
    return this._value.complexityIn !== undefined && this._value.complexityIn !== null ? new InFilterClass(this._value.complexityIn) : this._value.complexityIn;
  }

  get tagIn() {
    return this._value.tagIn !== undefined && this._value.tagIn !== null ? new InFilterClass(this._value.tagIn) : this._value.tagIn;
  }

  get clinicIdsIn() {
    return this._value.clinicIdsIn !== undefined && this._value.clinicIdsIn !== null ? new InFilterClass(this._value.clinicIdsIn) : this._value.clinicIdsIn;
  }

  get updatedByUser() {
    return this._value.updatedByUser !== undefined && this._value.updatedByUser !== null ? new LimitedUserClass(this._value.updatedByUser) : this._value.updatedByUser;
  }

  get watchInfo() {
    return this._value.watchInfo !== undefined && this._value.watchInfo !== null ? new WatchInfoClass(this._value.watchInfo) : this._value.watchInfo;
  }

  get pinInfo() {
    return this._value.pinInfo !== undefined && this._value.pinInfo !== null ? new PinInfoClass(this._value.pinInfo) : this._value.pinInfo;
  }

  get clinic() {
    return this._value.clinic !== undefined && this._value.clinic !== null ? new LimitedClinicClass(this._value.clinic) : this._value.clinic;
  }

  get medicalOrganizationAssignment() {
    return this._value.medicalOrganizationAssignment !== undefined && this._value.medicalOrganizationAssignment !== null ? new MedicalOrganizationAssignmentClass(this._value.medicalOrganizationAssignment) : this._value.medicalOrganizationAssignment;
  }

  get assignedToRDUser() {
    return this._value.assignedToRDUser !== undefined && this._value.assignedToRDUser !== null ? new LimitedUserClass(this._value.assignedToRDUser) : this._value.assignedToRDUser;
  }

  get assignedToCAUser() {
    return this._value.assignedToCAUser !== undefined && this._value.assignedToCAUser !== null ? new LimitedUserClass(this._value.assignedToCAUser) : this._value.assignedToCAUser;
  }

  get controlLevelIn() {
    return this._value.controlLevelIn !== undefined && this._value.controlLevelIn !== null ? new InFilterClass(this._value.controlLevelIn) : this._value.controlLevelIn;
  }
}
export interface ReferPatientReqBody extends PatientRequest {
	ucPatientId?: Nullable<string>;
	ucProviderId?: Nullable<string>;
	ucClinicId?: Nullable<string>;
	ucPrimaryProviderId?: Nullable<string>;
	ucReferralProviderId?: Nullable<string>;
}
export class PatientRequestClass {
  protected readonly _value: PatientRequest;

	constructor(value: PatientRequest) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get ehrOrgId() {
    return this._value.ehrOrgId;
  }

  get ehrDepartmentId() {
    return this._value.ehrDepartmentId;
  }

  get ehrDepartmentIdWithDefault() {
    return this._value.ehrDepartmentId || '';
  }

  get ehrPatientId() {
    return this._value.ehrPatientId;
  }

  get ehrPatientSecondaryId() {
    return this._value.ehrPatientSecondaryId;
  }

  get ehrPatientSecondaryIdWithDefault() {
    return this._value.ehrPatientSecondaryId || '';
  }

  get ehrProviderId() {
    return this._value.ehrProviderId;
  }

  get ehrProviderSecondaryId() {
    return this._value.ehrProviderSecondaryId;
  }

  get ehrProviderSecondaryIdWithDefault() {
    return this._value.ehrProviderSecondaryId || '';
  }
}
export class ReferPatientReqBodyClass extends PatientRequestClass {
  protected readonly _value: ReferPatientReqBody;

	constructor(value: ReferPatientReqBody) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get ucPatientId() {
    return this._value.ucPatientId;
  }

  get ucPatientIdWithDefault() {
    return this._value.ucPatientId || '';
  }

  get ucProviderId() {
    return this._value.ucProviderId;
  }

  get ucProviderIdWithDefault() {
    return this._value.ucProviderId || '';
  }

  get ucClinicId() {
    return this._value.ucClinicId;
  }

  get ucClinicIdWithDefault() {
    return this._value.ucClinicId || '';
  }

  get ucPrimaryProviderId() {
    return this._value.ucPrimaryProviderId;
  }

  get ucPrimaryProviderIdWithDefault() {
    return this._value.ucPrimaryProviderId || '';
  }

  get ucReferralProviderId() {
    return this._value.ucReferralProviderId;
  }

  get ucReferralProviderIdWithDefault() {
    return this._value.ucReferralProviderId || '';
  }
}
export interface EhrReferralGetPatientFromEhrParams {
	ehr: string;
	request: PatientRequest;
}

export interface EhrReferralReferPatientParams {
	ehr: string;
	request: ReferPatientReqBody;
}

export interface EhrReferralCreateProviderMappingParams {
	ehr: string;
	request: ReferPatientReqBody;
}

export interface EhrReferralMatchReferredPatientParams {
	ehr: string;
	request: ReferPatientReqBody;
}

export class EhrReferralService {
  protected baseURL = '';

  protected basePath = '/v1/ehr';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public getPatientFromEhr({ baseUrl, params, headers }: RequestOption<EhrReferralGetPatientFromEhrParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Patient>>(`${baseUrl || this.baseURL}${this.basePath}/${params.ehr}/patient/retrieve${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public referPatient({ baseUrl, params, headers }: RequestOption<EhrReferralReferPatientParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<EhrJobRecord>>(`${baseUrl || this.baseURL}${this.basePath}/${params.ehr}/patient/refer${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public createProviderMapping({ baseUrl, params, headers }: RequestOption<EhrReferralCreateProviderMappingParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<ReferPatientReqBody>>(`${baseUrl || this.baseURL}${this.basePath}/${params.ehr}/provider/mapping${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public matchReferredPatient({ baseUrl, params, headers }: RequestOption<EhrReferralMatchReferredPatientParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.ehr}/patient/match${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const ehrReferralService = new EhrReferralService();

export const createEhrReferralGetPatientFromEhrObject = (value: APIResponse<Patient>) => new APIResponseClass<PatientClass>(value, (value: Patient) => new PatientClass(value));

export const useEhrReferralGetPatientFromEhr = <RCN = undefined>(configs: MakeRequestHookConfig<EhrReferralGetPatientFromEhrParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EhrReferralGetPatientFromEhrParams,
    APIResponse<Patient>,
    typeof ehrReferralService.getPatientFromEhr,
    AjaxOptions,
    APIResponseClass<PatientClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ehrReferralService.getPatientFromEhr>) => (
      ehrReferralService.getPatientFromEhr(...args)
    ),
    ResponseClassCreator: createEhrReferralGetPatientFromEhrObject,
    typeName: 'useEhrReferralGetPatientFromEhr',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEhrReferralGetPatientFromEhr.mock = () => new Error('Mock has not been implemented!');
useEhrReferralGetPatientFromEhr.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEhrReferralGetPatientFromEhr.createObject = createEhrReferralGetPatientFromEhrObject;

export const createEhrReferralReferPatientObject = (value: APIResponse<EhrJobRecord>) => new APIResponseClass<EhrJobRecordClass>(value, (value: EhrJobRecord) => new EhrJobRecordClass(value));

export const useEhrReferralReferPatient = <RCN = undefined>(configs: MakeRequestHookConfig<EhrReferralReferPatientParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EhrReferralReferPatientParams,
    APIResponse<EhrJobRecord>,
    typeof ehrReferralService.referPatient,
    AjaxOptions,
    APIResponseClass<EhrJobRecordClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ehrReferralService.referPatient>) => (
      ehrReferralService.referPatient(...args)
    ),
    ResponseClassCreator: createEhrReferralReferPatientObject,
    typeName: 'useEhrReferralReferPatient',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEhrReferralReferPatient.mock = () => new Error('Mock has not been implemented!');
useEhrReferralReferPatient.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEhrReferralReferPatient.createObject = createEhrReferralReferPatientObject;

export const createEhrReferralCreateProviderMappingObject = (value: APIResponse<ReferPatientReqBody>) => new APIResponseClass<ReferPatientReqBodyClass>(value, (value: ReferPatientReqBody) => new ReferPatientReqBodyClass(value));

export const useEhrReferralCreateProviderMapping = <RCN = undefined>(configs: MakeRequestHookConfig<EhrReferralCreateProviderMappingParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EhrReferralCreateProviderMappingParams,
    APIResponse<ReferPatientReqBody>,
    typeof ehrReferralService.createProviderMapping,
    AjaxOptions,
    APIResponseClass<ReferPatientReqBodyClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ehrReferralService.createProviderMapping>) => (
      ehrReferralService.createProviderMapping(...args)
    ),
    ResponseClassCreator: createEhrReferralCreateProviderMappingObject,
    typeName: 'useEhrReferralCreateProviderMapping',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEhrReferralCreateProviderMapping.mock = () => new Error('Mock has not been implemented!');
useEhrReferralCreateProviderMapping.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEhrReferralCreateProviderMapping.createObject = createEhrReferralCreateProviderMappingObject;

export const createEhrReferralMatchReferredPatientObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useEhrReferralMatchReferredPatient = <RCN = undefined>(configs: MakeRequestHookConfig<EhrReferralMatchReferredPatientParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EhrReferralMatchReferredPatientParams,
    APIResponse<string>,
    typeof ehrReferralService.matchReferredPatient,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ehrReferralService.matchReferredPatient>) => (
      ehrReferralService.matchReferredPatient(...args)
    ),
    ResponseClassCreator: createEhrReferralMatchReferredPatientObject,
    typeName: 'useEhrReferralMatchReferredPatient',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEhrReferralMatchReferredPatient.mock = () => new Error('Mock has not been implemented!');
useEhrReferralMatchReferredPatient.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEhrReferralMatchReferredPatient.createObject = createEhrReferralMatchReferredPatientObject;
export interface Capacity {
	roleType?: Nullable<RoleTypeEnum>;
	capacity?: Nullable<number>;
}
export enum UserTypeEnum {
	IHEALTH_EMPLOYEE = 'IHEALTH_EMPLOYEE',
	CLINICAL_STAFF = 'CLINICAL_STAFF',
}
export enum EmployeeStatus {
	PENDING = 'PENDING',
	ACTIVATED = 'ACTIVATED',
	DEACTIVATED = 'DEACTIVATED',
	DELETED = 'DELETED',
	priority = 'priority',
}
export interface EmployeeProfile {
	firstName?: Nullable<string>;
	lastName?: Nullable<string>;
	title?: Nullable<string>;
	language?: Nullable<string[]>;
	credentials?: Nullable<string[]>;
	degree?: Nullable<string>;
	specialties?: Nullable<string[]>;
	workState?: Nullable<string>;
	workTimezone?: Nullable<string>;
	phone?: Nullable<Phone>;
	address?: Nullable<Address>;
	NPI?: Nullable<string>;
	directAddress?: Nullable<string>;
	avatar?: Nullable<ImageSchema>;
	firstNameNear?: Nullable<string>;
	lastNameNear?: Nullable<string>;
}
export enum EmployeeLanguageEnum {
	CN = 'CN',
	EL = 'EL',
	JP = 'JP',
	LR = 'LR',
	MN = 'MN',
	SP = 'SP',
	TA = 'TA',
	TW = 'TW',
	VN = 'VN',
}
export interface Employee extends User {
	capacities?: Nullable<Capacity[]>;
	isObserverUser?: Nullable<boolean>;
	dataAnalytics?: Nullable<boolean>;
	userType?: Nullable<UserTypeEnum>;
	staffRole?: Nullable<RoleTypeEnum>;
	status?: Nullable<EmployeeStatus>;
	loginId?: Nullable<string>;
	defaultClinicId?: Nullable<string>;
	createdBy?: Nullable<string>;
	updatedBy?: Nullable<string>;
	supervisingProviderId?: Nullable<string>;
	statusPriority?: Nullable<number>;
	verified?: Nullable<boolean>;
	employeeProfile?: Nullable<EmployeeProfile>;
	email?: Nullable<string>;
	lastLoginTime?: Nullable<string>;
	duration?: Nullable<number>;
	description?: Nullable<string>;
	assignedRoles?: Nullable<RoleAssignment[]>;
	assignedClinics?: Nullable<MedicalOrganizationAssignment[]>;
	staffMedicalOrgs?: Nullable<MedicalOrganization[]>;
	emailNear?: Nullable<string>;
	firstNameNear?: Nullable<string>;
	lastNameNear?: Nullable<string>;
	nameNear?: Nullable<string>;
	statusIn?: Nullable<InFilter<EmployeeStatus>>;
	userTypeIn?: Nullable<InFilter<UserTypeEnum>>;
	languageIn?: Nullable<InFilter<EmployeeLanguageEnum>>;
	titleIn?: Nullable<InFilter<string>>;
	emailIn?: Nullable<InFilter<string>>;
	staffRoleIn?: Nullable<InFilter<RoleTypeEnum>>;
	isObserverUserNe?: Nullable<NeFilter<boolean>>;
}
export interface EmployeeFilter {
	rootId?: Nullable<string>;
	roleList?: Nullable<string[]>;
	organizationIdList?: Nullable<string[]>;
	languageList?: Nullable<string[]>;
	timezoneList?: Nullable<string[]>;
	statusList?: Nullable<string[]>;
	ucOrgIdList?: Nullable<string[]>;
	clinicIdList?: Nullable<string[]>;
	inSameCareCenter?: Nullable<boolean>;
	limitOnCallRole?: Nullable<boolean>;
	internalEmployeeForPatientId?: Nullable<string>;
	externalEmployeeForPatientId?: Nullable<string>;
	needToFilter?: Nullable<boolean>;
	internalEmployeeForMyMedicalOrg?: Nullable<boolean>;
	excludeObserverUser?: Nullable<boolean>;
	excludeNoAssignmentUser?: Nullable<boolean>;
	excludeRoleList?: Nullable<RoleTypeEnum[]>;
	nameNear?: Nullable<string>;
	emailNear?: Nullable<string>;
	reassignAssigneesClinicIdList?: Nullable<string[]>;
}
export class CapacityClass {
  protected readonly _value: Capacity;

	constructor(value: Capacity) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get roleType() {
    return this._value.roleType;
  }

  roleTypeIsIn(values: RoleTypeEnum[] = []) {
    return this._value.roleType && values.includes(this._value.roleType);
  }

  get capacity() {
    return this._value.capacity;
  }

  get capacityWithDefault() {
    return this._value.capacity || 0;
  }
}
export class EmployeeProfileClass {
  protected readonly _value: EmployeeProfile;

	constructor(value: EmployeeProfile) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get firstName() {
    return this._value.firstName;
  }

  get firstNameWithDefault() {
    return this._value.firstName || '';
  }

  get lastName() {
    return this._value.lastName;
  }

  get lastNameWithDefault() {
    return this._value.lastName || '';
  }

  get title() {
    return this._value.title;
  }

  get titleWithDefault() {
    return this._value.title || '';
  }

  get language() {
    return this._value.language;
  }

  get languageWithDefault() {
    return this._value.language || [];
  }

  get credentials() {
    return this._value.credentials;
  }

  get credentialsWithDefault() {
    return this._value.credentials || [];
  }

  get degree() {
    return this._value.degree;
  }

  get degreeWithDefault() {
    return this._value.degree || '';
  }

  get specialties() {
    return this._value.specialties;
  }

  get specialtiesWithDefault() {
    return this._value.specialties || [];
  }

  get workState() {
    return this._value.workState;
  }

  get workStateWithDefault() {
    return this._value.workState || '';
  }

  get workTimezone() {
    return this._value.workTimezone;
  }

  get workTimezoneWithDefault() {
    return this._value.workTimezone || '';
  }

  get phone() {
    return this._value.phone !== undefined && this._value.phone !== null ? new PhoneClass(this._value.phone) : this._value.phone;
  }

  get address() {
    return this._value.address !== undefined && this._value.address !== null ? new AddressClass(this._value.address) : this._value.address;
  }

  get NPI() {
    return this._value.NPI;
  }

  get NPIWithDefault() {
    return this._value.NPI || '';
  }

  get directAddress() {
    return this._value.directAddress;
  }

  get directAddressWithDefault() {
    return this._value.directAddress || '';
  }

  get avatar() {
    return this._value.avatar !== undefined && this._value.avatar !== null ? new ImageSchemaClass(this._value.avatar) : this._value.avatar;
  }

  get firstNameNear() {
    return this._value.firstNameNear;
  }

  get firstNameNearWithDefault() {
    return this._value.firstNameNear || '';
  }

  get lastNameNear() {
    return this._value.lastNameNear;
  }

  get lastNameNearWithDefault() {
    return this._value.lastNameNear || '';
  }
}
export class EmployeeClass extends UserClass {
  protected readonly _value: Employee;

	constructor(value: Employee) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get capacities() {
    return this._value.capacities !== undefined && this._value.capacities !== null ? map(this._value.capacities, (v) => new CapacityClass(v)) : this._value.capacities;
  }

  get capacitiesWithDefault() {
    return this._value.capacities || [];
  }

  get isObserverUser() {
    return this._value.isObserverUser;
  }

  get isObserverUserWithDefault() {
    return this._value.isObserverUser || false;
  }

  get dataAnalytics() {
    return this._value.dataAnalytics;
  }

  get dataAnalyticsWithDefault() {
    return this._value.dataAnalytics || false;
  }

  get userType() {
    return this._value.userType;
  }

  userTypeIsIn(values: UserTypeEnum[] = []) {
    return this._value.userType && values.includes(this._value.userType);
  }

  get staffRole() {
    return this._value.staffRole;
  }

  staffRoleIsIn(values: RoleTypeEnum[] = []) {
    return this._value.staffRole && values.includes(this._value.staffRole);
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: EmployeeStatus[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get loginId() {
    return this._value.loginId;
  }

  get loginIdWithDefault() {
    return this._value.loginId || '';
  }

  get defaultClinicId() {
    return this._value.defaultClinicId;
  }

  get createdBy() {
    return this._value.createdBy;
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get supervisingProviderId() {
    return this._value.supervisingProviderId;
  }

  get statusPriority() {
    return this._value.statusPriority;
  }

  get statusPriorityWithDefault() {
    return this._value.statusPriority || 0;
  }

  get verified() {
    return this._value.verified;
  }

  get verifiedWithDefault() {
    return this._value.verified || false;
  }

  get employeeProfile() {
    return this._value.employeeProfile !== undefined && this._value.employeeProfile !== null ? new EmployeeProfileClass(this._value.employeeProfile) : this._value.employeeProfile;
  }

  get email() {
    return this._value.email;
  }

  get emailWithDefault() {
    return this._value.email || '';
  }

  get lastLoginTime() {
    return (this._value.lastLoginTime !== undefined && this._value.lastLoginTime !== null) ? dayjs(this._value.lastLoginTime) : this._value.lastLoginTime;
  }

  get duration() {
    return this._value.duration;
  }

  get durationWithDefault() {
    return this._value.duration || 0;
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get assignedRoles() {
    return this._value.assignedRoles !== undefined && this._value.assignedRoles !== null ? map(this._value.assignedRoles, (v) => new RoleAssignmentClass(v)) : this._value.assignedRoles;
  }

  get assignedRolesWithDefault() {
    return this._value.assignedRoles || [];
  }

  get assignedClinics() {
    return this._value.assignedClinics !== undefined && this._value.assignedClinics !== null ? map(this._value.assignedClinics, (v) => new MedicalOrganizationAssignmentClass(v)) : this._value.assignedClinics;
  }

  get assignedClinicsWithDefault() {
    return this._value.assignedClinics || [];
  }

  get staffMedicalOrgs() {
    return this._value.staffMedicalOrgs !== undefined && this._value.staffMedicalOrgs !== null ? map(this._value.staffMedicalOrgs, (v) => new MedicalOrganizationClass(v)) : this._value.staffMedicalOrgs;
  }

  get staffMedicalOrgsWithDefault() {
    return this._value.staffMedicalOrgs || [];
  }

  get emailNear() {
    return this._value.emailNear;
  }

  get emailNearWithDefault() {
    return this._value.emailNear || '';
  }

  get firstNameNear() {
    return this._value.firstNameNear;
  }

  get firstNameNearWithDefault() {
    return this._value.firstNameNear || '';
  }

  get lastNameNear() {
    return this._value.lastNameNear;
  }

  get lastNameNearWithDefault() {
    return this._value.lastNameNear || '';
  }

  get nameNear() {
    return this._value.nameNear;
  }

  get nameNearWithDefault() {
    return this._value.nameNear || '';
  }

  get statusIn() {
    return this._value.statusIn !== undefined && this._value.statusIn !== null ? new InFilterClass(this._value.statusIn) : this._value.statusIn;
  }

  get userTypeIn() {
    return this._value.userTypeIn !== undefined && this._value.userTypeIn !== null ? new InFilterClass(this._value.userTypeIn) : this._value.userTypeIn;
  }

  get languageIn() {
    return this._value.languageIn !== undefined && this._value.languageIn !== null ? new InFilterClass(this._value.languageIn) : this._value.languageIn;
  }

  get titleIn() {
    return this._value.titleIn !== undefined && this._value.titleIn !== null ? new InFilterClass(this._value.titleIn) : this._value.titleIn;
  }

  get emailIn() {
    return this._value.emailIn !== undefined && this._value.emailIn !== null ? new InFilterClass(this._value.emailIn) : this._value.emailIn;
  }

  get staffRoleIn() {
    return this._value.staffRoleIn !== undefined && this._value.staffRoleIn !== null ? new InFilterClass(this._value.staffRoleIn) : this._value.staffRoleIn;
  }

  get isObserverUserNe() {
    return this._value.isObserverUserNe !== undefined && this._value.isObserverUserNe !== null ? new NeFilterClass(this._value.isObserverUserNe) : this._value.isObserverUserNe;
  }
}
export interface OnBoardTokenInput {
	token?: Nullable<string>;
}
export interface TokenVerificationResult {
	token?: Nullable<string>;
	id?: Nullable<string>;
}
export class TokenVerificationResultClass {
  protected readonly _value: TokenVerificationResult;

	constructor(value: TokenVerificationResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get token() {
    return this._value.token;
  }

  get tokenWithDefault() {
    return this._value.token || '';
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }
}
export interface SetPasswordInput {
	id?: Nullable<string>;
	newPass?: Nullable<string>;
}
export interface SetPasswordResult {
	username?: Nullable<string>;
	id?: Nullable<string>;
	token?: Nullable<string>;
	active?: Nullable<boolean>;
}
export class SetPasswordResultClass {
  protected readonly _value: SetPasswordResult;

	constructor(value: SetPasswordResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get username() {
    return this._value.username;
  }

  get usernameWithDefault() {
    return this._value.username || '';
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get token() {
    return this._value.token;
  }

  get tokenWithDefault() {
    return this._value.token || '';
  }

  get active() {
    return this._value.active;
  }

  get activeWithDefault() {
    return this._value.active || false;
  }
}
export interface ResetPasswordInput {
	token?: Nullable<string>;
	newPass?: Nullable<string>;
}
export interface EmployeeAvatarModification {
	id?: Nullable<string>;
	avatarBefore?: Nullable<ImageSchema>;
	avatarAfter?: Nullable<ImageSchema>;
}
export class EmployeeAvatarModificationClass {
  protected readonly _value: EmployeeAvatarModification;

	constructor(value: EmployeeAvatarModification) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get avatarBefore() {
    return this._value.avatarBefore !== undefined && this._value.avatarBefore !== null ? new ImageSchemaClass(this._value.avatarBefore) : this._value.avatarBefore;
  }

  get avatarAfter() {
    return this._value.avatarAfter !== undefined && this._value.avatarAfter !== null ? new ImageSchemaClass(this._value.avatarAfter) : this._value.avatarAfter;
  }
}
export interface EmployeeResponse {
	employee?: Nullable<Employee>;
	roles?: Nullable<RoleAssignment[]>;
}
export class EmployeeResponseClass {
  protected readonly _value: EmployeeResponse;

	constructor(value: EmployeeResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get employee() {
    return this._value.employee !== undefined && this._value.employee !== null ? new EmployeeClass(this._value.employee) : this._value.employee;
  }

  get roles() {
    return this._value.roles !== undefined && this._value.roles !== null ? map(this._value.roles, (v) => new RoleAssignmentClass(v)) : this._value.roles;
  }

  get rolesWithDefault() {
    return this._value.roles || [];
  }
}
export interface RoleOrgIds {
	roleType: RoleTypeEnum;
	orgIds: string[];
}
export interface EmployeeCreateRequest {
	employee?: Nullable<Employee>;
	roles?: Nullable<RoleOrgIds[]>;
}
export interface EmployeeDeactivatePrerequisites {
	no_patients?: Nullable<boolean>;
	no_tasks?: Nullable<boolean>;
	no_visits?: Nullable<boolean>;
}
export class EmployeeDeactivatePrerequisitesClass {
  protected readonly _value: EmployeeDeactivatePrerequisites;

	constructor(value: EmployeeDeactivatePrerequisites) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get no_patients() {
    return this._value.no_patients;
  }

  get no_patientsWithDefault() {
    return this._value.no_patients || false;
  }

  get no_tasks() {
    return this._value.no_tasks;
  }

  get no_tasksWithDefault() {
    return this._value.no_tasks || false;
  }

  get no_visits() {
    return this._value.no_visits;
  }

  get no_visitsWithDefault() {
    return this._value.no_visits || false;
  }
}
export interface WatchRequest {
	id?: Nullable<string>;
	note?: Nullable<string>;
}
export interface WatchPatient {
	patientId?: Nullable<string>;
	patientNote?: Nullable<string>;
	addedTime?: Nullable<string>;
}
export interface PinnedPatient {
	patientId?: Nullable<string>;
	pinnedDate?: Nullable<string>;
}
export interface EmployeeSetting extends BaseModel {
	watchPatients?: Nullable<WatchPatient[]>;
	pinnedPatientList?: Nullable<PinnedPatient[]>;
}
export class WatchPatientClass {
  protected readonly _value: WatchPatient;

	constructor(value: WatchPatient) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientId() {
    return this._value.patientId;
  }

  get patientNote() {
    return this._value.patientNote;
  }

  get patientNoteWithDefault() {
    return this._value.patientNote || '';
  }

  get addedTime() {
    return (this._value.addedTime !== undefined && this._value.addedTime !== null) ? dayjs(this._value.addedTime) : this._value.addedTime;
  }
}
export class PinnedPatientClass {
  protected readonly _value: PinnedPatient;

	constructor(value: PinnedPatient) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientId() {
    return this._value.patientId;
  }

  get pinnedDate() {
    return (this._value.pinnedDate !== undefined && this._value.pinnedDate !== null) ? dayjs(this._value.pinnedDate) : this._value.pinnedDate;
  }
}
export class EmployeeSettingClass extends BaseModelClass {
  protected readonly _value: EmployeeSetting;

	constructor(value: EmployeeSetting) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get watchPatients() {
    return this._value.watchPatients !== undefined && this._value.watchPatients !== null ? map(this._value.watchPatients, (v) => new WatchPatientClass(v)) : this._value.watchPatients;
  }

  get watchPatientsWithDefault() {
    return this._value.watchPatients || [];
  }

  get pinnedPatientList() {
    return this._value.pinnedPatientList !== undefined && this._value.pinnedPatientList !== null ? map(this._value.pinnedPatientList, (v) => new PinnedPatientClass(v)) : this._value.pinnedPatientList;
  }

  get pinnedPatientListWithDefault() {
    return this._value.pinnedPatientList || [];
  }
}
export interface PinRequest {
	patientId?: Nullable<string>;
}
export interface RoleOrganizationHierarchyResponse {
	roleType?: Nullable<RoleTypeEnum>;
	careGroupId?: Nullable<string>;
	careCenterId?: Nullable<string>;
	careDivisionId?: Nullable<string>;
	rootId?: Nullable<string>;
	careGroupName?: Nullable<string>;
	careCenterName?: Nullable<string>;
	careDivisionName?: Nullable<string>;
	rootName?: Nullable<string>;
}
export class RoleOrganizationHierarchyResponseClass {
  protected readonly _value: RoleOrganizationHierarchyResponse;

	constructor(value: RoleOrganizationHierarchyResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get roleType() {
    return this._value.roleType;
  }

  roleTypeIsIn(values: RoleTypeEnum[] = []) {
    return this._value.roleType && values.includes(this._value.roleType);
  }

  get careGroupId() {
    return this._value.careGroupId;
  }

  get careCenterId() {
    return this._value.careCenterId;
  }

  get careDivisionId() {
    return this._value.careDivisionId;
  }

  get rootId() {
    return this._value.rootId;
  }

  get careGroupName() {
    return this._value.careGroupName;
  }

  get careGroupNameWithDefault() {
    return this._value.careGroupName || '';
  }

  get careCenterName() {
    return this._value.careCenterName;
  }

  get careCenterNameWithDefault() {
    return this._value.careCenterName || '';
  }

  get careDivisionName() {
    return this._value.careDivisionName;
  }

  get careDivisionNameWithDefault() {
    return this._value.careDivisionName || '';
  }

  get rootName() {
    return this._value.rootName;
  }

  get rootNameWithDefault() {
    return this._value.rootName || '';
  }
}
export interface BillingSupervisorRequest {
	employee?: Nullable<Employee>;
	clinicIds?: Nullable<string[]>;
}
export interface ResendEmailInput {
	id?: Nullable<string>;
	email?: Nullable<string>;
}
export interface ClinicProviderAssignment {
	providerIds?: Nullable<string[]>;
	clinicId?: Nullable<string>;
}
export interface AssignorRequest {
	memberId?: Nullable<string>;
	clinicProviderAssignmentList?: Nullable<ClinicProviderAssignment[]>;
}
export interface MedicalAssistantAssignor extends BaseModel {
	memberId?: Nullable<string>;
	clinicId?: Nullable<string>;
	providerIds?: Nullable<string[]>;
	active?: Nullable<boolean>;
}
export class MedicalAssistantAssignorClass extends BaseModelClass {
  protected readonly _value: MedicalAssistantAssignor;

	constructor(value: MedicalAssistantAssignor) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get clinicId() {
    return this._value.clinicId;
  }

  get providerIds() {
    return this._value.providerIds;
  }

  get providerIdsWithDefault() {
    return this._value.providerIds || [];
  }

  get active() {
    return this._value.active;
  }

  get activeWithDefault() {
    return this._value.active || false;
  }
}
export interface MiniPersonRequest {
	idList?: Nullable<string[]>;
}
export interface MiniPersonResponse {
	id?: Nullable<string>;
	firstName?: Nullable<string>;
	lastName?: Nullable<string>;
	fileKey?: Nullable<string>;
}
export class MiniPersonResponseClass {
  protected readonly _value: MiniPersonResponse;

	constructor(value: MiniPersonResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get firstName() {
    return this._value.firstName;
  }

  get firstNameWithDefault() {
    return this._value.firstName || '';
  }

  get lastName() {
    return this._value.lastName;
  }

  get lastNameWithDefault() {
    return this._value.lastName || '';
  }

  get fileKey() {
    return this._value.fileKey;
  }

  get fileKeyWithDefault() {
    return this._value.fileKey || '';
  }
}
export interface EmployeeGetIhealthEmployeeMedicalOrganizationAccessParams {
	id: string;
}

export interface EmployeeSearchEmployeesParams {
	searchRequest: SearchRequest<EmployeeFilter>;
}

export interface EmployeeVerifyOnBoardTokenParams {
	token: OnBoardTokenInput;
}

export interface EmployeeSetUserPasswordParams {
	spInput: SetPasswordInput;
}

export interface EmployeeResetPasswordParams {
	rpInput: ResetPasswordInput;
}

export interface EmployeeAvatarDownloadUrlParams {
	avatarDownloadUrlInput: AvatarDownloadUrlInput;
}

export interface EmployeeAvatarDeleteParams {
	id: string;
}

export interface EmployeeGetEmployeeWithRolesParams {
	id: string;
}

export interface EmployeeUpdateWithRoleAndReturnParams {
	id: string;
	employeeCreateRequest: EmployeeCreateRequest;
}

export interface EmployeeDeactivateEmployeeParams {
	id: string;
}

export interface EmployeeReactivateEmployeeParams {
	id: string;
}

export interface EmployeeDeactivatePrerequisitesParams {
	id: string;
}

export interface EmployeeAvatarUploadParams {
	id: string;
	photo: MultipartFile;
}

export interface EmployeeAddToWatchParams {
	addRequest: WatchRequest;
}

export interface EmployeeRemoveFromWatchParams {
	removeRequest: WatchRequest;
}

export interface EmployeePinPatientParams {
	pinRequest: PinRequest;
}

export interface EmployeeUnpinPatientParams {
	unpinRequest: PinRequest;
}

export interface EmployeeSearchAssociatedIhealthEmployeeByMedOrgIdParams {
	medOrgId: string;
}

export interface EmployeeSearchClinicalStaffParams {
	searchRequest: SearchRequest<EmployeeFilter>;
}

export interface EmployeeGetIhealthEmployeeRoleWithOrganizationHierarchyParams {
	id: string;
}

export interface EmployeeSearchBillingSupervisorParams {
	request: BillingSupervisorRequest;
}

export interface EmployeeCreateEmployeeParams {
	employeeCreateRequest: EmployeeCreateRequest;
}

export interface EmployeeCreateEmployeeWithRolesParams {
	employeeCreateRequest: EmployeeCreateRequest;
}

export interface EmployeeResendOnBoardEmailParams {
	resendEmailInput: ResendEmailInput;
}

export interface EmployeeForgotPasswordEmailParams {
	resendEmailInput: ResendEmailInput;
}

export interface EmployeeCoworkersParams {
	searchRequest: SearchRequest<EmployeeFilter>;
}

export interface EmployeeSearchUserParams {
	searchRequest: SearchRequest<EmployeeFilter>;
}

export interface EmployeeSearchIhealthEmployeeParams {
	searchRequest: SearchRequest<EmployeeFilter>;
}

export interface EmployeeUpsertMedicalAssistantAssignorParams {
	request: AssignorRequest;
}

export interface EmployeeMiniEmployeeListParams {
	request: MiniPersonRequest;
}

export class EmployeeService extends BaseController<Employee, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/employees';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getIhealthEmployeeMedicalOrganizationAccess({ baseUrl, params, headers }: RequestOption<EmployeeGetIhealthEmployeeMedicalOrganizationAccessParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<MedicalOrganizationAssignment[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/medical-organization-access${getQueryStrings(queryList)}`, {}, { headers });
	}

	public searchEmployees({ baseUrl, params, headers }: RequestOption<EmployeeSearchEmployeesParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<Employee>>>(`${baseUrl || this.baseURL}${this.basePath}/filter${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public verifyOnBoardToken({ baseUrl, params, headers }: RequestOption<EmployeeVerifyOnBoardTokenParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<TokenVerificationResult>>(`${baseUrl || this.baseURL}${this.basePath}/verifyOnBoardToken${getQueryStrings(queryList)}`, params.token, { headers });
	}

	public setUserPassword({ baseUrl, params, headers }: RequestOption<EmployeeSetUserPasswordParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<SetPasswordResult>>(`${baseUrl || this.baseURL}${this.basePath}/setUserPassword${getQueryStrings(queryList)}`, params.spInput, { headers });
	}

	public resetPassword({ baseUrl, params, headers }: RequestOption<EmployeeResetPasswordParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<TokenVerificationResult>>(`${baseUrl || this.baseURL}${this.basePath}/resetPassword${getQueryStrings(queryList)}`, params.rpInput, { headers });
	}

	public avatarDownloadUrl({ baseUrl, params, headers }: RequestOption<EmployeeAvatarDownloadUrlParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<AvatarDownloadUrlOutput>>(`${baseUrl || this.baseURL}${this.basePath}/avatar/download-url${getQueryStrings(queryList)}`, params.avatarDownloadUrlInput, { headers });
	}

	public avatarDelete({ baseUrl, params, headers }: RequestOption<EmployeeAvatarDeleteParams>) {
		const queryList: QueryParam[] = [];
		return axios.delete<APIResponse<EmployeeAvatarModification>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/avatar${getQueryStrings(queryList)}`, { headers });
	}

	public getEmployeeWithRoles({ baseUrl, params, headers }: RequestOption<EmployeeGetEmployeeWithRolesParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<EmployeeResponse>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/with-roles${getQueryStrings(queryList)}`, { headers });
	}

	public updateWithRoleAndReturn({ baseUrl, params, headers }: RequestOption<EmployeeUpdateWithRoleAndReturnParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<EmployeeResponse>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/with-roles${getQueryStrings(queryList)}`, params.employeeCreateRequest, { headers });
	}

	public deactivateEmployee({ baseUrl, params, headers }: RequestOption<EmployeeDeactivateEmployeeParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/deactivate${getQueryStrings(queryList)}`, {}, { headers });
	}

	public reactivateEmployee({ baseUrl, params, headers }: RequestOption<EmployeeReactivateEmployeeParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/reactivate${getQueryStrings(queryList)}`, {}, { headers });
	}

	public deactivatePrerequisites({ baseUrl, params, headers }: RequestOption<EmployeeDeactivatePrerequisitesParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<EmployeeDeactivatePrerequisites>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/deactivate-prerequisites${getQueryStrings(queryList)}`, { headers });
	}

	public avatarUpload({ baseUrl, params, headers }: RequestOption<EmployeeAvatarUploadParams>) {
		const queryList: QueryParam[] = [];
		if (params.photo !== undefined && params.photo !== null) {
			queryList.push({ name: 'photo', value: params.photo.toString() });
		}
		return axios.put<APIResponse<EmployeeAvatarModification>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/avatar${getQueryStrings(queryList)}`, {}, { headers });
	}

	public addToWatch({ baseUrl, params, headers }: RequestOption<EmployeeAddToWatchParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<EmployeeSetting>>(`${baseUrl || this.baseURL}${this.basePath}/add-to-watch${getQueryStrings(queryList)}`, params.addRequest, { headers });
	}

	public removeFromWatch({ baseUrl, params, headers }: RequestOption<EmployeeRemoveFromWatchParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<EmployeeSetting>>(`${baseUrl || this.baseURL}${this.basePath}/remove-from-watch${getQueryStrings(queryList)}`, params.removeRequest, { headers });
	}

	public pinPatient({ baseUrl, params, headers }: RequestOption<EmployeePinPatientParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<EmployeeSetting>>(`${baseUrl || this.baseURL}${this.basePath}/pin-patient${getQueryStrings(queryList)}`, params.pinRequest, { headers });
	}

	public unpinPatient({ baseUrl, params, headers }: RequestOption<EmployeeUnpinPatientParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<EmployeeSetting>>(`${baseUrl || this.baseURL}${this.basePath}/unpin-patient${getQueryStrings(queryList)}`, params.unpinRequest, { headers });
	}

	public searchAssociatedIhealthEmployeeByMedOrgId({ baseUrl, params, headers }: RequestOption<EmployeeSearchAssociatedIhealthEmployeeByMedOrgIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Employee[]>>(`${baseUrl || this.baseURL}${this.basePath}/medical-org-associates/${params.medOrgId}${getQueryStrings(queryList)}`, {}, { headers });
	}

	public searchClinicalStaff({ baseUrl, params, headers }: RequestOption<EmployeeSearchClinicalStaffParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<Employee>>>(`${baseUrl || this.baseURL}${this.basePath}/search-clinical-staff${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getIhealthEmployeeRoleWithOrganizationHierarchy({ baseUrl, params, headers }: RequestOption<EmployeeGetIhealthEmployeeRoleWithOrganizationHierarchyParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<RoleOrganizationHierarchyResponse[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/ihealth-role-organization-hierarchy${getQueryStrings(queryList)}`, {}, { headers });
	}

	public searchBillingSupervisor({ baseUrl, params, headers }: RequestOption<EmployeeSearchBillingSupervisorParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Employee[]>>(`${baseUrl || this.baseURL}${this.basePath}/search-billing-supervisor${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public createEmployee({ baseUrl, params, headers }: RequestOption<EmployeeCreateEmployeeParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Employee>>(`${baseUrl || this.baseURL}${this.basePath}/createUser${getQueryStrings(queryList)}`, params.employeeCreateRequest, { headers });
	}

	public createEmployeeWithRoles({ baseUrl, params, headers }: RequestOption<EmployeeCreateEmployeeWithRolesParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<EmployeeResponse>>(`${baseUrl || this.baseURL}${this.basePath}/create-with-roles${getQueryStrings(queryList)}`, params.employeeCreateRequest, { headers });
	}

	public resendOnBoardEmail({ baseUrl, params, headers }: RequestOption<EmployeeResendOnBoardEmailParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/resendOnBoardEmail${getQueryStrings(queryList)}`, params.resendEmailInput, { headers });
	}

	public forgotPasswordEmail({ baseUrl, params, headers }: RequestOption<EmployeeForgotPasswordEmailParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/forgotPasswordEmail${getQueryStrings(queryList)}`, params.resendEmailInput, { headers });
	}

	public coworkers({ baseUrl, params, headers }: RequestOption<EmployeeCoworkersParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Employee[]>>(`${baseUrl || this.baseURL}${this.basePath}/coworkers${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public searchUser({ baseUrl, params, headers }: RequestOption<EmployeeSearchUserParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<LimitedUser>>>(`${baseUrl || this.baseURL}${this.basePath}/mini-user/search${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public searchIhealthEmployee({ baseUrl, params, headers }: RequestOption<EmployeeSearchIhealthEmployeeParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<Employee>>>(`${baseUrl || this.baseURL}${this.basePath}/search-ihealth-employee${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public upsertMedicalAssistantAssignor({ baseUrl, params, headers }: RequestOption<EmployeeUpsertMedicalAssistantAssignorParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<MedicalAssistantAssignor[]>>(`${baseUrl || this.baseURL}${this.basePath}medical-assistant-assignors${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public miniEmployeeList({ baseUrl, params, headers }: RequestOption<EmployeeMiniEmployeeListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<MiniPersonResponse[]>>(`${baseUrl || this.baseURL}${this.basePath}/mini-employee-list${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const employeeService = new EmployeeService();

export const createEmployeeGetIhealthEmployeeMedicalOrganizationAccessObject = (value: APIResponse<MedicalOrganizationAssignment[]>) => new APIResponseClass<MedicalOrganizationAssignmentClass[]>(value, (v) => map(v, (value: MedicalOrganizationAssignment) => new MedicalOrganizationAssignmentClass(value)));

export const useEmployeeGetIhealthEmployeeMedicalOrganizationAccess = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeGetIhealthEmployeeMedicalOrganizationAccessParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeGetIhealthEmployeeMedicalOrganizationAccessParams,
    APIResponse<MedicalOrganizationAssignment[]>,
    typeof employeeService.getIhealthEmployeeMedicalOrganizationAccess,
    AjaxOptions,
    APIResponseClass<MedicalOrganizationAssignmentClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.getIhealthEmployeeMedicalOrganizationAccess>) => (
      employeeService.getIhealthEmployeeMedicalOrganizationAccess(...args)
    ),
    ResponseClassCreator: createEmployeeGetIhealthEmployeeMedicalOrganizationAccessObject,
    typeName: 'useEmployeeGetIhealthEmployeeMedicalOrganizationAccess',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeGetIhealthEmployeeMedicalOrganizationAccess.mock = () => new Error('Mock has not been implemented!');
useEmployeeGetIhealthEmployeeMedicalOrganizationAccess.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeGetIhealthEmployeeMedicalOrganizationAccess.createObject = createEmployeeGetIhealthEmployeeMedicalOrganizationAccessObject;

export const createEmployeeSearchEmployeesObject = (value: APIResponse<PageResponse<Employee>>) => new APIResponseClass<PageResponseClass<EmployeeClass>>(value, (value: PageResponse<Employee>) => new PageResponseClass<EmployeeClass>(value, (value: Employee) => new EmployeeClass(value)));

export const useEmployeeSearchEmployees = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeSearchEmployeesParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeSearchEmployeesParams,
    APIResponse<PageResponse<Employee>>,
    typeof employeeService.searchEmployees,
    AjaxOptions,
    APIResponseClass<PageResponseClass<EmployeeClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.searchEmployees>) => (
      employeeService.searchEmployees(...args)
    ),
    ResponseClassCreator: createEmployeeSearchEmployeesObject,
    typeName: 'useEmployeeSearchEmployees',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeSearchEmployees.mock = () => new Error('Mock has not been implemented!');
useEmployeeSearchEmployees.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeSearchEmployees.createObject = createEmployeeSearchEmployeesObject;

export const createEmployeeVerifyOnBoardTokenObject = (value: APIResponse<TokenVerificationResult>) => new APIResponseClass<TokenVerificationResultClass>(value, (value: TokenVerificationResult) => new TokenVerificationResultClass(value));

export const useEmployeeVerifyOnBoardToken = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeVerifyOnBoardTokenParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeVerifyOnBoardTokenParams,
    APIResponse<TokenVerificationResult>,
    typeof employeeService.verifyOnBoardToken,
    AjaxOptions,
    APIResponseClass<TokenVerificationResultClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.verifyOnBoardToken>) => (
      employeeService.verifyOnBoardToken(...args)
    ),
    ResponseClassCreator: createEmployeeVerifyOnBoardTokenObject,
    typeName: 'useEmployeeVerifyOnBoardToken',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeVerifyOnBoardToken.mock = () => new Error('Mock has not been implemented!');
useEmployeeVerifyOnBoardToken.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeVerifyOnBoardToken.createObject = createEmployeeVerifyOnBoardTokenObject;

export const createEmployeeSetUserPasswordObject = (value: APIResponse<SetPasswordResult>) => new APIResponseClass<SetPasswordResultClass>(value, (value: SetPasswordResult) => new SetPasswordResultClass(value));

export const useEmployeeSetUserPassword = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeSetUserPasswordParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeSetUserPasswordParams,
    APIResponse<SetPasswordResult>,
    typeof employeeService.setUserPassword,
    AjaxOptions,
    APIResponseClass<SetPasswordResultClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.setUserPassword>) => (
      employeeService.setUserPassword(...args)
    ),
    ResponseClassCreator: createEmployeeSetUserPasswordObject,
    typeName: 'useEmployeeSetUserPassword',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeSetUserPassword.mock = () => new Error('Mock has not been implemented!');
useEmployeeSetUserPassword.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeSetUserPassword.createObject = createEmployeeSetUserPasswordObject;

export const createEmployeeResetPasswordObject = (value: APIResponse<TokenVerificationResult>) => new APIResponseClass<TokenVerificationResultClass>(value, (value: TokenVerificationResult) => new TokenVerificationResultClass(value));

export const useEmployeeResetPassword = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeResetPasswordParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeResetPasswordParams,
    APIResponse<TokenVerificationResult>,
    typeof employeeService.resetPassword,
    AjaxOptions,
    APIResponseClass<TokenVerificationResultClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.resetPassword>) => (
      employeeService.resetPassword(...args)
    ),
    ResponseClassCreator: createEmployeeResetPasswordObject,
    typeName: 'useEmployeeResetPassword',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeResetPassword.mock = () => new Error('Mock has not been implemented!');
useEmployeeResetPassword.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeResetPassword.createObject = createEmployeeResetPasswordObject;

export const createEmployeeAvatarDownloadUrlObject = (value: APIResponse<AvatarDownloadUrlOutput>) => new APIResponseClass<AvatarDownloadUrlOutputClass>(value, (value: AvatarDownloadUrlOutput) => new AvatarDownloadUrlOutputClass(value));

export const useEmployeeAvatarDownloadUrl = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeAvatarDownloadUrlParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeAvatarDownloadUrlParams,
    APIResponse<AvatarDownloadUrlOutput>,
    typeof employeeService.avatarDownloadUrl,
    AjaxOptions,
    APIResponseClass<AvatarDownloadUrlOutputClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.avatarDownloadUrl>) => (
      employeeService.avatarDownloadUrl(...args)
    ),
    ResponseClassCreator: createEmployeeAvatarDownloadUrlObject,
    typeName: 'useEmployeeAvatarDownloadUrl',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeAvatarDownloadUrl.mock = () => new Error('Mock has not been implemented!');
useEmployeeAvatarDownloadUrl.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeAvatarDownloadUrl.createObject = createEmployeeAvatarDownloadUrlObject;

export const createEmployeeAvatarDeleteObject = (value: APIResponse<EmployeeAvatarModification>) => new APIResponseClass<EmployeeAvatarModificationClass>(value, (value: EmployeeAvatarModification) => new EmployeeAvatarModificationClass(value));

export const useEmployeeAvatarDelete = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeAvatarDeleteParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeAvatarDeleteParams,
    APIResponse<EmployeeAvatarModification>,
    typeof employeeService.avatarDelete,
    AjaxOptions,
    APIResponseClass<EmployeeAvatarModificationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.avatarDelete>) => (
      employeeService.avatarDelete(...args)
    ),
    ResponseClassCreator: createEmployeeAvatarDeleteObject,
    typeName: 'useEmployeeAvatarDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeAvatarDelete.mock = () => new Error('Mock has not been implemented!');
useEmployeeAvatarDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeAvatarDelete.createObject = createEmployeeAvatarDeleteObject;

export const createEmployeeGetEmployeeWithRolesObject = (value: APIResponse<EmployeeResponse>) => new APIResponseClass<EmployeeResponseClass>(value, (value: EmployeeResponse) => new EmployeeResponseClass(value));

export const useEmployeeGetEmployeeWithRoles = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeGetEmployeeWithRolesParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeGetEmployeeWithRolesParams,
    APIResponse<EmployeeResponse>,
    typeof employeeService.getEmployeeWithRoles,
    GetOptions,
    APIResponseClass<EmployeeResponseClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof employeeService.getEmployeeWithRoles>) => (
      employeeService.getEmployeeWithRoles(...args)
    ),
    ResponseClassCreator: createEmployeeGetEmployeeWithRolesObject,
    typeName: 'useEmployeeGetEmployeeWithRoles',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeGetEmployeeWithRoles.mock = () => new Error('Mock has not been implemented!');
useEmployeeGetEmployeeWithRoles.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeGetEmployeeWithRoles.createObject = createEmployeeGetEmployeeWithRolesObject;

export const createEmployeeUpdateWithRoleAndReturnObject = (value: APIResponse<EmployeeResponse>) => new APIResponseClass<EmployeeResponseClass>(value, (value: EmployeeResponse) => new EmployeeResponseClass(value));

export const useEmployeeUpdateWithRoleAndReturn = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeUpdateWithRoleAndReturnParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeUpdateWithRoleAndReturnParams,
    APIResponse<EmployeeResponse>,
    typeof employeeService.updateWithRoleAndReturn,
    AjaxOptions,
    APIResponseClass<EmployeeResponseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.updateWithRoleAndReturn>) => (
      employeeService.updateWithRoleAndReturn(...args)
    ),
    ResponseClassCreator: createEmployeeUpdateWithRoleAndReturnObject,
    typeName: 'useEmployeeUpdateWithRoleAndReturn',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeUpdateWithRoleAndReturn.mock = () => new Error('Mock has not been implemented!');
useEmployeeUpdateWithRoleAndReturn.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeUpdateWithRoleAndReturn.createObject = createEmployeeUpdateWithRoleAndReturnObject;

export const createEmployeeDeactivateEmployeeObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useEmployeeDeactivateEmployee = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeDeactivateEmployeeParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeDeactivateEmployeeParams,
    APIResponse<string>,
    typeof employeeService.deactivateEmployee,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.deactivateEmployee>) => (
      employeeService.deactivateEmployee(...args)
    ),
    ResponseClassCreator: createEmployeeDeactivateEmployeeObject,
    typeName: 'useEmployeeDeactivateEmployee',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeDeactivateEmployee.mock = () => new Error('Mock has not been implemented!');
useEmployeeDeactivateEmployee.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeDeactivateEmployee.createObject = createEmployeeDeactivateEmployeeObject;

export const createEmployeeReactivateEmployeeObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useEmployeeReactivateEmployee = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeReactivateEmployeeParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeReactivateEmployeeParams,
    APIResponse<string>,
    typeof employeeService.reactivateEmployee,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.reactivateEmployee>) => (
      employeeService.reactivateEmployee(...args)
    ),
    ResponseClassCreator: createEmployeeReactivateEmployeeObject,
    typeName: 'useEmployeeReactivateEmployee',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeReactivateEmployee.mock = () => new Error('Mock has not been implemented!');
useEmployeeReactivateEmployee.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeReactivateEmployee.createObject = createEmployeeReactivateEmployeeObject;

export const createEmployeeDeactivatePrerequisitesObject = (value: APIResponse<EmployeeDeactivatePrerequisites>) => new APIResponseClass<EmployeeDeactivatePrerequisitesClass>(value, (value: EmployeeDeactivatePrerequisites) => new EmployeeDeactivatePrerequisitesClass(value));

export const useEmployeeDeactivatePrerequisites = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeDeactivatePrerequisitesParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeDeactivatePrerequisitesParams,
    APIResponse<EmployeeDeactivatePrerequisites>,
    typeof employeeService.deactivatePrerequisites,
    GetOptions,
    APIResponseClass<EmployeeDeactivatePrerequisitesClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof employeeService.deactivatePrerequisites>) => (
      employeeService.deactivatePrerequisites(...args)
    ),
    ResponseClassCreator: createEmployeeDeactivatePrerequisitesObject,
    typeName: 'useEmployeeDeactivatePrerequisites',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeDeactivatePrerequisites.mock = () => new Error('Mock has not been implemented!');
useEmployeeDeactivatePrerequisites.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeDeactivatePrerequisites.createObject = createEmployeeDeactivatePrerequisitesObject;

export const createEmployeeAvatarUploadObject = (value: APIResponse<EmployeeAvatarModification>) => new APIResponseClass<EmployeeAvatarModificationClass>(value, (value: EmployeeAvatarModification) => new EmployeeAvatarModificationClass(value));

export const useEmployeeAvatarUpload = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeAvatarUploadParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeAvatarUploadParams,
    APIResponse<EmployeeAvatarModification>,
    typeof employeeService.avatarUpload,
    AjaxOptions,
    APIResponseClass<EmployeeAvatarModificationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.avatarUpload>) => (
      employeeService.avatarUpload(...args)
    ),
    ResponseClassCreator: createEmployeeAvatarUploadObject,
    typeName: 'useEmployeeAvatarUpload',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeAvatarUpload.mock = () => new Error('Mock has not been implemented!');
useEmployeeAvatarUpload.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeAvatarUpload.createObject = createEmployeeAvatarUploadObject;

export const createEmployeeAddToWatchObject = (value: APIResponse<EmployeeSetting>) => new APIResponseClass<EmployeeSettingClass>(value, (value: EmployeeSetting) => new EmployeeSettingClass(value));

export const useEmployeeAddToWatch = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeAddToWatchParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeAddToWatchParams,
    APIResponse<EmployeeSetting>,
    typeof employeeService.addToWatch,
    AjaxOptions,
    APIResponseClass<EmployeeSettingClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.addToWatch>) => (
      employeeService.addToWatch(...args)
    ),
    ResponseClassCreator: createEmployeeAddToWatchObject,
    typeName: 'useEmployeeAddToWatch',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeAddToWatch.mock = () => new Error('Mock has not been implemented!');
useEmployeeAddToWatch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeAddToWatch.createObject = createEmployeeAddToWatchObject;

export const createEmployeeRemoveFromWatchObject = (value: APIResponse<EmployeeSetting>) => new APIResponseClass<EmployeeSettingClass>(value, (value: EmployeeSetting) => new EmployeeSettingClass(value));

export const useEmployeeRemoveFromWatch = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeRemoveFromWatchParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeRemoveFromWatchParams,
    APIResponse<EmployeeSetting>,
    typeof employeeService.removeFromWatch,
    AjaxOptions,
    APIResponseClass<EmployeeSettingClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.removeFromWatch>) => (
      employeeService.removeFromWatch(...args)
    ),
    ResponseClassCreator: createEmployeeRemoveFromWatchObject,
    typeName: 'useEmployeeRemoveFromWatch',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeRemoveFromWatch.mock = () => new Error('Mock has not been implemented!');
useEmployeeRemoveFromWatch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeRemoveFromWatch.createObject = createEmployeeRemoveFromWatchObject;

export const createEmployeePinPatientObject = (value: APIResponse<EmployeeSetting>) => new APIResponseClass<EmployeeSettingClass>(value, (value: EmployeeSetting) => new EmployeeSettingClass(value));

export const useEmployeePinPatient = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeePinPatientParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeePinPatientParams,
    APIResponse<EmployeeSetting>,
    typeof employeeService.pinPatient,
    AjaxOptions,
    APIResponseClass<EmployeeSettingClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.pinPatient>) => (
      employeeService.pinPatient(...args)
    ),
    ResponseClassCreator: createEmployeePinPatientObject,
    typeName: 'useEmployeePinPatient',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeePinPatient.mock = () => new Error('Mock has not been implemented!');
useEmployeePinPatient.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeePinPatient.createObject = createEmployeePinPatientObject;

export const createEmployeeUnpinPatientObject = (value: APIResponse<EmployeeSetting>) => new APIResponseClass<EmployeeSettingClass>(value, (value: EmployeeSetting) => new EmployeeSettingClass(value));

export const useEmployeeUnpinPatient = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeUnpinPatientParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeUnpinPatientParams,
    APIResponse<EmployeeSetting>,
    typeof employeeService.unpinPatient,
    AjaxOptions,
    APIResponseClass<EmployeeSettingClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.unpinPatient>) => (
      employeeService.unpinPatient(...args)
    ),
    ResponseClassCreator: createEmployeeUnpinPatientObject,
    typeName: 'useEmployeeUnpinPatient',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeUnpinPatient.mock = () => new Error('Mock has not been implemented!');
useEmployeeUnpinPatient.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeUnpinPatient.createObject = createEmployeeUnpinPatientObject;

export const createEmployeeSearchAssociatedIhealthEmployeeByMedOrgIdObject = (value: APIResponse<Employee[]>) => new APIResponseClass<EmployeeClass[]>(value, (v) => map(v, (value: Employee) => new EmployeeClass(value)));

export const useEmployeeSearchAssociatedIhealthEmployeeByMedOrgId = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeSearchAssociatedIhealthEmployeeByMedOrgIdParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeSearchAssociatedIhealthEmployeeByMedOrgIdParams,
    APIResponse<Employee[]>,
    typeof employeeService.searchAssociatedIhealthEmployeeByMedOrgId,
    AjaxOptions,
    APIResponseClass<EmployeeClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.searchAssociatedIhealthEmployeeByMedOrgId>) => (
      employeeService.searchAssociatedIhealthEmployeeByMedOrgId(...args)
    ),
    ResponseClassCreator: createEmployeeSearchAssociatedIhealthEmployeeByMedOrgIdObject,
    typeName: 'useEmployeeSearchAssociatedIhealthEmployeeByMedOrgId',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeSearchAssociatedIhealthEmployeeByMedOrgId.mock = () => new Error('Mock has not been implemented!');
useEmployeeSearchAssociatedIhealthEmployeeByMedOrgId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeSearchAssociatedIhealthEmployeeByMedOrgId.createObject = createEmployeeSearchAssociatedIhealthEmployeeByMedOrgIdObject;

export const createEmployeeSearchClinicalStaffObject = (value: APIResponse<PageResponse<Employee>>) => new APIResponseClass<PageResponseClass<EmployeeClass>>(value, (value: PageResponse<Employee>) => new PageResponseClass<EmployeeClass>(value, (value: Employee) => new EmployeeClass(value)));

export const useEmployeeSearchClinicalStaff = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeSearchClinicalStaffParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeSearchClinicalStaffParams,
    APIResponse<PageResponse<Employee>>,
    typeof employeeService.searchClinicalStaff,
    AjaxOptions,
    APIResponseClass<PageResponseClass<EmployeeClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.searchClinicalStaff>) => (
      employeeService.searchClinicalStaff(...args)
    ),
    ResponseClassCreator: createEmployeeSearchClinicalStaffObject,
    typeName: 'useEmployeeSearchClinicalStaff',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeSearchClinicalStaff.mock = () => new Error('Mock has not been implemented!');
useEmployeeSearchClinicalStaff.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeSearchClinicalStaff.createObject = createEmployeeSearchClinicalStaffObject;

export const createEmployeeGetIhealthEmployeeRoleWithOrganizationHierarchyObject = (value: APIResponse<RoleOrganizationHierarchyResponse[]>) => new APIResponseClass<RoleOrganizationHierarchyResponseClass[]>(value, (v) => map(v, (value: RoleOrganizationHierarchyResponse) => new RoleOrganizationHierarchyResponseClass(value)));

export const useEmployeeGetIhealthEmployeeRoleWithOrganizationHierarchy = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeGetIhealthEmployeeRoleWithOrganizationHierarchyParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeGetIhealthEmployeeRoleWithOrganizationHierarchyParams,
    APIResponse<RoleOrganizationHierarchyResponse[]>,
    typeof employeeService.getIhealthEmployeeRoleWithOrganizationHierarchy,
    AjaxOptions,
    APIResponseClass<RoleOrganizationHierarchyResponseClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.getIhealthEmployeeRoleWithOrganizationHierarchy>) => (
      employeeService.getIhealthEmployeeRoleWithOrganizationHierarchy(...args)
    ),
    ResponseClassCreator: createEmployeeGetIhealthEmployeeRoleWithOrganizationHierarchyObject,
    typeName: 'useEmployeeGetIhealthEmployeeRoleWithOrganizationHierarchy',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeGetIhealthEmployeeRoleWithOrganizationHierarchy.mock = () => new Error('Mock has not been implemented!');
useEmployeeGetIhealthEmployeeRoleWithOrganizationHierarchy.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeGetIhealthEmployeeRoleWithOrganizationHierarchy.createObject = createEmployeeGetIhealthEmployeeRoleWithOrganizationHierarchyObject;

export const createEmployeeSearchBillingSupervisorObject = (value: APIResponse<Employee[]>) => new APIResponseClass<EmployeeClass[]>(value, (v) => map(v, (value: Employee) => new EmployeeClass(value)));

export const useEmployeeSearchBillingSupervisor = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeSearchBillingSupervisorParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeSearchBillingSupervisorParams,
    APIResponse<Employee[]>,
    typeof employeeService.searchBillingSupervisor,
    AjaxOptions,
    APIResponseClass<EmployeeClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.searchBillingSupervisor>) => (
      employeeService.searchBillingSupervisor(...args)
    ),
    ResponseClassCreator: createEmployeeSearchBillingSupervisorObject,
    typeName: 'useEmployeeSearchBillingSupervisor',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeSearchBillingSupervisor.mock = () => new Error('Mock has not been implemented!');
useEmployeeSearchBillingSupervisor.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeSearchBillingSupervisor.createObject = createEmployeeSearchBillingSupervisorObject;

export const createEmployeeCreateEmployeeObject = (value: APIResponse<Employee>) => new APIResponseClass<EmployeeClass>(value, (value: Employee) => new EmployeeClass(value));

export const useEmployeeCreateEmployee = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeCreateEmployeeParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeCreateEmployeeParams,
    APIResponse<Employee>,
    typeof employeeService.createEmployee,
    AjaxOptions,
    APIResponseClass<EmployeeClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.createEmployee>) => (
      employeeService.createEmployee(...args)
    ),
    ResponseClassCreator: createEmployeeCreateEmployeeObject,
    typeName: 'useEmployeeCreateEmployee',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeCreateEmployee.mock = () => new Error('Mock has not been implemented!');
useEmployeeCreateEmployee.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeCreateEmployee.createObject = createEmployeeCreateEmployeeObject;

export const createEmployeeCreateEmployeeWithRolesObject = (value: APIResponse<EmployeeResponse>) => new APIResponseClass<EmployeeResponseClass>(value, (value: EmployeeResponse) => new EmployeeResponseClass(value));

export const useEmployeeCreateEmployeeWithRoles = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeCreateEmployeeWithRolesParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeCreateEmployeeWithRolesParams,
    APIResponse<EmployeeResponse>,
    typeof employeeService.createEmployeeWithRoles,
    AjaxOptions,
    APIResponseClass<EmployeeResponseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.createEmployeeWithRoles>) => (
      employeeService.createEmployeeWithRoles(...args)
    ),
    ResponseClassCreator: createEmployeeCreateEmployeeWithRolesObject,
    typeName: 'useEmployeeCreateEmployeeWithRoles',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeCreateEmployeeWithRoles.mock = () => new Error('Mock has not been implemented!');
useEmployeeCreateEmployeeWithRoles.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeCreateEmployeeWithRoles.createObject = createEmployeeCreateEmployeeWithRolesObject;

export const createEmployeeResendOnBoardEmailObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useEmployeeResendOnBoardEmail = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeResendOnBoardEmailParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeResendOnBoardEmailParams,
    APIResponse<string>,
    typeof employeeService.resendOnBoardEmail,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.resendOnBoardEmail>) => (
      employeeService.resendOnBoardEmail(...args)
    ),
    ResponseClassCreator: createEmployeeResendOnBoardEmailObject,
    typeName: 'useEmployeeResendOnBoardEmail',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeResendOnBoardEmail.mock = () => new Error('Mock has not been implemented!');
useEmployeeResendOnBoardEmail.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeResendOnBoardEmail.createObject = createEmployeeResendOnBoardEmailObject;

export const createEmployeeForgotPasswordEmailObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useEmployeeForgotPasswordEmail = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeForgotPasswordEmailParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeForgotPasswordEmailParams,
    APIResponse<string>,
    typeof employeeService.forgotPasswordEmail,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.forgotPasswordEmail>) => (
      employeeService.forgotPasswordEmail(...args)
    ),
    ResponseClassCreator: createEmployeeForgotPasswordEmailObject,
    typeName: 'useEmployeeForgotPasswordEmail',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeForgotPasswordEmail.mock = () => new Error('Mock has not been implemented!');
useEmployeeForgotPasswordEmail.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeForgotPasswordEmail.createObject = createEmployeeForgotPasswordEmailObject;

export const createEmployeeCoworkersObject = (value: APIResponse<Employee[]>) => new APIResponseClass<EmployeeClass[]>(value, (v) => map(v, (value: Employee) => new EmployeeClass(value)));

export const useEmployeeCoworkers = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeCoworkersParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeCoworkersParams,
    APIResponse<Employee[]>,
    typeof employeeService.coworkers,
    AjaxOptions,
    APIResponseClass<EmployeeClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.coworkers>) => (
      employeeService.coworkers(...args)
    ),
    ResponseClassCreator: createEmployeeCoworkersObject,
    typeName: 'useEmployeeCoworkers',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeCoworkers.mock = () => new Error('Mock has not been implemented!');
useEmployeeCoworkers.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeCoworkers.createObject = createEmployeeCoworkersObject;

export const createEmployeeSearchUserObject = (value: APIResponse<PageResponse<LimitedUser>>) => new APIResponseClass<PageResponseClass<LimitedUserClass>>(value, (value: PageResponse<LimitedUser>) => new PageResponseClass<LimitedUserClass>(value, (value: LimitedUser) => new LimitedUserClass(value)));

export const useEmployeeSearchUser = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeSearchUserParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeSearchUserParams,
    APIResponse<PageResponse<LimitedUser>>,
    typeof employeeService.searchUser,
    AjaxOptions,
    APIResponseClass<PageResponseClass<LimitedUserClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.searchUser>) => (
      employeeService.searchUser(...args)
    ),
    ResponseClassCreator: createEmployeeSearchUserObject,
    typeName: 'useEmployeeSearchUser',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeSearchUser.mock = () => new Error('Mock has not been implemented!');
useEmployeeSearchUser.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeSearchUser.createObject = createEmployeeSearchUserObject;

export const createEmployeeSearchIhealthEmployeeObject = (value: APIResponse<PageResponse<Employee>>) => new APIResponseClass<PageResponseClass<EmployeeClass>>(value, (value: PageResponse<Employee>) => new PageResponseClass<EmployeeClass>(value, (value: Employee) => new EmployeeClass(value)));

export const useEmployeeSearchIhealthEmployee = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeSearchIhealthEmployeeParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeSearchIhealthEmployeeParams,
    APIResponse<PageResponse<Employee>>,
    typeof employeeService.searchIhealthEmployee,
    AjaxOptions,
    APIResponseClass<PageResponseClass<EmployeeClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.searchIhealthEmployee>) => (
      employeeService.searchIhealthEmployee(...args)
    ),
    ResponseClassCreator: createEmployeeSearchIhealthEmployeeObject,
    typeName: 'useEmployeeSearchIhealthEmployee',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeSearchIhealthEmployee.mock = () => new Error('Mock has not been implemented!');
useEmployeeSearchIhealthEmployee.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeSearchIhealthEmployee.createObject = createEmployeeSearchIhealthEmployeeObject;

export const createEmployeeUpsertMedicalAssistantAssignorObject = (value: APIResponse<MedicalAssistantAssignor[]>) => new APIResponseClass<MedicalAssistantAssignorClass[]>(value, (v) => map(v, (value: MedicalAssistantAssignor) => new MedicalAssistantAssignorClass(value)));

export const useEmployeeUpsertMedicalAssistantAssignor = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeUpsertMedicalAssistantAssignorParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeUpsertMedicalAssistantAssignorParams,
    APIResponse<MedicalAssistantAssignor[]>,
    typeof employeeService.upsertMedicalAssistantAssignor,
    AjaxOptions,
    APIResponseClass<MedicalAssistantAssignorClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.upsertMedicalAssistantAssignor>) => (
      employeeService.upsertMedicalAssistantAssignor(...args)
    ),
    ResponseClassCreator: createEmployeeUpsertMedicalAssistantAssignorObject,
    typeName: 'useEmployeeUpsertMedicalAssistantAssignor',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeUpsertMedicalAssistantAssignor.mock = () => new Error('Mock has not been implemented!');
useEmployeeUpsertMedicalAssistantAssignor.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeUpsertMedicalAssistantAssignor.createObject = createEmployeeUpsertMedicalAssistantAssignorObject;

export const createEmployeeMiniEmployeeListObject = (value: APIResponse<MiniPersonResponse[]>) => new APIResponseClass<MiniPersonResponseClass[]>(value, (v) => map(v, (value: MiniPersonResponse) => new MiniPersonResponseClass(value)));

export const useEmployeeMiniEmployeeList = <RCN = undefined>(configs: MakeRequestHookConfig<EmployeeMiniEmployeeListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EmployeeMiniEmployeeListParams,
    APIResponse<MiniPersonResponse[]>,
    typeof employeeService.miniEmployeeList,
    AjaxOptions,
    APIResponseClass<MiniPersonResponseClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.miniEmployeeList>) => (
      employeeService.miniEmployeeList(...args)
    ),
    ResponseClassCreator: createEmployeeMiniEmployeeListObject,
    typeName: 'useEmployeeMiniEmployeeList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEmployeeMiniEmployeeList.mock = () => new Error('Mock has not been implemented!');
useEmployeeMiniEmployeeList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeMiniEmployeeList.createObject = createEmployeeMiniEmployeeListObject;


export const createEmployeeGetObject = (value: APIResponse<Employee>) => new APIResponseClass<EmployeeClass>(value, (value: Employee) => new EmployeeClass(value));
export const useEmployeeGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<Employee>,
    // @ts-ignore
    typeof employeeService.get,
    GetOptions,
    APIResponseClass<EmployeeClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof employeeService.get>) => (
      employeeService.get(...args)
    ),
    ResponseClassCreator: createEmployeeGetObject,
    typeName: 'useEmployeeGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useEmployeeGet.mock = () => new Error('Mock has not been implemented!');
useEmployeeGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeGet.createObject = createEmployeeGetObject;

export const createEmployeeUpdateObject = (value: APIResponse<Employee>) => new APIResponseClass<EmployeeClass>(value, (value: Employee) => new EmployeeClass(value));
export const useEmployeeUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, Employee>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, Employee>,
    APIResponse<Employee>,
    // @ts-ignore
    typeof employeeService.update,
    AjaxOptions,
    APIResponseClass<EmployeeClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.update>) => (
      employeeService.update(...args)
    ),
    ResponseClassCreator: createEmployeeUpdateObject,
    typeName: 'useEmployeeUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useEmployeeUpdate.mock = () => new Error('Mock has not been implemented!');
useEmployeeUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeUpdate.createObject = createEmployeeUpdateObject;

export const createEmployeeInsertObject = (value: APIResponse<Employee>) => new APIResponseClass<EmployeeClass>(value, (value: Employee) => new EmployeeClass(value));
export const useEmployeeInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<Employee>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<Employee>,
    APIResponse<Employee>,
    // @ts-ignore
    typeof employeeService.insert,
    AjaxOptions,
    APIResponseClass<EmployeeClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.insert>) => (
      employeeService.insert(...args)
    ),
    ResponseClassCreator: createEmployeeInsertObject,
    typeName: 'useEmployeeInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useEmployeeInsert.mock = () => new Error('Mock has not been implemented!');
useEmployeeInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeInsert.createObject = createEmployeeInsertObject;

export const createEmployeeDeleteObject = (value: APIResponse<Employee>) => new APIResponseClass<EmployeeClass>(value, (value: Employee) => new EmployeeClass(value));
export const useEmployeeDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<Employee>,
    // @ts-ignore
    typeof employeeService.delete,
    AjaxOptions,
    APIResponseClass<EmployeeClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.delete>) => (
      employeeService.delete(...args)
    ),
    ResponseClassCreator: createEmployeeDeleteObject,
    typeName: 'useEmployeeDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useEmployeeDelete.mock = () => new Error('Mock has not been implemented!');
useEmployeeDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeDelete.createObject = createEmployeeDeleteObject;

export const createEmployeeSearchObject = (value: APIResponse<PageResponse<Employee>>) => new APIResponseClass<PageResponseClass<EmployeeClass>>(value, (value: PageResponse<Employee>) => new PageResponseClass<EmployeeClass>(value, (value: Employee) => new EmployeeClass(value)));
export const useEmployeeSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<Employee>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<Employee>,
    APIResponse<PageResponse<Employee>>,
    // @ts-ignore
    typeof employeeService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<EmployeeClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof employeeService.search>) => (
      employeeService.search(...args)
    ),
    ResponseClassCreator: createEmployeeSearchObject,
    typeName: 'useEmployeeSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useEmployeeSearch.mock = () => new Error('Mock has not been implemented!');
useEmployeeSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEmployeeSearch.createObject = createEmployeeSearchObject;

export enum FrequencyUnitEnum {
	DAILY = 'DAILY',
	WEEKLY = 'WEEKLY',
	MONTHLY = 'MONTHLY',
	ANNUALLY = 'ANNUALLY',
}
export interface UCTaskSchedule {
	unit?: Nullable<FrequencyUnitEnum>;
	mealType?: Nullable<BgMealTypeEnum>;
	isBeforeMeal?: Nullable<boolean>;
	frequency?: Nullable<number>;
}
export interface UCTask {
	id?: Nullable<string>;
	type?: Nullable<MeasurementResultTypeEnum>;
	description?: Nullable<string>;
	missedCounter?: Nullable<number>;
	activated?: Nullable<boolean>;
	repeat?: Nullable<TaskRepeatEnum>;
	taskSchedule?: Nullable<UCTaskSchedule>;
	threshold?: Nullable<AlertThreshold[]>;
	notification?: Nullable<NotificationSetting[]>;
	templateDetails?: Nullable<TemplateDetails>;
}
export interface EnrolledProgram extends BaseModel {
	organizationId?: Nullable<string>;
	name?: Nullable<string>;
	description?: Nullable<string>;
	memberId?: Nullable<string>;
	adminProgramId?: Nullable<string>;
	managers?: Nullable<string>;
	tasks?: Nullable<UCTask[]>;
	teamId?: Nullable<string>;
	healthCondition?: Nullable<HealthConditionEnum>;
	operationStatus?: Nullable<EnrolledProgramStatusEnum>;
	startDate?: Nullable<string>;
	vendors?: Nullable<User[]>;
	manualInputEnable?: Nullable<boolean>;
	manualInputEnabledTaskTypes?: Nullable<string[]>;
	deviceInputEnabledTaskTypes?: Nullable<string[]>;
	devices?: Nullable<ProgramDevice[]>;
	timezone?: Nullable<string>;
	memberIdIn?: Nullable<InFilter<string>>;
	vitalIn?: Nullable<InFilter<MeasurementResultTypeEnum>>;
}
export interface VitalSchedule {
	unit?: Nullable<FrequencyUnitEnum>;
	frequency?: Nullable<number>;
}
export interface BGMonitoringSchedule {
	templateId?: Nullable<string>;
	templateDetails?: Nullable<TemplateDetails>;
}
export interface VitalRequest {
	bp?: Nullable<VitalSchedule>;
	weight?: Nullable<VitalSchedule>;
	temperature?: Nullable<VitalSchedule>;
	bo?: Nullable<VitalSchedule>;
	cgm?: Nullable<CgmBrandEnum>;
	bg?: Nullable<BGMonitoringSchedule>;
}
export class UCTaskScheduleClass {
  protected readonly _value: UCTaskSchedule;

	constructor(value: UCTaskSchedule) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get unit() {
    return this._value.unit;
  }

  unitIsIn(values: FrequencyUnitEnum[] = []) {
    return this._value.unit && values.includes(this._value.unit);
  }

  get mealType() {
    return this._value.mealType;
  }

  mealTypeIsIn(values: BgMealTypeEnum[] = []) {
    return this._value.mealType && values.includes(this._value.mealType);
  }

  get isBeforeMeal() {
    return this._value.isBeforeMeal;
  }

  get isBeforeMealWithDefault() {
    return this._value.isBeforeMeal || false;
  }

  get frequency() {
    return this._value.frequency;
  }

  get frequencyWithDefault() {
    return this._value.frequency || 0;
  }
}
export class UCTaskClass {
  protected readonly _value: UCTask;

	constructor(value: UCTask) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: MeasurementResultTypeEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get missedCounter() {
    return this._value.missedCounter;
  }

  get missedCounterWithDefault() {
    return this._value.missedCounter || 0;
  }

  get activated() {
    return this._value.activated;
  }

  get activatedWithDefault() {
    return this._value.activated || false;
  }

  get repeat() {
    return this._value.repeat;
  }

  repeatIsIn(values: TaskRepeatEnum[] = []) {
    return this._value.repeat && values.includes(this._value.repeat);
  }

  get taskSchedule() {
    return this._value.taskSchedule !== undefined && this._value.taskSchedule !== null ? new UCTaskScheduleClass(this._value.taskSchedule) : this._value.taskSchedule;
  }

  get threshold() {
    return this._value.threshold !== undefined && this._value.threshold !== null ? map(this._value.threshold, (v) => new AlertThresholdClass(v)) : this._value.threshold;
  }

  get thresholdWithDefault() {
    return this._value.threshold || [];
  }

  get notification() {
    return this._value.notification !== undefined && this._value.notification !== null ? map(this._value.notification, (v) => new NotificationSettingClass(v)) : this._value.notification;
  }

  get notificationWithDefault() {
    return this._value.notification || [];
  }

  get templateDetails() {
    return this._value.templateDetails !== undefined && this._value.templateDetails !== null ? new TemplateDetailsClass(this._value.templateDetails) : this._value.templateDetails;
  }
}
export class EnrolledProgramClass extends BaseModelClass {
  protected readonly _value: EnrolledProgram;

	constructor(value: EnrolledProgram) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get memberId() {
    return this._value.memberId;
  }

  get adminProgramId() {
    return this._value.adminProgramId;
  }

  get managers() {
    return this._value.managers;
  }

  get managersWithDefault() {
    return this._value.managers || '';
  }

  get tasks() {
    return this._value.tasks !== undefined && this._value.tasks !== null ? map(this._value.tasks, (v) => new UCTaskClass(v)) : this._value.tasks;
  }

  get tasksWithDefault() {
    return this._value.tasks || [];
  }

  get teamId() {
    return this._value.teamId;
  }

  get healthCondition() {
    return this._value.healthCondition;
  }

  healthConditionIsIn(values: HealthConditionEnum[] = []) {
    return this._value.healthCondition && values.includes(this._value.healthCondition);
  }

  get operationStatus() {
    return this._value.operationStatus;
  }

  operationStatusIsIn(values: EnrolledProgramStatusEnum[] = []) {
    return this._value.operationStatus && values.includes(this._value.operationStatus);
  }

  get startDate() {
    return (this._value.startDate !== undefined && this._value.startDate !== null) ? dayjs(this._value.startDate) : this._value.startDate;
  }

  get vendors() {
    return this._value.vendors !== undefined && this._value.vendors !== null ? map(this._value.vendors, (v) => new UserClass(v)) : this._value.vendors;
  }

  get vendorsWithDefault() {
    return this._value.vendors || [];
  }

  get manualInputEnable() {
    return this._value.manualInputEnable;
  }

  get manualInputEnableWithDefault() {
    return this._value.manualInputEnable || false;
  }

  get manualInputEnabledTaskTypes() {
    return this._value.manualInputEnabledTaskTypes;
  }

  get manualInputEnabledTaskTypesWithDefault() {
    return this._value.manualInputEnabledTaskTypes || [];
  }

  get deviceInputEnabledTaskTypes() {
    return this._value.deviceInputEnabledTaskTypes;
  }

  get deviceInputEnabledTaskTypesWithDefault() {
    return this._value.deviceInputEnabledTaskTypes || [];
  }

  get devices() {
    return this._value.devices !== undefined && this._value.devices !== null ? map(this._value.devices, (v) => new ProgramDeviceClass(v)) : this._value.devices;
  }

  get devicesWithDefault() {
    return this._value.devices || [];
  }

  get timezone() {
    return this._value.timezone;
  }

  get timezoneWithDefault() {
    return this._value.timezone || '';
  }

  get memberIdIn() {
    return this._value.memberIdIn !== undefined && this._value.memberIdIn !== null ? new InFilterClass(this._value.memberIdIn) : this._value.memberIdIn;
  }

  get vitalIn() {
    return this._value.vitalIn !== undefined && this._value.vitalIn !== null ? new InFilterClass(this._value.vitalIn) : this._value.vitalIn;
  }
}
export interface EnrolledProgramAddVitalsParams {
	id: string;
	request: VitalRequest;
}

export class EnrolledProgramService extends BaseController<EnrolledProgram, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/enrolled-programs';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public addVitals({ baseUrl, params, headers }: RequestOption<EnrolledProgramAddVitalsParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/add-vital${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const enrolledProgramService = new EnrolledProgramService();

export const createEnrolledProgramAddVitalsObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useEnrolledProgramAddVitals = <RCN = undefined>(configs: MakeRequestHookConfig<EnrolledProgramAddVitalsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EnrolledProgramAddVitalsParams,
    APIResponse<string>,
    typeof enrolledProgramService.addVitals,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof enrolledProgramService.addVitals>) => (
      enrolledProgramService.addVitals(...args)
    ),
    ResponseClassCreator: createEnrolledProgramAddVitalsObject,
    typeName: 'useEnrolledProgramAddVitals',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEnrolledProgramAddVitals.mock = () => new Error('Mock has not been implemented!');
useEnrolledProgramAddVitals.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEnrolledProgramAddVitals.createObject = createEnrolledProgramAddVitalsObject;


export const createEnrolledProgramGetObject = (value: APIResponse<EnrolledProgram>) => new APIResponseClass<EnrolledProgramClass>(value, (value: EnrolledProgram) => new EnrolledProgramClass(value));
export const useEnrolledProgramGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<EnrolledProgram>,
    // @ts-ignore
    typeof enrolledProgramService.get,
    GetOptions,
    APIResponseClass<EnrolledProgramClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof enrolledProgramService.get>) => (
      enrolledProgramService.get(...args)
    ),
    ResponseClassCreator: createEnrolledProgramGetObject,
    typeName: 'useEnrolledProgramGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useEnrolledProgramGet.mock = () => new Error('Mock has not been implemented!');
useEnrolledProgramGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEnrolledProgramGet.createObject = createEnrolledProgramGetObject;

export const createEnrolledProgramUpdateObject = (value: APIResponse<EnrolledProgram>) => new APIResponseClass<EnrolledProgramClass>(value, (value: EnrolledProgram) => new EnrolledProgramClass(value));
export const useEnrolledProgramUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, EnrolledProgram>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, EnrolledProgram>,
    APIResponse<EnrolledProgram>,
    // @ts-ignore
    typeof enrolledProgramService.update,
    AjaxOptions,
    APIResponseClass<EnrolledProgramClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof enrolledProgramService.update>) => (
      enrolledProgramService.update(...args)
    ),
    ResponseClassCreator: createEnrolledProgramUpdateObject,
    typeName: 'useEnrolledProgramUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useEnrolledProgramUpdate.mock = () => new Error('Mock has not been implemented!');
useEnrolledProgramUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEnrolledProgramUpdate.createObject = createEnrolledProgramUpdateObject;

export const createEnrolledProgramInsertObject = (value: APIResponse<EnrolledProgram>) => new APIResponseClass<EnrolledProgramClass>(value, (value: EnrolledProgram) => new EnrolledProgramClass(value));
export const useEnrolledProgramInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<EnrolledProgram>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<EnrolledProgram>,
    APIResponse<EnrolledProgram>,
    // @ts-ignore
    typeof enrolledProgramService.insert,
    AjaxOptions,
    APIResponseClass<EnrolledProgramClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof enrolledProgramService.insert>) => (
      enrolledProgramService.insert(...args)
    ),
    ResponseClassCreator: createEnrolledProgramInsertObject,
    typeName: 'useEnrolledProgramInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useEnrolledProgramInsert.mock = () => new Error('Mock has not been implemented!');
useEnrolledProgramInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEnrolledProgramInsert.createObject = createEnrolledProgramInsertObject;

export const createEnrolledProgramDeleteObject = (value: APIResponse<EnrolledProgram>) => new APIResponseClass<EnrolledProgramClass>(value, (value: EnrolledProgram) => new EnrolledProgramClass(value));
export const useEnrolledProgramDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<EnrolledProgram>,
    // @ts-ignore
    typeof enrolledProgramService.delete,
    AjaxOptions,
    APIResponseClass<EnrolledProgramClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof enrolledProgramService.delete>) => (
      enrolledProgramService.delete(...args)
    ),
    ResponseClassCreator: createEnrolledProgramDeleteObject,
    typeName: 'useEnrolledProgramDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useEnrolledProgramDelete.mock = () => new Error('Mock has not been implemented!');
useEnrolledProgramDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEnrolledProgramDelete.createObject = createEnrolledProgramDeleteObject;

export const createEnrolledProgramSearchObject = (value: APIResponse<PageResponse<EnrolledProgram>>) => new APIResponseClass<PageResponseClass<EnrolledProgramClass>>(value, (value: PageResponse<EnrolledProgram>) => new PageResponseClass<EnrolledProgramClass>(value, (value: EnrolledProgram) => new EnrolledProgramClass(value)));
export const useEnrolledProgramSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<EnrolledProgram>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<EnrolledProgram>,
    APIResponse<PageResponse<EnrolledProgram>>,
    // @ts-ignore
    typeof enrolledProgramService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<EnrolledProgramClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof enrolledProgramService.search>) => (
      enrolledProgramService.search(...args)
    ),
    ResponseClassCreator: createEnrolledProgramSearchObject,
    typeName: 'useEnrolledProgramSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useEnrolledProgramSearch.mock = () => new Error('Mock has not been implemented!');
useEnrolledProgramSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEnrolledProgramSearch.createObject = createEnrolledProgramSearchObject;

export enum InsuranceRelationshipEnum {
	SUBSCRIBER = 'SUBSCRIBER',
	DEPENDENT = 'DEPENDENT',
}
export enum EligibilityStatusEnum {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	FAILED = 'FAILED',
	INCOMPLETE = 'INCOMPLETE',
}
export enum BenefitOrderEnum {
	PRIMARY = 'PRIMARY',
	SECONDARY = 'SECONDARY',
	TERTIARY = 'TERTIARY',
}
export interface InsuranceInfo {
	providerId?: Nullable<string>;
	provider?: Nullable<string>;
	policyNumber?: Nullable<string>;
	wayStarInquiryId?: Nullable<string>;
	patientRelationship?: Nullable<InsuranceRelationshipEnum>;
	eligibilityStatus?: Nullable<EligibilityStatusEnum>;
	benefitOrder?: Nullable<BenefitOrderEnum>;
	insuranceId?: Nullable<string>;
}
export enum PriorAuthCaseStatusEnum {
	NEED_SUBMISSION = 'NEED_SUBMISSION',
	PENDING_RESULT = 'PENDING_RESULT',
	APPROVED = 'APPROVED',
	CLOSED = 'CLOSED',
	DENIED = 'DENIED',
	description = 'description',
	FINISHED_STATUSES = 'FINISHED_STATUSES',
}
export interface PatientInsurance$PriorAuthorizationInfo extends BaseModel {
	needPriorAuth?: Nullable<boolean>;
	latestCaseStatus?: Nullable<PriorAuthCaseStatusEnum>;
	latestCaseId?: Nullable<string>;
	latestCaseActionDate?: Nullable<string>;
	latestCaseExpiredAt?: Nullable<string>;
	latestCaseUpdatedAt?: Nullable<string>;
}
export enum NoInsuranceCoverageReasonEnum {
	HIGH_DEDUCTIBLE_PLAN = 'HIGH_DEDUCTIBLE_PLAN',
	INSURANCE_CAPITATED = 'INSURANCE_CAPITATED',
	PROVIDER_CAPITATED = 'PROVIDER_CAPITATED',
	INSURANCE_NOT_COVERING_CCM_RPM = 'INSURANCE_NOT_COVERING_CCM_RPM',
	CLOSED_CASE_FROM_CENTRALIZE_INSURANCE_MANAGEMENT = 'CLOSED_CASE_FROM_CENTRALIZE_INSURANCE_MANAGEMENT',
	NO_INSURANCE_INFORMATION_AVAILABLE = 'NO_INSURANCE_INFORMATION_AVAILABLE',
	OTHER = 'OTHER',
}
export interface NonCovered {
	isNonCovered?: Nullable<boolean>;
	reason?: Nullable<string>;
	reasonEnum?: Nullable<NoInsuranceCoverageReasonEnum>;
}
export enum InsuranceVerificationStatusEnum {
	INIT = 'INIT',
	PENDING = 'PENDING',
	DONE = 'DONE',
}
export interface PatientInsurance extends PatientBase {
	insuranceInfoList?: Nullable<InsuranceInfo[]>;
	result?: Nullable<string>;
	submissionDate?: Nullable<string>;
	priorAuth?: Nullable<PatientInsurance$PriorAuthorizationInfo>;
	nonCovered?: Nullable<NonCovered>;
	note?: Nullable<string>;
	markMedCareEligible?: Nullable<boolean>;
	hasCopay?: Nullable<boolean>;
	copay?: Nullable<string>;
	deductible?: Nullable<string>;
	insuranceVerificationStatus?: Nullable<InsuranceVerificationStatusEnum>;
}
export interface ProgramEnrollment extends BaseModel {
	patientDiagnoses?: Nullable<PatientDiagnosesEnum[]>;
	patientInsurance?: Nullable<PatientInsurance>;
	programCategories?: Nullable<ProgramCategoryEnum[]>;
	vitalTypes?: Nullable<VitalEnumType[]>;
	addOnServices?: Nullable<AddOnServiceEnum[]>;
	patientProfile?: Nullable<PatientProfile>;
	hasSmartDevice?: Nullable<boolean>;
	ableToJoinProgram?: Nullable<boolean>;
	reason?: Nullable<string>;
}
export interface BasicInformation {
	providerId?: Nullable<string>;
	spokenLanguages?: Nullable<string[]>;
	username?: Nullable<string>;
	password?: Nullable<string>;
	countryCode?: Nullable<string>;
	mobilePhone?: Nullable<string>;
	homePhone?: Nullable<string>;
	allowOTP?: Nullable<boolean>;
	clinicId?: Nullable<string>;
}
export enum MonitorMethodEnum {
	APP_MONITOR = 'APP_MONITOR',
	NON_APP_MONITOR = 'NON_APP_MONITOR',
	MANUALLY_INPUT = 'MANUALLY_INPUT',
	CELLULAR_DEVICE = 'CELLULAR_DEVICE',
	WIFI_DEVICE = 'WIFI_DEVICE',
}
export enum CuffSizeEnum {
	MINI = 'MINI',
	STANDARD = 'STANDARD',
	LARGE = 'LARGE',
	EXTRA_LARGE = 'EXTRA_LARGE',
}
export interface AssignDeviceInfo {
	deviceId?: Nullable<string>;
	deviceType?: Nullable<DeviceTypeEnum>;
	deviceModel?: Nullable<DeviceModelEnum>;
	deviceMethod?: Nullable<MonitorMethodEnum>;
	deviceFirmware?: Nullable<string>;
	deviceHardware?: Nullable<string>;
	accessedAt?: Nullable<string>;
	pairTime?: Nullable<string>;
	cuffSize?: Nullable<CuffSizeEnum>;
	deviceLanguage?: Nullable<string>;
	bpReadOut?: Nullable<boolean>;
}
export interface DeviceAssignment {
	vitalType?: Nullable<VitalEnumType>;
	monitorMethod?: Nullable<MonitorMethodEnum>;
	cuffSize?: Nullable<CuffSizeEnum>;
	devices?: Nullable<AssignDeviceInfo[]>;
}
export enum DeviceStatusEnum {
	ASSIGN = 'ASSIGN',
	UNASSIGN = 'UNASSIGN',
	LOST = 'LOST',
}
export interface DeviceAssignmentHistory extends PatientBase {
	deviceId?: Nullable<string>;
	action?: Nullable<DeviceStatusEnum>;
	memberId?: Nullable<string>;
}
export enum DeviceDeliveryMethodEnum {
	SHIP = 'SHIP',
	PICK_UP = 'PICK_UP',
}
export enum LoanDeviceStatusEnum {
	IN_USE = 'IN_USE',
	AVAILABLE = 'AVAILABLE',
}
export interface LoanDevice extends PatientBase {
	serialNumber?: Nullable<string>;
	deviceId?: Nullable<string>;
	deviceType?: Nullable<string>;
	history?: Nullable<DeviceAssignmentHistory[]>;
	status?: Nullable<LoanDeviceStatusEnum>;
	appVersion?: Nullable<string>;
	memberId?: Nullable<string>;
	osVersion?: Nullable<string>;
	deviceIdNear?: Nullable<string>;
}
export interface PatientDevice extends PatientBase {
	deviceList?: Nullable<DeviceAssignment[]>;
	history?: Nullable<DeviceAssignmentHistory[]>;
	deviceDeliveryMethod?: Nullable<DeviceDeliveryMethodEnum>;
	patientTechLevel?: Nullable<PatientTechLevelEnum>;
	saveAddress?: Nullable<boolean>;
	shippingAddress?: Nullable<Address>;
	loanDevices?: Nullable<LoanDevice[]>;
	deviceIdIn?: Nullable<InFilter<string>>;
}
export enum SignatureCollectionMethodEnum {
	APP = 'APP',
	TEAM_MEMBER_DEVICE = 'TEAM_MEMBER_DEVICE',
	VERBALLY = 'VERBALLY',
	PAPER = 'PAPER',
	CLINIC_PRINTED = 'CLINIC_PRINTED',
	templateTailFileName = 'templateTailFileName',
}
export enum ConsentFormType {
	DEFAULT = 'DEFAULT',
	CLINIC_TERMINATED = 'CLINIC_TERMINATED',
}
export interface ConsentRequest {
	consentType?: Nullable<SignatureCollectionMethodEnum>;
	formType?: Nullable<ConsentFormType>;
	userData?: Nullable<{ [key: string]: string }>;
	source?: Nullable<string>;
	signedDate?: Nullable<string>;
	consentContent?: Nullable<string>;
	batchSentConsent?: Nullable<boolean>;
	enrolledBy?: Nullable<string>;
}
export enum EnrollmentActionEnum {
	ENROLL = 'ENROLL',
	REJECT = 'REJECT',
	SAVE_DRAFT = 'SAVE_DRAFT',
	SEND_INVITE = 'SEND_INVITE',
	ASSIGN_DEVICE = 'ASSIGN_DEVICE',
	CONSENT_SUBMIT = 'CONSENT_SUBMIT',
}
export enum EnrollmentStatusEnum {
	DRAFT_SAVED = 'DRAFT_SAVED',
	ENROLLED = 'ENROLLED',
	REJECTED = 'REJECTED',
	CONSENT_SUBMIT = 'CONSENT_SUBMIT',
	DEVICE_ASSIGNED = 'DEVICE_ASSIGNED',
	COMPLETED = 'COMPLETED',
}
export interface EnrollmentStatus {
	updatedAt?: Nullable<string>;
	status?: Nullable<EnrollmentStatusEnum>;
}
export interface EnrollmentRequest extends PatientBase {
	programEnrollment?: Nullable<ProgramEnrollment>;
	basicInformation?: Nullable<BasicInformation>;
	patientDevice?: Nullable<PatientDevice>;
	loanDeviceRequest?: Nullable<LoanDevice[]>;
	patientAssigneeIds?: Nullable<string[]>;
	consentRequest?: Nullable<ConsentRequest>;
	action?: Nullable<EnrollmentActionEnum>;
	waiveTechOnboarding?: Nullable<boolean>;
	statuses?: Nullable<EnrollmentStatus[]>;
	markEligible?: Nullable<boolean>;
	techOnboardingVisitEvent?: Nullable<ClinicEvent>;
	initialAssessmentVisitEvent?: Nullable<ClinicEvent>;
	diagnosesIn?: Nullable<InFilter<PatientDiagnosesEnum>>;
	providerIn?: Nullable<InFilter<string>>;
	provider2In?: Nullable<InFilter<string>>;
	provider3In?: Nullable<InFilter<string>>;
	insuranceVerificationStatusIn?: Nullable<InFilter<InsuranceVerificationStatusEnum>>;
	providerNear?: Nullable<string>;
	provider2Near?: Nullable<string>;
	provider3Near?: Nullable<string>;
}
export class InsuranceInfoClass {
  protected readonly _value: InsuranceInfo;

	constructor(value: InsuranceInfo) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get providerId() {
    return this._value.providerId;
  }

  get providerIdWithDefault() {
    return this._value.providerId || '';
  }

  get provider() {
    return this._value.provider;
  }

  get providerWithDefault() {
    return this._value.provider || '';
  }

  get policyNumber() {
    return this._value.policyNumber;
  }

  get policyNumberWithDefault() {
    return this._value.policyNumber || '';
  }

  get wayStarInquiryId() {
    return this._value.wayStarInquiryId;
  }

  get wayStarInquiryIdWithDefault() {
    return this._value.wayStarInquiryId || '';
  }

  get patientRelationship() {
    return this._value.patientRelationship;
  }

  patientRelationshipIsIn(values: InsuranceRelationshipEnum[] = []) {
    return this._value.patientRelationship && values.includes(this._value.patientRelationship);
  }

  get eligibilityStatus() {
    return this._value.eligibilityStatus;
  }

  eligibilityStatusIsIn(values: EligibilityStatusEnum[] = []) {
    return this._value.eligibilityStatus && values.includes(this._value.eligibilityStatus);
  }

  get benefitOrder() {
    return this._value.benefitOrder;
  }

  benefitOrderIsIn(values: BenefitOrderEnum[] = []) {
    return this._value.benefitOrder && values.includes(this._value.benefitOrder);
  }

  get insuranceId() {
    return this._value.insuranceId;
  }

  get insuranceIdWithDefault() {
    return this._value.insuranceId || '';
  }
}
export class PatientInsurance$PriorAuthorizationInfoClass extends BaseModelClass {
  protected readonly _value: PatientInsurance$PriorAuthorizationInfo;

	constructor(value: PatientInsurance$PriorAuthorizationInfo) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get needPriorAuth() {
    return this._value.needPriorAuth;
  }

  get needPriorAuthWithDefault() {
    return this._value.needPriorAuth || false;
  }

  get latestCaseStatus() {
    return this._value.latestCaseStatus;
  }

  latestCaseStatusIsIn(values: PriorAuthCaseStatusEnum[] = []) {
    return this._value.latestCaseStatus && values.includes(this._value.latestCaseStatus);
  }

  get latestCaseId() {
    return this._value.latestCaseId;
  }

  get latestCaseActionDate() {
    return (this._value.latestCaseActionDate !== undefined && this._value.latestCaseActionDate !== null) ? dayjs(this._value.latestCaseActionDate) : this._value.latestCaseActionDate;
  }

  get latestCaseExpiredAt() {
    return (this._value.latestCaseExpiredAt !== undefined && this._value.latestCaseExpiredAt !== null) ? dayjs(this._value.latestCaseExpiredAt) : this._value.latestCaseExpiredAt;
  }

  get latestCaseUpdatedAt() {
    return (this._value.latestCaseUpdatedAt !== undefined && this._value.latestCaseUpdatedAt !== null) ? dayjs(this._value.latestCaseUpdatedAt) : this._value.latestCaseUpdatedAt;
  }
}
export class NonCoveredClass {
  protected readonly _value: NonCovered;

	constructor(value: NonCovered) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get isNonCovered() {
    return this._value.isNonCovered;
  }

  get isNonCoveredWithDefault() {
    return this._value.isNonCovered || false;
  }

  get reason() {
    return this._value.reason;
  }

  get reasonWithDefault() {
    return this._value.reason || '';
  }

  get reasonEnum() {
    return this._value.reasonEnum;
  }

  reasonEnumIsIn(values: NoInsuranceCoverageReasonEnum[] = []) {
    return this._value.reasonEnum && values.includes(this._value.reasonEnum);
  }
}
export class PatientInsuranceClass extends PatientBaseClass {
  protected readonly _value: PatientInsurance;

	constructor(value: PatientInsurance) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get insuranceInfoList() {
    return this._value.insuranceInfoList !== undefined && this._value.insuranceInfoList !== null ? map(this._value.insuranceInfoList, (v) => new InsuranceInfoClass(v)) : this._value.insuranceInfoList;
  }

  get insuranceInfoListWithDefault() {
    return this._value.insuranceInfoList || [];
  }

  get result() {
    return this._value.result;
  }

  get resultWithDefault() {
    return this._value.result || '';
  }

  get submissionDate() {
    return (this._value.submissionDate !== undefined && this._value.submissionDate !== null) ? dayjs(this._value.submissionDate) : this._value.submissionDate;
  }

  get priorAuth() {
    return this._value.priorAuth !== undefined && this._value.priorAuth !== null ? new PatientInsurance$PriorAuthorizationInfoClass(this._value.priorAuth) : this._value.priorAuth;
  }

  get nonCovered() {
    return this._value.nonCovered !== undefined && this._value.nonCovered !== null ? new NonCoveredClass(this._value.nonCovered) : this._value.nonCovered;
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get markMedCareEligible() {
    return this._value.markMedCareEligible;
  }

  get markMedCareEligibleWithDefault() {
    return this._value.markMedCareEligible || false;
  }

  get hasCopay() {
    return this._value.hasCopay;
  }

  get hasCopayWithDefault() {
    return this._value.hasCopay || false;
  }

  get copay() {
    return this._value.copay;
  }

  get copayWithDefault() {
    return this._value.copay || '';
  }

  get deductible() {
    return this._value.deductible;
  }

  get deductibleWithDefault() {
    return this._value.deductible || '';
  }

  get insuranceVerificationStatus() {
    return this._value.insuranceVerificationStatus;
  }

  insuranceVerificationStatusIsIn(values: InsuranceVerificationStatusEnum[] = []) {
    return this._value.insuranceVerificationStatus && values.includes(this._value.insuranceVerificationStatus);
  }
}
export class ProgramEnrollmentClass extends BaseModelClass {
  protected readonly _value: ProgramEnrollment;

	constructor(value: ProgramEnrollment) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientDiagnoses() {
    return this._value.patientDiagnoses;
  }

  get patientDiagnosesWithDefault() {
    return this._value.patientDiagnoses || [];
  }

  get patientInsurance() {
    return this._value.patientInsurance !== undefined && this._value.patientInsurance !== null ? new PatientInsuranceClass(this._value.patientInsurance) : this._value.patientInsurance;
  }

  get programCategories() {
    return this._value.programCategories;
  }

  get programCategoriesWithDefault() {
    return this._value.programCategories || [];
  }

  get vitalTypes() {
    return this._value.vitalTypes;
  }

  get vitalTypesWithDefault() {
    return this._value.vitalTypes || [];
  }

  get addOnServices() {
    return this._value.addOnServices;
  }

  get addOnServicesWithDefault() {
    return this._value.addOnServices || [];
  }

  get patientProfile() {
    return this._value.patientProfile !== undefined && this._value.patientProfile !== null ? new PatientProfileClass(this._value.patientProfile) : this._value.patientProfile;
  }

  get hasSmartDevice() {
    return this._value.hasSmartDevice;
  }

  get hasSmartDeviceWithDefault() {
    return this._value.hasSmartDevice || false;
  }

  get ableToJoinProgram() {
    return this._value.ableToJoinProgram;
  }

  get ableToJoinProgramWithDefault() {
    return this._value.ableToJoinProgram || false;
  }

  get reason() {
    return this._value.reason;
  }

  get reasonWithDefault() {
    return this._value.reason || '';
  }
}
export class BasicInformationClass {
  protected readonly _value: BasicInformation;

	constructor(value: BasicInformation) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get providerId() {
    return this._value.providerId;
  }

  get spokenLanguages() {
    return this._value.spokenLanguages;
  }

  get spokenLanguagesWithDefault() {
    return this._value.spokenLanguages || [];
  }

  get username() {
    return this._value.username;
  }

  get usernameWithDefault() {
    return this._value.username || '';
  }

  get password() {
    return this._value.password;
  }

  get passwordWithDefault() {
    return this._value.password || '';
  }

  get countryCode() {
    return this._value.countryCode;
  }

  get countryCodeWithDefault() {
    return this._value.countryCode || '';
  }

  get mobilePhone() {
    return this._value.mobilePhone;
  }

  get mobilePhoneWithDefault() {
    return this._value.mobilePhone || '';
  }

  get homePhone() {
    return this._value.homePhone;
  }

  get homePhoneWithDefault() {
    return this._value.homePhone || '';
  }

  get allowOTP() {
    return this._value.allowOTP;
  }

  get allowOTPWithDefault() {
    return this._value.allowOTP || false;
  }

  get clinicId() {
    return this._value.clinicId;
  }
}
export class AssignDeviceInfoClass {
  protected readonly _value: AssignDeviceInfo;

	constructor(value: AssignDeviceInfo) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get deviceId() {
    return this._value.deviceId;
  }

  get deviceIdWithDefault() {
    return this._value.deviceId || '';
  }

  get deviceType() {
    return this._value.deviceType;
  }

  deviceTypeIsIn(values: DeviceTypeEnum[] = []) {
    return this._value.deviceType && values.includes(this._value.deviceType);
  }

  get deviceModel() {
    return this._value.deviceModel;
  }

  deviceModelIsIn(values: DeviceModelEnum[] = []) {
    return this._value.deviceModel && values.includes(this._value.deviceModel);
  }

  get deviceMethod() {
    return this._value.deviceMethod;
  }

  deviceMethodIsIn(values: MonitorMethodEnum[] = []) {
    return this._value.deviceMethod && values.includes(this._value.deviceMethod);
  }

  get deviceFirmware() {
    return this._value.deviceFirmware;
  }

  get deviceFirmwareWithDefault() {
    return this._value.deviceFirmware || '';
  }

  get deviceHardware() {
    return this._value.deviceHardware;
  }

  get deviceHardwareWithDefault() {
    return this._value.deviceHardware || '';
  }

  get accessedAt() {
    return (this._value.accessedAt !== undefined && this._value.accessedAt !== null) ? dayjs(this._value.accessedAt) : this._value.accessedAt;
  }

  get pairTime() {
    return (this._value.pairTime !== undefined && this._value.pairTime !== null) ? dayjs(this._value.pairTime) : this._value.pairTime;
  }

  get cuffSize() {
    return this._value.cuffSize;
  }

  cuffSizeIsIn(values: CuffSizeEnum[] = []) {
    return this._value.cuffSize && values.includes(this._value.cuffSize);
  }

  get deviceLanguage() {
    return this._value.deviceLanguage;
  }

  get deviceLanguageWithDefault() {
    return this._value.deviceLanguage || '';
  }

  get bpReadOut() {
    return this._value.bpReadOut;
  }

  get bpReadOutWithDefault() {
    return this._value.bpReadOut || false;
  }
}
export class DeviceAssignmentClass {
  protected readonly _value: DeviceAssignment;

	constructor(value: DeviceAssignment) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get vitalType() {
    return this._value.vitalType;
  }

  vitalTypeIsIn(values: VitalEnumType[] = []) {
    return this._value.vitalType && values.includes(this._value.vitalType);
  }

  get monitorMethod() {
    return this._value.monitorMethod;
  }

  monitorMethodIsIn(values: MonitorMethodEnum[] = []) {
    return this._value.monitorMethod && values.includes(this._value.monitorMethod);
  }

  get cuffSize() {
    return this._value.cuffSize;
  }

  cuffSizeIsIn(values: CuffSizeEnum[] = []) {
    return this._value.cuffSize && values.includes(this._value.cuffSize);
  }

  get devices() {
    return this._value.devices !== undefined && this._value.devices !== null ? map(this._value.devices, (v) => new AssignDeviceInfoClass(v)) : this._value.devices;
  }

  get devicesWithDefault() {
    return this._value.devices || [];
  }
}
export class DeviceAssignmentHistoryClass extends PatientBaseClass {
  protected readonly _value: DeviceAssignmentHistory;

	constructor(value: DeviceAssignmentHistory) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get deviceId() {
    return this._value.deviceId;
  }

  get deviceIdWithDefault() {
    return this._value.deviceId || '';
  }

  get action() {
    return this._value.action;
  }

  actionIsIn(values: DeviceStatusEnum[] = []) {
    return this._value.action && values.includes(this._value.action);
  }

  get memberId() {
    return this._value.memberId;
  }
}
export class LoanDeviceClass extends PatientBaseClass {
  protected readonly _value: LoanDevice;

	constructor(value: LoanDevice) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get serialNumber() {
    return this._value.serialNumber;
  }

  get serialNumberWithDefault() {
    return this._value.serialNumber || '';
  }

  get deviceId() {
    return this._value.deviceId;
  }

  get deviceIdWithDefault() {
    return this._value.deviceId || '';
  }

  get deviceType() {
    return this._value.deviceType;
  }

  get deviceTypeWithDefault() {
    return this._value.deviceType || '';
  }

  get history() {
    return this._value.history !== undefined && this._value.history !== null ? map(this._value.history, (v) => new DeviceAssignmentHistoryClass(v)) : this._value.history;
  }

  get historyWithDefault() {
    return this._value.history || [];
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: LoanDeviceStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get appVersion() {
    return this._value.appVersion;
  }

  get appVersionWithDefault() {
    return this._value.appVersion || '';
  }

  get memberId() {
    return this._value.memberId;
  }

  get osVersion() {
    return this._value.osVersion;
  }

  get osVersionWithDefault() {
    return this._value.osVersion || '';
  }

  get deviceIdNear() {
    return this._value.deviceIdNear;
  }

  get deviceIdNearWithDefault() {
    return this._value.deviceIdNear || '';
  }
}
export class PatientDeviceClass extends PatientBaseClass {
  protected readonly _value: PatientDevice;

	constructor(value: PatientDevice) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get deviceList() {
    return this._value.deviceList !== undefined && this._value.deviceList !== null ? map(this._value.deviceList, (v) => new DeviceAssignmentClass(v)) : this._value.deviceList;
  }

  get deviceListWithDefault() {
    return this._value.deviceList || [];
  }

  get history() {
    return this._value.history !== undefined && this._value.history !== null ? map(this._value.history, (v) => new DeviceAssignmentHistoryClass(v)) : this._value.history;
  }

  get historyWithDefault() {
    return this._value.history || [];
  }

  get deviceDeliveryMethod() {
    return this._value.deviceDeliveryMethod;
  }

  deviceDeliveryMethodIsIn(values: DeviceDeliveryMethodEnum[] = []) {
    return this._value.deviceDeliveryMethod && values.includes(this._value.deviceDeliveryMethod);
  }

  get patientTechLevel() {
    return this._value.patientTechLevel;
  }

  patientTechLevelIsIn(values: PatientTechLevelEnum[] = []) {
    return this._value.patientTechLevel && values.includes(this._value.patientTechLevel);
  }

  get saveAddress() {
    return this._value.saveAddress;
  }

  get saveAddressWithDefault() {
    return this._value.saveAddress || false;
  }

  get shippingAddress() {
    return this._value.shippingAddress !== undefined && this._value.shippingAddress !== null ? new AddressClass(this._value.shippingAddress) : this._value.shippingAddress;
  }

  get loanDevices() {
    return this._value.loanDevices !== undefined && this._value.loanDevices !== null ? map(this._value.loanDevices, (v) => new LoanDeviceClass(v)) : this._value.loanDevices;
  }

  get loanDevicesWithDefault() {
    return this._value.loanDevices || [];
  }

  get deviceIdIn() {
    return this._value.deviceIdIn !== undefined && this._value.deviceIdIn !== null ? new InFilterClass(this._value.deviceIdIn) : this._value.deviceIdIn;
  }
}
export class ConsentRequestClass {
  protected readonly _value: ConsentRequest;

	constructor(value: ConsentRequest) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get consentType() {
    return this._value.consentType;
  }

  consentTypeIsIn(values: SignatureCollectionMethodEnum[] = []) {
    return this._value.consentType && values.includes(this._value.consentType);
  }

  get formType() {
    return this._value.formType;
  }

  formTypeIsIn(values: ConsentFormType[] = []) {
    return this._value.formType && values.includes(this._value.formType);
  }

  get userData() {
    return this._value.userData;
  }

  get userDataWithDefault() {
    return this._value.userData || {};
  }

  get source() {
    return this._value.source;
  }

  get sourceWithDefault() {
    return this._value.source || '';
  }

  get signedDate() {
    return (this._value.signedDate !== undefined && this._value.signedDate !== null) ? dayjs(this._value.signedDate) : this._value.signedDate;
  }

  get consentContent() {
    return this._value.consentContent;
  }

  get consentContentWithDefault() {
    return this._value.consentContent || '';
  }

  get batchSentConsent() {
    return this._value.batchSentConsent;
  }

  get batchSentConsentWithDefault() {
    return this._value.batchSentConsent || false;
  }

  get enrolledBy() {
    return this._value.enrolledBy;
  }
}
export class EnrollmentStatusClass {
  protected readonly _value: EnrollmentStatus;

	constructor(value: EnrollmentStatus) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: EnrollmentStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }
}
export class EnrollmentRequestClass extends PatientBaseClass {
  protected readonly _value: EnrollmentRequest;

	constructor(value: EnrollmentRequest) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get programEnrollment() {
    return this._value.programEnrollment !== undefined && this._value.programEnrollment !== null ? new ProgramEnrollmentClass(this._value.programEnrollment) : this._value.programEnrollment;
  }

  get basicInformation() {
    return this._value.basicInformation !== undefined && this._value.basicInformation !== null ? new BasicInformationClass(this._value.basicInformation) : this._value.basicInformation;
  }

  get patientDevice() {
    return this._value.patientDevice !== undefined && this._value.patientDevice !== null ? new PatientDeviceClass(this._value.patientDevice) : this._value.patientDevice;
  }

  get loanDeviceRequest() {
    return this._value.loanDeviceRequest !== undefined && this._value.loanDeviceRequest !== null ? map(this._value.loanDeviceRequest, (v) => new LoanDeviceClass(v)) : this._value.loanDeviceRequest;
  }

  get loanDeviceRequestWithDefault() {
    return this._value.loanDeviceRequest || [];
  }

  get patientAssigneeIds() {
    return this._value.patientAssigneeIds;
  }

  get patientAssigneeIdsWithDefault() {
    return this._value.patientAssigneeIds || [];
  }

  get consentRequest() {
    return this._value.consentRequest !== undefined && this._value.consentRequest !== null ? new ConsentRequestClass(this._value.consentRequest) : this._value.consentRequest;
  }

  get action() {
    return this._value.action;
  }

  actionIsIn(values: EnrollmentActionEnum[] = []) {
    return this._value.action && values.includes(this._value.action);
  }

  get waiveTechOnboarding() {
    return this._value.waiveTechOnboarding;
  }

  get waiveTechOnboardingWithDefault() {
    return this._value.waiveTechOnboarding || false;
  }

  get statuses() {
    return this._value.statuses !== undefined && this._value.statuses !== null ? map(this._value.statuses, (v) => new EnrollmentStatusClass(v)) : this._value.statuses;
  }

  get statusesWithDefault() {
    return this._value.statuses || [];
  }

  get markEligible() {
    return this._value.markEligible;
  }

  get markEligibleWithDefault() {
    return this._value.markEligible || false;
  }

  get techOnboardingVisitEvent() {
    return this._value.techOnboardingVisitEvent !== undefined && this._value.techOnboardingVisitEvent !== null ? new ClinicEventClass(this._value.techOnboardingVisitEvent) : this._value.techOnboardingVisitEvent;
  }

  get initialAssessmentVisitEvent() {
    return this._value.initialAssessmentVisitEvent !== undefined && this._value.initialAssessmentVisitEvent !== null ? new ClinicEventClass(this._value.initialAssessmentVisitEvent) : this._value.initialAssessmentVisitEvent;
  }

  get diagnosesIn() {
    return this._value.diagnosesIn !== undefined && this._value.diagnosesIn !== null ? new InFilterClass(this._value.diagnosesIn) : this._value.diagnosesIn;
  }

  get providerIn() {
    return this._value.providerIn !== undefined && this._value.providerIn !== null ? new InFilterClass(this._value.providerIn) : this._value.providerIn;
  }

  get provider2In() {
    return this._value.provider2In !== undefined && this._value.provider2In !== null ? new InFilterClass(this._value.provider2In) : this._value.provider2In;
  }

  get provider3In() {
    return this._value.provider3In !== undefined && this._value.provider3In !== null ? new InFilterClass(this._value.provider3In) : this._value.provider3In;
  }

  get insuranceVerificationStatusIn() {
    return this._value.insuranceVerificationStatusIn !== undefined && this._value.insuranceVerificationStatusIn !== null ? new InFilterClass(this._value.insuranceVerificationStatusIn) : this._value.insuranceVerificationStatusIn;
  }

  get providerNear() {
    return this._value.providerNear;
  }

  get providerNearWithDefault() {
    return this._value.providerNear || '';
  }

  get provider2Near() {
    return this._value.provider2Near;
  }

  get provider2NearWithDefault() {
    return this._value.provider2Near || '';
  }

  get provider3Near() {
    return this._value.provider3Near;
  }

  get provider3NearWithDefault() {
    return this._value.provider3Near || '';
  }
}
export interface EnrollmentRequestListInsuranceProviderParams {
	request: SearchRequest<EnrollmentRequest>;
}

export class EnrollmentRequestService extends BaseController<EnrollmentRequest, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/enrollment-request';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public listInsuranceProvider({ baseUrl, params, headers }: RequestOption<EnrollmentRequestListInsuranceProviderParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<PatientInsurance>>>(`${baseUrl || this.baseURL}${this.basePath}/insurance-provider${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const enrollmentRequestService = new EnrollmentRequestService();

export const createEnrollmentRequestListInsuranceProviderObject = (value: APIResponse<PageResponse<PatientInsurance>>) => new APIResponseClass<PageResponseClass<PatientInsuranceClass>>(value, (value: PageResponse<PatientInsurance>) => new PageResponseClass<PatientInsuranceClass>(value, (value: PatientInsurance) => new PatientInsuranceClass(value)));

export const useEnrollmentRequestListInsuranceProvider = <RCN = undefined>(configs: MakeRequestHookConfig<EnrollmentRequestListInsuranceProviderParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    EnrollmentRequestListInsuranceProviderParams,
    APIResponse<PageResponse<PatientInsurance>>,
    typeof enrollmentRequestService.listInsuranceProvider,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientInsuranceClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof enrollmentRequestService.listInsuranceProvider>) => (
      enrollmentRequestService.listInsuranceProvider(...args)
    ),
    ResponseClassCreator: createEnrollmentRequestListInsuranceProviderObject,
    typeName: 'useEnrollmentRequestListInsuranceProvider',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useEnrollmentRequestListInsuranceProvider.mock = () => new Error('Mock has not been implemented!');
useEnrollmentRequestListInsuranceProvider.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEnrollmentRequestListInsuranceProvider.createObject = createEnrollmentRequestListInsuranceProviderObject;


export const createEnrollmentRequestGetObject = (value: APIResponse<EnrollmentRequest>) => new APIResponseClass<EnrollmentRequestClass>(value, (value: EnrollmentRequest) => new EnrollmentRequestClass(value));
export const useEnrollmentRequestGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<EnrollmentRequest>,
    // @ts-ignore
    typeof enrollmentRequestService.get,
    GetOptions,
    APIResponseClass<EnrollmentRequestClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof enrollmentRequestService.get>) => (
      enrollmentRequestService.get(...args)
    ),
    ResponseClassCreator: createEnrollmentRequestGetObject,
    typeName: 'useEnrollmentRequestGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useEnrollmentRequestGet.mock = () => new Error('Mock has not been implemented!');
useEnrollmentRequestGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEnrollmentRequestGet.createObject = createEnrollmentRequestGetObject;

export const createEnrollmentRequestUpdateObject = (value: APIResponse<EnrollmentRequest>) => new APIResponseClass<EnrollmentRequestClass>(value, (value: EnrollmentRequest) => new EnrollmentRequestClass(value));
export const useEnrollmentRequestUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, EnrollmentRequest>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, EnrollmentRequest>,
    APIResponse<EnrollmentRequest>,
    // @ts-ignore
    typeof enrollmentRequestService.update,
    AjaxOptions,
    APIResponseClass<EnrollmentRequestClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof enrollmentRequestService.update>) => (
      enrollmentRequestService.update(...args)
    ),
    ResponseClassCreator: createEnrollmentRequestUpdateObject,
    typeName: 'useEnrollmentRequestUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useEnrollmentRequestUpdate.mock = () => new Error('Mock has not been implemented!');
useEnrollmentRequestUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEnrollmentRequestUpdate.createObject = createEnrollmentRequestUpdateObject;

export const createEnrollmentRequestInsertObject = (value: APIResponse<EnrollmentRequest>) => new APIResponseClass<EnrollmentRequestClass>(value, (value: EnrollmentRequest) => new EnrollmentRequestClass(value));
export const useEnrollmentRequestInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<EnrollmentRequest>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<EnrollmentRequest>,
    APIResponse<EnrollmentRequest>,
    // @ts-ignore
    typeof enrollmentRequestService.insert,
    AjaxOptions,
    APIResponseClass<EnrollmentRequestClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof enrollmentRequestService.insert>) => (
      enrollmentRequestService.insert(...args)
    ),
    ResponseClassCreator: createEnrollmentRequestInsertObject,
    typeName: 'useEnrollmentRequestInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useEnrollmentRequestInsert.mock = () => new Error('Mock has not been implemented!');
useEnrollmentRequestInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEnrollmentRequestInsert.createObject = createEnrollmentRequestInsertObject;

export const createEnrollmentRequestDeleteObject = (value: APIResponse<EnrollmentRequest>) => new APIResponseClass<EnrollmentRequestClass>(value, (value: EnrollmentRequest) => new EnrollmentRequestClass(value));
export const useEnrollmentRequestDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<EnrollmentRequest>,
    // @ts-ignore
    typeof enrollmentRequestService.delete,
    AjaxOptions,
    APIResponseClass<EnrollmentRequestClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof enrollmentRequestService.delete>) => (
      enrollmentRequestService.delete(...args)
    ),
    ResponseClassCreator: createEnrollmentRequestDeleteObject,
    typeName: 'useEnrollmentRequestDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useEnrollmentRequestDelete.mock = () => new Error('Mock has not been implemented!');
useEnrollmentRequestDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEnrollmentRequestDelete.createObject = createEnrollmentRequestDeleteObject;

export const createEnrollmentRequestSearchObject = (value: APIResponse<PageResponse<EnrollmentRequest>>) => new APIResponseClass<PageResponseClass<EnrollmentRequestClass>>(value, (value: PageResponse<EnrollmentRequest>) => new PageResponseClass<EnrollmentRequestClass>(value, (value: EnrollmentRequest) => new EnrollmentRequestClass(value)));
export const useEnrollmentRequestSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<EnrollmentRequest>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<EnrollmentRequest>,
    APIResponse<PageResponse<EnrollmentRequest>>,
    // @ts-ignore
    typeof enrollmentRequestService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<EnrollmentRequestClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof enrollmentRequestService.search>) => (
      enrollmentRequestService.search(...args)
    ),
    ResponseClassCreator: createEnrollmentRequestSearchObject,
    typeName: 'useEnrollmentRequestSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useEnrollmentRequestSearch.mock = () => new Error('Mock has not been implemented!');
useEnrollmentRequestSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useEnrollmentRequestSearch.createObject = createEnrollmentRequestSearchObject;

export interface PatientDiscoveryCriteria {
	firstName?: Nullable<string>;
	lastName?: Nullable<string>;
	gender?: Nullable<string>;
	birthDate?: Nullable<string>;
	street?: Nullable<string>;
	city?: Nullable<string>;
	state?: Nullable<string>;
	zipCode?: Nullable<string>;
	county?: Nullable<string>;
	country?: Nullable<string>;
	phone?: Nullable<string>;
}
export interface FhirPatientGetPatientParams {
	memberId: string;
}

export interface FhirPatientPatientListParams {
	criteria: PatientDiscoveryCriteria;
}

export class FhirPatientService {
  protected baseURL = '';

  protected basePath = '//fhir/patients';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public getPatient({ baseUrl, params, headers }: RequestOption<FhirPatientGetPatientParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}${getQueryStrings(queryList)}`, { headers });
	}

	public patientList({ baseUrl, params, headers }: RequestOption<FhirPatientPatientListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string[]>>(`${baseUrl || this.baseURL}${this.basePath}/list${getQueryStrings(queryList)}`, params.criteria, { headers });
	}
}

export const fhirPatientService = new FhirPatientService();

export const createFhirPatientGetPatientObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useFhirPatientGetPatient = <RCN = undefined>(configs: MakeRequestHookConfig<FhirPatientGetPatientParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    FhirPatientGetPatientParams,
    APIResponse<string>,
    typeof fhirPatientService.getPatient,
    GetOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof fhirPatientService.getPatient>) => (
      fhirPatientService.getPatient(...args)
    ),
    ResponseClassCreator: createFhirPatientGetPatientObject,
    typeName: 'useFhirPatientGetPatient',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useFhirPatientGetPatient.mock = () => new Error('Mock has not been implemented!');
useFhirPatientGetPatient.getLastMockedValue = () => new Error('Mock has not been implemented!');
useFhirPatientGetPatient.createObject = createFhirPatientGetPatientObject;

export const createFhirPatientPatientListObject = (value: APIResponse<string[]>) => new APIResponseClass<string[]>(value, (v) => (v));

export const useFhirPatientPatientList = <RCN = undefined>(configs: MakeRequestHookConfig<FhirPatientPatientListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    FhirPatientPatientListParams,
    APIResponse<string[]>,
    typeof fhirPatientService.patientList,
    AjaxOptions,
    APIResponseClass<string[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof fhirPatientService.patientList>) => (
      fhirPatientService.patientList(...args)
    ),
    ResponseClassCreator: createFhirPatientPatientListObject,
    typeName: 'useFhirPatientPatientList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useFhirPatientPatientList.mock = () => new Error('Mock has not been implemented!');
useFhirPatientPatientList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useFhirPatientPatientList.createObject = createFhirPatientPatientListObject;
export enum BucketsTypeEnum {
	PRIVATEIMAGEUPLOAD = 'PRIVATEIMAGEUPLOAD',
	LOGOSUPLOAD = 'LOGOSUPLOAD',
	FILESUPLOAD = 'FILESUPLOAD',
	CAREPLANPDFUPLOAD = 'CAREPLANPDFUPLOAD',
	FEATURESUPLOAD = 'FEATURESUPLOAD',
	PATIENTCONSENT = 'PATIENTCONSENT',
	REWARDREDEEM = 'REWARDREDEEM',
	TRANSCRIBE = 'TRANSCRIBE',
	INVOICE = 'INVOICE',
	CSVUPLOAD = 'CSVUPLOAD',
}
export enum FileCategoryEnum {
	AVATAR = 'AVATAR',
	FOOD_LOG = 'FOOD_LOG',
	FILE_UPLOAD = 'FILE_UPLOAD',
	SIGNATURE = 'SIGNATURE',
	PREVIEW = 'PREVIEW',
	BATCH_CONSENT_PATIENT_LIST = 'BATCH_CONSENT_PATIENT_LIST',
	value = 'value',
}
export interface FileDownloadUrlInput {
	bucket?: Nullable<BucketsTypeEnum>;
	fileKey?: Nullable<string>;
	imageType?: Nullable<ImageTypeEnum>;
}
export interface FileManagerFileUploadParams {
	bucket: BucketsTypeEnum;
	category?: FileCategoryEnum;
	id?: string;
	file: MultipartFile;
}

export interface FileManagerDownloadUrlParams {
	fileDownloadUrlInput: FileDownloadUrlInput;
}

export class FileManagerService {
  protected baseURL = '';

  protected basePath = '/v1/uc/file-manager';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public fileUpload({ baseUrl, params, headers }: RequestOption<FileManagerFileUploadParams>) {
		const queryList: QueryParam[] = [];
		if (params.bucket !== undefined && params.bucket !== null) {
			queryList.push({ name: 'bucket', value: params.bucket.toString() });
		}
		if (params.category !== undefined && params.category !== null) {
			queryList.push({ name: 'category', value: params.category.toString() });
		}
		if (params.id !== undefined && params.id !== null) {
			queryList.push({ name: 'id', value: params.id.toString() });
		}
		if (params.file !== undefined && params.file !== null) {
			queryList.push({ name: 'file', value: params.file.toString() });
		}
		return axios.put<APIResponse<FileDownloadUrlOutput>>(`${baseUrl || this.baseURL}${this.basePath}/upload${getQueryStrings(queryList)}`, {}, { headers });
	}

	public downloadUrl({ baseUrl, params, headers }: RequestOption<FileManagerDownloadUrlParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<FileDownloadUrlOutput>>(`${baseUrl || this.baseURL}${this.basePath}/download-url${getQueryStrings(queryList)}`, params.fileDownloadUrlInput, { headers });
	}
}

export const fileManagerService = new FileManagerService();

export const createFileManagerFileUploadObject = (value: APIResponse<FileDownloadUrlOutput>) => new APIResponseClass<FileDownloadUrlOutputClass>(value, (value: FileDownloadUrlOutput) => new FileDownloadUrlOutputClass(value));

export const useFileManagerFileUpload = <RCN = undefined>(configs: MakeRequestHookConfig<FileManagerFileUploadParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    FileManagerFileUploadParams,
    APIResponse<FileDownloadUrlOutput>,
    typeof fileManagerService.fileUpload,
    AjaxOptions,
    APIResponseClass<FileDownloadUrlOutputClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof fileManagerService.fileUpload>) => (
      fileManagerService.fileUpload(...args)
    ),
    ResponseClassCreator: createFileManagerFileUploadObject,
    typeName: 'useFileManagerFileUpload',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useFileManagerFileUpload.mock = () => new Error('Mock has not been implemented!');
useFileManagerFileUpload.getLastMockedValue = () => new Error('Mock has not been implemented!');
useFileManagerFileUpload.createObject = createFileManagerFileUploadObject;

export const createFileManagerDownloadUrlObject = (value: APIResponse<FileDownloadUrlOutput>) => new APIResponseClass<FileDownloadUrlOutputClass>(value, (value: FileDownloadUrlOutput) => new FileDownloadUrlOutputClass(value));

export const useFileManagerDownloadUrl = <RCN = undefined>(configs: MakeRequestHookConfig<FileManagerDownloadUrlParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    FileManagerDownloadUrlParams,
    APIResponse<FileDownloadUrlOutput>,
    typeof fileManagerService.downloadUrl,
    AjaxOptions,
    APIResponseClass<FileDownloadUrlOutputClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof fileManagerService.downloadUrl>) => (
      fileManagerService.downloadUrl(...args)
    ),
    ResponseClassCreator: createFileManagerDownloadUrlObject,
    typeName: 'useFileManagerDownloadUrl',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useFileManagerDownloadUrl.mock = () => new Error('Mock has not been implemented!');
useFileManagerDownloadUrl.getLastMockedValue = () => new Error('Mock has not been implemented!');
useFileManagerDownloadUrl.createObject = createFileManagerDownloadUrlObject;
export interface BloodGlucose {
	value?: Nullable<number>;
	unit?: Nullable<string>;
}
export interface FoodLogBgReading {
	beforeMeal?: Nullable<boolean>;
	bloodGlucose?: Nullable<BloodGlucose>;
	bgUploadedAt?: Nullable<string>;
	bgReferenceId?: Nullable<string>;
}
export enum RatingCategoryEnum {
	EXCELLENT = 'EXCELLENT',
	POOR = 'POOR',
}
export interface FoodLogRatingCategory {
	carb?: Nullable<RatingCategoryEnum>;
	fat?: Nullable<RatingCategoryEnum>;
	protein?: Nullable<RatingCategoryEnum>;
	vegetable?: Nullable<RatingCategoryEnum>;
	fruit?: Nullable<RatingCategoryEnum>;
}
export interface Rating {
	star?: Nullable<number>;
	category?: Nullable<FoodLogRatingCategory>;
}
export interface Comment {
	originalText?: Nullable<string>;
	text?: Nullable<string>;
	translatedText?: Nullable<string>;
	commentedById?: Nullable<string>;
	commentedAt?: Nullable<string>;
	commentedByUser?: Nullable<LimitedUser>;
}
export interface FoodLog extends BaseModel {
	memberId?: Nullable<string>;
	organizationId?: Nullable<string>;
	note?: Nullable<string>;
	mealType?: Nullable<BgMealTypeEnum>;
	beforeMealBg?: Nullable<FoodLogBgReading>;
	afterMealBg?: Nullable<FoodLogBgReading>;
	timezone?: Nullable<string>;
	needReview?: Nullable<boolean>;
	rating?: Nullable<Rating>;
	uploadedAt?: Nullable<string>;
	images?: Nullable<ImageSchema[]>;
	comments?: Nullable<Comment[]>;
	source?: Nullable<string>;
	updatedBy?: Nullable<string>;
	organizationIdIn?: Nullable<InFilter<string>>;
	memberIdIn?: Nullable<InFilter<string>>;
	uploadedAtRange?: Nullable<DateRangeFilter>;
	fileKeyNear?: Nullable<string>;
	ratingStarIn?: Nullable<InFilter<number>>;
}
export class BloodGlucoseClass {
  protected readonly _value: BloodGlucose;

	constructor(value: BloodGlucose) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || 0.0;
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }
}
export class FoodLogBgReadingClass {
  protected readonly _value: FoodLogBgReading;

	constructor(value: FoodLogBgReading) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get beforeMeal() {
    return this._value.beforeMeal;
  }

  get beforeMealWithDefault() {
    return this._value.beforeMeal || false;
  }

  get bloodGlucose() {
    return this._value.bloodGlucose !== undefined && this._value.bloodGlucose !== null ? new BloodGlucoseClass(this._value.bloodGlucose) : this._value.bloodGlucose;
  }

  get bgUploadedAt() {
    return (this._value.bgUploadedAt !== undefined && this._value.bgUploadedAt !== null) ? dayjs(this._value.bgUploadedAt) : this._value.bgUploadedAt;
  }

  get bgReferenceId() {
    return this._value.bgReferenceId;
  }

  get bgReferenceIdWithDefault() {
    return this._value.bgReferenceId || '';
  }
}
export class FoodLogRatingCategoryClass {
  protected readonly _value: FoodLogRatingCategory;

	constructor(value: FoodLogRatingCategory) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get carb() {
    return this._value.carb;
  }

  carbIsIn(values: RatingCategoryEnum[] = []) {
    return this._value.carb && values.includes(this._value.carb);
  }

  get fat() {
    return this._value.fat;
  }

  fatIsIn(values: RatingCategoryEnum[] = []) {
    return this._value.fat && values.includes(this._value.fat);
  }

  get protein() {
    return this._value.protein;
  }

  proteinIsIn(values: RatingCategoryEnum[] = []) {
    return this._value.protein && values.includes(this._value.protein);
  }

  get vegetable() {
    return this._value.vegetable;
  }

  vegetableIsIn(values: RatingCategoryEnum[] = []) {
    return this._value.vegetable && values.includes(this._value.vegetable);
  }

  get fruit() {
    return this._value.fruit;
  }

  fruitIsIn(values: RatingCategoryEnum[] = []) {
    return this._value.fruit && values.includes(this._value.fruit);
  }
}
export class RatingClass {
  protected readonly _value: Rating;

	constructor(value: Rating) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get star() {
    return this._value.star;
  }

  get starWithDefault() {
    return this._value.star || 0;
  }

  get category() {
    return this._value.category !== undefined && this._value.category !== null ? new FoodLogRatingCategoryClass(this._value.category) : this._value.category;
  }
}
export class CommentClass {
  protected readonly _value: Comment;

	constructor(value: Comment) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get originalText() {
    return this._value.originalText;
  }

  get originalTextWithDefault() {
    return this._value.originalText || '';
  }

  get text() {
    return this._value.text;
  }

  get textWithDefault() {
    return this._value.text || '';
  }

  get translatedText() {
    return this._value.translatedText;
  }

  get translatedTextWithDefault() {
    return this._value.translatedText || '';
  }

  get commentedById() {
    return this._value.commentedById;
  }

  get commentedByIdWithDefault() {
    return this._value.commentedById || '';
  }

  get commentedAt() {
    return (this._value.commentedAt !== undefined && this._value.commentedAt !== null) ? dayjs(this._value.commentedAt) : this._value.commentedAt;
  }

  get commentedByUser() {
    return this._value.commentedByUser !== undefined && this._value.commentedByUser !== null ? new LimitedUserClass(this._value.commentedByUser) : this._value.commentedByUser;
  }
}
export class FoodLogClass extends BaseModelClass {
  protected readonly _value: FoodLog;

	constructor(value: FoodLog) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get mealType() {
    return this._value.mealType;
  }

  mealTypeIsIn(values: BgMealTypeEnum[] = []) {
    return this._value.mealType && values.includes(this._value.mealType);
  }

  get beforeMealBg() {
    return this._value.beforeMealBg !== undefined && this._value.beforeMealBg !== null ? new FoodLogBgReadingClass(this._value.beforeMealBg) : this._value.beforeMealBg;
  }

  get afterMealBg() {
    return this._value.afterMealBg !== undefined && this._value.afterMealBg !== null ? new FoodLogBgReadingClass(this._value.afterMealBg) : this._value.afterMealBg;
  }

  get timezone() {
    return this._value.timezone;
  }

  get timezoneWithDefault() {
    return this._value.timezone || '';
  }

  get needReview() {
    return this._value.needReview;
  }

  get needReviewWithDefault() {
    return this._value.needReview || false;
  }

  get rating() {
    return this._value.rating !== undefined && this._value.rating !== null ? new RatingClass(this._value.rating) : this._value.rating;
  }

  get uploadedAt() {
    return (this._value.uploadedAt !== undefined && this._value.uploadedAt !== null) ? dayjs(this._value.uploadedAt) : this._value.uploadedAt;
  }

  get images() {
    return this._value.images !== undefined && this._value.images !== null ? map(this._value.images, (v) => new ImageSchemaClass(v)) : this._value.images;
  }

  get imagesWithDefault() {
    return this._value.images || [];
  }

  get comments() {
    return this._value.comments !== undefined && this._value.comments !== null ? map(this._value.comments, (v) => new CommentClass(v)) : this._value.comments;
  }

  get commentsWithDefault() {
    return this._value.comments || [];
  }

  get source() {
    return this._value.source;
  }

  get sourceWithDefault() {
    return this._value.source || '';
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get organizationIdIn() {
    return this._value.organizationIdIn !== undefined && this._value.organizationIdIn !== null ? new InFilterClass(this._value.organizationIdIn) : this._value.organizationIdIn;
  }

  get memberIdIn() {
    return this._value.memberIdIn !== undefined && this._value.memberIdIn !== null ? new InFilterClass(this._value.memberIdIn) : this._value.memberIdIn;
  }

  get uploadedAtRange() {
    return this._value.uploadedAtRange !== undefined && this._value.uploadedAtRange !== null ? new DateRangeFilterClass(this._value.uploadedAtRange) : this._value.uploadedAtRange;
  }

  get fileKeyNear() {
    return this._value.fileKeyNear;
  }

  get fileKeyNearWithDefault() {
    return this._value.fileKeyNear || '';
  }

  get ratingStarIn() {
    return this._value.ratingStarIn !== undefined && this._value.ratingStarIn !== null ? new InFilterClass(this._value.ratingStarIn) : this._value.ratingStarIn;
  }
}
export interface FoodLogRatingInput {
	star?: Nullable<number>;
	category?: Nullable<FoodLogRatingCategory>;
}
export interface FoodLogCommentInput {
	text?: Nullable<string>;
	originalText?: Nullable<string>;
	translatedText?: Nullable<string>;
}
export interface FoodLogGetFoodLogListParams {
	patientId: string;
}

export interface FoodLogGetLastFoodLogParams {
	patientId: string;
}

export interface FoodLogGetTotalFoodLogSizeParams {
	patientId: string;
}

export interface FoodLogRateParams {
	foodLogId: string;
	foodLogRatingInput: FoodLogRatingInput;
}

export interface FoodLogAddCommentParams {
	foodLogId: string;
	foodLogCommentInput: FoodLogCommentInput;
}

export class FoodLogService extends BaseController<FoodLog, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/food-log';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getFoodLogList({ baseUrl, params, headers }: RequestOption<FoodLogGetFoodLogListParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<FoodLog[]>>(`${baseUrl || this.baseURL}${this.basePath}/list/${params.patientId}${getQueryStrings(queryList)}`, { headers });
	}

	public getLastFoodLog({ baseUrl, params, headers }: RequestOption<FoodLogGetLastFoodLogParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<FoodLog>>(`${baseUrl || this.baseURL}${this.basePath}/last-log/${params.patientId}${getQueryStrings(queryList)}`, { headers });
	}

	public getTotalFoodLogSize({ baseUrl, params, headers }: RequestOption<FoodLogGetTotalFoodLogSizeParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<number>>(`${baseUrl || this.baseURL}${this.basePath}/total-size/${params.patientId}${getQueryStrings(queryList)}`, { headers });
	}

	public rate({ baseUrl, params, headers }: RequestOption<FoodLogRateParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<FoodLog>>(`${baseUrl || this.baseURL}${this.basePath}/${params.foodLogId}/rating${getQueryStrings(queryList)}`, params.foodLogRatingInput, { headers });
	}

	public addComment({ baseUrl, params, headers }: RequestOption<FoodLogAddCommentParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<FoodLog>>(`${baseUrl || this.baseURL}${this.basePath}/${params.foodLogId}/comment${getQueryStrings(queryList)}`, params.foodLogCommentInput, { headers });
	}
}

export const foodLogService = new FoodLogService();

export const createFoodLogGetFoodLogListObject = (value: APIResponse<FoodLog[]>) => new APIResponseClass<FoodLogClass[]>(value, (v) => map(v, (value: FoodLog) => new FoodLogClass(value)));

export const useFoodLogGetFoodLogList = <RCN = undefined>(configs: MakeRequestHookConfig<FoodLogGetFoodLogListParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    FoodLogGetFoodLogListParams,
    APIResponse<FoodLog[]>,
    typeof foodLogService.getFoodLogList,
    GetOptions,
    APIResponseClass<FoodLogClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof foodLogService.getFoodLogList>) => (
      foodLogService.getFoodLogList(...args)
    ),
    ResponseClassCreator: createFoodLogGetFoodLogListObject,
    typeName: 'useFoodLogGetFoodLogList',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useFoodLogGetFoodLogList.mock = () => new Error('Mock has not been implemented!');
useFoodLogGetFoodLogList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useFoodLogGetFoodLogList.createObject = createFoodLogGetFoodLogListObject;

export const createFoodLogGetLastFoodLogObject = (value: APIResponse<FoodLog>) => new APIResponseClass<FoodLogClass>(value, (value: FoodLog) => new FoodLogClass(value));

export const useFoodLogGetLastFoodLog = <RCN = undefined>(configs: MakeRequestHookConfig<FoodLogGetLastFoodLogParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    FoodLogGetLastFoodLogParams,
    APIResponse<FoodLog>,
    typeof foodLogService.getLastFoodLog,
    GetOptions,
    APIResponseClass<FoodLogClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof foodLogService.getLastFoodLog>) => (
      foodLogService.getLastFoodLog(...args)
    ),
    ResponseClassCreator: createFoodLogGetLastFoodLogObject,
    typeName: 'useFoodLogGetLastFoodLog',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useFoodLogGetLastFoodLog.mock = () => new Error('Mock has not been implemented!');
useFoodLogGetLastFoodLog.getLastMockedValue = () => new Error('Mock has not been implemented!');
useFoodLogGetLastFoodLog.createObject = createFoodLogGetLastFoodLogObject;

export const createFoodLogGetTotalFoodLogSizeObject = (value: APIResponse<number>) => new APIResponseClass<number>(value, (v) => (v));

export const useFoodLogGetTotalFoodLogSize = <RCN = undefined>(configs: MakeRequestHookConfig<FoodLogGetTotalFoodLogSizeParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    FoodLogGetTotalFoodLogSizeParams,
    APIResponse<number>,
    typeof foodLogService.getTotalFoodLogSize,
    GetOptions,
    APIResponseClass<number>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof foodLogService.getTotalFoodLogSize>) => (
      foodLogService.getTotalFoodLogSize(...args)
    ),
    ResponseClassCreator: createFoodLogGetTotalFoodLogSizeObject,
    typeName: 'useFoodLogGetTotalFoodLogSize',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useFoodLogGetTotalFoodLogSize.mock = () => new Error('Mock has not been implemented!');
useFoodLogGetTotalFoodLogSize.getLastMockedValue = () => new Error('Mock has not been implemented!');
useFoodLogGetTotalFoodLogSize.createObject = createFoodLogGetTotalFoodLogSizeObject;

export const createFoodLogRateObject = (value: APIResponse<FoodLog>) => new APIResponseClass<FoodLogClass>(value, (value: FoodLog) => new FoodLogClass(value));

export const useFoodLogRate = <RCN = undefined>(configs: MakeRequestHookConfig<FoodLogRateParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    FoodLogRateParams,
    APIResponse<FoodLog>,
    typeof foodLogService.rate,
    AjaxOptions,
    APIResponseClass<FoodLogClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof foodLogService.rate>) => (
      foodLogService.rate(...args)
    ),
    ResponseClassCreator: createFoodLogRateObject,
    typeName: 'useFoodLogRate',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useFoodLogRate.mock = () => new Error('Mock has not been implemented!');
useFoodLogRate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useFoodLogRate.createObject = createFoodLogRateObject;

export const createFoodLogAddCommentObject = (value: APIResponse<FoodLog>) => new APIResponseClass<FoodLogClass>(value, (value: FoodLog) => new FoodLogClass(value));

export const useFoodLogAddComment = <RCN = undefined>(configs: MakeRequestHookConfig<FoodLogAddCommentParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    FoodLogAddCommentParams,
    APIResponse<FoodLog>,
    typeof foodLogService.addComment,
    AjaxOptions,
    APIResponseClass<FoodLogClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof foodLogService.addComment>) => (
      foodLogService.addComment(...args)
    ),
    ResponseClassCreator: createFoodLogAddCommentObject,
    typeName: 'useFoodLogAddComment',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useFoodLogAddComment.mock = () => new Error('Mock has not been implemented!');
useFoodLogAddComment.getLastMockedValue = () => new Error('Mock has not been implemented!');
useFoodLogAddComment.createObject = createFoodLogAddCommentObject;


export const createFoodLogGetObject = (value: APIResponse<FoodLog>) => new APIResponseClass<FoodLogClass>(value, (value: FoodLog) => new FoodLogClass(value));
export const useFoodLogGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<FoodLog>,
    // @ts-ignore
    typeof foodLogService.get,
    GetOptions,
    APIResponseClass<FoodLogClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof foodLogService.get>) => (
      foodLogService.get(...args)
    ),
    ResponseClassCreator: createFoodLogGetObject,
    typeName: 'useFoodLogGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useFoodLogGet.mock = () => new Error('Mock has not been implemented!');
useFoodLogGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useFoodLogGet.createObject = createFoodLogGetObject;

export const createFoodLogUpdateObject = (value: APIResponse<FoodLog>) => new APIResponseClass<FoodLogClass>(value, (value: FoodLog) => new FoodLogClass(value));
export const useFoodLogUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, FoodLog>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, FoodLog>,
    APIResponse<FoodLog>,
    // @ts-ignore
    typeof foodLogService.update,
    AjaxOptions,
    APIResponseClass<FoodLogClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof foodLogService.update>) => (
      foodLogService.update(...args)
    ),
    ResponseClassCreator: createFoodLogUpdateObject,
    typeName: 'useFoodLogUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useFoodLogUpdate.mock = () => new Error('Mock has not been implemented!');
useFoodLogUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useFoodLogUpdate.createObject = createFoodLogUpdateObject;

export const createFoodLogInsertObject = (value: APIResponse<FoodLog>) => new APIResponseClass<FoodLogClass>(value, (value: FoodLog) => new FoodLogClass(value));
export const useFoodLogInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<FoodLog>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<FoodLog>,
    APIResponse<FoodLog>,
    // @ts-ignore
    typeof foodLogService.insert,
    AjaxOptions,
    APIResponseClass<FoodLogClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof foodLogService.insert>) => (
      foodLogService.insert(...args)
    ),
    ResponseClassCreator: createFoodLogInsertObject,
    typeName: 'useFoodLogInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useFoodLogInsert.mock = () => new Error('Mock has not been implemented!');
useFoodLogInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useFoodLogInsert.createObject = createFoodLogInsertObject;

export const createFoodLogDeleteObject = (value: APIResponse<FoodLog>) => new APIResponseClass<FoodLogClass>(value, (value: FoodLog) => new FoodLogClass(value));
export const useFoodLogDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<FoodLog>,
    // @ts-ignore
    typeof foodLogService.delete,
    AjaxOptions,
    APIResponseClass<FoodLogClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof foodLogService.delete>) => (
      foodLogService.delete(...args)
    ),
    ResponseClassCreator: createFoodLogDeleteObject,
    typeName: 'useFoodLogDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useFoodLogDelete.mock = () => new Error('Mock has not been implemented!');
useFoodLogDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useFoodLogDelete.createObject = createFoodLogDeleteObject;

export const createFoodLogSearchObject = (value: APIResponse<PageResponse<FoodLog>>) => new APIResponseClass<PageResponseClass<FoodLogClass>>(value, (value: PageResponse<FoodLog>) => new PageResponseClass<FoodLogClass>(value, (value: FoodLog) => new FoodLogClass(value)));
export const useFoodLogSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<FoodLog>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<FoodLog>,
    APIResponse<PageResponse<FoodLog>>,
    // @ts-ignore
    typeof foodLogService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<FoodLogClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof foodLogService.search>) => (
      foodLogService.search(...args)
    ),
    ResponseClassCreator: createFoodLogSearchObject,
    typeName: 'useFoodLogSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useFoodLogSearch.mock = () => new Error('Mock has not been implemented!');
useFoodLogSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useFoodLogSearch.createObject = createFoodLogSearchObject;

export enum GeneralConfigTypeEnum {
	CALL_CENTER_PHONE_NUMBER = 'CALL_CENTER_PHONE_NUMBER',
}
export interface GeneralConfig<T> extends BaseModel {
	doc?: Nullable<T>;
	configType?: Nullable<GeneralConfigTypeEnum>;
	classNameIn?: Nullable<InFilter<string>>;
}
export class GeneralConfigClass<T> extends BaseModelClass {
  protected readonly _value: GeneralConfig<any>;
	protected readonly docConstructorFunc?: (value: any) => T;

	constructor(value: GeneralConfig<any>, docConstructorFunc?: (value: any) => T) {
		super(value);
		this._value = value;
		this.docConstructorFunc = docConstructorFunc;
	}

  get rawValue() {
    return this._value;
  }

  get doc(): T {
    return this.docConstructorFunc ? (this._value.doc && this.docConstructorFunc(this._value.doc)) : this._value.doc;
  }

  get configType() {
    return this._value.configType;
  }

  configTypeIsIn(values: GeneralConfigTypeEnum[] = []) {
    return this._value.configType && values.includes(this._value.configType);
  }

  get classNameIn() {
    return this._value.classNameIn !== undefined && this._value.classNameIn !== null ? new InFilterClass(this._value.classNameIn) : this._value.classNameIn;
  }
}
export class GeneralConfigService extends BaseController<GeneralConfig, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/general-config';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const generalConfigService = new GeneralConfigService();


export const createGeneralConfigGetObject = (value: APIResponse<GeneralConfig>) => new APIResponseClass<GeneralConfigClass>(value, (value: GeneralConfig) => new GeneralConfigClass(value));
export const useGeneralConfigGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<GeneralConfig>,
    // @ts-ignore
    typeof generalConfigService.get,
    GetOptions,
    APIResponseClass<GeneralConfigClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof generalConfigService.get>) => (
      generalConfigService.get(...args)
    ),
    ResponseClassCreator: createGeneralConfigGetObject,
    typeName: 'useGeneralConfigGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useGeneralConfigGet.mock = () => new Error('Mock has not been implemented!');
useGeneralConfigGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useGeneralConfigGet.createObject = createGeneralConfigGetObject;

export const createGeneralConfigUpdateObject = (value: APIResponse<GeneralConfig>) => new APIResponseClass<GeneralConfigClass>(value, (value: GeneralConfig) => new GeneralConfigClass(value));
export const useGeneralConfigUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, GeneralConfig>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, GeneralConfig>,
    APIResponse<GeneralConfig>,
    // @ts-ignore
    typeof generalConfigService.update,
    AjaxOptions,
    APIResponseClass<GeneralConfigClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof generalConfigService.update>) => (
      generalConfigService.update(...args)
    ),
    ResponseClassCreator: createGeneralConfigUpdateObject,
    typeName: 'useGeneralConfigUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useGeneralConfigUpdate.mock = () => new Error('Mock has not been implemented!');
useGeneralConfigUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useGeneralConfigUpdate.createObject = createGeneralConfigUpdateObject;

export const createGeneralConfigInsertObject = (value: APIResponse<GeneralConfig>) => new APIResponseClass<GeneralConfigClass>(value, (value: GeneralConfig) => new GeneralConfigClass(value));
export const useGeneralConfigInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<GeneralConfig>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<GeneralConfig>,
    APIResponse<GeneralConfig>,
    // @ts-ignore
    typeof generalConfigService.insert,
    AjaxOptions,
    APIResponseClass<GeneralConfigClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof generalConfigService.insert>) => (
      generalConfigService.insert(...args)
    ),
    ResponseClassCreator: createGeneralConfigInsertObject,
    typeName: 'useGeneralConfigInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useGeneralConfigInsert.mock = () => new Error('Mock has not been implemented!');
useGeneralConfigInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useGeneralConfigInsert.createObject = createGeneralConfigInsertObject;

export const createGeneralConfigDeleteObject = (value: APIResponse<GeneralConfig>) => new APIResponseClass<GeneralConfigClass>(value, (value: GeneralConfig) => new GeneralConfigClass(value));
export const useGeneralConfigDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<GeneralConfig>,
    // @ts-ignore
    typeof generalConfigService.delete,
    AjaxOptions,
    APIResponseClass<GeneralConfigClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof generalConfigService.delete>) => (
      generalConfigService.delete(...args)
    ),
    ResponseClassCreator: createGeneralConfigDeleteObject,
    typeName: 'useGeneralConfigDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useGeneralConfigDelete.mock = () => new Error('Mock has not been implemented!');
useGeneralConfigDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useGeneralConfigDelete.createObject = createGeneralConfigDeleteObject;

export const createGeneralConfigSearchObject = (value: APIResponse<PageResponse<GeneralConfig>>) => new APIResponseClass<PageResponseClass<GeneralConfigClass>>(value, (value: PageResponse<GeneralConfig>) => new PageResponseClass<GeneralConfigClass>(value, (value: GeneralConfig) => new GeneralConfigClass(value)));
export const useGeneralConfigSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<GeneralConfig>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<GeneralConfig>,
    APIResponse<PageResponse<GeneralConfig>>,
    // @ts-ignore
    typeof generalConfigService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<GeneralConfigClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof generalConfigService.search>) => (
      generalConfigService.search(...args)
    ),
    ResponseClassCreator: createGeneralConfigSearchObject,
    typeName: 'useGeneralConfigSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useGeneralConfigSearch.mock = () => new Error('Mock has not been implemented!');
useGeneralConfigSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useGeneralConfigSearch.createObject = createGeneralConfigSearchObject;

export interface Handout extends BaseModel {
	language?: Nullable<string[]>;
	title?: Nullable<string>;
	code?: Nullable<string>;
	category?: Nullable<string>;
	weblink?: Nullable<string>;
}
export class HandoutClass extends BaseModelClass {
  protected readonly _value: Handout;

	constructor(value: Handout) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get language() {
    return this._value.language;
  }

  get languageWithDefault() {
    return this._value.language || [];
  }

  get title() {
    return this._value.title;
  }

  get titleWithDefault() {
    return this._value.title || '';
  }

  get code() {
    return this._value.code;
  }

  get codeWithDefault() {
    return this._value.code || '';
  }

  get category() {
    return this._value.category;
  }

  get categoryWithDefault() {
    return this._value.category || '';
  }

  get weblink() {
    return this._value.weblink;
  }

  get weblinkWithDefault() {
    return this._value.weblink || '';
  }
}
export class HandoutService extends BaseController<Handout, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/handout';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const handoutService = new HandoutService();


export const createHandoutGetObject = (value: APIResponse<Handout>) => new APIResponseClass<HandoutClass>(value, (value: Handout) => new HandoutClass(value));
export const useHandoutGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<Handout>,
    // @ts-ignore
    typeof handoutService.get,
    GetOptions,
    APIResponseClass<HandoutClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof handoutService.get>) => (
      handoutService.get(...args)
    ),
    ResponseClassCreator: createHandoutGetObject,
    typeName: 'useHandoutGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useHandoutGet.mock = () => new Error('Mock has not been implemented!');
useHandoutGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useHandoutGet.createObject = createHandoutGetObject;

export const createHandoutUpdateObject = (value: APIResponse<Handout>) => new APIResponseClass<HandoutClass>(value, (value: Handout) => new HandoutClass(value));
export const useHandoutUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, Handout>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, Handout>,
    APIResponse<Handout>,
    // @ts-ignore
    typeof handoutService.update,
    AjaxOptions,
    APIResponseClass<HandoutClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof handoutService.update>) => (
      handoutService.update(...args)
    ),
    ResponseClassCreator: createHandoutUpdateObject,
    typeName: 'useHandoutUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useHandoutUpdate.mock = () => new Error('Mock has not been implemented!');
useHandoutUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useHandoutUpdate.createObject = createHandoutUpdateObject;

export const createHandoutInsertObject = (value: APIResponse<Handout>) => new APIResponseClass<HandoutClass>(value, (value: Handout) => new HandoutClass(value));
export const useHandoutInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<Handout>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<Handout>,
    APIResponse<Handout>,
    // @ts-ignore
    typeof handoutService.insert,
    AjaxOptions,
    APIResponseClass<HandoutClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof handoutService.insert>) => (
      handoutService.insert(...args)
    ),
    ResponseClassCreator: createHandoutInsertObject,
    typeName: 'useHandoutInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useHandoutInsert.mock = () => new Error('Mock has not been implemented!');
useHandoutInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useHandoutInsert.createObject = createHandoutInsertObject;

export const createHandoutDeleteObject = (value: APIResponse<Handout>) => new APIResponseClass<HandoutClass>(value, (value: Handout) => new HandoutClass(value));
export const useHandoutDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<Handout>,
    // @ts-ignore
    typeof handoutService.delete,
    AjaxOptions,
    APIResponseClass<HandoutClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof handoutService.delete>) => (
      handoutService.delete(...args)
    ),
    ResponseClassCreator: createHandoutDeleteObject,
    typeName: 'useHandoutDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useHandoutDelete.mock = () => new Error('Mock has not been implemented!');
useHandoutDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useHandoutDelete.createObject = createHandoutDeleteObject;

export const createHandoutSearchObject = (value: APIResponse<PageResponse<Handout>>) => new APIResponseClass<PageResponseClass<HandoutClass>>(value, (value: PageResponse<Handout>) => new PageResponseClass<HandoutClass>(value, (value: Handout) => new HandoutClass(value)));
export const useHandoutSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<Handout>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<Handout>,
    APIResponse<PageResponse<Handout>>,
    // @ts-ignore
    typeof handoutService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<HandoutClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof handoutService.search>) => (
      handoutService.search(...args)
    ),
    ResponseClassCreator: createHandoutSearchObject,
    typeName: 'useHandoutSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useHandoutSearch.mock = () => new Error('Mock has not been implemented!');
useHandoutSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useHandoutSearch.createObject = createHandoutSearchObject;

export interface HealthCondition extends PatientBase {
	healthConditions?: Nullable<HealthConditionItem[]>;
	diagnoses?: Nullable<PatientDiagnosesEnum[]>;
	visitId?: Nullable<string>;
	updateIcdCodes?: Nullable<boolean>;
	updateDiagnoses?: Nullable<boolean>;
}
export enum ChronicDiseaseEnum {
	CHRONIC_KIDNEY_DISEASE = 'CHRONIC_KIDNEY_DISEASE',
	CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
	CONGESTIVE_HEART_FAILURE = 'CONGESTIVE_HEART_FAILURE',
	DIABETES = 'DIABETES',
	HYPERLIPIDEMIA = 'HYPERLIPIDEMIA',
	HYPERTENSION = 'HYPERTENSION',
	OBESITY = 'OBESITY',
	PRE_DIABETES = 'PRE_DIABETES',
	CUSTOM_DISEASE = 'CUSTOM_DISEASE',
	NONE = 'NONE',
	name = 'name',
}
export interface MeasureFrequency {
	times?: Nullable<number>;
	frequency?: Nullable<FrequencyUnitEnum>;
}
export enum TreatmentTypeEnum {
	NO_TREATMENT = 'NO_TREATMENT',
	ORAL_MEDICATION = 'ORAL_MEDICATION',
	HERBAL_MEDICATION = 'HERBAL_MEDICATION',
	INJECTABLE_MEDICATION = 'INJECTABLE_MEDICATION',
	INSULIN = 'INSULIN',
	OXYGEN_THERAPY = 'OXYGEN_THERAPY',
	DIET = 'DIET',
	EXERCISE = 'EXERCISE',
	OTHER = 'OTHER',
}
export interface DiseaseHistoryItem {
	disease?: Nullable<ChronicDiseaseEnum>;
	customDisease?: Nullable<string>;
	yearsWithCondition?: Nullable<number>;
	diagnosedYear?: Nullable<number>;
	measureFrequency?: Nullable<MeasureFrequency>;
	hasSeenDoctor?: Nullable<boolean>;
	hasSeenSpecialist?: Nullable<boolean>;
	nameAndContact?: Nullable<string>;
	hasTestedBefore?: Nullable<boolean>;
	isTakingMedication?: Nullable<boolean>;
	otherTreatmentAndNotes?: Nullable<string>;
	treatmentTypes?: Nullable<TreatmentTypeEnum[]>;
	isManualInput?: Nullable<boolean>;
}
export enum ConditionEnum {
	GLUCOSE_MANAGEMENT = 'GLUCOSE_MANAGEMENT',
	CGM = 'CGM',
	BLOOD_PRESSURE_MANAGEMENT = 'BLOOD_PRESSURE_MANAGEMENT',
	LIPID_MANAGEMENT = 'LIPID_MANAGEMENT',
	WEIGHT_REDUCTION = 'WEIGHT_REDUCTION',
	WEIGHT_REDUCTION_GLP = 'WEIGHT_REDUCTION_GLP',
	CANCER = 'CANCER',
	UNDERWEIGHT = 'UNDERWEIGHT',
	CHRONIC_KIDNEY_DISEASE = 'CHRONIC_KIDNEY_DISEASE',
	CONGESTIVE_HEART_FAILURE = 'CONGESTIVE_HEART_FAILURE',
	CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
	COVID = 'COVID',
	UNMAPPED = 'UNMAPPED',
}
export enum ClinicGoalEnum {
	GLUCOSE_MANAGEMENT_1 = 'GLUCOSE_MANAGEMENT_1',
	GLUCOSE_MANAGEMENT_2 = 'GLUCOSE_MANAGEMENT_2',
	GLUCOSE_MANAGEMENT_3 = 'GLUCOSE_MANAGEMENT_3',
	GLUCOSE_MANAGEMENT_4 = 'GLUCOSE_MANAGEMENT_4',
	GLUCOSE_MANAGEMENT_5 = 'GLUCOSE_MANAGEMENT_5',
	GLUCOSE_MANAGEMENT_6 = 'GLUCOSE_MANAGEMENT_6',
	BLOOD_PRESSURE_MANAGEMENT_1 = 'BLOOD_PRESSURE_MANAGEMENT_1',
	BLOOD_PRESSURE_MANAGEMENT_2 = 'BLOOD_PRESSURE_MANAGEMENT_2',
	BLOOD_PRESSURE_MANAGEMENT_3 = 'BLOOD_PRESSURE_MANAGEMENT_3',
	BLOOD_PRESSURE_MANAGEMENT_4 = 'BLOOD_PRESSURE_MANAGEMENT_4',
	BLOOD_PRESSURE_MANAGEMENT_5 = 'BLOOD_PRESSURE_MANAGEMENT_5',
	WEIGHT_REDUCTION_1 = 'WEIGHT_REDUCTION_1',
	WEIGHT_REDUCTION_2 = 'WEIGHT_REDUCTION_2',
	WEIGHT_REDUCTION_3 = 'WEIGHT_REDUCTION_3',
	WEIGHT_REDUCTION_4 = 'WEIGHT_REDUCTION_4',
	WEIGHT_REDUCTION_5 = 'WEIGHT_REDUCTION_5',
	WEIGHT_REDUCTION_6 = 'WEIGHT_REDUCTION_6',
	WEIGHT_REDUCTION_7 = 'WEIGHT_REDUCTION_7',
	WEIGHT_REDUCTION_GLP_1 = 'WEIGHT_REDUCTION_GLP_1',
	WEIGHT_REDUCTION_GLP_2 = 'WEIGHT_REDUCTION_GLP_2',
	WEIGHT_REDUCTION_GLP_3 = 'WEIGHT_REDUCTION_GLP_3',
	WEIGHT_REDUCTION_GLP_4 = 'WEIGHT_REDUCTION_GLP_4',
	WEIGHT_REDUCTION_GLP_5 = 'WEIGHT_REDUCTION_GLP_5',
	WEIGHT_REDUCTION_GLP_6 = 'WEIGHT_REDUCTION_GLP_6',
	WEIGHT_REDUCTION_GLP_7 = 'WEIGHT_REDUCTION_GLP_7',
	CANCER_1 = 'CANCER_1',
	CANCER_2 = 'CANCER_2',
	CANCER_3 = 'CANCER_3',
	UNDERWEIGHT_1 = 'UNDERWEIGHT_1',
	UNDERWEIGHT_2 = 'UNDERWEIGHT_2',
	UNDERWEIGHT_3 = 'UNDERWEIGHT_3',
	CONGESTIVE_HEART_FAILURE_1 = 'CONGESTIVE_HEART_FAILURE_1',
	CONGESTIVE_HEART_FAILURE_2 = 'CONGESTIVE_HEART_FAILURE_2',
	CONGESTIVE_HEART_FAILURE_3 = 'CONGESTIVE_HEART_FAILURE_3',
	CONGESTIVE_HEART_FAILURE_4 = 'CONGESTIVE_HEART_FAILURE_4',
	CONGESTIVE_HEART_FAILURE_5 = 'CONGESTIVE_HEART_FAILURE_5',
	CONGESTIVE_HEART_FAILURE_6 = 'CONGESTIVE_HEART_FAILURE_6',
	CONGESTIVE_HEART_FAILURE_7 = 'CONGESTIVE_HEART_FAILURE_7',
	CONGESTIVE_HEART_FAILURE_8 = 'CONGESTIVE_HEART_FAILURE_8',
	CONGESTIVE_HEART_FAILURE_9 = 'CONGESTIVE_HEART_FAILURE_9',
	CONGESTIVE_HEART_FAILURE_10 = 'CONGESTIVE_HEART_FAILURE_10',
	CONGESTIVE_HEART_FAILURE_11 = 'CONGESTIVE_HEART_FAILURE_11',
	CONGESTIVE_HEART_FAILURE_12 = 'CONGESTIVE_HEART_FAILURE_12',
	CONGESTIVE_HEART_FAILURE_13 = 'CONGESTIVE_HEART_FAILURE_13',
	CONGESTIVE_HEART_FAILURE_14 = 'CONGESTIVE_HEART_FAILURE_14',
	CONGESTIVE_HEART_FAILURE_15 = 'CONGESTIVE_HEART_FAILURE_15',
	CONGESTIVE_HEART_FAILURE_16 = 'CONGESTIVE_HEART_FAILURE_16',
	CONGESTIVE_HEART_FAILURE_17 = 'CONGESTIVE_HEART_FAILURE_17',
	CONGESTIVE_HEART_FAILURE_18 = 'CONGESTIVE_HEART_FAILURE_18',
	CONGESTIVE_HEART_FAILURE_19 = 'CONGESTIVE_HEART_FAILURE_19',
	CONGESTIVE_HEART_FAILURE_20 = 'CONGESTIVE_HEART_FAILURE_20',
	CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_1 = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_1',
	CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_2 = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_2',
	CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_3 = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_3',
	CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_4 = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_4',
	CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_5 = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_5',
	CHRONIC_KIDNEY_DISEASE_1 = 'CHRONIC_KIDNEY_DISEASE_1',
	CHRONIC_KIDNEY_DISEASE_2 = 'CHRONIC_KIDNEY_DISEASE_2',
	CHRONIC_KIDNEY_DISEASE_3 = 'CHRONIC_KIDNEY_DISEASE_3',
	CHRONIC_KIDNEY_DISEASE_4 = 'CHRONIC_KIDNEY_DISEASE_4',
	CHRONIC_KIDNEY_DISEASE_5 = 'CHRONIC_KIDNEY_DISEASE_5',
	CHRONIC_KIDNEY_DISEASE_6 = 'CHRONIC_KIDNEY_DISEASE_6',
	LIPID_MANAGEMENT_1 = 'LIPID_MANAGEMENT_1',
	LIPID_MANAGEMENT_2 = 'LIPID_MANAGEMENT_2',
	LIPID_MANAGEMENT_3 = 'LIPID_MANAGEMENT_3',
	LIPID_MANAGEMENT_4 = 'LIPID_MANAGEMENT_4',
	LIPID_MANAGEMENT_5 = 'LIPID_MANAGEMENT_5',
	LIPID_MANAGEMENT_6 = 'LIPID_MANAGEMENT_6',
	LIPID_MANAGEMENT_7 = 'LIPID_MANAGEMENT_7',
	LIPID_MANAGEMENT_8 = 'LIPID_MANAGEMENT_8',
	LIPID_MANAGEMENT_9 = 'LIPID_MANAGEMENT_9',
	COVID_1 = 'COVID_1',
	CHOOSE_THE_OPTIONS = 'CHOOSE_THE_OPTIONS',
}
export enum GoalStatusEnum {
	MEETING = 'MEETING',
	NOT_MEETING = 'NOT_MEETING',
	UNABLE_TO_CONCLUDE = 'UNABLE_TO_CONCLUDE',
	NONE = 'NONE',
}
export interface ClinicalGoalItem {
	condition?: Nullable<ConditionEnum>;
	clinicalGoalName?: Nullable<ClinicGoalEnum>;
	customCategory?: Nullable<string>;
	customContent?: Nullable<string>;
	updatedDate?: Nullable<string>;
	isManualInput?: Nullable<boolean>;
	goalStatus?: Nullable<GoalStatusEnum>;
}
export interface HealthConditionRecommendResponse {
	diseaseList?: Nullable<DiseaseHistoryItem[]>;
	clinicalGoalList?: Nullable<ClinicalGoalItem[]>;
}
export class MeasureFrequencyClass {
  protected readonly _value: MeasureFrequency;

	constructor(value: MeasureFrequency) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get times() {
    return this._value.times;
  }

  get timesWithDefault() {
    return this._value.times || 0;
  }

  get frequency() {
    return this._value.frequency;
  }

  frequencyIsIn(values: FrequencyUnitEnum[] = []) {
    return this._value.frequency && values.includes(this._value.frequency);
  }
}
export class DiseaseHistoryItemClass {
  protected readonly _value: DiseaseHistoryItem;

	constructor(value: DiseaseHistoryItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get disease() {
    return this._value.disease;
  }

  diseaseIsIn(values: ChronicDiseaseEnum[] = []) {
    return this._value.disease && values.includes(this._value.disease);
  }

  get customDisease() {
    return this._value.customDisease;
  }

  get customDiseaseWithDefault() {
    return this._value.customDisease || '';
  }

  get yearsWithCondition() {
    return this._value.yearsWithCondition;
  }

  get yearsWithConditionWithDefault() {
    return this._value.yearsWithCondition || 0;
  }

  get diagnosedYear() {
    return this._value.diagnosedYear;
  }

  get diagnosedYearWithDefault() {
    return this._value.diagnosedYear || 0;
  }

  get measureFrequency() {
    return this._value.measureFrequency !== undefined && this._value.measureFrequency !== null ? new MeasureFrequencyClass(this._value.measureFrequency) : this._value.measureFrequency;
  }

  get hasSeenDoctor() {
    return this._value.hasSeenDoctor;
  }

  get hasSeenDoctorWithDefault() {
    return this._value.hasSeenDoctor || false;
  }

  get hasSeenSpecialist() {
    return this._value.hasSeenSpecialist;
  }

  get hasSeenSpecialistWithDefault() {
    return this._value.hasSeenSpecialist || false;
  }

  get nameAndContact() {
    return this._value.nameAndContact;
  }

  get nameAndContactWithDefault() {
    return this._value.nameAndContact || '';
  }

  get hasTestedBefore() {
    return this._value.hasTestedBefore;
  }

  get hasTestedBeforeWithDefault() {
    return this._value.hasTestedBefore || false;
  }

  get isTakingMedication() {
    return this._value.isTakingMedication;
  }

  get isTakingMedicationWithDefault() {
    return this._value.isTakingMedication || false;
  }

  get otherTreatmentAndNotes() {
    return this._value.otherTreatmentAndNotes;
  }

  get otherTreatmentAndNotesWithDefault() {
    return this._value.otherTreatmentAndNotes || '';
  }

  get treatmentTypes() {
    return this._value.treatmentTypes;
  }

  get treatmentTypesWithDefault() {
    return this._value.treatmentTypes || [];
  }

  get isManualInput() {
    return this._value.isManualInput;
  }

  get isManualInputWithDefault() {
    return this._value.isManualInput || false;
  }
}
export class ClinicalGoalItemClass {
  protected readonly _value: ClinicalGoalItem;

	constructor(value: ClinicalGoalItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get condition() {
    return this._value.condition;
  }

  conditionIsIn(values: ConditionEnum[] = []) {
    return this._value.condition && values.includes(this._value.condition);
  }

  get clinicalGoalName() {
    return this._value.clinicalGoalName;
  }

  clinicalGoalNameIsIn(values: ClinicGoalEnum[] = []) {
    return this._value.clinicalGoalName && values.includes(this._value.clinicalGoalName);
  }

  get customCategory() {
    return this._value.customCategory;
  }

  get customCategoryWithDefault() {
    return this._value.customCategory || '';
  }

  get customContent() {
    return this._value.customContent;
  }

  get customContentWithDefault() {
    return this._value.customContent || '';
  }

  get updatedDate() {
    return (this._value.updatedDate !== undefined && this._value.updatedDate !== null) ? dayjs(this._value.updatedDate) : this._value.updatedDate;
  }

  get isManualInput() {
    return this._value.isManualInput;
  }

  get isManualInputWithDefault() {
    return this._value.isManualInput || false;
  }

  get goalStatus() {
    return this._value.goalStatus;
  }

  goalStatusIsIn(values: GoalStatusEnum[] = []) {
    return this._value.goalStatus && values.includes(this._value.goalStatus);
  }
}
export class HealthConditionRecommendResponseClass {
  protected readonly _value: HealthConditionRecommendResponse;

	constructor(value: HealthConditionRecommendResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get diseaseList() {
    return this._value.diseaseList !== undefined && this._value.diseaseList !== null ? map(this._value.diseaseList, (v) => new DiseaseHistoryItemClass(v)) : this._value.diseaseList;
  }

  get diseaseListWithDefault() {
    return this._value.diseaseList || [];
  }

  get clinicalGoalList() {
    return this._value.clinicalGoalList !== undefined && this._value.clinicalGoalList !== null ? map(this._value.clinicalGoalList, (v) => new ClinicalGoalItemClass(v)) : this._value.clinicalGoalList;
  }

  get clinicalGoalListWithDefault() {
    return this._value.clinicalGoalList || [];
  }
}
export class HealthConditionClass extends PatientBaseClass {
  protected readonly _value: HealthCondition;

	constructor(value: HealthCondition) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get healthConditions() {
    return this._value.healthConditions !== undefined && this._value.healthConditions !== null ? map(this._value.healthConditions, (v) => new HealthConditionItemClass(v)) : this._value.healthConditions;
  }

  get healthConditionsWithDefault() {
    return this._value.healthConditions || [];
  }

  get diagnoses() {
    return this._value.diagnoses;
  }

  get diagnosesWithDefault() {
    return this._value.diagnoses || [];
  }

  get visitId() {
    return this._value.visitId;
  }

  get updateIcdCodes() {
    return this._value.updateIcdCodes;
  }

  get updateIcdCodesWithDefault() {
    return this._value.updateIcdCodes || false;
  }

  get updateDiagnoses() {
    return this._value.updateDiagnoses;
  }

  get updateDiagnosesWithDefault() {
    return this._value.updateDiagnoses || false;
  }
}
export interface HealthConditionGetRecommendDiseaseAndGoalParams {
	healthConditionItems: HealthConditionItem[];
}

export class HealthConditionService extends BaseController<HealthCondition, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/health-condition';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getRecommendDiseaseAndGoal({ baseUrl, params, headers }: RequestOption<HealthConditionGetRecommendDiseaseAndGoalParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<HealthConditionRecommendResponse>>(`${baseUrl || this.baseURL}${this.basePath}/recommend${getQueryStrings(queryList)}`, params.healthConditionItems, { headers });
	}
}

export const healthConditionService = new HealthConditionService();

export const createHealthConditionGetRecommendDiseaseAndGoalObject = (value: APIResponse<HealthConditionRecommendResponse>) => new APIResponseClass<HealthConditionRecommendResponseClass>(value, (value: HealthConditionRecommendResponse) => new HealthConditionRecommendResponseClass(value));

export const useHealthConditionGetRecommendDiseaseAndGoal = <RCN = undefined>(configs: MakeRequestHookConfig<HealthConditionGetRecommendDiseaseAndGoalParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    HealthConditionGetRecommendDiseaseAndGoalParams,
    APIResponse<HealthConditionRecommendResponse>,
    typeof healthConditionService.getRecommendDiseaseAndGoal,
    AjaxOptions,
    APIResponseClass<HealthConditionRecommendResponseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof healthConditionService.getRecommendDiseaseAndGoal>) => (
      healthConditionService.getRecommendDiseaseAndGoal(...args)
    ),
    ResponseClassCreator: createHealthConditionGetRecommendDiseaseAndGoalObject,
    typeName: 'useHealthConditionGetRecommendDiseaseAndGoal',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useHealthConditionGetRecommendDiseaseAndGoal.mock = () => new Error('Mock has not been implemented!');
useHealthConditionGetRecommendDiseaseAndGoal.getLastMockedValue = () => new Error('Mock has not been implemented!');
useHealthConditionGetRecommendDiseaseAndGoal.createObject = createHealthConditionGetRecommendDiseaseAndGoalObject;


export const createHealthConditionGetObject = (value: APIResponse<HealthCondition>) => new APIResponseClass<HealthConditionClass>(value, (value: HealthCondition) => new HealthConditionClass(value));
export const useHealthConditionGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<HealthCondition>,
    // @ts-ignore
    typeof healthConditionService.get,
    GetOptions,
    APIResponseClass<HealthConditionClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof healthConditionService.get>) => (
      healthConditionService.get(...args)
    ),
    ResponseClassCreator: createHealthConditionGetObject,
    typeName: 'useHealthConditionGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useHealthConditionGet.mock = () => new Error('Mock has not been implemented!');
useHealthConditionGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useHealthConditionGet.createObject = createHealthConditionGetObject;

export const createHealthConditionUpdateObject = (value: APIResponse<HealthCondition>) => new APIResponseClass<HealthConditionClass>(value, (value: HealthCondition) => new HealthConditionClass(value));
export const useHealthConditionUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, HealthCondition>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, HealthCondition>,
    APIResponse<HealthCondition>,
    // @ts-ignore
    typeof healthConditionService.update,
    AjaxOptions,
    APIResponseClass<HealthConditionClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof healthConditionService.update>) => (
      healthConditionService.update(...args)
    ),
    ResponseClassCreator: createHealthConditionUpdateObject,
    typeName: 'useHealthConditionUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useHealthConditionUpdate.mock = () => new Error('Mock has not been implemented!');
useHealthConditionUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useHealthConditionUpdate.createObject = createHealthConditionUpdateObject;

export const createHealthConditionInsertObject = (value: APIResponse<HealthCondition>) => new APIResponseClass<HealthConditionClass>(value, (value: HealthCondition) => new HealthConditionClass(value));
export const useHealthConditionInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<HealthCondition>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<HealthCondition>,
    APIResponse<HealthCondition>,
    // @ts-ignore
    typeof healthConditionService.insert,
    AjaxOptions,
    APIResponseClass<HealthConditionClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof healthConditionService.insert>) => (
      healthConditionService.insert(...args)
    ),
    ResponseClassCreator: createHealthConditionInsertObject,
    typeName: 'useHealthConditionInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useHealthConditionInsert.mock = () => new Error('Mock has not been implemented!');
useHealthConditionInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useHealthConditionInsert.createObject = createHealthConditionInsertObject;

export const createHealthConditionDeleteObject = (value: APIResponse<HealthCondition>) => new APIResponseClass<HealthConditionClass>(value, (value: HealthCondition) => new HealthConditionClass(value));
export const useHealthConditionDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<HealthCondition>,
    // @ts-ignore
    typeof healthConditionService.delete,
    AjaxOptions,
    APIResponseClass<HealthConditionClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof healthConditionService.delete>) => (
      healthConditionService.delete(...args)
    ),
    ResponseClassCreator: createHealthConditionDeleteObject,
    typeName: 'useHealthConditionDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useHealthConditionDelete.mock = () => new Error('Mock has not been implemented!');
useHealthConditionDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useHealthConditionDelete.createObject = createHealthConditionDeleteObject;

export const createHealthConditionSearchObject = (value: APIResponse<PageResponse<HealthCondition>>) => new APIResponseClass<PageResponseClass<HealthConditionClass>>(value, (value: PageResponse<HealthCondition>) => new PageResponseClass<HealthConditionClass>(value, (value: HealthCondition) => new HealthConditionClass(value)));
export const useHealthConditionSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<HealthCondition>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<HealthCondition>,
    APIResponse<PageResponse<HealthCondition>>,
    // @ts-ignore
    typeof healthConditionService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<HealthConditionClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof healthConditionService.search>) => (
      healthConditionService.search(...args)
    ),
    ResponseClassCreator: createHealthConditionSearchObject,
    typeName: 'useHealthConditionSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useHealthConditionSearch.mock = () => new Error('Mock has not been implemented!');
useHealthConditionSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useHealthConditionSearch.createObject = createHealthConditionSearchObject;

export interface Icd10Billable extends BaseModel {
	code?: Nullable<string>;
	title?: Nullable<string>;
	diagnosis?: Nullable<PatientDiagnosesEnum>;
	nameNear?: Nullable<string>;
	codeNear?: Nullable<string>;
	titleNear?: Nullable<string>;
}
export class Icd10BillableClass extends BaseModelClass {
  protected readonly _value: Icd10Billable;

	constructor(value: Icd10Billable) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get code() {
    return this._value.code;
  }

  get codeWithDefault() {
    return this._value.code || '';
  }

  get title() {
    return this._value.title;
  }

  get titleWithDefault() {
    return this._value.title || '';
  }

  get diagnosis() {
    return this._value.diagnosis;
  }

  diagnosisIsIn(values: PatientDiagnosesEnum[] = []) {
    return this._value.diagnosis && values.includes(this._value.diagnosis);
  }

  get nameNear() {
    return this._value.nameNear;
  }

  get nameNearWithDefault() {
    return this._value.nameNear || '';
  }

  get codeNear() {
    return this._value.codeNear;
  }

  get codeNearWithDefault() {
    return this._value.codeNear || '';
  }

  get titleNear() {
    return this._value.titleNear;
  }

  get titleNearWithDefault() {
    return this._value.titleNear || '';
  }
}
export interface Icd10BillableGetAvailableIcdParams {
	searchRequest: SearchRequest<Icd10Billable>;
}

export class Icd10BillableService {
  protected baseURL = '';

  protected basePath = '/v1/uc/icd10-billables';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public getAvailableIcd({ baseUrl, params, headers }: RequestOption<Icd10BillableGetAvailableIcdParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<Icd10Billable>>>(`${baseUrl || this.baseURL}${this.basePath}/search${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}
}

export const icd10BillableService = new Icd10BillableService();

export const createIcd10BillableGetAvailableIcdObject = (value: APIResponse<PageResponse<Icd10Billable>>) => new APIResponseClass<PageResponseClass<Icd10BillableClass>>(value, (value: PageResponse<Icd10Billable>) => new PageResponseClass<Icd10BillableClass>(value, (value: Icd10Billable) => new Icd10BillableClass(value)));

export const useIcd10BillableGetAvailableIcd = <RCN = undefined>(configs: MakeRequestHookConfig<Icd10BillableGetAvailableIcdParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    Icd10BillableGetAvailableIcdParams,
    APIResponse<PageResponse<Icd10Billable>>,
    typeof icd10BillableService.getAvailableIcd,
    AjaxOptions,
    APIResponseClass<PageResponseClass<Icd10BillableClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof icd10BillableService.getAvailableIcd>) => (
      icd10BillableService.getAvailableIcd(...args)
    ),
    ResponseClassCreator: createIcd10BillableGetAvailableIcdObject,
    typeName: 'useIcd10BillableGetAvailableIcd',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useIcd10BillableGetAvailableIcd.mock = () => new Error('Mock has not been implemented!');
useIcd10BillableGetAvailableIcd.getLastMockedValue = () => new Error('Mock has not been implemented!');
useIcd10BillableGetAvailableIcd.createObject = createIcd10BillableGetAvailableIcdObject;
export enum IcdCodeMatchTypeEnum {
	PREFIX_MATCH = 'PREFIX_MATCH',
	EXACT_MATCH = 'EXACT_MATCH',
	RANGE = 'RANGE',
}
export interface IcdCodeConfig extends BaseModel {
	icdCode?: Nullable<string>;
	icdCodeRangeStart?: Nullable<string>;
	icdCodeRangeEnd?: Nullable<string>;
	matchType?: Nullable<IcdCodeMatchTypeEnum>;
	patientCategory?: Nullable<PatientCategoryEnum>;
	patientDiagnosesEnum?: Nullable<PatientDiagnosesEnum>;
	priority?: Nullable<number>;
	description?: Nullable<string>;
}
export class IcdCodeConfigClass extends BaseModelClass {
  protected readonly _value: IcdCodeConfig;

	constructor(value: IcdCodeConfig) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get icdCode() {
    return this._value.icdCode;
  }

  get icdCodeWithDefault() {
    return this._value.icdCode || '';
  }

  get icdCodeRangeStart() {
    return this._value.icdCodeRangeStart;
  }

  get icdCodeRangeStartWithDefault() {
    return this._value.icdCodeRangeStart || '';
  }

  get icdCodeRangeEnd() {
    return this._value.icdCodeRangeEnd;
  }

  get icdCodeRangeEndWithDefault() {
    return this._value.icdCodeRangeEnd || '';
  }

  get matchType() {
    return this._value.matchType;
  }

  matchTypeIsIn(values: IcdCodeMatchTypeEnum[] = []) {
    return this._value.matchType && values.includes(this._value.matchType);
  }

  get patientCategory() {
    return this._value.patientCategory;
  }

  patientCategoryIsIn(values: PatientCategoryEnum[] = []) {
    return this._value.patientCategory && values.includes(this._value.patientCategory);
  }

  get patientDiagnosesEnum() {
    return this._value.patientDiagnosesEnum;
  }

  patientDiagnosesEnumIsIn(values: PatientDiagnosesEnum[] = []) {
    return this._value.patientDiagnosesEnum && values.includes(this._value.patientDiagnosesEnum);
  }

  get priority() {
    return this._value.priority;
  }

  get priorityWithDefault() {
    return this._value.priority || 0;
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }
}
export interface IcdCodeConfigRequest {
	icdCodes?: Nullable<string[]>;
}
export interface PatientClassificationResult {
	diagnoses?: Nullable<PatientDiagnosesEnum[]>;
	categories?: Nullable<PatientCategoryEnum[]>;
}
export class PatientClassificationResultClass {
  protected readonly _value: PatientClassificationResult;

	constructor(value: PatientClassificationResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get diagnoses() {
    return this._value.diagnoses;
  }

  get diagnosesWithDefault() {
    return this._value.diagnoses || [];
  }

  get categories() {
    return this._value.categories;
  }

  get categoriesWithDefault() {
    return this._value.categories || [];
  }
}
export interface IcdCodeConfigGetIcdCodeConfigsParams {
}

export interface IcdCodeConfigClassifyByIcdCodeParams {
	request: IcdCodeConfigRequest;
}

export class IcdCodeConfigService extends BaseController<IcdCodeConfig, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/icd-code-configs';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getIcdCodeConfigs({ baseUrl, params, headers }: RequestOption<IcdCodeConfigGetIcdCodeConfigsParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<IcdCodeConfig[]>>(`${baseUrl || this.baseURL}${this.basePath}${getQueryStrings(queryList)}`, { headers });
	}

	public classifyByIcdCode({ baseUrl, params, headers }: RequestOption<IcdCodeConfigClassifyByIcdCodeParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PatientClassificationResult>>(`${baseUrl || this.baseURL}${this.basePath}/classify${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const icdCodeConfigService = new IcdCodeConfigService();

export const createIcdCodeConfigGetIcdCodeConfigsObject = (value: APIResponse<IcdCodeConfig[]>) => new APIResponseClass<IcdCodeConfigClass[]>(value, (v) => map(v, (value: IcdCodeConfig) => new IcdCodeConfigClass(value)));

export const useIcdCodeConfigGetIcdCodeConfigs = <RCN = undefined>(configs: MakeRequestHookConfig<IcdCodeConfigGetIcdCodeConfigsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    IcdCodeConfigGetIcdCodeConfigsParams,
    APIResponse<IcdCodeConfig[]>,
    typeof icdCodeConfigService.getIcdCodeConfigs,
    GetOptions,
    APIResponseClass<IcdCodeConfigClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof icdCodeConfigService.getIcdCodeConfigs>) => (
      icdCodeConfigService.getIcdCodeConfigs(...args)
    ),
    ResponseClassCreator: createIcdCodeConfigGetIcdCodeConfigsObject,
    typeName: 'useIcdCodeConfigGetIcdCodeConfigs',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useIcdCodeConfigGetIcdCodeConfigs.mock = () => new Error('Mock has not been implemented!');
useIcdCodeConfigGetIcdCodeConfigs.getLastMockedValue = () => new Error('Mock has not been implemented!');
useIcdCodeConfigGetIcdCodeConfigs.createObject = createIcdCodeConfigGetIcdCodeConfigsObject;

export const createIcdCodeConfigClassifyByIcdCodeObject = (value: APIResponse<PatientClassificationResult>) => new APIResponseClass<PatientClassificationResultClass>(value, (value: PatientClassificationResult) => new PatientClassificationResultClass(value));

export const useIcdCodeConfigClassifyByIcdCode = <RCN = undefined>(configs: MakeRequestHookConfig<IcdCodeConfigClassifyByIcdCodeParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    IcdCodeConfigClassifyByIcdCodeParams,
    APIResponse<PatientClassificationResult>,
    typeof icdCodeConfigService.classifyByIcdCode,
    AjaxOptions,
    APIResponseClass<PatientClassificationResultClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof icdCodeConfigService.classifyByIcdCode>) => (
      icdCodeConfigService.classifyByIcdCode(...args)
    ),
    ResponseClassCreator: createIcdCodeConfigClassifyByIcdCodeObject,
    typeName: 'useIcdCodeConfigClassifyByIcdCode',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useIcdCodeConfigClassifyByIcdCode.mock = () => new Error('Mock has not been implemented!');
useIcdCodeConfigClassifyByIcdCode.getLastMockedValue = () => new Error('Mock has not been implemented!');
useIcdCodeConfigClassifyByIcdCode.createObject = createIcdCodeConfigClassifyByIcdCodeObject;


export const createIcdCodeConfigGetObject = (value: APIResponse<IcdCodeConfig>) => new APIResponseClass<IcdCodeConfigClass>(value, (value: IcdCodeConfig) => new IcdCodeConfigClass(value));
export const useIcdCodeConfigGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<IcdCodeConfig>,
    // @ts-ignore
    typeof icdCodeConfigService.get,
    GetOptions,
    APIResponseClass<IcdCodeConfigClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof icdCodeConfigService.get>) => (
      icdCodeConfigService.get(...args)
    ),
    ResponseClassCreator: createIcdCodeConfigGetObject,
    typeName: 'useIcdCodeConfigGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useIcdCodeConfigGet.mock = () => new Error('Mock has not been implemented!');
useIcdCodeConfigGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useIcdCodeConfigGet.createObject = createIcdCodeConfigGetObject;

export const createIcdCodeConfigUpdateObject = (value: APIResponse<IcdCodeConfig>) => new APIResponseClass<IcdCodeConfigClass>(value, (value: IcdCodeConfig) => new IcdCodeConfigClass(value));
export const useIcdCodeConfigUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, IcdCodeConfig>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, IcdCodeConfig>,
    APIResponse<IcdCodeConfig>,
    // @ts-ignore
    typeof icdCodeConfigService.update,
    AjaxOptions,
    APIResponseClass<IcdCodeConfigClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof icdCodeConfigService.update>) => (
      icdCodeConfigService.update(...args)
    ),
    ResponseClassCreator: createIcdCodeConfigUpdateObject,
    typeName: 'useIcdCodeConfigUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useIcdCodeConfigUpdate.mock = () => new Error('Mock has not been implemented!');
useIcdCodeConfigUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useIcdCodeConfigUpdate.createObject = createIcdCodeConfigUpdateObject;

export const createIcdCodeConfigInsertObject = (value: APIResponse<IcdCodeConfig>) => new APIResponseClass<IcdCodeConfigClass>(value, (value: IcdCodeConfig) => new IcdCodeConfigClass(value));
export const useIcdCodeConfigInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<IcdCodeConfig>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<IcdCodeConfig>,
    APIResponse<IcdCodeConfig>,
    // @ts-ignore
    typeof icdCodeConfigService.insert,
    AjaxOptions,
    APIResponseClass<IcdCodeConfigClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof icdCodeConfigService.insert>) => (
      icdCodeConfigService.insert(...args)
    ),
    ResponseClassCreator: createIcdCodeConfigInsertObject,
    typeName: 'useIcdCodeConfigInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useIcdCodeConfigInsert.mock = () => new Error('Mock has not been implemented!');
useIcdCodeConfigInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useIcdCodeConfigInsert.createObject = createIcdCodeConfigInsertObject;

export const createIcdCodeConfigDeleteObject = (value: APIResponse<IcdCodeConfig>) => new APIResponseClass<IcdCodeConfigClass>(value, (value: IcdCodeConfig) => new IcdCodeConfigClass(value));
export const useIcdCodeConfigDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<IcdCodeConfig>,
    // @ts-ignore
    typeof icdCodeConfigService.delete,
    AjaxOptions,
    APIResponseClass<IcdCodeConfigClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof icdCodeConfigService.delete>) => (
      icdCodeConfigService.delete(...args)
    ),
    ResponseClassCreator: createIcdCodeConfigDeleteObject,
    typeName: 'useIcdCodeConfigDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useIcdCodeConfigDelete.mock = () => new Error('Mock has not been implemented!');
useIcdCodeConfigDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useIcdCodeConfigDelete.createObject = createIcdCodeConfigDeleteObject;

export const createIcdCodeConfigSearchObject = (value: APIResponse<PageResponse<IcdCodeConfig>>) => new APIResponseClass<PageResponseClass<IcdCodeConfigClass>>(value, (value: PageResponse<IcdCodeConfig>) => new PageResponseClass<IcdCodeConfigClass>(value, (value: IcdCodeConfig) => new IcdCodeConfigClass(value)));
export const useIcdCodeConfigSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<IcdCodeConfig>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<IcdCodeConfig>,
    APIResponse<PageResponse<IcdCodeConfig>>,
    // @ts-ignore
    typeof icdCodeConfigService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<IcdCodeConfigClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof icdCodeConfigService.search>) => (
      icdCodeConfigService.search(...args)
    ),
    ResponseClassCreator: createIcdCodeConfigSearchObject,
    typeName: 'useIcdCodeConfigSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useIcdCodeConfigSearch.mock = () => new Error('Mock has not been implemented!');
useIcdCodeConfigSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useIcdCodeConfigSearch.createObject = createIcdCodeConfigSearchObject;

export enum DemographicsFilterEnum {
	CLINIC = 'CLINIC',
	PROVIDER = 'PROVIDER',
	AGE = 'AGE',
	VITALS = 'VITALS',
	PROGRAM = 'PROGRAM',
	SPOKEN_LANGUAGE = 'SPOKEN_LANGUAGE',
}
export enum VitalInsightEnum {
	CLINIC_OUTCOMES_BP = 'CLINIC_OUTCOMES_BP',
	CLINIC_OUTCOMES_BG = 'CLINIC_OUTCOMES_BG',
	COMPLIANCE_RATE_BP = 'COMPLIANCE_RATE_BP',
	COMPLIANCE_RATE_BG = 'COMPLIANCE_RATE_BG',
}
export enum VitalResolveEnum {
	HTN_CRISIS = 'HTN_CRISIS',
	HTN_STAGE_2 = 'HTN_STAGE_2',
	A1C_HIGHER_THAN_9 = 'A1C_HIGHER_THAN_9',
	A1C_LESS_THAN_7 = 'A1C_LESS_THAN_7',
	BP_VISIT_COMPLIANT_RATE = 'BP_VISIT_COMPLIANT_RATE',
	BG_VISIT_COMPLIANCE_RATE = 'BG_VISIT_COMPLIANCE_RATE',
	BG_A1C_COMPLIANCE_RATE = 'BG_A1C_COMPLIANCE_RATE',
}
export interface VitalGoalResult {
	vitalResolveEnum?: Nullable<VitalResolveEnum>;
	date?: Nullable<string>;
	value?: Nullable<number>;
}
export interface PatientVital {
	id?: Nullable<string>;
	vitalInsightEnum?: Nullable<VitalInsightEnum>;
	totalPatients?: Nullable<number>;
	curDateTime?: Nullable<string>;
	vitalGoalResults?: Nullable<VitalGoalResult[]>;
}
export class VitalGoalResultClass {
  protected readonly _value: VitalGoalResult;

	constructor(value: VitalGoalResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get vitalResolveEnum() {
    return this._value.vitalResolveEnum;
  }

  vitalResolveEnumIsIn(values: VitalResolveEnum[] = []) {
    return this._value.vitalResolveEnum && values.includes(this._value.vitalResolveEnum);
  }

  get date() {
    return this._value.date;
  }

  get dateWithDefault() {
    return this._value.date || '';
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || 0.0;
  }
}
export class PatientVitalClass {
  protected readonly _value: PatientVital;

	constructor(value: PatientVital) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get vitalInsightEnum() {
    return this._value.vitalInsightEnum;
  }

  vitalInsightEnumIsIn(values: VitalInsightEnum[] = []) {
    return this._value.vitalInsightEnum && values.includes(this._value.vitalInsightEnum);
  }

  get totalPatients() {
    return this._value.totalPatients;
  }

  get totalPatientsWithDefault() {
    return this._value.totalPatients || 0;
  }

  get curDateTime() {
    return (this._value.curDateTime !== undefined && this._value.curDateTime !== null) ? dayjs(this._value.curDateTime) : this._value.curDateTime;
  }

  get vitalGoalResults() {
    return this._value.vitalGoalResults !== undefined && this._value.vitalGoalResults !== null ? map(this._value.vitalGoalResults, (v) => new VitalGoalResultClass(v)) : this._value.vitalGoalResults;
  }

  get vitalGoalResultsWithDefault() {
    return this._value.vitalGoalResults || [];
  }
}
export enum BillableInsightEnum {
	TOTAL_BILLABLE_TIMES = 'TOTAL_BILLABLE_TIMES',
	BILLABLE_RATE = 'BILLABLE_RATE',
}
export enum DateEnum {
	DAILY = 'DAILY',
	MONTHLY = 'MONTHLY',
}
export interface BillableRequest {
	id?: Nullable<string>;
	billableInsightEnum?: Nullable<BillableInsightEnum>;
	startTime?: Nullable<string>;
	endTime?: Nullable<string>;
	dateType?: Nullable<DateEnum>;
}
export interface BillableTimeItem {
	date?: Nullable<number>;
	totalTimeByViewer?: Nullable<number>;
	totalTimeByCovered?: Nullable<number>;
	billableRate?: Nullable<number>;
	dailyAverage?: Nullable<number>;
}
export class BillableTimeItemClass {
  protected readonly _value: BillableTimeItem;

	constructor(value: BillableTimeItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get date() {
    return this._value.date;
  }

  get dateWithDefault() {
    return this._value.date || 0;
  }

  get totalTimeByViewer() {
    return this._value.totalTimeByViewer;
  }

  get totalTimeByViewerWithDefault() {
    return this._value.totalTimeByViewer || 0;
  }

  get totalTimeByCovered() {
    return this._value.totalTimeByCovered;
  }

  get totalTimeByCoveredWithDefault() {
    return this._value.totalTimeByCovered || 0;
  }

  get billableRate() {
    return this._value.billableRate;
  }

  get billableRateWithDefault() {
    return this._value.billableRate || 0.0;
  }

  get dailyAverage() {
    return this._value.dailyAverage;
  }

  get dailyAverageWithDefault() {
    return this._value.dailyAverage || 0.0;
  }
}
export interface InsightListDemographicsParams {
	id: string;
	filter: DemographicsFilterEnum;
}

export interface InsightGetVitalInsightParams {
	patientVital: PatientVital;
}

export interface InsightGetBillableDataParams {
	billableRequest: BillableRequest;
}

export class InsightService {
  protected baseURL = '';

  protected basePath = '/v1/uc/insight';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public listDemographics({ baseUrl, params, headers }: RequestOption<InsightListDemographicsParams>) {
		const queryList: QueryParam[] = [];
		if (params.id !== undefined && params.id !== null) {
			queryList.push({ name: 'id', value: params.id.toString() });
		}
		if (params.filter !== undefined && params.filter !== null) {
			queryList.push({ name: 'filter', value: params.filter.toString() });
		}
		return axios.get<APIResponse<{ [key: string]: number }>>(`${baseUrl || this.baseURL}${this.basePath}/demographics${getQueryStrings(queryList)}`, { headers });
	}

	public getVitalInsight({ baseUrl, params, headers }: RequestOption<InsightGetVitalInsightParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PatientVital[]>>(`${baseUrl || this.baseURL}${this.basePath}/vital${getQueryStrings(queryList)}`, params.patientVital, { headers });
	}

	public getBillableData({ baseUrl, params, headers }: RequestOption<InsightGetBillableDataParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<BillableTimeItem[]>>(`${baseUrl || this.baseURL}${this.basePath}/total-billable-data${getQueryStrings(queryList)}`, params.billableRequest, { headers });
	}
}

export const insightService = new InsightService();

export const createInsightListDemographicsObject = (value: APIResponse<{ [key: string]: number }>) => new APIResponseClass<{ [key: string]: number }>(value, (v) => (v));

export const useInsightListDemographics = <RCN = undefined>(configs: MakeRequestHookConfig<InsightListDemographicsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InsightListDemographicsParams,
    APIResponse<{ [key: string]: number }>,
    typeof insightService.listDemographics,
    GetOptions,
    APIResponseClass<{ [key: string]: number }>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof insightService.listDemographics>) => (
      insightService.listDemographics(...args)
    ),
    ResponseClassCreator: createInsightListDemographicsObject,
    typeName: 'useInsightListDemographics',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInsightListDemographics.mock = () => new Error('Mock has not been implemented!');
useInsightListDemographics.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsightListDemographics.createObject = createInsightListDemographicsObject;

export const createInsightGetVitalInsightObject = (value: APIResponse<PatientVital[]>) => new APIResponseClass<PatientVitalClass[]>(value, (v) => map(v, (value: PatientVital) => new PatientVitalClass(value)));

export const useInsightGetVitalInsight = <RCN = undefined>(configs: MakeRequestHookConfig<InsightGetVitalInsightParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InsightGetVitalInsightParams,
    APIResponse<PatientVital[]>,
    typeof insightService.getVitalInsight,
    AjaxOptions,
    APIResponseClass<PatientVitalClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof insightService.getVitalInsight>) => (
      insightService.getVitalInsight(...args)
    ),
    ResponseClassCreator: createInsightGetVitalInsightObject,
    typeName: 'useInsightGetVitalInsight',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInsightGetVitalInsight.mock = () => new Error('Mock has not been implemented!');
useInsightGetVitalInsight.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsightGetVitalInsight.createObject = createInsightGetVitalInsightObject;

export const createInsightGetBillableDataObject = (value: APIResponse<BillableTimeItem[]>) => new APIResponseClass<BillableTimeItemClass[]>(value, (v) => map(v, (value: BillableTimeItem) => new BillableTimeItemClass(value)));

export const useInsightGetBillableData = <RCN = undefined>(configs: MakeRequestHookConfig<InsightGetBillableDataParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InsightGetBillableDataParams,
    APIResponse<BillableTimeItem[]>,
    typeof insightService.getBillableData,
    AjaxOptions,
    APIResponseClass<BillableTimeItemClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof insightService.getBillableData>) => (
      insightService.getBillableData(...args)
    ),
    ResponseClassCreator: createInsightGetBillableDataObject,
    typeName: 'useInsightGetBillableData',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInsightGetBillableData.mock = () => new Error('Mock has not been implemented!');
useInsightGetBillableData.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsightGetBillableData.createObject = createInsightGetBillableDataObject;
export enum InsightRoleTypeEnum {
	RD_HC = 'RD_HC',
	CA = 'CA',
	COMBINED = 'COMBINED',
}
export interface InsightsGetDemographicsParams {
	dimension: string;
	roleType: InsightRoleTypeEnum;
}

export interface InsightsGetBillableRateParams {
	startDate: string;
	endDate: string;
	granularity: string;
	roleType: InsightRoleTypeEnum;
}

export interface InsightsGetActiveRateParams {
	startDate: string;
	endDate: string;
	granularity: string;
	roleType: InsightRoleTypeEnum;
}

export interface InsightsGetTotalBillableParams {
	startDate: string;
	endDate: string;
	granularity: string;
	roleType: InsightRoleTypeEnum;
}

export interface InsightsGetBgClinicalOutcomeParams {
	roleType: InsightRoleTypeEnum;
}

export interface InsightsGetBpClinicalOutcomeParams {
	roleType: InsightRoleTypeEnum;
}

export interface InsightsGetBgComplianceRateParams {
	roleType: InsightRoleTypeEnum;
}

export interface InsightsGetBpComplianceRateParams {
	roleType: InsightRoleTypeEnum;
}

export class InsightsService {
  protected baseURL = '';

  protected basePath = '/v1/uc/insights';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public getDemographics({ baseUrl, params, headers }: RequestOption<InsightsGetDemographicsParams>) {
		const queryList: QueryParam[] = [];
		if (params.dimension !== undefined && params.dimension !== null) {
			queryList.push({ name: 'dimension', value: params.dimension.toString() });
		}
		if (params.roleType !== undefined && params.roleType !== null) {
			queryList.push({ name: 'roleType', value: params.roleType.toString() });
		}
		return axios.get<APIResponse<{ [key: any]: any }>>(`${baseUrl || this.baseURL}${this.basePath}/demographics${getQueryStrings(queryList)}`, { headers });
	}

	public getBillableRate({ baseUrl, params, headers }: RequestOption<InsightsGetBillableRateParams>) {
		const queryList: QueryParam[] = [];
		if (params.startDate !== undefined && params.startDate !== null) {
			queryList.push({ name: 'startDate', value: params.startDate.toString() });
		}
		if (params.endDate !== undefined && params.endDate !== null) {
			queryList.push({ name: 'endDate', value: params.endDate.toString() });
		}
		if (params.granularity !== undefined && params.granularity !== null) {
			queryList.push({ name: 'granularity', value: params.granularity.toString() });
		}
		if (params.roleType !== undefined && params.roleType !== null) {
			queryList.push({ name: 'roleType', value: params.roleType.toString() });
		}
		return axios.get<APIResponse<{ [key: any]: any }>>(`${baseUrl || this.baseURL}${this.basePath}/billable-rate${getQueryStrings(queryList)}`, { headers });
	}

	public getActiveRate({ baseUrl, params, headers }: RequestOption<InsightsGetActiveRateParams>) {
		const queryList: QueryParam[] = [];
		if (params.startDate !== undefined && params.startDate !== null) {
			queryList.push({ name: 'startDate', value: params.startDate.toString() });
		}
		if (params.endDate !== undefined && params.endDate !== null) {
			queryList.push({ name: 'endDate', value: params.endDate.toString() });
		}
		if (params.granularity !== undefined && params.granularity !== null) {
			queryList.push({ name: 'granularity', value: params.granularity.toString() });
		}
		if (params.roleType !== undefined && params.roleType !== null) {
			queryList.push({ name: 'roleType', value: params.roleType.toString() });
		}
		return axios.get<APIResponse<{ [key: any]: any }>>(`${baseUrl || this.baseURL}${this.basePath}/active-rate${getQueryStrings(queryList)}`, { headers });
	}

	public getTotalBillable({ baseUrl, params, headers }: RequestOption<InsightsGetTotalBillableParams>) {
		const queryList: QueryParam[] = [];
		if (params.startDate !== undefined && params.startDate !== null) {
			queryList.push({ name: 'startDate', value: params.startDate.toString() });
		}
		if (params.endDate !== undefined && params.endDate !== null) {
			queryList.push({ name: 'endDate', value: params.endDate.toString() });
		}
		if (params.granularity !== undefined && params.granularity !== null) {
			queryList.push({ name: 'granularity', value: params.granularity.toString() });
		}
		if (params.roleType !== undefined && params.roleType !== null) {
			queryList.push({ name: 'roleType', value: params.roleType.toString() });
		}
		return axios.get<APIResponse<{ [key: any]: any }>>(`${baseUrl || this.baseURL}${this.basePath}/total-billable-time${getQueryStrings(queryList)}`, { headers });
	}

	public getBgClinicalOutcome({ baseUrl, params, headers }: RequestOption<InsightsGetBgClinicalOutcomeParams>) {
		const queryList: QueryParam[] = [];
		if (params.roleType !== undefined && params.roleType !== null) {
			queryList.push({ name: 'roleType', value: params.roleType.toString() });
		}
		return axios.get<APIResponse<{ [key: any]: any }>>(`${baseUrl || this.baseURL}${this.basePath}/clinical-outcome-bg${getQueryStrings(queryList)}`, { headers });
	}

	public getBpClinicalOutcome({ baseUrl, params, headers }: RequestOption<InsightsGetBpClinicalOutcomeParams>) {
		const queryList: QueryParam[] = [];
		if (params.roleType !== undefined && params.roleType !== null) {
			queryList.push({ name: 'roleType', value: params.roleType.toString() });
		}
		return axios.get<APIResponse<{ [key: any]: any }>>(`${baseUrl || this.baseURL}${this.basePath}/clinical-outcome-bp${getQueryStrings(queryList)}`, { headers });
	}

	public getBgComplianceRate({ baseUrl, params, headers }: RequestOption<InsightsGetBgComplianceRateParams>) {
		const queryList: QueryParam[] = [];
		if (params.roleType !== undefined && params.roleType !== null) {
			queryList.push({ name: 'roleType', value: params.roleType.toString() });
		}
		return axios.get<APIResponse<{ [key: any]: any }>>(`${baseUrl || this.baseURL}${this.basePath}/compliance-rate-bg${getQueryStrings(queryList)}`, { headers });
	}

	public getBpComplianceRate({ baseUrl, params, headers }: RequestOption<InsightsGetBpComplianceRateParams>) {
		const queryList: QueryParam[] = [];
		if (params.roleType !== undefined && params.roleType !== null) {
			queryList.push({ name: 'roleType', value: params.roleType.toString() });
		}
		return axios.get<APIResponse<{ [key: any]: any }>>(`${baseUrl || this.baseURL}${this.basePath}/compliance-rate-bp${getQueryStrings(queryList)}`, { headers });
	}
}

export const insightsService = new InsightsService();

export const createInsightsGetDemographicsObject = (value: APIResponse<{ [key: any]: any }>) => new APIResponseClass<{ [key: any]: any }>(value, (v) => (v));

export const useInsightsGetDemographics = <RCN = undefined>(configs: MakeRequestHookConfig<InsightsGetDemographicsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InsightsGetDemographicsParams,
    APIResponse<{ [key: any]: any }>,
    typeof insightsService.getDemographics,
    GetOptions,
    APIResponseClass<{ [key: any]: any }>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof insightsService.getDemographics>) => (
      insightsService.getDemographics(...args)
    ),
    ResponseClassCreator: createInsightsGetDemographicsObject,
    typeName: 'useInsightsGetDemographics',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInsightsGetDemographics.mock = () => new Error('Mock has not been implemented!');
useInsightsGetDemographics.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsightsGetDemographics.createObject = createInsightsGetDemographicsObject;

export const createInsightsGetBillableRateObject = (value: APIResponse<{ [key: any]: any }>) => new APIResponseClass<{ [key: any]: any }>(value, (v) => (v));

export const useInsightsGetBillableRate = <RCN = undefined>(configs: MakeRequestHookConfig<InsightsGetBillableRateParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InsightsGetBillableRateParams,
    APIResponse<{ [key: any]: any }>,
    typeof insightsService.getBillableRate,
    GetOptions,
    APIResponseClass<{ [key: any]: any }>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof insightsService.getBillableRate>) => (
      insightsService.getBillableRate(...args)
    ),
    ResponseClassCreator: createInsightsGetBillableRateObject,
    typeName: 'useInsightsGetBillableRate',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInsightsGetBillableRate.mock = () => new Error('Mock has not been implemented!');
useInsightsGetBillableRate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsightsGetBillableRate.createObject = createInsightsGetBillableRateObject;

export const createInsightsGetActiveRateObject = (value: APIResponse<{ [key: any]: any }>) => new APIResponseClass<{ [key: any]: any }>(value, (v) => (v));

export const useInsightsGetActiveRate = <RCN = undefined>(configs: MakeRequestHookConfig<InsightsGetActiveRateParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InsightsGetActiveRateParams,
    APIResponse<{ [key: any]: any }>,
    typeof insightsService.getActiveRate,
    GetOptions,
    APIResponseClass<{ [key: any]: any }>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof insightsService.getActiveRate>) => (
      insightsService.getActiveRate(...args)
    ),
    ResponseClassCreator: createInsightsGetActiveRateObject,
    typeName: 'useInsightsGetActiveRate',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInsightsGetActiveRate.mock = () => new Error('Mock has not been implemented!');
useInsightsGetActiveRate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsightsGetActiveRate.createObject = createInsightsGetActiveRateObject;

export const createInsightsGetTotalBillableObject = (value: APIResponse<{ [key: any]: any }>) => new APIResponseClass<{ [key: any]: any }>(value, (v) => (v));

export const useInsightsGetTotalBillable = <RCN = undefined>(configs: MakeRequestHookConfig<InsightsGetTotalBillableParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InsightsGetTotalBillableParams,
    APIResponse<{ [key: any]: any }>,
    typeof insightsService.getTotalBillable,
    GetOptions,
    APIResponseClass<{ [key: any]: any }>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof insightsService.getTotalBillable>) => (
      insightsService.getTotalBillable(...args)
    ),
    ResponseClassCreator: createInsightsGetTotalBillableObject,
    typeName: 'useInsightsGetTotalBillable',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInsightsGetTotalBillable.mock = () => new Error('Mock has not been implemented!');
useInsightsGetTotalBillable.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsightsGetTotalBillable.createObject = createInsightsGetTotalBillableObject;

export const createInsightsGetBgClinicalOutcomeObject = (value: APIResponse<{ [key: any]: any }>) => new APIResponseClass<{ [key: any]: any }>(value, (v) => (v));

export const useInsightsGetBgClinicalOutcome = <RCN = undefined>(configs: MakeRequestHookConfig<InsightsGetBgClinicalOutcomeParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InsightsGetBgClinicalOutcomeParams,
    APIResponse<{ [key: any]: any }>,
    typeof insightsService.getBgClinicalOutcome,
    GetOptions,
    APIResponseClass<{ [key: any]: any }>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof insightsService.getBgClinicalOutcome>) => (
      insightsService.getBgClinicalOutcome(...args)
    ),
    ResponseClassCreator: createInsightsGetBgClinicalOutcomeObject,
    typeName: 'useInsightsGetBgClinicalOutcome',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInsightsGetBgClinicalOutcome.mock = () => new Error('Mock has not been implemented!');
useInsightsGetBgClinicalOutcome.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsightsGetBgClinicalOutcome.createObject = createInsightsGetBgClinicalOutcomeObject;

export const createInsightsGetBpClinicalOutcomeObject = (value: APIResponse<{ [key: any]: any }>) => new APIResponseClass<{ [key: any]: any }>(value, (v) => (v));

export const useInsightsGetBpClinicalOutcome = <RCN = undefined>(configs: MakeRequestHookConfig<InsightsGetBpClinicalOutcomeParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InsightsGetBpClinicalOutcomeParams,
    APIResponse<{ [key: any]: any }>,
    typeof insightsService.getBpClinicalOutcome,
    GetOptions,
    APIResponseClass<{ [key: any]: any }>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof insightsService.getBpClinicalOutcome>) => (
      insightsService.getBpClinicalOutcome(...args)
    ),
    ResponseClassCreator: createInsightsGetBpClinicalOutcomeObject,
    typeName: 'useInsightsGetBpClinicalOutcome',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInsightsGetBpClinicalOutcome.mock = () => new Error('Mock has not been implemented!');
useInsightsGetBpClinicalOutcome.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsightsGetBpClinicalOutcome.createObject = createInsightsGetBpClinicalOutcomeObject;

export const createInsightsGetBgComplianceRateObject = (value: APIResponse<{ [key: any]: any }>) => new APIResponseClass<{ [key: any]: any }>(value, (v) => (v));

export const useInsightsGetBgComplianceRate = <RCN = undefined>(configs: MakeRequestHookConfig<InsightsGetBgComplianceRateParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InsightsGetBgComplianceRateParams,
    APIResponse<{ [key: any]: any }>,
    typeof insightsService.getBgComplianceRate,
    GetOptions,
    APIResponseClass<{ [key: any]: any }>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof insightsService.getBgComplianceRate>) => (
      insightsService.getBgComplianceRate(...args)
    ),
    ResponseClassCreator: createInsightsGetBgComplianceRateObject,
    typeName: 'useInsightsGetBgComplianceRate',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInsightsGetBgComplianceRate.mock = () => new Error('Mock has not been implemented!');
useInsightsGetBgComplianceRate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsightsGetBgComplianceRate.createObject = createInsightsGetBgComplianceRateObject;

export const createInsightsGetBpComplianceRateObject = (value: APIResponse<{ [key: any]: any }>) => new APIResponseClass<{ [key: any]: any }>(value, (v) => (v));

export const useInsightsGetBpComplianceRate = <RCN = undefined>(configs: MakeRequestHookConfig<InsightsGetBpComplianceRateParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InsightsGetBpComplianceRateParams,
    APIResponse<{ [key: any]: any }>,
    typeof insightsService.getBpComplianceRate,
    GetOptions,
    APIResponseClass<{ [key: any]: any }>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof insightsService.getBpComplianceRate>) => (
      insightsService.getBpComplianceRate(...args)
    ),
    ResponseClassCreator: createInsightsGetBpComplianceRateObject,
    typeName: 'useInsightsGetBpComplianceRate',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInsightsGetBpComplianceRate.mock = () => new Error('Mock has not been implemented!');
useInsightsGetBpComplianceRate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsightsGetBpComplianceRate.createObject = createInsightsGetBpComplianceRateObject;
export interface InsuranceProvider extends BaseModel {
	payerId?: Nullable<string>;
	payerName?: Nullable<string>;
	description?: Nullable<string>;
	clearingHouse?: Nullable<string>;
	hasCopay?: Nullable<boolean>;
	copay?: Nullable<string>;
	deductible?: Nullable<string>;
	nameNear?: Nullable<string>;
	descriptionNear?: Nullable<string>;
}
export interface EligibilityRequest {
	payerId?: Nullable<string>;
	payerName?: Nullable<string>;
	firstName?: Nullable<string>;
	lastName?: Nullable<string>;
	birthday?: Nullable<string>;
	policyNumber?: Nullable<string>;
	patientRelationship?: Nullable<InsuranceRelationshipEnum>;
	benefitOrder?: Nullable<BenefitOrderEnum>;
	providerName?: Nullable<string>;
	providerNpi?: Nullable<string>;
}
export interface EligibilityReport extends BaseModel {
	patientId?: Nullable<string>;
	eligibilityStatus?: Nullable<EligibilityStatusEnum>;
	inquiryId?: Nullable<string>;
	failReason?: Nullable<string>;
	inquiredBy?: Nullable<string>;
	shortHtml?: Nullable<string>;
	fullHtml?: Nullable<string>;
	eligibilityStartDate?: Nullable<string>;
	eligibilityEndDate?: Nullable<string>;
	serviceDate?: Nullable<string>;
	inquiredByUser?: Nullable<LimitedUser>;
}
export class EligibilityReportClass extends BaseModelClass {
  protected readonly _value: EligibilityReport;

	constructor(value: EligibilityReport) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientId() {
    return this._value.patientId;
  }

  get eligibilityStatus() {
    return this._value.eligibilityStatus;
  }

  eligibilityStatusIsIn(values: EligibilityStatusEnum[] = []) {
    return this._value.eligibilityStatus && values.includes(this._value.eligibilityStatus);
  }

  get inquiryId() {
    return this._value.inquiryId;
  }

  get inquiryIdWithDefault() {
    return this._value.inquiryId || '';
  }

  get failReason() {
    return this._value.failReason;
  }

  get failReasonWithDefault() {
    return this._value.failReason || '';
  }

  get inquiredBy() {
    return this._value.inquiredBy;
  }

  get shortHtml() {
    return this._value.shortHtml;
  }

  get shortHtmlWithDefault() {
    return this._value.shortHtml || '';
  }

  get fullHtml() {
    return this._value.fullHtml;
  }

  get fullHtmlWithDefault() {
    return this._value.fullHtml || '';
  }

  get eligibilityStartDate() {
    return this._value.eligibilityStartDate;
  }

  get eligibilityStartDateWithDefault() {
    return this._value.eligibilityStartDate || '';
  }

  get eligibilityEndDate() {
    return this._value.eligibilityEndDate;
  }

  get eligibilityEndDateWithDefault() {
    return this._value.eligibilityEndDate || '';
  }

  get serviceDate() {
    return (this._value.serviceDate !== undefined && this._value.serviceDate !== null) ? dayjs(this._value.serviceDate) : this._value.serviceDate;
  }

  get inquiredByUser() {
    return this._value.inquiredByUser !== undefined && this._value.inquiredByUser !== null ? new LimitedUserClass(this._value.inquiredByUser) : this._value.inquiredByUser;
  }
}
export interface InsurancePayerRequest {
	request?: Nullable<string>;
}
export enum InsuranceSourceEnum {
	WAYSTAR = 'WAYSTAR',
	MANUAL = 'MANUAL',
}
export interface InsurancePayer extends BaseModel {
	wayStarPayerId?: Nullable<string>;
	payerName?: Nullable<string>;
	source?: Nullable<InsuranceSourceEnum>;
}
export class InsurancePayerClass extends BaseModelClass {
  protected readonly _value: InsurancePayer;

	constructor(value: InsurancePayer) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get wayStarPayerId() {
    return this._value.wayStarPayerId;
  }

  get wayStarPayerIdWithDefault() {
    return this._value.wayStarPayerId || '';
  }

  get payerName() {
    return this._value.payerName;
  }

  get payerNameWithDefault() {
    return this._value.payerName || '';
  }

  get source() {
    return this._value.source;
  }

  sourceIsIn(values: InsuranceSourceEnum[] = []) {
    return this._value.source && values.includes(this._value.source);
  }
}
export class InsuranceProviderClass extends BaseModelClass {
  protected readonly _value: InsuranceProvider;

	constructor(value: InsuranceProvider) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get payerId() {
    return this._value.payerId;
  }

  get payerIdWithDefault() {
    return this._value.payerId || '';
  }

  get payerName() {
    return this._value.payerName;
  }

  get payerNameWithDefault() {
    return this._value.payerName || '';
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get clearingHouse() {
    return this._value.clearingHouse;
  }

  get clearingHouseWithDefault() {
    return this._value.clearingHouse || '';
  }

  get hasCopay() {
    return this._value.hasCopay;
  }

  get hasCopayWithDefault() {
    return this._value.hasCopay || false;
  }

  get copay() {
    return this._value.copay;
  }

  get copayWithDefault() {
    return this._value.copay || '';
  }

  get deductible() {
    return this._value.deductible;
  }

  get deductibleWithDefault() {
    return this._value.deductible || '';
  }

  get nameNear() {
    return this._value.nameNear;
  }

  get nameNearWithDefault() {
    return this._value.nameNear || '';
  }

  get descriptionNear() {
    return this._value.descriptionNear;
  }

  get descriptionNearWithDefault() {
    return this._value.descriptionNear || '';
  }
}
export interface InsuranceProviderVerifyEligibilityParams {
	memberId: string;
	request: EligibilityRequest;
}

export interface InsuranceProviderSearchPayersParams {
	request: SearchRequest<InsurancePayerRequest>;
}

export interface InsuranceProviderGetEligibilityHistoryParams {
	memberId: string;
}

export interface InsuranceProviderGetEligibilityReportParams {
	inquiryId: string;
}

export class InsuranceProviderService extends BaseController<InsuranceProvider, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/insurance-provider';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public verifyEligibility({ baseUrl, params, headers }: RequestOption<InsuranceProviderVerifyEligibilityParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<EligibilityReport>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/eligibility${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public searchPayers({ baseUrl, params, headers }: RequestOption<InsuranceProviderSearchPayersParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<InsurancePayer>>>(`${baseUrl || this.baseURL}${this.basePath}/search-payer${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getEligibilityHistory({ baseUrl, params, headers }: RequestOption<InsuranceProviderGetEligibilityHistoryParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<EligibilityReport[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/eligibility-history${getQueryStrings(queryList)}`, { headers });
	}

	public getEligibilityReport({ baseUrl, params, headers }: RequestOption<InsuranceProviderGetEligibilityReportParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<EligibilityReport>>(`${baseUrl || this.baseURL}${this.basePath}/${params.inquiryId}/get-report${getQueryStrings(queryList)}`, { headers });
	}
}

export const insuranceProviderService = new InsuranceProviderService();

export const createInsuranceProviderVerifyEligibilityObject = (value: APIResponse<EligibilityReport>) => new APIResponseClass<EligibilityReportClass>(value, (value: EligibilityReport) => new EligibilityReportClass(value));

export const useInsuranceProviderVerifyEligibility = <RCN = undefined>(configs: MakeRequestHookConfig<InsuranceProviderVerifyEligibilityParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InsuranceProviderVerifyEligibilityParams,
    APIResponse<EligibilityReport>,
    typeof insuranceProviderService.verifyEligibility,
    AjaxOptions,
    APIResponseClass<EligibilityReportClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof insuranceProviderService.verifyEligibility>) => (
      insuranceProviderService.verifyEligibility(...args)
    ),
    ResponseClassCreator: createInsuranceProviderVerifyEligibilityObject,
    typeName: 'useInsuranceProviderVerifyEligibility',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInsuranceProviderVerifyEligibility.mock = () => new Error('Mock has not been implemented!');
useInsuranceProviderVerifyEligibility.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsuranceProviderVerifyEligibility.createObject = createInsuranceProviderVerifyEligibilityObject;

export const createInsuranceProviderSearchPayersObject = (value: APIResponse<PageResponse<InsurancePayer>>) => new APIResponseClass<PageResponseClass<InsurancePayerClass>>(value, (value: PageResponse<InsurancePayer>) => new PageResponseClass<InsurancePayerClass>(value, (value: InsurancePayer) => new InsurancePayerClass(value)));

export const useInsuranceProviderSearchPayers = <RCN = undefined>(configs: MakeRequestHookConfig<InsuranceProviderSearchPayersParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InsuranceProviderSearchPayersParams,
    APIResponse<PageResponse<InsurancePayer>>,
    typeof insuranceProviderService.searchPayers,
    AjaxOptions,
    APIResponseClass<PageResponseClass<InsurancePayerClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof insuranceProviderService.searchPayers>) => (
      insuranceProviderService.searchPayers(...args)
    ),
    ResponseClassCreator: createInsuranceProviderSearchPayersObject,
    typeName: 'useInsuranceProviderSearchPayers',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInsuranceProviderSearchPayers.mock = () => new Error('Mock has not been implemented!');
useInsuranceProviderSearchPayers.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsuranceProviderSearchPayers.createObject = createInsuranceProviderSearchPayersObject;

export const createInsuranceProviderGetEligibilityHistoryObject = (value: APIResponse<EligibilityReport[]>) => new APIResponseClass<EligibilityReportClass[]>(value, (v) => map(v, (value: EligibilityReport) => new EligibilityReportClass(value)));

export const useInsuranceProviderGetEligibilityHistory = <RCN = undefined>(configs: MakeRequestHookConfig<InsuranceProviderGetEligibilityHistoryParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InsuranceProviderGetEligibilityHistoryParams,
    APIResponse<EligibilityReport[]>,
    typeof insuranceProviderService.getEligibilityHistory,
    GetOptions,
    APIResponseClass<EligibilityReportClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof insuranceProviderService.getEligibilityHistory>) => (
      insuranceProviderService.getEligibilityHistory(...args)
    ),
    ResponseClassCreator: createInsuranceProviderGetEligibilityHistoryObject,
    typeName: 'useInsuranceProviderGetEligibilityHistory',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInsuranceProviderGetEligibilityHistory.mock = () => new Error('Mock has not been implemented!');
useInsuranceProviderGetEligibilityHistory.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsuranceProviderGetEligibilityHistory.createObject = createInsuranceProviderGetEligibilityHistoryObject;

export const createInsuranceProviderGetEligibilityReportObject = (value: APIResponse<EligibilityReport>) => new APIResponseClass<EligibilityReportClass>(value, (value: EligibilityReport) => new EligibilityReportClass(value));

export const useInsuranceProviderGetEligibilityReport = <RCN = undefined>(configs: MakeRequestHookConfig<InsuranceProviderGetEligibilityReportParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InsuranceProviderGetEligibilityReportParams,
    APIResponse<EligibilityReport>,
    typeof insuranceProviderService.getEligibilityReport,
    GetOptions,
    APIResponseClass<EligibilityReportClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof insuranceProviderService.getEligibilityReport>) => (
      insuranceProviderService.getEligibilityReport(...args)
    ),
    ResponseClassCreator: createInsuranceProviderGetEligibilityReportObject,
    typeName: 'useInsuranceProviderGetEligibilityReport',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInsuranceProviderGetEligibilityReport.mock = () => new Error('Mock has not been implemented!');
useInsuranceProviderGetEligibilityReport.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsuranceProviderGetEligibilityReport.createObject = createInsuranceProviderGetEligibilityReportObject;


export const createInsuranceProviderGetObject = (value: APIResponse<InsuranceProvider>) => new APIResponseClass<InsuranceProviderClass>(value, (value: InsuranceProvider) => new InsuranceProviderClass(value));
export const useInsuranceProviderGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<InsuranceProvider>,
    // @ts-ignore
    typeof insuranceProviderService.get,
    GetOptions,
    APIResponseClass<InsuranceProviderClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof insuranceProviderService.get>) => (
      insuranceProviderService.get(...args)
    ),
    ResponseClassCreator: createInsuranceProviderGetObject,
    typeName: 'useInsuranceProviderGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useInsuranceProviderGet.mock = () => new Error('Mock has not been implemented!');
useInsuranceProviderGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsuranceProviderGet.createObject = createInsuranceProviderGetObject;

export const createInsuranceProviderUpdateObject = (value: APIResponse<InsuranceProvider>) => new APIResponseClass<InsuranceProviderClass>(value, (value: InsuranceProvider) => new InsuranceProviderClass(value));
export const useInsuranceProviderUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, InsuranceProvider>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, InsuranceProvider>,
    APIResponse<InsuranceProvider>,
    // @ts-ignore
    typeof insuranceProviderService.update,
    AjaxOptions,
    APIResponseClass<InsuranceProviderClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof insuranceProviderService.update>) => (
      insuranceProviderService.update(...args)
    ),
    ResponseClassCreator: createInsuranceProviderUpdateObject,
    typeName: 'useInsuranceProviderUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useInsuranceProviderUpdate.mock = () => new Error('Mock has not been implemented!');
useInsuranceProviderUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsuranceProviderUpdate.createObject = createInsuranceProviderUpdateObject;

export const createInsuranceProviderInsertObject = (value: APIResponse<InsuranceProvider>) => new APIResponseClass<InsuranceProviderClass>(value, (value: InsuranceProvider) => new InsuranceProviderClass(value));
export const useInsuranceProviderInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<InsuranceProvider>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<InsuranceProvider>,
    APIResponse<InsuranceProvider>,
    // @ts-ignore
    typeof insuranceProviderService.insert,
    AjaxOptions,
    APIResponseClass<InsuranceProviderClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof insuranceProviderService.insert>) => (
      insuranceProviderService.insert(...args)
    ),
    ResponseClassCreator: createInsuranceProviderInsertObject,
    typeName: 'useInsuranceProviderInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useInsuranceProviderInsert.mock = () => new Error('Mock has not been implemented!');
useInsuranceProviderInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsuranceProviderInsert.createObject = createInsuranceProviderInsertObject;

export const createInsuranceProviderDeleteObject = (value: APIResponse<InsuranceProvider>) => new APIResponseClass<InsuranceProviderClass>(value, (value: InsuranceProvider) => new InsuranceProviderClass(value));
export const useInsuranceProviderDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<InsuranceProvider>,
    // @ts-ignore
    typeof insuranceProviderService.delete,
    AjaxOptions,
    APIResponseClass<InsuranceProviderClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof insuranceProviderService.delete>) => (
      insuranceProviderService.delete(...args)
    ),
    ResponseClassCreator: createInsuranceProviderDeleteObject,
    typeName: 'useInsuranceProviderDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useInsuranceProviderDelete.mock = () => new Error('Mock has not been implemented!');
useInsuranceProviderDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsuranceProviderDelete.createObject = createInsuranceProviderDeleteObject;

export const createInsuranceProviderSearchObject = (value: APIResponse<PageResponse<InsuranceProvider>>) => new APIResponseClass<PageResponseClass<InsuranceProviderClass>>(value, (value: PageResponse<InsuranceProvider>) => new PageResponseClass<InsuranceProviderClass>(value, (value: InsuranceProvider) => new InsuranceProviderClass(value)));
export const useInsuranceProviderSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<InsuranceProvider>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<InsuranceProvider>,
    APIResponse<PageResponse<InsuranceProvider>>,
    // @ts-ignore
    typeof insuranceProviderService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<InsuranceProviderClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof insuranceProviderService.search>) => (
      insuranceProviderService.search(...args)
    ),
    ResponseClassCreator: createInsuranceProviderSearchObject,
    typeName: 'useInsuranceProviderSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useInsuranceProviderSearch.mock = () => new Error('Mock has not been implemented!');
useInsuranceProviderSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInsuranceProviderSearch.createObject = createInsuranceProviderSearchObject;

export enum EventTypeEnum {
	BP = 'BP',
	BG = 'BG',
	BP_RECHECK = 'BP_RECHECK',
	BG_RECHECK = 'BG_RECHECK',
}
export enum MedicalAlertCategoryEnum {
	CLINICAL = 'CLINICAL',
	PROVIDER = 'PROVIDER',
}
export interface MedicalAlert extends PatientBase {
	memberId?: Nullable<string>;
	measureId?: Nullable<string>;
	ruleId?: Nullable<string>;
	eventType?: Nullable<EventTypeEnum>;
	alertType?: Nullable<string>;
	alertCategory?: Nullable<MedicalAlertCategoryEnum>;
	priority?: Nullable<PriorityLevelEnum>;
	dueDate?: Nullable<string>;
	status?: Nullable<SmartAlertTaskStatus>;
	reason?: Nullable<string>;
	description?: Nullable<string>;
	displayName?: Nullable<string>;
	closedAt?: Nullable<string>;
	closedBy?: Nullable<string>;
	measureTime?: Nullable<string>;
	systemNote?: Nullable<string>;
	delayed?: Nullable<boolean>;
	closedByUser?: Nullable<LimitedUser>;
	priorityIn?: Nullable<InFilter<PriorityLevelEnum>>;
	alertTypeIn?: Nullable<InFilter<string>>;
	statusIn?: Nullable<InFilter<SmartAlertTaskStatus>>;
	alertCategoryIn?: Nullable<InFilter<MedicalAlertCategoryEnum>>;
	memberIdIn?: Nullable<InFilter<string>>;
	measureIdIn?: Nullable<InFilter<string>>;
	closeAtRange?: Nullable<DateRangeFilter>;
}
export class MedicalAlertClass extends PatientBaseClass {
  protected readonly _value: MedicalAlert;

	constructor(value: MedicalAlert) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get measureId() {
    return this._value.measureId;
  }

  get ruleId() {
    return this._value.ruleId;
  }

  get eventType() {
    return this._value.eventType;
  }

  eventTypeIsIn(values: EventTypeEnum[] = []) {
    return this._value.eventType && values.includes(this._value.eventType);
  }

  get alertType() {
    return this._value.alertType;
  }

  get alertTypeWithDefault() {
    return this._value.alertType || '';
  }

  get alertCategory() {
    return this._value.alertCategory;
  }

  alertCategoryIsIn(values: MedicalAlertCategoryEnum[] = []) {
    return this._value.alertCategory && values.includes(this._value.alertCategory);
  }

  get priority() {
    return this._value.priority;
  }

  priorityIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.priority && values.includes(this._value.priority);
  }

  get dueDate() {
    return (this._value.dueDate !== undefined && this._value.dueDate !== null) ? dayjs(this._value.dueDate) : this._value.dueDate;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: SmartAlertTaskStatus[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get reason() {
    return this._value.reason;
  }

  get reasonWithDefault() {
    return this._value.reason || '';
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get displayName() {
    return this._value.displayName;
  }

  get displayNameWithDefault() {
    return this._value.displayName || '';
  }

  get closedAt() {
    return (this._value.closedAt !== undefined && this._value.closedAt !== null) ? dayjs(this._value.closedAt) : this._value.closedAt;
  }

  get closedBy() {
    return this._value.closedBy;
  }

  get measureTime() {
    return (this._value.measureTime !== undefined && this._value.measureTime !== null) ? dayjs(this._value.measureTime) : this._value.measureTime;
  }

  get systemNote() {
    return this._value.systemNote;
  }

  get systemNoteWithDefault() {
    return this._value.systemNote || '';
  }

  get delayed() {
    return this._value.delayed;
  }

  get delayedWithDefault() {
    return this._value.delayed || false;
  }

  get closedByUser() {
    return this._value.closedByUser !== undefined && this._value.closedByUser !== null ? new LimitedUserClass(this._value.closedByUser) : this._value.closedByUser;
  }

  get priorityIn() {
    return this._value.priorityIn !== undefined && this._value.priorityIn !== null ? new InFilterClass(this._value.priorityIn) : this._value.priorityIn;
  }

  get alertTypeIn() {
    return this._value.alertTypeIn !== undefined && this._value.alertTypeIn !== null ? new InFilterClass(this._value.alertTypeIn) : this._value.alertTypeIn;
  }

  get statusIn() {
    return this._value.statusIn !== undefined && this._value.statusIn !== null ? new InFilterClass(this._value.statusIn) : this._value.statusIn;
  }

  get alertCategoryIn() {
    return this._value.alertCategoryIn !== undefined && this._value.alertCategoryIn !== null ? new InFilterClass(this._value.alertCategoryIn) : this._value.alertCategoryIn;
  }

  get memberIdIn() {
    return this._value.memberIdIn !== undefined && this._value.memberIdIn !== null ? new InFilterClass(this._value.memberIdIn) : this._value.memberIdIn;
  }

  get measureIdIn() {
    return this._value.measureIdIn !== undefined && this._value.measureIdIn !== null ? new InFilterClass(this._value.measureIdIn) : this._value.measureIdIn;
  }

  get closeAtRange() {
    return this._value.closeAtRange !== undefined && this._value.closeAtRange !== null ? new DateRangeFilterClass(this._value.closeAtRange) : this._value.closeAtRange;
  }
}
export enum ConsentStatusEnum {
	SIGNED = 'SIGNED',
	INIT = 'INIT',
	SIGNED_DISCHARGED = 'SIGNED_DISCHARGED',
}
export interface PatientConsent extends BaseModel {
	memberId?: Nullable<string>;
	status?: Nullable<ConsentStatusEnum>;
	provider?: Nullable<LimitedUser>;
	consentType?: Nullable<SignatureCollectionMethodEnum>;
	formType?: Nullable<ConsentFormType>;
	effectiveDate?: Nullable<string>;
	signatureAt?: Nullable<string>;
	content?: Nullable<string>;
	fileInfo?: Nullable<FileInfo>;
	source?: Nullable<string>;
	consent?: Nullable<boolean>;
	date?: Nullable<string>;
	isDischarged?: Nullable<boolean>;
	enrolledBy?: Nullable<string>;
	consentTitle?: Nullable<string>;
	consentSubTitle?: Nullable<string>;
	templateTitle?: Nullable<string>;
	updatedConsent?: Nullable<boolean>;
	batchSentConsent?: Nullable<boolean>;
	organizationId?: Nullable<string>;
	organizationIds?: Nullable<string[]>;
}
export class PatientConsentClass extends BaseModelClass {
  protected readonly _value: PatientConsent;

	constructor(value: PatientConsent) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: ConsentStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get provider() {
    return this._value.provider !== undefined && this._value.provider !== null ? new LimitedUserClass(this._value.provider) : this._value.provider;
  }

  get consentType() {
    return this._value.consentType;
  }

  consentTypeIsIn(values: SignatureCollectionMethodEnum[] = []) {
    return this._value.consentType && values.includes(this._value.consentType);
  }

  get formType() {
    return this._value.formType;
  }

  formTypeIsIn(values: ConsentFormType[] = []) {
    return this._value.formType && values.includes(this._value.formType);
  }

  get effectiveDate() {
    return (this._value.effectiveDate !== undefined && this._value.effectiveDate !== null) ? dayjs(this._value.effectiveDate) : this._value.effectiveDate;
  }

  get signatureAt() {
    return (this._value.signatureAt !== undefined && this._value.signatureAt !== null) ? dayjs(this._value.signatureAt) : this._value.signatureAt;
  }

  get content() {
    return this._value.content;
  }

  get contentWithDefault() {
    return this._value.content || '';
  }

  get fileInfo() {
    return this._value.fileInfo !== undefined && this._value.fileInfo !== null ? new FileInfoClass(this._value.fileInfo) : this._value.fileInfo;
  }

  get source() {
    return this._value.source;
  }

  get sourceWithDefault() {
    return this._value.source || '';
  }

  get consent() {
    return this._value.consent;
  }

  get consentWithDefault() {
    return this._value.consent || false;
  }

  get date() {
    return (this._value.date !== undefined && this._value.date !== null) ? dayjs(this._value.date) : this._value.date;
  }

  get isDischarged() {
    return this._value.isDischarged;
  }

  get isDischargedWithDefault() {
    return this._value.isDischarged || false;
  }

  get enrolledBy() {
    return this._value.enrolledBy;
  }

  get consentTitle() {
    return this._value.consentTitle;
  }

  get consentTitleWithDefault() {
    return this._value.consentTitle || '';
  }

  get consentSubTitle() {
    return this._value.consentSubTitle;
  }

  get consentSubTitleWithDefault() {
    return this._value.consentSubTitle || '';
  }

  get templateTitle() {
    return this._value.templateTitle;
  }

  get templateTitleWithDefault() {
    return this._value.templateTitle || '';
  }

  get updatedConsent() {
    return this._value.updatedConsent;
  }

  get updatedConsentWithDefault() {
    return this._value.updatedConsent || false;
  }

  get batchSentConsent() {
    return this._value.batchSentConsent;
  }

  get batchSentConsentWithDefault() {
    return this._value.batchSentConsent || false;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get organizationIds() {
    return this._value.organizationIds;
  }

  get organizationIdsWithDefault() {
    return this._value.organizationIds || [];
  }
}
export enum TimeOfTheDayEnum {
	MORNING = 'MORNING',
	AFTERNOON = 'AFTERNOON',
	EVENING = 'EVENING',
	OVERNIGHT = 'OVERNIGHT',
}
export enum BpTriageLevelEnum {
	TRIAGE_NOOP = 'TRIAGE_NOOP',
	TRIAGE_3_HOUR = 'TRIAGE_3_HOUR',
	TRIAGE_10_MIN = 'TRIAGE_10_MIN',
	TRIAGE_IMMEDIATE = 'TRIAGE_IMMEDIATE',
	TRIAGE_NOOP_IGNORE = 'TRIAGE_NOOP_IGNORE',
	info = 'info',
}
export enum SymptomTypeEnum {
	LOW_BP = 'LOW_BP',
	HIGH_BP = 'HIGH_BP',
}
export enum BpSymptomEnum {
	OTHER = 'OTHER',
	CONFUSION = 'CONFUSION',
	DIZZINESS = 'DIZZINESS',
	NAUSEA = 'NAUSEA',
	FAINTING = 'FAINTING',
	FATIGUE = 'FATIGUE',
	NECK_OR_BACK_PAIN = 'NECK_OR_BACK_PAIN',
	HEADACHE = 'HEADACHE',
	BLURRED_VISION = 'BLURRED_VISION',
	HEART_PALPITATIONS = 'HEART_PALPITATIONS',
	IRREGULAR_HEARTBEAT = 'IRREGULAR_HEARTBEAT',
	CHEST_PAIN = 'CHEST_PAIN',
	SHORTNESS_OF_BREATH = 'SHORTNESS_OF_BREATH',
	BACK_PAIN = 'BACK_PAIN',
	NUMBNESS = 'NUMBNESS',
	WEAKNESS = 'WEAKNESS',
	CHANGE_IN_VISION = 'CHANGE_IN_VISION',
	DIFFICULTY_SPEAKING = 'DIFFICULTY_SPEAKING',
}
export interface Symptom {
	symptomType?: Nullable<SymptomTypeEnum>;
	bpSymptomList?: Nullable<BpSymptomEnum[]>;
}
export enum MeasurementDeletionReasonEnum {
	CREATED_BY_MISTAKE = 'CREATED_BY_MISTAKE',
	CREATED_BY_SOMEONE_ELSE = 'CREATED_BY_SOMEONE_ELSE',
	DUPLICATE_ENTRY = 'DUPLICATE_ENTRY',
	FAULTY_READING = 'FAULTY_READING',
	PRIVACY_CONCERN = 'PRIVACY_CONCERN',
}
export interface BloodPressure {
	value?: Nullable<number>;
	unit?: Nullable<string>;
}
export interface HeartRate {
	value?: Nullable<number>;
	unit?: Nullable<string>;
}
export enum BpSeverityEnum {
	LOW = 'LOW',
	NORMAL = 'NORMAL',
	ELEVATED = 'ELEVATED',
	HYPERTENSION_STAGE_1 = 'HYPERTENSION_STAGE_1',
	HYPERTENSION_STAGE_2 = 'HYPERTENSION_STAGE_2',
	HYPERTENSIVE_CRISIS = 'HYPERTENSIVE_CRISIS',
}
export enum BgSeverityEnum {
	VERY_LOW = 'VERY_LOW',
	LOW = 'LOW',
	NORMAL = 'NORMAL',
	HIGH = 'HIGH',
	CRITICAL = 'CRITICAL',
}
export interface BloodOxygen {
	value?: Nullable<number>;
	unit?: Nullable<string>;
}
export interface PerfusionIndex {
	value?: Nullable<number>;
	unit?: Nullable<string>;
}
export enum PoSeverityEnum {
	NORMAL = 'NORMAL',
	LOW = 'LOW',
	CRITICAL_LOW = 'CRITICAL_LOW',
}
export interface BodyTemperature {
	value?: Nullable<number>;
	unit?: Nullable<string>;
}
export interface Step {
	value?: Nullable<number>;
	unit?: Nullable<string>;
}
export interface MeasurementResultDetail {
	blood_oxygen?: Nullable<BloodOxygen>;
	heart_rate?: Nullable<HeartRate>;
	perfusion_index?: Nullable<PerfusionIndex>;
	date?: Nullable<string>;
	startTime?: Nullable<string>;
	endTime?: Nullable<string>;
	steps?: Nullable<Step>;
}
export enum AlertSeverityEnum {
	MISSED = 'MISSED',
	NORMAL = 'NORMAL',
	RISK = 'RISK',
	CRITICAL = 'CRITICAL',
}
export enum CareStatusEnum {
	NEW = 'NEW',
	HANDLED = 'HANDLED',
	CLOSED = 'CLOSED',
}
export interface Measurement extends BaseModel {
	memberId?: Nullable<string>;
	measureBy?: Nullable<string>;
	measureHash?: Nullable<string>;
	date?: Nullable<string>;
	day?: Nullable<string>;
	timeOfTheDay?: Nullable<TimeOfTheDayEnum>;
	timezone?: Nullable<string>;
	type?: Nullable<MeasurementResultTypeEnum>;
	deviceAddress?: Nullable<string>;
	deviceModel?: Nullable<string>;
	manualInput?: Nullable<boolean>;
	delayedUpload?: Nullable<boolean>;
	user_notes?: Nullable<string>;
	imageUrl?: Nullable<string>;
	dataId?: Nullable<string>;
	msg?: Nullable<string>;
	hadMedicine?: Nullable<boolean>;
	hadActivity?: Nullable<boolean>;
	hadInsulin?: Nullable<boolean>;
	moodTags?: Nullable<string[]>;
	measureIndex?: Nullable<number>;
	triageLevel?: Nullable<BpTriageLevelEnum>;
	symptom?: Nullable<Symptom>;
	softDeleted?: Nullable<boolean>;
	deleteReason?: Nullable<MeasurementDeletionReasonEnum>;
	deletedBy?: Nullable<string>;
	deletedByUser?: Nullable<LimitedUser>;
	softDeletedNe?: Nullable<NeFilter<boolean>>;
	triageInfo?: Nullable<string>;
	refMeasurementId?: Nullable<string>;
	systolic_blood_pressure?: Nullable<BloodPressure>;
	diastolic_blood_pressure?: Nullable<BloodPressure>;
	heart_rate?: Nullable<HeartRate>;
	body_posture?: Nullable<string>;
	arrhythmia?: Nullable<boolean>;
	wavelet?: Nullable<number[]>;
	bpSeverity?: Nullable<BpSeverityEnum>;
	blood_glucose?: Nullable<BloodGlucose>;
	beforeMeal?: Nullable<boolean>;
	mealTimePassed?: Nullable<number>;
	mealType?: Nullable<BgMealTypeEnum>;
	bgSeverity?: Nullable<BgSeverityEnum>;
	body_weight?: Nullable<Weight>;
	body_weight_change?: Nullable<Weight>;
	blood_oxygen?: Nullable<BloodOxygen>;
	perfusion_index?: Nullable<PerfusionIndex>;
	poSeverity?: Nullable<PoSeverityEnum>;
	isCopd?: Nullable<boolean>;
	body_temperature?: Nullable<BodyTemperature>;
	steps?: Nullable<BloodOxygen>;
	details?: Nullable<MeasurementResultDetail[]>;
	severity?: Nullable<AlertSeverityEnum>;
	careStatus?: Nullable<CareStatusEnum>;
	shouldAlert?: Nullable<boolean>;
	dateRange?: Nullable<DateRangeFilter>;
	typeIn?: Nullable<InFilter<MeasurementResultTypeEnum>>;
	severityIn?: Nullable<InFilter<AlertSeverityEnum>>;
	careStatusIn?: Nullable<InFilter<CareStatusEnum>>;
	memberIdIn?: Nullable<InFilter<string>>;
	resultSource?: Nullable<ResultSourceEnum>;
	isBaseline?: Nullable<boolean>;
	source?: Nullable<string>;
	resultId?: Nullable<string>;
	weightFixTag?: Nullable<string>;
	tag?: Nullable<string>;
	resultSourceIn?: Nullable<InFilter<ResultSourceEnum>>;
}
export class SymptomClass {
  protected readonly _value: Symptom;

	constructor(value: Symptom) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get symptomType() {
    return this._value.symptomType;
  }

  symptomTypeIsIn(values: SymptomTypeEnum[] = []) {
    return this._value.symptomType && values.includes(this._value.symptomType);
  }

  get bpSymptomList() {
    return this._value.bpSymptomList;
  }

  get bpSymptomListWithDefault() {
    return this._value.bpSymptomList || [];
  }
}
export class BloodPressureClass {
  protected readonly _value: BloodPressure;

	constructor(value: BloodPressure) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || 0;
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }
}
export class HeartRateClass {
  protected readonly _value: HeartRate;

	constructor(value: HeartRate) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || 0;
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }
}
export class BloodOxygenClass {
  protected readonly _value: BloodOxygen;

	constructor(value: BloodOxygen) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || 0;
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }
}
export class PerfusionIndexClass {
  protected readonly _value: PerfusionIndex;

	constructor(value: PerfusionIndex) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || 0.0;
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }
}
export class BodyTemperatureClass {
  protected readonly _value: BodyTemperature;

	constructor(value: BodyTemperature) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || 0.0;
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }
}
export class StepClass {
  protected readonly _value: Step;

	constructor(value: Step) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || 0;
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }
}
export class MeasurementResultDetailClass {
  protected readonly _value: MeasurementResultDetail;

	constructor(value: MeasurementResultDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get blood_oxygen() {
    return this._value.blood_oxygen !== undefined && this._value.blood_oxygen !== null ? new BloodOxygenClass(this._value.blood_oxygen) : this._value.blood_oxygen;
  }

  get heart_rate() {
    return this._value.heart_rate !== undefined && this._value.heart_rate !== null ? new HeartRateClass(this._value.heart_rate) : this._value.heart_rate;
  }

  get perfusion_index() {
    return this._value.perfusion_index !== undefined && this._value.perfusion_index !== null ? new PerfusionIndexClass(this._value.perfusion_index) : this._value.perfusion_index;
  }

  get date() {
    return (this._value.date !== undefined && this._value.date !== null) ? dayjs(this._value.date) : this._value.date;
  }

  get startTime() {
    return (this._value.startTime !== undefined && this._value.startTime !== null) ? dayjs(this._value.startTime) : this._value.startTime;
  }

  get endTime() {
    return (this._value.endTime !== undefined && this._value.endTime !== null) ? dayjs(this._value.endTime) : this._value.endTime;
  }

  get steps() {
    return this._value.steps !== undefined && this._value.steps !== null ? new StepClass(this._value.steps) : this._value.steps;
  }
}
export class MeasurementClass extends BaseModelClass {
  protected readonly _value: Measurement;

	constructor(value: Measurement) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get measureBy() {
    return this._value.measureBy;
  }

  get measureHash() {
    return this._value.measureHash;
  }

  get measureHashWithDefault() {
    return this._value.measureHash || '';
  }

  get date() {
    return (this._value.date !== undefined && this._value.date !== null) ? dayjs(this._value.date) : this._value.date;
  }

  get day() {
    return this._value.day;
  }

  get dayWithDefault() {
    return this._value.day || '';
  }

  get timeOfTheDay() {
    return this._value.timeOfTheDay;
  }

  timeOfTheDayIsIn(values: TimeOfTheDayEnum[] = []) {
    return this._value.timeOfTheDay && values.includes(this._value.timeOfTheDay);
  }

  get timezone() {
    return this._value.timezone;
  }

  get timezoneWithDefault() {
    return this._value.timezone || '';
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: MeasurementResultTypeEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get deviceAddress() {
    return this._value.deviceAddress;
  }

  get deviceAddressWithDefault() {
    return this._value.deviceAddress || '';
  }

  get deviceModel() {
    return this._value.deviceModel;
  }

  get deviceModelWithDefault() {
    return this._value.deviceModel || '';
  }

  get manualInput() {
    return this._value.manualInput;
  }

  get manualInputWithDefault() {
    return this._value.manualInput || false;
  }

  get delayedUpload() {
    return this._value.delayedUpload;
  }

  get delayedUploadWithDefault() {
    return this._value.delayedUpload || false;
  }

  get user_notes() {
    return this._value.user_notes;
  }

  get user_notesWithDefault() {
    return this._value.user_notes || '';
  }

  get imageUrl() {
    return this._value.imageUrl;
  }

  get imageUrlWithDefault() {
    return this._value.imageUrl || '';
  }

  get dataId() {
    return this._value.dataId;
  }

  get dataIdWithDefault() {
    return this._value.dataId || '';
  }

  get msg() {
    return this._value.msg;
  }

  get msgWithDefault() {
    return this._value.msg || '';
  }

  get hadMedicine() {
    return this._value.hadMedicine;
  }

  get hadMedicineWithDefault() {
    return this._value.hadMedicine || false;
  }

  get hadActivity() {
    return this._value.hadActivity;
  }

  get hadActivityWithDefault() {
    return this._value.hadActivity || false;
  }

  get hadInsulin() {
    return this._value.hadInsulin;
  }

  get hadInsulinWithDefault() {
    return this._value.hadInsulin || false;
  }

  get moodTags() {
    return this._value.moodTags;
  }

  get moodTagsWithDefault() {
    return this._value.moodTags || [];
  }

  get measureIndex() {
    return this._value.measureIndex;
  }

  get measureIndexWithDefault() {
    return this._value.measureIndex || 0;
  }

  get triageLevel() {
    return this._value.triageLevel;
  }

  triageLevelIsIn(values: BpTriageLevelEnum[] = []) {
    return this._value.triageLevel && values.includes(this._value.triageLevel);
  }

  get symptom() {
    return this._value.symptom !== undefined && this._value.symptom !== null ? new SymptomClass(this._value.symptom) : this._value.symptom;
  }

  get softDeleted() {
    return this._value.softDeleted;
  }

  get softDeletedWithDefault() {
    return this._value.softDeleted || false;
  }

  get deleteReason() {
    return this._value.deleteReason;
  }

  deleteReasonIsIn(values: MeasurementDeletionReasonEnum[] = []) {
    return this._value.deleteReason && values.includes(this._value.deleteReason);
  }

  get deletedBy() {
    return this._value.deletedBy;
  }

  get deletedByUser() {
    return this._value.deletedByUser !== undefined && this._value.deletedByUser !== null ? new LimitedUserClass(this._value.deletedByUser) : this._value.deletedByUser;
  }

  get softDeletedNe() {
    return this._value.softDeletedNe !== undefined && this._value.softDeletedNe !== null ? new NeFilterClass(this._value.softDeletedNe) : this._value.softDeletedNe;
  }

  get triageInfo() {
    return this._value.triageInfo;
  }

  get triageInfoWithDefault() {
    return this._value.triageInfo || '';
  }

  get refMeasurementId() {
    return this._value.refMeasurementId;
  }

  get systolic_blood_pressure() {
    return this._value.systolic_blood_pressure !== undefined && this._value.systolic_blood_pressure !== null ? new BloodPressureClass(this._value.systolic_blood_pressure) : this._value.systolic_blood_pressure;
  }

  get diastolic_blood_pressure() {
    return this._value.diastolic_blood_pressure !== undefined && this._value.diastolic_blood_pressure !== null ? new BloodPressureClass(this._value.diastolic_blood_pressure) : this._value.diastolic_blood_pressure;
  }

  get heart_rate() {
    return this._value.heart_rate !== undefined && this._value.heart_rate !== null ? new HeartRateClass(this._value.heart_rate) : this._value.heart_rate;
  }

  get body_posture() {
    return this._value.body_posture;
  }

  get body_postureWithDefault() {
    return this._value.body_posture || '';
  }

  get arrhythmia() {
    return this._value.arrhythmia;
  }

  get arrhythmiaWithDefault() {
    return this._value.arrhythmia || false;
  }

  get wavelet() {
    return this._value.wavelet;
  }

  get waveletWithDefault() {
    return this._value.wavelet || [];
  }

  get bpSeverity() {
    return this._value.bpSeverity;
  }

  bpSeverityIsIn(values: BpSeverityEnum[] = []) {
    return this._value.bpSeverity && values.includes(this._value.bpSeverity);
  }

  get blood_glucose() {
    return this._value.blood_glucose !== undefined && this._value.blood_glucose !== null ? new BloodGlucoseClass(this._value.blood_glucose) : this._value.blood_glucose;
  }

  get beforeMeal() {
    return this._value.beforeMeal;
  }

  get beforeMealWithDefault() {
    return this._value.beforeMeal || false;
  }

  get mealTimePassed() {
    return this._value.mealTimePassed;
  }

  get mealTimePassedWithDefault() {
    return this._value.mealTimePassed || 0;
  }

  get mealType() {
    return this._value.mealType;
  }

  mealTypeIsIn(values: BgMealTypeEnum[] = []) {
    return this._value.mealType && values.includes(this._value.mealType);
  }

  get bgSeverity() {
    return this._value.bgSeverity;
  }

  bgSeverityIsIn(values: BgSeverityEnum[] = []) {
    return this._value.bgSeverity && values.includes(this._value.bgSeverity);
  }

  get body_weight() {
    return this._value.body_weight !== undefined && this._value.body_weight !== null ? new WeightClass(this._value.body_weight) : this._value.body_weight;
  }

  get body_weight_change() {
    return this._value.body_weight_change !== undefined && this._value.body_weight_change !== null ? new WeightClass(this._value.body_weight_change) : this._value.body_weight_change;
  }

  get blood_oxygen() {
    return this._value.blood_oxygen !== undefined && this._value.blood_oxygen !== null ? new BloodOxygenClass(this._value.blood_oxygen) : this._value.blood_oxygen;
  }

  get perfusion_index() {
    return this._value.perfusion_index !== undefined && this._value.perfusion_index !== null ? new PerfusionIndexClass(this._value.perfusion_index) : this._value.perfusion_index;
  }

  get poSeverity() {
    return this._value.poSeverity;
  }

  poSeverityIsIn(values: PoSeverityEnum[] = []) {
    return this._value.poSeverity && values.includes(this._value.poSeverity);
  }

  get isCopd() {
    return this._value.isCopd;
  }

  get isCopdWithDefault() {
    return this._value.isCopd || false;
  }

  get body_temperature() {
    return this._value.body_temperature !== undefined && this._value.body_temperature !== null ? new BodyTemperatureClass(this._value.body_temperature) : this._value.body_temperature;
  }

  get steps() {
    return this._value.steps !== undefined && this._value.steps !== null ? new BloodOxygenClass(this._value.steps) : this._value.steps;
  }

  get details() {
    return this._value.details !== undefined && this._value.details !== null ? map(this._value.details, (v) => new MeasurementResultDetailClass(v)) : this._value.details;
  }

  get detailsWithDefault() {
    return this._value.details || [];
  }

  get severity() {
    return this._value.severity;
  }

  severityIsIn(values: AlertSeverityEnum[] = []) {
    return this._value.severity && values.includes(this._value.severity);
  }

  get careStatus() {
    return this._value.careStatus;
  }

  careStatusIsIn(values: CareStatusEnum[] = []) {
    return this._value.careStatus && values.includes(this._value.careStatus);
  }

  get shouldAlert() {
    return this._value.shouldAlert;
  }

  get shouldAlertWithDefault() {
    return this._value.shouldAlert || false;
  }

  get dateRange() {
    return this._value.dateRange !== undefined && this._value.dateRange !== null ? new DateRangeFilterClass(this._value.dateRange) : this._value.dateRange;
  }

  get typeIn() {
    return this._value.typeIn !== undefined && this._value.typeIn !== null ? new InFilterClass(this._value.typeIn) : this._value.typeIn;
  }

  get severityIn() {
    return this._value.severityIn !== undefined && this._value.severityIn !== null ? new InFilterClass(this._value.severityIn) : this._value.severityIn;
  }

  get careStatusIn() {
    return this._value.careStatusIn !== undefined && this._value.careStatusIn !== null ? new InFilterClass(this._value.careStatusIn) : this._value.careStatusIn;
  }

  get memberIdIn() {
    return this._value.memberIdIn !== undefined && this._value.memberIdIn !== null ? new InFilterClass(this._value.memberIdIn) : this._value.memberIdIn;
  }

  get resultSource() {
    return this._value.resultSource;
  }

  resultSourceIsIn(values: ResultSourceEnum[] = []) {
    return this._value.resultSource && values.includes(this._value.resultSource);
  }

  get isBaseline() {
    return this._value.isBaseline;
  }

  get isBaselineWithDefault() {
    return this._value.isBaseline || false;
  }

  get source() {
    return this._value.source;
  }

  get sourceWithDefault() {
    return this._value.source || '';
  }

  get resultId() {
    return this._value.resultId;
  }

  get weightFixTag() {
    return this._value.weightFixTag;
  }

  get weightFixTagWithDefault() {
    return this._value.weightFixTag || '';
  }

  get tag() {
    return this._value.tag;
  }

  get tagWithDefault() {
    return this._value.tag || '';
  }

  get resultSourceIn() {
    return this._value.resultSourceIn !== undefined && this._value.resultSourceIn !== null ? new InFilterClass(this._value.resultSourceIn) : this._value.resultSourceIn;
  }
}
export interface DemoPatientRequest {
	patientNumber?: Nullable<number>;
	orgNumbers?: Nullable<string[]>;
}
export interface ControlLevelItem {
	serialVersionUID?: Nullable<number>;
	controlLevel?: Nullable<ControlLevelEnum>;
	updatedAt?: Nullable<string>;
}
export enum BpBaselineSource {
	EHR = 'EHR',
	ON_SITE = 'ON_SITE',
	AVG = 'AVG',
}
export interface BpBaseline {
	serialVersionUID?: Nullable<number>;
	sbp?: Nullable<number>;
	dbp?: Nullable<number>;
	source?: Nullable<BpBaselineSource>;
	refIds?: Nullable<string[]>;
	refStart?: Nullable<string>;
	refEnd?: Nullable<string>;
	updatedAt?: Nullable<string>;
}
export enum BgBaselineSource {
	TRANSCRIBED_A1C_BEFORE_ENROLLMENT = 'TRANSCRIBED_A1C_BEFORE_ENROLLMENT',
	A1C_AFTER_ENROLLMENT = 'A1C_AFTER_ENROLLMENT',
}
export interface BgBaseline {
	serialVersionUID?: Nullable<number>;
	a1c?: Nullable<number>;
	a1cDate?: Nullable<string>;
	updatedAt?: Nullable<string>;
	source?: Nullable<BgBaselineSource>;
}
export interface BpStatData {
	serialVersionUID?: Nullable<number>;
	sbp?: Nullable<number>;
	dbp?: Nullable<number>;
	count?: Nullable<number>;
	periodStart?: Nullable<string>;
	periodEnd?: Nullable<string>;
	startDay?: Nullable<number>;
	endDay?: Nullable<number>;
	updatedAt?: Nullable<string>;
}
export interface BgStatData {
	serialVersionUID?: Nullable<number>;
	latestA1c?: Nullable<number>;
	a1cDate?: Nullable<string>;
	updatedAt?: Nullable<string>;
}
export interface BpGoal {
	serialVersionUID?: Nullable<number>;
	sbp?: Nullable<number>;
	dbp?: Nullable<number>;
	updatedBy?: Nullable<string>;
	note?: Nullable<string>;
	updatedAt?: Nullable<string>;
}
export interface BgGoal {
	serialVersionUID?: Nullable<number>;
	a1c?: Nullable<number>;
	note?: Nullable<string>;
	updatedBy?: Nullable<string>;
	updatedAt?: Nullable<string>;
}
export interface PatientControlLevel extends BaseModel {
	enrollmentDate?: Nullable<string>;
	firstEnrollmentDate?: Nullable<string>;
	hasBpCategory?: Nullable<boolean>;
	hasBpVital?: Nullable<boolean>;
	hasBp?: Nullable<boolean>;
	hasBgCategory?: Nullable<boolean>;
	hasBgVital?: Nullable<boolean>;
	hasBg?: Nullable<boolean>;
	protocol?: Nullable<PatientProtocolEnum>;
	patientCategories?: Nullable<PatientCategoryEnum[]>;
	controlLevel?: Nullable<ControlLevelItem>;
	bpControlLevel?: Nullable<ControlLevelItem>;
	bgControlLevel?: Nullable<ControlLevelItem>;
	bpBaseline?: Nullable<BpBaseline>;
	bgBaseline?: Nullable<BgBaseline>;
	hsBaseline?: Nullable<Weight>;
	firstBpMeasurementDate?: Nullable<string>;
	bpStatData?: Nullable<BpStatData>;
	bgStatData?: Nullable<BgStatData>;
	bpGoal?: Nullable<BpGoal>;
	bgGoal?: Nullable<BgGoal>;
	note?: Nullable<string>;
	version?: Nullable<number>;
}
export class ControlLevelItemClass {
  protected readonly _value: ControlLevelItem;

	constructor(value: ControlLevelItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get serialVersionUID() {
    return this._value.serialVersionUID;
  }

  get serialVersionUIDWithDefault() {
    return this._value.serialVersionUID || 0;
  }

  get controlLevel() {
    return this._value.controlLevel;
  }

  controlLevelIsIn(values: ControlLevelEnum[] = []) {
    return this._value.controlLevel && values.includes(this._value.controlLevel);
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }
}
export class BpBaselineClass {
  protected readonly _value: BpBaseline;

	constructor(value: BpBaseline) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get serialVersionUID() {
    return this._value.serialVersionUID;
  }

  get serialVersionUIDWithDefault() {
    return this._value.serialVersionUID || 0;
  }

  get sbp() {
    return this._value.sbp;
  }

  get sbpWithDefault() {
    return this._value.sbp || 0.0;
  }

  get dbp() {
    return this._value.dbp;
  }

  get dbpWithDefault() {
    return this._value.dbp || 0.0;
  }

  get source() {
    return this._value.source;
  }

  sourceIsIn(values: BpBaselineSource[] = []) {
    return this._value.source && values.includes(this._value.source);
  }

  get refIds() {
    return this._value.refIds;
  }

  get refIdsWithDefault() {
    return this._value.refIds || [];
  }

  get refStart() {
    return (this._value.refStart !== undefined && this._value.refStart !== null) ? dayjs(this._value.refStart) : this._value.refStart;
  }

  get refEnd() {
    return (this._value.refEnd !== undefined && this._value.refEnd !== null) ? dayjs(this._value.refEnd) : this._value.refEnd;
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }
}
export class BgBaselineClass {
  protected readonly _value: BgBaseline;

	constructor(value: BgBaseline) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get serialVersionUID() {
    return this._value.serialVersionUID;
  }

  get serialVersionUIDWithDefault() {
    return this._value.serialVersionUID || 0;
  }

  get a1c() {
    return this._value.a1c;
  }

  get a1cWithDefault() {
    return this._value.a1c || 0.0;
  }

  get a1cDate() {
    return (this._value.a1cDate !== undefined && this._value.a1cDate !== null) ? dayjs(this._value.a1cDate) : this._value.a1cDate;
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }

  get source() {
    return this._value.source;
  }

  sourceIsIn(values: BgBaselineSource[] = []) {
    return this._value.source && values.includes(this._value.source);
  }
}
export class BpStatDataClass {
  protected readonly _value: BpStatData;

	constructor(value: BpStatData) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get serialVersionUID() {
    return this._value.serialVersionUID;
  }

  get serialVersionUIDWithDefault() {
    return this._value.serialVersionUID || 0;
  }

  get sbp() {
    return this._value.sbp;
  }

  get sbpWithDefault() {
    return this._value.sbp || 0.0;
  }

  get dbp() {
    return this._value.dbp;
  }

  get dbpWithDefault() {
    return this._value.dbp || 0.0;
  }

  get count() {
    return this._value.count;
  }

  get countWithDefault() {
    return this._value.count || 0;
  }

  get periodStart() {
    return (this._value.periodStart !== undefined && this._value.periodStart !== null) ? dayjs(this._value.periodStart) : this._value.periodStart;
  }

  get periodEnd() {
    return (this._value.periodEnd !== undefined && this._value.periodEnd !== null) ? dayjs(this._value.periodEnd) : this._value.periodEnd;
  }

  get startDay() {
    return this._value.startDay;
  }

  get startDayWithDefault() {
    return this._value.startDay || 0;
  }

  get endDay() {
    return this._value.endDay;
  }

  get endDayWithDefault() {
    return this._value.endDay || 0;
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }
}
export class BgStatDataClass {
  protected readonly _value: BgStatData;

	constructor(value: BgStatData) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get serialVersionUID() {
    return this._value.serialVersionUID;
  }

  get serialVersionUIDWithDefault() {
    return this._value.serialVersionUID || 0;
  }

  get latestA1c() {
    return this._value.latestA1c;
  }

  get latestA1cWithDefault() {
    return this._value.latestA1c || 0.0;
  }

  get a1cDate() {
    return (this._value.a1cDate !== undefined && this._value.a1cDate !== null) ? dayjs(this._value.a1cDate) : this._value.a1cDate;
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }
}
export class BpGoalClass {
  protected readonly _value: BpGoal;

	constructor(value: BpGoal) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get serialVersionUID() {
    return this._value.serialVersionUID;
  }

  get serialVersionUIDWithDefault() {
    return this._value.serialVersionUID || 0;
  }

  get sbp() {
    return this._value.sbp;
  }

  get sbpWithDefault() {
    return this._value.sbp || 0;
  }

  get dbp() {
    return this._value.dbp;
  }

  get dbpWithDefault() {
    return this._value.dbp || 0;
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }
}
export class BgGoalClass {
  protected readonly _value: BgGoal;

	constructor(value: BgGoal) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get serialVersionUID() {
    return this._value.serialVersionUID;
  }

  get serialVersionUIDWithDefault() {
    return this._value.serialVersionUID || 0;
  }

  get a1c() {
    return this._value.a1c;
  }

  get a1cWithDefault() {
    return this._value.a1c || 0.0;
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }
}
export class PatientControlLevelClass extends BaseModelClass {
  protected readonly _value: PatientControlLevel;

	constructor(value: PatientControlLevel) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get enrollmentDate() {
    return (this._value.enrollmentDate !== undefined && this._value.enrollmentDate !== null) ? dayjs(this._value.enrollmentDate) : this._value.enrollmentDate;
  }

  get firstEnrollmentDate() {
    return (this._value.firstEnrollmentDate !== undefined && this._value.firstEnrollmentDate !== null) ? dayjs(this._value.firstEnrollmentDate) : this._value.firstEnrollmentDate;
  }

  get hasBpCategory() {
    return this._value.hasBpCategory;
  }

  get hasBpCategoryWithDefault() {
    return this._value.hasBpCategory || false;
  }

  get hasBpVital() {
    return this._value.hasBpVital;
  }

  get hasBpVitalWithDefault() {
    return this._value.hasBpVital || false;
  }

  get hasBp() {
    return this._value.hasBp;
  }

  get hasBpWithDefault() {
    return this._value.hasBp || false;
  }

  get hasBgCategory() {
    return this._value.hasBgCategory;
  }

  get hasBgCategoryWithDefault() {
    return this._value.hasBgCategory || false;
  }

  get hasBgVital() {
    return this._value.hasBgVital;
  }

  get hasBgVitalWithDefault() {
    return this._value.hasBgVital || false;
  }

  get hasBg() {
    return this._value.hasBg;
  }

  get hasBgWithDefault() {
    return this._value.hasBg || false;
  }

  get protocol() {
    return this._value.protocol;
  }

  protocolIsIn(values: PatientProtocolEnum[] = []) {
    return this._value.protocol && values.includes(this._value.protocol);
  }

  get patientCategories() {
    return this._value.patientCategories;
  }

  get patientCategoriesWithDefault() {
    return this._value.patientCategories || [];
  }

  get controlLevel() {
    return this._value.controlLevel !== undefined && this._value.controlLevel !== null ? new ControlLevelItemClass(this._value.controlLevel) : this._value.controlLevel;
  }

  get bpControlLevel() {
    return this._value.bpControlLevel !== undefined && this._value.bpControlLevel !== null ? new ControlLevelItemClass(this._value.bpControlLevel) : this._value.bpControlLevel;
  }

  get bgControlLevel() {
    return this._value.bgControlLevel !== undefined && this._value.bgControlLevel !== null ? new ControlLevelItemClass(this._value.bgControlLevel) : this._value.bgControlLevel;
  }

  get bpBaseline() {
    return this._value.bpBaseline !== undefined && this._value.bpBaseline !== null ? new BpBaselineClass(this._value.bpBaseline) : this._value.bpBaseline;
  }

  get bgBaseline() {
    return this._value.bgBaseline !== undefined && this._value.bgBaseline !== null ? new BgBaselineClass(this._value.bgBaseline) : this._value.bgBaseline;
  }

  get hsBaseline() {
    return this._value.hsBaseline !== undefined && this._value.hsBaseline !== null ? new WeightClass(this._value.hsBaseline) : this._value.hsBaseline;
  }

  get firstBpMeasurementDate() {
    return (this._value.firstBpMeasurementDate !== undefined && this._value.firstBpMeasurementDate !== null) ? dayjs(this._value.firstBpMeasurementDate) : this._value.firstBpMeasurementDate;
  }

  get bpStatData() {
    return this._value.bpStatData !== undefined && this._value.bpStatData !== null ? new BpStatDataClass(this._value.bpStatData) : this._value.bpStatData;
  }

  get bgStatData() {
    return this._value.bgStatData !== undefined && this._value.bgStatData !== null ? new BgStatDataClass(this._value.bgStatData) : this._value.bgStatData;
  }

  get bpGoal() {
    return this._value.bpGoal !== undefined && this._value.bpGoal !== null ? new BpGoalClass(this._value.bpGoal) : this._value.bpGoal;
  }

  get bgGoal() {
    return this._value.bgGoal !== undefined && this._value.bgGoal !== null ? new BgGoalClass(this._value.bgGoal) : this._value.bgGoal;
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get version() {
    return this._value.version;
  }

  get versionWithDefault() {
    return this._value.version || 0;
  }
}
export interface CgmPercentGoal {
	metric?: Nullable<CgmMetricEnum>;
	thresholdRange?: Nullable<CgmThresholdRange>;
	goal?: Nullable<number>;
}
export interface CgmGoal extends PatientBase {
	percentGoals?: Nullable<CgmPercentGoal[]>;
	visitId?: Nullable<string>;
	goalStatus?: Nullable<GoalStatusEnum>;
}
export class CgmPercentGoalClass {
  protected readonly _value: CgmPercentGoal;

	constructor(value: CgmPercentGoal) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get metric() {
    return this._value.metric;
  }

  metricIsIn(values: CgmMetricEnum[] = []) {
    return this._value.metric && values.includes(this._value.metric);
  }

  get thresholdRange() {
    return this._value.thresholdRange !== undefined && this._value.thresholdRange !== null ? new CgmThresholdRangeClass(this._value.thresholdRange) : this._value.thresholdRange;
  }

  get goal() {
    return this._value.goal;
  }

  get goalWithDefault() {
    return this._value.goal || 0.0;
  }
}
export class CgmGoalClass extends PatientBaseClass {
  protected readonly _value: CgmGoal;

	constructor(value: CgmGoal) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get percentGoals() {
    return this._value.percentGoals !== undefined && this._value.percentGoals !== null ? map(this._value.percentGoals, (v) => new CgmPercentGoalClass(v)) : this._value.percentGoals;
  }

  get percentGoalsWithDefault() {
    return this._value.percentGoals || [];
  }

  get visitId() {
    return this._value.visitId;
  }

  get goalStatus() {
    return this._value.goalStatus;
  }

  goalStatusIsIn(values: GoalStatusEnum[] = []) {
    return this._value.goalStatus && values.includes(this._value.goalStatus);
  }
}
export interface InternalInsertParams {
	medicalAlert: MedicalAlert;
}

export interface InternalSearchParams {
	searchRequest: SearchRequest<PatientConsent>;
}

export interface InternalClearCacheParams {
	name?: string;
}

export interface InternalAuthByProviderIdParams {
	providerId: string;
}

export interface InternalSetDemoInterventionParams {
	memberIds: string[];
}

export interface InternalRefreshComplianceAlertParams {
	patientIds: string[];
}

export interface InternalRefreshOutstandingItemParams {
}

export interface InternalTriggerMonthlyReviewParams {
	patientIds: string[];
}

export interface InternalUpdateMonthlyReviewDueDateParams {
}

export interface InternalIsPatientMigratedParams {
	id: string;
}

export interface InternalIsClinicMigratedParams {
	id: string;
}

export interface InternalCreateBPM1FromSCParams {
	measurement: Measurement;
}

export interface InternalCreateDemoPatientParams {
	request: DemoPatientRequest;
}

export interface InternalEnrollDemoPatientParams {
	memberIds: string[];
}

export interface InternalInitAllPatientClassificationParams {
}

export interface InternalInitSinglePatientClassificationParams {
	patientId: string;
}

export interface InternalInitPatientControlLevelForAllParams {
}

export interface InternalInitPatientControlLevelParams {
	patientId: string;
}

export interface InternalInitIcdCodeConfigsParams {
}

export interface InternalFillUpMissingGCalVisitsParams {
}

export interface InternalSendConsentToAllPatientsWithinClinicParams {
	medicalOrgIds: string[];
}

export interface InternalFillUpDiagnosisByIcdCodeParams {
}

export interface InternalUpdateCgmGoalParams {
	memberId: string;
	goal: CgmGoal;
}

export class InternalService {
  protected baseURL = '';

  protected basePath = '/v1/uc/internal';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public insert({ baseUrl, params, headers }: RequestOption<InternalInsertParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<MedicalAlert>>(`${baseUrl || this.baseURL}${this.basePath}/medical-alerts${getQueryStrings(queryList)}`, params.medicalAlert, { headers });
	}

	public search({ baseUrl, params, headers }: RequestOption<InternalSearchParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<PatientConsent>>>(`${baseUrl || this.baseURL}${this.basePath}/search-patient-consent${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public clearCache({ baseUrl, params, headers }: RequestOption<InternalClearCacheParams>) {
		const queryList: QueryParam[] = [];
		if (params.name !== undefined && params.name !== null) {
			queryList.push({ name: 'name', value: params.name.toString() });
		}
		return axios.get<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/clear-cache${getQueryStrings(queryList)}`, { headers });
	}

	public authByProviderId({ baseUrl, params, headers }: RequestOption<InternalAuthByProviderIdParams>) {
		const queryList: QueryParam[] = [];
		if (params.providerId !== undefined && params.providerId !== null) {
			queryList.push({ name: 'providerId', value: params.providerId.toString() });
		}
		return axios.get<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/auth-by-providerId${getQueryStrings(queryList)}`, { headers });
	}

	public setDemoIntervention({ baseUrl, params, headers }: RequestOption<InternalSetDemoInterventionParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/demo-intervention${getQueryStrings(queryList)}`, params.memberIds, { headers });
	}

	public refreshComplianceAlert({ baseUrl, params, headers }: RequestOption<InternalRefreshComplianceAlertParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/compliance-alerts/trigger${getQueryStrings(queryList)}`, params.patientIds, { headers });
	}

	public refreshOutstandingItem({ baseUrl, params, headers }: RequestOption<InternalRefreshOutstandingItemParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/outstanding-item/trigger${getQueryStrings(queryList)}`, {}, { headers });
	}

	public triggerMonthlyReview({ baseUrl, params, headers }: RequestOption<InternalTriggerMonthlyReviewParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/monthly-review/trigger${getQueryStrings(queryList)}`, params.patientIds, { headers });
	}

	public updateMonthlyReviewDueDate({ baseUrl, params, headers }: RequestOption<InternalUpdateMonthlyReviewDueDateParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/monthly-review-due-date${getQueryStrings(queryList)}`, {}, { headers });
	}

	public isPatientMigrated({ baseUrl, params, headers }: RequestOption<InternalIsPatientMigratedParams>) {
		const queryList: QueryParam[] = [];
		if (params.id !== undefined && params.id !== null) {
			queryList.push({ name: 'id', value: params.id.toString() });
		}
		return axios.get<APIResponse<boolean>>(`${baseUrl || this.baseURL}${this.basePath}/migration/patient${getQueryStrings(queryList)}`, { headers });
	}

	public isClinicMigrated({ baseUrl, params, headers }: RequestOption<InternalIsClinicMigratedParams>) {
		const queryList: QueryParam[] = [];
		if (params.id !== undefined && params.id !== null) {
			queryList.push({ name: 'id', value: params.id.toString() });
		}
		return axios.get<APIResponse<boolean>>(`${baseUrl || this.baseURL}${this.basePath}/migration/clinic${getQueryStrings(queryList)}`, { headers });
	}

	public createBPM1FromSC({ baseUrl, params, headers }: RequestOption<InternalCreateBPM1FromSCParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Measurement>>(`${baseUrl || this.baseURL}${this.basePath}/measurement/bpm1${getQueryStrings(queryList)}`, params.measurement, { headers });
	}

	public createDemoPatient({ baseUrl, params, headers }: RequestOption<InternalCreateDemoPatientParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/create-demo-patient${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public enrollDemoPatient({ baseUrl, params, headers }: RequestOption<InternalEnrollDemoPatientParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/enroll-demo-patient${getQueryStrings(queryList)}`, params.memberIds, { headers });
	}

	public initAllPatientClassification({ baseUrl, params, headers }: RequestOption<InternalInitAllPatientClassificationParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Void>>(`${baseUrl || this.baseURL}${this.basePath}/patient-classification-init/all${getQueryStrings(queryList)}`, {}, { headers });
	}

	public initSinglePatientClassification({ baseUrl, params, headers }: RequestOption<InternalInitSinglePatientClassificationParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Void>>(`${baseUrl || this.baseURL}${this.basePath}/patient-classification-init/${params.patientId}${getQueryStrings(queryList)}`, {}, { headers });
	}

	public initPatientControlLevelForAll({ baseUrl, params, headers }: RequestOption<InternalInitPatientControlLevelForAllParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Void>>(`${baseUrl || this.baseURL}${this.basePath}/patient-control-level-init/all${getQueryStrings(queryList)}`, {}, { headers });
	}

	public initPatientControlLevel({ baseUrl, params, headers }: RequestOption<InternalInitPatientControlLevelParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PatientControlLevel>>(`${baseUrl || this.baseURL}${this.basePath}/patient-control-level-init/${params.patientId}${getQueryStrings(queryList)}`, {}, { headers });
	}

	public initIcdCodeConfigs({ baseUrl, params, headers }: RequestOption<InternalInitIcdCodeConfigsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Void>>(`${baseUrl || this.baseURL}${this.basePath}/icd-code-configs/init${getQueryStrings(queryList)}`, {}, { headers });
	}

	public fillUpMissingGCalVisits({ baseUrl, params, headers }: RequestOption<InternalFillUpMissingGCalVisitsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/fill-up-missing-gcal-visits${getQueryStrings(queryList)}`, {}, { headers });
	}

	public sendConsentToAllPatientsWithinClinic({ baseUrl, params, headers }: RequestOption<InternalSendConsentToAllPatientsWithinClinicParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/batch-consent${getQueryStrings(queryList)}`, params.medicalOrgIds, { headers });
	}

	public fillUpDiagnosisByIcdCode({ baseUrl, params, headers }: RequestOption<InternalFillUpDiagnosisByIcdCodeParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Void>>(`${baseUrl || this.baseURL}${this.basePath}/fill-up-diagnosis-by-icd-code${getQueryStrings(queryList)}`, {}, { headers });
	}

	public updateCgmGoal({ baseUrl, params, headers }: RequestOption<InternalUpdateCgmGoalParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<CgmGoal>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/default-cgm-goal${getQueryStrings(queryList)}`, params.goal, { headers });
	}
}

export const internalService = new InternalService();

export const createInternalInsertObject = (value: APIResponse<MedicalAlert>) => new APIResponseClass<MedicalAlertClass>(value, (value: MedicalAlert) => new MedicalAlertClass(value));

export const useInternalInsert = <RCN = undefined>(configs: MakeRequestHookConfig<InternalInsertParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalInsertParams,
    APIResponse<MedicalAlert>,
    typeof internalService.insert,
    AjaxOptions,
    APIResponseClass<MedicalAlertClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.insert>) => (
      internalService.insert(...args)
    ),
    ResponseClassCreator: createInternalInsertObject,
    typeName: 'useInternalInsert',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalInsert.mock = () => new Error('Mock has not been implemented!');
useInternalInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalInsert.createObject = createInternalInsertObject;

export const createInternalSearchObject = (value: APIResponse<PageResponse<PatientConsent>>) => new APIResponseClass<PageResponseClass<PatientConsentClass>>(value, (value: PageResponse<PatientConsent>) => new PageResponseClass<PatientConsentClass>(value, (value: PatientConsent) => new PatientConsentClass(value)));

export const useInternalSearch = <RCN = undefined>(configs: MakeRequestHookConfig<InternalSearchParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalSearchParams,
    APIResponse<PageResponse<PatientConsent>>,
    typeof internalService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientConsentClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.search>) => (
      internalService.search(...args)
    ),
    ResponseClassCreator: createInternalSearchObject,
    typeName: 'useInternalSearch',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalSearch.mock = () => new Error('Mock has not been implemented!');
useInternalSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalSearch.createObject = createInternalSearchObject;

export const createInternalClearCacheObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useInternalClearCache = <RCN = undefined>(configs: MakeRequestHookConfig<InternalClearCacheParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalClearCacheParams,
    APIResponse<string>,
    typeof internalService.clearCache,
    GetOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof internalService.clearCache>) => (
      internalService.clearCache(...args)
    ),
    ResponseClassCreator: createInternalClearCacheObject,
    typeName: 'useInternalClearCache',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalClearCache.mock = () => new Error('Mock has not been implemented!');
useInternalClearCache.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalClearCache.createObject = createInternalClearCacheObject;

export const createInternalAuthByProviderIdObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useInternalAuthByProviderId = <RCN = undefined>(configs: MakeRequestHookConfig<InternalAuthByProviderIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalAuthByProviderIdParams,
    APIResponse<string>,
    typeof internalService.authByProviderId,
    GetOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof internalService.authByProviderId>) => (
      internalService.authByProviderId(...args)
    ),
    ResponseClassCreator: createInternalAuthByProviderIdObject,
    typeName: 'useInternalAuthByProviderId',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalAuthByProviderId.mock = () => new Error('Mock has not been implemented!');
useInternalAuthByProviderId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalAuthByProviderId.createObject = createInternalAuthByProviderIdObject;

export const createInternalSetDemoInterventionObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useInternalSetDemoIntervention = <RCN = undefined>(configs: MakeRequestHookConfig<InternalSetDemoInterventionParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalSetDemoInterventionParams,
    APIResponse<string>,
    typeof internalService.setDemoIntervention,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.setDemoIntervention>) => (
      internalService.setDemoIntervention(...args)
    ),
    ResponseClassCreator: createInternalSetDemoInterventionObject,
    typeName: 'useInternalSetDemoIntervention',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalSetDemoIntervention.mock = () => new Error('Mock has not been implemented!');
useInternalSetDemoIntervention.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalSetDemoIntervention.createObject = createInternalSetDemoInterventionObject;

export const createInternalRefreshComplianceAlertObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useInternalRefreshComplianceAlert = <RCN = undefined>(configs: MakeRequestHookConfig<InternalRefreshComplianceAlertParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalRefreshComplianceAlertParams,
    APIResponse<string>,
    typeof internalService.refreshComplianceAlert,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.refreshComplianceAlert>) => (
      internalService.refreshComplianceAlert(...args)
    ),
    ResponseClassCreator: createInternalRefreshComplianceAlertObject,
    typeName: 'useInternalRefreshComplianceAlert',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalRefreshComplianceAlert.mock = () => new Error('Mock has not been implemented!');
useInternalRefreshComplianceAlert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalRefreshComplianceAlert.createObject = createInternalRefreshComplianceAlertObject;

export const createInternalRefreshOutstandingItemObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useInternalRefreshOutstandingItem = <RCN = undefined>(configs: MakeRequestHookConfig<InternalRefreshOutstandingItemParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalRefreshOutstandingItemParams,
    APIResponse<string>,
    typeof internalService.refreshOutstandingItem,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.refreshOutstandingItem>) => (
      internalService.refreshOutstandingItem(...args)
    ),
    ResponseClassCreator: createInternalRefreshOutstandingItemObject,
    typeName: 'useInternalRefreshOutstandingItem',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalRefreshOutstandingItem.mock = () => new Error('Mock has not been implemented!');
useInternalRefreshOutstandingItem.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalRefreshOutstandingItem.createObject = createInternalRefreshOutstandingItemObject;

export const createInternalTriggerMonthlyReviewObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useInternalTriggerMonthlyReview = <RCN = undefined>(configs: MakeRequestHookConfig<InternalTriggerMonthlyReviewParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalTriggerMonthlyReviewParams,
    APIResponse<string>,
    typeof internalService.triggerMonthlyReview,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.triggerMonthlyReview>) => (
      internalService.triggerMonthlyReview(...args)
    ),
    ResponseClassCreator: createInternalTriggerMonthlyReviewObject,
    typeName: 'useInternalTriggerMonthlyReview',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalTriggerMonthlyReview.mock = () => new Error('Mock has not been implemented!');
useInternalTriggerMonthlyReview.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalTriggerMonthlyReview.createObject = createInternalTriggerMonthlyReviewObject;

export const createInternalUpdateMonthlyReviewDueDateObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useInternalUpdateMonthlyReviewDueDate = <RCN = undefined>(configs: MakeRequestHookConfig<InternalUpdateMonthlyReviewDueDateParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalUpdateMonthlyReviewDueDateParams,
    APIResponse<string>,
    typeof internalService.updateMonthlyReviewDueDate,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.updateMonthlyReviewDueDate>) => (
      internalService.updateMonthlyReviewDueDate(...args)
    ),
    ResponseClassCreator: createInternalUpdateMonthlyReviewDueDateObject,
    typeName: 'useInternalUpdateMonthlyReviewDueDate',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalUpdateMonthlyReviewDueDate.mock = () => new Error('Mock has not been implemented!');
useInternalUpdateMonthlyReviewDueDate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalUpdateMonthlyReviewDueDate.createObject = createInternalUpdateMonthlyReviewDueDateObject;

export const createInternalIsPatientMigratedObject = (value: APIResponse<boolean>) => new APIResponseClass<boolean>(value, (v) => (v));

export const useInternalIsPatientMigrated = <RCN = undefined>(configs: MakeRequestHookConfig<InternalIsPatientMigratedParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalIsPatientMigratedParams,
    APIResponse<boolean>,
    typeof internalService.isPatientMigrated,
    GetOptions,
    APIResponseClass<boolean>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof internalService.isPatientMigrated>) => (
      internalService.isPatientMigrated(...args)
    ),
    ResponseClassCreator: createInternalIsPatientMigratedObject,
    typeName: 'useInternalIsPatientMigrated',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalIsPatientMigrated.mock = () => new Error('Mock has not been implemented!');
useInternalIsPatientMigrated.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalIsPatientMigrated.createObject = createInternalIsPatientMigratedObject;

export const createInternalIsClinicMigratedObject = (value: APIResponse<boolean>) => new APIResponseClass<boolean>(value, (v) => (v));

export const useInternalIsClinicMigrated = <RCN = undefined>(configs: MakeRequestHookConfig<InternalIsClinicMigratedParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalIsClinicMigratedParams,
    APIResponse<boolean>,
    typeof internalService.isClinicMigrated,
    GetOptions,
    APIResponseClass<boolean>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof internalService.isClinicMigrated>) => (
      internalService.isClinicMigrated(...args)
    ),
    ResponseClassCreator: createInternalIsClinicMigratedObject,
    typeName: 'useInternalIsClinicMigrated',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalIsClinicMigrated.mock = () => new Error('Mock has not been implemented!');
useInternalIsClinicMigrated.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalIsClinicMigrated.createObject = createInternalIsClinicMigratedObject;

export const createInternalCreateBPM1FromSCObject = (value: APIResponse<Measurement>) => new APIResponseClass<MeasurementClass>(value, (value: Measurement) => new MeasurementClass(value));

export const useInternalCreateBPM1FromSC = <RCN = undefined>(configs: MakeRequestHookConfig<InternalCreateBPM1FromSCParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalCreateBPM1FromSCParams,
    APIResponse<Measurement>,
    typeof internalService.createBPM1FromSC,
    AjaxOptions,
    APIResponseClass<MeasurementClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.createBPM1FromSC>) => (
      internalService.createBPM1FromSC(...args)
    ),
    ResponseClassCreator: createInternalCreateBPM1FromSCObject,
    typeName: 'useInternalCreateBPM1FromSC',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalCreateBPM1FromSC.mock = () => new Error('Mock has not been implemented!');
useInternalCreateBPM1FromSC.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalCreateBPM1FromSC.createObject = createInternalCreateBPM1FromSCObject;

export const createInternalCreateDemoPatientObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useInternalCreateDemoPatient = <RCN = undefined>(configs: MakeRequestHookConfig<InternalCreateDemoPatientParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalCreateDemoPatientParams,
    APIResponse<string>,
    typeof internalService.createDemoPatient,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.createDemoPatient>) => (
      internalService.createDemoPatient(...args)
    ),
    ResponseClassCreator: createInternalCreateDemoPatientObject,
    typeName: 'useInternalCreateDemoPatient',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalCreateDemoPatient.mock = () => new Error('Mock has not been implemented!');
useInternalCreateDemoPatient.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalCreateDemoPatient.createObject = createInternalCreateDemoPatientObject;

export const createInternalEnrollDemoPatientObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useInternalEnrollDemoPatient = <RCN = undefined>(configs: MakeRequestHookConfig<InternalEnrollDemoPatientParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalEnrollDemoPatientParams,
    APIResponse<string>,
    typeof internalService.enrollDemoPatient,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.enrollDemoPatient>) => (
      internalService.enrollDemoPatient(...args)
    ),
    ResponseClassCreator: createInternalEnrollDemoPatientObject,
    typeName: 'useInternalEnrollDemoPatient',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalEnrollDemoPatient.mock = () => new Error('Mock has not been implemented!');
useInternalEnrollDemoPatient.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalEnrollDemoPatient.createObject = createInternalEnrollDemoPatientObject;

export const createInternalInitAllPatientClassificationObject = (value: APIResponse<Void>) => new APIResponseClass<VoidClass>(value, (value: Void) => new VoidClass(value));

export const useInternalInitAllPatientClassification = <RCN = undefined>(configs: MakeRequestHookConfig<InternalInitAllPatientClassificationParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalInitAllPatientClassificationParams,
    APIResponse<Void>,
    typeof internalService.initAllPatientClassification,
    AjaxOptions,
    APIResponseClass<VoidClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.initAllPatientClassification>) => (
      internalService.initAllPatientClassification(...args)
    ),
    ResponseClassCreator: createInternalInitAllPatientClassificationObject,
    typeName: 'useInternalInitAllPatientClassification',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalInitAllPatientClassification.mock = () => new Error('Mock has not been implemented!');
useInternalInitAllPatientClassification.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalInitAllPatientClassification.createObject = createInternalInitAllPatientClassificationObject;

export const createInternalInitSinglePatientClassificationObject = (value: APIResponse<Void>) => new APIResponseClass<VoidClass>(value, (value: Void) => new VoidClass(value));

export const useInternalInitSinglePatientClassification = <RCN = undefined>(configs: MakeRequestHookConfig<InternalInitSinglePatientClassificationParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalInitSinglePatientClassificationParams,
    APIResponse<Void>,
    typeof internalService.initSinglePatientClassification,
    AjaxOptions,
    APIResponseClass<VoidClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.initSinglePatientClassification>) => (
      internalService.initSinglePatientClassification(...args)
    ),
    ResponseClassCreator: createInternalInitSinglePatientClassificationObject,
    typeName: 'useInternalInitSinglePatientClassification',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalInitSinglePatientClassification.mock = () => new Error('Mock has not been implemented!');
useInternalInitSinglePatientClassification.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalInitSinglePatientClassification.createObject = createInternalInitSinglePatientClassificationObject;

export const createInternalInitPatientControlLevelForAllObject = (value: APIResponse<Void>) => new APIResponseClass<VoidClass>(value, (value: Void) => new VoidClass(value));

export const useInternalInitPatientControlLevelForAll = <RCN = undefined>(configs: MakeRequestHookConfig<InternalInitPatientControlLevelForAllParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalInitPatientControlLevelForAllParams,
    APIResponse<Void>,
    typeof internalService.initPatientControlLevelForAll,
    AjaxOptions,
    APIResponseClass<VoidClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.initPatientControlLevelForAll>) => (
      internalService.initPatientControlLevelForAll(...args)
    ),
    ResponseClassCreator: createInternalInitPatientControlLevelForAllObject,
    typeName: 'useInternalInitPatientControlLevelForAll',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalInitPatientControlLevelForAll.mock = () => new Error('Mock has not been implemented!');
useInternalInitPatientControlLevelForAll.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalInitPatientControlLevelForAll.createObject = createInternalInitPatientControlLevelForAllObject;

export const createInternalInitPatientControlLevelObject = (value: APIResponse<PatientControlLevel>) => new APIResponseClass<PatientControlLevelClass>(value, (value: PatientControlLevel) => new PatientControlLevelClass(value));

export const useInternalInitPatientControlLevel = <RCN = undefined>(configs: MakeRequestHookConfig<InternalInitPatientControlLevelParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalInitPatientControlLevelParams,
    APIResponse<PatientControlLevel>,
    typeof internalService.initPatientControlLevel,
    AjaxOptions,
    APIResponseClass<PatientControlLevelClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.initPatientControlLevel>) => (
      internalService.initPatientControlLevel(...args)
    ),
    ResponseClassCreator: createInternalInitPatientControlLevelObject,
    typeName: 'useInternalInitPatientControlLevel',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalInitPatientControlLevel.mock = () => new Error('Mock has not been implemented!');
useInternalInitPatientControlLevel.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalInitPatientControlLevel.createObject = createInternalInitPatientControlLevelObject;

export const createInternalInitIcdCodeConfigsObject = (value: APIResponse<Void>) => new APIResponseClass<VoidClass>(value, (value: Void) => new VoidClass(value));

export const useInternalInitIcdCodeConfigs = <RCN = undefined>(configs: MakeRequestHookConfig<InternalInitIcdCodeConfigsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalInitIcdCodeConfigsParams,
    APIResponse<Void>,
    typeof internalService.initIcdCodeConfigs,
    AjaxOptions,
    APIResponseClass<VoidClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.initIcdCodeConfigs>) => (
      internalService.initIcdCodeConfigs(...args)
    ),
    ResponseClassCreator: createInternalInitIcdCodeConfigsObject,
    typeName: 'useInternalInitIcdCodeConfigs',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalInitIcdCodeConfigs.mock = () => new Error('Mock has not been implemented!');
useInternalInitIcdCodeConfigs.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalInitIcdCodeConfigs.createObject = createInternalInitIcdCodeConfigsObject;

export const createInternalFillUpMissingGCalVisitsObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useInternalFillUpMissingGCalVisits = <RCN = undefined>(configs: MakeRequestHookConfig<InternalFillUpMissingGCalVisitsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalFillUpMissingGCalVisitsParams,
    APIResponse<string>,
    typeof internalService.fillUpMissingGCalVisits,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.fillUpMissingGCalVisits>) => (
      internalService.fillUpMissingGCalVisits(...args)
    ),
    ResponseClassCreator: createInternalFillUpMissingGCalVisitsObject,
    typeName: 'useInternalFillUpMissingGCalVisits',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalFillUpMissingGCalVisits.mock = () => new Error('Mock has not been implemented!');
useInternalFillUpMissingGCalVisits.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalFillUpMissingGCalVisits.createObject = createInternalFillUpMissingGCalVisitsObject;

export const createInternalSendConsentToAllPatientsWithinClinicObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useInternalSendConsentToAllPatientsWithinClinic = <RCN = undefined>(configs: MakeRequestHookConfig<InternalSendConsentToAllPatientsWithinClinicParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalSendConsentToAllPatientsWithinClinicParams,
    APIResponse<string>,
    typeof internalService.sendConsentToAllPatientsWithinClinic,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.sendConsentToAllPatientsWithinClinic>) => (
      internalService.sendConsentToAllPatientsWithinClinic(...args)
    ),
    ResponseClassCreator: createInternalSendConsentToAllPatientsWithinClinicObject,
    typeName: 'useInternalSendConsentToAllPatientsWithinClinic',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalSendConsentToAllPatientsWithinClinic.mock = () => new Error('Mock has not been implemented!');
useInternalSendConsentToAllPatientsWithinClinic.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalSendConsentToAllPatientsWithinClinic.createObject = createInternalSendConsentToAllPatientsWithinClinicObject;

export const createInternalFillUpDiagnosisByIcdCodeObject = (value: APIResponse<Void>) => new APIResponseClass<VoidClass>(value, (value: Void) => new VoidClass(value));

export const useInternalFillUpDiagnosisByIcdCode = <RCN = undefined>(configs: MakeRequestHookConfig<InternalFillUpDiagnosisByIcdCodeParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalFillUpDiagnosisByIcdCodeParams,
    APIResponse<Void>,
    typeof internalService.fillUpDiagnosisByIcdCode,
    AjaxOptions,
    APIResponseClass<VoidClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.fillUpDiagnosisByIcdCode>) => (
      internalService.fillUpDiagnosisByIcdCode(...args)
    ),
    ResponseClassCreator: createInternalFillUpDiagnosisByIcdCodeObject,
    typeName: 'useInternalFillUpDiagnosisByIcdCode',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalFillUpDiagnosisByIcdCode.mock = () => new Error('Mock has not been implemented!');
useInternalFillUpDiagnosisByIcdCode.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalFillUpDiagnosisByIcdCode.createObject = createInternalFillUpDiagnosisByIcdCodeObject;

export const createInternalUpdateCgmGoalObject = (value: APIResponse<CgmGoal>) => new APIResponseClass<CgmGoalClass>(value, (value: CgmGoal) => new CgmGoalClass(value));

export const useInternalUpdateCgmGoal = <RCN = undefined>(configs: MakeRequestHookConfig<InternalUpdateCgmGoalParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    InternalUpdateCgmGoalParams,
    APIResponse<CgmGoal>,
    typeof internalService.updateCgmGoal,
    AjaxOptions,
    APIResponseClass<CgmGoalClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof internalService.updateCgmGoal>) => (
      internalService.updateCgmGoal(...args)
    ),
    ResponseClassCreator: createInternalUpdateCgmGoalObject,
    typeName: 'useInternalUpdateCgmGoal',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useInternalUpdateCgmGoal.mock = () => new Error('Mock has not been implemented!');
useInternalUpdateCgmGoal.getLastMockedValue = () => new Error('Mock has not been implemented!');
useInternalUpdateCgmGoal.createObject = createInternalUpdateCgmGoalObject;
export interface LabResultItem {
	dbKey?: Nullable<string>;
	name?: Nullable<string>;
	unit?: Nullable<string>;
	value?: Nullable<string>;
	flag?: Nullable<string>;
	interval?: Nullable<string>;
	isInHouse?: Nullable<boolean>;
	isManual?: Nullable<boolean>;
}
export enum LabResultSourceEnum {
	LLM = 'LLM',
	EHR = 'EHR',
}
export interface LabResult extends PatientBase {
	userId?: Nullable<string>;
	viewerId?: Nullable<string>;
	templateId?: Nullable<string>;
	templateName?: Nullable<string>;
	dateCollected?: Nullable<string>;
	localDateCollected?: Nullable<string>;
	dateReceived?: Nullable<string>;
	dateReported?: Nullable<string>;
	specimen?: Nullable<string>;
	requisition?: Nullable<string>;
	labRef?: Nullable<string>;
	deleted?: Nullable<boolean>;
	results?: Nullable<LabResultItem[]>;
	labSource?: Nullable<LabResultSourceEnum>;
	templateIdIn?: Nullable<InFilter<string>>;
	resultNameIn?: Nullable<InFilter<string>>;
	resultFlagIn?: Nullable<InFilter<string>>;
	userIdIn?: Nullable<InFilter<string>>;
	localDateCollectedIn?: Nullable<InFilter<string>>;
}
export class LabResultItemClass {
  protected readonly _value: LabResultItem;

	constructor(value: LabResultItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get dbKey() {
    return this._value.dbKey;
  }

  get dbKeyWithDefault() {
    return this._value.dbKey || '';
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || '';
  }

  get flag() {
    return this._value.flag;
  }

  get flagWithDefault() {
    return this._value.flag || '';
  }

  get interval() {
    return this._value.interval;
  }

  get intervalWithDefault() {
    return this._value.interval || '';
  }

  get isInHouse() {
    return this._value.isInHouse;
  }

  get isInHouseWithDefault() {
    return this._value.isInHouse || false;
  }

  get isManual() {
    return this._value.isManual;
  }

  get isManualWithDefault() {
    return this._value.isManual || false;
  }
}
export class LabResultClass extends PatientBaseClass {
  protected readonly _value: LabResult;

	constructor(value: LabResult) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get userId() {
    return this._value.userId;
  }

  get viewerId() {
    return this._value.viewerId;
  }

  get templateId() {
    return this._value.templateId;
  }

  get templateIdWithDefault() {
    return this._value.templateId || '';
  }

  get templateName() {
    return this._value.templateName;
  }

  get templateNameWithDefault() {
    return this._value.templateName || '';
  }

  get dateCollected() {
    return (this._value.dateCollected !== undefined && this._value.dateCollected !== null) ? dayjs(this._value.dateCollected) : this._value.dateCollected;
  }

  get localDateCollected() {
    return this._value.localDateCollected;
  }

  get localDateCollectedWithDefault() {
    return this._value.localDateCollected || '';
  }

  get dateReceived() {
    return (this._value.dateReceived !== undefined && this._value.dateReceived !== null) ? dayjs(this._value.dateReceived) : this._value.dateReceived;
  }

  get dateReported() {
    return (this._value.dateReported !== undefined && this._value.dateReported !== null) ? dayjs(this._value.dateReported) : this._value.dateReported;
  }

  get specimen() {
    return this._value.specimen;
  }

  get specimenWithDefault() {
    return this._value.specimen || '';
  }

  get requisition() {
    return this._value.requisition;
  }

  get requisitionWithDefault() {
    return this._value.requisition || '';
  }

  get labRef() {
    return this._value.labRef;
  }

  get labRefWithDefault() {
    return this._value.labRef || '';
  }

  get deleted() {
    return this._value.deleted;
  }

  get deletedWithDefault() {
    return this._value.deleted || false;
  }

  get results() {
    return this._value.results !== undefined && this._value.results !== null ? map(this._value.results, (v) => new LabResultItemClass(v)) : this._value.results;
  }

  get resultsWithDefault() {
    return this._value.results || [];
  }

  get labSource() {
    return this._value.labSource;
  }

  labSourceIsIn(values: LabResultSourceEnum[] = []) {
    return this._value.labSource && values.includes(this._value.labSource);
  }

  get templateIdIn() {
    return this._value.templateIdIn !== undefined && this._value.templateIdIn !== null ? new InFilterClass(this._value.templateIdIn) : this._value.templateIdIn;
  }

  get resultNameIn() {
    return this._value.resultNameIn !== undefined && this._value.resultNameIn !== null ? new InFilterClass(this._value.resultNameIn) : this._value.resultNameIn;
  }

  get resultFlagIn() {
    return this._value.resultFlagIn !== undefined && this._value.resultFlagIn !== null ? new InFilterClass(this._value.resultFlagIn) : this._value.resultFlagIn;
  }

  get userIdIn() {
    return this._value.userIdIn !== undefined && this._value.userIdIn !== null ? new InFilterClass(this._value.userIdIn) : this._value.userIdIn;
  }

  get localDateCollectedIn() {
    return this._value.localDateCollectedIn !== undefined && this._value.localDateCollectedIn !== null ? new InFilterClass(this._value.localDateCollectedIn) : this._value.localDateCollectedIn;
  }
}
export class LabResultService extends BaseController<LabResult, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/labResults';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const labResultService = new LabResultService();


export const createLabResultGetObject = (value: APIResponse<LabResult>) => new APIResponseClass<LabResultClass>(value, (value: LabResult) => new LabResultClass(value));
export const useLabResultGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<LabResult>,
    // @ts-ignore
    typeof labResultService.get,
    GetOptions,
    APIResponseClass<LabResultClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof labResultService.get>) => (
      labResultService.get(...args)
    ),
    ResponseClassCreator: createLabResultGetObject,
    typeName: 'useLabResultGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useLabResultGet.mock = () => new Error('Mock has not been implemented!');
useLabResultGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useLabResultGet.createObject = createLabResultGetObject;

export const createLabResultUpdateObject = (value: APIResponse<LabResult>) => new APIResponseClass<LabResultClass>(value, (value: LabResult) => new LabResultClass(value));
export const useLabResultUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, LabResult>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, LabResult>,
    APIResponse<LabResult>,
    // @ts-ignore
    typeof labResultService.update,
    AjaxOptions,
    APIResponseClass<LabResultClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof labResultService.update>) => (
      labResultService.update(...args)
    ),
    ResponseClassCreator: createLabResultUpdateObject,
    typeName: 'useLabResultUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useLabResultUpdate.mock = () => new Error('Mock has not been implemented!');
useLabResultUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useLabResultUpdate.createObject = createLabResultUpdateObject;

export const createLabResultInsertObject = (value: APIResponse<LabResult>) => new APIResponseClass<LabResultClass>(value, (value: LabResult) => new LabResultClass(value));
export const useLabResultInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<LabResult>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<LabResult>,
    APIResponse<LabResult>,
    // @ts-ignore
    typeof labResultService.insert,
    AjaxOptions,
    APIResponseClass<LabResultClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof labResultService.insert>) => (
      labResultService.insert(...args)
    ),
    ResponseClassCreator: createLabResultInsertObject,
    typeName: 'useLabResultInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useLabResultInsert.mock = () => new Error('Mock has not been implemented!');
useLabResultInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useLabResultInsert.createObject = createLabResultInsertObject;

export const createLabResultDeleteObject = (value: APIResponse<LabResult>) => new APIResponseClass<LabResultClass>(value, (value: LabResult) => new LabResultClass(value));
export const useLabResultDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<LabResult>,
    // @ts-ignore
    typeof labResultService.delete,
    AjaxOptions,
    APIResponseClass<LabResultClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof labResultService.delete>) => (
      labResultService.delete(...args)
    ),
    ResponseClassCreator: createLabResultDeleteObject,
    typeName: 'useLabResultDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useLabResultDelete.mock = () => new Error('Mock has not been implemented!');
useLabResultDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useLabResultDelete.createObject = createLabResultDeleteObject;

export const createLabResultSearchObject = (value: APIResponse<PageResponse<LabResult>>) => new APIResponseClass<PageResponseClass<LabResultClass>>(value, (value: PageResponse<LabResult>) => new PageResponseClass<LabResultClass>(value, (value: LabResult) => new LabResultClass(value)));
export const useLabResultSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<LabResult>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<LabResult>,
    APIResponse<PageResponse<LabResult>>,
    // @ts-ignore
    typeof labResultService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<LabResultClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof labResultService.search>) => (
      labResultService.search(...args)
    ),
    ResponseClassCreator: createLabResultSearchObject,
    typeName: 'useLabResultSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useLabResultSearch.mock = () => new Error('Mock has not been implemented!');
useLabResultSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useLabResultSearch.createObject = createLabResultSearchObject;

export interface CreateBpRequestPylo {
	event?: Nullable<string>;
	eventTime?: Nullable<string>;
	signature?: Nullable<string>;
	data?: Nullable<string>;
}
export interface BgDataTelliHealth {
	glucose?: Nullable<number>;
	readingPeriod?: Nullable<string>;
}
export interface CreateBgRequestTelliHealth {
	deviceId?: Nullable<string>;
	readingType?: Nullable<string>;
	time?: Nullable<string>;
	data?: Nullable<BgDataTelliHealth>;
}
export interface MeasurementFilter {
	memberId?: Nullable<string>;
	typeList?: Nullable<MeasurementResultTypeEnum[]>;
	severityList?: Nullable<AlertSeverityEnum[]>;
	careStatusList?: Nullable<CareStatusEnum[]>;
	shouldAlert?: Nullable<boolean>;
	dateRange?: Nullable<DateRangeFilter>;
	needStats?: Nullable<boolean>;
	needDataInVisits?: Nullable<boolean>;
	weightUnit?: Nullable<string>;
}
export interface Number {
	serialVersionUID?: Nullable<number>;
}
export interface VitalStatDetail {
	count?: Nullable<number>;
	average?: Nullable<number[]>;
	range?: Nullable<Number[]>;
	inRangePercentage?: Nullable<number>;
}
export interface BpStat {
	all?: Nullable<VitalStatDetail>;
	overnight?: Nullable<VitalStatDetail>;
	morning?: Nullable<VitalStatDetail>;
	afternoon?: Nullable<VitalStatDetail>;
	evening?: Nullable<VitalStatDetail>;
	low?: Nullable<VitalStatDetail>;
	normal?: Nullable<VitalStatDetail>;
	elevated?: Nullable<VitalStatDetail>;
	hypertension1?: Nullable<VitalStatDetail>;
	hypertension2?: Nullable<VitalStatDetail>;
	criticalHigh?: Nullable<VitalStatDetail>;
	arrhythmia?: Nullable<VitalStatDetail>;
	pulsePressure?: Nullable<VitalStatDetail>;
}
export interface BgStat {
	all?: Nullable<VitalStatDetail>;
	fasting?: Nullable<VitalStatDetail>;
	beforeMeal?: Nullable<VitalStatDetail>;
	afterMeal?: Nullable<VitalStatDetail>;
	bedtimeOvernightHypo?: Nullable<VitalStatDetail>;
	bedtime?: Nullable<VitalStatDetail>;
	overnight?: Nullable<VitalStatDetail>;
	criticalHigh?: Nullable<VitalStatDetail>;
	criticalLow?: Nullable<VitalStatDetail>;
}
export interface POVitalStatDetail {
	count?: Nullable<number>;
	average?: Nullable<number>;
	range?: Nullable<number[]>;
	perfusionIndexAverage?: Nullable<number>;
	pulseRateAverage?: Nullable<number>;
}
export interface PoStat {
	all?: Nullable<POVitalStatDetail>;
	overnight?: Nullable<POVitalStatDetail>;
	morning?: Nullable<POVitalStatDetail>;
	afternoon?: Nullable<POVitalStatDetail>;
	evening?: Nullable<POVitalStatDetail>;
	normal?: Nullable<POVitalStatDetail>;
	low?: Nullable<POVitalStatDetail>;
	criticalLow?: Nullable<POVitalStatDetail>;
	highestPO?: Nullable<number>;
}
export interface PeriodicWeightSummary {
	total?: Nullable<number>;
	average?: Nullable<number>;
	bmi?: Nullable<number>;
	min?: Nullable<number>;
	max?: Nullable<number>;
	changePercent?: Nullable<number>;
}
export interface DailyWeight {
	measurementId?: Nullable<string>;
	source?: Nullable<ResultSourceEnum>;
	date?: Nullable<string>;
	timezone?: Nullable<string>;
	dailyCount?: Nullable<number>;
	weight?: Nullable<number>;
	unit?: Nullable<string>;
	notes?: Nullable<string>;
	withMedication?: Nullable<boolean>;
	isOutlier?: Nullable<boolean>;
	bmi?: Nullable<number>;
	weightChange?: Nullable<number>;
	dailyWeightDetails?: Nullable<DailyWeight[]>;
}
export interface WeightTickDetail {
	tickStartDate?: Nullable<string>;
	measurementCount?: Nullable<number>;
	tickWeight?: Nullable<Weight>;
	hasMedication?: Nullable<boolean>;
}
export interface ExerciseStat {
	target?: Nullable<number>;
	total?: Nullable<number>;
	aboveTarget?: Nullable<number>;
	belowTarget?: Nullable<number>;
	aboveRatio?: Nullable<number>;
	belowRatio?: Nullable<number>;
}
export interface MeasurementResultsStats {
	bp?: Nullable<BpStat>;
	bg?: Nullable<BgStat>;
	po?: Nullable<PoStat>;
	periodicWeightSummary?: Nullable<PeriodicWeightSummary>;
	dailyWeight?: Nullable<DailyWeight[]>;
	weightTickDetails?: Nullable<WeightTickDetail[]>;
	exercise?: Nullable<ExerciseStat>;
}
export interface LastMeasurement {
	_id?: Nullable<MeasurementResultTypeEnum>;
	latestMeasurementDate?: Nullable<string>;
}
export interface MeasurementResponse {
	results?: Nullable<PageResponse<Measurement>>;
	stats?: Nullable<MeasurementResultsStats>;
	lastMeasurements?: Nullable<LastMeasurement[]>;
}
export class NumberClass {
  protected readonly _value: Number;

	constructor(value: Number) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get serialVersionUID() {
    return this._value.serialVersionUID;
  }

  get serialVersionUIDWithDefault() {
    return this._value.serialVersionUID || 0;
  }
}
export class VitalStatDetailClass {
  protected readonly _value: VitalStatDetail;

	constructor(value: VitalStatDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get count() {
    return this._value.count;
  }

  get countWithDefault() {
    return this._value.count || 0;
  }

  get average() {
    return this._value.average;
  }

  get averageWithDefault() {
    return this._value.average || [];
  }

  get range() {
    return this._value.range !== undefined && this._value.range !== null ? map(this._value.range, (v) => new NumberClass(v)) : this._value.range;
  }

  get rangeWithDefault() {
    return this._value.range || [];
  }

  get inRangePercentage() {
    return this._value.inRangePercentage;
  }

  get inRangePercentageWithDefault() {
    return this._value.inRangePercentage || 0.0;
  }
}
export class BpStatClass {
  protected readonly _value: BpStat;

	constructor(value: BpStat) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get all() {
    return this._value.all !== undefined && this._value.all !== null ? new VitalStatDetailClass(this._value.all) : this._value.all;
  }

  get overnight() {
    return this._value.overnight !== undefined && this._value.overnight !== null ? new VitalStatDetailClass(this._value.overnight) : this._value.overnight;
  }

  get morning() {
    return this._value.morning !== undefined && this._value.morning !== null ? new VitalStatDetailClass(this._value.morning) : this._value.morning;
  }

  get afternoon() {
    return this._value.afternoon !== undefined && this._value.afternoon !== null ? new VitalStatDetailClass(this._value.afternoon) : this._value.afternoon;
  }

  get evening() {
    return this._value.evening !== undefined && this._value.evening !== null ? new VitalStatDetailClass(this._value.evening) : this._value.evening;
  }

  get low() {
    return this._value.low !== undefined && this._value.low !== null ? new VitalStatDetailClass(this._value.low) : this._value.low;
  }

  get normal() {
    return this._value.normal !== undefined && this._value.normal !== null ? new VitalStatDetailClass(this._value.normal) : this._value.normal;
  }

  get elevated() {
    return this._value.elevated !== undefined && this._value.elevated !== null ? new VitalStatDetailClass(this._value.elevated) : this._value.elevated;
  }

  get hypertension1() {
    return this._value.hypertension1 !== undefined && this._value.hypertension1 !== null ? new VitalStatDetailClass(this._value.hypertension1) : this._value.hypertension1;
  }

  get hypertension2() {
    return this._value.hypertension2 !== undefined && this._value.hypertension2 !== null ? new VitalStatDetailClass(this._value.hypertension2) : this._value.hypertension2;
  }

  get criticalHigh() {
    return this._value.criticalHigh !== undefined && this._value.criticalHigh !== null ? new VitalStatDetailClass(this._value.criticalHigh) : this._value.criticalHigh;
  }

  get arrhythmia() {
    return this._value.arrhythmia !== undefined && this._value.arrhythmia !== null ? new VitalStatDetailClass(this._value.arrhythmia) : this._value.arrhythmia;
  }

  get pulsePressure() {
    return this._value.pulsePressure !== undefined && this._value.pulsePressure !== null ? new VitalStatDetailClass(this._value.pulsePressure) : this._value.pulsePressure;
  }
}
export class BgStatClass {
  protected readonly _value: BgStat;

	constructor(value: BgStat) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get all() {
    return this._value.all !== undefined && this._value.all !== null ? new VitalStatDetailClass(this._value.all) : this._value.all;
  }

  get fasting() {
    return this._value.fasting !== undefined && this._value.fasting !== null ? new VitalStatDetailClass(this._value.fasting) : this._value.fasting;
  }

  get beforeMeal() {
    return this._value.beforeMeal !== undefined && this._value.beforeMeal !== null ? new VitalStatDetailClass(this._value.beforeMeal) : this._value.beforeMeal;
  }

  get afterMeal() {
    return this._value.afterMeal !== undefined && this._value.afterMeal !== null ? new VitalStatDetailClass(this._value.afterMeal) : this._value.afterMeal;
  }

  get bedtimeOvernightHypo() {
    return this._value.bedtimeOvernightHypo !== undefined && this._value.bedtimeOvernightHypo !== null ? new VitalStatDetailClass(this._value.bedtimeOvernightHypo) : this._value.bedtimeOvernightHypo;
  }

  get bedtime() {
    return this._value.bedtime !== undefined && this._value.bedtime !== null ? new VitalStatDetailClass(this._value.bedtime) : this._value.bedtime;
  }

  get overnight() {
    return this._value.overnight !== undefined && this._value.overnight !== null ? new VitalStatDetailClass(this._value.overnight) : this._value.overnight;
  }

  get criticalHigh() {
    return this._value.criticalHigh !== undefined && this._value.criticalHigh !== null ? new VitalStatDetailClass(this._value.criticalHigh) : this._value.criticalHigh;
  }

  get criticalLow() {
    return this._value.criticalLow !== undefined && this._value.criticalLow !== null ? new VitalStatDetailClass(this._value.criticalLow) : this._value.criticalLow;
  }
}
export class POVitalStatDetailClass {
  protected readonly _value: POVitalStatDetail;

	constructor(value: POVitalStatDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get count() {
    return this._value.count;
  }

  get countWithDefault() {
    return this._value.count || 0;
  }

  get average() {
    return this._value.average;
  }

  get averageWithDefault() {
    return this._value.average || 0.0;
  }

  get range() {
    return this._value.range;
  }

  get rangeWithDefault() {
    return this._value.range || [];
  }

  get perfusionIndexAverage() {
    return this._value.perfusionIndexAverage;
  }

  get perfusionIndexAverageWithDefault() {
    return this._value.perfusionIndexAverage || 0.0;
  }

  get pulseRateAverage() {
    return this._value.pulseRateAverage;
  }

  get pulseRateAverageWithDefault() {
    return this._value.pulseRateAverage || 0.0;
  }
}
export class PoStatClass {
  protected readonly _value: PoStat;

	constructor(value: PoStat) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get all() {
    return this._value.all !== undefined && this._value.all !== null ? new POVitalStatDetailClass(this._value.all) : this._value.all;
  }

  get overnight() {
    return this._value.overnight !== undefined && this._value.overnight !== null ? new POVitalStatDetailClass(this._value.overnight) : this._value.overnight;
  }

  get morning() {
    return this._value.morning !== undefined && this._value.morning !== null ? new POVitalStatDetailClass(this._value.morning) : this._value.morning;
  }

  get afternoon() {
    return this._value.afternoon !== undefined && this._value.afternoon !== null ? new POVitalStatDetailClass(this._value.afternoon) : this._value.afternoon;
  }

  get evening() {
    return this._value.evening !== undefined && this._value.evening !== null ? new POVitalStatDetailClass(this._value.evening) : this._value.evening;
  }

  get normal() {
    return this._value.normal !== undefined && this._value.normal !== null ? new POVitalStatDetailClass(this._value.normal) : this._value.normal;
  }

  get low() {
    return this._value.low !== undefined && this._value.low !== null ? new POVitalStatDetailClass(this._value.low) : this._value.low;
  }

  get criticalLow() {
    return this._value.criticalLow !== undefined && this._value.criticalLow !== null ? new POVitalStatDetailClass(this._value.criticalLow) : this._value.criticalLow;
  }

  get highestPO() {
    return this._value.highestPO;
  }

  get highestPOWithDefault() {
    return this._value.highestPO || 0;
  }
}
export class PeriodicWeightSummaryClass {
  protected readonly _value: PeriodicWeightSummary;

	constructor(value: PeriodicWeightSummary) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get total() {
    return this._value.total;
  }

  get totalWithDefault() {
    return this._value.total || 0;
  }

  get average() {
    return this._value.average;
  }

  get averageWithDefault() {
    return this._value.average || 0.0;
  }

  get bmi() {
    return this._value.bmi;
  }

  get bmiWithDefault() {
    return this._value.bmi || 0.0;
  }

  get min() {
    return this._value.min;
  }

  get minWithDefault() {
    return this._value.min || 0.0;
  }

  get max() {
    return this._value.max;
  }

  get maxWithDefault() {
    return this._value.max || 0.0;
  }

  get changePercent() {
    return this._value.changePercent;
  }

  get changePercentWithDefault() {
    return this._value.changePercent || 0.0;
  }
}
export class DailyWeightClass {
  protected readonly _value: DailyWeight;

	constructor(value: DailyWeight) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get measurementId() {
    return this._value.measurementId;
  }

  get measurementIdWithDefault() {
    return this._value.measurementId || '';
  }

  get source() {
    return this._value.source;
  }

  sourceIsIn(values: ResultSourceEnum[] = []) {
    return this._value.source && values.includes(this._value.source);
  }

  get date() {
    return (this._value.date !== undefined && this._value.date !== null) ? dayjs(this._value.date) : this._value.date;
  }

  get timezone() {
    return this._value.timezone;
  }

  get timezoneWithDefault() {
    return this._value.timezone || '';
  }

  get dailyCount() {
    return this._value.dailyCount;
  }

  get dailyCountWithDefault() {
    return this._value.dailyCount || 0;
  }

  get weight() {
    return this._value.weight;
  }

  get weightWithDefault() {
    return this._value.weight || 0.0;
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }

  get notes() {
    return this._value.notes;
  }

  get notesWithDefault() {
    return this._value.notes || '';
  }

  get withMedication() {
    return this._value.withMedication;
  }

  get withMedicationWithDefault() {
    return this._value.withMedication || false;
  }

  get isOutlier() {
    return this._value.isOutlier;
  }

  get isOutlierWithDefault() {
    return this._value.isOutlier || false;
  }

  get bmi() {
    return this._value.bmi;
  }

  get bmiWithDefault() {
    return this._value.bmi || 0.0;
  }

  get weightChange() {
    return this._value.weightChange;
  }

  get weightChangeWithDefault() {
    return this._value.weightChange || 0.0;
  }

  get dailyWeightDetails() {
    return this._value.dailyWeightDetails !== undefined && this._value.dailyWeightDetails !== null ? map(this._value.dailyWeightDetails, (v) => new DailyWeightClass(v)) : this._value.dailyWeightDetails;
  }

  get dailyWeightDetailsWithDefault() {
    return this._value.dailyWeightDetails || [];
  }
}
export class WeightTickDetailClass {
  protected readonly _value: WeightTickDetail;

	constructor(value: WeightTickDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get tickStartDate() {
    return (this._value.tickStartDate !== undefined && this._value.tickStartDate !== null) ? dayjs(this._value.tickStartDate) : this._value.tickStartDate;
  }

  get measurementCount() {
    return this._value.measurementCount;
  }

  get measurementCountWithDefault() {
    return this._value.measurementCount || 0;
  }

  get tickWeight() {
    return this._value.tickWeight !== undefined && this._value.tickWeight !== null ? new WeightClass(this._value.tickWeight) : this._value.tickWeight;
  }

  get hasMedication() {
    return this._value.hasMedication;
  }

  get hasMedicationWithDefault() {
    return this._value.hasMedication || false;
  }
}
export class ExerciseStatClass {
  protected readonly _value: ExerciseStat;

	constructor(value: ExerciseStat) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get target() {
    return this._value.target;
  }

  get targetWithDefault() {
    return this._value.target || 0;
  }

  get total() {
    return this._value.total;
  }

  get totalWithDefault() {
    return this._value.total || 0;
  }

  get aboveTarget() {
    return this._value.aboveTarget;
  }

  get aboveTargetWithDefault() {
    return this._value.aboveTarget || 0;
  }

  get belowTarget() {
    return this._value.belowTarget;
  }

  get belowTargetWithDefault() {
    return this._value.belowTarget || 0;
  }

  get aboveRatio() {
    return this._value.aboveRatio;
  }

  get aboveRatioWithDefault() {
    return this._value.aboveRatio || 0.0;
  }

  get belowRatio() {
    return this._value.belowRatio;
  }

  get belowRatioWithDefault() {
    return this._value.belowRatio || 0.0;
  }
}
export class MeasurementResultsStatsClass {
  protected readonly _value: MeasurementResultsStats;

	constructor(value: MeasurementResultsStats) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get bp() {
    return this._value.bp !== undefined && this._value.bp !== null ? new BpStatClass(this._value.bp) : this._value.bp;
  }

  get bg() {
    return this._value.bg !== undefined && this._value.bg !== null ? new BgStatClass(this._value.bg) : this._value.bg;
  }

  get po() {
    return this._value.po !== undefined && this._value.po !== null ? new PoStatClass(this._value.po) : this._value.po;
  }

  get periodicWeightSummary() {
    return this._value.periodicWeightSummary !== undefined && this._value.periodicWeightSummary !== null ? new PeriodicWeightSummaryClass(this._value.periodicWeightSummary) : this._value.periodicWeightSummary;
  }

  get dailyWeight() {
    return this._value.dailyWeight !== undefined && this._value.dailyWeight !== null ? map(this._value.dailyWeight, (v) => new DailyWeightClass(v)) : this._value.dailyWeight;
  }

  get dailyWeightWithDefault() {
    return this._value.dailyWeight || [];
  }

  get weightTickDetails() {
    return this._value.weightTickDetails !== undefined && this._value.weightTickDetails !== null ? map(this._value.weightTickDetails, (v) => new WeightTickDetailClass(v)) : this._value.weightTickDetails;
  }

  get weightTickDetailsWithDefault() {
    return this._value.weightTickDetails || [];
  }

  get exercise() {
    return this._value.exercise !== undefined && this._value.exercise !== null ? new ExerciseStatClass(this._value.exercise) : this._value.exercise;
  }
}
export class LastMeasurementClass {
  protected readonly _value: LastMeasurement;

	constructor(value: LastMeasurement) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get _id() {
    return this._value._id;
  }

  _idIsIn(values: MeasurementResultTypeEnum[] = []) {
    return this._value._id && values.includes(this._value._id);
  }

  get latestMeasurementDate() {
    return (this._value.latestMeasurementDate !== undefined && this._value.latestMeasurementDate !== null) ? dayjs(this._value.latestMeasurementDate) : this._value.latestMeasurementDate;
  }
}
export class MeasurementResponseClass {
  protected readonly _value: MeasurementResponse;

	constructor(value: MeasurementResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get results() {
    return this._value.results !== undefined && this._value.results !== null ? new PageResponseClass(this._value.results) : this._value.results;
  }

  get stats() {
    return this._value.stats !== undefined && this._value.stats !== null ? new MeasurementResultsStatsClass(this._value.stats) : this._value.stats;
  }

  get lastMeasurements() {
    return this._value.lastMeasurements !== undefined && this._value.lastMeasurements !== null ? map(this._value.lastMeasurements, (v) => new LastMeasurementClass(v)) : this._value.lastMeasurements;
  }

  get lastMeasurementsWithDefault() {
    return this._value.lastMeasurements || [];
  }
}
export interface WeightChart {
	changeFromHsBaseline?: Nullable<number>;
	highLimit?: Nullable<number>;
	normalLimit?: Nullable<number>;
	lowLimit?: Nullable<number>;
	tickFrequency?: Nullable<FrequencyUnitEnum>;
	weightTickDetails?: Nullable<WeightTickDetail[]>;
}
export interface WeightSummary {
	lastHsDate?: Nullable<string>;
	dailyWeightSummaries?: Nullable<DailyWeight[]>;
	total?: Nullable<number>;
	average?: Nullable<number>;
	bmi?: Nullable<number>;
	hsBaseline?: Nullable<Weight>;
	currentWeightStat?: Nullable<PeriodicWeightSummary>;
	previousWeightStat?: Nullable<PeriodicWeightSummary>;
	overallWeightStat?: Nullable<PeriodicWeightSummary>;
	weightChart?: Nullable<WeightChart>;
}
export class WeightChartClass {
  protected readonly _value: WeightChart;

	constructor(value: WeightChart) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get changeFromHsBaseline() {
    return this._value.changeFromHsBaseline;
  }

  get changeFromHsBaselineWithDefault() {
    return this._value.changeFromHsBaseline || 0.0;
  }

  get highLimit() {
    return this._value.highLimit;
  }

  get highLimitWithDefault() {
    return this._value.highLimit || 0.0;
  }

  get normalLimit() {
    return this._value.normalLimit;
  }

  get normalLimitWithDefault() {
    return this._value.normalLimit || 0.0;
  }

  get lowLimit() {
    return this._value.lowLimit;
  }

  get lowLimitWithDefault() {
    return this._value.lowLimit || 0.0;
  }

  get tickFrequency() {
    return this._value.tickFrequency;
  }

  tickFrequencyIsIn(values: FrequencyUnitEnum[] = []) {
    return this._value.tickFrequency && values.includes(this._value.tickFrequency);
  }

  get weightTickDetails() {
    return this._value.weightTickDetails !== undefined && this._value.weightTickDetails !== null ? map(this._value.weightTickDetails, (v) => new WeightTickDetailClass(v)) : this._value.weightTickDetails;
  }

  get weightTickDetailsWithDefault() {
    return this._value.weightTickDetails || [];
  }
}
export class WeightSummaryClass {
  protected readonly _value: WeightSummary;

	constructor(value: WeightSummary) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get lastHsDate() {
    return (this._value.lastHsDate !== undefined && this._value.lastHsDate !== null) ? dayjs(this._value.lastHsDate) : this._value.lastHsDate;
  }

  get dailyWeightSummaries() {
    return this._value.dailyWeightSummaries !== undefined && this._value.dailyWeightSummaries !== null ? map(this._value.dailyWeightSummaries, (v) => new DailyWeightClass(v)) : this._value.dailyWeightSummaries;
  }

  get dailyWeightSummariesWithDefault() {
    return this._value.dailyWeightSummaries || [];
  }

  get total() {
    return this._value.total;
  }

  get totalWithDefault() {
    return this._value.total || 0;
  }

  get average() {
    return this._value.average;
  }

  get averageWithDefault() {
    return this._value.average || 0.0;
  }

  get bmi() {
    return this._value.bmi;
  }

  get bmiWithDefault() {
    return this._value.bmi || 0.0;
  }

  get hsBaseline() {
    return this._value.hsBaseline !== undefined && this._value.hsBaseline !== null ? new WeightClass(this._value.hsBaseline) : this._value.hsBaseline;
  }

  get currentWeightStat() {
    return this._value.currentWeightStat !== undefined && this._value.currentWeightStat !== null ? new PeriodicWeightSummaryClass(this._value.currentWeightStat) : this._value.currentWeightStat;
  }

  get previousWeightStat() {
    return this._value.previousWeightStat !== undefined && this._value.previousWeightStat !== null ? new PeriodicWeightSummaryClass(this._value.previousWeightStat) : this._value.previousWeightStat;
  }

  get overallWeightStat() {
    return this._value.overallWeightStat !== undefined && this._value.overallWeightStat !== null ? new PeriodicWeightSummaryClass(this._value.overallWeightStat) : this._value.overallWeightStat;
  }

  get weightChart() {
    return this._value.weightChart !== undefined && this._value.weightChart !== null ? new WeightChartClass(this._value.weightChart) : this._value.weightChart;
  }
}
export interface AverageValue {
	startTime?: Nullable<string>;
	endTime?: Nullable<string>;
	totalMeasInRange?: Nullable<number>;
	bloodGlucose?: Nullable<BloodGlucose>;
	systolicBloodPressure?: Nullable<BloodPressure>;
	diastolicBloodPressure?: Nullable<BloodPressure>;
}
export interface MonthlyMeasurement {
	lastMeasurementDate?: Nullable<string>;
	measurementDays?: Nullable<number>;
	totalMeasurements?: Nullable<number>;
	averageValue?: Nullable<AverageValue[]>;
	normalPercentage?: Nullable<number>;
	condition?: Nullable<ConditionEnum>;
}
export class AverageValueClass {
  protected readonly _value: AverageValue;

	constructor(value: AverageValue) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get startTime() {
    return (this._value.startTime !== undefined && this._value.startTime !== null) ? dayjs(this._value.startTime) : this._value.startTime;
  }

  get endTime() {
    return (this._value.endTime !== undefined && this._value.endTime !== null) ? dayjs(this._value.endTime) : this._value.endTime;
  }

  get totalMeasInRange() {
    return this._value.totalMeasInRange;
  }

  get totalMeasInRangeWithDefault() {
    return this._value.totalMeasInRange || 0;
  }

  get bloodGlucose() {
    return this._value.bloodGlucose !== undefined && this._value.bloodGlucose !== null ? new BloodGlucoseClass(this._value.bloodGlucose) : this._value.bloodGlucose;
  }

  get systolicBloodPressure() {
    return this._value.systolicBloodPressure !== undefined && this._value.systolicBloodPressure !== null ? new BloodPressureClass(this._value.systolicBloodPressure) : this._value.systolicBloodPressure;
  }

  get diastolicBloodPressure() {
    return this._value.diastolicBloodPressure !== undefined && this._value.diastolicBloodPressure !== null ? new BloodPressureClass(this._value.diastolicBloodPressure) : this._value.diastolicBloodPressure;
  }
}
export class MonthlyMeasurementClass {
  protected readonly _value: MonthlyMeasurement;

	constructor(value: MonthlyMeasurement) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get lastMeasurementDate() {
    return (this._value.lastMeasurementDate !== undefined && this._value.lastMeasurementDate !== null) ? dayjs(this._value.lastMeasurementDate) : this._value.lastMeasurementDate;
  }

  get measurementDays() {
    return this._value.measurementDays;
  }

  get measurementDaysWithDefault() {
    return this._value.measurementDays || 0;
  }

  get totalMeasurements() {
    return this._value.totalMeasurements;
  }

  get totalMeasurementsWithDefault() {
    return this._value.totalMeasurements || 0;
  }

  get averageValue() {
    return this._value.averageValue !== undefined && this._value.averageValue !== null ? map(this._value.averageValue, (v) => new AverageValueClass(v)) : this._value.averageValue;
  }

  get averageValueWithDefault() {
    return this._value.averageValue || [];
  }

  get normalPercentage() {
    return this._value.normalPercentage;
  }

  get normalPercentageWithDefault() {
    return this._value.normalPercentage || 0.0;
  }

  get condition() {
    return this._value.condition;
  }

  conditionIsIn(values: ConditionEnum[] = []) {
    return this._value.condition && values.includes(this._value.condition);
  }
}
export interface MeasurementCreateBpPyloParams {
	createBpRequestPylo: CreateBpRequestPylo;
}

export interface MeasurementCreateBgTelliHealthParams {
	createBgRequestTelliHealth: CreateBgRequestTelliHealth;
}

export interface MeasurementResultListParams {
	searchRequest: SearchRequest<MeasurementFilter>;
}

export interface MeasurementGetWeightSummaryParams {
	searchRequest: SearchRequest<MeasurementFilter>;
}

export interface MeasurementGetMonthlyMeasurementsParams {
	memberId: string;
}

export interface MeasurementInsertFromAppParams {
	measurement: Measurement;
}

export interface MeasurementInsertBpBaselineParams {
	measurement: Measurement;
}

export interface MeasurementGetBpBaselineParams {
	memberId: string;
}

export interface MeasurementSoftDeleteMeasurementParams {
	request: Measurement;
}

export interface MeasurementSearchDeletedMeasurementParams {
	request: SearchRequest<Measurement>;
}

export interface MeasurementGetMemberLastAllMeasurementTypeTimeParams {
	memberId: string;
}

export class MeasurementService extends BaseController<Measurement, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/measurement';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public createBpPylo({ baseUrl, params, headers }: RequestOption<MeasurementCreateBpPyloParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Measurement>>(`${baseUrl || this.baseURL}${this.basePath}/bp-pylo${getQueryStrings(queryList)}`, params.createBpRequestPylo, { headers });
	}

	public createBgTelliHealth({ baseUrl, params, headers }: RequestOption<MeasurementCreateBgTelliHealthParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Measurement>>(`${baseUrl || this.baseURL}${this.basePath}/bg-tellihealth${getQueryStrings(queryList)}`, params.createBgRequestTelliHealth, { headers });
	}

	public resultList({ baseUrl, params, headers }: RequestOption<MeasurementResultListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<MeasurementResponse>>(`${baseUrl || this.baseURL}${this.basePath}/list${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getWeightSummary({ baseUrl, params, headers }: RequestOption<MeasurementGetWeightSummaryParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<WeightSummary>>(`${baseUrl || this.baseURL}${this.basePath}/weight-summary${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getMonthlyMeasurements({ baseUrl, params, headers }: RequestOption<MeasurementGetMonthlyMeasurementsParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<MonthlyMeasurement[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/monthly-measurement${getQueryStrings(queryList)}`, { headers });
	}

	public insertFromApp({ baseUrl, params, headers }: RequestOption<MeasurementInsertFromAppParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Measurement>>(`${baseUrl || this.baseURL}${this.basePath}/insert-from-app${getQueryStrings(queryList)}`, params.measurement, { headers });
	}

	public insertBpBaseline({ baseUrl, params, headers }: RequestOption<MeasurementInsertBpBaselineParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Measurement>>(`${baseUrl || this.baseURL}${this.basePath}/bp-baseline${getQueryStrings(queryList)}`, params.measurement, { headers });
	}

	public getBpBaseline({ baseUrl, params, headers }: RequestOption<MeasurementGetBpBaselineParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<Measurement>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/bp-baseline${getQueryStrings(queryList)}`, { headers });
	}

	public softDeleteMeasurement({ baseUrl, params, headers }: RequestOption<MeasurementSoftDeleteMeasurementParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Measurement>>(`${baseUrl || this.baseURL}${this.basePath}/soft-delete${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public searchDeletedMeasurement({ baseUrl, params, headers }: RequestOption<MeasurementSearchDeletedMeasurementParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<Measurement>>>(`${baseUrl || this.baseURL}${this.basePath}/search-delete${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getMemberLastAllMeasurementTypeTime({ baseUrl, params, headers }: RequestOption<MeasurementGetMemberLastAllMeasurementTypeTimeParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<LastMeasurementResponse[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/last-measurement${getQueryStrings(queryList)}`, { headers });
	}
}

export const measurementService = new MeasurementService();

export const createMeasurementCreateBpPyloObject = (value: APIResponse<Measurement>) => new APIResponseClass<MeasurementClass>(value, (value: Measurement) => new MeasurementClass(value));

export const useMeasurementCreateBpPylo = <RCN = undefined>(configs: MakeRequestHookConfig<MeasurementCreateBpPyloParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MeasurementCreateBpPyloParams,
    APIResponse<Measurement>,
    typeof measurementService.createBpPylo,
    AjaxOptions,
    APIResponseClass<MeasurementClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof measurementService.createBpPylo>) => (
      measurementService.createBpPylo(...args)
    ),
    ResponseClassCreator: createMeasurementCreateBpPyloObject,
    typeName: 'useMeasurementCreateBpPylo',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMeasurementCreateBpPylo.mock = () => new Error('Mock has not been implemented!');
useMeasurementCreateBpPylo.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMeasurementCreateBpPylo.createObject = createMeasurementCreateBpPyloObject;

export const createMeasurementCreateBgTelliHealthObject = (value: APIResponse<Measurement>) => new APIResponseClass<MeasurementClass>(value, (value: Measurement) => new MeasurementClass(value));

export const useMeasurementCreateBgTelliHealth = <RCN = undefined>(configs: MakeRequestHookConfig<MeasurementCreateBgTelliHealthParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MeasurementCreateBgTelliHealthParams,
    APIResponse<Measurement>,
    typeof measurementService.createBgTelliHealth,
    AjaxOptions,
    APIResponseClass<MeasurementClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof measurementService.createBgTelliHealth>) => (
      measurementService.createBgTelliHealth(...args)
    ),
    ResponseClassCreator: createMeasurementCreateBgTelliHealthObject,
    typeName: 'useMeasurementCreateBgTelliHealth',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMeasurementCreateBgTelliHealth.mock = () => new Error('Mock has not been implemented!');
useMeasurementCreateBgTelliHealth.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMeasurementCreateBgTelliHealth.createObject = createMeasurementCreateBgTelliHealthObject;

export const createMeasurementResultListObject = (value: APIResponse<MeasurementResponse>) => new APIResponseClass<MeasurementResponseClass>(value, (value: MeasurementResponse) => new MeasurementResponseClass(value));

export const useMeasurementResultList = <RCN = undefined>(configs: MakeRequestHookConfig<MeasurementResultListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MeasurementResultListParams,
    APIResponse<MeasurementResponse>,
    typeof measurementService.resultList,
    AjaxOptions,
    APIResponseClass<MeasurementResponseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof measurementService.resultList>) => (
      measurementService.resultList(...args)
    ),
    ResponseClassCreator: createMeasurementResultListObject,
    typeName: 'useMeasurementResultList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMeasurementResultList.mock = () => new Error('Mock has not been implemented!');
useMeasurementResultList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMeasurementResultList.createObject = createMeasurementResultListObject;

export const createMeasurementGetWeightSummaryObject = (value: APIResponse<WeightSummary>) => new APIResponseClass<WeightSummaryClass>(value, (value: WeightSummary) => new WeightSummaryClass(value));

export const useMeasurementGetWeightSummary = <RCN = undefined>(configs: MakeRequestHookConfig<MeasurementGetWeightSummaryParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MeasurementGetWeightSummaryParams,
    APIResponse<WeightSummary>,
    typeof measurementService.getWeightSummary,
    AjaxOptions,
    APIResponseClass<WeightSummaryClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof measurementService.getWeightSummary>) => (
      measurementService.getWeightSummary(...args)
    ),
    ResponseClassCreator: createMeasurementGetWeightSummaryObject,
    typeName: 'useMeasurementGetWeightSummary',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMeasurementGetWeightSummary.mock = () => new Error('Mock has not been implemented!');
useMeasurementGetWeightSummary.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMeasurementGetWeightSummary.createObject = createMeasurementGetWeightSummaryObject;

export const createMeasurementGetMonthlyMeasurementsObject = (value: APIResponse<MonthlyMeasurement[]>) => new APIResponseClass<MonthlyMeasurementClass[]>(value, (v) => map(v, (value: MonthlyMeasurement) => new MonthlyMeasurementClass(value)));

export const useMeasurementGetMonthlyMeasurements = <RCN = undefined>(configs: MakeRequestHookConfig<MeasurementGetMonthlyMeasurementsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MeasurementGetMonthlyMeasurementsParams,
    APIResponse<MonthlyMeasurement[]>,
    typeof measurementService.getMonthlyMeasurements,
    GetOptions,
    APIResponseClass<MonthlyMeasurementClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof measurementService.getMonthlyMeasurements>) => (
      measurementService.getMonthlyMeasurements(...args)
    ),
    ResponseClassCreator: createMeasurementGetMonthlyMeasurementsObject,
    typeName: 'useMeasurementGetMonthlyMeasurements',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMeasurementGetMonthlyMeasurements.mock = () => new Error('Mock has not been implemented!');
useMeasurementGetMonthlyMeasurements.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMeasurementGetMonthlyMeasurements.createObject = createMeasurementGetMonthlyMeasurementsObject;

export const createMeasurementInsertFromAppObject = (value: APIResponse<Measurement>) => new APIResponseClass<MeasurementClass>(value, (value: Measurement) => new MeasurementClass(value));

export const useMeasurementInsertFromApp = <RCN = undefined>(configs: MakeRequestHookConfig<MeasurementInsertFromAppParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MeasurementInsertFromAppParams,
    APIResponse<Measurement>,
    typeof measurementService.insertFromApp,
    AjaxOptions,
    APIResponseClass<MeasurementClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof measurementService.insertFromApp>) => (
      measurementService.insertFromApp(...args)
    ),
    ResponseClassCreator: createMeasurementInsertFromAppObject,
    typeName: 'useMeasurementInsertFromApp',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMeasurementInsertFromApp.mock = () => new Error('Mock has not been implemented!');
useMeasurementInsertFromApp.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMeasurementInsertFromApp.createObject = createMeasurementInsertFromAppObject;

export const createMeasurementInsertBpBaselineObject = (value: APIResponse<Measurement>) => new APIResponseClass<MeasurementClass>(value, (value: Measurement) => new MeasurementClass(value));

export const useMeasurementInsertBpBaseline = <RCN = undefined>(configs: MakeRequestHookConfig<MeasurementInsertBpBaselineParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MeasurementInsertBpBaselineParams,
    APIResponse<Measurement>,
    typeof measurementService.insertBpBaseline,
    AjaxOptions,
    APIResponseClass<MeasurementClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof measurementService.insertBpBaseline>) => (
      measurementService.insertBpBaseline(...args)
    ),
    ResponseClassCreator: createMeasurementInsertBpBaselineObject,
    typeName: 'useMeasurementInsertBpBaseline',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMeasurementInsertBpBaseline.mock = () => new Error('Mock has not been implemented!');
useMeasurementInsertBpBaseline.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMeasurementInsertBpBaseline.createObject = createMeasurementInsertBpBaselineObject;

export const createMeasurementGetBpBaselineObject = (value: APIResponse<Measurement>) => new APIResponseClass<MeasurementClass>(value, (value: Measurement) => new MeasurementClass(value));

export const useMeasurementGetBpBaseline = <RCN = undefined>(configs: MakeRequestHookConfig<MeasurementGetBpBaselineParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MeasurementGetBpBaselineParams,
    APIResponse<Measurement>,
    typeof measurementService.getBpBaseline,
    GetOptions,
    APIResponseClass<MeasurementClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof measurementService.getBpBaseline>) => (
      measurementService.getBpBaseline(...args)
    ),
    ResponseClassCreator: createMeasurementGetBpBaselineObject,
    typeName: 'useMeasurementGetBpBaseline',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMeasurementGetBpBaseline.mock = () => new Error('Mock has not been implemented!');
useMeasurementGetBpBaseline.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMeasurementGetBpBaseline.createObject = createMeasurementGetBpBaselineObject;

export const createMeasurementSoftDeleteMeasurementObject = (value: APIResponse<Measurement>) => new APIResponseClass<MeasurementClass>(value, (value: Measurement) => new MeasurementClass(value));

export const useMeasurementSoftDeleteMeasurement = <RCN = undefined>(configs: MakeRequestHookConfig<MeasurementSoftDeleteMeasurementParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MeasurementSoftDeleteMeasurementParams,
    APIResponse<Measurement>,
    typeof measurementService.softDeleteMeasurement,
    AjaxOptions,
    APIResponseClass<MeasurementClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof measurementService.softDeleteMeasurement>) => (
      measurementService.softDeleteMeasurement(...args)
    ),
    ResponseClassCreator: createMeasurementSoftDeleteMeasurementObject,
    typeName: 'useMeasurementSoftDeleteMeasurement',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMeasurementSoftDeleteMeasurement.mock = () => new Error('Mock has not been implemented!');
useMeasurementSoftDeleteMeasurement.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMeasurementSoftDeleteMeasurement.createObject = createMeasurementSoftDeleteMeasurementObject;

export const createMeasurementSearchDeletedMeasurementObject = (value: APIResponse<PageResponse<Measurement>>) => new APIResponseClass<PageResponseClass<MeasurementClass>>(value, (value: PageResponse<Measurement>) => new PageResponseClass<MeasurementClass>(value, (value: Measurement) => new MeasurementClass(value)));

export const useMeasurementSearchDeletedMeasurement = <RCN = undefined>(configs: MakeRequestHookConfig<MeasurementSearchDeletedMeasurementParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MeasurementSearchDeletedMeasurementParams,
    APIResponse<PageResponse<Measurement>>,
    typeof measurementService.searchDeletedMeasurement,
    AjaxOptions,
    APIResponseClass<PageResponseClass<MeasurementClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof measurementService.searchDeletedMeasurement>) => (
      measurementService.searchDeletedMeasurement(...args)
    ),
    ResponseClassCreator: createMeasurementSearchDeletedMeasurementObject,
    typeName: 'useMeasurementSearchDeletedMeasurement',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMeasurementSearchDeletedMeasurement.mock = () => new Error('Mock has not been implemented!');
useMeasurementSearchDeletedMeasurement.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMeasurementSearchDeletedMeasurement.createObject = createMeasurementSearchDeletedMeasurementObject;

export const createMeasurementGetMemberLastAllMeasurementTypeTimeObject = (value: APIResponse<LastMeasurementResponse[]>) => new APIResponseClass<LastMeasurementResponseClass[]>(value, (v) => map(v, (value: LastMeasurementResponse) => new LastMeasurementResponseClass(value)));

export const useMeasurementGetMemberLastAllMeasurementTypeTime = <RCN = undefined>(configs: MakeRequestHookConfig<MeasurementGetMemberLastAllMeasurementTypeTimeParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MeasurementGetMemberLastAllMeasurementTypeTimeParams,
    APIResponse<LastMeasurementResponse[]>,
    typeof measurementService.getMemberLastAllMeasurementTypeTime,
    GetOptions,
    APIResponseClass<LastMeasurementResponseClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof measurementService.getMemberLastAllMeasurementTypeTime>) => (
      measurementService.getMemberLastAllMeasurementTypeTime(...args)
    ),
    ResponseClassCreator: createMeasurementGetMemberLastAllMeasurementTypeTimeObject,
    typeName: 'useMeasurementGetMemberLastAllMeasurementTypeTime',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMeasurementGetMemberLastAllMeasurementTypeTime.mock = () => new Error('Mock has not been implemented!');
useMeasurementGetMemberLastAllMeasurementTypeTime.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMeasurementGetMemberLastAllMeasurementTypeTime.createObject = createMeasurementGetMemberLastAllMeasurementTypeTimeObject;


export const createMeasurementGetObject = (value: APIResponse<Measurement>) => new APIResponseClass<MeasurementClass>(value, (value: Measurement) => new MeasurementClass(value));
export const useMeasurementGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<Measurement>,
    // @ts-ignore
    typeof measurementService.get,
    GetOptions,
    APIResponseClass<MeasurementClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof measurementService.get>) => (
      measurementService.get(...args)
    ),
    ResponseClassCreator: createMeasurementGetObject,
    typeName: 'useMeasurementGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMeasurementGet.mock = () => new Error('Mock has not been implemented!');
useMeasurementGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMeasurementGet.createObject = createMeasurementGetObject;

export const createMeasurementUpdateObject = (value: APIResponse<Measurement>) => new APIResponseClass<MeasurementClass>(value, (value: Measurement) => new MeasurementClass(value));
export const useMeasurementUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, Measurement>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, Measurement>,
    APIResponse<Measurement>,
    // @ts-ignore
    typeof measurementService.update,
    AjaxOptions,
    APIResponseClass<MeasurementClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof measurementService.update>) => (
      measurementService.update(...args)
    ),
    ResponseClassCreator: createMeasurementUpdateObject,
    typeName: 'useMeasurementUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMeasurementUpdate.mock = () => new Error('Mock has not been implemented!');
useMeasurementUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMeasurementUpdate.createObject = createMeasurementUpdateObject;

export const createMeasurementInsertObject = (value: APIResponse<Measurement>) => new APIResponseClass<MeasurementClass>(value, (value: Measurement) => new MeasurementClass(value));
export const useMeasurementInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<Measurement>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<Measurement>,
    APIResponse<Measurement>,
    // @ts-ignore
    typeof measurementService.insert,
    AjaxOptions,
    APIResponseClass<MeasurementClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof measurementService.insert>) => (
      measurementService.insert(...args)
    ),
    ResponseClassCreator: createMeasurementInsertObject,
    typeName: 'useMeasurementInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMeasurementInsert.mock = () => new Error('Mock has not been implemented!');
useMeasurementInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMeasurementInsert.createObject = createMeasurementInsertObject;

export const createMeasurementDeleteObject = (value: APIResponse<Measurement>) => new APIResponseClass<MeasurementClass>(value, (value: Measurement) => new MeasurementClass(value));
export const useMeasurementDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<Measurement>,
    // @ts-ignore
    typeof measurementService.delete,
    AjaxOptions,
    APIResponseClass<MeasurementClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof measurementService.delete>) => (
      measurementService.delete(...args)
    ),
    ResponseClassCreator: createMeasurementDeleteObject,
    typeName: 'useMeasurementDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMeasurementDelete.mock = () => new Error('Mock has not been implemented!');
useMeasurementDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMeasurementDelete.createObject = createMeasurementDeleteObject;

export const createMeasurementSearchObject = (value: APIResponse<PageResponse<Measurement>>) => new APIResponseClass<PageResponseClass<MeasurementClass>>(value, (value: PageResponse<Measurement>) => new PageResponseClass<MeasurementClass>(value, (value: Measurement) => new MeasurementClass(value)));
export const useMeasurementSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<Measurement>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<Measurement>,
    APIResponse<PageResponse<Measurement>>,
    // @ts-ignore
    typeof measurementService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<MeasurementClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof measurementService.search>) => (
      measurementService.search(...args)
    ),
    ResponseClassCreator: createMeasurementSearchObject,
    typeName: 'useMeasurementSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMeasurementSearch.mock = () => new Error('Mock has not been implemented!');
useMeasurementSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMeasurementSearch.createObject = createMeasurementSearchObject;

export interface Set<E> {
}
export interface PatientAlertSearchRequest {
	clinicIds?: Nullable<string[]>;
	alertTypes?: Nullable<MedicalAlertCategoryEnum[]>;
	priorities?: Nullable<PriorityLevelEnum[]>;
	triggeredOnTsStart?: Nullable<number>;
	triggeredOnTsEnd?: Nullable<number>;
	alertDisplayNames?: Nullable<string[]>;
	patientNames?: Nullable<Set<string>>;
	patientIds?: Nullable<Set<string>>;
	providerNames?: Nullable<Set<string>>;
	providerIds?: Nullable<Set<string>>;
	assignees?: Nullable<Set<string>>;
	assigneeIds?: Nullable<Set<string>>;
	status?: Nullable<SmartAlertTaskStatus[]>;
}
export interface PatientAlertRecord$MedicalAlertItem {
	alertName?: Nullable<string>;
	triggeredOnTs?: Nullable<number>;
	resolvedOnTs?: Nullable<number>;
	resolvedNote?: Nullable<string>;
	reason?: Nullable<string>;
	priority?: Nullable<PriorityLevelEnum>;
	status?: Nullable<SmartAlertTaskStatus>;
}
export interface PatientAlertRecord {
	memberId?: Nullable<string>;
	memberName?: Nullable<string>;
	alertItemList?: Nullable<PatientAlertRecord$MedicalAlertItem[]>;
	providerName?: Nullable<string>;
	assignedTo?: Nullable<string>;
}
export class PatientAlertRecord$MedicalAlertItemClass {
  protected readonly _value: PatientAlertRecord$MedicalAlertItem;

	constructor(value: PatientAlertRecord$MedicalAlertItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get alertName() {
    return this._value.alertName;
  }

  get alertNameWithDefault() {
    return this._value.alertName || '';
  }

  get triggeredOnTs() {
    return this._value.triggeredOnTs;
  }

  get triggeredOnTsWithDefault() {
    return this._value.triggeredOnTs || 0;
  }

  get resolvedOnTs() {
    return this._value.resolvedOnTs;
  }

  get resolvedOnTsWithDefault() {
    return this._value.resolvedOnTs || 0;
  }

  get resolvedNote() {
    return this._value.resolvedNote;
  }

  get resolvedNoteWithDefault() {
    return this._value.resolvedNote || '';
  }

  get reason() {
    return this._value.reason;
  }

  get reasonWithDefault() {
    return this._value.reason || '';
  }

  get priority() {
    return this._value.priority;
  }

  priorityIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.priority && values.includes(this._value.priority);
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: SmartAlertTaskStatus[] = []) {
    return this._value.status && values.includes(this._value.status);
  }
}
export class PatientAlertRecordClass {
  protected readonly _value: PatientAlertRecord;

	constructor(value: PatientAlertRecord) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get memberIdWithDefault() {
    return this._value.memberId || '';
  }

  get memberName() {
    return this._value.memberName;
  }

  get memberNameWithDefault() {
    return this._value.memberName || '';
  }

  get alertItemList() {
    return this._value.alertItemList !== undefined && this._value.alertItemList !== null ? map(this._value.alertItemList, (v) => new PatientAlertRecord$MedicalAlertItemClass(v)) : this._value.alertItemList;
  }

  get alertItemListWithDefault() {
    return this._value.alertItemList || [];
  }

  get providerName() {
    return this._value.providerName;
  }

  get providerNameWithDefault() {
    return this._value.providerName || '';
  }

  get assignedTo() {
    return this._value.assignedTo;
  }

  get assignedToWithDefault() {
    return this._value.assignedTo || '';
  }
}
export interface MedicalAlertResult {
	displayName?: Nullable<string>;
	alerts?: Nullable<MedicalAlert[]>;
	priority?: Nullable<PriorityLevelEnum>;
	alertCategory?: Nullable<MedicalAlertCategoryEnum>;
	dueDate?: Nullable<string>;
}
export class MedicalAlertResultClass {
  protected readonly _value: MedicalAlertResult;

	constructor(value: MedicalAlertResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get displayName() {
    return this._value.displayName;
  }

  get displayNameWithDefault() {
    return this._value.displayName || '';
  }

  get alerts() {
    return this._value.alerts !== undefined && this._value.alerts !== null ? map(this._value.alerts, (v) => new MedicalAlertClass(v)) : this._value.alerts;
  }

  get alertsWithDefault() {
    return this._value.alerts || [];
  }

  get priority() {
    return this._value.priority;
  }

  priorityIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.priority && values.includes(this._value.priority);
  }

  get alertCategory() {
    return this._value.alertCategory;
  }

  alertCategoryIsIn(values: MedicalAlertCategoryEnum[] = []) {
    return this._value.alertCategory && values.includes(this._value.alertCategory);
  }

  get dueDate() {
    return (this._value.dueDate !== undefined && this._value.dueDate !== null) ? dayjs(this._value.dueDate) : this._value.dueDate;
  }
}
export enum AlertCategoryEnum {
	CLINICAL = 'CLINICAL',
	PROVIDER = 'PROVIDER',
}
export interface MedicalAlertSummary {
	alertDisplayName?: Nullable<string>;
	lastTriggeredOnTs?: Nullable<number>;
	priority?: Nullable<string>;
	eventType?: Nullable<EventTypeEnum>;
	alertCategory?: Nullable<AlertCategoryEnum>;
	openedCount?: Nullable<number>;
	totalCount?: Nullable<number>;
}
export class MedicalAlertSummaryClass {
  protected readonly _value: MedicalAlertSummary;

	constructor(value: MedicalAlertSummary) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get alertDisplayName() {
    return this._value.alertDisplayName;
  }

  get alertDisplayNameWithDefault() {
    return this._value.alertDisplayName || '';
  }

  get lastTriggeredOnTs() {
    return this._value.lastTriggeredOnTs;
  }

  get lastTriggeredOnTsWithDefault() {
    return this._value.lastTriggeredOnTs || 0;
  }

  get priority() {
    return this._value.priority;
  }

  get priorityWithDefault() {
    return this._value.priority || '';
  }

  get eventType() {
    return this._value.eventType;
  }

  eventTypeIsIn(values: EventTypeEnum[] = []) {
    return this._value.eventType && values.includes(this._value.eventType);
  }

  get alertCategory() {
    return this._value.alertCategory;
  }

  alertCategoryIsIn(values: AlertCategoryEnum[] = []) {
    return this._value.alertCategory && values.includes(this._value.alertCategory);
  }

  get openedCount() {
    return this._value.openedCount;
  }

  get openedCountWithDefault() {
    return this._value.openedCount || 0;
  }

  get totalCount() {
    return this._value.totalCount;
  }

  get totalCountWithDefault() {
    return this._value.totalCount || 0;
  }
}
export interface MedicalAlertSearchPatientAlertsParams {
	searchRequest: SearchRequest<PatientAlertSearchRequest>;
}

export interface MedicalAlertGetAlertsByMemberIdParams {
	memberId: string;
}

export interface MedicalAlertGetAlertHistoryByMemberIdParams {
	searchRequest: SearchRequest<MedicalAlert>;
}

export interface MedicalAlertResolveSmartAlertsParams {
	resolveAlertRequest: ResolveAlertRequest;
}

export interface MedicalAlertGetPatientAlertSummaryParams {
	memberId: string;
	triggeredOnTsStart: number;
	triggeredOnTsEnd: number;
}

export class MedicalAlertService extends BaseController<MedicalAlert, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/medical-alerts';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public searchPatientAlerts({ baseUrl, params, headers }: RequestOption<MedicalAlertSearchPatientAlertsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<PatientAlertRecord>>>(`${baseUrl || this.baseURL}${this.basePath}/search-alerts${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getAlertsByMemberId({ baseUrl, params, headers }: RequestOption<MedicalAlertGetAlertsByMemberIdParams>) {
		const queryList: QueryParam[] = [];
		if (params.memberId !== undefined && params.memberId !== null) {
			queryList.push({ name: 'memberId', value: params.memberId.toString() });
		}
		return axios.get<APIResponse<MedicalAlertResult[]>>(`${baseUrl || this.baseURL}${this.basePath}${getQueryStrings(queryList)}`, { headers });
	}

	public getAlertHistoryByMemberId({ baseUrl, params, headers }: RequestOption<MedicalAlertGetAlertHistoryByMemberIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<MedicalAlert>>>(`${baseUrl || this.baseURL}${this.basePath}/search-history${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public resolveSmartAlerts({ baseUrl, params, headers }: RequestOption<MedicalAlertResolveSmartAlertsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string[]>>(`${baseUrl || this.baseURL}${this.basePath}/resolve${getQueryStrings(queryList)}`, params.resolveAlertRequest, { headers });
	}

	public getPatientAlertSummary({ baseUrl, params, headers }: RequestOption<MedicalAlertGetPatientAlertSummaryParams>) {
		const queryList: QueryParam[] = [];
		if (params.triggeredOnTsStart !== undefined && params.triggeredOnTsStart !== null) {
			queryList.push({ name: 'triggeredOnTsStart', value: params.triggeredOnTsStart.toString() });
		}
		if (params.triggeredOnTsEnd !== undefined && params.triggeredOnTsEnd !== null) {
			queryList.push({ name: 'triggeredOnTsEnd', value: params.triggeredOnTsEnd.toString() });
		}
		return axios.get<APIResponse<MedicalAlertSummary[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/summary${getQueryStrings(queryList)}`, { headers });
	}
}

export const medicalAlertService = new MedicalAlertService();

export const createMedicalAlertSearchPatientAlertsObject = (value: APIResponse<PageResponse<PatientAlertRecord>>) => new APIResponseClass<PageResponseClass<PatientAlertRecordClass>>(value, (value: PageResponse<PatientAlertRecord>) => new PageResponseClass<PatientAlertRecordClass>(value, (value: PatientAlertRecord) => new PatientAlertRecordClass(value)));

export const useMedicalAlertSearchPatientAlerts = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalAlertSearchPatientAlertsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalAlertSearchPatientAlertsParams,
    APIResponse<PageResponse<PatientAlertRecord>>,
    typeof medicalAlertService.searchPatientAlerts,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientAlertRecordClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalAlertService.searchPatientAlerts>) => (
      medicalAlertService.searchPatientAlerts(...args)
    ),
    ResponseClassCreator: createMedicalAlertSearchPatientAlertsObject,
    typeName: 'useMedicalAlertSearchPatientAlerts',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalAlertSearchPatientAlerts.mock = () => new Error('Mock has not been implemented!');
useMedicalAlertSearchPatientAlerts.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalAlertSearchPatientAlerts.createObject = createMedicalAlertSearchPatientAlertsObject;

export const createMedicalAlertGetAlertsByMemberIdObject = (value: APIResponse<MedicalAlertResult[]>) => new APIResponseClass<MedicalAlertResultClass[]>(value, (v) => map(v, (value: MedicalAlertResult) => new MedicalAlertResultClass(value)));

export const useMedicalAlertGetAlertsByMemberId = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalAlertGetAlertsByMemberIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalAlertGetAlertsByMemberIdParams,
    APIResponse<MedicalAlertResult[]>,
    typeof medicalAlertService.getAlertsByMemberId,
    GetOptions,
    APIResponseClass<MedicalAlertResultClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof medicalAlertService.getAlertsByMemberId>) => (
      medicalAlertService.getAlertsByMemberId(...args)
    ),
    ResponseClassCreator: createMedicalAlertGetAlertsByMemberIdObject,
    typeName: 'useMedicalAlertGetAlertsByMemberId',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalAlertGetAlertsByMemberId.mock = () => new Error('Mock has not been implemented!');
useMedicalAlertGetAlertsByMemberId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalAlertGetAlertsByMemberId.createObject = createMedicalAlertGetAlertsByMemberIdObject;

export const createMedicalAlertGetAlertHistoryByMemberIdObject = (value: APIResponse<PageResponse<MedicalAlert>>) => new APIResponseClass<PageResponseClass<MedicalAlertClass>>(value, (value: PageResponse<MedicalAlert>) => new PageResponseClass<MedicalAlertClass>(value, (value: MedicalAlert) => new MedicalAlertClass(value)));

export const useMedicalAlertGetAlertHistoryByMemberId = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalAlertGetAlertHistoryByMemberIdParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalAlertGetAlertHistoryByMemberIdParams,
    APIResponse<PageResponse<MedicalAlert>>,
    typeof medicalAlertService.getAlertHistoryByMemberId,
    AjaxOptions,
    APIResponseClass<PageResponseClass<MedicalAlertClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalAlertService.getAlertHistoryByMemberId>) => (
      medicalAlertService.getAlertHistoryByMemberId(...args)
    ),
    ResponseClassCreator: createMedicalAlertGetAlertHistoryByMemberIdObject,
    typeName: 'useMedicalAlertGetAlertHistoryByMemberId',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalAlertGetAlertHistoryByMemberId.mock = () => new Error('Mock has not been implemented!');
useMedicalAlertGetAlertHistoryByMemberId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalAlertGetAlertHistoryByMemberId.createObject = createMedicalAlertGetAlertHistoryByMemberIdObject;

export const createMedicalAlertResolveSmartAlertsObject = (value: APIResponse<string[]>) => new APIResponseClass<string[]>(value, (v) => (v));

export const useMedicalAlertResolveSmartAlerts = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalAlertResolveSmartAlertsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalAlertResolveSmartAlertsParams,
    APIResponse<string[]>,
    typeof medicalAlertService.resolveSmartAlerts,
    AjaxOptions,
    APIResponseClass<string[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalAlertService.resolveSmartAlerts>) => (
      medicalAlertService.resolveSmartAlerts(...args)
    ),
    ResponseClassCreator: createMedicalAlertResolveSmartAlertsObject,
    typeName: 'useMedicalAlertResolveSmartAlerts',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalAlertResolveSmartAlerts.mock = () => new Error('Mock has not been implemented!');
useMedicalAlertResolveSmartAlerts.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalAlertResolveSmartAlerts.createObject = createMedicalAlertResolveSmartAlertsObject;

export const createMedicalAlertGetPatientAlertSummaryObject = (value: APIResponse<MedicalAlertSummary[]>) => new APIResponseClass<MedicalAlertSummaryClass[]>(value, (v) => map(v, (value: MedicalAlertSummary) => new MedicalAlertSummaryClass(value)));

export const useMedicalAlertGetPatientAlertSummary = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalAlertGetPatientAlertSummaryParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalAlertGetPatientAlertSummaryParams,
    APIResponse<MedicalAlertSummary[]>,
    typeof medicalAlertService.getPatientAlertSummary,
    GetOptions,
    APIResponseClass<MedicalAlertSummaryClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof medicalAlertService.getPatientAlertSummary>) => (
      medicalAlertService.getPatientAlertSummary(...args)
    ),
    ResponseClassCreator: createMedicalAlertGetPatientAlertSummaryObject,
    typeName: 'useMedicalAlertGetPatientAlertSummary',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalAlertGetPatientAlertSummary.mock = () => new Error('Mock has not been implemented!');
useMedicalAlertGetPatientAlertSummary.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalAlertGetPatientAlertSummary.createObject = createMedicalAlertGetPatientAlertSummaryObject;


export const createMedicalAlertGetObject = (value: APIResponse<MedicalAlert>) => new APIResponseClass<MedicalAlertClass>(value, (value: MedicalAlert) => new MedicalAlertClass(value));
export const useMedicalAlertGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<MedicalAlert>,
    // @ts-ignore
    typeof medicalAlertService.get,
    GetOptions,
    APIResponseClass<MedicalAlertClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof medicalAlertService.get>) => (
      medicalAlertService.get(...args)
    ),
    ResponseClassCreator: createMedicalAlertGetObject,
    typeName: 'useMedicalAlertGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMedicalAlertGet.mock = () => new Error('Mock has not been implemented!');
useMedicalAlertGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalAlertGet.createObject = createMedicalAlertGetObject;

export const createMedicalAlertUpdateObject = (value: APIResponse<MedicalAlert>) => new APIResponseClass<MedicalAlertClass>(value, (value: MedicalAlert) => new MedicalAlertClass(value));
export const useMedicalAlertUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, MedicalAlert>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, MedicalAlert>,
    APIResponse<MedicalAlert>,
    // @ts-ignore
    typeof medicalAlertService.update,
    AjaxOptions,
    APIResponseClass<MedicalAlertClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalAlertService.update>) => (
      medicalAlertService.update(...args)
    ),
    ResponseClassCreator: createMedicalAlertUpdateObject,
    typeName: 'useMedicalAlertUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMedicalAlertUpdate.mock = () => new Error('Mock has not been implemented!');
useMedicalAlertUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalAlertUpdate.createObject = createMedicalAlertUpdateObject;

export const createMedicalAlertInsertObject = (value: APIResponse<MedicalAlert>) => new APIResponseClass<MedicalAlertClass>(value, (value: MedicalAlert) => new MedicalAlertClass(value));
export const useMedicalAlertInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<MedicalAlert>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<MedicalAlert>,
    APIResponse<MedicalAlert>,
    // @ts-ignore
    typeof medicalAlertService.insert,
    AjaxOptions,
    APIResponseClass<MedicalAlertClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalAlertService.insert>) => (
      medicalAlertService.insert(...args)
    ),
    ResponseClassCreator: createMedicalAlertInsertObject,
    typeName: 'useMedicalAlertInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMedicalAlertInsert.mock = () => new Error('Mock has not been implemented!');
useMedicalAlertInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalAlertInsert.createObject = createMedicalAlertInsertObject;

export const createMedicalAlertDeleteObject = (value: APIResponse<MedicalAlert>) => new APIResponseClass<MedicalAlertClass>(value, (value: MedicalAlert) => new MedicalAlertClass(value));
export const useMedicalAlertDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<MedicalAlert>,
    // @ts-ignore
    typeof medicalAlertService.delete,
    AjaxOptions,
    APIResponseClass<MedicalAlertClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalAlertService.delete>) => (
      medicalAlertService.delete(...args)
    ),
    ResponseClassCreator: createMedicalAlertDeleteObject,
    typeName: 'useMedicalAlertDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMedicalAlertDelete.mock = () => new Error('Mock has not been implemented!');
useMedicalAlertDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalAlertDelete.createObject = createMedicalAlertDeleteObject;

export const createMedicalAlertSearchObject = (value: APIResponse<PageResponse<MedicalAlert>>) => new APIResponseClass<PageResponseClass<MedicalAlertClass>>(value, (value: PageResponse<MedicalAlert>) => new PageResponseClass<MedicalAlertClass>(value, (value: MedicalAlert) => new MedicalAlertClass(value)));
export const useMedicalAlertSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<MedicalAlert>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<MedicalAlert>,
    APIResponse<PageResponse<MedicalAlert>>,
    // @ts-ignore
    typeof medicalAlertService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<MedicalAlertClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalAlertService.search>) => (
      medicalAlertService.search(...args)
    ),
    ResponseClassCreator: createMedicalAlertSearchObject,
    typeName: 'useMedicalAlertSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMedicalAlertSearch.mock = () => new Error('Mock has not been implemented!');
useMedicalAlertSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalAlertSearch.createObject = createMedicalAlertSearchObject;

export interface MedicalOrganizationSearchRequest extends MedicalOrganization {
	careUnitIdIn?: Nullable<InFilter<string>>;
}
export enum CareUnitMedicalOrgRequest$Type {
	CARE_UNIT = 'CARE_UNIT',
	MEDICAL_ORGANIZATION = 'MEDICAL_ORGANIZATION',
	ALL = 'ALL',
}
export interface CareUnitMedicalOrgRequest {
	type?: Nullable<CareUnitMedicalOrgRequest$Type>;
	careUnit?: Nullable<UcOrganization>;
	medicalOrganization?: Nullable<MedicalOrganization>;
}
export interface AllAccessMedicalOrgRequest {
	medicalOrganization?: Nullable<MedicalOrganization>;
	ehr?: Nullable<string>;
	ehrUserId?: Nullable<string>;
	ehrOrgId?: Nullable<string>;
	ehrDepartmentId?: Nullable<string>;
	roleList?: Nullable<RoleTypeEnum[]>;
	onCall?: Nullable<boolean>;
}
export interface ClinicStaffWithRole {
	staffId?: Nullable<string>;
	roles?: Nullable<RoleTypeEnum[]>;
}
export interface MedicalOrganizationAddStaffRequest {
	medicalOrganizationId: string;
	staffRoleList?: Nullable<ClinicStaffWithRole[]>;
}
export interface MedicalOrganizationRemoveStaffRequest {
	medicalOrganizationId: string;
	staffId: string;
}
export interface MedicalOrganizationStaffFilter {
	nameNear?: Nullable<string>;
	roleIn?: Nullable<InFilter<RoleTypeEnum>>;
	medicalOrganizationId?: Nullable<string>;
}
export interface RemoveAssociationCheckResponse {
	id?: Nullable<string>;
	removable?: Nullable<boolean>;
}
export class RemoveAssociationCheckResponseClass {
  protected readonly _value: RemoveAssociationCheckResponse;

	constructor(value: RemoveAssociationCheckResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get removable() {
    return this._value.removable;
  }

  get removableWithDefault() {
    return this._value.removable || false;
  }
}
export interface MedicalOrgAssignmentMultiUpsertRequest {
	careGroupId?: Nullable<string>;
	medicalOrgIds?: Nullable<string[]>;
}
export interface AssociatedMedicalOrgSearchRequest {
	careUnitId?: Nullable<string>;
}
export interface RemoveRoleValidationRequest {
	memberId: string;
	medicalOrgOrCareUnitId?: Nullable<string>;
}
export interface CareGroupAssociatedMap {
	clinicIds?: Nullable<string[]>;
	careGroup?: Nullable<MiniOrganization>;
	limitedClinics?: Nullable<LimitedClinic[]>;
}
export interface RemoveRoleValidationResponse {
	roles?: Nullable<RoleTypeEnum[]>;
	limitedClinics?: Nullable<LimitedClinic[]>;
	careGroups?: Nullable<UcOrganization[]>;
	careGroupAssociatedMaps?: Nullable<CareGroupAssociatedMap[]>;
}
export class CareGroupAssociatedMapClass {
  protected readonly _value: CareGroupAssociatedMap;

	constructor(value: CareGroupAssociatedMap) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get clinicIds() {
    return this._value.clinicIds;
  }

  get clinicIdsWithDefault() {
    return this._value.clinicIds || [];
  }

  get careGroup() {
    return this._value.careGroup !== undefined && this._value.careGroup !== null ? new MiniOrganizationClass(this._value.careGroup) : this._value.careGroup;
  }

  get limitedClinics() {
    return this._value.limitedClinics !== undefined && this._value.limitedClinics !== null ? map(this._value.limitedClinics, (v) => new LimitedClinicClass(v)) : this._value.limitedClinics;
  }

  get limitedClinicsWithDefault() {
    return this._value.limitedClinics || [];
  }
}
export class RemoveRoleValidationResponseClass {
  protected readonly _value: RemoveRoleValidationResponse;

	constructor(value: RemoveRoleValidationResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get roles() {
    return this._value.roles;
  }

  get rolesWithDefault() {
    return this._value.roles || [];
  }

  get limitedClinics() {
    return this._value.limitedClinics !== undefined && this._value.limitedClinics !== null ? map(this._value.limitedClinics, (v) => new LimitedClinicClass(v)) : this._value.limitedClinics;
  }

  get limitedClinicsWithDefault() {
    return this._value.limitedClinics || [];
  }

  get careGroups() {
    return this._value.careGroups !== undefined && this._value.careGroups !== null ? map(this._value.careGroups, (v) => new UcOrganizationClass(v)) : this._value.careGroups;
  }

  get careGroupsWithDefault() {
    return this._value.careGroups || [];
  }

  get careGroupAssociatedMaps() {
    return this._value.careGroupAssociatedMaps !== undefined && this._value.careGroupAssociatedMaps !== null ? map(this._value.careGroupAssociatedMaps, (v) => new CareGroupAssociatedMapClass(v)) : this._value.careGroupAssociatedMaps;
  }

  get careGroupAssociatedMapsWithDefault() {
    return this._value.careGroupAssociatedMaps || [];
  }
}
export interface UploadRequest {
	content?: Nullable<string>;
	fileName?: Nullable<string>;
}
export interface MedicalOrganizationGetConfigurationParams {
	id: string;
}

export interface MedicalOrganizationSearchListParams {
	searchRequest: SearchRequest<MedicalOrganizationSearchRequest>;
}

export interface MedicalOrganizationUpdateOutstandingConfigParams {
	id: string;
	configUpdate: CareServiceConfig;
}

export interface MedicalOrganizationLogoDownloadUrlParams {
	avatarDownloadUrlInput: AvatarDownloadUrlInput;
}

export interface MedicalOrganizationLogoUploadParams {
	id: string;
	photo: MultipartFile;
}

export interface MedicalOrganizationLogoDeleteParams {
	id: string;
}

export interface MedicalOrganizationInitiatedTerminateMedicalOrgParams {
	medicalOrgId: string;
}

export interface MedicalOrganizationSearchCareUnitMedicalOrgParams {
	request: CareUnitMedicalOrgRequest;
}

export interface MedicalOrganizationSearchLinkedAccessMedicalOrgParams {
	searchRequest: SearchRequest<AllAccessMedicalOrgRequest>;
}

export interface MedicalOrganizationGetMedicalOrgAssignmentsParams {
	id: string;
}

export interface MedicalOrganizationGetMedicalOrgAssignmentsByIdParams {
	assignmentId: string;
}

export interface MedicalOrganizationAddStaffParams {
	request: MedicalOrganizationAddStaffRequest;
}

export interface MedicalOrganizationRemoveStaffParams {
	request: MedicalOrganizationRemoveStaffRequest;
}

export interface MedicalOrganizationSearchStaffParams {
	searchRequest: SearchRequest<MedicalOrganizationStaffFilter>;
}

export interface MedicalOrganizationUpdateStaffRoleParams {
	request: MedicalOrganizationAddStaffRequest;
}

export interface MedicalOrganizationSearchAllAccessMedicalOrgParams {
	searchRequest: SearchRequest<AllAccessMedicalOrgRequest>;
}

export interface MedicalOrganizationSearchAllMedicalOrgParams {
	searchRequest: SearchRequest<MedicalOrganization>;
}

export interface MedicalOrganizationSearchPriorAuthEnabledMedicalOrgParams {
	searchRequest: SearchRequest<MedicalOrganization>;
}

export interface MedicalOrganizationUpsertAssignmentParams {
	t: MedicalOrganizationAssignment;
}

export interface MedicalOrganizationRemoveAssociationCheckParams {
	ids: string[];
}

export interface MedicalOrganizationRemoveMedicalOrgFromUserCheckParams {
	userId: string;
	ids: string[];
}

export interface MedicalOrganizationMultiUpsertAssignmentsParams {
	request: MedicalOrgAssignmentMultiUpsertRequest;
}

export interface MedicalOrganizationSearchAssociatedMedicalOrgParams {
	searchRequest: SearchRequest<AssociatedMedicalOrgSearchRequest>;
}

export interface MedicalOrganizationMedicalOrganizationAssignmentSearchParams {
	searchRequest: SearchRequest<MedicalOrganizationAssignment>;
}

export interface MedicalOrganizationRemoveStaffCheckParams {
	request: RemoveRoleValidationRequest;
}

export interface MedicalOrganizationUploadConsentParams {
	medicalOrgId: string;
	request: UploadRequest;
}

export class MedicalOrganizationService extends BaseController<MedicalOrganization, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/medical-organization';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getConfiguration({ baseUrl, params, headers }: RequestOption<MedicalOrganizationGetConfigurationParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<MedicalOrganization>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/configuration${getQueryStrings(queryList)}`, { headers });
	}

	public searchList({ baseUrl, params, headers }: RequestOption<MedicalOrganizationSearchListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<MedicalOrganization>>>(`${baseUrl || this.baseURL}${this.basePath}/search-list${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public updateOutstandingConfig({ baseUrl, params, headers }: RequestOption<MedicalOrganizationUpdateOutstandingConfigParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/configuration/outstanding${getQueryStrings(queryList)}`, params.configUpdate, { headers });
	}

	public logoDownloadUrl({ baseUrl, params, headers }: RequestOption<MedicalOrganizationLogoDownloadUrlParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<AvatarDownloadUrlOutput>>(`${baseUrl || this.baseURL}${this.basePath}/logo/download-url${getQueryStrings(queryList)}`, params.avatarDownloadUrlInput, { headers });
	}

	public logoUpload({ baseUrl, params, headers }: RequestOption<MedicalOrganizationLogoUploadParams>) {
		const queryList: QueryParam[] = [];
		if (params.photo !== undefined && params.photo !== null) {
			queryList.push({ name: 'photo', value: params.photo.toString() });
		}
		return axios.put<APIResponse<CoBrandingLogoModification>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/configuration/co-brand-logo${getQueryStrings(queryList)}`, {}, { headers });
	}

	public logoDelete({ baseUrl, params, headers }: RequestOption<MedicalOrganizationLogoDeleteParams>) {
		const queryList: QueryParam[] = [];
		return axios.delete<APIResponse<CoBrandingLogoModification>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/configuration/co-brand-logo${getQueryStrings(queryList)}`, { headers });
	}

	public initiatedTerminateMedicalOrg({ baseUrl, params, headers }: RequestOption<MedicalOrganizationInitiatedTerminateMedicalOrgParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<MedicalOrganization>>(`${baseUrl || this.baseURL}${this.basePath}/${params.medicalOrgId}/initiate-termination${getQueryStrings(queryList)}`, {}, { headers });
	}

	public searchCareUnitMedicalOrg({ baseUrl, params, headers }: RequestOption<MedicalOrganizationSearchCareUnitMedicalOrgParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<MiniOrganization[]>>(`${baseUrl || this.baseURL}${this.basePath}/search-care-unit-medical-org${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public searchLinkedAccessMedicalOrg({ baseUrl, params, headers }: RequestOption<MedicalOrganizationSearchLinkedAccessMedicalOrgParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<LimitedClinic>>>(`${baseUrl || this.baseURL}${this.basePath}/search-linked-access-medical-org${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getMedicalOrgAssignments({ baseUrl, params, headers }: RequestOption<MedicalOrganizationGetMedicalOrgAssignmentsParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<MedicalOrganizationAssignment>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/assignments${getQueryStrings(queryList)}`, { headers });
	}

	public getMedicalOrgAssignmentsById({ baseUrl, params, headers }: RequestOption<MedicalOrganizationGetMedicalOrgAssignmentsByIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<MedicalOrganizationAssignment>>(`${baseUrl || this.baseURL}${this.basePath}/assignments/${params.assignmentId}${getQueryStrings(queryList)}`, { headers });
	}

	public addStaff({ baseUrl, params, headers }: RequestOption<MedicalOrganizationAddStaffParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<MedicalOrganization>>(`${baseUrl || this.baseURL}${this.basePath}/add-staff${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public removeStaff({ baseUrl, params, headers }: RequestOption<MedicalOrganizationRemoveStaffParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<MedicalOrganization>>(`${baseUrl || this.baseURL}${this.basePath}/remove-staff${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public searchStaff({ baseUrl, params, headers }: RequestOption<MedicalOrganizationSearchStaffParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<Employee>>>(`${baseUrl || this.baseURL}${this.basePath}/search-staff${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public updateStaffRole({ baseUrl, params, headers }: RequestOption<MedicalOrganizationUpdateStaffRoleParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<Employee>>(`${baseUrl || this.baseURL}${this.basePath}/update-staff-role${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public searchAllAccessMedicalOrg({ baseUrl, params, headers }: RequestOption<MedicalOrganizationSearchAllAccessMedicalOrgParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<LimitedClinic>>>(`${baseUrl || this.baseURL}${this.basePath}/search-all-access-medical-org${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public searchAllMedicalOrg({ baseUrl, params, headers }: RequestOption<MedicalOrganizationSearchAllMedicalOrgParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<LimitedClinic>>>(`${baseUrl || this.baseURL}${this.basePath}/search-all-medical-org${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public searchPriorAuthEnabledMedicalOrg({ baseUrl, params, headers }: RequestOption<MedicalOrganizationSearchPriorAuthEnabledMedicalOrgParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<LimitedClinic>>>(`${baseUrl || this.baseURL}${this.basePath}/search-prior-auth-enabled-medical-org${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public upsertAssignment({ baseUrl, params, headers }: RequestOption<MedicalOrganizationUpsertAssignmentParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<MedicalOrganizationAssignment>>(`${baseUrl || this.baseURL}${this.basePath}/assignments${getQueryStrings(queryList)}`, params.t, { headers });
	}

	public removeAssociationCheck({ baseUrl, params, headers }: RequestOption<MedicalOrganizationRemoveAssociationCheckParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<RemoveAssociationCheckResponse[]>>(`${baseUrl || this.baseURL}${this.basePath}/assignments/remove-association-check${getQueryStrings(queryList)}`, params.ids, { headers });
	}

	public removeMedicalOrgFromUserCheck({ baseUrl, params, headers }: RequestOption<MedicalOrganizationRemoveMedicalOrgFromUserCheckParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<RemoveAssociationCheckResponse[]>>(`${baseUrl || this.baseURL}${this.basePath}/assignments/remove-medical-org-from-user-check/${params.userId}${getQueryStrings(queryList)}`, params.ids, { headers });
	}

	public multiUpsertAssignments({ baseUrl, params, headers }: RequestOption<MedicalOrganizationMultiUpsertAssignmentsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<MedicalOrganizationAssignment[]>>(`${baseUrl || this.baseURL}${this.basePath}/assignments/multi-upsert${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public searchAssociatedMedicalOrg({ baseUrl, params, headers }: RequestOption<MedicalOrganizationSearchAssociatedMedicalOrgParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<MedicalOrganizationAssignment[]>>(`${baseUrl || this.baseURL}${this.basePath}/assignments/associated-med-org${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public medicalOrganizationAssignmentSearch({ baseUrl, params, headers }: RequestOption<MedicalOrganizationMedicalOrganizationAssignmentSearchParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<MedicalOrganizationAssignment>>>(`${baseUrl || this.baseURL}${this.basePath}/assignments/search${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public removeStaffCheck({ baseUrl, params, headers }: RequestOption<MedicalOrganizationRemoveStaffCheckParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<RemoveRoleValidationResponse[]>>(`${baseUrl || this.baseURL}${this.basePath}/remove-role-check${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public uploadConsent({ baseUrl, params, headers }: RequestOption<MedicalOrganizationUploadConsentParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<FileInfo>>(`${baseUrl || this.baseURL}${this.basePath}/${params.medicalOrgId}/upload-consent${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const medicalOrganizationService = new MedicalOrganizationService();

export const createMedicalOrganizationGetConfigurationObject = (value: APIResponse<MedicalOrganization>) => new APIResponseClass<MedicalOrganizationClass>(value, (value: MedicalOrganization) => new MedicalOrganizationClass(value));

export const useMedicalOrganizationGetConfiguration = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationGetConfigurationParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationGetConfigurationParams,
    APIResponse<MedicalOrganization>,
    typeof medicalOrganizationService.getConfiguration,
    GetOptions,
    APIResponseClass<MedicalOrganizationClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.getConfiguration>) => (
      medicalOrganizationService.getConfiguration(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationGetConfigurationObject,
    typeName: 'useMedicalOrganizationGetConfiguration',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationGetConfiguration.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationGetConfiguration.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationGetConfiguration.createObject = createMedicalOrganizationGetConfigurationObject;

export const createMedicalOrganizationSearchListObject = (value: APIResponse<PageResponse<MedicalOrganization>>) => new APIResponseClass<PageResponseClass<MedicalOrganizationClass>>(value, (value: PageResponse<MedicalOrganization>) => new PageResponseClass<MedicalOrganizationClass>(value, (value: MedicalOrganization) => new MedicalOrganizationClass(value)));

export const useMedicalOrganizationSearchList = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationSearchListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationSearchListParams,
    APIResponse<PageResponse<MedicalOrganization>>,
    typeof medicalOrganizationService.searchList,
    AjaxOptions,
    APIResponseClass<PageResponseClass<MedicalOrganizationClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.searchList>) => (
      medicalOrganizationService.searchList(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationSearchListObject,
    typeName: 'useMedicalOrganizationSearchList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationSearchList.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearchList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearchList.createObject = createMedicalOrganizationSearchListObject;

export const createMedicalOrganizationUpdateOutstandingConfigObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useMedicalOrganizationUpdateOutstandingConfig = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationUpdateOutstandingConfigParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationUpdateOutstandingConfigParams,
    APIResponse<string>,
    typeof medicalOrganizationService.updateOutstandingConfig,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.updateOutstandingConfig>) => (
      medicalOrganizationService.updateOutstandingConfig(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationUpdateOutstandingConfigObject,
    typeName: 'useMedicalOrganizationUpdateOutstandingConfig',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationUpdateOutstandingConfig.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationUpdateOutstandingConfig.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationUpdateOutstandingConfig.createObject = createMedicalOrganizationUpdateOutstandingConfigObject;

export const createMedicalOrganizationLogoDownloadUrlObject = (value: APIResponse<AvatarDownloadUrlOutput>) => new APIResponseClass<AvatarDownloadUrlOutputClass>(value, (value: AvatarDownloadUrlOutput) => new AvatarDownloadUrlOutputClass(value));

export const useMedicalOrganizationLogoDownloadUrl = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationLogoDownloadUrlParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationLogoDownloadUrlParams,
    APIResponse<AvatarDownloadUrlOutput>,
    typeof medicalOrganizationService.logoDownloadUrl,
    AjaxOptions,
    APIResponseClass<AvatarDownloadUrlOutputClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.logoDownloadUrl>) => (
      medicalOrganizationService.logoDownloadUrl(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationLogoDownloadUrlObject,
    typeName: 'useMedicalOrganizationLogoDownloadUrl',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationLogoDownloadUrl.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationLogoDownloadUrl.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationLogoDownloadUrl.createObject = createMedicalOrganizationLogoDownloadUrlObject;

export const createMedicalOrganizationLogoUploadObject = (value: APIResponse<CoBrandingLogoModification>) => new APIResponseClass<CoBrandingLogoModificationClass>(value, (value: CoBrandingLogoModification) => new CoBrandingLogoModificationClass(value));

export const useMedicalOrganizationLogoUpload = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationLogoUploadParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationLogoUploadParams,
    APIResponse<CoBrandingLogoModification>,
    typeof medicalOrganizationService.logoUpload,
    AjaxOptions,
    APIResponseClass<CoBrandingLogoModificationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.logoUpload>) => (
      medicalOrganizationService.logoUpload(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationLogoUploadObject,
    typeName: 'useMedicalOrganizationLogoUpload',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationLogoUpload.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationLogoUpload.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationLogoUpload.createObject = createMedicalOrganizationLogoUploadObject;

export const createMedicalOrganizationLogoDeleteObject = (value: APIResponse<CoBrandingLogoModification>) => new APIResponseClass<CoBrandingLogoModificationClass>(value, (value: CoBrandingLogoModification) => new CoBrandingLogoModificationClass(value));

export const useMedicalOrganizationLogoDelete = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationLogoDeleteParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationLogoDeleteParams,
    APIResponse<CoBrandingLogoModification>,
    typeof medicalOrganizationService.logoDelete,
    AjaxOptions,
    APIResponseClass<CoBrandingLogoModificationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.logoDelete>) => (
      medicalOrganizationService.logoDelete(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationLogoDeleteObject,
    typeName: 'useMedicalOrganizationLogoDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationLogoDelete.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationLogoDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationLogoDelete.createObject = createMedicalOrganizationLogoDeleteObject;

export const createMedicalOrganizationInitiatedTerminateMedicalOrgObject = (value: APIResponse<MedicalOrganization>) => new APIResponseClass<MedicalOrganizationClass>(value, (value: MedicalOrganization) => new MedicalOrganizationClass(value));

export const useMedicalOrganizationInitiatedTerminateMedicalOrg = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationInitiatedTerminateMedicalOrgParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationInitiatedTerminateMedicalOrgParams,
    APIResponse<MedicalOrganization>,
    typeof medicalOrganizationService.initiatedTerminateMedicalOrg,
    AjaxOptions,
    APIResponseClass<MedicalOrganizationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.initiatedTerminateMedicalOrg>) => (
      medicalOrganizationService.initiatedTerminateMedicalOrg(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationInitiatedTerminateMedicalOrgObject,
    typeName: 'useMedicalOrganizationInitiatedTerminateMedicalOrg',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationInitiatedTerminateMedicalOrg.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationInitiatedTerminateMedicalOrg.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationInitiatedTerminateMedicalOrg.createObject = createMedicalOrganizationInitiatedTerminateMedicalOrgObject;

export const createMedicalOrganizationSearchCareUnitMedicalOrgObject = (value: APIResponse<MiniOrganization[]>) => new APIResponseClass<MiniOrganizationClass[]>(value, (v) => map(v, (value: MiniOrganization) => new MiniOrganizationClass(value)));

export const useMedicalOrganizationSearchCareUnitMedicalOrg = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationSearchCareUnitMedicalOrgParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationSearchCareUnitMedicalOrgParams,
    APIResponse<MiniOrganization[]>,
    typeof medicalOrganizationService.searchCareUnitMedicalOrg,
    AjaxOptions,
    APIResponseClass<MiniOrganizationClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.searchCareUnitMedicalOrg>) => (
      medicalOrganizationService.searchCareUnitMedicalOrg(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationSearchCareUnitMedicalOrgObject,
    typeName: 'useMedicalOrganizationSearchCareUnitMedicalOrg',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationSearchCareUnitMedicalOrg.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearchCareUnitMedicalOrg.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearchCareUnitMedicalOrg.createObject = createMedicalOrganizationSearchCareUnitMedicalOrgObject;

export const createMedicalOrganizationSearchLinkedAccessMedicalOrgObject = (value: APIResponse<PageResponse<LimitedClinic>>) => new APIResponseClass<PageResponseClass<LimitedClinicClass>>(value, (value: PageResponse<LimitedClinic>) => new PageResponseClass<LimitedClinicClass>(value, (value: LimitedClinic) => new LimitedClinicClass(value)));

export const useMedicalOrganizationSearchLinkedAccessMedicalOrg = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationSearchLinkedAccessMedicalOrgParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationSearchLinkedAccessMedicalOrgParams,
    APIResponse<PageResponse<LimitedClinic>>,
    typeof medicalOrganizationService.searchLinkedAccessMedicalOrg,
    AjaxOptions,
    APIResponseClass<PageResponseClass<LimitedClinicClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.searchLinkedAccessMedicalOrg>) => (
      medicalOrganizationService.searchLinkedAccessMedicalOrg(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationSearchLinkedAccessMedicalOrgObject,
    typeName: 'useMedicalOrganizationSearchLinkedAccessMedicalOrg',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationSearchLinkedAccessMedicalOrg.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearchLinkedAccessMedicalOrg.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearchLinkedAccessMedicalOrg.createObject = createMedicalOrganizationSearchLinkedAccessMedicalOrgObject;

export const createMedicalOrganizationGetMedicalOrgAssignmentsObject = (value: APIResponse<MedicalOrganizationAssignment>) => new APIResponseClass<MedicalOrganizationAssignmentClass>(value, (value: MedicalOrganizationAssignment) => new MedicalOrganizationAssignmentClass(value));

export const useMedicalOrganizationGetMedicalOrgAssignments = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationGetMedicalOrgAssignmentsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationGetMedicalOrgAssignmentsParams,
    APIResponse<MedicalOrganizationAssignment>,
    typeof medicalOrganizationService.getMedicalOrgAssignments,
    GetOptions,
    APIResponseClass<MedicalOrganizationAssignmentClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.getMedicalOrgAssignments>) => (
      medicalOrganizationService.getMedicalOrgAssignments(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationGetMedicalOrgAssignmentsObject,
    typeName: 'useMedicalOrganizationGetMedicalOrgAssignments',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationGetMedicalOrgAssignments.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationGetMedicalOrgAssignments.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationGetMedicalOrgAssignments.createObject = createMedicalOrganizationGetMedicalOrgAssignmentsObject;

export const createMedicalOrganizationGetMedicalOrgAssignmentsByIdObject = (value: APIResponse<MedicalOrganizationAssignment>) => new APIResponseClass<MedicalOrganizationAssignmentClass>(value, (value: MedicalOrganizationAssignment) => new MedicalOrganizationAssignmentClass(value));

export const useMedicalOrganizationGetMedicalOrgAssignmentsById = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationGetMedicalOrgAssignmentsByIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationGetMedicalOrgAssignmentsByIdParams,
    APIResponse<MedicalOrganizationAssignment>,
    typeof medicalOrganizationService.getMedicalOrgAssignmentsById,
    GetOptions,
    APIResponseClass<MedicalOrganizationAssignmentClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.getMedicalOrgAssignmentsById>) => (
      medicalOrganizationService.getMedicalOrgAssignmentsById(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationGetMedicalOrgAssignmentsByIdObject,
    typeName: 'useMedicalOrganizationGetMedicalOrgAssignmentsById',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationGetMedicalOrgAssignmentsById.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationGetMedicalOrgAssignmentsById.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationGetMedicalOrgAssignmentsById.createObject = createMedicalOrganizationGetMedicalOrgAssignmentsByIdObject;

export const createMedicalOrganizationAddStaffObject = (value: APIResponse<MedicalOrganization>) => new APIResponseClass<MedicalOrganizationClass>(value, (value: MedicalOrganization) => new MedicalOrganizationClass(value));

export const useMedicalOrganizationAddStaff = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationAddStaffParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationAddStaffParams,
    APIResponse<MedicalOrganization>,
    typeof medicalOrganizationService.addStaff,
    AjaxOptions,
    APIResponseClass<MedicalOrganizationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.addStaff>) => (
      medicalOrganizationService.addStaff(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationAddStaffObject,
    typeName: 'useMedicalOrganizationAddStaff',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationAddStaff.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationAddStaff.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationAddStaff.createObject = createMedicalOrganizationAddStaffObject;

export const createMedicalOrganizationRemoveStaffObject = (value: APIResponse<MedicalOrganization>) => new APIResponseClass<MedicalOrganizationClass>(value, (value: MedicalOrganization) => new MedicalOrganizationClass(value));

export const useMedicalOrganizationRemoveStaff = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationRemoveStaffParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationRemoveStaffParams,
    APIResponse<MedicalOrganization>,
    typeof medicalOrganizationService.removeStaff,
    AjaxOptions,
    APIResponseClass<MedicalOrganizationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.removeStaff>) => (
      medicalOrganizationService.removeStaff(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationRemoveStaffObject,
    typeName: 'useMedicalOrganizationRemoveStaff',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationRemoveStaff.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationRemoveStaff.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationRemoveStaff.createObject = createMedicalOrganizationRemoveStaffObject;

export const createMedicalOrganizationSearchStaffObject = (value: APIResponse<PageResponse<Employee>>) => new APIResponseClass<PageResponseClass<EmployeeClass>>(value, (value: PageResponse<Employee>) => new PageResponseClass<EmployeeClass>(value, (value: Employee) => new EmployeeClass(value)));

export const useMedicalOrganizationSearchStaff = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationSearchStaffParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationSearchStaffParams,
    APIResponse<PageResponse<Employee>>,
    typeof medicalOrganizationService.searchStaff,
    AjaxOptions,
    APIResponseClass<PageResponseClass<EmployeeClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.searchStaff>) => (
      medicalOrganizationService.searchStaff(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationSearchStaffObject,
    typeName: 'useMedicalOrganizationSearchStaff',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationSearchStaff.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearchStaff.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearchStaff.createObject = createMedicalOrganizationSearchStaffObject;

export const createMedicalOrganizationUpdateStaffRoleObject = (value: APIResponse<Employee>) => new APIResponseClass<EmployeeClass>(value, (value: Employee) => new EmployeeClass(value));

export const useMedicalOrganizationUpdateStaffRole = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationUpdateStaffRoleParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationUpdateStaffRoleParams,
    APIResponse<Employee>,
    typeof medicalOrganizationService.updateStaffRole,
    AjaxOptions,
    APIResponseClass<EmployeeClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.updateStaffRole>) => (
      medicalOrganizationService.updateStaffRole(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationUpdateStaffRoleObject,
    typeName: 'useMedicalOrganizationUpdateStaffRole',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationUpdateStaffRole.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationUpdateStaffRole.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationUpdateStaffRole.createObject = createMedicalOrganizationUpdateStaffRoleObject;

export const createMedicalOrganizationSearchAllAccessMedicalOrgObject = (value: APIResponse<PageResponse<LimitedClinic>>) => new APIResponseClass<PageResponseClass<LimitedClinicClass>>(value, (value: PageResponse<LimitedClinic>) => new PageResponseClass<LimitedClinicClass>(value, (value: LimitedClinic) => new LimitedClinicClass(value)));

export const useMedicalOrganizationSearchAllAccessMedicalOrg = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationSearchAllAccessMedicalOrgParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationSearchAllAccessMedicalOrgParams,
    APIResponse<PageResponse<LimitedClinic>>,
    typeof medicalOrganizationService.searchAllAccessMedicalOrg,
    AjaxOptions,
    APIResponseClass<PageResponseClass<LimitedClinicClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.searchAllAccessMedicalOrg>) => (
      medicalOrganizationService.searchAllAccessMedicalOrg(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationSearchAllAccessMedicalOrgObject,
    typeName: 'useMedicalOrganizationSearchAllAccessMedicalOrg',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationSearchAllAccessMedicalOrg.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearchAllAccessMedicalOrg.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearchAllAccessMedicalOrg.createObject = createMedicalOrganizationSearchAllAccessMedicalOrgObject;

export const createMedicalOrganizationSearchAllMedicalOrgObject = (value: APIResponse<PageResponse<LimitedClinic>>) => new APIResponseClass<PageResponseClass<LimitedClinicClass>>(value, (value: PageResponse<LimitedClinic>) => new PageResponseClass<LimitedClinicClass>(value, (value: LimitedClinic) => new LimitedClinicClass(value)));

export const useMedicalOrganizationSearchAllMedicalOrg = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationSearchAllMedicalOrgParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationSearchAllMedicalOrgParams,
    APIResponse<PageResponse<LimitedClinic>>,
    typeof medicalOrganizationService.searchAllMedicalOrg,
    AjaxOptions,
    APIResponseClass<PageResponseClass<LimitedClinicClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.searchAllMedicalOrg>) => (
      medicalOrganizationService.searchAllMedicalOrg(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationSearchAllMedicalOrgObject,
    typeName: 'useMedicalOrganizationSearchAllMedicalOrg',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationSearchAllMedicalOrg.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearchAllMedicalOrg.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearchAllMedicalOrg.createObject = createMedicalOrganizationSearchAllMedicalOrgObject;

export const createMedicalOrganizationSearchPriorAuthEnabledMedicalOrgObject = (value: APIResponse<PageResponse<LimitedClinic>>) => new APIResponseClass<PageResponseClass<LimitedClinicClass>>(value, (value: PageResponse<LimitedClinic>) => new PageResponseClass<LimitedClinicClass>(value, (value: LimitedClinic) => new LimitedClinicClass(value)));

export const useMedicalOrganizationSearchPriorAuthEnabledMedicalOrg = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationSearchPriorAuthEnabledMedicalOrgParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationSearchPriorAuthEnabledMedicalOrgParams,
    APIResponse<PageResponse<LimitedClinic>>,
    typeof medicalOrganizationService.searchPriorAuthEnabledMedicalOrg,
    AjaxOptions,
    APIResponseClass<PageResponseClass<LimitedClinicClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.searchPriorAuthEnabledMedicalOrg>) => (
      medicalOrganizationService.searchPriorAuthEnabledMedicalOrg(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationSearchPriorAuthEnabledMedicalOrgObject,
    typeName: 'useMedicalOrganizationSearchPriorAuthEnabledMedicalOrg',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationSearchPriorAuthEnabledMedicalOrg.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearchPriorAuthEnabledMedicalOrg.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearchPriorAuthEnabledMedicalOrg.createObject = createMedicalOrganizationSearchPriorAuthEnabledMedicalOrgObject;

export const createMedicalOrganizationUpsertAssignmentObject = (value: APIResponse<MedicalOrganizationAssignment>) => new APIResponseClass<MedicalOrganizationAssignmentClass>(value, (value: MedicalOrganizationAssignment) => new MedicalOrganizationAssignmentClass(value));

export const useMedicalOrganizationUpsertAssignment = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationUpsertAssignmentParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationUpsertAssignmentParams,
    APIResponse<MedicalOrganizationAssignment>,
    typeof medicalOrganizationService.upsertAssignment,
    AjaxOptions,
    APIResponseClass<MedicalOrganizationAssignmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.upsertAssignment>) => (
      medicalOrganizationService.upsertAssignment(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationUpsertAssignmentObject,
    typeName: 'useMedicalOrganizationUpsertAssignment',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationUpsertAssignment.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationUpsertAssignment.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationUpsertAssignment.createObject = createMedicalOrganizationUpsertAssignmentObject;

export const createMedicalOrganizationRemoveAssociationCheckObject = (value: APIResponse<RemoveAssociationCheckResponse[]>) => new APIResponseClass<RemoveAssociationCheckResponseClass[]>(value, (v) => map(v, (value: RemoveAssociationCheckResponse) => new RemoveAssociationCheckResponseClass(value)));

export const useMedicalOrganizationRemoveAssociationCheck = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationRemoveAssociationCheckParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationRemoveAssociationCheckParams,
    APIResponse<RemoveAssociationCheckResponse[]>,
    typeof medicalOrganizationService.removeAssociationCheck,
    AjaxOptions,
    APIResponseClass<RemoveAssociationCheckResponseClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.removeAssociationCheck>) => (
      medicalOrganizationService.removeAssociationCheck(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationRemoveAssociationCheckObject,
    typeName: 'useMedicalOrganizationRemoveAssociationCheck',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationRemoveAssociationCheck.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationRemoveAssociationCheck.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationRemoveAssociationCheck.createObject = createMedicalOrganizationRemoveAssociationCheckObject;

export const createMedicalOrganizationRemoveMedicalOrgFromUserCheckObject = (value: APIResponse<RemoveAssociationCheckResponse[]>) => new APIResponseClass<RemoveAssociationCheckResponseClass[]>(value, (v) => map(v, (value: RemoveAssociationCheckResponse) => new RemoveAssociationCheckResponseClass(value)));

export const useMedicalOrganizationRemoveMedicalOrgFromUserCheck = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationRemoveMedicalOrgFromUserCheckParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationRemoveMedicalOrgFromUserCheckParams,
    APIResponse<RemoveAssociationCheckResponse[]>,
    typeof medicalOrganizationService.removeMedicalOrgFromUserCheck,
    AjaxOptions,
    APIResponseClass<RemoveAssociationCheckResponseClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.removeMedicalOrgFromUserCheck>) => (
      medicalOrganizationService.removeMedicalOrgFromUserCheck(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationRemoveMedicalOrgFromUserCheckObject,
    typeName: 'useMedicalOrganizationRemoveMedicalOrgFromUserCheck',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationRemoveMedicalOrgFromUserCheck.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationRemoveMedicalOrgFromUserCheck.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationRemoveMedicalOrgFromUserCheck.createObject = createMedicalOrganizationRemoveMedicalOrgFromUserCheckObject;

export const createMedicalOrganizationMultiUpsertAssignmentsObject = (value: APIResponse<MedicalOrganizationAssignment[]>) => new APIResponseClass<MedicalOrganizationAssignmentClass[]>(value, (v) => map(v, (value: MedicalOrganizationAssignment) => new MedicalOrganizationAssignmentClass(value)));

export const useMedicalOrganizationMultiUpsertAssignments = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationMultiUpsertAssignmentsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationMultiUpsertAssignmentsParams,
    APIResponse<MedicalOrganizationAssignment[]>,
    typeof medicalOrganizationService.multiUpsertAssignments,
    AjaxOptions,
    APIResponseClass<MedicalOrganizationAssignmentClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.multiUpsertAssignments>) => (
      medicalOrganizationService.multiUpsertAssignments(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationMultiUpsertAssignmentsObject,
    typeName: 'useMedicalOrganizationMultiUpsertAssignments',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationMultiUpsertAssignments.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationMultiUpsertAssignments.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationMultiUpsertAssignments.createObject = createMedicalOrganizationMultiUpsertAssignmentsObject;

export const createMedicalOrganizationSearchAssociatedMedicalOrgObject = (value: APIResponse<MedicalOrganizationAssignment[]>) => new APIResponseClass<MedicalOrganizationAssignmentClass[]>(value, (v) => map(v, (value: MedicalOrganizationAssignment) => new MedicalOrganizationAssignmentClass(value)));

export const useMedicalOrganizationSearchAssociatedMedicalOrg = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationSearchAssociatedMedicalOrgParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationSearchAssociatedMedicalOrgParams,
    APIResponse<MedicalOrganizationAssignment[]>,
    typeof medicalOrganizationService.searchAssociatedMedicalOrg,
    AjaxOptions,
    APIResponseClass<MedicalOrganizationAssignmentClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.searchAssociatedMedicalOrg>) => (
      medicalOrganizationService.searchAssociatedMedicalOrg(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationSearchAssociatedMedicalOrgObject,
    typeName: 'useMedicalOrganizationSearchAssociatedMedicalOrg',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationSearchAssociatedMedicalOrg.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearchAssociatedMedicalOrg.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearchAssociatedMedicalOrg.createObject = createMedicalOrganizationSearchAssociatedMedicalOrgObject;

export const createMedicalOrganizationMedicalOrganizationAssignmentSearchObject = (value: APIResponse<PageResponse<MedicalOrganizationAssignment>>) => new APIResponseClass<PageResponseClass<MedicalOrganizationAssignmentClass>>(value, (value: PageResponse<MedicalOrganizationAssignment>) => new PageResponseClass<MedicalOrganizationAssignmentClass>(value, (value: MedicalOrganizationAssignment) => new MedicalOrganizationAssignmentClass(value)));

export const useMedicalOrganizationMedicalOrganizationAssignmentSearch = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationMedicalOrganizationAssignmentSearchParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationMedicalOrganizationAssignmentSearchParams,
    APIResponse<PageResponse<MedicalOrganizationAssignment>>,
    typeof medicalOrganizationService.medicalOrganizationAssignmentSearch,
    AjaxOptions,
    APIResponseClass<PageResponseClass<MedicalOrganizationAssignmentClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.medicalOrganizationAssignmentSearch>) => (
      medicalOrganizationService.medicalOrganizationAssignmentSearch(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationMedicalOrganizationAssignmentSearchObject,
    typeName: 'useMedicalOrganizationMedicalOrganizationAssignmentSearch',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationMedicalOrganizationAssignmentSearch.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationMedicalOrganizationAssignmentSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationMedicalOrganizationAssignmentSearch.createObject = createMedicalOrganizationMedicalOrganizationAssignmentSearchObject;

export const createMedicalOrganizationRemoveStaffCheckObject = (value: APIResponse<RemoveRoleValidationResponse[]>) => new APIResponseClass<RemoveRoleValidationResponseClass[]>(value, (v) => map(v, (value: RemoveRoleValidationResponse) => new RemoveRoleValidationResponseClass(value)));

export const useMedicalOrganizationRemoveStaffCheck = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationRemoveStaffCheckParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationRemoveStaffCheckParams,
    APIResponse<RemoveRoleValidationResponse[]>,
    typeof medicalOrganizationService.removeStaffCheck,
    AjaxOptions,
    APIResponseClass<RemoveRoleValidationResponseClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.removeStaffCheck>) => (
      medicalOrganizationService.removeStaffCheck(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationRemoveStaffCheckObject,
    typeName: 'useMedicalOrganizationRemoveStaffCheck',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationRemoveStaffCheck.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationRemoveStaffCheck.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationRemoveStaffCheck.createObject = createMedicalOrganizationRemoveStaffCheckObject;

export const createMedicalOrganizationUploadConsentObject = (value: APIResponse<FileInfo>) => new APIResponseClass<FileInfoClass>(value, (value: FileInfo) => new FileInfoClass(value));

export const useMedicalOrganizationUploadConsent = <RCN = undefined>(configs: MakeRequestHookConfig<MedicalOrganizationUploadConsentParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicalOrganizationUploadConsentParams,
    APIResponse<FileInfo>,
    typeof medicalOrganizationService.uploadConsent,
    AjaxOptions,
    APIResponseClass<FileInfoClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.uploadConsent>) => (
      medicalOrganizationService.uploadConsent(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationUploadConsentObject,
    typeName: 'useMedicalOrganizationUploadConsent',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationUploadConsent.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationUploadConsent.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationUploadConsent.createObject = createMedicalOrganizationUploadConsentObject;


export const createMedicalOrganizationGetObject = (value: APIResponse<MedicalOrganization>) => new APIResponseClass<MedicalOrganizationClass>(value, (value: MedicalOrganization) => new MedicalOrganizationClass(value));
export const useMedicalOrganizationGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<MedicalOrganization>,
    // @ts-ignore
    typeof medicalOrganizationService.get,
    GetOptions,
    APIResponseClass<MedicalOrganizationClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.get>) => (
      medicalOrganizationService.get(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationGetObject,
    typeName: 'useMedicalOrganizationGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationGet.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationGet.createObject = createMedicalOrganizationGetObject;

export const createMedicalOrganizationUpdateObject = (value: APIResponse<MedicalOrganization>) => new APIResponseClass<MedicalOrganizationClass>(value, (value: MedicalOrganization) => new MedicalOrganizationClass(value));
export const useMedicalOrganizationUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, MedicalOrganization>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, MedicalOrganization>,
    APIResponse<MedicalOrganization>,
    // @ts-ignore
    typeof medicalOrganizationService.update,
    AjaxOptions,
    APIResponseClass<MedicalOrganizationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.update>) => (
      medicalOrganizationService.update(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationUpdateObject,
    typeName: 'useMedicalOrganizationUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationUpdate.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationUpdate.createObject = createMedicalOrganizationUpdateObject;

export const createMedicalOrganizationInsertObject = (value: APIResponse<MedicalOrganization>) => new APIResponseClass<MedicalOrganizationClass>(value, (value: MedicalOrganization) => new MedicalOrganizationClass(value));
export const useMedicalOrganizationInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<MedicalOrganization>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<MedicalOrganization>,
    APIResponse<MedicalOrganization>,
    // @ts-ignore
    typeof medicalOrganizationService.insert,
    AjaxOptions,
    APIResponseClass<MedicalOrganizationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.insert>) => (
      medicalOrganizationService.insert(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationInsertObject,
    typeName: 'useMedicalOrganizationInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationInsert.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationInsert.createObject = createMedicalOrganizationInsertObject;

export const createMedicalOrganizationDeleteObject = (value: APIResponse<MedicalOrganization>) => new APIResponseClass<MedicalOrganizationClass>(value, (value: MedicalOrganization) => new MedicalOrganizationClass(value));
export const useMedicalOrganizationDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<MedicalOrganization>,
    // @ts-ignore
    typeof medicalOrganizationService.delete,
    AjaxOptions,
    APIResponseClass<MedicalOrganizationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.delete>) => (
      medicalOrganizationService.delete(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationDeleteObject,
    typeName: 'useMedicalOrganizationDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationDelete.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationDelete.createObject = createMedicalOrganizationDeleteObject;

export const createMedicalOrganizationSearchObject = (value: APIResponse<PageResponse<MedicalOrganization>>) => new APIResponseClass<PageResponseClass<MedicalOrganizationClass>>(value, (value: PageResponse<MedicalOrganization>) => new PageResponseClass<MedicalOrganizationClass>(value, (value: MedicalOrganization) => new MedicalOrganizationClass(value)));
export const useMedicalOrganizationSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<MedicalOrganization>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<MedicalOrganization>,
    APIResponse<PageResponse<MedicalOrganization>>,
    // @ts-ignore
    typeof medicalOrganizationService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<MedicalOrganizationClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicalOrganizationService.search>) => (
      medicalOrganizationService.search(...args)
    ),
    ResponseClassCreator: createMedicalOrganizationSearchObject,
    typeName: 'useMedicalOrganizationSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMedicalOrganizationSearch.mock = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicalOrganizationSearch.createObject = createMedicalOrganizationSearchObject;

export enum MedicationRouteEnum {
	ORAL = 'ORAL',
	SUBLINGUAL = 'SUBLINGUAL',
	RECTAL = 'RECTAL',
	INHALATION = 'INHALATION',
	INJECTION = 'INJECTION',
	TOPICAL = 'TOPICAL',
	OCULAR = 'OCULAR',
	AURICULAR = 'AURICULAR',
	INTRANASAL = 'INTRANASAL',
	UROGENITAL = 'UROGENITAL',
	OTHER = 'OTHER',
	displayName = 'displayName',
	description = 'description',
}
export interface MedicationHistoryItem {
	modifiedAt?: Nullable<string>;
	name?: Nullable<string>;
	dosage?: Nullable<string[]>;
	instruction?: Nullable<string>;
	active?: Nullable<boolean>;
	originalValue?: Nullable<string>;
	newValue?: Nullable<string>;
	field?: Nullable<string>;
}
export enum MedicationStatusEnum {
	NOT_SURE = 'NOT_SURE',
	TAKING = 'TAKING',
	NOT_TAKING = 'NOT_TAKING',
	TAKING_IRREGULARLY = 'TAKING_IRREGULARLY',
}
export enum MedicationInstructionEnum {
	BEFORE_MEAL = 'BEFORE_MEAL',
	WITH_MEAL = 'WITH_MEAL',
	AFTER_MEAL = 'AFTER_MEAL',
	BETWEEN_MEAL = 'BETWEEN_MEAL',
	AT_BEDTIME = 'AT_BEDTIME',
	UPON_WAKING = 'UPON_WAKING',
	NOT_SPECIFIED = 'NOT_SPECIFIED',
}
export enum FrequencyDisplayType {
	QD = 'QD',
	BID = 'BID',
	TID = 'TID',
	QID = 'QID',
	ON = 'ON',
	OM = 'OM',
	Q6H = 'Q6H',
	Q8H = 'Q8H',
	QOD = 'QOD',
	QWK = 'QWK',
	PRN = 'PRN',
	Others = 'Others',
}
export interface Medication$ReminderDetail {
	hour?: Nullable<number>;
	minute?: Nullable<number>;
}
export interface Medication extends PatientBase {
	memberId?: Nullable<string>;
	name?: Nullable<string>;
	type?: Nullable<MedicationRouteEnum>;
	healthCondition?: Nullable<HealthConditionEnum>;
	dosage?: Nullable<string[]>;
	isSameDosage?: Nullable<boolean>;
	note?: Nullable<string>;
	active?: Nullable<boolean>;
	histories?: Nullable<MedicationHistoryItem[]>;
	startDate?: Nullable<string>;
	endDate?: Nullable<string>;
	otherMD?: Nullable<boolean>;
	status?: Nullable<MedicationStatusEnum>;
	instruction?: Nullable<MedicationInstructionEnum>;
	frequency?: Nullable<FrequencyDisplayType>;
	reminders?: Nullable<Medication$ReminderDetail[]>;
	visitId?: Nullable<string>;
	endDateRange?: Nullable<DateRangeFilter>;
	isEnded?: Nullable<boolean>;
	duplicate?: Nullable<boolean>;
	conflict?: Nullable<boolean>;
	confirmed?: Nullable<boolean>;
	dictionaryId?: Nullable<string>;
	medicationType?: Nullable<string[]>;
}
export interface MedicationRemoveRequest {
	id?: Nullable<string>;
	visitId?: Nullable<string>;
}
export enum SortDirection {
	ASC = 'ASC',
	DESC = 'DESC',
}
export interface MedicationDictionaryItem {
	id?: Nullable<string>;
	ingredient?: Nullable<string>;
	brandName?: Nullable<string>;
	type?: Nullable<string[]>;
	displayName?: Nullable<string>;
}
export class MedicationDictionaryItemClass {
  protected readonly _value: MedicationDictionaryItem;

	constructor(value: MedicationDictionaryItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get ingredient() {
    return this._value.ingredient;
  }

  get ingredientWithDefault() {
    return this._value.ingredient || '';
  }

  get brandName() {
    return this._value.brandName;
  }

  get brandNameWithDefault() {
    return this._value.brandName || '';
  }

  get type() {
    return this._value.type;
  }

  get typeWithDefault() {
    return this._value.type || [];
  }

  get displayName() {
    return this._value.displayName;
  }

  get displayNameWithDefault() {
    return this._value.displayName || '';
  }
}
export enum MedicationTypeEnum {
	BP = 'BP',
	BG = 'BG',
	supplement = 'supplement',
}
export interface MedicationTakingDetail {
	medicationId?: Nullable<string>;
	medicationName?: Nullable<string>;
	dosage?: Nullable<string>;
	type?: Nullable<MedicationRouteEnum>;
	medicationTypes?: Nullable<MedicationTypeEnum[]>;
	instructionEnum?: Nullable<MedicationInstructionEnum>;
	scheduledTs?: Nullable<number>;
}
export class MedicationTakingDetailClass {
  protected readonly _value: MedicationTakingDetail;

	constructor(value: MedicationTakingDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get medicationId() {
    return this._value.medicationId;
  }

  get medicationIdWithDefault() {
    return this._value.medicationId || '';
  }

  get medicationName() {
    return this._value.medicationName;
  }

  get medicationNameWithDefault() {
    return this._value.medicationName || '';
  }

  get dosage() {
    return this._value.dosage;
  }

  get dosageWithDefault() {
    return this._value.dosage || '';
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: MedicationRouteEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get medicationTypes() {
    return this._value.medicationTypes;
  }

  get medicationTypesWithDefault() {
    return this._value.medicationTypes || [];
  }

  get instructionEnum() {
    return this._value.instructionEnum;
  }

  instructionEnumIsIn(values: MedicationInstructionEnum[] = []) {
    return this._value.instructionEnum && values.includes(this._value.instructionEnum);
  }

  get scheduledTs() {
    return this._value.scheduledTs;
  }

  get scheduledTsWithDefault() {
    return this._value.scheduledTs || 0;
  }
}
export enum MedicationReminderLogStatus {
	Skipped = 'Skipped',
	Taken = 'Taken',
}
export interface MedicationTakingReq {
	medicationId?: Nullable<string>;
	scheduledTs?: Nullable<number>;
	dosage?: Nullable<string>;
	status?: Nullable<MedicationReminderLogStatus>;
}
export class MedicationHistoryItemClass {
  protected readonly _value: MedicationHistoryItem;

	constructor(value: MedicationHistoryItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get modifiedAt() {
    return (this._value.modifiedAt !== undefined && this._value.modifiedAt !== null) ? dayjs(this._value.modifiedAt) : this._value.modifiedAt;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get dosage() {
    return this._value.dosage;
  }

  get dosageWithDefault() {
    return this._value.dosage || [];
  }

  get instruction() {
    return this._value.instruction;
  }

  get instructionWithDefault() {
    return this._value.instruction || '';
  }

  get active() {
    return this._value.active;
  }

  get activeWithDefault() {
    return this._value.active || false;
  }

  get originalValue() {
    return this._value.originalValue;
  }

  get originalValueWithDefault() {
    return this._value.originalValue || '';
  }

  get newValue() {
    return this._value.newValue;
  }

  get newValueWithDefault() {
    return this._value.newValue || '';
  }

  get field() {
    return this._value.field;
  }

  get fieldWithDefault() {
    return this._value.field || '';
  }
}
export class Medication$ReminderDetailClass {
  protected readonly _value: Medication$ReminderDetail;

	constructor(value: Medication$ReminderDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get hour() {
    return this._value.hour;
  }

  get hourWithDefault() {
    return this._value.hour || 0;
  }

  get minute() {
    return this._value.minute;
  }

  get minuteWithDefault() {
    return this._value.minute || 0;
  }
}
export class MedicationClass extends PatientBaseClass {
  protected readonly _value: Medication;

	constructor(value: Medication) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: MedicationRouteEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get healthCondition() {
    return this._value.healthCondition;
  }

  healthConditionIsIn(values: HealthConditionEnum[] = []) {
    return this._value.healthCondition && values.includes(this._value.healthCondition);
  }

  get dosage() {
    return this._value.dosage;
  }

  get dosageWithDefault() {
    return this._value.dosage || [];
  }

  get isSameDosage() {
    return this._value.isSameDosage;
  }

  get isSameDosageWithDefault() {
    return this._value.isSameDosage || false;
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get active() {
    return this._value.active;
  }

  get activeWithDefault() {
    return this._value.active || false;
  }

  get histories() {
    return this._value.histories !== undefined && this._value.histories !== null ? map(this._value.histories, (v) => new MedicationHistoryItemClass(v)) : this._value.histories;
  }

  get historiesWithDefault() {
    return this._value.histories || [];
  }

  get startDate() {
    return (this._value.startDate !== undefined && this._value.startDate !== null) ? dayjs(this._value.startDate) : this._value.startDate;
  }

  get endDate() {
    return (this._value.endDate !== undefined && this._value.endDate !== null) ? dayjs(this._value.endDate) : this._value.endDate;
  }

  get otherMD() {
    return this._value.otherMD;
  }

  get otherMDWithDefault() {
    return this._value.otherMD || false;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: MedicationStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get instruction() {
    return this._value.instruction;
  }

  instructionIsIn(values: MedicationInstructionEnum[] = []) {
    return this._value.instruction && values.includes(this._value.instruction);
  }

  get frequency() {
    return this._value.frequency;
  }

  frequencyIsIn(values: FrequencyDisplayType[] = []) {
    return this._value.frequency && values.includes(this._value.frequency);
  }

  get reminders() {
    return this._value.reminders !== undefined && this._value.reminders !== null ? map(this._value.reminders, (v) => new Medication$ReminderDetailClass(v)) : this._value.reminders;
  }

  get remindersWithDefault() {
    return this._value.reminders || [];
  }

  get visitId() {
    return this._value.visitId;
  }

  get endDateRange() {
    return this._value.endDateRange !== undefined && this._value.endDateRange !== null ? new DateRangeFilterClass(this._value.endDateRange) : this._value.endDateRange;
  }

  get isEnded() {
    return this._value.isEnded;
  }

  get isEndedWithDefault() {
    return this._value.isEnded || false;
  }

  get duplicate() {
    return this._value.duplicate;
  }

  get duplicateWithDefault() {
    return this._value.duplicate || false;
  }

  get conflict() {
    return this._value.conflict;
  }

  get conflictWithDefault() {
    return this._value.conflict || false;
  }

  get confirmed() {
    return this._value.confirmed;
  }

  get confirmedWithDefault() {
    return this._value.confirmed || false;
  }

  get dictionaryId() {
    return this._value.dictionaryId;
  }

  get medicationType() {
    return this._value.medicationType;
  }

  get medicationTypeWithDefault() {
    return this._value.medicationType || [];
  }
}
export interface MedicationDeleteMedicationParams {
	request: MedicationRemoveRequest;
}

export interface MedicationSearchDictionaryParams {
	request: SearchRequest<string>;
}

export interface MedicationGetReminderLogListParams {
	memberId: string;
	scheduledTs: number;
}

export interface MedicationLogMedicationTakingParams {
	memberId: string;
	request: MedicationTakingReq[];
}

export class MedicationService extends BaseController<Medication, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/medication';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public deleteMedication({ baseUrl, params, headers }: RequestOption<MedicationDeleteMedicationParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/remove-medication${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public searchDictionary({ baseUrl, params, headers }: RequestOption<MedicationSearchDictionaryParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<MedicationDictionaryItem>>>(`${baseUrl || this.baseURL}${this.basePath}/dictionary/search${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getReminderLogList({ baseUrl, params, headers }: RequestOption<MedicationGetReminderLogListParams>) {
		const queryList: QueryParam[] = [];
		if (params.scheduledTs !== undefined && params.scheduledTs !== null) {
			queryList.push({ name: 'scheduledTs', value: params.scheduledTs.toString() });
		}
		return axios.get<APIResponse<{ [key: number]: MedicationTakingDetail[] }>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/taking-log/list${getQueryStrings(queryList)}`, { headers });
	}

	public logMedicationTaking({ baseUrl, params, headers }: RequestOption<MedicationLogMedicationTakingParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<boolean>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/taking-log${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const medicationService = new MedicationService();

export const createMedicationDeleteMedicationObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useMedicationDeleteMedication = <RCN = undefined>(configs: MakeRequestHookConfig<MedicationDeleteMedicationParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicationDeleteMedicationParams,
    APIResponse<string>,
    typeof medicationService.deleteMedication,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicationService.deleteMedication>) => (
      medicationService.deleteMedication(...args)
    ),
    ResponseClassCreator: createMedicationDeleteMedicationObject,
    typeName: 'useMedicationDeleteMedication',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicationDeleteMedication.mock = () => new Error('Mock has not been implemented!');
useMedicationDeleteMedication.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicationDeleteMedication.createObject = createMedicationDeleteMedicationObject;

export const createMedicationSearchDictionaryObject = (value: APIResponse<PageResponse<MedicationDictionaryItem>>) => new APIResponseClass<PageResponseClass<MedicationDictionaryItemClass>>(value, (value: PageResponse<MedicationDictionaryItem>) => new PageResponseClass<MedicationDictionaryItemClass>(value, (value: MedicationDictionaryItem) => new MedicationDictionaryItemClass(value)));

export const useMedicationSearchDictionary = <RCN = undefined>(configs: MakeRequestHookConfig<MedicationSearchDictionaryParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicationSearchDictionaryParams,
    APIResponse<PageResponse<MedicationDictionaryItem>>,
    typeof medicationService.searchDictionary,
    AjaxOptions,
    APIResponseClass<PageResponseClass<MedicationDictionaryItemClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicationService.searchDictionary>) => (
      medicationService.searchDictionary(...args)
    ),
    ResponseClassCreator: createMedicationSearchDictionaryObject,
    typeName: 'useMedicationSearchDictionary',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicationSearchDictionary.mock = () => new Error('Mock has not been implemented!');
useMedicationSearchDictionary.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicationSearchDictionary.createObject = createMedicationSearchDictionaryObject;

export const createMedicationGetReminderLogListObject = (value: APIResponse<{ [key: number]: MedicationTakingDetail[] }>) => new APIResponseClass<{ [key: number]: MedicationTakingDetail[] }>(value, (v) => (v));

export const useMedicationGetReminderLogList = <RCN = undefined>(configs: MakeRequestHookConfig<MedicationGetReminderLogListParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicationGetReminderLogListParams,
    APIResponse<{ [key: number]: MedicationTakingDetail[] }>,
    typeof medicationService.getReminderLogList,
    GetOptions,
    APIResponseClass<{ [key: number]: MedicationTakingDetail[] }>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof medicationService.getReminderLogList>) => (
      medicationService.getReminderLogList(...args)
    ),
    ResponseClassCreator: createMedicationGetReminderLogListObject,
    typeName: 'useMedicationGetReminderLogList',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicationGetReminderLogList.mock = () => new Error('Mock has not been implemented!');
useMedicationGetReminderLogList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicationGetReminderLogList.createObject = createMedicationGetReminderLogListObject;

export const createMedicationLogMedicationTakingObject = (value: APIResponse<boolean>) => new APIResponseClass<boolean>(value, (v) => (v));

export const useMedicationLogMedicationTaking = <RCN = undefined>(configs: MakeRequestHookConfig<MedicationLogMedicationTakingParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MedicationLogMedicationTakingParams,
    APIResponse<boolean>,
    typeof medicationService.logMedicationTaking,
    AjaxOptions,
    APIResponseClass<boolean>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicationService.logMedicationTaking>) => (
      medicationService.logMedicationTaking(...args)
    ),
    ResponseClassCreator: createMedicationLogMedicationTakingObject,
    typeName: 'useMedicationLogMedicationTaking',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMedicationLogMedicationTaking.mock = () => new Error('Mock has not been implemented!');
useMedicationLogMedicationTaking.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicationLogMedicationTaking.createObject = createMedicationLogMedicationTakingObject;


export const createMedicationGetObject = (value: APIResponse<Medication>) => new APIResponseClass<MedicationClass>(value, (value: Medication) => new MedicationClass(value));
export const useMedicationGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<Medication>,
    // @ts-ignore
    typeof medicationService.get,
    GetOptions,
    APIResponseClass<MedicationClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof medicationService.get>) => (
      medicationService.get(...args)
    ),
    ResponseClassCreator: createMedicationGetObject,
    typeName: 'useMedicationGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMedicationGet.mock = () => new Error('Mock has not been implemented!');
useMedicationGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicationGet.createObject = createMedicationGetObject;

export const createMedicationUpdateObject = (value: APIResponse<Medication>) => new APIResponseClass<MedicationClass>(value, (value: Medication) => new MedicationClass(value));
export const useMedicationUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, Medication>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, Medication>,
    APIResponse<Medication>,
    // @ts-ignore
    typeof medicationService.update,
    AjaxOptions,
    APIResponseClass<MedicationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicationService.update>) => (
      medicationService.update(...args)
    ),
    ResponseClassCreator: createMedicationUpdateObject,
    typeName: 'useMedicationUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMedicationUpdate.mock = () => new Error('Mock has not been implemented!');
useMedicationUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicationUpdate.createObject = createMedicationUpdateObject;

export const createMedicationInsertObject = (value: APIResponse<Medication>) => new APIResponseClass<MedicationClass>(value, (value: Medication) => new MedicationClass(value));
export const useMedicationInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<Medication>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<Medication>,
    APIResponse<Medication>,
    // @ts-ignore
    typeof medicationService.insert,
    AjaxOptions,
    APIResponseClass<MedicationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicationService.insert>) => (
      medicationService.insert(...args)
    ),
    ResponseClassCreator: createMedicationInsertObject,
    typeName: 'useMedicationInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMedicationInsert.mock = () => new Error('Mock has not been implemented!');
useMedicationInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicationInsert.createObject = createMedicationInsertObject;

export const createMedicationDeleteObject = (value: APIResponse<Medication>) => new APIResponseClass<MedicationClass>(value, (value: Medication) => new MedicationClass(value));
export const useMedicationDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<Medication>,
    // @ts-ignore
    typeof medicationService.delete,
    AjaxOptions,
    APIResponseClass<MedicationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicationService.delete>) => (
      medicationService.delete(...args)
    ),
    ResponseClassCreator: createMedicationDeleteObject,
    typeName: 'useMedicationDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMedicationDelete.mock = () => new Error('Mock has not been implemented!');
useMedicationDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicationDelete.createObject = createMedicationDeleteObject;

export const createMedicationSearchObject = (value: APIResponse<PageResponse<Medication>>) => new APIResponseClass<PageResponseClass<MedicationClass>>(value, (value: PageResponse<Medication>) => new PageResponseClass<MedicationClass>(value, (value: Medication) => new MedicationClass(value)));
export const useMedicationSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<Medication>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<Medication>,
    APIResponse<PageResponse<Medication>>,
    // @ts-ignore
    typeof medicationService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<MedicationClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof medicationService.search>) => (
      medicationService.search(...args)
    ),
    ResponseClassCreator: createMedicationSearchObject,
    typeName: 'useMedicationSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMedicationSearch.mock = () => new Error('Mock has not been implemented!');
useMedicationSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMedicationSearch.createObject = createMedicationSearchObject;

export interface Memo extends BaseModel {
	memberId?: Nullable<string>;
	note?: Nullable<string>;
	createdBy?: Nullable<string>;
	updatedBy?: Nullable<string>;
	createdByUser?: Nullable<LimitedUser>;
	updatedByUser?: Nullable<LimitedUser>;
}
export class MemoClass extends BaseModelClass {
  protected readonly _value: Memo;

	constructor(value: Memo) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get createdBy() {
    return this._value.createdBy;
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get createdByUser() {
    return this._value.createdByUser !== undefined && this._value.createdByUser !== null ? new LimitedUserClass(this._value.createdByUser) : this._value.createdByUser;
  }

  get updatedByUser() {
    return this._value.updatedByUser !== undefined && this._value.updatedByUser !== null ? new LimitedUserClass(this._value.updatedByUser) : this._value.updatedByUser;
  }
}
export interface MemoUpdateParams {
	id: string;
	request: Memo;
}

export interface MemoInsertParams {
	request: Memo;
}

export class MemoService extends BaseController<Memo, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/memo';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public update({ baseUrl, params, headers }: RequestOption<MemoUpdateParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<Memo>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public insert({ baseUrl, params, headers }: RequestOption<MemoInsertParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Memo>>(`${baseUrl || this.baseURL}${this.basePath}${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const memoService = new MemoService();

export const createMemoUpdateObject = (value: APIResponse<Memo>) => new APIResponseClass<MemoClass>(value, (value: Memo) => new MemoClass(value));

export const useMemoUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<MemoUpdateParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MemoUpdateParams,
    APIResponse<Memo>,
    typeof memoService.update,
    AjaxOptions,
    APIResponseClass<MemoClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof memoService.update>) => (
      memoService.update(...args)
    ),
    ResponseClassCreator: createMemoUpdateObject,
    typeName: 'useMemoUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMemoUpdate.mock = () => new Error('Mock has not been implemented!');
useMemoUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMemoUpdate.createObject = createMemoUpdateObject;

export const createMemoInsertObject = (value: APIResponse<Memo>) => new APIResponseClass<MemoClass>(value, (value: Memo) => new MemoClass(value));

export const useMemoInsert = <RCN = undefined>(configs: MakeRequestHookConfig<MemoInsertParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MemoInsertParams,
    APIResponse<Memo>,
    typeof memoService.insert,
    AjaxOptions,
    APIResponseClass<MemoClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof memoService.insert>) => (
      memoService.insert(...args)
    ),
    ResponseClassCreator: createMemoInsertObject,
    typeName: 'useMemoInsert',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMemoInsert.mock = () => new Error('Mock has not been implemented!');
useMemoInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMemoInsert.createObject = createMemoInsertObject;


export const createMemoGetObject = (value: APIResponse<Memo>) => new APIResponseClass<MemoClass>(value, (value: Memo) => new MemoClass(value));
export const useMemoGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<Memo>,
    // @ts-ignore
    typeof memoService.get,
    GetOptions,
    APIResponseClass<MemoClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof memoService.get>) => (
      memoService.get(...args)
    ),
    ResponseClassCreator: createMemoGetObject,
    typeName: 'useMemoGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMemoGet.mock = () => new Error('Mock has not been implemented!');
useMemoGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMemoGet.createObject = createMemoGetObject;

export const createMemoDeleteObject = (value: APIResponse<Memo>) => new APIResponseClass<MemoClass>(value, (value: Memo) => new MemoClass(value));
export const useMemoDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<Memo>,
    // @ts-ignore
    typeof memoService.delete,
    AjaxOptions,
    APIResponseClass<MemoClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof memoService.delete>) => (
      memoService.delete(...args)
    ),
    ResponseClassCreator: createMemoDeleteObject,
    typeName: 'useMemoDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMemoDelete.mock = () => new Error('Mock has not been implemented!');
useMemoDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMemoDelete.createObject = createMemoDeleteObject;

export const createMemoSearchObject = (value: APIResponse<PageResponse<Memo>>) => new APIResponseClass<PageResponseClass<MemoClass>>(value, (value: PageResponse<Memo>) => new PageResponseClass<MemoClass>(value, (value: Memo) => new MemoClass(value)));
export const useMemoSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<Memo>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<Memo>,
    APIResponse<PageResponse<Memo>>,
    // @ts-ignore
    typeof memoService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<MemoClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof memoService.search>) => (
      memoService.search(...args)
    ),
    ResponseClassCreator: createMemoSearchObject,
    typeName: 'useMemoSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMemoSearch.mock = () => new Error('Mock has not been implemented!');
useMemoSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMemoSearch.createObject = createMemoSearchObject;

export interface MessageRecord extends BaseModel {
	memberId?: Nullable<string>;
	senderId?: Nullable<string>;
	eventId?: Nullable<string>;
	uniqueIdentifier?: Nullable<string>;
	success?: Nullable<boolean>;
	eventCompleted?: Nullable<boolean>;
	uniqueIdentifierIn?: Nullable<InFilter<string>>;
	memberIdIn?: Nullable<InFilter<string>>;
}
export interface MessageRecordRequest {
	uniqueIdentifier?: Nullable<string>;
}
export interface MessageRecordResponse {
	success?: Nullable<number>;
	failed?: Nullable<number>;
	failedMemberIds?: Nullable<string[]>;
	status?: Nullable<ConditionEnum>;
}
export class MessageRecordResponseClass {
  protected readonly _value: MessageRecordResponse;

	constructor(value: MessageRecordResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get success() {
    return this._value.success;
  }

  get successWithDefault() {
    return this._value.success || 0;
  }

  get failed() {
    return this._value.failed;
  }

  get failedWithDefault() {
    return this._value.failed || 0;
  }

  get failedMemberIds() {
    return this._value.failedMemberIds;
  }

  get failedMemberIdsWithDefault() {
    return this._value.failedMemberIds || [];
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: ConditionEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }
}
export class MessageRecordClass extends BaseModelClass {
  protected readonly _value: MessageRecord;

	constructor(value: MessageRecord) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get memberIdWithDefault() {
    return this._value.memberId || '';
  }

  get senderId() {
    return this._value.senderId;
  }

  get senderIdWithDefault() {
    return this._value.senderId || '';
  }

  get eventId() {
    return this._value.eventId;
  }

  get eventIdWithDefault() {
    return this._value.eventId || '';
  }

  get uniqueIdentifier() {
    return this._value.uniqueIdentifier;
  }

  get uniqueIdentifierWithDefault() {
    return this._value.uniqueIdentifier || '';
  }

  get success() {
    return this._value.success;
  }

  get successWithDefault() {
    return this._value.success || false;
  }

  get eventCompleted() {
    return this._value.eventCompleted;
  }

  get eventCompletedWithDefault() {
    return this._value.eventCompleted || false;
  }

  get uniqueIdentifierIn() {
    return this._value.uniqueIdentifierIn !== undefined && this._value.uniqueIdentifierIn !== null ? new InFilterClass(this._value.uniqueIdentifierIn) : this._value.uniqueIdentifierIn;
  }

  get memberIdIn() {
    return this._value.memberIdIn !== undefined && this._value.memberIdIn !== null ? new InFilterClass(this._value.memberIdIn) : this._value.memberIdIn;
  }
}
export interface MessageRecordCountSuccessParams {
	request: MessageRecordRequest;
}

export class MessageRecordService extends BaseController<MessageRecord, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/message-record';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public countSuccess({ baseUrl, params, headers }: RequestOption<MessageRecordCountSuccessParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<MessageRecordResponse>>(`${baseUrl || this.baseURL}${this.basePath}/info${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const messageRecordService = new MessageRecordService();

export const createMessageRecordCountSuccessObject = (value: APIResponse<MessageRecordResponse>) => new APIResponseClass<MessageRecordResponseClass>(value, (value: MessageRecordResponse) => new MessageRecordResponseClass(value));

export const useMessageRecordCountSuccess = <RCN = undefined>(configs: MakeRequestHookConfig<MessageRecordCountSuccessParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    MessageRecordCountSuccessParams,
    APIResponse<MessageRecordResponse>,
    typeof messageRecordService.countSuccess,
    AjaxOptions,
    APIResponseClass<MessageRecordResponseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof messageRecordService.countSuccess>) => (
      messageRecordService.countSuccess(...args)
    ),
    ResponseClassCreator: createMessageRecordCountSuccessObject,
    typeName: 'useMessageRecordCountSuccess',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useMessageRecordCountSuccess.mock = () => new Error('Mock has not been implemented!');
useMessageRecordCountSuccess.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMessageRecordCountSuccess.createObject = createMessageRecordCountSuccessObject;


export const createMessageRecordGetObject = (value: APIResponse<MessageRecord>) => new APIResponseClass<MessageRecordClass>(value, (value: MessageRecord) => new MessageRecordClass(value));
export const useMessageRecordGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<MessageRecord>,
    // @ts-ignore
    typeof messageRecordService.get,
    GetOptions,
    APIResponseClass<MessageRecordClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof messageRecordService.get>) => (
      messageRecordService.get(...args)
    ),
    ResponseClassCreator: createMessageRecordGetObject,
    typeName: 'useMessageRecordGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMessageRecordGet.mock = () => new Error('Mock has not been implemented!');
useMessageRecordGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMessageRecordGet.createObject = createMessageRecordGetObject;

export const createMessageRecordUpdateObject = (value: APIResponse<MessageRecord>) => new APIResponseClass<MessageRecordClass>(value, (value: MessageRecord) => new MessageRecordClass(value));
export const useMessageRecordUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, MessageRecord>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, MessageRecord>,
    APIResponse<MessageRecord>,
    // @ts-ignore
    typeof messageRecordService.update,
    AjaxOptions,
    APIResponseClass<MessageRecordClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof messageRecordService.update>) => (
      messageRecordService.update(...args)
    ),
    ResponseClassCreator: createMessageRecordUpdateObject,
    typeName: 'useMessageRecordUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMessageRecordUpdate.mock = () => new Error('Mock has not been implemented!');
useMessageRecordUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMessageRecordUpdate.createObject = createMessageRecordUpdateObject;

export const createMessageRecordInsertObject = (value: APIResponse<MessageRecord>) => new APIResponseClass<MessageRecordClass>(value, (value: MessageRecord) => new MessageRecordClass(value));
export const useMessageRecordInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<MessageRecord>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<MessageRecord>,
    APIResponse<MessageRecord>,
    // @ts-ignore
    typeof messageRecordService.insert,
    AjaxOptions,
    APIResponseClass<MessageRecordClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof messageRecordService.insert>) => (
      messageRecordService.insert(...args)
    ),
    ResponseClassCreator: createMessageRecordInsertObject,
    typeName: 'useMessageRecordInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMessageRecordInsert.mock = () => new Error('Mock has not been implemented!');
useMessageRecordInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMessageRecordInsert.createObject = createMessageRecordInsertObject;

export const createMessageRecordDeleteObject = (value: APIResponse<MessageRecord>) => new APIResponseClass<MessageRecordClass>(value, (value: MessageRecord) => new MessageRecordClass(value));
export const useMessageRecordDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<MessageRecord>,
    // @ts-ignore
    typeof messageRecordService.delete,
    AjaxOptions,
    APIResponseClass<MessageRecordClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof messageRecordService.delete>) => (
      messageRecordService.delete(...args)
    ),
    ResponseClassCreator: createMessageRecordDeleteObject,
    typeName: 'useMessageRecordDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMessageRecordDelete.mock = () => new Error('Mock has not been implemented!');
useMessageRecordDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMessageRecordDelete.createObject = createMessageRecordDeleteObject;

export const createMessageRecordSearchObject = (value: APIResponse<PageResponse<MessageRecord>>) => new APIResponseClass<PageResponseClass<MessageRecordClass>>(value, (value: PageResponse<MessageRecord>) => new PageResponseClass<MessageRecordClass>(value, (value: MessageRecord) => new MessageRecordClass(value)));
export const useMessageRecordSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<MessageRecord>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<MessageRecord>,
    APIResponse<PageResponse<MessageRecord>>,
    // @ts-ignore
    typeof messageRecordService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<MessageRecordClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof messageRecordService.search>) => (
      messageRecordService.search(...args)
    ),
    ResponseClassCreator: createMessageRecordSearchObject,
    typeName: 'useMessageRecordSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMessageRecordSearch.mock = () => new Error('Mock has not been implemented!');
useMessageRecordSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMessageRecordSearch.createObject = createMessageRecordSearchObject;

export enum MonthlySummaryReportReviewEnum {
	YES = 'YES',
	NO = 'NO',
	NONE = 'NONE',
}
export interface MonthlySummaryReportReview {
	isHelpful?: Nullable<MonthlySummaryReportReviewEnum>;
}
export interface Result extends BaseModel {
	enrolledProgramId?: Nullable<string>;
	memberId?: Nullable<string>;
	adminProgramId?: Nullable<string>;
	organizationId?: Nullable<string>;
	managers?: Nullable<string>;
	taskId?: Nullable<string>;
	schedule?: Nullable<string>;
	type?: Nullable<MeasurementResultTypeEnum>;
	rangeStart?: Nullable<string>;
	rangeEnd?: Nullable<string>;
	manualInput?: Nullable<boolean>;
	measureId?: Nullable<string>;
	severity?: Nullable<AlertSeverityEnum>;
	careStatus?: Nullable<CareStatusEnum>;
	notified?: Nullable<boolean>;
	threshold?: Nullable<AlertThreshold[]>;
	teamId?: Nullable<string>;
	userNotes?: Nullable<string>;
	timezone?: Nullable<string>;
	date?: Nullable<string>;
	day?: Nullable<string>;
	bodyWeight?: Nullable<Weight>;
	bodyWeightChange?: Nullable<Weight>;
	bodyWeightChangeBaseline?: Nullable<Weight>;
	systolicBloodPressure?: Nullable<BloodPressure>;
	diastolicBloodPressure?: Nullable<BloodPressure>;
	heartRate?: Nullable<HeartRate>;
	wavelet?: Nullable<string[]>;
	bloodGlucose?: Nullable<BloodGlucose>;
	beforeMeal?: Nullable<boolean>;
	mealType?: Nullable<BgMealTypeEnum>;
	resultSource?: Nullable<ResultSourceEnum>;
}
export interface BPResult extends Result {
}
export interface VitalStatDetailType {
	count?: Nullable<number>;
	average?: Nullable<number[]>;
	range?: Nullable<number[]>;
	inRangePercentage?: Nullable<number>;
}
export interface BPStatType {
	all?: Nullable<VitalStatDetailType>;
	overnight?: Nullable<VitalStatDetailType>;
	morning?: Nullable<VitalStatDetailType>;
	afternoon?: Nullable<VitalStatDetailType>;
	evening?: Nullable<VitalStatDetailType>;
	low?: Nullable<VitalStatDetailType>;
	normal?: Nullable<VitalStatDetailType>;
	elevated?: Nullable<VitalStatDetailType>;
	hypertension1?: Nullable<VitalStatDetailType>;
	hypertension2?: Nullable<VitalStatDetailType>;
	criticalHigh?: Nullable<VitalStatDetailType>;
	arrhythmia?: Nullable<VitalStatDetailType>;
	pulsePressure?: Nullable<VitalStatDetailType>;
}
export interface MonthlySummaryReportType<R, S> {
	data?: Nullable<R[]>;
	statistics?: Nullable<S>;
	severity?: Nullable<string>;
	measurementDays?: Nullable<number>;
}
export interface BGResult extends Result {
}
export interface BGStatType {
	all?: Nullable<VitalStatDetailType>;
	fasting?: Nullable<VitalStatDetailType>;
	beforeMeal?: Nullable<VitalStatDetailType>;
	afterMeal?: Nullable<VitalStatDetailType>;
	bedtimeOvernightHypo?: Nullable<VitalStatDetailType>;
	bedtime?: Nullable<VitalStatDetailType>;
	overnight?: Nullable<VitalStatDetailType>;
	criticalHigh?: Nullable<VitalStatDetailType>;
	criticalLow?: Nullable<VitalStatDetailType>;
}
export interface POResult extends Result {
}
export interface POVitalStatDetailType {
	count?: Nullable<number>;
	average?: Nullable<number[]>;
	range?: Nullable<number[]>;
	perfusionIndexAverage?: Nullable<number>;
	pulseRateAverage?: Nullable<number>;
}
export interface POStatType {
	all?: Nullable<POVitalStatDetailType>;
	overnight?: Nullable<POVitalStatDetailType>;
	morning?: Nullable<POVitalStatDetailType>;
	afternoon?: Nullable<POVitalStatDetailType>;
	evening?: Nullable<POVitalStatDetailType>;
	normal?: Nullable<POVitalStatDetailType>;
	low?: Nullable<POVitalStatDetailType>;
	criticalLow?: Nullable<POVitalStatDetailType>;
	highestPO?: Nullable<number>;
}
export interface HSResult extends Result {
}
export interface HSVitalStatDetailType {
	count?: Nullable<number>;
	average?: Nullable<number[]>;
}
export interface HSStatType {
	all?: Nullable<HSVitalStatDetailType>;
	overnight?: Nullable<HSVitalStatDetailType>;
	morning?: Nullable<HSVitalStatDetailType>;
	afternoon?: Nullable<HSVitalStatDetailType>;
	evening?: Nullable<HSVitalStatDetailType>;
}
export interface TMResult extends Result {
}
export interface TMVitalStatDetailType {
	count?: Nullable<number>;
	average?: Nullable<number[]>;
}
export interface TMStatType {
	all?: Nullable<TMVitalStatDetailType>;
	overnight?: Nullable<TMVitalStatDetailType>;
	morning?: Nullable<TMVitalStatDetailType>;
	afternoon?: Nullable<TMVitalStatDetailType>;
	evening?: Nullable<TMVitalStatDetailType>;
}
export interface MonthlySummaryReport extends BaseModel {
	memberId?: Nullable<string>;
	createdBy?: Nullable<string>;
	createdByUser?: Nullable<LimitedUser>;
	autoGenerated?: Nullable<boolean>;
	monthOfYear?: Nullable<number>;
	startDate?: Nullable<string>;
	endDate?: Nullable<string>;
	status?: Nullable<string>;
	review?: Nullable<MonthlySummaryReportReview>;
	reviewedAt?: Nullable<string>;
	sentChatAt?: Nullable<string>;
	sentPushNotificationAt?: Nullable<string>;
	BP?: Nullable<MonthlySummaryReportType<BPResult, BPStatType>>;
	BG?: Nullable<MonthlySummaryReportType<BGResult, BGStatType>>;
	PO?: Nullable<MonthlySummaryReportType<POResult, POStatType>>;
	HS?: Nullable<MonthlySummaryReportType<HSResult, HSStatType>>;
	TM?: Nullable<MonthlySummaryReportType<TMResult, TMStatType>>;
}
export class MonthlySummaryReportReviewClass {
  protected readonly _value: MonthlySummaryReportReview;

	constructor(value: MonthlySummaryReportReview) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get isHelpful() {
    return this._value.isHelpful;
  }

  isHelpfulIsIn(values: MonthlySummaryReportReviewEnum[] = []) {
    return this._value.isHelpful && values.includes(this._value.isHelpful);
  }
}
export class ResultClass extends BaseModelClass {
  protected readonly _value: Result;

	constructor(value: Result) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get enrolledProgramId() {
    return this._value.enrolledProgramId;
  }

  get memberId() {
    return this._value.memberId;
  }

  get adminProgramId() {
    return this._value.adminProgramId;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get managers() {
    return this._value.managers;
  }

  get managersWithDefault() {
    return this._value.managers || '';
  }

  get taskId() {
    return this._value.taskId;
  }

  get schedule() {
    return this._value.schedule;
  }

  get scheduleWithDefault() {
    return this._value.schedule || '';
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: MeasurementResultTypeEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get rangeStart() {
    return this._value.rangeStart;
  }

  get rangeStartWithDefault() {
    return this._value.rangeStart || '';
  }

  get rangeEnd() {
    return this._value.rangeEnd;
  }

  get rangeEndWithDefault() {
    return this._value.rangeEnd || '';
  }

  get manualInput() {
    return this._value.manualInput;
  }

  get manualInputWithDefault() {
    return this._value.manualInput || false;
  }

  get measureId() {
    return this._value.measureId;
  }

  get severity() {
    return this._value.severity;
  }

  severityIsIn(values: AlertSeverityEnum[] = []) {
    return this._value.severity && values.includes(this._value.severity);
  }

  get careStatus() {
    return this._value.careStatus;
  }

  careStatusIsIn(values: CareStatusEnum[] = []) {
    return this._value.careStatus && values.includes(this._value.careStatus);
  }

  get notified() {
    return this._value.notified;
  }

  get notifiedWithDefault() {
    return this._value.notified || false;
  }

  get threshold() {
    return this._value.threshold !== undefined && this._value.threshold !== null ? map(this._value.threshold, (v) => new AlertThresholdClass(v)) : this._value.threshold;
  }

  get thresholdWithDefault() {
    return this._value.threshold || [];
  }

  get teamId() {
    return this._value.teamId;
  }

  get userNotes() {
    return this._value.userNotes;
  }

  get userNotesWithDefault() {
    return this._value.userNotes || '';
  }

  get timezone() {
    return this._value.timezone;
  }

  get timezoneWithDefault() {
    return this._value.timezone || '';
  }

  get date() {
    return (this._value.date !== undefined && this._value.date !== null) ? dayjs(this._value.date) : this._value.date;
  }

  get day() {
    return this._value.day;
  }

  get dayWithDefault() {
    return this._value.day || '';
  }

  get bodyWeight() {
    return this._value.bodyWeight !== undefined && this._value.bodyWeight !== null ? new WeightClass(this._value.bodyWeight) : this._value.bodyWeight;
  }

  get bodyWeightChange() {
    return this._value.bodyWeightChange !== undefined && this._value.bodyWeightChange !== null ? new WeightClass(this._value.bodyWeightChange) : this._value.bodyWeightChange;
  }

  get bodyWeightChangeBaseline() {
    return this._value.bodyWeightChangeBaseline !== undefined && this._value.bodyWeightChangeBaseline !== null ? new WeightClass(this._value.bodyWeightChangeBaseline) : this._value.bodyWeightChangeBaseline;
  }

  get systolicBloodPressure() {
    return this._value.systolicBloodPressure !== undefined && this._value.systolicBloodPressure !== null ? new BloodPressureClass(this._value.systolicBloodPressure) : this._value.systolicBloodPressure;
  }

  get diastolicBloodPressure() {
    return this._value.diastolicBloodPressure !== undefined && this._value.diastolicBloodPressure !== null ? new BloodPressureClass(this._value.diastolicBloodPressure) : this._value.diastolicBloodPressure;
  }

  get heartRate() {
    return this._value.heartRate !== undefined && this._value.heartRate !== null ? new HeartRateClass(this._value.heartRate) : this._value.heartRate;
  }

  get wavelet() {
    return this._value.wavelet;
  }

  get waveletWithDefault() {
    return this._value.wavelet || [];
  }

  get bloodGlucose() {
    return this._value.bloodGlucose !== undefined && this._value.bloodGlucose !== null ? new BloodGlucoseClass(this._value.bloodGlucose) : this._value.bloodGlucose;
  }

  get beforeMeal() {
    return this._value.beforeMeal;
  }

  get beforeMealWithDefault() {
    return this._value.beforeMeal || false;
  }

  get mealType() {
    return this._value.mealType;
  }

  mealTypeIsIn(values: BgMealTypeEnum[] = []) {
    return this._value.mealType && values.includes(this._value.mealType);
  }

  get resultSource() {
    return this._value.resultSource;
  }

  resultSourceIsIn(values: ResultSourceEnum[] = []) {
    return this._value.resultSource && values.includes(this._value.resultSource);
  }
}
export class BPResultClass extends ResultClass {
  protected readonly _value: BPResult;

	constructor(value: BPResult) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }
}
export class VitalStatDetailTypeClass {
  protected readonly _value: VitalStatDetailType;

	constructor(value: VitalStatDetailType) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get count() {
    return this._value.count;
  }

  get countWithDefault() {
    return this._value.count || 0;
  }

  get average() {
    return this._value.average;
  }

  get averageWithDefault() {
    return this._value.average || [];
  }

  get range() {
    return this._value.range;
  }

  get rangeWithDefault() {
    return this._value.range || [];
  }

  get inRangePercentage() {
    return this._value.inRangePercentage;
  }

  get inRangePercentageWithDefault() {
    return this._value.inRangePercentage || 0.0;
  }
}
export class BPStatTypeClass {
  protected readonly _value: BPStatType;

	constructor(value: BPStatType) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get all() {
    return this._value.all !== undefined && this._value.all !== null ? new VitalStatDetailTypeClass(this._value.all) : this._value.all;
  }

  get overnight() {
    return this._value.overnight !== undefined && this._value.overnight !== null ? new VitalStatDetailTypeClass(this._value.overnight) : this._value.overnight;
  }

  get morning() {
    return this._value.morning !== undefined && this._value.morning !== null ? new VitalStatDetailTypeClass(this._value.morning) : this._value.morning;
  }

  get afternoon() {
    return this._value.afternoon !== undefined && this._value.afternoon !== null ? new VitalStatDetailTypeClass(this._value.afternoon) : this._value.afternoon;
  }

  get evening() {
    return this._value.evening !== undefined && this._value.evening !== null ? new VitalStatDetailTypeClass(this._value.evening) : this._value.evening;
  }

  get low() {
    return this._value.low !== undefined && this._value.low !== null ? new VitalStatDetailTypeClass(this._value.low) : this._value.low;
  }

  get normal() {
    return this._value.normal !== undefined && this._value.normal !== null ? new VitalStatDetailTypeClass(this._value.normal) : this._value.normal;
  }

  get elevated() {
    return this._value.elevated !== undefined && this._value.elevated !== null ? new VitalStatDetailTypeClass(this._value.elevated) : this._value.elevated;
  }

  get hypertension1() {
    return this._value.hypertension1 !== undefined && this._value.hypertension1 !== null ? new VitalStatDetailTypeClass(this._value.hypertension1) : this._value.hypertension1;
  }

  get hypertension2() {
    return this._value.hypertension2 !== undefined && this._value.hypertension2 !== null ? new VitalStatDetailTypeClass(this._value.hypertension2) : this._value.hypertension2;
  }

  get criticalHigh() {
    return this._value.criticalHigh !== undefined && this._value.criticalHigh !== null ? new VitalStatDetailTypeClass(this._value.criticalHigh) : this._value.criticalHigh;
  }

  get arrhythmia() {
    return this._value.arrhythmia !== undefined && this._value.arrhythmia !== null ? new VitalStatDetailTypeClass(this._value.arrhythmia) : this._value.arrhythmia;
  }

  get pulsePressure() {
    return this._value.pulsePressure !== undefined && this._value.pulsePressure !== null ? new VitalStatDetailTypeClass(this._value.pulsePressure) : this._value.pulsePressure;
  }
}
export class MonthlySummaryReportTypeClass<R, S> {
  protected readonly _value: MonthlySummaryReportType<any, any>;
	protected readonly dataConstructorFunc?: (value: any) => R;
	protected readonly statisticsConstructorFunc?: (value: any) => S;

	constructor(value: MonthlySummaryReportType<any, any>, dataConstructorFunc?: (value: any) => R, statisticsConstructorFunc?: (value: any) => S) {
		this._value = value;
		this.dataConstructorFunc = dataConstructorFunc;
		this.statisticsConstructorFunc = statisticsConstructorFunc;
	}

  get rawValue() {
    return this._value;
  }

  get data(): R {
    return this.dataConstructorFunc ? (this._value.data && this.dataConstructorFunc(this._value.data)) : this._value.data;
  }

  get dataWithDefault() {
    return this._value.data || [];
  }

  get statistics(): S {
    return this.statisticsConstructorFunc ? (this._value.statistics && this.statisticsConstructorFunc(this._value.statistics)) : this._value.statistics;
  }

  get severity() {
    return this._value.severity;
  }

  get severityWithDefault() {
    return this._value.severity || '';
  }

  get measurementDays() {
    return this._value.measurementDays;
  }

  get measurementDaysWithDefault() {
    return this._value.measurementDays || 0;
  }
}
export class BGResultClass extends ResultClass {
  protected readonly _value: BGResult;

	constructor(value: BGResult) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }
}
export class BGStatTypeClass {
  protected readonly _value: BGStatType;

	constructor(value: BGStatType) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get all() {
    return this._value.all !== undefined && this._value.all !== null ? new VitalStatDetailTypeClass(this._value.all) : this._value.all;
  }

  get fasting() {
    return this._value.fasting !== undefined && this._value.fasting !== null ? new VitalStatDetailTypeClass(this._value.fasting) : this._value.fasting;
  }

  get beforeMeal() {
    return this._value.beforeMeal !== undefined && this._value.beforeMeal !== null ? new VitalStatDetailTypeClass(this._value.beforeMeal) : this._value.beforeMeal;
  }

  get afterMeal() {
    return this._value.afterMeal !== undefined && this._value.afterMeal !== null ? new VitalStatDetailTypeClass(this._value.afterMeal) : this._value.afterMeal;
  }

  get bedtimeOvernightHypo() {
    return this._value.bedtimeOvernightHypo !== undefined && this._value.bedtimeOvernightHypo !== null ? new VitalStatDetailTypeClass(this._value.bedtimeOvernightHypo) : this._value.bedtimeOvernightHypo;
  }

  get bedtime() {
    return this._value.bedtime !== undefined && this._value.bedtime !== null ? new VitalStatDetailTypeClass(this._value.bedtime) : this._value.bedtime;
  }

  get overnight() {
    return this._value.overnight !== undefined && this._value.overnight !== null ? new VitalStatDetailTypeClass(this._value.overnight) : this._value.overnight;
  }

  get criticalHigh() {
    return this._value.criticalHigh !== undefined && this._value.criticalHigh !== null ? new VitalStatDetailTypeClass(this._value.criticalHigh) : this._value.criticalHigh;
  }

  get criticalLow() {
    return this._value.criticalLow !== undefined && this._value.criticalLow !== null ? new VitalStatDetailTypeClass(this._value.criticalLow) : this._value.criticalLow;
  }
}
export class POResultClass extends ResultClass {
  protected readonly _value: POResult;

	constructor(value: POResult) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }
}
export class POVitalStatDetailTypeClass {
  protected readonly _value: POVitalStatDetailType;

	constructor(value: POVitalStatDetailType) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get count() {
    return this._value.count;
  }

  get countWithDefault() {
    return this._value.count || 0;
  }

  get average() {
    return this._value.average;
  }

  get averageWithDefault() {
    return this._value.average || [];
  }

  get range() {
    return this._value.range;
  }

  get rangeWithDefault() {
    return this._value.range || [];
  }

  get perfusionIndexAverage() {
    return this._value.perfusionIndexAverage;
  }

  get perfusionIndexAverageWithDefault() {
    return this._value.perfusionIndexAverage || 0.0;
  }

  get pulseRateAverage() {
    return this._value.pulseRateAverage;
  }

  get pulseRateAverageWithDefault() {
    return this._value.pulseRateAverage || 0.0;
  }
}
export class POStatTypeClass {
  protected readonly _value: POStatType;

	constructor(value: POStatType) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get all() {
    return this._value.all !== undefined && this._value.all !== null ? new POVitalStatDetailTypeClass(this._value.all) : this._value.all;
  }

  get overnight() {
    return this._value.overnight !== undefined && this._value.overnight !== null ? new POVitalStatDetailTypeClass(this._value.overnight) : this._value.overnight;
  }

  get morning() {
    return this._value.morning !== undefined && this._value.morning !== null ? new POVitalStatDetailTypeClass(this._value.morning) : this._value.morning;
  }

  get afternoon() {
    return this._value.afternoon !== undefined && this._value.afternoon !== null ? new POVitalStatDetailTypeClass(this._value.afternoon) : this._value.afternoon;
  }

  get evening() {
    return this._value.evening !== undefined && this._value.evening !== null ? new POVitalStatDetailTypeClass(this._value.evening) : this._value.evening;
  }

  get normal() {
    return this._value.normal !== undefined && this._value.normal !== null ? new POVitalStatDetailTypeClass(this._value.normal) : this._value.normal;
  }

  get low() {
    return this._value.low !== undefined && this._value.low !== null ? new POVitalStatDetailTypeClass(this._value.low) : this._value.low;
  }

  get criticalLow() {
    return this._value.criticalLow !== undefined && this._value.criticalLow !== null ? new POVitalStatDetailTypeClass(this._value.criticalLow) : this._value.criticalLow;
  }

  get highestPO() {
    return this._value.highestPO;
  }

  get highestPOWithDefault() {
    return this._value.highestPO || 0;
  }
}
export class HSResultClass extends ResultClass {
  protected readonly _value: HSResult;

	constructor(value: HSResult) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }
}
export class HSVitalStatDetailTypeClass {
  protected readonly _value: HSVitalStatDetailType;

	constructor(value: HSVitalStatDetailType) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get count() {
    return this._value.count;
  }

  get countWithDefault() {
    return this._value.count || 0;
  }

  get average() {
    return this._value.average;
  }

  get averageWithDefault() {
    return this._value.average || [];
  }
}
export class HSStatTypeClass {
  protected readonly _value: HSStatType;

	constructor(value: HSStatType) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get all() {
    return this._value.all !== undefined && this._value.all !== null ? new HSVitalStatDetailTypeClass(this._value.all) : this._value.all;
  }

  get overnight() {
    return this._value.overnight !== undefined && this._value.overnight !== null ? new HSVitalStatDetailTypeClass(this._value.overnight) : this._value.overnight;
  }

  get morning() {
    return this._value.morning !== undefined && this._value.morning !== null ? new HSVitalStatDetailTypeClass(this._value.morning) : this._value.morning;
  }

  get afternoon() {
    return this._value.afternoon !== undefined && this._value.afternoon !== null ? new HSVitalStatDetailTypeClass(this._value.afternoon) : this._value.afternoon;
  }

  get evening() {
    return this._value.evening !== undefined && this._value.evening !== null ? new HSVitalStatDetailTypeClass(this._value.evening) : this._value.evening;
  }
}
export class TMResultClass extends ResultClass {
  protected readonly _value: TMResult;

	constructor(value: TMResult) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }
}
export class TMVitalStatDetailTypeClass {
  protected readonly _value: TMVitalStatDetailType;

	constructor(value: TMVitalStatDetailType) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get count() {
    return this._value.count;
  }

  get countWithDefault() {
    return this._value.count || 0;
  }

  get average() {
    return this._value.average;
  }

  get averageWithDefault() {
    return this._value.average || [];
  }
}
export class TMStatTypeClass {
  protected readonly _value: TMStatType;

	constructor(value: TMStatType) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get all() {
    return this._value.all !== undefined && this._value.all !== null ? new TMVitalStatDetailTypeClass(this._value.all) : this._value.all;
  }

  get overnight() {
    return this._value.overnight !== undefined && this._value.overnight !== null ? new TMVitalStatDetailTypeClass(this._value.overnight) : this._value.overnight;
  }

  get morning() {
    return this._value.morning !== undefined && this._value.morning !== null ? new TMVitalStatDetailTypeClass(this._value.morning) : this._value.morning;
  }

  get afternoon() {
    return this._value.afternoon !== undefined && this._value.afternoon !== null ? new TMVitalStatDetailTypeClass(this._value.afternoon) : this._value.afternoon;
  }

  get evening() {
    return this._value.evening !== undefined && this._value.evening !== null ? new TMVitalStatDetailTypeClass(this._value.evening) : this._value.evening;
  }
}
export class MonthlySummaryReportClass extends BaseModelClass {
  protected readonly _value: MonthlySummaryReport;

	constructor(value: MonthlySummaryReport) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get createdBy() {
    return this._value.createdBy;
  }

  get createdByUser() {
    return this._value.createdByUser !== undefined && this._value.createdByUser !== null ? new LimitedUserClass(this._value.createdByUser) : this._value.createdByUser;
  }

  get autoGenerated() {
    return this._value.autoGenerated;
  }

  get autoGeneratedWithDefault() {
    return this._value.autoGenerated || false;
  }

  get monthOfYear() {
    return this._value.monthOfYear;
  }

  get monthOfYearWithDefault() {
    return this._value.monthOfYear || 0;
  }

  get startDate() {
    return this._value.startDate;
  }

  get startDateWithDefault() {
    return this._value.startDate || '';
  }

  get endDate() {
    return this._value.endDate;
  }

  get endDateWithDefault() {
    return this._value.endDate || '';
  }

  get status() {
    return this._value.status;
  }

  get statusWithDefault() {
    return this._value.status || '';
  }

  get review() {
    return this._value.review !== undefined && this._value.review !== null ? new MonthlySummaryReportReviewClass(this._value.review) : this._value.review;
  }

  get reviewedAt() {
    return (this._value.reviewedAt !== undefined && this._value.reviewedAt !== null) ? dayjs(this._value.reviewedAt) : this._value.reviewedAt;
  }

  get sentChatAt() {
    return (this._value.sentChatAt !== undefined && this._value.sentChatAt !== null) ? dayjs(this._value.sentChatAt) : this._value.sentChatAt;
  }

  get sentPushNotificationAt() {
    return (this._value.sentPushNotificationAt !== undefined && this._value.sentPushNotificationAt !== null) ? dayjs(this._value.sentPushNotificationAt) : this._value.sentPushNotificationAt;
  }

  get BP() {
    return this._value.BP !== undefined && this._value.BP !== null ? new MonthlySummaryReportTypeClass(this._value.BP) : this._value.BP;
  }

  get BG() {
    return this._value.BG !== undefined && this._value.BG !== null ? new MonthlySummaryReportTypeClass(this._value.BG) : this._value.BG;
  }

  get PO() {
    return this._value.PO !== undefined && this._value.PO !== null ? new MonthlySummaryReportTypeClass(this._value.PO) : this._value.PO;
  }

  get HS() {
    return this._value.HS !== undefined && this._value.HS !== null ? new MonthlySummaryReportTypeClass(this._value.HS) : this._value.HS;
  }

  get TM() {
    return this._value.TM !== undefined && this._value.TM !== null ? new MonthlySummaryReportTypeClass(this._value.TM) : this._value.TM;
  }
}
export class MonthlySummaryReportService extends BaseController<MonthlySummaryReport, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/monthly-summary-report';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const monthlySummaryReportService = new MonthlySummaryReportService();


export const createMonthlySummaryReportGetObject = (value: APIResponse<MonthlySummaryReport>) => new APIResponseClass<MonthlySummaryReportClass>(value, (value: MonthlySummaryReport) => new MonthlySummaryReportClass(value));
export const useMonthlySummaryReportGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<MonthlySummaryReport>,
    // @ts-ignore
    typeof monthlySummaryReportService.get,
    GetOptions,
    APIResponseClass<MonthlySummaryReportClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof monthlySummaryReportService.get>) => (
      monthlySummaryReportService.get(...args)
    ),
    ResponseClassCreator: createMonthlySummaryReportGetObject,
    typeName: 'useMonthlySummaryReportGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMonthlySummaryReportGet.mock = () => new Error('Mock has not been implemented!');
useMonthlySummaryReportGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMonthlySummaryReportGet.createObject = createMonthlySummaryReportGetObject;

export const createMonthlySummaryReportUpdateObject = (value: APIResponse<MonthlySummaryReport>) => new APIResponseClass<MonthlySummaryReportClass>(value, (value: MonthlySummaryReport) => new MonthlySummaryReportClass(value));
export const useMonthlySummaryReportUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, MonthlySummaryReport>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, MonthlySummaryReport>,
    APIResponse<MonthlySummaryReport>,
    // @ts-ignore
    typeof monthlySummaryReportService.update,
    AjaxOptions,
    APIResponseClass<MonthlySummaryReportClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof monthlySummaryReportService.update>) => (
      monthlySummaryReportService.update(...args)
    ),
    ResponseClassCreator: createMonthlySummaryReportUpdateObject,
    typeName: 'useMonthlySummaryReportUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMonthlySummaryReportUpdate.mock = () => new Error('Mock has not been implemented!');
useMonthlySummaryReportUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMonthlySummaryReportUpdate.createObject = createMonthlySummaryReportUpdateObject;

export const createMonthlySummaryReportInsertObject = (value: APIResponse<MonthlySummaryReport>) => new APIResponseClass<MonthlySummaryReportClass>(value, (value: MonthlySummaryReport) => new MonthlySummaryReportClass(value));
export const useMonthlySummaryReportInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<MonthlySummaryReport>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<MonthlySummaryReport>,
    APIResponse<MonthlySummaryReport>,
    // @ts-ignore
    typeof monthlySummaryReportService.insert,
    AjaxOptions,
    APIResponseClass<MonthlySummaryReportClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof monthlySummaryReportService.insert>) => (
      monthlySummaryReportService.insert(...args)
    ),
    ResponseClassCreator: createMonthlySummaryReportInsertObject,
    typeName: 'useMonthlySummaryReportInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMonthlySummaryReportInsert.mock = () => new Error('Mock has not been implemented!');
useMonthlySummaryReportInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMonthlySummaryReportInsert.createObject = createMonthlySummaryReportInsertObject;

export const createMonthlySummaryReportDeleteObject = (value: APIResponse<MonthlySummaryReport>) => new APIResponseClass<MonthlySummaryReportClass>(value, (value: MonthlySummaryReport) => new MonthlySummaryReportClass(value));
export const useMonthlySummaryReportDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<MonthlySummaryReport>,
    // @ts-ignore
    typeof monthlySummaryReportService.delete,
    AjaxOptions,
    APIResponseClass<MonthlySummaryReportClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof monthlySummaryReportService.delete>) => (
      monthlySummaryReportService.delete(...args)
    ),
    ResponseClassCreator: createMonthlySummaryReportDeleteObject,
    typeName: 'useMonthlySummaryReportDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMonthlySummaryReportDelete.mock = () => new Error('Mock has not been implemented!');
useMonthlySummaryReportDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMonthlySummaryReportDelete.createObject = createMonthlySummaryReportDeleteObject;

export const createMonthlySummaryReportSearchObject = (value: APIResponse<PageResponse<MonthlySummaryReport>>) => new APIResponseClass<PageResponseClass<MonthlySummaryReportClass>>(value, (value: PageResponse<MonthlySummaryReport>) => new PageResponseClass<MonthlySummaryReportClass>(value, (value: MonthlySummaryReport) => new MonthlySummaryReportClass(value)));
export const useMonthlySummaryReportSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<MonthlySummaryReport>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<MonthlySummaryReport>,
    APIResponse<PageResponse<MonthlySummaryReport>>,
    // @ts-ignore
    typeof monthlySummaryReportService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<MonthlySummaryReportClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof monthlySummaryReportService.search>) => (
      monthlySummaryReportService.search(...args)
    ),
    ResponseClassCreator: createMonthlySummaryReportSearchObject,
    typeName: 'useMonthlySummaryReportSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useMonthlySummaryReportSearch.mock = () => new Error('Mock has not been implemented!');
useMonthlySummaryReportSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useMonthlySummaryReportSearch.createObject = createMonthlySummaryReportSearchObject;

export interface Customer {
	alcoholrecipienttype?: Nullable<string>;
	altname?: Nullable<string>;
	balancesearch?: Nullable<string>;
	companyname?: Nullable<string>;
	consolbalancesearch?: Nullable<string>;
	consoldaysoverduesearch?: Nullable<string>;
	consoloverduebalancesearch?: Nullable<string>;
	consolunbilledorderssearch?: Nullable<string>;
	creditholdoverride?: Nullable<string>;
	currency?: Nullable<string>;
	custentity3?: Nullable<string>;
	custentity_2663_customer_refund?: Nullable<string>;
	custentity_2663_direct_debit?: Nullable<string>;
	custentity_additional_to_recipients?: Nullable<string>;
	custentity_atlas_high_impact?: Nullable<string>;
	custentity_cc_recipients?: Nullable<string>;
	custentity_cps_bdc_lastupdatedbyimport?: Nullable<string>;
	custentity_custom_customerstype?: Nullable<string>;
	custentity_ihealth_integration_01?: Nullable<string>;
	custentity_ihealth_integration_02?: Nullable<string>;
	custentity_ihealth_integration_03?: Nullable<string>;
	custentity_ihealth_integration_04?: Nullable<string>;
	custentity_ihealth_integration_05?: Nullable<string>;
	custentity_ihn_auto_email?: Nullable<string>;
	custentity_ihn_quarterly_statement?: Nullable<string>;
	custentity_naw_trans_need_approval?: Nullable<string>;
	custentity_paystand_payerid?: Nullable<string>;
	custentity_paystand_payment_token?: Nullable<string>;
	custentity_ps_autopay_status?: Nullable<string>;
	custentity_ps_autopay_token_date_updated?: Nullable<string>;
	custentity_ps_do_not_send_email_notifi?: Nullable<string>;
	custentity_wmsse_asn_required?: Nullable<string>;
	dateclosed?: Nullable<string>;
	datecreated?: Nullable<string>;
	defaultbillingaddress?: Nullable<string>;
	duplicate?: Nullable<string>;
	email?: Nullable<string>;
	emailpreference?: Nullable<string>;
	emailtransactions?: Nullable<string>;
	entityid?: Nullable<string>;
	entitynumber?: Nullable<string>;
	entitystatus?: Nullable<string>;
	entitytitle?: Nullable<string>;
	faxtransactions?: Nullable<string>;
	firstsaledate?: Nullable<string>;
	giveaccess?: Nullable<string>;
	globalsubscriptionstatus?: Nullable<string>;
	id?: Nullable<string>;
	isautogeneratedrepresentingentity?: Nullable<string>;
	isbudgetapproved?: Nullable<string>;
	isinactive?: Nullable<string>;
	isperson?: Nullable<string>;
	language?: Nullable<string>;
	lastmodifieddate?: Nullable<string>;
	lastsaledate?: Nullable<string>;
	oncredithold?: Nullable<string>;
	overduebalancesearch?: Nullable<string>;
	printtransactions?: Nullable<string>;
	probability?: Nullable<string>;
	receivablesaccount?: Nullable<string>;
	searchstage?: Nullable<string>;
	shipcomplete?: Nullable<string>;
	shippingcarrier?: Nullable<string>;
	terms?: Nullable<string>;
	thirdpartycountry?: Nullable<string>;
	unbilledorderssearch?: Nullable<string>;
	weblead?: Nullable<string>;
}
export class CustomerClass {
  protected readonly _value: Customer;

	constructor(value: Customer) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get alcoholrecipienttype() {
    return this._value.alcoholrecipienttype;
  }

  get alcoholrecipienttypeWithDefault() {
    return this._value.alcoholrecipienttype || '';
  }

  get altname() {
    return this._value.altname;
  }

  get altnameWithDefault() {
    return this._value.altname || '';
  }

  get balancesearch() {
    return this._value.balancesearch;
  }

  get balancesearchWithDefault() {
    return this._value.balancesearch || '';
  }

  get companyname() {
    return this._value.companyname;
  }

  get companynameWithDefault() {
    return this._value.companyname || '';
  }

  get consolbalancesearch() {
    return this._value.consolbalancesearch;
  }

  get consolbalancesearchWithDefault() {
    return this._value.consolbalancesearch || '';
  }

  get consoldaysoverduesearch() {
    return this._value.consoldaysoverduesearch;
  }

  get consoldaysoverduesearchWithDefault() {
    return this._value.consoldaysoverduesearch || '';
  }

  get consoloverduebalancesearch() {
    return this._value.consoloverduebalancesearch;
  }

  get consoloverduebalancesearchWithDefault() {
    return this._value.consoloverduebalancesearch || '';
  }

  get consolunbilledorderssearch() {
    return this._value.consolunbilledorderssearch;
  }

  get consolunbilledorderssearchWithDefault() {
    return this._value.consolunbilledorderssearch || '';
  }

  get creditholdoverride() {
    return this._value.creditholdoverride;
  }

  get creditholdoverrideWithDefault() {
    return this._value.creditholdoverride || '';
  }

  get currency() {
    return this._value.currency;
  }

  get currencyWithDefault() {
    return this._value.currency || '';
  }

  get custentity3() {
    return this._value.custentity3;
  }

  get custentity3WithDefault() {
    return this._value.custentity3 || '';
  }

  get custentity_2663_customer_refund() {
    return this._value.custentity_2663_customer_refund;
  }

  get custentity_2663_customer_refundWithDefault() {
    return this._value.custentity_2663_customer_refund || '';
  }

  get custentity_2663_direct_debit() {
    return this._value.custentity_2663_direct_debit;
  }

  get custentity_2663_direct_debitWithDefault() {
    return this._value.custentity_2663_direct_debit || '';
  }

  get custentity_additional_to_recipients() {
    return this._value.custentity_additional_to_recipients;
  }

  get custentity_additional_to_recipientsWithDefault() {
    return this._value.custentity_additional_to_recipients || '';
  }

  get custentity_atlas_high_impact() {
    return this._value.custentity_atlas_high_impact;
  }

  get custentity_atlas_high_impactWithDefault() {
    return this._value.custentity_atlas_high_impact || '';
  }

  get custentity_cc_recipients() {
    return this._value.custentity_cc_recipients;
  }

  get custentity_cc_recipientsWithDefault() {
    return this._value.custentity_cc_recipients || '';
  }

  get custentity_cps_bdc_lastupdatedbyimport() {
    return this._value.custentity_cps_bdc_lastupdatedbyimport;
  }

  get custentity_cps_bdc_lastupdatedbyimportWithDefault() {
    return this._value.custentity_cps_bdc_lastupdatedbyimport || '';
  }

  get custentity_custom_customerstype() {
    return this._value.custentity_custom_customerstype;
  }

  get custentity_custom_customerstypeWithDefault() {
    return this._value.custentity_custom_customerstype || '';
  }

  get custentity_ihealth_integration_01() {
    return this._value.custentity_ihealth_integration_01;
  }

  get custentity_ihealth_integration_01WithDefault() {
    return this._value.custentity_ihealth_integration_01 || '';
  }

  get custentity_ihealth_integration_02() {
    return this._value.custentity_ihealth_integration_02;
  }

  get custentity_ihealth_integration_02WithDefault() {
    return this._value.custentity_ihealth_integration_02 || '';
  }

  get custentity_ihealth_integration_03() {
    return this._value.custentity_ihealth_integration_03;
  }

  get custentity_ihealth_integration_03WithDefault() {
    return this._value.custentity_ihealth_integration_03 || '';
  }

  get custentity_ihealth_integration_04() {
    return this._value.custentity_ihealth_integration_04;
  }

  get custentity_ihealth_integration_04WithDefault() {
    return this._value.custentity_ihealth_integration_04 || '';
  }

  get custentity_ihealth_integration_05() {
    return this._value.custentity_ihealth_integration_05;
  }

  get custentity_ihealth_integration_05WithDefault() {
    return this._value.custentity_ihealth_integration_05 || '';
  }

  get custentity_ihn_auto_email() {
    return this._value.custentity_ihn_auto_email;
  }

  get custentity_ihn_auto_emailWithDefault() {
    return this._value.custentity_ihn_auto_email || '';
  }

  get custentity_ihn_quarterly_statement() {
    return this._value.custentity_ihn_quarterly_statement;
  }

  get custentity_ihn_quarterly_statementWithDefault() {
    return this._value.custentity_ihn_quarterly_statement || '';
  }

  get custentity_naw_trans_need_approval() {
    return this._value.custentity_naw_trans_need_approval;
  }

  get custentity_naw_trans_need_approvalWithDefault() {
    return this._value.custentity_naw_trans_need_approval || '';
  }

  get custentity_paystand_payerid() {
    return this._value.custentity_paystand_payerid;
  }

  get custentity_paystand_payeridWithDefault() {
    return this._value.custentity_paystand_payerid || '';
  }

  get custentity_paystand_payment_token() {
    return this._value.custentity_paystand_payment_token;
  }

  get custentity_paystand_payment_tokenWithDefault() {
    return this._value.custentity_paystand_payment_token || '';
  }

  get custentity_ps_autopay_status() {
    return this._value.custentity_ps_autopay_status;
  }

  get custentity_ps_autopay_statusWithDefault() {
    return this._value.custentity_ps_autopay_status || '';
  }

  get custentity_ps_autopay_token_date_updated() {
    return this._value.custentity_ps_autopay_token_date_updated;
  }

  get custentity_ps_autopay_token_date_updatedWithDefault() {
    return this._value.custentity_ps_autopay_token_date_updated || '';
  }

  get custentity_ps_do_not_send_email_notifi() {
    return this._value.custentity_ps_do_not_send_email_notifi;
  }

  get custentity_ps_do_not_send_email_notifiWithDefault() {
    return this._value.custentity_ps_do_not_send_email_notifi || '';
  }

  get custentity_wmsse_asn_required() {
    return this._value.custentity_wmsse_asn_required;
  }

  get custentity_wmsse_asn_requiredWithDefault() {
    return this._value.custentity_wmsse_asn_required || '';
  }

  get dateclosed() {
    return this._value.dateclosed;
  }

  get dateclosedWithDefault() {
    return this._value.dateclosed || '';
  }

  get datecreated() {
    return this._value.datecreated;
  }

  get datecreatedWithDefault() {
    return this._value.datecreated || '';
  }

  get defaultbillingaddress() {
    return this._value.defaultbillingaddress;
  }

  get defaultbillingaddressWithDefault() {
    return this._value.defaultbillingaddress || '';
  }

  get duplicate() {
    return this._value.duplicate;
  }

  get duplicateWithDefault() {
    return this._value.duplicate || '';
  }

  get email() {
    return this._value.email;
  }

  get emailWithDefault() {
    return this._value.email || '';
  }

  get emailpreference() {
    return this._value.emailpreference;
  }

  get emailpreferenceWithDefault() {
    return this._value.emailpreference || '';
  }

  get emailtransactions() {
    return this._value.emailtransactions;
  }

  get emailtransactionsWithDefault() {
    return this._value.emailtransactions || '';
  }

  get entityid() {
    return this._value.entityid;
  }

  get entityidWithDefault() {
    return this._value.entityid || '';
  }

  get entitynumber() {
    return this._value.entitynumber;
  }

  get entitynumberWithDefault() {
    return this._value.entitynumber || '';
  }

  get entitystatus() {
    return this._value.entitystatus;
  }

  get entitystatusWithDefault() {
    return this._value.entitystatus || '';
  }

  get entitytitle() {
    return this._value.entitytitle;
  }

  get entitytitleWithDefault() {
    return this._value.entitytitle || '';
  }

  get faxtransactions() {
    return this._value.faxtransactions;
  }

  get faxtransactionsWithDefault() {
    return this._value.faxtransactions || '';
  }

  get firstsaledate() {
    return this._value.firstsaledate;
  }

  get firstsaledateWithDefault() {
    return this._value.firstsaledate || '';
  }

  get giveaccess() {
    return this._value.giveaccess;
  }

  get giveaccessWithDefault() {
    return this._value.giveaccess || '';
  }

  get globalsubscriptionstatus() {
    return this._value.globalsubscriptionstatus;
  }

  get globalsubscriptionstatusWithDefault() {
    return this._value.globalsubscriptionstatus || '';
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get isautogeneratedrepresentingentity() {
    return this._value.isautogeneratedrepresentingentity;
  }

  get isautogeneratedrepresentingentityWithDefault() {
    return this._value.isautogeneratedrepresentingentity || '';
  }

  get isbudgetapproved() {
    return this._value.isbudgetapproved;
  }

  get isbudgetapprovedWithDefault() {
    return this._value.isbudgetapproved || '';
  }

  get isinactive() {
    return this._value.isinactive;
  }

  get isinactiveWithDefault() {
    return this._value.isinactive || '';
  }

  get isperson() {
    return this._value.isperson;
  }

  get ispersonWithDefault() {
    return this._value.isperson || '';
  }

  get language() {
    return this._value.language;
  }

  get languageWithDefault() {
    return this._value.language || '';
  }

  get lastmodifieddate() {
    return this._value.lastmodifieddate;
  }

  get lastmodifieddateWithDefault() {
    return this._value.lastmodifieddate || '';
  }

  get lastsaledate() {
    return this._value.lastsaledate;
  }

  get lastsaledateWithDefault() {
    return this._value.lastsaledate || '';
  }

  get oncredithold() {
    return this._value.oncredithold;
  }

  get oncreditholdWithDefault() {
    return this._value.oncredithold || '';
  }

  get overduebalancesearch() {
    return this._value.overduebalancesearch;
  }

  get overduebalancesearchWithDefault() {
    return this._value.overduebalancesearch || '';
  }

  get printtransactions() {
    return this._value.printtransactions;
  }

  get printtransactionsWithDefault() {
    return this._value.printtransactions || '';
  }

  get probability() {
    return this._value.probability;
  }

  get probabilityWithDefault() {
    return this._value.probability || '';
  }

  get receivablesaccount() {
    return this._value.receivablesaccount;
  }

  get receivablesaccountWithDefault() {
    return this._value.receivablesaccount || '';
  }

  get searchstage() {
    return this._value.searchstage;
  }

  get searchstageWithDefault() {
    return this._value.searchstage || '';
  }

  get shipcomplete() {
    return this._value.shipcomplete;
  }

  get shipcompleteWithDefault() {
    return this._value.shipcomplete || '';
  }

  get shippingcarrier() {
    return this._value.shippingcarrier;
  }

  get shippingcarrierWithDefault() {
    return this._value.shippingcarrier || '';
  }

  get terms() {
    return this._value.terms;
  }

  get termsWithDefault() {
    return this._value.terms || '';
  }

  get thirdpartycountry() {
    return this._value.thirdpartycountry;
  }

  get thirdpartycountryWithDefault() {
    return this._value.thirdpartycountry || '';
  }

  get unbilledorderssearch() {
    return this._value.unbilledorderssearch;
  }

  get unbilledorderssearchWithDefault() {
    return this._value.unbilledorderssearch || '';
  }

  get weblead() {
    return this._value.weblead;
  }

  get webleadWithDefault() {
    return this._value.weblead || '';
  }
}
export enum InvoiceStatusEnum {
	UNPAID = 'UNPAID',
	PAID = 'PAID',
	PARTIALLY_PAID = 'PARTIALLY_PAID',
}
export interface Transaction {
	abbrevtype?: Nullable<string>;
	balsegstatus?: Nullable<string>;
	basetaxtotal?: Nullable<string>;
	billingaddress?: Nullable<string>;
	billingstatus?: Nullable<string>;
	closedate?: Nullable<string>;
	createddate?: Nullable<string>;
	currency?: Nullable<string>;
	custbody1?: Nullable<string>;
	customform?: Nullable<string>;
	customtype?: Nullable<string>;
	daysopen?: Nullable<string>;
	daysoverduesearch?: Nullable<string>;
	duedate?: Nullable<string>;
	email?: Nullable<string>;
	entity?: Nullable<string>;
	estgrossprofit?: Nullable<string>;
	estgrossprofitpercent?: Nullable<string>;
	exchangerate?: Nullable<string>;
	externalid?: Nullable<string>;
	foreignamountpaid?: Nullable<string>;
	foreignamountunpaid?: Nullable<string>;
	foreigntotal?: Nullable<string>;
	id?: Nullable<string>;
	isfinchrg?: Nullable<string>;
	isreversal?: Nullable<string>;
	lastmodifiedby?: Nullable<string>;
	lastmodifieddate?: Nullable<string>;
	legacytax?: Nullable<string>;
	memo?: Nullable<string>;
	nexus?: Nullable<string>;
	number?: Nullable<string>;
	ordpicked?: Nullable<string>;
	otherrefnum?: Nullable<string>;
	outsourced?: Nullable<string>;
	paymenthold?: Nullable<string>;
	posting?: Nullable<string>;
	postingperiod?: Nullable<string>;
	printedpickingticket?: Nullable<string>;
	recordtype?: Nullable<string>;
	shipcomplete?: Nullable<string>;
	shipdate?: Nullable<string>;
	shippingaddress?: Nullable<string>;
	source?: Nullable<string>;
	status?: Nullable<string>;
	subsidiarytaxregnum?: Nullable<string>;
	taxdetailsoverride?: Nullable<string>;
	taxpointdate?: Nullable<string>;
	taxpointdateoverride?: Nullable<string>;
	taxregoverride?: Nullable<string>;
	taxtotal?: Nullable<string>;
	terms?: Nullable<string>;
	tobeprinted?: Nullable<string>;
	totalcostestimate?: Nullable<string>;
	trandate?: Nullable<string>;
	trandisplayname?: Nullable<string>;
	tranid?: Nullable<string>;
	transactionnumber?: Nullable<string>;
	type?: Nullable<string>;
	typebaseddocumentnumber?: Nullable<string>;
	userevenuearrangement?: Nullable<string>;
	visibletocustomer?: Nullable<string>;
	voided?: Nullable<string>;
	invoiceStatus?: Nullable<InvoiceStatusEnum>;
	invoiceStatusIn?: Nullable<InFilter<InvoiceStatusEnum>>;
}
export class TransactionClass {
  protected readonly _value: Transaction;

	constructor(value: Transaction) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get abbrevtype() {
    return this._value.abbrevtype;
  }

  get abbrevtypeWithDefault() {
    return this._value.abbrevtype || '';
  }

  get balsegstatus() {
    return this._value.balsegstatus;
  }

  get balsegstatusWithDefault() {
    return this._value.balsegstatus || '';
  }

  get basetaxtotal() {
    return this._value.basetaxtotal;
  }

  get basetaxtotalWithDefault() {
    return this._value.basetaxtotal || '';
  }

  get billingaddress() {
    return this._value.billingaddress;
  }

  get billingaddressWithDefault() {
    return this._value.billingaddress || '';
  }

  get billingstatus() {
    return this._value.billingstatus;
  }

  get billingstatusWithDefault() {
    return this._value.billingstatus || '';
  }

  get closedate() {
    return this._value.closedate;
  }

  get closedateWithDefault() {
    return this._value.closedate || '';
  }

  get createddate() {
    return this._value.createddate;
  }

  get createddateWithDefault() {
    return this._value.createddate || '';
  }

  get currency() {
    return this._value.currency;
  }

  get currencyWithDefault() {
    return this._value.currency || '';
  }

  get custbody1() {
    return this._value.custbody1;
  }

  get custbody1WithDefault() {
    return this._value.custbody1 || '';
  }

  get customform() {
    return this._value.customform;
  }

  get customformWithDefault() {
    return this._value.customform || '';
  }

  get customtype() {
    return this._value.customtype;
  }

  get customtypeWithDefault() {
    return this._value.customtype || '';
  }

  get daysopen() {
    return this._value.daysopen;
  }

  get daysopenWithDefault() {
    return this._value.daysopen || '';
  }

  get daysoverduesearch() {
    return this._value.daysoverduesearch;
  }

  get daysoverduesearchWithDefault() {
    return this._value.daysoverduesearch || '';
  }

  get duedate() {
    return this._value.duedate;
  }

  get duedateWithDefault() {
    return this._value.duedate || '';
  }

  get email() {
    return this._value.email;
  }

  get emailWithDefault() {
    return this._value.email || '';
  }

  get entity() {
    return this._value.entity;
  }

  get entityWithDefault() {
    return this._value.entity || '';
  }

  get estgrossprofit() {
    return this._value.estgrossprofit;
  }

  get estgrossprofitWithDefault() {
    return this._value.estgrossprofit || '';
  }

  get estgrossprofitpercent() {
    return this._value.estgrossprofitpercent;
  }

  get estgrossprofitpercentWithDefault() {
    return this._value.estgrossprofitpercent || '';
  }

  get exchangerate() {
    return this._value.exchangerate;
  }

  get exchangerateWithDefault() {
    return this._value.exchangerate || '';
  }

  get externalid() {
    return this._value.externalid;
  }

  get externalidWithDefault() {
    return this._value.externalid || '';
  }

  get foreignamountpaid() {
    return this._value.foreignamountpaid;
  }

  get foreignamountpaidWithDefault() {
    return this._value.foreignamountpaid || '';
  }

  get foreignamountunpaid() {
    return this._value.foreignamountunpaid;
  }

  get foreignamountunpaidWithDefault() {
    return this._value.foreignamountunpaid || '';
  }

  get foreigntotal() {
    return this._value.foreigntotal;
  }

  get foreigntotalWithDefault() {
    return this._value.foreigntotal || '';
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get isfinchrg() {
    return this._value.isfinchrg;
  }

  get isfinchrgWithDefault() {
    return this._value.isfinchrg || '';
  }

  get isreversal() {
    return this._value.isreversal;
  }

  get isreversalWithDefault() {
    return this._value.isreversal || '';
  }

  get lastmodifiedby() {
    return this._value.lastmodifiedby;
  }

  get lastmodifiedbyWithDefault() {
    return this._value.lastmodifiedby || '';
  }

  get lastmodifieddate() {
    return this._value.lastmodifieddate;
  }

  get lastmodifieddateWithDefault() {
    return this._value.lastmodifieddate || '';
  }

  get legacytax() {
    return this._value.legacytax;
  }

  get legacytaxWithDefault() {
    return this._value.legacytax || '';
  }

  get memo() {
    return this._value.memo;
  }

  get memoWithDefault() {
    return this._value.memo || '';
  }

  get nexus() {
    return this._value.nexus;
  }

  get nexusWithDefault() {
    return this._value.nexus || '';
  }

  get number() {
    return this._value.number;
  }

  get numberWithDefault() {
    return this._value.number || '';
  }

  get ordpicked() {
    return this._value.ordpicked;
  }

  get ordpickedWithDefault() {
    return this._value.ordpicked || '';
  }

  get otherrefnum() {
    return this._value.otherrefnum;
  }

  get otherrefnumWithDefault() {
    return this._value.otherrefnum || '';
  }

  get outsourced() {
    return this._value.outsourced;
  }

  get outsourcedWithDefault() {
    return this._value.outsourced || '';
  }

  get paymenthold() {
    return this._value.paymenthold;
  }

  get paymentholdWithDefault() {
    return this._value.paymenthold || '';
  }

  get posting() {
    return this._value.posting;
  }

  get postingWithDefault() {
    return this._value.posting || '';
  }

  get postingperiod() {
    return this._value.postingperiod;
  }

  get postingperiodWithDefault() {
    return this._value.postingperiod || '';
  }

  get printedpickingticket() {
    return this._value.printedpickingticket;
  }

  get printedpickingticketWithDefault() {
    return this._value.printedpickingticket || '';
  }

  get recordtype() {
    return this._value.recordtype;
  }

  get recordtypeWithDefault() {
    return this._value.recordtype || '';
  }

  get shipcomplete() {
    return this._value.shipcomplete;
  }

  get shipcompleteWithDefault() {
    return this._value.shipcomplete || '';
  }

  get shipdate() {
    return this._value.shipdate;
  }

  get shipdateWithDefault() {
    return this._value.shipdate || '';
  }

  get shippingaddress() {
    return this._value.shippingaddress;
  }

  get shippingaddressWithDefault() {
    return this._value.shippingaddress || '';
  }

  get source() {
    return this._value.source;
  }

  get sourceWithDefault() {
    return this._value.source || '';
  }

  get status() {
    return this._value.status;
  }

  get statusWithDefault() {
    return this._value.status || '';
  }

  get subsidiarytaxregnum() {
    return this._value.subsidiarytaxregnum;
  }

  get subsidiarytaxregnumWithDefault() {
    return this._value.subsidiarytaxregnum || '';
  }

  get taxdetailsoverride() {
    return this._value.taxdetailsoverride;
  }

  get taxdetailsoverrideWithDefault() {
    return this._value.taxdetailsoverride || '';
  }

  get taxpointdate() {
    return this._value.taxpointdate;
  }

  get taxpointdateWithDefault() {
    return this._value.taxpointdate || '';
  }

  get taxpointdateoverride() {
    return this._value.taxpointdateoverride;
  }

  get taxpointdateoverrideWithDefault() {
    return this._value.taxpointdateoverride || '';
  }

  get taxregoverride() {
    return this._value.taxregoverride;
  }

  get taxregoverrideWithDefault() {
    return this._value.taxregoverride || '';
  }

  get taxtotal() {
    return this._value.taxtotal;
  }

  get taxtotalWithDefault() {
    return this._value.taxtotal || '';
  }

  get terms() {
    return this._value.terms;
  }

  get termsWithDefault() {
    return this._value.terms || '';
  }

  get tobeprinted() {
    return this._value.tobeprinted;
  }

  get tobeprintedWithDefault() {
    return this._value.tobeprinted || '';
  }

  get totalcostestimate() {
    return this._value.totalcostestimate;
  }

  get totalcostestimateWithDefault() {
    return this._value.totalcostestimate || '';
  }

  get trandate() {
    return this._value.trandate;
  }

  get trandateWithDefault() {
    return this._value.trandate || '';
  }

  get trandisplayname() {
    return this._value.trandisplayname;
  }

  get trandisplaynameWithDefault() {
    return this._value.trandisplayname || '';
  }

  get tranid() {
    return this._value.tranid;
  }

  get tranidWithDefault() {
    return this._value.tranid || '';
  }

  get transactionnumber() {
    return this._value.transactionnumber;
  }

  get transactionnumberWithDefault() {
    return this._value.transactionnumber || '';
  }

  get type() {
    return this._value.type;
  }

  get typeWithDefault() {
    return this._value.type || '';
  }

  get typebaseddocumentnumber() {
    return this._value.typebaseddocumentnumber;
  }

  get typebaseddocumentnumberWithDefault() {
    return this._value.typebaseddocumentnumber || '';
  }

  get userevenuearrangement() {
    return this._value.userevenuearrangement;
  }

  get userevenuearrangementWithDefault() {
    return this._value.userevenuearrangement || '';
  }

  get visibletocustomer() {
    return this._value.visibletocustomer;
  }

  get visibletocustomerWithDefault() {
    return this._value.visibletocustomer || '';
  }

  get voided() {
    return this._value.voided;
  }

  get voidedWithDefault() {
    return this._value.voided || '';
  }

  get invoiceStatus() {
    return this._value.invoiceStatus;
  }

  invoiceStatusIsIn(values: InvoiceStatusEnum[] = []) {
    return this._value.invoiceStatus && values.includes(this._value.invoiceStatus);
  }

  get invoiceStatusIn() {
    return this._value.invoiceStatusIn !== undefined && this._value.invoiceStatusIn !== null ? new InFilterClass(this._value.invoiceStatusIn) : this._value.invoiceStatusIn;
  }
}
export interface PdfRequest {
	invoiceId?: Nullable<string>;
	pdfUrl?: Nullable<string>;
}
export interface NetSuiteGetUserMedicalOrgsParams {
}

export interface NetSuiteGetCustomerParams {
	entityId: string;
}

export interface NetSuiteGetInvoiceByOrgIdParams {
	id: string;
	searchRequest: SearchRequest<Transaction>;
}

export interface NetSuiteGetPayLinkParams {
	id: string;
}

export interface NetSuiteGetInvoicePdfLinkParams {
	invoiceId: string;
}

export interface NetSuiteGetUserOpenInvoiceCountParams {
}

export interface NetSuitePdfCallbackParams {
	request: PdfRequest;
}

export class NetSuiteService {
  protected baseURL = '';

  protected basePath = '/v1/uc/netsuite';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public getUserMedicalOrgs({ baseUrl, params, headers }: RequestOption<NetSuiteGetUserMedicalOrgsParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<MedicalOrganization[]>>(`${baseUrl || this.baseURL}${this.basePath}/medical-org${getQueryStrings(queryList)}`, { headers });
	}

	public getCustomer({ baseUrl, params, headers }: RequestOption<NetSuiteGetCustomerParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PageResponse<Customer>>>(`${baseUrl || this.baseURL}${this.basePath}/customer/${params.entityId}${getQueryStrings(queryList)}`, { headers });
	}

	public getInvoiceByOrgId({ baseUrl, params, headers }: RequestOption<NetSuiteGetInvoiceByOrgIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<Transaction>>>(`${baseUrl || this.baseURL}${this.basePath}/medical-org/${params.id}/invoice${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getPayLink({ baseUrl, params, headers }: RequestOption<NetSuiteGetPayLinkParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/medical-org/${params.id}/payment-link${getQueryStrings(queryList)}`, { headers });
	}

	public getInvoicePdfLink({ baseUrl, params, headers }: RequestOption<NetSuiteGetInvoicePdfLinkParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/invoice-pdf-link/${params.invoiceId}${getQueryStrings(queryList)}`, { headers });
	}

	public getUserOpenInvoiceCount({ baseUrl, params, headers }: RequestOption<NetSuiteGetUserOpenInvoiceCountParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<number>>(`${baseUrl || this.baseURL}${this.basePath}/invoice/count${getQueryStrings(queryList)}`, { headers });
	}

	public pdfCallback({ baseUrl, params, headers }: RequestOption<NetSuitePdfCallbackParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/callback${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const netSuiteService = new NetSuiteService();

export const createNetSuiteGetUserMedicalOrgsObject = (value: APIResponse<MedicalOrganization[]>) => new APIResponseClass<MedicalOrganizationClass[]>(value, (v) => map(v, (value: MedicalOrganization) => new MedicalOrganizationClass(value)));

export const useNetSuiteGetUserMedicalOrgs = <RCN = undefined>(configs: MakeRequestHookConfig<NetSuiteGetUserMedicalOrgsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    NetSuiteGetUserMedicalOrgsParams,
    APIResponse<MedicalOrganization[]>,
    typeof netSuiteService.getUserMedicalOrgs,
    GetOptions,
    APIResponseClass<MedicalOrganizationClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof netSuiteService.getUserMedicalOrgs>) => (
      netSuiteService.getUserMedicalOrgs(...args)
    ),
    ResponseClassCreator: createNetSuiteGetUserMedicalOrgsObject,
    typeName: 'useNetSuiteGetUserMedicalOrgs',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useNetSuiteGetUserMedicalOrgs.mock = () => new Error('Mock has not been implemented!');
useNetSuiteGetUserMedicalOrgs.getLastMockedValue = () => new Error('Mock has not been implemented!');
useNetSuiteGetUserMedicalOrgs.createObject = createNetSuiteGetUserMedicalOrgsObject;

export const createNetSuiteGetCustomerObject = (value: APIResponse<PageResponse<Customer>>) => new APIResponseClass<PageResponseClass<CustomerClass>>(value, (value: PageResponse<Customer>) => new PageResponseClass<CustomerClass>(value, (value: Customer) => new CustomerClass(value)));

export const useNetSuiteGetCustomer = <RCN = undefined>(configs: MakeRequestHookConfig<NetSuiteGetCustomerParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    NetSuiteGetCustomerParams,
    APIResponse<PageResponse<Customer>>,
    typeof netSuiteService.getCustomer,
    GetOptions,
    APIResponseClass<PageResponseClass<CustomerClass>>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof netSuiteService.getCustomer>) => (
      netSuiteService.getCustomer(...args)
    ),
    ResponseClassCreator: createNetSuiteGetCustomerObject,
    typeName: 'useNetSuiteGetCustomer',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useNetSuiteGetCustomer.mock = () => new Error('Mock has not been implemented!');
useNetSuiteGetCustomer.getLastMockedValue = () => new Error('Mock has not been implemented!');
useNetSuiteGetCustomer.createObject = createNetSuiteGetCustomerObject;

export const createNetSuiteGetInvoiceByOrgIdObject = (value: APIResponse<PageResponse<Transaction>>) => new APIResponseClass<PageResponseClass<TransactionClass>>(value, (value: PageResponse<Transaction>) => new PageResponseClass<TransactionClass>(value, (value: Transaction) => new TransactionClass(value)));

export const useNetSuiteGetInvoiceByOrgId = <RCN = undefined>(configs: MakeRequestHookConfig<NetSuiteGetInvoiceByOrgIdParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    NetSuiteGetInvoiceByOrgIdParams,
    APIResponse<PageResponse<Transaction>>,
    typeof netSuiteService.getInvoiceByOrgId,
    AjaxOptions,
    APIResponseClass<PageResponseClass<TransactionClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof netSuiteService.getInvoiceByOrgId>) => (
      netSuiteService.getInvoiceByOrgId(...args)
    ),
    ResponseClassCreator: createNetSuiteGetInvoiceByOrgIdObject,
    typeName: 'useNetSuiteGetInvoiceByOrgId',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useNetSuiteGetInvoiceByOrgId.mock = () => new Error('Mock has not been implemented!');
useNetSuiteGetInvoiceByOrgId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useNetSuiteGetInvoiceByOrgId.createObject = createNetSuiteGetInvoiceByOrgIdObject;

export const createNetSuiteGetPayLinkObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useNetSuiteGetPayLink = <RCN = undefined>(configs: MakeRequestHookConfig<NetSuiteGetPayLinkParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    NetSuiteGetPayLinkParams,
    APIResponse<string>,
    typeof netSuiteService.getPayLink,
    GetOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof netSuiteService.getPayLink>) => (
      netSuiteService.getPayLink(...args)
    ),
    ResponseClassCreator: createNetSuiteGetPayLinkObject,
    typeName: 'useNetSuiteGetPayLink',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useNetSuiteGetPayLink.mock = () => new Error('Mock has not been implemented!');
useNetSuiteGetPayLink.getLastMockedValue = () => new Error('Mock has not been implemented!');
useNetSuiteGetPayLink.createObject = createNetSuiteGetPayLinkObject;

export const createNetSuiteGetInvoicePdfLinkObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useNetSuiteGetInvoicePdfLink = <RCN = undefined>(configs: MakeRequestHookConfig<NetSuiteGetInvoicePdfLinkParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    NetSuiteGetInvoicePdfLinkParams,
    APIResponse<string>,
    typeof netSuiteService.getInvoicePdfLink,
    GetOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof netSuiteService.getInvoicePdfLink>) => (
      netSuiteService.getInvoicePdfLink(...args)
    ),
    ResponseClassCreator: createNetSuiteGetInvoicePdfLinkObject,
    typeName: 'useNetSuiteGetInvoicePdfLink',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useNetSuiteGetInvoicePdfLink.mock = () => new Error('Mock has not been implemented!');
useNetSuiteGetInvoicePdfLink.getLastMockedValue = () => new Error('Mock has not been implemented!');
useNetSuiteGetInvoicePdfLink.createObject = createNetSuiteGetInvoicePdfLinkObject;

export const createNetSuiteGetUserOpenInvoiceCountObject = (value: APIResponse<number>) => new APIResponseClass<number>(value, (v) => (v));

export const useNetSuiteGetUserOpenInvoiceCount = <RCN = undefined>(configs: MakeRequestHookConfig<NetSuiteGetUserOpenInvoiceCountParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    NetSuiteGetUserOpenInvoiceCountParams,
    APIResponse<number>,
    typeof netSuiteService.getUserOpenInvoiceCount,
    GetOptions,
    APIResponseClass<number>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof netSuiteService.getUserOpenInvoiceCount>) => (
      netSuiteService.getUserOpenInvoiceCount(...args)
    ),
    ResponseClassCreator: createNetSuiteGetUserOpenInvoiceCountObject,
    typeName: 'useNetSuiteGetUserOpenInvoiceCount',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useNetSuiteGetUserOpenInvoiceCount.mock = () => new Error('Mock has not been implemented!');
useNetSuiteGetUserOpenInvoiceCount.getLastMockedValue = () => new Error('Mock has not been implemented!');
useNetSuiteGetUserOpenInvoiceCount.createObject = createNetSuiteGetUserOpenInvoiceCountObject;

export const createNetSuitePdfCallbackObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useNetSuitePdfCallback = <RCN = undefined>(configs: MakeRequestHookConfig<NetSuitePdfCallbackParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    NetSuitePdfCallbackParams,
    APIResponse<string>,
    typeof netSuiteService.pdfCallback,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof netSuiteService.pdfCallback>) => (
      netSuiteService.pdfCallback(...args)
    ),
    ResponseClassCreator: createNetSuitePdfCallbackObject,
    typeName: 'useNetSuitePdfCallback',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useNetSuitePdfCallback.mock = () => new Error('Mock has not been implemented!');
useNetSuitePdfCallback.getLastMockedValue = () => new Error('Mock has not been implemented!');
useNetSuitePdfCallback.createObject = createNetSuitePdfCallbackObject;
export enum PriorityCoverEnum {
	ALL = 'ALL',
	HIGH = 'HIGH',
}
export interface OnCallFor {
	employeeIds?: Nullable<string[]>;
	employees?: Nullable<LimitedUser[]>;
	role?: Nullable<RoleTypeEnum>;
	priority?: Nullable<PriorityCoverEnum>;
	allSelected?: Nullable<boolean>;
}
export interface OnCall extends BaseModel {
	onCallFor?: Nullable<OnCallFor[]>;
	active?: Nullable<boolean>;
	taskOnCallFor?: Nullable<OnCallFor[]>;
	taskOnCallActive?: Nullable<boolean>;
}
export class OnCallForClass {
  protected readonly _value: OnCallFor;

	constructor(value: OnCallFor) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get employeeIds() {
    return this._value.employeeIds;
  }

  get employeeIdsWithDefault() {
    return this._value.employeeIds || [];
  }

  get employees() {
    return this._value.employees !== undefined && this._value.employees !== null ? map(this._value.employees, (v) => new LimitedUserClass(v)) : this._value.employees;
  }

  get employeesWithDefault() {
    return this._value.employees || [];
  }

  get role() {
    return this._value.role;
  }

  roleIsIn(values: RoleTypeEnum[] = []) {
    return this._value.role && values.includes(this._value.role);
  }

  get priority() {
    return this._value.priority;
  }

  priorityIsIn(values: PriorityCoverEnum[] = []) {
    return this._value.priority && values.includes(this._value.priority);
  }

  get allSelected() {
    return this._value.allSelected;
  }

  get allSelectedWithDefault() {
    return this._value.allSelected || false;
  }
}
export class OnCallClass extends BaseModelClass {
  protected readonly _value: OnCall;

	constructor(value: OnCall) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get onCallFor() {
    return this._value.onCallFor !== undefined && this._value.onCallFor !== null ? map(this._value.onCallFor, (v) => new OnCallForClass(v)) : this._value.onCallFor;
  }

  get onCallForWithDefault() {
    return this._value.onCallFor || [];
  }

  get active() {
    return this._value.active;
  }

  get activeWithDefault() {
    return this._value.active || false;
  }

  get taskOnCallFor() {
    return this._value.taskOnCallFor !== undefined && this._value.taskOnCallFor !== null ? map(this._value.taskOnCallFor, (v) => new OnCallForClass(v)) : this._value.taskOnCallFor;
  }

  get taskOnCallForWithDefault() {
    return this._value.taskOnCallFor || [];
  }

  get taskOnCallActive() {
    return this._value.taskOnCallActive;
  }

  get taskOnCallActiveWithDefault() {
    return this._value.taskOnCallActive || false;
  }
}
export interface OnCallSaveParams {
	onCall: OnCall;
}

export class OnCallService extends BaseController<OnCall, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/on-call';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public save({ baseUrl, params, headers }: RequestOption<OnCallSaveParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<OnCall>>(`${baseUrl || this.baseURL}${this.basePath}/save${getQueryStrings(queryList)}`, params.onCall, { headers });
	}
}

export const onCallService = new OnCallService();

export const createOnCallSaveObject = (value: APIResponse<OnCall>) => new APIResponseClass<OnCallClass>(value, (value: OnCall) => new OnCallClass(value));

export const useOnCallSave = <RCN = undefined>(configs: MakeRequestHookConfig<OnCallSaveParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    OnCallSaveParams,
    APIResponse<OnCall>,
    typeof onCallService.save,
    AjaxOptions,
    APIResponseClass<OnCallClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof onCallService.save>) => (
      onCallService.save(...args)
    ),
    ResponseClassCreator: createOnCallSaveObject,
    typeName: 'useOnCallSave',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useOnCallSave.mock = () => new Error('Mock has not been implemented!');
useOnCallSave.getLastMockedValue = () => new Error('Mock has not been implemented!');
useOnCallSave.createObject = createOnCallSaveObject;


export const createOnCallGetObject = (value: APIResponse<OnCall>) => new APIResponseClass<OnCallClass>(value, (value: OnCall) => new OnCallClass(value));
export const useOnCallGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<OnCall>,
    // @ts-ignore
    typeof onCallService.get,
    GetOptions,
    APIResponseClass<OnCallClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof onCallService.get>) => (
      onCallService.get(...args)
    ),
    ResponseClassCreator: createOnCallGetObject,
    typeName: 'useOnCallGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useOnCallGet.mock = () => new Error('Mock has not been implemented!');
useOnCallGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useOnCallGet.createObject = createOnCallGetObject;

export const createOnCallUpdateObject = (value: APIResponse<OnCall>) => new APIResponseClass<OnCallClass>(value, (value: OnCall) => new OnCallClass(value));
export const useOnCallUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, OnCall>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, OnCall>,
    APIResponse<OnCall>,
    // @ts-ignore
    typeof onCallService.update,
    AjaxOptions,
    APIResponseClass<OnCallClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof onCallService.update>) => (
      onCallService.update(...args)
    ),
    ResponseClassCreator: createOnCallUpdateObject,
    typeName: 'useOnCallUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useOnCallUpdate.mock = () => new Error('Mock has not been implemented!');
useOnCallUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useOnCallUpdate.createObject = createOnCallUpdateObject;

export const createOnCallInsertObject = (value: APIResponse<OnCall>) => new APIResponseClass<OnCallClass>(value, (value: OnCall) => new OnCallClass(value));
export const useOnCallInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<OnCall>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<OnCall>,
    APIResponse<OnCall>,
    // @ts-ignore
    typeof onCallService.insert,
    AjaxOptions,
    APIResponseClass<OnCallClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof onCallService.insert>) => (
      onCallService.insert(...args)
    ),
    ResponseClassCreator: createOnCallInsertObject,
    typeName: 'useOnCallInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useOnCallInsert.mock = () => new Error('Mock has not been implemented!');
useOnCallInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useOnCallInsert.createObject = createOnCallInsertObject;

export const createOnCallDeleteObject = (value: APIResponse<OnCall>) => new APIResponseClass<OnCallClass>(value, (value: OnCall) => new OnCallClass(value));
export const useOnCallDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<OnCall>,
    // @ts-ignore
    typeof onCallService.delete,
    AjaxOptions,
    APIResponseClass<OnCallClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof onCallService.delete>) => (
      onCallService.delete(...args)
    ),
    ResponseClassCreator: createOnCallDeleteObject,
    typeName: 'useOnCallDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useOnCallDelete.mock = () => new Error('Mock has not been implemented!');
useOnCallDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useOnCallDelete.createObject = createOnCallDeleteObject;

export const createOnCallSearchObject = (value: APIResponse<PageResponse<OnCall>>) => new APIResponseClass<PageResponseClass<OnCallClass>>(value, (value: PageResponse<OnCall>) => new PageResponseClass<OnCallClass>(value, (value: OnCall) => new OnCallClass(value)));
export const useOnCallSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<OnCall>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<OnCall>,
    APIResponse<PageResponse<OnCall>>,
    // @ts-ignore
    typeof onCallService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<OnCallClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof onCallService.search>) => (
      onCallService.search(...args)
    ),
    ResponseClassCreator: createOnCallSearchObject,
    typeName: 'useOnCallSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useOnCallSearch.mock = () => new Error('Mock has not been implemented!');
useOnCallSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useOnCallSearch.createObject = createOnCallSearchObject;

export interface Organization extends BaseModel {
	name?: Nullable<string>;
	brandedName?: Nullable<string>;
	internalName?: Nullable<string>;
	description?: Nullable<string>;
	identifier?: Nullable<string>;
	parentId?: Nullable<string>;
	active?: Nullable<boolean>;
	systemNote?: Nullable<string>;
}
export class OrganizationClass extends BaseModelClass {
  protected readonly _value: Organization;

	constructor(value: Organization) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get brandedName() {
    return this._value.brandedName;
  }

  get brandedNameWithDefault() {
    return this._value.brandedName || '';
  }

  get internalName() {
    return this._value.internalName;
  }

  get internalNameWithDefault() {
    return this._value.internalName || '';
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get identifier() {
    return this._value.identifier;
  }

  get identifierWithDefault() {
    return this._value.identifier || '';
  }

  get parentId() {
    return this._value.parentId;
  }

  get parentIdWithDefault() {
    return this._value.parentId || '';
  }

  get active() {
    return this._value.active;
  }

  get activeWithDefault() {
    return this._value.active || false;
  }

  get systemNote() {
    return this._value.systemNote;
  }

  get systemNoteWithDefault() {
    return this._value.systemNote || '';
  }
}
export class OrganizationService extends BaseController<Organization, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/organizations';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const organizationService = new OrganizationService();


export const createOrganizationGetObject = (value: APIResponse<Organization>) => new APIResponseClass<OrganizationClass>(value, (value: Organization) => new OrganizationClass(value));
export const useOrganizationGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<Organization>,
    // @ts-ignore
    typeof organizationService.get,
    GetOptions,
    APIResponseClass<OrganizationClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof organizationService.get>) => (
      organizationService.get(...args)
    ),
    ResponseClassCreator: createOrganizationGetObject,
    typeName: 'useOrganizationGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useOrganizationGet.mock = () => new Error('Mock has not been implemented!');
useOrganizationGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useOrganizationGet.createObject = createOrganizationGetObject;

export const createOrganizationUpdateObject = (value: APIResponse<Organization>) => new APIResponseClass<OrganizationClass>(value, (value: Organization) => new OrganizationClass(value));
export const useOrganizationUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, Organization>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, Organization>,
    APIResponse<Organization>,
    // @ts-ignore
    typeof organizationService.update,
    AjaxOptions,
    APIResponseClass<OrganizationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof organizationService.update>) => (
      organizationService.update(...args)
    ),
    ResponseClassCreator: createOrganizationUpdateObject,
    typeName: 'useOrganizationUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useOrganizationUpdate.mock = () => new Error('Mock has not been implemented!');
useOrganizationUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useOrganizationUpdate.createObject = createOrganizationUpdateObject;

export const createOrganizationInsertObject = (value: APIResponse<Organization>) => new APIResponseClass<OrganizationClass>(value, (value: Organization) => new OrganizationClass(value));
export const useOrganizationInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<Organization>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<Organization>,
    APIResponse<Organization>,
    // @ts-ignore
    typeof organizationService.insert,
    AjaxOptions,
    APIResponseClass<OrganizationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof organizationService.insert>) => (
      organizationService.insert(...args)
    ),
    ResponseClassCreator: createOrganizationInsertObject,
    typeName: 'useOrganizationInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useOrganizationInsert.mock = () => new Error('Mock has not been implemented!');
useOrganizationInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useOrganizationInsert.createObject = createOrganizationInsertObject;

export const createOrganizationDeleteObject = (value: APIResponse<Organization>) => new APIResponseClass<OrganizationClass>(value, (value: Organization) => new OrganizationClass(value));
export const useOrganizationDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<Organization>,
    // @ts-ignore
    typeof organizationService.delete,
    AjaxOptions,
    APIResponseClass<OrganizationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof organizationService.delete>) => (
      organizationService.delete(...args)
    ),
    ResponseClassCreator: createOrganizationDeleteObject,
    typeName: 'useOrganizationDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useOrganizationDelete.mock = () => new Error('Mock has not been implemented!');
useOrganizationDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useOrganizationDelete.createObject = createOrganizationDeleteObject;

export const createOrganizationSearchObject = (value: APIResponse<PageResponse<Organization>>) => new APIResponseClass<PageResponseClass<OrganizationClass>>(value, (value: PageResponse<Organization>) => new PageResponseClass<OrganizationClass>(value, (value: Organization) => new OrganizationClass(value)));
export const useOrganizationSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<Organization>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<Organization>,
    APIResponse<PageResponse<Organization>>,
    // @ts-ignore
    typeof organizationService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<OrganizationClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof organizationService.search>) => (
      organizationService.search(...args)
    ),
    ResponseClassCreator: createOrganizationSearchObject,
    typeName: 'useOrganizationSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useOrganizationSearch.mock = () => new Error('Mock has not been implemented!');
useOrganizationSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useOrganizationSearch.createObject = createOrganizationSearchObject;

export interface LimitedArticle {
	id?: Nullable<string>;
	name?: Nullable<string>;
	url?: Nullable<string>;
}
export interface PatientArticles extends BaseModel {
	patientId?: Nullable<string>;
	sentById?: Nullable<string>;
	articleId?: Nullable<string>;
	article?: Nullable<LimitedArticle>;
}
export class LimitedArticleClass {
  protected readonly _value: LimitedArticle;

	constructor(value: LimitedArticle) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get url() {
    return this._value.url;
  }

  get urlWithDefault() {
    return this._value.url || '';
  }
}
export class PatientArticlesClass extends BaseModelClass {
  protected readonly _value: PatientArticles;

	constructor(value: PatientArticles) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientId() {
    return this._value.patientId;
  }

  get patientIdWithDefault() {
    return this._value.patientId || '';
  }

  get sentById() {
    return this._value.sentById;
  }

  get sentByIdWithDefault() {
    return this._value.sentById || '';
  }

  get articleId() {
    return this._value.articleId;
  }

  get articleIdWithDefault() {
    return this._value.articleId || '';
  }

  get article() {
    return this._value.article !== undefined && this._value.article !== null ? new LimitedArticleClass(this._value.article) : this._value.article;
  }
}
export interface PatientArticlesBulkUpsertPatientArticlesParams {
	patientArticlesArr: PatientArticles[];
}

export class PatientArticlesService extends BaseController<PatientArticles, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/patient-articles';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public bulkUpsertPatientArticles({ baseUrl, params, headers }: RequestOption<PatientArticlesBulkUpsertPatientArticlesParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/bulk-upsert${getQueryStrings(queryList)}`, params.patientArticlesArr, { headers });
	}
}

export const patientArticlesService = new PatientArticlesService();

export const createPatientArticlesBulkUpsertPatientArticlesObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const usePatientArticlesBulkUpsertPatientArticles = <RCN = undefined>(configs: MakeRequestHookConfig<PatientArticlesBulkUpsertPatientArticlesParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientArticlesBulkUpsertPatientArticlesParams,
    APIResponse<string>,
    typeof patientArticlesService.bulkUpsertPatientArticles,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientArticlesService.bulkUpsertPatientArticles>) => (
      patientArticlesService.bulkUpsertPatientArticles(...args)
    ),
    ResponseClassCreator: createPatientArticlesBulkUpsertPatientArticlesObject,
    typeName: 'usePatientArticlesBulkUpsertPatientArticles',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientArticlesBulkUpsertPatientArticles.mock = () => new Error('Mock has not been implemented!');
usePatientArticlesBulkUpsertPatientArticles.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientArticlesBulkUpsertPatientArticles.createObject = createPatientArticlesBulkUpsertPatientArticlesObject;


export const createPatientArticlesGetObject = (value: APIResponse<PatientArticles>) => new APIResponseClass<PatientArticlesClass>(value, (value: PatientArticles) => new PatientArticlesClass(value));
export const usePatientArticlesGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<PatientArticles>,
    // @ts-ignore
    typeof patientArticlesService.get,
    GetOptions,
    APIResponseClass<PatientArticlesClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientArticlesService.get>) => (
      patientArticlesService.get(...args)
    ),
    ResponseClassCreator: createPatientArticlesGetObject,
    typeName: 'usePatientArticlesGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientArticlesGet.mock = () => new Error('Mock has not been implemented!');
usePatientArticlesGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientArticlesGet.createObject = createPatientArticlesGetObject;

export const createPatientArticlesUpdateObject = (value: APIResponse<PatientArticles>) => new APIResponseClass<PatientArticlesClass>(value, (value: PatientArticles) => new PatientArticlesClass(value));
export const usePatientArticlesUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, PatientArticles>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, PatientArticles>,
    APIResponse<PatientArticles>,
    // @ts-ignore
    typeof patientArticlesService.update,
    AjaxOptions,
    APIResponseClass<PatientArticlesClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientArticlesService.update>) => (
      patientArticlesService.update(...args)
    ),
    ResponseClassCreator: createPatientArticlesUpdateObject,
    typeName: 'usePatientArticlesUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientArticlesUpdate.mock = () => new Error('Mock has not been implemented!');
usePatientArticlesUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientArticlesUpdate.createObject = createPatientArticlesUpdateObject;

export const createPatientArticlesInsertObject = (value: APIResponse<PatientArticles>) => new APIResponseClass<PatientArticlesClass>(value, (value: PatientArticles) => new PatientArticlesClass(value));
export const usePatientArticlesInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<PatientArticles>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<PatientArticles>,
    APIResponse<PatientArticles>,
    // @ts-ignore
    typeof patientArticlesService.insert,
    AjaxOptions,
    APIResponseClass<PatientArticlesClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientArticlesService.insert>) => (
      patientArticlesService.insert(...args)
    ),
    ResponseClassCreator: createPatientArticlesInsertObject,
    typeName: 'usePatientArticlesInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientArticlesInsert.mock = () => new Error('Mock has not been implemented!');
usePatientArticlesInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientArticlesInsert.createObject = createPatientArticlesInsertObject;

export const createPatientArticlesDeleteObject = (value: APIResponse<PatientArticles>) => new APIResponseClass<PatientArticlesClass>(value, (value: PatientArticles) => new PatientArticlesClass(value));
export const usePatientArticlesDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<PatientArticles>,
    // @ts-ignore
    typeof patientArticlesService.delete,
    AjaxOptions,
    APIResponseClass<PatientArticlesClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientArticlesService.delete>) => (
      patientArticlesService.delete(...args)
    ),
    ResponseClassCreator: createPatientArticlesDeleteObject,
    typeName: 'usePatientArticlesDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientArticlesDelete.mock = () => new Error('Mock has not been implemented!');
usePatientArticlesDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientArticlesDelete.createObject = createPatientArticlesDeleteObject;

export const createPatientArticlesSearchObject = (value: APIResponse<PageResponse<PatientArticles>>) => new APIResponseClass<PageResponseClass<PatientArticlesClass>>(value, (value: PageResponse<PatientArticles>) => new PageResponseClass<PatientArticlesClass>(value, (value: PatientArticles) => new PatientArticlesClass(value)));
export const usePatientArticlesSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<PatientArticles>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<PatientArticles>,
    APIResponse<PageResponse<PatientArticles>>,
    // @ts-ignore
    typeof patientArticlesService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientArticlesClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientArticlesService.search>) => (
      patientArticlesService.search(...args)
    ),
    ResponseClassCreator: createPatientArticlesSearchObject,
    typeName: 'usePatientArticlesSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientArticlesSearch.mock = () => new Error('Mock has not been implemented!');
usePatientArticlesSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientArticlesSearch.createObject = createPatientArticlesSearchObject;

export class PatientAssigneesService extends BaseController<PatientAssignees, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/patient-assignees';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const patientAssigneesService = new PatientAssigneesService();


export const createPatientAssigneesGetObject = (value: APIResponse<PatientAssignees>) => new APIResponseClass<PatientAssigneesClass>(value, (value: PatientAssignees) => new PatientAssigneesClass(value));
export const usePatientAssigneesGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<PatientAssignees>,
    // @ts-ignore
    typeof patientAssigneesService.get,
    GetOptions,
    APIResponseClass<PatientAssigneesClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientAssigneesService.get>) => (
      patientAssigneesService.get(...args)
    ),
    ResponseClassCreator: createPatientAssigneesGetObject,
    typeName: 'usePatientAssigneesGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientAssigneesGet.mock = () => new Error('Mock has not been implemented!');
usePatientAssigneesGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientAssigneesGet.createObject = createPatientAssigneesGetObject;

export const createPatientAssigneesUpdateObject = (value: APIResponse<PatientAssignees>) => new APIResponseClass<PatientAssigneesClass>(value, (value: PatientAssignees) => new PatientAssigneesClass(value));
export const usePatientAssigneesUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, PatientAssignees>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, PatientAssignees>,
    APIResponse<PatientAssignees>,
    // @ts-ignore
    typeof patientAssigneesService.update,
    AjaxOptions,
    APIResponseClass<PatientAssigneesClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientAssigneesService.update>) => (
      patientAssigneesService.update(...args)
    ),
    ResponseClassCreator: createPatientAssigneesUpdateObject,
    typeName: 'usePatientAssigneesUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientAssigneesUpdate.mock = () => new Error('Mock has not been implemented!');
usePatientAssigneesUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientAssigneesUpdate.createObject = createPatientAssigneesUpdateObject;

export const createPatientAssigneesInsertObject = (value: APIResponse<PatientAssignees>) => new APIResponseClass<PatientAssigneesClass>(value, (value: PatientAssignees) => new PatientAssigneesClass(value));
export const usePatientAssigneesInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<PatientAssignees>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<PatientAssignees>,
    APIResponse<PatientAssignees>,
    // @ts-ignore
    typeof patientAssigneesService.insert,
    AjaxOptions,
    APIResponseClass<PatientAssigneesClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientAssigneesService.insert>) => (
      patientAssigneesService.insert(...args)
    ),
    ResponseClassCreator: createPatientAssigneesInsertObject,
    typeName: 'usePatientAssigneesInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientAssigneesInsert.mock = () => new Error('Mock has not been implemented!');
usePatientAssigneesInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientAssigneesInsert.createObject = createPatientAssigneesInsertObject;

export const createPatientAssigneesDeleteObject = (value: APIResponse<PatientAssignees>) => new APIResponseClass<PatientAssigneesClass>(value, (value: PatientAssignees) => new PatientAssigneesClass(value));
export const usePatientAssigneesDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<PatientAssignees>,
    // @ts-ignore
    typeof patientAssigneesService.delete,
    AjaxOptions,
    APIResponseClass<PatientAssigneesClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientAssigneesService.delete>) => (
      patientAssigneesService.delete(...args)
    ),
    ResponseClassCreator: createPatientAssigneesDeleteObject,
    typeName: 'usePatientAssigneesDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientAssigneesDelete.mock = () => new Error('Mock has not been implemented!');
usePatientAssigneesDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientAssigneesDelete.createObject = createPatientAssigneesDeleteObject;

export const createPatientAssigneesSearchObject = (value: APIResponse<PageResponse<PatientAssignees>>) => new APIResponseClass<PageResponseClass<PatientAssigneesClass>>(value, (value: PageResponse<PatientAssignees>) => new PageResponseClass<PatientAssigneesClass>(value, (value: PatientAssignees) => new PatientAssigneesClass(value)));
export const usePatientAssigneesSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<PatientAssignees>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<PatientAssignees>,
    APIResponse<PageResponse<PatientAssignees>>,
    // @ts-ignore
    typeof patientAssigneesService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientAssigneesClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientAssigneesService.search>) => (
      patientAssigneesService.search(...args)
    ),
    ResponseClassCreator: createPatientAssigneesSearchObject,
    typeName: 'usePatientAssigneesSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientAssigneesSearch.mock = () => new Error('Mock has not been implemented!');
usePatientAssigneesSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientAssigneesSearch.createObject = createPatientAssigneesSearchObject;

export interface PatientAssignment extends BaseModel {
	patientId: string;
	roleAssigmentId: string;
	active?: Nullable<boolean>;
}
export class PatientAssignmentClass extends BaseModelClass {
  protected readonly _value: PatientAssignment;

	constructor(value: PatientAssignment) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientId() {
    return this._value.patientId;
  }

  get roleAssigmentId() {
    return this._value.roleAssigmentId;
  }

  get active() {
    return this._value.active;
  }

  get activeWithDefault() {
    return this._value.active || false;
  }
}
export class PatientAssignmentService extends BaseController<PatientAssignment, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/patient-assignment';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const patientAssignmentService = new PatientAssignmentService();


export const createPatientAssignmentGetObject = (value: APIResponse<PatientAssignment>) => new APIResponseClass<PatientAssignmentClass>(value, (value: PatientAssignment) => new PatientAssignmentClass(value));
export const usePatientAssignmentGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<PatientAssignment>,
    // @ts-ignore
    typeof patientAssignmentService.get,
    GetOptions,
    APIResponseClass<PatientAssignmentClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientAssignmentService.get>) => (
      patientAssignmentService.get(...args)
    ),
    ResponseClassCreator: createPatientAssignmentGetObject,
    typeName: 'usePatientAssignmentGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientAssignmentGet.mock = () => new Error('Mock has not been implemented!');
usePatientAssignmentGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientAssignmentGet.createObject = createPatientAssignmentGetObject;

export const createPatientAssignmentUpdateObject = (value: APIResponse<PatientAssignment>) => new APIResponseClass<PatientAssignmentClass>(value, (value: PatientAssignment) => new PatientAssignmentClass(value));
export const usePatientAssignmentUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, PatientAssignment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, PatientAssignment>,
    APIResponse<PatientAssignment>,
    // @ts-ignore
    typeof patientAssignmentService.update,
    AjaxOptions,
    APIResponseClass<PatientAssignmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientAssignmentService.update>) => (
      patientAssignmentService.update(...args)
    ),
    ResponseClassCreator: createPatientAssignmentUpdateObject,
    typeName: 'usePatientAssignmentUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientAssignmentUpdate.mock = () => new Error('Mock has not been implemented!');
usePatientAssignmentUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientAssignmentUpdate.createObject = createPatientAssignmentUpdateObject;

export const createPatientAssignmentInsertObject = (value: APIResponse<PatientAssignment>) => new APIResponseClass<PatientAssignmentClass>(value, (value: PatientAssignment) => new PatientAssignmentClass(value));
export const usePatientAssignmentInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<PatientAssignment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<PatientAssignment>,
    APIResponse<PatientAssignment>,
    // @ts-ignore
    typeof patientAssignmentService.insert,
    AjaxOptions,
    APIResponseClass<PatientAssignmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientAssignmentService.insert>) => (
      patientAssignmentService.insert(...args)
    ),
    ResponseClassCreator: createPatientAssignmentInsertObject,
    typeName: 'usePatientAssignmentInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientAssignmentInsert.mock = () => new Error('Mock has not been implemented!');
usePatientAssignmentInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientAssignmentInsert.createObject = createPatientAssignmentInsertObject;

export const createPatientAssignmentDeleteObject = (value: APIResponse<PatientAssignment>) => new APIResponseClass<PatientAssignmentClass>(value, (value: PatientAssignment) => new PatientAssignmentClass(value));
export const usePatientAssignmentDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<PatientAssignment>,
    // @ts-ignore
    typeof patientAssignmentService.delete,
    AjaxOptions,
    APIResponseClass<PatientAssignmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientAssignmentService.delete>) => (
      patientAssignmentService.delete(...args)
    ),
    ResponseClassCreator: createPatientAssignmentDeleteObject,
    typeName: 'usePatientAssignmentDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientAssignmentDelete.mock = () => new Error('Mock has not been implemented!');
usePatientAssignmentDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientAssignmentDelete.createObject = createPatientAssignmentDeleteObject;

export const createPatientAssignmentSearchObject = (value: APIResponse<PageResponse<PatientAssignment>>) => new APIResponseClass<PageResponseClass<PatientAssignmentClass>>(value, (value: PageResponse<PatientAssignment>) => new PageResponseClass<PatientAssignmentClass>(value, (value: PatientAssignment) => new PatientAssignmentClass(value)));
export const usePatientAssignmentSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<PatientAssignment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<PatientAssignment>,
    APIResponse<PageResponse<PatientAssignment>>,
    // @ts-ignore
    typeof patientAssignmentService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientAssignmentClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientAssignmentService.search>) => (
      patientAssignmentService.search(...args)
    ),
    ResponseClassCreator: createPatientAssignmentSearchObject,
    typeName: 'usePatientAssignmentSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientAssignmentSearch.mock = () => new Error('Mock has not been implemented!');
usePatientAssignmentSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientAssignmentSearch.createObject = createPatientAssignmentSearchObject;

export interface PatientCarePriority {
	priority?: Nullable<PriorityLevelEnum>;
	priorityLevel?: Nullable<number>;
	priorityItem?: Nullable<string>;
}
export interface PatientCareDueDate {
	dueDateItem?: Nullable<string>;
	dueDate?: Nullable<string>;
}
export enum PatientCareItemEnum {
	VISIT = 'VISIT',
	ONBOARDING_TO_DO = 'ONBOARDING_TO_DO',
	MONTHLY_REVIEW = 'MONTHLY_REVIEW',
	MESSAGE = 'MESSAGE',
	FOOD_LOG = 'FOOD_LOG',
	COMPLIANCE_ALERT = 'COMPLIANCE_ALERT',
	NEW_PATIENT_ENGAGEMENT_1_DAY = 'NEW_PATIENT_ENGAGEMENT_1_DAY',
	NEW_PATIENT_ENGAGEMENT_14_DAY = 'NEW_PATIENT_ENGAGEMENT_14_DAY',
	A1C_DUE = 'A1C_DUE',
	VISIT_DUE = 'VISIT_DUE',
	INACTIVE_14_DAY = 'INACTIVE_14_DAY',
	MEDICAL_ALERT = 'MEDICAL_ALERT',
	A1C_COLLECT = 'A1C_COLLECT',
	ENROLLMENT_ENGAGEMENT_14_DAY = 'ENROLLMENT_ENGAGEMENT_14_DAY',
	NON_APP_USER_ENGAGEMENT = 'NON_APP_USER_ENGAGEMENT',
	VISIT_INCOMPLETE = 'VISIT_INCOMPLETE',
	TODAY_VISIT = 'TODAY_VISIT',
	NEW_PATIENT_ENGAGEMENT = 'NEW_PATIENT_ENGAGEMENT',
	INACTIVE_7_DAY = 'INACTIVE_7_DAY',
}
export enum PatientTypeEnum {
	APP_USER = 'APP_USER',
	NON_APP_USER = 'NON_APP_USER',
}
export enum WorkTypeEnum {
	ONBOARDING_TO_DO = 'ONBOARDING_TO_DO',
	COMPLIANCE_ALERT_HIGH = 'COMPLIANCE_ALERT_HIGH',
	COMPLIANCE_ALERT_MED = 'COMPLIANCE_ALERT_MED',
	COMPLIANCE_ALERT_LOW = 'COMPLIANCE_ALERT_LOW',
	MEDICAL_ALERT_HIGH = 'MEDICAL_ALERT_HIGH',
	MEDICAL_ALERT_MED = 'MEDICAL_ALERT_MED',
	MEDICAL_ALERT_LOW = 'MEDICAL_ALERT_LOW',
	MESSAGE = 'MESSAGE',
	MONTHLY_REVIEW = 'MONTHLY_REVIEW',
	FOOD_LOG = 'FOOD_LOG',
	COMPLIANCE_ALERT = 'COMPLIANCE_ALERT',
	MEDICAL_ALERT = 'MEDICAL_ALERT',
}
export interface ContainAllFilter<T> {
	containsAll?: Nullable<T[]>;
}
export interface PatientCareDetail extends PatientBase {
	firstName?: Nullable<string>;
	lastName?: Nullable<string>;
	priority?: Nullable<PriorityLevelEnum>;
	caPriority?: Nullable<PriorityLevelEnum>;
	rdPriority?: Nullable<PriorityLevelEnum>;
	caRdPriority?: Nullable<PriorityLevelEnum>;
	priorityLevel?: Nullable<number>;
	caPriorityLevel?: Nullable<number>;
	rdPriorityLevel?: Nullable<number>;
	caRdPriorityLevel?: Nullable<number>;
	priorityList?: Nullable<PatientCarePriority[]>;
	dueDate?: Nullable<string>;
	overDue?: Nullable<boolean>;
	dueDateList?: Nullable<PatientCareDueDate[]>;
	items?: Nullable<PatientCareItemEnum[]>;
	itemSize?: Nullable<number>;
	vitals?: Nullable<VitalEnumType[]>;
	programs?: Nullable<ProgramCategoryEnum[]>;
	programsIn?: Nullable<InFilter<ProgramCategorySearchFilterEnum>>;
	enrolledOn?: Nullable<string>;
	upcomingVisit?: Nullable<string>;
	patientType?: Nullable<PatientTypeEnum>;
	doctorId?: Nullable<string>;
	clinicId?: Nullable<string>;
	isCompleted?: Nullable<boolean>;
	isTestPatient?: Nullable<boolean>;
	controlLevel?: Nullable<PatientControlLevel>;
	workTypes?: Nullable<WorkTypeEnum[]>;
	memberIdIn?: Nullable<InFilter<string>>;
	itemSizeNe?: Nullable<NeFilter<number>>;
	itemSizeRange?: Nullable<RangeFilter<number>>;
	priorityIn?: Nullable<InFilter<PriorityLevelEnum>>;
	caPriorityIn?: Nullable<InFilter<PriorityLevelEnum>>;
	rdPriorityIn?: Nullable<InFilter<PriorityLevelEnum>>;
	caRdPriorityIn?: Nullable<InFilter<PriorityLevelEnum>>;
	dueDateRange?: Nullable<DateRangeFilter>;
	itemsIn?: Nullable<InFilter<PatientCareItemEnum>>;
	workTypesIn?: Nullable<InFilter<WorkTypeEnum>>;
	vitalsIn?: Nullable<InFilter<VitalEnumType>>;
	programsFilterIn?: Nullable<InFilter<ProgramCategoryEnum>>;
	controlLevelIn?: Nullable<InFilter<ControlLevelEnum>>;
	programsContain?: Nullable<ContainAllFilter<ProgramCategoryEnum>>;
	enrolledOnRange?: Nullable<DateRangeFilter>;
	upcomingVisitRange?: Nullable<DateRangeFilter>;
	patientTypeIn?: Nullable<InFilter<PatientTypeEnum>>;
	doctorIdIn?: Nullable<InFilter<string>>;
	clinicIdIn?: Nullable<InFilter<string>>;
	clinicInfo?: Nullable<LimitedClinic>;
	doctorInfo?: Nullable<LimitedUser>;
	updatedAtRange?: Nullable<DateRangeFilter>;
	filterWatchedPatient?: Nullable<boolean>;
}
export interface CountTask {
	totalTask?: Nullable<number>;
	completedTask?: Nullable<number>;
}
export class CountTaskClass {
  protected readonly _value: CountTask;

	constructor(value: CountTask) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get totalTask() {
    return this._value.totalTask;
  }

  get totalTaskWithDefault() {
    return this._value.totalTask || 0;
  }

  get completedTask() {
    return this._value.completedTask;
  }

  get completedTaskWithDefault() {
    return this._value.completedTask || 0;
  }
}
export interface PatientCareRequest {
	patientCareRequest?: Nullable<SearchRequest<PatientCareDetail>>;
	onCall?: Nullable<boolean>;
	includeTestPatient?: Nullable<boolean>;
}
export interface PatientCareListItem$PatientControlLevel {
	controlLevel?: Nullable<ControlLevelItem>;
	bpStatData?: Nullable<BpStatData>;
	bgStatData?: Nullable<BgStatData>;
}
export interface PatientCareListItem {
	id?: Nullable<string>;
	firstName?: Nullable<string>;
	lastName?: Nullable<string>;
	priority?: Nullable<PriorityLevelEnum>;
	dueDate?: Nullable<string>;
	overDue?: Nullable<boolean>;
	vitals?: Nullable<VitalEnumType[]>;
	programs?: Nullable<ProgramCategoryEnum[]>;
	doctorInfo?: Nullable<LimitedUser>;
	controlLevel?: Nullable<PatientCareListItem$PatientControlLevel>;
	enrolledOn?: Nullable<string>;
	upcomingVisit?: Nullable<string>;
	patientType?: Nullable<PatientTypeEnum>;
}
export class PatientCareListItem$PatientControlLevelClass {
  protected readonly _value: PatientCareListItem$PatientControlLevel;

	constructor(value: PatientCareListItem$PatientControlLevel) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get controlLevel() {
    return this._value.controlLevel !== undefined && this._value.controlLevel !== null ? new ControlLevelItemClass(this._value.controlLevel) : this._value.controlLevel;
  }

  get bpStatData() {
    return this._value.bpStatData !== undefined && this._value.bpStatData !== null ? new BpStatDataClass(this._value.bpStatData) : this._value.bpStatData;
  }

  get bgStatData() {
    return this._value.bgStatData !== undefined && this._value.bgStatData !== null ? new BgStatDataClass(this._value.bgStatData) : this._value.bgStatData;
  }
}
export class PatientCareListItemClass {
  protected readonly _value: PatientCareListItem;

	constructor(value: PatientCareListItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get firstName() {
    return this._value.firstName;
  }

  get firstNameWithDefault() {
    return this._value.firstName || '';
  }

  get lastName() {
    return this._value.lastName;
  }

  get lastNameWithDefault() {
    return this._value.lastName || '';
  }

  get priority() {
    return this._value.priority;
  }

  priorityIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.priority && values.includes(this._value.priority);
  }

  get dueDate() {
    return (this._value.dueDate !== undefined && this._value.dueDate !== null) ? dayjs(this._value.dueDate) : this._value.dueDate;
  }

  get overDue() {
    return this._value.overDue;
  }

  get overDueWithDefault() {
    return this._value.overDue || false;
  }

  get vitals() {
    return this._value.vitals;
  }

  get vitalsWithDefault() {
    return this._value.vitals || [];
  }

  get programs() {
    return this._value.programs;
  }

  get programsWithDefault() {
    return this._value.programs || [];
  }

  get doctorInfo() {
    return this._value.doctorInfo !== undefined && this._value.doctorInfo !== null ? new LimitedUserClass(this._value.doctorInfo) : this._value.doctorInfo;
  }

  get controlLevel() {
    return this._value.controlLevel !== undefined && this._value.controlLevel !== null ? new PatientCareListItem$PatientControlLevelClass(this._value.controlLevel) : this._value.controlLevel;
  }

  get enrolledOn() {
    return (this._value.enrolledOn !== undefined && this._value.enrolledOn !== null) ? dayjs(this._value.enrolledOn) : this._value.enrolledOn;
  }

  get upcomingVisit() {
    return (this._value.upcomingVisit !== undefined && this._value.upcomingVisit !== null) ? dayjs(this._value.upcomingVisit) : this._value.upcomingVisit;
  }

  get patientType() {
    return this._value.patientType;
  }

  patientTypeIsIn(values: PatientTypeEnum[] = []) {
    return this._value.patientType && values.includes(this._value.patientType);
  }
}
export class PatientCarePriorityClass {
  protected readonly _value: PatientCarePriority;

	constructor(value: PatientCarePriority) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get priority() {
    return this._value.priority;
  }

  priorityIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.priority && values.includes(this._value.priority);
  }

  get priorityLevel() {
    return this._value.priorityLevel;
  }

  get priorityLevelWithDefault() {
    return this._value.priorityLevel || 0;
  }

  get priorityItem() {
    return this._value.priorityItem;
  }

  get priorityItemWithDefault() {
    return this._value.priorityItem || '';
  }
}
export class PatientCareDueDateClass {
  protected readonly _value: PatientCareDueDate;

	constructor(value: PatientCareDueDate) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get dueDateItem() {
    return this._value.dueDateItem;
  }

  get dueDateItemWithDefault() {
    return this._value.dueDateItem || '';
  }

  get dueDate() {
    return (this._value.dueDate !== undefined && this._value.dueDate !== null) ? dayjs(this._value.dueDate) : this._value.dueDate;
  }
}
export class ContainAllFilterClass<T> {
  protected readonly _value: ContainAllFilter<any>;
	protected readonly containsAllConstructorFunc?: (value: any) => T;

	constructor(value: ContainAllFilter<any>, containsAllConstructorFunc?: (value: any) => T) {
		this._value = value;
		this.containsAllConstructorFunc = containsAllConstructorFunc;
	}

  get rawValue() {
    return this._value;
  }

  get containsAll(): T {
    return this.containsAllConstructorFunc ? (this._value.containsAll && this.containsAllConstructorFunc(this._value.containsAll)) : this._value.containsAll;
  }

  get containsAllWithDefault() {
    return this._value.containsAll || [];
  }
}
export class PatientCareDetailClass extends PatientBaseClass {
  protected readonly _value: PatientCareDetail;

	constructor(value: PatientCareDetail) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get firstName() {
    return this._value.firstName;
  }

  get firstNameWithDefault() {
    return this._value.firstName || '';
  }

  get lastName() {
    return this._value.lastName;
  }

  get lastNameWithDefault() {
    return this._value.lastName || '';
  }

  get priority() {
    return this._value.priority;
  }

  priorityIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.priority && values.includes(this._value.priority);
  }

  get caPriority() {
    return this._value.caPriority;
  }

  caPriorityIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.caPriority && values.includes(this._value.caPriority);
  }

  get rdPriority() {
    return this._value.rdPriority;
  }

  rdPriorityIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.rdPriority && values.includes(this._value.rdPriority);
  }

  get caRdPriority() {
    return this._value.caRdPriority;
  }

  caRdPriorityIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.caRdPriority && values.includes(this._value.caRdPriority);
  }

  get priorityLevel() {
    return this._value.priorityLevel;
  }

  get priorityLevelWithDefault() {
    return this._value.priorityLevel || 0;
  }

  get caPriorityLevel() {
    return this._value.caPriorityLevel;
  }

  get caPriorityLevelWithDefault() {
    return this._value.caPriorityLevel || 0;
  }

  get rdPriorityLevel() {
    return this._value.rdPriorityLevel;
  }

  get rdPriorityLevelWithDefault() {
    return this._value.rdPriorityLevel || 0;
  }

  get caRdPriorityLevel() {
    return this._value.caRdPriorityLevel;
  }

  get caRdPriorityLevelWithDefault() {
    return this._value.caRdPriorityLevel || 0;
  }

  get priorityList() {
    return this._value.priorityList !== undefined && this._value.priorityList !== null ? map(this._value.priorityList, (v) => new PatientCarePriorityClass(v)) : this._value.priorityList;
  }

  get priorityListWithDefault() {
    return this._value.priorityList || [];
  }

  get dueDate() {
    return (this._value.dueDate !== undefined && this._value.dueDate !== null) ? dayjs(this._value.dueDate) : this._value.dueDate;
  }

  get overDue() {
    return this._value.overDue;
  }

  get overDueWithDefault() {
    return this._value.overDue || false;
  }

  get dueDateList() {
    return this._value.dueDateList !== undefined && this._value.dueDateList !== null ? map(this._value.dueDateList, (v) => new PatientCareDueDateClass(v)) : this._value.dueDateList;
  }

  get dueDateListWithDefault() {
    return this._value.dueDateList || [];
  }

  get items() {
    return this._value.items;
  }

  get itemsWithDefault() {
    return this._value.items || [];
  }

  get itemSize() {
    return this._value.itemSize;
  }

  get itemSizeWithDefault() {
    return this._value.itemSize || 0;
  }

  get vitals() {
    return this._value.vitals;
  }

  get vitalsWithDefault() {
    return this._value.vitals || [];
  }

  get programs() {
    return this._value.programs;
  }

  get programsWithDefault() {
    return this._value.programs || [];
  }

  get programsIn() {
    return this._value.programsIn !== undefined && this._value.programsIn !== null ? new InFilterClass(this._value.programsIn) : this._value.programsIn;
  }

  get enrolledOn() {
    return (this._value.enrolledOn !== undefined && this._value.enrolledOn !== null) ? dayjs(this._value.enrolledOn) : this._value.enrolledOn;
  }

  get upcomingVisit() {
    return (this._value.upcomingVisit !== undefined && this._value.upcomingVisit !== null) ? dayjs(this._value.upcomingVisit) : this._value.upcomingVisit;
  }

  get patientType() {
    return this._value.patientType;
  }

  patientTypeIsIn(values: PatientTypeEnum[] = []) {
    return this._value.patientType && values.includes(this._value.patientType);
  }

  get doctorId() {
    return this._value.doctorId;
  }

  get clinicId() {
    return this._value.clinicId;
  }

  get isCompleted() {
    return this._value.isCompleted;
  }

  get isCompletedWithDefault() {
    return this._value.isCompleted || false;
  }

  get isTestPatient() {
    return this._value.isTestPatient;
  }

  get isTestPatientWithDefault() {
    return this._value.isTestPatient || false;
  }

  get controlLevel() {
    return this._value.controlLevel !== undefined && this._value.controlLevel !== null ? new PatientControlLevelClass(this._value.controlLevel) : this._value.controlLevel;
  }

  get workTypes() {
    return this._value.workTypes;
  }

  get workTypesWithDefault() {
    return this._value.workTypes || [];
  }

  get memberIdIn() {
    return this._value.memberIdIn !== undefined && this._value.memberIdIn !== null ? new InFilterClass(this._value.memberIdIn) : this._value.memberIdIn;
  }

  get itemSizeNe() {
    return this._value.itemSizeNe !== undefined && this._value.itemSizeNe !== null ? new NeFilterClass(this._value.itemSizeNe) : this._value.itemSizeNe;
  }

  get itemSizeRange() {
    return this._value.itemSizeRange !== undefined && this._value.itemSizeRange !== null ? new RangeFilterClass(this._value.itemSizeRange) : this._value.itemSizeRange;
  }

  get priorityIn() {
    return this._value.priorityIn !== undefined && this._value.priorityIn !== null ? new InFilterClass(this._value.priorityIn) : this._value.priorityIn;
  }

  get caPriorityIn() {
    return this._value.caPriorityIn !== undefined && this._value.caPriorityIn !== null ? new InFilterClass(this._value.caPriorityIn) : this._value.caPriorityIn;
  }

  get rdPriorityIn() {
    return this._value.rdPriorityIn !== undefined && this._value.rdPriorityIn !== null ? new InFilterClass(this._value.rdPriorityIn) : this._value.rdPriorityIn;
  }

  get caRdPriorityIn() {
    return this._value.caRdPriorityIn !== undefined && this._value.caRdPriorityIn !== null ? new InFilterClass(this._value.caRdPriorityIn) : this._value.caRdPriorityIn;
  }

  get dueDateRange() {
    return this._value.dueDateRange !== undefined && this._value.dueDateRange !== null ? new DateRangeFilterClass(this._value.dueDateRange) : this._value.dueDateRange;
  }

  get itemsIn() {
    return this._value.itemsIn !== undefined && this._value.itemsIn !== null ? new InFilterClass(this._value.itemsIn) : this._value.itemsIn;
  }

  get workTypesIn() {
    return this._value.workTypesIn !== undefined && this._value.workTypesIn !== null ? new InFilterClass(this._value.workTypesIn) : this._value.workTypesIn;
  }

  get vitalsIn() {
    return this._value.vitalsIn !== undefined && this._value.vitalsIn !== null ? new InFilterClass(this._value.vitalsIn) : this._value.vitalsIn;
  }

  get programsFilterIn() {
    return this._value.programsFilterIn !== undefined && this._value.programsFilterIn !== null ? new InFilterClass(this._value.programsFilterIn) : this._value.programsFilterIn;
  }

  get controlLevelIn() {
    return this._value.controlLevelIn !== undefined && this._value.controlLevelIn !== null ? new InFilterClass(this._value.controlLevelIn) : this._value.controlLevelIn;
  }

  get programsContain() {
    return this._value.programsContain !== undefined && this._value.programsContain !== null ? new ContainAllFilterClass(this._value.programsContain) : this._value.programsContain;
  }

  get enrolledOnRange() {
    return this._value.enrolledOnRange !== undefined && this._value.enrolledOnRange !== null ? new DateRangeFilterClass(this._value.enrolledOnRange) : this._value.enrolledOnRange;
  }

  get upcomingVisitRange() {
    return this._value.upcomingVisitRange !== undefined && this._value.upcomingVisitRange !== null ? new DateRangeFilterClass(this._value.upcomingVisitRange) : this._value.upcomingVisitRange;
  }

  get patientTypeIn() {
    return this._value.patientTypeIn !== undefined && this._value.patientTypeIn !== null ? new InFilterClass(this._value.patientTypeIn) : this._value.patientTypeIn;
  }

  get doctorIdIn() {
    return this._value.doctorIdIn !== undefined && this._value.doctorIdIn !== null ? new InFilterClass(this._value.doctorIdIn) : this._value.doctorIdIn;
  }

  get clinicIdIn() {
    return this._value.clinicIdIn !== undefined && this._value.clinicIdIn !== null ? new InFilterClass(this._value.clinicIdIn) : this._value.clinicIdIn;
  }

  get clinicInfo() {
    return this._value.clinicInfo !== undefined && this._value.clinicInfo !== null ? new LimitedClinicClass(this._value.clinicInfo) : this._value.clinicInfo;
  }

  get doctorInfo() {
    return this._value.doctorInfo !== undefined && this._value.doctorInfo !== null ? new LimitedUserClass(this._value.doctorInfo) : this._value.doctorInfo;
  }

  get updatedAtRange() {
    return this._value.updatedAtRange !== undefined && this._value.updatedAtRange !== null ? new DateRangeFilterClass(this._value.updatedAtRange) : this._value.updatedAtRange;
  }

  get filterWatchedPatient() {
    return this._value.filterWatchedPatient;
  }

  get filterWatchedPatientWithDefault() {
    return this._value.filterWatchedPatient || false;
  }
}
export interface PatientCareGetPatientCareCountTaskParams {
	filter: SearchRequest<PatientCareDetail>;
}

export interface PatientCareGetMyPatientCareResponseParams {
	request: PatientCareRequest;
}

export class PatientCareService extends BaseController<PatientCareDetail, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/patient-care';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getPatientCareCountTask({ baseUrl, params, headers }: RequestOption<PatientCareGetPatientCareCountTaskParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<CountTask>>(`${baseUrl || this.baseURL}${this.basePath}/count-task${getQueryStrings(queryList)}`, params.filter, { headers });
	}

	public getMyPatientCareResponse({ baseUrl, params, headers }: RequestOption<PatientCareGetMyPatientCareResponseParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<PatientCareListItem>>>(`${baseUrl || this.baseURL}${this.basePath}/my-list${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const patientCareService = new PatientCareService();

export const createPatientCareGetPatientCareCountTaskObject = (value: APIResponse<CountTask>) => new APIResponseClass<CountTaskClass>(value, (value: CountTask) => new CountTaskClass(value));

export const usePatientCareGetPatientCareCountTask = <RCN = undefined>(configs: MakeRequestHookConfig<PatientCareGetPatientCareCountTaskParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientCareGetPatientCareCountTaskParams,
    APIResponse<CountTask>,
    typeof patientCareService.getPatientCareCountTask,
    AjaxOptions,
    APIResponseClass<CountTaskClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientCareService.getPatientCareCountTask>) => (
      patientCareService.getPatientCareCountTask(...args)
    ),
    ResponseClassCreator: createPatientCareGetPatientCareCountTaskObject,
    typeName: 'usePatientCareGetPatientCareCountTask',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientCareGetPatientCareCountTask.mock = () => new Error('Mock has not been implemented!');
usePatientCareGetPatientCareCountTask.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientCareGetPatientCareCountTask.createObject = createPatientCareGetPatientCareCountTaskObject;

export const createPatientCareGetMyPatientCareResponseObject = (value: APIResponse<PageResponse<PatientCareListItem>>) => new APIResponseClass<PageResponseClass<PatientCareListItemClass>>(value, (value: PageResponse<PatientCareListItem>) => new PageResponseClass<PatientCareListItemClass>(value, (value: PatientCareListItem) => new PatientCareListItemClass(value)));

export const usePatientCareGetMyPatientCareResponse = <RCN = undefined>(configs: MakeRequestHookConfig<PatientCareGetMyPatientCareResponseParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientCareGetMyPatientCareResponseParams,
    APIResponse<PageResponse<PatientCareListItem>>,
    typeof patientCareService.getMyPatientCareResponse,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientCareListItemClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientCareService.getMyPatientCareResponse>) => (
      patientCareService.getMyPatientCareResponse(...args)
    ),
    ResponseClassCreator: createPatientCareGetMyPatientCareResponseObject,
    typeName: 'usePatientCareGetMyPatientCareResponse',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientCareGetMyPatientCareResponse.mock = () => new Error('Mock has not been implemented!');
usePatientCareGetMyPatientCareResponse.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientCareGetMyPatientCareResponse.createObject = createPatientCareGetMyPatientCareResponseObject;


export const createPatientCareGetObject = (value: APIResponse<PatientCareDetail>) => new APIResponseClass<PatientCareDetailClass>(value, (value: PatientCareDetail) => new PatientCareDetailClass(value));
export const usePatientCareGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<PatientCareDetail>,
    // @ts-ignore
    typeof patientCareService.get,
    GetOptions,
    APIResponseClass<PatientCareDetailClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientCareService.get>) => (
      patientCareService.get(...args)
    ),
    ResponseClassCreator: createPatientCareGetObject,
    typeName: 'usePatientCareGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientCareGet.mock = () => new Error('Mock has not been implemented!');
usePatientCareGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientCareGet.createObject = createPatientCareGetObject;

export const createPatientCareUpdateObject = (value: APIResponse<PatientCareDetail>) => new APIResponseClass<PatientCareDetailClass>(value, (value: PatientCareDetail) => new PatientCareDetailClass(value));
export const usePatientCareUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, PatientCareDetail>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, PatientCareDetail>,
    APIResponse<PatientCareDetail>,
    // @ts-ignore
    typeof patientCareService.update,
    AjaxOptions,
    APIResponseClass<PatientCareDetailClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientCareService.update>) => (
      patientCareService.update(...args)
    ),
    ResponseClassCreator: createPatientCareUpdateObject,
    typeName: 'usePatientCareUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientCareUpdate.mock = () => new Error('Mock has not been implemented!');
usePatientCareUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientCareUpdate.createObject = createPatientCareUpdateObject;

export const createPatientCareInsertObject = (value: APIResponse<PatientCareDetail>) => new APIResponseClass<PatientCareDetailClass>(value, (value: PatientCareDetail) => new PatientCareDetailClass(value));
export const usePatientCareInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<PatientCareDetail>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<PatientCareDetail>,
    APIResponse<PatientCareDetail>,
    // @ts-ignore
    typeof patientCareService.insert,
    AjaxOptions,
    APIResponseClass<PatientCareDetailClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientCareService.insert>) => (
      patientCareService.insert(...args)
    ),
    ResponseClassCreator: createPatientCareInsertObject,
    typeName: 'usePatientCareInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientCareInsert.mock = () => new Error('Mock has not been implemented!');
usePatientCareInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientCareInsert.createObject = createPatientCareInsertObject;

export const createPatientCareDeleteObject = (value: APIResponse<PatientCareDetail>) => new APIResponseClass<PatientCareDetailClass>(value, (value: PatientCareDetail) => new PatientCareDetailClass(value));
export const usePatientCareDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<PatientCareDetail>,
    // @ts-ignore
    typeof patientCareService.delete,
    AjaxOptions,
    APIResponseClass<PatientCareDetailClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientCareService.delete>) => (
      patientCareService.delete(...args)
    ),
    ResponseClassCreator: createPatientCareDeleteObject,
    typeName: 'usePatientCareDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientCareDelete.mock = () => new Error('Mock has not been implemented!');
usePatientCareDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientCareDelete.createObject = createPatientCareDeleteObject;

export const createPatientCareSearchObject = (value: APIResponse<PageResponse<PatientCareDetail>>) => new APIResponseClass<PageResponseClass<PatientCareDetailClass>>(value, (value: PageResponse<PatientCareDetail>) => new PageResponseClass<PatientCareDetailClass>(value, (value: PatientCareDetail) => new PatientCareDetailClass(value)));
export const usePatientCareSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<PatientCareDetail>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<PatientCareDetail>,
    APIResponse<PageResponse<PatientCareDetail>>,
    // @ts-ignore
    typeof patientCareService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientCareDetailClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientCareService.search>) => (
      patientCareService.search(...args)
    ),
    ResponseClassCreator: createPatientCareSearchObject,
    typeName: 'usePatientCareSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientCareSearch.mock = () => new Error('Mock has not been implemented!');
usePatientCareSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientCareSearch.createObject = createPatientCareSearchObject;

export enum ServiceCategoryEnum {
	ENGAGEMENT_AUTO_MESSAGES = 'ENGAGEMENT_AUTO_MESSAGES',
	ROUTINE_VISITS = 'ROUTINE_VISITS',
	NUTRITION_SERVICE = 'NUTRITION_SERVICE',
}
export interface EnrollService extends BaseModel {
	category?: Nullable<ServiceCategoryEnum>;
}
export enum DischargeReasonEnum {
	DEVICE_ISSUES = 'DEVICE_ISSUES',
	SWITCH_TO_ANOTHER_DEVICE = 'SWITCH_TO_ANOTHER_DEVICE',
	TECHNICAL_CHALLENGES_TO_USE = 'TECHNICAL_CHALLENGES_TO_USE',
	REFUSE_TO_MONITOR_VITALS = 'REFUSE_TO_MONITOR_VITALS',
	DEVICE_UNDERUSED = 'DEVICE_UNDERUSED',
	PROGRAM_OVERWHELMING = 'PROGRAM_OVERWHELMING',
	PATIENT_HEALTH_STABLE = 'PATIENT_HEALTH_STABLE',
	PCP_CHANGE = 'PCP_CHANGE',
	INSURANCE_RELATED_ISSUES = 'INSURANCE_RELATED_ISSUES',
	DECEASED = 'DECEASED',
	BEHAVIOR_POSES_RISK_TO_CT = 'BEHAVIOR_POSES_RISK_TO_CT',
	OTHERS = 'OTHERS',
	value = 'value',
}
export interface DischargedDetail {
	dischargedAt?: Nullable<string>;
	dischargedBy?: Nullable<string>;
	note?: Nullable<string>;
	reason?: Nullable<DischargeReasonEnum>;
	devices?: Nullable<AssignDeviceInfo[]>;
}
export enum PatientHiatusStatusEnum {
	PENDING = 'PENDING',
	RESUMED = 'RESUMED',
	PAUSED = 'PAUSED',
}
export enum HiatusStatusReasonEnum {
	INACTIVITY = 'INACTIVITY',
	EXTENDED_TRAVEL = 'EXTENDED_TRAVEL',
	HOSPITALIZATION = 'HOSPITALIZATION',
	INSURANCE_MATTERS = 'INSURANCE_MATTERS',
	HEALTH_WELL_CONTROL = 'HEALTH_WELL_CONTROL',
	PROGRAM_OVERWHELMING = 'PROGRAM_OVERWHELMING',
	FAMILY_ISSUES = 'FAMILY_ISSUES',
	OTHERS = 'OTHERS',
	value = 'value',
}
export interface PauseServiceInfo {
	status?: Nullable<PatientHiatusStatusEnum>;
	reason?: Nullable<HiatusStatusReasonEnum[]>;
	pausedBy?: Nullable<string>;
	resumedBy?: Nullable<string>;
	isEdit?: Nullable<boolean>;
	startDate?: Nullable<string>;
	startDay?: Nullable<string>;
	endDate?: Nullable<string>;
	endDay?: Nullable<string>;
	updatedAt?: Nullable<string>;
	updatedDay?: Nullable<string>;
}
export interface PatientHiatusStatus extends PatientBase {
	vitalMonitoring?: Nullable<PauseServiceInfo>;
	visitService?: Nullable<PauseServiceInfo>;
	billingReport?: Nullable<PauseServiceInfo>;
	note?: Nullable<string>;
	status?: Nullable<PatientHiatusStatusEnum>;
	hasRefactored?: Nullable<boolean>;
	vitalMonitoringStatusIn?: Nullable<InFilter<PatientHiatusStatusEnum>>;
	visitServiceStatusIn?: Nullable<InFilter<PatientHiatusStatusEnum>>;
	billingReportStatusIn?: Nullable<InFilter<PatientHiatusStatusEnum>>;
	pauseVitalMonitoring?: Nullable<boolean>;
	pauseVisitService?: Nullable<boolean>;
	pauseBillingReport?: Nullable<boolean>;
	reason?: Nullable<HiatusStatusReasonEnum[]>;
	resumeBy?: Nullable<string>;
	isPauseAction?: Nullable<boolean>;
	startDate?: Nullable<string>;
	endDate?: Nullable<string>;
	memberId?: Nullable<string>;
	organizationId?: Nullable<string>;
	timezone?: Nullable<string>;
	vitalsMonitoringHiatus?: Nullable<boolean>;
	visitHiatus?: Nullable<boolean>;
	hiatusUnableToEngage?: Nullable<boolean>;
}
export interface UnableToEngage {
	hiatusUnableToEngage?: Nullable<boolean>;
	unableToEngageReason?: Nullable<string>;
	unableToEngageUpdateTime?: Nullable<string>;
	updatedBy?: Nullable<string>;
	hiatusUnableToEngageNe?: Nullable<NeFilter<boolean>>;
}
export interface PatientEnrolledProgram extends PatientBase {
	vitals?: Nullable<MonitoringVital[]>;
	programs?: Nullable<Program[]>;
	services?: Nullable<EnrollService[]>;
	additionalServices?: Nullable<AdditionalService[]>;
	enrollmentDate?: Nullable<string>;
	startDate?: Nullable<string>;
	endDate?: Nullable<string>;
	dischargedDetail?: Nullable<DischargedDetail>;
	hiatusDetail?: Nullable<PatientHiatusStatus>;
	unableToEngage?: Nullable<UnableToEngage>;
	enrolledStatus?: Nullable<EnrolledProgramStatusEnum>;
	visitId?: Nullable<string>;
	onboardingFinishTime?: Nullable<string>;
	removedVitalList?: Nullable<VitalEnumType[]>;
	isEditVitals?: Nullable<boolean>;
	isEditPrograms?: Nullable<boolean>;
	programIn?: Nullable<InFilter<ProgramCategoryEnum>>;
	vitalIn?: Nullable<InFilter<VitalEnumType>>;
	enrolledStatusIn?: Nullable<InFilter<EnrolledProgramStatusEnum>>;
	hiatusNoteIn?: Nullable<InFilter<string>>;
	enrollmentDateRange?: Nullable<DateRangeFilter>;
}
export enum CallResultEnum {
	AGREED_TO_ENROLL = 'AGREED_TO_ENROLL',
	VISIT_SCHEDULED = 'VISIT_SCHEDULED',
	DECISION_PENDING = 'DECISION_PENDING',
	NO_PICK_UP = 'NO_PICK_UP',
	NEED_INS_VERIFICATION = 'NEED_INS_VERIFICATION',
	REJECTED_TO_ENROLL = 'REJECTED_TO_ENROLL',
}
export interface OutReachCall {
	callerId?: Nullable<string>;
	caller?: Nullable<string>;
	callResult?: Nullable<CallResultEnum>;
	comment?: Nullable<string>;
	round?: Nullable<number>;
}
export interface PatientOutReachCall extends PatientBase {
	outReachCallRoundOne?: Nullable<OutReachCall>;
	outReachCallRoundTwo?: Nullable<OutReachCall>;
	outReachCallRoundThree?: Nullable<OutReachCall>;
	roundOneCallResultEnumIn?: Nullable<InFilter<CallResultEnum>>;
	roundTwoCallResultEnumIn?: Nullable<InFilter<CallResultEnum>>;
	roundThreeCallResultEnumIn?: Nullable<InFilter<CallResultEnum>>;
	callerRoundOneIn?: Nullable<InFilter<string>>;
	callerRoundTwoIn?: Nullable<InFilter<string>>;
	callerRoundThreeIn?: Nullable<InFilter<string>>;
}
export enum PatientListTypeEnum {
	ENROLLED = 'ENROLLED',
	UNENROLLED = 'UNENROLLED',
	SCREENING = 'SCREENING',
	CONVERSION = 'CONVERSION',
	ONBOARDING = 'ONBOARDING',
	ALL = 'ALL',
}
export enum BillableTimeSegmentEnum {
	TEN_MINUTES = 'TEN_MINUTES',
	TEN_TO_FIFTEEN_MINUTES = 'TEN_TO_FIFTEEN_MINUTES',
	FIFTEEN_TO_TWENTY_MINUTES = 'FIFTEEN_TO_TWENTY_MINUTES',
	TWENTY_TO_THIRTY_FIVE_MINUTES = 'TWENTY_TO_THIRTY_FIVE_MINUTES',
	THIRTY_FIVE_TO_FORTY_MINUTES = 'THIRTY_FIVE_TO_FORTY_MINUTES',
	FORTY_TO_FIFTY_FIVE_MINUTES = 'FORTY_TO_FIFTY_FIVE_MINUTES',
	FIFTY_FIVE_TO_SIXTY_MINUTES = 'FIFTY_FIVE_TO_SIXTY_MINUTES',
	SIXTY_TO_ABOVE_MINUTES = 'SIXTY_TO_ABOVE_MINUTES',
}
export interface BillableTimeSegmentRequest {
	billableTimeSegment?: Nullable<BillableTimeSegmentEnum>;
	monthOfYear?: Nullable<number>;
}
export enum EnrollmentDateSegmentEnum {
	ONE_WEEK = 'ONE_WEEK',
	TWO_WEEKS = 'TWO_WEEKS',
	ONE_MONTH = 'ONE_MONTH',
	THREE_MONTHS = 'THREE_MONTHS',
	ALL = 'ALL',
}
export interface PatientFilter {
	patient?: Nullable<Patient>;
	enrolledProgram?: Nullable<PatientEnrolledProgram>;
	callReachResult?: Nullable<PatientOutReachCall>;
	assignee?: Nullable<PatientAssignees>;
	listType?: Nullable<PatientListTypeEnum>;
	enrollmentRequest?: Nullable<EnrollmentRequest>;
	needToFilter?: Nullable<boolean>;
	medicalOrgIds?: Nullable<string[]>;
	billableTimeSegmentRequest?: Nullable<BillableTimeSegmentRequest>;
	enrollmentDateSegment?: Nullable<EnrollmentDateSegmentEnum>;
	patientType?: Nullable<string>;
}
export interface PatientInClinicResult {
	clinicId?: Nullable<string>;
	count?: Nullable<number>;
}
export interface PatientCountResult {
	enrolledPtCount?: Nullable<number>;
	unEnrolledPtCount?: Nullable<number>;
	allPtCount?: Nullable<number>;
	watchedPtCount?: Nullable<number>;
	assignedPtCount?: Nullable<number>;
	patientInClinicResults?: Nullable<PatientInClinicResult[]>;
}
export class PatientInClinicResultClass {
  protected readonly _value: PatientInClinicResult;

	constructor(value: PatientInClinicResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get clinicId() {
    return this._value.clinicId;
  }

  get count() {
    return this._value.count;
  }

  get countWithDefault() {
    return this._value.count || 0;
  }
}
export class PatientCountResultClass {
  protected readonly _value: PatientCountResult;

	constructor(value: PatientCountResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get enrolledPtCount() {
    return this._value.enrolledPtCount;
  }

  get enrolledPtCountWithDefault() {
    return this._value.enrolledPtCount || 0;
  }

  get unEnrolledPtCount() {
    return this._value.unEnrolledPtCount;
  }

  get unEnrolledPtCountWithDefault() {
    return this._value.unEnrolledPtCount || 0;
  }

  get allPtCount() {
    return this._value.allPtCount;
  }

  get allPtCountWithDefault() {
    return this._value.allPtCount || 0;
  }

  get watchedPtCount() {
    return this._value.watchedPtCount;
  }

  get watchedPtCountWithDefault() {
    return this._value.watchedPtCount || 0;
  }

  get assignedPtCount() {
    return this._value.assignedPtCount;
  }

  get assignedPtCountWithDefault() {
    return this._value.assignedPtCount || 0;
  }

  get patientInClinicResults() {
    return this._value.patientInClinicResults !== undefined && this._value.patientInClinicResults !== null ? map(this._value.patientInClinicResults, (v) => new PatientInClinicResultClass(v)) : this._value.patientInClinicResults;
  }

  get patientInClinicResultsWithDefault() {
    return this._value.patientInClinicResults || [];
  }
}
export enum MonthlyReviewStatusEnum {
	INIT = 'INIT',
	DONE = 'DONE',
	DRAFT = 'DRAFT',
	CLOSED = 'CLOSED',
}
export enum ProviderNoteCategoryEnum {
	GENERAL = 'GENERAL',
	NURSING = 'NURSING',
	MED_RECONCILIATION = 'MED_RECONCILIATION',
	CALL_LOG = 'CALL_LOG',
	RE_ENGAGEMENT = 'RE_ENGAGEMENT',
	ALERTS = 'ALERTS',
	UNFLAG = 'UNFLAG',
	PAUSE_FOLLOW_UP = 'PAUSE_FOLLOW_UP',
	FOLLOW_UP_COUNSELING = 'FOLLOW_UP_COUNSELING',
	SMART_ALERT = 'SMART_ALERT',
	TASK_NOTES = 'TASK_NOTES',
	DOCTOR_REVIEWED = 'DOCTOR_REVIEWED',
	OFFLINE_TASK_LOG = 'OFFLINE_TASK_LOG',
	MTPR = 'MTPR',
	MTPR_25 = 'MTPR_25',
	MTPR_MANUAL = 'MTPR_MANUAL',
	MTPR_FOLLOW_UP = 'MTPR_FOLLOW_UP',
	MRE = 'MRE',
	MTPR_ADDITIONAL = 'MTPR_ADDITIONAL',
	MRE_ADDITIONAL = 'MRE_ADDITIONAL',
}
export interface MonthlyReview extends PatientBase {
	memberId?: Nullable<string>;
	organizationId?: Nullable<string>;
	teamId?: Nullable<string>;
	status?: Nullable<MonthlyReviewStatusEnum>;
	type?: Nullable<ProviderNoteCategoryEnum>;
	monthOfYear?: Nullable<number>;
	dueDate?: Nullable<string>;
	note?: Nullable<string>;
	reviewedBy?: Nullable<string>;
	createdBy?: Nullable<string>;
	signedOffBy?: Nullable<string>;
	signedOffAt?: Nullable<string>;
	goals?: Nullable<ClinicalGoalItem[]>;
	previousGoals?: Nullable<ClinicalGoalItem[]>;
	memberIdIn?: Nullable<InFilter<string>>;
	statusIn?: Nullable<InFilter<MonthlyReviewStatusEnum>>;
	monthOfYearIn?: Nullable<InFilter<number>>;
	typeIn?: Nullable<InFilter<ProviderNoteCategoryEnum>>;
	monthOfYearRange?: Nullable<RangeFilter<number>>;
}
export interface MtprEligibility {
	followUpVisits?: Nullable<ClinicEvent[]>;
	completedMtprs?: Nullable<MonthlyReview[]>;
}
export class MonthlyReviewClass extends PatientBaseClass {
  protected readonly _value: MonthlyReview;

	constructor(value: MonthlyReview) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get teamId() {
    return this._value.teamId;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: MonthlyReviewStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: ProviderNoteCategoryEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get monthOfYear() {
    return this._value.monthOfYear;
  }

  get monthOfYearWithDefault() {
    return this._value.monthOfYear || 0;
  }

  get dueDate() {
    return (this._value.dueDate !== undefined && this._value.dueDate !== null) ? dayjs(this._value.dueDate) : this._value.dueDate;
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get reviewedBy() {
    return this._value.reviewedBy;
  }

  get createdBy() {
    return this._value.createdBy;
  }

  get signedOffBy() {
    return this._value.signedOffBy;
  }

  get signedOffAt() {
    return (this._value.signedOffAt !== undefined && this._value.signedOffAt !== null) ? dayjs(this._value.signedOffAt) : this._value.signedOffAt;
  }

  get goals() {
    return this._value.goals !== undefined && this._value.goals !== null ? map(this._value.goals, (v) => new ClinicalGoalItemClass(v)) : this._value.goals;
  }

  get goalsWithDefault() {
    return this._value.goals || [];
  }

  get previousGoals() {
    return this._value.previousGoals !== undefined && this._value.previousGoals !== null ? map(this._value.previousGoals, (v) => new ClinicalGoalItemClass(v)) : this._value.previousGoals;
  }

  get previousGoalsWithDefault() {
    return this._value.previousGoals || [];
  }

  get memberIdIn() {
    return this._value.memberIdIn !== undefined && this._value.memberIdIn !== null ? new InFilterClass(this._value.memberIdIn) : this._value.memberIdIn;
  }

  get statusIn() {
    return this._value.statusIn !== undefined && this._value.statusIn !== null ? new InFilterClass(this._value.statusIn) : this._value.statusIn;
  }

  get monthOfYearIn() {
    return this._value.monthOfYearIn !== undefined && this._value.monthOfYearIn !== null ? new InFilterClass(this._value.monthOfYearIn) : this._value.monthOfYearIn;
  }

  get typeIn() {
    return this._value.typeIn !== undefined && this._value.typeIn !== null ? new InFilterClass(this._value.typeIn) : this._value.typeIn;
  }

  get monthOfYearRange() {
    return this._value.monthOfYearRange !== undefined && this._value.monthOfYearRange !== null ? new RangeFilterClass(this._value.monthOfYearRange) : this._value.monthOfYearRange;
  }
}
export class MtprEligibilityClass {
  protected readonly _value: MtprEligibility;

	constructor(value: MtprEligibility) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get followUpVisits() {
    return this._value.followUpVisits !== undefined && this._value.followUpVisits !== null ? map(this._value.followUpVisits, (v) => new ClinicEventClass(v)) : this._value.followUpVisits;
  }

  get followUpVisitsWithDefault() {
    return this._value.followUpVisits || [];
  }

  get completedMtprs() {
    return this._value.completedMtprs !== undefined && this._value.completedMtprs !== null ? map(this._value.completedMtprs, (v) => new MonthlyReviewClass(v)) : this._value.completedMtprs;
  }

  get completedMtprsWithDefault() {
    return this._value.completedMtprs || [];
  }
}
export interface OnboardingOutstandingDetail {
	hasAssignedDevice?: Nullable<boolean>;
	hasOnboardingVisit?: Nullable<boolean>;
	hasInitialVisit?: Nullable<boolean>;
}
export enum TranscribeOutstandingTypeEnum {
	INIT = 'INIT',
	A1C = 'A1C',
	FOLLOW_UP = 'FOLLOW_UP',
}
export interface TranscribeOutstandingDetail {
	id?: Nullable<string>;
	dueDate?: Nullable<string>;
	type?: Nullable<TranscribeOutstandingTypeEnum>;
}
export interface OutstandingItems {
	visitCount?: Nullable<number>;
	visitIds?: Nullable<string[]>;
	messageCount?: Nullable<number>;
	alertCount?: Nullable<number>;
	snoozedAlertCount?: Nullable<number>;
	monthlyReviewCount?: Nullable<number>;
	monthlyReviewId?: Nullable<string>;
	foodLogCount?: Nullable<number>;
	taskCount?: Nullable<number>;
	taskIds?: Nullable<string[]>;
	onboardingToDoCount?: Nullable<number>;
	onboardingToDoDetail?: Nullable<OnboardingOutstandingDetail>;
	transcribeCount?: Nullable<number>;
	transcribeDetails?: Nullable<TranscribeOutstandingDetail[]>;
	referralCount?: Nullable<number>;
	enrollmentPrepCount?: Nullable<number>;
}
export class OnboardingOutstandingDetailClass {
  protected readonly _value: OnboardingOutstandingDetail;

	constructor(value: OnboardingOutstandingDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get hasAssignedDevice() {
    return this._value.hasAssignedDevice;
  }

  get hasAssignedDeviceWithDefault() {
    return this._value.hasAssignedDevice || false;
  }

  get hasOnboardingVisit() {
    return this._value.hasOnboardingVisit;
  }

  get hasOnboardingVisitWithDefault() {
    return this._value.hasOnboardingVisit || false;
  }

  get hasInitialVisit() {
    return this._value.hasInitialVisit;
  }

  get hasInitialVisitWithDefault() {
    return this._value.hasInitialVisit || false;
  }
}
export class TranscribeOutstandingDetailClass {
  protected readonly _value: TranscribeOutstandingDetail;

	constructor(value: TranscribeOutstandingDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get dueDate() {
    return (this._value.dueDate !== undefined && this._value.dueDate !== null) ? dayjs(this._value.dueDate) : this._value.dueDate;
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: TranscribeOutstandingTypeEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }
}
export class OutstandingItemsClass {
  protected readonly _value: OutstandingItems;

	constructor(value: OutstandingItems) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get visitCount() {
    return this._value.visitCount;
  }

  get visitCountWithDefault() {
    return this._value.visitCount || 0;
  }

  get visitIds() {
    return this._value.visitIds;
  }

  get visitIdsWithDefault() {
    return this._value.visitIds || [];
  }

  get messageCount() {
    return this._value.messageCount;
  }

  get messageCountWithDefault() {
    return this._value.messageCount || 0;
  }

  get alertCount() {
    return this._value.alertCount;
  }

  get alertCountWithDefault() {
    return this._value.alertCount || 0;
  }

  get snoozedAlertCount() {
    return this._value.snoozedAlertCount;
  }

  get snoozedAlertCountWithDefault() {
    return this._value.snoozedAlertCount || 0;
  }

  get monthlyReviewCount() {
    return this._value.monthlyReviewCount;
  }

  get monthlyReviewCountWithDefault() {
    return this._value.monthlyReviewCount || 0;
  }

  get monthlyReviewId() {
    return this._value.monthlyReviewId;
  }

  get foodLogCount() {
    return this._value.foodLogCount;
  }

  get foodLogCountWithDefault() {
    return this._value.foodLogCount || 0;
  }

  get taskCount() {
    return this._value.taskCount;
  }

  get taskCountWithDefault() {
    return this._value.taskCount || 0;
  }

  get taskIds() {
    return this._value.taskIds;
  }

  get taskIdsWithDefault() {
    return this._value.taskIds || [];
  }

  get onboardingToDoCount() {
    return this._value.onboardingToDoCount;
  }

  get onboardingToDoCountWithDefault() {
    return this._value.onboardingToDoCount || 0;
  }

  get onboardingToDoDetail() {
    return this._value.onboardingToDoDetail !== undefined && this._value.onboardingToDoDetail !== null ? new OnboardingOutstandingDetailClass(this._value.onboardingToDoDetail) : this._value.onboardingToDoDetail;
  }

  get transcribeCount() {
    return this._value.transcribeCount;
  }

  get transcribeCountWithDefault() {
    return this._value.transcribeCount || 0;
  }

  get transcribeDetails() {
    return this._value.transcribeDetails !== undefined && this._value.transcribeDetails !== null ? map(this._value.transcribeDetails, (v) => new TranscribeOutstandingDetailClass(v)) : this._value.transcribeDetails;
  }

  get transcribeDetailsWithDefault() {
    return this._value.transcribeDetails || [];
  }

  get referralCount() {
    return this._value.referralCount;
  }

  get referralCountWithDefault() {
    return this._value.referralCount || 0;
  }

  get enrollmentPrepCount() {
    return this._value.enrollmentPrepCount;
  }

  get enrollmentPrepCountWithDefault() {
    return this._value.enrollmentPrepCount || 0;
  }
}
export interface IotDevice extends BaseModel {
	deviceId?: Nullable<string>;
	position?: Nullable<number>;
	isActive?: Nullable<boolean>;
	memberId?: Nullable<string>;
	deviceType?: Nullable<DeviceTypeEnum>;
	language?: Nullable<string>;
	accessedAt?: Nullable<string>;
	brand?: Nullable<string>;
	history?: Nullable<DeviceAssignmentHistory[]>;
	cuffSize?: Nullable<CuffSizeEnum>;
}
export interface PatientDeviceStatus {
	devices?: Nullable<IotDevice[]>;
}
export class IotDeviceClass extends BaseModelClass {
  protected readonly _value: IotDevice;

	constructor(value: IotDevice) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get deviceId() {
    return this._value.deviceId;
  }

  get deviceIdWithDefault() {
    return this._value.deviceId || '';
  }

  get position() {
    return this._value.position;
  }

  get positionWithDefault() {
    return this._value.position || 0;
  }

  get isActive() {
    return this._value.isActive;
  }

  get isActiveWithDefault() {
    return this._value.isActive || false;
  }

  get memberId() {
    return this._value.memberId;
  }

  get deviceType() {
    return this._value.deviceType;
  }

  deviceTypeIsIn(values: DeviceTypeEnum[] = []) {
    return this._value.deviceType && values.includes(this._value.deviceType);
  }

  get language() {
    return this._value.language;
  }

  get languageWithDefault() {
    return this._value.language || '';
  }

  get accessedAt() {
    return (this._value.accessedAt !== undefined && this._value.accessedAt !== null) ? dayjs(this._value.accessedAt) : this._value.accessedAt;
  }

  get brand() {
    return this._value.brand;
  }

  get brandWithDefault() {
    return this._value.brand || '';
  }

  get history() {
    return this._value.history !== undefined && this._value.history !== null ? map(this._value.history, (v) => new DeviceAssignmentHistoryClass(v)) : this._value.history;
  }

  get historyWithDefault() {
    return this._value.history || [];
  }

  get cuffSize() {
    return this._value.cuffSize;
  }

  cuffSizeIsIn(values: CuffSizeEnum[] = []) {
    return this._value.cuffSize && values.includes(this._value.cuffSize);
  }
}
export class PatientDeviceStatusClass {
  protected readonly _value: PatientDeviceStatus;

	constructor(value: PatientDeviceStatus) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get devices() {
    return this._value.devices !== undefined && this._value.devices !== null ? map(this._value.devices, (v) => new IotDeviceClass(v)) : this._value.devices;
  }

  get devicesWithDefault() {
    return this._value.devices || [];
  }
}
export interface IotDeviceConfig extends BaseModel {
	deviceId?: Nullable<string>;
	deviceStatus?: Nullable<DeviceStatusEnum>;
	name?: Nullable<string>;
	deviceModel?: Nullable<DeviceModelEnum>;
	language?: Nullable<string>;
	volume?: Nullable<number>;
	lastSeenAt?: Nullable<string>;
	deviceIdNear?: Nullable<string>;
	deviceModelIn?: Nullable<InFilter<DeviceModelEnum>>;
	battery?: Nullable<number>;
	bpStandard?: Nullable<string>;
	bpUnit?: Nullable<string>;
	countryId?: Nullable<string>;
	dateFormat?: Nullable<string>;
	firmwareVersion?: Nullable<string>;
	isActive?: Nullable<boolean>;
	latitude?: Nullable<string>;
	longitude?: Nullable<string>;
	protocol?: Nullable<string>;
	temperatureUnit?: Nullable<string>;
	timbre?: Nullable<number>;
	timeFormat?: Nullable<number>;
	timezone?: Nullable<string>;
}
export class IotDeviceConfigClass extends BaseModelClass {
  protected readonly _value: IotDeviceConfig;

	constructor(value: IotDeviceConfig) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get deviceId() {
    return this._value.deviceId;
  }

  get deviceIdWithDefault() {
    return this._value.deviceId || '';
  }

  get deviceStatus() {
    return this._value.deviceStatus;
  }

  deviceStatusIsIn(values: DeviceStatusEnum[] = []) {
    return this._value.deviceStatus && values.includes(this._value.deviceStatus);
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get deviceModel() {
    return this._value.deviceModel;
  }

  deviceModelIsIn(values: DeviceModelEnum[] = []) {
    return this._value.deviceModel && values.includes(this._value.deviceModel);
  }

  get language() {
    return this._value.language;
  }

  get languageWithDefault() {
    return this._value.language || '';
  }

  get volume() {
    return this._value.volume;
  }

  get volumeWithDefault() {
    return this._value.volume || 0;
  }

  get lastSeenAt() {
    return (this._value.lastSeenAt !== undefined && this._value.lastSeenAt !== null) ? dayjs(this._value.lastSeenAt) : this._value.lastSeenAt;
  }

  get deviceIdNear() {
    return this._value.deviceIdNear;
  }

  get deviceIdNearWithDefault() {
    return this._value.deviceIdNear || '';
  }

  get deviceModelIn() {
    return this._value.deviceModelIn !== undefined && this._value.deviceModelIn !== null ? new InFilterClass(this._value.deviceModelIn) : this._value.deviceModelIn;
  }

  get battery() {
    return this._value.battery;
  }

  get batteryWithDefault() {
    return this._value.battery || 0;
  }

  get bpStandard() {
    return this._value.bpStandard;
  }

  get bpStandardWithDefault() {
    return this._value.bpStandard || '';
  }

  get bpUnit() {
    return this._value.bpUnit;
  }

  get bpUnitWithDefault() {
    return this._value.bpUnit || '';
  }

  get countryId() {
    return this._value.countryId;
  }

  get countryIdWithDefault() {
    return this._value.countryId || '';
  }

  get dateFormat() {
    return this._value.dateFormat;
  }

  get dateFormatWithDefault() {
    return this._value.dateFormat || '';
  }

  get firmwareVersion() {
    return this._value.firmwareVersion;
  }

  get firmwareVersionWithDefault() {
    return this._value.firmwareVersion || '';
  }

  get isActive() {
    return this._value.isActive;
  }

  get isActiveWithDefault() {
    return this._value.isActive || false;
  }

  get latitude() {
    return this._value.latitude;
  }

  get latitudeWithDefault() {
    return this._value.latitude || '';
  }

  get longitude() {
    return this._value.longitude;
  }

  get longitudeWithDefault() {
    return this._value.longitude || '';
  }

  get protocol() {
    return this._value.protocol;
  }

  get protocolWithDefault() {
    return this._value.protocol || '';
  }

  get temperatureUnit() {
    return this._value.temperatureUnit;
  }

  get temperatureUnitWithDefault() {
    return this._value.temperatureUnit || '';
  }

  get timbre() {
    return this._value.timbre;
  }

  get timbreWithDefault() {
    return this._value.timbre || 0;
  }

  get timeFormat() {
    return this._value.timeFormat;
  }

  get timeFormatWithDefault() {
    return this._value.timeFormat || 0;
  }

  get timezone() {
    return this._value.timezone;
  }

  get timezoneWithDefault() {
    return this._value.timezone || '';
  }
}
export enum ActivityLevelEnum {
	EXTREMELY_INACTIVE = 'EXTREMELY_INACTIVE',
	SEDENTARY = 'SEDENTARY',
	IRREGULARLY_EXERCISING = 'IRREGULARLY_EXERCISING',
	MODERATELY_ACTIVE = 'MODERATELY_ACTIVE',
	ACTIVE = 'ACTIVE',
	VERY_ACTIVE = 'VERY_ACTIVE',
	EXTREMELY_ACTIVE = 'EXTREMELY_ACTIVE',
	NONE = 'NONE',
}
export interface PhysicalActivity {
	activityLevel?: Nullable<ActivityLevelEnum>;
	activityValue?: Nullable<string>;
	activityFreq?: Nullable<FrequencyUnitEnum>;
	activityDuration?: Nullable<string>;
}
export enum SleepingRoutineEnum {
	DAILY = 'DAILY',
	WEEK_4_6_TIMES = 'WEEK_4_6_TIMES',
	WEEK_2_3_TIMES = 'WEEK_2_3_TIMES',
	WEEK_LESS_THAN_2 = 'WEEK_LESS_THAN_2',
	IRREGULAR_ROUTINE = 'IRREGULAR_ROUTINE',
	NONE = 'NONE',
	NO_ROUTINE = 'NO_ROUTINE',
	REGULAR_ROUTINE = 'REGULAR_ROUTINE',
	MODERATELY_REGULAR_ROUTINE = 'MODERATELY_REGULAR_ROUTINE',
}
export enum EatingRoutineEnum {
	NONE = 'NONE',
	NO_ROUTINE = 'NO_ROUTINE',
	IRREGULAR_ROUTINE = 'IRREGULAR_ROUTINE',
	REGULAR_ROUTINE = 'REGULAR_ROUTINE',
	SOMEWHERE_IRREGULAR_ROUTINE = 'SOMEWHERE_IRREGULAR_ROUTINE',
}
export enum FoodAccessEnum {
	NONE = 'NONE',
	LACK_RESOURCES = 'LACK_RESOURCES',
	SHOP_NO_CARE = 'SHOP_NO_CARE',
	NO_AID = 'NO_AID',
	AID = 'AID',
	KITCHEN = 'KITCHEN',
	COOK_NO_CARE = 'COOK_NO_CARE',
	COOK_CARE = 'COOK_CARE',
	SHOP_CARE = 'SHOP_CARE',
}
export interface SmokeDrinkHistory {
	smokingOrDrinking?: Nullable<boolean>;
	quitYear?: Nullable<string>;
	activityValue?: Nullable<string>;
	activityFreq?: Nullable<FrequencyUnitEnum>;
	drinkQuantity?: Nullable<string>;
	drinkChoice?: Nullable<string>;
}
export interface Lifestyle extends PatientBase {
	physicalActivity?: Nullable<PhysicalActivity>;
	sleepingRoutine?: Nullable<SleepingRoutineEnum>;
	eatingRoutine?: Nullable<EatingRoutineEnum>;
	foodAccessibility?: Nullable<FoodAccessEnum>;
	smokingHistory?: Nullable<SmokeDrinkHistory>;
	drinkingHistory?: Nullable<SmokeDrinkHistory>;
	lifestylePattern?: Nullable<string>;
	visitId?: Nullable<string>;
}
export class PhysicalActivityClass {
  protected readonly _value: PhysicalActivity;

	constructor(value: PhysicalActivity) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get activityLevel() {
    return this._value.activityLevel;
  }

  activityLevelIsIn(values: ActivityLevelEnum[] = []) {
    return this._value.activityLevel && values.includes(this._value.activityLevel);
  }

  get activityValue() {
    return this._value.activityValue;
  }

  get activityValueWithDefault() {
    return this._value.activityValue || '';
  }

  get activityFreq() {
    return this._value.activityFreq;
  }

  activityFreqIsIn(values: FrequencyUnitEnum[] = []) {
    return this._value.activityFreq && values.includes(this._value.activityFreq);
  }

  get activityDuration() {
    return this._value.activityDuration;
  }

  get activityDurationWithDefault() {
    return this._value.activityDuration || '';
  }
}
export class SmokeDrinkHistoryClass {
  protected readonly _value: SmokeDrinkHistory;

	constructor(value: SmokeDrinkHistory) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get smokingOrDrinking() {
    return this._value.smokingOrDrinking;
  }

  get smokingOrDrinkingWithDefault() {
    return this._value.smokingOrDrinking || false;
  }

  get quitYear() {
    return this._value.quitYear;
  }

  get quitYearWithDefault() {
    return this._value.quitYear || '';
  }

  get activityValue() {
    return this._value.activityValue;
  }

  get activityValueWithDefault() {
    return this._value.activityValue || '';
  }

  get activityFreq() {
    return this._value.activityFreq;
  }

  activityFreqIsIn(values: FrequencyUnitEnum[] = []) {
    return this._value.activityFreq && values.includes(this._value.activityFreq);
  }

  get drinkQuantity() {
    return this._value.drinkQuantity;
  }

  get drinkQuantityWithDefault() {
    return this._value.drinkQuantity || '';
  }

  get drinkChoice() {
    return this._value.drinkChoice;
  }

  get drinkChoiceWithDefault() {
    return this._value.drinkChoice || '';
  }
}
export class LifestyleClass extends PatientBaseClass {
  protected readonly _value: Lifestyle;

	constructor(value: Lifestyle) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get physicalActivity() {
    return this._value.physicalActivity !== undefined && this._value.physicalActivity !== null ? new PhysicalActivityClass(this._value.physicalActivity) : this._value.physicalActivity;
  }

  get sleepingRoutine() {
    return this._value.sleepingRoutine;
  }

  sleepingRoutineIsIn(values: SleepingRoutineEnum[] = []) {
    return this._value.sleepingRoutine && values.includes(this._value.sleepingRoutine);
  }

  get eatingRoutine() {
    return this._value.eatingRoutine;
  }

  eatingRoutineIsIn(values: EatingRoutineEnum[] = []) {
    return this._value.eatingRoutine && values.includes(this._value.eatingRoutine);
  }

  get foodAccessibility() {
    return this._value.foodAccessibility;
  }

  foodAccessibilityIsIn(values: FoodAccessEnum[] = []) {
    return this._value.foodAccessibility && values.includes(this._value.foodAccessibility);
  }

  get smokingHistory() {
    return this._value.smokingHistory !== undefined && this._value.smokingHistory !== null ? new SmokeDrinkHistoryClass(this._value.smokingHistory) : this._value.smokingHistory;
  }

  get drinkingHistory() {
    return this._value.drinkingHistory !== undefined && this._value.drinkingHistory !== null ? new SmokeDrinkHistoryClass(this._value.drinkingHistory) : this._value.drinkingHistory;
  }

  get lifestylePattern() {
    return this._value.lifestylePattern;
  }

  get lifestylePatternWithDefault() {
    return this._value.lifestylePattern || '';
  }

  get visitId() {
    return this._value.visitId;
  }
}
export enum DietEnum {
	NONE = 'NONE',
	ATKINS = 'ATKINS',
	BLOOD_TYPE = 'BLOOD_TYPE',
	KETO = 'KETO',
	EXERCISE = 'EXERCISE',
	CALORIE_RESTRICTION = 'CALORIE_RESTRICTION',
	DIET_PILLS = 'DIET_PILLS',
	HIGH_PROTEIN = 'HIGH_PROTEIN',
	LOW_SODIUM = 'LOW_SODIUM',
	LOW_SUGAR = 'LOW_SUGAR',
	LOW_CARB = 'LOW_CARB',
	LOW_FAT = 'LOW_FAT',
	WHOLE_30 = 'WHOLE_30',
	WEIGHT_WATCHERS = 'WEIGHT_WATCHERS',
	SOUTH_BEACH = 'SOUTH_BEACH',
}
export interface MealIntake {
	meal?: Nullable<BgMealTypeEnum>;
	timeRange?: Nullable<string>;
	foodTypeAmount?: Nullable<string>;
	mealFreq?: Nullable<string>;
	skipFreq?: Nullable<string>;
}
export interface JunkFoodFreq {
	value?: Nullable<number>;
	unit?: Nullable<FrequencyUnitEnum>;
}
export interface Nutrition extends PatientBase {
	previousDiets?: Nullable<DietEnum[]>;
	intake?: Nullable<MealIntake[]>;
	fastFoodFreq?: Nullable<JunkFoodFreq>;
	sweetBeverageFreq?: Nullable<JunkFoodFreq>;
	nutritionUnderstanding?: Nullable<string>;
	additionalComments?: Nullable<string>;
	visitId?: Nullable<string>;
}
export class MealIntakeClass {
  protected readonly _value: MealIntake;

	constructor(value: MealIntake) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get meal() {
    return this._value.meal;
  }

  mealIsIn(values: BgMealTypeEnum[] = []) {
    return this._value.meal && values.includes(this._value.meal);
  }

  get timeRange() {
    return this._value.timeRange;
  }

  get timeRangeWithDefault() {
    return this._value.timeRange || '';
  }

  get foodTypeAmount() {
    return this._value.foodTypeAmount;
  }

  get foodTypeAmountWithDefault() {
    return this._value.foodTypeAmount || '';
  }

  get mealFreq() {
    return this._value.mealFreq;
  }

  get mealFreqWithDefault() {
    return this._value.mealFreq || '';
  }

  get skipFreq() {
    return this._value.skipFreq;
  }

  get skipFreqWithDefault() {
    return this._value.skipFreq || '';
  }
}
export class JunkFoodFreqClass {
  protected readonly _value: JunkFoodFreq;

	constructor(value: JunkFoodFreq) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || 0;
  }

  get unit() {
    return this._value.unit;
  }

  unitIsIn(values: FrequencyUnitEnum[] = []) {
    return this._value.unit && values.includes(this._value.unit);
  }
}
export class NutritionClass extends PatientBaseClass {
  protected readonly _value: Nutrition;

	constructor(value: Nutrition) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get previousDiets() {
    return this._value.previousDiets;
  }

  get previousDietsWithDefault() {
    return this._value.previousDiets || [];
  }

  get intake() {
    return this._value.intake !== undefined && this._value.intake !== null ? map(this._value.intake, (v) => new MealIntakeClass(v)) : this._value.intake;
  }

  get intakeWithDefault() {
    return this._value.intake || [];
  }

  get fastFoodFreq() {
    return this._value.fastFoodFreq !== undefined && this._value.fastFoodFreq !== null ? new JunkFoodFreqClass(this._value.fastFoodFreq) : this._value.fastFoodFreq;
  }

  get sweetBeverageFreq() {
    return this._value.sweetBeverageFreq !== undefined && this._value.sweetBeverageFreq !== null ? new JunkFoodFreqClass(this._value.sweetBeverageFreq) : this._value.sweetBeverageFreq;
  }

  get nutritionUnderstanding() {
    return this._value.nutritionUnderstanding;
  }

  get nutritionUnderstandingWithDefault() {
    return this._value.nutritionUnderstanding || '';
  }

  get additionalComments() {
    return this._value.additionalComments;
  }

  get additionalCommentsWithDefault() {
    return this._value.additionalComments || '';
  }

  get visitId() {
    return this._value.visitId;
  }
}
export enum MsgTemplateTypeEnum {
	APP_INVITATION = 'APP_INVITATION',
	APP_MANUAL = 'APP_MANUAL',
	PRIME_APP_MANUAL = 'PRIME_APP_MANUAL',
	PRIME_APP_INVITATION = 'PRIME_APP_INVITATION',
	APP_UPGRADE = 'APP_UPGRADE',
	PROMOTE_UNIFIED_CARE = 'PROMOTE_UNIFIED_CARE',
	PROGRAM_INTRODUCTION = 'PROGRAM_INTRODUCTION',
	OTP = 'OTP',
	MSR_BG = 'MSR_BG',
	MSR_BP = 'MSR_BP',
	MSR_OTHER = 'MSR_OTHER',
	VIRTUAL_MEETING_NOTIFICATION_15_OTHER = 'VIRTUAL_MEETING_NOTIFICATION_15_OTHER',
	VIRTUAL_MEETING_NOTIFICATION_1440_TECH = 'VIRTUAL_MEETING_NOTIFICATION_1440_TECH',
	VIRTUAL_MEETING_NOTIFICATION_1440_OTHER = 'VIRTUAL_MEETING_NOTIFICATION_1440_OTHER',
	ONE_CLICK_VISIT = 'ONE_CLICK_VISIT',
	VISIT_CONFIRMATION = 'VISIT_CONFIRMATION',
	REWARD_REDEEM_IHEALTH_GIFT = 'REWARD_REDEEM_IHEALTH_GIFT',
	VISIT_TEXT_NOTIFICATION = 'VISIT_TEXT_NOTIFICATION',
	RESET_PASSWORD = 'RESET_PASSWORD',
}
export interface SmsRequest {
	msgType?: Nullable<MsgTemplateTypeEnum>;
	phoneNumber?: Nullable<string>;
}
export interface PatientResult extends Patient {
	enrolledProgram?: Nullable<PatientEnrolledProgram>;
	assignee?: Nullable<PatientAssignees>;
	enrollmentRequest?: Nullable<EnrollmentRequest>;
	outReachCallResult?: Nullable<PatientOutReachCall>;
	dischargedAt?: Nullable<string>;
}
export class EnrollServiceClass extends BaseModelClass {
  protected readonly _value: EnrollService;

	constructor(value: EnrollService) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get category() {
    return this._value.category;
  }

  categoryIsIn(values: ServiceCategoryEnum[] = []) {
    return this._value.category && values.includes(this._value.category);
  }
}
export class DischargedDetailClass {
  protected readonly _value: DischargedDetail;

	constructor(value: DischargedDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get dischargedAt() {
    return (this._value.dischargedAt !== undefined && this._value.dischargedAt !== null) ? dayjs(this._value.dischargedAt) : this._value.dischargedAt;
  }

  get dischargedBy() {
    return this._value.dischargedBy;
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get reason() {
    return this._value.reason;
  }

  reasonIsIn(values: DischargeReasonEnum[] = []) {
    return this._value.reason && values.includes(this._value.reason);
  }

  get devices() {
    return this._value.devices !== undefined && this._value.devices !== null ? map(this._value.devices, (v) => new AssignDeviceInfoClass(v)) : this._value.devices;
  }

  get devicesWithDefault() {
    return this._value.devices || [];
  }
}
export class PauseServiceInfoClass {
  protected readonly _value: PauseServiceInfo;

	constructor(value: PauseServiceInfo) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: PatientHiatusStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get reason() {
    return this._value.reason;
  }

  get reasonWithDefault() {
    return this._value.reason || [];
  }

  get pausedBy() {
    return this._value.pausedBy;
  }

  get resumedBy() {
    return this._value.resumedBy;
  }

  get isEdit() {
    return this._value.isEdit;
  }

  get isEditWithDefault() {
    return this._value.isEdit || false;
  }

  get startDate() {
    return (this._value.startDate !== undefined && this._value.startDate !== null) ? dayjs(this._value.startDate) : this._value.startDate;
  }

  get startDay() {
    return (this._value.startDay !== undefined && this._value.startDay !== null) ? dayjs(this._value.startDay) : this._value.startDay;
  }

  get endDate() {
    return (this._value.endDate !== undefined && this._value.endDate !== null) ? dayjs(this._value.endDate) : this._value.endDate;
  }

  get endDay() {
    return (this._value.endDay !== undefined && this._value.endDay !== null) ? dayjs(this._value.endDay) : this._value.endDay;
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }

  get updatedDay() {
    return (this._value.updatedDay !== undefined && this._value.updatedDay !== null) ? dayjs(this._value.updatedDay) : this._value.updatedDay;
  }
}
export class PatientHiatusStatusClass extends PatientBaseClass {
  protected readonly _value: PatientHiatusStatus;

	constructor(value: PatientHiatusStatus) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get vitalMonitoring() {
    return this._value.vitalMonitoring !== undefined && this._value.vitalMonitoring !== null ? new PauseServiceInfoClass(this._value.vitalMonitoring) : this._value.vitalMonitoring;
  }

  get visitService() {
    return this._value.visitService !== undefined && this._value.visitService !== null ? new PauseServiceInfoClass(this._value.visitService) : this._value.visitService;
  }

  get billingReport() {
    return this._value.billingReport !== undefined && this._value.billingReport !== null ? new PauseServiceInfoClass(this._value.billingReport) : this._value.billingReport;
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: PatientHiatusStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get hasRefactored() {
    return this._value.hasRefactored;
  }

  get hasRefactoredWithDefault() {
    return this._value.hasRefactored || false;
  }

  get vitalMonitoringStatusIn() {
    return this._value.vitalMonitoringStatusIn !== undefined && this._value.vitalMonitoringStatusIn !== null ? new InFilterClass(this._value.vitalMonitoringStatusIn) : this._value.vitalMonitoringStatusIn;
  }

  get visitServiceStatusIn() {
    return this._value.visitServiceStatusIn !== undefined && this._value.visitServiceStatusIn !== null ? new InFilterClass(this._value.visitServiceStatusIn) : this._value.visitServiceStatusIn;
  }

  get billingReportStatusIn() {
    return this._value.billingReportStatusIn !== undefined && this._value.billingReportStatusIn !== null ? new InFilterClass(this._value.billingReportStatusIn) : this._value.billingReportStatusIn;
  }

  get pauseVitalMonitoring() {
    return this._value.pauseVitalMonitoring;
  }

  get pauseVitalMonitoringWithDefault() {
    return this._value.pauseVitalMonitoring || false;
  }

  get pauseVisitService() {
    return this._value.pauseVisitService;
  }

  get pauseVisitServiceWithDefault() {
    return this._value.pauseVisitService || false;
  }

  get pauseBillingReport() {
    return this._value.pauseBillingReport;
  }

  get pauseBillingReportWithDefault() {
    return this._value.pauseBillingReport || false;
  }

  get reason() {
    return this._value.reason;
  }

  get reasonWithDefault() {
    return this._value.reason || [];
  }

  get resumeBy() {
    return this._value.resumeBy;
  }

  get isPauseAction() {
    return this._value.isPauseAction;
  }

  get isPauseActionWithDefault() {
    return this._value.isPauseAction || false;
  }

  get startDate() {
    return (this._value.startDate !== undefined && this._value.startDate !== null) ? dayjs(this._value.startDate) : this._value.startDate;
  }

  get endDate() {
    return (this._value.endDate !== undefined && this._value.endDate !== null) ? dayjs(this._value.endDate) : this._value.endDate;
  }

  get memberId() {
    return this._value.memberId;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get timezone() {
    return this._value.timezone;
  }

  get timezoneWithDefault() {
    return this._value.timezone || '';
  }

  get vitalsMonitoringHiatus() {
    return this._value.vitalsMonitoringHiatus;
  }

  get vitalsMonitoringHiatusWithDefault() {
    return this._value.vitalsMonitoringHiatus || false;
  }

  get visitHiatus() {
    return this._value.visitHiatus;
  }

  get visitHiatusWithDefault() {
    return this._value.visitHiatus || false;
  }

  get hiatusUnableToEngage() {
    return this._value.hiatusUnableToEngage;
  }

  get hiatusUnableToEngageWithDefault() {
    return this._value.hiatusUnableToEngage || false;
  }
}
export class UnableToEngageClass {
  protected readonly _value: UnableToEngage;

	constructor(value: UnableToEngage) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get hiatusUnableToEngage() {
    return this._value.hiatusUnableToEngage;
  }

  get hiatusUnableToEngageWithDefault() {
    return this._value.hiatusUnableToEngage || false;
  }

  get unableToEngageReason() {
    return this._value.unableToEngageReason;
  }

  get unableToEngageReasonWithDefault() {
    return this._value.unableToEngageReason || '';
  }

  get unableToEngageUpdateTime() {
    return (this._value.unableToEngageUpdateTime !== undefined && this._value.unableToEngageUpdateTime !== null) ? dayjs(this._value.unableToEngageUpdateTime) : this._value.unableToEngageUpdateTime;
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get hiatusUnableToEngageNe() {
    return this._value.hiatusUnableToEngageNe !== undefined && this._value.hiatusUnableToEngageNe !== null ? new NeFilterClass(this._value.hiatusUnableToEngageNe) : this._value.hiatusUnableToEngageNe;
  }
}
export class PatientEnrolledProgramClass extends PatientBaseClass {
  protected readonly _value: PatientEnrolledProgram;

	constructor(value: PatientEnrolledProgram) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get vitals() {
    return this._value.vitals !== undefined && this._value.vitals !== null ? map(this._value.vitals, (v) => new MonitoringVitalClass(v)) : this._value.vitals;
  }

  get vitalsWithDefault() {
    return this._value.vitals || [];
  }

  get programs() {
    return this._value.programs !== undefined && this._value.programs !== null ? map(this._value.programs, (v) => new ProgramClass(v)) : this._value.programs;
  }

  get programsWithDefault() {
    return this._value.programs || [];
  }

  get services() {
    return this._value.services !== undefined && this._value.services !== null ? map(this._value.services, (v) => new EnrollServiceClass(v)) : this._value.services;
  }

  get servicesWithDefault() {
    return this._value.services || [];
  }

  get additionalServices() {
    return this._value.additionalServices !== undefined && this._value.additionalServices !== null ? map(this._value.additionalServices, (v) => new AdditionalServiceClass(v)) : this._value.additionalServices;
  }

  get additionalServicesWithDefault() {
    return this._value.additionalServices || [];
  }

  get enrollmentDate() {
    return (this._value.enrollmentDate !== undefined && this._value.enrollmentDate !== null) ? dayjs(this._value.enrollmentDate) : this._value.enrollmentDate;
  }

  get startDate() {
    return (this._value.startDate !== undefined && this._value.startDate !== null) ? dayjs(this._value.startDate) : this._value.startDate;
  }

  get endDate() {
    return (this._value.endDate !== undefined && this._value.endDate !== null) ? dayjs(this._value.endDate) : this._value.endDate;
  }

  get dischargedDetail() {
    return this._value.dischargedDetail !== undefined && this._value.dischargedDetail !== null ? new DischargedDetailClass(this._value.dischargedDetail) : this._value.dischargedDetail;
  }

  get hiatusDetail() {
    return this._value.hiatusDetail !== undefined && this._value.hiatusDetail !== null ? new PatientHiatusStatusClass(this._value.hiatusDetail) : this._value.hiatusDetail;
  }

  get unableToEngage() {
    return this._value.unableToEngage !== undefined && this._value.unableToEngage !== null ? new UnableToEngageClass(this._value.unableToEngage) : this._value.unableToEngage;
  }

  get enrolledStatus() {
    return this._value.enrolledStatus;
  }

  enrolledStatusIsIn(values: EnrolledProgramStatusEnum[] = []) {
    return this._value.enrolledStatus && values.includes(this._value.enrolledStatus);
  }

  get visitId() {
    return this._value.visitId;
  }

  get onboardingFinishTime() {
    return (this._value.onboardingFinishTime !== undefined && this._value.onboardingFinishTime !== null) ? dayjs(this._value.onboardingFinishTime) : this._value.onboardingFinishTime;
  }

  get removedVitalList() {
    return this._value.removedVitalList;
  }

  get removedVitalListWithDefault() {
    return this._value.removedVitalList || [];
  }

  get isEditVitals() {
    return this._value.isEditVitals;
  }

  get isEditVitalsWithDefault() {
    return this._value.isEditVitals || false;
  }

  get isEditPrograms() {
    return this._value.isEditPrograms;
  }

  get isEditProgramsWithDefault() {
    return this._value.isEditPrograms || false;
  }

  get programIn() {
    return this._value.programIn !== undefined && this._value.programIn !== null ? new InFilterClass(this._value.programIn) : this._value.programIn;
  }

  get vitalIn() {
    return this._value.vitalIn !== undefined && this._value.vitalIn !== null ? new InFilterClass(this._value.vitalIn) : this._value.vitalIn;
  }

  get enrolledStatusIn() {
    return this._value.enrolledStatusIn !== undefined && this._value.enrolledStatusIn !== null ? new InFilterClass(this._value.enrolledStatusIn) : this._value.enrolledStatusIn;
  }

  get hiatusNoteIn() {
    return this._value.hiatusNoteIn !== undefined && this._value.hiatusNoteIn !== null ? new InFilterClass(this._value.hiatusNoteIn) : this._value.hiatusNoteIn;
  }

  get enrollmentDateRange() {
    return this._value.enrollmentDateRange !== undefined && this._value.enrollmentDateRange !== null ? new DateRangeFilterClass(this._value.enrollmentDateRange) : this._value.enrollmentDateRange;
  }
}
export class OutReachCallClass {
  protected readonly _value: OutReachCall;

	constructor(value: OutReachCall) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get callerId() {
    return this._value.callerId;
  }

  get caller() {
    return this._value.caller;
  }

  get callerWithDefault() {
    return this._value.caller || '';
  }

  get callResult() {
    return this._value.callResult;
  }

  callResultIsIn(values: CallResultEnum[] = []) {
    return this._value.callResult && values.includes(this._value.callResult);
  }

  get comment() {
    return this._value.comment;
  }

  get commentWithDefault() {
    return this._value.comment || '';
  }

  get round() {
    return this._value.round;
  }

  get roundWithDefault() {
    return this._value.round || 0;
  }
}
export class PatientOutReachCallClass extends PatientBaseClass {
  protected readonly _value: PatientOutReachCall;

	constructor(value: PatientOutReachCall) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get outReachCallRoundOne() {
    return this._value.outReachCallRoundOne !== undefined && this._value.outReachCallRoundOne !== null ? new OutReachCallClass(this._value.outReachCallRoundOne) : this._value.outReachCallRoundOne;
  }

  get outReachCallRoundTwo() {
    return this._value.outReachCallRoundTwo !== undefined && this._value.outReachCallRoundTwo !== null ? new OutReachCallClass(this._value.outReachCallRoundTwo) : this._value.outReachCallRoundTwo;
  }

  get outReachCallRoundThree() {
    return this._value.outReachCallRoundThree !== undefined && this._value.outReachCallRoundThree !== null ? new OutReachCallClass(this._value.outReachCallRoundThree) : this._value.outReachCallRoundThree;
  }

  get roundOneCallResultEnumIn() {
    return this._value.roundOneCallResultEnumIn !== undefined && this._value.roundOneCallResultEnumIn !== null ? new InFilterClass(this._value.roundOneCallResultEnumIn) : this._value.roundOneCallResultEnumIn;
  }

  get roundTwoCallResultEnumIn() {
    return this._value.roundTwoCallResultEnumIn !== undefined && this._value.roundTwoCallResultEnumIn !== null ? new InFilterClass(this._value.roundTwoCallResultEnumIn) : this._value.roundTwoCallResultEnumIn;
  }

  get roundThreeCallResultEnumIn() {
    return this._value.roundThreeCallResultEnumIn !== undefined && this._value.roundThreeCallResultEnumIn !== null ? new InFilterClass(this._value.roundThreeCallResultEnumIn) : this._value.roundThreeCallResultEnumIn;
  }

  get callerRoundOneIn() {
    return this._value.callerRoundOneIn !== undefined && this._value.callerRoundOneIn !== null ? new InFilterClass(this._value.callerRoundOneIn) : this._value.callerRoundOneIn;
  }

  get callerRoundTwoIn() {
    return this._value.callerRoundTwoIn !== undefined && this._value.callerRoundTwoIn !== null ? new InFilterClass(this._value.callerRoundTwoIn) : this._value.callerRoundTwoIn;
  }

  get callerRoundThreeIn() {
    return this._value.callerRoundThreeIn !== undefined && this._value.callerRoundThreeIn !== null ? new InFilterClass(this._value.callerRoundThreeIn) : this._value.callerRoundThreeIn;
  }
}
export class PatientResultClass extends PatientClass {
  protected readonly _value: PatientResult;

	constructor(value: PatientResult) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get enrolledProgram() {
    return this._value.enrolledProgram !== undefined && this._value.enrolledProgram !== null ? new PatientEnrolledProgramClass(this._value.enrolledProgram) : this._value.enrolledProgram;
  }

  get assignee() {
    return this._value.assignee !== undefined && this._value.assignee !== null ? new PatientAssigneesClass(this._value.assignee) : this._value.assignee;
  }

  get enrollmentRequest() {
    return this._value.enrollmentRequest !== undefined && this._value.enrollmentRequest !== null ? new EnrollmentRequestClass(this._value.enrollmentRequest) : this._value.enrollmentRequest;
  }

  get outReachCallResult() {
    return this._value.outReachCallResult !== undefined && this._value.outReachCallResult !== null ? new PatientOutReachCallClass(this._value.outReachCallResult) : this._value.outReachCallResult;
  }

  get dischargedAt() {
    return (this._value.dischargedAt !== undefined && this._value.dischargedAt !== null) ? dayjs(this._value.dischargedAt) : this._value.dischargedAt;
  }
}
export interface PatientViewer extends BaseModel {
	patientId?: Nullable<string>;
	userId?: Nullable<string>;
	limitedUser?: Nullable<LimitedUser>;
	userIdIn?: Nullable<InFilter<string>>;
}
export class PatientViewerClass extends BaseModelClass {
  protected readonly _value: PatientViewer;

	constructor(value: PatientViewer) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientId() {
    return this._value.patientId;
  }

  get userId() {
    return this._value.userId;
  }

  get limitedUser() {
    return this._value.limitedUser !== undefined && this._value.limitedUser !== null ? new LimitedUserClass(this._value.limitedUser) : this._value.limitedUser;
  }

  get userIdIn() {
    return this._value.userIdIn !== undefined && this._value.userIdIn !== null ? new InFilterClass(this._value.userIdIn) : this._value.userIdIn;
  }
}
export enum CarePlanUpdateFromEnum {
	PROFILE = 'PROFILE',
	INIT_ASSESSMENT = 'INIT_ASSESSMENT',
	FOLLOW_UP = 'FOLLOW_UP',
	ADDITIONAL = 'ADDITIONAL',
	OTHER = 'OTHER',
	INITIAL = 'INITIAL',
	POST_MD_FOLLOW_UP = 'POST_MD_FOLLOW_UP',
}
export interface CarePlanContent {
	hasHealthCondition?: Nullable<boolean>;
	hasSocialSupport?: Nullable<boolean>;
	hasDiseaseHistory?: Nullable<boolean>;
	hasMedication?: Nullable<boolean>;
	hasClinicGoal?: Nullable<boolean>;
	hasBehaviorGoal?: Nullable<boolean>;
	hasVitalMonitoring?: Nullable<boolean>;
	hasNutrition?: Nullable<boolean>;
	hasLifestyle?: Nullable<boolean>;
	hasDiagnosis?: Nullable<boolean>;
	hasMedicationList?: Nullable<boolean>;
}
export interface DiseaseHistory extends PatientBase {
	diseaseHistory?: Nullable<DiseaseHistoryItem[]>;
	visitId?: Nullable<string>;
}
export enum MedicationComplianceEnum {
	MEDICATION_REVIEW_COMPLETED = 'MEDICATION_REVIEW_COMPLETED',
	MEDICATION_REVIEW_NOT_COMPLETED = 'MEDICATION_REVIEW_NOT_COMPLETED',
	NO_MEDICATION_PRESCRIBED = 'NO_MEDICATION_PRESCRIBED',
}
export enum MedicationReminderEnum {
	PATIENT_ACCEPTED_MEDICATION_REMINDER = 'PATIENT_ACCEPTED_MEDICATION_REMINDER',
	PATIENT_DECLINED_MEDICATION_REMINDER = 'PATIENT_DECLINED_MEDICATION_REMINDER',
}
export enum MedicationReminderDeclineReasonEnum {
	FEELS_REMINDERS_ARE_UNNECESSARY = 'FEELS_REMINDERS_ARE_UNNECESSARY',
	FINDS_REMINDERS_DISTURBING_OR_INCONVENIENT = 'FINDS_REMINDERS_DISTURBING_OR_INCONVENIENT',
	PREFERS_MANUAL_TRACKING_OR_SELF_MANAGEMENT = 'PREFERS_MANUAL_TRACKING_OR_SELF_MANAGEMENT',
	ALREADY_HAS_OTHER_REMINDER_SYSTEM_IN_PLACE = 'ALREADY_HAS_OTHER_REMINDER_SYSTEM_IN_PLACE',
	PATIENT_DOES_NOT_HAVE_ANY_PRESCRIBED_MEDICATIONS_NO_NEED = 'PATIENT_DOES_NOT_HAVE_ANY_PRESCRIBED_MEDICATIONS_NO_NEED',
	NOT_APPLICABLE_CELLULAR_DEVICE = 'NOT_APPLICABLE_CELLULAR_DEVICE',
	MEDICATION_REMINDERS_NOT_DISCUSSED = 'MEDICATION_REMINDERS_NOT_DISCUSSED',
}
export interface UcMedicationManagement extends PatientBase {
	reconciliationCompliance?: Nullable<string>;
	reconciliationComplianceOther?: Nullable<string>;
	otherInfo?: Nullable<string>;
	extraNote?: Nullable<string>;
	visitId?: Nullable<string>;
	medicationCompliance?: Nullable<MedicationComplianceEnum>;
	medicationReminder?: Nullable<MedicationReminderEnum>;
	medicationReminderDeclineReason?: Nullable<MedicationReminderDeclineReasonEnum>;
	note?: Nullable<string>;
	medicationComplianceLastModifiedAt?: Nullable<string>;
	medicationReminderLastModifiedAt?: Nullable<string>;
	updatedMedicationComplianceBy?: Nullable<string>;
	updatedMedicationReminderBy?: Nullable<string>;
	isEditMedicationCompliance?: Nullable<boolean>;
	isEditMedicationReminder?: Nullable<boolean>;
	updatedMedicationComplianceByUser?: Nullable<LimitedUser>;
	updatedMedicationReminderByUser?: Nullable<LimitedUser>;
}
export interface PatientDataHistory<T> extends BaseModel {
	memberId?: Nullable<string>;
	version?: Nullable<string>;
	doc?: Nullable<T>;
	className?: Nullable<string>;
	classNameIn?: Nullable<InFilter<string>>;
}
export interface ClinicalGoal extends PatientBase {
	clinicalGoals?: Nullable<ClinicalGoalItem[]>;
	visitId?: Nullable<string>;
}
export enum BehaviorGoalTypeEnum {
	BLOOD_GLUCOSE_MANAGEMENT = 'BLOOD_GLUCOSE_MANAGEMENT',
	GLUCOSE_MANAGEMENT = 'GLUCOSE_MANAGEMENT',
	BLOOD_PRESSURE_MANAGEMENT = 'BLOOD_PRESSURE_MANAGEMENT',
	LIPID_MANAGEMENT = 'LIPID_MANAGEMENT',
	WEIGHT_REDUCTION = 'WEIGHT_REDUCTION',
	CANCER = 'CANCER',
	UNDERWEIGHT = 'UNDERWEIGHT',
	CHRONIC_KIDNEY_DISEASE = 'CHRONIC_KIDNEY_DISEASE',
	CONGESTIVE_HEART_FAILURE = 'CONGESTIVE_HEART_FAILURE',
	CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
	WELLNESS = 'WELLNESS',
	NONE = 'NONE',
}
export enum BehaviorGoalCategoryEnum {
	ALCOHOL = 'ALCOHOL',
	FAT = 'FAT',
	FLUID = 'FLUID',
	PHYSICAL_ACTIVITY = 'PHYSICAL_ACTIVITY',
	CARBOHYDRATES = 'CARBOHYDRATES',
	SODIUM = 'SODIUM',
	LIFESTYLE = 'LIFESTYLE',
	DIET = 'DIET',
	FLUIDS = 'FLUIDS',
	NON_STARCHY_VEGETABLES = 'NON_STARCHY_VEGETABLES',
	PROTEIN = 'PROTEIN',
	UNDERWEIGHT = 'UNDERWEIGHT',
	CANCER = 'CANCER',
	CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
	CHRONIC_KIDNEY_DISEASE = 'CHRONIC_KIDNEY_DISEASE',
	WELLNESS = 'WELLNESS',
	UNMAPPED = 'UNMAPPED',
}
export interface BehaviorGoalItem {
	behaviorGoalType?: Nullable<BehaviorGoalTypeEnum>;
	goalStatus?: Nullable<GoalStatusEnum>;
	behaviorGoalValue?: Nullable<string>;
	customCategory?: Nullable<string>;
	category?: Nullable<BehaviorGoalCategoryEnum>;
	updatedDate?: Nullable<string>;
}
export interface BehaviorGoal extends PatientBase {
	behaviorGoals?: Nullable<BehaviorGoalItem[]>;
	visitId?: Nullable<string>;
}
export interface CarePlan extends PatientBase {
	memberId?: Nullable<string>;
	version?: Nullable<string>;
	updateFrom?: Nullable<CarePlanUpdateFromEnum>;
	isFullVersion?: Nullable<boolean>;
	isLatest?: Nullable<boolean>;
	visitId?: Nullable<string>;
	visit?: Nullable<ClinicEvent>;
	updatedContent?: Nullable<CarePlanContent>;
	profile?: Nullable<PatientProfile>;
	medicalRecordId?: Nullable<string>;
	enrolledProgram?: Nullable<PatientEnrolledProgram>;
	assignees?: Nullable<PatientAssignees>;
	careTeamMembers?: Nullable<string[]>;
	healthCondition?: Nullable<HealthCondition>;
	diseaseHistory?: Nullable<DiseaseHistory>;
	medication?: Nullable<UcMedicationManagement>;
	medicationList?: Nullable<Medication[]>;
	lifeStyleId?: Nullable<string>;
	lifeStyle?: Nullable<PatientDataHistory<Lifestyle>>;
	nutritionId?: Nullable<string>;
	nutrition?: Nullable<PatientDataHistory<Nutrition>>;
	clinicGoalId?: Nullable<string>;
	clinicGoal?: Nullable<PatientDataHistory<ClinicalGoal>>;
	behaviorGoalId?: Nullable<string>;
	behaviorGoal?: Nullable<PatientDataHistory<BehaviorGoal>>;
}
export class CarePlanContentClass {
  protected readonly _value: CarePlanContent;

	constructor(value: CarePlanContent) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get hasHealthCondition() {
    return this._value.hasHealthCondition;
  }

  get hasHealthConditionWithDefault() {
    return this._value.hasHealthCondition || false;
  }

  get hasSocialSupport() {
    return this._value.hasSocialSupport;
  }

  get hasSocialSupportWithDefault() {
    return this._value.hasSocialSupport || false;
  }

  get hasDiseaseHistory() {
    return this._value.hasDiseaseHistory;
  }

  get hasDiseaseHistoryWithDefault() {
    return this._value.hasDiseaseHistory || false;
  }

  get hasMedication() {
    return this._value.hasMedication;
  }

  get hasMedicationWithDefault() {
    return this._value.hasMedication || false;
  }

  get hasClinicGoal() {
    return this._value.hasClinicGoal;
  }

  get hasClinicGoalWithDefault() {
    return this._value.hasClinicGoal || false;
  }

  get hasBehaviorGoal() {
    return this._value.hasBehaviorGoal;
  }

  get hasBehaviorGoalWithDefault() {
    return this._value.hasBehaviorGoal || false;
  }

  get hasVitalMonitoring() {
    return this._value.hasVitalMonitoring;
  }

  get hasVitalMonitoringWithDefault() {
    return this._value.hasVitalMonitoring || false;
  }

  get hasNutrition() {
    return this._value.hasNutrition;
  }

  get hasNutritionWithDefault() {
    return this._value.hasNutrition || false;
  }

  get hasLifestyle() {
    return this._value.hasLifestyle;
  }

  get hasLifestyleWithDefault() {
    return this._value.hasLifestyle || false;
  }

  get hasDiagnosis() {
    return this._value.hasDiagnosis;
  }

  get hasDiagnosisWithDefault() {
    return this._value.hasDiagnosis || false;
  }

  get hasMedicationList() {
    return this._value.hasMedicationList;
  }

  get hasMedicationListWithDefault() {
    return this._value.hasMedicationList || false;
  }
}
export class DiseaseHistoryClass extends PatientBaseClass {
  protected readonly _value: DiseaseHistory;

	constructor(value: DiseaseHistory) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get diseaseHistory() {
    return this._value.diseaseHistory !== undefined && this._value.diseaseHistory !== null ? map(this._value.diseaseHistory, (v) => new DiseaseHistoryItemClass(v)) : this._value.diseaseHistory;
  }

  get diseaseHistoryWithDefault() {
    return this._value.diseaseHistory || [];
  }

  get visitId() {
    return this._value.visitId;
  }
}
export class UcMedicationManagementClass extends PatientBaseClass {
  protected readonly _value: UcMedicationManagement;

	constructor(value: UcMedicationManagement) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get reconciliationCompliance() {
    return this._value.reconciliationCompliance;
  }

  get reconciliationComplianceWithDefault() {
    return this._value.reconciliationCompliance || '';
  }

  get reconciliationComplianceOther() {
    return this._value.reconciliationComplianceOther;
  }

  get reconciliationComplianceOtherWithDefault() {
    return this._value.reconciliationComplianceOther || '';
  }

  get otherInfo() {
    return this._value.otherInfo;
  }

  get otherInfoWithDefault() {
    return this._value.otherInfo || '';
  }

  get extraNote() {
    return this._value.extraNote;
  }

  get extraNoteWithDefault() {
    return this._value.extraNote || '';
  }

  get visitId() {
    return this._value.visitId;
  }

  get medicationCompliance() {
    return this._value.medicationCompliance;
  }

  medicationComplianceIsIn(values: MedicationComplianceEnum[] = []) {
    return this._value.medicationCompliance && values.includes(this._value.medicationCompliance);
  }

  get medicationReminder() {
    return this._value.medicationReminder;
  }

  medicationReminderIsIn(values: MedicationReminderEnum[] = []) {
    return this._value.medicationReminder && values.includes(this._value.medicationReminder);
  }

  get medicationReminderDeclineReason() {
    return this._value.medicationReminderDeclineReason;
  }

  medicationReminderDeclineReasonIsIn(values: MedicationReminderDeclineReasonEnum[] = []) {
    return this._value.medicationReminderDeclineReason && values.includes(this._value.medicationReminderDeclineReason);
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get medicationComplianceLastModifiedAt() {
    return (this._value.medicationComplianceLastModifiedAt !== undefined && this._value.medicationComplianceLastModifiedAt !== null) ? dayjs(this._value.medicationComplianceLastModifiedAt) : this._value.medicationComplianceLastModifiedAt;
  }

  get medicationReminderLastModifiedAt() {
    return (this._value.medicationReminderLastModifiedAt !== undefined && this._value.medicationReminderLastModifiedAt !== null) ? dayjs(this._value.medicationReminderLastModifiedAt) : this._value.medicationReminderLastModifiedAt;
  }

  get updatedMedicationComplianceBy() {
    return this._value.updatedMedicationComplianceBy;
  }

  get updatedMedicationReminderBy() {
    return this._value.updatedMedicationReminderBy;
  }

  get isEditMedicationCompliance() {
    return this._value.isEditMedicationCompliance;
  }

  get isEditMedicationComplianceWithDefault() {
    return this._value.isEditMedicationCompliance || false;
  }

  get isEditMedicationReminder() {
    return this._value.isEditMedicationReminder;
  }

  get isEditMedicationReminderWithDefault() {
    return this._value.isEditMedicationReminder || false;
  }

  get updatedMedicationComplianceByUser() {
    return this._value.updatedMedicationComplianceByUser !== undefined && this._value.updatedMedicationComplianceByUser !== null ? new LimitedUserClass(this._value.updatedMedicationComplianceByUser) : this._value.updatedMedicationComplianceByUser;
  }

  get updatedMedicationReminderByUser() {
    return this._value.updatedMedicationReminderByUser !== undefined && this._value.updatedMedicationReminderByUser !== null ? new LimitedUserClass(this._value.updatedMedicationReminderByUser) : this._value.updatedMedicationReminderByUser;
  }
}
export class PatientDataHistoryClass<T> extends BaseModelClass {
  protected readonly _value: PatientDataHistory<any>;
	protected readonly docConstructorFunc?: (value: any) => T;

	constructor(value: PatientDataHistory<any>, docConstructorFunc?: (value: any) => T) {
		super(value);
		this._value = value;
		this.docConstructorFunc = docConstructorFunc;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get version() {
    return this._value.version;
  }

  get versionWithDefault() {
    return this._value.version || '';
  }

  get doc(): T {
    return this.docConstructorFunc ? (this._value.doc && this.docConstructorFunc(this._value.doc)) : this._value.doc;
  }

  get className() {
    return this._value.className;
  }

  get classNameWithDefault() {
    return this._value.className || '';
  }

  get classNameIn() {
    return this._value.classNameIn !== undefined && this._value.classNameIn !== null ? new InFilterClass(this._value.classNameIn) : this._value.classNameIn;
  }
}
export class ClinicalGoalClass extends PatientBaseClass {
  protected readonly _value: ClinicalGoal;

	constructor(value: ClinicalGoal) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get clinicalGoals() {
    return this._value.clinicalGoals !== undefined && this._value.clinicalGoals !== null ? map(this._value.clinicalGoals, (v) => new ClinicalGoalItemClass(v)) : this._value.clinicalGoals;
  }

  get clinicalGoalsWithDefault() {
    return this._value.clinicalGoals || [];
  }

  get visitId() {
    return this._value.visitId;
  }
}
export class BehaviorGoalItemClass {
  protected readonly _value: BehaviorGoalItem;

	constructor(value: BehaviorGoalItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get behaviorGoalType() {
    return this._value.behaviorGoalType;
  }

  behaviorGoalTypeIsIn(values: BehaviorGoalTypeEnum[] = []) {
    return this._value.behaviorGoalType && values.includes(this._value.behaviorGoalType);
  }

  get goalStatus() {
    return this._value.goalStatus;
  }

  goalStatusIsIn(values: GoalStatusEnum[] = []) {
    return this._value.goalStatus && values.includes(this._value.goalStatus);
  }

  get behaviorGoalValue() {
    return this._value.behaviorGoalValue;
  }

  get behaviorGoalValueWithDefault() {
    return this._value.behaviorGoalValue || '';
  }

  get customCategory() {
    return this._value.customCategory;
  }

  get customCategoryWithDefault() {
    return this._value.customCategory || '';
  }

  get category() {
    return this._value.category;
  }

  categoryIsIn(values: BehaviorGoalCategoryEnum[] = []) {
    return this._value.category && values.includes(this._value.category);
  }

  get updatedDate() {
    return (this._value.updatedDate !== undefined && this._value.updatedDate !== null) ? dayjs(this._value.updatedDate) : this._value.updatedDate;
  }
}
export class BehaviorGoalClass extends PatientBaseClass {
  protected readonly _value: BehaviorGoal;

	constructor(value: BehaviorGoal) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get behaviorGoals() {
    return this._value.behaviorGoals !== undefined && this._value.behaviorGoals !== null ? map(this._value.behaviorGoals, (v) => new BehaviorGoalItemClass(v)) : this._value.behaviorGoals;
  }

  get behaviorGoalsWithDefault() {
    return this._value.behaviorGoals || [];
  }

  get visitId() {
    return this._value.visitId;
  }
}
export class CarePlanClass extends PatientBaseClass {
  protected readonly _value: CarePlan;

	constructor(value: CarePlan) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get version() {
    return this._value.version;
  }

  get versionWithDefault() {
    return this._value.version || '';
  }

  get updateFrom() {
    return this._value.updateFrom;
  }

  updateFromIsIn(values: CarePlanUpdateFromEnum[] = []) {
    return this._value.updateFrom && values.includes(this._value.updateFrom);
  }

  get isFullVersion() {
    return this._value.isFullVersion;
  }

  get isFullVersionWithDefault() {
    return this._value.isFullVersion || false;
  }

  get isLatest() {
    return this._value.isLatest;
  }

  get isLatestWithDefault() {
    return this._value.isLatest || false;
  }

  get visitId() {
    return this._value.visitId;
  }

  get visit() {
    return this._value.visit !== undefined && this._value.visit !== null ? new ClinicEventClass(this._value.visit) : this._value.visit;
  }

  get updatedContent() {
    return this._value.updatedContent !== undefined && this._value.updatedContent !== null ? new CarePlanContentClass(this._value.updatedContent) : this._value.updatedContent;
  }

  get profile() {
    return this._value.profile !== undefined && this._value.profile !== null ? new PatientProfileClass(this._value.profile) : this._value.profile;
  }

  get medicalRecordId() {
    return this._value.medicalRecordId;
  }

  get medicalRecordIdWithDefault() {
    return this._value.medicalRecordId || '';
  }

  get enrolledProgram() {
    return this._value.enrolledProgram !== undefined && this._value.enrolledProgram !== null ? new PatientEnrolledProgramClass(this._value.enrolledProgram) : this._value.enrolledProgram;
  }

  get assignees() {
    return this._value.assignees !== undefined && this._value.assignees !== null ? new PatientAssigneesClass(this._value.assignees) : this._value.assignees;
  }

  get careTeamMembers() {
    return this._value.careTeamMembers;
  }

  get careTeamMembersWithDefault() {
    return this._value.careTeamMembers || [];
  }

  get healthCondition() {
    return this._value.healthCondition !== undefined && this._value.healthCondition !== null ? new HealthConditionClass(this._value.healthCondition) : this._value.healthCondition;
  }

  get diseaseHistory() {
    return this._value.diseaseHistory !== undefined && this._value.diseaseHistory !== null ? new DiseaseHistoryClass(this._value.diseaseHistory) : this._value.diseaseHistory;
  }

  get medication() {
    return this._value.medication !== undefined && this._value.medication !== null ? new UcMedicationManagementClass(this._value.medication) : this._value.medication;
  }

  get medicationList() {
    return this._value.medicationList !== undefined && this._value.medicationList !== null ? map(this._value.medicationList, (v) => new MedicationClass(v)) : this._value.medicationList;
  }

  get medicationListWithDefault() {
    return this._value.medicationList || [];
  }

  get lifeStyleId() {
    return this._value.lifeStyleId;
  }

  get lifeStyleIdWithDefault() {
    return this._value.lifeStyleId || '';
  }

  get lifeStyle() {
    return this._value.lifeStyle !== undefined && this._value.lifeStyle !== null ? new PatientDataHistoryClass(this._value.lifeStyle) : this._value.lifeStyle;
  }

  get nutritionId() {
    return this._value.nutritionId;
  }

  get nutritionIdWithDefault() {
    return this._value.nutritionId || '';
  }

  get nutrition() {
    return this._value.nutrition !== undefined && this._value.nutrition !== null ? new PatientDataHistoryClass(this._value.nutrition) : this._value.nutrition;
  }

  get clinicGoalId() {
    return this._value.clinicGoalId;
  }

  get clinicGoalIdWithDefault() {
    return this._value.clinicGoalId || '';
  }

  get clinicGoal() {
    return this._value.clinicGoal !== undefined && this._value.clinicGoal !== null ? new PatientDataHistoryClass(this._value.clinicGoal) : this._value.clinicGoal;
  }

  get behaviorGoalId() {
    return this._value.behaviorGoalId;
  }

  get behaviorGoalIdWithDefault() {
    return this._value.behaviorGoalId || '';
  }

  get behaviorGoal() {
    return this._value.behaviorGoal !== undefined && this._value.behaviorGoal !== null ? new PatientDataHistoryClass(this._value.behaviorGoal) : this._value.behaviorGoal;
  }
}
export interface RecommendVitalAndProgram {
	bloodGlucose?: Nullable<boolean>;
	bloodPressure?: Nullable<boolean>;
	weight?: Nullable<boolean>;
	bloodOxygen?: Nullable<boolean>;
	isCCM?: Nullable<boolean>;
	isRPM?: Nullable<boolean>;
	isVBC?: Nullable<boolean>;
	isSaasModel?: Nullable<boolean>;
}
export class RecommendVitalAndProgramClass {
  protected readonly _value: RecommendVitalAndProgram;

	constructor(value: RecommendVitalAndProgram) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get bloodGlucose() {
    return this._value.bloodGlucose;
  }

  get bloodGlucoseWithDefault() {
    return this._value.bloodGlucose || false;
  }

  get bloodPressure() {
    return this._value.bloodPressure;
  }

  get bloodPressureWithDefault() {
    return this._value.bloodPressure || false;
  }

  get weight() {
    return this._value.weight;
  }

  get weightWithDefault() {
    return this._value.weight || false;
  }

  get bloodOxygen() {
    return this._value.bloodOxygen;
  }

  get bloodOxygenWithDefault() {
    return this._value.bloodOxygen || false;
  }

  get isCCM() {
    return this._value.isCCM;
  }

  get isCCMWithDefault() {
    return this._value.isCCM || false;
  }

  get isRPM() {
    return this._value.isRPM;
  }

  get isRPMWithDefault() {
    return this._value.isRPM || false;
  }

  get isVBC() {
    return this._value.isVBC;
  }

  get isVBCWithDefault() {
    return this._value.isVBC || false;
  }

  get isSaasModel() {
    return this._value.isSaasModel;
  }

  get isSaasModelWithDefault() {
    return this._value.isSaasModel || false;
  }
}
export interface PatientComplexity extends PatientBase {
	memberId?: Nullable<string>;
	level?: Nullable<PatientComplexityEnum>;
	manualInput?: Nullable<boolean>;
	patientInfo?: Nullable<LimitedPatient>;
	memberIdIn?: Nullable<InFilter<string>>;
}
export class PatientComplexityClass extends PatientBaseClass {
  protected readonly _value: PatientComplexity;

	constructor(value: PatientComplexity) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get level() {
    return this._value.level;
  }

  levelIsIn(values: PatientComplexityEnum[] = []) {
    return this._value.level && values.includes(this._value.level);
  }

  get manualInput() {
    return this._value.manualInput;
  }

  get manualInputWithDefault() {
    return this._value.manualInput || false;
  }

  get patientInfo() {
    return this._value.patientInfo !== undefined && this._value.patientInfo !== null ? new LimitedPatientClass(this._value.patientInfo) : this._value.patientInfo;
  }

  get memberIdIn() {
    return this._value.memberIdIn !== undefined && this._value.memberIdIn !== null ? new InFilterClass(this._value.memberIdIn) : this._value.memberIdIn;
  }
}
export enum PatientClassificationBasisEnum {
	ICD_CODE = 'ICD_CODE',
	DIAGNOSIS = 'DIAGNOSIS',
	NULL = 'NULL',
}
export interface PatientClassification extends BaseModel {
	icdCodes?: Nullable<string[]>;
	healthConditions?: Nullable<HealthConditionItem[]>;
	diagnoses?: Nullable<PatientDiagnosesEnum[]>;
	patientCategories?: Nullable<PatientCategoryEnum[]>;
	protocol?: Nullable<PatientProtocolEnum>;
	basis?: Nullable<PatientClassificationBasisEnum>;
	version?: Nullable<number>;
}
export class PatientClassificationClass extends BaseModelClass {
  protected readonly _value: PatientClassification;

	constructor(value: PatientClassification) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get icdCodes() {
    return this._value.icdCodes;
  }

  get icdCodesWithDefault() {
    return this._value.icdCodes || [];
  }

  get healthConditions() {
    return this._value.healthConditions !== undefined && this._value.healthConditions !== null ? map(this._value.healthConditions, (v) => new HealthConditionItemClass(v)) : this._value.healthConditions;
  }

  get healthConditionsWithDefault() {
    return this._value.healthConditions || [];
  }

  get diagnoses() {
    return this._value.diagnoses;
  }

  get diagnosesWithDefault() {
    return this._value.diagnoses || [];
  }

  get patientCategories() {
    return this._value.patientCategories;
  }

  get patientCategoriesWithDefault() {
    return this._value.patientCategories || [];
  }

  get protocol() {
    return this._value.protocol;
  }

  protocolIsIn(values: PatientProtocolEnum[] = []) {
    return this._value.protocol && values.includes(this._value.protocol);
  }

  get basis() {
    return this._value.basis;
  }

  basisIsIn(values: PatientClassificationBasisEnum[] = []) {
    return this._value.basis && values.includes(this._value.basis);
  }

  get version() {
    return this._value.version;
  }

  get versionWithDefault() {
    return this._value.version || 0;
  }
}
export enum PatientDataHistoryEntityEnum {
	CLINICAL_GOAL = 'CLINICAL_GOAL',
	BASELINE = 'BASELINE',
	NUTRITION = 'NUTRITION',
	LIFESTYLE = 'LIFESTYLE',
	VITALS_MONITORING_GOAL = 'VITALS_MONITORING_GOAL',
	GOAL = 'GOAL',
	BEHAVIOR_GOAL = 'BEHAVIOR_GOAL',
	DISEASE_HISTORY = 'DISEASE_HISTORY',
	HEALTH_CONDITION = 'HEALTH_CONDITION',
	COMPLEXITY = 'COMPLEXITY',
	INSURANCE = 'INSURANCE',
	label = 'label',
	className = 'className',
	BY_CLASS_NAME = 'BY_CLASS_NAME',
}
export interface VersionListItem {
	version?: Nullable<string>;
	updatedBy?: Nullable<string>;
	updatedByUser?: Nullable<LimitedUser>;
	isLatest?: Nullable<boolean>;
	entities?: Nullable<PatientDataHistoryEntityEnum[]>;
}
export class VersionListItemClass {
  protected readonly _value: VersionListItem;

	constructor(value: VersionListItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get version() {
    return this._value.version;
  }

  get versionWithDefault() {
    return this._value.version || '';
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get updatedByUser() {
    return this._value.updatedByUser !== undefined && this._value.updatedByUser !== null ? new LimitedUserClass(this._value.updatedByUser) : this._value.updatedByUser;
  }

  get isLatest() {
    return this._value.isLatest;
  }

  get isLatestWithDefault() {
    return this._value.isLatest || false;
  }

  get entities() {
    return this._value.entities;
  }

  get entitiesWithDefault() {
    return this._value.entities || [];
  }
}
export enum OrderStatusEnum {
	APPROVED = 'APPROVED',
	PICKED_UP = 'PICKED_UP',
	REJECTED = 'REJECTED',
}
export interface ShipTo {
	name?: Nullable<string>;
	fullName?: Nullable<string>;
	phone?: Nullable<Phone>;
	address?: Nullable<Address>;
}
export enum OrderItemEnum {
	BLOOD_GLUCOSE_SUPPLY = 'BLOOD_GLUCOSE_SUPPLY',
	EASE_CUFF = 'EASE_CUFF',
	BPM1_CUFF = 'BPM1_CUFF',
	BPM1_ROUTER = 'BPM1_ROUTER',
	BPM1_HOTSPOT = 'BPM1_HOTSPOT',
	REWARD_ITEM = 'REWARD_ITEM',
	IHEALTH_GLUCO = 'IHEALTH_GLUCO',
	IHEALTH_EASE = 'IHEALTH_EASE',
	IHEALTH_CLEAR = 'IHEALTH_CLEAR',
	IHEALTH_NEXUS = 'IHEALTH_NEXUS',
	IHEALTH_AIR = 'IHEALTH_AIR',
	LOAN_DEVICE = 'LOAN_DEVICE',
	CELLULAR_PYLO = 'CELLULAR_PYLO',
	CELLULAR_BIOLAND = 'CELLULAR_BIOLAND',
	A1C = 'A1C',
	OTHER = 'OTHER',
}
export interface OrderItem {
	orderName?: Nullable<OrderItemEnum>;
	cuffSize?: Nullable<CuffSizeEnum>;
	deviceId?: Nullable<string>;
	reason?: Nullable<string>;
	rewardTitle?: Nullable<string>;
}
export interface Order extends PatientBase {
	memberId?: Nullable<string>;
	orderPlanType?: Nullable<string>;
	orderNumber?: Nullable<string>;
	templateId?: Nullable<string>;
	status?: Nullable<OrderStatusEnum>;
	shipTo?: Nullable<ShipTo>;
	phone?: Nullable<Phone>;
	pickupLocation?: Nullable<string>;
	amount?: Nullable<string>;
	note?: Nullable<string>;
	organizationId?: Nullable<string>;
	pickup?: Nullable<boolean>;
	orderByName?: Nullable<string>;
	orderItems?: Nullable<OrderItem[]>;
	orderNamesIn?: Nullable<InFilter<OrderItemEnum>>;
	orderFromPortal?: Nullable<boolean>;
}
export class ShipToClass {
  protected readonly _value: ShipTo;

	constructor(value: ShipTo) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get fullName() {
    return this._value.fullName;
  }

  get fullNameWithDefault() {
    return this._value.fullName || '';
  }

  get phone() {
    return this._value.phone !== undefined && this._value.phone !== null ? new PhoneClass(this._value.phone) : this._value.phone;
  }

  get address() {
    return this._value.address !== undefined && this._value.address !== null ? new AddressClass(this._value.address) : this._value.address;
  }
}
export class OrderItemClass {
  protected readonly _value: OrderItem;

	constructor(value: OrderItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get orderName() {
    return this._value.orderName;
  }

  orderNameIsIn(values: OrderItemEnum[] = []) {
    return this._value.orderName && values.includes(this._value.orderName);
  }

  get cuffSize() {
    return this._value.cuffSize;
  }

  cuffSizeIsIn(values: CuffSizeEnum[] = []) {
    return this._value.cuffSize && values.includes(this._value.cuffSize);
  }

  get deviceId() {
    return this._value.deviceId;
  }

  get deviceIdWithDefault() {
    return this._value.deviceId || '';
  }

  get reason() {
    return this._value.reason;
  }

  get reasonWithDefault() {
    return this._value.reason || '';
  }

  get rewardTitle() {
    return this._value.rewardTitle;
  }

  get rewardTitleWithDefault() {
    return this._value.rewardTitle || '';
  }
}
export class OrderClass extends PatientBaseClass {
  protected readonly _value: Order;

	constructor(value: Order) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get orderPlanType() {
    return this._value.orderPlanType;
  }

  get orderPlanTypeWithDefault() {
    return this._value.orderPlanType || '';
  }

  get orderNumber() {
    return this._value.orderNumber;
  }

  get orderNumberWithDefault() {
    return this._value.orderNumber || '';
  }

  get templateId() {
    return this._value.templateId;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: OrderStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get shipTo() {
    return this._value.shipTo !== undefined && this._value.shipTo !== null ? new ShipToClass(this._value.shipTo) : this._value.shipTo;
  }

  get phone() {
    return this._value.phone !== undefined && this._value.phone !== null ? new PhoneClass(this._value.phone) : this._value.phone;
  }

  get pickupLocation() {
    return this._value.pickupLocation;
  }

  get pickupLocationWithDefault() {
    return this._value.pickupLocation || '';
  }

  get amount() {
    return this._value.amount;
  }

  get amountWithDefault() {
    return this._value.amount || '';
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get pickup() {
    return this._value.pickup;
  }

  get pickupWithDefault() {
    return this._value.pickup || false;
  }

  get orderByName() {
    return this._value.orderByName;
  }

  get orderByNameWithDefault() {
    return this._value.orderByName || '';
  }

  get orderItems() {
    return this._value.orderItems !== undefined && this._value.orderItems !== null ? map(this._value.orderItems, (v) => new OrderItemClass(v)) : this._value.orderItems;
  }

  get orderItemsWithDefault() {
    return this._value.orderItems || [];
  }

  get orderNamesIn() {
    return this._value.orderNamesIn !== undefined && this._value.orderNamesIn !== null ? new InFilterClass(this._value.orderNamesIn) : this._value.orderNamesIn;
  }

  get orderFromPortal() {
    return this._value.orderFromPortal;
  }

  get orderFromPortalWithDefault() {
    return this._value.orderFromPortal || false;
  }
}
export interface OrderStaging extends PatientBase {
	memberId?: Nullable<string>;
	orderPlanType?: Nullable<string>;
	note?: Nullable<string>;
	pickup?: Nullable<boolean>;
	orderItems?: Nullable<OrderItem>;
	orderByName?: Nullable<string>;
}
export class OrderStagingClass extends PatientBaseClass {
  protected readonly _value: OrderStaging;

	constructor(value: OrderStaging) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get orderPlanType() {
    return this._value.orderPlanType;
  }

  get orderPlanTypeWithDefault() {
    return this._value.orderPlanType || '';
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get pickup() {
    return this._value.pickup;
  }

  get pickupWithDefault() {
    return this._value.pickup || false;
  }

  get orderItems() {
    return this._value.orderItems !== undefined && this._value.orderItems !== null ? new OrderItemClass(this._value.orderItems) : this._value.orderItems;
  }

  get orderByName() {
    return this._value.orderByName;
  }

  get orderByNameWithDefault() {
    return this._value.orderByName || '';
  }
}
export interface UnsetPatientAssigneeRequest {
	ids?: Nullable<string[]>;
	deleteAssignRD?: Nullable<boolean>;
	deleteAssignCA?: Nullable<boolean>;
	deleteDoctor?: Nullable<boolean>;
}
export interface UpdatePasswordInput {
	newPass?: Nullable<string>;
}
export interface PatientSearchFilter {
	patient?: Nullable<Patient>;
	needToFilter?: Nullable<boolean>;
	medicalOrgIds?: Nullable<string[]>;
}
export interface PatientProfileSearchModel {
	firstName?: Nullable<string>;
	lastName?: Nullable<string>;
	nickName?: Nullable<string>;
	birthday?: Nullable<string>;
	gender?: Nullable<GenderEnum>;
	doctorId?: Nullable<string>;
	doctorUser?: Nullable<LimitedUser>;
}
export interface LimitedPatientListInfoSearchModel {
	enrolledStatus?: Nullable<EnrolledProgramStatusEnum>;
	programs?: Nullable<Program[]>;
	enrollmentDate?: Nullable<string>;
	dischargedAt?: Nullable<string>;
}
export interface PatientSearchModel extends BaseModel {
	profile?: Nullable<PatientProfileSearchModel>;
	phone?: Nullable<Phone[]>;
	medicalRecordId?: Nullable<string>;
	clinicId?: Nullable<string>;
	patientListInfo?: Nullable<LimitedPatientListInfoSearchModel>;
	clinic?: Nullable<LimitedClinic>;
	associatedList?: Nullable<AssociatedInfo[]>;
}
export interface PatientSearchResult extends PatientSearchModel {
	enrolledProgram?: Nullable<PatientEnrolledProgram>;
	dischargedAt?: Nullable<string>;
}
export class PatientProfileSearchModelClass {
  protected readonly _value: PatientProfileSearchModel;

	constructor(value: PatientProfileSearchModel) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get firstName() {
    return this._value.firstName;
  }

  get firstNameWithDefault() {
    return this._value.firstName || '';
  }

  get lastName() {
    return this._value.lastName;
  }

  get lastNameWithDefault() {
    return this._value.lastName || '';
  }

  get nickName() {
    return this._value.nickName;
  }

  get nickNameWithDefault() {
    return this._value.nickName || '';
  }

  get birthday() {
    return this._value.birthday;
  }

  get birthdayWithDefault() {
    return this._value.birthday || '';
  }

  get gender() {
    return this._value.gender;
  }

  genderIsIn(values: GenderEnum[] = []) {
    return this._value.gender && values.includes(this._value.gender);
  }

  get doctorId() {
    return this._value.doctorId;
  }

  get doctorUser() {
    return this._value.doctorUser !== undefined && this._value.doctorUser !== null ? new LimitedUserClass(this._value.doctorUser) : this._value.doctorUser;
  }
}
export class LimitedPatientListInfoSearchModelClass {
  protected readonly _value: LimitedPatientListInfoSearchModel;

	constructor(value: LimitedPatientListInfoSearchModel) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get enrolledStatus() {
    return this._value.enrolledStatus;
  }

  enrolledStatusIsIn(values: EnrolledProgramStatusEnum[] = []) {
    return this._value.enrolledStatus && values.includes(this._value.enrolledStatus);
  }

  get programs() {
    return this._value.programs !== undefined && this._value.programs !== null ? map(this._value.programs, (v) => new ProgramClass(v)) : this._value.programs;
  }

  get programsWithDefault() {
    return this._value.programs || [];
  }

  get enrollmentDate() {
    return (this._value.enrollmentDate !== undefined && this._value.enrollmentDate !== null) ? dayjs(this._value.enrollmentDate) : this._value.enrollmentDate;
  }

  get dischargedAt() {
    return (this._value.dischargedAt !== undefined && this._value.dischargedAt !== null) ? dayjs(this._value.dischargedAt) : this._value.dischargedAt;
  }
}
export class PatientSearchModelClass extends BaseModelClass {
  protected readonly _value: PatientSearchModel;

	constructor(value: PatientSearchModel) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get profile() {
    return this._value.profile !== undefined && this._value.profile !== null ? new PatientProfileSearchModelClass(this._value.profile) : this._value.profile;
  }

  get phone() {
    return this._value.phone !== undefined && this._value.phone !== null ? map(this._value.phone, (v) => new PhoneClass(v)) : this._value.phone;
  }

  get phoneWithDefault() {
    return this._value.phone || [];
  }

  get medicalRecordId() {
    return this._value.medicalRecordId;
  }

  get medicalRecordIdWithDefault() {
    return this._value.medicalRecordId || '';
  }

  get clinicId() {
    return this._value.clinicId;
  }

  get patientListInfo() {
    return this._value.patientListInfo !== undefined && this._value.patientListInfo !== null ? new LimitedPatientListInfoSearchModelClass(this._value.patientListInfo) : this._value.patientListInfo;
  }

  get clinic() {
    return this._value.clinic !== undefined && this._value.clinic !== null ? new LimitedClinicClass(this._value.clinic) : this._value.clinic;
  }

  get associatedList() {
    return this._value.associatedList !== undefined && this._value.associatedList !== null ? map(this._value.associatedList, (v) => new AssociatedInfoClass(v)) : this._value.associatedList;
  }

  get associatedListWithDefault() {
    return this._value.associatedList || [];
  }
}
export class PatientSearchResultClass extends PatientSearchModelClass {
  protected readonly _value: PatientSearchResult;

	constructor(value: PatientSearchResult) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get enrolledProgram() {
    return this._value.enrolledProgram !== undefined && this._value.enrolledProgram !== null ? new PatientEnrolledProgramClass(this._value.enrolledProgram) : this._value.enrolledProgram;
  }

  get dischargedAt() {
    return (this._value.dischargedAt !== undefined && this._value.dischargedAt !== null) ? dayjs(this._value.dischargedAt) : this._value.dischargedAt;
  }
}
export enum AlertLevelEnum {
	LEVEL_ONE = 'LEVEL_ONE',
	LEVEL_TWO = 'LEVEL_TWO',
	LEVEL_THREE = 'LEVEL_THREE',
	LEVEL_FOUR = 'LEVEL_FOUR',
	LEVEL_FIVE = 'LEVEL_FIVE',
}
export enum TaskTypeEnum {
	BG_PROVIDER_CRITICAL_HIGH = 'BG_PROVIDER_CRITICAL_HIGH',
	BG_PROVIDER_2_DAYS_HIGH = 'BG_PROVIDER_2_DAYS_HIGH',
	BG_PROVIDER_FREQUENT_LOW_GLUCOSE = 'BG_PROVIDER_FREQUENT_LOW_GLUCOSE',
	BG_PROVIDER_GLUCOSE_HIGH_30_DAY_AVERAGE = 'BG_PROVIDER_GLUCOSE_HIGH_30_DAY_AVERAGE',
	BG_PROVIDER_GLUCOSE_ELEVATED_60_DAY_AVERAGE = 'BG_PROVIDER_GLUCOSE_ELEVATED_60_DAY_AVERAGE',
	BG_PROVIDER_FASTING_GLUCOSE_HIGH = 'BG_PROVIDER_FASTING_GLUCOSE_HIGH',
	BP_UNCONTROLLED = 'BP_UNCONTROLLED',
	LEVEL_ONE_BG_CRITICAL_LOW = 'LEVEL_ONE_BG_CRITICAL_LOW',
	LEVEL_ONE_BG_CRITICAL_HIGH = 'LEVEL_ONE_BG_CRITICAL_HIGH',
	LEVEL_ONE_BG_CONTINUE_HIGH = 'LEVEL_ONE_BG_CONTINUE_HIGH',
	LEVEL_ONE_BP_CRITICAL_LOW = 'LEVEL_ONE_BP_CRITICAL_LOW',
	LEVEL_ONE_BP_CRITICAL_HIGH = 'LEVEL_ONE_BP_CRITICAL_HIGH',
	LEVEL_ONE_BP_MANUAL_TRIAGE_CRITICAL_HIGH = 'LEVEL_ONE_BP_MANUAL_TRIAGE_CRITICAL_HIGH',
	LEVEL_ONE_BP_HTN_CRITICAL_HIGH = 'LEVEL_ONE_BP_HTN_CRITICAL_HIGH',
	LEVEL_ONE_BP_HTN_CRITICAL_LOW = 'LEVEL_ONE_BP_HTN_CRITICAL_LOW',
	LEVEL_ONE_SPO2_CRITICAL_LOW = 'LEVEL_ONE_SPO2_CRITICAL_LOW',
	LEVEL_ONE_SPO2_HYPOXIA_CRITICAL_LOW = 'LEVEL_ONE_SPO2_HYPOXIA_CRITICAL_LOW',
	LEVEL_ONE_VISIT_DUE = 'LEVEL_ONE_VISIT_DUE',
	LEVEL_TWO_BG_FASTING_HIGH = 'LEVEL_TWO_BG_FASTING_HIGH',
	LEVEL_TWO_SPO2_LOW = 'LEVEL_TWO_SPO2_LOW',
	LEVEL_TWO_A1C_DUE = 'LEVEL_TWO_A1C_DUE',
	LEVEL_TWO_BP_HTN_CRITICAL_HIGH = 'LEVEL_TWO_BP_HTN_CRITICAL_HIGH',
	LEVEL_TWO_BP_HTN_CRITICAL_LOW = 'LEVEL_TWO_BP_HTN_CRITICAL_LOW',
	LEVEL_TWO_BG_PAIR_READING = 'LEVEL_TWO_BG_PAIR_READING',
	LEVEL_TWO_BG_CRITICAL_HIGH_BEFORE_MEAL = 'LEVEL_TWO_BG_CRITICAL_HIGH_BEFORE_MEAL',
	LEVEL_TWO_BG_CRITICAL_HIGH_AFTER_MEAL = 'LEVEL_TWO_BG_CRITICAL_HIGH_AFTER_MEAL',
	LEVEL_TWO_SPO2_HYPOXIA_LOW = 'LEVEL_TWO_SPO2_HYPOXIA_LOW',
	LEVEL_TWO_INITIAL_VISIT_NO_SHOW = 'LEVEL_TWO_INITIAL_VISIT_NO_SHOW',
	LEVEL_TWO_INITIAL_VISIT_NO_SCHEDULE = 'LEVEL_TWO_INITIAL_VISIT_NO_SCHEDULE',
	LEVEL_THREE_BP_HTN_STAGE_2_HIGH = 'LEVEL_THREE_BP_HTN_STAGE_2_HIGH',
	LEVEL_THREE_BP_HTN_STAGE_2_AVERAGE = 'LEVEL_THREE_BP_HTN_STAGE_2_AVERAGE',
	LEVEL_FOUR_BP_HTN_STAGE_2_AVERAGE = 'LEVEL_FOUR_BP_HTN_STAGE_2_AVERAGE',
	LEVEL_THREE_1_DAY_AFTER_ENROLLMENT = 'LEVEL_THREE_1_DAY_AFTER_ENROLLMENT',
	LEVEL_THREE_VISIT_OVERDUE = 'LEVEL_THREE_VISIT_OVERDUE',
	LEVEL_THREE_BG_PAIR_READING = 'LEVEL_THREE_BG_PAIR_READING',
	LEVEL_THREE_BIRTHDAY = 'LEVEL_THREE_BIRTHDAY',
	LEVEL_THREE_PATIENT_ENGAGEMENT = 'LEVEL_THREE_PATIENT_ENGAGEMENT',
	LEVEL_THREE_14_DAY_ENROLLMENT_ENGAGEMENT = 'LEVEL_THREE_14_DAY_ENROLLMENT_ENGAGEMENT',
	LEVEL_THREE_PATIENT_APP_ENGAGEMENT = 'LEVEL_THREE_PATIENT_APP_ENGAGEMENT',
	LEVEL_THREE_PATIENT_NO_APP_ENGAGEMENT = 'LEVEL_THREE_PATIENT_NO_APP_ENGAGEMENT',
	LEVEL_THREE_INACTIVE_PATIENT = 'LEVEL_THREE_INACTIVE_PATIENT',
	LEVEL_THREE_INACTIVE_PATIENT_HIGH_RISK = 'LEVEL_THREE_INACTIVE_PATIENT_HIGH_RISK',
	LEVEL_THREE_HIGH_FASTING_BG = 'LEVEL_THREE_HIGH_FASTING_BG',
	LEVEL_THREE_HIGH_AFTER_MEAL_BG = 'LEVEL_THREE_HIGH_AFTER_MEAL_BG',
	LEVEL_THREE_8_DAYS_LOW_ACTIVITY = 'LEVEL_THREE_8_DAYS_LOW_ACTIVITY',
	LEVEL_THREE_20_DAYS_LOW_ACTIVITY = 'LEVEL_THREE_20_DAYS_LOW_ACTIVITY',
	LEVEL_FOUR_HIGH_FASTING_BG = 'LEVEL_FOUR_HIGH_FASTING_BG',
	LEVEL_FOUR_HIGH_AFTER_MEAL_BG = 'LEVEL_FOUR_HIGH_AFTER_MEAL_BG',
	LEVEL_FOUR_A1C_OVERDUE = 'LEVEL_FOUR_A1C_OVERDUE',
	LEVEL_FOUR_HIGH_BG = 'LEVEL_FOUR_HIGH_BG',
	LEVEL_FOUR_HIGH_AVERAGE_BG = 'LEVEL_FOUR_HIGH_AVERAGE_BG',
	LEVEL_FOUR_BG_TRENDING_HIGH = 'LEVEL_FOUR_BG_TRENDING_HIGH',
	LEVEL_FOUR_BG_TRENDING_DOWN = 'LEVEL_FOUR_BG_TRENDING_DOWN',
	LEVEL_FOUR_7_DAY_HYPERTENSION_STAGE_II = 'LEVEL_FOUR_7_DAY_HYPERTENSION_STAGE_II',
	LEVEL_FOUR_14_DAY_HYPERTENSION_STAGE_II = 'LEVEL_FOUR_14_DAY_HYPERTENSION_STAGE_II',
	LEVEL_FOUR_BP_TRENDING_HIGH = 'LEVEL_FOUR_BP_TRENDING_HIGH',
	LEVEL_FOUR_BP_TRENDING_DOWN = 'LEVEL_FOUR_BP_TRENDING_DOWN',
	LEVEL_FIVE_BG_MISSING_PAIR = 'LEVEL_FIVE_BG_MISSING_PAIR',
	LEVEL_FOUR_NO_PROVIDERNOTE_IN_FOUR_WEEKS = 'LEVEL_FOUR_NO_PROVIDERNOTE_IN_FOUR_WEEKS',
	LEVEL_FIVE_NO_PROVIDERNOTE_IN_SIX_WEEKS = 'LEVEL_FIVE_NO_PROVIDERNOTE_IN_SIX_WEEKS',
	LEVEL_FIVE_HUB_USER_MISSING_CALL_LOG = 'LEVEL_FIVE_HUB_USER_MISSING_CALL_LOG',
}
export interface SmartAlertConfig {
	alertLevel?: Nullable<AlertLevelEnum>;
	taskType?: Nullable<TaskTypeEnum>;
	smartAlertType?: Nullable<ComplianceAlertTypeEnum>;
	priorityLevel?: Nullable<PriorityLevelEnum>;
	snoozeInterval?: Nullable<string>;
	startDate?: Nullable<string>;
	endDate?: Nullable<string>;
	alertDisplay?: Nullable<string>;
	isValid?: Nullable<boolean>;
}
export interface SmartAlertTasksConfig extends BaseModel {
	memberId?: Nullable<string>;
	configs?: Nullable<SmartAlertConfig[]>;
}
export class SmartAlertConfigClass {
  protected readonly _value: SmartAlertConfig;

	constructor(value: SmartAlertConfig) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get alertLevel() {
    return this._value.alertLevel;
  }

  alertLevelIsIn(values: AlertLevelEnum[] = []) {
    return this._value.alertLevel && values.includes(this._value.alertLevel);
  }

  get taskType() {
    return this._value.taskType;
  }

  taskTypeIsIn(values: TaskTypeEnum[] = []) {
    return this._value.taskType && values.includes(this._value.taskType);
  }

  get smartAlertType() {
    return this._value.smartAlertType;
  }

  smartAlertTypeIsIn(values: ComplianceAlertTypeEnum[] = []) {
    return this._value.smartAlertType && values.includes(this._value.smartAlertType);
  }

  get priorityLevel() {
    return this._value.priorityLevel;
  }

  priorityLevelIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.priorityLevel && values.includes(this._value.priorityLevel);
  }

  get snoozeInterval() {
    return this._value.snoozeInterval;
  }

  get snoozeIntervalWithDefault() {
    return this._value.snoozeInterval || '';
  }

  get startDate() {
    return (this._value.startDate !== undefined && this._value.startDate !== null) ? dayjs(this._value.startDate) : this._value.startDate;
  }

  get endDate() {
    return (this._value.endDate !== undefined && this._value.endDate !== null) ? dayjs(this._value.endDate) : this._value.endDate;
  }

  get alertDisplay() {
    return this._value.alertDisplay;
  }

  get alertDisplayWithDefault() {
    return this._value.alertDisplay || '';
  }

  get isValid() {
    return this._value.isValid;
  }

  get isValidWithDefault() {
    return this._value.isValid || false;
  }
}
export class SmartAlertTasksConfigClass extends BaseModelClass {
  protected readonly _value: SmartAlertTasksConfig;

	constructor(value: SmartAlertTasksConfig) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get configs() {
    return this._value.configs !== undefined && this._value.configs !== null ? map(this._value.configs, (v) => new SmartAlertConfigClass(v)) : this._value.configs;
  }

  get configsWithDefault() {
    return this._value.configs || [];
  }
}
export interface PatientDeleteRequest {
	patientId?: Nullable<string>;
	deleteReason?: Nullable<string>;
}
export interface PatientBaseline extends PatientBase {
	bloodOxygen?: Nullable<number>;
}
export class PatientBaselineClass extends PatientBaseClass {
  protected readonly _value: PatientBaseline;

	constructor(value: PatientBaseline) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get bloodOxygen() {
    return this._value.bloodOxygen;
  }

  get bloodOxygenWithDefault() {
    return this._value.bloodOxygen || 0;
  }
}
export interface MedicationListSearchRequest {
	prescribedMedication: Medication;
	discontinuedMedication: Medication;
}
export interface MedicationListSearchResponse {
	medicationManagement?: Nullable<UcMedicationManagement>;
	prescribedMedications?: Nullable<Medication[]>;
	discontinuedMedications?: Nullable<Medication[]>;
	updatedBy?: Nullable<string>;
	lastUpdatedAt?: Nullable<string>;
	updatedByUser?: Nullable<LimitedUser>;
}
export class MedicationListSearchResponseClass {
  protected readonly _value: MedicationListSearchResponse;

	constructor(value: MedicationListSearchResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get medicationManagement() {
    return this._value.medicationManagement !== undefined && this._value.medicationManagement !== null ? new UcMedicationManagementClass(this._value.medicationManagement) : this._value.medicationManagement;
  }

  get prescribedMedications() {
    return this._value.prescribedMedications !== undefined && this._value.prescribedMedications !== null ? map(this._value.prescribedMedications, (v) => new MedicationClass(v)) : this._value.prescribedMedications;
  }

  get prescribedMedicationsWithDefault() {
    return this._value.prescribedMedications || [];
  }

  get discontinuedMedications() {
    return this._value.discontinuedMedications !== undefined && this._value.discontinuedMedications !== null ? map(this._value.discontinuedMedications, (v) => new MedicationClass(v)) : this._value.discontinuedMedications;
  }

  get discontinuedMedicationsWithDefault() {
    return this._value.discontinuedMedications || [];
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get lastUpdatedAt() {
    return (this._value.lastUpdatedAt !== undefined && this._value.lastUpdatedAt !== null) ? dayjs(this._value.lastUpdatedAt) : this._value.lastUpdatedAt;
  }

  get updatedByUser() {
    return this._value.updatedByUser !== undefined && this._value.updatedByUser !== null ? new LimitedUserClass(this._value.updatedByUser) : this._value.updatedByUser;
  }
}
export interface PatientReward extends BaseModel {
	memberId?: Nullable<string>;
	points?: Nullable<number>;
	source?: Nullable<string>;
}
export class PatientRewardClass extends BaseModelClass {
  protected readonly _value: PatientReward;

	constructor(value: PatientReward) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get points() {
    return this._value.points;
  }

  get pointsWithDefault() {
    return this._value.points || 0;
  }

  get source() {
    return this._value.source;
  }

  get sourceWithDefault() {
    return this._value.source || '';
  }
}
export interface Tag extends BaseModel {
	name: string;
	patientIds?: Nullable<string[]>;
}
export class TagClass extends BaseModelClass {
  protected readonly _value: Tag;

	constructor(value: Tag) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get patientIds() {
    return this._value.patientIds;
  }

  get patientIdsWithDefault() {
    return this._value.patientIds || [];
  }
}
export interface ReassignRequest {
	fromVisitParticipant?: Nullable<VisitParticipant>;
	toVisitParticipant?: Nullable<VisitParticipant>;
}
export interface PatientClassificationRequest {
	icdCodes?: Nullable<string[]>;
	diagnoses?: Nullable<PatientDiagnosesEnum[]>;
}
export interface IncompleteInsuranceRequest {
	clinicIds?: Nullable<string[]>;
	memberIds?: Nullable<string[]>;
	billingEligibility?: Nullable<boolean>;
	assigneeIds?: Nullable<string[]>;
	isTestPatient?: Nullable<boolean>;
	missingStatusList?: Nullable<IncompleteStatusEnum[]>;
	billingEligibilityIn?: Nullable<InFilter<boolean>>;
}
export interface IncompleteStatusPatient {
	memberId?: Nullable<string>;
	memberName?: Nullable<string>;
	birthday?: Nullable<string>;
	enrollmentDate?: Nullable<string>;
	billingEligibility?: Nullable<boolean>;
	assignedToCA?: Nullable<string>;
	incompleteStatusList?: Nullable<IncompleteStatusEnum[]>;
	clinic?: Nullable<MedicalOrganizationProfile>;
}
export class IncompleteStatusPatientClass {
  protected readonly _value: IncompleteStatusPatient;

	constructor(value: IncompleteStatusPatient) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get memberIdWithDefault() {
    return this._value.memberId || '';
  }

  get memberName() {
    return this._value.memberName;
  }

  get memberNameWithDefault() {
    return this._value.memberName || '';
  }

  get birthday() {
    return this._value.birthday;
  }

  get birthdayWithDefault() {
    return this._value.birthday || '';
  }

  get enrollmentDate() {
    return (this._value.enrollmentDate !== undefined && this._value.enrollmentDate !== null) ? dayjs(this._value.enrollmentDate) : this._value.enrollmentDate;
  }

  get billingEligibility() {
    return this._value.billingEligibility;
  }

  get billingEligibilityWithDefault() {
    return this._value.billingEligibility || false;
  }

  get assignedToCA() {
    return this._value.assignedToCA;
  }

  get incompleteStatusList() {
    return this._value.incompleteStatusList;
  }

  get incompleteStatusListWithDefault() {
    return this._value.incompleteStatusList || [];
  }

  get clinic() {
    return this._value.clinic !== undefined && this._value.clinic !== null ? new MedicalOrganizationProfileClass(this._value.clinic) : this._value.clinic;
  }
}
export interface PatientGetParams {
	memberId: string;
}

export interface PatientUpdateParams {
	memberId: string;
	request: Patient;
}

export interface PatientCountParams {
	patientFilter: PatientFilter;
}

export interface PatientGetMtprEligibilityParams {
	memberId: string;
}

export interface PatientGetOutstandingItemCountParams {
	memberId: string;
}

export interface PatientGetPatientAssigneesParams {
	memberId: string;
}

export interface PatientListFiltersParams {
}

export interface PatientListPatientsParams {
	filter: { [key: string]: string[] };
}

export interface PatientGetPatientDeviceStatusParams {
	memberId: string;
}

export interface PatientUpdatePatientDeviceStatusParams {
	memberId: string;
	updateRequest: PatientDeviceStatus;
}

export interface PatientGetAvailableDevicesParams {
	searchRequest: SearchRequest<IotDeviceConfig>;
}

export interface PatientGetHealthConditionParams {
	memberId: string;
}

export interface PatientGetLifestyleByIdParams {
	memberId: string;
	version?: string;
}

export interface PatientUpdateLifestyleParams {
	memberId: string;
	request: Lifestyle;
}

export interface PatientGetNutritionByIdParams {
	memberId: string;
	version?: string;
}

export interface PatientUpdateNutritionParams {
	memberId: string;
	request: Nutrition;
}

export interface PatientSendSMSParams {
	memberId: string;
	request: SmsRequest;
}

export interface PatientUpsertPatientAssigneesParams {
	patientAssigneesRequest: PatientAssigneesRequest;
}

export interface PatientPatientListParams {
	searchRequest: SearchRequest<PatientFilter>;
}

export interface PatientGetPatientCgmDeviceParams {
	memberId: string;
}

export interface PatientRefreshOutstandingItemParams {
	memberId: string;
}

export interface PatientUpdateCgmGoalParams {
	memberId: string;
	request: CgmGoal;
}

export interface PatientGetPatientDeviceParams {
	memberId: string;
	vitalType?: VitalEnumType;
}

export interface PatientUpdatePatientDeviceParams {
	memberId: string;
	updateRequest: PatientDevice;
}

export interface PatientUpdateLoanDeviceParams {
	memberId: string;
	updateRequest: LoanDevice[];
}

export interface PatientDeletePatientDeviceParams {
	deviceId: string;
	memberId: string;
}

export interface PatientTrackPatientViewerParams {
	memberId: string;
}

export interface PatientSearchPatientViewerParams {
	memberId: string;
}

export interface PatientSearchCarePlanParams {
	request: SearchRequest<CarePlan>;
}

export interface PatientGetConsentFormHistoryParams {
	memberId: string;
	consentStatus?: ConsentStatusEnum[];
}

export interface PatientGetRecommendVitalAndProgramParams {
	diagnoses: PatientDiagnosesEnum[];
}

export interface PatientUpdateHealthConditionParams {
	memberId: string;
	request: HealthCondition;
}

export interface PatientCheckCodeIsBillableParams {
	memberId: string;
	icdCode: string;
}

export interface PatientUpdateClinicalGoalParams {
	memberId: string;
	request: ClinicalGoal;
}

export interface PatientUpdateBehaviorGoalParams {
	memberId: string;
	request: BehaviorGoal;
}

export interface PatientGetPatientComplexityByIdParams {
	memberId: string;
}

export interface PatientGetPatientClassificationByIdParams {
	memberId: string;
}

export interface PatientGetPatientComplexityByIcdCodesParams {
	conditions: HealthConditionItem[];
}

export interface PatientSearchMonthlyReviewParams {
	searchRequest: SearchRequest<MonthlyReview>;
}

export interface PatientUpdateMonthlyReviewParams {
	memberId: string;
	update: MonthlyReview;
}

export interface PatientGetVersionListParams {
	memberId: string;
	entities?: string[];
}

export interface PatientGetUserOrdersParams {
	memberId: string;
}

export interface PatientSearchOrdersParams {
	request: SearchRequest<Order>;
}

export interface PatientCreateUserOrderParams {
	memberId: string;
	order: Order;
}

export interface PatientGetOrderOptionParams {
	memberId: string;
}

export interface PatientUnsetAssigneesParams {
	request: UnsetPatientAssigneeRequest;
}

export interface PatientUpdatePasswordParams {
	memberId: string;
	spInput: UpdatePasswordInput;
}

export interface PatientPatientSearchListParams {
	searchRequest: SearchRequest<PatientSearchFilter>;
}

export interface PatientUpsertPatientEnrolledProgramParams {
	memberId: string;
	enrolledProgram: PatientEnrolledProgram;
}

export interface PatientUpdateUnableToEngageParams {
	memberId: string;
	request: UnableToEngage;
}

export interface PatientSetPatientAlertTasksConfigParams {
	configRequest: SmartAlertTasksConfig;
}

export interface PatientGetPatientAlertConfigParams {
	memberId: string;
}

export interface PatientUpsertPatientOutReachCallParams {
	memberId: string;
	request: PatientOutReachCall;
}

export interface PatientDeletePatientParams {
	request: PatientDeleteRequest;
}

export interface PatientUpdatePatientCgmDeviceParams {
	memberId: string;
	updateRequest: PatientCgmDevice;
}

export interface PatientGetPatientInsuranceParams {
	memberId: string;
}

export interface PatientUpdatePatientInsuranceParams {
	memberId: string;
	request: PatientInsurance;
}

export interface PatientGetPatientBaselineParams {
	memberId: string;
}

export interface PatientUpdatePatientBaselineParams {
	memberId: string;
	request: PatientBaseline;
}

export interface PatientGetConsentFormParams {
	memberId: string;
}

export interface PatientPreviewConsentFormParams {
	memberId: string;
	consentType?: SignatureCollectionMethodEnum;
}

export interface PatientUpdateConsentFormParams {
	memberId: string;
	request: ConsentRequest;
}

export interface PatientGetEnrolledProgramsParams {
	memberId: string;
}

export interface PatientGetPatientExerciseGoalParams {
	memberId: string;
}

export interface PatientUpsertDiseaseHistoryParams {
	memberId: string;
	request: DiseaseHistory;
}

export interface PatientGetDiseaseHistoryParams {
	memberId: string;
}

export interface PatientGetClinicalGoalByIdParams {
	memberId: string;
	version?: string;
}

export interface PatientGetCgmGoalByIdParams {
	memberId: string;
	version?: string;
}

export interface PatientGetBehaviorGoalByIdParams {
	memberId: string;
	version?: string;
}

export interface PatientGetEnrollmentParams {
	memberId: string;
}

export interface PatientUpdateEnrollmentParams {
	memberId: string;
	request: EnrollmentRequest;
}

export interface PatientInsertPatientComplexityParams {
	patientComplexity: PatientComplexity;
}

export interface PatientSearchPatientComplexityParams {
	searchRequest: SearchRequest<PatientComplexity>;
}

export interface PatientGetPatientControlLevelByIdParams {
	memberId: string;
}

export interface PatientUpdatePatientA1cGoalParams {
	memberId: string;
	goal: BgGoal;
}

export interface PatientUpdatePatientComplexityParams {
	memberId: string;
	request: PatientComplexity;
}

export interface PatientInsertUcMedicationManagementParams {
	ucMedicationManagement: UcMedicationManagement;
}

export interface PatientUpdateUcMedicationManagementParams {
	memberId: string;
	request: UcMedicationManagement;
}

export interface PatientGetUcMedicationManagementParams {
	memberId: string;
}

export interface PatientSearchUcMedicationManagementParams {
	searchRequest: SearchRequest<MedicationListSearchRequest>;
}

export interface PatientGetMonthlyReviewByMemberIdParams {
	memberId: string;
}

export interface PatientUpsertPatientExerciseGoalParams {
	memberId: string;
	exerciseVital: MonitoringVital;
}

export interface PatientUpsertPatientHiatusStatusParams {
	memberId: string;
	request: PatientHiatusStatus;
}

export interface PatientDischargeEnrolledProgramParams {
	memberId: string;
	dischargedDetail: DischargedDetail;
}

export interface PatientGetPatientRewardParams {
	memberId: string;
}

export interface PatientCheckPhoneNumberExistParams {
	phone: Phone;
}

export interface PatientTagListParams {
	request: Tag;
}

export interface PatientAssignPatientsParams {
	request: Tag;
}

export interface PatientPatientLimitedListParams {
	searchRequest: SearchRequest<PatientFilter>;
}

export interface PatientReAssignPatientRelatedParams {
	memberId: string;
	request: ReassignRequest;
}

export interface PatientGetPatientRecommendedCareProtocolParams {
	memberId: string;
	request: PatientClassificationRequest;
}

export interface PatientMiniPatientListParams {
	request: MiniPersonRequest;
}

export interface PatientGetIncompleteInsuranceParams {
	request: SearchRequest<IncompleteInsuranceRequest>;
}

export class PatientService extends BaseController<Patient, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/patient';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public get({ baseUrl, params, headers }: RequestOption<PatientGetParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<Patient>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}${getQueryStrings(queryList)}`, { headers });
	}

	public update({ baseUrl, params, headers }: RequestOption<PatientUpdateParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<Patient>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public count({ baseUrl, params, headers }: RequestOption<PatientCountParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PatientCountResult>>(`${baseUrl || this.baseURL}${this.basePath}/count${getQueryStrings(queryList)}`, params.patientFilter, { headers });
	}

	public getMtprEligibility({ baseUrl, params, headers }: RequestOption<PatientGetMtprEligibilityParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<MtprEligibility>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/monthly-review-eligibility${getQueryStrings(queryList)}`, { headers });
	}

	public getOutstandingItemCount({ baseUrl, params, headers }: RequestOption<PatientGetOutstandingItemCountParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<OutstandingItems>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/outstanding${getQueryStrings(queryList)}`, { headers });
	}

	public getPatientAssignees({ baseUrl, params, headers }: RequestOption<PatientGetPatientAssigneesParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PatientAssignees>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/assignees${getQueryStrings(queryList)}`, { headers });
	}

	public listFilters({ baseUrl, params, headers }: RequestOption<PatientListFiltersParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<ReassignOption>>(`${baseUrl || this.baseURL}${this.basePath}/assign/filters${getQueryStrings(queryList)}`, { headers });
	}

	public listPatients({ baseUrl, params, headers }: RequestOption<PatientListPatientsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PatientListResult[]>>(`${baseUrl || this.baseURL}${this.basePath}/assign/list${getQueryStrings(queryList)}`, params.filter, { headers });
	}

	public getPatientDeviceStatus({ baseUrl, params, headers }: RequestOption<PatientGetPatientDeviceStatusParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<PatientDeviceStatus>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/device-status${getQueryStrings(queryList)}`, { headers });
	}

	public updatePatientDeviceStatus({ baseUrl, params, headers }: RequestOption<PatientUpdatePatientDeviceStatusParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/device-status${getQueryStrings(queryList)}`, params.updateRequest, { headers });
	}

	public getAvailableDevices({ baseUrl, params, headers }: RequestOption<PatientGetAvailableDevicesParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<IotDeviceConfig>>>(`${baseUrl || this.baseURL}${this.basePath}/{memberId}/available-devices${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getHealthCondition({ baseUrl, params, headers }: RequestOption<PatientGetHealthConditionParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<HealthCondition>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/health-condition${getQueryStrings(queryList)}`, { headers });
	}

	public getLifestyleById({ baseUrl, params, headers }: RequestOption<PatientGetLifestyleByIdParams>) {
		const queryList: QueryParam[] = [];
		if (params.version !== undefined && params.version !== null) {
			queryList.push({ name: 'version', value: params.version.toString() });
		}
		return axios.get<APIResponse<Lifestyle>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/lifestyle${getQueryStrings(queryList)}`, { headers });
	}

	public updateLifestyle({ baseUrl, params, headers }: RequestOption<PatientUpdateLifestyleParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<Lifestyle>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/lifestyle${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getNutritionById({ baseUrl, params, headers }: RequestOption<PatientGetNutritionByIdParams>) {
		const queryList: QueryParam[] = [];
		if (params.version !== undefined && params.version !== null) {
			queryList.push({ name: 'version', value: params.version.toString() });
		}
		return axios.get<APIResponse<Nutrition>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/nutrition${getQueryStrings(queryList)}`, { headers });
	}

	public updateNutrition({ baseUrl, params, headers }: RequestOption<PatientUpdateNutritionParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<Nutrition>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/nutrition${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public sendSMS({ baseUrl, params, headers }: RequestOption<PatientSendSMSParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/send-sms${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public upsertPatientAssignees({ baseUrl, params, headers }: RequestOption<PatientUpsertPatientAssigneesParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/assignees${getQueryStrings(queryList)}`, params.patientAssigneesRequest, { headers });
	}

	public patientList({ baseUrl, params, headers }: RequestOption<PatientPatientListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<PatientResult>>>(`${baseUrl || this.baseURL}${this.basePath}/list${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getPatientCgmDevice({ baseUrl, params, headers }: RequestOption<PatientGetPatientCgmDeviceParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PatientCgmDevice>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/cgm-device${getQueryStrings(queryList)}`, { headers });
	}

	public refreshOutstandingItem({ baseUrl, params, headers }: RequestOption<PatientRefreshOutstandingItemParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/refresh-outstanding-item${getQueryStrings(queryList)}`, {}, { headers });
	}

	public updateCgmGoal({ baseUrl, params, headers }: RequestOption<PatientUpdateCgmGoalParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<CgmGoal>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/cgm-goal${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getPatientDevice({ baseUrl, params, headers }: RequestOption<PatientGetPatientDeviceParams>) {
		const queryList: QueryParam[] = [];
		if (params.vitalType !== undefined && params.vitalType !== null) {
			queryList.push({ name: 'vitalType', value: params.vitalType.toString() });
		}
		return axios.get<APIResponse<PatientDevice>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/device${getQueryStrings(queryList)}`, { headers });
	}

	public updatePatientDevice({ baseUrl, params, headers }: RequestOption<PatientUpdatePatientDeviceParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/device${getQueryStrings(queryList)}`, params.updateRequest, { headers });
	}

	public updateLoanDevice({ baseUrl, params, headers }: RequestOption<PatientUpdateLoanDeviceParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/loan-device${getQueryStrings(queryList)}`, params.updateRequest, { headers });
	}

	public deletePatientDevice({ baseUrl, params, headers }: RequestOption<PatientDeletePatientDeviceParams>) {
		const queryList: QueryParam[] = [];
		return axios.delete<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/device/${params.deviceId}${getQueryStrings(queryList)}`, { headers });
	}

	public trackPatientViewer({ baseUrl, params, headers }: RequestOption<PatientTrackPatientViewerParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/track-viewer${getQueryStrings(queryList)}`, {}, { headers });
	}

	public searchPatientViewer({ baseUrl, params, headers }: RequestOption<PatientSearchPatientViewerParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PatientViewer[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/search-viewer${getQueryStrings(queryList)}`, {}, { headers });
	}

	public searchCarePlan({ baseUrl, params, headers }: RequestOption<PatientSearchCarePlanParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<CarePlan>>>(`${baseUrl || this.baseURL}${this.basePath}/care-plan/search${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getConsentFormHistory({ baseUrl, params, headers }: RequestOption<PatientGetConsentFormHistoryParams>) {
		const queryList: QueryParam[] = [];
		if (params.consentStatus !== undefined && params.consentStatus !== null) {
			queryList.push({ name: 'consentStatus', value: params.consentStatus.toString() });
		}
		return axios.get<APIResponse<PatientConsent[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/consent-form/history${getQueryStrings(queryList)}`, { headers });
	}

	public getRecommendVitalAndProgram({ baseUrl, params, headers }: RequestOption<PatientGetRecommendVitalAndProgramParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<RecommendVitalAndProgram>>(`${baseUrl || this.baseURL}${this.basePath}/enrolled-programs/recommend-vital${getQueryStrings(queryList)}`, params.diagnoses, { headers });
	}

	public updateHealthCondition({ baseUrl, params, headers }: RequestOption<PatientUpdateHealthConditionParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<HealthCondition>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/health-condition${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public checkCodeIsBillable({ baseUrl, params, headers }: RequestOption<PatientCheckCodeIsBillableParams>) {
		const queryList: QueryParam[] = [];
		if (params.icdCode !== undefined && params.icdCode !== null) {
			queryList.push({ name: 'icdCode', value: params.icdCode.toString() });
		}
		return axios.get<APIResponse<boolean>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/clinical-goal-template/billable${getQueryStrings(queryList)}`, { headers });
	}

	public updateClinicalGoal({ baseUrl, params, headers }: RequestOption<PatientUpdateClinicalGoalParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<ClinicalGoal>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/clinical-goal${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public updateBehaviorGoal({ baseUrl, params, headers }: RequestOption<PatientUpdateBehaviorGoalParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<BehaviorGoal>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/behavior-goal${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getPatientComplexityById({ baseUrl, params, headers }: RequestOption<PatientGetPatientComplexityByIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PatientComplexity>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/complexity${getQueryStrings(queryList)}`, { headers });
	}

	public getPatientClassificationById({ baseUrl, params, headers }: RequestOption<PatientGetPatientClassificationByIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PatientClassification>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/classification${getQueryStrings(queryList)}`, { headers });
	}

	public getPatientComplexityByIcdCodes({ baseUrl, params, headers }: RequestOption<PatientGetPatientComplexityByIcdCodesParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PatientComplexityEnum>>(`${baseUrl || this.baseURL}${this.basePath}/complexity/icd${getQueryStrings(queryList)}`, params.conditions, { headers });
	}

	public searchMonthlyReview({ baseUrl, params, headers }: RequestOption<PatientSearchMonthlyReviewParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<MonthlyReview[]>>(`${baseUrl || this.baseURL}${this.basePath}/monthly-review/search${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public updateMonthlyReview({ baseUrl, params, headers }: RequestOption<PatientUpdateMonthlyReviewParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<MonthlyReview>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/monthly-review${getQueryStrings(queryList)}`, params.update, { headers });
	}

	public getVersionList({ baseUrl, params, headers }: RequestOption<PatientGetVersionListParams>) {
		const queryList: QueryParam[] = [];
		if (params.entities !== undefined && params.entities !== null) {
			queryList.push({ name: 'entities', value: params.entities.toString() });
		}
		return axios.get<APIResponse<VersionListItem[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/version-list${getQueryStrings(queryList)}`, { headers });
	}

	public getUserOrders({ baseUrl, params, headers }: RequestOption<PatientGetUserOrdersParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<Order[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/orders${getQueryStrings(queryList)}`, { headers });
	}

	public searchOrders({ baseUrl, params, headers }: RequestOption<PatientSearchOrdersParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<OrderStaging>>>(`${baseUrl || this.baseURL}${this.basePath}/order/search${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public createUserOrder({ baseUrl, params, headers }: RequestOption<PatientCreateUserOrderParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Order>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/orders${getQueryStrings(queryList)}`, params.order, { headers });
	}

	public getOrderOption({ baseUrl, params, headers }: RequestOption<PatientGetOrderOptionParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<Order>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/order-option${getQueryStrings(queryList)}`, { headers });
	}

	public unsetAssignees({ baseUrl, params, headers }: RequestOption<PatientUnsetAssigneesParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/assignees/unset${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public updatePassword({ baseUrl, params, headers }: RequestOption<PatientUpdatePasswordParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<SetPasswordResult>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/password${getQueryStrings(queryList)}`, params.spInput, { headers });
	}

	public patientSearchList({ baseUrl, params, headers }: RequestOption<PatientPatientSearchListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<PatientSearchResult>>>(`${baseUrl || this.baseURL}${this.basePath}/search-list${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public upsertPatientEnrolledProgram({ baseUrl, params, headers }: RequestOption<PatientUpsertPatientEnrolledProgramParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<PatientEnrolledProgram>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/enrolled-programs${getQueryStrings(queryList)}`, params.enrolledProgram, { headers });
	}

	public updateUnableToEngage({ baseUrl, params, headers }: RequestOption<PatientUpdateUnableToEngageParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<PatientEnrolledProgram>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/unable-to-engage${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public setPatientAlertTasksConfig({ baseUrl, params, headers }: RequestOption<PatientSetPatientAlertTasksConfigParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<SmartAlertTasksConfig>>(`${baseUrl || this.baseURL}${this.basePath}/alert-tasks-config${getQueryStrings(queryList)}`, params.configRequest, { headers });
	}

	public getPatientAlertConfig({ baseUrl, params, headers }: RequestOption<PatientGetPatientAlertConfigParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<SmartAlertConfig[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/get-alert-config${getQueryStrings(queryList)}`, { headers });
	}

	public upsertPatientOutReachCall({ baseUrl, params, headers }: RequestOption<PatientUpsertPatientOutReachCallParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<PatientOutReachCall>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/out-reach-call${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public deletePatient({ baseUrl, params, headers }: RequestOption<PatientDeletePatientParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Patient>>(`${baseUrl || this.baseURL}${this.basePath}/delete${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public updatePatientCgmDevice({ baseUrl, params, headers }: RequestOption<PatientUpdatePatientCgmDeviceParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/cgm-device${getQueryStrings(queryList)}`, params.updateRequest, { headers });
	}

	public getPatientInsurance({ baseUrl, params, headers }: RequestOption<PatientGetPatientInsuranceParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PatientInsurance>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/insurance${getQueryStrings(queryList)}`, { headers });
	}

	public updatePatientInsurance({ baseUrl, params, headers }: RequestOption<PatientUpdatePatientInsuranceParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<PatientInsurance>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/insurance${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getPatientBaseline({ baseUrl, params, headers }: RequestOption<PatientGetPatientBaselineParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PatientBaseline>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/baseline${getQueryStrings(queryList)}`, { headers });
	}

	public updatePatientBaseline({ baseUrl, params, headers }: RequestOption<PatientUpdatePatientBaselineParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<PatientBaseline>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/baseline${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getConsentForm({ baseUrl, params, headers }: RequestOption<PatientGetConsentFormParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PatientConsent>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/consent-form${getQueryStrings(queryList)}`, { headers });
	}

	public previewConsentForm({ baseUrl, params, headers }: RequestOption<PatientPreviewConsentFormParams>) {
		const queryList: QueryParam[] = [];
		if (params.consentType !== undefined && params.consentType !== null) {
			queryList.push({ name: 'consentType', value: params.consentType.toString() });
		}
		return axios.get<APIResponse<FileInfo>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/consent-form/preview${getQueryStrings(queryList)}`, { headers });
	}

	public updateConsentForm({ baseUrl, params, headers }: RequestOption<PatientUpdateConsentFormParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<PatientConsent>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/consent-form${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getEnrolledPrograms({ baseUrl, params, headers }: RequestOption<PatientGetEnrolledProgramsParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PatientEnrolledProgram>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/enrolled-programs${getQueryStrings(queryList)}`, { headers });
	}

	public getPatientExerciseGoal({ baseUrl, params, headers }: RequestOption<PatientGetPatientExerciseGoalParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<MonitoringVital>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/enrolled-programs/exercise-goal${getQueryStrings(queryList)}`, { headers });
	}

	public upsertDiseaseHistory({ baseUrl, params, headers }: RequestOption<PatientUpsertDiseaseHistoryParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<DiseaseHistory>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/disease-history${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getDiseaseHistory({ baseUrl, params, headers }: RequestOption<PatientGetDiseaseHistoryParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<DiseaseHistory>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/disease-history${getQueryStrings(queryList)}`, { headers });
	}

	public getClinicalGoalById({ baseUrl, params, headers }: RequestOption<PatientGetClinicalGoalByIdParams>) {
		const queryList: QueryParam[] = [];
		if (params.version !== undefined && params.version !== null) {
			queryList.push({ name: 'version', value: params.version.toString() });
		}
		return axios.get<APIResponse<ClinicalGoal>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/clinical-goal${getQueryStrings(queryList)}`, { headers });
	}

	public getCgmGoalById({ baseUrl, params, headers }: RequestOption<PatientGetCgmGoalByIdParams>) {
		const queryList: QueryParam[] = [];
		if (params.version !== undefined && params.version !== null) {
			queryList.push({ name: 'version', value: params.version.toString() });
		}
		return axios.get<APIResponse<CgmGoal>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/cgm-goal${getQueryStrings(queryList)}`, { headers });
	}

	public getBehaviorGoalById({ baseUrl, params, headers }: RequestOption<PatientGetBehaviorGoalByIdParams>) {
		const queryList: QueryParam[] = [];
		if (params.version !== undefined && params.version !== null) {
			queryList.push({ name: 'version', value: params.version.toString() });
		}
		return axios.get<APIResponse<BehaviorGoal>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/behavior-goal${getQueryStrings(queryList)}`, { headers });
	}

	public getEnrollment({ baseUrl, params, headers }: RequestOption<PatientGetEnrollmentParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<EnrollmentRequest>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/enrollment${getQueryStrings(queryList)}`, { headers });
	}

	public updateEnrollment({ baseUrl, params, headers }: RequestOption<PatientUpdateEnrollmentParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<EnrollmentRequest>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/enrollment${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public insertPatientComplexity({ baseUrl, params, headers }: RequestOption<PatientInsertPatientComplexityParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PatientComplexity>>(`${baseUrl || this.baseURL}${this.basePath}/complexity${getQueryStrings(queryList)}`, params.patientComplexity, { headers });
	}

	public searchPatientComplexity({ baseUrl, params, headers }: RequestOption<PatientSearchPatientComplexityParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<PatientComplexity>>>(`${baseUrl || this.baseURL}${this.basePath}/complexity/search${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getPatientControlLevelById({ baseUrl, params, headers }: RequestOption<PatientGetPatientControlLevelByIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PatientControlLevel>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/control-level${getQueryStrings(queryList)}`, { headers });
	}

	public updatePatientA1cGoal({ baseUrl, params, headers }: RequestOption<PatientUpdatePatientA1cGoalParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<PatientControlLevel>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/a1c-goal${getQueryStrings(queryList)}`, params.goal, { headers });
	}

	public updatePatientComplexity({ baseUrl, params, headers }: RequestOption<PatientUpdatePatientComplexityParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<PatientComplexity>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/complexity${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public insertUcMedicationManagement({ baseUrl, params, headers }: RequestOption<PatientInsertUcMedicationManagementParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<UcMedicationManagement>>(`${baseUrl || this.baseURL}${this.basePath}/medication-management${getQueryStrings(queryList)}`, params.ucMedicationManagement, { headers });
	}

	public updateUcMedicationManagement({ baseUrl, params, headers }: RequestOption<PatientUpdateUcMedicationManagementParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<UcMedicationManagement>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/medication-management${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getUcMedicationManagement({ baseUrl, params, headers }: RequestOption<PatientGetUcMedicationManagementParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<UcMedicationManagement>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/medication-management${getQueryStrings(queryList)}`, { headers });
	}

	public searchUcMedicationManagement({ baseUrl, params, headers }: RequestOption<PatientSearchUcMedicationManagementParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<MedicationListSearchResponse>>(`${baseUrl || this.baseURL}${this.basePath}/medication-list/search${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getMonthlyReviewByMemberId({ baseUrl, params, headers }: RequestOption<PatientGetMonthlyReviewByMemberIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<MonthlyReview[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/monthly-review${getQueryStrings(queryList)}`, { headers });
	}

	public upsertPatientExerciseGoal({ baseUrl, params, headers }: RequestOption<PatientUpsertPatientExerciseGoalParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<PatientEnrolledProgram>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/enrolled-programs/exercise-goal${getQueryStrings(queryList)}`, params.exerciseVital, { headers });
	}

	public upsertPatientHiatusStatus({ baseUrl, params, headers }: RequestOption<PatientUpsertPatientHiatusStatusParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<PatientEnrolledProgram>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/hiatus-status${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public dischargeEnrolledProgram({ baseUrl, params, headers }: RequestOption<PatientDischargeEnrolledProgramParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<PatientEnrolledProgram>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/discharge-enrolled-program${getQueryStrings(queryList)}`, params.dischargedDetail, { headers });
	}

	public getPatientReward({ baseUrl, params, headers }: RequestOption<PatientGetPatientRewardParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PatientReward>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/patient-reward${getQueryStrings(queryList)}`, { headers });
	}

	public checkPhoneNumberExist({ baseUrl, params, headers }: RequestOption<PatientCheckPhoneNumberExistParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<boolean>>(`${baseUrl || this.baseURL}${this.basePath}/check-number-exist${getQueryStrings(queryList)}`, params.phone, { headers });
	}

	public tagList({ baseUrl, params, headers }: RequestOption<PatientTagListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Tag[]>>(`${baseUrl || this.baseURL}${this.basePath}/tag-list${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public assignPatients({ baseUrl, params, headers }: RequestOption<PatientAssignPatientsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Tag>>(`${baseUrl || this.baseURL}${this.basePath}/assign-patients-tag${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public patientLimitedList({ baseUrl, params, headers }: RequestOption<PatientPatientLimitedListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<Patient>>>(`${baseUrl || this.baseURL}${this.basePath}/home-list${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public reAssignPatientRelated({ baseUrl, params, headers }: RequestOption<PatientReAssignPatientRelatedParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/reassign-patient-related${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getPatientRecommendedCareProtocol({ baseUrl, params, headers }: RequestOption<PatientGetPatientRecommendedCareProtocolParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PatientProtocolEnum>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/care-protocol-recommendation${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public miniPatientList({ baseUrl, params, headers }: RequestOption<PatientMiniPatientListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<MiniPersonResponse[]>>(`${baseUrl || this.baseURL}${this.basePath}/mini-patient-list${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getIncompleteInsurance({ baseUrl, params, headers }: RequestOption<PatientGetIncompleteInsuranceParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<IncompleteStatusPatient>>>(`${baseUrl || this.baseURL}${this.basePath}/incomplete-status-list${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const patientService = new PatientService();

export const createPatientGetObject = (value: APIResponse<Patient>) => new APIResponseClass<PatientClass>(value, (value: Patient) => new PatientClass(value));

export const usePatientGet = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetParams,
    APIResponse<Patient>,
    typeof patientService.get,
    GetOptions,
    APIResponseClass<PatientClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.get>) => (
      patientService.get(...args)
    ),
    ResponseClassCreator: createPatientGetObject,
    typeName: 'usePatientGet',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGet.mock = () => new Error('Mock has not been implemented!');
usePatientGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGet.createObject = createPatientGetObject;

export const createPatientUpdateObject = (value: APIResponse<Patient>) => new APIResponseClass<PatientClass>(value, (value: Patient) => new PatientClass(value));

export const usePatientUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdateParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdateParams,
    APIResponse<Patient>,
    typeof patientService.update,
    AjaxOptions,
    APIResponseClass<PatientClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.update>) => (
      patientService.update(...args)
    ),
    ResponseClassCreator: createPatientUpdateObject,
    typeName: 'usePatientUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdate.mock = () => new Error('Mock has not been implemented!');
usePatientUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdate.createObject = createPatientUpdateObject;

export const createPatientCountObject = (value: APIResponse<PatientCountResult>) => new APIResponseClass<PatientCountResultClass>(value, (value: PatientCountResult) => new PatientCountResultClass(value));

export const usePatientCount = <RCN = undefined>(configs: MakeRequestHookConfig<PatientCountParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientCountParams,
    APIResponse<PatientCountResult>,
    typeof patientService.count,
    AjaxOptions,
    APIResponseClass<PatientCountResultClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.count>) => (
      patientService.count(...args)
    ),
    ResponseClassCreator: createPatientCountObject,
    typeName: 'usePatientCount',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientCount.mock = () => new Error('Mock has not been implemented!');
usePatientCount.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientCount.createObject = createPatientCountObject;

export const createPatientGetMtprEligibilityObject = (value: APIResponse<MtprEligibility>) => new APIResponseClass<MtprEligibilityClass>(value, (value: MtprEligibility) => new MtprEligibilityClass(value));

export const usePatientGetMtprEligibility = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetMtprEligibilityParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetMtprEligibilityParams,
    APIResponse<MtprEligibility>,
    typeof patientService.getMtprEligibility,
    GetOptions,
    APIResponseClass<MtprEligibilityClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getMtprEligibility>) => (
      patientService.getMtprEligibility(...args)
    ),
    ResponseClassCreator: createPatientGetMtprEligibilityObject,
    typeName: 'usePatientGetMtprEligibility',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetMtprEligibility.mock = () => new Error('Mock has not been implemented!');
usePatientGetMtprEligibility.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetMtprEligibility.createObject = createPatientGetMtprEligibilityObject;

export const createPatientGetOutstandingItemCountObject = (value: APIResponse<OutstandingItems>) => new APIResponseClass<OutstandingItemsClass>(value, (value: OutstandingItems) => new OutstandingItemsClass(value));

export const usePatientGetOutstandingItemCount = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetOutstandingItemCountParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetOutstandingItemCountParams,
    APIResponse<OutstandingItems>,
    typeof patientService.getOutstandingItemCount,
    GetOptions,
    APIResponseClass<OutstandingItemsClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getOutstandingItemCount>) => (
      patientService.getOutstandingItemCount(...args)
    ),
    ResponseClassCreator: createPatientGetOutstandingItemCountObject,
    typeName: 'usePatientGetOutstandingItemCount',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetOutstandingItemCount.mock = () => new Error('Mock has not been implemented!');
usePatientGetOutstandingItemCount.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetOutstandingItemCount.createObject = createPatientGetOutstandingItemCountObject;

export const createPatientGetPatientAssigneesObject = (value: APIResponse<PatientAssignees>) => new APIResponseClass<PatientAssigneesClass>(value, (value: PatientAssignees) => new PatientAssigneesClass(value));

export const usePatientGetPatientAssignees = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetPatientAssigneesParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetPatientAssigneesParams,
    APIResponse<PatientAssignees>,
    typeof patientService.getPatientAssignees,
    GetOptions,
    APIResponseClass<PatientAssigneesClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getPatientAssignees>) => (
      patientService.getPatientAssignees(...args)
    ),
    ResponseClassCreator: createPatientGetPatientAssigneesObject,
    typeName: 'usePatientGetPatientAssignees',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetPatientAssignees.mock = () => new Error('Mock has not been implemented!');
usePatientGetPatientAssignees.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetPatientAssignees.createObject = createPatientGetPatientAssigneesObject;

export const createPatientListFiltersObject = (value: APIResponse<ReassignOption>) => new APIResponseClass<ReassignOptionClass>(value, (value: ReassignOption) => new ReassignOptionClass(value));

export const usePatientListFilters = <RCN = undefined>(configs: MakeRequestHookConfig<PatientListFiltersParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientListFiltersParams,
    APIResponse<ReassignOption>,
    typeof patientService.listFilters,
    GetOptions,
    APIResponseClass<ReassignOptionClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.listFilters>) => (
      patientService.listFilters(...args)
    ),
    ResponseClassCreator: createPatientListFiltersObject,
    typeName: 'usePatientListFilters',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientListFilters.mock = () => new Error('Mock has not been implemented!');
usePatientListFilters.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientListFilters.createObject = createPatientListFiltersObject;

export const createPatientListPatientsObject = (value: APIResponse<PatientListResult[]>) => new APIResponseClass<PatientListResultClass[]>(value, (v) => map(v, (value: PatientListResult) => new PatientListResultClass(value)));

export const usePatientListPatients = <RCN = undefined>(configs: MakeRequestHookConfig<PatientListPatientsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientListPatientsParams,
    APIResponse<PatientListResult[]>,
    typeof patientService.listPatients,
    AjaxOptions,
    APIResponseClass<PatientListResultClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.listPatients>) => (
      patientService.listPatients(...args)
    ),
    ResponseClassCreator: createPatientListPatientsObject,
    typeName: 'usePatientListPatients',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientListPatients.mock = () => new Error('Mock has not been implemented!');
usePatientListPatients.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientListPatients.createObject = createPatientListPatientsObject;

export const createPatientGetPatientDeviceStatusObject = (value: PatientDeviceStatus) => new PatientDeviceStatusClass(value);

export const usePatientGetPatientDeviceStatus = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetPatientDeviceStatusParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetPatientDeviceStatusParams,
    PatientDeviceStatus,
    typeof patientService.getPatientDeviceStatus,
    GetOptions,
    PatientDeviceStatusClass
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getPatientDeviceStatus>) => (
      patientService.getPatientDeviceStatus(...args)
    ),
    ResponseClassCreator: createPatientGetPatientDeviceStatusObject,
    typeName: 'usePatientGetPatientDeviceStatus',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetPatientDeviceStatus.mock = () => new Error('Mock has not been implemented!');
usePatientGetPatientDeviceStatus.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetPatientDeviceStatus.createObject = createPatientGetPatientDeviceStatusObject;

export const createPatientUpdatePatientDeviceStatusObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const usePatientUpdatePatientDeviceStatus = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdatePatientDeviceStatusParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdatePatientDeviceStatusParams,
    APIResponse<string>,
    typeof patientService.updatePatientDeviceStatus,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updatePatientDeviceStatus>) => (
      patientService.updatePatientDeviceStatus(...args)
    ),
    ResponseClassCreator: createPatientUpdatePatientDeviceStatusObject,
    typeName: 'usePatientUpdatePatientDeviceStatus',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdatePatientDeviceStatus.mock = () => new Error('Mock has not been implemented!');
usePatientUpdatePatientDeviceStatus.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdatePatientDeviceStatus.createObject = createPatientUpdatePatientDeviceStatusObject;

export const createPatientGetAvailableDevicesObject = (value: APIResponse<PageResponse<IotDeviceConfig>>) => new APIResponseClass<PageResponseClass<IotDeviceConfigClass>>(value, (value: PageResponse<IotDeviceConfig>) => new PageResponseClass<IotDeviceConfigClass>(value, (value: IotDeviceConfig) => new IotDeviceConfigClass(value)));

export const usePatientGetAvailableDevices = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetAvailableDevicesParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetAvailableDevicesParams,
    APIResponse<PageResponse<IotDeviceConfig>>,
    typeof patientService.getAvailableDevices,
    AjaxOptions,
    APIResponseClass<PageResponseClass<IotDeviceConfigClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.getAvailableDevices>) => (
      patientService.getAvailableDevices(...args)
    ),
    ResponseClassCreator: createPatientGetAvailableDevicesObject,
    typeName: 'usePatientGetAvailableDevices',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetAvailableDevices.mock = () => new Error('Mock has not been implemented!');
usePatientGetAvailableDevices.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetAvailableDevices.createObject = createPatientGetAvailableDevicesObject;

export const createPatientGetHealthConditionObject = (value: APIResponse<HealthCondition>) => new APIResponseClass<HealthConditionClass>(value, (value: HealthCondition) => new HealthConditionClass(value));

export const usePatientGetHealthCondition = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetHealthConditionParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetHealthConditionParams,
    APIResponse<HealthCondition>,
    typeof patientService.getHealthCondition,
    GetOptions,
    APIResponseClass<HealthConditionClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getHealthCondition>) => (
      patientService.getHealthCondition(...args)
    ),
    ResponseClassCreator: createPatientGetHealthConditionObject,
    typeName: 'usePatientGetHealthCondition',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetHealthCondition.mock = () => new Error('Mock has not been implemented!');
usePatientGetHealthCondition.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetHealthCondition.createObject = createPatientGetHealthConditionObject;

export const createPatientGetLifestyleByIdObject = (value: APIResponse<Lifestyle>) => new APIResponseClass<LifestyleClass>(value, (value: Lifestyle) => new LifestyleClass(value));

export const usePatientGetLifestyleById = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetLifestyleByIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetLifestyleByIdParams,
    APIResponse<Lifestyle>,
    typeof patientService.getLifestyleById,
    GetOptions,
    APIResponseClass<LifestyleClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getLifestyleById>) => (
      patientService.getLifestyleById(...args)
    ),
    ResponseClassCreator: createPatientGetLifestyleByIdObject,
    typeName: 'usePatientGetLifestyleById',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetLifestyleById.mock = () => new Error('Mock has not been implemented!');
usePatientGetLifestyleById.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetLifestyleById.createObject = createPatientGetLifestyleByIdObject;

export const createPatientUpdateLifestyleObject = (value: APIResponse<Lifestyle>) => new APIResponseClass<LifestyleClass>(value, (value: Lifestyle) => new LifestyleClass(value));

export const usePatientUpdateLifestyle = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdateLifestyleParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdateLifestyleParams,
    APIResponse<Lifestyle>,
    typeof patientService.updateLifestyle,
    AjaxOptions,
    APIResponseClass<LifestyleClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updateLifestyle>) => (
      patientService.updateLifestyle(...args)
    ),
    ResponseClassCreator: createPatientUpdateLifestyleObject,
    typeName: 'usePatientUpdateLifestyle',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdateLifestyle.mock = () => new Error('Mock has not been implemented!');
usePatientUpdateLifestyle.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdateLifestyle.createObject = createPatientUpdateLifestyleObject;

export const createPatientGetNutritionByIdObject = (value: APIResponse<Nutrition>) => new APIResponseClass<NutritionClass>(value, (value: Nutrition) => new NutritionClass(value));

export const usePatientGetNutritionById = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetNutritionByIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetNutritionByIdParams,
    APIResponse<Nutrition>,
    typeof patientService.getNutritionById,
    GetOptions,
    APIResponseClass<NutritionClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getNutritionById>) => (
      patientService.getNutritionById(...args)
    ),
    ResponseClassCreator: createPatientGetNutritionByIdObject,
    typeName: 'usePatientGetNutritionById',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetNutritionById.mock = () => new Error('Mock has not been implemented!');
usePatientGetNutritionById.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetNutritionById.createObject = createPatientGetNutritionByIdObject;

export const createPatientUpdateNutritionObject = (value: APIResponse<Nutrition>) => new APIResponseClass<NutritionClass>(value, (value: Nutrition) => new NutritionClass(value));

export const usePatientUpdateNutrition = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdateNutritionParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdateNutritionParams,
    APIResponse<Nutrition>,
    typeof patientService.updateNutrition,
    AjaxOptions,
    APIResponseClass<NutritionClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updateNutrition>) => (
      patientService.updateNutrition(...args)
    ),
    ResponseClassCreator: createPatientUpdateNutritionObject,
    typeName: 'usePatientUpdateNutrition',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdateNutrition.mock = () => new Error('Mock has not been implemented!');
usePatientUpdateNutrition.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdateNutrition.createObject = createPatientUpdateNutritionObject;

export const createPatientSendSMSObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const usePatientSendSMS = <RCN = undefined>(configs: MakeRequestHookConfig<PatientSendSMSParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientSendSMSParams,
    APIResponse<string>,
    typeof patientService.sendSMS,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.sendSMS>) => (
      patientService.sendSMS(...args)
    ),
    ResponseClassCreator: createPatientSendSMSObject,
    typeName: 'usePatientSendSMS',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientSendSMS.mock = () => new Error('Mock has not been implemented!');
usePatientSendSMS.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientSendSMS.createObject = createPatientSendSMSObject;

export const createPatientUpsertPatientAssigneesObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const usePatientUpsertPatientAssignees = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpsertPatientAssigneesParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpsertPatientAssigneesParams,
    APIResponse<string>,
    typeof patientService.upsertPatientAssignees,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.upsertPatientAssignees>) => (
      patientService.upsertPatientAssignees(...args)
    ),
    ResponseClassCreator: createPatientUpsertPatientAssigneesObject,
    typeName: 'usePatientUpsertPatientAssignees',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpsertPatientAssignees.mock = () => new Error('Mock has not been implemented!');
usePatientUpsertPatientAssignees.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpsertPatientAssignees.createObject = createPatientUpsertPatientAssigneesObject;

export const createPatientPatientListObject = (value: APIResponse<PageResponse<PatientResult>>) => new APIResponseClass<PageResponseClass<PatientResultClass>>(value, (value: PageResponse<PatientResult>) => new PageResponseClass<PatientResultClass>(value, (value: PatientResult) => new PatientResultClass(value)));

export const usePatientPatientList = <RCN = undefined>(configs: MakeRequestHookConfig<PatientPatientListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientPatientListParams,
    APIResponse<PageResponse<PatientResult>>,
    typeof patientService.patientList,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientResultClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.patientList>) => (
      patientService.patientList(...args)
    ),
    ResponseClassCreator: createPatientPatientListObject,
    typeName: 'usePatientPatientList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientPatientList.mock = () => new Error('Mock has not been implemented!');
usePatientPatientList.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientPatientList.createObject = createPatientPatientListObject;

export const createPatientGetPatientCgmDeviceObject = (value: APIResponse<PatientCgmDevice>) => new APIResponseClass<PatientCgmDeviceClass>(value, (value: PatientCgmDevice) => new PatientCgmDeviceClass(value));

export const usePatientGetPatientCgmDevice = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetPatientCgmDeviceParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetPatientCgmDeviceParams,
    APIResponse<PatientCgmDevice>,
    typeof patientService.getPatientCgmDevice,
    GetOptions,
    APIResponseClass<PatientCgmDeviceClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getPatientCgmDevice>) => (
      patientService.getPatientCgmDevice(...args)
    ),
    ResponseClassCreator: createPatientGetPatientCgmDeviceObject,
    typeName: 'usePatientGetPatientCgmDevice',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetPatientCgmDevice.mock = () => new Error('Mock has not been implemented!');
usePatientGetPatientCgmDevice.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetPatientCgmDevice.createObject = createPatientGetPatientCgmDeviceObject;

export const createPatientRefreshOutstandingItemObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const usePatientRefreshOutstandingItem = <RCN = undefined>(configs: MakeRequestHookConfig<PatientRefreshOutstandingItemParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientRefreshOutstandingItemParams,
    APIResponse<string>,
    typeof patientService.refreshOutstandingItem,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.refreshOutstandingItem>) => (
      patientService.refreshOutstandingItem(...args)
    ),
    ResponseClassCreator: createPatientRefreshOutstandingItemObject,
    typeName: 'usePatientRefreshOutstandingItem',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientRefreshOutstandingItem.mock = () => new Error('Mock has not been implemented!');
usePatientRefreshOutstandingItem.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientRefreshOutstandingItem.createObject = createPatientRefreshOutstandingItemObject;

export const createPatientUpdateCgmGoalObject = (value: APIResponse<CgmGoal>) => new APIResponseClass<CgmGoalClass>(value, (value: CgmGoal) => new CgmGoalClass(value));

export const usePatientUpdateCgmGoal = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdateCgmGoalParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdateCgmGoalParams,
    APIResponse<CgmGoal>,
    typeof patientService.updateCgmGoal,
    AjaxOptions,
    APIResponseClass<CgmGoalClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updateCgmGoal>) => (
      patientService.updateCgmGoal(...args)
    ),
    ResponseClassCreator: createPatientUpdateCgmGoalObject,
    typeName: 'usePatientUpdateCgmGoal',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdateCgmGoal.mock = () => new Error('Mock has not been implemented!');
usePatientUpdateCgmGoal.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdateCgmGoal.createObject = createPatientUpdateCgmGoalObject;

export const createPatientGetPatientDeviceObject = (value: APIResponse<PatientDevice>) => new APIResponseClass<PatientDeviceClass>(value, (value: PatientDevice) => new PatientDeviceClass(value));

export const usePatientGetPatientDevice = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetPatientDeviceParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetPatientDeviceParams,
    APIResponse<PatientDevice>,
    typeof patientService.getPatientDevice,
    GetOptions,
    APIResponseClass<PatientDeviceClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getPatientDevice>) => (
      patientService.getPatientDevice(...args)
    ),
    ResponseClassCreator: createPatientGetPatientDeviceObject,
    typeName: 'usePatientGetPatientDevice',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetPatientDevice.mock = () => new Error('Mock has not been implemented!');
usePatientGetPatientDevice.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetPatientDevice.createObject = createPatientGetPatientDeviceObject;

export const createPatientUpdatePatientDeviceObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const usePatientUpdatePatientDevice = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdatePatientDeviceParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdatePatientDeviceParams,
    APIResponse<string>,
    typeof patientService.updatePatientDevice,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updatePatientDevice>) => (
      patientService.updatePatientDevice(...args)
    ),
    ResponseClassCreator: createPatientUpdatePatientDeviceObject,
    typeName: 'usePatientUpdatePatientDevice',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdatePatientDevice.mock = () => new Error('Mock has not been implemented!');
usePatientUpdatePatientDevice.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdatePatientDevice.createObject = createPatientUpdatePatientDeviceObject;

export const createPatientUpdateLoanDeviceObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const usePatientUpdateLoanDevice = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdateLoanDeviceParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdateLoanDeviceParams,
    APIResponse<string>,
    typeof patientService.updateLoanDevice,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updateLoanDevice>) => (
      patientService.updateLoanDevice(...args)
    ),
    ResponseClassCreator: createPatientUpdateLoanDeviceObject,
    typeName: 'usePatientUpdateLoanDevice',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdateLoanDevice.mock = () => new Error('Mock has not been implemented!');
usePatientUpdateLoanDevice.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdateLoanDevice.createObject = createPatientUpdateLoanDeviceObject;

export const createPatientDeletePatientDeviceObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const usePatientDeletePatientDevice = <RCN = undefined>(configs: MakeRequestHookConfig<PatientDeletePatientDeviceParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientDeletePatientDeviceParams,
    APIResponse<string>,
    typeof patientService.deletePatientDevice,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.deletePatientDevice>) => (
      patientService.deletePatientDevice(...args)
    ),
    ResponseClassCreator: createPatientDeletePatientDeviceObject,
    typeName: 'usePatientDeletePatientDevice',
    type: MethodTypeEnum.DELETE,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientDeletePatientDevice.mock = () => new Error('Mock has not been implemented!');
usePatientDeletePatientDevice.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientDeletePatientDevice.createObject = createPatientDeletePatientDeviceObject;

export const createPatientTrackPatientViewerObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const usePatientTrackPatientViewer = <RCN = undefined>(configs: MakeRequestHookConfig<PatientTrackPatientViewerParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientTrackPatientViewerParams,
    APIResponse<string>,
    typeof patientService.trackPatientViewer,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.trackPatientViewer>) => (
      patientService.trackPatientViewer(...args)
    ),
    ResponseClassCreator: createPatientTrackPatientViewerObject,
    typeName: 'usePatientTrackPatientViewer',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientTrackPatientViewer.mock = () => new Error('Mock has not been implemented!');
usePatientTrackPatientViewer.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientTrackPatientViewer.createObject = createPatientTrackPatientViewerObject;

export const createPatientSearchPatientViewerObject = (value: APIResponse<PatientViewer[]>) => new APIResponseClass<PatientViewerClass[]>(value, (v) => map(v, (value: PatientViewer) => new PatientViewerClass(value)));

export const usePatientSearchPatientViewer = <RCN = undefined>(configs: MakeRequestHookConfig<PatientSearchPatientViewerParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientSearchPatientViewerParams,
    APIResponse<PatientViewer[]>,
    typeof patientService.searchPatientViewer,
    AjaxOptions,
    APIResponseClass<PatientViewerClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.searchPatientViewer>) => (
      patientService.searchPatientViewer(...args)
    ),
    ResponseClassCreator: createPatientSearchPatientViewerObject,
    typeName: 'usePatientSearchPatientViewer',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientSearchPatientViewer.mock = () => new Error('Mock has not been implemented!');
usePatientSearchPatientViewer.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientSearchPatientViewer.createObject = createPatientSearchPatientViewerObject;

export const createPatientSearchCarePlanObject = (value: APIResponse<PageResponse<CarePlan>>) => new APIResponseClass<PageResponseClass<CarePlanClass>>(value, (value: PageResponse<CarePlan>) => new PageResponseClass<CarePlanClass>(value, (value: CarePlan) => new CarePlanClass(value)));

export const usePatientSearchCarePlan = <RCN = undefined>(configs: MakeRequestHookConfig<PatientSearchCarePlanParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientSearchCarePlanParams,
    APIResponse<PageResponse<CarePlan>>,
    typeof patientService.searchCarePlan,
    AjaxOptions,
    APIResponseClass<PageResponseClass<CarePlanClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.searchCarePlan>) => (
      patientService.searchCarePlan(...args)
    ),
    ResponseClassCreator: createPatientSearchCarePlanObject,
    typeName: 'usePatientSearchCarePlan',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientSearchCarePlan.mock = () => new Error('Mock has not been implemented!');
usePatientSearchCarePlan.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientSearchCarePlan.createObject = createPatientSearchCarePlanObject;

export const createPatientGetConsentFormHistoryObject = (value: APIResponse<PatientConsent[]>) => new APIResponseClass<PatientConsentClass[]>(value, (v) => map(v, (value: PatientConsent) => new PatientConsentClass(value)));

export const usePatientGetConsentFormHistory = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetConsentFormHistoryParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetConsentFormHistoryParams,
    APIResponse<PatientConsent[]>,
    typeof patientService.getConsentFormHistory,
    GetOptions,
    APIResponseClass<PatientConsentClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getConsentFormHistory>) => (
      patientService.getConsentFormHistory(...args)
    ),
    ResponseClassCreator: createPatientGetConsentFormHistoryObject,
    typeName: 'usePatientGetConsentFormHistory',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetConsentFormHistory.mock = () => new Error('Mock has not been implemented!');
usePatientGetConsentFormHistory.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetConsentFormHistory.createObject = createPatientGetConsentFormHistoryObject;

export const createPatientGetRecommendVitalAndProgramObject = (value: APIResponse<RecommendVitalAndProgram>) => new APIResponseClass<RecommendVitalAndProgramClass>(value, (value: RecommendVitalAndProgram) => new RecommendVitalAndProgramClass(value));

export const usePatientGetRecommendVitalAndProgram = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetRecommendVitalAndProgramParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetRecommendVitalAndProgramParams,
    APIResponse<RecommendVitalAndProgram>,
    typeof patientService.getRecommendVitalAndProgram,
    AjaxOptions,
    APIResponseClass<RecommendVitalAndProgramClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.getRecommendVitalAndProgram>) => (
      patientService.getRecommendVitalAndProgram(...args)
    ),
    ResponseClassCreator: createPatientGetRecommendVitalAndProgramObject,
    typeName: 'usePatientGetRecommendVitalAndProgram',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetRecommendVitalAndProgram.mock = () => new Error('Mock has not been implemented!');
usePatientGetRecommendVitalAndProgram.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetRecommendVitalAndProgram.createObject = createPatientGetRecommendVitalAndProgramObject;

export const createPatientUpdateHealthConditionObject = (value: APIResponse<HealthCondition>) => new APIResponseClass<HealthConditionClass>(value, (value: HealthCondition) => new HealthConditionClass(value));

export const usePatientUpdateHealthCondition = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdateHealthConditionParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdateHealthConditionParams,
    APIResponse<HealthCondition>,
    typeof patientService.updateHealthCondition,
    AjaxOptions,
    APIResponseClass<HealthConditionClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updateHealthCondition>) => (
      patientService.updateHealthCondition(...args)
    ),
    ResponseClassCreator: createPatientUpdateHealthConditionObject,
    typeName: 'usePatientUpdateHealthCondition',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdateHealthCondition.mock = () => new Error('Mock has not been implemented!');
usePatientUpdateHealthCondition.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdateHealthCondition.createObject = createPatientUpdateHealthConditionObject;

export const createPatientCheckCodeIsBillableObject = (value: APIResponse<boolean>) => new APIResponseClass<boolean>(value, (v) => (v));

export const usePatientCheckCodeIsBillable = <RCN = undefined>(configs: MakeRequestHookConfig<PatientCheckCodeIsBillableParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientCheckCodeIsBillableParams,
    APIResponse<boolean>,
    typeof patientService.checkCodeIsBillable,
    GetOptions,
    APIResponseClass<boolean>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.checkCodeIsBillable>) => (
      patientService.checkCodeIsBillable(...args)
    ),
    ResponseClassCreator: createPatientCheckCodeIsBillableObject,
    typeName: 'usePatientCheckCodeIsBillable',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientCheckCodeIsBillable.mock = () => new Error('Mock has not been implemented!');
usePatientCheckCodeIsBillable.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientCheckCodeIsBillable.createObject = createPatientCheckCodeIsBillableObject;

export const createPatientUpdateClinicalGoalObject = (value: APIResponse<ClinicalGoal>) => new APIResponseClass<ClinicalGoalClass>(value, (value: ClinicalGoal) => new ClinicalGoalClass(value));

export const usePatientUpdateClinicalGoal = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdateClinicalGoalParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdateClinicalGoalParams,
    APIResponse<ClinicalGoal>,
    typeof patientService.updateClinicalGoal,
    AjaxOptions,
    APIResponseClass<ClinicalGoalClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updateClinicalGoal>) => (
      patientService.updateClinicalGoal(...args)
    ),
    ResponseClassCreator: createPatientUpdateClinicalGoalObject,
    typeName: 'usePatientUpdateClinicalGoal',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdateClinicalGoal.mock = () => new Error('Mock has not been implemented!');
usePatientUpdateClinicalGoal.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdateClinicalGoal.createObject = createPatientUpdateClinicalGoalObject;

export const createPatientUpdateBehaviorGoalObject = (value: APIResponse<BehaviorGoal>) => new APIResponseClass<BehaviorGoalClass>(value, (value: BehaviorGoal) => new BehaviorGoalClass(value));

export const usePatientUpdateBehaviorGoal = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdateBehaviorGoalParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdateBehaviorGoalParams,
    APIResponse<BehaviorGoal>,
    typeof patientService.updateBehaviorGoal,
    AjaxOptions,
    APIResponseClass<BehaviorGoalClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updateBehaviorGoal>) => (
      patientService.updateBehaviorGoal(...args)
    ),
    ResponseClassCreator: createPatientUpdateBehaviorGoalObject,
    typeName: 'usePatientUpdateBehaviorGoal',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdateBehaviorGoal.mock = () => new Error('Mock has not been implemented!');
usePatientUpdateBehaviorGoal.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdateBehaviorGoal.createObject = createPatientUpdateBehaviorGoalObject;

export const createPatientGetPatientComplexityByIdObject = (value: APIResponse<PatientComplexity>) => new APIResponseClass<PatientComplexityClass>(value, (value: PatientComplexity) => new PatientComplexityClass(value));

export const usePatientGetPatientComplexityById = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetPatientComplexityByIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetPatientComplexityByIdParams,
    APIResponse<PatientComplexity>,
    typeof patientService.getPatientComplexityById,
    GetOptions,
    APIResponseClass<PatientComplexityClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getPatientComplexityById>) => (
      patientService.getPatientComplexityById(...args)
    ),
    ResponseClassCreator: createPatientGetPatientComplexityByIdObject,
    typeName: 'usePatientGetPatientComplexityById',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetPatientComplexityById.mock = () => new Error('Mock has not been implemented!');
usePatientGetPatientComplexityById.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetPatientComplexityById.createObject = createPatientGetPatientComplexityByIdObject;

export const createPatientGetPatientClassificationByIdObject = (value: APIResponse<PatientClassification>) => new APIResponseClass<PatientClassificationClass>(value, (value: PatientClassification) => new PatientClassificationClass(value));

export const usePatientGetPatientClassificationById = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetPatientClassificationByIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetPatientClassificationByIdParams,
    APIResponse<PatientClassification>,
    typeof patientService.getPatientClassificationById,
    GetOptions,
    APIResponseClass<PatientClassificationClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getPatientClassificationById>) => (
      patientService.getPatientClassificationById(...args)
    ),
    ResponseClassCreator: createPatientGetPatientClassificationByIdObject,
    typeName: 'usePatientGetPatientClassificationById',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetPatientClassificationById.mock = () => new Error('Mock has not been implemented!');
usePatientGetPatientClassificationById.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetPatientClassificationById.createObject = createPatientGetPatientClassificationByIdObject;

export const createPatientGetPatientComplexityByIcdCodesObject = (value: APIResponse<PatientComplexityEnum>) => new APIResponseClass<PatientComplexityEnum>(value, (value: PatientComplexityEnum) => new PatientComplexityEnum(value));

export const usePatientGetPatientComplexityByIcdCodes = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetPatientComplexityByIcdCodesParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetPatientComplexityByIcdCodesParams,
    APIResponse<PatientComplexityEnum>,
    typeof patientService.getPatientComplexityByIcdCodes,
    AjaxOptions,
    APIResponseClass<PatientComplexityEnum>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.getPatientComplexityByIcdCodes>) => (
      patientService.getPatientComplexityByIcdCodes(...args)
    ),
    ResponseClassCreator: createPatientGetPatientComplexityByIcdCodesObject,
    typeName: 'usePatientGetPatientComplexityByIcdCodes',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetPatientComplexityByIcdCodes.mock = () => new Error('Mock has not been implemented!');
usePatientGetPatientComplexityByIcdCodes.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetPatientComplexityByIcdCodes.createObject = createPatientGetPatientComplexityByIcdCodesObject;

export const createPatientSearchMonthlyReviewObject = (value: APIResponse<MonthlyReview[]>) => new APIResponseClass<MonthlyReviewClass[]>(value, (v) => map(v, (value: MonthlyReview) => new MonthlyReviewClass(value)));

export const usePatientSearchMonthlyReview = <RCN = undefined>(configs: MakeRequestHookConfig<PatientSearchMonthlyReviewParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientSearchMonthlyReviewParams,
    APIResponse<MonthlyReview[]>,
    typeof patientService.searchMonthlyReview,
    AjaxOptions,
    APIResponseClass<MonthlyReviewClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.searchMonthlyReview>) => (
      patientService.searchMonthlyReview(...args)
    ),
    ResponseClassCreator: createPatientSearchMonthlyReviewObject,
    typeName: 'usePatientSearchMonthlyReview',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientSearchMonthlyReview.mock = () => new Error('Mock has not been implemented!');
usePatientSearchMonthlyReview.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientSearchMonthlyReview.createObject = createPatientSearchMonthlyReviewObject;

export const createPatientUpdateMonthlyReviewObject = (value: APIResponse<MonthlyReview>) => new APIResponseClass<MonthlyReviewClass>(value, (value: MonthlyReview) => new MonthlyReviewClass(value));

export const usePatientUpdateMonthlyReview = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdateMonthlyReviewParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdateMonthlyReviewParams,
    APIResponse<MonthlyReview>,
    typeof patientService.updateMonthlyReview,
    AjaxOptions,
    APIResponseClass<MonthlyReviewClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updateMonthlyReview>) => (
      patientService.updateMonthlyReview(...args)
    ),
    ResponseClassCreator: createPatientUpdateMonthlyReviewObject,
    typeName: 'usePatientUpdateMonthlyReview',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdateMonthlyReview.mock = () => new Error('Mock has not been implemented!');
usePatientUpdateMonthlyReview.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdateMonthlyReview.createObject = createPatientUpdateMonthlyReviewObject;

export const createPatientGetVersionListObject = (value: APIResponse<VersionListItem[]>) => new APIResponseClass<VersionListItemClass[]>(value, (v) => map(v, (value: VersionListItem) => new VersionListItemClass(value)));

export const usePatientGetVersionList = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetVersionListParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetVersionListParams,
    APIResponse<VersionListItem[]>,
    typeof patientService.getVersionList,
    GetOptions,
    APIResponseClass<VersionListItemClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getVersionList>) => (
      patientService.getVersionList(...args)
    ),
    ResponseClassCreator: createPatientGetVersionListObject,
    typeName: 'usePatientGetVersionList',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetVersionList.mock = () => new Error('Mock has not been implemented!');
usePatientGetVersionList.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetVersionList.createObject = createPatientGetVersionListObject;

export const createPatientGetUserOrdersObject = (value: APIResponse<Order[]>) => new APIResponseClass<OrderClass[]>(value, (v) => map(v, (value: Order) => new OrderClass(value)));

export const usePatientGetUserOrders = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetUserOrdersParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetUserOrdersParams,
    APIResponse<Order[]>,
    typeof patientService.getUserOrders,
    GetOptions,
    APIResponseClass<OrderClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getUserOrders>) => (
      patientService.getUserOrders(...args)
    ),
    ResponseClassCreator: createPatientGetUserOrdersObject,
    typeName: 'usePatientGetUserOrders',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetUserOrders.mock = () => new Error('Mock has not been implemented!');
usePatientGetUserOrders.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetUserOrders.createObject = createPatientGetUserOrdersObject;

export const createPatientSearchOrdersObject = (value: APIResponse<PageResponse<OrderStaging>>) => new APIResponseClass<PageResponseClass<OrderStagingClass>>(value, (value: PageResponse<OrderStaging>) => new PageResponseClass<OrderStagingClass>(value, (value: OrderStaging) => new OrderStagingClass(value)));

export const usePatientSearchOrders = <RCN = undefined>(configs: MakeRequestHookConfig<PatientSearchOrdersParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientSearchOrdersParams,
    APIResponse<PageResponse<OrderStaging>>,
    typeof patientService.searchOrders,
    AjaxOptions,
    APIResponseClass<PageResponseClass<OrderStagingClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.searchOrders>) => (
      patientService.searchOrders(...args)
    ),
    ResponseClassCreator: createPatientSearchOrdersObject,
    typeName: 'usePatientSearchOrders',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientSearchOrders.mock = () => new Error('Mock has not been implemented!');
usePatientSearchOrders.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientSearchOrders.createObject = createPatientSearchOrdersObject;

export const createPatientCreateUserOrderObject = (value: APIResponse<Order>) => new APIResponseClass<OrderClass>(value, (value: Order) => new OrderClass(value));

export const usePatientCreateUserOrder = <RCN = undefined>(configs: MakeRequestHookConfig<PatientCreateUserOrderParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientCreateUserOrderParams,
    APIResponse<Order>,
    typeof patientService.createUserOrder,
    AjaxOptions,
    APIResponseClass<OrderClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.createUserOrder>) => (
      patientService.createUserOrder(...args)
    ),
    ResponseClassCreator: createPatientCreateUserOrderObject,
    typeName: 'usePatientCreateUserOrder',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientCreateUserOrder.mock = () => new Error('Mock has not been implemented!');
usePatientCreateUserOrder.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientCreateUserOrder.createObject = createPatientCreateUserOrderObject;

export const createPatientGetOrderOptionObject = (value: APIResponse<Order>) => new APIResponseClass<OrderClass>(value, (value: Order) => new OrderClass(value));

export const usePatientGetOrderOption = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetOrderOptionParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetOrderOptionParams,
    APIResponse<Order>,
    typeof patientService.getOrderOption,
    GetOptions,
    APIResponseClass<OrderClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getOrderOption>) => (
      patientService.getOrderOption(...args)
    ),
    ResponseClassCreator: createPatientGetOrderOptionObject,
    typeName: 'usePatientGetOrderOption',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetOrderOption.mock = () => new Error('Mock has not been implemented!');
usePatientGetOrderOption.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetOrderOption.createObject = createPatientGetOrderOptionObject;

export const createPatientUnsetAssigneesObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const usePatientUnsetAssignees = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUnsetAssigneesParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUnsetAssigneesParams,
    APIResponse<string>,
    typeof patientService.unsetAssignees,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.unsetAssignees>) => (
      patientService.unsetAssignees(...args)
    ),
    ResponseClassCreator: createPatientUnsetAssigneesObject,
    typeName: 'usePatientUnsetAssignees',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUnsetAssignees.mock = () => new Error('Mock has not been implemented!');
usePatientUnsetAssignees.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUnsetAssignees.createObject = createPatientUnsetAssigneesObject;

export const createPatientUpdatePasswordObject = (value: APIResponse<SetPasswordResult>) => new APIResponseClass<SetPasswordResultClass>(value, (value: SetPasswordResult) => new SetPasswordResultClass(value));

export const usePatientUpdatePassword = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdatePasswordParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdatePasswordParams,
    APIResponse<SetPasswordResult>,
    typeof patientService.updatePassword,
    AjaxOptions,
    APIResponseClass<SetPasswordResultClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updatePassword>) => (
      patientService.updatePassword(...args)
    ),
    ResponseClassCreator: createPatientUpdatePasswordObject,
    typeName: 'usePatientUpdatePassword',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdatePassword.mock = () => new Error('Mock has not been implemented!');
usePatientUpdatePassword.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdatePassword.createObject = createPatientUpdatePasswordObject;

export const createPatientPatientSearchListObject = (value: APIResponse<PageResponse<PatientSearchResult>>) => new APIResponseClass<PageResponseClass<PatientSearchResultClass>>(value, (value: PageResponse<PatientSearchResult>) => new PageResponseClass<PatientSearchResultClass>(value, (value: PatientSearchResult) => new PatientSearchResultClass(value)));

export const usePatientPatientSearchList = <RCN = undefined>(configs: MakeRequestHookConfig<PatientPatientSearchListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientPatientSearchListParams,
    APIResponse<PageResponse<PatientSearchResult>>,
    typeof patientService.patientSearchList,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientSearchResultClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.patientSearchList>) => (
      patientService.patientSearchList(...args)
    ),
    ResponseClassCreator: createPatientPatientSearchListObject,
    typeName: 'usePatientPatientSearchList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientPatientSearchList.mock = () => new Error('Mock has not been implemented!');
usePatientPatientSearchList.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientPatientSearchList.createObject = createPatientPatientSearchListObject;

export const createPatientUpsertPatientEnrolledProgramObject = (value: APIResponse<PatientEnrolledProgram>) => new APIResponseClass<PatientEnrolledProgramClass>(value, (value: PatientEnrolledProgram) => new PatientEnrolledProgramClass(value));

export const usePatientUpsertPatientEnrolledProgram = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpsertPatientEnrolledProgramParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpsertPatientEnrolledProgramParams,
    APIResponse<PatientEnrolledProgram>,
    typeof patientService.upsertPatientEnrolledProgram,
    AjaxOptions,
    APIResponseClass<PatientEnrolledProgramClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.upsertPatientEnrolledProgram>) => (
      patientService.upsertPatientEnrolledProgram(...args)
    ),
    ResponseClassCreator: createPatientUpsertPatientEnrolledProgramObject,
    typeName: 'usePatientUpsertPatientEnrolledProgram',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpsertPatientEnrolledProgram.mock = () => new Error('Mock has not been implemented!');
usePatientUpsertPatientEnrolledProgram.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpsertPatientEnrolledProgram.createObject = createPatientUpsertPatientEnrolledProgramObject;

export const createPatientUpdateUnableToEngageObject = (value: APIResponse<PatientEnrolledProgram>) => new APIResponseClass<PatientEnrolledProgramClass>(value, (value: PatientEnrolledProgram) => new PatientEnrolledProgramClass(value));

export const usePatientUpdateUnableToEngage = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdateUnableToEngageParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdateUnableToEngageParams,
    APIResponse<PatientEnrolledProgram>,
    typeof patientService.updateUnableToEngage,
    AjaxOptions,
    APIResponseClass<PatientEnrolledProgramClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updateUnableToEngage>) => (
      patientService.updateUnableToEngage(...args)
    ),
    ResponseClassCreator: createPatientUpdateUnableToEngageObject,
    typeName: 'usePatientUpdateUnableToEngage',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdateUnableToEngage.mock = () => new Error('Mock has not been implemented!');
usePatientUpdateUnableToEngage.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdateUnableToEngage.createObject = createPatientUpdateUnableToEngageObject;

export const createPatientSetPatientAlertTasksConfigObject = (value: APIResponse<SmartAlertTasksConfig>) => new APIResponseClass<SmartAlertTasksConfigClass>(value, (value: SmartAlertTasksConfig) => new SmartAlertTasksConfigClass(value));

export const usePatientSetPatientAlertTasksConfig = <RCN = undefined>(configs: MakeRequestHookConfig<PatientSetPatientAlertTasksConfigParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientSetPatientAlertTasksConfigParams,
    APIResponse<SmartAlertTasksConfig>,
    typeof patientService.setPatientAlertTasksConfig,
    AjaxOptions,
    APIResponseClass<SmartAlertTasksConfigClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.setPatientAlertTasksConfig>) => (
      patientService.setPatientAlertTasksConfig(...args)
    ),
    ResponseClassCreator: createPatientSetPatientAlertTasksConfigObject,
    typeName: 'usePatientSetPatientAlertTasksConfig',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientSetPatientAlertTasksConfig.mock = () => new Error('Mock has not been implemented!');
usePatientSetPatientAlertTasksConfig.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientSetPatientAlertTasksConfig.createObject = createPatientSetPatientAlertTasksConfigObject;

export const createPatientGetPatientAlertConfigObject = (value: APIResponse<SmartAlertConfig[]>) => new APIResponseClass<SmartAlertConfigClass[]>(value, (v) => map(v, (value: SmartAlertConfig) => new SmartAlertConfigClass(value)));

export const usePatientGetPatientAlertConfig = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetPatientAlertConfigParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetPatientAlertConfigParams,
    APIResponse<SmartAlertConfig[]>,
    typeof patientService.getPatientAlertConfig,
    GetOptions,
    APIResponseClass<SmartAlertConfigClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getPatientAlertConfig>) => (
      patientService.getPatientAlertConfig(...args)
    ),
    ResponseClassCreator: createPatientGetPatientAlertConfigObject,
    typeName: 'usePatientGetPatientAlertConfig',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetPatientAlertConfig.mock = () => new Error('Mock has not been implemented!');
usePatientGetPatientAlertConfig.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetPatientAlertConfig.createObject = createPatientGetPatientAlertConfigObject;

export const createPatientUpsertPatientOutReachCallObject = (value: APIResponse<PatientOutReachCall>) => new APIResponseClass<PatientOutReachCallClass>(value, (value: PatientOutReachCall) => new PatientOutReachCallClass(value));

export const usePatientUpsertPatientOutReachCall = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpsertPatientOutReachCallParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpsertPatientOutReachCallParams,
    APIResponse<PatientOutReachCall>,
    typeof patientService.upsertPatientOutReachCall,
    AjaxOptions,
    APIResponseClass<PatientOutReachCallClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.upsertPatientOutReachCall>) => (
      patientService.upsertPatientOutReachCall(...args)
    ),
    ResponseClassCreator: createPatientUpsertPatientOutReachCallObject,
    typeName: 'usePatientUpsertPatientOutReachCall',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpsertPatientOutReachCall.mock = () => new Error('Mock has not been implemented!');
usePatientUpsertPatientOutReachCall.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpsertPatientOutReachCall.createObject = createPatientUpsertPatientOutReachCallObject;

export const createPatientDeletePatientObject = (value: APIResponse<Patient>) => new APIResponseClass<PatientClass>(value, (value: Patient) => new PatientClass(value));

export const usePatientDeletePatient = <RCN = undefined>(configs: MakeRequestHookConfig<PatientDeletePatientParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientDeletePatientParams,
    APIResponse<Patient>,
    typeof patientService.deletePatient,
    AjaxOptions,
    APIResponseClass<PatientClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.deletePatient>) => (
      patientService.deletePatient(...args)
    ),
    ResponseClassCreator: createPatientDeletePatientObject,
    typeName: 'usePatientDeletePatient',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientDeletePatient.mock = () => new Error('Mock has not been implemented!');
usePatientDeletePatient.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientDeletePatient.createObject = createPatientDeletePatientObject;

export const createPatientUpdatePatientCgmDeviceObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const usePatientUpdatePatientCgmDevice = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdatePatientCgmDeviceParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdatePatientCgmDeviceParams,
    APIResponse<string>,
    typeof patientService.updatePatientCgmDevice,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updatePatientCgmDevice>) => (
      patientService.updatePatientCgmDevice(...args)
    ),
    ResponseClassCreator: createPatientUpdatePatientCgmDeviceObject,
    typeName: 'usePatientUpdatePatientCgmDevice',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdatePatientCgmDevice.mock = () => new Error('Mock has not been implemented!');
usePatientUpdatePatientCgmDevice.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdatePatientCgmDevice.createObject = createPatientUpdatePatientCgmDeviceObject;

export const createPatientGetPatientInsuranceObject = (value: APIResponse<PatientInsurance>) => new APIResponseClass<PatientInsuranceClass>(value, (value: PatientInsurance) => new PatientInsuranceClass(value));

export const usePatientGetPatientInsurance = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetPatientInsuranceParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetPatientInsuranceParams,
    APIResponse<PatientInsurance>,
    typeof patientService.getPatientInsurance,
    GetOptions,
    APIResponseClass<PatientInsuranceClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getPatientInsurance>) => (
      patientService.getPatientInsurance(...args)
    ),
    ResponseClassCreator: createPatientGetPatientInsuranceObject,
    typeName: 'usePatientGetPatientInsurance',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetPatientInsurance.mock = () => new Error('Mock has not been implemented!');
usePatientGetPatientInsurance.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetPatientInsurance.createObject = createPatientGetPatientInsuranceObject;

export const createPatientUpdatePatientInsuranceObject = (value: APIResponse<PatientInsurance>) => new APIResponseClass<PatientInsuranceClass>(value, (value: PatientInsurance) => new PatientInsuranceClass(value));

export const usePatientUpdatePatientInsurance = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdatePatientInsuranceParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdatePatientInsuranceParams,
    APIResponse<PatientInsurance>,
    typeof patientService.updatePatientInsurance,
    AjaxOptions,
    APIResponseClass<PatientInsuranceClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updatePatientInsurance>) => (
      patientService.updatePatientInsurance(...args)
    ),
    ResponseClassCreator: createPatientUpdatePatientInsuranceObject,
    typeName: 'usePatientUpdatePatientInsurance',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdatePatientInsurance.mock = () => new Error('Mock has not been implemented!');
usePatientUpdatePatientInsurance.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdatePatientInsurance.createObject = createPatientUpdatePatientInsuranceObject;

export const createPatientGetPatientBaselineObject = (value: APIResponse<PatientBaseline>) => new APIResponseClass<PatientBaselineClass>(value, (value: PatientBaseline) => new PatientBaselineClass(value));

export const usePatientGetPatientBaseline = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetPatientBaselineParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetPatientBaselineParams,
    APIResponse<PatientBaseline>,
    typeof patientService.getPatientBaseline,
    GetOptions,
    APIResponseClass<PatientBaselineClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getPatientBaseline>) => (
      patientService.getPatientBaseline(...args)
    ),
    ResponseClassCreator: createPatientGetPatientBaselineObject,
    typeName: 'usePatientGetPatientBaseline',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetPatientBaseline.mock = () => new Error('Mock has not been implemented!');
usePatientGetPatientBaseline.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetPatientBaseline.createObject = createPatientGetPatientBaselineObject;

export const createPatientUpdatePatientBaselineObject = (value: APIResponse<PatientBaseline>) => new APIResponseClass<PatientBaselineClass>(value, (value: PatientBaseline) => new PatientBaselineClass(value));

export const usePatientUpdatePatientBaseline = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdatePatientBaselineParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdatePatientBaselineParams,
    APIResponse<PatientBaseline>,
    typeof patientService.updatePatientBaseline,
    AjaxOptions,
    APIResponseClass<PatientBaselineClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updatePatientBaseline>) => (
      patientService.updatePatientBaseline(...args)
    ),
    ResponseClassCreator: createPatientUpdatePatientBaselineObject,
    typeName: 'usePatientUpdatePatientBaseline',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdatePatientBaseline.mock = () => new Error('Mock has not been implemented!');
usePatientUpdatePatientBaseline.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdatePatientBaseline.createObject = createPatientUpdatePatientBaselineObject;

export const createPatientGetConsentFormObject = (value: APIResponse<PatientConsent>) => new APIResponseClass<PatientConsentClass>(value, (value: PatientConsent) => new PatientConsentClass(value));

export const usePatientGetConsentForm = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetConsentFormParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetConsentFormParams,
    APIResponse<PatientConsent>,
    typeof patientService.getConsentForm,
    GetOptions,
    APIResponseClass<PatientConsentClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getConsentForm>) => (
      patientService.getConsentForm(...args)
    ),
    ResponseClassCreator: createPatientGetConsentFormObject,
    typeName: 'usePatientGetConsentForm',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetConsentForm.mock = () => new Error('Mock has not been implemented!');
usePatientGetConsentForm.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetConsentForm.createObject = createPatientGetConsentFormObject;

export const createPatientPreviewConsentFormObject = (value: APIResponse<FileInfo>) => new APIResponseClass<FileInfoClass>(value, (value: FileInfo) => new FileInfoClass(value));

export const usePatientPreviewConsentForm = <RCN = undefined>(configs: MakeRequestHookConfig<PatientPreviewConsentFormParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientPreviewConsentFormParams,
    APIResponse<FileInfo>,
    typeof patientService.previewConsentForm,
    GetOptions,
    APIResponseClass<FileInfoClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.previewConsentForm>) => (
      patientService.previewConsentForm(...args)
    ),
    ResponseClassCreator: createPatientPreviewConsentFormObject,
    typeName: 'usePatientPreviewConsentForm',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientPreviewConsentForm.mock = () => new Error('Mock has not been implemented!');
usePatientPreviewConsentForm.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientPreviewConsentForm.createObject = createPatientPreviewConsentFormObject;

export const createPatientUpdateConsentFormObject = (value: APIResponse<PatientConsent>) => new APIResponseClass<PatientConsentClass>(value, (value: PatientConsent) => new PatientConsentClass(value));

export const usePatientUpdateConsentForm = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdateConsentFormParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdateConsentFormParams,
    APIResponse<PatientConsent>,
    typeof patientService.updateConsentForm,
    AjaxOptions,
    APIResponseClass<PatientConsentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updateConsentForm>) => (
      patientService.updateConsentForm(...args)
    ),
    ResponseClassCreator: createPatientUpdateConsentFormObject,
    typeName: 'usePatientUpdateConsentForm',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdateConsentForm.mock = () => new Error('Mock has not been implemented!');
usePatientUpdateConsentForm.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdateConsentForm.createObject = createPatientUpdateConsentFormObject;

export const createPatientGetEnrolledProgramsObject = (value: APIResponse<PatientEnrolledProgram>) => new APIResponseClass<PatientEnrolledProgramClass>(value, (value: PatientEnrolledProgram) => new PatientEnrolledProgramClass(value));

export const usePatientGetEnrolledPrograms = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetEnrolledProgramsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetEnrolledProgramsParams,
    APIResponse<PatientEnrolledProgram>,
    typeof patientService.getEnrolledPrograms,
    GetOptions,
    APIResponseClass<PatientEnrolledProgramClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getEnrolledPrograms>) => (
      patientService.getEnrolledPrograms(...args)
    ),
    ResponseClassCreator: createPatientGetEnrolledProgramsObject,
    typeName: 'usePatientGetEnrolledPrograms',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetEnrolledPrograms.mock = () => new Error('Mock has not been implemented!');
usePatientGetEnrolledPrograms.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetEnrolledPrograms.createObject = createPatientGetEnrolledProgramsObject;

export const createPatientGetPatientExerciseGoalObject = (value: APIResponse<MonitoringVital>) => new APIResponseClass<MonitoringVitalClass>(value, (value: MonitoringVital) => new MonitoringVitalClass(value));

export const usePatientGetPatientExerciseGoal = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetPatientExerciseGoalParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetPatientExerciseGoalParams,
    APIResponse<MonitoringVital>,
    typeof patientService.getPatientExerciseGoal,
    GetOptions,
    APIResponseClass<MonitoringVitalClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getPatientExerciseGoal>) => (
      patientService.getPatientExerciseGoal(...args)
    ),
    ResponseClassCreator: createPatientGetPatientExerciseGoalObject,
    typeName: 'usePatientGetPatientExerciseGoal',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetPatientExerciseGoal.mock = () => new Error('Mock has not been implemented!');
usePatientGetPatientExerciseGoal.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetPatientExerciseGoal.createObject = createPatientGetPatientExerciseGoalObject;

export const createPatientUpsertDiseaseHistoryObject = (value: APIResponse<DiseaseHistory>) => new APIResponseClass<DiseaseHistoryClass>(value, (value: DiseaseHistory) => new DiseaseHistoryClass(value));

export const usePatientUpsertDiseaseHistory = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpsertDiseaseHistoryParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpsertDiseaseHistoryParams,
    APIResponse<DiseaseHistory>,
    typeof patientService.upsertDiseaseHistory,
    AjaxOptions,
    APIResponseClass<DiseaseHistoryClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.upsertDiseaseHistory>) => (
      patientService.upsertDiseaseHistory(...args)
    ),
    ResponseClassCreator: createPatientUpsertDiseaseHistoryObject,
    typeName: 'usePatientUpsertDiseaseHistory',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpsertDiseaseHistory.mock = () => new Error('Mock has not been implemented!');
usePatientUpsertDiseaseHistory.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpsertDiseaseHistory.createObject = createPatientUpsertDiseaseHistoryObject;

export const createPatientGetDiseaseHistoryObject = (value: APIResponse<DiseaseHistory>) => new APIResponseClass<DiseaseHistoryClass>(value, (value: DiseaseHistory) => new DiseaseHistoryClass(value));

export const usePatientGetDiseaseHistory = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetDiseaseHistoryParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetDiseaseHistoryParams,
    APIResponse<DiseaseHistory>,
    typeof patientService.getDiseaseHistory,
    GetOptions,
    APIResponseClass<DiseaseHistoryClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getDiseaseHistory>) => (
      patientService.getDiseaseHistory(...args)
    ),
    ResponseClassCreator: createPatientGetDiseaseHistoryObject,
    typeName: 'usePatientGetDiseaseHistory',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetDiseaseHistory.mock = () => new Error('Mock has not been implemented!');
usePatientGetDiseaseHistory.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetDiseaseHistory.createObject = createPatientGetDiseaseHistoryObject;

export const createPatientGetClinicalGoalByIdObject = (value: APIResponse<ClinicalGoal>) => new APIResponseClass<ClinicalGoalClass>(value, (value: ClinicalGoal) => new ClinicalGoalClass(value));

export const usePatientGetClinicalGoalById = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetClinicalGoalByIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetClinicalGoalByIdParams,
    APIResponse<ClinicalGoal>,
    typeof patientService.getClinicalGoalById,
    GetOptions,
    APIResponseClass<ClinicalGoalClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getClinicalGoalById>) => (
      patientService.getClinicalGoalById(...args)
    ),
    ResponseClassCreator: createPatientGetClinicalGoalByIdObject,
    typeName: 'usePatientGetClinicalGoalById',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetClinicalGoalById.mock = () => new Error('Mock has not been implemented!');
usePatientGetClinicalGoalById.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetClinicalGoalById.createObject = createPatientGetClinicalGoalByIdObject;

export const createPatientGetCgmGoalByIdObject = (value: APIResponse<CgmGoal>) => new APIResponseClass<CgmGoalClass>(value, (value: CgmGoal) => new CgmGoalClass(value));

export const usePatientGetCgmGoalById = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetCgmGoalByIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetCgmGoalByIdParams,
    APIResponse<CgmGoal>,
    typeof patientService.getCgmGoalById,
    GetOptions,
    APIResponseClass<CgmGoalClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getCgmGoalById>) => (
      patientService.getCgmGoalById(...args)
    ),
    ResponseClassCreator: createPatientGetCgmGoalByIdObject,
    typeName: 'usePatientGetCgmGoalById',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetCgmGoalById.mock = () => new Error('Mock has not been implemented!');
usePatientGetCgmGoalById.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetCgmGoalById.createObject = createPatientGetCgmGoalByIdObject;

export const createPatientGetBehaviorGoalByIdObject = (value: APIResponse<BehaviorGoal>) => new APIResponseClass<BehaviorGoalClass>(value, (value: BehaviorGoal) => new BehaviorGoalClass(value));

export const usePatientGetBehaviorGoalById = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetBehaviorGoalByIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetBehaviorGoalByIdParams,
    APIResponse<BehaviorGoal>,
    typeof patientService.getBehaviorGoalById,
    GetOptions,
    APIResponseClass<BehaviorGoalClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getBehaviorGoalById>) => (
      patientService.getBehaviorGoalById(...args)
    ),
    ResponseClassCreator: createPatientGetBehaviorGoalByIdObject,
    typeName: 'usePatientGetBehaviorGoalById',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetBehaviorGoalById.mock = () => new Error('Mock has not been implemented!');
usePatientGetBehaviorGoalById.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetBehaviorGoalById.createObject = createPatientGetBehaviorGoalByIdObject;

export const createPatientGetEnrollmentObject = (value: APIResponse<EnrollmentRequest>) => new APIResponseClass<EnrollmentRequestClass>(value, (value: EnrollmentRequest) => new EnrollmentRequestClass(value));

export const usePatientGetEnrollment = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetEnrollmentParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetEnrollmentParams,
    APIResponse<EnrollmentRequest>,
    typeof patientService.getEnrollment,
    GetOptions,
    APIResponseClass<EnrollmentRequestClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getEnrollment>) => (
      patientService.getEnrollment(...args)
    ),
    ResponseClassCreator: createPatientGetEnrollmentObject,
    typeName: 'usePatientGetEnrollment',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetEnrollment.mock = () => new Error('Mock has not been implemented!');
usePatientGetEnrollment.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetEnrollment.createObject = createPatientGetEnrollmentObject;

export const createPatientUpdateEnrollmentObject = (value: APIResponse<EnrollmentRequest>) => new APIResponseClass<EnrollmentRequestClass>(value, (value: EnrollmentRequest) => new EnrollmentRequestClass(value));

export const usePatientUpdateEnrollment = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdateEnrollmentParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdateEnrollmentParams,
    APIResponse<EnrollmentRequest>,
    typeof patientService.updateEnrollment,
    AjaxOptions,
    APIResponseClass<EnrollmentRequestClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updateEnrollment>) => (
      patientService.updateEnrollment(...args)
    ),
    ResponseClassCreator: createPatientUpdateEnrollmentObject,
    typeName: 'usePatientUpdateEnrollment',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdateEnrollment.mock = () => new Error('Mock has not been implemented!');
usePatientUpdateEnrollment.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdateEnrollment.createObject = createPatientUpdateEnrollmentObject;

export const createPatientInsertPatientComplexityObject = (value: APIResponse<PatientComplexity>) => new APIResponseClass<PatientComplexityClass>(value, (value: PatientComplexity) => new PatientComplexityClass(value));

export const usePatientInsertPatientComplexity = <RCN = undefined>(configs: MakeRequestHookConfig<PatientInsertPatientComplexityParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientInsertPatientComplexityParams,
    APIResponse<PatientComplexity>,
    typeof patientService.insertPatientComplexity,
    AjaxOptions,
    APIResponseClass<PatientComplexityClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.insertPatientComplexity>) => (
      patientService.insertPatientComplexity(...args)
    ),
    ResponseClassCreator: createPatientInsertPatientComplexityObject,
    typeName: 'usePatientInsertPatientComplexity',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientInsertPatientComplexity.mock = () => new Error('Mock has not been implemented!');
usePatientInsertPatientComplexity.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsertPatientComplexity.createObject = createPatientInsertPatientComplexityObject;

export const createPatientSearchPatientComplexityObject = (value: APIResponse<PageResponse<PatientComplexity>>) => new APIResponseClass<PageResponseClass<PatientComplexityClass>>(value, (value: PageResponse<PatientComplexity>) => new PageResponseClass<PatientComplexityClass>(value, (value: PatientComplexity) => new PatientComplexityClass(value)));

export const usePatientSearchPatientComplexity = <RCN = undefined>(configs: MakeRequestHookConfig<PatientSearchPatientComplexityParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientSearchPatientComplexityParams,
    APIResponse<PageResponse<PatientComplexity>>,
    typeof patientService.searchPatientComplexity,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientComplexityClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.searchPatientComplexity>) => (
      patientService.searchPatientComplexity(...args)
    ),
    ResponseClassCreator: createPatientSearchPatientComplexityObject,
    typeName: 'usePatientSearchPatientComplexity',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientSearchPatientComplexity.mock = () => new Error('Mock has not been implemented!');
usePatientSearchPatientComplexity.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientSearchPatientComplexity.createObject = createPatientSearchPatientComplexityObject;

export const createPatientGetPatientControlLevelByIdObject = (value: APIResponse<PatientControlLevel>) => new APIResponseClass<PatientControlLevelClass>(value, (value: PatientControlLevel) => new PatientControlLevelClass(value));

export const usePatientGetPatientControlLevelById = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetPatientControlLevelByIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetPatientControlLevelByIdParams,
    APIResponse<PatientControlLevel>,
    typeof patientService.getPatientControlLevelById,
    GetOptions,
    APIResponseClass<PatientControlLevelClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getPatientControlLevelById>) => (
      patientService.getPatientControlLevelById(...args)
    ),
    ResponseClassCreator: createPatientGetPatientControlLevelByIdObject,
    typeName: 'usePatientGetPatientControlLevelById',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetPatientControlLevelById.mock = () => new Error('Mock has not been implemented!');
usePatientGetPatientControlLevelById.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetPatientControlLevelById.createObject = createPatientGetPatientControlLevelByIdObject;

export const createPatientUpdatePatientA1cGoalObject = (value: APIResponse<PatientControlLevel>) => new APIResponseClass<PatientControlLevelClass>(value, (value: PatientControlLevel) => new PatientControlLevelClass(value));

export const usePatientUpdatePatientA1cGoal = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdatePatientA1cGoalParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdatePatientA1cGoalParams,
    APIResponse<PatientControlLevel>,
    typeof patientService.updatePatientA1cGoal,
    AjaxOptions,
    APIResponseClass<PatientControlLevelClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updatePatientA1cGoal>) => (
      patientService.updatePatientA1cGoal(...args)
    ),
    ResponseClassCreator: createPatientUpdatePatientA1cGoalObject,
    typeName: 'usePatientUpdatePatientA1cGoal',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdatePatientA1cGoal.mock = () => new Error('Mock has not been implemented!');
usePatientUpdatePatientA1cGoal.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdatePatientA1cGoal.createObject = createPatientUpdatePatientA1cGoalObject;

export const createPatientUpdatePatientComplexityObject = (value: APIResponse<PatientComplexity>) => new APIResponseClass<PatientComplexityClass>(value, (value: PatientComplexity) => new PatientComplexityClass(value));

export const usePatientUpdatePatientComplexity = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdatePatientComplexityParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdatePatientComplexityParams,
    APIResponse<PatientComplexity>,
    typeof patientService.updatePatientComplexity,
    AjaxOptions,
    APIResponseClass<PatientComplexityClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updatePatientComplexity>) => (
      patientService.updatePatientComplexity(...args)
    ),
    ResponseClassCreator: createPatientUpdatePatientComplexityObject,
    typeName: 'usePatientUpdatePatientComplexity',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdatePatientComplexity.mock = () => new Error('Mock has not been implemented!');
usePatientUpdatePatientComplexity.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdatePatientComplexity.createObject = createPatientUpdatePatientComplexityObject;

export const createPatientInsertUcMedicationManagementObject = (value: APIResponse<UcMedicationManagement>) => new APIResponseClass<UcMedicationManagementClass>(value, (value: UcMedicationManagement) => new UcMedicationManagementClass(value));

export const usePatientInsertUcMedicationManagement = <RCN = undefined>(configs: MakeRequestHookConfig<PatientInsertUcMedicationManagementParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientInsertUcMedicationManagementParams,
    APIResponse<UcMedicationManagement>,
    typeof patientService.insertUcMedicationManagement,
    AjaxOptions,
    APIResponseClass<UcMedicationManagementClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.insertUcMedicationManagement>) => (
      patientService.insertUcMedicationManagement(...args)
    ),
    ResponseClassCreator: createPatientInsertUcMedicationManagementObject,
    typeName: 'usePatientInsertUcMedicationManagement',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientInsertUcMedicationManagement.mock = () => new Error('Mock has not been implemented!');
usePatientInsertUcMedicationManagement.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsertUcMedicationManagement.createObject = createPatientInsertUcMedicationManagementObject;

export const createPatientUpdateUcMedicationManagementObject = (value: APIResponse<UcMedicationManagement>) => new APIResponseClass<UcMedicationManagementClass>(value, (value: UcMedicationManagement) => new UcMedicationManagementClass(value));

export const usePatientUpdateUcMedicationManagement = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpdateUcMedicationManagementParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpdateUcMedicationManagementParams,
    APIResponse<UcMedicationManagement>,
    typeof patientService.updateUcMedicationManagement,
    AjaxOptions,
    APIResponseClass<UcMedicationManagementClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.updateUcMedicationManagement>) => (
      patientService.updateUcMedicationManagement(...args)
    ),
    ResponseClassCreator: createPatientUpdateUcMedicationManagementObject,
    typeName: 'usePatientUpdateUcMedicationManagement',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpdateUcMedicationManagement.mock = () => new Error('Mock has not been implemented!');
usePatientUpdateUcMedicationManagement.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpdateUcMedicationManagement.createObject = createPatientUpdateUcMedicationManagementObject;

export const createPatientGetUcMedicationManagementObject = (value: APIResponse<UcMedicationManagement>) => new APIResponseClass<UcMedicationManagementClass>(value, (value: UcMedicationManagement) => new UcMedicationManagementClass(value));

export const usePatientGetUcMedicationManagement = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetUcMedicationManagementParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetUcMedicationManagementParams,
    APIResponse<UcMedicationManagement>,
    typeof patientService.getUcMedicationManagement,
    GetOptions,
    APIResponseClass<UcMedicationManagementClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getUcMedicationManagement>) => (
      patientService.getUcMedicationManagement(...args)
    ),
    ResponseClassCreator: createPatientGetUcMedicationManagementObject,
    typeName: 'usePatientGetUcMedicationManagement',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetUcMedicationManagement.mock = () => new Error('Mock has not been implemented!');
usePatientGetUcMedicationManagement.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetUcMedicationManagement.createObject = createPatientGetUcMedicationManagementObject;

export const createPatientSearchUcMedicationManagementObject = (value: APIResponse<MedicationListSearchResponse>) => new APIResponseClass<MedicationListSearchResponseClass>(value, (value: MedicationListSearchResponse) => new MedicationListSearchResponseClass(value));

export const usePatientSearchUcMedicationManagement = <RCN = undefined>(configs: MakeRequestHookConfig<PatientSearchUcMedicationManagementParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientSearchUcMedicationManagementParams,
    APIResponse<MedicationListSearchResponse>,
    typeof patientService.searchUcMedicationManagement,
    AjaxOptions,
    APIResponseClass<MedicationListSearchResponseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.searchUcMedicationManagement>) => (
      patientService.searchUcMedicationManagement(...args)
    ),
    ResponseClassCreator: createPatientSearchUcMedicationManagementObject,
    typeName: 'usePatientSearchUcMedicationManagement',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientSearchUcMedicationManagement.mock = () => new Error('Mock has not been implemented!');
usePatientSearchUcMedicationManagement.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientSearchUcMedicationManagement.createObject = createPatientSearchUcMedicationManagementObject;

export const createPatientGetMonthlyReviewByMemberIdObject = (value: APIResponse<MonthlyReview[]>) => new APIResponseClass<MonthlyReviewClass[]>(value, (v) => map(v, (value: MonthlyReview) => new MonthlyReviewClass(value)));

export const usePatientGetMonthlyReviewByMemberId = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetMonthlyReviewByMemberIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetMonthlyReviewByMemberIdParams,
    APIResponse<MonthlyReview[]>,
    typeof patientService.getMonthlyReviewByMemberId,
    GetOptions,
    APIResponseClass<MonthlyReviewClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getMonthlyReviewByMemberId>) => (
      patientService.getMonthlyReviewByMemberId(...args)
    ),
    ResponseClassCreator: createPatientGetMonthlyReviewByMemberIdObject,
    typeName: 'usePatientGetMonthlyReviewByMemberId',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetMonthlyReviewByMemberId.mock = () => new Error('Mock has not been implemented!');
usePatientGetMonthlyReviewByMemberId.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetMonthlyReviewByMemberId.createObject = createPatientGetMonthlyReviewByMemberIdObject;

export const createPatientUpsertPatientExerciseGoalObject = (value: APIResponse<PatientEnrolledProgram>) => new APIResponseClass<PatientEnrolledProgramClass>(value, (value: PatientEnrolledProgram) => new PatientEnrolledProgramClass(value));

export const usePatientUpsertPatientExerciseGoal = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpsertPatientExerciseGoalParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpsertPatientExerciseGoalParams,
    APIResponse<PatientEnrolledProgram>,
    typeof patientService.upsertPatientExerciseGoal,
    AjaxOptions,
    APIResponseClass<PatientEnrolledProgramClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.upsertPatientExerciseGoal>) => (
      patientService.upsertPatientExerciseGoal(...args)
    ),
    ResponseClassCreator: createPatientUpsertPatientExerciseGoalObject,
    typeName: 'usePatientUpsertPatientExerciseGoal',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpsertPatientExerciseGoal.mock = () => new Error('Mock has not been implemented!');
usePatientUpsertPatientExerciseGoal.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpsertPatientExerciseGoal.createObject = createPatientUpsertPatientExerciseGoalObject;

export const createPatientUpsertPatientHiatusStatusObject = (value: APIResponse<PatientEnrolledProgram>) => new APIResponseClass<PatientEnrolledProgramClass>(value, (value: PatientEnrolledProgram) => new PatientEnrolledProgramClass(value));

export const usePatientUpsertPatientHiatusStatus = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUpsertPatientHiatusStatusParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUpsertPatientHiatusStatusParams,
    APIResponse<PatientEnrolledProgram>,
    typeof patientService.upsertPatientHiatusStatus,
    AjaxOptions,
    APIResponseClass<PatientEnrolledProgramClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.upsertPatientHiatusStatus>) => (
      patientService.upsertPatientHiatusStatus(...args)
    ),
    ResponseClassCreator: createPatientUpsertPatientHiatusStatusObject,
    typeName: 'usePatientUpsertPatientHiatusStatus',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUpsertPatientHiatusStatus.mock = () => new Error('Mock has not been implemented!');
usePatientUpsertPatientHiatusStatus.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUpsertPatientHiatusStatus.createObject = createPatientUpsertPatientHiatusStatusObject;

export const createPatientDischargeEnrolledProgramObject = (value: APIResponse<PatientEnrolledProgram>) => new APIResponseClass<PatientEnrolledProgramClass>(value, (value: PatientEnrolledProgram) => new PatientEnrolledProgramClass(value));

export const usePatientDischargeEnrolledProgram = <RCN = undefined>(configs: MakeRequestHookConfig<PatientDischargeEnrolledProgramParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientDischargeEnrolledProgramParams,
    APIResponse<PatientEnrolledProgram>,
    typeof patientService.dischargeEnrolledProgram,
    AjaxOptions,
    APIResponseClass<PatientEnrolledProgramClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.dischargeEnrolledProgram>) => (
      patientService.dischargeEnrolledProgram(...args)
    ),
    ResponseClassCreator: createPatientDischargeEnrolledProgramObject,
    typeName: 'usePatientDischargeEnrolledProgram',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientDischargeEnrolledProgram.mock = () => new Error('Mock has not been implemented!');
usePatientDischargeEnrolledProgram.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientDischargeEnrolledProgram.createObject = createPatientDischargeEnrolledProgramObject;

export const createPatientGetPatientRewardObject = (value: APIResponse<PatientReward>) => new APIResponseClass<PatientRewardClass>(value, (value: PatientReward) => new PatientRewardClass(value));

export const usePatientGetPatientReward = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetPatientRewardParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetPatientRewardParams,
    APIResponse<PatientReward>,
    typeof patientService.getPatientReward,
    GetOptions,
    APIResponseClass<PatientRewardClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientService.getPatientReward>) => (
      patientService.getPatientReward(...args)
    ),
    ResponseClassCreator: createPatientGetPatientRewardObject,
    typeName: 'usePatientGetPatientReward',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetPatientReward.mock = () => new Error('Mock has not been implemented!');
usePatientGetPatientReward.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetPatientReward.createObject = createPatientGetPatientRewardObject;

export const createPatientCheckPhoneNumberExistObject = (value: APIResponse<boolean>) => new APIResponseClass<boolean>(value, (v) => (v));

export const usePatientCheckPhoneNumberExist = <RCN = undefined>(configs: MakeRequestHookConfig<PatientCheckPhoneNumberExistParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientCheckPhoneNumberExistParams,
    APIResponse<boolean>,
    typeof patientService.checkPhoneNumberExist,
    AjaxOptions,
    APIResponseClass<boolean>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.checkPhoneNumberExist>) => (
      patientService.checkPhoneNumberExist(...args)
    ),
    ResponseClassCreator: createPatientCheckPhoneNumberExistObject,
    typeName: 'usePatientCheckPhoneNumberExist',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientCheckPhoneNumberExist.mock = () => new Error('Mock has not been implemented!');
usePatientCheckPhoneNumberExist.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientCheckPhoneNumberExist.createObject = createPatientCheckPhoneNumberExistObject;

export const createPatientTagListObject = (value: APIResponse<Tag[]>) => new APIResponseClass<TagClass[]>(value, (v) => map(v, (value: Tag) => new TagClass(value)));

export const usePatientTagList = <RCN = undefined>(configs: MakeRequestHookConfig<PatientTagListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientTagListParams,
    APIResponse<Tag[]>,
    typeof patientService.tagList,
    AjaxOptions,
    APIResponseClass<TagClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.tagList>) => (
      patientService.tagList(...args)
    ),
    ResponseClassCreator: createPatientTagListObject,
    typeName: 'usePatientTagList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientTagList.mock = () => new Error('Mock has not been implemented!');
usePatientTagList.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientTagList.createObject = createPatientTagListObject;

export const createPatientAssignPatientsObject = (value: APIResponse<Tag>) => new APIResponseClass<TagClass>(value, (value: Tag) => new TagClass(value));

export const usePatientAssignPatients = <RCN = undefined>(configs: MakeRequestHookConfig<PatientAssignPatientsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientAssignPatientsParams,
    APIResponse<Tag>,
    typeof patientService.assignPatients,
    AjaxOptions,
    APIResponseClass<TagClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.assignPatients>) => (
      patientService.assignPatients(...args)
    ),
    ResponseClassCreator: createPatientAssignPatientsObject,
    typeName: 'usePatientAssignPatients',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientAssignPatients.mock = () => new Error('Mock has not been implemented!');
usePatientAssignPatients.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientAssignPatients.createObject = createPatientAssignPatientsObject;

export const createPatientPatientLimitedListObject = (value: APIResponse<PageResponse<Patient>>) => new APIResponseClass<PageResponseClass<PatientClass>>(value, (value: PageResponse<Patient>) => new PageResponseClass<PatientClass>(value, (value: Patient) => new PatientClass(value)));

export const usePatientPatientLimitedList = <RCN = undefined>(configs: MakeRequestHookConfig<PatientPatientLimitedListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientPatientLimitedListParams,
    APIResponse<PageResponse<Patient>>,
    typeof patientService.patientLimitedList,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.patientLimitedList>) => (
      patientService.patientLimitedList(...args)
    ),
    ResponseClassCreator: createPatientPatientLimitedListObject,
    typeName: 'usePatientPatientLimitedList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientPatientLimitedList.mock = () => new Error('Mock has not been implemented!');
usePatientPatientLimitedList.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientPatientLimitedList.createObject = createPatientPatientLimitedListObject;

export const createPatientReAssignPatientRelatedObject = (value: APIResponse) => new APIResponseClass(value);

export const usePatientReAssignPatientRelated = <RCN = undefined>(configs: MakeRequestHookConfig<PatientReAssignPatientRelatedParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientReAssignPatientRelatedParams,
    APIResponse,
    typeof patientService.reAssignPatientRelated,
    AjaxOptions,
    APIResponseClass
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.reAssignPatientRelated>) => (
      patientService.reAssignPatientRelated(...args)
    ),
    ResponseClassCreator: createPatientReAssignPatientRelatedObject,
    typeName: 'usePatientReAssignPatientRelated',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientReAssignPatientRelated.mock = () => new Error('Mock has not been implemented!');
usePatientReAssignPatientRelated.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientReAssignPatientRelated.createObject = createPatientReAssignPatientRelatedObject;

export const createPatientGetPatientRecommendedCareProtocolObject = (value: APIResponse<PatientProtocolEnum>) => new APIResponseClass<PatientProtocolEnum>(value, (value: PatientProtocolEnum) => new PatientProtocolEnum(value));

export const usePatientGetPatientRecommendedCareProtocol = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetPatientRecommendedCareProtocolParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetPatientRecommendedCareProtocolParams,
    APIResponse<PatientProtocolEnum>,
    typeof patientService.getPatientRecommendedCareProtocol,
    AjaxOptions,
    APIResponseClass<PatientProtocolEnum>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.getPatientRecommendedCareProtocol>) => (
      patientService.getPatientRecommendedCareProtocol(...args)
    ),
    ResponseClassCreator: createPatientGetPatientRecommendedCareProtocolObject,
    typeName: 'usePatientGetPatientRecommendedCareProtocol',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetPatientRecommendedCareProtocol.mock = () => new Error('Mock has not been implemented!');
usePatientGetPatientRecommendedCareProtocol.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetPatientRecommendedCareProtocol.createObject = createPatientGetPatientRecommendedCareProtocolObject;

export const createPatientMiniPatientListObject = (value: APIResponse<MiniPersonResponse[]>) => new APIResponseClass<MiniPersonResponseClass[]>(value, (v) => map(v, (value: MiniPersonResponse) => new MiniPersonResponseClass(value)));

export const usePatientMiniPatientList = <RCN = undefined>(configs: MakeRequestHookConfig<PatientMiniPatientListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientMiniPatientListParams,
    APIResponse<MiniPersonResponse[]>,
    typeof patientService.miniPatientList,
    AjaxOptions,
    APIResponseClass<MiniPersonResponseClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.miniPatientList>) => (
      patientService.miniPatientList(...args)
    ),
    ResponseClassCreator: createPatientMiniPatientListObject,
    typeName: 'usePatientMiniPatientList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientMiniPatientList.mock = () => new Error('Mock has not been implemented!');
usePatientMiniPatientList.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientMiniPatientList.createObject = createPatientMiniPatientListObject;

export const createPatientGetIncompleteInsuranceObject = (value: APIResponse<PageResponse<IncompleteStatusPatient>>) => new APIResponseClass<PageResponseClass<IncompleteStatusPatientClass>>(value, (value: PageResponse<IncompleteStatusPatient>) => new PageResponseClass<IncompleteStatusPatientClass>(value, (value: IncompleteStatusPatient) => new IncompleteStatusPatientClass(value)));

export const usePatientGetIncompleteInsurance = <RCN = undefined>(configs: MakeRequestHookConfig<PatientGetIncompleteInsuranceParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientGetIncompleteInsuranceParams,
    APIResponse<PageResponse<IncompleteStatusPatient>>,
    typeof patientService.getIncompleteInsurance,
    AjaxOptions,
    APIResponseClass<PageResponseClass<IncompleteStatusPatientClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.getIncompleteInsurance>) => (
      patientService.getIncompleteInsurance(...args)
    ),
    ResponseClassCreator: createPatientGetIncompleteInsuranceObject,
    typeName: 'usePatientGetIncompleteInsurance',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientGetIncompleteInsurance.mock = () => new Error('Mock has not been implemented!');
usePatientGetIncompleteInsurance.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientGetIncompleteInsurance.createObject = createPatientGetIncompleteInsuranceObject;


export const createPatientInsertObject = (value: APIResponse<Patient>) => new APIResponseClass<PatientClass>(value, (value: Patient) => new PatientClass(value));
export const usePatientInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<Patient>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<Patient>,
    APIResponse<Patient>,
    // @ts-ignore
    typeof patientService.insert,
    AjaxOptions,
    APIResponseClass<PatientClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.insert>) => (
      patientService.insert(...args)
    ),
    ResponseClassCreator: createPatientInsertObject,
    typeName: 'usePatientInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientInsert.mock = () => new Error('Mock has not been implemented!');
usePatientInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsert.createObject = createPatientInsertObject;

export const createPatientDeleteObject = (value: APIResponse<Patient>) => new APIResponseClass<PatientClass>(value, (value: Patient) => new PatientClass(value));
export const usePatientDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<Patient>,
    // @ts-ignore
    typeof patientService.delete,
    AjaxOptions,
    APIResponseClass<PatientClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.delete>) => (
      patientService.delete(...args)
    ),
    ResponseClassCreator: createPatientDeleteObject,
    typeName: 'usePatientDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientDelete.mock = () => new Error('Mock has not been implemented!');
usePatientDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientDelete.createObject = createPatientDeleteObject;

export const createPatientSearchObject = (value: APIResponse<PageResponse<Patient>>) => new APIResponseClass<PageResponseClass<PatientClass>>(value, (value: PageResponse<Patient>) => new PageResponseClass<PatientClass>(value, (value: Patient) => new PatientClass(value)));
export const usePatientSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<Patient>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<Patient>,
    APIResponse<PageResponse<Patient>>,
    // @ts-ignore
    typeof patientService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientService.search>) => (
      patientService.search(...args)
    ),
    ResponseClassCreator: createPatientSearchObject,
    typeName: 'usePatientSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientSearch.mock = () => new Error('Mock has not been implemented!');
usePatientSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientSearch.createObject = createPatientSearchObject;

export interface PatientDeviceEntity {
	patient?: Nullable<Patient>;
	devices?: Nullable<AssignDeviceInfo[]>;
}
export class PatientDeviceEntityClass {
  protected readonly _value: PatientDeviceEntity;

	constructor(value: PatientDeviceEntity) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patient() {
    return this._value.patient !== undefined && this._value.patient !== null ? new PatientClass(this._value.patient) : this._value.patient;
  }

  get devices() {
    return this._value.devices !== undefined && this._value.devices !== null ? map(this._value.devices, (v) => new AssignDeviceInfoClass(v)) : this._value.devices;
  }

  get devicesWithDefault() {
    return this._value.devices || [];
  }
}
export interface PatientDeviceGetUserTypeParams {
	memberId: string;
}

export interface PatientDeviceGetAvailableDevicesByModelParams {
	searchRequest: SearchRequest<IotDeviceConfig>;
}

export interface PatientDeviceGetAvailableLoanDeviceParams {
	searchRequest: SearchRequest<LoanDevice>;
}

export interface PatientDeviceGetToBeRemovedDeviceParams {
	memberId: string;
	removedVitals: VitalEnumType[];
}

export interface PatientDeviceGetPatientDeviceListParams {
	medicalOrgIds: string[];
}

export class PatientDeviceService extends BaseController<PatientDevice, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/patient-device';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getUserType({ baseUrl, params, headers }: RequestOption<PatientDeviceGetUserTypeParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PatientTypeEnum>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/patient-type${getQueryStrings(queryList)}`, { headers });
	}

	public getAvailableDevicesByModel({ baseUrl, params, headers }: RequestOption<PatientDeviceGetAvailableDevicesByModelParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<IotDeviceConfig>>>(`${baseUrl || this.baseURL}${this.basePath}/available-devices${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getAvailableLoanDevice({ baseUrl, params, headers }: RequestOption<PatientDeviceGetAvailableLoanDeviceParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<LoanDevice>>>(`${baseUrl || this.baseURL}${this.basePath}/available-loan-devices${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getToBeRemovedDevice({ baseUrl, params, headers }: RequestOption<PatientDeviceGetToBeRemovedDeviceParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<AssignDeviceInfo[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/to-be-removed-device${getQueryStrings(queryList)}`, params.removedVitals, { headers });
	}

	public getPatientDeviceList({ baseUrl, params, headers }: RequestOption<PatientDeviceGetPatientDeviceListParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PatientDeviceEntity[]>>(`${baseUrl || this.baseURL}${this.basePath}/patient-device-list${getQueryStrings(queryList)}`, params.medicalOrgIds, { headers });
	}
}

export const patientDeviceService = new PatientDeviceService();

export const createPatientDeviceGetUserTypeObject = (value: APIResponse<PatientTypeEnum>) => new APIResponseClass<PatientTypeEnum>(value, (value: PatientTypeEnum) => new PatientTypeEnum(value));

export const usePatientDeviceGetUserType = <RCN = undefined>(configs: MakeRequestHookConfig<PatientDeviceGetUserTypeParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientDeviceGetUserTypeParams,
    APIResponse<PatientTypeEnum>,
    typeof patientDeviceService.getUserType,
    GetOptions,
    APIResponseClass<PatientTypeEnum>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientDeviceService.getUserType>) => (
      patientDeviceService.getUserType(...args)
    ),
    ResponseClassCreator: createPatientDeviceGetUserTypeObject,
    typeName: 'usePatientDeviceGetUserType',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientDeviceGetUserType.mock = () => new Error('Mock has not been implemented!');
usePatientDeviceGetUserType.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientDeviceGetUserType.createObject = createPatientDeviceGetUserTypeObject;

export const createPatientDeviceGetAvailableDevicesByModelObject = (value: APIResponse<PageResponse<IotDeviceConfig>>) => new APIResponseClass<PageResponseClass<IotDeviceConfigClass>>(value, (value: PageResponse<IotDeviceConfig>) => new PageResponseClass<IotDeviceConfigClass>(value, (value: IotDeviceConfig) => new IotDeviceConfigClass(value)));

export const usePatientDeviceGetAvailableDevicesByModel = <RCN = undefined>(configs: MakeRequestHookConfig<PatientDeviceGetAvailableDevicesByModelParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientDeviceGetAvailableDevicesByModelParams,
    APIResponse<PageResponse<IotDeviceConfig>>,
    typeof patientDeviceService.getAvailableDevicesByModel,
    AjaxOptions,
    APIResponseClass<PageResponseClass<IotDeviceConfigClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientDeviceService.getAvailableDevicesByModel>) => (
      patientDeviceService.getAvailableDevicesByModel(...args)
    ),
    ResponseClassCreator: createPatientDeviceGetAvailableDevicesByModelObject,
    typeName: 'usePatientDeviceGetAvailableDevicesByModel',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientDeviceGetAvailableDevicesByModel.mock = () => new Error('Mock has not been implemented!');
usePatientDeviceGetAvailableDevicesByModel.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientDeviceGetAvailableDevicesByModel.createObject = createPatientDeviceGetAvailableDevicesByModelObject;

export const createPatientDeviceGetAvailableLoanDeviceObject = (value: APIResponse<PageResponse<LoanDevice>>) => new APIResponseClass<PageResponseClass<LoanDeviceClass>>(value, (value: PageResponse<LoanDevice>) => new PageResponseClass<LoanDeviceClass>(value, (value: LoanDevice) => new LoanDeviceClass(value)));

export const usePatientDeviceGetAvailableLoanDevice = <RCN = undefined>(configs: MakeRequestHookConfig<PatientDeviceGetAvailableLoanDeviceParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientDeviceGetAvailableLoanDeviceParams,
    APIResponse<PageResponse<LoanDevice>>,
    typeof patientDeviceService.getAvailableLoanDevice,
    AjaxOptions,
    APIResponseClass<PageResponseClass<LoanDeviceClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientDeviceService.getAvailableLoanDevice>) => (
      patientDeviceService.getAvailableLoanDevice(...args)
    ),
    ResponseClassCreator: createPatientDeviceGetAvailableLoanDeviceObject,
    typeName: 'usePatientDeviceGetAvailableLoanDevice',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientDeviceGetAvailableLoanDevice.mock = () => new Error('Mock has not been implemented!');
usePatientDeviceGetAvailableLoanDevice.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientDeviceGetAvailableLoanDevice.createObject = createPatientDeviceGetAvailableLoanDeviceObject;

export const createPatientDeviceGetToBeRemovedDeviceObject = (value: APIResponse<AssignDeviceInfo[]>) => new APIResponseClass<AssignDeviceInfoClass[]>(value, (v) => map(v, (value: AssignDeviceInfo) => new AssignDeviceInfoClass(value)));

export const usePatientDeviceGetToBeRemovedDevice = <RCN = undefined>(configs: MakeRequestHookConfig<PatientDeviceGetToBeRemovedDeviceParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientDeviceGetToBeRemovedDeviceParams,
    APIResponse<AssignDeviceInfo[]>,
    typeof patientDeviceService.getToBeRemovedDevice,
    AjaxOptions,
    APIResponseClass<AssignDeviceInfoClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientDeviceService.getToBeRemovedDevice>) => (
      patientDeviceService.getToBeRemovedDevice(...args)
    ),
    ResponseClassCreator: createPatientDeviceGetToBeRemovedDeviceObject,
    typeName: 'usePatientDeviceGetToBeRemovedDevice',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientDeviceGetToBeRemovedDevice.mock = () => new Error('Mock has not been implemented!');
usePatientDeviceGetToBeRemovedDevice.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientDeviceGetToBeRemovedDevice.createObject = createPatientDeviceGetToBeRemovedDeviceObject;

export const createPatientDeviceGetPatientDeviceListObject = (value: APIResponse<PatientDeviceEntity[]>) => new APIResponseClass<PatientDeviceEntityClass[]>(value, (v) => map(v, (value: PatientDeviceEntity) => new PatientDeviceEntityClass(value)));

export const usePatientDeviceGetPatientDeviceList = <RCN = undefined>(configs: MakeRequestHookConfig<PatientDeviceGetPatientDeviceListParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientDeviceGetPatientDeviceListParams,
    APIResponse<PatientDeviceEntity[]>,
    typeof patientDeviceService.getPatientDeviceList,
    AjaxOptions,
    APIResponseClass<PatientDeviceEntityClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientDeviceService.getPatientDeviceList>) => (
      patientDeviceService.getPatientDeviceList(...args)
    ),
    ResponseClassCreator: createPatientDeviceGetPatientDeviceListObject,
    typeName: 'usePatientDeviceGetPatientDeviceList',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientDeviceGetPatientDeviceList.mock = () => new Error('Mock has not been implemented!');
usePatientDeviceGetPatientDeviceList.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientDeviceGetPatientDeviceList.createObject = createPatientDeviceGetPatientDeviceListObject;


export const createPatientDeviceGetObject = (value: APIResponse<PatientDevice>) => new APIResponseClass<PatientDeviceClass>(value, (value: PatientDevice) => new PatientDeviceClass(value));
export const usePatientDeviceGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<PatientDevice>,
    // @ts-ignore
    typeof patientDeviceService.get,
    GetOptions,
    APIResponseClass<PatientDeviceClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientDeviceService.get>) => (
      patientDeviceService.get(...args)
    ),
    ResponseClassCreator: createPatientDeviceGetObject,
    typeName: 'usePatientDeviceGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientDeviceGet.mock = () => new Error('Mock has not been implemented!');
usePatientDeviceGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientDeviceGet.createObject = createPatientDeviceGetObject;

export const createPatientDeviceUpdateObject = (value: APIResponse<PatientDevice>) => new APIResponseClass<PatientDeviceClass>(value, (value: PatientDevice) => new PatientDeviceClass(value));
export const usePatientDeviceUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, PatientDevice>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, PatientDevice>,
    APIResponse<PatientDevice>,
    // @ts-ignore
    typeof patientDeviceService.update,
    AjaxOptions,
    APIResponseClass<PatientDeviceClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientDeviceService.update>) => (
      patientDeviceService.update(...args)
    ),
    ResponseClassCreator: createPatientDeviceUpdateObject,
    typeName: 'usePatientDeviceUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientDeviceUpdate.mock = () => new Error('Mock has not been implemented!');
usePatientDeviceUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientDeviceUpdate.createObject = createPatientDeviceUpdateObject;

export const createPatientDeviceInsertObject = (value: APIResponse<PatientDevice>) => new APIResponseClass<PatientDeviceClass>(value, (value: PatientDevice) => new PatientDeviceClass(value));
export const usePatientDeviceInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<PatientDevice>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<PatientDevice>,
    APIResponse<PatientDevice>,
    // @ts-ignore
    typeof patientDeviceService.insert,
    AjaxOptions,
    APIResponseClass<PatientDeviceClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientDeviceService.insert>) => (
      patientDeviceService.insert(...args)
    ),
    ResponseClassCreator: createPatientDeviceInsertObject,
    typeName: 'usePatientDeviceInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientDeviceInsert.mock = () => new Error('Mock has not been implemented!');
usePatientDeviceInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientDeviceInsert.createObject = createPatientDeviceInsertObject;

export const createPatientDeviceDeleteObject = (value: APIResponse<PatientDevice>) => new APIResponseClass<PatientDeviceClass>(value, (value: PatientDevice) => new PatientDeviceClass(value));
export const usePatientDeviceDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<PatientDevice>,
    // @ts-ignore
    typeof patientDeviceService.delete,
    AjaxOptions,
    APIResponseClass<PatientDeviceClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientDeviceService.delete>) => (
      patientDeviceService.delete(...args)
    ),
    ResponseClassCreator: createPatientDeviceDeleteObject,
    typeName: 'usePatientDeviceDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientDeviceDelete.mock = () => new Error('Mock has not been implemented!');
usePatientDeviceDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientDeviceDelete.createObject = createPatientDeviceDeleteObject;

export const createPatientDeviceSearchObject = (value: APIResponse<PageResponse<PatientDevice>>) => new APIResponseClass<PageResponseClass<PatientDeviceClass>>(value, (value: PageResponse<PatientDevice>) => new PageResponseClass<PatientDeviceClass>(value, (value: PatientDevice) => new PatientDeviceClass(value)));
export const usePatientDeviceSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<PatientDevice>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<PatientDevice>,
    APIResponse<PageResponse<PatientDevice>>,
    // @ts-ignore
    typeof patientDeviceService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientDeviceClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientDeviceService.search>) => (
      patientDeviceService.search(...args)
    ),
    ResponseClassCreator: createPatientDeviceSearchObject,
    typeName: 'usePatientDeviceSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientDeviceSearch.mock = () => new Error('Mock has not been implemented!');
usePatientDeviceSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientDeviceSearch.createObject = createPatientDeviceSearchObject;

export enum CptCodeUnitEnum {
	THREE_MONTH_UNITS = 'THREE_MONTH_UNITS',
	SIX_MONTH_UNITS = 'SIX_MONTH_UNITS',
}
export interface PriorAuthCaseBase {
	status?: Nullable<PriorAuthCaseStatusEnum>;
	submissionDate?: Nullable<string>;
	approvalDate?: Nullable<string>;
	comment?: Nullable<string>;
	authorizationNumber?: Nullable<string>;
	cptCodeCategory?: Nullable<CptCodeUnitEnum>;
	cptCodes?: Nullable<{ [key: string]: number }>;
	authStartDate?: Nullable<string>;
	authExpiredDate?: Nullable<string>;
	deniedDate?: Nullable<string>;
	deniedReason?: Nullable<string>;
	localStartDate?: Nullable<string>;
	localExpiredDate?: Nullable<string>;
}
export interface PriorAuthCase extends PriorAuthCaseBase {
	needPriorAuth?: Nullable<boolean>;
}
export interface InsuranceInfoRequest extends InsuranceInfo {
	priorAuthCase?: Nullable<PriorAuthCase>;
}
export interface InsuranceInfoResponse extends InsuranceInfo {
	priorAuthCaseList?: Nullable<PriorAuthCase[]>;
	needPriorAuth?: Nullable<boolean>;
}
export interface InsuranceSubmitForm {
	memberId?: Nullable<string>;
	insuranceInfoRequests?: Nullable<InsuranceInfoRequest[]>;
	insuranceInfoResponses?: Nullable<InsuranceInfoResponse[]>;
	nonCovered?: Nullable<NonCovered>;
	markMedCareEligible?: Nullable<boolean>;
	note?: Nullable<string>;
}
export interface InsuranceResponse extends InsuranceSubmitForm {
	updatedBy?: Nullable<string>;
	updatedByUser?: Nullable<LimitedUser>;
	updatedAt?: Nullable<string>;
}
export class PriorAuthCaseBaseClass {
  protected readonly _value: PriorAuthCaseBase;

	constructor(value: PriorAuthCaseBase) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: PriorAuthCaseStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get submissionDate() {
    return (this._value.submissionDate !== undefined && this._value.submissionDate !== null) ? dayjs(this._value.submissionDate) : this._value.submissionDate;
  }

  get approvalDate() {
    return (this._value.approvalDate !== undefined && this._value.approvalDate !== null) ? dayjs(this._value.approvalDate) : this._value.approvalDate;
  }

  get comment() {
    return this._value.comment;
  }

  get commentWithDefault() {
    return this._value.comment || '';
  }

  get authorizationNumber() {
    return this._value.authorizationNumber;
  }

  get authorizationNumberWithDefault() {
    return this._value.authorizationNumber || '';
  }

  get cptCodeCategory() {
    return this._value.cptCodeCategory;
  }

  cptCodeCategoryIsIn(values: CptCodeUnitEnum[] = []) {
    return this._value.cptCodeCategory && values.includes(this._value.cptCodeCategory);
  }

  get cptCodes() {
    return this._value.cptCodes;
  }

  get cptCodesWithDefault() {
    return this._value.cptCodes || {};
  }

  get authStartDate() {
    return (this._value.authStartDate !== undefined && this._value.authStartDate !== null) ? dayjs(this._value.authStartDate) : this._value.authStartDate;
  }

  get authExpiredDate() {
    return (this._value.authExpiredDate !== undefined && this._value.authExpiredDate !== null) ? dayjs(this._value.authExpiredDate) : this._value.authExpiredDate;
  }

  get deniedDate() {
    return (this._value.deniedDate !== undefined && this._value.deniedDate !== null) ? dayjs(this._value.deniedDate) : this._value.deniedDate;
  }

  get deniedReason() {
    return this._value.deniedReason;
  }

  get deniedReasonWithDefault() {
    return this._value.deniedReason || '';
  }

  get localStartDate() {
    return this._value.localStartDate;
  }

  get localStartDateWithDefault() {
    return this._value.localStartDate || '';
  }

  get localExpiredDate() {
    return this._value.localExpiredDate;
  }

  get localExpiredDateWithDefault() {
    return this._value.localExpiredDate || '';
  }
}
export class PriorAuthCaseClass extends PriorAuthCaseBaseClass {
  protected readonly _value: PriorAuthCase;

	constructor(value: PriorAuthCase) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get needPriorAuth() {
    return this._value.needPriorAuth;
  }

  get needPriorAuthWithDefault() {
    return this._value.needPriorAuth || false;
  }
}
export class InsuranceInfoRequestClass extends InsuranceInfoClass {
  protected readonly _value: InsuranceInfoRequest;

	constructor(value: InsuranceInfoRequest) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get priorAuthCase() {
    return this._value.priorAuthCase !== undefined && this._value.priorAuthCase !== null ? new PriorAuthCaseClass(this._value.priorAuthCase) : this._value.priorAuthCase;
  }
}
export class InsuranceInfoResponseClass extends InsuranceInfoClass {
  protected readonly _value: InsuranceInfoResponse;

	constructor(value: InsuranceInfoResponse) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get priorAuthCaseList() {
    return this._value.priorAuthCaseList !== undefined && this._value.priorAuthCaseList !== null ? map(this._value.priorAuthCaseList, (v) => new PriorAuthCaseClass(v)) : this._value.priorAuthCaseList;
  }

  get priorAuthCaseListWithDefault() {
    return this._value.priorAuthCaseList || [];
  }

  get needPriorAuth() {
    return this._value.needPriorAuth;
  }

  get needPriorAuthWithDefault() {
    return this._value.needPriorAuth || false;
  }
}
export class InsuranceSubmitFormClass {
  protected readonly _value: InsuranceSubmitForm;

	constructor(value: InsuranceSubmitForm) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get memberIdWithDefault() {
    return this._value.memberId || '';
  }

  get insuranceInfoRequests() {
    return this._value.insuranceInfoRequests !== undefined && this._value.insuranceInfoRequests !== null ? map(this._value.insuranceInfoRequests, (v) => new InsuranceInfoRequestClass(v)) : this._value.insuranceInfoRequests;
  }

  get insuranceInfoRequestsWithDefault() {
    return this._value.insuranceInfoRequests || [];
  }

  get insuranceInfoResponses() {
    return this._value.insuranceInfoResponses !== undefined && this._value.insuranceInfoResponses !== null ? map(this._value.insuranceInfoResponses, (v) => new InsuranceInfoResponseClass(v)) : this._value.insuranceInfoResponses;
  }

  get insuranceInfoResponsesWithDefault() {
    return this._value.insuranceInfoResponses || [];
  }

  get nonCovered() {
    return this._value.nonCovered !== undefined && this._value.nonCovered !== null ? new NonCoveredClass(this._value.nonCovered) : this._value.nonCovered;
  }

  get markMedCareEligible() {
    return this._value.markMedCareEligible;
  }

  get markMedCareEligibleWithDefault() {
    return this._value.markMedCareEligible || false;
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }
}
export class InsuranceResponseClass extends InsuranceSubmitFormClass {
  protected readonly _value: InsuranceResponse;

	constructor(value: InsuranceResponse) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get updatedByUser() {
    return this._value.updatedByUser !== undefined && this._value.updatedByUser !== null ? new LimitedUserClass(this._value.updatedByUser) : this._value.updatedByUser;
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }
}
export interface PriorAuthCountResponse {
	needAttentionCount?: Nullable<number>;
	approvedCount?: Nullable<number>;
	needSubmitCount?: Nullable<number>;
	pendingResultCount?: Nullable<number>;
	deniedCount?: Nullable<number>;
}
export class PriorAuthCountResponseClass {
  protected readonly _value: PriorAuthCountResponse;

	constructor(value: PriorAuthCountResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get needAttentionCount() {
    return this._value.needAttentionCount;
  }

  get needAttentionCountWithDefault() {
    return this._value.needAttentionCount || 0;
  }

  get approvedCount() {
    return this._value.approvedCount;
  }

  get approvedCountWithDefault() {
    return this._value.approvedCount || 0;
  }

  get needSubmitCount() {
    return this._value.needSubmitCount;
  }

  get needSubmitCountWithDefault() {
    return this._value.needSubmitCount || 0;
  }

  get pendingResultCount() {
    return this._value.pendingResultCount;
  }

  get pendingResultCountWithDefault() {
    return this._value.pendingResultCount || 0;
  }

  get deniedCount() {
    return this._value.deniedCount;
  }

  get deniedCountWithDefault() {
    return this._value.deniedCount || 0;
  }
}
export interface PriorAuthListRequest {
	clinicId?: Nullable<string>;
	memberIds?: Nullable<string[]>;
	enrolledStatuses?: Nullable<EnrolledProgramStatusEnum[]>;
	status?: Nullable<PriorAuthCaseStatusEnum>;
	statusIn?: Nullable<InFilter<PriorAuthCaseStatusEnum>>;
}
export interface PriorAuthCaseRecord extends PriorAuthCase {
	memberId?: Nullable<string>;
	memberName?: Nullable<string>;
	enrolledStatus?: Nullable<EnrolledProgramStatusEnum>;
	primaryInsurance?: Nullable<InsuranceInfo>;
	statusUpdatedAt?: Nullable<string>;
	caseHistoryCount?: Nullable<number>;
	mrn?: Nullable<string>;
	gender?: Nullable<string>;
	dateOfBirth?: Nullable<string>;
	providerId?: Nullable<string>;
	latestCaseId?: Nullable<string>;
	providerName?: Nullable<string>;
	icdCodes?: Nullable<string[]>;
	insuranceId?: Nullable<string>;
}
export class PriorAuthCaseRecordClass extends PriorAuthCaseClass {
  protected readonly _value: PriorAuthCaseRecord;

	constructor(value: PriorAuthCaseRecord) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get memberIdWithDefault() {
    return this._value.memberId || '';
  }

  get memberName() {
    return this._value.memberName;
  }

  get memberNameWithDefault() {
    return this._value.memberName || '';
  }

  get enrolledStatus() {
    return this._value.enrolledStatus;
  }

  enrolledStatusIsIn(values: EnrolledProgramStatusEnum[] = []) {
    return this._value.enrolledStatus && values.includes(this._value.enrolledStatus);
  }

  get primaryInsurance() {
    return this._value.primaryInsurance !== undefined && this._value.primaryInsurance !== null ? new InsuranceInfoClass(this._value.primaryInsurance) : this._value.primaryInsurance;
  }

  get statusUpdatedAt() {
    return (this._value.statusUpdatedAt !== undefined && this._value.statusUpdatedAt !== null) ? dayjs(this._value.statusUpdatedAt) : this._value.statusUpdatedAt;
  }

  get caseHistoryCount() {
    return this._value.caseHistoryCount;
  }

  get caseHistoryCountWithDefault() {
    return this._value.caseHistoryCount || 0;
  }

  get mrn() {
    return this._value.mrn;
  }

  get mrnWithDefault() {
    return this._value.mrn || '';
  }

  get gender() {
    return this._value.gender;
  }

  get genderWithDefault() {
    return this._value.gender || '';
  }

  get dateOfBirth() {
    return this._value.dateOfBirth;
  }

  get dateOfBirthWithDefault() {
    return this._value.dateOfBirth || '';
  }

  get providerId() {
    return this._value.providerId;
  }

  get providerIdWithDefault() {
    return this._value.providerId || '';
  }

  get latestCaseId() {
    return this._value.latestCaseId;
  }

  get latestCaseIdWithDefault() {
    return this._value.latestCaseId || '';
  }

  get providerName() {
    return this._value.providerName;
  }

  get providerNameWithDefault() {
    return this._value.providerName || '';
  }

  get icdCodes() {
    return this._value.icdCodes;
  }

  get icdCodesWithDefault() {
    return this._value.icdCodes || [];
  }

  get insuranceId() {
    return this._value.insuranceId;
  }

  get insuranceIdWithDefault() {
    return this._value.insuranceId || '';
  }
}
export interface PriorAuthStatusCountRequest {
	clinicId?: Nullable<string>;
}
export interface PatientInsuranceUpdateParams {
	request: InsuranceSubmitForm;
}

export interface PatientInsuranceGetPriorAuthParams {
	memberId: string;
}

export interface PatientInsuranceRenewPriorAuthParams {
	memberId: string;
	primaryInsurance?: InsuranceInfo;
}

export interface PatientInsuranceCancelPriorAuthParams {
	memberId: string;
}

export interface PatientInsuranceUpdatePriorAuthParams {
	memberId: string;
	request: PriorAuthCase;
}

export interface PatientInsuranceGetActionRequiredCountParams {
}

export interface PatientInsuranceGetPriorAuthHistoryParams {
	memberId: string;
	insuranceId: string;
}

export interface PatientInsuranceGetPriorAuthCasesParams {
	request: SearchRequest<PriorAuthListRequest>;
}

export interface PatientInsuranceGetPriorAuthStatusCountsParams {
	request: PriorAuthStatusCountRequest;
}

export interface PatientInsuranceCloseIncompleteInsuranceParams {
	memberId: string;
	nonCoveredInfo: NonCovered;
}

export interface PatientInsuranceUpdateInsuranceNoteParams {
	memberId: string;
	note: string;
}

export class PatientInsuranceService extends BaseController<PatientInsurance, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/insurance';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public update({ baseUrl, params, headers }: RequestOption<PatientInsuranceUpdateParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<InsuranceResponse>>(`${baseUrl || this.baseURL}${this.basePath}${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getPriorAuth({ baseUrl, params, headers }: RequestOption<PatientInsuranceGetPriorAuthParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<InsuranceResponse>>(`${baseUrl || this.baseURL}${this.basePath}/all/${params.memberId}${getQueryStrings(queryList)}`, { headers });
	}

	public renewPriorAuth({ baseUrl, params, headers }: RequestOption<PatientInsuranceRenewPriorAuthParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<PriorAuthCase>>(`${baseUrl || this.baseURL}${this.basePath}/prior-auth/${params.memberId}/renew${getQueryStrings(queryList)}`, params.primaryInsurance, { headers });
	}

	public cancelPriorAuth({ baseUrl, params, headers }: RequestOption<PatientInsuranceCancelPriorAuthParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<PriorAuthCase>>(`${baseUrl || this.baseURL}${this.basePath}/prior-auth/${params.memberId}/cancel${getQueryStrings(queryList)}`, {}, { headers });
	}

	public updatePriorAuth({ baseUrl, params, headers }: RequestOption<PatientInsuranceUpdatePriorAuthParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<PriorAuthCase>>(`${baseUrl || this.baseURL}${this.basePath}/prior-auth/${params.memberId}${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getActionRequiredCount({ baseUrl, params, headers }: RequestOption<PatientInsuranceGetActionRequiredCountParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PriorAuthCountResponse>>(`${baseUrl || this.baseURL}${this.basePath}/prior-auth/action-required/count${getQueryStrings(queryList)}`, { headers });
	}

	public getPriorAuthHistory({ baseUrl, params, headers }: RequestOption<PatientInsuranceGetPriorAuthHistoryParams>) {
		const queryList: QueryParam[] = [];
		if (params.insuranceId !== undefined && params.insuranceId !== null) {
			queryList.push({ name: 'insuranceId', value: params.insuranceId.toString() });
		}
		return axios.get<APIResponse<PriorAuthCase[]>>(`${baseUrl || this.baseURL}${this.basePath}/prior-auth/${params.memberId}/history${getQueryStrings(queryList)}`, { headers });
	}

	public getPriorAuthCases({ baseUrl, params, headers }: RequestOption<PatientInsuranceGetPriorAuthCasesParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<PriorAuthCaseRecord>>>(`${baseUrl || this.baseURL}${this.basePath}/prior-auth/list${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getPriorAuthStatusCounts({ baseUrl, params, headers }: RequestOption<PatientInsuranceGetPriorAuthStatusCountsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PriorAuthCountResponse>>(`${baseUrl || this.baseURL}${this.basePath}/prior-auth/status/counts${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public closeIncompleteInsurance({ baseUrl, params, headers }: RequestOption<PatientInsuranceCloseIncompleteInsuranceParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/close-incomplete-insurance${getQueryStrings(queryList)}`, params.nonCoveredInfo, { headers });
	}

	public updateInsuranceNote({ baseUrl, params, headers }: RequestOption<PatientInsuranceUpdateInsuranceNoteParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/update-insurance-note${getQueryStrings(queryList)}`, params.note, { headers });
	}
}

export const patientInsuranceService = new PatientInsuranceService();

export const createPatientInsuranceUpdateObject = (value: APIResponse<InsuranceResponse>) => new APIResponseClass<InsuranceResponseClass>(value, (value: InsuranceResponse) => new InsuranceResponseClass(value));

export const usePatientInsuranceUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<PatientInsuranceUpdateParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientInsuranceUpdateParams,
    APIResponse<InsuranceResponse>,
    typeof patientInsuranceService.update,
    AjaxOptions,
    APIResponseClass<InsuranceResponseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientInsuranceService.update>) => (
      patientInsuranceService.update(...args)
    ),
    ResponseClassCreator: createPatientInsuranceUpdateObject,
    typeName: 'usePatientInsuranceUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientInsuranceUpdate.mock = () => new Error('Mock has not been implemented!');
usePatientInsuranceUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsuranceUpdate.createObject = createPatientInsuranceUpdateObject;

export const createPatientInsuranceGetPriorAuthObject = (value: APIResponse<InsuranceResponse>) => new APIResponseClass<InsuranceResponseClass>(value, (value: InsuranceResponse) => new InsuranceResponseClass(value));

export const usePatientInsuranceGetPriorAuth = <RCN = undefined>(configs: MakeRequestHookConfig<PatientInsuranceGetPriorAuthParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientInsuranceGetPriorAuthParams,
    APIResponse<InsuranceResponse>,
    typeof patientInsuranceService.getPriorAuth,
    GetOptions,
    APIResponseClass<InsuranceResponseClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientInsuranceService.getPriorAuth>) => (
      patientInsuranceService.getPriorAuth(...args)
    ),
    ResponseClassCreator: createPatientInsuranceGetPriorAuthObject,
    typeName: 'usePatientInsuranceGetPriorAuth',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientInsuranceGetPriorAuth.mock = () => new Error('Mock has not been implemented!');
usePatientInsuranceGetPriorAuth.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsuranceGetPriorAuth.createObject = createPatientInsuranceGetPriorAuthObject;

export const createPatientInsuranceRenewPriorAuthObject = (value: APIResponse<PriorAuthCase>) => new APIResponseClass<PriorAuthCaseClass>(value, (value: PriorAuthCase) => new PriorAuthCaseClass(value));

export const usePatientInsuranceRenewPriorAuth = <RCN = undefined>(configs: MakeRequestHookConfig<PatientInsuranceRenewPriorAuthParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientInsuranceRenewPriorAuthParams,
    APIResponse<PriorAuthCase>,
    typeof patientInsuranceService.renewPriorAuth,
    AjaxOptions,
    APIResponseClass<PriorAuthCaseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientInsuranceService.renewPriorAuth>) => (
      patientInsuranceService.renewPriorAuth(...args)
    ),
    ResponseClassCreator: createPatientInsuranceRenewPriorAuthObject,
    typeName: 'usePatientInsuranceRenewPriorAuth',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientInsuranceRenewPriorAuth.mock = () => new Error('Mock has not been implemented!');
usePatientInsuranceRenewPriorAuth.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsuranceRenewPriorAuth.createObject = createPatientInsuranceRenewPriorAuthObject;

export const createPatientInsuranceCancelPriorAuthObject = (value: APIResponse<PriorAuthCase>) => new APIResponseClass<PriorAuthCaseClass>(value, (value: PriorAuthCase) => new PriorAuthCaseClass(value));

export const usePatientInsuranceCancelPriorAuth = <RCN = undefined>(configs: MakeRequestHookConfig<PatientInsuranceCancelPriorAuthParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientInsuranceCancelPriorAuthParams,
    APIResponse<PriorAuthCase>,
    typeof patientInsuranceService.cancelPriorAuth,
    AjaxOptions,
    APIResponseClass<PriorAuthCaseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientInsuranceService.cancelPriorAuth>) => (
      patientInsuranceService.cancelPriorAuth(...args)
    ),
    ResponseClassCreator: createPatientInsuranceCancelPriorAuthObject,
    typeName: 'usePatientInsuranceCancelPriorAuth',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientInsuranceCancelPriorAuth.mock = () => new Error('Mock has not been implemented!');
usePatientInsuranceCancelPriorAuth.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsuranceCancelPriorAuth.createObject = createPatientInsuranceCancelPriorAuthObject;

export const createPatientInsuranceUpdatePriorAuthObject = (value: APIResponse<PriorAuthCase>) => new APIResponseClass<PriorAuthCaseClass>(value, (value: PriorAuthCase) => new PriorAuthCaseClass(value));

export const usePatientInsuranceUpdatePriorAuth = <RCN = undefined>(configs: MakeRequestHookConfig<PatientInsuranceUpdatePriorAuthParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientInsuranceUpdatePriorAuthParams,
    APIResponse<PriorAuthCase>,
    typeof patientInsuranceService.updatePriorAuth,
    AjaxOptions,
    APIResponseClass<PriorAuthCaseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientInsuranceService.updatePriorAuth>) => (
      patientInsuranceService.updatePriorAuth(...args)
    ),
    ResponseClassCreator: createPatientInsuranceUpdatePriorAuthObject,
    typeName: 'usePatientInsuranceUpdatePriorAuth',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientInsuranceUpdatePriorAuth.mock = () => new Error('Mock has not been implemented!');
usePatientInsuranceUpdatePriorAuth.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsuranceUpdatePriorAuth.createObject = createPatientInsuranceUpdatePriorAuthObject;

export const createPatientInsuranceGetActionRequiredCountObject = (value: APIResponse<PriorAuthCountResponse>) => new APIResponseClass<PriorAuthCountResponseClass>(value, (value: PriorAuthCountResponse) => new PriorAuthCountResponseClass(value));

export const usePatientInsuranceGetActionRequiredCount = <RCN = undefined>(configs: MakeRequestHookConfig<PatientInsuranceGetActionRequiredCountParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientInsuranceGetActionRequiredCountParams,
    APIResponse<PriorAuthCountResponse>,
    typeof patientInsuranceService.getActionRequiredCount,
    GetOptions,
    APIResponseClass<PriorAuthCountResponseClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientInsuranceService.getActionRequiredCount>) => (
      patientInsuranceService.getActionRequiredCount(...args)
    ),
    ResponseClassCreator: createPatientInsuranceGetActionRequiredCountObject,
    typeName: 'usePatientInsuranceGetActionRequiredCount',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientInsuranceGetActionRequiredCount.mock = () => new Error('Mock has not been implemented!');
usePatientInsuranceGetActionRequiredCount.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsuranceGetActionRequiredCount.createObject = createPatientInsuranceGetActionRequiredCountObject;

export const createPatientInsuranceGetPriorAuthHistoryObject = (value: APIResponse<PriorAuthCase[]>) => new APIResponseClass<PriorAuthCaseClass[]>(value, (v) => map(v, (value: PriorAuthCase) => new PriorAuthCaseClass(value)));

export const usePatientInsuranceGetPriorAuthHistory = <RCN = undefined>(configs: MakeRequestHookConfig<PatientInsuranceGetPriorAuthHistoryParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientInsuranceGetPriorAuthHistoryParams,
    APIResponse<PriorAuthCase[]>,
    typeof patientInsuranceService.getPriorAuthHistory,
    GetOptions,
    APIResponseClass<PriorAuthCaseClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientInsuranceService.getPriorAuthHistory>) => (
      patientInsuranceService.getPriorAuthHistory(...args)
    ),
    ResponseClassCreator: createPatientInsuranceGetPriorAuthHistoryObject,
    typeName: 'usePatientInsuranceGetPriorAuthHistory',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientInsuranceGetPriorAuthHistory.mock = () => new Error('Mock has not been implemented!');
usePatientInsuranceGetPriorAuthHistory.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsuranceGetPriorAuthHistory.createObject = createPatientInsuranceGetPriorAuthHistoryObject;

export const createPatientInsuranceGetPriorAuthCasesObject = (value: APIResponse<PageResponse<PriorAuthCaseRecord>>) => new APIResponseClass<PageResponseClass<PriorAuthCaseRecordClass>>(value, (value: PageResponse<PriorAuthCaseRecord>) => new PageResponseClass<PriorAuthCaseRecordClass>(value, (value: PriorAuthCaseRecord) => new PriorAuthCaseRecordClass(value)));

export const usePatientInsuranceGetPriorAuthCases = <RCN = undefined>(configs: MakeRequestHookConfig<PatientInsuranceGetPriorAuthCasesParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientInsuranceGetPriorAuthCasesParams,
    APIResponse<PageResponse<PriorAuthCaseRecord>>,
    typeof patientInsuranceService.getPriorAuthCases,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PriorAuthCaseRecordClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientInsuranceService.getPriorAuthCases>) => (
      patientInsuranceService.getPriorAuthCases(...args)
    ),
    ResponseClassCreator: createPatientInsuranceGetPriorAuthCasesObject,
    typeName: 'usePatientInsuranceGetPriorAuthCases',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientInsuranceGetPriorAuthCases.mock = () => new Error('Mock has not been implemented!');
usePatientInsuranceGetPriorAuthCases.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsuranceGetPriorAuthCases.createObject = createPatientInsuranceGetPriorAuthCasesObject;

export const createPatientInsuranceGetPriorAuthStatusCountsObject = (value: APIResponse<PriorAuthCountResponse>) => new APIResponseClass<PriorAuthCountResponseClass>(value, (value: PriorAuthCountResponse) => new PriorAuthCountResponseClass(value));

export const usePatientInsuranceGetPriorAuthStatusCounts = <RCN = undefined>(configs: MakeRequestHookConfig<PatientInsuranceGetPriorAuthStatusCountsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientInsuranceGetPriorAuthStatusCountsParams,
    APIResponse<PriorAuthCountResponse>,
    typeof patientInsuranceService.getPriorAuthStatusCounts,
    AjaxOptions,
    APIResponseClass<PriorAuthCountResponseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientInsuranceService.getPriorAuthStatusCounts>) => (
      patientInsuranceService.getPriorAuthStatusCounts(...args)
    ),
    ResponseClassCreator: createPatientInsuranceGetPriorAuthStatusCountsObject,
    typeName: 'usePatientInsuranceGetPriorAuthStatusCounts',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientInsuranceGetPriorAuthStatusCounts.mock = () => new Error('Mock has not been implemented!');
usePatientInsuranceGetPriorAuthStatusCounts.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsuranceGetPriorAuthStatusCounts.createObject = createPatientInsuranceGetPriorAuthStatusCountsObject;

export const createPatientInsuranceCloseIncompleteInsuranceObject = (value: APIResponse) => new APIResponseClass(value);

export const usePatientInsuranceCloseIncompleteInsurance = <RCN = undefined>(configs: MakeRequestHookConfig<PatientInsuranceCloseIncompleteInsuranceParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientInsuranceCloseIncompleteInsuranceParams,
    APIResponse,
    typeof patientInsuranceService.closeIncompleteInsurance,
    AjaxOptions,
    APIResponseClass
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientInsuranceService.closeIncompleteInsurance>) => (
      patientInsuranceService.closeIncompleteInsurance(...args)
    ),
    ResponseClassCreator: createPatientInsuranceCloseIncompleteInsuranceObject,
    typeName: 'usePatientInsuranceCloseIncompleteInsurance',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientInsuranceCloseIncompleteInsurance.mock = () => new Error('Mock has not been implemented!');
usePatientInsuranceCloseIncompleteInsurance.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsuranceCloseIncompleteInsurance.createObject = createPatientInsuranceCloseIncompleteInsuranceObject;

export const createPatientInsuranceUpdateInsuranceNoteObject = (value: APIResponse) => new APIResponseClass(value);

export const usePatientInsuranceUpdateInsuranceNote = <RCN = undefined>(configs: MakeRequestHookConfig<PatientInsuranceUpdateInsuranceNoteParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientInsuranceUpdateInsuranceNoteParams,
    APIResponse,
    typeof patientInsuranceService.updateInsuranceNote,
    AjaxOptions,
    APIResponseClass
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientInsuranceService.updateInsuranceNote>) => (
      patientInsuranceService.updateInsuranceNote(...args)
    ),
    ResponseClassCreator: createPatientInsuranceUpdateInsuranceNoteObject,
    typeName: 'usePatientInsuranceUpdateInsuranceNote',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientInsuranceUpdateInsuranceNote.mock = () => new Error('Mock has not been implemented!');
usePatientInsuranceUpdateInsuranceNote.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsuranceUpdateInsuranceNote.createObject = createPatientInsuranceUpdateInsuranceNoteObject;


export const createPatientInsuranceGetObject = (value: APIResponse<PatientInsurance>) => new APIResponseClass<PatientInsuranceClass>(value, (value: PatientInsurance) => new PatientInsuranceClass(value));
export const usePatientInsuranceGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<PatientInsurance>,
    // @ts-ignore
    typeof patientInsuranceService.get,
    GetOptions,
    APIResponseClass<PatientInsuranceClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientInsuranceService.get>) => (
      patientInsuranceService.get(...args)
    ),
    ResponseClassCreator: createPatientInsuranceGetObject,
    typeName: 'usePatientInsuranceGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientInsuranceGet.mock = () => new Error('Mock has not been implemented!');
usePatientInsuranceGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsuranceGet.createObject = createPatientInsuranceGetObject;

export const createPatientInsuranceInsertObject = (value: APIResponse<PatientInsurance>) => new APIResponseClass<PatientInsuranceClass>(value, (value: PatientInsurance) => new PatientInsuranceClass(value));
export const usePatientInsuranceInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<PatientInsurance>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<PatientInsurance>,
    APIResponse<PatientInsurance>,
    // @ts-ignore
    typeof patientInsuranceService.insert,
    AjaxOptions,
    APIResponseClass<PatientInsuranceClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientInsuranceService.insert>) => (
      patientInsuranceService.insert(...args)
    ),
    ResponseClassCreator: createPatientInsuranceInsertObject,
    typeName: 'usePatientInsuranceInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientInsuranceInsert.mock = () => new Error('Mock has not been implemented!');
usePatientInsuranceInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsuranceInsert.createObject = createPatientInsuranceInsertObject;

export const createPatientInsuranceDeleteObject = (value: APIResponse<PatientInsurance>) => new APIResponseClass<PatientInsuranceClass>(value, (value: PatientInsurance) => new PatientInsuranceClass(value));
export const usePatientInsuranceDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<PatientInsurance>,
    // @ts-ignore
    typeof patientInsuranceService.delete,
    AjaxOptions,
    APIResponseClass<PatientInsuranceClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientInsuranceService.delete>) => (
      patientInsuranceService.delete(...args)
    ),
    ResponseClassCreator: createPatientInsuranceDeleteObject,
    typeName: 'usePatientInsuranceDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientInsuranceDelete.mock = () => new Error('Mock has not been implemented!');
usePatientInsuranceDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsuranceDelete.createObject = createPatientInsuranceDeleteObject;

export const createPatientInsuranceSearchObject = (value: APIResponse<PageResponse<PatientInsurance>>) => new APIResponseClass<PageResponseClass<PatientInsuranceClass>>(value, (value: PageResponse<PatientInsurance>) => new PageResponseClass<PatientInsuranceClass>(value, (value: PatientInsurance) => new PatientInsuranceClass(value)));
export const usePatientInsuranceSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<PatientInsurance>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<PatientInsurance>,
    APIResponse<PageResponse<PatientInsurance>>,
    // @ts-ignore
    typeof patientInsuranceService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientInsuranceClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientInsuranceService.search>) => (
      patientInsuranceService.search(...args)
    ),
    ResponseClassCreator: createPatientInsuranceSearchObject,
    typeName: 'usePatientInsuranceSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientInsuranceSearch.mock = () => new Error('Mock has not been implemented!');
usePatientInsuranceSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientInsuranceSearch.createObject = createPatientInsuranceSearchObject;

export enum PatientTaskAssigneeTypeEnum {
	ASSIGNED_CA = 'ASSIGNED_CA',
	ASSIGNED_RD = 'ASSIGNED_RD',
	ASSIGNED_HC = 'ASSIGNED_HC',
	ASSIGNED_TEAM = 'ASSIGNED_TEAM',
}
export enum PatientTaskTypeEnum {
	ENROLLMENT = 'ENROLLMENT',
	TRANSCRIBE = 'TRANSCRIBE',
	VISIT = 'VISIT',
	COMPLIANCE_ALERT = 'COMPLIANCE_ALERT',
	MEDICAL_ALERT = 'MEDICAL_ALERT',
	FOOD_LOG = 'FOOD_LOG',
	ONBOARDING_TODO = 'ONBOARDING_TODO',
	MONTHLY_REVIEW = 'MONTHLY_REVIEW',
	TASK = 'TASK',
	MESSAGE = 'MESSAGE',
}
export enum PatientTaskNameEnum {
	VISIT = 'VISIT',
	NEW_PATIENT_ENGAGEMENT_1_DAY = 'NEW_PATIENT_ENGAGEMENT_1_DAY',
	NEW_PATIENT_ENGAGEMENT_14_DAY = 'NEW_PATIENT_ENGAGEMENT_14_DAY',
	NEW_PATIENT_ENGAGEMENT = 'NEW_PATIENT_ENGAGEMENT',
	VISIT_DUE_FOLLOW_UP = 'VISIT_DUE_FOLLOW_UP',
	VISIT_DUE_INIT = 'VISIT_DUE_INIT',
	VISIT_DUE_TECH_ONBOARDING = 'VISIT_DUE_TECH_ONBOARDING',
	A1C_DUE = 'A1C_DUE',
	INACTIVE_7_DAY = 'INACTIVE_7_DAY',
	INACTIVE_14_DAY = 'INACTIVE_14_DAY',
	DEVICE_PENDING = 'DEVICE_PENDING',
	NO_VISIT_SCHEDULED = 'NO_VISIT_SCHEDULED',
	MTPR = 'MTPR',
	A1C_TRANSCRIBE = 'A1C_TRANSCRIBE',
	INIT_TRANSCRIBE = 'INIT_TRANSCRIBE',
	FOLLOW_UP_TRANSCRIBE = 'FOLLOW_UP_TRANSCRIBE',
	VISIT_TRANSCRIBE = 'VISIT_TRANSCRIBE',
	TASK = 'TASK',
	MEDICAL_ALERT = 'MEDICAL_ALERT',
	FOOD_LOG = 'FOOD_LOG',
	MESSAGE = 'MESSAGE',
	VISIT_INCOMPLETE = 'VISIT_INCOMPLETE',
	TODAY_VISIT = 'TODAY_VISIT',
	A1C_COLLECT = 'A1C_COLLECT',
	NON_APP_ENGAGEMENT = 'NON_APP_ENGAGEMENT',
	ENROLLMENT_ENGAGEMENT_14_DAY = 'ENROLLMENT_ENGAGEMENT_14_DAY',
	VISIT_DUE = 'VISIT_DUE',
	UNMATCHED = 'UNMATCHED',
	COMPLIANCE_ALERTS = 'COMPLIANCE_ALERTS',
}
export enum PatientTaskStatus {
	TODO = 'TODO',
	DONE = 'DONE',
	MUTE = 'MUTE',
	SILENCED = 'SILENCED',
}
export interface PatientTask extends BaseModel {
	patientId?: Nullable<string>;
	assigneeType?: Nullable<PatientTaskAssigneeTypeEnum[]>;
	type?: Nullable<PatientTaskTypeEnum>;
	name?: Nullable<PatientTaskNameEnum>;
	status?: Nullable<PatientTaskStatus>;
	dueDate?: Nullable<string>;
	priority?: Nullable<PriorityLevelEnum>;
	priorityLevel?: Nullable<number>;
	referenceId?: Nullable<string>;
	finishedTime?: Nullable<string>;
	patientIdIn?: Nullable<InFilter<string>>;
	nameIn?: Nullable<InFilter<PatientTaskNameEnum>>;
	typeIn?: Nullable<InFilter<PatientTaskTypeEnum>>;
	dueDateRange?: Nullable<DateRangeFilter>;
	referenceIdIn?: Nullable<InFilter<string>>;
}
export class PatientTaskClass extends BaseModelClass {
  protected readonly _value: PatientTask;

	constructor(value: PatientTask) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientId() {
    return this._value.patientId;
  }

  get assigneeType() {
    return this._value.assigneeType;
  }

  get assigneeTypeWithDefault() {
    return this._value.assigneeType || [];
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: PatientTaskTypeEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get name() {
    return this._value.name;
  }

  nameIsIn(values: PatientTaskNameEnum[] = []) {
    return this._value.name && values.includes(this._value.name);
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: PatientTaskStatus[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get dueDate() {
    return (this._value.dueDate !== undefined && this._value.dueDate !== null) ? dayjs(this._value.dueDate) : this._value.dueDate;
  }

  get priority() {
    return this._value.priority;
  }

  priorityIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.priority && values.includes(this._value.priority);
  }

  get priorityLevel() {
    return this._value.priorityLevel;
  }

  get priorityLevelWithDefault() {
    return this._value.priorityLevel || 0;
  }

  get referenceId() {
    return this._value.referenceId;
  }

  get referenceIdWithDefault() {
    return this._value.referenceId || '';
  }

  get finishedTime() {
    return (this._value.finishedTime !== undefined && this._value.finishedTime !== null) ? dayjs(this._value.finishedTime) : this._value.finishedTime;
  }

  get patientIdIn() {
    return this._value.patientIdIn !== undefined && this._value.patientIdIn !== null ? new InFilterClass(this._value.patientIdIn) : this._value.patientIdIn;
  }

  get nameIn() {
    return this._value.nameIn !== undefined && this._value.nameIn !== null ? new InFilterClass(this._value.nameIn) : this._value.nameIn;
  }

  get typeIn() {
    return this._value.typeIn !== undefined && this._value.typeIn !== null ? new InFilterClass(this._value.typeIn) : this._value.typeIn;
  }

  get dueDateRange() {
    return this._value.dueDateRange !== undefined && this._value.dueDateRange !== null ? new DateRangeFilterClass(this._value.dueDateRange) : this._value.dueDateRange;
  }

  get referenceIdIn() {
    return this._value.referenceIdIn !== undefined && this._value.referenceIdIn !== null ? new InFilterClass(this._value.referenceIdIn) : this._value.referenceIdIn;
  }
}
export interface PatientTaskResolveMessageParams {
	memberId: string;
}

export class PatientTaskService extends BaseController<PatientTask, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/outstanding';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public resolveMessage({ baseUrl, params, headers }: RequestOption<PatientTaskResolveMessageParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.memberId}/resolve-message${getQueryStrings(queryList)}`, {}, { headers });
	}
}

export const patientTaskService = new PatientTaskService();

export const createPatientTaskResolveMessageObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const usePatientTaskResolveMessage = <RCN = undefined>(configs: MakeRequestHookConfig<PatientTaskResolveMessageParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientTaskResolveMessageParams,
    APIResponse<string>,
    typeof patientTaskService.resolveMessage,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientTaskService.resolveMessage>) => (
      patientTaskService.resolveMessage(...args)
    ),
    ResponseClassCreator: createPatientTaskResolveMessageObject,
    typeName: 'usePatientTaskResolveMessage',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientTaskResolveMessage.mock = () => new Error('Mock has not been implemented!');
usePatientTaskResolveMessage.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientTaskResolveMessage.createObject = createPatientTaskResolveMessageObject;


export const createPatientTaskGetObject = (value: APIResponse<PatientTask>) => new APIResponseClass<PatientTaskClass>(value, (value: PatientTask) => new PatientTaskClass(value));
export const usePatientTaskGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<PatientTask>,
    // @ts-ignore
    typeof patientTaskService.get,
    GetOptions,
    APIResponseClass<PatientTaskClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientTaskService.get>) => (
      patientTaskService.get(...args)
    ),
    ResponseClassCreator: createPatientTaskGetObject,
    typeName: 'usePatientTaskGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientTaskGet.mock = () => new Error('Mock has not been implemented!');
usePatientTaskGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientTaskGet.createObject = createPatientTaskGetObject;

export const createPatientTaskUpdateObject = (value: APIResponse<PatientTask>) => new APIResponseClass<PatientTaskClass>(value, (value: PatientTask) => new PatientTaskClass(value));
export const usePatientTaskUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, PatientTask>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, PatientTask>,
    APIResponse<PatientTask>,
    // @ts-ignore
    typeof patientTaskService.update,
    AjaxOptions,
    APIResponseClass<PatientTaskClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientTaskService.update>) => (
      patientTaskService.update(...args)
    ),
    ResponseClassCreator: createPatientTaskUpdateObject,
    typeName: 'usePatientTaskUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientTaskUpdate.mock = () => new Error('Mock has not been implemented!');
usePatientTaskUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientTaskUpdate.createObject = createPatientTaskUpdateObject;

export const createPatientTaskInsertObject = (value: APIResponse<PatientTask>) => new APIResponseClass<PatientTaskClass>(value, (value: PatientTask) => new PatientTaskClass(value));
export const usePatientTaskInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<PatientTask>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<PatientTask>,
    APIResponse<PatientTask>,
    // @ts-ignore
    typeof patientTaskService.insert,
    AjaxOptions,
    APIResponseClass<PatientTaskClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientTaskService.insert>) => (
      patientTaskService.insert(...args)
    ),
    ResponseClassCreator: createPatientTaskInsertObject,
    typeName: 'usePatientTaskInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientTaskInsert.mock = () => new Error('Mock has not been implemented!');
usePatientTaskInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientTaskInsert.createObject = createPatientTaskInsertObject;

export const createPatientTaskDeleteObject = (value: APIResponse<PatientTask>) => new APIResponseClass<PatientTaskClass>(value, (value: PatientTask) => new PatientTaskClass(value));
export const usePatientTaskDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<PatientTask>,
    // @ts-ignore
    typeof patientTaskService.delete,
    AjaxOptions,
    APIResponseClass<PatientTaskClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientTaskService.delete>) => (
      patientTaskService.delete(...args)
    ),
    ResponseClassCreator: createPatientTaskDeleteObject,
    typeName: 'usePatientTaskDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientTaskDelete.mock = () => new Error('Mock has not been implemented!');
usePatientTaskDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientTaskDelete.createObject = createPatientTaskDeleteObject;

export const createPatientTaskSearchObject = (value: APIResponse<PageResponse<PatientTask>>) => new APIResponseClass<PageResponseClass<PatientTaskClass>>(value, (value: PageResponse<PatientTask>) => new PageResponseClass<PatientTaskClass>(value, (value: PatientTask) => new PatientTaskClass(value)));
export const usePatientTaskSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<PatientTask>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<PatientTask>,
    APIResponse<PageResponse<PatientTask>>,
    // @ts-ignore
    typeof patientTaskService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientTaskClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientTaskService.search>) => (
      patientTaskService.search(...args)
    ),
    ResponseClassCreator: createPatientTaskSearchObject,
    typeName: 'usePatientTaskSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientTaskSearch.mock = () => new Error('Mock has not been implemented!');
usePatientTaskSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientTaskSearch.createObject = createPatientTaskSearchObject;

export enum PatientUploadJobStatus {
	INIT = 'INIT',
	PROCESSING = 'PROCESSING',
	DONE = 'DONE',
}
export interface PatientUploadJobManager extends BaseModel {
	status?: Nullable<PatientUploadJobStatus>;
	orgId: string;
	docId?: Nullable<string>;
	previewMode?: Nullable<boolean>;
	addStickyNotes?: Nullable<boolean>;
	addVBCPrefix?: Nullable<boolean>;
	useExactMatch?: Nullable<boolean>;
	timezone?: Nullable<string>;
	totalPatient?: Nullable<number>;
	startAt?: Nullable<string>;
	endAt?: Nullable<string>;
	completedPatient?: Nullable<number>;
	statusNe?: Nullable<NeFilter<PatientUploadJobStatus>>;
}
export enum PatientUploadResult {
	INIT = 'INIT',
	INSERT = 'INSERT',
	ASSOCIATE = 'ASSOCIATE',
	FAILED = 'FAILED',
	DUPLICATED = 'DUPLICATED',
}
export interface PatientUpload extends BaseModel {
	jobId?: Nullable<string>;
	result?: Nullable<PatientUploadResult>;
	detail?: Nullable<string>;
	row?: Nullable<number>;
	firstName?: Nullable<string>;
	lastName?: Nullable<string>;
	username?: Nullable<string>;
	mainAddress?: Nullable<Address>;
	insuranceProvider?: Nullable<string>;
	insuranceProvider2?: Nullable<string>;
	birthday?: Nullable<string>;
	language?: Nullable<string>;
	medId?: Nullable<string>;
	gender?: Nullable<GenderEnum>;
	email?: Nullable<string>;
	phoneList?: Nullable<Phone[]>;
	note?: Nullable<string>;
	doctorId?: Nullable<string>;
	doctorName?: Nullable<string>;
	resultIn?: Nullable<InFilter<PatientUploadResult>>;
}
export interface PatientUploadJobManagerRequest {
	patientUploadJobManager: PatientUploadJobManager;
	records?: Nullable<PatientUpload[]>;
}
export class PatientUploadJobManagerClass extends BaseModelClass {
  protected readonly _value: PatientUploadJobManager;

	constructor(value: PatientUploadJobManager) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: PatientUploadJobStatus[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get orgId() {
    return this._value.orgId;
  }

  get docId() {
    return this._value.docId;
  }

  get previewMode() {
    return this._value.previewMode;
  }

  get previewModeWithDefault() {
    return this._value.previewMode || false;
  }

  get addStickyNotes() {
    return this._value.addStickyNotes;
  }

  get addStickyNotesWithDefault() {
    return this._value.addStickyNotes || false;
  }

  get addVBCPrefix() {
    return this._value.addVBCPrefix;
  }

  get addVBCPrefixWithDefault() {
    return this._value.addVBCPrefix || false;
  }

  get useExactMatch() {
    return this._value.useExactMatch;
  }

  get useExactMatchWithDefault() {
    return this._value.useExactMatch || false;
  }

  get timezone() {
    return this._value.timezone;
  }

  get timezoneWithDefault() {
    return this._value.timezone || '';
  }

  get totalPatient() {
    return this._value.totalPatient;
  }

  get totalPatientWithDefault() {
    return this._value.totalPatient || 0;
  }

  get startAt() {
    return (this._value.startAt !== undefined && this._value.startAt !== null) ? dayjs(this._value.startAt) : this._value.startAt;
  }

  get endAt() {
    return (this._value.endAt !== undefined && this._value.endAt !== null) ? dayjs(this._value.endAt) : this._value.endAt;
  }

  get completedPatient() {
    return this._value.completedPatient;
  }

  get completedPatientWithDefault() {
    return this._value.completedPatient || 0;
  }

  get statusNe() {
    return this._value.statusNe !== undefined && this._value.statusNe !== null ? new NeFilterClass(this._value.statusNe) : this._value.statusNe;
  }
}
export interface PatientUploadJobDetails {
	patientUploadJobManager?: Nullable<PatientUploadJobManager>;
	completedPatients?: Nullable<PatientUpload[]>;
}
export class PatientUploadClass extends BaseModelClass {
  protected readonly _value: PatientUpload;

	constructor(value: PatientUpload) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get jobId() {
    return this._value.jobId;
  }

  get result() {
    return this._value.result;
  }

  resultIsIn(values: PatientUploadResult[] = []) {
    return this._value.result && values.includes(this._value.result);
  }

  get detail() {
    return this._value.detail;
  }

  get detailWithDefault() {
    return this._value.detail || '';
  }

  get row() {
    return this._value.row;
  }

  get rowWithDefault() {
    return this._value.row || 0;
  }

  get firstName() {
    return this._value.firstName;
  }

  get firstNameWithDefault() {
    return this._value.firstName || '';
  }

  get lastName() {
    return this._value.lastName;
  }

  get lastNameWithDefault() {
    return this._value.lastName || '';
  }

  get username() {
    return this._value.username;
  }

  get usernameWithDefault() {
    return this._value.username || '';
  }

  get mainAddress() {
    return this._value.mainAddress !== undefined && this._value.mainAddress !== null ? new AddressClass(this._value.mainAddress) : this._value.mainAddress;
  }

  get insuranceProvider() {
    return this._value.insuranceProvider;
  }

  get insuranceProviderWithDefault() {
    return this._value.insuranceProvider || '';
  }

  get insuranceProvider2() {
    return this._value.insuranceProvider2;
  }

  get insuranceProvider2WithDefault() {
    return this._value.insuranceProvider2 || '';
  }

  get birthday() {
    return this._value.birthday;
  }

  get birthdayWithDefault() {
    return this._value.birthday || '';
  }

  get language() {
    return this._value.language;
  }

  get languageWithDefault() {
    return this._value.language || '';
  }

  get medId() {
    return this._value.medId;
  }

  get medIdWithDefault() {
    return this._value.medId || '';
  }

  get gender() {
    return this._value.gender;
  }

  genderIsIn(values: GenderEnum[] = []) {
    return this._value.gender && values.includes(this._value.gender);
  }

  get email() {
    return this._value.email;
  }

  get emailWithDefault() {
    return this._value.email || '';
  }

  get phoneList() {
    return this._value.phoneList !== undefined && this._value.phoneList !== null ? map(this._value.phoneList, (v) => new PhoneClass(v)) : this._value.phoneList;
  }

  get phoneListWithDefault() {
    return this._value.phoneList || [];
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get doctorId() {
    return this._value.doctorId;
  }

  get doctorName() {
    return this._value.doctorName;
  }

  get doctorNameWithDefault() {
    return this._value.doctorName || '';
  }

  get resultIn() {
    return this._value.resultIn !== undefined && this._value.resultIn !== null ? new InFilterClass(this._value.resultIn) : this._value.resultIn;
  }
}
export class PatientUploadJobDetailsClass {
  protected readonly _value: PatientUploadJobDetails;

	constructor(value: PatientUploadJobDetails) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientUploadJobManager() {
    return this._value.patientUploadJobManager !== undefined && this._value.patientUploadJobManager !== null ? new PatientUploadJobManagerClass(this._value.patientUploadJobManager) : this._value.patientUploadJobManager;
  }

  get completedPatients() {
    return this._value.completedPatients !== undefined && this._value.completedPatients !== null ? map(this._value.completedPatients, (v) => new PatientUploadClass(v)) : this._value.completedPatients;
  }

  get completedPatientsWithDefault() {
    return this._value.completedPatients || [];
  }
}
export interface PatientUploadJobManagerCreateJobParams {
	request: PatientUploadJobManagerRequest;
}

export interface PatientUploadJobManagerLatestJobParams {
}

export interface PatientUploadJobManagerDetailsParams {
	jobId: string;
}

export class PatientUploadJobManagerService extends BaseController<PatientUploadJobManager, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/patient-upload-job-manager';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public createJob({ baseUrl, params, headers }: RequestOption<PatientUploadJobManagerCreateJobParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PatientUploadJobManager>>(`${baseUrl || this.baseURL}${this.basePath}/create-job${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public latestJob({ baseUrl, params, headers }: RequestOption<PatientUploadJobManagerLatestJobParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PatientUploadJobManager>>(`${baseUrl || this.baseURL}${this.basePath}/latest-job${getQueryStrings(queryList)}`, { headers });
	}

	public details({ baseUrl, params, headers }: RequestOption<PatientUploadJobManagerDetailsParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PatientUploadJobDetails>>(`${baseUrl || this.baseURL}${this.basePath}/${params.jobId}/details${getQueryStrings(queryList)}`, { headers });
	}
}

export const patientUploadJobManagerService = new PatientUploadJobManagerService();

export const createPatientUploadJobManagerCreateJobObject = (value: APIResponse<PatientUploadJobManager>) => new APIResponseClass<PatientUploadJobManagerClass>(value, (value: PatientUploadJobManager) => new PatientUploadJobManagerClass(value));

export const usePatientUploadJobManagerCreateJob = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUploadJobManagerCreateJobParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUploadJobManagerCreateJobParams,
    APIResponse<PatientUploadJobManager>,
    typeof patientUploadJobManagerService.createJob,
    AjaxOptions,
    APIResponseClass<PatientUploadJobManagerClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientUploadJobManagerService.createJob>) => (
      patientUploadJobManagerService.createJob(...args)
    ),
    ResponseClassCreator: createPatientUploadJobManagerCreateJobObject,
    typeName: 'usePatientUploadJobManagerCreateJob',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUploadJobManagerCreateJob.mock = () => new Error('Mock has not been implemented!');
usePatientUploadJobManagerCreateJob.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUploadJobManagerCreateJob.createObject = createPatientUploadJobManagerCreateJobObject;

export const createPatientUploadJobManagerLatestJobObject = (value: APIResponse<PatientUploadJobManager>) => new APIResponseClass<PatientUploadJobManagerClass>(value, (value: PatientUploadJobManager) => new PatientUploadJobManagerClass(value));

export const usePatientUploadJobManagerLatestJob = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUploadJobManagerLatestJobParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUploadJobManagerLatestJobParams,
    APIResponse<PatientUploadJobManager>,
    typeof patientUploadJobManagerService.latestJob,
    GetOptions,
    APIResponseClass<PatientUploadJobManagerClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientUploadJobManagerService.latestJob>) => (
      patientUploadJobManagerService.latestJob(...args)
    ),
    ResponseClassCreator: createPatientUploadJobManagerLatestJobObject,
    typeName: 'usePatientUploadJobManagerLatestJob',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUploadJobManagerLatestJob.mock = () => new Error('Mock has not been implemented!');
usePatientUploadJobManagerLatestJob.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUploadJobManagerLatestJob.createObject = createPatientUploadJobManagerLatestJobObject;

export const createPatientUploadJobManagerDetailsObject = (value: APIResponse<PatientUploadJobDetails>) => new APIResponseClass<PatientUploadJobDetailsClass>(value, (value: PatientUploadJobDetails) => new PatientUploadJobDetailsClass(value));

export const usePatientUploadJobManagerDetails = <RCN = undefined>(configs: MakeRequestHookConfig<PatientUploadJobManagerDetailsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PatientUploadJobManagerDetailsParams,
    APIResponse<PatientUploadJobDetails>,
    typeof patientUploadJobManagerService.details,
    GetOptions,
    APIResponseClass<PatientUploadJobDetailsClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientUploadJobManagerService.details>) => (
      patientUploadJobManagerService.details(...args)
    ),
    ResponseClassCreator: createPatientUploadJobManagerDetailsObject,
    typeName: 'usePatientUploadJobManagerDetails',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePatientUploadJobManagerDetails.mock = () => new Error('Mock has not been implemented!');
usePatientUploadJobManagerDetails.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUploadJobManagerDetails.createObject = createPatientUploadJobManagerDetailsObject;


export const createPatientUploadJobManagerGetObject = (value: APIResponse<PatientUploadJobManager>) => new APIResponseClass<PatientUploadJobManagerClass>(value, (value: PatientUploadJobManager) => new PatientUploadJobManagerClass(value));
export const usePatientUploadJobManagerGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<PatientUploadJobManager>,
    // @ts-ignore
    typeof patientUploadJobManagerService.get,
    GetOptions,
    APIResponseClass<PatientUploadJobManagerClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof patientUploadJobManagerService.get>) => (
      patientUploadJobManagerService.get(...args)
    ),
    ResponseClassCreator: createPatientUploadJobManagerGetObject,
    typeName: 'usePatientUploadJobManagerGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientUploadJobManagerGet.mock = () => new Error('Mock has not been implemented!');
usePatientUploadJobManagerGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUploadJobManagerGet.createObject = createPatientUploadJobManagerGetObject;

export const createPatientUploadJobManagerUpdateObject = (value: APIResponse<PatientUploadJobManager>) => new APIResponseClass<PatientUploadJobManagerClass>(value, (value: PatientUploadJobManager) => new PatientUploadJobManagerClass(value));
export const usePatientUploadJobManagerUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, PatientUploadJobManager>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, PatientUploadJobManager>,
    APIResponse<PatientUploadJobManager>,
    // @ts-ignore
    typeof patientUploadJobManagerService.update,
    AjaxOptions,
    APIResponseClass<PatientUploadJobManagerClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientUploadJobManagerService.update>) => (
      patientUploadJobManagerService.update(...args)
    ),
    ResponseClassCreator: createPatientUploadJobManagerUpdateObject,
    typeName: 'usePatientUploadJobManagerUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientUploadJobManagerUpdate.mock = () => new Error('Mock has not been implemented!');
usePatientUploadJobManagerUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUploadJobManagerUpdate.createObject = createPatientUploadJobManagerUpdateObject;

export const createPatientUploadJobManagerInsertObject = (value: APIResponse<PatientUploadJobManager>) => new APIResponseClass<PatientUploadJobManagerClass>(value, (value: PatientUploadJobManager) => new PatientUploadJobManagerClass(value));
export const usePatientUploadJobManagerInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<PatientUploadJobManager>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<PatientUploadJobManager>,
    APIResponse<PatientUploadJobManager>,
    // @ts-ignore
    typeof patientUploadJobManagerService.insert,
    AjaxOptions,
    APIResponseClass<PatientUploadJobManagerClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientUploadJobManagerService.insert>) => (
      patientUploadJobManagerService.insert(...args)
    ),
    ResponseClassCreator: createPatientUploadJobManagerInsertObject,
    typeName: 'usePatientUploadJobManagerInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientUploadJobManagerInsert.mock = () => new Error('Mock has not been implemented!');
usePatientUploadJobManagerInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUploadJobManagerInsert.createObject = createPatientUploadJobManagerInsertObject;

export const createPatientUploadJobManagerDeleteObject = (value: APIResponse<PatientUploadJobManager>) => new APIResponseClass<PatientUploadJobManagerClass>(value, (value: PatientUploadJobManager) => new PatientUploadJobManagerClass(value));
export const usePatientUploadJobManagerDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<PatientUploadJobManager>,
    // @ts-ignore
    typeof patientUploadJobManagerService.delete,
    AjaxOptions,
    APIResponseClass<PatientUploadJobManagerClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientUploadJobManagerService.delete>) => (
      patientUploadJobManagerService.delete(...args)
    ),
    ResponseClassCreator: createPatientUploadJobManagerDeleteObject,
    typeName: 'usePatientUploadJobManagerDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientUploadJobManagerDelete.mock = () => new Error('Mock has not been implemented!');
usePatientUploadJobManagerDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUploadJobManagerDelete.createObject = createPatientUploadJobManagerDeleteObject;

export const createPatientUploadJobManagerSearchObject = (value: APIResponse<PageResponse<PatientUploadJobManager>>) => new APIResponseClass<PageResponseClass<PatientUploadJobManagerClass>>(value, (value: PageResponse<PatientUploadJobManager>) => new PageResponseClass<PatientUploadJobManagerClass>(value, (value: PatientUploadJobManager) => new PatientUploadJobManagerClass(value)));
export const usePatientUploadJobManagerSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<PatientUploadJobManager>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<PatientUploadJobManager>,
    APIResponse<PageResponse<PatientUploadJobManager>>,
    // @ts-ignore
    typeof patientUploadJobManagerService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PatientUploadJobManagerClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof patientUploadJobManagerService.search>) => (
      patientUploadJobManagerService.search(...args)
    ),
    ResponseClassCreator: createPatientUploadJobManagerSearchObject,
    typeName: 'usePatientUploadJobManagerSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePatientUploadJobManagerSearch.mock = () => new Error('Mock has not been implemented!');
usePatientUploadJobManagerSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePatientUploadJobManagerSearch.createObject = createPatientUploadJobManagerSearchObject;

export interface PerformanceListRequest {
	rootId: string;
	startDate?: Nullable<string>;
	endDate?: Nullable<string>;
}
export interface Performance {
	organizationId?: Nullable<string>;
	organizationType?: Nullable<OrganizationTypeEnum>;
	newPatientNum?: Nullable<number>;
	clinicNum?: Nullable<number>;
	enrolledPatientNum?: Nullable<number>;
	potentialPatientNum?: Nullable<number>;
	enrolledPct?: Nullable<number>;
	providerNum?: Nullable<number>;
}
export class PerformanceClass {
  protected readonly _value: Performance;

	constructor(value: Performance) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get organizationType() {
    return this._value.organizationType;
  }

  organizationTypeIsIn(values: OrganizationTypeEnum[] = []) {
    return this._value.organizationType && values.includes(this._value.organizationType);
  }

  get newPatientNum() {
    return this._value.newPatientNum;
  }

  get newPatientNumWithDefault() {
    return this._value.newPatientNum || 0;
  }

  get clinicNum() {
    return this._value.clinicNum;
  }

  get clinicNumWithDefault() {
    return this._value.clinicNum || 0;
  }

  get enrolledPatientNum() {
    return this._value.enrolledPatientNum;
  }

  get enrolledPatientNumWithDefault() {
    return this._value.enrolledPatientNum || 0;
  }

  get potentialPatientNum() {
    return this._value.potentialPatientNum;
  }

  get potentialPatientNumWithDefault() {
    return this._value.potentialPatientNum || 0;
  }

  get enrolledPct() {
    return this._value.enrolledPct;
  }

  get enrolledPctWithDefault() {
    return this._value.enrolledPct || 0.0;
  }

  get providerNum() {
    return this._value.providerNum;
  }

  get providerNumWithDefault() {
    return this._value.providerNum || 0;
  }
}
export interface PerformanceGetRequest {
	organizationId: string;
	startDate: string;
	endDate: string;
}
export interface PerformanceListPerformanceParams {
	request: PerformanceListRequest;
}

export interface PerformanceGetPerformanceParams {
	request: PerformanceGetRequest;
}

export class PerformanceService {
  protected baseURL = '';

  protected basePath = '/v1/uc/performance';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public listPerformance({ baseUrl, params, headers }: RequestOption<PerformanceListPerformanceParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Performance[]>>(`${baseUrl || this.baseURL}${this.basePath}/list${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getPerformance({ baseUrl, params, headers }: RequestOption<PerformanceGetPerformanceParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Performance>>(`${baseUrl || this.baseURL}${this.basePath}/get-one${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const performanceService = new PerformanceService();

export const createPerformanceListPerformanceObject = (value: APIResponse<Performance[]>) => new APIResponseClass<PerformanceClass[]>(value, (v) => map(v, (value: Performance) => new PerformanceClass(value)));

export const usePerformanceListPerformance = <RCN = undefined>(configs: MakeRequestHookConfig<PerformanceListPerformanceParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PerformanceListPerformanceParams,
    APIResponse<Performance[]>,
    typeof performanceService.listPerformance,
    AjaxOptions,
    APIResponseClass<PerformanceClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof performanceService.listPerformance>) => (
      performanceService.listPerformance(...args)
    ),
    ResponseClassCreator: createPerformanceListPerformanceObject,
    typeName: 'usePerformanceListPerformance',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePerformanceListPerformance.mock = () => new Error('Mock has not been implemented!');
usePerformanceListPerformance.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePerformanceListPerformance.createObject = createPerformanceListPerformanceObject;

export const createPerformanceGetPerformanceObject = (value: APIResponse<Performance>) => new APIResponseClass<PerformanceClass>(value, (value: Performance) => new PerformanceClass(value));

export const usePerformanceGetPerformance = <RCN = undefined>(configs: MakeRequestHookConfig<PerformanceGetPerformanceParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PerformanceGetPerformanceParams,
    APIResponse<Performance>,
    typeof performanceService.getPerformance,
    AjaxOptions,
    APIResponseClass<PerformanceClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof performanceService.getPerformance>) => (
      performanceService.getPerformance(...args)
    ),
    ResponseClassCreator: createPerformanceGetPerformanceObject,
    typeName: 'usePerformanceGetPerformance',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePerformanceGetPerformance.mock = () => new Error('Mock has not been implemented!');
usePerformanceGetPerformance.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePerformanceGetPerformance.createObject = createPerformanceGetPerformanceObject;
export interface MobileInfo {
	phoneId?: Nullable<string>;
	phoneName?: Nullable<string>;
	phoneModel?: Nullable<string>;
	phoneOSVersion?: Nullable<string>;
	appVersion?: Nullable<string>;
	appVersionIn?: Nullable<InFilter<string>>;
}
export enum PhoneReportInfoPermissionStatusEnum {
	unavailable = 'unavailable',
	denied = 'denied',
	blocked = 'blocked',
	granted = 'granted',
	limited = 'limited',
}
export interface AuthorityInfo {
	cameraStatus?: Nullable<PhoneReportInfoPermissionStatusEnum>;
	audioStatus?: Nullable<PhoneReportInfoPermissionStatusEnum>;
	microphoneStatus?: Nullable<PhoneReportInfoPermissionStatusEnum>;
	photoLibraryStatus?: Nullable<PhoneReportInfoPermissionStatusEnum>;
	notificationStatus?: Nullable<PhoneReportInfoPermissionStatusEnum>;
	contactsStatus?: Nullable<PhoneReportInfoPermissionStatusEnum>;
	locationAlwaysStatus?: Nullable<PhoneReportInfoPermissionStatusEnum>;
	locationWhenInUseStatus?: Nullable<PhoneReportInfoPermissionStatusEnum>;
	readContactsStatus?: Nullable<PhoneReportInfoPermissionStatusEnum>;
	writeContactsStatus?: Nullable<PhoneReportInfoPermissionStatusEnum>;
	coarseLocationStatus?: Nullable<PhoneReportInfoPermissionStatusEnum>;
	fineLocationStatus?: Nullable<PhoneReportInfoPermissionStatusEnum>;
	autoUpdateStatus?: Nullable<PhoneReportInfoPermissionStatusEnum>;
	openAppCountsAfterLogin?: Nullable<number>;
}
export interface PhoneReportInfo extends BaseModel {
	memberId?: Nullable<string>;
	phoneId?: Nullable<string>;
	mobileInfo?: Nullable<MobileInfo>;
	authorityInfo?: Nullable<AuthorityInfo>;
	rewardPoints?: Nullable<number>;
}
export interface AppInfoResponse extends PhoneReportInfo {
	initialUsed?: Nullable<string>;
}
export class MobileInfoClass {
  protected readonly _value: MobileInfo;

	constructor(value: MobileInfo) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get phoneId() {
    return this._value.phoneId;
  }

  get phoneIdWithDefault() {
    return this._value.phoneId || '';
  }

  get phoneName() {
    return this._value.phoneName;
  }

  get phoneNameWithDefault() {
    return this._value.phoneName || '';
  }

  get phoneModel() {
    return this._value.phoneModel;
  }

  get phoneModelWithDefault() {
    return this._value.phoneModel || '';
  }

  get phoneOSVersion() {
    return this._value.phoneOSVersion;
  }

  get phoneOSVersionWithDefault() {
    return this._value.phoneOSVersion || '';
  }

  get appVersion() {
    return this._value.appVersion;
  }

  get appVersionWithDefault() {
    return this._value.appVersion || '';
  }

  get appVersionIn() {
    return this._value.appVersionIn !== undefined && this._value.appVersionIn !== null ? new InFilterClass(this._value.appVersionIn) : this._value.appVersionIn;
  }
}
export class AuthorityInfoClass {
  protected readonly _value: AuthorityInfo;

	constructor(value: AuthorityInfo) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get cameraStatus() {
    return this._value.cameraStatus;
  }

  cameraStatusIsIn(values: PhoneReportInfoPermissionStatusEnum[] = []) {
    return this._value.cameraStatus && values.includes(this._value.cameraStatus);
  }

  get audioStatus() {
    return this._value.audioStatus;
  }

  audioStatusIsIn(values: PhoneReportInfoPermissionStatusEnum[] = []) {
    return this._value.audioStatus && values.includes(this._value.audioStatus);
  }

  get microphoneStatus() {
    return this._value.microphoneStatus;
  }

  microphoneStatusIsIn(values: PhoneReportInfoPermissionStatusEnum[] = []) {
    return this._value.microphoneStatus && values.includes(this._value.microphoneStatus);
  }

  get photoLibraryStatus() {
    return this._value.photoLibraryStatus;
  }

  photoLibraryStatusIsIn(values: PhoneReportInfoPermissionStatusEnum[] = []) {
    return this._value.photoLibraryStatus && values.includes(this._value.photoLibraryStatus);
  }

  get notificationStatus() {
    return this._value.notificationStatus;
  }

  notificationStatusIsIn(values: PhoneReportInfoPermissionStatusEnum[] = []) {
    return this._value.notificationStatus && values.includes(this._value.notificationStatus);
  }

  get contactsStatus() {
    return this._value.contactsStatus;
  }

  contactsStatusIsIn(values: PhoneReportInfoPermissionStatusEnum[] = []) {
    return this._value.contactsStatus && values.includes(this._value.contactsStatus);
  }

  get locationAlwaysStatus() {
    return this._value.locationAlwaysStatus;
  }

  locationAlwaysStatusIsIn(values: PhoneReportInfoPermissionStatusEnum[] = []) {
    return this._value.locationAlwaysStatus && values.includes(this._value.locationAlwaysStatus);
  }

  get locationWhenInUseStatus() {
    return this._value.locationWhenInUseStatus;
  }

  locationWhenInUseStatusIsIn(values: PhoneReportInfoPermissionStatusEnum[] = []) {
    return this._value.locationWhenInUseStatus && values.includes(this._value.locationWhenInUseStatus);
  }

  get readContactsStatus() {
    return this._value.readContactsStatus;
  }

  readContactsStatusIsIn(values: PhoneReportInfoPermissionStatusEnum[] = []) {
    return this._value.readContactsStatus && values.includes(this._value.readContactsStatus);
  }

  get writeContactsStatus() {
    return this._value.writeContactsStatus;
  }

  writeContactsStatusIsIn(values: PhoneReportInfoPermissionStatusEnum[] = []) {
    return this._value.writeContactsStatus && values.includes(this._value.writeContactsStatus);
  }

  get coarseLocationStatus() {
    return this._value.coarseLocationStatus;
  }

  coarseLocationStatusIsIn(values: PhoneReportInfoPermissionStatusEnum[] = []) {
    return this._value.coarseLocationStatus && values.includes(this._value.coarseLocationStatus);
  }

  get fineLocationStatus() {
    return this._value.fineLocationStatus;
  }

  fineLocationStatusIsIn(values: PhoneReportInfoPermissionStatusEnum[] = []) {
    return this._value.fineLocationStatus && values.includes(this._value.fineLocationStatus);
  }

  get autoUpdateStatus() {
    return this._value.autoUpdateStatus;
  }

  autoUpdateStatusIsIn(values: PhoneReportInfoPermissionStatusEnum[] = []) {
    return this._value.autoUpdateStatus && values.includes(this._value.autoUpdateStatus);
  }

  get openAppCountsAfterLogin() {
    return this._value.openAppCountsAfterLogin;
  }

  get openAppCountsAfterLoginWithDefault() {
    return this._value.openAppCountsAfterLogin || 0;
  }
}
export class PhoneReportInfoClass extends BaseModelClass {
  protected readonly _value: PhoneReportInfo;

	constructor(value: PhoneReportInfo) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get phoneId() {
    return this._value.phoneId;
  }

  get phoneIdWithDefault() {
    return this._value.phoneId || '';
  }

  get mobileInfo() {
    return this._value.mobileInfo !== undefined && this._value.mobileInfo !== null ? new MobileInfoClass(this._value.mobileInfo) : this._value.mobileInfo;
  }

  get authorityInfo() {
    return this._value.authorityInfo !== undefined && this._value.authorityInfo !== null ? new AuthorityInfoClass(this._value.authorityInfo) : this._value.authorityInfo;
  }

  get rewardPoints() {
    return this._value.rewardPoints;
  }

  get rewardPointsWithDefault() {
    return this._value.rewardPoints || 0;
  }
}
export class AppInfoResponseClass extends PhoneReportInfoClass {
  protected readonly _value: AppInfoResponse;

	constructor(value: AppInfoResponse) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get initialUsed() {
    return (this._value.initialUsed !== undefined && this._value.initialUsed !== null) ? dayjs(this._value.initialUsed) : this._value.initialUsed;
  }
}
export interface PhoneReportInfoGetAppInfoByPatientIdParams {
	memberId: string;
}

export interface PhoneReportInfoGetAppVersionsParams {
}

export interface PhoneReportInfoGetByPatientIdParams {
	memberId: string;
}

export class PhoneReportInfoService extends BaseController<PhoneReportInfo, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/phone-report-info';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getAppInfoByPatientId({ baseUrl, params, headers }: RequestOption<PhoneReportInfoGetAppInfoByPatientIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<AppInfoResponse>>(`${baseUrl || this.baseURL}${this.basePath}/app-info/${params.memberId}${getQueryStrings(queryList)}`, { headers });
	}

	public getAppVersions({ baseUrl, params, headers }: RequestOption<PhoneReportInfoGetAppVersionsParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<string[]>>(`${baseUrl || this.baseURL}${this.basePath}/app-versions/list${getQueryStrings(queryList)}`, { headers });
	}

	public getByPatientId({ baseUrl, params, headers }: RequestOption<PhoneReportInfoGetByPatientIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PhoneReportInfo[]>>(`${baseUrl || this.baseURL}${this.basePath}/list/${params.memberId}${getQueryStrings(queryList)}`, { headers });
	}
}

export const phoneReportInfoService = new PhoneReportInfoService();

export const createPhoneReportInfoGetAppInfoByPatientIdObject = (value: APIResponse<AppInfoResponse>) => new APIResponseClass<AppInfoResponseClass>(value, (value: AppInfoResponse) => new AppInfoResponseClass(value));

export const usePhoneReportInfoGetAppInfoByPatientId = <RCN = undefined>(configs: MakeRequestHookConfig<PhoneReportInfoGetAppInfoByPatientIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PhoneReportInfoGetAppInfoByPatientIdParams,
    APIResponse<AppInfoResponse>,
    typeof phoneReportInfoService.getAppInfoByPatientId,
    GetOptions,
    APIResponseClass<AppInfoResponseClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof phoneReportInfoService.getAppInfoByPatientId>) => (
      phoneReportInfoService.getAppInfoByPatientId(...args)
    ),
    ResponseClassCreator: createPhoneReportInfoGetAppInfoByPatientIdObject,
    typeName: 'usePhoneReportInfoGetAppInfoByPatientId',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePhoneReportInfoGetAppInfoByPatientId.mock = () => new Error('Mock has not been implemented!');
usePhoneReportInfoGetAppInfoByPatientId.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePhoneReportInfoGetAppInfoByPatientId.createObject = createPhoneReportInfoGetAppInfoByPatientIdObject;

export const createPhoneReportInfoGetAppVersionsObject = (value: APIResponse<string[]>) => new APIResponseClass<string[]>(value, (v) => (v));

export const usePhoneReportInfoGetAppVersions = <RCN = undefined>(configs: MakeRequestHookConfig<PhoneReportInfoGetAppVersionsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PhoneReportInfoGetAppVersionsParams,
    APIResponse<string[]>,
    typeof phoneReportInfoService.getAppVersions,
    GetOptions,
    APIResponseClass<string[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof phoneReportInfoService.getAppVersions>) => (
      phoneReportInfoService.getAppVersions(...args)
    ),
    ResponseClassCreator: createPhoneReportInfoGetAppVersionsObject,
    typeName: 'usePhoneReportInfoGetAppVersions',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePhoneReportInfoGetAppVersions.mock = () => new Error('Mock has not been implemented!');
usePhoneReportInfoGetAppVersions.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePhoneReportInfoGetAppVersions.createObject = createPhoneReportInfoGetAppVersionsObject;

export const createPhoneReportInfoGetByPatientIdObject = (value: APIResponse<PhoneReportInfo[]>) => new APIResponseClass<PhoneReportInfoClass[]>(value, (v) => map(v, (value: PhoneReportInfo) => new PhoneReportInfoClass(value)));

export const usePhoneReportInfoGetByPatientId = <RCN = undefined>(configs: MakeRequestHookConfig<PhoneReportInfoGetByPatientIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PhoneReportInfoGetByPatientIdParams,
    APIResponse<PhoneReportInfo[]>,
    typeof phoneReportInfoService.getByPatientId,
    GetOptions,
    APIResponseClass<PhoneReportInfoClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof phoneReportInfoService.getByPatientId>) => (
      phoneReportInfoService.getByPatientId(...args)
    ),
    ResponseClassCreator: createPhoneReportInfoGetByPatientIdObject,
    typeName: 'usePhoneReportInfoGetByPatientId',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePhoneReportInfoGetByPatientId.mock = () => new Error('Mock has not been implemented!');
usePhoneReportInfoGetByPatientId.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePhoneReportInfoGetByPatientId.createObject = createPhoneReportInfoGetByPatientIdObject;


export const createPhoneReportInfoGetObject = (value: APIResponse<PhoneReportInfo>) => new APIResponseClass<PhoneReportInfoClass>(value, (value: PhoneReportInfo) => new PhoneReportInfoClass(value));
export const usePhoneReportInfoGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<PhoneReportInfo>,
    // @ts-ignore
    typeof phoneReportInfoService.get,
    GetOptions,
    APIResponseClass<PhoneReportInfoClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof phoneReportInfoService.get>) => (
      phoneReportInfoService.get(...args)
    ),
    ResponseClassCreator: createPhoneReportInfoGetObject,
    typeName: 'usePhoneReportInfoGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePhoneReportInfoGet.mock = () => new Error('Mock has not been implemented!');
usePhoneReportInfoGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePhoneReportInfoGet.createObject = createPhoneReportInfoGetObject;

export const createPhoneReportInfoUpdateObject = (value: APIResponse<PhoneReportInfo>) => new APIResponseClass<PhoneReportInfoClass>(value, (value: PhoneReportInfo) => new PhoneReportInfoClass(value));
export const usePhoneReportInfoUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, PhoneReportInfo>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, PhoneReportInfo>,
    APIResponse<PhoneReportInfo>,
    // @ts-ignore
    typeof phoneReportInfoService.update,
    AjaxOptions,
    APIResponseClass<PhoneReportInfoClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof phoneReportInfoService.update>) => (
      phoneReportInfoService.update(...args)
    ),
    ResponseClassCreator: createPhoneReportInfoUpdateObject,
    typeName: 'usePhoneReportInfoUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePhoneReportInfoUpdate.mock = () => new Error('Mock has not been implemented!');
usePhoneReportInfoUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePhoneReportInfoUpdate.createObject = createPhoneReportInfoUpdateObject;

export const createPhoneReportInfoInsertObject = (value: APIResponse<PhoneReportInfo>) => new APIResponseClass<PhoneReportInfoClass>(value, (value: PhoneReportInfo) => new PhoneReportInfoClass(value));
export const usePhoneReportInfoInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<PhoneReportInfo>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<PhoneReportInfo>,
    APIResponse<PhoneReportInfo>,
    // @ts-ignore
    typeof phoneReportInfoService.insert,
    AjaxOptions,
    APIResponseClass<PhoneReportInfoClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof phoneReportInfoService.insert>) => (
      phoneReportInfoService.insert(...args)
    ),
    ResponseClassCreator: createPhoneReportInfoInsertObject,
    typeName: 'usePhoneReportInfoInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePhoneReportInfoInsert.mock = () => new Error('Mock has not been implemented!');
usePhoneReportInfoInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePhoneReportInfoInsert.createObject = createPhoneReportInfoInsertObject;

export const createPhoneReportInfoDeleteObject = (value: APIResponse<PhoneReportInfo>) => new APIResponseClass<PhoneReportInfoClass>(value, (value: PhoneReportInfo) => new PhoneReportInfoClass(value));
export const usePhoneReportInfoDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<PhoneReportInfo>,
    // @ts-ignore
    typeof phoneReportInfoService.delete,
    AjaxOptions,
    APIResponseClass<PhoneReportInfoClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof phoneReportInfoService.delete>) => (
      phoneReportInfoService.delete(...args)
    ),
    ResponseClassCreator: createPhoneReportInfoDeleteObject,
    typeName: 'usePhoneReportInfoDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePhoneReportInfoDelete.mock = () => new Error('Mock has not been implemented!');
usePhoneReportInfoDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePhoneReportInfoDelete.createObject = createPhoneReportInfoDeleteObject;

export const createPhoneReportInfoSearchObject = (value: APIResponse<PageResponse<PhoneReportInfo>>) => new APIResponseClass<PageResponseClass<PhoneReportInfoClass>>(value, (value: PageResponse<PhoneReportInfo>) => new PageResponseClass<PhoneReportInfoClass>(value, (value: PhoneReportInfo) => new PhoneReportInfoClass(value)));
export const usePhoneReportInfoSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<PhoneReportInfo>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<PhoneReportInfo>,
    APIResponse<PageResponse<PhoneReportInfo>>,
    // @ts-ignore
    typeof phoneReportInfoService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PhoneReportInfoClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof phoneReportInfoService.search>) => (
      phoneReportInfoService.search(...args)
    ),
    ResponseClassCreator: createPhoneReportInfoSearchObject,
    typeName: 'usePhoneReportInfoSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePhoneReportInfoSearch.mock = () => new Error('Mock has not been implemented!');
usePhoneReportInfoSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePhoneReportInfoSearch.createObject = createPhoneReportInfoSearchObject;

export interface PostIt extends BaseModel {
	memberId?: Nullable<string>;
	organizationId?: Nullable<string>;
	note?: Nullable<string>;
	createdBy?: Nullable<string>;
	modifiedBy?: Nullable<string>;
}
export class PostItClass extends BaseModelClass {
  protected readonly _value: PostIt;

	constructor(value: PostIt) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get createdBy() {
    return this._value.createdBy;
  }

  get modifiedBy() {
    return this._value.modifiedBy;
  }
}
export interface PostItGetListParams {
	memberId: string;
}

export class PostItService extends BaseController<PostIt, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/post-its';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getList({ baseUrl, params, headers }: RequestOption<PostItGetListParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<PostIt[]>>(`${baseUrl || this.baseURL}${this.basePath}/list/${params.memberId}${getQueryStrings(queryList)}`, { headers });
	}
}

export const postItService = new PostItService();

export const createPostItGetListObject = (value: APIResponse<PostIt[]>) => new APIResponseClass<PostItClass[]>(value, (v) => map(v, (value: PostIt) => new PostItClass(value)));

export const usePostItGetList = <RCN = undefined>(configs: MakeRequestHookConfig<PostItGetListParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    PostItGetListParams,
    APIResponse<PostIt[]>,
    typeof postItService.getList,
    GetOptions,
    APIResponseClass<PostItClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof postItService.getList>) => (
      postItService.getList(...args)
    ),
    ResponseClassCreator: createPostItGetListObject,
    typeName: 'usePostItGetList',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

usePostItGetList.mock = () => new Error('Mock has not been implemented!');
usePostItGetList.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePostItGetList.createObject = createPostItGetListObject;


export const createPostItGetObject = (value: APIResponse<PostIt>) => new APIResponseClass<PostItClass>(value, (value: PostIt) => new PostItClass(value));
export const usePostItGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<PostIt>,
    // @ts-ignore
    typeof postItService.get,
    GetOptions,
    APIResponseClass<PostItClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof postItService.get>) => (
      postItService.get(...args)
    ),
    ResponseClassCreator: createPostItGetObject,
    typeName: 'usePostItGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePostItGet.mock = () => new Error('Mock has not been implemented!');
usePostItGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePostItGet.createObject = createPostItGetObject;

export const createPostItUpdateObject = (value: APIResponse<PostIt>) => new APIResponseClass<PostItClass>(value, (value: PostIt) => new PostItClass(value));
export const usePostItUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, PostIt>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, PostIt>,
    APIResponse<PostIt>,
    // @ts-ignore
    typeof postItService.update,
    AjaxOptions,
    APIResponseClass<PostItClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof postItService.update>) => (
      postItService.update(...args)
    ),
    ResponseClassCreator: createPostItUpdateObject,
    typeName: 'usePostItUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePostItUpdate.mock = () => new Error('Mock has not been implemented!');
usePostItUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePostItUpdate.createObject = createPostItUpdateObject;

export const createPostItInsertObject = (value: APIResponse<PostIt>) => new APIResponseClass<PostItClass>(value, (value: PostIt) => new PostItClass(value));
export const usePostItInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<PostIt>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<PostIt>,
    APIResponse<PostIt>,
    // @ts-ignore
    typeof postItService.insert,
    AjaxOptions,
    APIResponseClass<PostItClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof postItService.insert>) => (
      postItService.insert(...args)
    ),
    ResponseClassCreator: createPostItInsertObject,
    typeName: 'usePostItInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePostItInsert.mock = () => new Error('Mock has not been implemented!');
usePostItInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePostItInsert.createObject = createPostItInsertObject;

export const createPostItDeleteObject = (value: APIResponse<PostIt>) => new APIResponseClass<PostItClass>(value, (value: PostIt) => new PostItClass(value));
export const usePostItDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<PostIt>,
    // @ts-ignore
    typeof postItService.delete,
    AjaxOptions,
    APIResponseClass<PostItClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof postItService.delete>) => (
      postItService.delete(...args)
    ),
    ResponseClassCreator: createPostItDeleteObject,
    typeName: 'usePostItDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePostItDelete.mock = () => new Error('Mock has not been implemented!');
usePostItDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePostItDelete.createObject = createPostItDeleteObject;

export const createPostItSearchObject = (value: APIResponse<PageResponse<PostIt>>) => new APIResponseClass<PageResponseClass<PostItClass>>(value, (value: PageResponse<PostIt>) => new PageResponseClass<PostItClass>(value, (value: PostIt) => new PostItClass(value)));
export const usePostItSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<PostIt>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<PostIt>,
    APIResponse<PageResponse<PostIt>>,
    // @ts-ignore
    typeof postItService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<PostItClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof postItService.search>) => (
      postItService.search(...args)
    ),
    ResponseClassCreator: createPostItSearchObject,
    typeName: 'usePostItSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

usePostItSearch.mock = () => new Error('Mock has not been implemented!');
usePostItSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
usePostItSearch.createObject = createPostItSearchObject;

export enum ProviderNoteTypeEnum {
	MEMBER = 'MEMBER',
	TASK_RESULT = 'TASK_RESULT',
	PROGRAM = 'PROGRAM',
	SMART_ALERT = 'SMART_ALERT',
	MONTHLY_REVIEW = 'MONTHLY_REVIEW',
}
export enum PatientHiatusCategoryEnum {
	SEVEN_DAY_INACTIVITY = 'SEVEN_DAY_INACTIVITY',
	VISITS_DUE = 'VISITS_DUE',
	BILLING_REPORT = 'BILLING_REPORT',
	VITALS_MONITORING_HIATUS = 'VITALS_MONITORING_HIATUS',
	VISIT_HIATUS = 'VISIT_HIATUS',
	HIATUS_UNABLE_TO_ENGAGE = 'HIATUS_UNABLE_TO_ENGAGE',
}
export enum ProviderHiatusActionEnum {
	CREATE = 'CREATE',
	RESUMED = 'RESUMED',
	UPDATE = 'UPDATE',
}
export interface ProviderNote extends PatientBase {
	organizationId?: Nullable<string>;
	memberId?: Nullable<string>;
	type?: Nullable<ProviderNoteTypeEnum>;
	category?: Nullable<ProviderNoteCategoryEnum>;
	content?: Nullable<string>;
	hiatusCategories?: Nullable<PatientHiatusCategoryEnum[]>;
	hiatusStartDate?: Nullable<string>;
	hiatusAction?: Nullable<ProviderHiatusActionEnum>;
	tags?: Nullable<string[]>;
	creatorRole?: Nullable<string>;
	createdBy?: Nullable<string>;
	modifiedBy?: Nullable<string>;
	smartAlertId?: Nullable<string>;
	smartAlertTaskType?: Nullable<string>;
	smartAlertLevel?: Nullable<string>;
	resumeDate?: Nullable<string>;
	techCall?: Nullable<boolean>;
	pauseDuration?: Nullable<number>;
	enrolledProgramId?: Nullable<string>;
	patientHiatusStatusId?: Nullable<string>;
}
export interface ProviderNoteRequest {
	enrolledProgramId?: Nullable<string>;
	resultId?: Nullable<string>;
	memberId?: Nullable<string>;
	organizationId?: Nullable<string>;
	smartAlertId?: Nullable<string>;
	category?: Nullable<ProviderNoteCategoryEnum>;
	content?: Nullable<string>;
	tags?: Nullable<string[]>;
	pauseDuration?: Nullable<number>;
	resumeDate?: Nullable<string>;
	techCall?: Nullable<boolean>;
	notBillableReason?: Nullable<string>;
	patientHiatusStatusId?: Nullable<string>;
	hiatusStartDate?: Nullable<string>;
	hiatusAction?: Nullable<ProviderHiatusActionEnum>;
	hiatusCategories?: Nullable<PatientHiatusCategoryEnum[]>;
}
export class ProviderNoteClass extends PatientBaseClass {
  protected readonly _value: ProviderNote;

	constructor(value: ProviderNote) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get memberId() {
    return this._value.memberId;
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: ProviderNoteTypeEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get category() {
    return this._value.category;
  }

  categoryIsIn(values: ProviderNoteCategoryEnum[] = []) {
    return this._value.category && values.includes(this._value.category);
  }

  get content() {
    return this._value.content;
  }

  get contentWithDefault() {
    return this._value.content || '';
  }

  get hiatusCategories() {
    return this._value.hiatusCategories;
  }

  get hiatusCategoriesWithDefault() {
    return this._value.hiatusCategories || [];
  }

  get hiatusStartDate() {
    return (this._value.hiatusStartDate !== undefined && this._value.hiatusStartDate !== null) ? dayjs(this._value.hiatusStartDate) : this._value.hiatusStartDate;
  }

  get hiatusAction() {
    return this._value.hiatusAction;
  }

  hiatusActionIsIn(values: ProviderHiatusActionEnum[] = []) {
    return this._value.hiatusAction && values.includes(this._value.hiatusAction);
  }

  get tags() {
    return this._value.tags;
  }

  get tagsWithDefault() {
    return this._value.tags || [];
  }

  get creatorRole() {
    return this._value.creatorRole;
  }

  get creatorRoleWithDefault() {
    return this._value.creatorRole || '';
  }

  get createdBy() {
    return this._value.createdBy;
  }

  get modifiedBy() {
    return this._value.modifiedBy;
  }

  get smartAlertId() {
    return this._value.smartAlertId;
  }

  get smartAlertTaskType() {
    return this._value.smartAlertTaskType;
  }

  get smartAlertTaskTypeWithDefault() {
    return this._value.smartAlertTaskType || '';
  }

  get smartAlertLevel() {
    return this._value.smartAlertLevel;
  }

  get smartAlertLevelWithDefault() {
    return this._value.smartAlertLevel || '';
  }

  get resumeDate() {
    return (this._value.resumeDate !== undefined && this._value.resumeDate !== null) ? dayjs(this._value.resumeDate) : this._value.resumeDate;
  }

  get techCall() {
    return this._value.techCall;
  }

  get techCallWithDefault() {
    return this._value.techCall || false;
  }

  get pauseDuration() {
    return this._value.pauseDuration;
  }

  get pauseDurationWithDefault() {
    return this._value.pauseDuration || 0;
  }

  get enrolledProgramId() {
    return this._value.enrolledProgramId;
  }

  get patientHiatusStatusId() {
    return this._value.patientHiatusStatusId;
  }
}
export interface ProviderNoteCreateParams {
	providerNoteRequest: ProviderNoteRequest;
}

export class ProviderNoteService extends BaseController<ProviderNote, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/provider-note';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public create({ baseUrl, params, headers }: RequestOption<ProviderNoteCreateParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<ProviderNote>>(`${baseUrl || this.baseURL}${this.basePath}/create${getQueryStrings(queryList)}`, params.providerNoteRequest, { headers });
	}
}

export const providerNoteService = new ProviderNoteService();

export const createProviderNoteCreateObject = (value: APIResponse<ProviderNote>) => new APIResponseClass<ProviderNoteClass>(value, (value: ProviderNote) => new ProviderNoteClass(value));

export const useProviderNoteCreate = <RCN = undefined>(configs: MakeRequestHookConfig<ProviderNoteCreateParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ProviderNoteCreateParams,
    APIResponse<ProviderNote>,
    typeof providerNoteService.create,
    AjaxOptions,
    APIResponseClass<ProviderNoteClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof providerNoteService.create>) => (
      providerNoteService.create(...args)
    ),
    ResponseClassCreator: createProviderNoteCreateObject,
    typeName: 'useProviderNoteCreate',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useProviderNoteCreate.mock = () => new Error('Mock has not been implemented!');
useProviderNoteCreate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useProviderNoteCreate.createObject = createProviderNoteCreateObject;


export const createProviderNoteGetObject = (value: APIResponse<ProviderNote>) => new APIResponseClass<ProviderNoteClass>(value, (value: ProviderNote) => new ProviderNoteClass(value));
export const useProviderNoteGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<ProviderNote>,
    // @ts-ignore
    typeof providerNoteService.get,
    GetOptions,
    APIResponseClass<ProviderNoteClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof providerNoteService.get>) => (
      providerNoteService.get(...args)
    ),
    ResponseClassCreator: createProviderNoteGetObject,
    typeName: 'useProviderNoteGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useProviderNoteGet.mock = () => new Error('Mock has not been implemented!');
useProviderNoteGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useProviderNoteGet.createObject = createProviderNoteGetObject;

export const createProviderNoteUpdateObject = (value: APIResponse<ProviderNote>) => new APIResponseClass<ProviderNoteClass>(value, (value: ProviderNote) => new ProviderNoteClass(value));
export const useProviderNoteUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, ProviderNote>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, ProviderNote>,
    APIResponse<ProviderNote>,
    // @ts-ignore
    typeof providerNoteService.update,
    AjaxOptions,
    APIResponseClass<ProviderNoteClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof providerNoteService.update>) => (
      providerNoteService.update(...args)
    ),
    ResponseClassCreator: createProviderNoteUpdateObject,
    typeName: 'useProviderNoteUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useProviderNoteUpdate.mock = () => new Error('Mock has not been implemented!');
useProviderNoteUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useProviderNoteUpdate.createObject = createProviderNoteUpdateObject;

export const createProviderNoteInsertObject = (value: APIResponse<ProviderNote>) => new APIResponseClass<ProviderNoteClass>(value, (value: ProviderNote) => new ProviderNoteClass(value));
export const useProviderNoteInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<ProviderNote>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<ProviderNote>,
    APIResponse<ProviderNote>,
    // @ts-ignore
    typeof providerNoteService.insert,
    AjaxOptions,
    APIResponseClass<ProviderNoteClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof providerNoteService.insert>) => (
      providerNoteService.insert(...args)
    ),
    ResponseClassCreator: createProviderNoteInsertObject,
    typeName: 'useProviderNoteInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useProviderNoteInsert.mock = () => new Error('Mock has not been implemented!');
useProviderNoteInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useProviderNoteInsert.createObject = createProviderNoteInsertObject;

export const createProviderNoteDeleteObject = (value: APIResponse<ProviderNote>) => new APIResponseClass<ProviderNoteClass>(value, (value: ProviderNote) => new ProviderNoteClass(value));
export const useProviderNoteDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<ProviderNote>,
    // @ts-ignore
    typeof providerNoteService.delete,
    AjaxOptions,
    APIResponseClass<ProviderNoteClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof providerNoteService.delete>) => (
      providerNoteService.delete(...args)
    ),
    ResponseClassCreator: createProviderNoteDeleteObject,
    typeName: 'useProviderNoteDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useProviderNoteDelete.mock = () => new Error('Mock has not been implemented!');
useProviderNoteDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useProviderNoteDelete.createObject = createProviderNoteDeleteObject;

export const createProviderNoteSearchObject = (value: APIResponse<PageResponse<ProviderNote>>) => new APIResponseClass<PageResponseClass<ProviderNoteClass>>(value, (value: PageResponse<ProviderNote>) => new PageResponseClass<ProviderNoteClass>(value, (value: ProviderNote) => new ProviderNoteClass(value)));
export const useProviderNoteSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<ProviderNote>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<ProviderNote>,
    APIResponse<PageResponse<ProviderNote>>,
    // @ts-ignore
    typeof providerNoteService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ProviderNoteClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof providerNoteService.search>) => (
      providerNoteService.search(...args)
    ),
    ResponseClassCreator: createProviderNoteSearchObject,
    typeName: 'useProviderNoteSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useProviderNoteSearch.mock = () => new Error('Mock has not been implemented!');
useProviderNoteSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useProviderNoteSearch.createObject = createProviderNoteSearchObject;

export interface RPMPatientReview extends BaseModel {
	memberId?: Nullable<string>;
	monthOfYear?: Nullable<number>;
	reviewedBy?: Nullable<string>;
	comment?: Nullable<string>;
}
export class RPMPatientReviewClass extends BaseModelClass {
  protected readonly _value: RPMPatientReview;

	constructor(value: RPMPatientReview) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get monthOfYear() {
    return this._value.monthOfYear;
  }

  get monthOfYearWithDefault() {
    return this._value.monthOfYear || 0;
  }

  get reviewedBy() {
    return this._value.reviewedBy;
  }

  get comment() {
    return this._value.comment;
  }

  get commentWithDefault() {
    return this._value.comment || '';
  }
}
export class RPMPatientReviewService extends BaseController<RPMPatientReview, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/rpm-patient-review';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const rPMPatientReviewService = new RPMPatientReviewService();


export const createRPMPatientReviewGetObject = (value: APIResponse<RPMPatientReview>) => new APIResponseClass<RPMPatientReviewClass>(value, (value: RPMPatientReview) => new RPMPatientReviewClass(value));
export const useRPMPatientReviewGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<RPMPatientReview>,
    // @ts-ignore
    typeof rPMPatientReviewService.get,
    GetOptions,
    APIResponseClass<RPMPatientReviewClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof rPMPatientReviewService.get>) => (
      rPMPatientReviewService.get(...args)
    ),
    ResponseClassCreator: createRPMPatientReviewGetObject,
    typeName: 'useRPMPatientReviewGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRPMPatientReviewGet.mock = () => new Error('Mock has not been implemented!');
useRPMPatientReviewGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRPMPatientReviewGet.createObject = createRPMPatientReviewGetObject;

export const createRPMPatientReviewUpdateObject = (value: APIResponse<RPMPatientReview>) => new APIResponseClass<RPMPatientReviewClass>(value, (value: RPMPatientReview) => new RPMPatientReviewClass(value));
export const useRPMPatientReviewUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, RPMPatientReview>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, RPMPatientReview>,
    APIResponse<RPMPatientReview>,
    // @ts-ignore
    typeof rPMPatientReviewService.update,
    AjaxOptions,
    APIResponseClass<RPMPatientReviewClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof rPMPatientReviewService.update>) => (
      rPMPatientReviewService.update(...args)
    ),
    ResponseClassCreator: createRPMPatientReviewUpdateObject,
    typeName: 'useRPMPatientReviewUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRPMPatientReviewUpdate.mock = () => new Error('Mock has not been implemented!');
useRPMPatientReviewUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRPMPatientReviewUpdate.createObject = createRPMPatientReviewUpdateObject;

export const createRPMPatientReviewInsertObject = (value: APIResponse<RPMPatientReview>) => new APIResponseClass<RPMPatientReviewClass>(value, (value: RPMPatientReview) => new RPMPatientReviewClass(value));
export const useRPMPatientReviewInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<RPMPatientReview>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<RPMPatientReview>,
    APIResponse<RPMPatientReview>,
    // @ts-ignore
    typeof rPMPatientReviewService.insert,
    AjaxOptions,
    APIResponseClass<RPMPatientReviewClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof rPMPatientReviewService.insert>) => (
      rPMPatientReviewService.insert(...args)
    ),
    ResponseClassCreator: createRPMPatientReviewInsertObject,
    typeName: 'useRPMPatientReviewInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRPMPatientReviewInsert.mock = () => new Error('Mock has not been implemented!');
useRPMPatientReviewInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRPMPatientReviewInsert.createObject = createRPMPatientReviewInsertObject;

export const createRPMPatientReviewDeleteObject = (value: APIResponse<RPMPatientReview>) => new APIResponseClass<RPMPatientReviewClass>(value, (value: RPMPatientReview) => new RPMPatientReviewClass(value));
export const useRPMPatientReviewDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<RPMPatientReview>,
    // @ts-ignore
    typeof rPMPatientReviewService.delete,
    AjaxOptions,
    APIResponseClass<RPMPatientReviewClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof rPMPatientReviewService.delete>) => (
      rPMPatientReviewService.delete(...args)
    ),
    ResponseClassCreator: createRPMPatientReviewDeleteObject,
    typeName: 'useRPMPatientReviewDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRPMPatientReviewDelete.mock = () => new Error('Mock has not been implemented!');
useRPMPatientReviewDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRPMPatientReviewDelete.createObject = createRPMPatientReviewDeleteObject;

export const createRPMPatientReviewSearchObject = (value: APIResponse<PageResponse<RPMPatientReview>>) => new APIResponseClass<PageResponseClass<RPMPatientReviewClass>>(value, (value: PageResponse<RPMPatientReview>) => new PageResponseClass<RPMPatientReviewClass>(value, (value: RPMPatientReview) => new RPMPatientReviewClass(value)));
export const useRPMPatientReviewSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<RPMPatientReview>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<RPMPatientReview>,
    APIResponse<PageResponse<RPMPatientReview>>,
    // @ts-ignore
    typeof rPMPatientReviewService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<RPMPatientReviewClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof rPMPatientReviewService.search>) => (
      rPMPatientReviewService.search(...args)
    ),
    ResponseClassCreator: createRPMPatientReviewSearchObject,
    typeName: 'useRPMPatientReviewSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRPMPatientReviewSearch.mock = () => new Error('Mock has not been implemented!');
useRPMPatientReviewSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRPMPatientReviewSearch.createObject = createRPMPatientReviewSearchObject;

export interface RemoteEnrollmentVisit {
	visitId?: Nullable<string>;
	appointmentAt?: Nullable<string>;
}
export enum RemoteEnrollmentStatusEnum {
	INIT = 'INIT',
	REFERRED = 'REFERRED',
	TBD = 'TBD',
	CONFIRMED = 'CONFIRMED',
	COMPLETED = 'COMPLETED',
	EXCLUDED = 'EXCLUDED',
}
export interface RemoteTaskStatus {
	value?: Nullable<boolean>;
	createdBy?: Nullable<MiniProvider>;
	createdAt?: Nullable<string>;
	updatedBy?: Nullable<MiniProvider>;
	updatedAt?: Nullable<string>;
}
export interface CallRecord {
	providerId?: Nullable<string>;
	isPickedUp?: Nullable<boolean>;
	name?: Nullable<string>;
	callDate?: Nullable<string>;
}
export interface ShippingInfo {
	shippingNumber?: Nullable<string>;
	shippingDate?: Nullable<string>;
}
export interface RemoteEnrollment extends BaseModel {
	memberId?: Nullable<string>;
	organizationId?: Nullable<string>;
	nextVisit?: Nullable<RemoteEnrollmentVisit>;
	nextVisitDate?: Nullable<string>;
	status?: Nullable<RemoteEnrollmentStatusEnum>;
	deleted?: Nullable<boolean>;
	doctorReferral?: Nullable<boolean>;
	doctorReferralDate?: Nullable<string>;
	doctorReferralBy?: Nullable<RemoteTaskStatus>;
	confirmedBy?: Nullable<MiniProvider>;
	confirmedDate?: Nullable<string>;
	callers?: Nullable<MiniProvider[]>;
	callHistories?: Nullable<CallRecord[]>;
	lastCallDate?: Nullable<string>;
	nutritionAssessment?: Nullable<RemoteTaskStatus>;
	techOnBoard?: Nullable<RemoteTaskStatus>;
	deviceDelivered?: Nullable<RemoteTaskStatus>;
	shippingInfo?: Nullable<ShippingInfo>;
	carePlanFiled?: Nullable<RemoteTaskStatus>;
	consentFiled?: Nullable<RemoteTaskStatus>;
	billingCodeAttached?: Nullable<RemoteTaskStatus>;
	pinnedToEMR?: Nullable<RemoteTaskStatus>;
	createdBy?: Nullable<MiniProvider>;
	updatedBy?: Nullable<MiniProvider>;
	excludedReason?: Nullable<string>;
	conditionsList?: Nullable<{ [key: string]: boolean }>;
}
export class RemoteEnrollmentVisitClass {
  protected readonly _value: RemoteEnrollmentVisit;

	constructor(value: RemoteEnrollmentVisit) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get visitId() {
    return this._value.visitId;
  }

  get appointmentAt() {
    return (this._value.appointmentAt !== undefined && this._value.appointmentAt !== null) ? dayjs(this._value.appointmentAt) : this._value.appointmentAt;
  }
}
export class RemoteTaskStatusClass {
  protected readonly _value: RemoteTaskStatus;

	constructor(value: RemoteTaskStatus) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || false;
  }

  get createdBy() {
    return this._value.createdBy !== undefined && this._value.createdBy !== null ? new MiniProviderClass(this._value.createdBy) : this._value.createdBy;
  }

  get createdAt() {
    return (this._value.createdAt !== undefined && this._value.createdAt !== null) ? dayjs(this._value.createdAt) : this._value.createdAt;
  }

  get updatedBy() {
    return this._value.updatedBy !== undefined && this._value.updatedBy !== null ? new MiniProviderClass(this._value.updatedBy) : this._value.updatedBy;
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }
}
export class CallRecordClass {
  protected readonly _value: CallRecord;

	constructor(value: CallRecord) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get providerId() {
    return this._value.providerId;
  }

  get isPickedUp() {
    return this._value.isPickedUp;
  }

  get isPickedUpWithDefault() {
    return this._value.isPickedUp || false;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get callDate() {
    return (this._value.callDate !== undefined && this._value.callDate !== null) ? dayjs(this._value.callDate) : this._value.callDate;
  }
}
export class ShippingInfoClass {
  protected readonly _value: ShippingInfo;

	constructor(value: ShippingInfo) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get shippingNumber() {
    return this._value.shippingNumber;
  }

  get shippingNumberWithDefault() {
    return this._value.shippingNumber || '';
  }

  get shippingDate() {
    return (this._value.shippingDate !== undefined && this._value.shippingDate !== null) ? dayjs(this._value.shippingDate) : this._value.shippingDate;
  }
}
export class RemoteEnrollmentClass extends BaseModelClass {
  protected readonly _value: RemoteEnrollment;

	constructor(value: RemoteEnrollment) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get nextVisit() {
    return this._value.nextVisit !== undefined && this._value.nextVisit !== null ? new RemoteEnrollmentVisitClass(this._value.nextVisit) : this._value.nextVisit;
  }

  get nextVisitDate() {
    return (this._value.nextVisitDate !== undefined && this._value.nextVisitDate !== null) ? dayjs(this._value.nextVisitDate) : this._value.nextVisitDate;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: RemoteEnrollmentStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get deleted() {
    return this._value.deleted;
  }

  get deletedWithDefault() {
    return this._value.deleted || false;
  }

  get doctorReferral() {
    return this._value.doctorReferral;
  }

  get doctorReferralWithDefault() {
    return this._value.doctorReferral || false;
  }

  get doctorReferralDate() {
    return (this._value.doctorReferralDate !== undefined && this._value.doctorReferralDate !== null) ? dayjs(this._value.doctorReferralDate) : this._value.doctorReferralDate;
  }

  get doctorReferralBy() {
    return this._value.doctorReferralBy !== undefined && this._value.doctorReferralBy !== null ? new RemoteTaskStatusClass(this._value.doctorReferralBy) : this._value.doctorReferralBy;
  }

  get confirmedBy() {
    return this._value.confirmedBy !== undefined && this._value.confirmedBy !== null ? new MiniProviderClass(this._value.confirmedBy) : this._value.confirmedBy;
  }

  get confirmedDate() {
    return (this._value.confirmedDate !== undefined && this._value.confirmedDate !== null) ? dayjs(this._value.confirmedDate) : this._value.confirmedDate;
  }

  get callers() {
    return this._value.callers !== undefined && this._value.callers !== null ? map(this._value.callers, (v) => new MiniProviderClass(v)) : this._value.callers;
  }

  get callersWithDefault() {
    return this._value.callers || [];
  }

  get callHistories() {
    return this._value.callHistories !== undefined && this._value.callHistories !== null ? map(this._value.callHistories, (v) => new CallRecordClass(v)) : this._value.callHistories;
  }

  get callHistoriesWithDefault() {
    return this._value.callHistories || [];
  }

  get lastCallDate() {
    return (this._value.lastCallDate !== undefined && this._value.lastCallDate !== null) ? dayjs(this._value.lastCallDate) : this._value.lastCallDate;
  }

  get nutritionAssessment() {
    return this._value.nutritionAssessment !== undefined && this._value.nutritionAssessment !== null ? new RemoteTaskStatusClass(this._value.nutritionAssessment) : this._value.nutritionAssessment;
  }

  get techOnBoard() {
    return this._value.techOnBoard !== undefined && this._value.techOnBoard !== null ? new RemoteTaskStatusClass(this._value.techOnBoard) : this._value.techOnBoard;
  }

  get deviceDelivered() {
    return this._value.deviceDelivered !== undefined && this._value.deviceDelivered !== null ? new RemoteTaskStatusClass(this._value.deviceDelivered) : this._value.deviceDelivered;
  }

  get shippingInfo() {
    return this._value.shippingInfo !== undefined && this._value.shippingInfo !== null ? new ShippingInfoClass(this._value.shippingInfo) : this._value.shippingInfo;
  }

  get carePlanFiled() {
    return this._value.carePlanFiled !== undefined && this._value.carePlanFiled !== null ? new RemoteTaskStatusClass(this._value.carePlanFiled) : this._value.carePlanFiled;
  }

  get consentFiled() {
    return this._value.consentFiled !== undefined && this._value.consentFiled !== null ? new RemoteTaskStatusClass(this._value.consentFiled) : this._value.consentFiled;
  }

  get billingCodeAttached() {
    return this._value.billingCodeAttached !== undefined && this._value.billingCodeAttached !== null ? new RemoteTaskStatusClass(this._value.billingCodeAttached) : this._value.billingCodeAttached;
  }

  get pinnedToEMR() {
    return this._value.pinnedToEMR !== undefined && this._value.pinnedToEMR !== null ? new RemoteTaskStatusClass(this._value.pinnedToEMR) : this._value.pinnedToEMR;
  }

  get createdBy() {
    return this._value.createdBy !== undefined && this._value.createdBy !== null ? new MiniProviderClass(this._value.createdBy) : this._value.createdBy;
  }

  get updatedBy() {
    return this._value.updatedBy !== undefined && this._value.updatedBy !== null ? new MiniProviderClass(this._value.updatedBy) : this._value.updatedBy;
  }

  get excludedReason() {
    return this._value.excludedReason;
  }

  get excludedReasonWithDefault() {
    return this._value.excludedReason || '';
  }

  get conditionsList() {
    return this._value.conditionsList;
  }

  get conditionsListWithDefault() {
    return this._value.conditionsList || {};
  }
}
export class RemoteEnrollmentService extends BaseController<RemoteEnrollment, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/remoteEnrollments';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const remoteEnrollmentService = new RemoteEnrollmentService();


export const createRemoteEnrollmentGetObject = (value: APIResponse<RemoteEnrollment>) => new APIResponseClass<RemoteEnrollmentClass>(value, (value: RemoteEnrollment) => new RemoteEnrollmentClass(value));
export const useRemoteEnrollmentGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<RemoteEnrollment>,
    // @ts-ignore
    typeof remoteEnrollmentService.get,
    GetOptions,
    APIResponseClass<RemoteEnrollmentClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof remoteEnrollmentService.get>) => (
      remoteEnrollmentService.get(...args)
    ),
    ResponseClassCreator: createRemoteEnrollmentGetObject,
    typeName: 'useRemoteEnrollmentGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRemoteEnrollmentGet.mock = () => new Error('Mock has not been implemented!');
useRemoteEnrollmentGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRemoteEnrollmentGet.createObject = createRemoteEnrollmentGetObject;

export const createRemoteEnrollmentUpdateObject = (value: APIResponse<RemoteEnrollment>) => new APIResponseClass<RemoteEnrollmentClass>(value, (value: RemoteEnrollment) => new RemoteEnrollmentClass(value));
export const useRemoteEnrollmentUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, RemoteEnrollment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, RemoteEnrollment>,
    APIResponse<RemoteEnrollment>,
    // @ts-ignore
    typeof remoteEnrollmentService.update,
    AjaxOptions,
    APIResponseClass<RemoteEnrollmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof remoteEnrollmentService.update>) => (
      remoteEnrollmentService.update(...args)
    ),
    ResponseClassCreator: createRemoteEnrollmentUpdateObject,
    typeName: 'useRemoteEnrollmentUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRemoteEnrollmentUpdate.mock = () => new Error('Mock has not been implemented!');
useRemoteEnrollmentUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRemoteEnrollmentUpdate.createObject = createRemoteEnrollmentUpdateObject;

export const createRemoteEnrollmentInsertObject = (value: APIResponse<RemoteEnrollment>) => new APIResponseClass<RemoteEnrollmentClass>(value, (value: RemoteEnrollment) => new RemoteEnrollmentClass(value));
export const useRemoteEnrollmentInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<RemoteEnrollment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<RemoteEnrollment>,
    APIResponse<RemoteEnrollment>,
    // @ts-ignore
    typeof remoteEnrollmentService.insert,
    AjaxOptions,
    APIResponseClass<RemoteEnrollmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof remoteEnrollmentService.insert>) => (
      remoteEnrollmentService.insert(...args)
    ),
    ResponseClassCreator: createRemoteEnrollmentInsertObject,
    typeName: 'useRemoteEnrollmentInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRemoteEnrollmentInsert.mock = () => new Error('Mock has not been implemented!');
useRemoteEnrollmentInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRemoteEnrollmentInsert.createObject = createRemoteEnrollmentInsertObject;

export const createRemoteEnrollmentDeleteObject = (value: APIResponse<RemoteEnrollment>) => new APIResponseClass<RemoteEnrollmentClass>(value, (value: RemoteEnrollment) => new RemoteEnrollmentClass(value));
export const useRemoteEnrollmentDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<RemoteEnrollment>,
    // @ts-ignore
    typeof remoteEnrollmentService.delete,
    AjaxOptions,
    APIResponseClass<RemoteEnrollmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof remoteEnrollmentService.delete>) => (
      remoteEnrollmentService.delete(...args)
    ),
    ResponseClassCreator: createRemoteEnrollmentDeleteObject,
    typeName: 'useRemoteEnrollmentDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRemoteEnrollmentDelete.mock = () => new Error('Mock has not been implemented!');
useRemoteEnrollmentDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRemoteEnrollmentDelete.createObject = createRemoteEnrollmentDeleteObject;

export const createRemoteEnrollmentSearchObject = (value: APIResponse<PageResponse<RemoteEnrollment>>) => new APIResponseClass<PageResponseClass<RemoteEnrollmentClass>>(value, (value: PageResponse<RemoteEnrollment>) => new PageResponseClass<RemoteEnrollmentClass>(value, (value: RemoteEnrollment) => new RemoteEnrollmentClass(value)));
export const useRemoteEnrollmentSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<RemoteEnrollment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<RemoteEnrollment>,
    APIResponse<PageResponse<RemoteEnrollment>>,
    // @ts-ignore
    typeof remoteEnrollmentService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<RemoteEnrollmentClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof remoteEnrollmentService.search>) => (
      remoteEnrollmentService.search(...args)
    ),
    ResponseClassCreator: createRemoteEnrollmentSearchObject,
    typeName: 'useRemoteEnrollmentSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRemoteEnrollmentSearch.mock = () => new Error('Mock has not been implemented!');
useRemoteEnrollmentSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRemoteEnrollmentSearch.createObject = createRemoteEnrollmentSearchObject;

export enum RewardActionEnum {
	VIDEO = 'VIDEO',
	DISCOUNT = 'DISCOUNT',
	THIRD_PARTY_DISCOUNT = 'THIRD_PARTY_DISCOUNT',
	PHYSICAL_GIFT = 'PHYSICAL_GIFT',
}
export interface RewardAuthor {
	id?: Nullable<string>;
	name?: Nullable<string>;
	role?: Nullable<string>;
	info?: Nullable<string>;
	avatarUrl?: Nullable<string>;
}
export interface MicroClass {
	duration?: Nullable<number>;
	description?: Nullable<string>;
	content?: Nullable<string>;
	fileInfo?: Nullable<FileInfo>;
	fileUrl?: Nullable<string>;
}
export interface DiscountInfo {
	discount?: Nullable<number>;
	productInclude?: Nullable<string>;
	productExclude?: Nullable<string>;
	storeLink?: Nullable<string>;
	shopyfiyUrl?: Nullable<string>;
	ruleId?: Nullable<string>;
}
export interface DiscountCode {
	code?: Nullable<string>;
	status?: Nullable<string>;
}
export interface DateSetting {
	startTime?: Nullable<string>;
	expireTime?: Nullable<string>;
}
export interface RewardRedeemParentTemplate extends BaseModel {
	title?: Nullable<string>;
	subTitle?: Nullable<string>;
	isValid?: Nullable<boolean>;
	category?: Nullable<string>;
	total?: Nullable<number>;
	views?: Nullable<number>;
}
export interface RewardRedeemTemplate extends BaseModel {
	title?: Nullable<string>;
	isValid?: Nullable<boolean>;
	category?: Nullable<string>;
	action?: Nullable<RewardActionEnum>;
	author?: Nullable<RewardAuthor>;
	subTitle?: Nullable<string>;
	parentTemplateId?: Nullable<string>;
	language?: Nullable<string>;
	points?: Nullable<number>;
	originalPoints?: Nullable<number>;
	episodeNumber?: Nullable<number>;
	tags?: Nullable<string>;
	isNewReward?: Nullable<boolean>;
	iconFileInfo?: Nullable<FileInfo>;
	iconUrl?: Nullable<string>;
	microClass?: Nullable<MicroClass>;
	images?: Nullable<string[]>;
	views?: Nullable<number>;
	remaining?: Nullable<number>;
	discountInfo?: Nullable<DiscountInfo>;
	discountCode?: Nullable<DiscountCode>;
	dateSetting?: Nullable<DateSetting>;
	parentTemplate?: Nullable<RewardRedeemParentTemplate>;
	categoryIn?: Nullable<InFilter<string>>;
	groupBy?: Nullable<string>;
}
export interface RewardRedeem extends BaseModel {
	memberId?: Nullable<string>;
	redeemDate?: Nullable<string>;
	templateId?: Nullable<string>;
	parentTemplateId?: Nullable<string>;
	rewardRedeemTemplate?: Nullable<RewardRedeemTemplate>;
	timezone?: Nullable<string>;
	discountCode?: Nullable<DiscountCode>;
	discountInfo?: Nullable<DiscountInfo>;
	shippingInfo?: Nullable<ShipTo>;
	log?: Nullable<string>;
	source?: Nullable<string>;
}
export class RewardAuthorClass {
  protected readonly _value: RewardAuthor;

	constructor(value: RewardAuthor) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get role() {
    return this._value.role;
  }

  get roleWithDefault() {
    return this._value.role || '';
  }

  get info() {
    return this._value.info;
  }

  get infoWithDefault() {
    return this._value.info || '';
  }

  get avatarUrl() {
    return this._value.avatarUrl;
  }

  get avatarUrlWithDefault() {
    return this._value.avatarUrl || '';
  }
}
export class MicroClassClass {
  protected readonly _value: MicroClass;

	constructor(value: MicroClass) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get duration() {
    return this._value.duration;
  }

  get durationWithDefault() {
    return this._value.duration || 0;
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get content() {
    return this._value.content;
  }

  get contentWithDefault() {
    return this._value.content || '';
  }

  get fileInfo() {
    return this._value.fileInfo !== undefined && this._value.fileInfo !== null ? new FileInfoClass(this._value.fileInfo) : this._value.fileInfo;
  }

  get fileUrl() {
    return this._value.fileUrl;
  }

  get fileUrlWithDefault() {
    return this._value.fileUrl || '';
  }
}
export class DiscountInfoClass {
  protected readonly _value: DiscountInfo;

	constructor(value: DiscountInfo) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get discount() {
    return this._value.discount;
  }

  get discountWithDefault() {
    return this._value.discount || 0;
  }

  get productInclude() {
    return this._value.productInclude;
  }

  get productIncludeWithDefault() {
    return this._value.productInclude || '';
  }

  get productExclude() {
    return this._value.productExclude;
  }

  get productExcludeWithDefault() {
    return this._value.productExclude || '';
  }

  get storeLink() {
    return this._value.storeLink;
  }

  get storeLinkWithDefault() {
    return this._value.storeLink || '';
  }

  get shopyfiyUrl() {
    return this._value.shopyfiyUrl;
  }

  get shopyfiyUrlWithDefault() {
    return this._value.shopyfiyUrl || '';
  }

  get ruleId() {
    return this._value.ruleId;
  }

  get ruleIdWithDefault() {
    return this._value.ruleId || '';
  }
}
export class DiscountCodeClass {
  protected readonly _value: DiscountCode;

	constructor(value: DiscountCode) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get code() {
    return this._value.code;
  }

  get codeWithDefault() {
    return this._value.code || '';
  }

  get status() {
    return this._value.status;
  }

  get statusWithDefault() {
    return this._value.status || '';
  }
}
export class DateSettingClass {
  protected readonly _value: DateSetting;

	constructor(value: DateSetting) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get startTime() {
    return (this._value.startTime !== undefined && this._value.startTime !== null) ? dayjs(this._value.startTime) : this._value.startTime;
  }

  get expireTime() {
    return (this._value.expireTime !== undefined && this._value.expireTime !== null) ? dayjs(this._value.expireTime) : this._value.expireTime;
  }
}
export class RewardRedeemParentTemplateClass extends BaseModelClass {
  protected readonly _value: RewardRedeemParentTemplate;

	constructor(value: RewardRedeemParentTemplate) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get title() {
    return this._value.title;
  }

  get titleWithDefault() {
    return this._value.title || '';
  }

  get subTitle() {
    return this._value.subTitle;
  }

  get subTitleWithDefault() {
    return this._value.subTitle || '';
  }

  get isValid() {
    return this._value.isValid;
  }

  get isValidWithDefault() {
    return this._value.isValid || false;
  }

  get category() {
    return this._value.category;
  }

  get categoryWithDefault() {
    return this._value.category || '';
  }

  get total() {
    return this._value.total;
  }

  get totalWithDefault() {
    return this._value.total || 0;
  }

  get views() {
    return this._value.views;
  }

  get viewsWithDefault() {
    return this._value.views || 0;
  }
}
export class RewardRedeemTemplateClass extends BaseModelClass {
  protected readonly _value: RewardRedeemTemplate;

	constructor(value: RewardRedeemTemplate) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get title() {
    return this._value.title;
  }

  get titleWithDefault() {
    return this._value.title || '';
  }

  get isValid() {
    return this._value.isValid;
  }

  get isValidWithDefault() {
    return this._value.isValid || false;
  }

  get category() {
    return this._value.category;
  }

  get categoryWithDefault() {
    return this._value.category || '';
  }

  get action() {
    return this._value.action;
  }

  actionIsIn(values: RewardActionEnum[] = []) {
    return this._value.action && values.includes(this._value.action);
  }

  get author() {
    return this._value.author !== undefined && this._value.author !== null ? new RewardAuthorClass(this._value.author) : this._value.author;
  }

  get subTitle() {
    return this._value.subTitle;
  }

  get subTitleWithDefault() {
    return this._value.subTitle || '';
  }

  get parentTemplateId() {
    return this._value.parentTemplateId;
  }

  get language() {
    return this._value.language;
  }

  get languageWithDefault() {
    return this._value.language || '';
  }

  get points() {
    return this._value.points;
  }

  get pointsWithDefault() {
    return this._value.points || 0;
  }

  get originalPoints() {
    return this._value.originalPoints;
  }

  get originalPointsWithDefault() {
    return this._value.originalPoints || 0;
  }

  get episodeNumber() {
    return this._value.episodeNumber;
  }

  get episodeNumberWithDefault() {
    return this._value.episodeNumber || 0;
  }

  get tags() {
    return this._value.tags;
  }

  get tagsWithDefault() {
    return this._value.tags || '';
  }

  get isNewReward() {
    return this._value.isNewReward;
  }

  get isNewRewardWithDefault() {
    return this._value.isNewReward || false;
  }

  get iconFileInfo() {
    return this._value.iconFileInfo !== undefined && this._value.iconFileInfo !== null ? new FileInfoClass(this._value.iconFileInfo) : this._value.iconFileInfo;
  }

  get iconUrl() {
    return this._value.iconUrl;
  }

  get iconUrlWithDefault() {
    return this._value.iconUrl || '';
  }

  get microClass() {
    return this._value.microClass !== undefined && this._value.microClass !== null ? new MicroClassClass(this._value.microClass) : this._value.microClass;
  }

  get images() {
    return this._value.images;
  }

  get imagesWithDefault() {
    return this._value.images || [];
  }

  get views() {
    return this._value.views;
  }

  get viewsWithDefault() {
    return this._value.views || 0;
  }

  get remaining() {
    return this._value.remaining;
  }

  get remainingWithDefault() {
    return this._value.remaining || 0;
  }

  get discountInfo() {
    return this._value.discountInfo !== undefined && this._value.discountInfo !== null ? new DiscountInfoClass(this._value.discountInfo) : this._value.discountInfo;
  }

  get discountCode() {
    return this._value.discountCode !== undefined && this._value.discountCode !== null ? new DiscountCodeClass(this._value.discountCode) : this._value.discountCode;
  }

  get dateSetting() {
    return this._value.dateSetting !== undefined && this._value.dateSetting !== null ? new DateSettingClass(this._value.dateSetting) : this._value.dateSetting;
  }

  get parentTemplate() {
    return this._value.parentTemplate !== undefined && this._value.parentTemplate !== null ? new RewardRedeemParentTemplateClass(this._value.parentTemplate) : this._value.parentTemplate;
  }

  get categoryIn() {
    return this._value.categoryIn !== undefined && this._value.categoryIn !== null ? new InFilterClass(this._value.categoryIn) : this._value.categoryIn;
  }

  get groupBy() {
    return this._value.groupBy;
  }

  get groupByWithDefault() {
    return this._value.groupBy || '';
  }
}
export class RewardRedeemClass extends BaseModelClass {
  protected readonly _value: RewardRedeem;

	constructor(value: RewardRedeem) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get redeemDate() {
    return (this._value.redeemDate !== undefined && this._value.redeemDate !== null) ? dayjs(this._value.redeemDate) : this._value.redeemDate;
  }

  get templateId() {
    return this._value.templateId;
  }

  get parentTemplateId() {
    return this._value.parentTemplateId;
  }

  get rewardRedeemTemplate() {
    return this._value.rewardRedeemTemplate !== undefined && this._value.rewardRedeemTemplate !== null ? new RewardRedeemTemplateClass(this._value.rewardRedeemTemplate) : this._value.rewardRedeemTemplate;
  }

  get timezone() {
    return this._value.timezone;
  }

  get timezoneWithDefault() {
    return this._value.timezone || '';
  }

  get discountCode() {
    return this._value.discountCode !== undefined && this._value.discountCode !== null ? new DiscountCodeClass(this._value.discountCode) : this._value.discountCode;
  }

  get discountInfo() {
    return this._value.discountInfo !== undefined && this._value.discountInfo !== null ? new DiscountInfoClass(this._value.discountInfo) : this._value.discountInfo;
  }

  get shippingInfo() {
    return this._value.shippingInfo !== undefined && this._value.shippingInfo !== null ? new ShipToClass(this._value.shippingInfo) : this._value.shippingInfo;
  }

  get log() {
    return this._value.log;
  }

  get logWithDefault() {
    return this._value.log || '';
  }

  get source() {
    return this._value.source;
  }

  get sourceWithDefault() {
    return this._value.source || '';
  }
}
export interface RewardSurvey {
	rating?: Nullable<number>;
	question?: Nullable<string>;
}
export enum RedeemSurveyStatus {
	SUBMITTED = 'SUBMITTED',
	CLOSED = 'CLOSED',
}
export interface RewardRedeemSurvey extends BaseModel {
	surveys?: Nullable<RewardSurvey[]>;
	memberId?: Nullable<string>;
	parentTemplateId?: Nullable<string>;
	status?: Nullable<RedeemSurveyStatus>;
}
export class RewardSurveyClass {
  protected readonly _value: RewardSurvey;

	constructor(value: RewardSurvey) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get rating() {
    return this._value.rating;
  }

  get ratingWithDefault() {
    return this._value.rating || 0;
  }

  get question() {
    return this._value.question;
  }

  get questionWithDefault() {
    return this._value.question || '';
  }
}
export class RewardRedeemSurveyClass extends BaseModelClass {
  protected readonly _value: RewardRedeemSurvey;

	constructor(value: RewardRedeemSurvey) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get surveys() {
    return this._value.surveys !== undefined && this._value.surveys !== null ? map(this._value.surveys, (v) => new RewardSurveyClass(v)) : this._value.surveys;
  }

  get surveysWithDefault() {
    return this._value.surveys || [];
  }

  get memberId() {
    return this._value.memberId;
  }

  get parentTemplateId() {
    return this._value.parentTemplateId;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: RedeemSurveyStatus[] = []) {
    return this._value.status && values.includes(this._value.status);
  }
}
export interface RewardRedeemFindByMemberIdParams {
	memberId: string;
}

export interface RewardRedeemInsertSurveyParams {
	t: RewardRedeemSurvey;
}

export class RewardRedeemService extends BaseController<RewardRedeem, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/reward-redeem';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public findByMemberId({ baseUrl, params, headers }: RequestOption<RewardRedeemFindByMemberIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<RewardRedeem[]>>(`${baseUrl || this.baseURL}${this.basePath}/list/${params.memberId}${getQueryStrings(queryList)}`, { headers });
	}

	public insertSurvey({ baseUrl, params, headers }: RequestOption<RewardRedeemInsertSurveyParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<RewardRedeemSurvey>>(`${baseUrl || this.baseURL}${this.basePath}/survey${getQueryStrings(queryList)}`, params.t, { headers });
	}
}

export const rewardRedeemService = new RewardRedeemService();

export const createRewardRedeemFindByMemberIdObject = (value: APIResponse<RewardRedeem[]>) => new APIResponseClass<RewardRedeemClass[]>(value, (v) => map(v, (value: RewardRedeem) => new RewardRedeemClass(value)));

export const useRewardRedeemFindByMemberId = <RCN = undefined>(configs: MakeRequestHookConfig<RewardRedeemFindByMemberIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RewardRedeemFindByMemberIdParams,
    APIResponse<RewardRedeem[]>,
    typeof rewardRedeemService.findByMemberId,
    GetOptions,
    APIResponseClass<RewardRedeemClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof rewardRedeemService.findByMemberId>) => (
      rewardRedeemService.findByMemberId(...args)
    ),
    ResponseClassCreator: createRewardRedeemFindByMemberIdObject,
    typeName: 'useRewardRedeemFindByMemberId',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRewardRedeemFindByMemberId.mock = () => new Error('Mock has not been implemented!');
useRewardRedeemFindByMemberId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRewardRedeemFindByMemberId.createObject = createRewardRedeemFindByMemberIdObject;

export const createRewardRedeemInsertSurveyObject = (value: APIResponse<RewardRedeemSurvey>) => new APIResponseClass<RewardRedeemSurveyClass>(value, (value: RewardRedeemSurvey) => new RewardRedeemSurveyClass(value));

export const useRewardRedeemInsertSurvey = <RCN = undefined>(configs: MakeRequestHookConfig<RewardRedeemInsertSurveyParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RewardRedeemInsertSurveyParams,
    APIResponse<RewardRedeemSurvey>,
    typeof rewardRedeemService.insertSurvey,
    AjaxOptions,
    APIResponseClass<RewardRedeemSurveyClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof rewardRedeemService.insertSurvey>) => (
      rewardRedeemService.insertSurvey(...args)
    ),
    ResponseClassCreator: createRewardRedeemInsertSurveyObject,
    typeName: 'useRewardRedeemInsertSurvey',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRewardRedeemInsertSurvey.mock = () => new Error('Mock has not been implemented!');
useRewardRedeemInsertSurvey.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRewardRedeemInsertSurvey.createObject = createRewardRedeemInsertSurveyObject;


export const createRewardRedeemGetObject = (value: APIResponse<RewardRedeem>) => new APIResponseClass<RewardRedeemClass>(value, (value: RewardRedeem) => new RewardRedeemClass(value));
export const useRewardRedeemGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<RewardRedeem>,
    // @ts-ignore
    typeof rewardRedeemService.get,
    GetOptions,
    APIResponseClass<RewardRedeemClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof rewardRedeemService.get>) => (
      rewardRedeemService.get(...args)
    ),
    ResponseClassCreator: createRewardRedeemGetObject,
    typeName: 'useRewardRedeemGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRewardRedeemGet.mock = () => new Error('Mock has not been implemented!');
useRewardRedeemGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRewardRedeemGet.createObject = createRewardRedeemGetObject;

export const createRewardRedeemUpdateObject = (value: APIResponse<RewardRedeem>) => new APIResponseClass<RewardRedeemClass>(value, (value: RewardRedeem) => new RewardRedeemClass(value));
export const useRewardRedeemUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, RewardRedeem>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, RewardRedeem>,
    APIResponse<RewardRedeem>,
    // @ts-ignore
    typeof rewardRedeemService.update,
    AjaxOptions,
    APIResponseClass<RewardRedeemClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof rewardRedeemService.update>) => (
      rewardRedeemService.update(...args)
    ),
    ResponseClassCreator: createRewardRedeemUpdateObject,
    typeName: 'useRewardRedeemUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRewardRedeemUpdate.mock = () => new Error('Mock has not been implemented!');
useRewardRedeemUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRewardRedeemUpdate.createObject = createRewardRedeemUpdateObject;

export const createRewardRedeemInsertObject = (value: APIResponse<RewardRedeem>) => new APIResponseClass<RewardRedeemClass>(value, (value: RewardRedeem) => new RewardRedeemClass(value));
export const useRewardRedeemInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<RewardRedeem>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<RewardRedeem>,
    APIResponse<RewardRedeem>,
    // @ts-ignore
    typeof rewardRedeemService.insert,
    AjaxOptions,
    APIResponseClass<RewardRedeemClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof rewardRedeemService.insert>) => (
      rewardRedeemService.insert(...args)
    ),
    ResponseClassCreator: createRewardRedeemInsertObject,
    typeName: 'useRewardRedeemInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRewardRedeemInsert.mock = () => new Error('Mock has not been implemented!');
useRewardRedeemInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRewardRedeemInsert.createObject = createRewardRedeemInsertObject;

export const createRewardRedeemDeleteObject = (value: APIResponse<RewardRedeem>) => new APIResponseClass<RewardRedeemClass>(value, (value: RewardRedeem) => new RewardRedeemClass(value));
export const useRewardRedeemDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<RewardRedeem>,
    // @ts-ignore
    typeof rewardRedeemService.delete,
    AjaxOptions,
    APIResponseClass<RewardRedeemClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof rewardRedeemService.delete>) => (
      rewardRedeemService.delete(...args)
    ),
    ResponseClassCreator: createRewardRedeemDeleteObject,
    typeName: 'useRewardRedeemDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRewardRedeemDelete.mock = () => new Error('Mock has not been implemented!');
useRewardRedeemDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRewardRedeemDelete.createObject = createRewardRedeemDeleteObject;

export const createRewardRedeemSearchObject = (value: APIResponse<PageResponse<RewardRedeem>>) => new APIResponseClass<PageResponseClass<RewardRedeemClass>>(value, (value: PageResponse<RewardRedeem>) => new PageResponseClass<RewardRedeemClass>(value, (value: RewardRedeem) => new RewardRedeemClass(value)));
export const useRewardRedeemSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<RewardRedeem>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<RewardRedeem>,
    APIResponse<PageResponse<RewardRedeem>>,
    // @ts-ignore
    typeof rewardRedeemService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<RewardRedeemClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof rewardRedeemService.search>) => (
      rewardRedeemService.search(...args)
    ),
    ResponseClassCreator: createRewardRedeemSearchObject,
    typeName: 'useRewardRedeemSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRewardRedeemSearch.mock = () => new Error('Mock has not been implemented!');
useRewardRedeemSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRewardRedeemSearch.createObject = createRewardRedeemSearchObject;

export interface MemberItem {
	id?: Nullable<string>;
	roleType?: Nullable<RoleTypeEnum>;
	patientNum?: Nullable<number>;
	capacity?: Nullable<number>;
	occupancyRate?: Nullable<number>;
	employee?: Nullable<Employee>;
	organization?: Nullable<UcOrganization>;
	clinic?: Nullable<Clinic>;
	clinicList?: Nullable<Clinic[]>;
}
export class MemberItemClass {
  protected readonly _value: MemberItem;

	constructor(value: MemberItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get roleType() {
    return this._value.roleType;
  }

  roleTypeIsIn(values: RoleTypeEnum[] = []) {
    return this._value.roleType && values.includes(this._value.roleType);
  }

  get patientNum() {
    return this._value.patientNum;
  }

  get patientNumWithDefault() {
    return this._value.patientNum || 0;
  }

  get capacity() {
    return this._value.capacity;
  }

  get capacityWithDefault() {
    return this._value.capacity || 0;
  }

  get occupancyRate() {
    return this._value.occupancyRate;
  }

  get occupancyRateWithDefault() {
    return this._value.occupancyRate || 0.0;
  }

  get employee() {
    return this._value.employee !== undefined && this._value.employee !== null ? new EmployeeClass(this._value.employee) : this._value.employee;
  }

  get organization() {
    return this._value.organization !== undefined && this._value.organization !== null ? new UcOrganizationClass(this._value.organization) : this._value.organization;
  }

  get clinic() {
    return this._value.clinic !== undefined && this._value.clinic !== null ? new ClinicClass(this._value.clinic) : this._value.clinic;
  }

  get clinicList() {
    return this._value.clinicList !== undefined && this._value.clinicList !== null ? map(this._value.clinicList, (v) => new ClinicClass(v)) : this._value.clinicList;
  }

  get clinicListWithDefault() {
    return this._value.clinicList || [];
  }
}
export interface RoleAssignmentMultiUpsertRequest {
	memberId: string;
	roles: RoleOrgIds[];
}
export interface UpsertManagerByOrgRequest {
	organizationId: string;
	managerIds: string[];
}
export interface RoleAssignmentOverWriteByOrganizationRequest {
	memberId: string;
	orgId: string;
	roles: RoleTypeEnum[];
}
export interface DeactiveRoleAssignmentRequest {
	userId?: Nullable<string>;
	orgId?: Nullable<string>;
}
export interface RoleAssignmentGetProvidersParams {
	organizationId: string;
}

export interface RoleAssignmentGetByEmployeeParams {
	memberId: string;
}

export interface RoleAssignmentGetManagersParams {
	organizationId: string;
}

export interface RoleAssignmentListOrganizationMembersParams {
	searchRequest: SearchRequest<EmployeeFilter>;
}

export interface RoleAssignmentListClinicStaffsParams {
	searchRequest: SearchRequest<EmployeeFilter>;
}

export interface RoleAssignmentMultiUpsertParams {
	request: RoleAssignmentMultiUpsertRequest;
}

export interface RoleAssignmentUpsertManagerByOrganizationParams {
	request: UpsertManagerByOrgRequest;
}

export interface RoleAssignmentInsertMultiParams {
	request: RoleAssignmentMultiUpsertRequest[];
}

export interface RoleAssignmentOverwriteCurrentRolesInOrganizationParams {
	request: RoleAssignmentOverWriteByOrganizationRequest;
}

export interface RoleAssignmentOverwriteCurrentRolesParams {
	request: RoleAssignmentMultiUpsertRequest;
}

export interface RoleAssignmentDeactivateRoleAssignmentParams {
	request: DeactiveRoleAssignmentRequest;
}

export class RoleAssignmentService extends BaseController<RoleAssignment, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/role-assignments';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getProviders({ baseUrl, params, headers }: RequestOption<RoleAssignmentGetProvidersParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<LimitedUser[]>>(`${baseUrl || this.baseURL}${this.basePath}/getProviders/${params.organizationId}${getQueryStrings(queryList)}`, { headers });
	}

	public getByEmployee({ baseUrl, params, headers }: RequestOption<RoleAssignmentGetByEmployeeParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<MemberItem[]>>(`${baseUrl || this.baseURL}${this.basePath}/getByEmployee/${params.memberId}${getQueryStrings(queryList)}`, { headers });
	}

	public getManagers({ baseUrl, params, headers }: RequestOption<RoleAssignmentGetManagersParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<Employee[]>>(`${baseUrl || this.baseURL}${this.basePath}/getManagers/${params.organizationId}${getQueryStrings(queryList)}`, { headers });
	}

	public listOrganizationMembers({ baseUrl, params, headers }: RequestOption<RoleAssignmentListOrganizationMembersParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<MemberItem>>>(`${baseUrl || this.baseURL}${this.basePath}/list/members${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public listClinicStaffs({ baseUrl, params, headers }: RequestOption<RoleAssignmentListClinicStaffsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<MemberItem>>>(`${baseUrl || this.baseURL}${this.basePath}/list/staffs${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public multiUpsert({ baseUrl, params, headers }: RequestOption<RoleAssignmentMultiUpsertParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<RoleAssignment[]>>(`${baseUrl || this.baseURL}${this.basePath}/multi-upsert${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public upsertManagerByOrganization({ baseUrl, params, headers }: RequestOption<RoleAssignmentUpsertManagerByOrganizationParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<RoleAssignment[]>>(`${baseUrl || this.baseURL}${this.basePath}/upsertManagerByOrganization${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public insertMulti({ baseUrl, params, headers }: RequestOption<RoleAssignmentInsertMultiParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<RoleAssignment[]>>(`${baseUrl || this.baseURL}${this.basePath}/insert-multi${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public overwriteCurrentRolesInOrganization({ baseUrl, params, headers }: RequestOption<RoleAssignmentOverwriteCurrentRolesInOrganizationParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<RoleAssignment[]>>(`${baseUrl || this.baseURL}${this.basePath}/overwrite-organization${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public overwriteCurrentRoles({ baseUrl, params, headers }: RequestOption<RoleAssignmentOverwriteCurrentRolesParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<RoleAssignment[]>>(`${baseUrl || this.baseURL}${this.basePath}/overwrite${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public deactivateRoleAssignment({ baseUrl, params, headers }: RequestOption<RoleAssignmentDeactivateRoleAssignmentParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<RoleAssignment[]>>(`${baseUrl || this.baseURL}${this.basePath}/deactivate-role-assignment${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const roleAssignmentService = new RoleAssignmentService();

export const createRoleAssignmentGetProvidersObject = (value: APIResponse<LimitedUser[]>) => new APIResponseClass<LimitedUserClass[]>(value, (v) => map(v, (value: LimitedUser) => new LimitedUserClass(value)));

export const useRoleAssignmentGetProviders = <RCN = undefined>(configs: MakeRequestHookConfig<RoleAssignmentGetProvidersParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RoleAssignmentGetProvidersParams,
    APIResponse<LimitedUser[]>,
    typeof roleAssignmentService.getProviders,
    GetOptions,
    APIResponseClass<LimitedUserClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof roleAssignmentService.getProviders>) => (
      roleAssignmentService.getProviders(...args)
    ),
    ResponseClassCreator: createRoleAssignmentGetProvidersObject,
    typeName: 'useRoleAssignmentGetProviders',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRoleAssignmentGetProviders.mock = () => new Error('Mock has not been implemented!');
useRoleAssignmentGetProviders.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRoleAssignmentGetProviders.createObject = createRoleAssignmentGetProvidersObject;

export const createRoleAssignmentGetByEmployeeObject = (value: APIResponse<MemberItem[]>) => new APIResponseClass<MemberItemClass[]>(value, (v) => map(v, (value: MemberItem) => new MemberItemClass(value)));

export const useRoleAssignmentGetByEmployee = <RCN = undefined>(configs: MakeRequestHookConfig<RoleAssignmentGetByEmployeeParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RoleAssignmentGetByEmployeeParams,
    APIResponse<MemberItem[]>,
    typeof roleAssignmentService.getByEmployee,
    GetOptions,
    APIResponseClass<MemberItemClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof roleAssignmentService.getByEmployee>) => (
      roleAssignmentService.getByEmployee(...args)
    ),
    ResponseClassCreator: createRoleAssignmentGetByEmployeeObject,
    typeName: 'useRoleAssignmentGetByEmployee',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRoleAssignmentGetByEmployee.mock = () => new Error('Mock has not been implemented!');
useRoleAssignmentGetByEmployee.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRoleAssignmentGetByEmployee.createObject = createRoleAssignmentGetByEmployeeObject;

export const createRoleAssignmentGetManagersObject = (value: APIResponse<Employee[]>) => new APIResponseClass<EmployeeClass[]>(value, (v) => map(v, (value: Employee) => new EmployeeClass(value)));

export const useRoleAssignmentGetManagers = <RCN = undefined>(configs: MakeRequestHookConfig<RoleAssignmentGetManagersParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RoleAssignmentGetManagersParams,
    APIResponse<Employee[]>,
    typeof roleAssignmentService.getManagers,
    GetOptions,
    APIResponseClass<EmployeeClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof roleAssignmentService.getManagers>) => (
      roleAssignmentService.getManagers(...args)
    ),
    ResponseClassCreator: createRoleAssignmentGetManagersObject,
    typeName: 'useRoleAssignmentGetManagers',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRoleAssignmentGetManagers.mock = () => new Error('Mock has not been implemented!');
useRoleAssignmentGetManagers.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRoleAssignmentGetManagers.createObject = createRoleAssignmentGetManagersObject;

export const createRoleAssignmentListOrganizationMembersObject = (value: APIResponse<PageResponse<MemberItem>>) => new APIResponseClass<PageResponseClass<MemberItemClass>>(value, (value: PageResponse<MemberItem>) => new PageResponseClass<MemberItemClass>(value, (value: MemberItem) => new MemberItemClass(value)));

export const useRoleAssignmentListOrganizationMembers = <RCN = undefined>(configs: MakeRequestHookConfig<RoleAssignmentListOrganizationMembersParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RoleAssignmentListOrganizationMembersParams,
    APIResponse<PageResponse<MemberItem>>,
    typeof roleAssignmentService.listOrganizationMembers,
    AjaxOptions,
    APIResponseClass<PageResponseClass<MemberItemClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof roleAssignmentService.listOrganizationMembers>) => (
      roleAssignmentService.listOrganizationMembers(...args)
    ),
    ResponseClassCreator: createRoleAssignmentListOrganizationMembersObject,
    typeName: 'useRoleAssignmentListOrganizationMembers',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRoleAssignmentListOrganizationMembers.mock = () => new Error('Mock has not been implemented!');
useRoleAssignmentListOrganizationMembers.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRoleAssignmentListOrganizationMembers.createObject = createRoleAssignmentListOrganizationMembersObject;

export const createRoleAssignmentListClinicStaffsObject = (value: APIResponse<PageResponse<MemberItem>>) => new APIResponseClass<PageResponseClass<MemberItemClass>>(value, (value: PageResponse<MemberItem>) => new PageResponseClass<MemberItemClass>(value, (value: MemberItem) => new MemberItemClass(value)));

export const useRoleAssignmentListClinicStaffs = <RCN = undefined>(configs: MakeRequestHookConfig<RoleAssignmentListClinicStaffsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RoleAssignmentListClinicStaffsParams,
    APIResponse<PageResponse<MemberItem>>,
    typeof roleAssignmentService.listClinicStaffs,
    AjaxOptions,
    APIResponseClass<PageResponseClass<MemberItemClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof roleAssignmentService.listClinicStaffs>) => (
      roleAssignmentService.listClinicStaffs(...args)
    ),
    ResponseClassCreator: createRoleAssignmentListClinicStaffsObject,
    typeName: 'useRoleAssignmentListClinicStaffs',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRoleAssignmentListClinicStaffs.mock = () => new Error('Mock has not been implemented!');
useRoleAssignmentListClinicStaffs.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRoleAssignmentListClinicStaffs.createObject = createRoleAssignmentListClinicStaffsObject;

export const createRoleAssignmentMultiUpsertObject = (value: APIResponse<RoleAssignment[]>) => new APIResponseClass<RoleAssignmentClass[]>(value, (v) => map(v, (value: RoleAssignment) => new RoleAssignmentClass(value)));

export const useRoleAssignmentMultiUpsert = <RCN = undefined>(configs: MakeRequestHookConfig<RoleAssignmentMultiUpsertParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RoleAssignmentMultiUpsertParams,
    APIResponse<RoleAssignment[]>,
    typeof roleAssignmentService.multiUpsert,
    AjaxOptions,
    APIResponseClass<RoleAssignmentClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof roleAssignmentService.multiUpsert>) => (
      roleAssignmentService.multiUpsert(...args)
    ),
    ResponseClassCreator: createRoleAssignmentMultiUpsertObject,
    typeName: 'useRoleAssignmentMultiUpsert',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRoleAssignmentMultiUpsert.mock = () => new Error('Mock has not been implemented!');
useRoleAssignmentMultiUpsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRoleAssignmentMultiUpsert.createObject = createRoleAssignmentMultiUpsertObject;

export const createRoleAssignmentUpsertManagerByOrganizationObject = (value: APIResponse<RoleAssignment[]>) => new APIResponseClass<RoleAssignmentClass[]>(value, (v) => map(v, (value: RoleAssignment) => new RoleAssignmentClass(value)));

export const useRoleAssignmentUpsertManagerByOrganization = <RCN = undefined>(configs: MakeRequestHookConfig<RoleAssignmentUpsertManagerByOrganizationParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RoleAssignmentUpsertManagerByOrganizationParams,
    APIResponse<RoleAssignment[]>,
    typeof roleAssignmentService.upsertManagerByOrganization,
    AjaxOptions,
    APIResponseClass<RoleAssignmentClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof roleAssignmentService.upsertManagerByOrganization>) => (
      roleAssignmentService.upsertManagerByOrganization(...args)
    ),
    ResponseClassCreator: createRoleAssignmentUpsertManagerByOrganizationObject,
    typeName: 'useRoleAssignmentUpsertManagerByOrganization',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRoleAssignmentUpsertManagerByOrganization.mock = () => new Error('Mock has not been implemented!');
useRoleAssignmentUpsertManagerByOrganization.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRoleAssignmentUpsertManagerByOrganization.createObject = createRoleAssignmentUpsertManagerByOrganizationObject;

export const createRoleAssignmentInsertMultiObject = (value: APIResponse<RoleAssignment[]>) => new APIResponseClass<RoleAssignmentClass[]>(value, (v) => map(v, (value: RoleAssignment) => new RoleAssignmentClass(value)));

export const useRoleAssignmentInsertMulti = <RCN = undefined>(configs: MakeRequestHookConfig<RoleAssignmentInsertMultiParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RoleAssignmentInsertMultiParams,
    APIResponse<RoleAssignment[]>,
    typeof roleAssignmentService.insertMulti,
    AjaxOptions,
    APIResponseClass<RoleAssignmentClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof roleAssignmentService.insertMulti>) => (
      roleAssignmentService.insertMulti(...args)
    ),
    ResponseClassCreator: createRoleAssignmentInsertMultiObject,
    typeName: 'useRoleAssignmentInsertMulti',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRoleAssignmentInsertMulti.mock = () => new Error('Mock has not been implemented!');
useRoleAssignmentInsertMulti.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRoleAssignmentInsertMulti.createObject = createRoleAssignmentInsertMultiObject;

export const createRoleAssignmentOverwriteCurrentRolesInOrganizationObject = (value: APIResponse<RoleAssignment[]>) => new APIResponseClass<RoleAssignmentClass[]>(value, (v) => map(v, (value: RoleAssignment) => new RoleAssignmentClass(value)));

export const useRoleAssignmentOverwriteCurrentRolesInOrganization = <RCN = undefined>(configs: MakeRequestHookConfig<RoleAssignmentOverwriteCurrentRolesInOrganizationParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RoleAssignmentOverwriteCurrentRolesInOrganizationParams,
    APIResponse<RoleAssignment[]>,
    typeof roleAssignmentService.overwriteCurrentRolesInOrganization,
    AjaxOptions,
    APIResponseClass<RoleAssignmentClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof roleAssignmentService.overwriteCurrentRolesInOrganization>) => (
      roleAssignmentService.overwriteCurrentRolesInOrganization(...args)
    ),
    ResponseClassCreator: createRoleAssignmentOverwriteCurrentRolesInOrganizationObject,
    typeName: 'useRoleAssignmentOverwriteCurrentRolesInOrganization',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRoleAssignmentOverwriteCurrentRolesInOrganization.mock = () => new Error('Mock has not been implemented!');
useRoleAssignmentOverwriteCurrentRolesInOrganization.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRoleAssignmentOverwriteCurrentRolesInOrganization.createObject = createRoleAssignmentOverwriteCurrentRolesInOrganizationObject;

export const createRoleAssignmentOverwriteCurrentRolesObject = (value: APIResponse<RoleAssignment[]>) => new APIResponseClass<RoleAssignmentClass[]>(value, (v) => map(v, (value: RoleAssignment) => new RoleAssignmentClass(value)));

export const useRoleAssignmentOverwriteCurrentRoles = <RCN = undefined>(configs: MakeRequestHookConfig<RoleAssignmentOverwriteCurrentRolesParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RoleAssignmentOverwriteCurrentRolesParams,
    APIResponse<RoleAssignment[]>,
    typeof roleAssignmentService.overwriteCurrentRoles,
    AjaxOptions,
    APIResponseClass<RoleAssignmentClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof roleAssignmentService.overwriteCurrentRoles>) => (
      roleAssignmentService.overwriteCurrentRoles(...args)
    ),
    ResponseClassCreator: createRoleAssignmentOverwriteCurrentRolesObject,
    typeName: 'useRoleAssignmentOverwriteCurrentRoles',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRoleAssignmentOverwriteCurrentRoles.mock = () => new Error('Mock has not been implemented!');
useRoleAssignmentOverwriteCurrentRoles.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRoleAssignmentOverwriteCurrentRoles.createObject = createRoleAssignmentOverwriteCurrentRolesObject;

export const createRoleAssignmentDeactivateRoleAssignmentObject = (value: APIResponse<RoleAssignment[]>) => new APIResponseClass<RoleAssignmentClass[]>(value, (v) => map(v, (value: RoleAssignment) => new RoleAssignmentClass(value)));

export const useRoleAssignmentDeactivateRoleAssignment = <RCN = undefined>(configs: MakeRequestHookConfig<RoleAssignmentDeactivateRoleAssignmentParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RoleAssignmentDeactivateRoleAssignmentParams,
    APIResponse<RoleAssignment[]>,
    typeof roleAssignmentService.deactivateRoleAssignment,
    AjaxOptions,
    APIResponseClass<RoleAssignmentClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof roleAssignmentService.deactivateRoleAssignment>) => (
      roleAssignmentService.deactivateRoleAssignment(...args)
    ),
    ResponseClassCreator: createRoleAssignmentDeactivateRoleAssignmentObject,
    typeName: 'useRoleAssignmentDeactivateRoleAssignment',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRoleAssignmentDeactivateRoleAssignment.mock = () => new Error('Mock has not been implemented!');
useRoleAssignmentDeactivateRoleAssignment.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRoleAssignmentDeactivateRoleAssignment.createObject = createRoleAssignmentDeactivateRoleAssignmentObject;


export const createRoleAssignmentGetObject = (value: APIResponse<RoleAssignment>) => new APIResponseClass<RoleAssignmentClass>(value, (value: RoleAssignment) => new RoleAssignmentClass(value));
export const useRoleAssignmentGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<RoleAssignment>,
    // @ts-ignore
    typeof roleAssignmentService.get,
    GetOptions,
    APIResponseClass<RoleAssignmentClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof roleAssignmentService.get>) => (
      roleAssignmentService.get(...args)
    ),
    ResponseClassCreator: createRoleAssignmentGetObject,
    typeName: 'useRoleAssignmentGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRoleAssignmentGet.mock = () => new Error('Mock has not been implemented!');
useRoleAssignmentGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRoleAssignmentGet.createObject = createRoleAssignmentGetObject;

export const createRoleAssignmentUpdateObject = (value: APIResponse<RoleAssignment>) => new APIResponseClass<RoleAssignmentClass>(value, (value: RoleAssignment) => new RoleAssignmentClass(value));
export const useRoleAssignmentUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, RoleAssignment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, RoleAssignment>,
    APIResponse<RoleAssignment>,
    // @ts-ignore
    typeof roleAssignmentService.update,
    AjaxOptions,
    APIResponseClass<RoleAssignmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof roleAssignmentService.update>) => (
      roleAssignmentService.update(...args)
    ),
    ResponseClassCreator: createRoleAssignmentUpdateObject,
    typeName: 'useRoleAssignmentUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRoleAssignmentUpdate.mock = () => new Error('Mock has not been implemented!');
useRoleAssignmentUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRoleAssignmentUpdate.createObject = createRoleAssignmentUpdateObject;

export const createRoleAssignmentInsertObject = (value: APIResponse<RoleAssignment>) => new APIResponseClass<RoleAssignmentClass>(value, (value: RoleAssignment) => new RoleAssignmentClass(value));
export const useRoleAssignmentInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<RoleAssignment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<RoleAssignment>,
    APIResponse<RoleAssignment>,
    // @ts-ignore
    typeof roleAssignmentService.insert,
    AjaxOptions,
    APIResponseClass<RoleAssignmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof roleAssignmentService.insert>) => (
      roleAssignmentService.insert(...args)
    ),
    ResponseClassCreator: createRoleAssignmentInsertObject,
    typeName: 'useRoleAssignmentInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRoleAssignmentInsert.mock = () => new Error('Mock has not been implemented!');
useRoleAssignmentInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRoleAssignmentInsert.createObject = createRoleAssignmentInsertObject;

export const createRoleAssignmentDeleteObject = (value: APIResponse<RoleAssignment>) => new APIResponseClass<RoleAssignmentClass>(value, (value: RoleAssignment) => new RoleAssignmentClass(value));
export const useRoleAssignmentDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<RoleAssignment>,
    // @ts-ignore
    typeof roleAssignmentService.delete,
    AjaxOptions,
    APIResponseClass<RoleAssignmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof roleAssignmentService.delete>) => (
      roleAssignmentService.delete(...args)
    ),
    ResponseClassCreator: createRoleAssignmentDeleteObject,
    typeName: 'useRoleAssignmentDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRoleAssignmentDelete.mock = () => new Error('Mock has not been implemented!');
useRoleAssignmentDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRoleAssignmentDelete.createObject = createRoleAssignmentDeleteObject;

export const createRoleAssignmentSearchObject = (value: APIResponse<PageResponse<RoleAssignment>>) => new APIResponseClass<PageResponseClass<RoleAssignmentClass>>(value, (value: PageResponse<RoleAssignment>) => new PageResponseClass<RoleAssignmentClass>(value, (value: RoleAssignment) => new RoleAssignmentClass(value)));
export const useRoleAssignmentSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<RoleAssignment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<RoleAssignment>,
    APIResponse<PageResponse<RoleAssignment>>,
    // @ts-ignore
    typeof roleAssignmentService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<RoleAssignmentClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof roleAssignmentService.search>) => (
      roleAssignmentService.search(...args)
    ),
    ResponseClassCreator: createRoleAssignmentSearchObject,
    typeName: 'useRoleAssignmentSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRoleAssignmentSearch.mock = () => new Error('Mock has not been implemented!');
useRoleAssignmentSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRoleAssignmentSearch.createObject = createRoleAssignmentSearchObject;

export enum MealTypeEnum {
	BEDTIME = 'BEDTIME',
	BREAKFAST = 'BREAKFAST',
	DINNER = 'DINNER',
	LUNCH = 'LUNCH',
	OVERNIGHT = 'OVERNIGHT',
	SNACK = 'SNACK',
}
export interface EventRequest {
	id?: Nullable<string>;
	measureTime?: Nullable<number>;
	timezone?: Nullable<string>;
	sbp?: Nullable<number>;
	dbp?: Nullable<number>;
	pulse?: Nullable<number>;
	triageLevel?: Nullable<BpTriageLevelEnum>;
	bgMmol?: Nullable<number>;
	mealType?: Nullable<MealTypeEnum>;
	beforeMeal?: Nullable<boolean>;
}
export interface DryRunAlertRequest {
	alertType?: Nullable<string>;
	events?: Nullable<EventRequest[]>;
}
export enum AlertStatusEnum {
	TODO = 'TODO',
	DONE = 'DONE',
	MUTE = 'MUTE',
	SILENCED = 'SILENCED',
}
export interface DryRunResult$MedicalAlert {
	alertType?: Nullable<string>;
	alertCategory?: Nullable<AlertCategoryEnum>;
	displayName?: Nullable<string>;
	description?: Nullable<string>;
	priority?: Nullable<PriorityLevelEnum>;
	dueDate?: Nullable<string>;
	measureId?: Nullable<string>;
	reason?: Nullable<string>;
	ruleId?: Nullable<string>;
	eventType?: Nullable<EventTypeEnum>;
	status?: Nullable<AlertStatusEnum>;
	measureTime?: Nullable<string>;
}
export interface Event {
	_id?: Nullable<string>;
	memberId?: Nullable<string>;
	day?: Nullable<string>;
	eventType?: Nullable<EventTypeEnum>;
	timezone?: Nullable<string>;
	date?: Nullable<string>;
}
export interface Condition {
	metric?: Nullable<string>;
	operator?: Nullable<string>;
	value?: Nullable<unknown>;
}
export enum ActionTypeEnum {
	GENERATE = 'GENERATE',
	CLOSE = 'CLOSE',
	RECHECK = 'RECHECK',
}
export interface RuleBase {
	name?: Nullable<string>;
	conditions?: Nullable<Condition[]>;
	ruleType?: Nullable<ActionTypeEnum>;
	timeWindow?: Nullable<string>;
}
export interface RuleItem extends RuleBase {
	id?: Nullable<string>;
}
export interface EventEvaluationResult$ConditionResult {
	condition?: Nullable<Condition>;
	success?: Nullable<boolean>;
	actualValue?: Nullable<unknown>;
}
export interface EventEvaluationResult$RuleResult {
	rule?: Nullable<RuleItem>;
	success?: Nullable<boolean>;
	conditionResults?: Nullable<EventEvaluationResult$ConditionResult[]>;
}
export interface EventEvaluationResult$AlertResult {
	alertType?: Nullable<string>;
	successfulRule?: Nullable<EventEvaluationResult$RuleResult>;
}
export interface EventEvaluationResult$MetricResult {
	metric?: Nullable<string>;
	actualVal?: Nullable<unknown>;
}
export interface EventEvaluationResult {
	event?: Nullable<Event>;
	alertResults?: Nullable<EventEvaluationResult$AlertResult[]>;
	metricResults?: Nullable<EventEvaluationResult$MetricResult[]>;
}
export interface DryRunResult {
	medicalAlert?: Nullable<DryRunResult$MedicalAlert>;
	eventEvaluationResult?: Nullable<EventEvaluationResult>;
}
export class DryRunResult$MedicalAlertClass {
  protected readonly _value: DryRunResult$MedicalAlert;

	constructor(value: DryRunResult$MedicalAlert) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get alertType() {
    return this._value.alertType;
  }

  get alertTypeWithDefault() {
    return this._value.alertType || '';
  }

  get alertCategory() {
    return this._value.alertCategory;
  }

  alertCategoryIsIn(values: AlertCategoryEnum[] = []) {
    return this._value.alertCategory && values.includes(this._value.alertCategory);
  }

  get displayName() {
    return this._value.displayName;
  }

  get displayNameWithDefault() {
    return this._value.displayName || '';
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get priority() {
    return this._value.priority;
  }

  priorityIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.priority && values.includes(this._value.priority);
  }

  get dueDate() {
    return (this._value.dueDate !== undefined && this._value.dueDate !== null) ? dayjs(this._value.dueDate) : this._value.dueDate;
  }

  get measureId() {
    return this._value.measureId;
  }

  get measureIdWithDefault() {
    return this._value.measureId || '';
  }

  get reason() {
    return this._value.reason;
  }

  get reasonWithDefault() {
    return this._value.reason || '';
  }

  get ruleId() {
    return this._value.ruleId;
  }

  get ruleIdWithDefault() {
    return this._value.ruleId || '';
  }

  get eventType() {
    return this._value.eventType;
  }

  eventTypeIsIn(values: EventTypeEnum[] = []) {
    return this._value.eventType && values.includes(this._value.eventType);
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: AlertStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get measureTime() {
    return (this._value.measureTime !== undefined && this._value.measureTime !== null) ? dayjs(this._value.measureTime) : this._value.measureTime;
  }
}
export class EventClass {
  protected readonly _value: Event;

	constructor(value: Event) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get _id() {
    return this._value._id;
  }

  get _idWithDefault() {
    return this._value._id || '';
  }

  get memberId() {
    return this._value.memberId;
  }

  get memberIdWithDefault() {
    return this._value.memberId || '';
  }

  get day() {
    return this._value.day;
  }

  get dayWithDefault() {
    return this._value.day || '';
  }

  get eventType() {
    return this._value.eventType;
  }

  eventTypeIsIn(values: EventTypeEnum[] = []) {
    return this._value.eventType && values.includes(this._value.eventType);
  }

  get timezone() {
    return this._value.timezone;
  }

  get timezoneWithDefault() {
    return this._value.timezone || '';
  }

  get date() {
    return (this._value.date !== undefined && this._value.date !== null) ? dayjs(this._value.date) : this._value.date;
  }
}
export class ConditionClass {
  protected readonly _value: Condition;

	constructor(value: Condition) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get metric() {
    return this._value.metric;
  }

  get metricWithDefault() {
    return this._value.metric || '';
  }

  get operator() {
    return this._value.operator;
  }

  get operatorWithDefault() {
    return this._value.operator || '';
  }

  get value() {
    return this._value.value;
  }
}
export class RuleBaseClass {
  protected readonly _value: RuleBase;

	constructor(value: RuleBase) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get conditions() {
    return this._value.conditions !== undefined && this._value.conditions !== null ? map(this._value.conditions, (v) => new ConditionClass(v)) : this._value.conditions;
  }

  get conditionsWithDefault() {
    return this._value.conditions || [];
  }

  get ruleType() {
    return this._value.ruleType;
  }

  ruleTypeIsIn(values: ActionTypeEnum[] = []) {
    return this._value.ruleType && values.includes(this._value.ruleType);
  }

  get timeWindow() {
    return this._value.timeWindow;
  }

  get timeWindowWithDefault() {
    return this._value.timeWindow || '';
  }
}
export class RuleItemClass extends RuleBaseClass {
  protected readonly _value: RuleItem;

	constructor(value: RuleItem) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }
}
export class EventEvaluationResult$ConditionResultClass {
  protected readonly _value: EventEvaluationResult$ConditionResult;

	constructor(value: EventEvaluationResult$ConditionResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get condition() {
    return this._value.condition !== undefined && this._value.condition !== null ? new ConditionClass(this._value.condition) : this._value.condition;
  }

  get success() {
    return this._value.success;
  }

  get successWithDefault() {
    return this._value.success || false;
  }

  get actualValue() {
    return this._value.actualValue;
  }
}
export class EventEvaluationResult$RuleResultClass {
  protected readonly _value: EventEvaluationResult$RuleResult;

	constructor(value: EventEvaluationResult$RuleResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get rule() {
    return this._value.rule !== undefined && this._value.rule !== null ? new RuleItemClass(this._value.rule) : this._value.rule;
  }

  get success() {
    return this._value.success;
  }

  get successWithDefault() {
    return this._value.success || false;
  }

  get conditionResults() {
    return this._value.conditionResults !== undefined && this._value.conditionResults !== null ? map(this._value.conditionResults, (v) => new EventEvaluationResult$ConditionResultClass(v)) : this._value.conditionResults;
  }

  get conditionResultsWithDefault() {
    return this._value.conditionResults || [];
  }
}
export class EventEvaluationResult$AlertResultClass {
  protected readonly _value: EventEvaluationResult$AlertResult;

	constructor(value: EventEvaluationResult$AlertResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get alertType() {
    return this._value.alertType;
  }

  get alertTypeWithDefault() {
    return this._value.alertType || '';
  }

  get successfulRule() {
    return this._value.successfulRule !== undefined && this._value.successfulRule !== null ? new EventEvaluationResult$RuleResultClass(this._value.successfulRule) : this._value.successfulRule;
  }
}
export class EventEvaluationResult$MetricResultClass {
  protected readonly _value: EventEvaluationResult$MetricResult;

	constructor(value: EventEvaluationResult$MetricResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get metric() {
    return this._value.metric;
  }

  get metricWithDefault() {
    return this._value.metric || '';
  }

  get actualVal() {
    return this._value.actualVal;
  }
}
export class EventEvaluationResultClass {
  protected readonly _value: EventEvaluationResult;

	constructor(value: EventEvaluationResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get event() {
    return this._value.event !== undefined && this._value.event !== null ? new EventClass(this._value.event) : this._value.event;
  }

  get alertResults() {
    return this._value.alertResults !== undefined && this._value.alertResults !== null ? map(this._value.alertResults, (v) => new EventEvaluationResult$AlertResultClass(v)) : this._value.alertResults;
  }

  get alertResultsWithDefault() {
    return this._value.alertResults || [];
  }

  get metricResults() {
    return this._value.metricResults !== undefined && this._value.metricResults !== null ? map(this._value.metricResults, (v) => new EventEvaluationResult$MetricResultClass(v)) : this._value.metricResults;
  }

  get metricResultsWithDefault() {
    return this._value.metricResults || [];
  }
}
export class DryRunResultClass {
  protected readonly _value: DryRunResult;

	constructor(value: DryRunResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get medicalAlert() {
    return this._value.medicalAlert !== undefined && this._value.medicalAlert !== null ? new DryRunResult$MedicalAlertClass(this._value.medicalAlert) : this._value.medicalAlert;
  }

  get eventEvaluationResult() {
    return this._value.eventEvaluationResult !== undefined && this._value.eventEvaluationResult !== null ? new EventEvaluationResultClass(this._value.eventEvaluationResult) : this._value.eventEvaluationResult;
  }
}
export enum AlertSourceType {
	CUSTOM = 'CUSTOM',
	DEFAULT = 'DEFAULT',
}
export interface AlertBase {
	alertType?: Nullable<string>;
	alertCategory?: Nullable<AlertCategoryEnum>;
	eventType?: Nullable<EventTypeEnum>;
	priority?: Nullable<PriorityLevelEnum>;
	displayName?: Nullable<string>;
	description?: Nullable<string>;
	reason?: Nullable<string>;
	alertSourceType?: Nullable<AlertSourceType>;
	active?: Nullable<boolean>;
	silencedDuration?: Nullable<string>;
}
export interface AlertDetail extends AlertBase {
	rules?: Nullable<RuleItem[]>;
}
export class AlertBaseClass {
  protected readonly _value: AlertBase;

	constructor(value: AlertBase) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get alertType() {
    return this._value.alertType;
  }

  get alertTypeWithDefault() {
    return this._value.alertType || '';
  }

  get alertCategory() {
    return this._value.alertCategory;
  }

  alertCategoryIsIn(values: AlertCategoryEnum[] = []) {
    return this._value.alertCategory && values.includes(this._value.alertCategory);
  }

  get eventType() {
    return this._value.eventType;
  }

  eventTypeIsIn(values: EventTypeEnum[] = []) {
    return this._value.eventType && values.includes(this._value.eventType);
  }

  get priority() {
    return this._value.priority;
  }

  priorityIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.priority && values.includes(this._value.priority);
  }

  get displayName() {
    return this._value.displayName;
  }

  get displayNameWithDefault() {
    return this._value.displayName || '';
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get reason() {
    return this._value.reason;
  }

  get reasonWithDefault() {
    return this._value.reason || '';
  }

  get alertSourceType() {
    return this._value.alertSourceType;
  }

  alertSourceTypeIsIn(values: AlertSourceType[] = []) {
    return this._value.alertSourceType && values.includes(this._value.alertSourceType);
  }

  get active() {
    return this._value.active;
  }

  get activeWithDefault() {
    return this._value.active || false;
  }

  get silencedDuration() {
    return this._value.silencedDuration;
  }

  get silencedDurationWithDefault() {
    return this._value.silencedDuration || '';
  }
}
export class AlertDetailClass extends AlertBaseClass {
  protected readonly _value: AlertDetail;

	constructor(value: AlertDetail) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get rules() {
    return this._value.rules !== undefined && this._value.rules !== null ? map(this._value.rules, (v) => new RuleItemClass(v)) : this._value.rules;
  }

  get rulesWithDefault() {
    return this._value.rules || [];
  }
}
export enum MetricTypeEnum {
	RAW_VALUE = 'RAW_VALUE',
	AGGREGATION = 'AGGREGATION',
}
export enum MetricValueTypeEnum {
	INT = 'INT',
	DOUBLE = 'DOUBLE',
	STRING = 'STRING',
	DATE = 'DATE',
	LIST = 'LIST',
	BOOLEAN = 'BOOLEAN',
}
export interface MetricItem$Filter {
	name?: Nullable<string>;
	value?: Nullable<unknown>;
	operator?: Nullable<string>;
}
export interface MetricItem {
	name?: Nullable<string>;
	description?: Nullable<string>;
	metricType?: Nullable<MetricTypeEnum>;
	supportedValues?: Nullable<string[]>;
	metricValueType?: Nullable<MetricValueTypeEnum>;
	field?: Nullable<string>;
	supportedOperators?: Nullable<string[]>;
	filters?: Nullable<MetricItem$Filter[]>;
}
export class MetricItem$FilterClass {
  protected readonly _value: MetricItem$Filter;

	constructor(value: MetricItem$Filter) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get value() {
    return this._value.value;
  }

  get operator() {
    return this._value.operator;
  }

  get operatorWithDefault() {
    return this._value.operator || '';
  }
}
export class MetricItemClass {
  protected readonly _value: MetricItem;

	constructor(value: MetricItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get metricType() {
    return this._value.metricType;
  }

  metricTypeIsIn(values: MetricTypeEnum[] = []) {
    return this._value.metricType && values.includes(this._value.metricType);
  }

  get supportedValues() {
    return this._value.supportedValues;
  }

  get supportedValuesWithDefault() {
    return this._value.supportedValues || [];
  }

  get metricValueType() {
    return this._value.metricValueType;
  }

  metricValueTypeIsIn(values: MetricValueTypeEnum[] = []) {
    return this._value.metricValueType && values.includes(this._value.metricValueType);
  }

  get field() {
    return this._value.field;
  }

  get fieldWithDefault() {
    return this._value.field || '';
  }

  get supportedOperators() {
    return this._value.supportedOperators;
  }

  get supportedOperatorsWithDefault() {
    return this._value.supportedOperators || [];
  }

  get filters() {
    return this._value.filters !== undefined && this._value.filters !== null ? map(this._value.filters, (v) => new MetricItem$FilterClass(v)) : this._value.filters;
  }

  get filtersWithDefault() {
    return this._value.filters || [];
  }
}
export interface IdsRequest {
	ids?: Nullable<string[]>;
}
export interface ClinicConfigRequest {
	clinicId?: Nullable<string>;
	alertTypes?: Nullable<string[]>;
}
export interface BaseResponse {
	msg?: Nullable<string>;
}
export class BaseResponseClass {
  protected readonly _value: BaseResponse;

	constructor(value: BaseResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get msg() {
    return this._value.msg;
  }

  get msgWithDefault() {
    return this._value.msg || '';
  }
}
export interface AlertDisplay {
	alertCategory?: Nullable<AlertCategoryEnum>;
	displayName?: Nullable<string>;
	alertType?: Nullable<string>;
}
export class AlertDisplayClass {
  protected readonly _value: AlertDisplay;

	constructor(value: AlertDisplay) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get alertCategory() {
    return this._value.alertCategory;
  }

  alertCategoryIsIn(values: AlertCategoryEnum[] = []) {
    return this._value.alertCategory && values.includes(this._value.alertCategory);
  }

  get displayName() {
    return this._value.displayName;
  }

  get displayNameWithDefault() {
    return this._value.displayName || '';
  }

  get alertType() {
    return this._value.alertType;
  }

  get alertTypeWithDefault() {
    return this._value.alertType || '';
  }
}
export interface CustomAlertCreateRequest {
	clinicId?: Nullable<string>;
	displayName?: Nullable<string>;
	alertCategory: AlertCategoryEnum;
	eventType: EventTypeEnum;
	priority: PriorityLevelEnum;
	active?: Nullable<boolean>;
	silencedDuration?: Nullable<string>;
	description?: Nullable<string>;
	rules?: Nullable<RuleBase[]>;
}
export interface CustomAlertUpdateRequest {
	clinicId?: Nullable<string>;
	alertType?: Nullable<string>;
	displayName?: Nullable<string>;
	priority: PriorityLevelEnum;
	active?: Nullable<boolean>;
	silencedDuration?: Nullable<string>;
	description?: Nullable<string>;
	rules?: Nullable<RuleItem[]>;
}
export interface DryRunOnTheFlyRequest {
	alert?: Nullable<AlertDetail>;
	events?: Nullable<EventRequest[]>;
}
export interface DryRunRuleRequest {
	ruleId?: Nullable<string>;
	events?: Nullable<EventRequest[]>;
}
export interface DryRunRuleResult {
	metricResults?: Nullable<EventEvaluationResult$MetricResult[]>;
	ruleResult?: Nullable<EventEvaluationResult$RuleResult>;
	event?: Nullable<Event>;
}
export class DryRunRuleResultClass {
  protected readonly _value: DryRunRuleResult;

	constructor(value: DryRunRuleResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get metricResults() {
    return this._value.metricResults !== undefined && this._value.metricResults !== null ? map(this._value.metricResults, (v) => new EventEvaluationResult$MetricResultClass(v)) : this._value.metricResults;
  }

  get metricResultsWithDefault() {
    return this._value.metricResults || [];
  }

  get ruleResult() {
    return this._value.ruleResult !== undefined && this._value.ruleResult !== null ? new EventEvaluationResult$RuleResultClass(this._value.ruleResult) : this._value.ruleResult;
  }

  get event() {
    return this._value.event !== undefined && this._value.event !== null ? new EventClass(this._value.event) : this._value.event;
  }
}
export interface DryRunRuleOnTheFlyRequest {
	rule?: Nullable<RuleItem>;
	eventType?: Nullable<EventTypeEnum>;
	events?: Nullable<EventRequest[]>;
}
export interface RuleEngineDryRunParams {
	request: DryRunAlertRequest;
}

export interface RuleEngineAddRuleParams {
	alertType: string;
	rule: RuleBase;
}

export interface RuleEngineGetAllMetricsParams {
	eventType?: EventTypeEnum[];
}

export interface RuleEngineUpdateRuleParams {
	alertType: string;
	rule: RuleItem;
}

export interface RuleEngineDeleteRuleParams {
	alertType: string;
	request: IdsRequest;
}

export interface RuleEngineGetAlertParams {
	alertType: string;
}

export interface RuleEngineUpdateClinicConfigParams {
	request: ClinicConfigRequest;
}

export interface RuleEngineDeleteClinicConfigParams {
	request: ClinicConfigRequest;
}

export interface RuleEngineGetClinicConfigParams {
	clinicId: string;
}

export interface RuleEngineGetClinicActiveConfigParams {
	clinicId: string;
}

export interface RuleEngineGetAlertTypesParams {
	clinicId: string;
}

export interface RuleEngineAddCustomAlertParams {
	request: CustomAlertCreateRequest;
}

export interface RuleEngineUpdateCustomAlertParams {
	request: CustomAlertUpdateRequest;
}

export interface RuleEngineDryRunOnTheFlyParams {
	request: DryRunOnTheFlyRequest;
}

export interface RuleEngineDryRunRuleParams {
	request: DryRunRuleRequest;
}

export interface RuleEngineDryRunRuleOnTheFlyParams {
	request: DryRunRuleOnTheFlyRequest;
}

export class RuleEngineService extends BaseController<MedicalAlert, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/rule-engine';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public dryRun({ baseUrl, params, headers }: RequestOption<RuleEngineDryRunParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<DryRunResult[]>>(`${baseUrl || this.baseURL}${this.basePath}/rules/dry-run${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public addRule({ baseUrl, params, headers }: RequestOption<RuleEngineAddRuleParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<AlertDetail>>(`${baseUrl || this.baseURL}${this.basePath}/alert-configs/${params.alertType}/rule${getQueryStrings(queryList)}`, params.rule, { headers });
	}

	public getAllMetrics({ baseUrl, params, headers }: RequestOption<RuleEngineGetAllMetricsParams>) {
		const queryList: QueryParam[] = [];
		if (params.eventType !== undefined && params.eventType !== null) {
			queryList.push({ name: 'eventType', value: params.eventType.toString() });
		}
		return axios.get<APIResponse<MetricItem[]>>(`${baseUrl || this.baseURL}${this.basePath}/metrics/list${getQueryStrings(queryList)}`, { headers });
	}

	public updateRule({ baseUrl, params, headers }: RequestOption<RuleEngineUpdateRuleParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<AlertDetail>>(`${baseUrl || this.baseURL}${this.basePath}/alert-configs/${params.alertType}/rule${getQueryStrings(queryList)}`, params.rule, { headers });
	}

	public deleteRule({ baseUrl, params, headers }: RequestOption<RuleEngineDeleteRuleParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<AlertDetail>>(`${baseUrl || this.baseURL}${this.basePath}/alert-configs/${params.alertType}/rule/delete${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getAlert({ baseUrl, params, headers }: RequestOption<RuleEngineGetAlertParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<AlertDetail>>(`${baseUrl || this.baseURL}${this.basePath}/alert-configs/${params.alertType}${getQueryStrings(queryList)}`, { headers });
	}

	public updateClinicConfig({ baseUrl, params, headers }: RequestOption<RuleEngineUpdateClinicConfigParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<BaseResponse>>(`${baseUrl || this.baseURL}${this.basePath}/clinic-alert-configs${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public deleteClinicConfig({ baseUrl, params, headers }: RequestOption<RuleEngineDeleteClinicConfigParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<BaseResponse>>(`${baseUrl || this.baseURL}${this.basePath}/clinic-alert-configs/delete-alerts${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getClinicConfig({ baseUrl, params, headers }: RequestOption<RuleEngineGetClinicConfigParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<{ [key: string]: AlertDetail[] }>>(`${baseUrl || this.baseURL}${this.basePath}/clinic-alert-configs/${params.clinicId}${getQueryStrings(queryList)}`, { headers });
	}

	public getClinicActiveConfig({ baseUrl, params, headers }: RequestOption<RuleEngineGetClinicActiveConfigParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<{ [key: string]: AlertDisplay[] }>>(`${baseUrl || this.baseURL}${this.basePath}/clinic-alert-configs/${params.clinicId}/active${getQueryStrings(queryList)}`, { headers });
	}

	public getAlertTypes({ baseUrl, params, headers }: RequestOption<RuleEngineGetAlertTypesParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<AlertDisplay[]>>(`${baseUrl || this.baseURL}${this.basePath}/clinic-alert-configs/${params.clinicId}/alert-types${getQueryStrings(queryList)}`, { headers });
	}

	public addCustomAlert({ baseUrl, params, headers }: RequestOption<RuleEngineAddCustomAlertParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<AlertDetail[]>>(`${baseUrl || this.baseURL}${this.basePath}/clinic-alert-configs/custom-alert${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public updateCustomAlert({ baseUrl, params, headers }: RequestOption<RuleEngineUpdateCustomAlertParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<AlertDetail[]>>(`${baseUrl || this.baseURL}${this.basePath}/clinic-alert-configs/custom-alert${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public dryRunOnTheFly({ baseUrl, params, headers }: RequestOption<RuleEngineDryRunOnTheFlyParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<DryRunResult[]>>(`${baseUrl || this.baseURL}${this.basePath}/rules/dry-run/on-the-fly${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public dryRunRule({ baseUrl, params, headers }: RequestOption<RuleEngineDryRunRuleParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<DryRunRuleResult[]>>(`${baseUrl || this.baseURL}${this.basePath}/rules/dry-run/rule${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public dryRunRuleOnTheFly({ baseUrl, params, headers }: RequestOption<RuleEngineDryRunRuleOnTheFlyParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<DryRunRuleResult[]>>(`${baseUrl || this.baseURL}${this.basePath}/rules/dry-run/rule/on-the-fly${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const ruleEngineService = new RuleEngineService();

export const createRuleEngineDryRunObject = (value: APIResponse<DryRunResult[]>) => new APIResponseClass<DryRunResultClass[]>(value, (v) => map(v, (value: DryRunResult) => new DryRunResultClass(value)));

export const useRuleEngineDryRun = <RCN = undefined>(configs: MakeRequestHookConfig<RuleEngineDryRunParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RuleEngineDryRunParams,
    APIResponse<DryRunResult[]>,
    typeof ruleEngineService.dryRun,
    AjaxOptions,
    APIResponseClass<DryRunResultClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ruleEngineService.dryRun>) => (
      ruleEngineService.dryRun(...args)
    ),
    ResponseClassCreator: createRuleEngineDryRunObject,
    typeName: 'useRuleEngineDryRun',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineDryRun.mock = () => new Error('Mock has not been implemented!');
useRuleEngineDryRun.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineDryRun.createObject = createRuleEngineDryRunObject;

export const createRuleEngineAddRuleObject = (value: APIResponse<AlertDetail>) => new APIResponseClass<AlertDetailClass>(value, (value: AlertDetail) => new AlertDetailClass(value));

export const useRuleEngineAddRule = <RCN = undefined>(configs: MakeRequestHookConfig<RuleEngineAddRuleParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RuleEngineAddRuleParams,
    APIResponse<AlertDetail>,
    typeof ruleEngineService.addRule,
    AjaxOptions,
    APIResponseClass<AlertDetailClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ruleEngineService.addRule>) => (
      ruleEngineService.addRule(...args)
    ),
    ResponseClassCreator: createRuleEngineAddRuleObject,
    typeName: 'useRuleEngineAddRule',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineAddRule.mock = () => new Error('Mock has not been implemented!');
useRuleEngineAddRule.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineAddRule.createObject = createRuleEngineAddRuleObject;

export const createRuleEngineGetAllMetricsObject = (value: APIResponse<MetricItem[]>) => new APIResponseClass<MetricItemClass[]>(value, (v) => map(v, (value: MetricItem) => new MetricItemClass(value)));

export const useRuleEngineGetAllMetrics = <RCN = undefined>(configs: MakeRequestHookConfig<RuleEngineGetAllMetricsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RuleEngineGetAllMetricsParams,
    APIResponse<MetricItem[]>,
    typeof ruleEngineService.getAllMetrics,
    GetOptions,
    APIResponseClass<MetricItemClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ruleEngineService.getAllMetrics>) => (
      ruleEngineService.getAllMetrics(...args)
    ),
    ResponseClassCreator: createRuleEngineGetAllMetricsObject,
    typeName: 'useRuleEngineGetAllMetrics',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineGetAllMetrics.mock = () => new Error('Mock has not been implemented!');
useRuleEngineGetAllMetrics.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineGetAllMetrics.createObject = createRuleEngineGetAllMetricsObject;

export const createRuleEngineUpdateRuleObject = (value: APIResponse<AlertDetail>) => new APIResponseClass<AlertDetailClass>(value, (value: AlertDetail) => new AlertDetailClass(value));

export const useRuleEngineUpdateRule = <RCN = undefined>(configs: MakeRequestHookConfig<RuleEngineUpdateRuleParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RuleEngineUpdateRuleParams,
    APIResponse<AlertDetail>,
    typeof ruleEngineService.updateRule,
    AjaxOptions,
    APIResponseClass<AlertDetailClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ruleEngineService.updateRule>) => (
      ruleEngineService.updateRule(...args)
    ),
    ResponseClassCreator: createRuleEngineUpdateRuleObject,
    typeName: 'useRuleEngineUpdateRule',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineUpdateRule.mock = () => new Error('Mock has not been implemented!');
useRuleEngineUpdateRule.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineUpdateRule.createObject = createRuleEngineUpdateRuleObject;

export const createRuleEngineDeleteRuleObject = (value: APIResponse<AlertDetail>) => new APIResponseClass<AlertDetailClass>(value, (value: AlertDetail) => new AlertDetailClass(value));

export const useRuleEngineDeleteRule = <RCN = undefined>(configs: MakeRequestHookConfig<RuleEngineDeleteRuleParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RuleEngineDeleteRuleParams,
    APIResponse<AlertDetail>,
    typeof ruleEngineService.deleteRule,
    AjaxOptions,
    APIResponseClass<AlertDetailClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ruleEngineService.deleteRule>) => (
      ruleEngineService.deleteRule(...args)
    ),
    ResponseClassCreator: createRuleEngineDeleteRuleObject,
    typeName: 'useRuleEngineDeleteRule',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineDeleteRule.mock = () => new Error('Mock has not been implemented!');
useRuleEngineDeleteRule.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineDeleteRule.createObject = createRuleEngineDeleteRuleObject;

export const createRuleEngineGetAlertObject = (value: APIResponse<AlertDetail>) => new APIResponseClass<AlertDetailClass>(value, (value: AlertDetail) => new AlertDetailClass(value));

export const useRuleEngineGetAlert = <RCN = undefined>(configs: MakeRequestHookConfig<RuleEngineGetAlertParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RuleEngineGetAlertParams,
    APIResponse<AlertDetail>,
    typeof ruleEngineService.getAlert,
    GetOptions,
    APIResponseClass<AlertDetailClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ruleEngineService.getAlert>) => (
      ruleEngineService.getAlert(...args)
    ),
    ResponseClassCreator: createRuleEngineGetAlertObject,
    typeName: 'useRuleEngineGetAlert',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineGetAlert.mock = () => new Error('Mock has not been implemented!');
useRuleEngineGetAlert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineGetAlert.createObject = createRuleEngineGetAlertObject;

export const createRuleEngineUpdateClinicConfigObject = (value: APIResponse<BaseResponse>) => new APIResponseClass<BaseResponseClass>(value, (value: BaseResponse) => new BaseResponseClass(value));

export const useRuleEngineUpdateClinicConfig = <RCN = undefined>(configs: MakeRequestHookConfig<RuleEngineUpdateClinicConfigParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RuleEngineUpdateClinicConfigParams,
    APIResponse<BaseResponse>,
    typeof ruleEngineService.updateClinicConfig,
    AjaxOptions,
    APIResponseClass<BaseResponseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ruleEngineService.updateClinicConfig>) => (
      ruleEngineService.updateClinicConfig(...args)
    ),
    ResponseClassCreator: createRuleEngineUpdateClinicConfigObject,
    typeName: 'useRuleEngineUpdateClinicConfig',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineUpdateClinicConfig.mock = () => new Error('Mock has not been implemented!');
useRuleEngineUpdateClinicConfig.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineUpdateClinicConfig.createObject = createRuleEngineUpdateClinicConfigObject;

export const createRuleEngineDeleteClinicConfigObject = (value: APIResponse<BaseResponse>) => new APIResponseClass<BaseResponseClass>(value, (value: BaseResponse) => new BaseResponseClass(value));

export const useRuleEngineDeleteClinicConfig = <RCN = undefined>(configs: MakeRequestHookConfig<RuleEngineDeleteClinicConfigParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RuleEngineDeleteClinicConfigParams,
    APIResponse<BaseResponse>,
    typeof ruleEngineService.deleteClinicConfig,
    AjaxOptions,
    APIResponseClass<BaseResponseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ruleEngineService.deleteClinicConfig>) => (
      ruleEngineService.deleteClinicConfig(...args)
    ),
    ResponseClassCreator: createRuleEngineDeleteClinicConfigObject,
    typeName: 'useRuleEngineDeleteClinicConfig',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineDeleteClinicConfig.mock = () => new Error('Mock has not been implemented!');
useRuleEngineDeleteClinicConfig.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineDeleteClinicConfig.createObject = createRuleEngineDeleteClinicConfigObject;

export const createRuleEngineGetClinicConfigObject = (value: APIResponse<{ [key: string]: AlertDetail[] }>) => new APIResponseClass<{ [key: string]: AlertDetail[] }>(value, (v) => (v));

export const useRuleEngineGetClinicConfig = <RCN = undefined>(configs: MakeRequestHookConfig<RuleEngineGetClinicConfigParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RuleEngineGetClinicConfigParams,
    APIResponse<{ [key: string]: AlertDetail[] }>,
    typeof ruleEngineService.getClinicConfig,
    GetOptions,
    APIResponseClass<{ [key: string]: AlertDetail[] }>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ruleEngineService.getClinicConfig>) => (
      ruleEngineService.getClinicConfig(...args)
    ),
    ResponseClassCreator: createRuleEngineGetClinicConfigObject,
    typeName: 'useRuleEngineGetClinicConfig',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineGetClinicConfig.mock = () => new Error('Mock has not been implemented!');
useRuleEngineGetClinicConfig.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineGetClinicConfig.createObject = createRuleEngineGetClinicConfigObject;

export const createRuleEngineGetClinicActiveConfigObject = (value: APIResponse<{ [key: string]: AlertDisplay[] }>) => new APIResponseClass<{ [key: string]: AlertDisplay[] }>(value, (v) => (v));

export const useRuleEngineGetClinicActiveConfig = <RCN = undefined>(configs: MakeRequestHookConfig<RuleEngineGetClinicActiveConfigParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RuleEngineGetClinicActiveConfigParams,
    APIResponse<{ [key: string]: AlertDisplay[] }>,
    typeof ruleEngineService.getClinicActiveConfig,
    GetOptions,
    APIResponseClass<{ [key: string]: AlertDisplay[] }>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ruleEngineService.getClinicActiveConfig>) => (
      ruleEngineService.getClinicActiveConfig(...args)
    ),
    ResponseClassCreator: createRuleEngineGetClinicActiveConfigObject,
    typeName: 'useRuleEngineGetClinicActiveConfig',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineGetClinicActiveConfig.mock = () => new Error('Mock has not been implemented!');
useRuleEngineGetClinicActiveConfig.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineGetClinicActiveConfig.createObject = createRuleEngineGetClinicActiveConfigObject;

export const createRuleEngineGetAlertTypesObject = (value: APIResponse<AlertDisplay[]>) => new APIResponseClass<AlertDisplayClass[]>(value, (v) => map(v, (value: AlertDisplay) => new AlertDisplayClass(value)));

export const useRuleEngineGetAlertTypes = <RCN = undefined>(configs: MakeRequestHookConfig<RuleEngineGetAlertTypesParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RuleEngineGetAlertTypesParams,
    APIResponse<AlertDisplay[]>,
    typeof ruleEngineService.getAlertTypes,
    GetOptions,
    APIResponseClass<AlertDisplayClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ruleEngineService.getAlertTypes>) => (
      ruleEngineService.getAlertTypes(...args)
    ),
    ResponseClassCreator: createRuleEngineGetAlertTypesObject,
    typeName: 'useRuleEngineGetAlertTypes',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineGetAlertTypes.mock = () => new Error('Mock has not been implemented!');
useRuleEngineGetAlertTypes.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineGetAlertTypes.createObject = createRuleEngineGetAlertTypesObject;

export const createRuleEngineAddCustomAlertObject = (value: APIResponse<AlertDetail[]>) => new APIResponseClass<AlertDetailClass[]>(value, (v) => map(v, (value: AlertDetail) => new AlertDetailClass(value)));

export const useRuleEngineAddCustomAlert = <RCN = undefined>(configs: MakeRequestHookConfig<RuleEngineAddCustomAlertParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RuleEngineAddCustomAlertParams,
    APIResponse<AlertDetail[]>,
    typeof ruleEngineService.addCustomAlert,
    AjaxOptions,
    APIResponseClass<AlertDetailClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ruleEngineService.addCustomAlert>) => (
      ruleEngineService.addCustomAlert(...args)
    ),
    ResponseClassCreator: createRuleEngineAddCustomAlertObject,
    typeName: 'useRuleEngineAddCustomAlert',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineAddCustomAlert.mock = () => new Error('Mock has not been implemented!');
useRuleEngineAddCustomAlert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineAddCustomAlert.createObject = createRuleEngineAddCustomAlertObject;

export const createRuleEngineUpdateCustomAlertObject = (value: APIResponse<AlertDetail[]>) => new APIResponseClass<AlertDetailClass[]>(value, (v) => map(v, (value: AlertDetail) => new AlertDetailClass(value)));

export const useRuleEngineUpdateCustomAlert = <RCN = undefined>(configs: MakeRequestHookConfig<RuleEngineUpdateCustomAlertParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RuleEngineUpdateCustomAlertParams,
    APIResponse<AlertDetail[]>,
    typeof ruleEngineService.updateCustomAlert,
    AjaxOptions,
    APIResponseClass<AlertDetailClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ruleEngineService.updateCustomAlert>) => (
      ruleEngineService.updateCustomAlert(...args)
    ),
    ResponseClassCreator: createRuleEngineUpdateCustomAlertObject,
    typeName: 'useRuleEngineUpdateCustomAlert',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineUpdateCustomAlert.mock = () => new Error('Mock has not been implemented!');
useRuleEngineUpdateCustomAlert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineUpdateCustomAlert.createObject = createRuleEngineUpdateCustomAlertObject;

export const createRuleEngineDryRunOnTheFlyObject = (value: APIResponse<DryRunResult[]>) => new APIResponseClass<DryRunResultClass[]>(value, (v) => map(v, (value: DryRunResult) => new DryRunResultClass(value)));

export const useRuleEngineDryRunOnTheFly = <RCN = undefined>(configs: MakeRequestHookConfig<RuleEngineDryRunOnTheFlyParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RuleEngineDryRunOnTheFlyParams,
    APIResponse<DryRunResult[]>,
    typeof ruleEngineService.dryRunOnTheFly,
    AjaxOptions,
    APIResponseClass<DryRunResultClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ruleEngineService.dryRunOnTheFly>) => (
      ruleEngineService.dryRunOnTheFly(...args)
    ),
    ResponseClassCreator: createRuleEngineDryRunOnTheFlyObject,
    typeName: 'useRuleEngineDryRunOnTheFly',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineDryRunOnTheFly.mock = () => new Error('Mock has not been implemented!');
useRuleEngineDryRunOnTheFly.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineDryRunOnTheFly.createObject = createRuleEngineDryRunOnTheFlyObject;

export const createRuleEngineDryRunRuleObject = (value: APIResponse<DryRunRuleResult[]>) => new APIResponseClass<DryRunRuleResultClass[]>(value, (v) => map(v, (value: DryRunRuleResult) => new DryRunRuleResultClass(value)));

export const useRuleEngineDryRunRule = <RCN = undefined>(configs: MakeRequestHookConfig<RuleEngineDryRunRuleParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RuleEngineDryRunRuleParams,
    APIResponse<DryRunRuleResult[]>,
    typeof ruleEngineService.dryRunRule,
    AjaxOptions,
    APIResponseClass<DryRunRuleResultClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ruleEngineService.dryRunRule>) => (
      ruleEngineService.dryRunRule(...args)
    ),
    ResponseClassCreator: createRuleEngineDryRunRuleObject,
    typeName: 'useRuleEngineDryRunRule',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineDryRunRule.mock = () => new Error('Mock has not been implemented!');
useRuleEngineDryRunRule.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineDryRunRule.createObject = createRuleEngineDryRunRuleObject;

export const createRuleEngineDryRunRuleOnTheFlyObject = (value: APIResponse<DryRunRuleResult[]>) => new APIResponseClass<DryRunRuleResultClass[]>(value, (v) => map(v, (value: DryRunRuleResult) => new DryRunRuleResultClass(value)));

export const useRuleEngineDryRunRuleOnTheFly = <RCN = undefined>(configs: MakeRequestHookConfig<RuleEngineDryRunRuleOnTheFlyParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    RuleEngineDryRunRuleOnTheFlyParams,
    APIResponse<DryRunRuleResult[]>,
    typeof ruleEngineService.dryRunRuleOnTheFly,
    AjaxOptions,
    APIResponseClass<DryRunRuleResultClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ruleEngineService.dryRunRuleOnTheFly>) => (
      ruleEngineService.dryRunRuleOnTheFly(...args)
    ),
    ResponseClassCreator: createRuleEngineDryRunRuleOnTheFlyObject,
    typeName: 'useRuleEngineDryRunRuleOnTheFly',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineDryRunRuleOnTheFly.mock = () => new Error('Mock has not been implemented!');
useRuleEngineDryRunRuleOnTheFly.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineDryRunRuleOnTheFly.createObject = createRuleEngineDryRunRuleOnTheFlyObject;


export const createRuleEngineGetObject = (value: APIResponse<MedicalAlert>) => new APIResponseClass<MedicalAlertClass>(value, (value: MedicalAlert) => new MedicalAlertClass(value));
export const useRuleEngineGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<MedicalAlert>,
    // @ts-ignore
    typeof ruleEngineService.get,
    GetOptions,
    APIResponseClass<MedicalAlertClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ruleEngineService.get>) => (
      ruleEngineService.get(...args)
    ),
    ResponseClassCreator: createRuleEngineGetObject,
    typeName: 'useRuleEngineGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineGet.mock = () => new Error('Mock has not been implemented!');
useRuleEngineGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineGet.createObject = createRuleEngineGetObject;

export const createRuleEngineUpdateObject = (value: APIResponse<MedicalAlert>) => new APIResponseClass<MedicalAlertClass>(value, (value: MedicalAlert) => new MedicalAlertClass(value));
export const useRuleEngineUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, MedicalAlert>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, MedicalAlert>,
    APIResponse<MedicalAlert>,
    // @ts-ignore
    typeof ruleEngineService.update,
    AjaxOptions,
    APIResponseClass<MedicalAlertClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ruleEngineService.update>) => (
      ruleEngineService.update(...args)
    ),
    ResponseClassCreator: createRuleEngineUpdateObject,
    typeName: 'useRuleEngineUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineUpdate.mock = () => new Error('Mock has not been implemented!');
useRuleEngineUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineUpdate.createObject = createRuleEngineUpdateObject;

export const createRuleEngineInsertObject = (value: APIResponse<MedicalAlert>) => new APIResponseClass<MedicalAlertClass>(value, (value: MedicalAlert) => new MedicalAlertClass(value));
export const useRuleEngineInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<MedicalAlert>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<MedicalAlert>,
    APIResponse<MedicalAlert>,
    // @ts-ignore
    typeof ruleEngineService.insert,
    AjaxOptions,
    APIResponseClass<MedicalAlertClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ruleEngineService.insert>) => (
      ruleEngineService.insert(...args)
    ),
    ResponseClassCreator: createRuleEngineInsertObject,
    typeName: 'useRuleEngineInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineInsert.mock = () => new Error('Mock has not been implemented!');
useRuleEngineInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineInsert.createObject = createRuleEngineInsertObject;

export const createRuleEngineDeleteObject = (value: APIResponse<MedicalAlert>) => new APIResponseClass<MedicalAlertClass>(value, (value: MedicalAlert) => new MedicalAlertClass(value));
export const useRuleEngineDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<MedicalAlert>,
    // @ts-ignore
    typeof ruleEngineService.delete,
    AjaxOptions,
    APIResponseClass<MedicalAlertClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ruleEngineService.delete>) => (
      ruleEngineService.delete(...args)
    ),
    ResponseClassCreator: createRuleEngineDeleteObject,
    typeName: 'useRuleEngineDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineDelete.mock = () => new Error('Mock has not been implemented!');
useRuleEngineDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineDelete.createObject = createRuleEngineDeleteObject;

export const createRuleEngineSearchObject = (value: APIResponse<PageResponse<MedicalAlert>>) => new APIResponseClass<PageResponseClass<MedicalAlertClass>>(value, (value: PageResponse<MedicalAlert>) => new PageResponseClass<MedicalAlertClass>(value, (value: MedicalAlert) => new MedicalAlertClass(value)));
export const useRuleEngineSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<MedicalAlert>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<MedicalAlert>,
    APIResponse<PageResponse<MedicalAlert>>,
    // @ts-ignore
    typeof ruleEngineService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<MedicalAlertClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ruleEngineService.search>) => (
      ruleEngineService.search(...args)
    ),
    ResponseClassCreator: createRuleEngineSearchObject,
    typeName: 'useRuleEngineSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useRuleEngineSearch.mock = () => new Error('Mock has not been implemented!');
useRuleEngineSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useRuleEngineSearch.createObject = createRuleEngineSearchObject;

export interface ServerInfo extends BaseModel {
	beVersion?: Nullable<string>;
	feVersion?: Nullable<string>;
	mobileVersion?: Nullable<string>;
	updatePopUpVersion?: Nullable<string>;
	latestIOSVersion?: Nullable<string>;
	latestAndroidVersion?: Nullable<string>;
	releaseNote?: Nullable<string>;
}
export class ServerInfoClass extends BaseModelClass {
  protected readonly _value: ServerInfo;

	constructor(value: ServerInfo) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get beVersion() {
    return this._value.beVersion;
  }

  get beVersionWithDefault() {
    return this._value.beVersion || '';
  }

  get feVersion() {
    return this._value.feVersion;
  }

  get feVersionWithDefault() {
    return this._value.feVersion || '';
  }

  get mobileVersion() {
    return this._value.mobileVersion;
  }

  get mobileVersionWithDefault() {
    return this._value.mobileVersion || '';
  }

  get updatePopUpVersion() {
    return this._value.updatePopUpVersion;
  }

  get updatePopUpVersionWithDefault() {
    return this._value.updatePopUpVersion || '';
  }

  get latestIOSVersion() {
    return this._value.latestIOSVersion;
  }

  get latestIOSVersionWithDefault() {
    return this._value.latestIOSVersion || '';
  }

  get latestAndroidVersion() {
    return this._value.latestAndroidVersion;
  }

  get latestAndroidVersionWithDefault() {
    return this._value.latestAndroidVersion || '';
  }

  get releaseNote() {
    return this._value.releaseNote;
  }

  get releaseNoteWithDefault() {
    return this._value.releaseNote || '';
  }
}
export interface ServerInfoGetParams {
}

export class ServerInfoService extends BaseController<ServerInfo, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/server-info';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public get({ baseUrl, params, headers }: RequestOption<ServerInfoGetParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<ServerInfo>>(`${baseUrl || this.baseURL}${this.basePath}${getQueryStrings(queryList)}`, { headers });
	}
}

export const serverInfoService = new ServerInfoService();

export const createServerInfoGetObject = (value: APIResponse<ServerInfo>) => new APIResponseClass<ServerInfoClass>(value, (value: ServerInfo) => new ServerInfoClass(value));

export const useServerInfoGet = <RCN = undefined>(configs: MakeRequestHookConfig<ServerInfoGetParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ServerInfoGetParams,
    APIResponse<ServerInfo>,
    typeof serverInfoService.get,
    GetOptions,
    APIResponseClass<ServerInfoClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof serverInfoService.get>) => (
      serverInfoService.get(...args)
    ),
    ResponseClassCreator: createServerInfoGetObject,
    typeName: 'useServerInfoGet',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useServerInfoGet.mock = () => new Error('Mock has not been implemented!');
useServerInfoGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useServerInfoGet.createObject = createServerInfoGetObject;


export const createServerInfoUpdateObject = (value: APIResponse<ServerInfo>) => new APIResponseClass<ServerInfoClass>(value, (value: ServerInfo) => new ServerInfoClass(value));
export const useServerInfoUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, ServerInfo>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, ServerInfo>,
    APIResponse<ServerInfo>,
    // @ts-ignore
    typeof serverInfoService.update,
    AjaxOptions,
    APIResponseClass<ServerInfoClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof serverInfoService.update>) => (
      serverInfoService.update(...args)
    ),
    ResponseClassCreator: createServerInfoUpdateObject,
    typeName: 'useServerInfoUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useServerInfoUpdate.mock = () => new Error('Mock has not been implemented!');
useServerInfoUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useServerInfoUpdate.createObject = createServerInfoUpdateObject;

export const createServerInfoInsertObject = (value: APIResponse<ServerInfo>) => new APIResponseClass<ServerInfoClass>(value, (value: ServerInfo) => new ServerInfoClass(value));
export const useServerInfoInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<ServerInfo>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<ServerInfo>,
    APIResponse<ServerInfo>,
    // @ts-ignore
    typeof serverInfoService.insert,
    AjaxOptions,
    APIResponseClass<ServerInfoClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof serverInfoService.insert>) => (
      serverInfoService.insert(...args)
    ),
    ResponseClassCreator: createServerInfoInsertObject,
    typeName: 'useServerInfoInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useServerInfoInsert.mock = () => new Error('Mock has not been implemented!');
useServerInfoInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useServerInfoInsert.createObject = createServerInfoInsertObject;

export const createServerInfoDeleteObject = (value: APIResponse<ServerInfo>) => new APIResponseClass<ServerInfoClass>(value, (value: ServerInfo) => new ServerInfoClass(value));
export const useServerInfoDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<ServerInfo>,
    // @ts-ignore
    typeof serverInfoService.delete,
    AjaxOptions,
    APIResponseClass<ServerInfoClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof serverInfoService.delete>) => (
      serverInfoService.delete(...args)
    ),
    ResponseClassCreator: createServerInfoDeleteObject,
    typeName: 'useServerInfoDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useServerInfoDelete.mock = () => new Error('Mock has not been implemented!');
useServerInfoDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useServerInfoDelete.createObject = createServerInfoDeleteObject;

export const createServerInfoSearchObject = (value: APIResponse<PageResponse<ServerInfo>>) => new APIResponseClass<PageResponseClass<ServerInfoClass>>(value, (value: PageResponse<ServerInfo>) => new PageResponseClass<ServerInfoClass>(value, (value: ServerInfo) => new ServerInfoClass(value)));
export const useServerInfoSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<ServerInfo>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<ServerInfo>,
    APIResponse<PageResponse<ServerInfo>>,
    // @ts-ignore
    typeof serverInfoService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ServerInfoClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof serverInfoService.search>) => (
      serverInfoService.search(...args)
    ),
    ResponseClassCreator: createServerInfoSearchObject,
    typeName: 'useServerInfoSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useServerInfoSearch.mock = () => new Error('Mock has not been implemented!');
useServerInfoSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useServerInfoSearch.createObject = createServerInfoSearchObject;

export interface LimitedProviderNote {
	id?: Nullable<string>;
	content?: Nullable<string>;
	createdAt?: Nullable<string>;
	createdBy?: Nullable<string>;
}
export interface SmartAlertTask extends BaseModel {
	organizationId?: Nullable<string>;
	teamId?: Nullable<string>;
	memberId?: Nullable<string>;
	firstName?: Nullable<string>;
	lastName?: Nullable<string>;
	category?: Nullable<string>;
	alertLevel?: Nullable<number>;
	alertLevelEnum?: Nullable<AlertLevelEnum>;
	replyTime?: Nullable<number>;
	alertLog?: Nullable<string>;
	visitWindow?: Nullable<string>;
	measureId?: Nullable<string>;
	taskType?: Nullable<string>;
	taskTypeEnum?: Nullable<TaskTypeEnum>;
	status?: Nullable<SmartAlertTaskStatus>;
	closeAt?: Nullable<string>;
	closeBy?: Nullable<string>;
	closedBy?: Nullable<LimitedUser>;
	alertDisplay?: Nullable<string>;
	alertRecord?: Nullable<string>;
	reason?: Nullable<string[]>;
	providerNotes?: Nullable<LimitedProviderNote[]>;
	alertLevelEnumIn?: Nullable<InFilter<AlertLevelEnum>>;
	taskTypeIn?: Nullable<InFilter<TaskTypeEnum>>;
	statusIn?: Nullable<InFilter<SmartAlertTaskStatus>>;
	memberIdIn?: Nullable<InFilter<string>>;
}
export class LimitedProviderNoteClass {
  protected readonly _value: LimitedProviderNote;

	constructor(value: LimitedProviderNote) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get content() {
    return this._value.content;
  }

  get contentWithDefault() {
    return this._value.content || '';
  }

  get createdAt() {
    return (this._value.createdAt !== undefined && this._value.createdAt !== null) ? dayjs(this._value.createdAt) : this._value.createdAt;
  }

  get createdBy() {
    return this._value.createdBy;
  }
}
export class SmartAlertTaskClass extends BaseModelClass {
  protected readonly _value: SmartAlertTask;

	constructor(value: SmartAlertTask) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get teamId() {
    return this._value.teamId;
  }

  get memberId() {
    return this._value.memberId;
  }

  get firstName() {
    return this._value.firstName;
  }

  get firstNameWithDefault() {
    return this._value.firstName || '';
  }

  get lastName() {
    return this._value.lastName;
  }

  get lastNameWithDefault() {
    return this._value.lastName || '';
  }

  get category() {
    return this._value.category;
  }

  get categoryWithDefault() {
    return this._value.category || '';
  }

  get alertLevel() {
    return this._value.alertLevel;
  }

  get alertLevelWithDefault() {
    return this._value.alertLevel || 0;
  }

  get alertLevelEnum() {
    return this._value.alertLevelEnum;
  }

  alertLevelEnumIsIn(values: AlertLevelEnum[] = []) {
    return this._value.alertLevelEnum && values.includes(this._value.alertLevelEnum);
  }

  get replyTime() {
    return this._value.replyTime;
  }

  get replyTimeWithDefault() {
    return this._value.replyTime || 0;
  }

  get alertLog() {
    return this._value.alertLog;
  }

  get alertLogWithDefault() {
    return this._value.alertLog || '';
  }

  get visitWindow() {
    return this._value.visitWindow;
  }

  get visitWindowWithDefault() {
    return this._value.visitWindow || '';
  }

  get measureId() {
    return this._value.measureId;
  }

  get taskType() {
    return this._value.taskType;
  }

  get taskTypeWithDefault() {
    return this._value.taskType || '';
  }

  get taskTypeEnum() {
    return this._value.taskTypeEnum;
  }

  taskTypeEnumIsIn(values: TaskTypeEnum[] = []) {
    return this._value.taskTypeEnum && values.includes(this._value.taskTypeEnum);
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: SmartAlertTaskStatus[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get closeAt() {
    return (this._value.closeAt !== undefined && this._value.closeAt !== null) ? dayjs(this._value.closeAt) : this._value.closeAt;
  }

  get closeBy() {
    return this._value.closeBy;
  }

  get closedBy() {
    return this._value.closedBy !== undefined && this._value.closedBy !== null ? new LimitedUserClass(this._value.closedBy) : this._value.closedBy;
  }

  get alertDisplay() {
    return this._value.alertDisplay;
  }

  get alertDisplayWithDefault() {
    return this._value.alertDisplay || '';
  }

  get alertRecord() {
    return this._value.alertRecord;
  }

  get alertRecordWithDefault() {
    return this._value.alertRecord || '';
  }

  get reason() {
    return this._value.reason;
  }

  get reasonWithDefault() {
    return this._value.reason || [];
  }

  get providerNotes() {
    return this._value.providerNotes !== undefined && this._value.providerNotes !== null ? map(this._value.providerNotes, (v) => new LimitedProviderNoteClass(v)) : this._value.providerNotes;
  }

  get providerNotesWithDefault() {
    return this._value.providerNotes || [];
  }

  get alertLevelEnumIn() {
    return this._value.alertLevelEnumIn !== undefined && this._value.alertLevelEnumIn !== null ? new InFilterClass(this._value.alertLevelEnumIn) : this._value.alertLevelEnumIn;
  }

  get taskTypeIn() {
    return this._value.taskTypeIn !== undefined && this._value.taskTypeIn !== null ? new InFilterClass(this._value.taskTypeIn) : this._value.taskTypeIn;
  }

  get statusIn() {
    return this._value.statusIn !== undefined && this._value.statusIn !== null ? new InFilterClass(this._value.statusIn) : this._value.statusIn;
  }

  get memberIdIn() {
    return this._value.memberIdIn !== undefined && this._value.memberIdIn !== null ? new InFilterClass(this._value.memberIdIn) : this._value.memberIdIn;
  }
}
export interface SmartAlertTaskGetComplianceSmartAlertByMemberIdParams {
	memberId: string;
}

export interface SmartAlertTaskCloseSmartAlertByIdParams {
	alertId: string;
}

export interface SmartAlertTaskGetMedicalSmartAlertByMemberIdParams {
	memberId: string;
}

export interface SmartAlertTaskGetSmartAlertByMemberIdParams {
	memberId: string;
}

export interface SmartAlertTaskGetSmartAlertByOrganizationIdParams {
	organizationId: string;
}

export class SmartAlertTaskService extends BaseController<SmartAlertTask, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/smart-alert-tasks';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getComplianceSmartAlertByMemberId({ baseUrl, params, headers }: RequestOption<SmartAlertTaskGetComplianceSmartAlertByMemberIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<SmartAlertTask[]>>(`${baseUrl || this.baseURL}${this.basePath}/patient-list/${params.memberId}/compliance${getQueryStrings(queryList)}`, { headers });
	}

	public closeSmartAlertById({ baseUrl, params, headers }: RequestOption<SmartAlertTaskCloseSmartAlertByIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/close/${params.alertId}${getQueryStrings(queryList)}`, {}, { headers });
	}

	public getMedicalSmartAlertByMemberId({ baseUrl, params, headers }: RequestOption<SmartAlertTaskGetMedicalSmartAlertByMemberIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<SmartAlertTask[]>>(`${baseUrl || this.baseURL}${this.basePath}/patient-list/${params.memberId}/medical${getQueryStrings(queryList)}`, { headers });
	}

	public getSmartAlertByMemberId({ baseUrl, params, headers }: RequestOption<SmartAlertTaskGetSmartAlertByMemberIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<SmartAlertTask[]>>(`${baseUrl || this.baseURL}${this.basePath}/patient-list/${params.memberId}${getQueryStrings(queryList)}`, { headers });
	}

	public getSmartAlertByOrganizationId({ baseUrl, params, headers }: RequestOption<SmartAlertTaskGetSmartAlertByOrganizationIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<SmartAlertTask[]>>(`${baseUrl || this.baseURL}${this.basePath}/organization-list/${params.organizationId}${getQueryStrings(queryList)}`, { headers });
	}
}

export const smartAlertTaskService = new SmartAlertTaskService();

export const createSmartAlertTaskGetComplianceSmartAlertByMemberIdObject = (value: APIResponse<SmartAlertTask[]>) => new APIResponseClass<SmartAlertTaskClass[]>(value, (v) => map(v, (value: SmartAlertTask) => new SmartAlertTaskClass(value)));

export const useSmartAlertTaskGetComplianceSmartAlertByMemberId = <RCN = undefined>(configs: MakeRequestHookConfig<SmartAlertTaskGetComplianceSmartAlertByMemberIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    SmartAlertTaskGetComplianceSmartAlertByMemberIdParams,
    APIResponse<SmartAlertTask[]>,
    typeof smartAlertTaskService.getComplianceSmartAlertByMemberId,
    GetOptions,
    APIResponseClass<SmartAlertTaskClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof smartAlertTaskService.getComplianceSmartAlertByMemberId>) => (
      smartAlertTaskService.getComplianceSmartAlertByMemberId(...args)
    ),
    ResponseClassCreator: createSmartAlertTaskGetComplianceSmartAlertByMemberIdObject,
    typeName: 'useSmartAlertTaskGetComplianceSmartAlertByMemberId',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useSmartAlertTaskGetComplianceSmartAlertByMemberId.mock = () => new Error('Mock has not been implemented!');
useSmartAlertTaskGetComplianceSmartAlertByMemberId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSmartAlertTaskGetComplianceSmartAlertByMemberId.createObject = createSmartAlertTaskGetComplianceSmartAlertByMemberIdObject;

export const createSmartAlertTaskCloseSmartAlertByIdObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useSmartAlertTaskCloseSmartAlertById = <RCN = undefined>(configs: MakeRequestHookConfig<SmartAlertTaskCloseSmartAlertByIdParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    SmartAlertTaskCloseSmartAlertByIdParams,
    APIResponse<string>,
    typeof smartAlertTaskService.closeSmartAlertById,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof smartAlertTaskService.closeSmartAlertById>) => (
      smartAlertTaskService.closeSmartAlertById(...args)
    ),
    ResponseClassCreator: createSmartAlertTaskCloseSmartAlertByIdObject,
    typeName: 'useSmartAlertTaskCloseSmartAlertById',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useSmartAlertTaskCloseSmartAlertById.mock = () => new Error('Mock has not been implemented!');
useSmartAlertTaskCloseSmartAlertById.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSmartAlertTaskCloseSmartAlertById.createObject = createSmartAlertTaskCloseSmartAlertByIdObject;

export const createSmartAlertTaskGetMedicalSmartAlertByMemberIdObject = (value: APIResponse<SmartAlertTask[]>) => new APIResponseClass<SmartAlertTaskClass[]>(value, (v) => map(v, (value: SmartAlertTask) => new SmartAlertTaskClass(value)));

export const useSmartAlertTaskGetMedicalSmartAlertByMemberId = <RCN = undefined>(configs: MakeRequestHookConfig<SmartAlertTaskGetMedicalSmartAlertByMemberIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    SmartAlertTaskGetMedicalSmartAlertByMemberIdParams,
    APIResponse<SmartAlertTask[]>,
    typeof smartAlertTaskService.getMedicalSmartAlertByMemberId,
    GetOptions,
    APIResponseClass<SmartAlertTaskClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof smartAlertTaskService.getMedicalSmartAlertByMemberId>) => (
      smartAlertTaskService.getMedicalSmartAlertByMemberId(...args)
    ),
    ResponseClassCreator: createSmartAlertTaskGetMedicalSmartAlertByMemberIdObject,
    typeName: 'useSmartAlertTaskGetMedicalSmartAlertByMemberId',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useSmartAlertTaskGetMedicalSmartAlertByMemberId.mock = () => new Error('Mock has not been implemented!');
useSmartAlertTaskGetMedicalSmartAlertByMemberId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSmartAlertTaskGetMedicalSmartAlertByMemberId.createObject = createSmartAlertTaskGetMedicalSmartAlertByMemberIdObject;

export const createSmartAlertTaskGetSmartAlertByMemberIdObject = (value: APIResponse<SmartAlertTask[]>) => new APIResponseClass<SmartAlertTaskClass[]>(value, (v) => map(v, (value: SmartAlertTask) => new SmartAlertTaskClass(value)));

export const useSmartAlertTaskGetSmartAlertByMemberId = <RCN = undefined>(configs: MakeRequestHookConfig<SmartAlertTaskGetSmartAlertByMemberIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    SmartAlertTaskGetSmartAlertByMemberIdParams,
    APIResponse<SmartAlertTask[]>,
    typeof smartAlertTaskService.getSmartAlertByMemberId,
    GetOptions,
    APIResponseClass<SmartAlertTaskClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof smartAlertTaskService.getSmartAlertByMemberId>) => (
      smartAlertTaskService.getSmartAlertByMemberId(...args)
    ),
    ResponseClassCreator: createSmartAlertTaskGetSmartAlertByMemberIdObject,
    typeName: 'useSmartAlertTaskGetSmartAlertByMemberId',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useSmartAlertTaskGetSmartAlertByMemberId.mock = () => new Error('Mock has not been implemented!');
useSmartAlertTaskGetSmartAlertByMemberId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSmartAlertTaskGetSmartAlertByMemberId.createObject = createSmartAlertTaskGetSmartAlertByMemberIdObject;

export const createSmartAlertTaskGetSmartAlertByOrganizationIdObject = (value: APIResponse<SmartAlertTask[]>) => new APIResponseClass<SmartAlertTaskClass[]>(value, (v) => map(v, (value: SmartAlertTask) => new SmartAlertTaskClass(value)));

export const useSmartAlertTaskGetSmartAlertByOrganizationId = <RCN = undefined>(configs: MakeRequestHookConfig<SmartAlertTaskGetSmartAlertByOrganizationIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    SmartAlertTaskGetSmartAlertByOrganizationIdParams,
    APIResponse<SmartAlertTask[]>,
    typeof smartAlertTaskService.getSmartAlertByOrganizationId,
    GetOptions,
    APIResponseClass<SmartAlertTaskClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof smartAlertTaskService.getSmartAlertByOrganizationId>) => (
      smartAlertTaskService.getSmartAlertByOrganizationId(...args)
    ),
    ResponseClassCreator: createSmartAlertTaskGetSmartAlertByOrganizationIdObject,
    typeName: 'useSmartAlertTaskGetSmartAlertByOrganizationId',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useSmartAlertTaskGetSmartAlertByOrganizationId.mock = () => new Error('Mock has not been implemented!');
useSmartAlertTaskGetSmartAlertByOrganizationId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSmartAlertTaskGetSmartAlertByOrganizationId.createObject = createSmartAlertTaskGetSmartAlertByOrganizationIdObject;


export const createSmartAlertTaskGetObject = (value: APIResponse<SmartAlertTask>) => new APIResponseClass<SmartAlertTaskClass>(value, (value: SmartAlertTask) => new SmartAlertTaskClass(value));
export const useSmartAlertTaskGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<SmartAlertTask>,
    // @ts-ignore
    typeof smartAlertTaskService.get,
    GetOptions,
    APIResponseClass<SmartAlertTaskClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof smartAlertTaskService.get>) => (
      smartAlertTaskService.get(...args)
    ),
    ResponseClassCreator: createSmartAlertTaskGetObject,
    typeName: 'useSmartAlertTaskGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useSmartAlertTaskGet.mock = () => new Error('Mock has not been implemented!');
useSmartAlertTaskGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSmartAlertTaskGet.createObject = createSmartAlertTaskGetObject;

export const createSmartAlertTaskUpdateObject = (value: APIResponse<SmartAlertTask>) => new APIResponseClass<SmartAlertTaskClass>(value, (value: SmartAlertTask) => new SmartAlertTaskClass(value));
export const useSmartAlertTaskUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, SmartAlertTask>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, SmartAlertTask>,
    APIResponse<SmartAlertTask>,
    // @ts-ignore
    typeof smartAlertTaskService.update,
    AjaxOptions,
    APIResponseClass<SmartAlertTaskClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof smartAlertTaskService.update>) => (
      smartAlertTaskService.update(...args)
    ),
    ResponseClassCreator: createSmartAlertTaskUpdateObject,
    typeName: 'useSmartAlertTaskUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useSmartAlertTaskUpdate.mock = () => new Error('Mock has not been implemented!');
useSmartAlertTaskUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSmartAlertTaskUpdate.createObject = createSmartAlertTaskUpdateObject;

export const createSmartAlertTaskInsertObject = (value: APIResponse<SmartAlertTask>) => new APIResponseClass<SmartAlertTaskClass>(value, (value: SmartAlertTask) => new SmartAlertTaskClass(value));
export const useSmartAlertTaskInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<SmartAlertTask>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<SmartAlertTask>,
    APIResponse<SmartAlertTask>,
    // @ts-ignore
    typeof smartAlertTaskService.insert,
    AjaxOptions,
    APIResponseClass<SmartAlertTaskClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof smartAlertTaskService.insert>) => (
      smartAlertTaskService.insert(...args)
    ),
    ResponseClassCreator: createSmartAlertTaskInsertObject,
    typeName: 'useSmartAlertTaskInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useSmartAlertTaskInsert.mock = () => new Error('Mock has not been implemented!');
useSmartAlertTaskInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSmartAlertTaskInsert.createObject = createSmartAlertTaskInsertObject;

export const createSmartAlertTaskDeleteObject = (value: APIResponse<SmartAlertTask>) => new APIResponseClass<SmartAlertTaskClass>(value, (value: SmartAlertTask) => new SmartAlertTaskClass(value));
export const useSmartAlertTaskDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<SmartAlertTask>,
    // @ts-ignore
    typeof smartAlertTaskService.delete,
    AjaxOptions,
    APIResponseClass<SmartAlertTaskClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof smartAlertTaskService.delete>) => (
      smartAlertTaskService.delete(...args)
    ),
    ResponseClassCreator: createSmartAlertTaskDeleteObject,
    typeName: 'useSmartAlertTaskDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useSmartAlertTaskDelete.mock = () => new Error('Mock has not been implemented!');
useSmartAlertTaskDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSmartAlertTaskDelete.createObject = createSmartAlertTaskDeleteObject;

export const createSmartAlertTaskSearchObject = (value: APIResponse<PageResponse<SmartAlertTask>>) => new APIResponseClass<PageResponseClass<SmartAlertTaskClass>>(value, (value: PageResponse<SmartAlertTask>) => new PageResponseClass<SmartAlertTaskClass>(value, (value: SmartAlertTask) => new SmartAlertTaskClass(value)));
export const useSmartAlertTaskSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<SmartAlertTask>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<SmartAlertTask>,
    APIResponse<PageResponse<SmartAlertTask>>,
    // @ts-ignore
    typeof smartAlertTaskService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<SmartAlertTaskClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof smartAlertTaskService.search>) => (
      smartAlertTaskService.search(...args)
    ),
    ResponseClassCreator: createSmartAlertTaskSearchObject,
    typeName: 'useSmartAlertTaskSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useSmartAlertTaskSearch.mock = () => new Error('Mock has not been implemented!');
useSmartAlertTaskSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSmartAlertTaskSearch.createObject = createSmartAlertTaskSearchObject;

export enum SurveyEventEnum {
	CHAT_FUNCTION = 'CHAT_FUNCTION',
}
export interface Survey extends BaseModel {
	memberId?: Nullable<string>;
	rating?: Nullable<number>;
	comment?: Nullable<string>;
	event?: Nullable<SurveyEventEnum>;
}
export class SurveyClass extends BaseModelClass {
  protected readonly _value: Survey;

	constructor(value: Survey) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get rating() {
    return this._value.rating;
  }

  get ratingWithDefault() {
    return this._value.rating || 0;
  }

  get comment() {
    return this._value.comment;
  }

  get commentWithDefault() {
    return this._value.comment || '';
  }

  get event() {
    return this._value.event;
  }

  eventIsIn(values: SurveyEventEnum[] = []) {
    return this._value.event && values.includes(this._value.event);
  }
}
export class SurveyService extends BaseController<Survey, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/survey';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const surveyService = new SurveyService();


export const createSurveyGetObject = (value: APIResponse<Survey>) => new APIResponseClass<SurveyClass>(value, (value: Survey) => new SurveyClass(value));
export const useSurveyGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<Survey>,
    // @ts-ignore
    typeof surveyService.get,
    GetOptions,
    APIResponseClass<SurveyClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof surveyService.get>) => (
      surveyService.get(...args)
    ),
    ResponseClassCreator: createSurveyGetObject,
    typeName: 'useSurveyGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useSurveyGet.mock = () => new Error('Mock has not been implemented!');
useSurveyGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSurveyGet.createObject = createSurveyGetObject;

export const createSurveyUpdateObject = (value: APIResponse<Survey>) => new APIResponseClass<SurveyClass>(value, (value: Survey) => new SurveyClass(value));
export const useSurveyUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, Survey>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, Survey>,
    APIResponse<Survey>,
    // @ts-ignore
    typeof surveyService.update,
    AjaxOptions,
    APIResponseClass<SurveyClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof surveyService.update>) => (
      surveyService.update(...args)
    ),
    ResponseClassCreator: createSurveyUpdateObject,
    typeName: 'useSurveyUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useSurveyUpdate.mock = () => new Error('Mock has not been implemented!');
useSurveyUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSurveyUpdate.createObject = createSurveyUpdateObject;

export const createSurveyInsertObject = (value: APIResponse<Survey>) => new APIResponseClass<SurveyClass>(value, (value: Survey) => new SurveyClass(value));
export const useSurveyInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<Survey>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<Survey>,
    APIResponse<Survey>,
    // @ts-ignore
    typeof surveyService.insert,
    AjaxOptions,
    APIResponseClass<SurveyClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof surveyService.insert>) => (
      surveyService.insert(...args)
    ),
    ResponseClassCreator: createSurveyInsertObject,
    typeName: 'useSurveyInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useSurveyInsert.mock = () => new Error('Mock has not been implemented!');
useSurveyInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSurveyInsert.createObject = createSurveyInsertObject;

export const createSurveyDeleteObject = (value: APIResponse<Survey>) => new APIResponseClass<SurveyClass>(value, (value: Survey) => new SurveyClass(value));
export const useSurveyDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<Survey>,
    // @ts-ignore
    typeof surveyService.delete,
    AjaxOptions,
    APIResponseClass<SurveyClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof surveyService.delete>) => (
      surveyService.delete(...args)
    ),
    ResponseClassCreator: createSurveyDeleteObject,
    typeName: 'useSurveyDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useSurveyDelete.mock = () => new Error('Mock has not been implemented!');
useSurveyDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSurveyDelete.createObject = createSurveyDeleteObject;

export const createSurveySearchObject = (value: APIResponse<PageResponse<Survey>>) => new APIResponseClass<PageResponseClass<SurveyClass>>(value, (value: PageResponse<Survey>) => new PageResponseClass<SurveyClass>(value, (value: Survey) => new SurveyClass(value)));
export const useSurveySearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<Survey>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<Survey>,
    APIResponse<PageResponse<Survey>>,
    // @ts-ignore
    typeof surveyService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<SurveyClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof surveyService.search>) => (
      surveyService.search(...args)
    ),
    ResponseClassCreator: createSurveySearchObject,
    typeName: 'useSurveySearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useSurveySearch.mock = () => new Error('Mock has not been implemented!');
useSurveySearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSurveySearch.createObject = createSurveySearchObject;

export enum SymptomEnum {
	ABDOMINAL_PAIN = 'ABDOMINAL_PAIN',
	CONSTIPATION = 'CONSTIPATION',
	DIARRHEA = 'DIARRHEA',
	DIZZINESS = 'DIZZINESS',
	HAIR_LOSS = 'HAIR_LOSS',
	HEADACHE = 'HEADACHE',
	LACK_OF_APPETITE = 'LACK_OF_APPETITE',
	NAUSEA = 'NAUSEA',
	REACTION_AT_INJECTION_SITE = 'REACTION_AT_INJECTION_SITE',
	RASH = 'RASH',
	VOMITING = 'VOMITING',
	OTHER = 'OTHER',
}
export enum SymptomSeverityEnum {
	SEVERE = 'SEVERE',
	MODERATE = 'MODERATE',
	MILD = 'MILD',
	NOT_SURE = 'NOT_SURE',
	NA = 'NA',
}
export interface PatientSymptom {
	symptom?: Nullable<SymptomEnum>;
	severity?: Nullable<SymptomSeverityEnum>;
	manualSymptom?: Nullable<string>;
	note?: Nullable<string>;
}
export enum ReporterEnum {
	CARE_TEAM_REPORTED = 'CARE_TEAM_REPORTED',
	PATIENT_REPORTED = 'PATIENT_REPORTED',
}
export interface SymptomLog extends BaseModel {
	patientId?: Nullable<string>;
	symptomList?: Nullable<PatientSymptom[]>;
	reporterType?: Nullable<ReporterEnum>;
	reportedBy?: Nullable<string>;
	updatedBy?: Nullable<string>;
	measurementId?: Nullable<string>;
	reportedByUser?: Nullable<LimitedUser>;
}
export interface SymptomLogRequest {
	patientId?: Nullable<string>;
	severityList?: Nullable<SymptomSeverityEnum[]>;
	reporterList?: Nullable<ReporterEnum[]>;
}
export class PatientSymptomClass {
  protected readonly _value: PatientSymptom;

	constructor(value: PatientSymptom) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get symptom() {
    return this._value.symptom;
  }

  symptomIsIn(values: SymptomEnum[] = []) {
    return this._value.symptom && values.includes(this._value.symptom);
  }

  get severity() {
    return this._value.severity;
  }

  severityIsIn(values: SymptomSeverityEnum[] = []) {
    return this._value.severity && values.includes(this._value.severity);
  }

  get manualSymptom() {
    return this._value.manualSymptom;
  }

  get manualSymptomWithDefault() {
    return this._value.manualSymptom || '';
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }
}
export class SymptomLogClass extends BaseModelClass {
  protected readonly _value: SymptomLog;

	constructor(value: SymptomLog) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientId() {
    return this._value.patientId;
  }

  get symptomList() {
    return this._value.symptomList !== undefined && this._value.symptomList !== null ? map(this._value.symptomList, (v) => new PatientSymptomClass(v)) : this._value.symptomList;
  }

  get symptomListWithDefault() {
    return this._value.symptomList || [];
  }

  get reporterType() {
    return this._value.reporterType;
  }

  reporterTypeIsIn(values: ReporterEnum[] = []) {
    return this._value.reporterType && values.includes(this._value.reporterType);
  }

  get reportedBy() {
    return this._value.reportedBy;
  }

  get updatedBy() {
    return this._value.updatedBy;
  }

  get measurementId() {
    return this._value.measurementId;
  }

  get reportedByUser() {
    return this._value.reportedByUser !== undefined && this._value.reportedByUser !== null ? new LimitedUserClass(this._value.reportedByUser) : this._value.reportedByUser;
  }
}
export interface SymptomLogGetPatientSymptomLogParams {
	searchRequest: SearchRequest<SymptomLogRequest>;
}

export class SymptomLogService extends BaseController<SymptomLog, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/symptom-logs';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getPatientSymptomLog({ baseUrl, params, headers }: RequestOption<SymptomLogGetPatientSymptomLogParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<SymptomLog>>>(`${baseUrl || this.baseURL}${this.basePath}/list${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}
}

export const symptomLogService = new SymptomLogService();

export const createSymptomLogGetPatientSymptomLogObject = (value: APIResponse<PageResponse<SymptomLog>>) => new APIResponseClass<PageResponseClass<SymptomLogClass>>(value, (value: PageResponse<SymptomLog>) => new PageResponseClass<SymptomLogClass>(value, (value: SymptomLog) => new SymptomLogClass(value)));

export const useSymptomLogGetPatientSymptomLog = <RCN = undefined>(configs: MakeRequestHookConfig<SymptomLogGetPatientSymptomLogParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    SymptomLogGetPatientSymptomLogParams,
    APIResponse<PageResponse<SymptomLog>>,
    typeof symptomLogService.getPatientSymptomLog,
    AjaxOptions,
    APIResponseClass<PageResponseClass<SymptomLogClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof symptomLogService.getPatientSymptomLog>) => (
      symptomLogService.getPatientSymptomLog(...args)
    ),
    ResponseClassCreator: createSymptomLogGetPatientSymptomLogObject,
    typeName: 'useSymptomLogGetPatientSymptomLog',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useSymptomLogGetPatientSymptomLog.mock = () => new Error('Mock has not been implemented!');
useSymptomLogGetPatientSymptomLog.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSymptomLogGetPatientSymptomLog.createObject = createSymptomLogGetPatientSymptomLogObject;


export const createSymptomLogGetObject = (value: APIResponse<SymptomLog>) => new APIResponseClass<SymptomLogClass>(value, (value: SymptomLog) => new SymptomLogClass(value));
export const useSymptomLogGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<SymptomLog>,
    // @ts-ignore
    typeof symptomLogService.get,
    GetOptions,
    APIResponseClass<SymptomLogClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof symptomLogService.get>) => (
      symptomLogService.get(...args)
    ),
    ResponseClassCreator: createSymptomLogGetObject,
    typeName: 'useSymptomLogGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useSymptomLogGet.mock = () => new Error('Mock has not been implemented!');
useSymptomLogGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSymptomLogGet.createObject = createSymptomLogGetObject;

export const createSymptomLogUpdateObject = (value: APIResponse<SymptomLog>) => new APIResponseClass<SymptomLogClass>(value, (value: SymptomLog) => new SymptomLogClass(value));
export const useSymptomLogUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, SymptomLog>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, SymptomLog>,
    APIResponse<SymptomLog>,
    // @ts-ignore
    typeof symptomLogService.update,
    AjaxOptions,
    APIResponseClass<SymptomLogClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof symptomLogService.update>) => (
      symptomLogService.update(...args)
    ),
    ResponseClassCreator: createSymptomLogUpdateObject,
    typeName: 'useSymptomLogUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useSymptomLogUpdate.mock = () => new Error('Mock has not been implemented!');
useSymptomLogUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSymptomLogUpdate.createObject = createSymptomLogUpdateObject;

export const createSymptomLogInsertObject = (value: APIResponse<SymptomLog>) => new APIResponseClass<SymptomLogClass>(value, (value: SymptomLog) => new SymptomLogClass(value));
export const useSymptomLogInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<SymptomLog>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<SymptomLog>,
    APIResponse<SymptomLog>,
    // @ts-ignore
    typeof symptomLogService.insert,
    AjaxOptions,
    APIResponseClass<SymptomLogClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof symptomLogService.insert>) => (
      symptomLogService.insert(...args)
    ),
    ResponseClassCreator: createSymptomLogInsertObject,
    typeName: 'useSymptomLogInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useSymptomLogInsert.mock = () => new Error('Mock has not been implemented!');
useSymptomLogInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSymptomLogInsert.createObject = createSymptomLogInsertObject;

export const createSymptomLogDeleteObject = (value: APIResponse<SymptomLog>) => new APIResponseClass<SymptomLogClass>(value, (value: SymptomLog) => new SymptomLogClass(value));
export const useSymptomLogDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<SymptomLog>,
    // @ts-ignore
    typeof symptomLogService.delete,
    AjaxOptions,
    APIResponseClass<SymptomLogClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof symptomLogService.delete>) => (
      symptomLogService.delete(...args)
    ),
    ResponseClassCreator: createSymptomLogDeleteObject,
    typeName: 'useSymptomLogDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useSymptomLogDelete.mock = () => new Error('Mock has not been implemented!');
useSymptomLogDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSymptomLogDelete.createObject = createSymptomLogDeleteObject;

export const createSymptomLogSearchObject = (value: APIResponse<PageResponse<SymptomLog>>) => new APIResponseClass<PageResponseClass<SymptomLogClass>>(value, (value: PageResponse<SymptomLog>) => new PageResponseClass<SymptomLogClass>(value, (value: SymptomLog) => new SymptomLogClass(value)));
export const useSymptomLogSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<SymptomLog>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<SymptomLog>,
    APIResponse<PageResponse<SymptomLog>>,
    // @ts-ignore
    typeof symptomLogService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<SymptomLogClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof symptomLogService.search>) => (
      symptomLogService.search(...args)
    ),
    ResponseClassCreator: createSymptomLogSearchObject,
    typeName: 'useSymptomLogSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useSymptomLogSearch.mock = () => new Error('Mock has not been implemented!');
useSymptomLogSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useSymptomLogSearch.createObject = createSymptomLogSearchObject;

export enum TaskPriorityLevelEnum {
	URGENT = 'URGENT',
	MEDIUM = 'MEDIUM',
	LOW = 'LOW',
}
export enum TaskAssignmentStatusEnum {
	OPEN = 'OPEN',
	RESOLVED = 'RESOLVED',
}
export interface TaskAssignmentHistoryType {
	note?: Nullable<string>;
	assignedTo?: Nullable<string>;
	assignedBy?: Nullable<string>;
	assignedAt?: Nullable<string>;
	assignedToUser?: Nullable<LimitedUser>;
	assignedByUser?: Nullable<LimitedUser>;
}
export interface ResolvedNote {
	note?: Nullable<string>;
	resolvedAt?: Nullable<string>;
	resolvedBy?: Nullable<string>;
	resolvedByUser?: Nullable<LimitedUser>;
}
export interface IsReadType {
	value?: Nullable<boolean>;
	readAt?: Nullable<string>;
	readBy?: Nullable<string>;
}
export interface TaskAssignment extends BaseModel {
	memberId?: Nullable<string>;
	organizationId?: Nullable<string>;
	teamId?: Nullable<string>;
	dueDate?: Nullable<string>;
	assignedTo?: Nullable<string>;
	createdBy?: Nullable<string>;
	priorityLevel?: Nullable<TaskPriorityLevelEnum>;
	reason?: Nullable<string>;
	status?: Nullable<TaskAssignmentStatusEnum>;
	history?: Nullable<TaskAssignmentHistoryType[]>;
	priorityScore?: Nullable<number>;
	doctorId?: Nullable<string>;
	resolvedNote?: Nullable<ResolvedNote>;
	assignedToDate?: Nullable<string>;
	isAddToProviderNote?: Nullable<boolean>;
	isRead?: Nullable<IsReadType>;
	createdByIn?: Nullable<InFilter<string>>;
	assignedToIn?: Nullable<InFilter<string>>;
	statusIn?: Nullable<InFilter<TaskAssignmentStatusEnum>>;
	priorityLevelIn?: Nullable<InFilter<TaskPriorityLevelEnum>>;
	doctorIdIn?: Nullable<InFilter<string>>;
	createdByUser?: Nullable<LimitedUser>;
	assignedToUser?: Nullable<LimitedUser>;
	doctorUser?: Nullable<LimitedUser>;
}
export class TaskAssignmentHistoryTypeClass {
  protected readonly _value: TaskAssignmentHistoryType;

	constructor(value: TaskAssignmentHistoryType) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get assignedTo() {
    return this._value.assignedTo;
  }

  get assignedBy() {
    return this._value.assignedBy;
  }

  get assignedAt() {
    return (this._value.assignedAt !== undefined && this._value.assignedAt !== null) ? dayjs(this._value.assignedAt) : this._value.assignedAt;
  }

  get assignedToUser() {
    return this._value.assignedToUser !== undefined && this._value.assignedToUser !== null ? new LimitedUserClass(this._value.assignedToUser) : this._value.assignedToUser;
  }

  get assignedByUser() {
    return this._value.assignedByUser !== undefined && this._value.assignedByUser !== null ? new LimitedUserClass(this._value.assignedByUser) : this._value.assignedByUser;
  }
}
export class ResolvedNoteClass {
  protected readonly _value: ResolvedNote;

	constructor(value: ResolvedNote) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get note() {
    return this._value.note;
  }

  get noteWithDefault() {
    return this._value.note || '';
  }

  get resolvedAt() {
    return (this._value.resolvedAt !== undefined && this._value.resolvedAt !== null) ? dayjs(this._value.resolvedAt) : this._value.resolvedAt;
  }

  get resolvedBy() {
    return this._value.resolvedBy;
  }

  get resolvedByUser() {
    return this._value.resolvedByUser !== undefined && this._value.resolvedByUser !== null ? new LimitedUserClass(this._value.resolvedByUser) : this._value.resolvedByUser;
  }
}
export class IsReadTypeClass {
  protected readonly _value: IsReadType;

	constructor(value: IsReadType) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || false;
  }

  get readAt() {
    return (this._value.readAt !== undefined && this._value.readAt !== null) ? dayjs(this._value.readAt) : this._value.readAt;
  }

  get readBy() {
    return this._value.readBy;
  }
}
export class TaskAssignmentClass extends BaseModelClass {
  protected readonly _value: TaskAssignment;

	constructor(value: TaskAssignment) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get teamId() {
    return this._value.teamId;
  }

  get dueDate() {
    return (this._value.dueDate !== undefined && this._value.dueDate !== null) ? dayjs(this._value.dueDate) : this._value.dueDate;
  }

  get assignedTo() {
    return this._value.assignedTo;
  }

  get createdBy() {
    return this._value.createdBy;
  }

  get priorityLevel() {
    return this._value.priorityLevel;
  }

  priorityLevelIsIn(values: TaskPriorityLevelEnum[] = []) {
    return this._value.priorityLevel && values.includes(this._value.priorityLevel);
  }

  get reason() {
    return this._value.reason;
  }

  get reasonWithDefault() {
    return this._value.reason || '';
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: TaskAssignmentStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get history() {
    return this._value.history !== undefined && this._value.history !== null ? map(this._value.history, (v) => new TaskAssignmentHistoryTypeClass(v)) : this._value.history;
  }

  get historyWithDefault() {
    return this._value.history || [];
  }

  get priorityScore() {
    return this._value.priorityScore;
  }

  get priorityScoreWithDefault() {
    return this._value.priorityScore || 0;
  }

  get doctorId() {
    return this._value.doctorId;
  }

  get resolvedNote() {
    return this._value.resolvedNote !== undefined && this._value.resolvedNote !== null ? new ResolvedNoteClass(this._value.resolvedNote) : this._value.resolvedNote;
  }

  get assignedToDate() {
    return (this._value.assignedToDate !== undefined && this._value.assignedToDate !== null) ? dayjs(this._value.assignedToDate) : this._value.assignedToDate;
  }

  get isAddToProviderNote() {
    return this._value.isAddToProviderNote;
  }

  get isAddToProviderNoteWithDefault() {
    return this._value.isAddToProviderNote || false;
  }

  get isRead() {
    return this._value.isRead !== undefined && this._value.isRead !== null ? new IsReadTypeClass(this._value.isRead) : this._value.isRead;
  }

  get createdByIn() {
    return this._value.createdByIn !== undefined && this._value.createdByIn !== null ? new InFilterClass(this._value.createdByIn) : this._value.createdByIn;
  }

  get assignedToIn() {
    return this._value.assignedToIn !== undefined && this._value.assignedToIn !== null ? new InFilterClass(this._value.assignedToIn) : this._value.assignedToIn;
  }

  get statusIn() {
    return this._value.statusIn !== undefined && this._value.statusIn !== null ? new InFilterClass(this._value.statusIn) : this._value.statusIn;
  }

  get priorityLevelIn() {
    return this._value.priorityLevelIn !== undefined && this._value.priorityLevelIn !== null ? new InFilterClass(this._value.priorityLevelIn) : this._value.priorityLevelIn;
  }

  get doctorIdIn() {
    return this._value.doctorIdIn !== undefined && this._value.doctorIdIn !== null ? new InFilterClass(this._value.doctorIdIn) : this._value.doctorIdIn;
  }

  get createdByUser() {
    return this._value.createdByUser !== undefined && this._value.createdByUser !== null ? new LimitedUserClass(this._value.createdByUser) : this._value.createdByUser;
  }

  get assignedToUser() {
    return this._value.assignedToUser !== undefined && this._value.assignedToUser !== null ? new LimitedUserClass(this._value.assignedToUser) : this._value.assignedToUser;
  }

  get doctorUser() {
    return this._value.doctorUser !== undefined && this._value.doctorUser !== null ? new LimitedUserClass(this._value.doctorUser) : this._value.doctorUser;
  }
}
export interface TaskAssignmentFilter {
	createdBy?: Nullable<string[]>;
	assignedTo?: Nullable<string[]>;
	status?: Nullable<TaskAssignmentStatusEnum[]>;
	priorityLevel?: Nullable<TaskPriorityLevelEnum[]>;
	doctorId?: Nullable<string[]>;
	isRead?: Nullable<boolean>;
	isNotAssignedByCurrentUser?: Nullable<boolean>;
}
export interface TaskAssignmentPatientGroup {
	_id?: Nullable<string>;
	patientUser?: Nullable<LimitedPatient>;
	tasks?: Nullable<TaskAssignment[]>;
}
export class TaskAssignmentPatientGroupClass {
  protected readonly _value: TaskAssignmentPatientGroup;

	constructor(value: TaskAssignmentPatientGroup) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get _id() {
    return this._value._id;
  }

  get patientUser() {
    return this._value.patientUser !== undefined && this._value.patientUser !== null ? new LimitedPatientClass(this._value.patientUser) : this._value.patientUser;
  }

  get tasks() {
    return this._value.tasks !== undefined && this._value.tasks !== null ? map(this._value.tasks, (v) => new TaskAssignmentClass(v)) : this._value.tasks;
  }

  get tasksWithDefault() {
    return this._value.tasks || [];
  }
}
export interface TaskAssignmentGetTaskAssignmentByPatientIdParams {
	patientId: string;
}

export interface TaskAssignmentResolveTaskAssignmentParams {
	id: string;
	request: TaskAssignment;
}

export interface TaskAssignmentSearchGroupParams {
	searchRequest: SearchRequest<TaskAssignmentFilter>;
}

export interface TaskAssignmentGetTaskAssignmentListParams {
	providerId: string;
}

export class TaskAssignmentService extends BaseController<TaskAssignment, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/task-assignment';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public getTaskAssignmentByPatientId({ baseUrl, params, headers }: RequestOption<TaskAssignmentGetTaskAssignmentByPatientIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<TaskAssignment[]>>(`${baseUrl || this.baseURL}${this.basePath}/patient/${params.patientId}${getQueryStrings(queryList)}`, { headers });
	}

	public resolveTaskAssignment({ baseUrl, params, headers }: RequestOption<TaskAssignmentResolveTaskAssignmentParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/resolve/${params.id}${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public searchGroup({ baseUrl, params, headers }: RequestOption<TaskAssignmentSearchGroupParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<TaskAssignmentPatientGroup>>>(`${baseUrl || this.baseURL}${this.basePath}/search-grouped${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getTaskAssignmentList({ baseUrl, params, headers }: RequestOption<TaskAssignmentGetTaskAssignmentListParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<TaskAssignment[]>>(`${baseUrl || this.baseURL}${this.basePath}/list/${params.providerId}${getQueryStrings(queryList)}`, { headers });
	}
}

export const taskAssignmentService = new TaskAssignmentService();

export const createTaskAssignmentGetTaskAssignmentByPatientIdObject = (value: APIResponse<TaskAssignment[]>) => new APIResponseClass<TaskAssignmentClass[]>(value, (v) => map(v, (value: TaskAssignment) => new TaskAssignmentClass(value)));

export const useTaskAssignmentGetTaskAssignmentByPatientId = <RCN = undefined>(configs: MakeRequestHookConfig<TaskAssignmentGetTaskAssignmentByPatientIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TaskAssignmentGetTaskAssignmentByPatientIdParams,
    APIResponse<TaskAssignment[]>,
    typeof taskAssignmentService.getTaskAssignmentByPatientId,
    GetOptions,
    APIResponseClass<TaskAssignmentClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof taskAssignmentService.getTaskAssignmentByPatientId>) => (
      taskAssignmentService.getTaskAssignmentByPatientId(...args)
    ),
    ResponseClassCreator: createTaskAssignmentGetTaskAssignmentByPatientIdObject,
    typeName: 'useTaskAssignmentGetTaskAssignmentByPatientId',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTaskAssignmentGetTaskAssignmentByPatientId.mock = () => new Error('Mock has not been implemented!');
useTaskAssignmentGetTaskAssignmentByPatientId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTaskAssignmentGetTaskAssignmentByPatientId.createObject = createTaskAssignmentGetTaskAssignmentByPatientIdObject;

export const createTaskAssignmentResolveTaskAssignmentObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useTaskAssignmentResolveTaskAssignment = <RCN = undefined>(configs: MakeRequestHookConfig<TaskAssignmentResolveTaskAssignmentParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TaskAssignmentResolveTaskAssignmentParams,
    APIResponse<string>,
    typeof taskAssignmentService.resolveTaskAssignment,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof taskAssignmentService.resolveTaskAssignment>) => (
      taskAssignmentService.resolveTaskAssignment(...args)
    ),
    ResponseClassCreator: createTaskAssignmentResolveTaskAssignmentObject,
    typeName: 'useTaskAssignmentResolveTaskAssignment',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTaskAssignmentResolveTaskAssignment.mock = () => new Error('Mock has not been implemented!');
useTaskAssignmentResolveTaskAssignment.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTaskAssignmentResolveTaskAssignment.createObject = createTaskAssignmentResolveTaskAssignmentObject;

export const createTaskAssignmentSearchGroupObject = (value: APIResponse<PageResponse<TaskAssignmentPatientGroup>>) => new APIResponseClass<PageResponseClass<TaskAssignmentPatientGroupClass>>(value, (value: PageResponse<TaskAssignmentPatientGroup>) => new PageResponseClass<TaskAssignmentPatientGroupClass>(value, (value: TaskAssignmentPatientGroup) => new TaskAssignmentPatientGroupClass(value)));

export const useTaskAssignmentSearchGroup = <RCN = undefined>(configs: MakeRequestHookConfig<TaskAssignmentSearchGroupParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TaskAssignmentSearchGroupParams,
    APIResponse<PageResponse<TaskAssignmentPatientGroup>>,
    typeof taskAssignmentService.searchGroup,
    AjaxOptions,
    APIResponseClass<PageResponseClass<TaskAssignmentPatientGroupClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof taskAssignmentService.searchGroup>) => (
      taskAssignmentService.searchGroup(...args)
    ),
    ResponseClassCreator: createTaskAssignmentSearchGroupObject,
    typeName: 'useTaskAssignmentSearchGroup',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTaskAssignmentSearchGroup.mock = () => new Error('Mock has not been implemented!');
useTaskAssignmentSearchGroup.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTaskAssignmentSearchGroup.createObject = createTaskAssignmentSearchGroupObject;

export const createTaskAssignmentGetTaskAssignmentListObject = (value: APIResponse<TaskAssignment[]>) => new APIResponseClass<TaskAssignmentClass[]>(value, (v) => map(v, (value: TaskAssignment) => new TaskAssignmentClass(value)));

export const useTaskAssignmentGetTaskAssignmentList = <RCN = undefined>(configs: MakeRequestHookConfig<TaskAssignmentGetTaskAssignmentListParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TaskAssignmentGetTaskAssignmentListParams,
    APIResponse<TaskAssignment[]>,
    typeof taskAssignmentService.getTaskAssignmentList,
    GetOptions,
    APIResponseClass<TaskAssignmentClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof taskAssignmentService.getTaskAssignmentList>) => (
      taskAssignmentService.getTaskAssignmentList(...args)
    ),
    ResponseClassCreator: createTaskAssignmentGetTaskAssignmentListObject,
    typeName: 'useTaskAssignmentGetTaskAssignmentList',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTaskAssignmentGetTaskAssignmentList.mock = () => new Error('Mock has not been implemented!');
useTaskAssignmentGetTaskAssignmentList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTaskAssignmentGetTaskAssignmentList.createObject = createTaskAssignmentGetTaskAssignmentListObject;


export const createTaskAssignmentGetObject = (value: APIResponse<TaskAssignment>) => new APIResponseClass<TaskAssignmentClass>(value, (value: TaskAssignment) => new TaskAssignmentClass(value));
export const useTaskAssignmentGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<TaskAssignment>,
    // @ts-ignore
    typeof taskAssignmentService.get,
    GetOptions,
    APIResponseClass<TaskAssignmentClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof taskAssignmentService.get>) => (
      taskAssignmentService.get(...args)
    ),
    ResponseClassCreator: createTaskAssignmentGetObject,
    typeName: 'useTaskAssignmentGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTaskAssignmentGet.mock = () => new Error('Mock has not been implemented!');
useTaskAssignmentGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTaskAssignmentGet.createObject = createTaskAssignmentGetObject;

export const createTaskAssignmentUpdateObject = (value: APIResponse<TaskAssignment>) => new APIResponseClass<TaskAssignmentClass>(value, (value: TaskAssignment) => new TaskAssignmentClass(value));
export const useTaskAssignmentUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, TaskAssignment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, TaskAssignment>,
    APIResponse<TaskAssignment>,
    // @ts-ignore
    typeof taskAssignmentService.update,
    AjaxOptions,
    APIResponseClass<TaskAssignmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof taskAssignmentService.update>) => (
      taskAssignmentService.update(...args)
    ),
    ResponseClassCreator: createTaskAssignmentUpdateObject,
    typeName: 'useTaskAssignmentUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTaskAssignmentUpdate.mock = () => new Error('Mock has not been implemented!');
useTaskAssignmentUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTaskAssignmentUpdate.createObject = createTaskAssignmentUpdateObject;

export const createTaskAssignmentInsertObject = (value: APIResponse<TaskAssignment>) => new APIResponseClass<TaskAssignmentClass>(value, (value: TaskAssignment) => new TaskAssignmentClass(value));
export const useTaskAssignmentInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<TaskAssignment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<TaskAssignment>,
    APIResponse<TaskAssignment>,
    // @ts-ignore
    typeof taskAssignmentService.insert,
    AjaxOptions,
    APIResponseClass<TaskAssignmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof taskAssignmentService.insert>) => (
      taskAssignmentService.insert(...args)
    ),
    ResponseClassCreator: createTaskAssignmentInsertObject,
    typeName: 'useTaskAssignmentInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTaskAssignmentInsert.mock = () => new Error('Mock has not been implemented!');
useTaskAssignmentInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTaskAssignmentInsert.createObject = createTaskAssignmentInsertObject;

export const createTaskAssignmentDeleteObject = (value: APIResponse<TaskAssignment>) => new APIResponseClass<TaskAssignmentClass>(value, (value: TaskAssignment) => new TaskAssignmentClass(value));
export const useTaskAssignmentDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<TaskAssignment>,
    // @ts-ignore
    typeof taskAssignmentService.delete,
    AjaxOptions,
    APIResponseClass<TaskAssignmentClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof taskAssignmentService.delete>) => (
      taskAssignmentService.delete(...args)
    ),
    ResponseClassCreator: createTaskAssignmentDeleteObject,
    typeName: 'useTaskAssignmentDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTaskAssignmentDelete.mock = () => new Error('Mock has not been implemented!');
useTaskAssignmentDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTaskAssignmentDelete.createObject = createTaskAssignmentDeleteObject;

export const createTaskAssignmentSearchObject = (value: APIResponse<PageResponse<TaskAssignment>>) => new APIResponseClass<PageResponseClass<TaskAssignmentClass>>(value, (value: PageResponse<TaskAssignment>) => new PageResponseClass<TaskAssignmentClass>(value, (value: TaskAssignment) => new TaskAssignmentClass(value)));
export const useTaskAssignmentSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<TaskAssignment>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<TaskAssignment>,
    APIResponse<PageResponse<TaskAssignment>>,
    // @ts-ignore
    typeof taskAssignmentService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<TaskAssignmentClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof taskAssignmentService.search>) => (
      taskAssignmentService.search(...args)
    ),
    ResponseClassCreator: createTaskAssignmentSearchObject,
    typeName: 'useTaskAssignmentSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTaskAssignmentSearch.mock = () => new Error('Mock has not been implemented!');
useTaskAssignmentSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTaskAssignmentSearch.createObject = createTaskAssignmentSearchObject;

export interface TaskPayload {
	alertId?: Nullable<string>;
	category?: Nullable<string>;
}
export interface Task extends BaseModel {
	identifier?: Nullable<string>;
	organizationId?: Nullable<string>;
	patientId?: Nullable<string>;
	teamId?: Nullable<string>;
	eventType?: Nullable<string>;
	taskCategory?: Nullable<string>;
	taskType?: Nullable<string>;
	payload?: Nullable<TaskPayload>;
	assignedToRD?: Nullable<string>;
	assignedToCA?: Nullable<string>;
}
export class TaskPayloadClass {
  protected readonly _value: TaskPayload;

	constructor(value: TaskPayload) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get alertId() {
    return this._value.alertId;
  }

  get alertIdWithDefault() {
    return this._value.alertId || '';
  }

  get category() {
    return this._value.category;
  }

  get categoryWithDefault() {
    return this._value.category || '';
  }
}
export class TaskClass extends BaseModelClass {
  protected readonly _value: Task;

	constructor(value: Task) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get identifier() {
    return this._value.identifier;
  }

  get identifierWithDefault() {
    return this._value.identifier || '';
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get patientId() {
    return this._value.patientId;
  }

  get teamId() {
    return this._value.teamId;
  }

  get eventType() {
    return this._value.eventType;
  }

  get eventTypeWithDefault() {
    return this._value.eventType || '';
  }

  get taskCategory() {
    return this._value.taskCategory;
  }

  get taskCategoryWithDefault() {
    return this._value.taskCategory || '';
  }

  get taskType() {
    return this._value.taskType;
  }

  get taskTypeWithDefault() {
    return this._value.taskType || '';
  }

  get payload() {
    return this._value.payload !== undefined && this._value.payload !== null ? new TaskPayloadClass(this._value.payload) : this._value.payload;
  }

  get assignedToRD() {
    return this._value.assignedToRD;
  }

  get assignedToRDWithDefault() {
    return this._value.assignedToRD || '';
  }

  get assignedToCA() {
    return this._value.assignedToCA;
  }

  get assignedToCAWithDefault() {
    return this._value.assignedToCA || '';
  }
}
export class TaskService extends BaseController<Task, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/tasks';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const taskService = new TaskService();


export const createTaskGetObject = (value: APIResponse<Task>) => new APIResponseClass<TaskClass>(value, (value: Task) => new TaskClass(value));
export const useTaskGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<Task>,
    // @ts-ignore
    typeof taskService.get,
    GetOptions,
    APIResponseClass<TaskClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof taskService.get>) => (
      taskService.get(...args)
    ),
    ResponseClassCreator: createTaskGetObject,
    typeName: 'useTaskGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTaskGet.mock = () => new Error('Mock has not been implemented!');
useTaskGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTaskGet.createObject = createTaskGetObject;

export const createTaskUpdateObject = (value: APIResponse<Task>) => new APIResponseClass<TaskClass>(value, (value: Task) => new TaskClass(value));
export const useTaskUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, Task>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, Task>,
    APIResponse<Task>,
    // @ts-ignore
    typeof taskService.update,
    AjaxOptions,
    APIResponseClass<TaskClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof taskService.update>) => (
      taskService.update(...args)
    ),
    ResponseClassCreator: createTaskUpdateObject,
    typeName: 'useTaskUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTaskUpdate.mock = () => new Error('Mock has not been implemented!');
useTaskUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTaskUpdate.createObject = createTaskUpdateObject;

export const createTaskInsertObject = (value: APIResponse<Task>) => new APIResponseClass<TaskClass>(value, (value: Task) => new TaskClass(value));
export const useTaskInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<Task>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<Task>,
    APIResponse<Task>,
    // @ts-ignore
    typeof taskService.insert,
    AjaxOptions,
    APIResponseClass<TaskClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof taskService.insert>) => (
      taskService.insert(...args)
    ),
    ResponseClassCreator: createTaskInsertObject,
    typeName: 'useTaskInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTaskInsert.mock = () => new Error('Mock has not been implemented!');
useTaskInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTaskInsert.createObject = createTaskInsertObject;

export const createTaskDeleteObject = (value: APIResponse<Task>) => new APIResponseClass<TaskClass>(value, (value: Task) => new TaskClass(value));
export const useTaskDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<Task>,
    // @ts-ignore
    typeof taskService.delete,
    AjaxOptions,
    APIResponseClass<TaskClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof taskService.delete>) => (
      taskService.delete(...args)
    ),
    ResponseClassCreator: createTaskDeleteObject,
    typeName: 'useTaskDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTaskDelete.mock = () => new Error('Mock has not been implemented!');
useTaskDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTaskDelete.createObject = createTaskDeleteObject;

export const createTaskSearchObject = (value: APIResponse<PageResponse<Task>>) => new APIResponseClass<PageResponseClass<TaskClass>>(value, (value: PageResponse<Task>) => new PageResponseClass<TaskClass>(value, (value: Task) => new TaskClass(value)));
export const useTaskSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<Task>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<Task>,
    APIResponse<PageResponse<Task>>,
    // @ts-ignore
    typeof taskService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<TaskClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof taskService.search>) => (
      taskService.search(...args)
    ),
    ResponseClassCreator: createTaskSearchObject,
    typeName: 'useTaskSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTaskSearch.mock = () => new Error('Mock has not been implemented!');
useTaskSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTaskSearch.createObject = createTaskSearchObject;

export interface GoalItem {
	weekOfYear?: Nullable<string>;
	goal?: Nullable<number>;
	actual?: Nullable<number>;
	updatedAt?: Nullable<string>;
}
export interface TeamGoal {
	clinicId?: Nullable<string>;
	goals?: Nullable<GoalItem[]>;
}
export class GoalItemClass {
  protected readonly _value: GoalItem;

	constructor(value: GoalItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get weekOfYear() {
    return this._value.weekOfYear;
  }

  get weekOfYearWithDefault() {
    return this._value.weekOfYear || '';
  }

  get goal() {
    return this._value.goal;
  }

  get goalWithDefault() {
    return this._value.goal || 0;
  }

  get actual() {
    return this._value.actual;
  }

  get actualWithDefault() {
    return this._value.actual || 0;
  }

  get updatedAt() {
    return (this._value.updatedAt !== undefined && this._value.updatedAt !== null) ? dayjs(this._value.updatedAt) : this._value.updatedAt;
  }
}
export class TeamGoalClass {
  protected readonly _value: TeamGoal;

	constructor(value: TeamGoal) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get clinicId() {
    return this._value.clinicId;
  }

  get clinicIdWithDefault() {
    return this._value.clinicId || '';
  }

  get goals() {
    return this._value.goals !== undefined && this._value.goals !== null ? map(this._value.goals, (v) => new GoalItemClass(v)) : this._value.goals;
  }

  get goalsWithDefault() {
    return this._value.goals || [];
  }
}
export interface ClinicItem {
	id?: Nullable<string>;
	name?: Nullable<string>;
	childClinics?: Nullable<ClinicItem[]>;
}
export interface HierarchyClinics {
	teams?: Nullable<ClinicItem[]>;
}
export class ClinicItemClass {
  protected readonly _value: ClinicItem;

	constructor(value: ClinicItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get id() {
    return this._value.id;
  }

  get idWithDefault() {
    return this._value.id || '';
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get childClinics() {
    return this._value.childClinics !== undefined && this._value.childClinics !== null ? map(this._value.childClinics, (v) => new ClinicItemClass(v)) : this._value.childClinics;
  }

  get childClinicsWithDefault() {
    return this._value.childClinics || [];
  }
}
export class HierarchyClinicsClass {
  protected readonly _value: HierarchyClinics;

	constructor(value: HierarchyClinics) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get teams() {
    return this._value.teams !== undefined && this._value.teams !== null ? map(this._value.teams, (v) => new ClinicItemClass(v)) : this._value.teams;
  }

  get teamsWithDefault() {
    return this._value.teams || [];
  }
}
export interface TeamGoalGetGoalsParams {
	clinicId: string;
}

export interface TeamGoalSaveGoalsParams {
	clinicId: string;
	teamGoal: TeamGoal;
}

export interface TeamGoalGetClinicsParams {
}

export class TeamGoalService {
  protected baseURL = '';

  protected basePath = '/v1/uc/team-goal';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public getGoals({ baseUrl, params, headers }: RequestOption<TeamGoalGetGoalsParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<TeamGoal>>(`${baseUrl || this.baseURL}${this.basePath}/goals/${params.clinicId}${getQueryStrings(queryList)}`, { headers });
	}

	public saveGoals({ baseUrl, params, headers }: RequestOption<TeamGoalSaveGoalsParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<TeamGoal>>(`${baseUrl || this.baseURL}${this.basePath}/goals/${params.clinicId}${getQueryStrings(queryList)}`, params.teamGoal, { headers });
	}

	public getClinics({ baseUrl, params, headers }: RequestOption<TeamGoalGetClinicsParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<HierarchyClinics>>(`${baseUrl || this.baseURL}${this.basePath}/clinics${getQueryStrings(queryList)}`, { headers });
	}
}

export const teamGoalService = new TeamGoalService();

export const createTeamGoalGetGoalsObject = (value: APIResponse<TeamGoal>) => new APIResponseClass<TeamGoalClass>(value, (value: TeamGoal) => new TeamGoalClass(value));

export const useTeamGoalGetGoals = <RCN = undefined>(configs: MakeRequestHookConfig<TeamGoalGetGoalsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TeamGoalGetGoalsParams,
    APIResponse<TeamGoal>,
    typeof teamGoalService.getGoals,
    GetOptions,
    APIResponseClass<TeamGoalClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof teamGoalService.getGoals>) => (
      teamGoalService.getGoals(...args)
    ),
    ResponseClassCreator: createTeamGoalGetGoalsObject,
    typeName: 'useTeamGoalGetGoals',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTeamGoalGetGoals.mock = () => new Error('Mock has not been implemented!');
useTeamGoalGetGoals.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTeamGoalGetGoals.createObject = createTeamGoalGetGoalsObject;

export const createTeamGoalSaveGoalsObject = (value: APIResponse<TeamGoal>) => new APIResponseClass<TeamGoalClass>(value, (value: TeamGoal) => new TeamGoalClass(value));

export const useTeamGoalSaveGoals = <RCN = undefined>(configs: MakeRequestHookConfig<TeamGoalSaveGoalsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TeamGoalSaveGoalsParams,
    APIResponse<TeamGoal>,
    typeof teamGoalService.saveGoals,
    AjaxOptions,
    APIResponseClass<TeamGoalClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof teamGoalService.saveGoals>) => (
      teamGoalService.saveGoals(...args)
    ),
    ResponseClassCreator: createTeamGoalSaveGoalsObject,
    typeName: 'useTeamGoalSaveGoals',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTeamGoalSaveGoals.mock = () => new Error('Mock has not been implemented!');
useTeamGoalSaveGoals.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTeamGoalSaveGoals.createObject = createTeamGoalSaveGoalsObject;

export const createTeamGoalGetClinicsObject = (value: APIResponse<HierarchyClinics>) => new APIResponseClass<HierarchyClinicsClass>(value, (value: HierarchyClinics) => new HierarchyClinicsClass(value));

export const useTeamGoalGetClinics = <RCN = undefined>(configs: MakeRequestHookConfig<TeamGoalGetClinicsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TeamGoalGetClinicsParams,
    APIResponse<HierarchyClinics>,
    typeof teamGoalService.getClinics,
    GetOptions,
    APIResponseClass<HierarchyClinicsClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof teamGoalService.getClinics>) => (
      teamGoalService.getClinics(...args)
    ),
    ResponseClassCreator: createTeamGoalGetClinicsObject,
    typeName: 'useTeamGoalGetClinics',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTeamGoalGetClinics.mock = () => new Error('Mock has not been implemented!');
useTeamGoalGetClinics.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTeamGoalGetClinics.createObject = createTeamGoalGetClinicsObject;
export enum RevenueModelCategoryEnum {
	CCM_RPM = 'CCM_RPM',
	CCM_RPM_APCM = 'CCM_RPM_APCM',
	VBC = 'VBC',
	DIRECT_PAY = 'DIRECT_PAY',
}
export interface TemplateGroup extends BaseModel {
	name?: Nullable<string>;
	revenueModel?: Nullable<RevenueModelCategoryEnum>;
	addOnServiceList?: Nullable<AddOnServiceEnum[]>;
	programCategory?: Nullable<ProgramCategoryEnum[]>;
	programCategoryIn?: Nullable<InFilter<ProgramCategoryEnum>>;
	revenueModelIn?: Nullable<InFilter<RevenueModelCategoryEnum>>;
	addOnServiceIn?: Nullable<InFilter<AddOnServiceEnum>>;
}
export interface ConsentForm extends TemplateGroup {
	languages?: Nullable<string[]>;
}
export class TemplateGroupClass extends BaseModelClass {
  protected readonly _value: TemplateGroup;

	constructor(value: TemplateGroup) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get revenueModel() {
    return this._value.revenueModel;
  }

  revenueModelIsIn(values: RevenueModelCategoryEnum[] = []) {
    return this._value.revenueModel && values.includes(this._value.revenueModel);
  }

  get addOnServiceList() {
    return this._value.addOnServiceList;
  }

  get addOnServiceListWithDefault() {
    return this._value.addOnServiceList || [];
  }

  get programCategory() {
    return this._value.programCategory;
  }

  get programCategoryWithDefault() {
    return this._value.programCategory || [];
  }

  get programCategoryIn() {
    return this._value.programCategoryIn !== undefined && this._value.programCategoryIn !== null ? new InFilterClass(this._value.programCategoryIn) : this._value.programCategoryIn;
  }

  get revenueModelIn() {
    return this._value.revenueModelIn !== undefined && this._value.revenueModelIn !== null ? new InFilterClass(this._value.revenueModelIn) : this._value.revenueModelIn;
  }

  get addOnServiceIn() {
    return this._value.addOnServiceIn !== undefined && this._value.addOnServiceIn !== null ? new InFilterClass(this._value.addOnServiceIn) : this._value.addOnServiceIn;
  }
}
export class ConsentFormClass extends TemplateGroupClass {
  protected readonly _value: ConsentForm;

	constructor(value: ConsentForm) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get languages() {
    return this._value.languages;
  }

  get languagesWithDefault() {
    return this._value.languages || [];
  }
}
export interface TemplateGroupConsentFormsParams {
	searchRequest: SearchRequest<TemplateGroup>;
}

export class TemplateGroupService extends BaseController<TemplateGroup, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/template_group';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public consentForms({ baseUrl, params, headers }: RequestOption<TemplateGroupConsentFormsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<ConsentForm>>>(`${baseUrl || this.baseURL}${this.basePath}/consent-forms${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}
}

export const templateGroupService = new TemplateGroupService();

export const createTemplateGroupConsentFormsObject = (value: APIResponse<PageResponse<ConsentForm>>) => new APIResponseClass<PageResponseClass<ConsentFormClass>>(value, (value: PageResponse<ConsentForm>) => new PageResponseClass<ConsentFormClass>(value, (value: ConsentForm) => new ConsentFormClass(value)));

export const useTemplateGroupConsentForms = <RCN = undefined>(configs: MakeRequestHookConfig<TemplateGroupConsentFormsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TemplateGroupConsentFormsParams,
    APIResponse<PageResponse<ConsentForm>>,
    typeof templateGroupService.consentForms,
    AjaxOptions,
    APIResponseClass<PageResponseClass<ConsentFormClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof templateGroupService.consentForms>) => (
      templateGroupService.consentForms(...args)
    ),
    ResponseClassCreator: createTemplateGroupConsentFormsObject,
    typeName: 'useTemplateGroupConsentForms',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTemplateGroupConsentForms.mock = () => new Error('Mock has not been implemented!');
useTemplateGroupConsentForms.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTemplateGroupConsentForms.createObject = createTemplateGroupConsentFormsObject;


export const createTemplateGroupGetObject = (value: APIResponse<TemplateGroup>) => new APIResponseClass<TemplateGroupClass>(value, (value: TemplateGroup) => new TemplateGroupClass(value));
export const useTemplateGroupGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<TemplateGroup>,
    // @ts-ignore
    typeof templateGroupService.get,
    GetOptions,
    APIResponseClass<TemplateGroupClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof templateGroupService.get>) => (
      templateGroupService.get(...args)
    ),
    ResponseClassCreator: createTemplateGroupGetObject,
    typeName: 'useTemplateGroupGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTemplateGroupGet.mock = () => new Error('Mock has not been implemented!');
useTemplateGroupGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTemplateGroupGet.createObject = createTemplateGroupGetObject;

export const createTemplateGroupUpdateObject = (value: APIResponse<TemplateGroup>) => new APIResponseClass<TemplateGroupClass>(value, (value: TemplateGroup) => new TemplateGroupClass(value));
export const useTemplateGroupUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, TemplateGroup>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, TemplateGroup>,
    APIResponse<TemplateGroup>,
    // @ts-ignore
    typeof templateGroupService.update,
    AjaxOptions,
    APIResponseClass<TemplateGroupClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof templateGroupService.update>) => (
      templateGroupService.update(...args)
    ),
    ResponseClassCreator: createTemplateGroupUpdateObject,
    typeName: 'useTemplateGroupUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTemplateGroupUpdate.mock = () => new Error('Mock has not been implemented!');
useTemplateGroupUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTemplateGroupUpdate.createObject = createTemplateGroupUpdateObject;

export const createTemplateGroupInsertObject = (value: APIResponse<TemplateGroup>) => new APIResponseClass<TemplateGroupClass>(value, (value: TemplateGroup) => new TemplateGroupClass(value));
export const useTemplateGroupInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<TemplateGroup>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<TemplateGroup>,
    APIResponse<TemplateGroup>,
    // @ts-ignore
    typeof templateGroupService.insert,
    AjaxOptions,
    APIResponseClass<TemplateGroupClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof templateGroupService.insert>) => (
      templateGroupService.insert(...args)
    ),
    ResponseClassCreator: createTemplateGroupInsertObject,
    typeName: 'useTemplateGroupInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTemplateGroupInsert.mock = () => new Error('Mock has not been implemented!');
useTemplateGroupInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTemplateGroupInsert.createObject = createTemplateGroupInsertObject;

export const createTemplateGroupDeleteObject = (value: APIResponse<TemplateGroup>) => new APIResponseClass<TemplateGroupClass>(value, (value: TemplateGroup) => new TemplateGroupClass(value));
export const useTemplateGroupDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<TemplateGroup>,
    // @ts-ignore
    typeof templateGroupService.delete,
    AjaxOptions,
    APIResponseClass<TemplateGroupClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof templateGroupService.delete>) => (
      templateGroupService.delete(...args)
    ),
    ResponseClassCreator: createTemplateGroupDeleteObject,
    typeName: 'useTemplateGroupDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTemplateGroupDelete.mock = () => new Error('Mock has not been implemented!');
useTemplateGroupDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTemplateGroupDelete.createObject = createTemplateGroupDeleteObject;

export const createTemplateGroupSearchObject = (value: APIResponse<PageResponse<TemplateGroup>>) => new APIResponseClass<PageResponseClass<TemplateGroupClass>>(value, (value: PageResponse<TemplateGroup>) => new PageResponseClass<TemplateGroupClass>(value, (value: TemplateGroup) => new TemplateGroupClass(value)));
export const useTemplateGroupSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<TemplateGroup>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<TemplateGroup>,
    APIResponse<PageResponse<TemplateGroup>>,
    // @ts-ignore
    typeof templateGroupService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<TemplateGroupClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof templateGroupService.search>) => (
      templateGroupService.search(...args)
    ),
    ResponseClassCreator: createTemplateGroupSearchObject,
    typeName: 'useTemplateGroupSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTemplateGroupSearch.mock = () => new Error('Mock has not been implemented!');
useTemplateGroupSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTemplateGroupSearch.createObject = createTemplateGroupSearchObject;

export enum ReferenceLevelEnum {
	LOW = 'LOW',
	NORMAL = 'NORMAL',
	HIGH = 'HIGH',
	DIABETES = 'DIABETES',
	PRE_DIABETES = 'PRE_DIABETES',
	UNCONTROLLED = 'UNCONTROLLED',
}
export interface ReferenceLevel {
	gt?: Nullable<string>;
	lt?: Nullable<string>;
	isGte?: Nullable<boolean>;
	isLte?: Nullable<boolean>;
	description?: Nullable<ReferenceLevelEnum>;
	gender?: Nullable<string>;
}
export interface LabResultTemplateItem {
	name?: Nullable<string>;
	unit?: Nullable<string>;
	value?: Nullable<string>;
	interval?: Nullable<string>;
	referenceLevels?: Nullable<ReferenceLevel[]>;
}
export interface LabResultTemplate extends BaseModel {
	templateId?: Nullable<string>;
	templateName?: Nullable<string>;
	items?: Nullable<LabResultTemplateItem[]>;
	isCommon?: Nullable<boolean>;
	templateIdNear?: Nullable<string>;
	templateNameNear?: Nullable<string>;
}
export class ReferenceLevelClass {
  protected readonly _value: ReferenceLevel;

	constructor(value: ReferenceLevel) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get gt() {
    return this._value.gt;
  }

  get gtWithDefault() {
    return this._value.gt || '';
  }

  get lt() {
    return this._value.lt;
  }

  get ltWithDefault() {
    return this._value.lt || '';
  }

  get isGte() {
    return this._value.isGte;
  }

  get isGteWithDefault() {
    return this._value.isGte || false;
  }

  get isLte() {
    return this._value.isLte;
  }

  get isLteWithDefault() {
    return this._value.isLte || false;
  }

  get description() {
    return this._value.description;
  }

  descriptionIsIn(values: ReferenceLevelEnum[] = []) {
    return this._value.description && values.includes(this._value.description);
  }

  get gender() {
    return this._value.gender;
  }

  get genderWithDefault() {
    return this._value.gender || '';
  }
}
export class LabResultTemplateItemClass {
  protected readonly _value: LabResultTemplateItem;

	constructor(value: LabResultTemplateItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || '';
  }

  get interval() {
    return this._value.interval;
  }

  get intervalWithDefault() {
    return this._value.interval || '';
  }

  get referenceLevels() {
    return this._value.referenceLevels !== undefined && this._value.referenceLevels !== null ? map(this._value.referenceLevels, (v) => new ReferenceLevelClass(v)) : this._value.referenceLevels;
  }

  get referenceLevelsWithDefault() {
    return this._value.referenceLevels || [];
  }
}
export class LabResultTemplateClass extends BaseModelClass {
  protected readonly _value: LabResultTemplate;

	constructor(value: LabResultTemplate) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get templateId() {
    return this._value.templateId;
  }

  get templateIdWithDefault() {
    return this._value.templateId || '';
  }

  get templateName() {
    return this._value.templateName;
  }

  get templateNameWithDefault() {
    return this._value.templateName || '';
  }

  get items() {
    return this._value.items !== undefined && this._value.items !== null ? map(this._value.items, (v) => new LabResultTemplateItemClass(v)) : this._value.items;
  }

  get itemsWithDefault() {
    return this._value.items || [];
  }

  get isCommon() {
    return this._value.isCommon;
  }

  get isCommonWithDefault() {
    return this._value.isCommon || false;
  }

  get templateIdNear() {
    return this._value.templateIdNear;
  }

  get templateIdNearWithDefault() {
    return this._value.templateIdNear || '';
  }

  get templateNameNear() {
    return this._value.templateNameNear;
  }

  get templateNameNearWithDefault() {
    return this._value.templateNameNear || '';
  }
}
export interface DeviceModelInfo extends BaseModel {
	model?: Nullable<DeviceModelEnum>;
	quickStartGuideUrl?: Nullable<string>;
	troubleshootingUrl?: Nullable<string>;
}
export class DeviceModelInfoClass extends BaseModelClass {
  protected readonly _value: DeviceModelInfo;

	constructor(value: DeviceModelInfo) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get model() {
    return this._value.model;
  }

  modelIsIn(values: DeviceModelEnum[] = []) {
    return this._value.model && values.includes(this._value.model);
  }

  get quickStartGuideUrl() {
    return this._value.quickStartGuideUrl;
  }

  get quickStartGuideUrlWithDefault() {
    return this._value.quickStartGuideUrl || '';
  }

  get troubleshootingUrl() {
    return this._value.troubleshootingUrl;
  }

  get troubleshootingUrlWithDefault() {
    return this._value.troubleshootingUrl || '';
  }
}
export enum ConditionCheckboxEnum {
	DM = 'DM',
	HLD = 'HLD',
	HTN = 'HTN',
	PreDM = 'PreDM',
	CHF = 'CHF',
	CKD = 'CKD',
	COPD = 'COPD',
	Obesity = 'Obesity',
	ESRD_Dialysis = 'ESRD_Dialysis',
	NONE = 'NONE',
}
export interface ClinicGoalTemplate extends BaseModel {
	codes?: Nullable<string[]>;
	condition?: Nullable<ConditionEnum>;
	chronicDisease?: Nullable<ChronicDiseaseEnum>;
	vitals?: Nullable<VitalEnumType[]>;
	conditionCheckbox?: Nullable<ConditionCheckboxEnum>;
	values?: Nullable<string[]>;
	goals?: Nullable<ClinicGoalEnum[]>;
	isRPM?: Nullable<boolean>;
	isCCM?: Nullable<boolean>;
	isMNT?: Nullable<boolean>;
}
export class ClinicGoalTemplateClass extends BaseModelClass {
  protected readonly _value: ClinicGoalTemplate;

	constructor(value: ClinicGoalTemplate) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get codes() {
    return this._value.codes;
  }

  get codesWithDefault() {
    return this._value.codes || [];
  }

  get condition() {
    return this._value.condition;
  }

  conditionIsIn(values: ConditionEnum[] = []) {
    return this._value.condition && values.includes(this._value.condition);
  }

  get chronicDisease() {
    return this._value.chronicDisease;
  }

  chronicDiseaseIsIn(values: ChronicDiseaseEnum[] = []) {
    return this._value.chronicDisease && values.includes(this._value.chronicDisease);
  }

  get vitals() {
    return this._value.vitals;
  }

  get vitalsWithDefault() {
    return this._value.vitals || [];
  }

  get conditionCheckbox() {
    return this._value.conditionCheckbox;
  }

  conditionCheckboxIsIn(values: ConditionCheckboxEnum[] = []) {
    return this._value.conditionCheckbox && values.includes(this._value.conditionCheckbox);
  }

  get values() {
    return this._value.values;
  }

  get valuesWithDefault() {
    return this._value.values || [];
  }

  get goals() {
    return this._value.goals;
  }

  get goalsWithDefault() {
    return this._value.goals || [];
  }

  get isRPM() {
    return this._value.isRPM;
  }

  get isRPMWithDefault() {
    return this._value.isRPM || false;
  }

  get isCCM() {
    return this._value.isCCM;
  }

  get isCCMWithDefault() {
    return this._value.isCCM || false;
  }

  get isMNT() {
    return this._value.isMNT;
  }

  get isMNTWithDefault() {
    return this._value.isMNT || false;
  }
}
export interface BehaviorGoalTemplate extends BaseModel {
	type?: Nullable<BehaviorGoalTypeEnum>;
	category?: Nullable<BehaviorGoalCategoryEnum>;
	values?: Nullable<string[]>;
	typeNear?: Nullable<string>;
	categoryNear?: Nullable<string>;
	valueNear?: Nullable<string>;
}
export class BehaviorGoalTemplateClass extends BaseModelClass {
  protected readonly _value: BehaviorGoalTemplate;

	constructor(value: BehaviorGoalTemplate) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: BehaviorGoalTypeEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get category() {
    return this._value.category;
  }

  categoryIsIn(values: BehaviorGoalCategoryEnum[] = []) {
    return this._value.category && values.includes(this._value.category);
  }

  get values() {
    return this._value.values;
  }

  get valuesWithDefault() {
    return this._value.values || [];
  }

  get typeNear() {
    return this._value.typeNear;
  }

  get typeNearWithDefault() {
    return this._value.typeNear || '';
  }

  get categoryNear() {
    return this._value.categoryNear;
  }

  get categoryNearWithDefault() {
    return this._value.categoryNear || '';
  }

  get valueNear() {
    return this._value.valueNear;
  }

  get valueNearWithDefault() {
    return this._value.valueNear || '';
  }
}
export interface TemplatesGetParams {
	id: string;
}

export interface TemplatesUpdateParams {
	id: string;
	request: LabResultTemplate;
}

export interface TemplatesInsertParams {
	t: LabResultTemplate;
}

export interface TemplatesDeleteParams {
	id: string;
}

export interface TemplatesSearchParams {
	searchRequest: SearchRequest<LabResultTemplate>;
}

export interface TemplatesGetDefaultClinicGoalParams {
	condition: ConditionEnum;
}

export interface TemplatesGetDeviceModelsParams {
}

export interface TemplatesGetClinicGoalTemplateParams {
}

export interface TemplatesGetBehaviorGoalTemplateParams {
	searchRequest: SearchRequest<BehaviorGoalTemplate>;
}

export class TemplatesService {
  protected baseURL = '';

  protected basePath = '/v1/uc/templates';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public get({ baseUrl, params, headers }: RequestOption<TemplatesGetParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<LabResultTemplate>>(`${baseUrl || this.baseURL}${this.basePath}/lab-result/${params.id}${getQueryStrings(queryList)}`, { headers });
	}

	public update({ baseUrl, params, headers }: RequestOption<TemplatesUpdateParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<LabResultTemplate>>(`${baseUrl || this.baseURL}${this.basePath}/lab-result/${params.id}${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public insert({ baseUrl, params, headers }: RequestOption<TemplatesInsertParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<LabResultTemplate>>(`${baseUrl || this.baseURL}${this.basePath}/lab-result${getQueryStrings(queryList)}`, params.t, { headers });
	}

	public delete({ baseUrl, params, headers }: RequestOption<TemplatesDeleteParams>) {
		const queryList: QueryParam[] = [];
		return axios.delete<APIResponse<LabResultTemplate>>(`${baseUrl || this.baseURL}${this.basePath}/lab-result/${params.id}${getQueryStrings(queryList)}`, { headers });
	}

	public search({ baseUrl, params, headers }: RequestOption<TemplatesSearchParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<LabResultTemplate>>>(`${baseUrl || this.baseURL}${this.basePath}/lab-result/search${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public getDefaultClinicGoal({ baseUrl, params, headers }: RequestOption<TemplatesGetDefaultClinicGoalParams>) {
		const queryList: QueryParam[] = [];
		if (params.condition !== undefined && params.condition !== null) {
			queryList.push({ name: 'condition', value: params.condition.toString() });
		}
		return axios.get<APIResponse<ClinicGoalEnum>>(`${baseUrl || this.baseURL}${this.basePath}/default-clinical-goal${getQueryStrings(queryList)}`, { headers });
	}

	public getDeviceModels({ baseUrl, params, headers }: RequestOption<TemplatesGetDeviceModelsParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<{ [key: DeviceModelEnum]: DeviceModelInfo }>>(`${baseUrl || this.baseURL}${this.basePath}/device-models${getQueryStrings(queryList)}`, { headers });
	}

	public getClinicGoalTemplate({ baseUrl, params, headers }: RequestOption<TemplatesGetClinicGoalTemplateParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<ClinicGoalTemplate[]>>(`${baseUrl || this.baseURL}${this.basePath}/clinical-goal-template${getQueryStrings(queryList)}`, { headers });
	}

	public getBehaviorGoalTemplate({ baseUrl, params, headers }: RequestOption<TemplatesGetBehaviorGoalTemplateParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<BehaviorGoalTemplate>>>(`${baseUrl || this.baseURL}${this.basePath}/behavior-goal-template${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}
}

export const templatesService = new TemplatesService();

export const createTemplatesGetObject = (value: APIResponse<LabResultTemplate>) => new APIResponseClass<LabResultTemplateClass>(value, (value: LabResultTemplate) => new LabResultTemplateClass(value));

export const useTemplatesGet = <RCN = undefined>(configs: MakeRequestHookConfig<TemplatesGetParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TemplatesGetParams,
    APIResponse<LabResultTemplate>,
    typeof templatesService.get,
    GetOptions,
    APIResponseClass<LabResultTemplateClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof templatesService.get>) => (
      templatesService.get(...args)
    ),
    ResponseClassCreator: createTemplatesGetObject,
    typeName: 'useTemplatesGet',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTemplatesGet.mock = () => new Error('Mock has not been implemented!');
useTemplatesGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTemplatesGet.createObject = createTemplatesGetObject;

export const createTemplatesUpdateObject = (value: APIResponse<LabResultTemplate>) => new APIResponseClass<LabResultTemplateClass>(value, (value: LabResultTemplate) => new LabResultTemplateClass(value));

export const useTemplatesUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<TemplatesUpdateParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TemplatesUpdateParams,
    APIResponse<LabResultTemplate>,
    typeof templatesService.update,
    AjaxOptions,
    APIResponseClass<LabResultTemplateClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof templatesService.update>) => (
      templatesService.update(...args)
    ),
    ResponseClassCreator: createTemplatesUpdateObject,
    typeName: 'useTemplatesUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTemplatesUpdate.mock = () => new Error('Mock has not been implemented!');
useTemplatesUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTemplatesUpdate.createObject = createTemplatesUpdateObject;

export const createTemplatesInsertObject = (value: APIResponse<LabResultTemplate>) => new APIResponseClass<LabResultTemplateClass>(value, (value: LabResultTemplate) => new LabResultTemplateClass(value));

export const useTemplatesInsert = <RCN = undefined>(configs: MakeRequestHookConfig<TemplatesInsertParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TemplatesInsertParams,
    APIResponse<LabResultTemplate>,
    typeof templatesService.insert,
    AjaxOptions,
    APIResponseClass<LabResultTemplateClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof templatesService.insert>) => (
      templatesService.insert(...args)
    ),
    ResponseClassCreator: createTemplatesInsertObject,
    typeName: 'useTemplatesInsert',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTemplatesInsert.mock = () => new Error('Mock has not been implemented!');
useTemplatesInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTemplatesInsert.createObject = createTemplatesInsertObject;

export const createTemplatesDeleteObject = (value: APIResponse<LabResultTemplate>) => new APIResponseClass<LabResultTemplateClass>(value, (value: LabResultTemplate) => new LabResultTemplateClass(value));

export const useTemplatesDelete = <RCN = undefined>(configs: MakeRequestHookConfig<TemplatesDeleteParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TemplatesDeleteParams,
    APIResponse<LabResultTemplate>,
    typeof templatesService.delete,
    AjaxOptions,
    APIResponseClass<LabResultTemplateClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof templatesService.delete>) => (
      templatesService.delete(...args)
    ),
    ResponseClassCreator: createTemplatesDeleteObject,
    typeName: 'useTemplatesDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTemplatesDelete.mock = () => new Error('Mock has not been implemented!');
useTemplatesDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTemplatesDelete.createObject = createTemplatesDeleteObject;

export const createTemplatesSearchObject = (value: APIResponse<PageResponse<LabResultTemplate>>) => new APIResponseClass<PageResponseClass<LabResultTemplateClass>>(value, (value: PageResponse<LabResultTemplate>) => new PageResponseClass<LabResultTemplateClass>(value, (value: LabResultTemplate) => new LabResultTemplateClass(value)));

export const useTemplatesSearch = <RCN = undefined>(configs: MakeRequestHookConfig<TemplatesSearchParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TemplatesSearchParams,
    APIResponse<PageResponse<LabResultTemplate>>,
    typeof templatesService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<LabResultTemplateClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof templatesService.search>) => (
      templatesService.search(...args)
    ),
    ResponseClassCreator: createTemplatesSearchObject,
    typeName: 'useTemplatesSearch',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTemplatesSearch.mock = () => new Error('Mock has not been implemented!');
useTemplatesSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTemplatesSearch.createObject = createTemplatesSearchObject;

export const createTemplatesGetDefaultClinicGoalObject = (value: APIResponse<ClinicGoalEnum>) => new APIResponseClass<ClinicGoalEnum>(value, (value: ClinicGoalEnum) => new ClinicGoalEnum(value));

export const useTemplatesGetDefaultClinicGoal = <RCN = undefined>(configs: MakeRequestHookConfig<TemplatesGetDefaultClinicGoalParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TemplatesGetDefaultClinicGoalParams,
    APIResponse<ClinicGoalEnum>,
    typeof templatesService.getDefaultClinicGoal,
    GetOptions,
    APIResponseClass<ClinicGoalEnum>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof templatesService.getDefaultClinicGoal>) => (
      templatesService.getDefaultClinicGoal(...args)
    ),
    ResponseClassCreator: createTemplatesGetDefaultClinicGoalObject,
    typeName: 'useTemplatesGetDefaultClinicGoal',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTemplatesGetDefaultClinicGoal.mock = () => new Error('Mock has not been implemented!');
useTemplatesGetDefaultClinicGoal.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTemplatesGetDefaultClinicGoal.createObject = createTemplatesGetDefaultClinicGoalObject;

export const createTemplatesGetDeviceModelsObject = (value: APIResponse<{ [key: DeviceModelEnum]: DeviceModelInfo }>) => new APIResponseClass<{ [key: DeviceModelEnum]: DeviceModelInfo }>(value, (v) => (v));

export const useTemplatesGetDeviceModels = <RCN = undefined>(configs: MakeRequestHookConfig<TemplatesGetDeviceModelsParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TemplatesGetDeviceModelsParams,
    APIResponse<{ [key: DeviceModelEnum]: DeviceModelInfo }>,
    typeof templatesService.getDeviceModels,
    GetOptions,
    APIResponseClass<{ [key: DeviceModelEnum]: DeviceModelInfo }>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof templatesService.getDeviceModels>) => (
      templatesService.getDeviceModels(...args)
    ),
    ResponseClassCreator: createTemplatesGetDeviceModelsObject,
    typeName: 'useTemplatesGetDeviceModels',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTemplatesGetDeviceModels.mock = () => new Error('Mock has not been implemented!');
useTemplatesGetDeviceModels.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTemplatesGetDeviceModels.createObject = createTemplatesGetDeviceModelsObject;

export const createTemplatesGetClinicGoalTemplateObject = (value: APIResponse<ClinicGoalTemplate[]>) => new APIResponseClass<ClinicGoalTemplateClass[]>(value, (v) => map(v, (value: ClinicGoalTemplate) => new ClinicGoalTemplateClass(value)));

export const useTemplatesGetClinicGoalTemplate = <RCN = undefined>(configs: MakeRequestHookConfig<TemplatesGetClinicGoalTemplateParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TemplatesGetClinicGoalTemplateParams,
    APIResponse<ClinicGoalTemplate[]>,
    typeof templatesService.getClinicGoalTemplate,
    GetOptions,
    APIResponseClass<ClinicGoalTemplateClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof templatesService.getClinicGoalTemplate>) => (
      templatesService.getClinicGoalTemplate(...args)
    ),
    ResponseClassCreator: createTemplatesGetClinicGoalTemplateObject,
    typeName: 'useTemplatesGetClinicGoalTemplate',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTemplatesGetClinicGoalTemplate.mock = () => new Error('Mock has not been implemented!');
useTemplatesGetClinicGoalTemplate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTemplatesGetClinicGoalTemplate.createObject = createTemplatesGetClinicGoalTemplateObject;

export const createTemplatesGetBehaviorGoalTemplateObject = (value: APIResponse<PageResponse<BehaviorGoalTemplate>>) => new APIResponseClass<PageResponseClass<BehaviorGoalTemplateClass>>(value, (value: PageResponse<BehaviorGoalTemplate>) => new PageResponseClass<BehaviorGoalTemplateClass>(value, (value: BehaviorGoalTemplate) => new BehaviorGoalTemplateClass(value)));

export const useTemplatesGetBehaviorGoalTemplate = <RCN = undefined>(configs: MakeRequestHookConfig<TemplatesGetBehaviorGoalTemplateParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TemplatesGetBehaviorGoalTemplateParams,
    APIResponse<PageResponse<BehaviorGoalTemplate>>,
    typeof templatesService.getBehaviorGoalTemplate,
    AjaxOptions,
    APIResponseClass<PageResponseClass<BehaviorGoalTemplateClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof templatesService.getBehaviorGoalTemplate>) => (
      templatesService.getBehaviorGoalTemplate(...args)
    ),
    ResponseClassCreator: createTemplatesGetBehaviorGoalTemplateObject,
    typeName: 'useTemplatesGetBehaviorGoalTemplate',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTemplatesGetBehaviorGoalTemplate.mock = () => new Error('Mock has not been implemented!');
useTemplatesGetBehaviorGoalTemplate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTemplatesGetBehaviorGoalTemplate.createObject = createTemplatesGetBehaviorGoalTemplateObject;
export enum TicketStatusEnum {
	OPEN = 'OPEN',
	RESOLVED = 'RESOLVED',
	CLOSED = 'CLOSED',
}
export enum TicketHistoryEnum {
	CREATE = 'CREATE',
	REOPEN = 'REOPEN',
	RESOLVE = 'RESOLVE',
	DESCRIPTION = 'DESCRIPTION',
	DUE_DATE = 'DUE_DATE',
	PATIENT_NAME = 'PATIENT_NAME',
	PRIORITY = 'PRIORITY',
	ASSIGN = 'ASSIGN',
	ADD_COMMENT = 'ADD_COMMENT',
	EDIT_COMMENT = 'EDIT_COMMENT',
	DELETE_COMMENT = 'DELETE_COMMENT',
	CLOSED = 'CLOSED',
}
export interface TicketHistory {
	type?: Nullable<TicketHistoryEnum>;
	assignedBy?: Nullable<string>;
	assignedTo?: Nullable<string>;
	editBy?: Nullable<string>;
	assignedByFullNameTitle?: Nullable<string>;
	assignedToFullNameTitle?: Nullable<string>;
	editByFullNameTitle?: Nullable<string>;
	updatedDescription?: Nullable<string>;
	oldDescription?: Nullable<string>;
	updatedDueDate?: Nullable<string>;
	oldDueDate?: Nullable<string>;
	updatedPriorityLevel?: Nullable<PriorityLevelEnum>;
	oldPriorityLevel?: Nullable<PriorityLevelEnum>;
	updatedPatientFullName?: Nullable<string>;
	oldPatientFullName?: Nullable<string>;
	comment?: Nullable<string>;
	assignedAt?: Nullable<string>;
	createdAt?: Nullable<string>;
	assignedToUser?: Nullable<LimitedUser>;
	assignedByUser?: Nullable<LimitedUser>;
	editByUser?: Nullable<LimitedUser>;
}
export interface Ticket extends BaseModel {
	ticketNumber?: Nullable<number>;
	ticketNumberDisplay?: Nullable<string>;
	memberId?: Nullable<string>;
	firstName?: Nullable<string>;
	lastName?: Nullable<string>;
	patientUser?: Nullable<LimitedPatient>;
	organizationId?: Nullable<string>;
	description?: Nullable<string>;
	assignedTo?: Nullable<string>;
	createdBy?: Nullable<string>;
	priorityLevel?: Nullable<PriorityLevelEnum>;
	priority?: Nullable<number>;
	status?: Nullable<TicketStatusEnum>;
	histories?: Nullable<TicketHistory[]>;
	assignedToDate?: Nullable<string>;
	dueDate?: Nullable<string>;
	resolvedAt?: Nullable<string>;
	resolvedBy?: Nullable<string>;
	closedAt?: Nullable<string>;
	closedBy?: Nullable<string>;
	ticketNumberDisplayNear?: Nullable<string>;
	firstNameNear?: Nullable<string>;
	lastNameNear?: Nullable<string>;
	nameNear?: Nullable<string>;
	ticketNumberIn?: Nullable<InFilter<number>>;
	memberIdIn?: Nullable<InFilter<string>>;
	createdByIn?: Nullable<InFilter<string>>;
	assignedToIn?: Nullable<InFilter<string>>;
	statusIn?: Nullable<InFilter<TicketStatusEnum>>;
	priorityLevelIn?: Nullable<InFilter<PriorityLevelEnum>>;
	createdByUser?: Nullable<LimitedUser>;
	assignedToUser?: Nullable<LimitedUser>;
	doctorUser?: Nullable<LimitedUser>;
	resolvedByDoctorUser?: Nullable<LimitedUser>;
	clinic?: Nullable<LimitedClinic>;
}
export class TicketHistoryClass {
  protected readonly _value: TicketHistory;

	constructor(value: TicketHistory) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: TicketHistoryEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get assignedBy() {
    return this._value.assignedBy;
  }

  get assignedTo() {
    return this._value.assignedTo;
  }

  get editBy() {
    return this._value.editBy;
  }

  get assignedByFullNameTitle() {
    return this._value.assignedByFullNameTitle;
  }

  get assignedByFullNameTitleWithDefault() {
    return this._value.assignedByFullNameTitle || '';
  }

  get assignedToFullNameTitle() {
    return this._value.assignedToFullNameTitle;
  }

  get assignedToFullNameTitleWithDefault() {
    return this._value.assignedToFullNameTitle || '';
  }

  get editByFullNameTitle() {
    return this._value.editByFullNameTitle;
  }

  get editByFullNameTitleWithDefault() {
    return this._value.editByFullNameTitle || '';
  }

  get updatedDescription() {
    return this._value.updatedDescription;
  }

  get updatedDescriptionWithDefault() {
    return this._value.updatedDescription || '';
  }

  get oldDescription() {
    return this._value.oldDescription;
  }

  get oldDescriptionWithDefault() {
    return this._value.oldDescription || '';
  }

  get updatedDueDate() {
    return (this._value.updatedDueDate !== undefined && this._value.updatedDueDate !== null) ? dayjs(this._value.updatedDueDate) : this._value.updatedDueDate;
  }

  get oldDueDate() {
    return (this._value.oldDueDate !== undefined && this._value.oldDueDate !== null) ? dayjs(this._value.oldDueDate) : this._value.oldDueDate;
  }

  get updatedPriorityLevel() {
    return this._value.updatedPriorityLevel;
  }

  updatedPriorityLevelIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.updatedPriorityLevel && values.includes(this._value.updatedPriorityLevel);
  }

  get oldPriorityLevel() {
    return this._value.oldPriorityLevel;
  }

  oldPriorityLevelIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.oldPriorityLevel && values.includes(this._value.oldPriorityLevel);
  }

  get updatedPatientFullName() {
    return this._value.updatedPatientFullName;
  }

  get updatedPatientFullNameWithDefault() {
    return this._value.updatedPatientFullName || '';
  }

  get oldPatientFullName() {
    return this._value.oldPatientFullName;
  }

  get oldPatientFullNameWithDefault() {
    return this._value.oldPatientFullName || '';
  }

  get comment() {
    return this._value.comment;
  }

  get commentWithDefault() {
    return this._value.comment || '';
  }

  get assignedAt() {
    return (this._value.assignedAt !== undefined && this._value.assignedAt !== null) ? dayjs(this._value.assignedAt) : this._value.assignedAt;
  }

  get createdAt() {
    return (this._value.createdAt !== undefined && this._value.createdAt !== null) ? dayjs(this._value.createdAt) : this._value.createdAt;
  }

  get assignedToUser() {
    return this._value.assignedToUser !== undefined && this._value.assignedToUser !== null ? new LimitedUserClass(this._value.assignedToUser) : this._value.assignedToUser;
  }

  get assignedByUser() {
    return this._value.assignedByUser !== undefined && this._value.assignedByUser !== null ? new LimitedUserClass(this._value.assignedByUser) : this._value.assignedByUser;
  }

  get editByUser() {
    return this._value.editByUser !== undefined && this._value.editByUser !== null ? new LimitedUserClass(this._value.editByUser) : this._value.editByUser;
  }
}
export class TicketClass extends BaseModelClass {
  protected readonly _value: Ticket;

	constructor(value: Ticket) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get ticketNumber() {
    return this._value.ticketNumber;
  }

  get ticketNumberWithDefault() {
    return this._value.ticketNumber || 0;
  }

  get ticketNumberDisplay() {
    return this._value.ticketNumberDisplay;
  }

  get ticketNumberDisplayWithDefault() {
    return this._value.ticketNumberDisplay || '';
  }

  get memberId() {
    return this._value.memberId;
  }

  get firstName() {
    return this._value.firstName;
  }

  get firstNameWithDefault() {
    return this._value.firstName || '';
  }

  get lastName() {
    return this._value.lastName;
  }

  get lastNameWithDefault() {
    return this._value.lastName || '';
  }

  get patientUser() {
    return this._value.patientUser !== undefined && this._value.patientUser !== null ? new LimitedPatientClass(this._value.patientUser) : this._value.patientUser;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get assignedTo() {
    return this._value.assignedTo;
  }

  get createdBy() {
    return this._value.createdBy;
  }

  get priorityLevel() {
    return this._value.priorityLevel;
  }

  priorityLevelIsIn(values: PriorityLevelEnum[] = []) {
    return this._value.priorityLevel && values.includes(this._value.priorityLevel);
  }

  get priority() {
    return this._value.priority;
  }

  get priorityWithDefault() {
    return this._value.priority || 0;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: TicketStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get histories() {
    return this._value.histories !== undefined && this._value.histories !== null ? map(this._value.histories, (v) => new TicketHistoryClass(v)) : this._value.histories;
  }

  get historiesWithDefault() {
    return this._value.histories || [];
  }

  get assignedToDate() {
    return (this._value.assignedToDate !== undefined && this._value.assignedToDate !== null) ? dayjs(this._value.assignedToDate) : this._value.assignedToDate;
  }

  get dueDate() {
    return (this._value.dueDate !== undefined && this._value.dueDate !== null) ? dayjs(this._value.dueDate) : this._value.dueDate;
  }

  get resolvedAt() {
    return (this._value.resolvedAt !== undefined && this._value.resolvedAt !== null) ? dayjs(this._value.resolvedAt) : this._value.resolvedAt;
  }

  get resolvedBy() {
    return this._value.resolvedBy;
  }

  get closedAt() {
    return (this._value.closedAt !== undefined && this._value.closedAt !== null) ? dayjs(this._value.closedAt) : this._value.closedAt;
  }

  get closedBy() {
    return this._value.closedBy;
  }

  get ticketNumberDisplayNear() {
    return this._value.ticketNumberDisplayNear;
  }

  get ticketNumberDisplayNearWithDefault() {
    return this._value.ticketNumberDisplayNear || '';
  }

  get firstNameNear() {
    return this._value.firstNameNear;
  }

  get firstNameNearWithDefault() {
    return this._value.firstNameNear || '';
  }

  get lastNameNear() {
    return this._value.lastNameNear;
  }

  get lastNameNearWithDefault() {
    return this._value.lastNameNear || '';
  }

  get nameNear() {
    return this._value.nameNear;
  }

  get nameNearWithDefault() {
    return this._value.nameNear || '';
  }

  get ticketNumberIn() {
    return this._value.ticketNumberIn !== undefined && this._value.ticketNumberIn !== null ? new InFilterClass(this._value.ticketNumberIn) : this._value.ticketNumberIn;
  }

  get memberIdIn() {
    return this._value.memberIdIn !== undefined && this._value.memberIdIn !== null ? new InFilterClass(this._value.memberIdIn) : this._value.memberIdIn;
  }

  get createdByIn() {
    return this._value.createdByIn !== undefined && this._value.createdByIn !== null ? new InFilterClass(this._value.createdByIn) : this._value.createdByIn;
  }

  get assignedToIn() {
    return this._value.assignedToIn !== undefined && this._value.assignedToIn !== null ? new InFilterClass(this._value.assignedToIn) : this._value.assignedToIn;
  }

  get statusIn() {
    return this._value.statusIn !== undefined && this._value.statusIn !== null ? new InFilterClass(this._value.statusIn) : this._value.statusIn;
  }

  get priorityLevelIn() {
    return this._value.priorityLevelIn !== undefined && this._value.priorityLevelIn !== null ? new InFilterClass(this._value.priorityLevelIn) : this._value.priorityLevelIn;
  }

  get createdByUser() {
    return this._value.createdByUser !== undefined && this._value.createdByUser !== null ? new LimitedUserClass(this._value.createdByUser) : this._value.createdByUser;
  }

  get assignedToUser() {
    return this._value.assignedToUser !== undefined && this._value.assignedToUser !== null ? new LimitedUserClass(this._value.assignedToUser) : this._value.assignedToUser;
  }

  get doctorUser() {
    return this._value.doctorUser !== undefined && this._value.doctorUser !== null ? new LimitedUserClass(this._value.doctorUser) : this._value.doctorUser;
  }

  get resolvedByDoctorUser() {
    return this._value.resolvedByDoctorUser !== undefined && this._value.resolvedByDoctorUser !== null ? new LimitedUserClass(this._value.resolvedByDoctorUser) : this._value.resolvedByDoctorUser;
  }

  get clinic() {
    return this._value.clinic !== undefined && this._value.clinic !== null ? new LimitedClinicClass(this._value.clinic) : this._value.clinic;
  }
}
export interface TicketComment extends BaseModel {
	comment?: Nullable<string>;
	ticketId?: Nullable<string>;
	employeeId?: Nullable<string>;
	generateCareNote?: Nullable<boolean>;
	employeeUser?: Nullable<LimitedUser>;
}
export interface TicketCommentRequest {
	comment?: Nullable<string>;
	generateCareNote?: Nullable<boolean>;
}
export class TicketCommentClass extends BaseModelClass {
  protected readonly _value: TicketComment;

	constructor(value: TicketComment) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get comment() {
    return this._value.comment;
  }

  get commentWithDefault() {
    return this._value.comment || '';
  }

  get ticketId() {
    return this._value.ticketId;
  }

  get employeeId() {
    return this._value.employeeId;
  }

  get generateCareNote() {
    return this._value.generateCareNote;
  }

  get generateCareNoteWithDefault() {
    return this._value.generateCareNote || false;
  }

  get employeeUser() {
    return this._value.employeeUser !== undefined && this._value.employeeUser !== null ? new LimitedUserClass(this._value.employeeUser) : this._value.employeeUser;
  }
}
export interface TicketFilter {
	ticketNumber?: Nullable<number[]>;
	memberId?: Nullable<string[]>;
	createdBy?: Nullable<string[]>;
	assignedTo?: Nullable<string[]>;
	status?: Nullable<TicketStatusEnum[]>;
	priorityLevel?: Nullable<PriorityLevelEnum[]>;
	isNotAssignedByCurrentUser?: Nullable<boolean>;
	ticketNumberDisplayNear?: Nullable<string>;
	nameNear?: Nullable<string>;
	assignedToNameNear?: Nullable<string>;
}
export interface TicketDeleteParams {
	id: string;
}

export interface TicketAddCommentParams {
	request: TicketComment;
}

export interface TicketGetTicketByPatientIdParams {
	patientId: string;
}

export interface TicketEditCommentParams {
	id: string;
	request: TicketComment;
}

export interface TicketDeleteCommentParams {
	id: string;
}

export interface TicketResolveTicketParams {
	id: string;
	request: TicketCommentRequest;
}

export interface TicketGetTicketListParams {
	providerId: string;
}

export interface TicketGetCommentListByTicketIdParams {
	ticketId: string;
}

export interface TicketSearchTicketsParams {
	searchRequest: SearchRequest<TicketFilter>;
}

export class TicketService extends BaseController<Ticket, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/ticket';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public delete({ baseUrl, params, headers }: RequestOption<TicketDeleteParams>) {
		const queryList: QueryParam[] = [];
		return axios.delete<APIResponse<Ticket>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}${getQueryStrings(queryList)}`, { headers });
	}

	public addComment({ baseUrl, params, headers }: RequestOption<TicketAddCommentParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/comment${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getTicketByPatientId({ baseUrl, params, headers }: RequestOption<TicketGetTicketByPatientIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<Ticket[]>>(`${baseUrl || this.baseURL}${this.basePath}/patient/${params.patientId}${getQueryStrings(queryList)}`, { headers });
	}

	public editComment({ baseUrl, params, headers }: RequestOption<TicketEditCommentParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/comment/${params.id}${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public deleteComment({ baseUrl, params, headers }: RequestOption<TicketDeleteCommentParams>) {
		const queryList: QueryParam[] = [];
		return axios.delete<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/comment/${params.id}${getQueryStrings(queryList)}`, { headers });
	}

	public resolveTicket({ baseUrl, params, headers }: RequestOption<TicketResolveTicketParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/resolve${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public getTicketList({ baseUrl, params, headers }: RequestOption<TicketGetTicketListParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<Ticket[]>>(`${baseUrl || this.baseURL}${this.basePath}/list/${params.providerId}${getQueryStrings(queryList)}`, { headers });
	}

	public getCommentListByTicketId({ baseUrl, params, headers }: RequestOption<TicketGetCommentListByTicketIdParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<TicketComment[]>>(`${baseUrl || this.baseURL}${this.basePath}/comment/list/${params.ticketId}${getQueryStrings(queryList)}`, { headers });
	}

	public searchTickets({ baseUrl, params, headers }: RequestOption<TicketSearchTicketsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<Ticket>>>(`${baseUrl || this.baseURL}${this.basePath}/search-tickets${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}
}

export const ticketService = new TicketService();

export const createTicketDeleteObject = (value: APIResponse<Ticket>) => new APIResponseClass<TicketClass>(value, (value: Ticket) => new TicketClass(value));

export const useTicketDelete = <RCN = undefined>(configs: MakeRequestHookConfig<TicketDeleteParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TicketDeleteParams,
    APIResponse<Ticket>,
    typeof ticketService.delete,
    AjaxOptions,
    APIResponseClass<TicketClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ticketService.delete>) => (
      ticketService.delete(...args)
    ),
    ResponseClassCreator: createTicketDeleteObject,
    typeName: 'useTicketDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTicketDelete.mock = () => new Error('Mock has not been implemented!');
useTicketDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTicketDelete.createObject = createTicketDeleteObject;

export const createTicketAddCommentObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useTicketAddComment = <RCN = undefined>(configs: MakeRequestHookConfig<TicketAddCommentParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TicketAddCommentParams,
    APIResponse<string>,
    typeof ticketService.addComment,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ticketService.addComment>) => (
      ticketService.addComment(...args)
    ),
    ResponseClassCreator: createTicketAddCommentObject,
    typeName: 'useTicketAddComment',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTicketAddComment.mock = () => new Error('Mock has not been implemented!');
useTicketAddComment.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTicketAddComment.createObject = createTicketAddCommentObject;

export const createTicketGetTicketByPatientIdObject = (value: APIResponse<Ticket[]>) => new APIResponseClass<TicketClass[]>(value, (v) => map(v, (value: Ticket) => new TicketClass(value)));

export const useTicketGetTicketByPatientId = <RCN = undefined>(configs: MakeRequestHookConfig<TicketGetTicketByPatientIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TicketGetTicketByPatientIdParams,
    APIResponse<Ticket[]>,
    typeof ticketService.getTicketByPatientId,
    GetOptions,
    APIResponseClass<TicketClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ticketService.getTicketByPatientId>) => (
      ticketService.getTicketByPatientId(...args)
    ),
    ResponseClassCreator: createTicketGetTicketByPatientIdObject,
    typeName: 'useTicketGetTicketByPatientId',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTicketGetTicketByPatientId.mock = () => new Error('Mock has not been implemented!');
useTicketGetTicketByPatientId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTicketGetTicketByPatientId.createObject = createTicketGetTicketByPatientIdObject;

export const createTicketEditCommentObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useTicketEditComment = <RCN = undefined>(configs: MakeRequestHookConfig<TicketEditCommentParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TicketEditCommentParams,
    APIResponse<string>,
    typeof ticketService.editComment,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ticketService.editComment>) => (
      ticketService.editComment(...args)
    ),
    ResponseClassCreator: createTicketEditCommentObject,
    typeName: 'useTicketEditComment',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTicketEditComment.mock = () => new Error('Mock has not been implemented!');
useTicketEditComment.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTicketEditComment.createObject = createTicketEditCommentObject;

export const createTicketDeleteCommentObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useTicketDeleteComment = <RCN = undefined>(configs: MakeRequestHookConfig<TicketDeleteCommentParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TicketDeleteCommentParams,
    APIResponse<string>,
    typeof ticketService.deleteComment,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ticketService.deleteComment>) => (
      ticketService.deleteComment(...args)
    ),
    ResponseClassCreator: createTicketDeleteCommentObject,
    typeName: 'useTicketDeleteComment',
    type: MethodTypeEnum.DELETE,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTicketDeleteComment.mock = () => new Error('Mock has not been implemented!');
useTicketDeleteComment.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTicketDeleteComment.createObject = createTicketDeleteCommentObject;

export const createTicketResolveTicketObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useTicketResolveTicket = <RCN = undefined>(configs: MakeRequestHookConfig<TicketResolveTicketParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TicketResolveTicketParams,
    APIResponse<string>,
    typeof ticketService.resolveTicket,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ticketService.resolveTicket>) => (
      ticketService.resolveTicket(...args)
    ),
    ResponseClassCreator: createTicketResolveTicketObject,
    typeName: 'useTicketResolveTicket',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTicketResolveTicket.mock = () => new Error('Mock has not been implemented!');
useTicketResolveTicket.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTicketResolveTicket.createObject = createTicketResolveTicketObject;

export const createTicketGetTicketListObject = (value: APIResponse<Ticket[]>) => new APIResponseClass<TicketClass[]>(value, (v) => map(v, (value: Ticket) => new TicketClass(value)));

export const useTicketGetTicketList = <RCN = undefined>(configs: MakeRequestHookConfig<TicketGetTicketListParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TicketGetTicketListParams,
    APIResponse<Ticket[]>,
    typeof ticketService.getTicketList,
    GetOptions,
    APIResponseClass<TicketClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ticketService.getTicketList>) => (
      ticketService.getTicketList(...args)
    ),
    ResponseClassCreator: createTicketGetTicketListObject,
    typeName: 'useTicketGetTicketList',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTicketGetTicketList.mock = () => new Error('Mock has not been implemented!');
useTicketGetTicketList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTicketGetTicketList.createObject = createTicketGetTicketListObject;

export const createTicketGetCommentListByTicketIdObject = (value: APIResponse<TicketComment[]>) => new APIResponseClass<TicketCommentClass[]>(value, (v) => map(v, (value: TicketComment) => new TicketCommentClass(value)));

export const useTicketGetCommentListByTicketId = <RCN = undefined>(configs: MakeRequestHookConfig<TicketGetCommentListByTicketIdParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TicketGetCommentListByTicketIdParams,
    APIResponse<TicketComment[]>,
    typeof ticketService.getCommentListByTicketId,
    GetOptions,
    APIResponseClass<TicketCommentClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ticketService.getCommentListByTicketId>) => (
      ticketService.getCommentListByTicketId(...args)
    ),
    ResponseClassCreator: createTicketGetCommentListByTicketIdObject,
    typeName: 'useTicketGetCommentListByTicketId',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTicketGetCommentListByTicketId.mock = () => new Error('Mock has not been implemented!');
useTicketGetCommentListByTicketId.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTicketGetCommentListByTicketId.createObject = createTicketGetCommentListByTicketIdObject;

export const createTicketSearchTicketsObject = (value: APIResponse<PageResponse<Ticket>>) => new APIResponseClass<PageResponseClass<TicketClass>>(value, (value: PageResponse<Ticket>) => new PageResponseClass<TicketClass>(value, (value: Ticket) => new TicketClass(value)));

export const useTicketSearchTickets = <RCN = undefined>(configs: MakeRequestHookConfig<TicketSearchTicketsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TicketSearchTicketsParams,
    APIResponse<PageResponse<Ticket>>,
    typeof ticketService.searchTickets,
    AjaxOptions,
    APIResponseClass<PageResponseClass<TicketClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ticketService.searchTickets>) => (
      ticketService.searchTickets(...args)
    ),
    ResponseClassCreator: createTicketSearchTicketsObject,
    typeName: 'useTicketSearchTickets',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTicketSearchTickets.mock = () => new Error('Mock has not been implemented!');
useTicketSearchTickets.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTicketSearchTickets.createObject = createTicketSearchTicketsObject;


export const createTicketGetObject = (value: APIResponse<Ticket>) => new APIResponseClass<TicketClass>(value, (value: Ticket) => new TicketClass(value));
export const useTicketGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<Ticket>,
    // @ts-ignore
    typeof ticketService.get,
    GetOptions,
    APIResponseClass<TicketClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ticketService.get>) => (
      ticketService.get(...args)
    ),
    ResponseClassCreator: createTicketGetObject,
    typeName: 'useTicketGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTicketGet.mock = () => new Error('Mock has not been implemented!');
useTicketGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTicketGet.createObject = createTicketGetObject;

export const createTicketUpdateObject = (value: APIResponse<Ticket>) => new APIResponseClass<TicketClass>(value, (value: Ticket) => new TicketClass(value));
export const useTicketUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, Ticket>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, Ticket>,
    APIResponse<Ticket>,
    // @ts-ignore
    typeof ticketService.update,
    AjaxOptions,
    APIResponseClass<TicketClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ticketService.update>) => (
      ticketService.update(...args)
    ),
    ResponseClassCreator: createTicketUpdateObject,
    typeName: 'useTicketUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTicketUpdate.mock = () => new Error('Mock has not been implemented!');
useTicketUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTicketUpdate.createObject = createTicketUpdateObject;

export const createTicketInsertObject = (value: APIResponse<Ticket>) => new APIResponseClass<TicketClass>(value, (value: Ticket) => new TicketClass(value));
export const useTicketInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<Ticket>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<Ticket>,
    APIResponse<Ticket>,
    // @ts-ignore
    typeof ticketService.insert,
    AjaxOptions,
    APIResponseClass<TicketClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ticketService.insert>) => (
      ticketService.insert(...args)
    ),
    ResponseClassCreator: createTicketInsertObject,
    typeName: 'useTicketInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTicketInsert.mock = () => new Error('Mock has not been implemented!');
useTicketInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTicketInsert.createObject = createTicketInsertObject;

export const createTicketSearchObject = (value: APIResponse<PageResponse<Ticket>>) => new APIResponseClass<PageResponseClass<TicketClass>>(value, (value: PageResponse<Ticket>) => new PageResponseClass<TicketClass>(value, (value: Ticket) => new TicketClass(value)));
export const useTicketSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<Ticket>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<Ticket>,
    APIResponse<PageResponse<Ticket>>,
    // @ts-ignore
    typeof ticketService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<TicketClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ticketService.search>) => (
      ticketService.search(...args)
    ),
    ResponseClassCreator: createTicketSearchObject,
    typeName: 'useTicketSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTicketSearch.mock = () => new Error('Mock has not been implemented!');
useTicketSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTicketSearch.createObject = createTicketSearchObject;

export enum TimeTrackingEventEnum {
	LOGIN = 'LOGIN',
	LOGOUT = 'LOGOUT',
	COMPONENT_MOUNT = 'COMPONENT_MOUNT',
	COMPONENT_UNMOUNT = 'COMPONENT_UNMOUNT',
	IDLE = 'IDLE',
	ACTIVE = 'ACTIVE',
	HEARTBEAT = 'HEARTBEAT',
	INTERVENTION = 'INTERVENTION',
	COMPLETE_TASK = 'COMPLETE_TASK',
}
export interface TimeTrackingEvent extends BaseModel {
	sessionId?: Nullable<string>;
	clinicId?: Nullable<string>;
	userId?: Nullable<string>;
	eventType?: Nullable<TimeTrackingEventEnum>;
	roleType?: Nullable<TrackingEventRoleEnumType>;
	timestamp?: Nullable<number>;
	url?: Nullable<string>;
	patientId?: Nullable<string>;
	component?: Nullable<string>;
	detail?: Nullable<unknown>;
}
export class TimeTrackingEventClass extends BaseModelClass {
  protected readonly _value: TimeTrackingEvent;

	constructor(value: TimeTrackingEvent) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get sessionId() {
    return this._value.sessionId;
  }

  get sessionIdWithDefault() {
    return this._value.sessionId || '';
  }

  get clinicId() {
    return this._value.clinicId;
  }

  get clinicIdWithDefault() {
    return this._value.clinicId || '';
  }

  get userId() {
    return this._value.userId;
  }

  get userIdWithDefault() {
    return this._value.userId || '';
  }

  get eventType() {
    return this._value.eventType;
  }

  eventTypeIsIn(values: TimeTrackingEventEnum[] = []) {
    return this._value.eventType && values.includes(this._value.eventType);
  }

  get roleType() {
    return this._value.roleType;
  }

  roleTypeIsIn(values: TrackingEventRoleEnumType[] = []) {
    return this._value.roleType && values.includes(this._value.roleType);
  }

  get timestamp() {
    return this._value.timestamp;
  }

  get timestampWithDefault() {
    return this._value.timestamp || 0;
  }

  get url() {
    return this._value.url;
  }

  get urlWithDefault() {
    return this._value.url || '';
  }

  get patientId() {
    return this._value.patientId;
  }

  get patientIdWithDefault() {
    return this._value.patientId || '';
  }

  get component() {
    return this._value.component;
  }

  get componentWithDefault() {
    return this._value.component || '';
  }

  get detail() {
    return this._value.detail;
  }
}
export interface TimeTrackingInsertParams {
	event: TimeTrackingEvent;
}

export class TimeTrackingService extends BaseController<TimeTrackingEvent, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/time-tracking-events';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public insert({ baseUrl, params, headers }: RequestOption<TimeTrackingInsertParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<TimeTrackingEvent>>(`${baseUrl || this.baseURL}${this.basePath}${getQueryStrings(queryList)}`, params.event, { headers });
	}
}

export const timeTrackingService = new TimeTrackingService();

export const createTimeTrackingInsertObject = (value: APIResponse<TimeTrackingEvent>) => new APIResponseClass<TimeTrackingEventClass>(value, (value: TimeTrackingEvent) => new TimeTrackingEventClass(value));

export const useTimeTrackingInsert = <RCN = undefined>(configs: MakeRequestHookConfig<TimeTrackingInsertParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TimeTrackingInsertParams,
    APIResponse<TimeTrackingEvent>,
    typeof timeTrackingService.insert,
    AjaxOptions,
    APIResponseClass<TimeTrackingEventClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof timeTrackingService.insert>) => (
      timeTrackingService.insert(...args)
    ),
    ResponseClassCreator: createTimeTrackingInsertObject,
    typeName: 'useTimeTrackingInsert',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTimeTrackingInsert.mock = () => new Error('Mock has not been implemented!');
useTimeTrackingInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTimeTrackingInsert.createObject = createTimeTrackingInsertObject;


export const createTimeTrackingGetObject = (value: APIResponse<TimeTrackingEvent>) => new APIResponseClass<TimeTrackingEventClass>(value, (value: TimeTrackingEvent) => new TimeTrackingEventClass(value));
export const useTimeTrackingGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<TimeTrackingEvent>,
    // @ts-ignore
    typeof timeTrackingService.get,
    GetOptions,
    APIResponseClass<TimeTrackingEventClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof timeTrackingService.get>) => (
      timeTrackingService.get(...args)
    ),
    ResponseClassCreator: createTimeTrackingGetObject,
    typeName: 'useTimeTrackingGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTimeTrackingGet.mock = () => new Error('Mock has not been implemented!');
useTimeTrackingGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTimeTrackingGet.createObject = createTimeTrackingGetObject;

export const createTimeTrackingUpdateObject = (value: APIResponse<TimeTrackingEvent>) => new APIResponseClass<TimeTrackingEventClass>(value, (value: TimeTrackingEvent) => new TimeTrackingEventClass(value));
export const useTimeTrackingUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, TimeTrackingEvent>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, TimeTrackingEvent>,
    APIResponse<TimeTrackingEvent>,
    // @ts-ignore
    typeof timeTrackingService.update,
    AjaxOptions,
    APIResponseClass<TimeTrackingEventClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof timeTrackingService.update>) => (
      timeTrackingService.update(...args)
    ),
    ResponseClassCreator: createTimeTrackingUpdateObject,
    typeName: 'useTimeTrackingUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTimeTrackingUpdate.mock = () => new Error('Mock has not been implemented!');
useTimeTrackingUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTimeTrackingUpdate.createObject = createTimeTrackingUpdateObject;

export const createTimeTrackingDeleteObject = (value: APIResponse<TimeTrackingEvent>) => new APIResponseClass<TimeTrackingEventClass>(value, (value: TimeTrackingEvent) => new TimeTrackingEventClass(value));
export const useTimeTrackingDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<TimeTrackingEvent>,
    // @ts-ignore
    typeof timeTrackingService.delete,
    AjaxOptions,
    APIResponseClass<TimeTrackingEventClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof timeTrackingService.delete>) => (
      timeTrackingService.delete(...args)
    ),
    ResponseClassCreator: createTimeTrackingDeleteObject,
    typeName: 'useTimeTrackingDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTimeTrackingDelete.mock = () => new Error('Mock has not been implemented!');
useTimeTrackingDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTimeTrackingDelete.createObject = createTimeTrackingDeleteObject;

export const createTimeTrackingSearchObject = (value: APIResponse<PageResponse<TimeTrackingEvent>>) => new APIResponseClass<PageResponseClass<TimeTrackingEventClass>>(value, (value: PageResponse<TimeTrackingEvent>) => new PageResponseClass<TimeTrackingEventClass>(value, (value: TimeTrackingEvent) => new TimeTrackingEventClass(value)));
export const useTimeTrackingSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<TimeTrackingEvent>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<TimeTrackingEvent>,
    APIResponse<PageResponse<TimeTrackingEvent>>,
    // @ts-ignore
    typeof timeTrackingService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<TimeTrackingEventClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof timeTrackingService.search>) => (
      timeTrackingService.search(...args)
    ),
    ResponseClassCreator: createTimeTrackingSearchObject,
    typeName: 'useTimeTrackingSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTimeTrackingSearch.mock = () => new Error('Mock has not been implemented!');
useTimeTrackingSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTimeTrackingSearch.createObject = createTimeTrackingSearchObject;

export interface CareDetail {
	total?: Nullable<number>;
	dueToday?: Nullable<number>;
	medicalAlerts?: Nullable<number>;
	messages?: Nullable<number>;
	foodDiaries?: Nullable<number>;
	visitIncomplete?: Nullable<number>;
	monthlyReview?: Nullable<number>;
	complianceAlerts?: Nullable<number>;
	onboardingToDos?: Nullable<number>;
}
export interface StatusDetail {
	total?: Nullable<number>;
	dueToday?: Nullable<number>;
}
export interface ToDo extends BaseModel {
	careDetail?: Nullable<CareDetail>;
	enrollmentDetail?: Nullable<StatusDetail>;
	ticketDetail?: Nullable<StatusDetail>;
	transcribeDetail?: Nullable<StatusDetail>;
	hasScreening?: Nullable<boolean>;
}
export class CareDetailClass {
  protected readonly _value: CareDetail;

	constructor(value: CareDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get total() {
    return this._value.total;
  }

  get totalWithDefault() {
    return this._value.total || 0;
  }

  get dueToday() {
    return this._value.dueToday;
  }

  get dueTodayWithDefault() {
    return this._value.dueToday || 0;
  }

  get medicalAlerts() {
    return this._value.medicalAlerts;
  }

  get medicalAlertsWithDefault() {
    return this._value.medicalAlerts || 0;
  }

  get messages() {
    return this._value.messages;
  }

  get messagesWithDefault() {
    return this._value.messages || 0;
  }

  get foodDiaries() {
    return this._value.foodDiaries;
  }

  get foodDiariesWithDefault() {
    return this._value.foodDiaries || 0;
  }

  get visitIncomplete() {
    return this._value.visitIncomplete;
  }

  get visitIncompleteWithDefault() {
    return this._value.visitIncomplete || 0;
  }

  get monthlyReview() {
    return this._value.monthlyReview;
  }

  get monthlyReviewWithDefault() {
    return this._value.monthlyReview || 0;
  }

  get complianceAlerts() {
    return this._value.complianceAlerts;
  }

  get complianceAlertsWithDefault() {
    return this._value.complianceAlerts || 0;
  }

  get onboardingToDos() {
    return this._value.onboardingToDos;
  }

  get onboardingToDosWithDefault() {
    return this._value.onboardingToDos || 0;
  }
}
export class StatusDetailClass {
  protected readonly _value: StatusDetail;

	constructor(value: StatusDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get total() {
    return this._value.total;
  }

  get totalWithDefault() {
    return this._value.total || 0;
  }

  get dueToday() {
    return this._value.dueToday;
  }

  get dueTodayWithDefault() {
    return this._value.dueToday || 0;
  }
}
export class ToDoClass extends BaseModelClass {
  protected readonly _value: ToDo;

	constructor(value: ToDo) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get careDetail() {
    return this._value.careDetail !== undefined && this._value.careDetail !== null ? new CareDetailClass(this._value.careDetail) : this._value.careDetail;
  }

  get enrollmentDetail() {
    return this._value.enrollmentDetail !== undefined && this._value.enrollmentDetail !== null ? new StatusDetailClass(this._value.enrollmentDetail) : this._value.enrollmentDetail;
  }

  get ticketDetail() {
    return this._value.ticketDetail !== undefined && this._value.ticketDetail !== null ? new StatusDetailClass(this._value.ticketDetail) : this._value.ticketDetail;
  }

  get transcribeDetail() {
    return this._value.transcribeDetail !== undefined && this._value.transcribeDetail !== null ? new StatusDetailClass(this._value.transcribeDetail) : this._value.transcribeDetail;
  }

  get hasScreening() {
    return this._value.hasScreening;
  }

  get hasScreeningWithDefault() {
    return this._value.hasScreening || false;
  }
}
export interface ToDoGetCurrentToDoParams {
}

export interface ToDoMarkScreeningDoneParams {
}

export class ToDoService {
  protected baseURL = '';

  protected basePath = '/v1/uc/to-do';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public getCurrentToDo({ baseUrl, params, headers }: RequestOption<ToDoGetCurrentToDoParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<ToDo>>(`${baseUrl || this.baseURL}${this.basePath}${getQueryStrings(queryList)}`, { headers });
	}

	public markScreeningDone({ baseUrl, params, headers }: RequestOption<ToDoMarkScreeningDoneParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/screening/done${getQueryStrings(queryList)}`, {}, { headers });
	}
}

export const toDoService = new ToDoService();

export const createToDoGetCurrentToDoObject = (value: APIResponse<ToDo>) => new APIResponseClass<ToDoClass>(value, (value: ToDo) => new ToDoClass(value));

export const useToDoGetCurrentToDo = <RCN = undefined>(configs: MakeRequestHookConfig<ToDoGetCurrentToDoParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ToDoGetCurrentToDoParams,
    APIResponse<ToDo>,
    typeof toDoService.getCurrentToDo,
    GetOptions,
    APIResponseClass<ToDoClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof toDoService.getCurrentToDo>) => (
      toDoService.getCurrentToDo(...args)
    ),
    ResponseClassCreator: createToDoGetCurrentToDoObject,
    typeName: 'useToDoGetCurrentToDo',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useToDoGetCurrentToDo.mock = () => new Error('Mock has not been implemented!');
useToDoGetCurrentToDo.getLastMockedValue = () => new Error('Mock has not been implemented!');
useToDoGetCurrentToDo.createObject = createToDoGetCurrentToDoObject;

export const createToDoMarkScreeningDoneObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useToDoMarkScreeningDone = <RCN = undefined>(configs: MakeRequestHookConfig<ToDoMarkScreeningDoneParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ToDoMarkScreeningDoneParams,
    APIResponse<string>,
    typeof toDoService.markScreeningDone,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof toDoService.markScreeningDone>) => (
      toDoService.markScreeningDone(...args)
    ),
    ResponseClassCreator: createToDoMarkScreeningDoneObject,
    typeName: 'useToDoMarkScreeningDone',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useToDoMarkScreeningDone.mock = () => new Error('Mock has not been implemented!');
useToDoMarkScreeningDone.getLastMockedValue = () => new Error('Mock has not been implemented!');
useToDoMarkScreeningDone.createObject = createToDoMarkScreeningDoneObject;
export interface TransactionalDemo extends BaseModel {
	name?: Nullable<string>;
}
export class TransactionalDemoClass extends BaseModelClass {
  protected readonly _value: TransactionalDemo;

	constructor(value: TransactionalDemo) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }
}
export class TransactionalDemoService extends BaseController<TransactionalDemo, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/transactionalDemo';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const transactionalDemoService = new TransactionalDemoService();


export const createTransactionalDemoGetObject = (value: APIResponse<TransactionalDemo>) => new APIResponseClass<TransactionalDemoClass>(value, (value: TransactionalDemo) => new TransactionalDemoClass(value));
export const useTransactionalDemoGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<TransactionalDemo>,
    // @ts-ignore
    typeof transactionalDemoService.get,
    GetOptions,
    APIResponseClass<TransactionalDemoClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof transactionalDemoService.get>) => (
      transactionalDemoService.get(...args)
    ),
    ResponseClassCreator: createTransactionalDemoGetObject,
    typeName: 'useTransactionalDemoGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTransactionalDemoGet.mock = () => new Error('Mock has not been implemented!');
useTransactionalDemoGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTransactionalDemoGet.createObject = createTransactionalDemoGetObject;

export const createTransactionalDemoUpdateObject = (value: APIResponse<TransactionalDemo>) => new APIResponseClass<TransactionalDemoClass>(value, (value: TransactionalDemo) => new TransactionalDemoClass(value));
export const useTransactionalDemoUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, TransactionalDemo>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, TransactionalDemo>,
    APIResponse<TransactionalDemo>,
    // @ts-ignore
    typeof transactionalDemoService.update,
    AjaxOptions,
    APIResponseClass<TransactionalDemoClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transactionalDemoService.update>) => (
      transactionalDemoService.update(...args)
    ),
    ResponseClassCreator: createTransactionalDemoUpdateObject,
    typeName: 'useTransactionalDemoUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTransactionalDemoUpdate.mock = () => new Error('Mock has not been implemented!');
useTransactionalDemoUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTransactionalDemoUpdate.createObject = createTransactionalDemoUpdateObject;

export const createTransactionalDemoInsertObject = (value: APIResponse<TransactionalDemo>) => new APIResponseClass<TransactionalDemoClass>(value, (value: TransactionalDemo) => new TransactionalDemoClass(value));
export const useTransactionalDemoInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<TransactionalDemo>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<TransactionalDemo>,
    APIResponse<TransactionalDemo>,
    // @ts-ignore
    typeof transactionalDemoService.insert,
    AjaxOptions,
    APIResponseClass<TransactionalDemoClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transactionalDemoService.insert>) => (
      transactionalDemoService.insert(...args)
    ),
    ResponseClassCreator: createTransactionalDemoInsertObject,
    typeName: 'useTransactionalDemoInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTransactionalDemoInsert.mock = () => new Error('Mock has not been implemented!');
useTransactionalDemoInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTransactionalDemoInsert.createObject = createTransactionalDemoInsertObject;

export const createTransactionalDemoDeleteObject = (value: APIResponse<TransactionalDemo>) => new APIResponseClass<TransactionalDemoClass>(value, (value: TransactionalDemo) => new TransactionalDemoClass(value));
export const useTransactionalDemoDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<TransactionalDemo>,
    // @ts-ignore
    typeof transactionalDemoService.delete,
    AjaxOptions,
    APIResponseClass<TransactionalDemoClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transactionalDemoService.delete>) => (
      transactionalDemoService.delete(...args)
    ),
    ResponseClassCreator: createTransactionalDemoDeleteObject,
    typeName: 'useTransactionalDemoDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTransactionalDemoDelete.mock = () => new Error('Mock has not been implemented!');
useTransactionalDemoDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTransactionalDemoDelete.createObject = createTransactionalDemoDeleteObject;

export const createTransactionalDemoSearchObject = (value: APIResponse<PageResponse<TransactionalDemo>>) => new APIResponseClass<PageResponseClass<TransactionalDemoClass>>(value, (value: PageResponse<TransactionalDemo>) => new PageResponseClass<TransactionalDemoClass>(value, (value: TransactionalDemo) => new TransactionalDemoClass(value)));
export const useTransactionalDemoSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<TransactionalDemo>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<TransactionalDemo>,
    APIResponse<PageResponse<TransactionalDemo>>,
    // @ts-ignore
    typeof transactionalDemoService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<TransactionalDemoClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transactionalDemoService.search>) => (
      transactionalDemoService.search(...args)
    ),
    ResponseClassCreator: createTransactionalDemoSearchObject,
    typeName: 'useTransactionalDemoSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTransactionalDemoSearch.mock = () => new Error('Mock has not been implemented!');
useTransactionalDemoSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTransactionalDemoSearch.createObject = createTransactionalDemoSearchObject;

export enum TranscribeTypeEnum {
	A1C = 'A1C',
	VISIT = 'VISIT',
}
export interface Transcribe extends BaseModel {
	transcribeType?: Nullable<TranscribeTypeEnum>;
	patientId?: Nullable<string>;
	firstName?: Nullable<string>;
	lastName?: Nullable<string>;
	providerId?: Nullable<string>;
	assigneeId?: Nullable<string>;
	clinicId?: Nullable<string>;
	status?: Nullable<TranscribeStatusEnum>;
	visitType?: Nullable<VisitTypeEnum>;
	visitId?: Nullable<string>;
	ehrCheckDate?: Nullable<string>;
	dueDate?: Nullable<string>;
	notes?: Nullable<Note[]>;
	source?: Nullable<string>;
	patientIdIn?: Nullable<InFilter<string>>;
	clinicIdIn?: Nullable<InFilter<string>>;
	providerIdIn?: Nullable<InFilter<string>>;
	assigneeIdIn?: Nullable<InFilter<string>>;
	statusIn?: Nullable<InFilter<TranscribeStatusEnum>>;
	visitTypeIn?: Nullable<InFilter<VisitTypeEnum>>;
	dueDateRange?: Nullable<DateRangeFilter>;
	patientUser?: Nullable<LimitedPatient>;
	doctorUser?: Nullable<LimitedUser>;
	assigneeUser?: Nullable<LimitedUser>;
}
export class TranscribeClass extends BaseModelClass {
  protected readonly _value: Transcribe;

	constructor(value: Transcribe) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get transcribeType() {
    return this._value.transcribeType;
  }

  transcribeTypeIsIn(values: TranscribeTypeEnum[] = []) {
    return this._value.transcribeType && values.includes(this._value.transcribeType);
  }

  get patientId() {
    return this._value.patientId;
  }

  get firstName() {
    return this._value.firstName;
  }

  get firstNameWithDefault() {
    return this._value.firstName || '';
  }

  get lastName() {
    return this._value.lastName;
  }

  get lastNameWithDefault() {
    return this._value.lastName || '';
  }

  get providerId() {
    return this._value.providerId;
  }

  get assigneeId() {
    return this._value.assigneeId;
  }

  get clinicId() {
    return this._value.clinicId;
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: TranscribeStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get visitType() {
    return this._value.visitType;
  }

  visitTypeIsIn(values: VisitTypeEnum[] = []) {
    return this._value.visitType && values.includes(this._value.visitType);
  }

  get visitId() {
    return this._value.visitId;
  }

  get ehrCheckDate() {
    return (this._value.ehrCheckDate !== undefined && this._value.ehrCheckDate !== null) ? dayjs(this._value.ehrCheckDate) : this._value.ehrCheckDate;
  }

  get dueDate() {
    return (this._value.dueDate !== undefined && this._value.dueDate !== null) ? dayjs(this._value.dueDate) : this._value.dueDate;
  }

  get notes() {
    return this._value.notes !== undefined && this._value.notes !== null ? map(this._value.notes, (v) => new NoteClass(v)) : this._value.notes;
  }

  get notesWithDefault() {
    return this._value.notes || [];
  }

  get source() {
    return this._value.source;
  }

  get sourceWithDefault() {
    return this._value.source || '';
  }

  get patientIdIn() {
    return this._value.patientIdIn !== undefined && this._value.patientIdIn !== null ? new InFilterClass(this._value.patientIdIn) : this._value.patientIdIn;
  }

  get clinicIdIn() {
    return this._value.clinicIdIn !== undefined && this._value.clinicIdIn !== null ? new InFilterClass(this._value.clinicIdIn) : this._value.clinicIdIn;
  }

  get providerIdIn() {
    return this._value.providerIdIn !== undefined && this._value.providerIdIn !== null ? new InFilterClass(this._value.providerIdIn) : this._value.providerIdIn;
  }

  get assigneeIdIn() {
    return this._value.assigneeIdIn !== undefined && this._value.assigneeIdIn !== null ? new InFilterClass(this._value.assigneeIdIn) : this._value.assigneeIdIn;
  }

  get statusIn() {
    return this._value.statusIn !== undefined && this._value.statusIn !== null ? new InFilterClass(this._value.statusIn) : this._value.statusIn;
  }

  get visitTypeIn() {
    return this._value.visitTypeIn !== undefined && this._value.visitTypeIn !== null ? new InFilterClass(this._value.visitTypeIn) : this._value.visitTypeIn;
  }

  get dueDateRange() {
    return this._value.dueDateRange !== undefined && this._value.dueDateRange !== null ? new DateRangeFilterClass(this._value.dueDateRange) : this._value.dueDateRange;
  }

  get patientUser() {
    return this._value.patientUser !== undefined && this._value.patientUser !== null ? new LimitedPatientClass(this._value.patientUser) : this._value.patientUser;
  }

  get doctorUser() {
    return this._value.doctorUser !== undefined && this._value.doctorUser !== null ? new LimitedUserClass(this._value.doctorUser) : this._value.doctorUser;
  }

  get assigneeUser() {
    return this._value.assigneeUser !== undefined && this._value.assigneeUser !== null ? new LimitedUserClass(this._value.assigneeUser) : this._value.assigneeUser;
  }
}
export interface TranscribeCount {
	clinicId?: Nullable<string>;
	number?: Nullable<number>;
	clinic?: Nullable<LimitedClinic>;
}
export class TranscribeCountClass {
  protected readonly _value: TranscribeCount;

	constructor(value: TranscribeCount) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get clinicId() {
    return this._value.clinicId;
  }

  get clinicIdWithDefault() {
    return this._value.clinicId || '';
  }

  get number() {
    return this._value.number;
  }

  get numberWithDefault() {
    return this._value.number || 0;
  }

  get clinic() {
    return this._value.clinic !== undefined && this._value.clinic !== null ? new LimitedClinicClass(this._value.clinic) : this._value.clinic;
  }
}
export interface JsonTimeLocalDate {
	date?: Nullable<string>;
	startDate?: Nullable<string>;
	endDate?: Nullable<string>;
}
export class JsonTimeLocalDateClass {
  protected readonly _value: JsonTimeLocalDate;

	constructor(value: JsonTimeLocalDate) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get date() {
    return (this._value.date !== undefined && this._value.date !== null) ? dayjs(this._value.date) : this._value.date;
  }

  get startDate() {
    return (this._value.startDate !== undefined && this._value.startDate !== null) ? dayjs(this._value.startDate) : this._value.startDate;
  }

  get endDate() {
    return (this._value.endDate !== undefined && this._value.endDate !== null) ? dayjs(this._value.endDate) : this._value.endDate;
  }
}
export interface A1CTranscribeSearchFilter {
	clinicId?: Nullable<string>;
	providerId?: Nullable<string>;
	assigneeId?: Nullable<string>;
	status?: Nullable<TranscribeStatusEnum>;
	clinicIdIn?: Nullable<InFilter<string>>;
	providerIdIn?: Nullable<InFilter<string>>;
	assigneeIdIn?: Nullable<InFilter<string>>;
	statusIn?: Nullable<InFilter<TranscribeStatusEnum>>;
}
export interface VisitTranscribeSearchFilter {
	clinicId?: Nullable<string>;
	providerId?: Nullable<string>;
	assignedId?: Nullable<string>;
	visitType?: Nullable<VisitTypeEnum>;
	status?: Nullable<TranscribeStatusEnum>;
	searchAll?: Nullable<boolean>;
	clinicIdIn?: Nullable<InFilter<string>>;
	providerIdIn?: Nullable<InFilter<string>>;
	assignedIdIn?: Nullable<InFilter<string>>;
	visitTypeIn?: Nullable<InFilter<VisitTypeEnum>>;
	statusIn?: Nullable<InFilter<TranscribeStatusEnum>>;
}
export interface TranscribeSearchParams {
	searchRequest: SearchRequest<Transcribe>;
}

export interface TranscribeCountByClinicsParams {
	filter: Transcribe;
}

export interface TranscribeGetA1CDueDateParams {
	patientId: string;
}

export interface TranscribeGetA1CTestWindowParams {
	patientId: string;
}

export interface TranscribeAddNoteParams {
	id: string;
	note: string;
}

export interface TranscribeSearchA1cParams {
	searchRequest: SearchRequest<A1CTranscribeSearchFilter>;
}

export interface TranscribeSearchVisitParams {
	searchRequest: SearchRequest<VisitTranscribeSearchFilter>;
}

export class TranscribeService extends BaseController<Transcribe, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/transcribe';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public search({ baseUrl, params, headers }: RequestOption<TranscribeSearchParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<Transcribe>>>(`${baseUrl || this.baseURL}${this.basePath}/search${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public countByClinics({ baseUrl, params, headers }: RequestOption<TranscribeCountByClinicsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<TranscribeCount[]>>(`${baseUrl || this.baseURL}${this.basePath}/count-by-clinics${getQueryStrings(queryList)}`, params.filter, { headers });
	}

	public getA1CDueDate({ baseUrl, params, headers }: RequestOption<TranscribeGetA1CDueDateParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<JsonTimeLocalDate>>(`${baseUrl || this.baseURL}${this.basePath}/a1c-due/${params.patientId}${getQueryStrings(queryList)}`, { headers });
	}

	public getA1CTestWindow({ baseUrl, params, headers }: RequestOption<TranscribeGetA1CTestWindowParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<JsonTimeLocalDate>>(`${baseUrl || this.baseURL}${this.basePath}/a1c-window/${params.patientId}${getQueryStrings(queryList)}`, { headers });
	}

	public addNote({ baseUrl, params, headers }: RequestOption<TranscribeAddNoteParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<Note[]>>(`${baseUrl || this.baseURL}${this.basePath}/note/${params.id}${getQueryStrings(queryList)}`, params.note, { headers });
	}

	public searchA1c({ baseUrl, params, headers }: RequestOption<TranscribeSearchA1cParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<Transcribe>>>(`${baseUrl || this.baseURL}${this.basePath}/search-a1c${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}

	public searchVisit({ baseUrl, params, headers }: RequestOption<TranscribeSearchVisitParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PageResponse<Transcribe>>>(`${baseUrl || this.baseURL}${this.basePath}/search-visit${getQueryStrings(queryList)}`, params.searchRequest, { headers });
	}
}

export const transcribeService = new TranscribeService();

export const createTranscribeSearchObject = (value: APIResponse<PageResponse<Transcribe>>) => new APIResponseClass<PageResponseClass<TranscribeClass>>(value, (value: PageResponse<Transcribe>) => new PageResponseClass<TranscribeClass>(value, (value: Transcribe) => new TranscribeClass(value)));

export const useTranscribeSearch = <RCN = undefined>(configs: MakeRequestHookConfig<TranscribeSearchParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TranscribeSearchParams,
    APIResponse<PageResponse<Transcribe>>,
    typeof transcribeService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<TranscribeClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transcribeService.search>) => (
      transcribeService.search(...args)
    ),
    ResponseClassCreator: createTranscribeSearchObject,
    typeName: 'useTranscribeSearch',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTranscribeSearch.mock = () => new Error('Mock has not been implemented!');
useTranscribeSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribeSearch.createObject = createTranscribeSearchObject;

export const createTranscribeCountByClinicsObject = (value: APIResponse<TranscribeCount[]>) => new APIResponseClass<TranscribeCountClass[]>(value, (v) => map(v, (value: TranscribeCount) => new TranscribeCountClass(value)));

export const useTranscribeCountByClinics = <RCN = undefined>(configs: MakeRequestHookConfig<TranscribeCountByClinicsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TranscribeCountByClinicsParams,
    APIResponse<TranscribeCount[]>,
    typeof transcribeService.countByClinics,
    AjaxOptions,
    APIResponseClass<TranscribeCountClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transcribeService.countByClinics>) => (
      transcribeService.countByClinics(...args)
    ),
    ResponseClassCreator: createTranscribeCountByClinicsObject,
    typeName: 'useTranscribeCountByClinics',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTranscribeCountByClinics.mock = () => new Error('Mock has not been implemented!');
useTranscribeCountByClinics.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribeCountByClinics.createObject = createTranscribeCountByClinicsObject;

export const createTranscribeGetA1CDueDateObject = (value: APIResponse<JsonTimeLocalDate>) => new APIResponseClass<JsonTimeLocalDateClass>(value, (value: JsonTimeLocalDate) => new JsonTimeLocalDateClass(value));

export const useTranscribeGetA1CDueDate = <RCN = undefined>(configs: MakeRequestHookConfig<TranscribeGetA1CDueDateParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TranscribeGetA1CDueDateParams,
    APIResponse<JsonTimeLocalDate>,
    typeof transcribeService.getA1CDueDate,
    GetOptions,
    APIResponseClass<JsonTimeLocalDateClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof transcribeService.getA1CDueDate>) => (
      transcribeService.getA1CDueDate(...args)
    ),
    ResponseClassCreator: createTranscribeGetA1CDueDateObject,
    typeName: 'useTranscribeGetA1CDueDate',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTranscribeGetA1CDueDate.mock = () => new Error('Mock has not been implemented!');
useTranscribeGetA1CDueDate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribeGetA1CDueDate.createObject = createTranscribeGetA1CDueDateObject;

export const createTranscribeGetA1CTestWindowObject = (value: APIResponse<JsonTimeLocalDate>) => new APIResponseClass<JsonTimeLocalDateClass>(value, (value: JsonTimeLocalDate) => new JsonTimeLocalDateClass(value));

export const useTranscribeGetA1CTestWindow = <RCN = undefined>(configs: MakeRequestHookConfig<TranscribeGetA1CTestWindowParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TranscribeGetA1CTestWindowParams,
    APIResponse<JsonTimeLocalDate>,
    typeof transcribeService.getA1CTestWindow,
    GetOptions,
    APIResponseClass<JsonTimeLocalDateClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof transcribeService.getA1CTestWindow>) => (
      transcribeService.getA1CTestWindow(...args)
    ),
    ResponseClassCreator: createTranscribeGetA1CTestWindowObject,
    typeName: 'useTranscribeGetA1CTestWindow',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTranscribeGetA1CTestWindow.mock = () => new Error('Mock has not been implemented!');
useTranscribeGetA1CTestWindow.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribeGetA1CTestWindow.createObject = createTranscribeGetA1CTestWindowObject;

export const createTranscribeAddNoteObject = (value: APIResponse<Note[]>) => new APIResponseClass<NoteClass[]>(value, (v) => map(v, (value: Note) => new NoteClass(value)));

export const useTranscribeAddNote = <RCN = undefined>(configs: MakeRequestHookConfig<TranscribeAddNoteParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TranscribeAddNoteParams,
    APIResponse<Note[]>,
    typeof transcribeService.addNote,
    AjaxOptions,
    APIResponseClass<NoteClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transcribeService.addNote>) => (
      transcribeService.addNote(...args)
    ),
    ResponseClassCreator: createTranscribeAddNoteObject,
    typeName: 'useTranscribeAddNote',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTranscribeAddNote.mock = () => new Error('Mock has not been implemented!');
useTranscribeAddNote.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribeAddNote.createObject = createTranscribeAddNoteObject;

export const createTranscribeSearchA1cObject = (value: APIResponse<PageResponse<Transcribe>>) => new APIResponseClass<PageResponseClass<TranscribeClass>>(value, (value: PageResponse<Transcribe>) => new PageResponseClass<TranscribeClass>(value, (value: Transcribe) => new TranscribeClass(value)));

export const useTranscribeSearchA1c = <RCN = undefined>(configs: MakeRequestHookConfig<TranscribeSearchA1cParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TranscribeSearchA1cParams,
    APIResponse<PageResponse<Transcribe>>,
    typeof transcribeService.searchA1c,
    AjaxOptions,
    APIResponseClass<PageResponseClass<TranscribeClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transcribeService.searchA1c>) => (
      transcribeService.searchA1c(...args)
    ),
    ResponseClassCreator: createTranscribeSearchA1cObject,
    typeName: 'useTranscribeSearchA1c',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTranscribeSearchA1c.mock = () => new Error('Mock has not been implemented!');
useTranscribeSearchA1c.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribeSearchA1c.createObject = createTranscribeSearchA1cObject;

export const createTranscribeSearchVisitObject = (value: APIResponse<PageResponse<Transcribe>>) => new APIResponseClass<PageResponseClass<TranscribeClass>>(value, (value: PageResponse<Transcribe>) => new PageResponseClass<TranscribeClass>(value, (value: Transcribe) => new TranscribeClass(value)));

export const useTranscribeSearchVisit = <RCN = undefined>(configs: MakeRequestHookConfig<TranscribeSearchVisitParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TranscribeSearchVisitParams,
    APIResponse<PageResponse<Transcribe>>,
    typeof transcribeService.searchVisit,
    AjaxOptions,
    APIResponseClass<PageResponseClass<TranscribeClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transcribeService.searchVisit>) => (
      transcribeService.searchVisit(...args)
    ),
    ResponseClassCreator: createTranscribeSearchVisitObject,
    typeName: 'useTranscribeSearchVisit',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTranscribeSearchVisit.mock = () => new Error('Mock has not been implemented!');
useTranscribeSearchVisit.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribeSearchVisit.createObject = createTranscribeSearchVisitObject;


export const createTranscribeGetObject = (value: APIResponse<Transcribe>) => new APIResponseClass<TranscribeClass>(value, (value: Transcribe) => new TranscribeClass(value));
export const useTranscribeGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<Transcribe>,
    // @ts-ignore
    typeof transcribeService.get,
    GetOptions,
    APIResponseClass<TranscribeClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof transcribeService.get>) => (
      transcribeService.get(...args)
    ),
    ResponseClassCreator: createTranscribeGetObject,
    typeName: 'useTranscribeGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTranscribeGet.mock = () => new Error('Mock has not been implemented!');
useTranscribeGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribeGet.createObject = createTranscribeGetObject;

export const createTranscribeUpdateObject = (value: APIResponse<Transcribe>) => new APIResponseClass<TranscribeClass>(value, (value: Transcribe) => new TranscribeClass(value));
export const useTranscribeUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, Transcribe>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, Transcribe>,
    APIResponse<Transcribe>,
    // @ts-ignore
    typeof transcribeService.update,
    AjaxOptions,
    APIResponseClass<TranscribeClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transcribeService.update>) => (
      transcribeService.update(...args)
    ),
    ResponseClassCreator: createTranscribeUpdateObject,
    typeName: 'useTranscribeUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTranscribeUpdate.mock = () => new Error('Mock has not been implemented!');
useTranscribeUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribeUpdate.createObject = createTranscribeUpdateObject;

export const createTranscribeInsertObject = (value: APIResponse<Transcribe>) => new APIResponseClass<TranscribeClass>(value, (value: Transcribe) => new TranscribeClass(value));
export const useTranscribeInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<Transcribe>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<Transcribe>,
    APIResponse<Transcribe>,
    // @ts-ignore
    typeof transcribeService.insert,
    AjaxOptions,
    APIResponseClass<TranscribeClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transcribeService.insert>) => (
      transcribeService.insert(...args)
    ),
    ResponseClassCreator: createTranscribeInsertObject,
    typeName: 'useTranscribeInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTranscribeInsert.mock = () => new Error('Mock has not been implemented!');
useTranscribeInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribeInsert.createObject = createTranscribeInsertObject;

export const createTranscribeDeleteObject = (value: APIResponse<Transcribe>) => new APIResponseClass<TranscribeClass>(value, (value: Transcribe) => new TranscribeClass(value));
export const useTranscribeDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<Transcribe>,
    // @ts-ignore
    typeof transcribeService.delete,
    AjaxOptions,
    APIResponseClass<TranscribeClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transcribeService.delete>) => (
      transcribeService.delete(...args)
    ),
    ResponseClassCreator: createTranscribeDeleteObject,
    typeName: 'useTranscribeDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useTranscribeDelete.mock = () => new Error('Mock has not been implemented!');
useTranscribeDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribeDelete.createObject = createTranscribeDeleteObject;

export interface PresignedURLRequest {
	patientId?: Nullable<string>;
	fileNames?: Nullable<string[]>;
}
export interface PresignedURLDetail {
	fileName?: Nullable<string>;
	s3FileKey?: Nullable<string>;
	s3PresignedUrl?: Nullable<string>;
}
export class PresignedURLDetailClass {
  protected readonly _value: PresignedURLDetail;

	constructor(value: PresignedURLDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get fileName() {
    return this._value.fileName;
  }

  get fileNameWithDefault() {
    return this._value.fileName || '';
  }

  get s3FileKey() {
    return this._value.s3FileKey;
  }

  get s3FileKeyWithDefault() {
    return this._value.s3FileKey || '';
  }

  get s3PresignedUrl() {
    return this._value.s3PresignedUrl;
  }

  get s3PresignedUrlWithDefault() {
    return this._value.s3PresignedUrl || '';
  }
}
export enum TranscribeResourceType {
	MEDICATION = 'MEDICATION',
	CONDITION = 'CONDITION',
	LAB_RESULT = 'LAB_RESULT',
	INSURANCE = 'INSURANCE',
}
export interface TranscribeJob<T> extends BaseModel {
	jobId?: Nullable<string>;
	patientId?: Nullable<string>;
	s3FileKeys?: Nullable<string[]>;
	status?: Nullable<JobStatusEnum>;
	completedAt?: Nullable<string>;
	reviewedAt?: Nullable<string>;
	reviewedBy?: Nullable<string>;
	costTimeSeconds?: Nullable<number>;
	resourceType?: Nullable<TranscribeResourceType>;
	processedResult?: Nullable<T[]>;
	originalFiles?: Nullable<{ [key: string]: string }>;
	userReviewedResult?: Nullable<T[]>;
}
export class TranscribeJobClass<T> extends BaseModelClass {
  protected readonly _value: TranscribeJob<any>;
	protected readonly processedResultConstructorFunc?: (value: any) => T;
	protected readonly userReviewedResultConstructorFunc?: (value: any) => T;

	constructor(value: TranscribeJob<any>, processedResultConstructorFunc?: (value: any) => T, userReviewedResultConstructorFunc?: (value: any) => T) {
		super(value);
		this._value = value;
		this.processedResultConstructorFunc = processedResultConstructorFunc;
		this.userReviewedResultConstructorFunc = userReviewedResultConstructorFunc;
	}

  get rawValue() {
    return this._value;
  }

  get jobId() {
    return this._value.jobId;
  }

  get jobIdWithDefault() {
    return this._value.jobId || '';
  }

  get patientId() {
    return this._value.patientId;
  }

  get s3FileKeys() {
    return this._value.s3FileKeys;
  }

  get s3FileKeysWithDefault() {
    return this._value.s3FileKeys || [];
  }

  get status() {
    return this._value.status;
  }

  statusIsIn(values: JobStatusEnum[] = []) {
    return this._value.status && values.includes(this._value.status);
  }

  get completedAt() {
    return (this._value.completedAt !== undefined && this._value.completedAt !== null) ? dayjs(this._value.completedAt) : this._value.completedAt;
  }

  get reviewedAt() {
    return (this._value.reviewedAt !== undefined && this._value.reviewedAt !== null) ? dayjs(this._value.reviewedAt) : this._value.reviewedAt;
  }

  get reviewedBy() {
    return this._value.reviewedBy;
  }

  get reviewedByWithDefault() {
    return this._value.reviewedBy || '';
  }

  get costTimeSeconds() {
    return this._value.costTimeSeconds;
  }

  get costTimeSecondsWithDefault() {
    return this._value.costTimeSeconds || 0.0;
  }

  get resourceType() {
    return this._value.resourceType;
  }

  resourceTypeIsIn(values: TranscribeResourceType[] = []) {
    return this._value.resourceType && values.includes(this._value.resourceType);
  }

  get processedResult(): T {
    return this.processedResultConstructorFunc ? (this._value.processedResult && this.processedResultConstructorFunc(this._value.processedResult)) : this._value.processedResult;
  }

  get processedResultWithDefault() {
    return this._value.processedResult || [];
  }

  get originalFiles() {
    return this._value.originalFiles;
  }

  get originalFilesWithDefault() {
    return this._value.originalFiles || {};
  }

  get userReviewedResult(): T {
    return this.userReviewedResultConstructorFunc ? (this._value.userReviewedResult && this.userReviewedResultConstructorFunc(this._value.userReviewedResult)) : this._value.userReviewedResult;
  }

  get userReviewedResultWithDefault() {
    return this._value.userReviewedResult || [];
  }
}
export interface TranscribeRequest {
	patientId?: Nullable<string>;
	jobId?: Nullable<string>;
	resourceType?: Nullable<TranscribeResourceType>;
}
export class TranscribeRequestClass {
  protected readonly _value: TranscribeRequest;

	constructor(value: TranscribeRequest) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get patientId() {
    return this._value.patientId;
  }

  get patientIdWithDefault() {
    return this._value.patientId || '';
  }

  get jobId() {
    return this._value.jobId;
  }

  get jobIdWithDefault() {
    return this._value.jobId || '';
  }

  get resourceType() {
    return this._value.resourceType;
  }

  resourceTypeIsIn(values: TranscribeResourceType[] = []) {
    return this._value.resourceType && values.includes(this._value.resourceType);
  }
}
export interface TranscribedLabResultItem {
	name?: Nullable<string>;
	unit?: Nullable<string>;
	rawUnit?: Nullable<string>;
	processedUnit?: Nullable<string>;
	value?: Nullable<string>;
	isInHouse?: Nullable<boolean>;
	imageIndex?: Nullable<number>;
	interval?: Nullable<string>;
	referenceLevels?: Nullable<ReferenceLevel[]>;
	duplicate?: Nullable<boolean>;
	conflict?: Nullable<boolean>;
	unitMismatch?: Nullable<boolean>;
	existingValue?: Nullable<string>;
}
export interface TranscribedLabResult {
	userId?: Nullable<string>;
	templateName?: Nullable<string>;
	templateId?: Nullable<string>;
	source?: Nullable<string>;
	dateCollected?: Nullable<string>;
	dateReceived?: Nullable<string>;
	localDateCollected?: Nullable<string>;
	s3FileKey?: Nullable<string>;
	results?: Nullable<TranscribedLabResultItem[]>;
}
export class TranscribedLabResultItemClass {
  protected readonly _value: TranscribedLabResultItem;

	constructor(value: TranscribedLabResultItem) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }

  get rawUnit() {
    return this._value.rawUnit;
  }

  get rawUnitWithDefault() {
    return this._value.rawUnit || '';
  }

  get processedUnit() {
    return this._value.processedUnit;
  }

  get processedUnitWithDefault() {
    return this._value.processedUnit || '';
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || '';
  }

  get isInHouse() {
    return this._value.isInHouse;
  }

  get isInHouseWithDefault() {
    return this._value.isInHouse || false;
  }

  get imageIndex() {
    return this._value.imageIndex;
  }

  get imageIndexWithDefault() {
    return this._value.imageIndex || 0;
  }

  get interval() {
    return this._value.interval;
  }

  get intervalWithDefault() {
    return this._value.interval || '';
  }

  get referenceLevels() {
    return this._value.referenceLevels !== undefined && this._value.referenceLevels !== null ? map(this._value.referenceLevels, (v) => new ReferenceLevelClass(v)) : this._value.referenceLevels;
  }

  get referenceLevelsWithDefault() {
    return this._value.referenceLevels || [];
  }

  get duplicate() {
    return this._value.duplicate;
  }

  get duplicateWithDefault() {
    return this._value.duplicate || false;
  }

  get conflict() {
    return this._value.conflict;
  }

  get conflictWithDefault() {
    return this._value.conflict || false;
  }

  get unitMismatch() {
    return this._value.unitMismatch;
  }

  get unitMismatchWithDefault() {
    return this._value.unitMismatch || false;
  }

  get existingValue() {
    return this._value.existingValue;
  }

  get existingValueWithDefault() {
    return this._value.existingValue || '';
  }
}
export class TranscribedLabResultClass {
  protected readonly _value: TranscribedLabResult;

	constructor(value: TranscribedLabResult) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get userId() {
    return this._value.userId;
  }

  get templateName() {
    return this._value.templateName;
  }

  get templateNameWithDefault() {
    return this._value.templateName || '';
  }

  get templateId() {
    return this._value.templateId;
  }

  get templateIdWithDefault() {
    return this._value.templateId || '';
  }

  get source() {
    return this._value.source;
  }

  get sourceWithDefault() {
    return this._value.source || '';
  }

  get dateCollected() {
    return (this._value.dateCollected !== undefined && this._value.dateCollected !== null) ? dayjs(this._value.dateCollected) : this._value.dateCollected;
  }

  get dateReceived() {
    return (this._value.dateReceived !== undefined && this._value.dateReceived !== null) ? dayjs(this._value.dateReceived) : this._value.dateReceived;
  }

  get localDateCollected() {
    return this._value.localDateCollected;
  }

  get localDateCollectedWithDefault() {
    return this._value.localDateCollected || '';
  }

  get s3FileKey() {
    return this._value.s3FileKey;
  }

  get s3FileKeyWithDefault() {
    return this._value.s3FileKey || '';
  }

  get results() {
    return this._value.results !== undefined && this._value.results !== null ? map(this._value.results, (v) => new TranscribedLabResultItemClass(v)) : this._value.results;
  }

  get resultsWithDefault() {
    return this._value.results || [];
  }
}
export interface UserReviewRequest {
	jobId?: Nullable<string>;
	userReviewedResult?: Nullable<LabResult[]>;
	viewerId?: Nullable<string>;
}
export interface UserFeedbackRequest {
	jobId?: Nullable<string>;
	score?: Nullable<number>;
	comment?: Nullable<string>;
	viewerId?: Nullable<string>;
}
export interface TranscribingJobGetPresignedUrlsParams {
	request: PresignedURLRequest;
}

export interface TranscribingJobPostProcessParams {
	request: TranscribeJob<T>;
}

export interface TranscribingJobTranscribeParams {
	transcriptionRequest: TranscribeRequest;
}

export interface TranscribingJobGetTranscriptionJobParams {
	jobId: string;
	resourceType?: string;
}

export interface TranscribingJobBatchInsertParams {
	request: UserReviewRequest;
}

export interface TranscribingJobFeedbackParams {
	request: UserFeedbackRequest;
}

export class TranscribingJobService {
  protected baseURL = '';

  protected basePath = '/v1/uc/transcribe-jobs';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public getPresignedUrls({ baseUrl, params, headers }: RequestOption<TranscribingJobGetPresignedUrlsParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<PresignedURLDetail[]>>(`${baseUrl || this.baseURL}${this.basePath}/presigned-urls${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public postProcess({ baseUrl, params, headers }: RequestOption<TranscribingJobPostProcessParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<TranscribeJob<T>>>(`${baseUrl || this.baseURL}${this.basePath}/post-process${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public transcribe({ baseUrl, params, headers }: RequestOption<TranscribingJobTranscribeParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<TranscribeRequest>>(`${baseUrl || this.baseURL}${this.basePath}${getQueryStrings(queryList)}`, params.transcriptionRequest, { headers });
	}

	public getTranscriptionJob({ baseUrl, params, headers }: RequestOption<TranscribingJobGetTranscriptionJobParams>) {
		const queryList: QueryParam[] = [];
		if (params.resourceType !== undefined && params.resourceType !== null) {
			queryList.push({ name: 'resourceType', value: params.resourceType.toString() });
		}
		return axios.get<APIResponse<TranscribeJob<TranscribedLabResult>>>(`${baseUrl || this.baseURL}${this.basePath}/${params.jobId}${getQueryStrings(queryList)}`, { headers });
	}

	public batchInsert({ baseUrl, params, headers }: RequestOption<TranscribingJobBatchInsertParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Void>>(`${baseUrl || this.baseURL}${this.basePath}/user-reviews${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public feedback({ baseUrl, params, headers }: RequestOption<TranscribingJobFeedbackParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<Void>>(`${baseUrl || this.baseURL}${this.basePath}/feedbacks${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const transcribingJobService = new TranscribingJobService();

export const createTranscribingJobGetPresignedUrlsObject = (value: APIResponse<PresignedURLDetail[]>) => new APIResponseClass<PresignedURLDetailClass[]>(value, (v) => map(v, (value: PresignedURLDetail) => new PresignedURLDetailClass(value)));

export const useTranscribingJobGetPresignedUrls = <RCN = undefined>(configs: MakeRequestHookConfig<TranscribingJobGetPresignedUrlsParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TranscribingJobGetPresignedUrlsParams,
    APIResponse<PresignedURLDetail[]>,
    typeof transcribingJobService.getPresignedUrls,
    AjaxOptions,
    APIResponseClass<PresignedURLDetailClass[]>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transcribingJobService.getPresignedUrls>) => (
      transcribingJobService.getPresignedUrls(...args)
    ),
    ResponseClassCreator: createTranscribingJobGetPresignedUrlsObject,
    typeName: 'useTranscribingJobGetPresignedUrls',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTranscribingJobGetPresignedUrls.mock = () => new Error('Mock has not been implemented!');
useTranscribingJobGetPresignedUrls.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribingJobGetPresignedUrls.createObject = createTranscribingJobGetPresignedUrlsObject;

export const createTranscribingJobPostProcessObject = (value: APIResponse<TranscribeJob<T>>) => new APIResponseClass<TranscribeJobClass<TClass>>(value, (value: TranscribeJob<T>) => new TranscribeJobClass<TClass>(value, (value: T) => new TClass(value)));

export const useTranscribingJobPostProcess = <RCN = undefined>(configs: MakeRequestHookConfig<TranscribingJobPostProcessParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TranscribingJobPostProcessParams,
    APIResponse<TranscribeJob<T>>,
    typeof transcribingJobService.postProcess,
    AjaxOptions,
    APIResponseClass<TranscribeJobClass<TClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transcribingJobService.postProcess>) => (
      transcribingJobService.postProcess(...args)
    ),
    ResponseClassCreator: createTranscribingJobPostProcessObject,
    typeName: 'useTranscribingJobPostProcess',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTranscribingJobPostProcess.mock = () => new Error('Mock has not been implemented!');
useTranscribingJobPostProcess.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribingJobPostProcess.createObject = createTranscribingJobPostProcessObject;

export const createTranscribingJobTranscribeObject = (value: APIResponse<TranscribeRequest>) => new APIResponseClass<TranscribeRequestClass>(value, (value: TranscribeRequest) => new TranscribeRequestClass(value));

export const useTranscribingJobTranscribe = <RCN = undefined>(configs: MakeRequestHookConfig<TranscribingJobTranscribeParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TranscribingJobTranscribeParams,
    APIResponse<TranscribeRequest>,
    typeof transcribingJobService.transcribe,
    AjaxOptions,
    APIResponseClass<TranscribeRequestClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transcribingJobService.transcribe>) => (
      transcribingJobService.transcribe(...args)
    ),
    ResponseClassCreator: createTranscribingJobTranscribeObject,
    typeName: 'useTranscribingJobTranscribe',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTranscribingJobTranscribe.mock = () => new Error('Mock has not been implemented!');
useTranscribingJobTranscribe.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribingJobTranscribe.createObject = createTranscribingJobTranscribeObject;

export const createTranscribingJobGetTranscriptionJobObject = (value: APIResponse<TranscribeJob<TranscribedLabResult>>) => new APIResponseClass<TranscribeJobClass<TranscribedLabResultClass>>(value, (value: TranscribeJob<TranscribedLabResult>) => new TranscribeJobClass<TranscribedLabResultClass>(value, (value: TranscribedLabResult) => new TranscribedLabResultClass(value)));

export const useTranscribingJobGetTranscriptionJob = <RCN = undefined>(configs: MakeRequestHookConfig<TranscribingJobGetTranscriptionJobParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TranscribingJobGetTranscriptionJobParams,
    APIResponse<TranscribeJob<TranscribedLabResult>>,
    typeof transcribingJobService.getTranscriptionJob,
    GetOptions,
    APIResponseClass<TranscribeJobClass<TranscribedLabResultClass>>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof transcribingJobService.getTranscriptionJob>) => (
      transcribingJobService.getTranscriptionJob(...args)
    ),
    ResponseClassCreator: createTranscribingJobGetTranscriptionJobObject,
    typeName: 'useTranscribingJobGetTranscriptionJob',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTranscribingJobGetTranscriptionJob.mock = () => new Error('Mock has not been implemented!');
useTranscribingJobGetTranscriptionJob.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribingJobGetTranscriptionJob.createObject = createTranscribingJobGetTranscriptionJobObject;

export const createTranscribingJobBatchInsertObject = (value: APIResponse<Void>) => new APIResponseClass<VoidClass>(value, (value: Void) => new VoidClass(value));

export const useTranscribingJobBatchInsert = <RCN = undefined>(configs: MakeRequestHookConfig<TranscribingJobBatchInsertParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TranscribingJobBatchInsertParams,
    APIResponse<Void>,
    typeof transcribingJobService.batchInsert,
    AjaxOptions,
    APIResponseClass<VoidClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transcribingJobService.batchInsert>) => (
      transcribingJobService.batchInsert(...args)
    ),
    ResponseClassCreator: createTranscribingJobBatchInsertObject,
    typeName: 'useTranscribingJobBatchInsert',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTranscribingJobBatchInsert.mock = () => new Error('Mock has not been implemented!');
useTranscribingJobBatchInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribingJobBatchInsert.createObject = createTranscribingJobBatchInsertObject;

export const createTranscribingJobFeedbackObject = (value: APIResponse<Void>) => new APIResponseClass<VoidClass>(value, (value: Void) => new VoidClass(value));

export const useTranscribingJobFeedback = <RCN = undefined>(configs: MakeRequestHookConfig<TranscribingJobFeedbackParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TranscribingJobFeedbackParams,
    APIResponse<Void>,
    typeof transcribingJobService.feedback,
    AjaxOptions,
    APIResponseClass<VoidClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transcribingJobService.feedback>) => (
      transcribingJobService.feedback(...args)
    ),
    ResponseClassCreator: createTranscribingJobFeedbackObject,
    typeName: 'useTranscribingJobFeedback',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTranscribingJobFeedback.mock = () => new Error('Mock has not been implemented!');
useTranscribingJobFeedback.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranscribingJobFeedback.createObject = createTranscribingJobFeedbackObject;
export interface TranslateRequest {
	content?: Nullable<string>;
	targetLanguage?: Nullable<string>;
}
export interface TranslateResponse {
	translatedContent?: Nullable<string>;
	sourceLanguage?: Nullable<string>;
	targetLanguage?: Nullable<string>;
}
export class TranslateResponseClass {
  protected readonly _value: TranslateResponse;

	constructor(value: TranslateResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get translatedContent() {
    return this._value.translatedContent;
  }

  get translatedContentWithDefault() {
    return this._value.translatedContent || '';
  }

  get sourceLanguage() {
    return this._value.sourceLanguage;
  }

  get sourceLanguageWithDefault() {
    return this._value.sourceLanguage || '';
  }

  get targetLanguage() {
    return this._value.targetLanguage;
  }

  get targetLanguageWithDefault() {
    return this._value.targetLanguage || '';
  }
}
export interface TranslateSupportedLanguage {
	languages?: Nullable<string[]>;
}
export class TranslateSupportedLanguageClass {
  protected readonly _value: TranslateSupportedLanguage;

	constructor(value: TranslateSupportedLanguage) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get languages() {
    return this._value.languages;
  }

  get languagesWithDefault() {
    return this._value.languages || [];
  }
}
export interface TranslateTranslateParams {
	request: TranslateRequest;
}

export interface TranslateLanguagesParams {
}

export class TranslateService {
  protected baseURL = '';

  protected basePath = '/v1/uc/translate';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public translate({ baseUrl, params, headers }: RequestOption<TranslateTranslateParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<TranslateResponse>>(`${baseUrl || this.baseURL}${this.basePath}${getQueryStrings(queryList)}`, params.request, { headers });
	}

	public languages({ baseUrl, params, headers }: RequestOption<TranslateLanguagesParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<TranslateSupportedLanguage>>(`${baseUrl || this.baseURL}${this.basePath}/languages${getQueryStrings(queryList)}`, { headers });
	}
}

export const translateService = new TranslateService();

export const createTranslateTranslateObject = (value: APIResponse<TranslateResponse>) => new APIResponseClass<TranslateResponseClass>(value, (value: TranslateResponse) => new TranslateResponseClass(value));

export const useTranslateTranslate = <RCN = undefined>(configs: MakeRequestHookConfig<TranslateTranslateParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TranslateTranslateParams,
    APIResponse<TranslateResponse>,
    typeof translateService.translate,
    AjaxOptions,
    APIResponseClass<TranslateResponseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof translateService.translate>) => (
      translateService.translate(...args)
    ),
    ResponseClassCreator: createTranslateTranslateObject,
    typeName: 'useTranslateTranslate',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTranslateTranslate.mock = () => new Error('Mock has not been implemented!');
useTranslateTranslate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranslateTranslate.createObject = createTranslateTranslateObject;

export const createTranslateLanguagesObject = (value: APIResponse<TranslateSupportedLanguage>) => new APIResponseClass<TranslateSupportedLanguageClass>(value, (value: TranslateSupportedLanguage) => new TranslateSupportedLanguageClass(value));

export const useTranslateLanguages = <RCN = undefined>(configs: MakeRequestHookConfig<TranslateLanguagesParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    TranslateLanguagesParams,
    APIResponse<TranslateSupportedLanguage>,
    typeof translateService.languages,
    GetOptions,
    APIResponseClass<TranslateSupportedLanguageClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof translateService.languages>) => (
      translateService.languages(...args)
    ),
    ResponseClassCreator: createTranslateLanguagesObject,
    typeName: 'useTranslateLanguages',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useTranslateLanguages.mock = () => new Error('Mock has not been implemented!');
useTranslateLanguages.getLastMockedValue = () => new Error('Mock has not been implemented!');
useTranslateLanguages.createObject = createTranslateLanguagesObject;
export interface UcOrganizationDeletePrerequisites {
	exists?: Nullable<boolean>;
	allow_deleted_type?: Nullable<boolean>;
	no_children?: Nullable<boolean>;
}
export class UcOrganizationDeletePrerequisitesClass {
  protected readonly _value: UcOrganizationDeletePrerequisites;

	constructor(value: UcOrganizationDeletePrerequisites) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get exists() {
    return this._value.exists;
  }

  get existsWithDefault() {
    return this._value.exists || false;
  }

  get allow_deleted_type() {
    return this._value.allow_deleted_type;
  }

  get allow_deleted_typeWithDefault() {
    return this._value.allow_deleted_type || false;
  }

  get no_children() {
    return this._value.no_children;
  }

  get no_childrenWithDefault() {
    return this._value.no_children || false;
  }
}
export interface RoleCount {
	roleType?: Nullable<RoleTypeEnum>;
	count?: Nullable<number>;
}
export interface DirectMembersCount {
	total?: Nullable<number>;
	roles?: Nullable<RoleCount[]>;
}
export class RoleCountClass {
  protected readonly _value: RoleCount;

	constructor(value: RoleCount) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get roleType() {
    return this._value.roleType;
  }

  roleTypeIsIn(values: RoleTypeEnum[] = []) {
    return this._value.roleType && values.includes(this._value.roleType);
  }

  get count() {
    return this._value.count;
  }

  get countWithDefault() {
    return this._value.count || 0;
  }
}
export class DirectMembersCountClass {
  protected readonly _value: DirectMembersCount;

	constructor(value: DirectMembersCount) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get total() {
    return this._value.total;
  }

  get totalWithDefault() {
    return this._value.total || 0;
  }

  get roles() {
    return this._value.roles !== undefined && this._value.roles !== null ? map(this._value.roles, (v) => new RoleCountClass(v)) : this._value.roles;
  }

  get rolesWithDefault() {
    return this._value.roles || [];
  }
}
export interface UcOrganizationDeletePrerequisitesParams {
	id: string;
}

export interface UcOrganizationDirectMembersCountParams {
	id: string;
}

export interface UcOrganizationGetAssignedPatientEmployeeListParams {
	id: string;
	medOrgId: string;
}

export class UcOrganizationService extends BaseController<UcOrganization, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/uc-organizations';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public deletePrerequisites({ baseUrl, params, headers }: RequestOption<UcOrganizationDeletePrerequisitesParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<UcOrganizationDeletePrerequisites>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/delete-prerequisites${getQueryStrings(queryList)}`, { headers });
	}

	public directMembersCount({ baseUrl, params, headers }: RequestOption<UcOrganizationDirectMembersCountParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<DirectMembersCount>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/direct-members-count${getQueryStrings(queryList)}`, { headers });
	}

	public getAssignedPatientEmployeeList({ baseUrl, params, headers }: RequestOption<UcOrganizationGetAssignedPatientEmployeeListParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<EmployeeResponse[]>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}/assigned-patient-employee-list/${params.medOrgId}${getQueryStrings(queryList)}`, { headers });
	}
}

export const ucOrganizationService = new UcOrganizationService();

export const createUcOrganizationDeletePrerequisitesObject = (value: APIResponse<UcOrganizationDeletePrerequisites>) => new APIResponseClass<UcOrganizationDeletePrerequisitesClass>(value, (value: UcOrganizationDeletePrerequisites) => new UcOrganizationDeletePrerequisitesClass(value));

export const useUcOrganizationDeletePrerequisites = <RCN = undefined>(configs: MakeRequestHookConfig<UcOrganizationDeletePrerequisitesParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    UcOrganizationDeletePrerequisitesParams,
    APIResponse<UcOrganizationDeletePrerequisites>,
    typeof ucOrganizationService.deletePrerequisites,
    GetOptions,
    APIResponseClass<UcOrganizationDeletePrerequisitesClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ucOrganizationService.deletePrerequisites>) => (
      ucOrganizationService.deletePrerequisites(...args)
    ),
    ResponseClassCreator: createUcOrganizationDeletePrerequisitesObject,
    typeName: 'useUcOrganizationDeletePrerequisites',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useUcOrganizationDeletePrerequisites.mock = () => new Error('Mock has not been implemented!');
useUcOrganizationDeletePrerequisites.getLastMockedValue = () => new Error('Mock has not been implemented!');
useUcOrganizationDeletePrerequisites.createObject = createUcOrganizationDeletePrerequisitesObject;

export const createUcOrganizationDirectMembersCountObject = (value: APIResponse<DirectMembersCount>) => new APIResponseClass<DirectMembersCountClass>(value, (value: DirectMembersCount) => new DirectMembersCountClass(value));

export const useUcOrganizationDirectMembersCount = <RCN = undefined>(configs: MakeRequestHookConfig<UcOrganizationDirectMembersCountParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    UcOrganizationDirectMembersCountParams,
    APIResponse<DirectMembersCount>,
    typeof ucOrganizationService.directMembersCount,
    GetOptions,
    APIResponseClass<DirectMembersCountClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ucOrganizationService.directMembersCount>) => (
      ucOrganizationService.directMembersCount(...args)
    ),
    ResponseClassCreator: createUcOrganizationDirectMembersCountObject,
    typeName: 'useUcOrganizationDirectMembersCount',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useUcOrganizationDirectMembersCount.mock = () => new Error('Mock has not been implemented!');
useUcOrganizationDirectMembersCount.getLastMockedValue = () => new Error('Mock has not been implemented!');
useUcOrganizationDirectMembersCount.createObject = createUcOrganizationDirectMembersCountObject;

export const createUcOrganizationGetAssignedPatientEmployeeListObject = (value: APIResponse<EmployeeResponse[]>) => new APIResponseClass<EmployeeResponseClass[]>(value, (v) => map(v, (value: EmployeeResponse) => new EmployeeResponseClass(value)));

export const useUcOrganizationGetAssignedPatientEmployeeList = <RCN = undefined>(configs: MakeRequestHookConfig<UcOrganizationGetAssignedPatientEmployeeListParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    UcOrganizationGetAssignedPatientEmployeeListParams,
    APIResponse<EmployeeResponse[]>,
    typeof ucOrganizationService.getAssignedPatientEmployeeList,
    GetOptions,
    APIResponseClass<EmployeeResponseClass[]>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ucOrganizationService.getAssignedPatientEmployeeList>) => (
      ucOrganizationService.getAssignedPatientEmployeeList(...args)
    ),
    ResponseClassCreator: createUcOrganizationGetAssignedPatientEmployeeListObject,
    typeName: 'useUcOrganizationGetAssignedPatientEmployeeList',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useUcOrganizationGetAssignedPatientEmployeeList.mock = () => new Error('Mock has not been implemented!');
useUcOrganizationGetAssignedPatientEmployeeList.getLastMockedValue = () => new Error('Mock has not been implemented!');
useUcOrganizationGetAssignedPatientEmployeeList.createObject = createUcOrganizationGetAssignedPatientEmployeeListObject;


export const createUcOrganizationGetObject = (value: APIResponse<UcOrganization>) => new APIResponseClass<UcOrganizationClass>(value, (value: UcOrganization) => new UcOrganizationClass(value));
export const useUcOrganizationGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<UcOrganization>,
    // @ts-ignore
    typeof ucOrganizationService.get,
    GetOptions,
    APIResponseClass<UcOrganizationClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof ucOrganizationService.get>) => (
      ucOrganizationService.get(...args)
    ),
    ResponseClassCreator: createUcOrganizationGetObject,
    typeName: 'useUcOrganizationGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useUcOrganizationGet.mock = () => new Error('Mock has not been implemented!');
useUcOrganizationGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useUcOrganizationGet.createObject = createUcOrganizationGetObject;

export const createUcOrganizationUpdateObject = (value: APIResponse<UcOrganization>) => new APIResponseClass<UcOrganizationClass>(value, (value: UcOrganization) => new UcOrganizationClass(value));
export const useUcOrganizationUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, UcOrganization>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, UcOrganization>,
    APIResponse<UcOrganization>,
    // @ts-ignore
    typeof ucOrganizationService.update,
    AjaxOptions,
    APIResponseClass<UcOrganizationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ucOrganizationService.update>) => (
      ucOrganizationService.update(...args)
    ),
    ResponseClassCreator: createUcOrganizationUpdateObject,
    typeName: 'useUcOrganizationUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useUcOrganizationUpdate.mock = () => new Error('Mock has not been implemented!');
useUcOrganizationUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useUcOrganizationUpdate.createObject = createUcOrganizationUpdateObject;

export const createUcOrganizationInsertObject = (value: APIResponse<UcOrganization>) => new APIResponseClass<UcOrganizationClass>(value, (value: UcOrganization) => new UcOrganizationClass(value));
export const useUcOrganizationInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<UcOrganization>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<UcOrganization>,
    APIResponse<UcOrganization>,
    // @ts-ignore
    typeof ucOrganizationService.insert,
    AjaxOptions,
    APIResponseClass<UcOrganizationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ucOrganizationService.insert>) => (
      ucOrganizationService.insert(...args)
    ),
    ResponseClassCreator: createUcOrganizationInsertObject,
    typeName: 'useUcOrganizationInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useUcOrganizationInsert.mock = () => new Error('Mock has not been implemented!');
useUcOrganizationInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useUcOrganizationInsert.createObject = createUcOrganizationInsertObject;

export const createUcOrganizationDeleteObject = (value: APIResponse<UcOrganization>) => new APIResponseClass<UcOrganizationClass>(value, (value: UcOrganization) => new UcOrganizationClass(value));
export const useUcOrganizationDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<UcOrganization>,
    // @ts-ignore
    typeof ucOrganizationService.delete,
    AjaxOptions,
    APIResponseClass<UcOrganizationClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ucOrganizationService.delete>) => (
      ucOrganizationService.delete(...args)
    ),
    ResponseClassCreator: createUcOrganizationDeleteObject,
    typeName: 'useUcOrganizationDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useUcOrganizationDelete.mock = () => new Error('Mock has not been implemented!');
useUcOrganizationDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useUcOrganizationDelete.createObject = createUcOrganizationDeleteObject;

export const createUcOrganizationSearchObject = (value: APIResponse<PageResponse<UcOrganization>>) => new APIResponseClass<PageResponseClass<UcOrganizationClass>>(value, (value: PageResponse<UcOrganization>) => new PageResponseClass<UcOrganizationClass>(value, (value: UcOrganization) => new UcOrganizationClass(value)));
export const useUcOrganizationSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<UcOrganization>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<UcOrganization>,
    APIResponse<PageResponse<UcOrganization>>,
    // @ts-ignore
    typeof ucOrganizationService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<UcOrganizationClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof ucOrganizationService.search>) => (
      ucOrganizationService.search(...args)
    ),
    ResponseClassCreator: createUcOrganizationSearchObject,
    typeName: 'useUcOrganizationSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useUcOrganizationSearch.mock = () => new Error('Mock has not been implemented!');
useUcOrganizationSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useUcOrganizationSearch.createObject = createUcOrganizationSearchObject;

export interface A1CSchema {
	a1cId?: Nullable<string>;
	name?: Nullable<string>;
	value?: Nullable<number>;
	dateCollected?: Nullable<string>;
	isInHouse?: Nullable<boolean>;
	unit?: Nullable<string>;
}
export interface VisitAssignee {
	assignedToRD?: Nullable<MiniProvider>;
	assignedToCA?: Nullable<MiniProvider>;
	assignedToHC?: Nullable<MiniProvider>;
}
export enum VisitTaskStatusEnum {
	PENDING = 'PENDING',
	COMPLETED = 'COMPLETED',
}
export interface VisitDetail {
	height?: Nullable<Height>;
	weight?: Nullable<Weight>;
	addToContactsStatus?: Nullable<VisitTaskStatusEnum>;
	autoUpdateStatus?: Nullable<VisitTaskStatusEnum>;
	appIsUptoDate?: Nullable<VisitTaskStatusEnum>;
	EMRAttached?: Nullable<string>;
	temperature?: Nullable<BodyTemperature>;
}
export interface Visit extends BaseModel {
	memberId?: Nullable<string>;
	organizationId?: Nullable<string>;
	providerIds?: Nullable<string[]>;
	programIds?: Nullable<string[]>;
	createdBy?: Nullable<string>;
	modifiedBy?: Nullable<string>;
	lastRemindedAt?: Nullable<string>;
	rescheduledFromId?: Nullable<string>;
	type?: Nullable<VisitTypeEnum>;
	actualType?: Nullable<VisitTypeEnum>;
	labResultId?: Nullable<string>;
	reason?: Nullable<string>;
	appointmentAt?: Nullable<string>;
	checkinAt?: Nullable<string>;
	checkoutAt?: Nullable<string>;
	appointmentTo?: Nullable<string>;
	visitRoles?: Nullable<string[]>;
	shouldSeeDoctor?: Nullable<string>;
	conditionsList?: Nullable<string>;
	needInHouseA1C?: Nullable<string>;
	A1C?: Nullable<A1CSchema>;
	reviewBillable?: Nullable<string>;
	associatedVisitId?: Nullable<string>;
	category?: Nullable<VisitMethodEnum>;
	doctorNotes?: Nullable<string>;
	assignees?: Nullable<VisitAssignee>;
	declineToCheckVital?: Nullable<boolean>;
	unableToCheckVital?: Nullable<string>;
	details?: Nullable<VisitDetail>;
	memberIdIn?: Nullable<InFilter<string>>;
	appointmentAtRange?: Nullable<DateRangeFilter>;
	appointmentToRange?: Nullable<DateRangeFilter>;
}
export class A1CSchemaClass {
  protected readonly _value: A1CSchema;

	constructor(value: A1CSchema) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get a1cId() {
    return this._value.a1cId;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get value() {
    return this._value.value;
  }

  get valueWithDefault() {
    return this._value.value || 0.0;
  }

  get dateCollected() {
    return (this._value.dateCollected !== undefined && this._value.dateCollected !== null) ? dayjs(this._value.dateCollected) : this._value.dateCollected;
  }

  get isInHouse() {
    return this._value.isInHouse;
  }

  get isInHouseWithDefault() {
    return this._value.isInHouse || false;
  }

  get unit() {
    return this._value.unit;
  }

  get unitWithDefault() {
    return this._value.unit || '';
  }
}
export class VisitAssigneeClass {
  protected readonly _value: VisitAssignee;

	constructor(value: VisitAssignee) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get assignedToRD() {
    return this._value.assignedToRD !== undefined && this._value.assignedToRD !== null ? new MiniProviderClass(this._value.assignedToRD) : this._value.assignedToRD;
  }

  get assignedToCA() {
    return this._value.assignedToCA !== undefined && this._value.assignedToCA !== null ? new MiniProviderClass(this._value.assignedToCA) : this._value.assignedToCA;
  }

  get assignedToHC() {
    return this._value.assignedToHC !== undefined && this._value.assignedToHC !== null ? new MiniProviderClass(this._value.assignedToHC) : this._value.assignedToHC;
  }
}
export class VisitDetailClass {
  protected readonly _value: VisitDetail;

	constructor(value: VisitDetail) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get height() {
    return this._value.height !== undefined && this._value.height !== null ? new HeightClass(this._value.height) : this._value.height;
  }

  get weight() {
    return this._value.weight !== undefined && this._value.weight !== null ? new WeightClass(this._value.weight) : this._value.weight;
  }

  get addToContactsStatus() {
    return this._value.addToContactsStatus;
  }

  addToContactsStatusIsIn(values: VisitTaskStatusEnum[] = []) {
    return this._value.addToContactsStatus && values.includes(this._value.addToContactsStatus);
  }

  get autoUpdateStatus() {
    return this._value.autoUpdateStatus;
  }

  autoUpdateStatusIsIn(values: VisitTaskStatusEnum[] = []) {
    return this._value.autoUpdateStatus && values.includes(this._value.autoUpdateStatus);
  }

  get appIsUptoDate() {
    return this._value.appIsUptoDate;
  }

  appIsUptoDateIsIn(values: VisitTaskStatusEnum[] = []) {
    return this._value.appIsUptoDate && values.includes(this._value.appIsUptoDate);
  }

  get EMRAttached() {
    return this._value.EMRAttached;
  }

  get EMRAttachedWithDefault() {
    return this._value.EMRAttached || '';
  }

  get temperature() {
    return this._value.temperature !== undefined && this._value.temperature !== null ? new BodyTemperatureClass(this._value.temperature) : this._value.temperature;
  }
}
export class VisitClass extends BaseModelClass {
  protected readonly _value: Visit;

	constructor(value: Visit) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get memberId() {
    return this._value.memberId;
  }

  get organizationId() {
    return this._value.organizationId;
  }

  get providerIds() {
    return this._value.providerIds;
  }

  get providerIdsWithDefault() {
    return this._value.providerIds || [];
  }

  get programIds() {
    return this._value.programIds;
  }

  get programIdsWithDefault() {
    return this._value.programIds || [];
  }

  get createdBy() {
    return this._value.createdBy;
  }

  get modifiedBy() {
    return this._value.modifiedBy;
  }

  get lastRemindedAt() {
    return (this._value.lastRemindedAt !== undefined && this._value.lastRemindedAt !== null) ? dayjs(this._value.lastRemindedAt) : this._value.lastRemindedAt;
  }

  get rescheduledFromId() {
    return this._value.rescheduledFromId;
  }

  get type() {
    return this._value.type;
  }

  typeIsIn(values: VisitTypeEnum[] = []) {
    return this._value.type && values.includes(this._value.type);
  }

  get actualType() {
    return this._value.actualType;
  }

  actualTypeIsIn(values: VisitTypeEnum[] = []) {
    return this._value.actualType && values.includes(this._value.actualType);
  }

  get labResultId() {
    return this._value.labResultId;
  }

  get reason() {
    return this._value.reason;
  }

  get reasonWithDefault() {
    return this._value.reason || '';
  }

  get appointmentAt() {
    return (this._value.appointmentAt !== undefined && this._value.appointmentAt !== null) ? dayjs(this._value.appointmentAt) : this._value.appointmentAt;
  }

  get checkinAt() {
    return (this._value.checkinAt !== undefined && this._value.checkinAt !== null) ? dayjs(this._value.checkinAt) : this._value.checkinAt;
  }

  get checkoutAt() {
    return (this._value.checkoutAt !== undefined && this._value.checkoutAt !== null) ? dayjs(this._value.checkoutAt) : this._value.checkoutAt;
  }

  get appointmentTo() {
    return (this._value.appointmentTo !== undefined && this._value.appointmentTo !== null) ? dayjs(this._value.appointmentTo) : this._value.appointmentTo;
  }

  get visitRoles() {
    return this._value.visitRoles;
  }

  get visitRolesWithDefault() {
    return this._value.visitRoles || [];
  }

  get shouldSeeDoctor() {
    return this._value.shouldSeeDoctor;
  }

  get shouldSeeDoctorWithDefault() {
    return this._value.shouldSeeDoctor || '';
  }

  get conditionsList() {
    return this._value.conditionsList;
  }

  get conditionsListWithDefault() {
    return this._value.conditionsList || '';
  }

  get needInHouseA1C() {
    return this._value.needInHouseA1C;
  }

  get needInHouseA1CWithDefault() {
    return this._value.needInHouseA1C || '';
  }

  get A1C() {
    return this._value.A1C !== undefined && this._value.A1C !== null ? new A1CSchemaClass(this._value.A1C) : this._value.A1C;
  }

  get reviewBillable() {
    return this._value.reviewBillable;
  }

  get reviewBillableWithDefault() {
    return this._value.reviewBillable || '';
  }

  get associatedVisitId() {
    return this._value.associatedVisitId;
  }

  get associatedVisitIdWithDefault() {
    return this._value.associatedVisitId || '';
  }

  get category() {
    return this._value.category;
  }

  categoryIsIn(values: VisitMethodEnum[] = []) {
    return this._value.category && values.includes(this._value.category);
  }

  get doctorNotes() {
    return this._value.doctorNotes;
  }

  get doctorNotesWithDefault() {
    return this._value.doctorNotes || '';
  }

  get assignees() {
    return this._value.assignees !== undefined && this._value.assignees !== null ? new VisitAssigneeClass(this._value.assignees) : this._value.assignees;
  }

  get declineToCheckVital() {
    return this._value.declineToCheckVital;
  }

  get declineToCheckVitalWithDefault() {
    return this._value.declineToCheckVital || false;
  }

  get unableToCheckVital() {
    return this._value.unableToCheckVital;
  }

  get unableToCheckVitalWithDefault() {
    return this._value.unableToCheckVital || '';
  }

  get details() {
    return this._value.details !== undefined && this._value.details !== null ? new VisitDetailClass(this._value.details) : this._value.details;
  }

  get memberIdIn() {
    return this._value.memberIdIn !== undefined && this._value.memberIdIn !== null ? new InFilterClass(this._value.memberIdIn) : this._value.memberIdIn;
  }

  get appointmentAtRange() {
    return this._value.appointmentAtRange !== undefined && this._value.appointmentAtRange !== null ? new DateRangeFilterClass(this._value.appointmentAtRange) : this._value.appointmentAtRange;
  }

  get appointmentToRange() {
    return this._value.appointmentToRange !== undefined && this._value.appointmentToRange !== null ? new DateRangeFilterClass(this._value.appointmentToRange) : this._value.appointmentToRange;
  }
}
export interface VisitUpdateParams {
	id: string;
	visit: Visit;
}

export interface VisitDeleteParams {
	id: string;
}

export class VisitService extends BaseController<Visit, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/visits';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }

	public update({ baseUrl, params, headers }: RequestOption<VisitUpdateParams>) {
		const queryList: QueryParam[] = [];
		return axios.put<APIResponse<Visit>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}${getQueryStrings(queryList)}`, params.visit, { headers });
	}

	public delete({ baseUrl, params, headers }: RequestOption<VisitDeleteParams>) {
		const queryList: QueryParam[] = [];
		return axios.delete<APIResponse<Visit>>(`${baseUrl || this.baseURL}${this.basePath}/${params.id}${getQueryStrings(queryList)}`, { headers });
	}
}

export const visitService = new VisitService();

export const createVisitUpdateObject = (value: APIResponse<Visit>) => new APIResponseClass<VisitClass>(value, (value: Visit) => new VisitClass(value));

export const useVisitUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<VisitUpdateParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    VisitUpdateParams,
    APIResponse<Visit>,
    typeof visitService.update,
    AjaxOptions,
    APIResponseClass<VisitClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof visitService.update>) => (
      visitService.update(...args)
    ),
    ResponseClassCreator: createVisitUpdateObject,
    typeName: 'useVisitUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useVisitUpdate.mock = () => new Error('Mock has not been implemented!');
useVisitUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useVisitUpdate.createObject = createVisitUpdateObject;

export const createVisitDeleteObject = (value: APIResponse<Visit>) => new APIResponseClass<VisitClass>(value, (value: Visit) => new VisitClass(value));

export const useVisitDelete = <RCN = undefined>(configs: MakeRequestHookConfig<VisitDeleteParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    VisitDeleteParams,
    APIResponse<Visit>,
    typeof visitService.delete,
    AjaxOptions,
    APIResponseClass<VisitClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof visitService.delete>) => (
      visitService.delete(...args)
    ),
    ResponseClassCreator: createVisitDeleteObject,
    typeName: 'useVisitDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useVisitDelete.mock = () => new Error('Mock has not been implemented!');
useVisitDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useVisitDelete.createObject = createVisitDeleteObject;


export const createVisitGetObject = (value: APIResponse<Visit>) => new APIResponseClass<VisitClass>(value, (value: Visit) => new VisitClass(value));
export const useVisitGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<Visit>,
    // @ts-ignore
    typeof visitService.get,
    GetOptions,
    APIResponseClass<VisitClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof visitService.get>) => (
      visitService.get(...args)
    ),
    ResponseClassCreator: createVisitGetObject,
    typeName: 'useVisitGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useVisitGet.mock = () => new Error('Mock has not been implemented!');
useVisitGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useVisitGet.createObject = createVisitGetObject;

export const createVisitInsertObject = (value: APIResponse<Visit>) => new APIResponseClass<VisitClass>(value, (value: Visit) => new VisitClass(value));
export const useVisitInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<Visit>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<Visit>,
    APIResponse<Visit>,
    // @ts-ignore
    typeof visitService.insert,
    AjaxOptions,
    APIResponseClass<VisitClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof visitService.insert>) => (
      visitService.insert(...args)
    ),
    ResponseClassCreator: createVisitInsertObject,
    typeName: 'useVisitInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useVisitInsert.mock = () => new Error('Mock has not been implemented!');
useVisitInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useVisitInsert.createObject = createVisitInsertObject;

export const createVisitSearchObject = (value: APIResponse<PageResponse<Visit>>) => new APIResponseClass<PageResponseClass<VisitClass>>(value, (value: PageResponse<Visit>) => new PageResponseClass<VisitClass>(value, (value: Visit) => new VisitClass(value)));
export const useVisitSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<Visit>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<Visit>,
    APIResponse<PageResponse<Visit>>,
    // @ts-ignore
    typeof visitService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<VisitClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof visitService.search>) => (
      visitService.search(...args)
    ),
    ResponseClassCreator: createVisitSearchObject,
    typeName: 'useVisitSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useVisitSearch.mock = () => new Error('Mock has not been implemented!');
useVisitSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useVisitSearch.createObject = createVisitSearchObject;

export interface WeeklyBGScheduleTemplatesScheduleDetails {
	beforeBreakfast?: Nullable<boolean>;
	afterBreakfast?: Nullable<boolean>;
	beforeLunch?: Nullable<boolean>;
	afterLunch?: Nullable<boolean>;
	beforeDinner?: Nullable<boolean>;
	afterDinner?: Nullable<boolean>;
	bedtime?: Nullable<boolean>;
	overnight?: Nullable<boolean>;
}
export interface WeeklyBGScheduleTemplatesSchedule {
	mon?: Nullable<WeeklyBGScheduleTemplatesScheduleDetails>;
	tues?: Nullable<WeeklyBGScheduleTemplatesScheduleDetails>;
	wed?: Nullable<WeeklyBGScheduleTemplatesScheduleDetails>;
	thurs?: Nullable<WeeklyBGScheduleTemplatesScheduleDetails>;
	fri?: Nullable<WeeklyBGScheduleTemplatesScheduleDetails>;
	sat?: Nullable<WeeklyBGScheduleTemplatesScheduleDetails>;
	sun?: Nullable<WeeklyBGScheduleTemplatesScheduleDetails>;
}
export interface WeeklyBGScheduleTemplate extends BaseModel {
	name?: Nullable<string>;
	description?: Nullable<string>;
	schedule?: Nullable<WeeklyBGScheduleTemplatesSchedule>;
	isDefault?: Nullable<boolean>;
}
export class WeeklyBGScheduleTemplatesScheduleDetailsClass {
  protected readonly _value: WeeklyBGScheduleTemplatesScheduleDetails;

	constructor(value: WeeklyBGScheduleTemplatesScheduleDetails) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get beforeBreakfast() {
    return this._value.beforeBreakfast;
  }

  get beforeBreakfastWithDefault() {
    return this._value.beforeBreakfast || false;
  }

  get afterBreakfast() {
    return this._value.afterBreakfast;
  }

  get afterBreakfastWithDefault() {
    return this._value.afterBreakfast || false;
  }

  get beforeLunch() {
    return this._value.beforeLunch;
  }

  get beforeLunchWithDefault() {
    return this._value.beforeLunch || false;
  }

  get afterLunch() {
    return this._value.afterLunch;
  }

  get afterLunchWithDefault() {
    return this._value.afterLunch || false;
  }

  get beforeDinner() {
    return this._value.beforeDinner;
  }

  get beforeDinnerWithDefault() {
    return this._value.beforeDinner || false;
  }

  get afterDinner() {
    return this._value.afterDinner;
  }

  get afterDinnerWithDefault() {
    return this._value.afterDinner || false;
  }

  get bedtime() {
    return this._value.bedtime;
  }

  get bedtimeWithDefault() {
    return this._value.bedtime || false;
  }

  get overnight() {
    return this._value.overnight;
  }

  get overnightWithDefault() {
    return this._value.overnight || false;
  }
}
export class WeeklyBGScheduleTemplatesScheduleClass {
  protected readonly _value: WeeklyBGScheduleTemplatesSchedule;

	constructor(value: WeeklyBGScheduleTemplatesSchedule) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get mon() {
    return this._value.mon !== undefined && this._value.mon !== null ? new WeeklyBGScheduleTemplatesScheduleDetailsClass(this._value.mon) : this._value.mon;
  }

  get tues() {
    return this._value.tues !== undefined && this._value.tues !== null ? new WeeklyBGScheduleTemplatesScheduleDetailsClass(this._value.tues) : this._value.tues;
  }

  get wed() {
    return this._value.wed !== undefined && this._value.wed !== null ? new WeeklyBGScheduleTemplatesScheduleDetailsClass(this._value.wed) : this._value.wed;
  }

  get thurs() {
    return this._value.thurs !== undefined && this._value.thurs !== null ? new WeeklyBGScheduleTemplatesScheduleDetailsClass(this._value.thurs) : this._value.thurs;
  }

  get fri() {
    return this._value.fri !== undefined && this._value.fri !== null ? new WeeklyBGScheduleTemplatesScheduleDetailsClass(this._value.fri) : this._value.fri;
  }

  get sat() {
    return this._value.sat !== undefined && this._value.sat !== null ? new WeeklyBGScheduleTemplatesScheduleDetailsClass(this._value.sat) : this._value.sat;
  }

  get sun() {
    return this._value.sun !== undefined && this._value.sun !== null ? new WeeklyBGScheduleTemplatesScheduleDetailsClass(this._value.sun) : this._value.sun;
  }
}
export class WeeklyBGScheduleTemplateClass extends BaseModelClass {
  protected readonly _value: WeeklyBGScheduleTemplate;

	constructor(value: WeeklyBGScheduleTemplate) {
		super(value);
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get name() {
    return this._value.name;
  }

  get nameWithDefault() {
    return this._value.name || '';
  }

  get description() {
    return this._value.description;
  }

  get descriptionWithDefault() {
    return this._value.description || '';
  }

  get schedule() {
    return this._value.schedule !== undefined && this._value.schedule !== null ? new WeeklyBGScheduleTemplatesScheduleClass(this._value.schedule) : this._value.schedule;
  }

  get isDefault() {
    return this._value.isDefault;
  }

  get isDefaultWithDefault() {
    return this._value.isDefault || false;
  }
}
export class WeeklyBGScheduleTemplateService extends BaseController<WeeklyBGScheduleTemplate, string> {
  protected baseURL = '';

  protected basePath = '/v1/uc/weekly-bg-schedule-templates';

  constructor(baseUrl?: string) {
    super(baseUrl);
    this.baseURL = baseUrl || '';
  }
}

export const weeklyBGScheduleTemplateService = new WeeklyBGScheduleTemplateService();


export const createWeeklyBGScheduleTemplateGetObject = (value: APIResponse<WeeklyBGScheduleTemplate>) => new APIResponseClass<WeeklyBGScheduleTemplateClass>(value, (value: WeeklyBGScheduleTemplate) => new WeeklyBGScheduleTemplateClass(value));
export const useWeeklyBGScheduleTemplateGet = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<WeeklyBGScheduleTemplate>,
    // @ts-ignore
    typeof weeklyBGScheduleTemplateService.get,
    GetOptions,
    APIResponseClass<WeeklyBGScheduleTemplateClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof weeklyBGScheduleTemplateService.get>) => (
      weeklyBGScheduleTemplateService.get(...args)
    ),
    ResponseClassCreator: createWeeklyBGScheduleTemplateGetObject,
    typeName: 'useWeeklyBGScheduleTemplateGet',
    type: MethodTypeEnum.GET,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useWeeklyBGScheduleTemplateGet.mock = () => new Error('Mock has not been implemented!');
useWeeklyBGScheduleTemplateGet.getLastMockedValue = () => new Error('Mock has not been implemented!');
useWeeklyBGScheduleTemplateGet.createObject = createWeeklyBGScheduleTemplateGetObject;

export const createWeeklyBGScheduleTemplateUpdateObject = (value: APIResponse<WeeklyBGScheduleTemplate>) => new APIResponseClass<WeeklyBGScheduleTemplateClass>(value, (value: WeeklyBGScheduleTemplate) => new WeeklyBGScheduleTemplateClass(value));
export const useWeeklyBGScheduleTemplateUpdate = <RCN = undefined>(configs: MakeRequestHookConfig<BaseUpdateParams<string, WeeklyBGScheduleTemplate>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseUpdateParams<string, WeeklyBGScheduleTemplate>,
    APIResponse<WeeklyBGScheduleTemplate>,
    // @ts-ignore
    typeof weeklyBGScheduleTemplateService.update,
    AjaxOptions,
    APIResponseClass<WeeklyBGScheduleTemplateClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof weeklyBGScheduleTemplateService.update>) => (
      weeklyBGScheduleTemplateService.update(...args)
    ),
    ResponseClassCreator: createWeeklyBGScheduleTemplateUpdateObject,
    typeName: 'useWeeklyBGScheduleTemplateUpdate',
    type: MethodTypeEnum.PUT,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useWeeklyBGScheduleTemplateUpdate.mock = () => new Error('Mock has not been implemented!');
useWeeklyBGScheduleTemplateUpdate.getLastMockedValue = () => new Error('Mock has not been implemented!');
useWeeklyBGScheduleTemplateUpdate.createObject = createWeeklyBGScheduleTemplateUpdateObject;

export const createWeeklyBGScheduleTemplateInsertObject = (value: APIResponse<WeeklyBGScheduleTemplate>) => new APIResponseClass<WeeklyBGScheduleTemplateClass>(value, (value: WeeklyBGScheduleTemplate) => new WeeklyBGScheduleTemplateClass(value));
export const useWeeklyBGScheduleTemplateInsert = <RCN = undefined>(configs: MakeRequestHookConfig<BaseInsertParams<WeeklyBGScheduleTemplate>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseInsertParams<WeeklyBGScheduleTemplate>,
    APIResponse<WeeklyBGScheduleTemplate>,
    // @ts-ignore
    typeof weeklyBGScheduleTemplateService.insert,
    AjaxOptions,
    APIResponseClass<WeeklyBGScheduleTemplateClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof weeklyBGScheduleTemplateService.insert>) => (
      weeklyBGScheduleTemplateService.insert(...args)
    ),
    ResponseClassCreator: createWeeklyBGScheduleTemplateInsertObject,
    typeName: 'useWeeklyBGScheduleTemplateInsert',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useWeeklyBGScheduleTemplateInsert.mock = () => new Error('Mock has not been implemented!');
useWeeklyBGScheduleTemplateInsert.getLastMockedValue = () => new Error('Mock has not been implemented!');
useWeeklyBGScheduleTemplateInsert.createObject = createWeeklyBGScheduleTemplateInsertObject;

export const createWeeklyBGScheduleTemplateDeleteObject = (value: APIResponse<WeeklyBGScheduleTemplate>) => new APIResponseClass<WeeklyBGScheduleTemplateClass>(value, (value: WeeklyBGScheduleTemplate) => new WeeklyBGScheduleTemplateClass(value));
export const useWeeklyBGScheduleTemplateDelete = <RCN = undefined>(configs: MakeRequestHookConfig<BaseDeleteParams<string>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseDeleteParams<string>,
    APIResponse<WeeklyBGScheduleTemplate>,
    // @ts-ignore
    typeof weeklyBGScheduleTemplateService.delete,
    AjaxOptions,
    APIResponseClass<WeeklyBGScheduleTemplateClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof weeklyBGScheduleTemplateService.delete>) => (
      weeklyBGScheduleTemplateService.delete(...args)
    ),
    ResponseClassCreator: createWeeklyBGScheduleTemplateDeleteObject,
    typeName: 'useWeeklyBGScheduleTemplateDelete',
    type: MethodTypeEnum.DELETE,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useWeeklyBGScheduleTemplateDelete.mock = () => new Error('Mock has not been implemented!');
useWeeklyBGScheduleTemplateDelete.getLastMockedValue = () => new Error('Mock has not been implemented!');
useWeeklyBGScheduleTemplateDelete.createObject = createWeeklyBGScheduleTemplateDeleteObject;

export const createWeeklyBGScheduleTemplateSearchObject = (value: APIResponse<PageResponse<WeeklyBGScheduleTemplate>>) => new APIResponseClass<PageResponseClass<WeeklyBGScheduleTemplateClass>>(value, (value: PageResponse<WeeklyBGScheduleTemplate>) => new PageResponseClass<WeeklyBGScheduleTemplateClass>(value, (value: WeeklyBGScheduleTemplate) => new WeeklyBGScheduleTemplateClass(value)));
export const useWeeklyBGScheduleTemplateSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<WeeklyBGScheduleTemplate>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<WeeklyBGScheduleTemplate>,
    APIResponse<PageResponse<WeeklyBGScheduleTemplate>>,
    // @ts-ignore
    typeof weeklyBGScheduleTemplateService.search,
    AjaxOptions,
    APIResponseClass<PageResponseClass<WeeklyBGScheduleTemplateClass>>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof weeklyBGScheduleTemplateService.search>) => (
      weeklyBGScheduleTemplateService.search(...args)
    ),
    ResponseClassCreator: createWeeklyBGScheduleTemplateSearchObject,
    typeName: 'useWeeklyBGScheduleTemplateSearch',
    type: MethodTypeEnum.POST,
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

useWeeklyBGScheduleTemplateSearch.mock = () => new Error('Mock has not been implemented!');
useWeeklyBGScheduleTemplateSearch.getLastMockedValue = () => new Error('Mock has not been implemented!');
useWeeklyBGScheduleTemplateSearch.createObject = createWeeklyBGScheduleTemplateSearchObject;

export interface ZoomResponse {
	clientId?: Nullable<string>;
	signature?: Nullable<string>;
}
export class ZoomResponseClass {
  protected readonly _value: ZoomResponse;

	constructor(value: ZoomResponse) {
		this._value = value;
	}

  get rawValue() {
    return this._value;
  }

  get clientId() {
    return this._value.clientId;
  }

  get clientIdWithDefault() {
    return this._value.clientId || '';
  }

  get signature() {
    return this._value.signature;
  }

  get signatureWithDefault() {
    return this._value.signature || '';
  }
}
export interface ZoomGetRealTimeSignatureParams {
	meetingId: string;
}

export interface ZoomCreateZoomMeetingParams {
	startTime: string;
}

export interface ZoomEndMeetingParams {
	meetingId: string;
	clinicEventId?: string;
}

export interface ZoomNotifyCareTeamParams {
	request: CareTeamNotificationRequest;
}

export class ZoomService {
  protected baseURL = '';

  protected basePath = '/v1/uc/zoom';

  constructor(baseUrl?: string) {
    this.baseURL = baseUrl || '';
  }

	public getRealTimeSignature({ baseUrl, params, headers }: RequestOption<ZoomGetRealTimeSignatureParams>) {
		const queryList: QueryParam[] = [];
		return axios.get<APIResponse<ZoomResponse>>(`${baseUrl || this.baseURL}${this.basePath}/${params.meetingId}/signature${getQueryStrings(queryList)}`, { headers });
	}

	public createZoomMeeting({ baseUrl, params, headers }: RequestOption<ZoomCreateZoomMeetingParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<ZoomCredential>>(`${baseUrl || this.baseURL}${this.basePath}/create${getQueryStrings(queryList)}`, params.startTime, { headers });
	}

	public endMeeting({ baseUrl, params, headers }: RequestOption<ZoomEndMeetingParams>) {
		const queryList: QueryParam[] = [];
		if (params.clinicEventId !== undefined && params.clinicEventId !== null) {
			queryList.push({ name: 'clinicEventId', value: params.clinicEventId.toString() });
		}
		return axios.put<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/${params.meetingId}/end${getQueryStrings(queryList)}`, {}, { headers });
	}

	public notifyCareTeam({ baseUrl, params, headers }: RequestOption<ZoomNotifyCareTeamParams>) {
		const queryList: QueryParam[] = [];
		return axios.post<APIResponse<string>>(`${baseUrl || this.baseURL}${this.basePath}/join${getQueryStrings(queryList)}`, params.request, { headers });
	}
}

export const zoomService = new ZoomService();

export const createZoomGetRealTimeSignatureObject = (value: APIResponse<ZoomResponse>) => new APIResponseClass<ZoomResponseClass>(value, (value: ZoomResponse) => new ZoomResponseClass(value));

export const useZoomGetRealTimeSignature = <RCN = undefined>(configs: MakeRequestHookConfig<ZoomGetRealTimeSignatureParams, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ZoomGetRealTimeSignatureParams,
    APIResponse<ZoomResponse>,
    typeof zoomService.getRealTimeSignature,
    GetOptions,
    APIResponseClass<ZoomResponseClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof zoomService.getRealTimeSignature>) => (
      zoomService.getRealTimeSignature(...args)
    ),
    ResponseClassCreator: createZoomGetRealTimeSignatureObject,
    typeName: 'useZoomGetRealTimeSignature',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useZoomGetRealTimeSignature.mock = () => new Error('Mock has not been implemented!');
useZoomGetRealTimeSignature.getLastMockedValue = () => new Error('Mock has not been implemented!');
useZoomGetRealTimeSignature.createObject = createZoomGetRealTimeSignatureObject;

export const createZoomCreateZoomMeetingObject = (value: APIResponse<ZoomCredential>) => new APIResponseClass<ZoomCredentialClass>(value, (value: ZoomCredential) => new ZoomCredentialClass(value));

export const useZoomCreateZoomMeeting = <RCN = undefined>(configs: MakeRequestHookConfig<ZoomCreateZoomMeetingParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ZoomCreateZoomMeetingParams,
    APIResponse<ZoomCredential>,
    typeof zoomService.createZoomMeeting,
    AjaxOptions,
    APIResponseClass<ZoomCredentialClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof zoomService.createZoomMeeting>) => (
      zoomService.createZoomMeeting(...args)
    ),
    ResponseClassCreator: createZoomCreateZoomMeetingObject,
    typeName: 'useZoomCreateZoomMeeting',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useZoomCreateZoomMeeting.mock = () => new Error('Mock has not been implemented!');
useZoomCreateZoomMeeting.getLastMockedValue = () => new Error('Mock has not been implemented!');
useZoomCreateZoomMeeting.createObject = createZoomCreateZoomMeetingObject;

export const createZoomEndMeetingObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useZoomEndMeeting = <RCN = undefined>(configs: MakeRequestHookConfig<ZoomEndMeetingParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ZoomEndMeetingParams,
    APIResponse<string>,
    typeof zoomService.endMeeting,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof zoomService.endMeeting>) => (
      zoomService.endMeeting(...args)
    ),
    ResponseClassCreator: createZoomEndMeetingObject,
    typeName: 'useZoomEndMeeting',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useZoomEndMeeting.mock = () => new Error('Mock has not been implemented!');
useZoomEndMeeting.getLastMockedValue = () => new Error('Mock has not been implemented!');
useZoomEndMeeting.createObject = createZoomEndMeetingObject;

export const createZoomNotifyCareTeamObject = (value: APIResponse<string>) => new APIResponseClass<string>(value, (v) => (v));

export const useZoomNotifyCareTeam = <RCN = undefined>(configs: MakeRequestHookConfig<ZoomNotifyCareTeamParams, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    ZoomNotifyCareTeamParams,
    APIResponse<string>,
    typeof zoomService.notifyCareTeam,
    AjaxOptions,
    APIResponseClass<string>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof zoomService.notifyCareTeam>) => (
      zoomService.notifyCareTeam(...args)
    ),
    ResponseClassCreator: createZoomNotifyCareTeamObject,
    typeName: 'useZoomNotifyCareTeam',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

useZoomNotifyCareTeam.mock = () => new Error('Mock has not been implemented!');
useZoomNotifyCareTeam.getLastMockedValue = () => new Error('Mock has not been implemented!');
useZoomNotifyCareTeam.createObject = createZoomNotifyCareTeamObject;
