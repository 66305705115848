import { TabsProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { MixpanelEvents, MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { useMpTrackingHelper } from '../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import { PatientService } from '../../../../services/PatientService';
import { LifestyleContainer } from '../../../intervention/container/LifestyleContainer/LifestyleContainer';
import { NutritionContainer } from '../../../intervention/container/NutritionContainer/NutritionContainer';
import { BasicMeasuresComponent } from '../../../patient/component/BasicMeasuresComponent/BasicMeasuresComponent';
import { LifestyleAssessmentTabKey } from '../../types';
import { WorklistContentComponent } from '../WorklistContentComponent/WorklistContentComponent';
import { WorklistSocialSupportComponent } from '../WorklistSocialSupportComponent/WorklistSocialSupportComponent';
import { WorklistTabProps } from '../WorklistTabsComponent/type';
import { WorklistTabsComponent } from '../WorklistTabsComponent/WorklistTabsComponent';

export interface LifestyleAssessmentComponentProps
  extends WorklistTabProps<LifestyleAssessmentTabKey> {
  patientId: string;
  patientInfoService: PatientService;
  onSubmit?: () => void;
}

export const LifestyleAssessmentComponent = ({
  patientId,
  patientInfoService,
  onSubmit,
  defaultActiveKey,
}: LifestyleAssessmentComponentProps) => {
  const [
    currentActiveTab,
    setCurrentActiveTab
  ] = useState(defaultActiveKey || LifestyleAssessmentTabKey.Lifestyle);
  const debouncedOnSubmit = useDebounce(() => onSubmit?.(), 500, [onSubmit]);
  const items = useMemo(() => ([
    {
      key: LifestyleAssessmentTabKey.Lifestyle,
      label: 'Lifestyle',
      children: (
        <div className="flex fd-c gap3">
          <NestedFormComponent>
            <BasicMeasuresComponent
              patientInfoService={patientInfoService}
            />
            <WorklistContentComponent>
              <LifestyleContainer
                patientId={patientId}
                title=""
                isEditing
                showEdit={false}
                formButtons={null}
              />
            </WorklistContentComponent>
          </NestedFormComponent>
          <NestedFormComponent>
            <WorklistSocialSupportComponent
              patientId={patientId}
              patientInfoService={patientInfoService}
              onSubmit={debouncedOnSubmit}
            />
          </NestedFormComponent>
        </div>
      )
    },
    {
      key: LifestyleAssessmentTabKey.NutritionIntake,
      label: 'Nutrition Intake',
      forceRender: false,
      children: (
        <NestedFormComponent>
          <BasicMeasuresComponent
            patientInfoService={patientInfoService}
          />
          <WorklistContentComponent>
            <NutritionContainer
              patientId={patientId}
              title=""
              isEditing
              showEdit={false}
              formButtons={null}
            />
          </WorklistContentComponent>
        </NestedFormComponent>
      ),
    }
  ]), [patientId]);

  const {
    startEvent: startNutritionTracking,
    endEvent: endNutritionTracking
  } = useMpTrackingHelper({
    eventStart: MixpanelEvents.NutritionStart,
    eventEnd: MixpanelEvents.NutritionEnd,
    parent: MixpanelEventsParents.Transcribing,
    patientId,
  });

  const {
    startEvent: startLifeStyleTracking,
    endEvent: endLifeStyleTracking
  } = useMpTrackingHelper({
    eventStart: MixpanelEvents.LifeStyleAssessmentStart,
    eventEnd: MixpanelEvents.LifeStyleAssessmentEnd,
    parent: MixpanelEventsParents.Transcribing,
    patientId
  });

  useEffect(() => () => {
    if (currentActiveTab === LifestyleAssessmentTabKey.NutritionIntake) {
      endNutritionTracking();
    } else {
      endLifeStyleTracking();
    }
  }, [currentActiveTab]);

  const onTabChange: TabsProps['onChange'] = (key) => {
    if (key === LifestyleAssessmentTabKey.NutritionIntake) {
      startNutritionTracking();
    } else {
      startLifeStyleTracking();
    }
    setCurrentActiveTab(key as LifestyleAssessmentTabKey);
  };

  useEffect(() => {
    if (currentActiveTab === LifestyleAssessmentTabKey.Lifestyle) {
      startLifeStyleTracking();
    }
  }, []);

  return (
    <WorklistTabsComponent
      items={items}
      defaultActiveKey={currentActiveTab}
      onChange={onTabChange}
    />
  );
};
