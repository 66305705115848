import { Form } from 'antd';
import { CGMDeviceBrandSelectorComponent } from '../CGMDeviceBrandSelectorComponent/CGMDeviceBrandSelectorComponent';
import { CGMDeviceModelSelectorComponent } from '../CGMDeviceModelSelectorComponent/CGMDeviceModelSelectorComponent';
import { CGMFormSubmitValue, useCGMDeviceForm } from './useCGMDeviceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { CgmBrandEnum, CgmModelEnum, CgmSupportedDevice } from '../../../../uc-api-sdk';
import { CGMDeviceAccountLinkingContainer } from '../../container/CGMDeviceAccountLinkingContainer/CGMDeviceAccountLinkingContainer';

import './CGMDeviceFormItemComponent.scss';

export interface CGMDeviceFormItemComponentProps {
  patientId: string;
  deviceData: CgmSupportedDevice[];
  onValuesChange?: (changedValues:
    Partial<CGMFormSubmitValue>,
    allValues: CGMFormSubmitValue
  ) => void;
}

export const CGMDeviceFormItemComponent = ({
  patientId,
  deviceData,
  onValuesChange,
}: CGMDeviceFormItemComponentProps) => {
  const form = Form.useFormInstance();
  const cgmDeviceForm = useCGMDeviceForm({ form });

  const handleValuesChange = (
    changed: Partial<CGMFormSubmitValue>,
  ) => {
    cgmDeviceForm.form.getFieldsValue();
    onValuesChange?.(changed, cgmDeviceForm.form.getFieldsValue());
  };

  const handleBrandChange = (brand?: CgmBrandEnum) => {
    cgmDeviceForm.form.setFieldValue(
      cgmDeviceForm.getName('model'),
      null
    );
    handleValuesChange({ brand, model: null });
  };

  return (
    <div className="cgm-device-form-item">
      <FormItem
        info={cgmDeviceForm.getInfo('brand')}
        required
      >
        <CGMDeviceBrandSelectorComponent
          data={deviceData}
          onChange={handleBrandChange}
        />
      </FormItem>
      <FormItem
        noStyle
        shouldUpdate={cgmDeviceForm.shouldUpdate(['brand'])}
      >
        {
          ({ getFieldValue }) => {
            const show = cgmDeviceForm.shouldShowModel(getFieldValue);
            const brand = cgmDeviceForm.getValue('brand', getFieldValue);
            if (!show) return null;
            return (
              <FormItem
                info={cgmDeviceForm.getInfo('model')}
              >
                <CGMDeviceModelSelectorComponent
                  brand={brand}
                  data={deviceData}
                  onChange={(model) => handleValuesChange({ model })}
                />
              </FormItem>
            );
          }
        }
      </FormItem>
      <FormItem
        noStyle
        shouldUpdate={cgmDeviceForm.shouldUpdate(['brand', 'model'])}
      >
        {
          ({ getFieldValue }) => {
            const show = cgmDeviceForm.shouldShowLinkingStatus(getFieldValue);
            if (!show) return null;
            const brand = cgmDeviceForm.getValue('brand', getFieldValue) as CgmBrandEnum;
            const model = cgmDeviceForm.getValue('model', getFieldValue) as CgmModelEnum;
            return (
              <FormItem
                label="Account Linking"
              >
                <CGMDeviceAccountLinkingContainer
                  patientId={patientId}
                  brand={brand}
                  model={model}
                />
              </FormItem>
            );
          }
        }
      </FormItem>
    </div>
  );
};
