import { ColumnType } from 'antd/lib/table';
import { BatchConsentResult } from '../../../../uc-api-sdk';
import { ClinicNameDisplayComponent } from '../../../../uiComponent/DisplayComponent/ClinicDisplayComponent/ClinicNameDisplayComponent';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { createClinicFilter } from '../../../table/filter/ClinicFilter';
import { DownloadCsvButtonContainer } from '../../container/DownloadCsvButtonContainer/DownloadCsvButtonContainer';

export const createBatchSentConsentColumns = () => ({
  time: (): ColumnType<BatchConsentResult> => ({
    title: 'Sent time and date',
    dataIndex: 'sentAt',
    key: 'sentAt',
    width: '30%',
    render: (time: string) => <DisplayDateComponent value={time} format="CONSENT_FORM_SENT_DATE" useTimezoneService />,
  }),
  medicalOrg: (): ColumnType<BatchConsentResult> => ({
    title: 'Medical Org',
    dataIndex: 'medicalOrg',
    key: 'medicalOrg',
    width: '30%',
    ...createClinicFilter('multiple', 'batchSentConsentHistoryTableOrgFilterContainer'),
    render: (v, rec) => (
      <ClinicNameDisplayComponent
        clinic={rec.medicalOrg || undefined}
      />
    ),
  }),
  output: (): ColumnType<BatchConsentResult> => ({
    title: 'Sign status download',
    width: '30%',
    dataIndex: 'ptDownloadFileOutput',
    key: 'ptDownloadFileOutput',
    render: (v, rec) => (
      <DownloadCsvButtonContainer fileInfo={rec.ptDownloadFileOutput || undefined} />
    ),
  }),
});
