import { Space } from 'antd';
import { useState } from 'react';
import { useBoolean } from 'usehooks-ts';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents, MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { PatientInfo, usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { useSingleEditModeHook } from '../../../../hooks/useSingleEditModeHook/useSingleEditModeHook';
import { AnchorComponent } from '../../../../uiComponent/AnchorComponent/AnchorComponent';
import { AnchorLinkComponent } from '../../../../uiComponent/AnchorComponent/AnchorLinkComponent';
import { FoodLogComponent } from '../../../foodLog/component/FoodLogComponent/FoodLogComponent';
import { PatientCommonCardTitleComponent } from '../../../patient/component/PatientCommonCardTitleComponent/PatientCommonCardTitleComponent';
import { BehaviorChangeContainer } from '../../container/BehaviorChangeContainer/BehaviorChangeContainer';
import { BehaviorGoalContainer } from '../../container/BehaviorGoalContainer/BehaviorGoalContainer';
import { ClinicalGoalContainer } from '../../container/ClinicalGoalContainer/ClinicalGoalContainer';
import { InterventionVitalsMonitoringContainer } from '../../container/InterventionVitalsMonitoringContainer/InterventionVitalsMonitoringContainer';
import { LifestyleContainer } from '../../container/LifestyleContainer/LifestyleContainer';
import { NutritionContainer } from '../../container/NutritionContainer/NutritionContainer';
import { VersionHistorySelectContainer, VersionHistorySelectContainerProps } from '../../container/VersionHistorySelectContainer/VersionHistorySelectContainer';
import { InterventionEducationComponent } from '../InterventionEducationComponent/InterventionEducationComponent';
import { VersionValue } from '../VersionHistorySelectComponent/VersionHistorySelectComponent';

export const InterventionTabAnchors = {
  goals: {
    label: 'Goals',
    href: '#goals',
  },
  lifestyle: {
    label: 'Lifestyle',
    href: '#lifestyle',
  },
  nutrition: {
    label: 'Nutrition',
    href: '#nutrition',
  },
  education: {
    label: 'Education',
    href: '#education',
  },
  foodLog: {
    label: 'Food Diary',
    href: '#foodLog',
  },
};

export interface InterventionComponentProps {

}

enum InterventionEnumType {
  Template = 'Template',
  ClinicGoals = 'ClinicGoals',
  LifeStyle = 'LifeStyle',
  VitalsMonitoringGoal = 'VitalsMonitoringGoal',
  Nutrition = 'Nutrition',
  BehaviorGoals = 'BehaviorGoals',
  BehaviorChange = 'BehaviorChange',
}

export const InterventionComponent = () => {
  const { info } = usePatientContext();
  const { id = '' } = info || {};
  const { value: showEdit, setValue: setShowEdit } = useBoolean(true);
  const [versionValue, setVersion] = useState<VersionValue>();
  const version = versionValue?.version;
  const { isEditing, handleEdit, closeEditMode } = useSingleEditModeHook<InterventionEnumType>();
  const eventCreator = useUpdate('INTERVENTION_UPDATED');
  const { send: sendMixpanel } = useMixpanelContext();

  const handleVersionChange: VersionHistorySelectContainerProps['onChange'] = (v) => {
    setVersion(v);
    setShowEdit(!!v?.isLatest);
  };

  const handleSubmit = () => {
    eventCreator.updateValue();
    closeEditMode();
  };

  const handleEditMode = (type: InterventionEnumType) => () => {
    const getMixpanelEvent = () => {
      switch (type) {
        case InterventionEnumType.ClinicGoals:
          return MixpanelEvents.InterventionClinicalGoalEditIcon;
        case InterventionEnumType.VitalsMonitoringGoal:
          return MixpanelEvents.InterventionVitalsMonitoringGoalEditIcon;
        case InterventionEnumType.BehaviorGoals:
          return MixpanelEvents.InterventionBehaviorGoalEditIcon;
        case InterventionEnumType.BehaviorChange:
          return MixpanelEvents.InterventionStageOfBehaviorChangeEditIcon;
        case InterventionEnumType.LifeStyle:
          return MixpanelEvents.InterventionLifestyleEditIcon;
        case InterventionEnumType.Nutrition:
          return MixpanelEvents.InterventionNutritionEditIcon;
        default:
          return MixpanelEvents.Unknown;
      }
    };

    sendMixpanel({
      event: getMixpanelEvent(),
      patientId: id,
    });
    handleEdit(type)();
  };

  return (
    <AnchorComponent targetOffset={32} hideAnchorLinks>
      <div className="flex w100 jc-e">
        {/* this div is necessary otherwise the fetch component
            inside Version history will take the entire space
            and this component will render on the left side */}
        <div>
          <VersionHistorySelectContainer
            patientId={id}
            value={versionValue}
            onChange={handleVersionChange}
          />
        </div>
      </div>
      <div className="flex fd-c gap3 w100">
        <AnchorLinkComponent
          id="goals"
          href={InterventionTabAnchors.goals.href}
          title={InterventionTabAnchors.goals.label}
        >
          <PatientCommonCardTitleComponent className="mb10">
            Goals
          </PatientCommonCardTitleComponent>
          <Space size="middle" direction="vertical" className="w100">
            <ClinicalGoalContainer
              patientId={id}
              version={version}
              showEdit={showEdit}
              onEdit={handleEditMode(InterventionEnumType.ClinicGoals)}
              isEditing={isEditing(InterventionEnumType.ClinicGoals)}
              onCancel={closeEditMode}
              onSubmit={handleSubmit}
            />
            <InterventionVitalsMonitoringContainer
              patientId={id}
              showEdit={showEdit}
              isEditing={isEditing(InterventionEnumType.VitalsMonitoringGoal)}
              onEdit={handleEditMode(InterventionEnumType.VitalsMonitoringGoal)}
              onCancel={closeEditMode}
              onSubmit={handleSubmit}
            />
            <BehaviorChangeContainer
              version={version}
              showEdit={showEdit}
              onCancel={closeEditMode}
              onSubmit={handleSubmit}
              info={info || {} as PatientInfo}
              isEditing={isEditing(InterventionEnumType.BehaviorChange)}
              onEdit={handleEditMode(InterventionEnumType.BehaviorChange)}
            />
            <BehaviorGoalContainer
              patientId={id}
              version={version}
              showEdit={showEdit}
              onEdit={handleEditMode(InterventionEnumType.BehaviorGoals)}
              isEditing={isEditing(InterventionEnumType.BehaviorGoals)}
              onCancel={closeEditMode}
              onSubmit={handleSubmit}
            />
          </Space>
        </AnchorLinkComponent>
        <AnchorLinkComponent
          title={InterventionTabAnchors.lifestyle.label}
          id="lifestyle"
          href={InterventionTabAnchors.lifestyle.href}
        >
          <LifestyleContainer
            patientId={id}
            version={version}
            showEdit={showEdit}
            onEdit={handleEditMode(InterventionEnumType.LifeStyle)}
            isEditing={isEditing(InterventionEnumType.LifeStyle)}
            onCancel={closeEditMode}
            onSubmit={handleSubmit}
            parent={MixpanelEventsParents.PatientProfile}
          />
        </AnchorLinkComponent>
        <AnchorLinkComponent
          id="nutrition"
          href={InterventionTabAnchors.nutrition.href}
          title={InterventionTabAnchors.nutrition.label}
        >
          <NutritionContainer
            patientId={id}
            version={version}
            showEdit={showEdit}
            onEdit={handleEditMode(InterventionEnumType.Nutrition)}
            isEditing={isEditing(InterventionEnumType.Nutrition)}
            onCancel={closeEditMode}
            onSubmit={handleSubmit}
            parent={MixpanelEventsParents.PatientProfile}
          />
        </AnchorLinkComponent>
        <AnchorLinkComponent
          id="education"
          title={InterventionTabAnchors.education.label}
          href={InterventionTabAnchors.education.href}
        >
          <InterventionEducationComponent />
        </AnchorLinkComponent>
        <AnchorLinkComponent
          title={InterventionTabAnchors.foodLog.label}
          id="foodLog"
          href={InterventionTabAnchors.foodLog.href}
        >
          <FoodLogComponent patientId={id} />
        </AnchorLinkComponent>
      </div>
    </AnchorComponent>
  );
};
