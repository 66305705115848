import { BucketsTypeEnum, ImageTypeEnum, useFileManagerDownloadUrl } from '../../uc-api-sdk';

export interface ImageProps {
  fileKey: string;
  bucket: BucketsTypeEnum;
  imageType?: ImageTypeEnum;
  cache?: boolean;
}

export const useImage = ({
  fileKey,
  bucket,
  imageType,
  cache = true
}: ImageProps) => {
  const fileDownloadInfo = useFileManagerDownloadUrl({
    params: {
      fileDownloadUrlInput: {
        bucket,
        fileKey,
        imageType,
      },
    },
    options: {
      sendOnMount: !!fileKey,
      retry: 1,
      cache,
    },
  });

  return fileDownloadInfo;
};

export const usePrivateImage = (fileKey: string) => {
  const request = useImage({
    fileKey,
    bucket: BucketsTypeEnum.PRIVATEIMAGEUPLOAD,
  });
  return request;
};

export const useUploadedImage = (fileKey: string) => {
  const request = useImage({
    fileKey,
    bucket: BucketsTypeEnum.FILESUPLOAD,
  });
  return request;
};
