import { startCase } from 'lodash';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface CGMDeviceModelEnumComponentProps {
  value?: string | null;
}

export const CGMDeviceModelEnumComponent = ({
  value,
}: CGMDeviceModelEnumComponentProps) => {
  const getText = () => {
    if (!value) return value;
    if (value.startsWith('G')) {
      return value;
    }
    return startCase(value.toLowerCase());
  };

  return (<DisplayOrEmptyComponent value={getText()} />);
};
