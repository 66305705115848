import { get } from 'lodash';
import { ReactNode } from 'react';
import { Button, ButtonProps, Checkbox } from 'antd';
import { InputType } from '../../../Input/types';
import {
  AssignDeviceInfo,
  DeviceModelEnum,
  DeviceModelInfo,
  DeviceTypeEnum,
} from '../../../../uc-api-sdk';
import './DeviceCheckboxComponent.scss';
import { DeviceModelEnumComponent } from '../../../../enumComponent/DeviceModelEnumComponent/DeviceModelEnumComponent';
import { DeviceTypeEnumComponent } from '../../../../enumComponent/DeviceTypeEnumComponent/DeviceTypeEnumComponent';
import { DeviceHelper } from '../../../device/helper';
import { useSetupGuideContext } from '../../../../contexts/SetupGuideContext/SetupGuideContext';
import { DeviceImageComponent } from '../../../device/DeviceImageComponent';

export interface DeviceCheckboxComponentProps extends
  Partial<InputType<AssignDeviceInfo>> {
  model: DeviceModelEnum;
  type: DeviceTypeEnum | null;
  title?: ReactNode;
  name?: ReactNode;
  extra?: ReactNode;
}

export const DeviceCheckboxComponent = ({
  model,
  type,
  title = (<DeviceTypeEnumComponent value={type} />),
  name = (<DeviceModelEnumComponent value={model} />),
  value,
  onChange,
  extra,
  disabled,
}: DeviceCheckboxComponentProps) => {
  const { data, loading } = useSetupGuideContext();
  const guide: DeviceModelInfo | undefined = get(data, model);

  const handleSetupGuide: ButtonProps['onClick'] = async (evt) => {
    evt.stopPropagation();
    if (guide?.quickStartGuideUrl) {
      window.open(guide.quickStartGuideUrl, '_blank');
    }
  };

  const handleClick: ButtonProps['onClick'] = (evt) => {
    evt.stopPropagation();
    if (!value) {
      onChange?.({
        deviceType: type,
        deviceModel: model,
        deviceMethod: DeviceHelper.getMethodByModel(model),
      });
    } else {
      onChange?.(undefined);
    }
  };

  return (
    <div className="device-checkbox-component">
      <Checkbox
        key={model}
        value={model}
        checked={!!value}
        onClick={handleClick}
        className="w100"
        disabled={disabled}
      >
        <div className="flex ai-s">
          <div className="first-section">
            <div className="flex jc-s">
              {title}
            </div>
            {guide?.quickStartGuideUrl && model !== DeviceModelEnum.BP3L && (
              <div>
                <Button
                  type="link"
                  className="pl0"
                  onClick={handleSetupGuide}
                  disabled={disabled}
                  loading={loading}
                >
                  Setup Guide
                </Button>
              </div>
            )}
          </div>
          <DeviceImageComponent
            className="device-img"
            deviceModel={model}
          />
          <div className="device-info">
            <h3>{name}</h3>
            <div>{extra}</div>
          </div>
        </div>

      </Checkbox>
    </div>
  );
};
