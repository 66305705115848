import { Button, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import { MONTH_DAY_YEAR } from '../../../../constants/timeFormat';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { Nullable, ResultSourceEnum, useMeasurementSoftDeleteMeasurement } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import ModalButton from '../../../../uiComponent/ModalButton/ModalButton';
import { HSDeleteCFormValues, HSDeleteConfirmModalComponent } from '../../component/HSDeleteConfirmModalComponent/HSDeleteConfirmModalComponent';
import { createHSTableColumns, HSTableRowValue } from '../../component/HSTableComponent/columns';

export interface HSDeleteModalContainerProps {
  data?: HSTableRowValue;
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
}

export const HSDeleteModalContainer = ({
  data,
  isOpen,
  onClose,
  onSubmit,
}: HSDeleteModalContainerProps) => {
  const { info } = usePatientContext();
  const { timezone } = info?.patientInfo?.clinic || {};

  const deleteMeasurement = useMeasurementSoftDeleteMeasurement({});
  const updatePatientInfo = useUpdate('PATIENT_INFO_UPDATED');

  const handleDelete = useDebounce(async (v: HSDeleteCFormValues) => {
    const req = deleteMeasurement.send({
      params: {
        request: {
          id: data?.measurementId,
          deleteReason: v.deleteReason
        }
      }
    });
    await ApiRequestHelper.tryCatch(
      req,
      {
        success: 'Measurement deleted successfully.',
        error: 'An error occurred while deleting the measurement. Please try again later.',
        onError: () => {
          onClose?.();
        },
        onSuccess: () => {
          onSubmit?.();
          updatePatientInfo.updateValue();
        }
      }
    );
  }, 300, [data?.measurementId, deleteMeasurement]);

  const columns = useTableColumns(
    createHSTableColumns,
    ['date', 'weight', 'bmi', 'weightChange'],
  );

  const getSourceString = (source: Nullable<ResultSourceEnum> | undefined) => {
    if (
      [
        ResultSourceEnum.MEASUREMENT,
        ResultSourceEnum.MANUALLY_INPUT
      ].includes(source as ResultSourceEnum)
    ) {
      return 'Patient\'s Device';
    }
    return 'Care Team\'s';
  };

  return (
    <ModalButton
      open={isOpen}
      onCancel={onClose}
      title={TimezoneService.calcDateTime(data?.date || '', timezone || '').format(MONTH_DAY_YEAR)}
    >
      <div>
        <Table
          columns={columns}
          dataSource={[data]}
          pagination={false}
          size="small"
          bordered
        />
        <div className="text-gray-scale-2 fs12">{`Local Time of ${getSourceString(data?.source)} Upload (${data?.timezone || ''})`}</div>
        <Title level={5} className="fs15 mt20">Patient Note</Title>
        <div className="text-gray-scale-2 fs15"><DisplayOrEmptyComponent value={data?.notes} /></div>
        <div className="mt20 flex gap2">
          <Button onClick={onClose}>Close</Button>
          <HSDeleteConfirmModalComponent
            onSubmit={handleDelete}
            isLoading={deleteMeasurement.isLoading}
          />
        </div>
      </div>
    </ModalButton>
  );
};
