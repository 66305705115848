import classNames from 'classnames';
import { useMemo } from 'react';
import {
  BG1_IMG,
  BIOLAND_IMG,
  BPM_CLEAR_IMG,
  EASE_IMG,
  GLUCO_IMG, HOTSPOT_IMG, HS2S_IMG,
  HS2_IMG, LOANER_PHONE_IMG, PO3_IMG,
  PYLO_IMG,
  TS28B_IMG,
  WIFI_ROUTER_IMG,
  CGM_IMG,
} from '../../assets/deviceIconInfo';
import { AlternativeDeviveType, DeviceModelEnum } from '../../uc-api-sdk';
import { DeviceNameComponentProps } from '../../uiComponent/DeviceNameComponent/DeviceNameComponent';
import { SupportDeviceEnum } from './type';

export interface DeviceImageComponentProps {
  deviceModel?: DeviceNameComponentProps['device'];
  width?: string | number;
  height?: string | number;
  className?: string;
}

export const DeviceImageComponent = ({
  deviceModel,
  width = 68,
  height,
  className = '',
}: DeviceImageComponentProps) => {
  const info = useMemo(() => {
    switch (deviceModel) {
      case DeviceModelEnum.PY_802_LTE:
        return PYLO_IMG;
      case DeviceModelEnum.BIOLAND:
        return BIOLAND_IMG;
      case DeviceModelEnum.BPM1:
      case DeviceModelEnum.BPM1_HOTSPOT:
        return BPM_CLEAR_IMG;
      case DeviceModelEnum.BP3L:
        return EASE_IMG;
      case DeviceModelEnum.BG1:
        return BG1_IMG;
      case DeviceModelEnum.BG5S:
        return GLUCO_IMG;
      case DeviceModelEnum.PO3:
        return PO3_IMG;
      case DeviceModelEnum.HS2:
        return HS2_IMG;
      case DeviceModelEnum.HS2S:
        return HS2S_IMG;
      case DeviceModelEnum.TS28B:
        return TS28B_IMG;
      case DeviceModelEnum.LOAN_DEVICE:
        return LOANER_PHONE_IMG;
      case DeviceModelEnum.CGM:
        return CGM_IMG;
      case SupportDeviceEnum.WIFI_ROUTER:
        return WIFI_ROUTER_IMG;
      case SupportDeviceEnum.HOTSPOT:
        return HOTSPOT_IMG;
      case AlternativeDeviveType.BP_CELLULAR_PYLO:
        return PYLO_IMG;
      case AlternativeDeviveType.BP_CLEAR:
        return BPM_CLEAR_IMG;
      case AlternativeDeviveType.LOANER_PHONE:
        return LOANER_PHONE_IMG;
      case AlternativeDeviveType.WIFI_ROUTER:
        return WIFI_ROUTER_IMG;
      case AlternativeDeviveType.HOTSPOT:
        return HOTSPOT_IMG;
      default:
        return { src: '', alt: deviceModel };
    }
  }, [deviceModel]);

  return (
    <img
      src={info.src}
      alt={info.alt}
      width={width}
      style={{ maxHeight: height }}
      className={classNames({
        'px0 blend-dark': true,
        [className]: !!className,
      })}
    />
  );
};
