import { useMemo } from 'react';
import {
  APIResponse,
  TranscribeRequest,
  transcribingJobService,
  APIResponseClass,
  TranscribeRequestClass,
  createTranscribingJobTranscribeObject,
  TranscribeResourceType,
} from '../../../uc-api-sdk';
import {
  MakeRequestHookConfig,
  AjaxOptions,
  UseRequestFactory,
  MethodTypeEnum,
  useRequestFactory
} from '../../../uc-api-sdk/staticFiles/useReqHook';

export type TranscribingJobTranscribeParamsByResourceType = {
  jobId?: string;
  patientId: string;
} & ({
  resourceType: TranscribeResourceType.LAB_RESULT;
  s3FileKeys: string[];
} | {
  resourceType: TranscribeResourceType.MEDICATION;
  textInput: string;
});

interface TranscribingJobTranscribeParams {
  transcriptionRequest: TranscribingJobTranscribeParamsByResourceType
}

export const useManualTranscribingJobTranscribe = <RCN = undefined>(
  configs: MakeRequestHookConfig<TranscribingJobTranscribeParams, AjaxOptions, RCN>
) => {
  const baseConfigs: UseRequestFactory<
    TranscribingJobTranscribeParams,
    APIResponse<TranscribeRequest>,
    typeof transcribingJobService.transcribe,
    AjaxOptions,
    APIResponseClass<TranscribeRequestClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof transcribingJobService.transcribe>) => (
      transcribingJobService.transcribe(...args)
    ),
    ResponseClassCreator: createTranscribingJobTranscribeObject,
    typeName: 'useTranscribingJobTranscribe',
    type: MethodTypeEnum.POST,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};
