import { CgmBrandEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface CGMDeviceBrandEnumComponentProps {
  value?: CgmBrandEnum | null;
}

export const CGMDeviceBrandEnumComponent = ({
  value,
}: CGMDeviceBrandEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case CgmBrandEnum.DEXCOM:
        return 'Dexcom';
      case CgmBrandEnum.FREESTYLELIBRE:
        return 'Freestyle';
      default:
        return undefined;
    }
  };
  return (<DisplayOrEmptyComponent value={getText()} />);
};
