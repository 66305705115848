import {
  every,
  find,
  forEach,
  get,
  intersection,
  isEqual,
  map,
  omit,
  some
} from 'lodash';
import {
  EnrolledProgramStatusEnum,
  EnrollService,
  MonitoringVital,
  PatientEnrolledProgram,
  PatientHiatusStatusEnum,
  ProgramCategoryEnum,
  ServiceCategoryEnum,
  UCTask,
  VitalEnumType
} from '../uc-api-sdk';
import { BGTaskService } from './TaskService/BGTask';
import { TaskService } from './TaskService/TaskService';
import TimezoneService from '../helpers/timezone/timezoneService';
import { USA_DATE } from '../constants/timeFormat';
import { vitalsMonitoringOrder } from '../lib/constants/vitalsMonitoringOrder';
import { orderByArray } from '../helpers/order/orderByArray';

export class EnrolledProgramService {
  public enrolledProgram?: PatientEnrolledProgram;

  public vitalScheduleFields = [
    'repeat',
    'target',
    'templateDetails.name',
    'templateDetails.description',
    'templateDetails.schedule',
  ];

  constructor(enrolledProgram?: PatientEnrolledProgram) {
    this.enrolledProgram = enrolledProgram;
  }

  public get id() {
    return this.enrolledProgram?.id;
  }

  public isEnrolled() {
    return this.enrolledProgram?.enrolledStatus === EnrolledProgramStatusEnum.ENROLLED;
  }

  public getEnrollmentDate() {
    const enrollmentDate = this.enrolledProgram?.enrollmentDate;
    return enrollmentDate
      ? TimezoneService.calcDateTimeDayjs(enrollmentDate || '', undefined).format(USA_DATE)
      : '';
  }

  public isDischarged() {
    return this.enrolledProgram?.enrolledStatus === EnrolledProgramStatusEnum.DISCHARGED;
  }

  public getMonitoringVitals() {
    return this.enrolledProgram?.vitals || [];
  }

  public getMonitoringVitalsInOrder() {
    return orderByArray(
      this.getMonitoringVitals(),
      vitalsMonitoringOrder,
      'type'
    );
  }

  public getVitals() {
    return (this.enrolledProgram?.vitals?.map((v) => v.type)
      || []) as VitalEnumType[];
  }

  public getVitalTask = (vitalType: VitalEnumType) => {
    const task = this.enrolledProgram?.vitals?.find((v) => v.type === vitalType);
    if (task) {
      if (vitalType === VitalEnumType.BG) {
        return new BGTaskService(task as UCTask);
      }
      return new TaskService(task as UCTask);
    }
    return task;
  };

  public hasVital(vital: VitalEnumType) {
    return this.getVitals().includes(vital);
  }

  public getPrograms() {
    return (this.enrolledProgram?.programs?.map((v) => v.category)
      || []) as ProgramCategoryEnum[];
  }

  public getProgramEnrollments() {
    return this.enrolledProgram?.programs || [];
  }

  public hasProgram(program: ProgramCategoryEnum) {
    return this.getPrograms().includes(program);
  }

  public getServices() {
    return this.enrolledProgram?.services || [] as EnrollService[];
  }

  public getServiceCategories() {
    return map(this.getServices(), (s) => s.category) as ServiceCategoryEnum[];
  }

  public hasService(service: ServiceCategoryEnum) {
    return this.getServiceCategories().includes(service);
  }

  public getHiatusDetail() {
    return this.enrolledProgram?.hiatusDetail;
  }

  public isHiatusStatusPaused() {
    if (!this || !this.getHiatusDetail()) {
      return false;
    }
    const hiatusDetail = this.getHiatusDetail();
    return hiatusDetail?.status
    && hiatusDetail?.status !== PatientHiatusStatusEnum.RESUMED;
  }

  public isVitalMonitoringPaused() {
    const hiatusDetail = this.getHiatusDetail();
    return hiatusDetail?.vitalMonitoring?.status
    && hiatusDetail?.vitalMonitoring?.status !== PatientHiatusStatusEnum.RESUMED;
  }

  public isVisitServicePaused() {
    const hiatusDetail = this.getHiatusDetail();
    return hiatusDetail?.visitService?.status
    && hiatusDetail?.visitService?.status !== PatientHiatusStatusEnum.RESUMED;
  }

  public isBillingPaused() {
    const hiatusDetail = this.getHiatusDetail();
    return hiatusDetail?.billingReport?.status
    && hiatusDetail?.billingReport?.status !== PatientHiatusStatusEnum.RESUMED;
  }

  public isPatientUnableToEngage() {
    return this.enrolledProgram?.unableToEngage?.hiatusUnableToEngage;
  }

  public getEngagementDetail() {
    return {
      hiatusUnableToEngage: this.enrolledProgram?.unableToEngage?.hiatusUnableToEngage,
      unableToEngageReason: this.enrolledProgram?.unableToEngage?.unableToEngageReason,
      unableToEngageUpdateTime: this.enrolledProgram?.unableToEngage?.unableToEngageUpdateTime
    };
  }

  public getDischargedDetail() {
    return this.enrolledProgram?.dischargedDetail;
  }

  public getDischargedAtDayjs() {
    const { dischargedAt } = this.getDischargedDetail() || {};
    return dischargedAt
      ? TimezoneService.calcDateTimeDayjs(dischargedAt, undefined)
      : undefined;
  }

  public getStatus() {
    return this.enrolledProgram?.enrolledStatus;
  }

  public isEditPrograms = (newPrograms?: ProgramCategoryEnum[]) => {
    if (!newPrograms) {
      return false;
    }
    return (
      this.getPrograms().length !== newPrograms.length
      || (
        intersection(
          this.getPrograms(),
          newPrograms
        ).length !== newPrograms.length
      )
    );
  };

  public isVitalSchedulesChanged = (newVitals?: MonitoringVital[]) => {
    if (!newVitals) {
      return false;
    }

    const hasScheduleSet = (v: MonitoringVital) => (
      some(this.vitalScheduleFields, (f) => !!get(v, f))
    );

    const currentVitals = this.getMonitoringVitals();

    if (
      currentVitals.length < newVitals.length
      && every(newVitals, hasScheduleSet)
    ) {
      // new vitals are added with schedule
      return true;
    }

    let hasChanged = false;
    forEach(currentVitals, (currentV) => {
      const newVital = find(newVitals, { type: currentV.type });
      if (!newVital) {
        // vital is removed; skip
        return;
      }
      hasChanged = hasChanged || (
        some(this.vitalScheduleFields, (f) => {
          let currentFV = get(currentV, f) || undefined;
          let newFV = get(newVital, f) || undefined;
          if (f === 'templateDetails.schedule') {
            currentFV = omit(currentFV, '_id');
            newFV = omit(newFV, '_id');
          }
          if (!isEqual(currentFV, newFV)) {
            return true;
          }
          return false;
        })
      );
    });

    return hasChanged;
  };
}
