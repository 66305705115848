import { useMemo } from 'react';
import {
  compact,
  last,
  map,
  without,
} from 'lodash';
import { Checkbox, Col, Row } from 'antd';
import { VitalEnumType } from '../../../../uc-api-sdk';
import { getBasicVitalsOptions } from '../../helper/VitalsOptions';
import { InputTypeArray } from '../../../Input/types';

export interface EnrollmentVitalsCheckboxComponentProps extends InputTypeArray<VitalEnumType> {
  className?: string;
  isCGMEligible?: boolean;
}

export const EnrollmentVitalsCheckboxComponent = ({
  value,
  onChange,
  className,
  isCGMEligible,
}: EnrollmentVitalsCheckboxComponentProps) => {
  const vitalsOptions = useMemo(() => {
    const vitals = [
      VitalEnumType.BG,
      isCGMEligible ? VitalEnumType.CGM : undefined,
      VitalEnumType.BP,
      VitalEnumType.HS,
      VitalEnumType.PO,
      VitalEnumType.TM,
    ];
    return getBasicVitalsOptions(compact(vitals));
  }, [isCGMEligible]);

  const handleOnChange = (vitals?: VitalEnumType[]) => {
    let newVitals = [...vitals || []];
    const isAdded = newVitals.length > (value?.length || 0);
    if (isAdded && last(newVitals) === VitalEnumType.CGM) {
      // if CGM is selected, then de-select BG
      newVitals = without(newVitals, VitalEnumType.BG);
    }
    onChange?.(newVitals);
  };

  return (
    <Checkbox.Group
      className={className}
      value={value}
      onChange={(values) => handleOnChange(values as VitalEnumType[])}
    >
      <Row gutter={[15, 10]}>
        {map(vitalsOptions, ({ label, value }) => (
          <Col span={8} key={value as string}>
            <Checkbox
              className="block"
              value={value}
            >
              {label}
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
};
