import { FC } from 'react';
import { useBGResultToTable } from '../../hook/useBGResultToTable/useBGResultToTable';
import { BGTableComponent } from './BGTableComponent';
import { MeasurementType } from '../../type/MeasurementTypes';
import { FromDateToDateDayjs } from '../../type';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { BGTooltipComponent } from '../TooltipComponent/BGTooltipComponent';

export interface BGTableDataProcessorComponentProps extends FromDateToDateDayjs {
  data: MeasurementType[];
}

export const BGTableDataProcessorComponent: FC<BGTableDataProcessorComponentProps> = ({
  data,
  fromDate,
  toDate,
}) => {
  const processedData = useBGResultToTable(data, fromDate, toDate);

  return (
    <>
      <TooltipComponent
        className="mb10 w-mc"
        title={<BGTooltipComponent />}
        placement="left"
        type="info-icon"
        color="white"
        overlayStyle={{ minWidth: '1185px' }}
      >
        <span>Log book</span>
      </TooltipComponent>
      <BGTableComponent data={processedData} />
    </>
  );
};
