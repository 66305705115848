import { Button, ButtonProps, message } from 'antd';
import { ReactNode } from 'react';
import { useLoggedInUserFromContext } from '../../contexts/loggedInUserContext';
import useDebounce from '../../hooks/useDebounce/useDebounce';
import { TimeTrackingEventEnum } from '../../uc-api-sdk';
import { useBillableEventTracker } from '../../features/billableTime/hook/useBillableEventTracker/useBillableEventTracker';
import { useMixpanelContext } from '../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../contexts/MixpanelContext/MixpanelEvents';

interface LogoutChildrenProps {
  onClick: () => void;
  isLoggingOut: boolean;
}

export interface LogoutComponentProps {
  onLogout?: () => void;
  onError?: (error?: unknown) => void;
  buttonType?: ButtonProps['type'];
  children?: ReactNode | ((props: LogoutChildrenProps) => JSX.Element);
}

export const LogoutComponent = ({
  onLogout,
  onError,
  buttonType,
  children,
}: LogoutComponentProps) => {
  const { send } = useMixpanelContext();
  const { sendTimeTracking } = useBillableEventTracker();
  const {
    logout,
    isLoggingOut,
  } = useLoggedInUserFromContext();

  const handleOnClick = useDebounce(async () => {
    try {
      sendTimeTracking({
        eventType: TimeTrackingEventEnum.LOGOUT,
        patientId: undefined,
        component: undefined,
      });
      send({
        event: MixpanelEvents.UserLogout,
      });
      logout();
      onLogout?.();
    } catch (error) {
      message.error(`Something went wrong. ${error}`);
      onError?.(error);
    }
  }, 250, [onLogout, sendTimeTracking], { leading: true });

  return (
    typeof children === 'function'
      ? children({
        onClick: handleOnClick,
        isLoggingOut: !!isLoggingOut
      })
      : (
        <Button
          type={buttonType}
          onClick={handleOnClick}
        >
          {children}
        </Button>
      )
  );
};
