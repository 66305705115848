import { useTableChange } from '../../../../hooks/useTableChange/useTableChange';
import {
  Measurement, MeasurementResultTypeEnum, SearchRequest, useMeasurementSearchDeletedMeasurement
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { HSDeletionLogTableComponent } from '../../component/HSDeletionLogTableComponent/HSDeletionLogTableComponent';

export interface HSDeletionLogTableContainerProps {
  memberId: string;
}

export const HSDeletionLogTableContainer = ({
  memberId
}: HSDeletionLogTableContainerProps) => {
  const tableChange = useTableChange<Measurement, SearchRequest<Measurement>>({
    pageSize: 10,
  });

  const deletedWeights = useMeasurementSearchDeletedMeasurement({
    params: {
      request: {
        filter: {
          memberId,
          type: MeasurementResultTypeEnum.HS
        },
        pageInfo: {
          ...tableChange.pageInfo
        }
      }
    },
    options: {
      sendOnMount: !!memberId
    }
  });

  return (
    <FetchComponent
      info={deletedWeights}
    >
      {(data, info) => (
        <HSDeletionLogTableComponent
          dataSource={data?.data?.rawValue.content || []}
          onChange={tableChange.handleTableChange}
          pagination={info.pagination}
          size="middle"
        />
      )}
    </FetchComponent>
  );
};
