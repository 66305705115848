import { Button, Form, Modal } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useMemo } from 'react';
import { Icons } from '../../../../icons/Icons';
import { UploadPatientListResult } from '../../../../uc-api-sdk';
import { ClinicSelectComponent } from '../../../../uiComponent/ClinicSelectComponent/ClinicSelectComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { MedicalOrgCurrentConsentContainer } from '../../container/MedicalOrgCurrentConsentContainer/MedicalOrgCurrentConsentContainer';
import { PatientListUploadComponent } from '../PatientListUploadComponent/PatientListUploadComponent';
import './BatchSendConsentFormComponent.scss';

export interface BatchSendConsentFormValues {
  clinicId: string;
  ptListFile: RcFile;
}

export interface BatchSendConsentFormComponentProps {
  onSubmit?: (
    values: BatchSendConsentFormValues,
    onSuccess?: () => void
  ) => void;
  isLoading?: boolean;
  disabled?: boolean;
  onFileStatusChange: (file: UploadPatientListResult | undefined) => void;
}

export const BatchSendConsentFormComponent = ({
  onSubmit,
  isLoading,
  disabled,
  onFileStatusChange,
}: BatchSendConsentFormComponentProps) => {
  const [form] = Form.useForm();

  const onFileChange = (f: RcFile) => {
    form.setFieldValue('ptListFile', f);
    return f.name;
  };

  const onRemove = () => {
    form.setFieldValue('ptListFile', null);
    onFileStatusChange(undefined);
  };

  const confirmModalConfig = useMemo(
    () => ({
      content: (
        <div className="fs16">
          Are you sure to send consent form notification on patient mobile app
          for signing ?
        </div>
      ),
      okText: 'Send now',
      cancelText: 'No',
      centered: true,
      icon: <Icons.PaperPlane />,
      closable: true,
      wrapClassName: 'batch-send-consent-confirm-modal',
      onOk: () => {
        onSubmit?.(form.getFieldsValue(), () => form.resetFields());
      },
      maskClosable: true,
    }),
    [onSubmit]
  );

  const handleSubmit = () => {
    Modal.confirm(confirmModalConfig);
  };

  return (
    <div className="BatchSendConsentFormComponent">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        disabled={isLoading}
      >
        <div className="bg-white p30 default-border">
          <div className="w50">
            <FormItem
              name="clinicId"
              label="1. To which medical organization would you like to send patient consent forms?"
              required
            >
              <ClinicSelectComponent />
            </FormItem>
            <FormItem dependencies={['clinicId']}>
              {({ getFieldValue }) => {
                const clinicId = getFieldValue('clinicId');
                return (
                  <MedicalOrgCurrentConsentContainer clinicId={clinicId} />
                );
              }}
            </FormItem>
            <FormItem dependencies={['clinicId']}>
              {({ getFieldValue }) => {
                const clinicId = getFieldValue('clinicId');
                return (
                  clinicId && (
                    <div>
                      <FormItem
                        required
                        name="ptListFile"
                        label="2. Upload the patient list to send consent forms to their mobile app."
                      >
                        <PatientListUploadComponent
                          action={onFileChange}
                          onRemove={onRemove}
                          onFileStatusChange={onFileStatusChange}
                        />
                      </FormItem>
                    </div>
                  )
                );
              }}
            </FormItem>
          </div>
        </div>
        <div className="flex jc-c mt50">
          <Button
            loading={isLoading}
            disabled={isLoading || disabled}
            type="primary"
            htmlType="submit"
          >
            Send Consent Form to Patient Mobile App
          </Button>
        </div>
      </Form>
    </div>
  );
};
