import { CGMDeviceBrandEnumComponent } from '../../../../enumComponent/CGMDeviceBrandEnumComponent/CGMDeviceBrandEnumComponent';
import { CGMDeviceModelEnumComponent } from '../../../../enumComponent/CGMDeviceModelEnumComponent/CGMDeviceModelEnumComponent';
import { CgmBrandEnum, CgmModelEnum } from '../../../../uc-api-sdk';

export interface CGMDeviceNameComponentProps {
  brand?: CgmBrandEnum | null;
  model?: CgmModelEnum | null;
}

export const CGMDeviceNameComponent = ({
  brand,
  model,
}: CGMDeviceNameComponentProps) => (
  <div className="flex">
    <CGMDeviceBrandEnumComponent value={brand as CgmBrandEnum} />
    {
      model && (
        <CGMDeviceModelEnumComponent value={model} />
      )
    }
  </div>
);
