import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { useMeasurementGetWeightSummary, WeightSummary } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { FromDateToDateDayjs } from '../../type';

export interface HSLogBookTableDataContainerProps extends FromDateToDateDayjs {
  memberId: string;
  children: (data: WeightSummary, refetch?: () => void) => React.ReactNode;
  unit?: 'lb' | 'kg';
}

export const HSLogBookTableDataContainer = ({
  memberId,
  fromDate,
  toDate,
  unit,
  children
}: HSLogBookTableDataContainerProps) => {
  const summary = useMeasurementGetWeightSummary({
    params: {
      searchRequest: {
        filter: {
          memberId,
          dateRange: {
            lte: toDate.toISOString(),
            gte: fromDate.toISOString(),
          },
          weightUnit: unit,
        }
      }
    },
    options: {
      sendOnMount: !!memberId,
    }
  });

  useUpdateListener('WEIGHT_UPDATED', summary.refetch);

  return (
    <FetchComponent
      info={summary}
    >
      {(_, info) => children(
        info.data?.data || {},
      )}

    </FetchComponent>
  );
};
