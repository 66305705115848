import { Col, FormInstance, Row } from 'antd';
import { PatientSpokenLanguageSelectComponent } from '../../../../selectComponents/PatientSpokenLanguageSelectComponent/PatientSpokenLanguageSelectComponent';
import { ChangePasswordModalComponent } from '../../../../uiComponent/ChangePasswordModalComponent/ChangePasswordModalComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PatientUsernameInputContainer } from '../../../../uiComponent/PatientUsernameInputContainer/PatientUsernameInputContainer';
import PhoneInput from '../../../../uiComponent/PhoneInput/PhoneInput';
import { OTPSwitchContainer } from '../../../../uiContainer/OTPSwitchContainer/OTPSwitchContainer';
import { useBasicInfoForm } from '../../hook/useBasicInfoForm/useBasicInfoForm';

export interface EnrollmentBasicInfoComponentProps {
  form?: FormInstance;
  patientId: string;
  setValid?: () => void;
  setInvalid?: () => void;
}

export const EnrollmentBasicInfoComponent = ({
  form,
  patientId,
  setValid,
  setInvalid,
}: EnrollmentBasicInfoComponentProps) => {
  const basicInfo = useBasicInfoForm({ form });

  return (
    <>
      <Row gutter={10} className="mr0i">
        <Col span={6}>
          <FormItem
            info={basicInfo.getInfo('spokenLanguage')}
            required
          >
            <PatientSpokenLanguageSelectComponent />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            info={basicInfo.getInfo('username')}
            required
          >
            <PatientUsernameInputContainer
              fieldName="username"
              form={form}
              setInvalid={setInvalid}
              setValid={setValid}
            />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem label=" ">
            <ChangePasswordModalComponent
              userId={patientId}
              userType="PATIENT"
              buttonText="Set Password"
              buttonBlock
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={10} className="mr0i">
        <Col span={6}>
          <FormItem
            info={basicInfo.getInfo('mobilePhoneNum')}
            required
          >
            <PhoneInput prefix="+1" />
          </FormItem>
        </Col>
        <Col span={6} className="mr0i">
          <FormItem dependencies={['mobilePhoneNum']}>
            {({ getFieldValue }) => {
              const phone = getFieldValue('mobilePhoneNum');
              return (
                <FormItem info={basicInfo.getInfo('appOTPLogin')}>
                  <OTPSwitchContainer phone={phone} />
                </FormItem>
              );
            }}
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem info={basicInfo.getInfo('homePhoneNum')}>
            <PhoneInput prefix="+1" />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};
