import biolandImg from './images/devices/bg-bioland.png';
import bg1Img from './images/devices/BG1_medium.png';
import pyloImg from './images/devices/bp-pylo.png';
import bpmClearImg from './images/devices/bpmc.png';
import hotSpotImg from './images/devices/HotSpot.jpg';
import hs2Img from './images/devices/HS2.png';
import hs2sImg from './images/devices/HS2S.png';
import easeImg from './images/devices/ihealth-ease.png';
import glucoImg from './images/devices/ihealth-gluco.png';
import po3Img from './images/devices/ihealth-PO3.png';
import loanerPhoneImg from './images/devices/loaner-phone-2.png';
import ts28bImg from './images/devices/TS28B.png';
import wifiRouterImg from './images/devices/Wifi-Router.png';
import bpm1CuffImg from './images/supplies/bpm1-cuff.png';
import easeCuffImg from './images/supplies/ease-cuff.png';
import lancetsImg from './images/supplies/lancets.png';
import testStripsImg from './images/supplies/test-strips.png';
import cgmImg from './images/devices/cgmImg.png';
import dexcomLogo from './images/devices/dexcom.png';
import libreLogo from './images/devices/libre.png';

export const BPM_CLEAR_IMG = {
  src: bpmClearImg,
  alt: 'BP monitor clear',
};

export const LOANER_PHONE_IMG = {
  src: loanerPhoneImg,
  alt: 'Loaner phone',
};

export const EASE_IMG = {
  src: easeImg,
  alt: 'iHealth Ease',
};

export const GLUCO_IMG = {
  src: glucoImg,
  alt: 'iHealth Gluco+',
};

export const PO3_IMG = {
  src: po3Img,
  alt: 'iHealth po3',
};

export const BG1_IMG = {
  src: bg1Img,
  alt: 'BG1',
};

export const HS2_IMG = {
  src: hs2Img,
  alt: 'HS2',
};

export const HS2S_IMG = {
  src: hs2sImg,
  alt: 'HS2S',
};

export const TS28B_IMG = {
  src: ts28bImg,
  alt: 'TS28B',
};

export const BIOLAND_IMG = {
  src: biolandImg,
  alt: 'BG Cellular Bioland',
};

export const PYLO_IMG = {
  src: pyloImg,
  alt: 'BP Cellular Pylo',
};

export const WIFI_ROUTER_IMG = {
  src: wifiRouterImg,
  alt: 'Wi-Fi Router',
};

export const HOTSPOT_IMG = {
  src: hotSpotImg,
  alt: 'Hotspot',
};

export const BG_STRIP_IMG = {
  src: testStripsImg,
  alt: 'Test strips',
};

export const LANCETS_IMG = {
  src: lancetsImg,
  alt: 'Lancets',
};

export const BPM1_CUFF_IMG = {
  src: bpm1CuffImg,
  alt: 'BPM1 cuff',
};

export const EASE_CUFF_IMG = {
  src: easeCuffImg,
  alt: 'Ease cuff',
};

export const CGM_IMG = {
  src: cgmImg,
  alt: 'CGM Device',
};
export const DEXCOM_LOGO_IMG = {
  src: dexcomLogo,
  alt: 'Dexcom',
};
export const LIBRE_LOGO_IMG = {
  src: libreLogo,
  alt: 'Libre',
};
