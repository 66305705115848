import { DeviceMonitorMethodEnum } from '../../../enumComponent/DeviceMonitorMethodEnumComponent/DeviceMonitorMethodEnumComponent';
import { DeviceModelEnum, VitalEnumType } from '../../../uc-api-sdk';
import { defaultAppDeviceModel } from '../constant';
import { ConnectedDeviceInfo } from '../type';

export class DeviceHelper {
  static BPM1DeviceLanguage = {
    en: { value: 'en', label: 'English', supportVolume: true },
    fr: { value: 'fr', label: 'French', supportVolume: true },
    zh: { value: 'zh', label: 'Mandarin', supportVolume: false },
    es: { value: 'es', label: 'Spanish', supportVolume: false },
  };

  static deviceOrderByVitalType = [
    VitalEnumType.BG,
    VitalEnumType.BP,
    VitalEnumType.HS,
    VitalEnumType.PO,
    VitalEnumType.TM,
  ];

  static getMethodByModel(model: DeviceModelEnum): DeviceMonitorMethodEnum | undefined {
    switch (model) {
      case DeviceModelEnum.PY_802_LTE:
      case DeviceModelEnum.BIOLAND:
      case DeviceModelEnum.BPM1:
      case DeviceModelEnum.BPM1_HOTSPOT:
        return DeviceMonitorMethodEnum.NON_APP_MONITOR;
      case DeviceModelEnum.BP3L:
      case DeviceModelEnum.BG1:
      case DeviceModelEnum.BG5S:
      case DeviceModelEnum.HS2:
      case DeviceModelEnum.HS2S:
      case DeviceModelEnum.PO3:
      case DeviceModelEnum.TS28B:
        return DeviceMonitorMethodEnum.APP_MONITOR;
      default:
        return undefined;
    }
  }

  static getVitalTypeByModel(model: DeviceModelEnum): VitalEnumType | undefined {
    switch (model) {
      case DeviceModelEnum.PY_802_LTE:
      case DeviceModelEnum.BP3L:
      case DeviceModelEnum.BPM1:
      case DeviceModelEnum.BPM1_HOTSPOT:
        return VitalEnumType.BP;
      case DeviceModelEnum.BIOLAND:
      case DeviceModelEnum.BG1:
      case DeviceModelEnum.BG5S:
        return VitalEnumType.BG;
      case DeviceModelEnum.HS2:
      case DeviceModelEnum.HS2S:
        return VitalEnumType.HS;
      case DeviceModelEnum.PO3:
        return VitalEnumType.PO;
      case DeviceModelEnum.TS28B:
        return VitalEnumType.TM;
      case DeviceModelEnum.CGM:
        return VitalEnumType.CGM;
      default:
        return undefined;
    }
  }

  static getDefaultDeviceByVitalType(vitalType: VitalEnumType): ConnectedDeviceInfo | undefined {
    const deviceModel = defaultAppDeviceModel[vitalType];
    if (deviceModel) {
      const defaultDevice = {
        deviceId: '',
        deviceModel,
        deviceMethod: this.getMethodByModel(deviceModel),
      } as ConnectedDeviceInfo;
      return defaultDevice;
    }
    return undefined;
  }

  static correctSearchDeviceModel<T extends DeviceModelEnum>(deviceModel: T) {
    if (deviceModel === DeviceModelEnum.BPM1_HOTSPOT) {
      return DeviceModelEnum.BPM1;
    }
    return deviceModel;
  }

  static isNonAppMonitor(deviceMonitorMethod?: DeviceMonitorMethodEnum) {
    return (
      deviceMonitorMethod
      && (
        [
          DeviceMonitorMethodEnum.NON_APP_MONITOR,
          DeviceMonitorMethodEnum.CELLULAR_DEVICE,
          DeviceMonitorMethodEnum.WIFI_DEVICE,
        ].includes(deviceMonitorMethod)
      )
    );
  }
}
