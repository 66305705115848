import { MessagePayload } from '../../../../services/CHSServices/types/data';
import { SymptomSeverityEnumComponent } from '../../../symptom/component/SymptomSeverityEnumComponent/SymptomSeverityEnumComponent';
import { SymptomSeverityEnum } from '../../../../uc-api-sdk';
import { SYMPTOM_SAD_FACE_ICON } from '../../../../assets/commonIconInfo';
import Icon from '../../../../uiComponent/Icon/Icon';

interface ParsedSymptom {
  symptom: string;
  severity: string | SymptomSeverityEnum;
}

export interface MessageSymptomLogComponentProps {
  messagePayload: MessagePayload;
}

export const MessageSymptomLogComponent = ({
  messagePayload,
}: MessageSymptomLogComponentProps) => {
  // ex: "Care Team, please review.; symptomLogId: 123; symptoms: Cough, MILD; Fever, SEVERE;"
  const {
    text,
  } = messagePayload;

  const parseTitle = (input: string) => {
    const textMatch = input.match(/(.*?);/);
    return textMatch ? textMatch[1].trim() : '';
  };

  const parseSymptomLogId = (input: string) => {
    const symptomLogIdMatch = input.match(/symptomLogId: (.*?);/);
    return symptomLogIdMatch ? symptomLogIdMatch[1].trim() : '';
  };

  const parseSymptoms = (input: string) => {
    const symptomsMatch = input.match(/symptoms: (.*?);$/);
    const symptoms: ParsedSymptom[] = symptomsMatch
      ? symptomsMatch[1]
        .split(';').filter(Boolean) // Remove empty elements
        .map((entry: string) => {
          const [symptom, severity] = entry.split(',').map(s => s.trim());
          return { symptom, severity };
        })
      : [];

    return symptoms as ParsedSymptom[];
  };

  return (
    <div className="message-item__message-content message-item__message-highlight">
      <div className="flex gap0 py12">
        <div className="h100 w30 ml10">
          <Icon img={SYMPTOM_SAD_FACE_ICON} originalSize />
        </div>
        <div>
          <div>
            {parseSymptoms(text as string).map((symptom) => (
              <div
                key={`${parseSymptomLogId(text as string)}-${symptom.symptom}`}
                className="flex jc-fs"
              >
                <div className="w50">{symptom.symptom}</div>
                <div className="flex jc-fs gap10">
                  <SymptomSeverityEnumComponent value={symptom.severity as SymptomSeverityEnum} />
                </div>
              </div>
            ))}
          </div>
          <div className="border-t mt10 pt10 pb10">
            {parseTitle(text as string)}
          </div>
        </div>
      </div>
    </div>
  );
};
