import { ReactNode } from 'react';
import { PDFViewerComponent } from '../../../../uiComponent/PDFViewerComponent/PDFViewerComponent';

export interface ConsentFormFileViewComponentProps {
  fileUrl?: string | null;
  missingText?: ReactNode;
}

export const ConsentFormFileViewComponent = ({
  fileUrl,
  missingText = 'File url is not available',
}: ConsentFormFileViewComponentProps) => (
  !fileUrl
    ? <div>{missingText}</div>
    : (
      <PDFViewerComponent
        fileUrl={fileUrl}
        height="100%"
      />
    )
);
