import { FormOptions, useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import { GetFieldValue } from '../../../../types/form';
import { useGetContextValue } from '../../../../hooks/useGetContextValue/useGetContextValue';
import { CgmBrandEnum, CgmModelEnum } from '../../../../uc-api-sdk';

export interface CGMFormSubmitValue {
  brand?: CgmBrandEnum | null;
  model?: CgmModelEnum | null;
}

export const useCGMDeviceForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    brand: {
      name: 'brand',
      label: 'Device Brand',
    },
    model: {
      name: 'model',
      label: 'Device Model',
    },
  }, options);

  const shouldShowModel = (getFieldValue: GetFieldValue) => (
    factory.getValue('brand', getFieldValue) !== undefined
  );

  const shouldShowLinkingStatus = (getFieldValue: GetFieldValue) => (
    factory.getValue('brand', getFieldValue) !== undefined
  );

  return useGetContextValue({
    ...factory,
    shouldShowModel,
    shouldShowLinkingStatus,
  }, [factory]);
};
