import { Drawer, DrawerProps } from 'antd';
import { ReactNode } from 'react';
import { LargeDrawerWidth } from '../../../../constants/drawer';

export interface ConsentFormDrawerComponentProps extends DrawerProps {
  children?: ReactNode;
}

export const ConsentFormDrawerComponent = ({
  children,
  ...props
}: ConsentFormDrawerComponentProps) => (
  <Drawer
    width={LargeDrawerWidth}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </Drawer>
);
