import { RightOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Form,
  FormProps,
} from 'antd';
import {
  compact,
  difference,
  filter,
  isEqual,
  uniq
} from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useBoolean } from 'usehooks-ts';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { usePatientMedicalOrganizationContext } from '../../../../contexts/PatientMedicalOrganizationContext/PatientMedicalOrganizationContext';
import {
  PatientDiagnosesEnum,
  ProgramCategoryEnum,
  VitalEnumType,
  HealthConditionItem,
  PatientComplexity,
  AddOnServiceEnum
} from '../../../../uc-api-sdk';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { FormItem } from '../../../../uiComponent/FormItem/FormItem';
import { FormType } from '../../../Input/types';
import { useProgramEnrollmentForm } from '../../hook/useProgramEnrollmentForm/useProgramEnrollmentForm';
import { EnrollmentBasicInfoComponent } from '../EnrollmentBasicInfoComponent/EnrollmentBasicInfoComponent';
import { HaveSmartPhoneRadioComponent } from '../HaveSmartPhoneRadioComponent/HaveSmartPhoneRadioComponent';
import { JoinProgramRadioComponent } from '../JoinProgramRadioComponent/JoinProgramRadioComponent';
import { PatientVitalsAndProgramsComponent } from '../PatientVitalsAndProgramsComponent/PatientVitalsAndProgramsComponent';
import { RejectedAlertComponent } from '../RejectedAlertComponent/RejectedAlertComponent';
import { RejectReasonInputComponent } from '../RejectReasonInputComponent/RejectReasonInputComponent';
import { useUpdate, useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { InsuranceSubmitValue } from '../../../../hooks/formHook/useInsuranceForm';
import { InsuranceFormContainer, InsuranceFormContainerProps } from '../../../Insurance/container/InsuranceFormContainer/InsuranceFormContainer';
import { useInsuranceBlockPrompt } from '../../../Insurance/hook/useInsuranceBlockPrompt';
import { IntroduceUnifiedCareBtnComponent } from '../IntroduceUnifiedCareBtnComponent/IntroduceUnifiedCareBtnComponent';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { DiagnosisIcdCodeFormContainer } from '../../../patient/container/DiagnosisIcdCodeFormContainer/DiagnosisIcdCodeFormContainer';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { getProtocolFromDiagnoses } from '../../../ICDCode/helper';
import { ConsentFormStatusBarComponent } from '../../../consentForm/component/ConsentFormStatusBarComponent/ConsentFormStatusBarComponent';
import { useEffectWithPrevValue } from '../../../../hooks/useEffectWithPrevValue/useEffectWithPrevValue';

export interface SubmitValue extends InsuranceSubmitValue {
  diagnoses?: PatientDiagnosesEnum[];
  icdTable?: HealthConditionItem[] | null;
  complexity?: PatientComplexity | null;
  vitals?: VitalEnumType[];
  programs?: ProgramCategoryEnum[];
  wouldJoin?: boolean;
  notJoiningReason?: string;
  provider?: string;
  spokenLanguage?: string[];
  username?: string;
  mobilePhoneNum?: string;
  homePhoneNum?: string;
  countryCode?: string;
  appOTPLogin?: boolean;
  hasSmartPhone?: boolean;
  clinicId?: string;
  addOnServices?: AddOnServiceEnum[];
}

export interface ProgramEnrollmentFormComponentBasePros {
  patientInfo: PatientInfo;
  isVitalsAndProgramsDone?: boolean;
  onVitalsAndProgramsDone?: () => void;
}

export interface ProgramEnrollmentFormComponentProps extends
  Omit<FormType<SubmitValue>, 'onValuesChange'>,
  ProgramEnrollmentFormComponentBasePros {
  onReject: (value: SubmitValue) => void;
  patientId: string;
  onValuesChange?: (
    changedValues: Partial<SubmitValue>,
    allValues?: SubmitValue
  ) => void;
}

export const ProgramEnrollmentFormComponent = ({
  patientId,
  initialValues,
  onSubmit,
  onReject,
  onValuesChange,
  patientInfo,
  isLoading,
  isVitalsAndProgramsDone,
  onVitalsAndProgramsDone,
}: ProgramEnrollmentFormComponentProps) => {
  const [
    inProgress,
    setInProgress,
  ] = useState<boolean>(false);
  const [
    eligiblePrograms,
    setEligiblePrograms,
  ] = useState<ProgramCategoryEnum[]>(initialValues?.programs || []);
  const {
    value: valid,
    setTrue,
    setFalse
  } = useBoolean(true);
  const updateInsurance = useUpdate('INSURANCE_UPDATED').updateValue;

  const {
    medicalOrganizationService,
    isLoading: medicalOrgLoading,
    fetchMedicalOrganization,
  } = usePatientMedicalOrganizationContext();
  const {
    medicalOrgAvailablePrograms,
    medicalOrgAddOnServices,
  } = useMemo(() => {
    if (medicalOrgLoading) return {};

    return {
      medicalOrgAvailablePrograms: medicalOrganizationService?.availablePrograms(),
      medicalOrgAddOnServices: medicalOrganizationService?.addOnServices(),
    };
  }, [
    medicalOrgLoading,
    patientInfo?.patientInsurance?.markMedCareEligible
  ]);

  const isPatientEnrolled = patientInfo.enrollmentService.isEnrolled();
  const isPatientRejected = patientInfo.enrollmentService.isRejected();
  // The current correct status is obtained from enrolledProgramService.
  // The patientInfo.enrollmentService is solely utilized for flow of patient enrollment.
  // The status is specifically confined to the Enrollment page.
  const isDischarged = patientInfo.enrolledProgramService?.isDischarged?.();
  const isEnrolled = patientInfo.enrolledProgramService?.isEnrolled?.();
  const associatedList = patientInfo.patientInfoService?.patientAssociatedClinics;

  const peForm = useProgramEnrollmentForm();
  const { form } = peForm;
  const {
    isInsuranceEdit,
    setIsInsuranceEdit,
    insuranceBlockPrompt,
  } = useInsuranceBlockPrompt();
  const debouncedInsuranceRefetch = useDebounce(() => {
    patientInfo.refetch(['patientInsuranceRefetch']);
  }, 100, [patientInfo?.refetch]);
  useUpdateListener('INSURANCE_UPDATED', debouncedInsuranceRefetch);

  const updateEligiblePrograms = useDebounce(async (
    diagnoses?: PatientDiagnosesEnum[],
    initialValues?: SubmitValue, // compare update on initial render
  ) => {
    try {
      setInProgress(true);
      if (diagnoses) {
        const { vitals = [], programs = [] } = await peForm.getPrefilledValues(
          diagnoses,
          medicalOrgAvailablePrograms || []
        );
        setEligiblePrograms((prev) => {
          if (prev.includes(ProgramCategoryEnum.APCM)) {
            return [ProgramCategoryEnum.APCM, ...programs];
          }
          return programs;
        });
        if (
          // prevent unnecessary update on initial render
          !initialValues
          || difference(vitals, initialValues.vitals || []).length
          || difference(programs, initialValues.programs || []).length
        ) {
          const currentPrograms = peForm.getValue('programs', form.getFieldValue) || [];
          let newPrograms = compact([...programs]) as ProgramCategoryEnum[];
          if (currentPrograms?.includes(ProgramCategoryEnum.APCM)) {
            // APCM has higher priority than CCM, can be changed manually
            newPrograms = newPrograms.filter((p) => p !== ProgramCategoryEnum.CCM);
            newPrograms = uniq([ProgramCategoryEnum.APCM, ...newPrograms]);
          }

          const currentVitals = peForm.getValue('vitals', form.getFieldValue) || [];
          let newVitals = compact([...vitals]) as VitalEnumType[];
          if (currentVitals?.includes(VitalEnumType.CGM)) {
            newVitals = uniq([VitalEnumType.CGM, ...newVitals]);
          }
          form.setFieldsValue({
            vitals: newVitals,
            programs: newPrograms,
          });
          onValuesChange?.({ vitals, programs: newPrograms });
        }
      }
    } catch (e) {
      // ignore
    } finally {
      setInProgress(false);
    }
  }, 200, [medicalOrgAvailablePrograms]);

  const onFormChange: FormType<SubmitValue>['onValuesChange'] = async (changedValues, allValues) => {
    if (
      peForm.getName('diagnoses') in changedValues
      || peForm.getName('clinicId') in changedValues
    ) {
      updateEligiblePrograms(allValues.diagnoses);
    }
    onValuesChange?.(changedValues, allValues);
  };

  const handleSubmit: FormProps['onFinish'] = (
    v: SubmitValue
  ) => {
    if (isInsuranceEdit) {
      insuranceBlockPrompt();
      return;
    }
    if (
      v.wouldJoin
      || (v.wouldJoin !== false && isEnrolled)
    ) {
      onSubmit?.({
        ...v,
        wouldJoin: true, // make sure always true
      });
    } else {
      onReject?.(v);
    }
  };

  const handleSubmitInsurance: InsuranceFormContainerProps['onSubmit'] = () => {
    debouncedInsuranceRefetch();
  };

  const handleConsentFormSubmit = () => {
    updateInsurance();
    patientInfo?.refetch(undefined, ['patientInsuranceRefetch']);
  };

  useEffectWithPrevValue(medicalOrgAvailablePrograms, (prev) => {
    // allow to update eligible on mount after initial render
    if (!prev && medicalOrgAvailablePrograms) {
      updateEligiblePrograms(
        initialValues?.diagnoses,
        initialValues
      );
    }
  });

  useEffect(() => {
    if (!medicalOrgAvailablePrograms?.includes(ProgramCategoryEnum.APCM)) {
      return;
    }
    const isAPCMEligible = !!patientInfo.patientInsurance?.markMedCareEligible;
    setEligiblePrograms((prev) => {
      const selectedPrograms = peForm.getValue('programs', form.getFieldValue) || [];
      let newSelectedPrograms = [...selectedPrograms];
      let newEligiblePrograms = [...prev];

      if (isAPCMEligible) {
        newEligiblePrograms = uniq([...prev, ProgramCategoryEnum.APCM]);
        newSelectedPrograms = filter(
          newSelectedPrograms,
          (p) => p !== ProgramCategoryEnum.CCM
        );
        newSelectedPrograms.push(ProgramCategoryEnum.APCM);
        if (prev.includes(ProgramCategoryEnum.RPM)) {
          // auto-select RPM when APCM is selected; no vice versa
          newSelectedPrograms.push(ProgramCategoryEnum.RPM);
        }
      } else {
        newEligiblePrograms = filter(
          prev,
          (p) => p !== ProgramCategoryEnum.APCM
        );
        newSelectedPrograms = filter(
          newSelectedPrograms,
          (p) => p && p !== ProgramCategoryEnum.APCM
        );
        // auto-select CCM, RPM if it's eligible
        [ProgramCategoryEnum.CCM, ProgramCategoryEnum.RPM].forEach((p) => {
          if (prev.includes(p)) newSelectedPrograms.push(p);
        });
      }

      newSelectedPrograms = uniq(newSelectedPrograms);
      // update program selection
      if (!isEqual(selectedPrograms, newSelectedPrograms)) {
        form.setFieldsValue({
          programs: newSelectedPrograms,
        });
      }

      return newEligiblePrograms;
    });
  }, [patientInfo.patientInsurance?.markMedCareEligible, medicalOrgAvailablePrograms]);

  useEffect(() => {
    const currentPrograms = peForm.getValue('programs', form.getFieldValue) || [];
    if (
      initialValues
      && difference(initialValues.programs, currentPrograms).length
    ) {
      peForm.form.resetFields(['programs']);
    }
  }, [initialValues]);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onValuesChange={onFormChange}
      onFinish={handleSubmit}
      disabled={isLoading}
      layout="vertical"
      className="flex h100"
    >
      <FixedComponent className="f1">
        <FixedComponent.Child>
          <div>
            {!isDischarged && (
              <div>
                {isPatientRejected
                  ? (
                    <div className="mb20">
                      <RejectedAlertComponent />
                    </div>
                  ) : (
                    <div className="mb20">
                      <ConsentFormStatusBarComponent
                        patientInfo={patientInfo}
                        onSubmit={handleConsentFormSubmit}
                      />
                    </div>
                  )}
              </div>
            )}
            <div className="ta-r">
              <IntroduceUnifiedCareBtnComponent />
            </div>
            <div className="mb20">
              <TextComponent
                className="mb5"
                size="large"
                bold
              >
                Diagnosis / ICD code
              </TextComponent>
              <Card>
                <DiagnosisIcdCodeFormContainer
                  form={form}
                  initialValues={{
                    icdTable: initialValues?.icdTable ?? undefined,
                  }}
                  patientId={patientId}
                  formButtons={null}
                />
              </Card>
            </div>
            <FormItem noStyle shouldUpdate={peForm.shouldUpdate(['diagnoses', 'vitals', 'programs'])}>
              {({ getFieldValue }) => {
                const show = peForm.shouldVitalsAndPrograms(getFieldValue);
                const diagnoses = peForm.getValue('diagnoses', getFieldValue);
                const programs = peForm.getValue('programs', getFieldValue);
                return (
                  show
                    ? (
                      <>
                        <PatientVitalsAndProgramsComponent
                          isEnrolled={isEnrolled}
                          clinicProgramParticipation={medicalOrgAvailablePrograms}
                          addOnServices={medicalOrgAddOnServices}
                          isSaasModel={medicalOrganizationService?.isSaasModel}
                          eligiblePrograms={eligiblePrograms}
                          onClinicChange={fetchMedicalOrganization}
                          associatedList={associatedList}
                          onValuesChange={onValuesChange}
                          recommendedProtocol={getProtocolFromDiagnoses(diagnoses)}
                        />
                        {!isVitalsAndProgramsDone && (
                          <Button
                            className="mt20"
                            type="primary"
                            disabled={programs?.length === 0}
                            onClick={onVitalsAndProgramsDone}
                          >
                            Confirm
                          </Button>
                        )}
                      </>
                    ) : null
                );
              }}
            </FormItem>
          </div>
          {
            isVitalsAndProgramsDone
              ? (
                <div className="mt20">
                  <div className="my15">
                    <h4>Insurance</h4>
                    <InsuranceFormContainer
                      patientInfo={patientInfo.patientInfo || {}}
                      onEdit={setIsInsuranceEdit}
                      onSubmit={handleSubmitInsurance}
                    />
                  </div>
                  <FormItem noStyle info={peForm.getInfo('wouldJoin')}>
                    <JoinProgramRadioComponent />
                  </FormItem>
                  <FormItem noStyle shouldUpdate={peForm.shouldUpdate(['wouldJoin'])}>
                    {({ getFieldValue }) => {
                      const show = peForm.shouldShowNotJoinReason(getFieldValue);
                      return show ? (
                        <FormItem
                          info={peForm.getInfo('notJoiningReason')}
                          className="mt20"
                          required
                        >
                          <RejectReasonInputComponent />
                        </FormItem>
                      ) : null;
                    }}
                  </FormItem>
                </div>
              ) : null
          }
          <FormItem noStyle shouldUpdate={peForm.shouldUpdate(['wouldJoin'])}>
            {({ getFieldValue }) => {
              const show = peForm.shouldShowBasicInformation(getFieldValue);
              return (
                show
                  ? (
                    <div className="mt20">
                      <h4>Confirm Basic Information</h4>
                      <EnrollmentBasicInfoComponent
                        form={form}
                        patientId={patientId}
                        setValid={setTrue}
                        setInvalid={setFalse}
                      />
                      <FormItem noStyle info={peForm.getInfo('hasSmartPhone')}>
                        <HaveSmartPhoneRadioComponent />
                      </FormItem>
                      <FormItem noStyle shouldUpdate={peForm.shouldUpdate(['wouldJoin', 'hasSmartPhone'])}>
                        {({ getFieldValue }) => {
                          const show = peForm.shouldShowNoSmartPhoneWarning(getFieldValue);
                          return show ? (
                            <p className="text-red mt10">
                              * Patient will only be able to choose non-App solution
                            </p>
                          ) : null;
                        }}
                      </FormItem>
                    </div>
                  )
                  : null
              );
            }}
          </FormItem>
        </FixedComponent.Child>
        <FixedComponent.Child isFixed className="pt30">
          <FormItem
            noStyle
            shouldUpdate={peForm.shouldUpdate(['wouldJoin', 'hasSmartPhone', 'programs'])}
          >
            {({ getFieldValue }) => {
              const isJoining = peForm.getValue('wouldJoin', getFieldValue);
              const lastFieldHasValue = peForm.hasValue('hasSmartPhone', getFieldValue);
              const isProgramShow = peForm.shouldVitalsAndPrograms(getFieldValue);
              const programs = peForm.getValue('programs', getFieldValue) || [];
              if (isJoining === false || isPatientEnrolled) {
                return (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={(
                      !isProgramShow
                      || programs?.length === 0
                      || !valid
                      || inProgress
                    )}
                    loading={inProgress}
                  >
                    Submit
                  </Button>
                );
              }
              if (lastFieldHasValue) {
                return (
                  <div className="flex jc-c ali-c">
                    <Button
                      className="input-med"
                      type="primary"
                      htmlType="submit"
                      disabled={programs?.length === 0 || !valid}
                    >
                      Enroll Patient
                      {' '}
                      <RightOutlined />
                    </Button>
                  </div>
                );
              }
              return null;
            }}
          </FormItem>
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};
