import { useMemo } from 'react';
import { CgmBrandEnum } from '../../../../uc-api-sdk';
import { DEXCOM_LOGO_IMG, LIBRE_LOGO_IMG } from '../../../../assets/deviceIconInfo';

export interface CGMDeviceBrandLogoComponentProps {
  brand?: CgmBrandEnum | null;
  width?: number | string;
  height?: number | string;
}

export const CGMDeviceBrandLogoComponent = ({
  brand,
  width = 88,
  height = 'auto',
}: CGMDeviceBrandLogoComponentProps) => {
  const logo = useMemo(() => {
    switch (brand) {
      case CgmBrandEnum.DEXCOM:
        return DEXCOM_LOGO_IMG;
      case CgmBrandEnum.FREESTYLELIBRE:
        return LIBRE_LOGO_IMG;
      default:
        return null;
    }
  }, [brand]);

  if (!logo) {
    return null;
  }

  return (
    <img
      width={width}
      height={height}
      src={logo.src}
      alt={logo.alt}
    />
  );
};
