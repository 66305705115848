import { Button } from 'antd';
import useBoolean from '../../../../../hooks/useBoolean/useBoolean';
import { PageComponent } from '../../../../../uiComponent/PageComponent/PageComponent';
import { BatchSendConsentContainer } from '../../../container/BatchSendConsentContainer/BatchSendConsentContainer';
import { BatchSentConsentHistoryContainer } from '../../../container/BatchSentConsentHistoryContainer/BatchSentConsentHistoryContainer';
import './BatchSendConsentPageComponent.scss';

export const BatchSendConsentPageComponent = () => {
  const { value: historyView, setFalse, setTrue } = useBoolean(false);

  const handleClick = () => {
    setTrue();
  };

  return (
    <PageComponent
      title="Batch-send Consent Form to Mobile App"
      className="bg-gray5 h100"
      extra={!historyView && (
        <Button
          type="text"
          className="underlined"
          onClick={handleClick}
        >
          View History
        </Button>
      )}
    >
      {!historyView ? <BatchSendConsentContainer onHistoryClick={setTrue} />
        : <BatchSentConsentHistoryContainer onExit={setFalse} />}
    </PageComponent>
  );
};
