import { ReactNode } from 'react';
import { Drawer } from 'antd';
import { LargeDrawerWidth } from '../../../../constants/drawer';
import { ClickableDiv } from '../../../../uiComponent/ClickableDiv/ClickableDiv';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { ConsentFormFetchContainer } from '../../container/ConsentFormFetchContainer/ConsentFormFetchContainer';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { Title } from '../../../../uiComponent/Title/Title';
import { ReviewConsentHistoryComponent } from './ReviewConsentHistoryComponent';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';

export interface ReviewConsentDrawerComponentProps {
  children: ReactNode;
  patientInfo: PatientInfo;
  onResignOpen?: () => void;
}

export const ReviewConsentDrawerComponent = ({
  children,
  patientInfo,
  onResignOpen,
}: ReviewConsentDrawerComponentProps) => {
  const {
    isOpen: isReviewOpen,
    open: openReview,
    close: closeReview,
  } = useOpen();

  const handleResignConsent = () => {
    onResignOpen?.();
    closeReview();
  };

  return (
    <>
      <ClickableDiv onClick={openReview}>
        {children}
      </ClickableDiv>
      <Drawer
        title={(
          <div className="flex jc-sb">
            <Title noMargin>Signed Consent Forms</Title>
            <LinkButton
              useBlueColor
              underlineOnHover
              onClick={handleResignConsent}
              className="mr30"
            >
              Sign a New Consent Form
            </LinkButton>
          </div>
        )}
        open={isReviewOpen}
        onClose={closeReview}
        width={LargeDrawerWidth}
        destroyOnClose
      >
        <ConsentFormFetchContainer
          patientId={patientInfo.id}
        >
          {
            ({ consentForms, isLoading }) => (
              <LoadingOverlayComponent isLoading={isLoading}>
                <div className="flex fd-c h100">
                  <div className="f1">
                    <ReviewConsentHistoryComponent
                      consentForms={consentForms}
                      isLoadingConsentForms={isLoading}
                    />
                  </div>
                </div>
              </LoadingOverlayComponent>
            )
          }
        </ConsentFormFetchContainer>
      </Drawer>
    </>
  );
};
