import { FileInfoWithUrl } from '../../../../services/ConsentService';
import { SignatureCollectionMethodEnum, usePatientPreviewConsentForm } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { ConsentFormFileViewComponent } from '../../component/ConsentFormFileViewComponent/ConsentFormFileViewComponent';
import './ConsentTempContainer.scss';

export interface ConsentTempContainerProps {
  patientId: string;
  signatureMethod: SignatureCollectionMethodEnum;
}

export const ConsentTempContainer = ({
  patientId,
  signatureMethod,
}: ConsentTempContainerProps) => {
  const consentPreview = usePatientPreviewConsentForm({
    options: { sendOnMount: !!patientId && !!signatureMethod },
    params: {
      memberId: patientId,
      consentType: signatureMethod,
    },
  });

  return (
    <div className="consent-temp-container">
      <FetchComponent info={consentPreview}>
        {
          ({ data }) => {
            const info = data?.rawValue as FileInfoWithUrl;
            return (
              <ConsentFormFileViewComponent
                fileUrl={info?.url}
                missingText="No preview available"
              />
            );
          }
        }
      </FetchComponent>
    </div>
  );
};
