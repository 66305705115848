import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Button, ButtonProps, Modal } from 'antd';
import { isEmpty } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import {
  UploadPatientListResult,
  useBatchSendConsentSendConsentFormPN,
} from '../../../../uc-api-sdk';
import {
  BatchSendConsentFormComponent,
  BatchSendConsentFormValues,
} from '../../component/BatchSendConsentFormComponent/BatchSendConsentFormComponent';

export interface BatchSendConsentContainerProps {
  onHistoryClick?: () => void;
}

export const BatchSendConsentContainer = ({
  onHistoryClick,
}: BatchSendConsentContainerProps) => {
  const sendPN = useBatchSendConsentSendConsentFormPN({});
  const [fileStatus, setFileStatus] = useState<
    UploadPatientListResult | undefined
  >();

  const goToHistory = useCallback(() => {
    Modal.destroyAll();
    onHistoryClick?.();
  }, [onHistoryClick]);

  const baseModalConfig = useMemo(
    () => ({
      content: (
        <div>
          <div className="bold fs18">Sending Consent Form Starts...</div>
          <div className="fs14">
            <span>
              You can track patients' signing status in the
              {' '}
              <Button type="link" className="m0 p0" onClick={goToHistory}>
                history
              </Button>
              {' '}
              section.
            </span>
          </div>
        </div>
      ),
      okText: 'Got it',
      centered: true,
      icon: <CheckCircleFilled />,
      closable: true,
      okButtonProps: {
        size: 'middle',
        ghost: true,
        block: true,
      } as ButtonProps,
      wrapClassName: 'batch-send-consent-success-modal',
    }),
    [goToHistory]
  );

  const errorModalConfig = useMemo(
    () => ({
      ...baseModalConfig,
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          <div className="bold fs18">System error</div>
          <div className="fs14">
            Failed to send the mobile app notification. Please wait to re-upload
            or contact system admin.
          </div>
        </div>
      ),
    }),
    []
  );
  const handleSubmit = useDebounce(
    async (values: BatchSendConsentFormValues, onSuccess?: () => void) => {
      if (!isEmpty(fileStatus)) {
        const sendStatus = sendPN.send({
          params: {
            request: {
              patientIdList: fileStatus.patientIdList,
              ptDownloadFileOutput: fileStatus.fileDownloadUrlOutput,
              medicalOrgId: values.clinicId,
            },
          },
        });
        ApiRequestHelper.tryCatch(sendStatus, {
          error: undefined,
          success: undefined,
          onSuccess: () => {
            Modal.success({ ...baseModalConfig, onOk: onSuccess });
            setFileStatus(undefined);
          },
          onError: () => {
            Modal.error(errorModalConfig);
          },
        });
      }
    },
    300,
    [fileStatus]
  );

  return (
    <div className="BatchSendConsentContainer">
      <BatchSendConsentFormComponent
        onSubmit={handleSubmit}
        disabled={isEmpty(fileStatus)}
        onFileStatusChange={setFileStatus}
      />
    </div>
  );
};
