import { Tabs, TabsProps } from 'antd';
import './WorklistTabsComponent.scss';

export interface WorklistTabsComponentProps extends TabsProps { }

export const WorklistTabsComponent = ({
  items,
  ...props
}: WorklistTabsComponentProps) => (
  <Tabs
    items={items}
    className="worklist-tabs"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);
