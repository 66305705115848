import { get, join, map } from 'lodash';
import { ColumnsType } from 'antd/lib/table';
import { getProgramCategoryEnumShortText } from '../../enumComponent/ProgramCategoriesComponent/ProgramCategoriesComponent';
import { EnrolledProgramService } from '../../services/EnrolledProgramService';
import {
  PatientEnrolledProgram,
  PatientResult,
  VitalEnumType
} from '../../uc-api-sdk';
import { EmptyString } from '../../uiComponent/EmptyComponent/EmptyComponent';
import { getVitalEnumShortText } from '../../enumComponent/VitalsEnumTypeComponent/VitalsEnumTypeComponent';
import {
  billableStatisticBillable,
  billableStatisticDays,
  billableStatisticTime,
} from '../../features/billing/component/BillableStatisticComponent/BillableStatisticComponent';
import TimezoneService from '../timezone/timezoneService';
import { USA_DATE } from '../../constants/timeFormat';
import { ColumnType } from '../../hooks/table/useTableColumns/useTableColumns';
import { PatientService } from '../../services/PatientService';
import { PatientAssigneesService } from '../../services/PatientAssigneesService';
import { PatientColumnKey } from '../../features/patient/component/PatientsTableComponent/columns';
import { ProviderPatientColumnKey } from '../../features/providerView/component/ProviderViewTablesComponent/columns';
import { getControlLevelText } from '../../enumComponent/ControlLevelEnumComponent/ControlLevelEnumComponent';
import { parseCSVValue } from '.';
import { getEnrolledStatusText } from '../../enumComponent/EnrolledProgramStatusEnumComponent/EnrolledProgramStatusEnumComponent';
import { getClinicName } from '../../uiComponent/DisplayComponent/ClinicDisplayComponent/ClinicNameDisplayComponent';
import { age } from '../../features/patient/component/PatientsTableComponent/PatientAgeComponent';

const getRPMDays = (
  patient?: PatientResult
) => billableStatisticDays(patient?.monthlyBillableStatistic ?? undefined);

const getBillableTime = (
  patient?: PatientResult
) => billableStatisticTime(patient?.monthlyBillableStatistic ?? undefined);

const getBillingEligibility = (
  patient?: PatientResult
) => (billableStatisticBillable(patient?.monthlyBillableStatistic ?? undefined) ? 'Yes' : 'No');

const getAddedDate = (
  patient?: PatientResult
) => (patient?.watchInfo?.addedTime
  ? TimezoneService.calcDateTimeDayjs(patient?.watchInfo?.addedTime, undefined)
    .format(USA_DATE) : EmptyString);

const getTitles = (columns: ColumnsType<PatientResult> | undefined) => {
  const titles: React.ReactNode[] = map(columns, (v: ColumnType<PatientResult> | undefined) => {
    if (typeof v?.title === 'string') {
      return v?.title ?? '';
    }
    return get(get(v?.title, 'props'), 'children');
  });
  return titles;
};

const getContent = (
  patients: PatientResult[],
  column: ColumnsType<PatientResult> | undefined,
  isProvider: boolean
) => {
  const titles = getTitles(column);
  let csvString = `${join(titles, ',')}\n`; // CSV header

  patients.forEach((patient) => {
    const patientService = new PatientService({ patientInfo: patient });
    const assigneeService = new PatientAssigneesService(patient);
    const enrolledProgramService = new EnrolledProgramService(
      patient.patientListInfo as unknown as PatientEnrolledProgram
    );

    const columns: string[] = [];
    map(column, (columnResult) => {
      const key = columnResult.key || '';
      const each = () => {
        switch (key) {
          case PatientColumnKey.id:
            return patientService.getFullName() || EmptyString;
          case PatientColumnKey.age:
            return patientService.birthday ? age(patientService.birthday) : EmptyString;
          case PatientColumnKey.gender:
            return patientService.getGenderText() || EmptyString;
          case PatientColumnKey.program:
            return (
              enrolledProgramService.getPrograms().map(getProgramCategoryEnumShortText).join(', ')
              || EmptyString
            );
          case PatientColumnKey.vitals:
            return (
              enrolledProgramService.getVitals().map(getVitalEnumShortText).join(', ')
              || EmptyString
            );
          case PatientColumnKey.rpmDays:
            return getRPMDays(patient);
          case PatientColumnKey.billableTime:
            return getBillableTime(patient);
          case PatientColumnKey.billable:
            return getBillingEligibility(patient);
          case PatientColumnKey.clinic:
            return (
              getClinicName({
                clinic: patientService.getClinic(),
                isProvider
              }) || EmptyString
            );
          case PatientColumnKey.provider:
            return patientService.getProviderName() || EmptyString;
          case PatientColumnKey.assignedRdHC:
            return assigneeService.getAssignedRdName() || EmptyString;
          case PatientColumnKey.assignedCA:
            return assigneeService.getAssignedCaName() || EmptyString;
          case PatientColumnKey.enrollmentDate:
            return enrolledProgramService.getEnrollmentDate() || EmptyString;
          case PatientColumnKey.mrn:
            return patientService.getMedicalId() || EmptyString;
          case PatientColumnKey.status:
            return getEnrolledStatusText(enrolledProgramService.getStatus()) || EmptyString;
          case PatientColumnKey.addedTime:
            return getAddedDate(patient);
          case PatientColumnKey.watchInfoNote:
            return patient.watchInfo?.watchNote || EmptyString;
          case PatientColumnKey.registrationDate:
            return patientService.getRegistrationDate() || EmptyString;
          case PatientColumnKey.dischargedDate:
            return enrolledProgramService.getDischargedAtDayjs()?.format(USA_DATE) || EmptyString;
          case PatientColumnKey.primaryInsurance:
            return patientService.getPrimaryInsuranceProvider() || EmptyString;
          case ProviderPatientColumnKey.bpDeviceMacId:
            return (
              patientService.getDeviceRecentUsedMacId(VitalEnumType.BP)
              || EmptyString
            );
          case ProviderPatientColumnKey.bpPairDate:
            return (
              patientService.getDevicePairDate(VitalEnumType.BP)
              || EmptyString
            );
          case ProviderPatientColumnKey.bpDeviceLastUsed:
            return patientService.getDeviceRecentUsedDate(VitalEnumType.BP) || EmptyString;
          case ProviderPatientColumnKey.bgPairDate:
            return (
              patientService.getDevicePairDate(VitalEnumType.BG)
              || EmptyString
            );
          case ProviderPatientColumnKey.bgDeviceMacId:
            return (
              patientService.getDeviceRecentUsedMacId(VitalEnumType.BG)
              || EmptyString
            );
          case ProviderPatientColumnKey.bgDeviceLastUsed:
            return patientService.getDeviceRecentUsedDate(VitalEnumType.BG) || EmptyString;
          case PatientColumnKey.controlLevel:
            return getControlLevelText(patientService.controlLevel) || EmptyString;
          default:
            return EmptyString;
        }
      };
      columns.push(parseCSVValue(each()));
    });
    csvString += `${join(columns, ',')}\n`;
  });
  return csvString;
};

export const convertToCSV = (
  patients: PatientResult[],
  column: ColumnsType<PatientResult> | undefined,
  isProvider: boolean,
) => getContent(patients, column, isProvider);
