import {
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';
import { trim } from 'lodash';
import { useUpdateEffect } from 'usehooks-ts';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import {
  APIResponse,
  MonthlyReview,
  MonthlyReviewStatusEnum,
  Nullable,
  ProviderNoteCategoryEnum,
  VisitTypeEnum,
  usePatientUpdateMonthlyReview
} from '../../uc-api-sdk';
import { ApiRequestHelper } from '../../helpers/ApiRequest';
import { useUpdate } from '../UpdateContext/UpdateContext';
import { useMTPRUnsavedNotes } from '../../features/mtpr/hook/useMTPRUnsavedNotes';
import { MTPRService } from '../../features/mtpr/service';

export interface WorklistMTPRContextValue {
  visitType: VisitTypeEnum;
  shouldShowMTPR: boolean;
  isMTPRDone: boolean;
  isMTPRValid: boolean;
  mtprNote?: string;
  setMTPRNote: (value: string) => void;
  monthlyReviewData?: MonthlyReview;
  refetch?: () => void;
  isLoading: boolean;
  initialLoading: boolean;
  saveMTPR: (
    values: MonthlyReview,
    shouldRefetch?: boolean,
  ) => Promise<APIResponse<MonthlyReview> | null | undefined>;
  completeMTPR: (values?: MonthlyReview, refetch?: boolean) => Promise<void>;
  shouldCompleteMTPR: boolean;
  isMTPRDisabled: boolean;
  setIsMTPRDisabled: (value: boolean) => void;
  setUnsavedNotes: (value: string) => void;
  finishUnsavedNotes: () => void;
}

const WorklistMTPRContext = createContext<
  WorklistMTPRContextValue | undefined
>(undefined);

export const useWorklistMTPRContext = () => {
  const context = useContext(WorklistMTPRContext);
  if (!context) throw new Error('WorklistMTPRContext not found');
  return context as WorklistMTPRContextValue;
};

export interface WorklistMTPRContextProviderProps {
  children: ReactNode;
  clinicEventId: string;
  patientId: string;
  visitType: VisitTypeEnum;
  monthlyReviewList?: Nullable<MonthlyReview[]>;
  refetch?: () => void;
  isLoading?: boolean;
}
export const WorklistMTPRContextProvider = ({
  children,
  clinicEventId,
  patientId,
  visitType,
  monthlyReviewList,
  refetch,
  isLoading,
}: WorklistMTPRContextProviderProps) => {
  const monthlyReviewUpdateInfo = usePatientUpdateMonthlyReview({});
  const monthlyReviewData = monthlyReviewList?.[0] as MonthlyReview;
  const {
    getUnsavedNotes,
    setUnsavedNotes,
    finishUnsavedNotes,
  } = useMTPRUnsavedNotes(`ce_${clinicEventId}`);
  const [mtprNote, setMTPRNote] = useState<string | undefined>();
  const [isMTPRDisabled, setIsMTPRDisabled] = useState<boolean>(false);

  const saveMTPRHook = useUpdate('MTPR_SAVED');
  const completeMTPRHook = useUpdate('MTPR_FINISHED');

  useUpdateEffect(() => {
    setMTPRNote(monthlyReviewData?.note || getUnsavedNotes());
  }, [monthlyReviewData?.note]);

  const saveMTPR: WorklistMTPRContextValue['saveMTPR'] = async (
    values,
    shouldRefetch,
  ) => (
    ApiRequestHelper.tryCatch(monthlyReviewUpdateInfo.send({
      params: {
        memberId: patientId,
        update: values,
      }
    }), {
      success: 'Updated monthly review successfully.',
      error: 'Failed to update monthly review.',
      onSuccess: () => {
        const { status } = values;
        if (status === MonthlyReviewStatusEnum.DONE) {
          completeMTPRHook.updateValue();
        } else {
          saveMTPRHook.updateValue();
        }
        finishUnsavedNotes();
        if (shouldRefetch) refetch?.();
      }
    })
  );

  const completeMTPR: WorklistMTPRContextValue['completeMTPR'] = async (
    values,
    shouldRefetch,
  ) => {
    saveMTPR({
      id: monthlyReviewData?.id,
      note: mtprNote,
      type: ProviderNoteCategoryEnum.MTPR_FOLLOW_UP,
      status: MonthlyReviewStatusEnum.DONE,
      ...values,
      goals: undefined,
    }, shouldRefetch);
  };

  const contextValue = useGetContextValue<WorklistMTPRContextValue>((() => {
    const shouldShowMTPR = MTPRService.isVisitEligible(visitType);
    const isMTPRDone = monthlyReviewData?.status === MonthlyReviewStatusEnum.DONE;
    const isMTPRValid = (
      !!trim(mtprNote).length // mtpr has note
      || isMTPRDone // or MTPR is already Done
      || isMTPRDisabled // or MTPR is skipped
    );
    const shouldCompleteMTPR = (
      shouldShowMTPR
      && !isMTPRDone // mtpr is not done
      && !isMTPRDisabled // mtpr is not skipped
      && !!trim(mtprNote).length // mtpr has note
    );
    return {
      visitType,
      shouldShowMTPR,
      isMTPRDone,
      isMTPRValid,
      mtprNote,
      setMTPRNote,
      monthlyReviewData,
      refetch,
      initialLoading: monthlyReviewList === undefined,
      isLoading: !!isLoading,
      saveMTPR,
      completeMTPR,
      shouldCompleteMTPR,
      isMTPRDisabled,
      setIsMTPRDisabled,
      setUnsavedNotes,
      finishUnsavedNotes,
    };
  })(), [
    visitType,
    monthlyReviewData,
    mtprNote,
    monthlyReviewList,
  ]);

  return (
    <WorklistMTPRContext.Provider value={contextValue}>
      {children}
    </WorklistMTPRContext.Provider>
  );
};
