import { ColumnType } from 'antd/es/table';
import { isNil, map } from 'lodash';
import { BGSummaryTableData } from './BGSummaryTableComponent';
import { SummaryTwoIndexComponent } from '../SummaryColumnComponents/SummaryTwoIndexComponent';
import { SummaryEmptyComponent } from '../SummaryColumnComponents/SummaryEmptyComponent';
import { BGSummaryTypeComponent } from './BGSummaryTypeComponent';
import { NumberService } from '../../../../helpers/number';
import { TotalMeasurementCountDisplayComponent } from '../TotalMeasurementCountDisplayComponent/TotalMeasurementCountDisplayComponent';

const convertAllValuesToMgdL = (values: number[]) => (
  map(values, (v) => +v.toFixed(0))
);

/* eslint-disable import/prefer-default-export */
export const BGSummaryTableColumns = {
  type: (): ColumnType<BGSummaryTableData> => ({
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
    width: '23%',
    render: (value) => <BGSummaryTypeComponent type={value} />,
  }),
  count: (totalCount?: number): ColumnType<BGSummaryTableData> => ({
    title: '#',
    key: 'count',
    dataIndex: 'count',
    render: (value) => (
      <TotalMeasurementCountDisplayComponent value={value} totalCount={totalCount} />
    ),
  }),
  average: (): ColumnType<BGSummaryTableData> => ({
    title: 'Avg (mg/dL)',
    key: 'average',
    dataIndex: 'average',
    render: (value) => (isNil(value)
      ? <SummaryEmptyComponent />
      : convertAllValuesToMgdL(value)),
  }),
  range: (): ColumnType<BGSummaryTableData> => ({
    title: 'Range (mg/dL)',
    key: 'range',
    dataIndex: 'range',
    render: (value) => <SummaryTwoIndexComponent value={convertAllValuesToMgdL(value)} divider="-" />,
  }),
  inRangePercentage: (): ColumnType<BGSummaryTableData> => ({
    title: 'Vitals in Normal',
    key: 'rangePercentage',
    dataIndex: 'inRangePercentage',
    width: '17%',
    render: (value) => (isNil(value) ? <SummaryEmptyComponent /> : `${NumberService.getPercentageFromRatio(value / 100)}%`),
  }),
};
