import { isNil } from 'lodash';
import { VisitTypeEnum } from '../../../uc-api-sdk';

export class MTPRService {
  static MTPR_VISIT_TYPE = [
    VisitTypeEnum.FOLLOW_UP,
    VisitTypeEnum.ADDITIONAL
  ];

  static isVisitEligible = (visitType?: VisitTypeEnum | null) => (
    !isNil(visitType) && this.MTPR_VISIT_TYPE.includes(visitType)
  );
}
