import { isEmpty } from 'lodash';
import {
  Button, Col, Divider, Row
} from 'antd';
import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { NestedFormControlProvider } from '../../../../contexts/NestedFormControlContext/NestedFormControlContext';
import { NestedFormSubmitButtonComponent } from '../../../../contexts/NestedFormControlContext/NestedFormSubmitButtonComponent';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { EnrolledProgramService } from '../../../../services/EnrolledProgramService';
import { ProgramCategoryEnum } from '../../../../uc-api-sdk';
import { PatientProfileVitalsMonitoringFormContainer } from '../../container/PatientProfileVitalsMonitoringFormContainer/PatientProfileVitalsMonitoringFormContainer';
import { AddRemoveVitalsMonitoringSubmitValue } from '../../hook/useAddRemoveVitalsMonitoringForm';
import { PatientProfileServicesSubmitValue } from '../../hook/usePatientServicesForm';
import { ProgramEnrollmentSubmitValue } from '../../hook/useProfileProgramEnrollmentForm';
import { PatientProfileProgramEnrollmentComponent } from '../PatientProfileProgramEnrollmentComponent/PatientProfileProgramEnrollmentComponent';
import { PatientProfileVitalsMonitoringComponent } from '../PatientProfileVitalsMonitoringComponent/PatientProfileVitalsMonitoringComponent';
import { PatientProfileProgramsItemComponent } from './PatientProfileProgramsItemComponent';

import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { PauseServiceDrawerComponent } from '../../../../uiComponent/PauseServiceDrawerComponent/PauseServiceDrawerComponent';
import { DischargeModalButtonComponent } from '../../../discharge/component/DischargeModalButtonComponent/DischargeModalButtonComponent';
import { UnableToEngageModalContainer } from '../UnableToEngageModalButtonComponent/UnableToEngageModalButtonComponent';
import './PatientProfileProgramsComponent.scss';
import { ReviewAndResignConsentComponent } from '../../../consentForm/component/ReviewAndResignConsentComponent/ReviewAndResignConsentComponent';

export interface ProgramsSubmitValues
  extends ProgramEnrollmentSubmitValue,
  AddRemoveVitalsMonitoringSubmitValue,
  PatientProfileServicesSubmitValue { }

export interface PatientProfileProgramsComponentProps {
  patientInfo?: PatientInfo;
  enrolledProgramService?: EnrolledProgramService;
  isEditing?: boolean;
  onSubmit?: (values: ProgramsSubmitValues) => void;
  onCancel?: () => void;
  isLoading?: boolean;
  patientClinicProgramParticipation: ProgramCategoryEnum[];
}

export const PatientProfileProgramsComponent = ({
  patientInfo,
  enrolledProgramService,
  patientClinicProgramParticipation,
  isEditing,
  onSubmit,
  onCancel,
  isLoading,
}: PatientProfileProgramsComponentProps) => {
  useUpdateListener('PATIENT_DISCHARGED', () => {
    patientInfo?.refetch();
  });
  if (!patientInfo?.patientInfo || enrolledProgramService === undefined) return null;

  const handleOnSubmit = (values: ProgramsSubmitValues) => {
    const { vitals } = values;
    if (vitals && isEmpty(vitals)) {
      // prevent submit empty array of vitals
      return;
    }
    onSubmit?.(values);
  };

  const handleOnResignConsent = () => {
    patientInfo?.refetch(['enrolledProgramRefetch']);
  };

  return (
    <NestedFormControlProvider
      onFinish={handleOnSubmit}
      disabled={isLoading}
    >
      <Row>
        <Col span={12}>
          <PatientProfileProgramsItemComponent
            title="Program Enrollment"
            className="flex fd-c profile-program-enrollment"
            isEditing={isEditing}
            formContent={(
              <PatientProfileProgramEnrollmentComponent
                enrolledProgramService={enrolledProgramService}
                patientClinicProgramParticipation={patientClinicProgramParticipation}
              />
            )}
          >
            <PatientProfileProgramEnrollmentComponent
              enrolledProgramService={enrolledProgramService}
              patientClinicProgramParticipation={patientClinicProgramParticipation}
            />
          </PatientProfileProgramsItemComponent>
        </Col>
        <Col span={12} className="flex jc-e">
          <ReviewAndResignConsentComponent
            patientInfo={patientInfo}
            onResign={handleOnResignConsent}
            showSentModal={false}
          >
            <Button>View Signed Consent Form</Button>
          </ReviewAndResignConsentComponent>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <PatientProfileProgramsItemComponent
            title="Vitals Monitoring"
            className="flex fd-c profile-vitals-monitoring"
            isEditing={isEditing}
            formContent={(
              <NestedFormComponent>
                <PatientProfileVitalsMonitoringFormContainer
                  patientId={patientInfo.id}
                  enrolledProgramService={enrolledProgramService}
                  isLoading={isLoading}
                  formButtons={null}
                />
              </NestedFormComponent>
            )}
          >
            <PatientProfileVitalsMonitoringComponent
              enrolledProgramService={enrolledProgramService}
            />
          </PatientProfileProgramsItemComponent>
        </Col>
        <Col span={12} className="flex gap0 jc-e ai-e f-w profile-program-buttons">
          <PauseServiceDrawerComponent
            memberId={patientInfo.id}
          />
          <UnableToEngageModalContainer patientId={patientInfo.id} />
          <DischargeModalButtonComponent
            patientInfo={patientInfo}
          />
        </Col>
      </Row>
      <Col span={24}>
        <Divider />
        <div className="flex jc-sb ai-c">
          {
            isEditing
            && (
              <NestedFormSubmitButtonComponent
                onCancel={onCancel}
                submitText="Save"
                reverseOrder
                noMargin
                isLoading={isLoading}
              />
            )
          }
        </div>
      </Col>
    </NestedFormControlProvider>
  );
};
