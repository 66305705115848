import { useMemo, useState } from 'react';
import { intersection, merge } from 'lodash';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import {
  EnrollmentActionEnum,
  usePatientUpdateEnrollment,
  usePatientUpdateHealthCondition,
  usePatientUpdatePatientComplexity
} from '../../../../uc-api-sdk';
import { EnrollmentWrapperComponent } from '../../component/EnrollmentWrapperComponent/EnrollmentWrapperComponent';
import {
  ProgramEnrollmentFormComponent, ProgramEnrollmentFormComponentProps, SubmitValue
} from '../../component/ProgramEnrollmentFormComponent/ProgramEnrollmentFormComponent';
import { useEnrollmentProgressContext } from '../../context/EnrollmentProgressContext/EnrollmentProgressContext';
import { EnrollmentSectionsEnum } from '../../context/EnrollmentProgressContext/type';
import { EnrollmentReq } from '../../type';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useUpdate, useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { useBlockUnmountContext } from '../../../../contexts/BlockUnmountContext/BlockUnmountContext';
import { usePatientMedicalOrganizationContext } from '../../../../contexts/PatientMedicalOrganizationContext/PatientMedicalOrganizationContext';

export interface ProgramEnrollmentFormContainerProps {
  patientInfo: PatientInfo;
  onSubmit?: () => void;
  onReject?: () => void;
  onClose?: (refetch?: boolean) => void;
}

export const ProgramEnrollmentFormContainer = ({
  patientInfo,
  onSubmit,
  onReject,
  onClose,
}: ProgramEnrollmentFormContainerProps) => {
  const { send: sendMixpanel } = useMixpanelContext();
  const [currValue, setCurrValue] = useState<SubmitValue>();
  const programEnrollmentUpdate = usePatientUpdateEnrollment({});
  const healthConditionUpdateInfo = usePatientUpdateHealthCondition({});
  const patientComplexityUpdateInfo = usePatientUpdatePatientComplexity({});
  const { sections, onSectionDone } = useEnrollmentProgressContext();
  const { isLoading } = programEnrollmentUpdate;
  const { blockPrompt } = useBlockUnmountContext();
  const icdUpdateHook = useUpdate('ICD_UPDATED');
  const {
    medicalOrganizationService,
  } = usePatientMedicalOrganizationContext();

  useUpdateListener('INSURANCE_ELIGIBILITY_UPDATED', () => {
    patientInfo.refetch(['patientInsuranceRefetch']);
  });

  const handleVitalsAndProgramsComplete = () => {
    onSectionDone(EnrollmentSectionsEnum.Diagnosis);
    onSectionDone(EnrollmentSectionsEnum.VitalsAndPrograms);
  };

  const sendReq = async (value: EnrollmentReq<SubmitValue>) => {
    sendMixpanel({ event: MixpanelEvents.PatientEnrollmentSubmit });
    // TODO: [t] Replace hcRes and pcRes in next enhancement
    let hcRes;
    let pcRes;
    if (value.icdTable) {
      hcRes = await ApiRequestHelper.tryCatch(healthConditionUpdateInfo.send({
        params: {
          memberId: patientInfo.id,
          request: { healthConditions: value.icdTable },
        },
      }), 'Failed to update icd code');
      if (!hcRes) {
        return undefined;
      }
    }
    if (value.complexity) {
      pcRes = await ApiRequestHelper.tryCatch(patientComplexityUpdateInfo.send({
        params: {
          memberId: patientInfo.id,
          request: {
            level: value?.complexity?.level,
            source: value?.complexity?.source,
          }
        },
      }), 'Failed to update complexity');
      if (!pcRes) {
        return undefined;
      }
    }
    if (hcRes) {
      icdUpdateHook.updateValue();
    }

    const toSavePrograms = value.programs
      ? intersection(value.programs, medicalOrganizationService?.availablePrograms())
      : value.programs;

    return ApiRequestHelper.tryCatch(
      programEnrollmentUpdate.send({
        params: {
          memberId: patientInfo.id,
          request: {
            action: value.action,
            basicInformation: {
              providerId: value.provider,
              spokenLanguages: value.spokenLanguage,
              username: value.username,
              mobilePhone: value.mobilePhoneNum,
              homePhone: value.homePhoneNum,
              countryCode: (
                (value.mobilePhoneNum || value.homePhoneNum)
                  ? '1'
                  : undefined
              ),
              allowOTP: value.appOTPLogin,
              clinicId: value.clinicId,
            },
            programEnrollment: {
              patientDiagnoses: value.diagnoses,
              vitalTypes: value.vitals,
              programCategories: toSavePrograms,
              ableToJoinProgram: value.wouldJoin,
              reason: value.notJoiningReason,
              hasSmartDevice: value.hasSmartPhone,
              // empty array to remove if addOnServices is not available
              addOnServices: value.addOnServices || [],
            },
          },
        },
      }),
      {
        success: '',
        error: 'Failed to update enrollment!',
        onSuccess: () => {
          setCurrValue(undefined);
        }
      }
    );
  };

  const initialValues = useMemo<ProgramEnrollmentFormComponentProps['initialValues']>(() => ({
    diagnoses: patientInfo.enrollmentInfo?.programEnrollment?.patientDiagnoses ?? undefined,
    complexity: patientInfo?.patientComplexity,
    icdTable: patientInfo?.patientHealthCondition?.healthConditions ?? [],
    vitals: patientInfo.enrollmentInfo?.programEnrollment?.vitalTypes ?? [],
    programs: patientInfo.enrollmentInfo?.programEnrollment?.programCategories ?? [],
    clinicId: patientInfo.patientInfoService?.getClinicId() ?? undefined,
    wouldJoin: patientInfo.enrollmentInfo?.programEnrollment?.ableToJoinProgram
      ?? undefined,
    notJoiningReason: patientInfo.enrollmentInfo?.programEnrollment?.reason
      ?? undefined,
    provider: patientInfo.patientInfo?.profile?.doctorId ?? undefined,
    spokenLanguage: patientInfo.patientInfoService?.getSpokenLanguage() ?? undefined,
    username: patientInfo.patientInfo?.loginId ?? undefined,
    mobilePhoneNum: patientInfo.patientInfoService?.getMobilePhoneNumber() ?? undefined,
    homePhoneNum: patientInfo.patientInfoService?.getHomePhoneNumber() ?? undefined,
    appOTPLogin: patientInfo.patientInfoService?.getMobilePhoneOTP() ?? true,
    hasSmartPhone: patientInfo.enrollmentInfo?.programEnrollment?.hasSmartDevice ?? undefined,
    addOnServices: patientInfo.enrollmentInfo?.programEnrollment?.addOnServices ?? [],
  }), [patientInfo]);

  const handleValuesChange: ProgramEnrollmentFormComponentProps['onValuesChange'] = (
    changedValues,
    allValues,
  ) => {
    setCurrValue((prev) => (
      merge(
        prev,
        allValues,
        changedValues
      )
    ));
  };

  const handleSubmit = async (value: SubmitValue) => {
    const res = await sendReq({ ...value, action: EnrollmentActionEnum.ENROLL });
    // if there was no error
    if (res !== undefined) {
      onSubmit?.();
    }
  };

  const handleReject = async (value: SubmitValue) => {
    const res = await sendReq({ ...value, action: EnrollmentActionEnum.REJECT });
    // if there was no error
    if (res !== undefined) {
      onReject?.();
    }
  };

  const handleClose = async () => {
    blockPrompt({
      next: async () => {
        let shouldRefetch = false;
        if (currValue) {
          await sendReq(currValue);
          shouldRefetch = true;
        }
        onClose?.(shouldRefetch);
      }
    });
  };

  return (
    <EnrollmentWrapperComponent
      onClose={handleClose}
    >
      <ProgramEnrollmentFormComponent
        onValuesChange={handleValuesChange}
        patientId={patientInfo.id}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onReject={handleReject}
        isLoading={isLoading}
        patientInfo={patientInfo}
        isVitalsAndProgramsDone={sections.VitalsAndPrograms}
        onVitalsAndProgramsDone={handleVitalsAndProgramsComplete}
      />
    </EnrollmentWrapperComponent>
  );
};
