import { Button } from 'antd';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { useDownloadCsvFile } from '../../../../hooks/useDownloadCsvFile/useDownloadCsvFile';
import { FileDownloadUrlOutput } from '../../../../uc-api-sdk';

export interface DownloadCsvButtonContainerProps {
  fileInfo?: FileDownloadUrlOutput;
}

export const DownloadCsvButtonContainer = ({
  fileInfo
}: DownloadCsvButtonContainerProps) => {
  const { handleDownload } = useDownloadCsvFile();
  const handleClick = useDebounce(() => {
    if (fileInfo) {
      handleDownload(fileInfo);
    }
  });

  return (fileInfo
    ? <Button type="link" onClick={handleClick}>Download</Button> : null
  );
};
