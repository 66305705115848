import { Rule } from 'antd/lib/form';
import { Dayjs } from 'dayjs';
import { useCallback, useMemo } from 'react';
import { insurancePolicyNumber } from '../../constants/regex/policyNumber';
import { CPTCodesValue } from '../../features/Insurance/component/CPTCodeComponent/CPTCodeUnitsComponent/CPTCodeUnitsComponent';
import { useInsuranceHelper } from '../../features/Insurance/hook/useInsuranceHelper';
import { GetFieldValue } from '../../types/form';
import {
  CptCodeUnitEnum,
  InsuranceInfo,
  NonCovered,
  PriorAuthCase, PriorAuthCaseStatusEnum
} from '../../uc-api-sdk';
import { FormOptions } from '../useFormHookFactory/useFormHookFactory';
import { useIterableFormHook } from '../useIterableFormListHook/useIterableFormHook';

const SELF_PAY_PROVIDER_NAME = 'Self Pay';

type FormDate = Dayjs | string | undefined;

export interface TempPriorAuthCase extends PriorAuthCase {
  // TODO: [t][4199] BE doesn't give this field
  // -1 means expired by 1 day
  // 0 means expire today
  // 2 means expire in 2 day
  // undefined means there is no expiration day
  expirationDays?: number;

  cptCodeCategory?: CptCodeUnitEnum | null;
}

export interface PriorAuthSubmitValue {
  priorAuth?: boolean;
  priorAuthStatus?: PriorAuthCaseStatusEnum;
  priorAuthSubmissionDate?: FormDate;
  priorAuthDeniedDate?: FormDate;
  priorAuthApprovalDate?: FormDate;
  priorAuthAuthorizationNumber?: string;
  priorAuthAuthPeriod?: [FormDate, FormDate];
  priorAuthCPTCodeCategory?: CptCodeUnitEnum;
  priorAuthCPTCodeUnits?: CPTCodesValue;
  priorAuthDeniedReason?: string;
  priorAuthComments?: string;
  // readonly
  priorAuthActiveCase?: PriorAuthCase;
  priorAuthHistoryCases?: PriorAuthCase[];
}

export interface InsuranceProvider {
  id: string;
  name: string;
}

// (optional) PriorAuthSubmitValue
export type InsuranceCardSubmitValue = InsuranceInfo & PriorAuthSubmitValue & {
  insuranceProviderId?: string; // provider id is matching with patient's providerId
};

export interface InsuranceSubmitValue extends PriorAuthSubmitValue, NonCovered {
  insuranceList?: InsuranceCardSubmitValue[];
  note?: string;
  markMedCareEligible?: boolean;
}

export interface InquiringProviderValue {
  providerName: string;
  providerNpi: string;
}

export interface InsuranceEligibilityRequestSubmitValue extends InsuranceCardSubmitValue {
  firstName: string;
  lastName: string;
  birthday: string | Dayjs;
  inquiringProvider?: InquiringProviderValue;
}

type ShouldRequire = (getFieldValue: GetFieldValue, fieldName?: number) => boolean;

const formName = 'insuranceList';

export const useInsuranceForm = (options?: FormOptions) => {
  const {
    shouldPriorAuthCaseBeCancellable,
  } = useInsuranceHelper();
  const factory = useIterableFormHook(formName, {
    provider: {
      name: 'provider',
      label: 'Insurance plan',
      required: true,
    },
    insuranceProviderId: { // read-only
      name: 'insuranceProviderId',
      label: '',
    },
    insuranceId: { // read-only
      name: 'insuranceId',
      label: '',
    },
    benefitOrder: {
      name: 'benefitOrder',
      label: 'Order of benefits',
      required: true,
    },
    eligibilityStatus: {
      name: 'eligibilityStatus',
      label: 'Eligibility status',
      required: true,
      tooltip: {
        tip: 'Please select the status based on your knowledge of the insurance eligibility. If you are unsure about the insurance coverage, you can use the third-party clearinghouse service to validate patient insurance coverage. This status selection is important for generating the billing report.'
      }
    },
    wayStarInquiryId: { // read-only
      name: 'wayStarInquiryId',
      label: '',
    },
    policyNumber: {
      name: 'policyNumber',
      label: 'Policy #',
    },
    patientRelationship: {
      name: 'patientRelationship',
      label: 'Relationship to insured',
    },
    firstName: {
      name: 'firstName',
      label: "Patient's first name",
      required: true,
    },
    lastName: {
      name: 'lastName',
      label: "Patient's last name",
      required: true,
    },
    birthday: {
      name: 'birthday',
      label: "Patient's DOB",
      required: true,
    },
    priorAuth: {
      name: 'priorAuth',
      label: 'Prior-authorization required',
    },
    priorAuthStatus: {
      name: 'priorAuthStatus',
      label: 'Status',
      required: true,
    },
    priorAuthSubmissionDate: {
      name: 'priorAuthSubmissionDate',
      label: 'Submission date',
    },
    priorAuthDeniedDate: {
      name: 'priorAuthDeniedDate',
      label: 'Denied date',
    },
    priorAuthApprovalDate: {
      name: 'priorAuthApprovalDate',
      label: 'Approval date',
      required: true,
    },
    priorAuthAuthorizationNumber: {
      name: 'priorAuthAuthorizationNumber',
      label: 'Authorization #',
      required: true,
    },
    priorAuthAuthPeriod: {
      name: 'priorAuthAuthPeriod',
      label: 'Auth period',
      required: true,
    },
    priorAuthCPTCodeCategory: {
      name: 'priorAuthCPTCodeCategory',
      label: 'CPT code units',
    },
    priorAuthCPTCodeUnits: {
      name: 'priorAuthCPTCodeUnits',
      label: 'CPT code units',
    },
    priorAuthDeniedReason: {
      name: 'priorAuthDeniedReason',
      label: 'Denied reason',
    },
    priorAuthActiveCase: {
      name: 'priorAuthActiveCase',
      label: '',
    },
    priorAuthHistoryCases: {
      name: 'priorAuthHistoryCases',
      label: '',
    },
    priorAuthComments: {
      name: 'priorAuthComments',
      label: 'Comments',
    },
    inquiringProvider: {
      name: 'inquiringProvider',
      label: 'Inquiring provider name',
    },
  }, options);

  const shouldDisablePriorAuthStatus: ShouldRequire = useCallback((
    getFieldValue,
    fieldName
  ) => (
    // when priorAuthActiveCase is temporarily saved, it will be undefined
    !factory.getObjectValue('priorAuthActiveCase', getFieldValue, fieldName)
  ), []);

  const isCaseReadOnly = useCallback((priorAuthCase: PriorAuthCase) => (
    [
      PriorAuthCaseStatusEnum.APPROVED,
      PriorAuthCaseStatusEnum.DENIED
    ].includes(priorAuthCase?.status || '' as PriorAuthCaseStatusEnum)
  ), []);

  const shouldAllowCancelPriorAuthCase: ShouldRequire = useCallback((
    getFieldValue,
    fieldName
  ) => {
    const priorAuthActiveCase = (
      factory.getObjectValue('priorAuthActiveCase', getFieldValue, fieldName)
    ) as TempPriorAuthCase;
    return shouldPriorAuthCaseBeCancellable(priorAuthActiveCase);
  }, [factory]);

  const doesHaveReadOnlyActiveCase: ShouldRequire = useCallback((
    getFieldValue,
    fieldName
  ) => {
    const priorAuthActiveCase = factory.getObjectValue('priorAuthActiveCase', getFieldValue, fieldName);
    return isCaseReadOnly(priorAuthActiveCase);
  }, [factory]);

  const shouldConfirmSubmit: ShouldRequire = useCallback((
    getFieldValue,
    fieldName
  ) => {
    const activeCase = factory.getObjectValue('priorAuthActiveCase', getFieldValue, fieldName);
    const priorAuthStatus = factory.getObjectValue('priorAuthStatus', getFieldValue, fieldName);
    if (isCaseReadOnly(activeCase)) {
      // case exists and readonly
      return false;
    }
    if (isCaseReadOnly({ status: priorAuthStatus })) {
      // if new case is approved or denied
      return true;
    }
    return false;
  }, [factory]);

  const getPolicyNumberRules = useCallback((
    isRequired = false,
    shouldValidate = false,
  ): Rule[] => ([
    {
      validator: (rule, value) => (
        new Promise((resolve, reject) => {
          if (!value && isRequired) {
            reject(new Error('Missing!'));
          }
          if (value && shouldValidate && !insurancePolicyNumber.test(value)) {
            const label = factory.getLabel('policyNumber');
            reject(new Error(`${label} is invalid!`));
          }
          resolve(true);
        })
      ),
    }
  ]), []);

  // provider is manually input instead of being selected from payer search list
  const isManualInput = useCallback((
    getFieldValue: GetFieldValue,
    fieldName?: number,
  ) => {
    const provider = factory.getObjectValue(
      'provider',
      getFieldValue,
      fieldName
    );
    const providerId = factory.getObjectValue(
      'insuranceProviderId',
      getFieldValue,
      fieldName
    );
    return !!provider && !providerId;
  }, [factory]);

  const isSelfPay = useCallback((
    insuranceValue?: InsuranceCardSubmitValue,
  ) => (
    SELF_PAY_PROVIDER_NAME === insuranceValue?.provider
    // if self pay is selected from manual input, providerId will be undefined
    && insuranceValue?.insuranceProviderId === null
  ), []);

  const shouldDisableVerify = useCallback((
    getFieldValue: GetFieldValue,
    fieldName?: number
  ) => {
    const fields = [
      'firstName',
      'lastName',
      'birthday',
      'provider',
      'benefitOrder',
      'policyNumber',
      'patientRelationship'
    ] as Partial<keyof typeof factory.formInput>[];
    if (isManualInput(getFieldValue, fieldName)) {
      return true;
    }
    return fields.some((field) => {
      type FieldType = keyof typeof factory.formInput;
      return (
        !factory.getObjectValue(
          factory.getName(field as FieldType) as FieldType,
          getFieldValue,
          fieldName,
        )
      );
    });
  }, [factory]);

  const res = useMemo(() => ({
    ...factory,
    shouldDisablePriorAuthStatus,
    doesHaveReadOnlyActiveCase,
    shouldAllowCancelPriorAuthCase,
    shouldConfirmSubmit,
    isManualInput,
    shouldDisableVerify,
    getPolicyNumberRules,
    isSelfPay,
  }), [factory]);

  return res;
};
