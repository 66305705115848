import { Button } from 'antd';
import { Icons } from '../../../../icons/Icons';
import { BatchSentConsentHistoryTableContainer } from '../BatchSentConsentHistoryTableContainer/BatchSentConsentHistoryTableContainer';

export interface BatchSentConsentHistoryContainerProps {
  onExit?: () => void;
}

export const BatchSentConsentHistoryContainer = ({
  onExit
}: BatchSentConsentHistoryContainerProps) => (
  <>
    <Button onClick={onExit} type="text" className="p0 mb20 flex ai-c">
      <Icons.LeftArrowSquare hexColor="#2D3238" />
      Exit
    </Button>
    <div>
      <BatchSentConsentHistoryTableContainer />
    </div>
  </>
);
