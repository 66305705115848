import { ReactNode } from 'react';
import { Drawer } from 'antd';
import { LargeDrawerWidth } from '../../../../constants/drawer';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { ConsentFormContainer } from '../../container/ConsentFormContainer/ConsentFormContainer';
import { ReviewConsentDrawerComponent } from '../ReviewConsentComponent/ReviewConsentDrawerComponent';

export interface ReviewAndResignConsentComponentProps {
  patientInfo: PatientInfo;
  children: ReactNode;
  onResign?: () => void;
  showSentModal?: boolean;
}

export const ReviewAndResignConsentComponent = ({
  patientInfo,
  children,
  onResign,
  showSentModal = true,
}: ReviewAndResignConsentComponentProps) => {
  const {
    isOpen,
    open,
    close,
  } = useOpen();

  const handleOnSubmit = () => {
    close();
    onResign?.();
  };

  return (
    <>
      <ReviewConsentDrawerComponent
        patientInfo={patientInfo}
        onResignOpen={open}
      >
        {children}
      </ReviewConsentDrawerComponent>
      <Drawer
        open={isOpen}
        onClose={close}
        width={LargeDrawerWidth}
        destroyOnClose
      >
        <ConsentFormContainer
          patientInfo={patientInfo}
          showSentModal={showSentModal}
          onSubmit={handleOnSubmit}
        />
      </Drawer>
    </>
  );
};
