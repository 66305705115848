import { Button } from 'antd';
import './PatientListUploadButtonComponent.scss';

export const PatientListUploadButtonComponent = () => (
  <div className="PatientListUploadButtonComponent mt20 flex jc-sb ai-c w100">
    <div>
      <span className="b6">Upload file here.</span>
      {' '}
      <span className="text-gray-scale-2">Supported file formats: CSV.</span>
    </div>
    <Button type="link">Upload</Button>
  </div>
);
