import {
  Button,
  Form,
  Input,
  Space,
} from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { trim } from 'lodash';
import { Dayjs } from 'dayjs';
import { SignatureCollectionMethodEnum } from '../../../../uc-api-sdk';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { Title } from '../../../../uiComponent/Title/Title';
import { useConsentForm } from '../../../enrollment/hook/useConsentForm/useConsentForm';
import { FormType } from '../../../Input/types';
import { ConsentTempContainer } from '../../container/ConsentTempContainer/ConsentTempContainer';
import { ConsentFormSignatureRadioComponent } from './ConsentFormSignatureRadioComponent';
import { ConsentFormPaperFormItemComponent } from '../ConsentFormPaperFormItemComponent/ConsentFormPaperFormItemComponent';

export interface SubmitValue {
  signatureMethod: SignatureCollectionMethodEnum;
  initials?: string;
  consentContent?: string;
  signDate?: Dayjs
}

export interface ConsentFormComponentProps extends FormType<SubmitValue> {
  patientId: string;
}

export const ConsentFormComponent = ({
  initialValues,
  onSubmit,
  isLoading,
  patientId,
}: ConsentFormComponentProps) => {
  const consentForm = useConsentForm();
  const signatureMethod = useWatch(consentForm.getName('signatureMethod'), consentForm.form);
  const initials = useWatch(consentForm.getName('initials'), consentForm.form);

  const shouldDisableSubmit = () => {
    if (isLoading) {
      return true;
    }
    switch (signatureMethod) {
      case SignatureCollectionMethodEnum.TEAM_MEMBER_DEVICE:
        return !trim(initials);
      default:
        return false;
    }
  };

  return (
    <Form
      form={consentForm.form}
      initialValues={initialValues}
      onFinish={consentForm.handleSubmit(onSubmit)}
      disabled={isLoading}
      className="h100"
      layout="vertical"
      colon={false}
    >
      <div className="flex gap0 fd-c h100">
        <FixedComponent>
          <FixedComponent.Child isFixed>
            <Title>Consent Form</Title>
            <h3>Signature Collection Method</h3>
            <FormItem noStyle info={consentForm.getInfo('signatureMethod')}>
              <ConsentFormSignatureRadioComponent />
            </FormItem>
          </FixedComponent.Child>
          <FixedComponent.Child className="mt20 mb10">
            <FormItem
              noStyle
              shouldUpdate={consentForm.shouldUpdate(['signatureMethod'])}
            >
              {({ getFieldValue }) => {
                const signatureMethod = getFieldValue(consentForm.getName('signatureMethod'));
                if (signatureMethod === SignatureCollectionMethodEnum.CLINIC_PRINTED) {
                  return (<ConsentFormPaperFormItemComponent />);
                }
                return (
                  <ConsentTempContainer
                    signatureMethod={signatureMethod}
                    patientId={patientId}
                  />
                );
              }}
            </FormItem>
          </FixedComponent.Child>
          <FixedComponent.Child isFixed>
            {
              signatureMethod === SignatureCollectionMethodEnum.TEAM_MEMBER_DEVICE && (
                <FormItem info={consentForm.getInfo('initials')}>
                  <Input
                    className="w140 mb20"
                    placeholder={consentForm.getInfo('initials').placeholder}
                  />
                </FormItem>
              )
            }
            <Space size="large" align="center">
              <Button
                type="primary"
                htmlType="submit"
                disabled={shouldDisableSubmit()}
                loading={isLoading}
              >
                Submit
              </Button>
              <div>
                A text message containing the App download link will be sent to the patient
              </div>
            </Space>
          </FixedComponent.Child>
        </FixedComponent>
      </div>
    </Form>
  );
};
