import {
  Col, Form, InputNumber, Row
} from 'antd';
import { useEffect } from 'react';
import { MixpanelEvents, MixpanelEventsParents } from '../../../../../contexts/MixpanelContext/MixpanelEvents';
import { useMpTrackingHelper } from '../../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import { DietEnum, FrequencyUnitEnum, MealIntake } from '../../../../../uc-api-sdk';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { FreqSelectComponent } from '../../../../../uiComponent/FreqSelectComponent/FreqSelectComponent';
import { HistoryDrawerComponent } from '../../../../../uiComponent/HistoryComponent/HistoryDrawerComponent';
import { HistoryInfo } from '../../../../../uiComponent/HistoryComponent/HistoryItemComponent';
import {
  NutritionUnderstandingSelectComponent
} from '../../../../../uiComponent/NutritionUnderstandingSelectComponent/NutritionUnderstandingSelectComponent';
import { TextareaComponent } from '../../../../../uiComponent/TextareaComponent/TextareaComponent';
import { TitleWithContent } from '../../../../../uiComponent/TitleWithContent/TitleWithContent';
import { InterventionFormComponentProps } from '../../../type';
import { InterventionSubmitCancelButtons } from '../../InterventionSubmitCancelButtons/InterventionSubmitCancelButtons';
import { CalorieManagementComponent } from '../CalorieManagementComponent/CalorieManagementComponent';
import { useNutritionHook } from '../hook/useNutritionHook';
import { IntakeFormTableComponent } from '../IntakeFormTableComponent/IntakeFormTableComponent';
import '../NutritionComponent/NutritionComponent.scss';

export interface IntakeTableProps extends MealIntake{
  key: string;
}
export interface SubmitValue {
  intake: MealIntake[];
  Breakfast_foodTypeAmount?: string;
  Breakfast_mealFreq?: string;
  Breakfast_skipFreq?: string;
  Breakfast_timeRange?: string;
  Dinner_foodTypeAmount?: string;
  Dinner_mealFreq?: string;
  Dinner_skipFreq?: string;
  Dinner_timeRange?: string;
  Lunch_foodTypeAmount?: string;
  Lunch_mealFreq?: string;
  Lunch_skipFreq?: string;
  Lunch_timeRange?: string;
  Snack_foodTypeAmount?: string;
  Snack_mealFreq?: string;
  Snack_skipFreq?: string;
  Snack_timeRange?: string;
  additionalComments?: string;
  fastFoodFreq?: number;
  fastFoodFreqUnit?: FrequencyUnitEnum;
  nutritionUnderstanding?: string;
  previousDiets?: DietEnum[];
  sweetBeverageFreq?: number;
  sweetBeverageFreqUnit?: FrequencyUnitEnum;
}
export interface NutritionFormComponentProps extends
  InterventionFormComponentProps<SubmitValue> {
  history?: HistoryInfo[];
  memberId: string;
  parent?: MixpanelEventsParents;
}

export const NutritionFormComponent = ({
  onSubmit,
  initialValues,
  isLoading,
  onCancel,
  history,
  onValuesChange,
  memberId,
  formButtons = <InterventionSubmitCancelButtons onCancel={onCancel} />,
  parent,
}: NutritionFormComponentProps) => {
  const formHook = useNutritionHook();
  const { startEvent, endEvent } = useMpTrackingHelper({
    eventStart: MixpanelEvents.NutritionStart,
    eventEnd: MixpanelEvents.NutritionEnd,
    parent,
    patientId: memberId,
  });

  useEffect(() => {
    if (parent) {
      startEvent();
      return endEvent;
    }
    return undefined;
  }, [parent]);

  return (
    <Form
      className="nutrition-component"
      name="nutrition-form"
      layout="vertical"
      form={formHook.form}
      onFinish={formHook.handleSubmit(onSubmit)}
      initialValues={initialValues}
      disabled={formHook.formDisabled || isLoading}
      onValuesChange={onValuesChange}
    >
      <FormItem>
        <IntakeFormTableComponent form={formHook.form} />
      </FormItem>
      <FormItem>
        <Row className="pt20" gutter={[16, 16]}>
          <Col span={6}>
            <TitleWithContent
              title="Fast Food Frequency"
              content={(
                <FormItem name="fastFoodFreq">
                  <InputNumber
                    precision={0}
                    min={0}
                    max={10000}
                    placeholder="Times"
                    defaultValue={initialValues?.fastFoodFreq}
                    addonAfter={(
                      <FormItem name="fastFoodFreqUnit" noStyle>
                        <FreqSelectComponent
                          value={initialValues?.fastFoodFreqUnit}
                        />
                      </FormItem>
                    )}
                  />
                </FormItem>
              )}
            />
          </Col>
          <Col span={6}>
            <TitleWithContent
              title="Sweet Beverage Frequency"
              content={(
                <FormItem name="sweetBeverageFreq">
                  <InputNumber
                    precision={0}
                    min={0}
                    max={10000}
                    placeholder="Times"
                    defaultValue={initialValues?.sweetBeverageFreq}
                    addonAfter={(
                      <FormItem name="sweetBeverageFreqUnit" noStyle>
                        <FreqSelectComponent
                          value={initialValues?.sweetBeverageFreqUnit}
                        />
                      </FormItem>
                    )}
                  />
                </FormItem>
              )}
            />
          </Col>
        </Row>
      </FormItem>
      <div className="pt20 flex-wrap">
        <TitleWithContent
          title="Previous Calorie Management Regimens"
          content={(
            <FormItem name="previousDiets" shouldUpdate>
              <CalorieManagementComponent
                defaultCheckedList={initialValues?.previousDiets?.map(it => it.valueOf())}
              />
            </FormItem>
          )}
        />
      </div>

      <div className="pt20">
        <TitleWithContent
          title="Nutrition Understanding"
          content={(
            <FormItem name="nutritionUnderstanding">
              <NutritionUnderstandingSelectComponent
                defaultValue={initialValues?.nutritionUnderstanding}
              />
            </FormItem>
          )}
        />
      </div>
      <div className="pt20">
        <TitleWithContent
          title="Additional Comments"
        />
        <FormItem name="additionalComments">
          <TextareaComponent
            placeholder="Enter more comments."
            defaultValue={formHook.form.getFieldValue('additionalComments')}
            autoSize={{ minRows: 3, maxRows: 6 }}
          />
        </FormItem>
      </div>
      <HistoryDrawerComponent history={history} />
      {formButtons}
    </Form>
  );
};
