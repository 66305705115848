import { Space, Typography } from 'antd';
import {
  CheckCircleFilled, ClockCircleFilled
} from '@ant-design/icons';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { EnrollmentConsentFormContainer } from '../../../enrollment/container/EnrollmentConsentFormContainer/EnrollmentConsentFormContainer';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { logWarn } from '../../../../lib/helpers/logging';
import { AlertCardComponent } from '../../../../uiComponent/AlertCardComponent/AlertCardComponent';
import { ConsentFormDrawerComponent } from '../ConsentFormDrawerComponent/ConsentFormDrawerComponent';
import { ReviewConsentDrawerComponent } from '../ReviewConsentComponent/ReviewConsentDrawerComponent';

import './ConsentFormStatusBarComponent.scss';

export interface ConsentFormStatusBarComponentProps {
  patientInfo?: PatientInfo;
  onSubmit?: () => void;
  onClose?: () => void;
}

export const ConsentFormStatusBarComponent = ({
  patientInfo,
  onSubmit,
  onClose,
}: ConsentFormStatusBarComponentProps) => {
  const {
    isOpen,
    open,
    close,
  } = useOpen();

  const {
    enrolledProgramService,
    consentService
  } = patientInfo || {};

  const isEnrolled = enrolledProgramService?.isEnrolled();

  const handleClose = () => {
    close();
    onClose?.();
  };

  const handleOnSubmit = () => {
    onSubmit?.();
    handleClose();
  };

  if (!patientInfo || !enrolledProgramService || !consentService) {
    logWarn('Missing required info or services');
    return null;
  }

  const renderConsentFormDrawer = () => (
    <ConsentFormDrawerComponent
      open={isOpen}
      onClose={handleClose}
    >
      <EnrollmentConsentFormContainer
        patientInfo={patientInfo}
        showSentModal={!isEnrolled}
        onSubmit={handleOnSubmit}
      />
    </ConsentFormDrawerComponent>
  );

  // enrolled and has new consent form not signed
  if (
    isEnrolled
    && consentService?.isUpdatedConsent()
    && !consentService.isSigned()
  ) {
    return (
      <>
        <AlertCardComponent
          type="custom"
          className="consent-form-status-bar updated-consent"
          // eslint-disable-next-line no-inline-styles/no-inline-styles
          icon={<CheckCircleFilled style={{ color: 'var(--secondary-orange)' }} />}
          message={(
            <Space>
              Patient is enrolled but needs to sign a new consent form.
              <Typography.Text
                className="pointer"
                underline
                onClick={open}
              >
                Sign consent form
              </Typography.Text>
            </Space>
          )}
        />
        {renderConsentFormDrawer()}
      </>
    );
  }

  if (
    isEnrolled
    && consentService?.isSigned()
  ) {
    return (
      <>
        <AlertCardComponent
          type="success"
          className="consent-form-status-bar signed-consent"
          message={(
            <Space>
              Consent signed and patient is enrolled.
              <ReviewConsentDrawerComponent
                patientInfo={patientInfo}
                onResignOpen={open}
              >
                <Typography.Text
                  className="pointer"
                  underline
                >
                  View Signed Consent Form
                </Typography.Text>
              </ReviewConsentDrawerComponent>
            </Space>
          )}
        />
        {renderConsentFormDrawer()}
      </>
    );
  }

  if (
    !isEnrolled
    && consentService?.hasPendingConsent()
  ) {
    // pending, waiting for patient to sign
    return (
      <>
        <AlertCardComponent
          type="custom"
          className="consent-form-status-bar pending-consent"
          // eslint-disable-next-line no-inline-styles/no-inline-styles
          icon={<ClockCircleFilled style={{ color: 'var(--secondary-yellow)' }} />}
          message={(
            <Space>
              Consent form not signed and patient enrollment pending.
              <Typography.Text
                className="pointer"
                underline
                onClick={open}
              >
                Sign consent form
              </Typography.Text>
            </Space>
          )}
        />
        {renderConsentFormDrawer()}
      </>
    );
  }

  return null;
};
