import { EditOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { TagComponent } from '../../../../uiComponent/TagComponent/TagComponent';
import { ClinicEventsService } from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';

import './OnboardingVisitStatusComponent.scss';
import { TimeUpdaterComponent } from '../../../../uiComponent/TimeUpdaterComponent/TimeUpdaterComponent';
import { ChartingStatusEnum } from '../../../../enumComponent/ChartingStatusEnumComponent/ChartingStatusEnum';
import { VisitStatusEnum } from '../../../../uc-api-sdk';
import { Icon } from '../../../../icons/Icon';
import { CLOCK_ICON } from '../../../../assets/commonIconInfo';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';

export interface OnboardingVisitStatusComponentProps {
  clinicEvent?: ClinicEventsService;
  onEdit?: () => void;
}

export const OnboardingVisitStatusComponent = ({
  clinicEvent,
  onEdit,
}: OnboardingVisitStatusComponentProps) => {
  const {
    startTime,
    checkInTime,
    chartingStatus,
    visitsStatus,
  } = clinicEvent || {};

  const renderStartTime = () => (
    <DisplayDateComponent
      value={startTime}
      format="DATE_COMMA_TIME_AM_PM"
    />
  );

  const renderStatus = () => {
    const isCheckedOut = visitsStatus === VisitStatusEnum.CHECKED_OUT;
    const isCompleted = chartingStatus === ChartingStatusEnum.CHARTING;

    if (isCheckedOut && isCompleted) {
      // it can be completed without checkout for migrated visits, which is wrong
      return (
        <>
          <TagComponent
            type="success"
            background="none"
            className="fs12"
          >
            Completed
          </TagComponent>
          {renderStartTime()}
        </>
      );
    }

    if (isCheckedOut) {
      return (
        <>
          Incomplete
          {renderStartTime()}
        </>
      );
    }

    if (
      visitsStatus === VisitStatusEnum.ONGOING
      || checkInTime
    ) {
      return (
        <>
          <Badge
            status="processing"
            className="onboarding-visit-status-status-badge"
          />
          <div className="flex gap2">
            <span>Ongoing</span>
            {
              checkInTime
              && (
                <>
                  <span>|</span>
                  <div>
                    Duration:
                    {' '}
                    <span className="text-link">
                      <TimeUpdaterComponent
                        startTime={checkInTime}
                        showSecond
                      />
                    </span>
                  </div>
                </>
              )
            }
          </div>
        </>
      );
    }

    const statusText = (
      visitsStatus !== VisitStatusEnum.NO_SHOW
        ? 'Scheduled'
        : 'No show'
    );

    // Cover status:
    // NO_SHOW, UPCOMING, STARTING, WAITING
    return (
      <LinkButton
        className="text-gray-scale-2"
        onClick={onEdit}
        underlineOnHover
      >
        <div className="flex ai-c">
          <Icon
            info={CLOCK_ICON}
            size={14}
            className="clock-icon"
          />
          <span>
            {statusText}
            ,
          </span>
          {renderStartTime()}
          <EditOutlined />
        </div>
      </LinkButton>
    );
  };

  if (!clinicEvent) {
    return null;
  }

  return (
    <div className="flex ai-c">
      {renderStatus()}
    </div>
  );
};
