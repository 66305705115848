import { ConsentService } from '../../../services/ConsentService';

export const isMissingConsent = (consentService?: ConsentService) => {
  const isUpdatedConsent = consentService?.isUpdatedConsent();
  if (isUpdatedConsent) {
    // if consent is updated, there is already signed consent form
    return false;
  }
  return !consentService?.hasConsent() || !consentService?.isSigned();
};
