import { ClinicEvent } from '../../../uc-api-sdk';
import { ClinicEventsService } from '../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';

export interface WorklistComponentCommonProps {
  patientId: string;
  clinicEvent: ClinicEventsService;
  isLoading?: boolean;
  onClose?: () => void;
  onCheckOut?: (
    clinicEvent?: ClinicEvent,
  ) => Promise<ClinicEvent | undefined>;
  onClinicEventUpdate?: (
    values: ClinicEvent,
    shouldRefetchClinicEvent?: boolean,
  ) => Promise<ClinicEvent | undefined>;
  onCompleteCharting?: (
    values?: ClinicEvent,
    closeOnDone?: boolean,
  ) => void;
  disabled?: boolean;
}

export enum LifestyleAssessmentTabKey {
  Lifestyle = 'Lifestyle',
  NutritionIntake = 'NutritionIntake',
}
