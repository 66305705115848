import { DeviceCheckboxComponent } from '../DeviceCheckboxComponent/DeviceCheckboxComponent';
import { InputTypeArray } from '../../../Input/types';
import {
  AssignDeviceInfo,
  DeviceModelEnum, VitalEnumType
} from '../../../../uc-api-sdk';
import { BPDeviceCheckboxComponent } from '../BPDeviceCheckboxComponent/BPDeviceCheckboxComponent';
import { useDeviceCheckboxGroupHelper } from '../../hook/useDeviceCheckboxGroupHelper/useDeviceCheckboxGroupHelper';
import { enrollmentCommonDeviceMap } from './constant';
import { EnrollmentCGMDeviceCheckboxComponent } from '../EnrollmentCGMDeviceCheckboxComponent/EnrollmentCGMDeviceCheckboxComponent';

export interface DevicesCheckboxGroupComponentProps extends
  Partial<InputTypeArray<AssignDeviceInfo>> {
  vitals: VitalEnumType[];
}

export const DevicesCheckboxGroupComponent = ({
  value,
  onChange,
  vitals,
}: DevicesCheckboxGroupComponentProps) => {
  const {
    vitalsMap,
    getDevice,
    handleChange,
  } = useDeviceCheckboxGroupHelper(vitals, value, onChange);

  return (
    <>
      {
        [
          VitalEnumType.CGM,
          VitalEnumType.BP,
          VitalEnumType.BG,
          VitalEnumType.HS,
          VitalEnumType.PO,
          VitalEnumType.TM,
        ].map((vital) => {
          if (vitalsMap[vital] && vital === VitalEnumType.CGM) {
            return (
              <div className="mt10">
                <EnrollmentCGMDeviceCheckboxComponent
                  key={VitalEnumType.CGM}
                  value={getDevice(DeviceModelEnum.CGM)}
                  onChange={handleChange(DeviceModelEnum.CGM)}
                />
              </div>
            );
          }

          const { model, type } = enrollmentCommonDeviceMap[vital] || {};
          if (!vitalsMap[vital] || !model || !type) {
            return null;
          }

          if (vital === VitalEnumType.BP) {
            return (
              <BPDeviceCheckboxComponent
                key={DeviceModelEnum.BP3L}
                value={getDevice(DeviceModelEnum.BP3L)}
                onChange={handleChange(DeviceModelEnum.BP3L)}
              />
            );
          }

          return (
            <DeviceCheckboxComponent
              key={model}
              type={type}
              model={model}
              value={getDevice(model)}
              onChange={handleChange(model)}
            />
          );
        })
      }
    </>
  );
};
