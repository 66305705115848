import { Divider, Space } from 'antd';
import { FC, ReactNode } from 'react';
import { EXERCISED_ICON, HAD_INSULIN_ICON, HAD_MEDS_ICON } from '../../assets/commonIconInfo';
import { Icon } from '../../icons/Icon';
import EmptyComponent from '../EmptyComponent/EmptyComponent';
import { VitalEnumType } from '../../uc-api-sdk';

export interface MeasurementTagsComponentProps {
  hadActivity?: boolean;
  hadInsulin?: boolean;
  hadMedicine?: boolean;
  vitalType?: VitalEnumType;
  isIotDevice?: boolean;
}

export const MeasurementTagsComponent: FC<MeasurementTagsComponentProps> = ({
  hadActivity,
  hadInsulin,
  hadMedicine,
  vitalType = VitalEnumType.BG,
  isIotDevice,
}) => {
  const tags: ReactNode[] = [];
  const getMedicineTag = (hadMedicine: boolean | undefined) => {
    let message = '';
    if (isIotDevice) {
      return <EmptyComponent />;
    }
    if (hadMedicine === true) {
      message = `The patient has taken the ${vitalType} medication today`;
    } else if (hadMedicine === false) {
      message = `The patient hasn't taken the ${vitalType} medication today`;
    } else {
      message = `The patient wasn't on ${vitalType} medication`;
    }
    return (
      <Space key="medicine">
        <Icon src={HAD_MEDS_ICON.src} alt={HAD_MEDS_ICON.alt} />
        {message}
      </Space>
    );
  };
  tags.push(getMedicineTag(hadMedicine));
  if (hadInsulin) {
    tags.push((
      <Space key="insulin">
        <Icon src={HAD_INSULIN_ICON.src} alt={HAD_INSULIN_ICON.alt} />
        Took Insulin
      </Space>
    ));
  }
  if (hadActivity) {
    tags.push((
      <Space key="activity">
        <Icon src={EXERCISED_ICON.src} alt={EXERCISED_ICON.alt} />
        Exercised
      </Space>
    ));
  }

  return tags.length === 0
    ? <EmptyComponent />
    : <Space split={<Divider type="vertical" />}>{tags}</Space>;
};
