// @ts-nocheck
import { map } from 'lodash';
import { Image, Typography } from 'antd';
import React, { FC, useMemo } from 'react';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  createNewMarker,
  createWeightChartSeries,
  inClinicSourceList,
} from './HSChartHelper';
import { FromDateToDateDayjs } from '../../type';
import { FrequencyUnitEnum, ResultSourceEnum, WeightSummary } from '../../../../uc-api-sdk';
import { HSSummaryComponent } from '../HSSummaryComponent/HSSummaryComponent';
import { HSTableUnitDropdownComponent } from '../HSTableUnitDropdownComponent/HSTableUnitDropdownComponent';
import { useEventAnnotation } from '../../../../hooks/highcharts/useEventAnnotation';
import './WeightChartComponent.scss';
import BMIRanges from '../../../../assets/images/hs-bmi-ranges-tooltip.png';
import Legend from '../../../../assets/images/hs-weight-chart-legend.png';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';

const { Text } = Typography;

export interface WeightChartComponentProps {
  data: WeightSummary;
  unit: 'lb' | 'kg';
  onUnitChange?: (unit: 'lb' | 'kg') => void;
  fromDate: FromDateToDateDayjs['fromDate'];
  toDate: FromDateToDateDayjs['toDate'];
}

export interface WeightChartSummaryData {
  total: number;
  average: number;
  bmi: number;
  hsBaseline?: number;
  hsBaselineBmi?: number;
  changeFromBaseline?: number;
}

export const WeightChartComponent: FC<WeightChartComponentProps> = ({
  data,
  unit = 'kg',
  onUnitChange,
  fromDate,
  toDate,
}) => {
  const tickFrequency = data.weightChart?.tickFrequency || FrequencyUnitEnum.DAILY;

  const renderWeightSummaryAndUnitSelection = () => (
    <div className="flex jc-sb">
      <div className="w60">
        <HSSummaryComponent data={data} unit={unit} />
      </div>
      <div className="mb10 flex gap2 ai-c">
        <span>Unit </span>
        <HSTableUnitDropdownComponent onChange={onUnitChange} value={unit} />
      </div>
    </div>
  );

  const annotationDisplay = `
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" border-radius: "16" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="#E7EAED"/>
    <path d="M19.1611 8.27258C17.9414 8.27258 16.7944 8.74765 15.9327 9.60998L9.61043 15.9323C8.7481 16.7949 8.27271 17.9416 8.27271 19.161C8.27271 21.6787 10.3211 23.7271 12.8388 23.7271C14.0593 23.7271 15.2056 23.2521 16.0672 22.3897L22.3895 16.0678C23.2525 15.2051 23.7273 14.0584 23.7273 12.8387C23.7272 10.321 21.6788 8.27258 19.1611 8.27258ZM13.2079 15.3152L11.1005 17.4223C10.636 17.8867 10.3801 18.5045 10.3801 19.161C10.3801 19.3552 10.223 19.5123 10.0289 19.5123C9.83474 19.5123 9.67764 19.3552 9.67764 19.161C9.67764 18.3165 10.0062 17.5228 10.6038 16.9256L12.7112 14.8185C12.8484 14.6813 13.0707 14.6813 13.2079 14.8185C13.3451 14.9557 13.3451 15.178 13.2079 15.3152ZM21.3962 15.0744L18.4834 17.9868L14.0132 13.5162L16.9261 10.6033C17.5229 10.0061 18.3166 9.67752 19.1611 9.67752C20.9043 9.67752 22.3223 11.0955 22.3223 12.8387C22.3223 13.6832 21.9937 14.4772 21.3962 15.0744Z" fill="#5A5B5C"/>
  </svg>
  `;

  const convertedValue = (value: number) => {
    if (unit === 'lb') return (value / 0.453592).toFixed(1);
    return value.toFixed(1);
  };

  const getDataTooltipColor = (value: number) => {
    if (value < parseInt(convertedValue(data.weightChart?.lowLimit as number), 10)) return '#4E77BE';
    if (value < parseInt(convertedValue(data.weightChart?.normalLimit as number), 10)) return '#3BA767';
    if (value < parseInt(convertedValue(data.weightChart?.highLimit as number), 10)) return '#EF973B';
    return '#DA6453';
  };

  const { makeEventAnnotations } = useEventAnnotation();
  const eventAnnotationsOptions = makeEventAnnotations(
    (data.weightChart?.weightTickDetails
      || []).filter((d) => d.hasMedication && tickFrequency === FrequencyUnitEnum.DAILY),
    {
      getDataValue: (d) => (tickFrequency === FrequencyUnitEnum.DAILY
        ? dayjs.utc(d.tickWeight?.updatedAt as string
          || d.tickStartDate as string).startOf('day').valueOf()
        : dayjs.utc(d?.tickStartDate as string).startOf('week').valueOf()),
      annotationDisplay,
    }
  );

  const getSourceIcon = (source: ResultSourceEnum) => {
    if (source === ResultSourceEnum.MIXED) {
      return `
      <div><img src="/static/media/mixed-hs-source-icon.621be11a14aa82d385c6.svg" alt="mixed-source" draggable="false"></div> 
      `;
    }
    if (inClinicSourceList.includes(source)) {
      return `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.47123 1.52869C8.34621 1.40371 8.17668 1.3335 7.9999 1.3335C7.82312 1.3335 7.65358 1.40371 7.52857 1.52869L1.52857 7.52869C1.43536 7.62192 1.37189 7.7407 1.34618 7.87C1.32046 7.9993 1.33367 8.13332 1.38411 8.25512C1.43456 8.37692 1.51999 8.48103 1.62959 8.55429C1.7392 8.62754 1.86807 8.66666 1.9999 8.66669H2.66657V13.3334C2.66657 14.0687 3.26457 14.6667 3.9999 14.6667H11.9999C12.7352 14.6667 13.3332 14.0687 13.3332 13.3334V8.66669H13.9999C14.1319 8.66698 14.261 8.62805 14.3708 8.55485C14.4806 8.48165 14.5662 8.37747 14.6167 8.25554C14.6672 8.1336 14.6803 7.99942 14.6544 7.87001C14.6285 7.7406 14.5648 7.6218 14.4712 7.52869L8.47123 1.52869ZM12.0006 13.3334H3.9999V6.94269L7.9999 2.94269L11.9999 6.94269V10L12.0006 13.3334Z" fill="#00C8BC"/>
          <path d="M8.66671 6.66669H7.33337V8.66669H5.33337V10H7.33337V12H8.66671V10H10.6667V8.66669H8.66671V6.66669Z" fill="#00C8BC"/>
        </svg>
      `;
    }
    return `
    <img src="/static/media/scale-icon.ca1c847ff0cf5220bd4c.svg" alt="scale" draggable="false">   
    `;
  };

  /* eslint-disable react/no-this-in-sfc */
  const tooltipOptions = {
    useHTML: true,
    shared: true,
    borderColor: '#ffffff',
    backgroundColor: 'white',
    formatter(this: Highcharts.TooltipFormatterContextObject) {
      const pointDetails = this.points?.map((point) => {
        const value = point.y;
        const { bmi, measurementCount } = point.point;

        return `<div>
          <div style="margin-top: 16px; color: black; font-size: 20px; font-weight: 600;">${value}</div>
          <div style="color: #6B7178; margin-bottom: 16px; font-size: 14px; ">BMI: ${bmi.toFixed(1)}</div>
          <div style="color: ${measurementCount > 1 || tickFrequency !== FrequencyUnitEnum.DAILY
    ? '#6B7178' : 'white'}; font-size: 14px;">Average of ${measurementCount} readings</div>
        </div>`;
      })[0];

      const sourceIcon = getSourceIcon(this.points?.[0].point.source);
      const getDateCopy = () => {
        const measurementCount = this.points?.[0].point.measurementCount;
        if (tickFrequency === FrequencyUnitEnum.DAILY) {
          if (measurementCount && measurementCount > 1) {
            return dayjs.utc(this.points?.[0].point.time).format('MM/DD/YY');
          }
          return dayjs.utc(this.points?.[0].point.time).format('MM/DD/YY hh:mm A');
        }
        if (tickFrequency === FrequencyUnitEnum.WEEKLY) return `week of ${dayjs.utc(this.points?.[0].point.time).format('MM/DD/YY')}`;
        if (tickFrequency === FrequencyUnitEnum.MONTHLY) return `month of ${dayjs.utc(this.points?.[0].point.time).startOf('month').format('MM/YY')}`;
        if (tickFrequency === FrequencyUnitEnum.ANNUALLY) return `year of ${dayjs.utc(this.points?.[0].point.time).startOf('year').format('YYYY')}`;
        return dayjs.utc(this.points?.[0].point.time).subtract(0, 'day').valueOf();
      };

      return `
        <div style="display: flex; align-items: space-between; gap: 40px; width: 180px; border-color: white;">
          <div style="padding: 0px; width: 2px; background-color: ${getDataTooltipColor(this.points?.[0].point.y as number)}; height: 100%;" />
          <div style="padding: 10px;">
            <div style="display: flex; align-items: space-between;">
              <div style="min-width: 150px;">${getDateCopy()}</div>
              <div style="height: 16px;">${sourceIcon}</div>
            </div>
            ${pointDetails}
          </div>
        </div>
        `;
    },
  };

  const options = useMemo(() => {
    const seriesData = map(data.weightChart?.weightTickDetails, (curr) => {
      const realTime = tickFrequency === FrequencyUnitEnum.DAILY
        ? dayjs.utc(curr.tickWeight?.updatedAt as string || curr.tickStartDate as string).valueOf()
        : dayjs.utc(curr?.tickStartDate as string).valueOf();
      const tickTime = tickFrequency === FrequencyUnitEnum.DAILY
        ? dayjs.utc(curr.tickWeight?.updatedAt as string || curr.tickStartDate as string).startOf('day').valueOf()
        : dayjs.utc(curr?.tickStartDate as string).valueOf();
      return {
        x: tickTime,
        y: +(curr.tickWeight?.value || 0).toFixed(2),
        marker: createNewMarker(curr),
        hasMedication: curr.hasMedication,
        measurementCount: curr.measurementCount,
        bmi: curr.tickWeight?.bmi,
        source: curr.tickWeight?.source,
        frequency: tickFrequency,
        time: realTime,
      };
    });
    const maximum = Math.max(...seriesData.map((d) => d.y));
    const minimum = Math.min(...seriesData.map((d) => d.y));
    const getMinX = () => {
      if (tickFrequency === FrequencyUnitEnum.DAILY) return fromDate.startOf('day').subtract(90000, 'second').valueOf();
      if (tickFrequency === FrequencyUnitEnum.WEEKLY) return fromDate.startOf('week').subtract(24000, 'second').valueOf();
      if (tickFrequency === FrequencyUnitEnum.MONTHLY) return fromDate.startOf('month').subtract(36000, 'second').valueOf();
      if (tickFrequency === FrequencyUnitEnum.ANNUALLY) return fromDate.startOf('year').subtract(7, 'day').valueOf();
      return fromDate.startOf('year').valueOf();
    };
    const getTickInterval = () => {
      if (tickFrequency === FrequencyUnitEnum.DAILY) {
        if (toDate.diff(fromDate, 'days') >= 20) return 7 * 24 * 3600 * 1000;
        if (toDate.diff(fromDate, 'days') >= 13) return 2 * 24 * 3600 * 1000;
        return 24 * 3600 * 1000;
      }
      if (tickFrequency === FrequencyUnitEnum.WEEKLY) {
        const alignedFromDate = fromDate.startOf('week');
        const alignedToDate = toDate.startOf('week');

        if (alignedToDate.diff(alignedFromDate, 'weeks') >= 20) return 5 * 7 * 24 * 3600 * 1000;
        if (alignedToDate.diff(alignedFromDate, 'weeks') >= 13) return 2 * 7 * 24 * 3600 * 1000;
        return 7 * 24 * 3600 * 1000;
      }
      if (tickFrequency === FrequencyUnitEnum.MONTHLY) {
        const alignedFromDate = fromDate.startOf('month');
        const alignedToDate = toDate.startOf('month');

        if (alignedToDate.diff(alignedFromDate, 'months') >= 20) {
          return alignedFromDate.add(3, 'months').diff(alignedFromDate);
        }
        if (alignedToDate.diff(alignedFromDate, 'months') >= 13) {
          return alignedFromDate.add(2, 'months').diff(alignedFromDate);
        }
        return alignedFromDate.add(1, 'months').diff(alignedFromDate);
      }
      if (tickFrequency === FrequencyUnitEnum.ANNUALLY) return 365 * 24 * 3600 * 1000;
      return 24 * 3600 * 1000;
    };

    const getMinorTickInterval = () => {
      if (tickFrequency === FrequencyUnitEnum.DAILY) {
        if (toDate.diff(fromDate, 'days') >= 13) return 24 * 3600 * 1000;
        if (toDate.diff(fromDate, 'days') >= 1) return 0;
        return 24 * 3600 * 1000;
      }
      if (tickFrequency === FrequencyUnitEnum.WEEKLY) {
        const alignedFromDate = fromDate.startOf('week');
        if (toDate.diff(alignedFromDate, 'weeks') >= 13) return 7 * 24 * 3600 * 1000;
        if (toDate.diff(alignedFromDate, 'weeks') >= 1) return 0;
        return 7 * 24 * 3600 * 1000;
      }
      if ([FrequencyUnitEnum.ANNUALLY, FrequencyUnitEnum.MONTHLY].includes(tickFrequency)) return 0;
      return 24 * 3600 * 1000;
    };

    const getTickPositions = () => {
      if (tickFrequency === FrequencyUnitEnum.WEEKLY) {
        const alignedFromDate = fromDate.startOf('week');
        const alignedToDate = toDate.startOf('week');
        const weeksBetween = alignedToDate.diff(alignedFromDate, 'weeks');
        const tickPositions = [];
        if (weeksBetween >= 20) {
          for (let i = 0; i <= weeksBetween; i += 5) {
            tickPositions.push(alignedFromDate.add(i, 'weeks').valueOf());
          }
          return tickPositions;
        }
        return undefined;
      }
      if (tickFrequency === FrequencyUnitEnum.MONTHLY) {
        const alignedFromDate = fromDate.startOf('month');
        const alignedToDate = toDate.startOf('month');
        const monthsBetween = alignedToDate.diff(alignedFromDate, 'months');
        const tickPositions = [];
        if (monthsBetween >= 20) {
          for (let i = 0; i <= monthsBetween; i += 3) {
            tickPositions.push(alignedFromDate.add(i, 'months').valueOf());
          }
          return tickPositions;
        }
        return undefined;
      }
      return undefined;
    };

    const getMinorTickPositions = () => {
      const minorTickPositions = [];
      if (tickFrequency === FrequencyUnitEnum.MONTHLY) {
        const totalMonths = toDate.diff(fromDate, 'months');
        if (totalMonths >= 21) {
          for (let i = 1; i <= totalMonths; i += 1) {
            if (i % 4 !== 0) {
              minorTickPositions.push(fromDate.clone().add(i, 'months').valueOf());
            }
          }
        } else if (totalMonths >= 14) {
          for (let i = 1; i <= totalMonths; i += 1) {
            if (i % 2 !== 0) {
              minorTickPositions.push(fromDate.clone().add(i, 'months').valueOf());
            }
          }
        } else {
          return undefined;
        }
        return minorTickPositions;
      }
      return undefined;
    };

    const getFrequencyValue = () => {
      if (tickFrequency === FrequencyUnitEnum.DAILY) return 'day';
      if (tickFrequency === FrequencyUnitEnum.WEEKLY) return 'week';
      if (tickFrequency === FrequencyUnitEnum.MONTHLY) return 'month';
      if (tickFrequency === FrequencyUnitEnum.ANNUALLY) return 'year';
      return 'day';
    };

    return {
      ...eventAnnotationsOptions,
      title: null,
      noData: 'No Data',
      backgroundColor: 'transparent',
      chart: {
        plotBackgroundColor: 'white',
        backgroundColor: 'transparent',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: tooltipOptions,
      xAxis: {
        type: 'datetime',
        width: '96%',
        gridLineDashStyle: 'dash',
        tickPositions: getTickPositions(),
        minorTickPositions: getMinorTickPositions(),
        tickInterval: getTickInterval(),
        minorGridLineWidth: 0,
        minorTickInterval: getMinorTickInterval(),
        minorTicks: true,
        minorTickWidth: 1,
        minorTickLength: 5,
        min: getMinX(),
        max: toDate.valueOf(),
        showLastLabel: true,
        crosshair: {
          width: 1,
          color: '#E7EAED',
          dashStyle: 'solid',
        },
        labels: {
          formatter: (xAxis: { value: Date; }) => {
            if (tickFrequency === FrequencyUnitEnum.MONTHLY) {
              return dayjs.utc(xAxis.value).format('MM/YY');
            }
            if (tickFrequency === FrequencyUnitEnum.ANNUALLY) {
              return dayjs.utc(xAxis.value).format('YYYY');
            }
            return dayjs.utc(xAxis.value).format('MM/DD/YY');
          },
          align: 'center',
        },
        ...eventAnnotationsOptions.xAxis,
      },
      yAxis: {
        title: {
          text: '',
        },
        useHTML: true,
        labels: {
          format: '{value}',
        },
        showLastLabel: true,
        showFirstLabel: true,
        gridLineWidth: 1,
        gridLineDashStyle: 'dash',
        min: minimum - (maximum * 0.1),
        max: maximum + (maximum * 0.1),
        plotLines: [
          {
            value: convertedValue(data.weightChart?.highLimit as number), // Overweight threshold
            color: '#B5B9BE',
            dashStyle: 'solid',
            width: 1,
            label: {
              text: `<div style="background: #B5B9BE; width: 100%; color: white; border-radius: 2px; padding: 2px;">
              ${convertedValue(data.weightChart?.highLimit as number)}</div>`,
              align: 'right',
              useHTML: true,
              verticalAlign: 'middle',
              x: 4,
              y: 2,
              style: {
                fontWeight: 'bold',
                fontSize: '14px',
              },
            },
          },
          {
            value: convertedValue(data.weightChart?.normalLimit as number), // Normal threshold
            color: '#B5B9BE',
            dashStyle: 'solid',
            width: 1,
            label: {
              text: `
                <div style="background: #B5B9BE; width: 100%; color: white; border-radius: 2px;
                padding: 2px;">${convertedValue(data.weightChart?.normalLimit as number)}</div>
              `,
              align: 'right',
              useHTML: true,
              verticalAlign: 'middle',
              x: 4,
              y: 2,
              style: {
                fontWeight: 'bold',
                fontSize: '14px',
              },
            },
          },
          {
            value: convertedValue(data.weightChart?.lowLimit as number), // Underweight threshold
            color: '#B5B9BE',
            dashStyle: 'solid',
            width: 1,
            label: {
              text: `
                <div style="background: #B5B9BE; width: 100%; color: white;
                border-radius: 2px; padding: 2px;">${convertedValue(data.weightChart?.lowLimit as number)}</div>
              `,
              align: 'right',
              useHTML: true,
              verticalAlign: 'middle',
              x: 4,
              y: 2,
              style: {
                fontWeight: 'bold',
                fontSize: '14px',
              },
            },
          },
        ],
      },
      series: createWeightChartSeries({
        data: seriesData,
        fromDate: fromDate.startOf(
          getFrequencyValue()
        ).subtract(tickFrequency === FrequencyUnitEnum.DAILY ? 1 : 0, 'day'),
        unit,
        lowLimit: parseInt(convertedValue(data.weightChart?.lowLimit as number), 10),
        normalLimit: parseInt(convertedValue(data.weightChart?.normalLimit as number), 10),
        highLimit: parseInt(convertedValue(data.weightChart?.highLimit as number), 10),
      }),
    };
  }, [data, fromDate, toDate, data.weightChart?.weightTickDetails]);

  return (
    <div>
      {renderWeightSummaryAndUnitSelection()}
      <TooltipComponent
        className="text-secondary-gray ml10"
        title={(
          <div className="p20">
            <Text className="b7">
              How to Read Weight Chart
            </Text>
            <div className="flex jc-sb gap3 mt10">
              <Image src={BMIRanges} preview={false} width={500} />
              <Image src={Legend} preview={false} />
            </div>
          </div>
        )}
        placement="topLeft"
        type="info-icon"
        color="white"
        overlayStyle={{ minWidth: '530px' }}
      >
        <div>Legend</div>
      </TooltipComponent>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};
