import { useEffect, useState } from 'react';
import { message, Select } from 'antd';
import { find, map, sortBy } from 'lodash';
import dayjs from 'dayjs';
import { SignatureCollectionMethodEnumComponent } from '../../../../enumComponent/SignatureCollectionMethodEnumComponent/SignatureCollectionMethodEnumComponent';
import { PatientConsent } from '../../../../uc-api-sdk';
import { ConsentService } from '../../../../services/ConsentService';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';
import { USA_DATE } from '../../../../constants/timeFormat';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { ConsentFormFileViewComponent } from '../ConsentFormFileViewComponent/ConsentFormFileViewComponent';

export interface ReviewConsentHistoryComponentProps {
  consentForms?: PatientConsent[];
  isLoadingConsentForms?: boolean;
}

export const ReviewConsentHistoryComponent = ({
  consentForms,
  isLoadingConsentForms,
}: ReviewConsentHistoryComponentProps) => {
  const [
    reviewConsentForm,
    setReviewConsentForm,
  ] = useState<ConsentService | undefined>();

  const versionOptions = useDeepCompareMemo(() => {
    const sorted = sortBy(
      consentForms,
      (cs) => dayjs(cs.signatureAt).valueOf()
    ).reverse();
    return map(sorted, (cs) => {
      const service = new ConsentService(cs);
      return {
        label: `Signed on ${service.signedDateDayjs?.format(USA_DATE)}`,
        value: service.id,
      };
    });
  }, [consentForms]);

  const handleSelectVersion = (
    selectedId?: string | null,
  ) => {
    if (!selectedId) {
      message.error('Version id is not valid');
      return;
    }
    const selectedConsentForm = find(consentForms, { id: selectedId });
    setReviewConsentForm(new ConsentService(selectedConsentForm));
  };

  useEffect(() => {
    const latestConsentFormId = versionOptions?.[0]?.value;
    // select the latest version by default
    handleSelectVersion(latestConsentFormId);
  }, [versionOptions]);

  return (
    <div className="flex gap0 fd-c h100">
      <div className="flex ai-e jc-sb">
        <div className="flex fd-c">
          <span>Version history</span>
          <Select
            options={versionOptions}
            value={reviewConsentForm?.id}
            onChange={handleSelectVersion}
            className="w220"
            loading={isLoadingConsentForms}
          />
        </div>
        <TextComponent size="large">
          <div className="flex f-w jc-e">
            <div>Signature Collection Method:</div>
            <div>
              {
                reviewConsentForm?.signatureMethod
                  ? (
                    <SignatureCollectionMethodEnumComponent
                      value={reviewConsentForm.signatureMethod}
                    />
                  ) : <DisplayOrEmptyComponent />
              }
            </div>
          </div>
        </TextComponent>
      </div>
      <div className="f1 mt10">
        <ConsentFormFileViewComponent
          fileUrl={reviewConsentForm?.fileUrl}
        />
      </div>
    </div>
  );
};
