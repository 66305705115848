import { ReactNode } from 'react';
import { map } from 'lodash';
import CardComponent from '../../../../uiComponent/CardComponent/CardComponent';
import './EnrollmentCareProtocolComponent.scss';
import { PatientProtocolEnum } from '../../../../uc-api-sdk';
import { careProtocols, careProtocolWithCGM } from './constant';
import { CARE_PROTOCOL_BACKGROUND_ICON } from '../../../../assets/commonIconInfo';
import { Icon } from '../../../../icons/Icon';

export interface CareProtocolComponentProps {
  recommendedProtocol: PatientProtocolEnum;
  hasCGMService?: boolean;
}

export const EnrollmentCareProtocolComponent = ({
  recommendedProtocol,
  hasCGMService,
}: CareProtocolComponentProps) => {
  const careProtocol = careProtocols[recommendedProtocol as keyof typeof careProtocols];

  const renderTitle = (): ReactNode => {
    const title = careProtocol?.title || 'UNKNOWN Care Protocol';
    if (hasCGMService) {
      return (
        <div className="flex ai-c">
          <div>{title}</div>
          <div>+ CGM Service</div>
        </div>
      );
    }
    return title;
  };

  const renderContent = () => {
    const content = !hasCGMService ? careProtocol.content : careProtocolWithCGM.content;
    return content
      ? map(content, (v, i) => (<li key={i}>{v}</li>))
      : 'Missing Protocol Content!';
  };

  return (
    <div className="care-protocol mt5">
      <h4>Care Protocol</h4>
      <CardComponent hovered className="care-protocol-card">
        <div className="care-protocol-content">
          <h5 className="care-protocol-title">
            {renderTitle()}
          </h5>
          <ul className="care-protocol-list">
            {renderContent()}
          </ul>
          <Icon info={CARE_PROTOCOL_BACKGROUND_ICON} className="care-protocol-icon" />
        </div>
      </CardComponent>
    </div>
  );
};
