import { Table, TableProps } from 'antd';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { Measurement } from '../../../../uc-api-sdk';
import { createHSDeletionLogTableColumns } from './columns';

export interface HSDeletionLogTableComponentProps extends Omit<TableProps<Measurement>, 'columns'> {
}

export const HSDeletionLogTableComponent = ({
  ...props
}: HSDeletionLogTableComponentProps) => {
  const columns = useTableColumns(
    createHSDeletionLogTableColumns,
    ['date', 'weight', 'deleteBy', 'deleteReason', 'deleteDate']
  );

  return (
    <Table
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      columns={columns}
    />
  );
};
