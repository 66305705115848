import { Form } from 'antd';
import FormList from 'antd/lib/form/FormList';
import { map } from 'lodash';
import { useEffect, useState } from 'react';
// eslint-disable-next-line camelcase
import {
  InsuranceCardSubmitValue,
  InsuranceSubmitValue,
  PriorAuthSubmitValue,
  useInsuranceForm
} from '../../../../hooks/formHook/useInsuranceForm';
import { NonCovered, Patient } from '../../../../uc-api-sdk';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { InsuranceFormContextProvider } from '../../context/InsuranceFormContext';
import { Insurance } from '../../hook/insuranceApis';
import { useInsuranceHelper } from '../../hook/useInsuranceHelper';
import { EmptyInsuranceComponent } from '../EmptyInsuranceComponent/EmptyInsuranceComponent';
import { InsuranceAddButtonComponent } from '../InsuranceAddButtonComponent/InsuranceAddButtonComponent';
import { InsuranceCardComponent } from '../InsuranceCardComponent/InsuranceCardComponent';
import { MarkInsuranceNonCoveredComponent } from '../MarkInsuranceNonCoveredComponent/MarkInsuranceNonCoveredComponent';
import { BlockUnmountKey, useBlockUnmountContext } from '../../../../contexts/BlockUnmountContext/BlockUnmountContext';
import { useInsuranceBlockPrompt } from '../../hook/useInsuranceBlockPrompt';
import { StandaloneInputComponent } from '../../../../uiComponent/StandaloneInputComponent/StandaloneInputComponent';

import './InsuranceFormComponent.scss';
import { InsuranceMedCareCheckboxComponent } from '../InsuranceMedCareCheckboxComponent/InsuranceMedCareCheckboxComponent';

export interface InsuranceFormComponentProps {
  patient: Patient;
  insurance?: Insurance;
  disabled?: boolean;
  onValuesChange?: (v: InsuranceSubmitValue, allValues: InsuranceSubmitValue) => void;
  onSave?: (v: InsuranceSubmitValue, successMsg?: string) => void;
  onEdit?: (isEdit: boolean) => void;
  onSaveNote?: (note: string) => void;
}

export const InsuranceFormComponent = ({
  patient,
  insurance,
  disabled,
  onValuesChange,
  onSave,
  onEdit,
  onSaveNote,
}: InsuranceFormComponentProps) => {
  const form = Form.useForm()[0];
  const insuranceForm = useInsuranceForm({ form });
  const {
    initialValues,
    getNextOrderOfBenefit,
    promptDeleteConfirmation,
  } = useInsuranceHelper(insurance);
  const [
    editableCard,
    setEditableCard,
  ] = useState<number | undefined>();
  const [
    tempValues, // use to save values before edit
    setTempValues,
  ] = useState<InsuranceCardSubmitValue | undefined>();
  const { insuranceBlockPrompt } = useInsuranceBlockPrompt();
  const {
    setBlockPrompt,
    blockUnmount,
    unblockUnmount,
  } = useBlockUnmountContext();

  const patientId = patient.id || '';
  const hasEditableCard = typeof editableCard === 'number';

  const handleSetIsEdit = (
    key?: number,
    value?: boolean,
  ) => {
    if (typeof key === undefined) {
      setEditableCard(undefined);
      return;
    }
    if (
      typeof editableCard === 'number'
      && editableCard !== key
      && value
    ) {
      // other card is editable
      return;
    }
    setEditableCard(value ? key : undefined);
  };

  const handleCancelEdit = () => {
    handleSetIsEdit(undefined);
  };

  const handleAddInsurance = (
    onAdd: (value?: InsuranceCardSubmitValue) => void,
    currentInsuranceList: InsuranceCardSubmitValue[],
  ) => {
    const newOrderOfBenefit = getNextOrderOfBenefit(currentInsuranceList);
    onAdd({
      benefitOrder: newOrderOfBenefit,
    });
    // length represents the new index of temp card
    handleSetIsEdit(currentInsuranceList.length, true);
  };

  const onSaveInsurance = (
    value: InsuranceSubmitValue,
    successMessage?: string,
  ) => {
    handleCancelEdit();
    const allValues = insuranceForm.form.getFieldsValue(true);
    onValuesChange?.(value, allValues);
    onSave?.(value, successMessage);
  };

  const handleDeleteInsurance = (
    value: InsuranceSubmitValue,
  ) => {
    promptDeleteConfirmation(() => {
      handleSetIsEdit(undefined);
      onSaveInsurance?.(value, 'Insurance plan deleted');
    });
  };

  const handleSaveMedicareEligible = (
    checked: boolean,
    formValues: InsuranceSubmitValue
  ) => {
    onSaveInsurance?.({
      ...formValues,
      markMedCareEligible: checked
    });
  };

  const handleSaveNonCovered = (
    nonCovered: NonCovered,
    formValues: InsuranceSubmitValue,
  ) => {
    onSaveInsurance?.({
      ...formValues,
      ...nonCovered
    });
  };

  useEffect(() => {
    const isEdit = editableCard !== undefined;
    onEdit?.(isEdit);
    if (isEdit) {
      blockUnmount?.(BlockUnmountKey.Insurance);
    } else {
      setTempValues(undefined);
      unblockUnmount?.(BlockUnmountKey.Insurance);
    }
  }, [editableCard]);

  useEffect(() => {
    setBlockPrompt?.(BlockUnmountKey.Insurance, insuranceBlockPrompt);
  }, []);

  return (
    <div className="insurance-card-list">
      <Form
        form={insuranceForm.form}
        initialValues={initialValues}
        disabled={disabled}
        layout="vertical"
      >
        <FormItem noStyle shouldUpdate>
          {
            ({ getFieldsValue }) => {
              // true is IMPORTANT to get preserved values
              const formValues = getFieldsValue(true) as {
                insuranceList: InsuranceCardSubmitValue[];
                nonCovered: NonCovered;
                markMedCareEligible: boolean;
              } & PriorAuthSubmitValue;
              const {
                insuranceList = [],
                nonCovered,
                markMedCareEligible,
              } = formValues || {};
              const insuranceListLength = insuranceList?.length;
              return (
                <InsuranceFormContextProvider
                  patient={patient}
                  insurances={insuranceList}
                  insuranceFormValues={formValues}
                  currentEditableCard={editableCard}
                >
                  <FormList name={insuranceForm.getMainFormItemName()}>
                    {
                      (fields, { add, remove }) => (
                        <>
                          {
                            fields.length
                              ? (
                                map(fields, (field, idx) => {
                                  const currentInsurance = insuranceList?.[idx] || {};
                                  const isEdit = editableCard === idx;
                                  return (
                                    <div
                                      key={field.key}
                                      className={idx !== insuranceListLength - 1 ? 'mb20' : ''}
                                    >
                                      <InsuranceCardComponent
                                        field={field}
                                        patientId={patientId}
                                        insurance={currentInsurance}
                                        onSave={(value) => {
                                          onSaveInsurance(value);
                                        }}
                                        isEdit={isEdit}
                                        onEdit={() => {
                                          // only for existing card
                                          setTempValues(currentInsurance);
                                          handleSetIsEdit(idx, true);
                                        }}
                                        onCancelEdit={() => {
                                          if (tempValues) {
                                            remove(idx);
                                            add(tempValues, idx);
                                          } else {
                                            // remove the card if it's new
                                            remove(idx);
                                          }
                                          handleCancelEdit();
                                        }}
                                        onDelete={() => {
                                          const newInsuranceList = (
                                            insuranceList?.filter((_, i) => i !== idx)
                                          );
                                          handleDeleteInsurance({
                                            ...formValues,
                                            insuranceList: newInsuranceList,
                                          });
                                        }}
                                        disableEditDelete={hasEditableCard}
                                      />
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="empty-insurance">
                                  <EmptyInsuranceComponent
                                    onAddInsurance={() => {
                                      handleAddInsurance(add, insuranceList);
                                    }}
                                  />
                                </div>
                              )
                          }
                          <div>
                            <InsuranceAddButtonComponent
                              onAdd={() => handleAddInsurance(add, insuranceList)}
                              listLength={insuranceListLength}
                              disabled={disabled || hasEditableCard}
                            />
                          </div>
                          <div className="mt20">
                            <StandaloneInputComponent
                              onSubmit={onSaveNote}
                              value={initialValues?.note}
                              loading={disabled}
                            />
                          </div>
                          <div className="flex ai-c jc-sb mt5">
                            <InsuranceMedCareCheckboxComponent
                              value={!!markMedCareEligible}
                              onChange={(isChecked) => {
                                handleSaveMedicareEligible(isChecked, formValues);
                              }}
                            />
                            <MarkInsuranceNonCoveredComponent
                              value={nonCovered}
                              onSave={(nonCovered) => {
                                handleSaveNonCovered(nonCovered, formValues);
                              }}
                              disabled={disabled || hasEditableCard}
                            />
                          </div>
                        </>
                      )
                    }
                  </FormList>
                </InsuranceFormContextProvider>
              );
            }
          }
        </FormItem>
      </Form>
    </div>
  );
};
