import { Table, TableProps } from 'antd';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { Filter } from '../../../../hooks/useTableChange/useTableChange';
import { useTableColumnWithFiltersAndSorters } from '../../../../hooks/useTableColumnWithFiltersAndSorters/useTableColumnWithFiltersAndSorters';
import { BatchConsentResult } from '../../../../uc-api-sdk';
import { createBatchSentConsentColumns } from './columns';

export interface BatchSentConsentHistoryTableComponentProps extends
  Omit<TableProps<BatchConsentResult>, 'dataSource' | 'columns'> {
  dataSource: BatchConsentResult[];
  isLoading?: boolean;
  filters: Filter<string>
}

export const BatchSentConsentHistoryTableComponent = ({
  dataSource,
  isLoading,
  filters,
  ...props
}: BatchSentConsentHistoryTableComponentProps) => {
  const columns = useTableColumns(
    createBatchSentConsentColumns(),
    ['time', 'medicalOrg', 'output']
  );

  const columnsWithFilters = useTableColumnWithFiltersAndSorters({
    columns,
    filters,
  });

  return (
    <Table<BatchConsentResult>
      columns={columnsWithFilters}
      dataSource={dataSource}
      loading={isLoading}
      rowKey="id"
      size="middle"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
