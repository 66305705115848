import _ from 'lodash';
import { ReactNode, useMemo } from 'react';
import { MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import {
  FrequencyUnitEnum,
  MealIntake,
  Nullable, Nutrition,
  usePatientGetNutritionById,
  usePatientUpdateNutrition
} from '../../../../uc-api-sdk';
import { HistoryInfo } from '../../../../uiComponent/HistoryComponent/HistoryItemComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { NutritionComponent } from '../../component/NutritionComponent/NutritionComponent/NutritionComponent';
import {
  IntakeTableProps,
  NutritionFormComponent,
  NutritionFormComponentProps,
  SubmitValue
} from '../../component/NutritionComponent/NutritionFormComponent/NutritionFormComponent';
import { MealTypes } from '../../component/NutritionComponent/type';
import { InterventionContainerProps } from '../../type';

export interface NutritionContainerProps
  extends Omit<InterventionContainerProps, 'onSubmit' | 'onError'>,
  NestedFormComponentChildProps {
  title?: ReactNode;
  parent?: MixpanelEventsParents;
  }

export const NutritionContainer = ({
  title = 'Nutrition',
  version,
  visitId,
  showEdit,
  onEdit,
  isEditing,
  onSubmit,
  onCancel,
  onError,
  onValuesChange,
  formButtons,
  patientId: memberId,
  parent,
}: NutritionContainerProps) => {
  const fetchHook = usePatientGetNutritionById({
    params: {
      memberId,
      version,
    },
  });
  useUpdateListener('WORKLIST_UPDATED', fetchHook.refetch);
  const nutrition = fetchHook?.data?.data;
  const getInitialIntakes = (inputs: Nullable<MealIntake[]> | undefined) => {
    const res: SubmitValue = {
      additionalComments: undefined,
      fastFoodFreq: undefined,
      fastFoodFreqUnit: undefined,
      intake: [],
      nutritionUnderstanding: undefined,
      sweetBeverageFreq: undefined,
      sweetBeverageFreqUnit: undefined,
    };
    let extraObj = {};
    const intake: IntakeTableProps[] = [];
    MealTypes.forEach((meal, index) => {
      const targetMeal = inputs?.find(it => meal === _.toUpper(it?.meal || ''));
      if (targetMeal) {
        intake.push({
          key: `${index}`,
          meal,
          timeRange: targetMeal.timeRange,
          foodTypeAmount: targetMeal.foodTypeAmount,
          mealFreq: targetMeal.mealFreq,
          skipFreq: targetMeal.skipFreq,
        });
        extraObj = { ...extraObj, [`${meal}_timeRange`]: targetMeal.timeRange };
        extraObj = { ...extraObj, [`${meal}_foodTypeAmount`]: targetMeal.foodTypeAmount };
        extraObj = { ...extraObj, [`${meal}_mealFreq`]: targetMeal.mealFreq };
        extraObj = { ...extraObj, [`${meal}_skipFreq`]: targetMeal.skipFreq };
      } else {
        intake.push({
          key: `${index}`,
          meal,
          timeRange: '',
          foodTypeAmount: '',
          mealFreq: '',
          skipFreq: '',
        });
      }
    });
    res.intake = intake;
    return { ...res, ...extraObj };
  };
  const initialValues: NutritionFormComponentProps['initialValues'] = useMemo<SubmitValue>(() => ({
    ...getInitialIntakes(nutrition?.intake),
    previousDiets: nutrition?.previousDiets
      ? nutrition?.previousDiets : [],
    fastFoodFreq: nutrition?.fastFoodFreq?.value
      ? nutrition?.fastFoodFreq?.value : 0,
    fastFoodFreqUnit: nutrition?.fastFoodFreq?.unit
      ? nutrition?.fastFoodFreq?.unit : FrequencyUnitEnum.WEEKLY,
    sweetBeverageFreq: nutrition?.sweetBeverageFreq?.value
      ? nutrition?.sweetBeverageFreq?.value : 0,
    sweetBeverageFreqUnit: nutrition?.sweetBeverageFreq?.unit
      ? nutrition?.sweetBeverageFreq?.unit : FrequencyUnitEnum.WEEKLY,
    nutritionUnderstanding: nutrition?.nutritionUnderstanding
      ? nutrition?.nutritionUnderstanding : undefined,
    additionalComments: nutrition?.additionalComments
      ? nutrition?.additionalComments : undefined,
  }), [
    nutrition,
  ]);
  const updateHook = usePatientUpdateNutrition({});
  const getSubmitNutritionObject = (inputs: SubmitValue) => {
    const res: Nutrition = {};
    const intake: MealIntake[] = [];
    MealTypes.forEach(meal => {
      intake.push({
        meal,
        timeRange: _.get(inputs, `${meal}_timeRange`),
        foodTypeAmount: _.get(inputs, `${meal}_foodTypeAmount`),
        mealFreq: _.get(inputs, `${meal}_mealFreq`),
        skipFreq: _.get(inputs, `${meal}_skipFreq`),
      });
    });
    res.intake = intake;
    res.fastFoodFreq = {
      value: inputs.fastFoodFreq,
      unit: inputs.fastFoodFreqUnit,
    };
    res.sweetBeverageFreq = {
      value: inputs.sweetBeverageFreq,
      unit: inputs.sweetBeverageFreqUnit,
    };
    res.previousDiets = inputs.previousDiets;
    res.nutritionUnderstanding = inputs.nutritionUnderstanding;
    res.additionalComments = inputs.additionalComments;
    res.notes = initialValues.additionalComments !== inputs.additionalComments
      ? [{ note: inputs.additionalComments }] : [];
    return res;
  };
  const handleSubmit: NutritionFormComponentProps['onSubmit'] = async (formValue) => {
    const req = updateHook.send({
      params: {
        memberId,
        request: {
          ...getSubmitNutritionObject(formValue),
          visitId,
        }
      },
    });
    const res = await ApiRequestHelper.tryCatch(
      req,
      {
        success: 'Nutrition updated!',
        error: 'Failed to updated nutrition!',
        onError,
      },
    );
    if (res !== undefined) {
      fetchHook.refetch();
    }
    onSubmit?.();
  };

  const handleCancel = () => {
    onCancel?.();
  };
  const history = useMemo<HistoryInfo[] | undefined>(
    () => nutrition?.notes?.map(note => ({
      content: note.note,
      date: note.createdAt,
    })).reverse(),
    [nutrition],
  );
  return (
    <PatientCommonCardComponent
      title={title}
      showEdit={showEdit}
      isEditing={isEditing}
      onEdit={onEdit}
      updateInfo={fetchHook.data?.data}
      content={(
        <NutritionComponent
          value={initialValues}
          history={history}
        />
      )}
      formContent={(
        <LoadingOverlayComponent isLoading={nutrition === undefined}>
          <NutritionFormComponent
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            initialValues={initialValues}
            isLoading={updateHook.isLoading}
            history={history}
            onValuesChange={onValuesChange}
            formButtons={formButtons}
            memberId={memberId}
            parent={parent}
          />
        </LoadingOverlayComponent>
      )}
    />
  );
};
