import { Radio } from 'antd';
import {
  compact, find, map
} from 'lodash';
import { InputType } from '../../../Input/types';
import { CgmBrandEnum, CgmModelEnum, CgmSupportedDevice } from '../../../../uc-api-sdk';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';
import './CGMDeviceModelSelectorComponent.scss';
import { CGMDeviceModelEnumComponent } from '../../../../enumComponent/CGMDeviceModelEnumComponent/CGMDeviceModelEnumComponent';

interface CGMDeviceModelSelectorComponentProps extends InputType<CgmModelEnum> {
  brand: CgmBrandEnum;
  data: CgmSupportedDevice[];
}

export const CGMDeviceModelSelectorComponent = ({
  brand,
  data,
  value,
  onChange,
}: CGMDeviceModelSelectorComponentProps) => {
  const options = useDeepCompareMemo(() => {
    const models = find(data, { brand })?.devices || [];
    const options = map(models, (option) => (
      option.model
        ? {
          label: <CGMDeviceModelEnumComponent value={option.model} />,
          value: option.model,
        } : null
    ));
    return compact(options);
  }, [data, brand]);

  return (
    <Radio.Group
      value={value}
      onChange={(e) => onChange?.(e.target.value)}
      options={options}
    />
  );
};
