import classNames from 'classnames';
import { CGMDeviceAccountLinkingStatusComponent } from './CGMDeviceAccountLinkingStatusComponent';
import { CGMDeviceUnlinkAccountButtonContainer } from '../../container/CGMDeviceUnlinkAccountButtonContainer/CGMDeviceUnlinkAccountButtonContainer';
import { CGMDeviceLinkAccountButtonContainer, CGMDeviceLinkAccountButtonContainerProps } from '../../container/CGMDeviceLinkAccountButtonContainer/CGMDeviceLinkAccountButtonContainer';
import { CGMDeviceBrandLogoComponent } from '../CGMDeviceBrandLogoComponent/CGMDeviceBrandLogoComponent';
import { CgmDeviceStatusEnum, PatientCgmDeviceResponse } from '../../../../uc-api-sdk';

export interface CGMDeviceAccountLinkingComponentProps
  extends CGMDeviceLinkAccountButtonContainerProps {
  linkingStatus?: PatientCgmDeviceResponse;
  isLoading?: boolean;
  onLinkAccountClick?: CGMDeviceLinkAccountButtonContainerProps['onClick'];
  onUnlinkAccountClick?: () => void;
  onLearnMoreClick?: () => void;
}

export const CGMDeviceAccountLinkingComponent = ({
  patientId,
  brand,
  model,
  linkingStatus,
  isLoading,
  onLinkAccountClick,
  onUnlinkAccountClick,
  onLearnMoreClick,
}: CGMDeviceAccountLinkingComponentProps) => {
  const isLinked = linkingStatus?.status === CgmDeviceStatusEnum.LINK_SUCCESS;

  return (
    <div className="cgm-account-linking-content">
      <div className="p14 secondary-gray flex ai-c">
        Link patient's
        {' '}
        <CGMDeviceBrandLogoComponent brand={brand} />
        {' '}
        account to Unified Care to initiate service.
      </div>
      <CGMDeviceAccountLinkingStatusComponent
        linkingStatus={linkingStatus}
        isLoading={isLoading}
        onLearnMoreClick={onLearnMoreClick}
      />
      <div
        className={classNames({
          'cgm-account-linking-content__buttons': true,
          'cgm-linked': isLinked,
        })}
      >
        {isLinked ? (
          <CGMDeviceUnlinkAccountButtonContainer
            externalUserId={linkingStatus?.externalUserId}
            onConfirm={onUnlinkAccountClick}
          />
        ) : (
          <CGMDeviceLinkAccountButtonContainer
            patientId={patientId}
            brand={brand}
            model={model}
            onClick={onLinkAccountClick}
          />
        )}
      </div>
    </div>
  );
};
