import * as Highcharts from 'highcharts';
import * as HighchartsAnnotations from 'highcharts/modules/annotations';
import { forEach } from 'lodash';
import { Nullable } from '../../uc-api-sdk';

// @ts-ignore
HighchartsAnnotations(Highcharts);

interface EventOptions<T> {
  getDataValue: (d: T) => Nullable<string | number>;
  onClick?: (event: T) => void;
  maxYValue?: number;
  annotationDisplay?: string; // html string
}

export const useEventAnnotation = () => {
  const makeEventAnnotations = <T>(data: T[], eventOptions?: EventOptions<T>) => {
    const {
      getDataValue = () => undefined,
      onClick,
      maxYValue = Infinity,
      annotationDisplay,
    } = eventOptions || {};

    const eventLines = [] as Highcharts.PlotLineOptions[];

    forEach(data, (d) => {
      const eventLine = {
        value: getDataValue(d),
        color: 'lightgrey',
        width: 1,
      };

      eventLines.push(eventLine);
    });

    const eventAnnotations = data.map((d) => ({
      draggable: '',
      crop: false,
      events: {
        click: () => onClick?.(d),
      },
      labels: [{

        point: {
          x: getDataValue(d),
          y: -10,
          xAxis: 0,
          yAxis: maxYValue,
        },
        useHTML: true,
        text: `
          <div class="event-annotation-icon">
            ${annotationDisplay}
          </div>
        `,
        backgroundColor: 'transparent',
        borderWidth: 0,
        style: { cursor: onClick ? 'pointer' : undefined },
      }]
    })) as Highcharts.AnnotationsOptions[];

    return {
      chart: {
        // add margin to make room for event text to show when needed
        // ie. marginTop: 50,
      },
      annotations: eventAnnotations,
      xAxis: {
        plotLines: eventLines,
      }
    } as Highcharts.Options;
  };

  return {
    makeEventAnnotations
  };
};
