import { ExclamationCircleFilled } from '@ant-design/icons';

import './CGMLinkingStatusComponent.scss';

export const CGMLinkingStatus = {
  IncompleteLinking: () => (
    <div className="cgm-incomplete-linking">
      <ExclamationCircleFilled className="incomplete-icon mr5" />
      <span className="incomplete-text">Account Linking Incomplete</span>
    </div>
  ),
  MissingDeviceSelection: () => (
    <div className="cgm-missing-device-selection">
      <ExclamationCircleFilled className="missing-icon mr5" />
      <span className="missing-text">Missing Device Selection</span>
    </div>
  ),
  NoDeviceStatus: () => (
    <div className="cgm-missing-device-selection">
      <ExclamationCircleFilled className="missing-icon mr5" />
      <span className="missing-text">No Sensor Information Available</span>
    </div>
  )
};
