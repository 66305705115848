import { useState } from 'react';
import { Collapse, Form } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { last } from 'lodash';
import classNames from 'classnames';
import { DeviceCheckboxComponent } from '../DeviceCheckboxComponent/DeviceCheckboxComponent';
import { InputType } from '../../../Input/types';
import {
  APIResponseClass,
  AssignDeviceInfo, CgmDeviceStatusEnum, DeviceModelEnum,
  DeviceTypeEnum,
  PatientCgmDeviceDto,
  PatientCgmDeviceResponse
} from '../../../../uc-api-sdk';
import { ClickableDiv } from '../../../../uiComponent/ClickableDiv/ClickableDiv';
import { CGMDeviceFormItemComponent } from '../../../CGMDevice/component/CGMDeviceFormItemComponent/CGMDeviceFormItemComponent';
import './EnrollmentCGMDeviceCheckboxComponent.scss';
import { CGMDeviceContainer } from '../../../CGMDevice/container/CGMDeviceContainer/CGMDeviceContainer';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { CGMFormSubmitValue } from '../../../CGMDevice/component/CGMDeviceFormItemComponent/useCGMDeviceForm';
import { CGMDeviceNameComponent } from '../../../CGMDevice/component/CGMDeviceNameComponent/CGMDeviceNameComponent';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { CGMLinkingStatus } from '../../../CGMDevice/component/CGMLinkingStatusComponent/CGMLinkingStatusComponent';

type ExtendedAssignDeviceInfo = AssignDeviceInfo & CGMFormSubmitValue;

export interface EnrollmentCGMDeviceCheckboxComponentProps
  extends InputType<ExtendedAssignDeviceInfo> { }

export const EnrollmentCGMDeviceCheckboxComponent = ({
  value,
  onChange,
}: EnrollmentCGMDeviceCheckboxComponentProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(!!value);
  const { info } = usePatientContext();

  const renderLinkingStatus = (isAccountLinked = false) => (
    isAccountLinked
      ? (
        <div className="device-info">
          <LinkButton useBlueColor>
            <CGMDeviceNameComponent
              brand={value?.brand}
              model={value?.model}
            />
          </LinkButton>
        </div>
      ) : (
        <CGMLinkingStatus.IncompleteLinking />
      )
  );

  const renderDeviceStatus = (
    linkingStatus?: PatientCgmDeviceResponse
  ) => (
    <div className="cgm-device-checkbox-component__device-status">
      {
        (!value?.brand || !value?.model)
          ? (
            <CGMLinkingStatus.MissingDeviceSelection />
          ) : (
            renderLinkingStatus(linkingStatus?.status === CgmDeviceStatusEnum.LINK_SUCCESS)
          )
      }
    </div>
  );

  const handleExpand = (isExpanded: boolean) => {
    if (!value) return;
    setIsExpanded(isExpanded);
  };

  const handleChange = (
    value?: ExtendedAssignDeviceInfo
  ) => {
    setIsExpanded(!!value);
    onChange?.(
      value
        ? { ...value, deviceModel: DeviceModelEnum.CGM }
        : undefined
    );
  };

  return (
    <CGMDeviceContainer patientId={info?.id || ''}>
      {
        ({
          cgmDeviceData,
          patientCGMDeviceLinkStatusInfo,
        }) => (
          <div className="cgm-device-checkbox-component">
            <Collapse
              className={classNames({
                'cgm-device-checkbox-component__collapse': true,
                'cgm-device-checkbox-component__collapse--selected': value,
              })}
              expandIconPosition="end"
              activeKey={+isExpanded}
              onChange={(activeKeys) => {
                handleExpand(!!activeKeys?.length);
              }}
            >
              <CollapsePanel
                key={1}
                header={(
                  <ClickableDiv
                    onClick={() => handleExpand(!isExpanded)}
                    className="flex ai-c jc-sb"
                  >
                    <DeviceCheckboxComponent
                      model={DeviceModelEnum.CGM}
                      type={DeviceTypeEnum.CGM}
                      value={value}
                      onChange={handleChange}
                    />
                    {
                      value && (
                        <div>
                          <FetchComponent
                            info={patientCGMDeviceLinkStatusInfo}
                            alwaysShowChildren
                          >
                            {
                              (info) => {
                                const {
                                  rawValue,
                                } = info as APIResponseClass<PatientCgmDeviceDto> || {};
                                const patientCgmDeviceStatusList = rawValue?.data || [];
                                return (
                                  renderDeviceStatus(last(patientCgmDeviceStatusList))
                                );
                              }
                            }
                          </FetchComponent>
                        </div>
                      )
                    }
                  </ClickableDiv>
                )}
              >
                {
                  value && (
                    <Form
                      component={false}
                      initialValues={value}
                    >
                      <CGMDeviceFormItemComponent
                        patientId={info?.id || ''}
                        deviceData={cgmDeviceData}
                        onValuesChange={(changed, allValues) => {
                          handleChange({ ...value, ...allValues });
                        }}
                      />
                    </Form>
                  )
                }
              </CollapsePanel>
            </Collapse>
          </div>
        )
      }
    </CGMDeviceContainer>
  );
};
