import { Table, TableProps } from 'antd';
import {
  FC, useCallback, useMemo, useState
} from 'react';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { HSUnitProps, WeightService } from '../../../../helpers/weight';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { DailyWeight } from '../../../../uc-api-sdk';
import ExpandIcon from '../../../../uiComponent/ExpandIconComponent/ExpandIconComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { HSDeleteModalContainer } from '../../container/HSDeleteModalContainer/HSDeleteModalContainer';
import { HSDeletionLogModalComponent } from '../HSDeletionLogModalComponent/HSDeletionLogModalComponent';
import { HSTableUnitDropdownComponent } from '../HSTableUnitDropdownComponent/HSTableUnitDropdownComponent';
import { HSTooltipComponent } from '../TooltipComponent/HSTooltipComponent';
import { createHSTableColumns, HSTableRowValue } from './columns';
import './HSTableComponent.scss';

export interface HSTableComponentProps extends HSUnitProps {
  data: DailyWeight[];
  onUnitChange?: (unit: HSUnitProps['unit']) => void;
  memberId: string;
}

export const HSTableComponent: FC<HSTableComponentProps> = ({
  data,
  onUnitChange,
  unit,
  memberId
}) => {
  // const [hiddenIndexes, setHiddenIndexes] = useState<boolean[]>([]);
  const [selectedRow, setSelectedRow] = useState<HSTableRowValue | undefined>(undefined);
  const { isOpen, open, close } = useOpen();
  const weightConvertFn = useCallback((w: number) => WeightService.toLbOrKg(unit)(w), [unit]);
  const updateWeightList = useUpdate('WEIGHT_UPDATED');

  const flattenData = useMemo(() => {
    const result = data.map((d) => {
      const { dailyWeightDetails, ...rest } = d;
      let rows: HSTableRowValue[] = [];
      if (dailyWeightDetails && dailyWeightDetails.length > 1) {
        rows.push({
          ...rest,
          isNested: false,
          isAverage: true,
          weight: Number(weightConvertFn(rest.weight || 0)),
          children: [],
        });
        rows = rows.concat(dailyWeightDetails.map((dd) => ({
          ...dd,
          isNested: true,
          isAverage: false,
          weight: Number(weightConvertFn(dd.weight || 0)),
        })));
      } else {
        rows.push({
          ...rest,
          isNested: false,
          isAverage: false,
          measurementId: dailyWeightDetails?.[0].measurementId,
          weight: Number(weightConvertFn(rest.weight || 0)),
        });
      }
      return rows;
    }).flat();

    // calculate the weight change for each day (isAverage only) instead of each row
    let currentIndex = 0;
    let nextIndex = 0;
    while (nextIndex < result.length) {
      if (!result[currentIndex].isNested) {
        if (!result[nextIndex].isNested && currentIndex !== nextIndex) {
          result[currentIndex].weightChange = (result?.[currentIndex].weight || 0)
            - (result?.[nextIndex].weight || 0);
          result[currentIndex].key = `${result[currentIndex].measurementId}-${currentIndex}`;
          currentIndex = nextIndex;
          nextIndex += 1;
        } else {
          nextIndex += 1;
        }
      } else {
        currentIndex += 1;
        nextIndex += 1;
      }
    }

    // calculate the weight change for each nested/regular row after conversion
    currentIndex = 0;
    nextIndex = 0;
    while (nextIndex < result.length) {
      if (!result[currentIndex].isAverage) {
        if (currentIndex !== nextIndex) {
          result[currentIndex].weightChange = (result?.[currentIndex].weight || 0)
            - (result?.[nextIndex].weight || 0);
          result[currentIndex].key = `${result[currentIndex].measurementId}-${currentIndex}`;
          currentIndex = nextIndex;
          nextIndex += 1;
        } else {
          nextIndex += 1;
        }
      } else {
        currentIndex += 1;
        nextIndex += 1;
      }
    }
    return result;
  }, [data, weightConvertFn, unit]);

  const onDelete = () => {
    updateWeightList.updateValue?.();
    close();
  };

  const columns = useTableColumns(
    createHSTableColumns,
    ['action', 'date', 'weight', 'bmi', 'weightChange'],
    [unit]
  );

  const dataSource = useMemo(() => {
    if (flattenData.length === 0) {
      return [];
    }

    const result: HSTableRowValue[] = [];
    let i = 0;
    let currentRow = flattenData[i];
    while (i < flattenData.length) {
      if (!currentRow.isAverage && !currentRow.isNested) {
        result.push(currentRow);
        i += 1;
        currentRow = flattenData[i];
      }
      if (currentRow?.isAverage) {
        // push the average row after adding all nested rows to children
        i += 1;
        while (i < flattenData.length && flattenData[i].isNested) {
          // push nested rows to the average's children
          currentRow.children?.push(flattenData[i]);
          i += 1;
        }
        result.push(currentRow);
        currentRow = flattenData[i];
      }
    }
    return result;
  }, [flattenData]);

  const onRowClick: TableProps<HSTableRowValue>['onRow'] = (record) => ({
    onClick: () => {
      if (record.isAverage) {
        return;
      }
      setSelectedRow(record);
      open();
    }
  });

  return (
    <div className="HSTableComponent">
      <div className="flex jc-sb">
        <TooltipComponent
          className="mb10 w-mc"
          title={<HSTooltipComponent />}
          placement="topLeft"
          type="info-icon"
          color="white"
          overlayStyle={{ minWidth: '530px' }}
        >
          <span>Log book</span>
        </TooltipComponent>
        <div className="mb10 flex gap2 ai-c">
          <span>Unit </span>
          <HSTableUnitDropdownComponent onChange={onUnitChange} value={unit} />
        </div>
      </div>
      <Table
        dataSource={dataSource}
        bordered
        size="middle"
        columns={columns}
        pagination={false}
        className="hs-table"
        onRow={onRowClick}
        scroll={{ y: 350 }}
        expandable={{
          expandIcon: ExpandIcon,
        }}
      />
      <HSDeletionLogModalComponent memberId={memberId} />
      <HSDeleteModalContainer
        data={{ ...selectedRow, isNested: false }}
        isOpen={isOpen}
        onClose={close}
        onSubmit={onDelete}
      />
    </div>
  );
};
