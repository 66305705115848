import { DeviceModelEnum, DeviceTypeEnum, VitalEnumType } from '../../../../uc-api-sdk';

export const enrollmentCommonDeviceMap = {
  [VitalEnumType.BP]: {
    type: DeviceTypeEnum.BP,
    model: DeviceModelEnum.BP3L,
  },
  [VitalEnumType.BG]: {
    type: DeviceTypeEnum.BG,
    model: DeviceModelEnum.BG5S,
  },
  [VitalEnumType.HS]: {
    type: DeviceTypeEnum.HS,
    model: DeviceModelEnum.HS2S,
  },
  [VitalEnumType.PO]: {
    type: DeviceTypeEnum.PO,
    model: DeviceModelEnum.PO3,
  },
  [VitalEnumType.TM]: {
    type: DeviceTypeEnum.TM,
    model: DeviceModelEnum.TS28B,
  },
} as Record<Partial<VitalEnumType>, { type: DeviceTypeEnum; model: DeviceModelEnum }>;
