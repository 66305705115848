import { CLOCK_ICON } from '../../../../assets/commonIconInfo';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { Icon } from '../../../../icons/Icon';
import { PluralComponent } from '../../../../uiComponent/PluralComponent/PluralComponent';

export interface CGMDeviceDaysLeftComponentProps {
  startDate?: string | null;
  endDate?: string | null;
}

export const CGMDeviceDaysLeftComponent = ({
  startDate,
  endDate,
}: CGMDeviceDaysLeftComponentProps) => {
  if (!startDate || !endDate) {
    return null;
  }

  const daysLeft = TimezoneService.calcDateTimeDayjs(endDate).diff(
    TimezoneService.calcDateTimeDayjs(startDate),
    'days'
  );

  if (daysLeft < 0) {
    return null;
  }
  return (
    <>
      <Icon info={CLOCK_ICON} />
      {' '}
      {daysLeft}
      {' '}
      <PluralComponent
        value={daysLeft}
        unit="day"
      />
      {' '}
      ago
    </>
  );
};
