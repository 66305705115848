import { useEffect, useState } from 'react';
import { useLoggedInUserFromContext } from '../../../contexts/loggedInUserContext';
import { useAuthGetLoginResponse, useAuthRefreshToken } from '../../../uc-api-sdk';
import { ApiRequestHelper } from '../../../helpers/ApiRequest';
import { AuthLoginResponse } from '../../../features/auth/types';
import EmployeeInfo from '../../../hooks/useUserInfo/employeeInfo';
import { useMixpanelContext } from '../../../contexts/MixpanelContext/MixpanelContext';
import useDebounce from '../../../hooks/useDebounce/useDebounce';
import { MixpanelEvents } from '../../../contexts/MixpanelContext/MixpanelEvents';
import { useDeepCompareEffect } from '../../../hooks/useDeepCompareEffect';

export const useRefreshSessionToken = () => {
  const [startRefresh, setStartRefresh] = useState(false);
  const { token, setLoginInfo } = useLoggedInUserFromContext();
  const { send } = useMixpanelContext();
  const authMeInfo = useAuthGetLoginResponse({ options: { sendOnMount: false, retry: 0 } });
  const refreshTokenInfo = useAuthRefreshToken({ options: { sendOnMount: false, retry: 0 } });

  const refreshToken = (
    mixpanelProperties?: Record<string, unknown>
  ) => {
    send({
      event: MixpanelEvents.UserRefreshTokenStart,
      properties: mixpanelProperties,
    });
    setStartRefresh(true);
  };

  const handleRefresh = useDebounce(() => {
    ApiRequestHelper.tryCatch(
      authMeInfo.send(),
      {
        success: '',
        error: '',
        onSuccess: (res) => {
          if (res === null) {
            // invalid or expired
            // axios interceptor will handle this
            return;
          }
          refreshTokenInfo.send();
        },
        onError: () => {
          // ignore for now
        }
      }
    );
  }, 2000, [token]);

  useEffect(() => {
    if (startRefresh) {
      handleRefresh();
      setStartRefresh(false);
    }
  }, [startRefresh, handleRefresh]);

  useDeepCompareEffect(() => {
    if (!refreshTokenInfo.data) {
      return;
    }
    const refreshData = refreshTokenInfo.data || {};
    send({
      event: MixpanelEvents.UserRefreshTokenEnd,
      properties: {
        refreshDataCode: refreshData.code,
        refreshDataMessage: refreshData.msg
      },
    });
    setTimeout(() => {
      if (refreshData.code === 200 && refreshData.data) {
        setLoginInfo(refreshData.data as AuthLoginResponse<EmployeeInfo>);
      }
    }, 100); // give time for mixpanel to send
  }, [refreshTokenInfo?.data]);

  return {
    refreshToken,
  };
};
