/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
import axios from 'axios';
import {
  intersection,
  last,
} from 'lodash';
import {
  createElement,
} from 'react';
import EnvConfig from '../../configs/envConfig/envConfig';
import {
  CHSRequestFunction,
  GetChannelHistoryParams,
  GetChannelHistoryResponseData,
  InitChannelParams,
  InitChannelResponseData,
  NotificationGroupParams,
  NotificationGroupResponseData,
  PaginatedChannelsParams,
  PaginatedChannelsResponseData,
  PublishMessageParams,
  PublishMessageResponseData
} from './types';
import {
  MessageHistory,
  MessageHistoryMessage,
  MessagePayload,
  MessageSubType,
  MessageType,
} from './types/data';
import { RoleTypeEnum } from '../../uc-api-sdk';
import {
  MessageACKPublisher,
  MessageContent
} from '../../types/message';
import { MESSAGE_NOTIFICATION } from './constants';

const CHSDomain = EnvConfig.chsDomain;

export class CHSServices {
  protected endPoints = {
    notificationChannelGroup: `${CHSDomain}/notificationChannelGroup`,
    paginatedChannels: `${CHSDomain}/channels`,
    history: `${CHSDomain}/history`,
    publish: `${CHSDomain}/publish`,
    initChannel: `${CHSDomain}/initChannel`
  };

  protected rolesCanMarkUnread = [
    RoleTypeEnum.RD,
    RoleTypeEnum.HC,
    RoleTypeEnum.CA,
    RoleTypeEnum.PROVIDER,
    RoleTypeEnum.MA,
  ];

  static excludeMessageTypesAndSubTypes = [
    MessageType.FOOD_LOG_REVIEWED,
    // MessageSubType.SYSTEM,
    MessageSubType.ONLINE_CLASS,
  ];

  static readableMessageTypes = [
    MessageType.TEXT,
    MessageType.FOOD_LOG_REQUEST,
    MessageType.FILE_UPLOAD,
    MessageType.MEASUREMENT,
    MessageType.MEASUREMENTS,
    MessageType.SYMPTOM_LOG,
  ];

  static patientUserRole = 'patient';

  static isPatient = (userRole?: string) => userRole === CHSServices.patientUserRole;

  static isSystemMessage = (userRole?: string) => userRole === 'admin';

  static convertTimestamp = (timestamp?: string) => (timestamp ? (+timestamp / 1e4) : 0);

  static parseTimestamp = (time: string | number) => String(Number(time) * 1e4);

  static parseClickableUrl = (
    messageText?: string,
  ) => {
    if (!messageText) return null;
    const urlRegEx = /http[s]?:\/\/\S+/gi;
    const htmlString = messageText.replace(urlRegEx, (
      url,
    ) => (
      `<a href='${url}' target='_blank'>${url}</a>`
    ));
    const htmlDocument = new DOMParser().parseFromString(htmlString, 'text/html');
    const { childNodes } = htmlDocument.body;
    return Array.from(childNodes).map((childNode, index) => {
      if (childNode.nodeName.includes('text')) {
        return (childNode as Text).data;
      }
      return createElement(
        childNode.nodeName?.toLowerCase(),
        {
          key: (index + 1).toString(),
          href: (childNode as HTMLAnchorElement).href,
          target: (childNode as HTMLAnchorElement).target,
          style: {
            wordBreak: 'break-word',
            wordWrap: 'break-word',
          },
        },
        (childNode as HTMLElement).innerHTML,
      );
    });
  };

  static parseFileKey = (fileKey?: string) => {
    const fileKeyName = last(fileKey?.split('/')) || 'Unknown';
    const fileKeyExtension = last(fileKeyName?.split('.')) as string;
    return {
      fileKey,
      name: fileKeyName,
      isPDF: ['pdf'].includes(fileKeyExtension),
      isImage: ['jpg', 'png', 'jpeg'].includes(fileKeyExtension),
      extension: fileKeyExtension,
    };
  };

  static getListOfReadableMessages = (
    messages: MessageHistoryMessage[],
  ) => (
    messages?.filter((msg) => {
      const { type, subType } = msg.payload;
      return (
        this.readableMessageTypes.includes(type)
        && (!subType || !this.excludeMessageTypesAndSubTypes.includes(subType))
      );
    })
  );

  getTagMessageIndex = (tagMessageOffset: number) => tagMessageOffset;

  static getLastMessage = (messages: MessageHistoryMessage[]) => {
    const lastMessage = last(messages);
    const lastMsg = lastMessage
      ? {
        text: lastMessage?.payload?.text || '--',
        dateTime: CHSServices.convertTimestamp(lastMessage?.timestamp),
        publisherName: lastMessage?.payload?.displayName || '--',
        isPatientMessage: CHSServices.isPatient(lastMessage?.payload?.userRole),
        type: lastMessage?.payload?.type,
      } : {
        text: MESSAGE_NOTIFICATION,
        dateTime: 0,
        publisherName: '',
        isPatientMessage: false,
      };
    return lastMsg as MessageContent;
  };

  shouldExcludeMessage = (
    lastMsgPayload?: MessagePayload,
  ) => {
    let isExcluded = false;
    if (lastMsgPayload?.type) {
      const { type, subType = '' } = lastMsgPayload;
      isExcluded = intersection(
        CHSServices.excludeMessageTypesAndSubTypes,
        [type, subType],
      ).length > 0;
    }
    return isExcluded;
  };

  getFromTimestamp = (
    messages: MessageHistory['messages'],
  ) => {
    if (!Array.isArray(messages) || !messages.length) return '0';

    const fromTimestamp = messages[0]?.timestamp || '0';
    return fromTimestamp;
  };

  isACKPublisher = (
    publisher: string,
  ) => (Object.keys(MessageACKPublisher).includes(publisher.split('-')?.[0]));

  getResponse = <T>(value: T) => value;

  getNotificationChannelGroup: CHSRequestFunction<NotificationGroupParams, NotificationGroupResponseData> = (
    { params, headers },
  ) => axios.post(this.endPoints.notificationChannelGroup, params, { headers });

  getPaginatedChannels: CHSRequestFunction<PaginatedChannelsParams, PaginatedChannelsResponseData> = (
    { params, headers },
  ) => axios.post(this.endPoints.paginatedChannels, params, { headers });

  getChannelHistory: CHSRequestFunction<GetChannelHistoryParams, GetChannelHistoryResponseData> = (
    { params, headers },
  ) => axios.post(this.endPoints.history, params, { headers });

  publishMessage: CHSRequestFunction<PublishMessageParams, PublishMessageResponseData> = (
    { params, headers },
  ) => axios.post(this.endPoints.publish, params, { headers });

  initChannel: CHSRequestFunction<InitChannelParams, InitChannelResponseData> = (
    { params, headers },
  ) => axios.post(this.endPoints.initChannel, params, { headers });
}

export const chsServices = new CHSServices();
