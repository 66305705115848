import { ReactNode, useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Checkbox, Popover } from 'antd';
import { useDoNotShowAgain } from '../../../patient/hook/useDoNotShowAlertAgain';

import './CGMDeviceBrandChangeConfirmComponent.tsx.scss';

interface CGMDeviceBrandChangeConfirmComponentProps {
  open: boolean;
  onClose?: () => void;
  children?: ReactNode;
}

export const CGMDeviceBrandChangeConfirmComponent = ({
  open,
  onClose,
  children,
}: CGMDeviceBrandChangeConfirmComponentProps) => {
  const [dontShowAgainValue, setDontShowAgainValue] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useDoNotShowAgain('SwitchToAnotherBrand');

  const handleClose = () => {
    if (dontShowAgainValue) {
      setDontShowAgain(true);
    }
    onClose?.();
  };

  useEffect(() => {
    if (!open && dontShowAgainValue) {
      handleClose();
    }
  }, [open, dontShowAgainValue]);

  if (dontShowAgain) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{children}</>
    );
  }

  return (
    <Popover
      open={open}
      placement="bottomLeft"
      overlayClassName="cgm-device-brand-confirm"
      showArrow={false}
      content={(
        <div className="cgm-device-brand-confirm__content">
          <div className="flex jc-sb">
            <div className="b6 mb5">
              Switching to Another Brand
            </div>
            <CloseOutlined
              onClick={handleClose}
            />
          </div>
          <div className="mb10">
            Your previously linked account will be saved, but only data from the
            currently selected brand will be synced.
          </div>
          <Checkbox
            checked={dontShowAgainValue}
            onChange={(e) => setDontShowAgainValue(e.target.checked)}
          >
            Don't show me again
          </Checkbox>
        </div>
      )}
    >
      {children}
    </Popover>
  );
};
