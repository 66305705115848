import {
  ReactNode,
  createContext,
  useContext,
  useRef,
  useState
} from 'react';
import {
  difference,
  filter,
  forEach,
  intersection,
  keys,
  map,
  omit,
  pick,
  some,
  sortBy,
  uniq
} from 'lodash';
import { message } from 'antd';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import {
  MessageChannel,
  MessageChannelMap,
  MessageUserInfo,
  MessageUserMap,
} from '../../types/message';
import {
  MessageHistory,
  MessageHistoryMessage,
} from '../../services/CHSServices/types/data';
import { useGetNotificationChannelGroup } from '../../services/CHSServices/hooks/useGetNotificationChannelGroup';
import { useLoggedInUserFromContext } from '../loggedInUserContext';
import { useGetPaginatedChannels } from '../../services/CHSServices/hooks/useGetPaginatedChannels';
import { useGetChannelHistory } from '../../services/CHSServices/hooks/useGetChannelHistory';
import {
  GetChannelHistoryParams,
  MessageEventType,
  PaginatedChannelsParams,
  SubscriptionMessagePayload,
} from '../../services/CHSServices/types';
import { UNKNOWN_VALUE } from '../../services/CHSServices/constants';
import { CHSServices, chsServices } from '../../services/CHSServices/CHSServices';
import {
  BucketsTypeEnum,
  ImageTypeEnum,
  MiniPersonResponse,
  Nullable,
  Patient,
  RoleTypeEnum,
  useEmployeeMiniEmployeeList,
  usePatientMiniPatientList
} from '../../uc-api-sdk';
import { useDeepCompareEffect, useDeepCompareMemo } from '../../hooks/useDeepCompareEffect';
import { useInitChannel } from '../../services/CHSServices/hooks/useInitChannel';
import { MessageStorageContextProvider } from './MessageStorageContext';
import { useMixpanelContext } from '../MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../MixpanelContext/MixpanelEvents';
import { useUserActiveContext } from '../UserActiveContext/UserActiveContext';
import { useRefState } from '../../hooks/useRefState/useRefState';
import { useImage } from '../../uiHooks/useImage/useImage';
import EnvConfig from '../../configs/envConfig/envConfig';
import useDebounce from '../../hooks/useDebounce/useDebounce';
import { ApiRequestHelper } from '../../helpers/ApiRequest';

const INACTIVE_PERIOD = 6 * 60 * 60 * 1000; // 6 hours

const AVATAR_BUCKET = BucketsTypeEnum.PRIVATEIMAGEUPLOAD;
const AVATAR_LIFE_TIME = EnvConfig.avatarLifeTime; // 5 minutes

// true: target all, fetch channel history for all notification
// false: target none, add placeholder channel only
// string: target specific patient, fetch and update channel history
type NotificationTarget = string | boolean;

interface HandleSetUserMapParams {
  patientIds: string[],
  employeeIds?: string[],
}

export interface MessageServicesContextValue {
  channelMap: MessageChannelMap;
  // forceClean: true to clean up all channels regardless of updatedAt
  cleanUpChannelMap: (forceClean?: boolean) => void;
  getChannel: (patientId?: string) => MessageChannel | undefined;
  getUserMap: () => Nullable<MessageUserMap> | undefined;
  loggedInUserHasUnread: boolean;
  handleSetChannel: (
    patientId: string,
    messageHistory: MessageHistory,
    isOngoing?: boolean,
  ) => void;
  handleFetchAndSetUserMap: (params: HandleSetUserMapParams) => void;
  handleSetPatientMapData: (patientInfo: Patient) => void;
  patientSearchLoading: boolean;
  paginatedChannelsLoading: boolean;
  getChannelHistoryLoading: boolean;
  handleGetNotificationChannelGroup: () => ReturnType<ReturnType<typeof useGetNotificationChannelGroup>['send']>;
  handleGetPaginatedChannels: (params: PaginatedChannelsParams) => ReturnType<ReturnType<typeof useGetPaginatedChannels>['send']>;
  handleGetUnreadPaginatedChannels: (params?: PaginatedChannelsParams,) => void;
  handleGetChannelHistory: (params: GetChannelHistoryParams) => ReturnType<ReturnType<typeof useGetChannelHistory>['send']>;
  handleInitChannel: (patientId: string) => ReturnType<ReturnType<typeof useInitChannel>['send']>;
  checkChannelHasUnread: (channel?: MessageChannel) => boolean;
  handleMessageNotification: (messagePayload: SubscriptionMessagePayload) => void;
  setNotificationTarget: (target: NotificationTarget) => void;
  setActiveSubscription: (patientId: string, isAdded?: boolean) => void;
  setOnGoingChannel: (patientId: string) => void;
  safeCleanUp: () => void;
  fetchAndSetAvatarUrls: (userInfoMap: MessageUserMap) => void;
}

const MessageServicesContext = createContext<MessageServicesContextValue | undefined>(undefined);

export const useMessageServicesContext = () => {
  const context = useContext(MessageServicesContext);
  return (context || {}) as MessageServicesContextValue;
};

export interface MessageServicesContextProviderProps {
  children: ReactNode;
}
export const MessageServicesContextProvider = ({
  children,
}: MessageServicesContextProviderProps) => {
  const {
    loginInfo,
    userInfo,
    userId,
    isUserLoggedIn,
  } = useLoggedInUserFromContext();
  const { send } = useMixpanelContext();
  const { isActive } = useUserActiveContext();
  const [
    getLastCleanupTime,
    setLastCleanupTime,
  ] = useRefState(Date.now());
  const [channelMap, setChannelMap] = useState<MessageServicesContextValue['channelMap']>({});
  const cleanUpChannelMapRef = useRef<MessageServicesContextValue['cleanUpChannelMap']>();
  const [getUserMap, setUserMap] = useRefState<MessageUserMap>({});
  const [notificationTarget, _setNotificationTarget] = useState<NotificationTarget>(false);
  const [activeSubscriptions, setActiveSubscriptions] = useState<string[]>([]);

  const employeeSearchInfo = useEmployeeMiniEmployeeList({});
  const patientSearchInfo = usePatientMiniPatientList({});

  const notificationChannelGroupInfo = useGetNotificationChannelGroup();
  const paginatedChannelsInfo = useGetPaginatedChannels();
  const getChannelHistoryInfo = useGetChannelHistory();
  const initChannelInfo = useInitChannel();
  const avatarInfo = useImage({ fileKey: '', bucket: AVATAR_BUCKET });

  const setActiveSubscription: MessageServicesContextValue['setActiveSubscription'] = (
    patientId,
    isAdded,
  ) => {
    setActiveSubscriptions((prev) => {
      if (isAdded) {
        return uniq([...prev, patientId]);
      }
      return filter(prev, (p) => p !== patientId);
    });
  };

  const setNotificationTarget = (target: NotificationTarget) => {
    if (target === true) {
      _setNotificationTarget(true);
      return;
    }
    if (typeof target === 'string') {
      if (notificationTarget === true) {
        // do nothing, target=true has higher privilege
        return;
      }
      if (!target) {
        _setNotificationTarget(false);
        return;
      }
    }
    // target is patientId or false
    _setNotificationTarget(target);
  };

  const setOnGoingChannel = (patientId: string) => {
    setChannelMap((prevMap) => {
      const oldChannelInfo = prevMap[patientId] || {};
      return {
        ...prevMap,
        [patientId]: {
          ...oldChannelInfo,
          isOngoing: true,
        },
      };
    });
  };

  const processChannel = (
    patientId: string,
    messageHistory: MessageHistory,
    oldChannelInfo = {} as MessageChannel,
  ): MessageChannel => {
    try {
      const {
        messages,
        teamUnread,
        lastClientACK,
      } = messageHistory || {};
      const readableMessages = CHSServices.getListOfReadableMessages(messages);
      const sortedMessages = sortBy(readableMessages, (msg) => msg.timestamp);
      // history list has desc order
      const lastMsg = CHSServices.getLastMessage(sortedMessages);

      const unreadMessages = teamUnread;
      const hasOfflineMessage = unreadMessages > 0;
      const isUnread = hasOfflineMessage; // (hasOfflineMessage || hasTagMessage);

      return {
        ...oldChannelInfo,
        patientId,
        isUnread,
        unreadMessages,
        lastMsg,
        lastClientACK,
        isPlaceholder: false,
        newNotification: undefined,
        updatedAt: Date.now(),
      } as MessageChannel;
    } catch (error) {
      send({
        event: MixpanelEvents.MessageError,
        properties: {
          error,
          patientId,
          messageHistory,
        },
      });
      return { ...oldChannelInfo };
    }
  };

  const handleSetUnreadChannels = (
    patientIds: string[],
  ) => {
    setChannelMap((prevMap) => {
      const newChannelInfoList = {} as MessageChannelMap;
      forEach(patientIds, (patientId) => {
        const oldChannelInfo = prevMap[patientId] || {};

        newChannelInfoList[patientId] = {
          ...oldChannelInfo,
          isUnread: true,
          isPlaceholder: true,
          isOngoing: true, // all unread channels are ongoing
        };
      });
      return {
        ...prevMap,
        ...newChannelInfoList
      };
    });
  };

  const handleSetChannel: MessageServicesContextValue['handleSetChannel'] = (
    patientId,
    messageHistory,
    isOngoing,
  ) => {
    setChannelMap((prevMap) => {
      if (!patientId) return prevMap;

      const patientChannel = processChannel(
        patientId,
        messageHistory,
        prevMap[patientId],
      );

      if (isOngoing !== undefined) {
        patientChannel.isOngoing = isOngoing;
      }

      return {
        ...prevMap,
        [patientId]: patientChannel,
      };
    });
  };

  const processUserInfo = (
    user?: (MiniPersonResponse & MessageUserInfo),
  ): MessageUserInfo => ({
    id: user?.id,
    firstName: user?.firstName || UNKNOWN_VALUE,
    lastName: user?.lastName || UNKNOWN_VALUE,
    fileKey: user?.fileKey,
    thumbnailLink: user?.thumbnailLink,
    thumbnailLinkFetchAt: user?.thumbnailLinkFetchAt,
    updatedAt: new Date().getTime(),
  });

  const fetchAndSetAvatarUrls: MessageServicesContextValue['fetchAndSetAvatarUrls'] = useDebounce(async (
    userInfoMap,
  ) => {
    const avatarUrlPromises = await Promise.all(map(userInfoMap, async (userInfo) => {
      const { id, fileKey, thumbnailLinkFetchAt } = userInfo;
      let res;
      if (
        fileKey
        && Date.now() - (thumbnailLinkFetchAt || 0) >= AVATAR_LIFE_TIME
      ) {
        res = await ApiRequestHelper.tryCatch(
          avatarInfo.send({
            params: {
              fileDownloadUrlInput: {
                bucket: AVATAR_BUCKET,
                fileKey,
                imageType: ImageTypeEnum.THUMBNAIL,
              },
            },
            options: { cache: false }
          }),
          {
            success: '',
            error: ''
          }
        );
      }
      return Promise.resolve({ id, ...res?.data });
    }));
    setUserMap((prevMap) => {
      const newMap = { ...prevMap } as MessageUserMap;
      avatarUrlPromises.forEach((avatarUrl) => {
        const { id, url } = avatarUrl;
        if (id && newMap[id] && url) {
          newMap[id] = processUserInfo({
            ...newMap[id],
            thumbnailLink: url,
            thumbnailLinkFetchAt: new Date().getTime(),
          });
        }
      });
      return newMap;
    });
  });

  const handleFetchAndSetUserMap: MessageServicesContextValue['handleFetchAndSetUserMap'] = async ({
    patientIds,
    employeeIds,
  }) => {
    const userMap = getUserMap() || {};
    let patientUserMap = {} as MessageUserMap;
    let employeeUserMap = {} as MessageUserMap;
    const patientIdsToSearch = difference(patientIds, Object.keys(userMap));
    if (patientIdsToSearch?.length) {
      if (!patientIdsToSearch.length) return;
      const res = await patientSearchInfo.send({
        params: { request: { idList: patientIdsToSearch } },
      });
      if (!res?.data || res.code !== 200) {
        console.error('Failed to get patient info', res?.msg);
        return;
      }
      const patients = res.data || [];
      patientUserMap = Object.assign({}, ...patients.map((p) => ({
        [p.id as string]: processUserInfo(p),
      })));
    }

    const employeeIdsToSearch = difference(employeeIds, Object.keys(userMap));
    if (employeeIdsToSearch?.length) {
      const res = await employeeSearchInfo.send({
        params: { request: { idList: employeeIdsToSearch } }
      });
      if (!res?.data || res.code !== 200) {
        console.error('Failed to get patient info', res?.msg);
        return;
      }
      const employees = res.data || [];
      employeeUserMap = Object.assign({}, ...employees.map((e) => ({
        [e.id as string]: processUserInfo(e as MiniPersonResponse),
      })));
    }
    const newUserMap = { ...patientUserMap, ...employeeUserMap };
    setUserMap((prev) => {
      const userMap = { ...prev };
      forEach(newUserMap, (userInfo, id) => {
        userMap[id] = {
          ...userMap[id],
          ...userInfo,
        };
      });
      return userMap;
    });
    fetchAndSetAvatarUrls(newUserMap);
  };

  const handleSetPatientMapData: MessageServicesContextValue['handleSetPatientMapData'] = (
    patientInfo,
  ) => {
    if (!patientInfo?.id) return;
    const thumbnailLink = patientInfo.profile?.avatar?.thumbnailLink || undefined;
    setUserMap((prevMap) => ({
      ...prevMap,
      [patientInfo.id as string]: processUserInfo({
        id: patientInfo.id,
        firstName: patientInfo.profile?.firstName,
        lastName: patientInfo.profile?.lastName,
        fileKey: patientInfo.profile?.avatar?.fileKey,
        thumbnailLink,
        thumbnailLinkFetchAt: thumbnailLink ? Date.now() : undefined,
      }),
    }));
  };

  const handleGetNotificationChannelGroup: MessageServicesContextValue['handleGetNotificationChannelGroup'] = () => (
    notificationChannelGroupInfo.send({
      params: { authKey: loginInfo?.chatInfo?.authKey || '' },
    })
  );

  const handleGetPaginatedChannels: MessageServicesContextValue['handleGetPaginatedChannels'] = (
    params,
  ) => (
    paginatedChannelsInfo.send({
      params,
    })
  );

  const handleGetChannelHistory: MessageServicesContextValue['handleGetChannelHistory'] = (
    params,
  ) => {
    const {
      patientIds = [],
      count = 1,
      fromTimestamp,
      ignoreACK = true,
    } = params || {};
    if (!patientIds?.length) return Promise.resolve([]);
    return getChannelHistoryInfo.send({
      params: {
        count,
        patientIds,
        origin: 'new',
        ignoreACK,
        fromTimestamp,
      },
    });
  };

  const handleInitChannel: MessageServicesContextValue['handleInitChannel'] = async (
    patientId,
  ) => {
    // response:
    // init=true, fresh=true => no channel before + init successfully
    // init=false, fresh = false/true => had channel before
    // init=true, fresh=false => issue with server
    const res = await initChannelInfo.send({
      params: {
        patientId,
        userId: userId || '',
      }
    });
    if (
      res
      && res.initChannel
      && !res.freshSubscription
    ) {
      message.error('Failed to subscribe the channel. Please refresh the page again.');
      return undefined;
    }
    return res;
  };

  const getChannel: MessageServicesContextValue['getChannel'] = (
    patientId,
  ) => (
    patientId ? channelMap[patientId] : undefined
  );

  const checkChannelHasUnread: MessageServicesContextValue['checkChannelHasUnread'] = (
    channel
  ) => {
    const redDotFlag = true;
    const { isUnread } = channel || {};
    return redDotFlag && !!isUnread;
  };

  const handleGetUnreadPaginatedChannels: MessageServicesContextValue['handleGetUnreadPaginatedChannels'] = async (
    params,
  ) => {
    const {
      toTimestamp,
      fromTimestamp,
      ...restParams
    } = params || {};
    // get channels
    const res = await handleGetPaginatedChannels({
      toTimestamp: toTimestamp ? CHSServices.parseTimestamp(toTimestamp) : undefined,
      fromTimestamp: fromTimestamp ? CHSServices.parseTimestamp(fromTimestamp) : undefined,
      pageSize: undefined,
      ...restParams,
      unread: true,
    });
    const {
      Channels = [],
    } = res || {};

    const patientIds = Channels?.map((c) => c.split('-')[1]);
    handleSetUnreadChannels(patientIds);
  };

  const loggedInUserHasUnread = useDeepCompareMemo(() => {
    const isRDHC = intersection(
      [RoleTypeEnum.RD, RoleTypeEnum.HC],
      userInfo?.allRoleTypes
    ).length > 0;
    return isRDHC && some(Object.values(channelMap), checkChannelHasUnread);
  }, [channelMap]);

  const handleMarkChannelPlaceholder = (
    patientId: string,
  ) => {
    setChannelMap((prevMap) => ({
      ...prevMap,
      [patientId]: {
        ...(prevMap[patientId] || {}),
        patientId,
        isPlaceholder: true,
      } as MessageChannel,
    }));
  };

  const getSetChannelHistoryForNotification = (
    patientId: string,
    isACKNotification: boolean,
  ) => {
    setTimeout(async () => {
      const res = await handleGetChannelHistory({
        patientIds: [patientId],
        count: 1,
      });
      if (!res) return;
      const data = res[0] || {};
      const {
        messages,
      } = data;
      if (messages.length) {
        const lastMessageInHistory = messages?.[0] || {} as MessageHistoryMessage;
        if (
          !isACKNotification
          && chsServices.shouldExcludeMessage(lastMessageInHistory.payload)
        ) {
          return;
        }
        handleSetChannel(patientId, data);
      }
    }, 350);
  };

  const handleSetChannelHasNewNotification = (
    patientId: string,
    isACK: boolean,
  ) => {
    setChannelMap((prevMap) => {
      const oldChannelInfo = prevMap[patientId] || {};
      return {
        ...prevMap,
        [patientId]: {
          ...oldChannelInfo,
          patientId,
          newNotification: {
            receivedAt: Date.now(),
            isACK,
          }
        } as MessageChannel,
      };
    });
  };

  const handleMessageNotification: MessageServicesContextValue['handleMessageNotification'] = (
    payload,
  ) => {
    const {
      type,
      publisher,
      patient: patientId,
    } = payload || {};
    if (!patientId) {
      return;
    }
    const isPatientMessage = publisher === patientId;
    const isACKNotification = type === MessageEventType.ACK_NOTIFICATION;
    if (
      (notificationTarget === true && activeSubscriptions?.includes(patientId))
      // OR target specific patient => fetch history only for that channel
      || (typeof notificationTarget === 'string'
        && notificationTarget === patientId)
    ) {
      // let history component fetch new message
      handleSetChannelHasNewNotification(patientId, isACKNotification);
      return;
    }
    if (notificationTarget === true) {
      // target all => fetch history for any channel
      getSetChannelHistoryForNotification(
        patientId,
        isACKNotification,
      );
      const patientIds = [];
      const employeeIds = [];
      // fetch patient info
      if (isPatientMessage) {
        patientIds.push(publisher);
      } else {
        employeeIds.push(patientId);
      }
      handleFetchAndSetUserMap({
        patientIds,
        employeeIds,
      });
      return;
    }
    // target none => add placeholder channel IFF message is from patient
    if (isPatientMessage) {
      if (isACKNotification) {
        // ignore patient's ACK message if no target
        return;
      }

      handleSetUnreadChannels([patientId]);
      return;
    }
    // Messages page will fetch history for channels with this flag
    handleMarkChannelPlaceholder(patientId);
  };

  const safeCleanUp = () => {
    const clean = (currentChannel?: MessageChannel) => ({
      ...pick(
        currentChannel,
        ['isUnread', 'isOngoing', 'updatedAt'],
      ),
      isPlaceholder: true,
    } as MessageChannel);
    setChannelMap((prev) => {
      let newChannelMap = { ...prev } as MessageChannelMap;
      forEach(prev, (channel, key) => {
        newChannelMap[key] = clean(channel);
        if (!channel.isOngoing) {
          newChannelMap = omit(newChannelMap, key);
        }
      });
      return newChannelMap;
    });
    setUserMap({});
  };

  const shouldRemoveChannel = (
    channel?: MessageChannel,
    isForce?: boolean, // ignore updatedAt
  ) => {
    // clean up channels
    // AND with no unread messages
    const {
      updatedAt,
      isUnread,
      isOngoing,
    } = channel || {};
    if (isUnread) return false;
    if (isForce && !isOngoing) return true;
    if (!updatedAt) return false; // unknown case
    return Date.now() - updatedAt >= INACTIVE_PERIOD;
  };

  useDeepCompareEffect(() => {
    cleanUpChannelMapRef.current = (
      forceClean,
    ) => {
      const totalChannelCount = keys(channelMap).length;
      let reducedChannelMap = { ...channelMap } as MessageChannelMap;
      let reducedUserMap = { ...getUserMap() } as MessageUserMap;
      const removedIds = [] as string[];
      forEach(channelMap, (channel, key) => {
        if (
          key !== notificationTarget
          && shouldRemoveChannel(channel, forceClean)
        ) {
          reducedChannelMap = omit(reducedChannelMap, key);
          reducedUserMap = omit(reducedUserMap, key);
          removedIds.push(key);
        }
      });
      const reducedChannelCount = keys(reducedChannelMap).length;
      if (totalChannelCount !== reducedChannelCount) {
        send({
          event: MixpanelEvents.MessageChannelCleanup,
          properties: {
            totalChannelCount,
            reducedChannelCount,
            removedIds,
          },
        });
        setChannelMap(reducedChannelMap);
        setUserMap(reducedUserMap);
      }
      setLastCleanupTime(Date.now());
    };
  }, [channelMap, notificationTarget]);

  useDeepCompareEffect(() => {
    if (!isUserLoggedIn) {
      return;
    }
    // remove inactive channels to reduce unused memory
    if (
      isActive
      && (getLastCleanupTime() || 0) + INACTIVE_PERIOD <= Date.now()
      // message container will clean onUnmount
      && notificationTarget !== true
    ) {
      cleanUpChannelMapRef.current?.();
    }
  }, [isUserLoggedIn, isActive, notificationTarget]);

  const contextValue = useGetContextValue<MessageServicesContextValue>({
    channelMap,
    cleanUpChannelMap: (forceClean) => cleanUpChannelMapRef.current?.(forceClean),
    getChannel,
    getUserMap,
    handleSetChannel,
    handleFetchAndSetUserMap,
    patientSearchLoading: patientSearchInfo.isLoading,
    paginatedChannelsLoading: paginatedChannelsInfo.isLoading,
    getChannelHistoryLoading: getChannelHistoryInfo.isLoading,
    handleGetNotificationChannelGroup,
    handleGetPaginatedChannels,
    handleGetUnreadPaginatedChannels,
    handleGetChannelHistory,
    handleInitChannel,
    checkChannelHasUnread,
    handleSetPatientMapData,
    loggedInUserHasUnread,
    handleMessageNotification,
    setNotificationTarget,
    setActiveSubscription,
    setOnGoingChannel,
    safeCleanUp,
    fetchAndSetAvatarUrls,
  }, [
    patientSearchInfo.isLoading,
    paginatedChannelsInfo.isLoading,
    getChannelHistoryInfo.isLoading,
  ]);

  return (
    <MessageServicesContext.Provider value={contextValue}>
      <MessageStorageContextProvider>
        {children}
      </MessageStorageContextProvider>
    </MessageServicesContext.Provider>
  );
};
