import TimezoneService from '../helpers/timezone/timezoneService';
import {
  ConsentFormType,
  ConsentStatusEnum,
  FileInfo,
  PatientConsent,
} from '../uc-api-sdk';

export interface ConsentServiceArg {
  consent?: PatientConsent;
}
export interface FileInfoWithUrl extends FileInfo {
  url?: string;
}
export class ConsentService {
  private consent: PatientConsent | undefined;

  constructor(consent?: PatientConsent) {
    switch (consent?.formType) {
      case ConsentFormType.CLINIC_TERMINATED:
        this.consent = undefined;
        break;
      case ConsentFormType.DEFAULT:
      default:
        this.consent = consent;
    }
  }

  public hasConsent() {
    return !!this.consent?.id
      && this.consent?.status === ConsentStatusEnum.SIGNED
      && this.consent?.isDischarged !== true;
  }

  public hasPendingConsent() {
    return !!this.consent?.id
      && this.consent?.status !== ConsentStatusEnum.SIGNED;
  }

  public isSigned() {
    return this.consent?.status === ConsentStatusEnum.SIGNED
      && this.consent?.isDischarged !== true;
  }

  public isUpdatedConsent() {
    return this.consent?.updatedConsent === true;
  }

  public get id() {
    return this.consent?.id ?? undefined;
  }

  public get signatureMethod() {
    return this.consent?.consentType ?? undefined;
  }

  public get consentHTML() {
    return this.consent?.content ?? undefined;
  }

  public get fileUrl() {
    const info = this.consent?.fileInfo as FileInfoWithUrl;
    return info?.url ?? undefined;
  }

  public get signedDateDayjs() {
    const { signatureAt } = this.consent ?? {};
    if (!signatureAt) return undefined;
    return TimezoneService.calcDateTimeDayjs(signatureAt);
  }
}
