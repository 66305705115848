import { last } from 'lodash';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import {
  CgmBrandEnum, useCgmGetPatientCgmDevice, usePatientGetPatientCgmDevice
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { CGMDeviceItemComponent } from '../../component/CGMDeviceItemComponent/CGMDeviceItemComponent';

export interface CGMDeviceItemContainerProps {
  patientId: string;
}

export const CGMDeviceItemContainer = ({
  patientId,
}: CGMDeviceItemContainerProps) => {
  const patientCGMDeviceInfo = usePatientGetPatientCgmDevice({
    params: { memberId: patientId || '' },
    options: { sendOnMount: !!patientId }
  });
  const cgmDevice = patientCGMDeviceInfo?.data?.data;

  const linkingStatusInfo = useCgmGetPatientCgmDevice({
    params: { patientId, request: { brandIn: [cgmDevice?.brand as CgmBrandEnum] } },
    options: { sendOnMount: !!cgmDevice?.brand }
  });
  const linkingStatus = last(linkingStatusInfo?.data?.data);

  useUpdateListener('CGM_DEVICE_UPDATED', patientCGMDeviceInfo.refetch);

  return (
    <FetchComponent
      info={patientCGMDeviceInfo}
      alwaysShowChildren
      showLoadingOverlay={false}
    >
      {
        cgmDevice?.brand
        && (
          <div className="card">
            <CGMDeviceItemComponent
              patientId={patientId}
              device={cgmDevice}
              linkingStatus={linkingStatus}
            />
          </div>
        )
      }
    </FetchComponent>
  );
};
