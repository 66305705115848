/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
export enum _StorageKeyEnum {
  TOKEN = 'token',
  LAST_FOCUS_TIME = 'lastFocusTime',
  DOCTOR_NAME = 'doctorName',
  CURRENT_USER = 'currentUser',
  PATIENTLIST_TABLE = 'patientlist',
  LOGIN_INFO = 'loginInfo',
  LOADING_USER_INFO = 'loadingUserInfo',
  TREE_DATA = 'treeData',
  CALL_PATIENT_PROFILE = 'callPatientProfile',
  ALL_CALL_PATIENT_PROFILES = 'allCallPatientProfiles',
  ONSITE_INFO = 'onSiteInfo',
  CLINIC_TO_CARE_GROUPS = 'clinicToCareGroups',
  CALL_LOGS = 'callLogs',
  NOT_SHOW_ELIGIBILITY_CONFIRM = 'notShowEligibilityConfirm',
  LAB_DATE_COLLECTED = 'labDateCollected',
  TABLES_FILTERS_SORTERS = 'tableFiltersSorters',
  PATIENT_CARE_OTHER_FILTERS = 'patientCareOtherFilters',
  PATIENTS_TABLE_LAST_SELECTED_TABLE = 'patientsTableLastSelectedTable',
  CURRENT_PLATFORM = 'currentPlatform',
  SELECTED_MED_ORG_HIERARCHY_NODE_ID = 'selectedMedOrgHierarchyNodeId',
  SELECTED_MED_ORG_HIERARCHY_NODE_IDS = 'selectedMedOrgHierarchyNodeIds',
  PROVIDER_VIEW_SELECTED_CLINIC = 'providerViewSelectedClinic',
  PATIENT_TABLE_NAME='patientTableName',
  SELECTED_MEDICAL_ALERT_DASHBOARD_CLINIC = 'selectedMedicalAlertDashboardClinic',
  SELECTED_MEDICAL_ALERT_DASHBOARD_ALERT_TYPE = 'selectedMedicalAlertDashboardAlertType',
  SELECTED_MEDICAL_ALERT_DASHBOARD_FROM_TO_DATE = 'selectedMedicalAlertDashboardFromToDate',
  ENABLE_INSIGHT = 'enableInsight', // for testing only, also support by envConfig
  ENABLE_UNICARE_COPILOT = 'enableUnicareCopilot', // for preview only
  EHR_QUERY = 'ehrQuery',
  EHR_URL = 'ehrUrl',
  AVATAR = 'avatar',
  BATCH_MESSAGE_TEXT_CONTENT = 'batchMessageTextContent',
  TRANSLATION_PREFIX = 'translation-',
  DEFAULT_CLINIC = 'defaultClinic',
  BATCH_MESSAGE_TYPE = 'batchMessageType',
  BATCH_MESSAGE_FILE_CONTENT = 'batchMessageFileContent',
  RECENT_PATIENT_CARE_PATIENTS = 'recentPatients',
  OLD_APP_VERSION_NOTE_CLOSED = 'oldAppVersionNoteClosed',
  DO_NOT_SHOW_UNTIL_AI_LAB_RESULT_FEEDBACK = 'doNotShowUntil-AiLabResultFeedbackUntil',
  RELOAD_ON_LAZY_LOAD_ERROR = 'ReloadOnLazyLoadError',
  AUTO_SAVE = 'AutoSave',
  WEIGHT_UNIT = 'weightUnit',
}
