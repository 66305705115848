import { useUpdate } from '../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper, Options } from '../../../helpers/ApiRequest';
import useDebounce from '../../../hooks/useDebounce/useDebounce';
import {
  PatientCgmDevice,
  usePatientUpdatePatientCgmDevice
} from '../../../uc-api-sdk';

export const useCgmUpdateHelper = (
  patientId: string,
) => {
  const updatePatientCGMDeviceInfo = usePatientUpdatePatientCgmDevice({});
  const cgmUpdateHook = useUpdate('CGM_DEVICE_UPDATED');

  const updatePatientCGMDevice = useDebounce((
    newCgmDevice?: Partial<PatientCgmDevice>,
    options?: Partial<Options<string>> & {
      success?: string;
      error?: string;
      onSuccess?: () => void;
    }
  ) => {
    const {
      onSuccess,
      ...restOptions
    } = options || {};
    return ApiRequestHelper.tryCatch(
      updatePatientCGMDeviceInfo.send({
        params: {
          memberId: patientId,
          updateRequest: {
            brand: null,
            model: null,
            ...newCgmDevice
          }
        }
      }),
      {
        success: '',
        error: 'Failed to update CGM device.',
        ...(restOptions || {}),
        onSuccess: () => {
          cgmUpdateHook.updateValue();
          onSuccess?.();
        },
      }
    );
  }, 250, [patientId]);

  return {
    updatePatientCGMDeviceInfo,
    updatePatientCGMDevice,
  };
};
