import { ColumnType } from 'antd/lib/table';
import { MeasurementDeletionReasonEnumComponent } from '../../../../enumComponent/MeasurementDeletionReasonEnumComponent/MeasurementDeletionReasonEnumComponent';
import { Measurement, ResultSourceEnum } from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { UserNameTitleComponent } from '../../../user/component/UserNameTitleComponent/UserNameTitleComponent';
import { HSLogBookDateComponent } from '../HSLogBookDateComponent/HSLogBookDateComponent';
import { HSLogBookWeightColComponent } from '../HSLogBookWeightColComponent/HSLogBookWeightColComponent';

export const createHSDeletionLogTableColumns = {
  date: (): ColumnType<Measurement> => ({
    title: 'Date and Time',
    dataIndex: 'date',
    key: 'date',
    render: (v, record) => (
      <HSLogBookDateComponent
        source={record.resultSource as ResultSourceEnum}
        date={record.date || ''}
        timezone={record.timezone || undefined}
        count={1}
      />
    ),
  }),
  weight: (): ColumnType<Measurement> => ({
    title: 'Weight(kg)',
    dataIndex: 'body_weight',
    key: 'body_weight',
    width: 100,
    render: (v, record) => (
      <HSLogBookWeightColComponent
        weight={Number(record?.body_weight?.value?.toFixed(1)) || 0}
        tookMedication={record.hadMedicine || false}
      />
    ),
  }),
  deleteBy: (): ColumnType<Measurement> => ({
    title: 'Deleted By',
    dataIndex: 'deletedByUser',
    key: 'deletedByUser',
    width: 200,
    render: (v) => <DisplayOrEmptyComponent value={<UserNameTitleComponent value={v} />} />,
  }),
  deleteReason: (): ColumnType<Measurement> => ({
    title: 'Delete Reason',
    dataIndex: 'deleteReason',
    key: 'deleteReason',
    width: '30%',
    render: (v) => <MeasurementDeletionReasonEnumComponent value={v} />,
  }),
  deleteDate: (): ColumnType<Measurement> => ({
    title: 'Delete Date',
    dataIndex: 'deleteDate',
    key: 'deleteDate',
    width: 170,
    render: (v, record) => (
      <DisplayDateComponent
        value={record.updatedAt}
        timezone={record.timezone || undefined}
        format="USA_DATE"
      />
    )
  }),
};
