import { Checkbox, Col, Row } from 'antd';
import {
  intersection,
  last,
  map,
  uniq,
  without,
} from 'lodash';
import { ProgramCategoryEnum } from '../../../../uc-api-sdk';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';
import { getBasicProgramCategoriesOptions } from '../../helper/ProgramCategoriesOptions';
import { acceptedEnrollmentPrograms } from './constant';

export interface EnrollmentProgramsCheckboxComponentProps {
  value?: ProgramCategoryEnum[];
  onChange?: (value?: ProgramCategoryEnum[]) => void;
  medicalOrgProgramParticipation?: ProgramCategoryEnum[];
  disabledPrograms?: ProgramCategoryEnum[];
  className?: string;
}

export const EnrollmentProgramsCheckboxComponent = ({
  value,
  onChange,
  medicalOrgProgramParticipation,
  disabledPrograms,
  className,
}: EnrollmentProgramsCheckboxComponentProps) => {
  const availablePrograms = useDeepCompareMemo(() => {
    if (!medicalOrgProgramParticipation) return undefined;
    const orderedPrograms = intersection(
      acceptedEnrollmentPrograms,
      medicalOrgProgramParticipation,
    );
    return getBasicProgramCategoriesOptions(orderedPrograms);
  }, [medicalOrgProgramParticipation]);

  const handleOnChange = (values: ProgramCategoryEnum[]) => {
    let selectedPrograms = [...values];
    const valuesToExclude = [] as ProgramCategoryEnum[];
    const isAdded = selectedPrograms.length > (value?.length || 0);
    if (isAdded) {
      const newSelection = last(selectedPrograms);
      // either CCM or APCM can exist at a time
      switch (newSelection) {
        case ProgramCategoryEnum.CCM:
          valuesToExclude.push(ProgramCategoryEnum.APCM);
          break;
        case ProgramCategoryEnum.APCM:
          valuesToExclude.push(ProgramCategoryEnum.CCM);
          // auto-add RPM
          selectedPrograms.push(ProgramCategoryEnum.RPM);
          break;
        default:
      }
    }
    if (!isAdded && !selectedPrograms.includes(ProgramCategoryEnum.RPM)) {
      // if RPM is removed, remove APCM as well
      valuesToExclude.push(ProgramCategoryEnum.APCM);
    }
    selectedPrograms = without(selectedPrograms, ...valuesToExclude);
    selectedPrograms = intersection(uniq(selectedPrograms), medicalOrgProgramParticipation);
    onChange?.(selectedPrograms);
  };

  return (
    <Checkbox.Group
      value={value}
      onChange={(values) => handleOnChange(values as ProgramCategoryEnum[])}
      className={className}
    >
      <Row gutter={10}>
        {
          map(
            availablePrograms,
            ({ label, value }) => (
              <Col span={12} key={value}>
                <Checkbox
                  className="block"
                  value={value}
                  disabled={disabledPrograms?.includes(value)}
                >
                  {label}
                </Checkbox>
              </Col>
            )
          )
        }
      </Row>
    </Checkbox.Group>
  );
};
