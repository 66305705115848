import { Col } from 'antd';
import { ProgramCategoryEnumComponent } from '../../../../enumComponent/ProgramCategoriesComponent/ProgramCategoriesComponent';
import { Program } from '../../../../uc-api-sdk';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { PatientProfileDateDisplayComponent } from '../PatientProfileDateDisplayComponent/PatientProfileDateDisplayComponent';

export interface PatientProfileProgramEnrollmentItemComponentProps {
  programEnrollment: Program;
}

export const PatientProfileProgramEnrollmentItemComponent = ({
  programEnrollment,
}: PatientProfileProgramEnrollmentItemComponentProps) => {
  if (!programEnrollment.category) {
    return <EmptyComponent />;
  }

  return (
    <div className="flex f-w jc-sb mb20">
      <Col span={14}>
        <ProgramCategoryEnumComponent
          value={programEnrollment.category}
          type="long"
        />
      </Col>
      <Col span={9}>
        <PatientProfileDateDisplayComponent
          value={programEnrollment.createdAt}
          label="Started on"
        />
      </Col>
    </div>
  );
};
