import { Button } from 'antd';
import {
  AddOnServiceEnum,
  PatientProtocolEnum,
  ProgramCategoryEnum,
  VitalEnumType
} from '../../../../uc-api-sdk';
import { VitalEnumTypeListComponent } from '../../../../enumComponent/VitalsEnumTypeComponent/VitalEnumTypeListComponent';
import { ProgramCategoryEnumListComponent } from '../../../../enumComponent/ProgramCategoriesComponent/ProgramCategoryEnumListComponent';
import { SuccessLottie } from '../../../../assets/lottie/SuccessLottie/SuccessLottie';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { EnrollmentCareProtocolComponent } from '../EnrollmentCareProtocolComponent.tsx/EnrollmentCareProtocolComponent';
import { AddOnServiceEnumComponent } from '../../../../enumComponent/AddOnServiceEnumComponent/AddOnServiceEnumComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface EnrollmentSuccessComponentProps {
  vitals: VitalEnumType[];
  enrolledPrograms: ProgramCategoryEnum[];
  recommendedProtocol?: PatientProtocolEnum | null;
  addOnServices?: AddOnServiceEnum[];
  onOkay: () => void;
}

export const EnrollmentSuccessComponent = ({
  vitals,
  enrolledPrograms,
  recommendedProtocol,
  addOnServices,
  onOkay,
}: EnrollmentSuccessComponentProps) => {
  const { send: sendMixpanel } = useMixpanelContext();

  const handleOkay = () => {
    sendMixpanel({ event: MixpanelEvents.PatientEnrollmentDone });
    onOkay();
  };

  return (
    <div className="flex fd-c jc-c ai-c w100 h100">
      <SuccessLottie />
      <h3>Good Job</h3>
      <p className="mb5">You've successfully enrolled this patient!</p>
      <div className="flex ai-c">
        <div className="b6">Care Protocol:</div>
        <div>
          {
            recommendedProtocol ? (
              <EnrollmentCareProtocolComponent
                recommendedProtocol={recommendedProtocol}
                hasCGMService={addOnServices?.includes(AddOnServiceEnum.CGM)}
              />
            ) : <DisplayOrEmptyComponent />
          }
        </div>
      </div>
      <div className="flex ai-c">
        <div className="b6">Vitals to Monitor:</div>
        <div>
          <VitalEnumTypeListComponent
            value={vitals}
            splitter=", "
            type="long"
          />
        </div>
      </div>
      <div className="flex ai-c">
        <div className="b6">Additional Services:</div>
        <div>
          {
            addOnServices?.map((service) => (
              <AddOnServiceEnumComponent
                key={service}
                value={service}
                type="service"
              />
            ))
          }
        </div>
      </div>
      <div className="flex ai-c">
        <div className="b6">Billing Programs:</div>
        <div>
          <ProgramCategoryEnumListComponent
            value={enrolledPrograms}
            type="short"
            splitter="/"
          />
        </div>
      </div>
      <Button
        type="primary"
        onClick={handleOkay}
        className="mt40 w100px"
      >
        Got it
      </Button>
    </div>
  );
};
