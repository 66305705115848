import { Radio, RadioGroupProps } from 'antd';
import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { map } from 'lodash';
import { CGMDeviceBrandChangeConfirmComponent } from '../CGMDeviceBrandChangeConfirmComponent.tsx/CGMDeviceBrandChangeConfirmComponent';
import { InputType } from '../../../Input/types';
import { CgmBrandEnum, CgmSupportedDevice } from '../../../../uc-api-sdk';
import { CGMDeviceBrandEnumComponent } from '../../../../enumComponent/CGMDeviceBrandEnumComponent/CGMDeviceBrandEnumComponent';

interface CGMDeviceBrandSelectorComponentProps extends InputType<CgmBrandEnum> {
  data?: CgmSupportedDevice[];
}

export const CGMDeviceBrandSelectorComponent = ({
  data,
  value,
  onChange,
}: CGMDeviceBrandSelectorComponentProps) => {
  const [showSwitchBrandConfirm, setShowSwitchBrandConfirm] = useState<boolean>(false);
  const timeoutOpen = useRef<NodeJS.Timeout>();
  const timeoutClose = useRef<NodeJS.Timeout>();

  const handleOnBrandChange: RadioGroupProps['onChange'] = (e) => {
    clearInterval(timeoutOpen.current);
    timeoutOpen.current = setTimeout(() => setShowSwitchBrandConfirm(true), 800);
    onChange?.(e.target.value);
  };

  useEffect(() => {
    if (showSwitchBrandConfirm) {
      clearTimeout(timeoutClose.current);
      timeoutClose.current = setTimeout(() => setShowSwitchBrandConfirm(false), 5000);
    }
  }, [showSwitchBrandConfirm]);

  useEffect(() => () => {
    clearTimeout(timeoutOpen.current);
    clearTimeout(timeoutClose.current);
  }, []);

  const options = useMemo(() => (
    map(data, (option) => ({
      label: <CGMDeviceBrandEnumComponent value={option.brand} />,
      value: option.brand as CgmBrandEnum,
    }))
  ), [data]);

  return (
    <CGMDeviceBrandChangeConfirmComponent
      open={showSwitchBrandConfirm}
      onClose={() => setShowSwitchBrandConfirm(false)}
    >
      <Radio.Group
        value={value}
        onChange={handleOnBrandChange}
        options={options}
      />
    </CGMDeviceBrandChangeConfirmComponent>
  );
};
