import { useCallback } from 'react';
import { ApiRequestHelper } from '../../helpers/ApiRequest';
import { FileDownloadUrlOutput, useBatchSendConsentDownloadSignStatusList } from '../../uc-api-sdk';
import { useFileDownloadRequestHelper } from '../useFileDownloadRequestHelper/useFileDownloadRequestHelper';

export const useDownloadCsvFile = () => {
  const downloadHook = useBatchSendConsentDownloadSignStatusList({});
  const handleDownload = useCallback((fileInfo: FileDownloadUrlOutput) => {
    const res = downloadHook.send({
      params: {
        fileInfo,
      },
    });

    ApiRequestHelper.tryCatch(res, {
      success: 'Download will begin in a moment!',
      error: 'Download failed!',
      onSuccess: (data) => {
        useFileDownloadRequestHelper({ data: data?.data as string, fileType: 'CSV', fileName: 'patients.csv' });
      },
    });
  }, []);
  return { handleDownload };
};
