import { EnrolledProgramStatusEnum, Nullable } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export const getEnrolledStatusText = (value?: string | null) => {
  if (!value) return 'Unenrolled';

  switch (value) {
    case EnrolledProgramStatusEnum.ENROLLED:
      return 'Enrolled';
    case EnrolledProgramStatusEnum.UNENROLLED:
      return 'Unenrolled';
    case EnrolledProgramStatusEnum.DISCHARGED:
      return 'Discharged';
    case EnrolledProgramStatusEnum.REJECTED:
      return 'Rejected';
    default:
      return undefined;
  }
};

export interface EnrolledProgramStatusEnumComponentProps {
  value?: Nullable<EnrolledProgramStatusEnum>
}

export const EnrolledProgramStatusEnumComponent = ({
  value
}: EnrolledProgramStatusEnumComponentProps) => (
  <span>
    {getEnrolledStatusText(value) || <DisplayOrEmptyComponent value={value} />}
  </span>
);
