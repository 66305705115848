import { AddOnServiceEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface AddOnServiceEnumComponentProps {
  value: AddOnServiceEnum | null | undefined;
  type?: 'short' | 'long' | 'service';
}

export const AddOnServiceEnumComponent = ({
  value,
  type = 'short',
}: AddOnServiceEnumComponentProps) => {
  const getLongText = () => {
    switch (value) {
      case AddOnServiceEnum.CGM: return 'Continuous Glucose Monitoring (CGM)';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  const getShortText = () => {
    switch (value) {
      case AddOnServiceEnum.CGM: return 'CGM';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  const getServiceText = () => {
    switch (value) {
      case AddOnServiceEnum.CGM: return 'CGM Service';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  const getText = () => {
    switch (type) {
      case 'service': return getServiceText();
      case 'long': return getLongText();
      case 'short':
      default: return getShortText();
    }
  };

  return (
    <span>{getText()}</span>
  );
};
