import { Checkbox, CheckboxProps } from 'antd';
import { InsuranceMedCareTooltipComponent } from './InsuranceMedCareTooltipComponent';

export interface InsuranceMedCareCheckboxComponentProps {
  value?: boolean;
  onChange?: (checked: boolean) => void;
}

export const InsuranceMedCareCheckboxComponent = ({
  value,
  onChange,
}: InsuranceMedCareCheckboxComponentProps) => {
  const handleChange: CheckboxProps['onChange'] = (e) => {
    const { checked } = e.target;
    onChange?.(checked);
  };

  return (
    <Checkbox
      checked={!!value}
      onChange={handleChange}
    >
      <div className="flex gap0 ai-c">
        <span>
          MCare and M-M eligible
        </span>
        <InsuranceMedCareTooltipComponent />
      </div>
    </Checkbox>
  );
};
