import { Switch } from 'antd';
import React, { FC } from 'react';
import { Dayjs } from 'dayjs';
import { PatientCommonCardTitleComponent } from '../../../patient/component/PatientCommonCardTitleComponent/PatientCommonCardTitleComponent';
import { LastMeasurementComponent } from '../LastMeasurementComponent/LastMeasurementComponent';

export interface VitalHeaderComponentProps {
  title: string;
  unit: string | undefined;
  switchText?: string;
  onSwitchChange?: () => void;
  lastMeasurementAt?: Dayjs;
  checked?: boolean;
}

export const VitalHeaderComponent: FC<VitalHeaderComponentProps> = ({
  title,
  unit,
  switchText,
  onSwitchChange,
  lastMeasurementAt,
  checked,
}) => {
  const renderUnit = () => {
    if (unit) {
      return (
        <div>
          (
          {unit}
          )
        </div>
      );
    }
    return null;
  };

  return (
    <div className="flex jc-sb ai-c w100 f-w">
      <div className="flex ai-c mr30">
        <PatientCommonCardTitleComponent>
          {title}
        </PatientCommonCardTitleComponent>
        {renderUnit()}
        <div className="ml30">
          <LastMeasurementComponent value={lastMeasurementAt} />
        </div>
      </div>
      {onSwitchChange && (
        <div className="flex ai-c">
          {
            title === 'Weight'
              ? <Switch onChange={onSwitchChange} checked={checked} />
              : <Switch onChange={onSwitchChange} />
          }
          <div>
            {switchText}
          </div>
        </div>
      )}
    </div>
  );
};

export default VitalHeaderComponent;
