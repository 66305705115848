import { last } from 'lodash';
import { useEffect } from 'react';
import { CgmBrandEnum, useCgmGetPatientCgmDevice } from '../../../../uc-api-sdk';
import { CGMDeviceAccountLinkingComponent, CGMDeviceAccountLinkingComponentProps } from '../../component/CGMDeviceAccountLinkingComponent/CGMDeviceAccountLinkingComponent';
import { useCGMDeviceContext } from '../CGMDeviceContainer/CGMDeviceContainer';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';

export interface CGMDeviceAccountLinkingContainerProps
  extends CGMDeviceAccountLinkingComponentProps { }

export const CGMDeviceAccountLinkingContainer = ({
  patientId,
  brand,
  model,
}: CGMDeviceAccountLinkingContainerProps) => {
  const cgmDeviceContext = useCGMDeviceContext();
  const info = useCgmGetPatientCgmDevice({
    params: { patientId, request: { brandIn: [brand] } },
    options: { sendOnMount: !cgmDeviceContext && !!patientId },
  });

  const patientCGMDeviceInfo = (
    cgmDeviceContext?.patientCGMDeviceLinkStatusInfo || info
  );
  const patientCGMDevices = patientCGMDeviceInfo?.data?.data || [];

  const fetchNewStatus = (brand: CgmBrandEnum) => {
    if (cgmDeviceContext) {
      cgmDeviceContext?.getPatientCGMDeviceLinkStatusByBrand(brand);
    } else {
      patientCGMDeviceInfo.send({
        params: {
          patientId, request: { brandIn: [brand] }
        }
      });
    }
  };
  useUpdateListener('CGM_LINKING_UPDATED', () => fetchNewStatus(brand));

  useEffect(() => {
    fetchNewStatus(brand);
  }, [brand]);

  return (
    <CGMDeviceAccountLinkingComponent
      patientId={patientId}
      linkingStatus={last(patientCGMDevices)}
      isLoading={patientCGMDeviceInfo.isLoading}
      brand={brand}
      model={model}
    />
  );
};
