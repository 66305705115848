import ModalButton from '../../../../uiComponent/ModalButton/ModalButton';
import { HSDeletionLogTableContainer } from '../../container/HSDeletionLogTableContainer/HSDeletionLogTableContainer';

export interface HSDeletionLogModalComponentProps {
  memberId: string;
}

export const HSDeletionLogModalComponent = ({
  memberId,
}: HSDeletionLogModalComponentProps) => (
  <ModalButton
    button="View Deletion Log"
    buttonType="text"
    buttonClassName="underline text-gray-scale-2 p0"
    width={1200}
    title="Weight Deletion Log"
  >
    <HSDeletionLogTableContainer memberId={memberId} />
  </ModalButton>
);
