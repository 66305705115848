import { useMedicalOrganizationGet } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { MedicalOrgCurrentConsentComponent } from '../../component/MedicalOrgCurrentConsentComponent/MedicalOrgCurrentConsentComponent';

export interface MedicalOrgCurrentConsentContainerProps {
  clinicId?: string;
}

export const MedicalOrgCurrentConsentContainer = ({
  clinicId
}: MedicalOrgCurrentConsentContainerProps) => {
  const clinicInfo = useMedicalOrganizationGet({
    params: {
      id: clinicId || ''
    },
    options: {
      sendOnMount: !!clinicId
    }
  });

  return (
    <FetchComponent
      info={clinicInfo}
    >
      {({ data }) => (
        <MedicalOrgCurrentConsentComponent
          consentFormId={data.rawValue.programParticipation?.[0].consentFormId || ''}
        />
      )}
    </FetchComponent>
  );
};
