import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useEffect, useState } from 'react';
import StrictFormData from '../../../../hooks/ajaxRequest/formData/strictFormData';
import { UploadPatientListResult } from '../../../../uc-api-sdk';
import { useBatchSendConsentUploadPatientList } from '../../hook/useBatchSendConsentUploadPatientList';

export interface BatchConsentCsvRowCountContainerProps {
  file?: RcFile;
  onFileStatusChange: (file: UploadPatientListResult | undefined) => void;
}

export const BatchConsentCsvRowCountContainer = ({
  file,
  onFileStatusChange,
}: BatchConsentCsvRowCountContainerProps) => {
  const { uploadPatientCsv } = useBatchSendConsentUploadPatientList();
  const [count, setCount] = useState(0);
  const getRowCount = async (file: RcFile) => {
    try {
      const strictFormData = new StrictFormData({
        ptListFile: file as Blob,
      });
      const res = await uploadPatientCsv(strictFormData);
      if (res?.code && ![200, 204].includes(res.code)) {
        throw new Error(res.msg as string);
      }
      if (res?.data?.patientIdList?.length) {
        setCount(res.data.patientIdList.length);
        onFileStatusChange(res.data);
      } else {
        setCount(0);
        onFileStatusChange(undefined);
      }
    } catch (e) {
      message.error(
        'Error occurred while getting patient count, please verify the file includes the correct data and try again.',
        5
      );
      setCount(0);
      onFileStatusChange(undefined);
    }
  };

  useEffect(() => {
    if (file) {
      getRowCount(file);
    } else {
      setCount(0);
    }
  }, [file]);

  return (
    <div className="CsvRowCountComponent bg-gray5 p20 b5 mt10">
      {`Total patients: ${count}`}
    </div>
  );
};
