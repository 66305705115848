import { lowerCase, map, startCase } from 'lodash';
import { FC, ReactNode } from 'react';
import { MeasurementTagsComponent } from '../../../../uiComponent/MeasurementTagsComponent/MeasurementTagsComponent';
import { LogbookSingleInfoComponent } from './LogbookSingleInfoComponent';
import { VitalEnumType } from '../../../../uc-api-sdk';

export interface LogbookInfoComponentProps {
  hadActivity: boolean;
  hadInsulin: boolean;
  hadMedicine: boolean;
  moodTags: string[];
  patientNote: string;
  others?: Array<{ title: string, content: ReactNode }>;
  children?: ReactNode;
  vitalType?: VitalEnumType;
  isIotDevice?: boolean;
}

export const LogbookInfoComponent: FC<LogbookInfoComponentProps> = ({
  hadActivity,
  hadInsulin,
  hadMedicine,
  moodTags = [],
  patientNote = '',
  others = [],
  children,
  vitalType,
  isIotDevice,
}) => {
  if (children) {
    return <div>{children}</div>;
  }
  return (
    <div>
      <LogbookSingleInfoComponent
        title="Tags"
        content={(
          <MeasurementTagsComponent
            vitalType={vitalType}
            hadActivity={hadActivity}
            hadInsulin={hadInsulin}
            hadMedicine={hadMedicine}
            isIotDevice={isIotDevice}
          />
        )}
      />
      <LogbookSingleInfoComponent
        title="Mood and Symptoms"
        content={moodTags.map((tag) => startCase(lowerCase(tag || ''))).join(', ')}
      />
      <LogbookSingleInfoComponent
        title="Patient Notes (Measurement)"
        content={patientNote}
        emptyMessage="No Comment"
      />
      {map(others, (v, k) => (
        <LogbookSingleInfoComponent key={k} title={v.title} content={v.content} />
      ))}
    </div>
  );
};
