import { NonNullUndefined } from '../../../types/common';
import { AlertSeverityEnum, BgSeverityEnum, Measurement } from '../../../uc-api-sdk';
import { BGHelper } from './BGHelper';
import { MeasurementService } from './MeasurementService';

export class BGMeasurementService extends MeasurementService {
  constructor(measurement: Measurement) {
    super(measurement as NonNullUndefined<Measurement>);
  }

  get beforeMeal() {
    return this.value.beforeMeal;
  }

  get mealType() {
    return this.value.mealType;
  }

  get severity() {
    return this.value.bgSeverity;
  }

  get bloodGlucose() {
    return {
      value: BGHelper.mmollToMgdl(this.value.blood_glucose.value || 0),
      unit: BGHelper.getMgdlUnit(),
    };
  }

  get chartSeverity() {
    switch (this.severity) {
      case BgSeverityEnum.CRITICAL: return AlertSeverityEnum.CRITICAL;
      case BgSeverityEnum.LOW:
      case BgSeverityEnum.HIGH:
        return AlertSeverityEnum.RISK;
      case BgSeverityEnum.NORMAL:
      default: return AlertSeverityEnum.NORMAL;
    }
  }

  get severityColor() {
    switch (this.severity) {
      case BgSeverityEnum.VERY_LOW:
      case BgSeverityEnum.CRITICAL:
        return '#f0666b';
      case BgSeverityEnum.LOW:
      case BgSeverityEnum.HIGH:
        return '#fdb543';
      case BgSeverityEnum.NORMAL:
        return '#37cc8d';
      default: return '#37cc8d';
    }
  }

  get deviceModel() {
    return this.value.deviceModel;
  }
}
