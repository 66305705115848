import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';

export const InsuranceMedCareTooltipComponent = () => (
  <TooltipComponent
    type="info-icon"
    title={(
      <div>
        <div>MCare: Medicare</div>
        <div>M-M: Medicare and Medicaid (Dual eligibility)</div>
      </div>
    )}
    overlayClassName="no-max-width"
    arrowPointAtCenter={false}
  />
);
