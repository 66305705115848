export type DownloadableFileType = 'CSV' | 'XLSX';

export const CONTENT_TYPE_MAP: Record<DownloadableFileType, string> = {
  CSV: 'text/csv; charset=utf-8',
  XLSX: 'application/x-www-form-urlencoded; charset=UTF-8'
};

export interface UseFileDownloadRequestHelperProps {
  data: string;
  fileType: DownloadableFileType;
  fileName: string;
}

export const useFileDownloadRequestHelper = (
  { data, fileType, fileName }: UseFileDownloadRequestHelperProps,
) => {
  const request = new XMLHttpRequest();
  request.open('GET', data, true);
  request.setRequestHeader('Content-Type', CONTENT_TYPE_MAP[fileType]);
  request.responseType = 'blob';

  request.onload = () => {
    if (request.status === 200) {
      const blob = request.response;
      const downloadLink = window.document.createElement('a');
      const contentTypeHeader = request.getResponseHeader('Content-Type');
      downloadLink.href = window.URL.createObjectURL(
        new Blob([blob], { type: contentTypeHeader as string }),
      );
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };
  request.send();
};
