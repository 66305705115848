import { Checkbox, Col, Row } from 'antd';
import {
  difference,
  find,
  map
} from 'lodash';
import { useMemo } from 'react';
import {
  AssociatedInfo,
  ProgramCategoryEnum, PatientProtocolEnum,
  AddOnServiceEnum
} from '../../../../uc-api-sdk';
import CardComponent from '../../../../uiComponent/CardComponent/CardComponent';
import { EligibleProgramsTooltipComponent } from '../../../../uiComponent/EligibleProgramsTooltipComponent/EligibleProgramsTooltipComponent';
import { VitalsToMonitorTooltipComponent } from '../../../../uiComponent/VitalsToMonitorTooltipComponent/VitalsToMonitorTooltipComponent';
import { ProviderSelectContainer, ProviderSelectContainerProps } from '../../../../uiComponent/Transcribing/component/ProviderSelectContainer/ProviderSelectContainer';

import './PatientVitalsAndProgramsComponent.scss';
import { ClinicSelectComponent } from '../../../../uiComponent/ClinicSelectComponent/ClinicSelectComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PotentialPatientInfoComponent } from '../../../patient/component/PotentialPatientInfoComponent/PotentialPatientInfoComponent';
import { useProgramEnrollmentForm } from '../../hook/useProgramEnrollmentForm/useProgramEnrollmentForm';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { AddOnServiceEnumComponent } from '../../../../enumComponent/AddOnServiceEnumComponent/AddOnServiceEnumComponent';
import { EnrollmentCareProtocolComponent } from '../EnrollmentCareProtocolComponent.tsx/EnrollmentCareProtocolComponent';
import { EnrollmentProgramsCheckboxComponent } from '../EnrollmentProgramsCheckboxComponent/EnrollmentProgramsCheckboxComponent';
import { acceptedEnrollmentPrograms } from '../EnrollmentProgramsCheckboxComponent/constant';
import { EnrollmentVitalsCheckboxComponent } from '../EnrollmentVitalsCheckboxComponent/EnrollmentVitalsCheckboxComponent';

export interface PatientVitalsAndProgramsComponentProps {
  isEnrolled?: boolean;
  clinicProgramParticipation?: ProgramCategoryEnum[];
  isSaasModel?: boolean;
  isAPCMEligible?: boolean;
  eligiblePrograms: ProgramCategoryEnum[];
  onClinicChange?: (clinicId: string) => void;
  associatedList?: AssociatedInfo[];
  onValuesChange?: (changedValues: Record<string, unknown>) => void;
  recommendedProtocol?: PatientProtocolEnum;
  addOnServices?: AddOnServiceEnum[];
}

export const PatientVitalsAndProgramsComponent = ({
  isEnrolled,
  clinicProgramParticipation,
  isSaasModel,
  isAPCMEligible,
  eligiblePrograms,
  onClinicChange,
  associatedList,
  onValuesChange,
  recommendedProtocol,
  addOnServices,
}: PatientVitalsAndProgramsComponentProps) => {
  const { isProvider } = useLoggedInUserFromContext();
  const peForm = useProgramEnrollmentForm();
  const isCGMEligible = addOnServices?.includes(AddOnServiceEnum.CGM);

  const updateFieldValue: typeof peForm['form']['setFieldValue'] = (
    key: keyof typeof peForm['formInput'],
    value: unknown,
  ) => {
    peForm.form.setFieldsValue({ [key]: value });
    onValuesChange?.({ [key]: value });
  };

  const disabledPrograms = useMemo(() => {
    let disabledPrograms = [] as ProgramCategoryEnum[];
    if (!isSaasModel) {
      // if SaaS model, enable all accepted programs
      disabledPrograms = difference(acceptedEnrollmentPrograms, eligiblePrograms);
    }
    if (isAPCMEligible) {
      disabledPrograms = disabledPrograms.filter((p) => p !== ProgramCategoryEnum.APCM);
    }
    return disabledPrograms;
  }, [isAPCMEligible, isSaasModel, eligiblePrograms]);

  const handleOnProviderListLoaded: ProviderSelectContainerProps['onProviderListLoaded'] = (
    providerList,
  ) => {
    const clinicId = peForm.getValue('clinicId', peForm.form.getFieldValue);
    const currentProvider = peForm.getValue('provider', peForm.form.getFieldValue);
    const providerListIds = map(providerList, 'id');
    const associatedProviderId = find(associatedList, { clinicId })?.doctorId;
    // if provider list for new selected clinic includes the selected provider, then keep selection
    // else select provider from associated list, if applicable
    // else select the first provider from the list
    if (!providerListIds.includes(currentProvider)) {
      let newProviderId = providerListIds[0] ?? undefined;
      if (associatedProviderId && providerListIds.includes(associatedProviderId)) {
        newProviderId = associatedProviderId;
      }
      updateFieldValue(peForm.getName('provider'), newProviderId);
    }
  };

  return (
    <div className="patient-enrollment-vitals-and-programs">
      <p>Recommended based on the diagnose selection.</p>
      <CardComponent hovered className="vitals-monitoring-card">
        <VitalsToMonitorTooltipComponent>
          <h4>Vitals to Monitor</h4>
        </VitalsToMonitorTooltipComponent>
        <FormItem info={peForm.getInfo('vitals')}>
          <EnrollmentVitalsCheckboxComponent
            className="checkbox-group-btn-style w100"
            isCGMEligible={isCGMEligible}
          />
        </FormItem>
        {
          isCGMEligible && (
            <Row className="mt20">
              <Col span={24}>
                <h4>{peForm.getLabel('addOnServices')}</h4>
              </Col>
              <Col span={24}>
                <FormItem
                  name={peForm.getName('addOnServices')}
                >
                  <Checkbox.Group
                    className="checkbox-group-btn-style programs-checkbox-group w100"
                  >
                    <Row gutter={10}>
                      {
                        map(AddOnServiceEnum, (v) => (
                          <Col span={12}>
                            <Checkbox
                              key={v}
                              value={v}
                              className="block"
                            >
                              <AddOnServiceEnumComponent
                                value={v}
                                type="service"
                              />
                            </Checkbox>
                          </Col>
                        ))
                      }
                    </Row>
                  </Checkbox.Group>
                </FormItem>
              </Col>
            </Row>
          )
        }
        <Row
          gutter={10}
          className="confirm-patient-clinic mt20"
        >
          <Col span={24}>
            <h4>Confirm patient's clinic</h4>
          </Col>
          <FormItem
            noStyle
            shouldUpdate={peForm.shouldUpdate(['clinicId', 'provider'])}
          >
            {
              ({ getFieldValue }) => {
                const clinicId = peForm.getValue('clinicId', getFieldValue);
                return (
                  <>
                    <Col span={12}>
                      <FormItem
                        info={peForm.getInfo('clinicId')}
                        required
                      >
                        <ClinicSelectComponent
                          getAllClinics={!isProvider}
                          disabled={isEnrolled}
                          getClinicData={(clinic) => onClinicChange?.(clinic?.id || '')}
                          paginatedSearch={false}
                        />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        info={peForm.getInfo('provider')}
                        required
                      >
                        <ProviderSelectContainer
                          clinicIds={clinicId ? [clinicId] : undefined}
                          onProviderListLoaded={handleOnProviderListLoaded}
                        />
                      </FormItem>
                    </Col>
                  </>
                );
              }
            }
          </FormItem>
          <Col span={24} className="mt5">
            <PotentialPatientInfoComponent
              associatedList={associatedList}
            />
          </Col>
        </Row>
        <EligibleProgramsTooltipComponent>
          <h4>Billing Program Selection</h4>
        </EligibleProgramsTooltipComponent>
        <FormItem info={peForm.getInfo('programs')}>
          <EnrollmentProgramsCheckboxComponent
            medicalOrgProgramParticipation={clinicProgramParticipation}
            disabledPrograms={disabledPrograms}
            className="checkbox-group-btn-style programs-checkbox-group w100"
          />
        </FormItem>
        <FormItem
          noStyle
          shouldUpdate={peForm.shouldUpdate(['addOnServices'])}
        >
          {
            ({ getFieldValue }) => {
              if (!recommendedProtocol) {
                return null;
              }
              const selectedServices = peForm.getValue('addOnServices', getFieldValue);
              const isCGMSelected = selectedServices?.includes(AddOnServiceEnum.CGM);
              return (
                <EnrollmentCareProtocolComponent
                  recommendedProtocol={recommendedProtocol}
                  hasCGMService={isCGMSelected}
                />
              );
            }
          }
        </FormItem>
      </CardComponent>
    </div>
  );
};
