import { ConsentFormNameContainer } from '../../medicalOrg/container/ConsentFormNameContainer/ConsentFormNameContainer';

export interface MedicalOrgCurrentConsentComponentProps {
  consentFormId: string;
}

export const MedicalOrgCurrentConsentComponent = ({
  consentFormId
}: MedicalOrgCurrentConsentComponentProps) => (
  <div className="flex bg-gray5 p20">
    <span className="autoW">Consent form used: </span>
    <span>
      <ConsentFormNameContainer
        id={consentFormId}
      />
    </span>
  </div>
);
